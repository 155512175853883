import { Neo } from "@singularsystems/neo-react";
import Button from "Components/Button";
import TextAsParagraphs from "Components/TextAsParagraphs";
import { textConstants } from "common/textConstants";
import React from "react";
import * as Icon from 'react-feather';

interface IUploadModal {
  show: boolean;
  title: string;
  onClose: () => void;
  onDocumentSelected: (e: FileList | File[]) => void
  downloadTemplate: () => void
  fileName: string;
  clearFileList: () => void;
  addFile: () => void;
  disableAdd: boolean;
  uploadTitle: string;
  description?: string[];
}

export default class BlacklistAndGreyListUploadModal extends React.Component<IUploadModal> {

  render() {
    const { show, title, onClose, onDocumentSelected, downloadTemplate,
      fileName, clearFileList, addFile, disableAdd, uploadTitle, description } = this.props

    return (
      <Neo.Modal
        title={title}
        show={show}
        onClose={onClose}
        className={"blacklistAndGreylistModal"}
      >
        <Neo.GridLayout xl={1} lg={1} md={1} sm={1} xs={1} className="">
          {description && <div className="neg-m-md smallText">
            <TextAsParagraphs paragraphArray={description} />
          </div>}
          <ol>
            <a className="text-right secondaryDownloadLink"
              onClick={downloadTemplate}>{textConstants.generalText.DownloadTemplate.toLocaleUpperCase()}
            </a>
          </ol>
          <Neo.FileContext onFilesSelected={onDocumentSelected} allowedExtensions={[".csv"]}>
            <Neo.FileDropArea multiple={false}>
              <p className="text-center dropZoneText">
                <Neo.FileUploadButton>
                  <Icon.Upload />
                  {textConstants.generalText.uploadOrDragFile}
                </Neo.FileUploadButton>
              </p>
            </Neo.FileDropArea>
          </Neo.FileContext>

          {fileName &&
            <div className="row m-0 mt-2">
              <p className="mt-2 mb-0 fontSize16">Uploaded File: {fileName} </p>
              <a className="mr-2 ml-2 mt-1 mb-0" onClick={clearFileList}>
                <Icon.Trash2 />
              </a>
            </div>
          }
        </Neo.GridLayout>
        <div className="d-grid align-self-end modal-button">
          <Button variant={"contained"} buttonType={"primary"} size={"medium"}
            disabled={disableAdd}
            onClick={addFile}>
            {uploadTitle}
          </Button>
        </div>
      </Neo.Modal>
    )
  }
}