import { ILanguagesApiClient } from './LanguagesApiClient';
import { IManagementFunctionsApiClient } from './ManagementFunctionsApiClient';
import { ITargetMarketStatusesApiClient } from './TargetMarketStatusesApiClient';
import { ITargetMarketFunctionsApiClient } from './TargetMarketFunctionsApiClient';
import { ITargetMarketAccountStatusesApiClient } from './TargetMarketAccountStatusesApiClient';
import { ISourceTypesApiClient } from './SourceTypesApiClient';
import { ISeniorityLevelsApiClient } from './SeniorityLevelsApiClient';
import { IRolesApiClient } from './RolesApiClient';
import { IReProspectingStatusesApiClient } from './ReProspectingStatusesApiClient';
import { IMasterAccountStatusesApiClient } from './MasterAccountStatusesApiClient';
import { IFunctionTypesApiClient } from './FunctionTypesApiClient';
import { IEmployeeSizesApiClient } from './EmployeeSizesApiClient';
import { IAccountStatusesApiClient } from './AccountStatusesApiClient';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import { ICountriesApiClient } from './CountriesApiClient';
import { IIndustriesApiClient } from './IndustriesApiClient';
import { ISubIndustriesApiClient } from './SubIndustriesApiClient';
import { ITargetMarketTypesApiClient } from './TargetMarketTypesApiClient';
import {IClientsApiClient } from './ClientsApiClient';
import { IManagementRolesApiClient } from './ManagementRolesApiClient';
import { IIROEditStatusApiClient } from './IROEditStatusApiClient';
import { IExcludedKeywordsApiClient } from './ExcludedKeywordsApiClient';
import { ISubRegionApiClient } from './SubRegionApiClient';
import { IRegionApiClient } from './RegionApiClient';
import { IPlatformsApiClient } from './PlatformsApiClient';
import { ITargetMarketAccountTabsApiClient } from './TargetMarketAccountTabsApiClient';
import { IComXConfigurationsApiClient } from './ComXConfigurationsApiClient';
import { IInformationManagementApiClient } from './InformationManagementApiClient';
import { ICampaignMessageStatusesApiClient } from './CampaignMessageStatusesApiClient';
import { ICampaignMessageTypesApiClient } from './CampaignMessageTypesApiClient';
import { ICustomerProfileStatusesApiClient } from './CustomerProfileStatusesApiClient';
import { ITechnicalSettingsConfigApiClient } from './TechnicalSettingsConfigApiClient';
import { IEmailProviderTypesApiClient } from './EmailProviderTypesApiClient';
import { ICustomerProfileQuestionsApiClient } from './CustomerProfileQuestionsApiClient';
import { ICalendlyLinksApiClient } from './CalendlyLinksApiClient';
import { IDashboardCardsApiClient } from './DashboardCardsApiClient';
import { IDashboardTooltipApiClient } from './DashboardTooltipsApiClient';
import { IDashboardTooltipTypeApiClient } from './DashboardTooltipTypeApiClient';
import { IInvalidReasonsApiClient } from './InvalidReasonsApiClient';
import { IFollowUpTemplateCategories } from './FollowUpTemplateCategoriesApiClient';
import { IDashboardReportConfigApiClient } from './DashboardReportConfigApiClient';
import { IBackgroundJobTypeApiClient } from './BackgroundJobTypeApiClient';
import { IOnboardingMaintenanceApiClient } from './OnboardingMaintenanceApiClient';
import { IOnboardingStepsApiClient } from './OnboardingStepsApiClient';
import { ILeadTypesApiClient } from './LeadTypesApiClient';
import { IActionListStatusesApiClient } from './ActionListStatusesApiClient';
import { ILeadTypeStatusesApiClient } from './LeadTypeStatusesApiClient';
import { INavigationStepsApiClient } from './NavigationStepsApiClient';
import { IIndustriesVersionApiClient } from './IndustriesVersionApiClient';
import { IDemoClientsApiClient } from './DemoClientsApiClient';

export interface IComXApiClient {
    countries: ICountriesApiClient,
    followUpTemplateCategories: IFollowUpTemplateCategories,
    industries : IIndustriesApiClient,
    subIndustries: ISubIndustriesApiClient,
    accountStatuses: IAccountStatusesApiClient,
    employeeSizes: IEmployeeSizesApiClient,
    functionTypes: IFunctionTypesApiClient,
    masterAccountStatuses: IMasterAccountStatusesApiClient,
    reProspectingStatuses: IReProspectingStatusesApiClient,
    roles: IRolesApiClient,
    seniorityLevels: ISeniorityLevelsApiClient,
    sourceTypes: ISourceTypesApiClient,
    targetMarketAccountStatuses: ITargetMarketAccountStatusesApiClient,
    targetMarketFunctions: ITargetMarketFunctionsApiClient,
    targetMarketStatuses: ITargetMarketStatusesApiClient,
    targetMarketTypes: ITargetMarketTypesApiClient,
    clients: IClientsApiClient,
    managementFunctions: IManagementFunctionsApiClient,
    managementRoles: IManagementRolesApiClient,
    languages: ILanguagesApiClient,
    iroEditStatuses: IIROEditStatusApiClient,
    excludedKeywords: IExcludedKeywordsApiClient,
    subRegions: ISubRegionApiClient,
    regions: IRegionApiClient,
    platforms: IPlatformsApiClient,
    targetMarketAccountTabs: ITargetMarketAccountTabsApiClient,
    comXConfigurations: IComXConfigurationsApiClient,
    dashboardReportConfigs: IDashboardReportConfigApiClient,
    backgroundJobTypeConfigs: IBackgroundJobTypeApiClient,
    informationManagement: IInformationManagementApiClient
    campaignMessageStatuses: ICampaignMessageStatusesApiClient,
    campaignMessageTypes: ICampaignMessageTypesApiClient,
    customerProfileStatuses : ICustomerProfileStatusesApiClient,
    technicalSettingsConfig : ITechnicalSettingsConfigApiClient,
    emailProviderTypes : IEmailProviderTypesApiClient,
    customerProfileQuestions : ICustomerProfileQuestionsApiClient,
    calendlyLinks : ICalendlyLinksApiClient,
    dashboardCards : IDashboardCardsApiClient,
    dashboardTooltips : IDashboardTooltipApiClient,
    dashboardTooltipType : IDashboardTooltipTypeApiClient,
    invalidReasons : IInvalidReasonsApiClient,
    onboardingMaintenance: IOnboardingMaintenanceApiClient,
    onboardingSteps: IOnboardingStepsApiClient,
    leadTypes: ILeadTypesApiClient,
    leadTypeStatuses: ILeadTypeStatusesApiClient,
    actionListStatuses: IActionListStatusesApiClient,
    navigationSteps: INavigationStepsApiClient,
    industriesVersions: IIndustriesVersionApiClient,
    demoClients: IDemoClientsApiClient,
}

@injectable()
export class ComXApiClient implements IComXApiClient {
   
    constructor(public countries = AppService.get(Types.ApiClients.CountriesApiClient),
                public followUpTemplateCategories = AppService.get(Types.ApiClients.FollowUpTemplateCategoriesApiClient),
                public industries = AppService.get(Types.ApiClients.IndustriesApiClient),
                public subIndustries = AppService.get(Types.ApiClients.SubIndustriesApiClient),
                public accountStatuses = AppService.get(Types.ApiClients.AccountStatusesApiClient),
                public employeeSizes = AppService.get(Types.ApiClients.EmployeeSizesApiClient),
                public functionTypes = AppService.get(Types.ApiClients.FunctionTypesApiClient),
                public masterAccountStatuses = AppService.get(Types.ApiClients.MasterAccountStatusesApiClient),
                public reProspectingStatuses = AppService.get(Types.ApiClients.ReProspectingStatusesApiClient),
                public roles = AppService.get(Types.ApiClients.RolesApiClient),
                public seniorityLevels = AppService.get(Types.ApiClients.SeniorityLevelsApiClient),
                public sourceTypes = AppService.get(Types.ApiClients.SourceTypesApiClient),
                public targetMarketAccountStatuses = AppService.get(Types.ApiClients.TargetMarketAccountStatusesApiClient),
                public targetMarketFunctions = AppService.get(Types.ApiClients.TargetMarketFunctionsApiClient),
                public targetMarketStatuses = AppService.get(Types.ApiClients.TargetMarketStatusesApiClient),
                public targetMarketTypes = AppService.get(Types.ApiClients.TargetMarketTypesApiClient),
                public clients = AppService.get(Types.ApiClients.ClientsApiClient),
                public managementFunctions = AppService.get(Types.ApiClients.ManagementFunctionsApiClient),
                public managementRoles = AppService.get(Types.ApiClients.ManagementRolesApiClient),
                public languages = AppService.get(Types.ApiClients.LanguagesApiClient),
                public iroEditStatuses = AppService.get(Types.ApiClients.IROEditStatusApiClient),
                public excludedKeywords = AppService.get(Types.ApiClients.ExcludedKeywordsApiClient),
                public subRegions = AppService.get(Types.ApiClients.SubRegionApiClient),
                public regions = AppService.get(Types.ApiClients.RegionApiClient),
                public platforms = AppService.get(Types.ApiClients.PlatformsApiClient),
                public targetMarketAccountTabs = AppService.get(Types.ApiClients.TargetMarketAccountTabsApiClient),
                public comXConfigurations = AppService.get(Types.ApiClients.ComXConfigurationsApiClient),
                public dashboardReportConfigs = AppService.get(Types.ApiClients.DashboardReportConfigApiClient),
                public backgroundJobTypeConfigs = AppService.get(Types.ApiClients.BackgroundJobTypeApiClient),
                public informationManagement = AppService.get(Types.ApiClients.InformationManagementApiClient),
                public campaignMessageStatuses = AppService.get(Types.ApiClients.CampaignMessageStatusesApiClient),
                public campaignMessageTypes = AppService.get(Types.ApiClients.CampaignMessageTypesApiClient),
                public customerProfileStatuses = AppService.get(Types.ApiClients.CustomerProfileStatusesApiClient),
                public technicalSettingsConfig = AppService.get(Types.ApiClients.TechnicalSettingsConfigApiClient),
                public emailProviderTypes = AppService.get(Types.ApiClients.EmailProviderTypesApiClient),
                public customerProfileQuestions = AppService.get(Types.ApiClients.CustomerProfileQuestionsApiClient),
                public calendlyLinks = AppService.get(Types.ApiClients.CalendlyLinksApiClient),
                public dashboardCards = AppService.get(Types.ApiClients.DashboardCardsApiClient),
                public dashboardTooltips = AppService.get(Types.ApiClients.DashboardTooltipApiClient),
                public dashboardTooltipType = AppService.get(Types.ApiClients.DashboardTooltipTypeApiClient),
                public invalidReasons = AppService.get(Types.ApiClients.InvalidReasonsApiClient),
                public onboardingMaintenance = AppService.get(Types.ApiClients.OnboardingMaintenanceApiClient),
                public onboardingSteps = AppService.get(Types.ApiClients.OnboardingStepsApiClient),
                public leadTypes = AppService.get(Types.ApiClients.LeadTypesApiClient),
                public leadTypeStatuses = AppService.get(Types.ApiClients.LeadTypeStatusesApiClient),
                public actionListStatuses = AppService.get(Types.ApiClients.ActionListStatusesApiClient),
                public navigationSteps = AppService.get(Types.ApiClients.NavigationStepsApiClient),
                public industriesVersions = AppService.get(Types.ApiClients.IndustriesVersionsApiClient),
                public demoClients = AppService.get(Types.ApiClients.DemoClientsApiClient)
                ) {
    }
}