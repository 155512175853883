import {NeoModel, ValueObject, Rules} from '@singularsystems/neo-core';

@NeoModel
export default class OutboundEmailCriteria extends ValueObject{

  @Rules.Required()
  public clientId: number = 0;
  
  public searchString: string = "";

  public isDeleted: boolean = false
}