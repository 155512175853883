import React from 'react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import ProspectingVM from './ProspectingVM';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import CopyToClipboard from 'react-copy-to-clipboard';
import { NotifyUtils } from '@singularsystems/neo-core';
import SubIndustryRange from '../../Models/Prospecting/SubIndustryRange';
import IROJobSection from './IROSaerch/IROJobSection';
import UploadProspectsTab from './UploadProspects/UploadProspectsTab';
import PlatformSearchLookup from '../../Models/Scraping/PlatformSearchLookup';
import SegmentedProgressBar, { IProgressSegment } from '../../Components/SegmentedProgressBar';
import CustomPagingControl from '../../Components/CustomPagingControl';
import * as Icon from 'react-feather';
import Loading from '../../Components/Loading';
import Button from 'Components/Button/Button';

interface IProspectingProps {
  viewModel: ProspectingVM,
  back: () => void
}

@observer
export default class ProspectingView extends React.Component<IProspectingProps> {

  private getSearchStatusSegments(platformSearchLookup: PlatformSearchLookup): IProgressSegment[] {
    return [
      { name: "Complete", value: platformSearchLookup.completedAccountsPercentage, variant: "primary" },
    ];
  }

  public onDocumentSelected(fileList: File[] | FileList, uploadType: string) {
    this.props.viewModel.fileList = fileList
    this.props.viewModel.fileName = fileList[0].name;
    this.props.viewModel.documentUpload(uploadType);
  }

  public highlightAB() {
    return this.props.viewModel.isAB === true ? "highlightedText" : "";
  }

  public highlightIRO() {
    return this.props.viewModel.isIRO === true ? "highlightedText" : "";
  }

  private showCopySuccess() {
    var variant = "success"
    NotifyUtils.add(variant, textConstants.messageText.saveMessage.TextToClipboard,
      variant as any, 5);
  }

  public render() {
    const DownloadProspectListTab =
      // Download List of Accounts
      <Neo.Tab header={textConstants.titleText.DownloadProspectList}>
        {/* Download Accounts to Prospect */}
        <div className='row m-0'>
          <div className='col-12 p-0'>
            <h3 className='p-0 mt-5'>{textConstants.titleText.DownloadProspectList}</h3>
            <div>
              <Neo.GridLayout md={4} xl={4}>
                {/* Total Number of Accounts Available */}
                <div> <Neo.FormGroup display={this.props.viewModel.meta.numberOfAccounts} /> </div>
                {/* Download */}
                <div className='download-prospect'>
                  <Button
                    buttonType='primary'
                    className="pull-left w-100 customMarginTop31"
                    variant="hasIcon"
                    size="xSmall"
                    onClick={() => this.props.viewModel.downloadAccountsToProspect()}
                  >
                    {textConstants.buttonText.Download}
                  </Button>
                </div>
                {/* Batch */}
                <div>
                  <Neo.FormGroup
                    display={this.props.viewModel.meta.batch} />
                </div>
              </Neo.GridLayout>
            </div>

            {/* Other Downloads */}
            <h3 className='p-0 mt-4 pt-2'>{textConstants.titleText.OtherDownloads}</h3>
            <Neo.GridLayout md={2} xl={2}>
              <div className="d-flex">
                <label className="domainFontSize">{textConstants.titleText.GatsbyTargetMarketSystemID}</label>
                <label className="marginLeft10">{this.props.viewModel.targetMarketId}</label>
              </div>
            </Neo.GridLayout>

            {/* Batches in Progress */}
            <h3 className='p-0 mt-4 pt-2'>{textConstants.titleText.BatchesInProgress}</h3>
            <Neo.GridLayout md={1} xl={1}>
              {this.props.viewModel.batchesInProgressLookup.map(b => (
                <div key={b.downloadedBy} className={"row mt-1"}>
                  <div className={"col-4  mt-1"}>
                    <span> -{b.batchCount} Accounts - Downloaded by - </span>
                    <span className={""}>{b.downloadedBy}</span>
                    <span>
                      <Neo.Button className="redownloadButton mx-2"
                        isOutline
                        icon={<Icon.Download color='#111e1e' />}
                        variant="secondary"
                        onClick={() => this.props.viewModel.reDownloadAccountsToProspect(b.userId)}>
                      </Neo.Button>
                    </span>
                  </div>
                </div>
              ))}
            </Neo.GridLayout>
          </div>
        </div>
      </Neo.Tab>

    const UploadListOfAccountsTab =
      // Upload List of Accounts
      <Neo.Tab header={textConstants.titleText.UploadListOfAccounts}>
        <div className='row m-0'>
          <div className='col-12 p-0'>
            <h3 className='p-0 mt-4 mb-2'>{textConstants.titleText.UploadProspectedAccounts}</h3>
            <div className='row m-0'>

              {/* AB / IRO toggle */}
              <div className='col-9 p-0'>
                <div className='row m-0'>
                  <label className={`${this.highlightAB()} mr-1`}> {textConstants.titleText.ABProspectedAccounts}</label>
                  <Neo.FormGroup
                    bind={this.props.viewModel.meta.isABOrIRO}
                    className={`${this.highlightIRO()} ml-1`}
                    input={{ type: "switch" }}
                    onChange={() => { this.props.viewModel.toggleIsABOrIRO() }} />
                </div>
              </div>

              {/* Download Template */}
              <ol className='col-3'>
                <a className="text-right customDownloadLink"
                  onClick={() => this.props.viewModel.downloadTemplate()}>{textConstants.generalText.DownloadTemplate}
                </a>
              </ol>
            </div>

            <div className='row m-0'>
              <div className='col-12 p-0'>
                <Neo.FileContext onFilesSelected={(e) => this.onDocumentSelected(e, "ABIRO")} allowedExtensions={[".csv"]}>
                  <Neo.FileDropArea multiple={false}>
                    <p className="text-center dropZoneText">
                      <Neo.FileUploadButton>
                        <Icon.Upload />
                        {textConstants.generalText.uploadOrDragFile}
                      </Neo.FileUploadButton>
                    </p>
                  </Neo.FileDropArea>
                </Neo.FileContext>
                <p className="mt-2 mb-0"> Uploaded File: {this.props.viewModel.fileName} </p>
              </div></div>
          </div>
        </div>
      </Neo.Tab>

    const SearchesAndLPCTab =
      // Searches & LPC Template
      <Neo.Tab header={textConstants.titleText.SearchesAndLPC}>
        <div className='row negSideMargins24'>
          <h3 className='col-xl-8 p-24 pt-0 mt-4 mb-2'>{textConstants.titleText.SearchesAndLPC}</h3>
          <div className='col-xl-4 p-24 mt-4 mb-2'>
            <Button
              buttonType='primary'
              className="pull-right mt-3"
              variant="hasIcon"
              size="xSmall"
              onClick={() => this.props.viewModel.downloadLPCSearch()}
            >
              {textConstants.buttonText.DownloadLPC}
            </Button>
          </div>
        </div>
        <div className="row negSideMargins24">
          <NeoGrid.Grid items={this.props.viewModel.LPCTemplateList}>
            {(template, templateMeta) => (
              <NeoGrid.Row >
                <NeoGrid.Column display={templateMeta.order} />
                <NeoGrid.Column display={templateMeta.searchText} />
                <NeoGrid.ButtonColumn>
                  <CopyToClipboard text={template.searchText} onCopy={() => this.showCopySuccess()}>
                    <Neo.Button variant='light' >{textConstants.buttonText.Copy}</Neo.Button>
                  </CopyToClipboard>
                </NeoGrid.ButtonColumn>
              </NeoGrid.Row>
            )}
          </NeoGrid.Grid>
        </div>

      </Neo.Tab>

    const XingTab =
      // XING
      <Neo.Tab header={textConstants.titleText.Xing}>
        <div className="row negSideMargins24 ">
          <h3 className='col-xl-8 pt-0 p-24 mt-4 mb-2'>{textConstants.titleText.XINGSearches}</h3>
          <div className="col-xl-4 mt-4 mb-2 p-24">
            <Button
              buttonType='primary'
              className="pull-right mb-3"
              variant="hasIcon"
              size="xSmall"
              onClick={() => this.props.viewModel.openUploadModal()}
            >
              {textConstants.buttonText.StartNewSearch}
            </Button>
          </div>
        </div>

        <div className="row negSideMargins24">
          <div className="col-lg-12 p-0">
            <Neo.Pager pageManager={this.props.viewModel.xingPageManager} pageControls="none">
              <NeoGrid.Grid<PlatformSearchLookup>>
                {(platformSearchLookup, meta) => (
                  <NeoGrid.Row >

                    {/* Search */}
                    <NeoGrid.Column
                      readOnly
                      display={meta.searchFilename}
                      label={textConstants.titleText.Search}
                      className="cursorPointer"
                    />

                    {/* Date Created */}
                    <NeoGrid.Column
                      display={meta.createdDate}
                      label={textConstants.titleText.DateCreated}
                      width={145}
                      className="text-center"
                    />

                    {/* Created By */}
                    <NeoGrid.Column
                      display={meta.createdBy}
                      label={textConstants.titleText.CreatedBy}
                      width={145}
                      className="text-center"
                    />

                    {/* Status */}
                    <NeoGrid.Column
                      width={150}
                      label={textConstants.titleText.Status}>

                      {platformSearchLookup.completedAccountsPercentage < 100 &&
                        <Loading text="Searching" />
                      }

                      {platformSearchLookup.completedAccountsPercentage === 100 &&
                        <SegmentedProgressBar segments={this.getSearchStatusSegments(platformSearchLookup)} total={100} />
                      }
                    </NeoGrid.Column>

                    {/* Results */}
                    <NeoGrid.Column
                      display={meta.totalResults}
                      width={100}
                      label={textConstants.titleText.Results}>
                    </NeoGrid.Column>

                    {/* Requests */}
                    <NeoGrid.Column
                      display={meta.totalRequests}
                      width={100}
                      label={textConstants.titleText.Requests}>
                    </NeoGrid.Column>

                    {/* Requests per result */}
                    <NeoGrid.Column
                      display={meta.requestsPerResult}
                      width={100}
                      label={textConstants.titleText.RequestsPerResult}>
                    </NeoGrid.Column>

                    {/* Requests per minute */}
                    <NeoGrid.Column
                      display={meta.requestsPerMinute}
                      width={100}
                      label={textConstants.titleText.RequestsPerMinute}>
                    </NeoGrid.Column>

                    {/* Download */}
                    <NeoGrid.Column
                      width={150}
                      label={textConstants.titleText.Download}>

                      <Neo.Button
                        className='textColour'
                        variant="light"
                        icon={<Icon.Download />}
                        disabled={platformSearchLookup.totalResults === 0}
                        onClick={() => this.props.viewModel.downloadPlatformSearchResults(platformSearchLookup)}>
                      </Neo.Button>
                    </NeoGrid.Column>

                    {/* Delete */}
                    {platformSearchLookup.completedAccountsPercentage === 100 &&
                      <NeoGrid.ButtonColumn
                        deleteButton={{
                          icon: <Icon.Trash2 />,
                          onClick: () => this.props.viewModel.openPlatformDeleteModal(platformSearchLookup)
                        }}
                      />
                    }

                    {/* Stop */}
                    {platformSearchLookup.completedAccountsPercentage < 100 &&
                      <NeoGrid.ButtonColumn className='paddingRight30'>
                        <Icon.Square
                          onClick={() => this.props.viewModel.stopSearch(platformSearchLookup.platformSearchId)}
                          className={"stopButton"}
                        />
                      </NeoGrid.ButtonColumn>
                    }

                  </NeoGrid.Row>
                )}
              </NeoGrid.Grid>
            </Neo.Pager>
          </div>
        </div>
        <div className='col-12 p-24'>
          <CustomPagingControl
            displayLabel={true}
            pageManager={this.props.viewModel.xingPageManager} />
        </div>
      </Neo.Tab >

    const IROSearchTab =
      // IRO Search
      <Neo.Tab header={textConstants.titleText.IROSearch}>
        <h3 className='p-0 mt-5 mb-2'>{textConstants.titleText.IROSearch}</h3>

        <Neo.GridLayout xl={2} lg={2} md={2} sm={2} xs={2}>
          {/* On IRO STAGE */}
          <div className="">
            <label className={""}> {textConstants.titleText.OnIROStage} </label>
            <Neo.FormGroup
              bind={this.props.viewModel.meta.IsIROStage}
              input={{ type: "switch" }}
              label={" "}
              onChange={() => { this.props.viewModel.saveIsIROStage() }}
            />
            <span className="regionWarning">{this.props.viewModel.regionWarningMessage}</span>
          </div>

          {/* IRO EDIT STATUS */}
          <div className="text-left">
            <Neo.FormGroup
              bind={this.props.viewModel.meta.IROEditStatusId}
              select={{ items: this.props.viewModel.IROEditStatuses }}
              onChange={() => { this.props.viewModel.saveIROEditStatus() }} />
          </div>
        </Neo.GridLayout>

        {/* Job Title, Job Levels, Job Functions */}
        <IROJobSection viewModel={this.props.viewModel} showCopySuccess={this.showCopySuccess} />

        {/* Employees */}
        <h3 className='p-0 mt-5 mb-2'>{textConstants.titleText.Employees}</h3>
        <div className="row negSideMargins24">
          <div className="col-lg-12 p-0">
            <NeoGrid.Grid items={this.props.viewModel.employeeSizeRangeList} className="w-50">
              {(employeeSizeRange, employeeSizeRangeMeta) => (
                <NeoGrid.Row >
                  <NeoGrid.Column display={employeeSizeRangeMeta.add} />
                  <NeoGrid.Column display={employeeSizeRangeMeta.keep} />
                  <NeoGrid.Column display={employeeSizeRangeMeta.remove} />
                </NeoGrid.Row>
              )}
            </NeoGrid.Grid>
          </div>
        </div>

        {/* Sub Markets */}
        <h3 className='p-0 mt-5 mb-2'>{textConstants.titleText.SubMarkets}</h3>
        {(!this.props.viewModel.subIndustryPageManager.hasFetched || this.props.viewModel.subIndustryPageManager.hasFetched === null) &&
          <Neo.Loader show={this.props.viewModel.showSpinner}>
            <div>
              <p>  </p>
            </div>
          </Neo.Loader>
        }
        <div className="row negSideMargins24">
          <div className="col-lg-12 p-0">
            <React.Fragment>
              < Neo.Pager pageManager={this.props.viewModel.subIndustryPageManager} className="w-50" pageControls="none">
                {(data) => (
                  <NeoGrid.Grid<SubIndustryRange> items={data}>
                    {(subIndustry, subIndustryMeta) => (
                      <NeoGrid.Row >
                        <NeoGrid.Column display={subIndustryMeta.add} />
                        <NeoGrid.Column display={subIndustryMeta.keep} />
                        <NeoGrid.Column display={subIndustryMeta.remove} />
                      </NeoGrid.Row>
                    )}
                  </NeoGrid.Grid>
                )}
              </Neo.Pager>
              <div className="col-12 p-24">
                <CustomPagingControl
                  displayLabel={true}
                  pageManager={this.props.viewModel.subIndustryPageManager} />
              </div>
            </React.Fragment>
          </div>
        </div>

      </Neo.Tab >

    return (
      <div className="ProspectingView">

        <Neo.Button
          className="btn-width-100 mt-3 marginLeft24"
          variant="light"
          icon={<Icon.ArrowLeft />}
          onClick={async (e: any) => {
            this.props.back()
          }}
        >
          {textConstants.buttonText.Back}
        </Neo.Button>
        <Neo.Card>
          <div className='row m-0'>
            <h2 className='col-12 p-24 mt-3'>{textConstants.titleText.Prospecting}</h2>
            <div className="col-12 p-0">
              <Neo.GridLayout xl={4} lg={4} md={2} sm={2} xs={2} className="mb-3 m-0">
                <div className="row m-0">
                  <label className="p-24 mr-2 mt-2">{textConstants.titleText.TargetMarketName}:</label>
                  <div className="mt-2">{this.props.viewModel.targetMarketName}</div>
                </div>
                <div className="row m-0">
                  <label className="p-24 mr-2 mt-2">{textConstants.titleText.Client}:</label>
                  <div className="mt-2">{this.props.viewModel.clientName}</div>
                </div>
              </Neo.GridLayout>
              <Neo.TabContainer selectedTab={this.props.viewModel.meta.selectedTab} className="m-0 tabContainer">
                {DownloadProspectListTab}
                {UploadListOfAccountsTab}
                <UploadProspectsTab viewModel={this.props.viewModel} />
                {SearchesAndLPCTab}
                {XingTab}
                {IROSearchTab}
              </Neo.TabContainer>
            </div>
          </div>

          {/* Xing Upload Modal */}
          <Neo.Modal title={`Upload Xing Search`} show={this.props.viewModel.showXingUploadModal}
            onClose={() => this.props.viewModel.showXingUploadModal = false}
            closeButton={{ text: "Close", variant: "light" }}
          >
            <Neo.GridLayout xl={1} lg={1} md={1} sm={1} xs={1} className="">
              <p className="neg-m-md"> {textConstants.titleText.PleaseEnsureHeaders} </p>
              <ol>
                {this.props.viewModel.xingColumnHeadings.map((i) =>
                  <li className="dropZoneListDisplay" key={i}> {i} </li>
                )}
                <a className="text-right customDownloadLink"
                  onClick={() => this.props.viewModel.downloadXingTemplate()}>{textConstants.generalText.DownloadTemplate}</a>
              </ol>
              <Neo.FileContext onFilesSelected={(e) => this.onDocumentSelected(e, "Xing")} allowedExtensions={[".xlsx"]}>
                <Neo.FileDropArea multiple={false}>
                  <p className="text-center dropZoneText">
                    <Neo.FileUploadButton>
                      <Icon.Upload />
                      {textConstants.generalText.uploadOrDragFile}
                    </Neo.FileUploadButton>
                  </p>
                </Neo.FileDropArea>
              </Neo.FileContext>
            </Neo.GridLayout>
          </Neo.Modal>

          {/* Delete Xing Search Modal */}
          <Neo.Modal title={`Delete confirmation`} show={this.props.viewModel.showXingSearchDeleteModal}
            onClose={() => this.props.viewModel.showXingSearchDeleteModal = false}
            closeButton={{ text: "No", variant: "light" }}
            acceptButton={{ text: "Yes", variant: "secondary", onClick: () => this.props.viewModel.deleteSearch() }}>
            Xing search &#34;{this.props.viewModel.selectedSearchName}&#34; is about to be deleted. Do you wish to continue?
          </Neo.Modal>
        </Neo.Card>
      </div>
    );
  }
}