import styled from "styled-components";
import { IDataTooltipVariant } from './types';

const typeStyles = (type?: string) => {
  switch (type) {
    case "disabledTip":
      return `left: 25%;
      transform: translate(-50%, -125%);`;

    case "tooltip":
      return `left: 50%;
      transform: translate(-50%, -50%);`;

    case "description":
      return `
      transform: translate(-35%, 0%);
      max-width: 430px;
      width: max-content;
      white-space: normal;
      left: 50%;

      &::after {
        left: 35%;
      }
      `;

    case "hint":
      return `
      transform: translate(-88%, 90%);
      max-width: 430px;
      width: max-content;
      white-space: normal;

      left: 50%;

      &::after {
        left: 88%;
      }
      `

    default:
      return `left: 50%;
      transform: translate(-50%, -50%);`;
  }
}

const variantStyles = (variant?: string, direction?: string) => {
  let backgroundColor: string = ""
  let color: string = ""
  let additional: string = ""

  switch (variant) {
    case "dark":
      backgroundColor = "#374242"
      color = "#fff"
      break;

    case 'light':
      backgroundColor = "#f8f8f8"
      color = "#111e1e"
      break;

    default:
      backgroundColor = "#f8f8f8"
      color = "#111e1e"
      break;
  };

  additional = toolTipDirection(backgroundColor, direction)

  return `background-color: ${backgroundColor};
    color: ${color};
    border: 1px solid ${backgroundColor};
    &::after {
      ${additional};  
    }
  `
}

const toolTipDirection = (backgroundColor: string, direction?: string) => {

  let additional: string = ""
  let defaultProperties: string = `border-left: 8px solid transparent;
  border-right: 8px solid transparent;`

  switch (direction) {
    case "up":
      additional = `bottom: unset;
        top: 100%;
        border-bottom: 0px;
        border-top: 8px solid ${backgroundColor};`
      break;

    case "down":
      additional = `border-top: 0px;
        top: unset;
        bottom: 100%;
        border-bottom: 8px solid ${backgroundColor};`
      break;

    default:
      additional = `bottom: unset;
        top: 100%;
        border-bottom: 0px;
        border-top: 8px solid ${backgroundColor};`
      break;
  }

  return `${defaultProperties}
  ${additional}`;
}

export const StyledDataTooltipContainer = styled("i")`
  position: relative; 
  &:hover > div { 
    display: block;
  }
  font-style: normal;
`;

export const StyledDataTooltipHover = styled("div") <IDataTooltipVariant>` 

  ${({ variant, direction }) => variantStyles(variant, direction)}

  display: none;
  position: absolute;
  z-index: 10;
  padding: 4px 8px;
  font-style: normal;
  border-radius: 8px;
  left: 50%;
  transform: translate(-50%, -50%);

  ${StyledDataTooltipContainer}:hover & { 
    display: block;
  }

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  ${({ type }) => typeStyles(type)}

  ${({ isFinalItem }) => isFinalItem && `
    transform: translate(-35%, -120%);

    &::after {
      border-bottom: 0px;
      bottom: unset;
      top: 100%;
      border-top: 8px solid #374242;
      left: 30%;
    }
    `}
  
    ${({ parentOffset, isFinalItem }) => parentOffset && `
    transform: translate(-30%, ${isFinalItem ? '-120%' : 0});
    left: 100%;

    &::after {
      left: 25%;
    }
  `}
`;