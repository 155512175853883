import React, { useEffect, useRef } from 'react';
import flagSpriteMap from '../assets/img/flagSprite60.png';
import { CountryCodes } from 'Models/Enums/ApplicationEnums';

interface ISpriteMap {
  value: CountryCodes;
  width?: number;
  height?: number;
}

const SpriteMap = ({value, width, height }: ISpriteMap) => {
  const countryCode = value;

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    const flagIcon = new Image();
    flagIcon.src = flagSpriteMap;

    flagIcon.onload = () => {

      context.clearRect(0, 0, canvas.width, canvas.height);

      context.drawImage(
        flagIcon, 
        1, // x value
        42 * countryCode - 40, //y value
        60, 37, // Icon size on main png
        0, 0,
        canvas.width,
        canvas.height
      );
    };
  }, []);

  return <div className='flagRadius'><canvas ref={canvasRef} width={width ?? 16} height={height ?? 10} /></div>;
};

export default SpriteMap;