import { NeoModel, List } from '@singularsystems/neo-core';
import { AppService, Types } from '../../Services/AppService';
import Industry from './../../Models/Maintenance/Industry';
import SubIndustry from '../../Models/Maintenance/SubIndustry';
import IndustrySubIndustry from '../../Models/Maintenance/IndustrySubIndustry';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';
import IndustriesVersion from 'Models/Maintenance/IndustriesVersion';

@NeoModel
export default class IndustrySubIndustryVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private appDataCache = AppService.get(Types.Services.AppDataCache),
        private industrySubIndustriesApiClient = AppService.get(Types.ApiClients.IndustrySubIndustriesApiClient)) {

        super(taskRunner);
    }

    public IndustryList = new List(Industry)
    public SubIndustryList = new List(SubIndustry);
    public IndustrySubIndustryList = new List(IndustrySubIndustry);
    public IndustriesVersionList = new List(IndustriesVersion);

    public async initialise() {
        await this.setUpViewModel();        
    }

    public async setUpViewModel() {
        const industries = (await this.taskRunner.waitFor(this.appDataCache.industries.getDataAsync())).sortBy("industryName");
        this.IndustryList.set(industries);

        const subIndustries = (await this.taskRunner.waitFor(this.appDataCache.subIndustries.getDataAsync())).sortBy("subIndustryName")
        this.SubIndustryList.set(subIndustries);

        const industriesVersion = (await this.taskRunner.waitFor(this.appDataCache.industriesVersions.getDataAsync()));
        this.IndustriesVersionList.set(industriesVersion);

        this.taskRunner.run(async () => {
            const response = await this.industrySubIndustriesApiClient.getIndustrySubIndustry();
            this.IndustrySubIndustryList.set(response.data);
        });
    }

    public saveList(){
        this.taskRunner.run(async () => {
            const Data = this.IndustrySubIndustryList.toJSArray();
            await this.industrySubIndustriesApiClient.saveList(Data);
            const response = await this.industrySubIndustriesApiClient.getIndustrySubIndustry();
            this.IndustrySubIndustryList.set(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved,
                                          textConstants.messageText.saveMessage.IndustrySubIndustriesSaved);
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}