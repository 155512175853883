import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import Country from '../../Models/Maintenance/Country';
import { AppService, Types } from '../../Services/AppService';

@NeoModel
export default class DomainVariationVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private countriesApiClient = AppService.get(Types.ApiClients.CountriesApiClient)) {

        super(taskRunner);
    }

    public countryDomainsList = new List(Country);

    public async initialise() {
        this.getDomainVariations()
    }


    public saveDomainVariations() {

        this.countryDomainsList.forEach(country => {
            country.domainVariations.forEach(variation => {
                variation.countryId = country.countryId
            });
        });

        this.taskRunner.run(async () => {
            const response = await this.countriesApiClient.saveDomainVariations(this.countryDomainsList);

            if (response.data.success) {
                this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.DomainVariationsSaved);
                this.getDomainVariations()
            } else {
                this.notifications.addDanger(textConstants.titleText.SaveFailed, response.data.error);
            }    
        });
    }

    public async getDomainVariations() {
        const countryDomainsResponse = await this.taskRunner.waitFor(this.countriesApiClient.getDomainVariationsAsync())
        this.countryDomainsList.set(countryDomainsResponse.data);
    }
}