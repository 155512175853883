import { Attributes, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';

@NeoModel
export default class ReminderData extends ModelBase {

  public title: string = "";

  public id: string = "";

  public description: string = "";

  @Attributes.Date()
  public time: Date = this.roundToNext15Mark();

  public is_active: boolean = true;

  // Function to set the date to be the closest 15 minute mark
  private roundToNext15Mark() {

    let date = new Date()

    date.setMinutes(Math.ceil(date.getMinutes()/15) * 15);
    date.setSeconds(0)

    return date
  }
}