import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class TargetMarketRole extends ModelBase {

    public targetMarketRoleId: number = 0;   

    public targetMarketId: number = 0;

    public roleId: number = 0;

    public targetMarketRoleName: string = "";
}