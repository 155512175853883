import { Misc } from '@singularsystems/neo-core';
import React from 'react';
import * as Icon from 'react-feather';

export default class GlobalSettings {

    public static setup() {
        Misc.Settings.grid.deleteButton.icon = <Icon.Archive />
        Misc.Settings.icons.expanded = "chevron-up"
        Misc.Settings.icons.collapsed = "chevron-down"
    }
}