import { Data, Model, Utils } from '@singularsystems/neo-core';
import Axios, { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { ResponseResolver } from '../common/utils';
import CommandResult from '../Models/InvitedUsers/Commands/CommandResult';
import DeletePlatformSearch from '../Models/Scraping/Commands/DeletePlatformSearch';
import PlatformSearchCriteria from '../Models/Scraping/PlatformSearchCriteria';
import { AppService, Types } from '../Services/AppService';

export interface IScrapingApiClient {
  getXingSearches(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<PlatformSearchCriteria>>): AxiosPromise<any>,
  uploadXingDocument(filelist: File[] | FileList, targetMarketId: number, platformId: number): AxiosPromise<any>,
  deletePlatformSearch(deletePlatformSearchCommand: DeletePlatformSearch): AxiosPromise<Model.PlainObject<CommandResult>>;
  downloadResults(platformSearchId: number): AxiosPromise<any>,
  getXingTemplate(): AxiosPromise<any>,
  stopSearch(platformSearchId: number): AxiosPromise<any>,
}

@injectable()
export default class ScrapingApiClient implements IScrapingApiClient {

  constructor(private config = AppService.get(Types.Config)) {
  }

  public getXingSearches(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<PlatformSearchCriteria>>): AxiosPromise<any> {
    const apiPath = AppService.get(Types.Config).apiPath;
    return Axios.get(`${apiPath}/XingScraper/GetXingSearches?${Utils.getQueryString(request)}`)
  }

  public uploadXingDocument(filelist: File[] | FileList, targetMarketId: number, platformId: number): AxiosPromise<any> {

    const apiPath = AppService.get(Types.Config).apiPath;
    const bodyFormData = new FormData();
    bodyFormData.set('files', filelist[0]);
    bodyFormData.set('targetMarketId', targetMarketId.toString());

    return ResponseResolver<any>(Axios.post(`${apiPath}/XingScraper/PerformXingScrape?targetMarketId=${targetMarketId}&platformId=${platformId}`, bodyFormData));
  }

  public getXingTemplate(): AxiosPromise<any> {
    const apiPath = AppService.get(Types.Config).apiPath;
    return ResponseResolver<any>(Axios.post(`${apiPath}/XingScraper/DownloadTemplate`));
  }

  public deletePlatformSearch(deletePlatformSearch: DeletePlatformSearch): AxiosPromise<{ success: boolean; error: string; id: number; }> {
    return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.config.apiPath}/XingScraper/DeletePlatformSearch`, deletePlatformSearch.toJSObject()));
  }

  public downloadResults(platformSearchId: number): AxiosPromise<any> {
    const apiPath = AppService.get(Types.Config).apiPath;
    return ResponseResolver<any>(Axios.post(`${apiPath}/XingScraper/GetPlatformSearchResultsFile?platformSearchId=${platformSearchId}`));
  }

  public stopSearch(platformSearchId: number): AxiosPromise<any> {
    const apiPath = AppService.get(Types.Config).apiPath;
    return ResponseResolver<any>(Axios.post(`${apiPath}/XingScraper/StopSearch?platformSearchId=${platformSearchId}`));
  }
}