import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import EmployeeSize from '../Models/Maintenance/EmployeeSize';
import { AppService, Types } from '../Services/AppService';

export interface IEmployeeSizesApiClient extends Data.IUpdateableApiClient<EmployeeSize, number> {

}

@injectable()
export default class EmployeeSizesApiClient extends Data.UpdateableApiClient<EmployeeSize, number> implements IEmployeeSizesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/employeeSizes`);
    }
}