import { observer } from "mobx-react";
import DashboardVM from "../../Views/Home/DashboardVM";
import React from "react";
import GridChartSwitch from "../GridChartSwitch";
import LineChart from "../LineChart";
import ColumnChart from "../ColumnChart";
import { textConstants } from "../../common/textConstants";
import { Neo } from "@singularsystems/neo-react";
import { isMobile } from "../../common/utils";
import { Spinner } from "reactstrap";
import * as Icon from 'react-feather';
import DashboardTable from "./DashboardTable";
import AppLayout, { ScreenSize } from "../AppLayout";
import ReportingDatePicker from "./ReportingDatePicker";
import { ChartType } from "../../Models/Dashboard/ChartType";
import CustomPagingControl from "../CustomPagingControl";
import PieChart from "../PieChart";
import DashboardActionListCard from "Components/DashboardActionListCard";
import NoCampaignCard from './NoCampaignsCard';
import { SeriesNames } from "Models/Enums/ApplicationEnums";

interface IDashboardReports {
    viewModel: DashboardVM,
    viewCampaignDetails: (clientCampaignId: number) => void,
    back?: () => void,
    showBack?: boolean,
    viewActionList?: (clientId: number, selectedTab: string) => void;
}

@observer
class DashboardReports extends React.Component<IDashboardReports> {

    public getViewStyling() {
        let styling = "DashboardView"
        if (AppLayout.current.currentScreenSize === ScreenSize.Small) {
            styling = "DashboardViewMobileVersion"
        }
        else if (AppLayout.current.currentScreenSize === ScreenSize.ExtraSmall) {
            styling = "DashboardViewMobileVersion"
        }
        return styling
    }

    private getDatePicker() {
        const { viewModel } = this.props
        const { chartType } = viewModel

        // DateFormat of date picker (month picker by default)
        let dateFormat = textConstants.GraphData.datePickerOptions.monthPicker

        let isMonthChart = chartType === ChartType.RepliesOverTime || chartType === ChartType.ReplyRate

        // Add more to if statement as needed
        if (!isMonthChart) {
            // All the rest of the charts are day pickers
            dateFormat = textConstants.GraphData.datePickerOptions.dayPicker
        }

        return <ReportingDatePicker
            viewModel={viewModel}
            dateFormat={dateFormat}
        />
    }

    private noCampaignCard() {
        const { viewModel } = this.props

        let hasNoCampaigns = ((!viewModel.isRetrievingCampaignData && !viewModel.isCampaignDataRetrievalFailure)
            && viewModel.pageManager.totalRecords === 0)
        return (hasNoCampaigns ? <NoCampaignCard /> : undefined)
    }

    private getSelectedLineChart() {
        const { viewModel } = this.props
        const { isEnoughData, chartType } = viewModel

        let seriesNames: string[] = []
        let seriesColours: string[] = []
        let xAxisCategories: string[] = []
        let styling = ""
        let additionalOptions = viewModel.noDataPointsRendered()
        let chartDataSeriesValues: string[] = textConstants.GraphData.lineChartSeriesNames.emailSeriesNames
        let visibleSeries: number[] | undefined = undefined

        // Gets the specific chart type which is being displayed.
        if (chartType === ChartType.RepliesOverTime) {
            seriesNames = Array.from(textConstants.GraphData.lineChartSeriesNames.repliesOverTime)
            seriesColours = isEnoughData ? textConstants.GraphData.repliesOverTimeColours : textConstants.GraphData.repliesOverTimeGreyedColours
            xAxisCategories = viewModel.getMonthYear(viewModel.filteredQueryList)

            // Sets the first two items of the series to be visible
            visibleSeries = [SeriesNames.PositiveSeries, SeriesNames.NeutralSeries]
        }
        else if (chartType === ChartType.ReplyRate) {
            seriesNames = Array.from(textConstants.GraphData.lineChartSeriesNames.replyRateOverTime)
            seriesColours = isEnoughData ? textConstants.GraphData.replyRateOverTimeColours : textConstants.GraphData.replyRateOverTimeGreyedColours
            xAxisCategories = viewModel.getMonthYear(viewModel.filteredQueryList)
        }
        else {
            seriesNames = Array.from(viewModel.seriesNames)
            styling = "sentOutEmailChart"
            seriesColours = isEnoughData ? textConstants.GraphData.chartBaseColour : textConstants.GraphData.emailSeriesGreyedColours
            xAxisCategories = viewModel.getDayMonth(viewModel.filteredQueryList)

            // Sets the first Item to be the only visible series
            visibleSeries = [SeriesNames.Email1Series]

            // Gets the specific daily chart
            if (chartType === ChartType.SentOutEmails) {
                seriesNames.push(textConstants.GraphData.lineChartSeriesNames.totalSentOutSeriesName)
            }
            else if (chartType === ChartType.BouncedRate) {
                seriesNames.push(textConstants.GraphData.lineChartSeriesNames.overallBounceRateSeriesName)
            }
            else if (chartType === ChartType.OpenRate) {
                seriesNames.push(textConstants.GraphData.lineChartSeriesNames.overallOpenRateSeriesName)

                // Sets the last item to be the only visible series
                visibleSeries = [seriesNames.length - 1]
            }
        }

        // Returns the line chart component
        return <div className={styling}>
            <LineChart
                SeriesNames={seriesNames}
                SeriesColours={seriesColours}
                SeriesData={viewModel.setLineChartSeriesValues(chartDataSeriesValues, viewModel.filteredQueryList)}
                XAxisCategories={xAxisCategories}
                additionalOptions={additionalOptions}
                visibleSeries={visibleSeries}
            />
        </div>
    }

    async componentDidUpdate() {
        const { viewModel } = this.props
        if (!viewModel.pageManager.data.length && viewModel.clientId) {
            await viewModel.pageManager.refreshPage()
        }
    }

    render() {
        const { viewModel } = this.props
        const { isEnoughData } = viewModel

        return (
            <div className={this.getViewStyling()}>

                {this.props.showBack &&
                    <div>
                        {/* Back button */}
                        <Neo.Button
                            className="btn-width-100 mt-3 mb-5 marginLeft24"
                            variant="light"
                            icon={<Icon.ArrowLeft />}
                            onClick={() => {
                                this.props.back!()
                            }}
                        >
                            {textConstants.buttonText.Back}
                        </Neo.Button>
                    </div>
                }

                {this.props.showBack && viewModel.clientId !== 0 && !isMobile() &&
                    <DashboardActionListCard viewModel={viewModel} viewActionList={this.props.viewActionList} />
                }

                <Neo.Card className="m-4">

                    {/* Campaign Count */}
                    <div className="row mb-0 mt-3 ml-0 mr-0 pl-24 pr-24">
                        <div className='col-12 p-0'>
                            {viewModel.pageManager.totalRecords >= 0 && !isMobile() &&
                                <div className='row m-0'>
                                    {/* Campaign Count */}
                                    <div className='p-0'>
                                        <div className="row running-campaign-counter">
                                            {viewModel.pageManager.totalRecords > 0 ?
                                                <>{viewModel.runningCampaignCount} Campaigns<span className="areRunning">&nbsp;are running </span></>
                                                : <>{textConstants.generalText.campaignCard.campaignsBeingPrepared} </>
                                            }
                                        </div>
                                    </div>
                                    {/* Filter dropdowns */}
                                    {/* Email Filter */}
                                    <div className="col dropdownPositionRelative p-0 mr-4">
                                        <Neo.FormGroup bind={viewModel.meta.selectedEmailerClientCampaignId}
                                            placeholder={""}
                                            suppressLabel={true}
                                            select={{ items: viewModel.clientCampaignEmails, allowNulls: true, deSelectText: textConstants.Dashboard.allEmailFilter }}
                                            onChange={() => {
                                                viewModel.filterApplied = true
                                                viewModel.firstFetch = false
                                                viewModel.emailChange = true
                                                viewModel.getFilteredCampaigns();
                                            }}
                                            className={"filterDropDown"}
                                        />
                                    </div>

                                    {/* Status Filter */}
                                    <div className="dropdownPositionRelative p-0 mr-4">
                                        <Neo.FormGroup bind={viewModel.meta.selectedCampaignStatusId}
                                            placeholder={"Status"}
                                            suppressLabel={true}
                                            select={{ items: viewModel.clientCampaignStatuses, allowNulls: true, deSelectText: textConstants.Dashboard.allStatusFilter }}
                                            onChange={() => {
                                                viewModel.filterApplied = true
                                                viewModel.firstFetch = false
                                                viewModel.getFilteredCampaigns();
                                            }}
                                            className={"filterDropDown"}
                                        />
                                    </div>

                                    {/* Chart v Grid switch */}
                                    <div className='dropdownPositionRelative p-0 moveSwitchRight'>
                                        <GridChartSwitch
                                            isButton1Selected={this.props.viewModel.isGridSelected}
                                            toggle={() => {
                                                this.props.viewModel.isGridSelected = !this.props.viewModel.isGridSelected;
                                                this.props.viewModel.dataHasChanged = true;
                                            }}
                                        >
                                        </GridChartSwitch>
                                    </div>
                                </div>
                            }
                        </div>

                        {(viewModel.isRetrievingCampaignData || viewModel.isCampaignDataRetrievalFailure)
                            &&
                            <>
                                <div className='col-12' >
                                    {/* DATA FAILURE DISPLAY BOX */}
                                    {viewModel.isCampaignDataRetrievalFailure &&
                                        <div className='text-center noCampaignsBox mt-4'>
                                            <div className='primaryNoCampaignText'>
                                                {textConstants.generalText.campaignCard.failedToLoadCampaignData}
                                            </div>
                                            <div className='mt-2'>
                                                <span className="failedCampaignRetrieval">
                                                    ❌
                                                </span>
                                            </div>
                                        </div>
                                    }

                                    {/* LOADING DATA DISPLAY BOX */}
                                    {viewModel.isRetrievingCampaignData &&
                                        <div className='text-center noCampaignsBox mt-4'>
                                            <div className='primaryNoCampaignText'>
                                                {textConstants.generalText.campaignCard.loadingCampaignData}
                                            </div>
                                            <div className='mt-2'>
                                                <Spinner animation="border" role="status" />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }

                        {/* Mobile Campaign Counter */}
                        {viewModel.originalCampaigns.length > 0 && isMobile() &&
                            <>
                                <div className='col-12'>
                                    <div className='mb-xs-4 mb-sm-4 mb-md-4  running-campaign-counter'>
                                        <b>{viewModel.runningCampaignCount} Campaigns</b> are running
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                    {/*Dashboard Data - Grid view*/}
                    {viewModel.isGridSelected &&
                        (!viewModel.isRetrievingCampaignData && !viewModel.isCampaignDataRetrievalFailure) &&
                        <>
                            <div className='row m-0'>
                                <div className='col-12 p-0'>
                                    {viewModel.clientId !== 0 &&
                                        <>
                                            <Neo.Pager pageManager={viewModel.pageManager} pageControls="none" className="mr-4 ml-4">
                                                <DashboardTable
                                                    campaignData={viewModel.pageManager.data}
                                                    viewModel={viewModel}
                                                    openCampaignsDetailOverview={(clientCampaignId: number) => this.props.viewCampaignDetails(clientCampaignId)}
                                                    noCampaignsJSX={this.noCampaignCard()}
                                                />
                                            </Neo.Pager>
                                            {viewModel.pageManager.totalRecords > 0 &&
                                                <div className="col-12 p-24">
                                                    <CustomPagingControl
                                                        displayLabel={true}
                                                        pageManager={viewModel.pageManager}
                                                        showPageSizePicker={true}
                                                        isDashboard={true}
                                                    />
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                    }

                    {/*Dashboard Data - Charts View */}
                    {viewModel.originalCampaigns.length > 0 && !viewModel.isGridSelected &&
                        <div className='marginBottom24 marginTop24'>
                            <div>
                                <div className='onChart'>
                                    <div className="p-24">
                                        {/* Percentage vs Numeric Values */}
                                        <GridChartSwitch
                                            isButton1Selected={viewModel.isPercent}
                                            toggle={() => {
                                                viewModel.isPercent = !viewModel.isPercent;
                                                viewModel.dataHasChanged = true
                                            }}
                                            button1Icon={<Icon.Percent />}
                                            button2Icon={<Icon.Hash />}
                                        >
                                        </GridChartSwitch>
                                    </div>
                                </div>
                                <div>
                                    {/* Graphs */}
                                    <ColumnChart
                                        SeriesNames={textConstants.GraphData.graphSeriesNames}
                                        SeriesColours={textConstants.GraphData.chartBaseColour}
                                        SeriesData={
                                            viewModel.setSeriesValues(!viewModel.isPercent
                                                ? viewModel.campaignSeriesNumber
                                                : viewModel.campaignSeriesPercent,
                                                viewModel.campaignData,
                                                true)}
                                        XAxisCategories={viewModel.setChartValues("campaignName", viewModel.campaignData, true)}
                                        hasDataChanged={() => { return viewModel.hasDataChanged() }}
                                        additionalOptions={viewModel.additionalOptionsColumnChart()}
                                    >
                                    </ColumnChart>
                                </div>
                            </div>
                        </div>
                    }
                </Neo.Card >

                {/* Reports Section*/}
                {viewModel.originalCampaigns.length > 0 && // When refreshing hide this as well
                    <Neo.Card className="m-4 p-0">

                        {!isEnoughData && !viewModel.pieChartActive &&
                            <div className="notEnoughChartDataWords">
                                <Neo.FormGroup suppressLabel isReadOnly display={viewModel.meta.notEnoughDataOnChart} />
                            </div>
                        }

                        <div className='row ml-0 mr-0 mt-4 p-24 reportingFormGroups'>
                            <h2 className='p-0'>Reports</h2>

                            {/* Filters */}
                            {/* Chart Type Filter */}
                            <div className="col dropdownPositionRelative p-0 mr-4">
                                <Neo.FormGroup
                                    bind={viewModel.meta.chartType}
                                    suppressLabel={true}
                                    select={{
                                        items: viewModel.reportingChartSelections, allowNulls: true,
                                        deSelectText: textConstants.GraphData.chartTypes[0]
                                    }}
                                    onChange={() => {
                                        viewModel.setSelectedChart(viewModel.meta.chartType.value)
                                    }}
                                    className={"filterDropDown"}
                                />
                            </div>

                            {/* Campaign Filter */}
                            {!viewModel.pieChartActive &&
                                <div className="dropdownPositionRelative p-0">
                                    <Neo.FormGroup bind={viewModel.meta.selectedClientCampaignId}
                                        suppressLabel={true}
                                        select={{ items: viewModel.reportingCampaignSelections, allowNulls: true, deSelectText: textConstants.GraphData.allCampaigns }}
                                        onChange={() => {
                                            viewModel.setSelectedChart(viewModel.meta.chartType.value);
                                        }}
                                        className={"filterDropDown"}
                                    />
                                </div>
                            }

                            {/* Status Filter */}
                            {!viewModel.pieChartActive &&
                                <div className="dropdownPositionRelative p-0 pl-4">
                                    <Neo.FormGroup bind={viewModel.meta.selectedReportingStatusId}
                                        suppressLabel={true}
                                        select={{ items: viewModel.clientCampaignStatuses, allowNulls: true, deSelectText: textConstants.Dashboard.allStatusesFilter }}
                                        onChange={() => {
                                            viewModel.ReportingStatusChanged();
                                        }}
                                        className={"filterDropDown"}
                                    />
                                </div>
                            }

                            {/* Pie Chart Target Market Filter */}
                            {viewModel.pieChartActive &&
                                <div className="dropdownPositionRelative p-0">
                                    <Neo.FormGroup bind={viewModel.meta.selectedPieChartTargetMarketId}
                                        suppressLabel={true}
                                        select={{ items: viewModel.pieChartTargetMarkets, allowNulls: true, deSelectText: textConstants.GraphData.allTargetMarkets }}
                                        onChange={() => {
                                            viewModel.relevantPieChartDataSelection();
                                        }}
                                        className={"filterDropDown"}
                                    />
                                </div>
                            }
                            {viewModel.pieChartActive &&
                                <div className="col-4 pr-0 pl-0 pt-0 pb-0"></div>
                            }

                            <div className="col-12 p-0">
                                <div className="row m-0 d-block">
                                    <div className="pull-right">
                                        {/* Get Date Picker */}
                                        {!viewModel.pieChartActive &&
                                            this.getDatePicker()
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {viewModel.pieChartActive && viewModel.pieChartTargetMarkets.length === 0 &&
                            <div className="notEnoughPieChart">
                                <Neo.FormGroup suppressLabel isReadOnly display={viewModel.meta.noActiveTargetMarkets} />
                            </div>
                        }

                        {!viewModel.pieChartActive &&
                            <div className={`chartStyling ${isEnoughData ? '' : `notEnoughChartData`}`}>
                                {/* Get Line Chart Values */}
                                {this.getSelectedLineChart()}
                            </div>
                        }


                        {viewModel.pieChartTargetMarkets.length > 0 && viewModel.pieChartActive &&
                            <PieChart
                                SeriesData={viewModel.relevantPieChartDataSelection()}
                            />
                        }
                    </Neo.Card>
                }

                <Neo.Modal
                    title="Campaign Data"
                    show={viewModel.showRefreshModal}
                    onClose={() => viewModel.showRefreshModal = false}
                    closeButton={{ text: "Close", variant: "light" }}>
                    <p>Data has been refreshed within the last {viewModel.defaultRefreshTime} minutes.
                        <br></br>
                        <br></br>
                        Please try again later.
                    </p>
                </Neo.Modal>
            </div>
        )
    }
}


export default DashboardReports;