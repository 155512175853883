import { observer } from "mobx-react";
import { Neo } from '@singularsystems/neo-react';
import React from "react";
import HtmlParser from "react-html-parser";
import { textConstants } from "../../common/textConstants";
import ActionPlanChecklistLookup from "Models/Client/Query/ActionPlanChecklistLookup";
import TechnicalSettingsVM from "./TechnicalSettingsVM";
import Button from "Components/Button";
import * as Icon from 'react-feather';


interface IActionPlanChecklistProps {
    actionPlanChecklistLookup: ActionPlanChecklistLookup,
    viewModel: TechnicalSettingsVM
}

@observer
class ActionPlanChecklist extends React.Component<IActionPlanChecklistProps>{

    constructor(props: IActionPlanChecklistProps) {
        super(props);
    }

    public render() {
        return (
            <>
                <div className='actionPlanChecklist row'>
                    <div className='col-12 p-24'>
                        <h2 className='p-0'>{textConstants.ActionPlanChecklist}</h2>
                        <>
                            {/* Text */}
                            {
                                HtmlParser(this.props.actionPlanChecklistLookup.actionPlanChecklistHtml)
                            }
                            <>
                                <Button
                                    buttonType='primary'
                                    className="mt-0 mb-1"
                                    variant="hasIcon"
                                    size="xSmall"
                                    onClick={() => this.props.viewModel.openLink()}
                                >
                                    {this.props.actionPlanChecklistLookup.actionPlanChecklistButtonText}
                                </Button>
                            </>
                        </>
                    </div>
                </div>
            </>
        );
    }
}

export default ActionPlanChecklist