import React from 'react';
import { Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import ClientView from '../Client/ClientView';
import { AppService, Types } from '../../Services/AppService';
import DashboardView from './DashboardView';
import { Spinner } from 'reactstrap';
import ModalOverlay from '../../Components/ModalOverlay';
import OnboardingView from '../Onboarding/OnboardingView';
import OnboardingStepLookup from '../../Models/Onboarding/OnboardingStepLookup';
import { PartialPlainObject } from '@singularsystems/neo-core/dist/Model';

@observer
export default class HomeView extends Views.ViewBase {

    private authService = AppService.get(Types.Neo.Security.AuthenticationService);
    protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService)
    private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient);

    constructor(props: unknown) {
        super("Home", Views.EmptyViewModel, props);
    }

    public async navigateUser() {

        if (this.authService.isAuthenticated) {
            const response = await this.onboardingApiClient.getUserLoginInfo(this.authService!.user!.userName);

            this.authenticationService.globalProps.clientId = response.data.data.clientId
            this.authenticationService.globalProps.isClientUser = response.data.data.isClientUser
            this.authenticationService.globalProps.isFirstOnboardingLogin = response.data.data.isFirstOnboardingLogin
            this.authenticationService.globalProps.hasSeenTutorial = response.data.data.hasSeenTutorial
            this.authenticationService.globalProps.clientHasSeenTutorial = response.data.data.clientHasSeenTutorial
            this.authenticationService.globalProps.hasSeenTechnicalIntegration = response.data.data.hasSeenTechnicalIntegration
            this.authenticationService.globalProps.clientCampaignMessageId = response.data.data.clientCampaignMessageId
            this.authenticationService.globalProps.isCampaignMessageReady = response.data.data.isCampaignMessageReady
            this.authenticationService.globalProps.isMeetingBooked = response.data.data.isMeetingBooked

            this.authenticationService.globalProps.joinSupportCallURL = response.data.data.joinSupportCallURL
            this.authenticationService.globalProps.joinSupportCallURLOnboarding = response.data.data.joinSupportCallURLOnboarding

            let steps = response.data.data.clientOnboardingSteps as PartialPlainObject<OnboardingStepLookup>[]

            if (steps !== null) {
                this.authenticationService.globalProps.onboardingSteps.set(steps)
            }

            if (response.data.data.isClientUser && response.data.data.isOnboardingComplete) {
                this.authenticationService.globalProps.showBurgerIcon = true
                this.authenticationService.globalProps.showBreadCrumb = true
                this.authenticationService.globalProps.isOnboarding = false

                this.navigation.navigateToView(DashboardView);
            }
            else if (response.data.data.isClientUser && !response.data.data.isOnboardingComplete) {
                this.authenticationService.globalProps.isOnboarding = true

                this.navigation.navigateToView(OnboardingView);
            }
            else {
                this.authenticationService.globalProps.isOnboarding = false
                this.authenticationService.globalProps.showBurgerIcon = true
                this.authenticationService.globalProps.showBreadCrumb = true
                this.navigation.navigateToView(ClientView, { clientId: null });
            }
        }
    }

    public render() {
        this.navigateUser();
        return (
            <div className="HomeView">
                <ModalOverlay isHidden className="overlayPosition">
                    <Spinner animation="border" role="status" />
                </ModalOverlay>
            </div>
        );
    }
}