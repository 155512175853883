import {ModelBase, NeoModel} from '@singularsystems/neo-core';


@NeoModel
export default class SubIndustryLookup extends ModelBase {

    // Properties
    public subIndustryId: number = 0;
    public industrySubIndustryId: number = 0;
    public subIndustryName: string = "";
    public subIndustryDetails: string = "";
    public isPriority: boolean = false;
    public isSelected: boolean = false;
}