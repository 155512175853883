import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';

@NeoModel
export default class EditUserCommand extends ModelBase {

    public id : number = 0;

    public identityGuid : string = "";

    @Required("First Name is required")
    public firstName : string = "";

    @Required("Last Name is required")
    public lastName : string = "";

    public email : string = "";

    public clientid : number = 0;

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Edit User Command"
    }
}