import { List, ModelBase, NeoModel } from "@singularsystems/neo-core";
import CalendlyLink from "./CalendlyLink";
import EmailProviderType from "./EmailProviderType";
import InformationManagementDetail from "./InformationManagementDetail";
import TechnicalSettingsConfig from "./TechnicalSettingsConfig";

@NeoModel
export default class InformationManagement extends ModelBase{

    public informationManagementId : number = 0; 

    public informationManagementDetails = new List(InformationManagementDetail)

    public emailProviderTypes = new List(EmailProviderType)

    public calendlyLinks = new List(CalendlyLink)

    public technicalSettings = new TechnicalSettingsConfig()
}