import { ModelBase, NeoModel, Rules } from '@singularsystems/neo-core'; 

@NeoModel 
export default class SaveFollowUpCommentCommand extends ModelBase { 
   @Rules.Required()
   public followUpTemplateId: number = 0;

   @Rules.Required()
   public comment: string = "";

   public followUpTemplateName: string = "";
}