import { Attributes, ModelBase, NeoModel } from "@singularsystems/neo-core";

@NeoModel
export default class OnboardingPopupLookup extends ModelBase {

    public onboardingPopupId : number = 0;
    
    @Attributes.Display("Onboarding Popup Name")
    public popupName: string = "";

    public text: string = "";

}