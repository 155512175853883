import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import AddClientListVM from './AddClientListVM';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import * as Icon from 'react-feather';

interface IAddClientListViewProps {
    viewModel: AddClientListVM,
    back: () => void,
}

@observer
export default class AddClientListView extends React.Component<IAddClientListViewProps> {

    public async onDocumentSelected(fileList: File[] | FileList) {
        this.props.viewModel.documentUpload(fileList, this.props.viewModel.targetMarketId);
    }

    public render() {
        return (
            <div className="AddClientListView">
                <Neo.Card className='pb-3'>
                    <Neo.Button
                        className="btn-width-100 mt-3 marginLeft24"
                        variant="light"
                        icon={<Icon.ArrowLeft />}
                        onClick={async (e: any) => {
                            this.props.back()
                        }}
                    >
                        {textConstants.buttonText.Back}
                    </Neo.Button>
                    <h3 className='p-24'>{textConstants.titleText.AddClientList}</h3>
                    <div className='p-24'><Neo.GridLayout md={2}>
                        <Neo.FormGroupInline className="w-100 d-block" display={this.props.viewModel.targetMarketSummary.meta.targetMarketName} />
                        <Neo.FormGroupInline className="w-100 d-block"
                            bind={this.props.viewModel.selectedCountry.meta.countryId}
                            select={{ items: this.props.viewModel.countries }} />
                    </Neo.GridLayout>

                        <Neo.GridLayout xl={1} lg={1} md={1} sm={1} xs={1} className="mb-2">
                            <p> {textConstants.titleText.PleaseEnsureHeaders} </p>
                            <ol>
                                {this.props.viewModel.columnHeadings.map((i) =>
                                    <li className="dropZoneListDisplay" key={i}> {i} </li>
                                )}
                                <a className="text-right customDownloadLink" onClick={() => this.props.viewModel.downloadTemplate()}>{textConstants.generalText.DownloadTemplate}</a>
                            </ol>
                            <Neo.FileContext onFilesSelected={(e) => this.onDocumentSelected(e)}>
                                <Neo.FileDropArea multiple={false}>
                                    <p className="text-center dropZoneText">
                                        <Neo.FileUploadButton>
                                            <Icon.Upload />
                                            {textConstants.generalText.uploadOrDragFile}
                                        </Neo.FileUploadButton>
                                    </p>
                                </Neo.FileDropArea>
                            </Neo.FileContext>
                        </Neo.GridLayout>
                    </div>
                </Neo.Card>
            </div>
        );
    }
}