import React from "react";
import { Neo } from "@singularsystems/neo-react";
import AddEditFollowUpTemplateVM from "./AddEditFollowUpTemplateVM";
import { observer } from "mobx-react";
import * as Icon from "react-feather";
import { textConstants } from "../../common/textConstants";
import { UnsavedChanges } from "../../Components/UnsavedChanges";
import FollowUpTemplateCard from "./FollowUpTemplateCard";
import { StyledButton } from "Components/Button/styles";

interface IAddEditFollowUpTemplateProps {
  viewModel: AddEditFollowUpTemplateVM;
  back: () => void;
  statusId?: number;
}

interface IAccountState {
  showValue: boolean | null;
  showPrompt: boolean;
}

@observer
export default class AddEditFollowUpTemplateView extends React.Component<
  IAddEditFollowUpTemplateProps,
  IAccountState
> {
  constructor(props: IAddEditFollowUpTemplateProps) {
    super(props);
    this.state = {
      showValue: false,
      showPrompt: false,
    };
    this.props.viewModel.messageStatusId = this.props.statusId;
  }

  onEntry() {
    const { viewModel } = this.props;

    if (viewModel.isSaving === true) {
      return false;
    }

    const result = viewModel.isScreenDirty || viewModel.isCommentDirty;

    return result;
  }

  public validateDraftAndSave() {
    const { viewModel } = this.props;

    if (viewModel.isComXUser) {
      if (viewModel.validationBeforeSave(false, false)) {
        // Internal user save should follow normal draft save process
        viewModel.SaveFollowUpTemplates(() => {
          this.props.back();
        }, false);
      }
    } else {
      if (viewModel.validationBeforeSave(false, true)) {
        // External user save should follow Ready for Client Review process
        viewModel.SaveFollowUpTemplates(
          () => {
            this.props.back();
          },
          false,
          true
        );
      }
    }
  }

  public render() {
    const { viewModel } = this.props;

    // Styling for the dropdown menus
    const dropdownStyle = {
      container: (styles: any) => {
        return {
          ...styles,
          borderRadius: "0px",
        };
      },
      clearIndicator: (styles: any) => {
        return {
          ...styles,
          color: "#111e1e",
          ":hover": {
            color: "@111e1e",
          },
        };
      },
      menu: (styles: any) => {
        return {
          ...styles,
          zIndex: 1000,
        };
      },
      multiValue: (styles: any) => {
        return {
          ...styles,
          backgroundColor: "#edeeee",
          borderRadius: "15px",
        };
      },
      multiValueLabel: (styles: any) => ({
        ...styles,
        color: "#111e1e",
        size: "13px",
      }),
      multiValueRemove: (styles: any) => ({
        ...styles,
        color: "#111e1e",
        ":hover": {
          backgroundColor: "#edeeee",
          color: "#111e1e",
          borderRadius: "15px",
        },
      }),
    };

    // Styling for incorrect dropdown options/Erros
    const invalidDropdownStyle = {
      container: (styles: any) => {
        return {
          ...styles,
          borderRadius: "0px",
        };
      },
      menu: (styles: any) => {
        return {
          ...styles,
          zIndex: 1000,
        };
      },
      control: (styles: any) => {
        return {
          ...styles,
          borderColor: "#cb5151",
          borderRadius: "0px",
          ":hover": {
            borderColor: "#FBA174 !important",
            boxShadow: "0 0 0 0.2rem rgba(251, 161, 116, 0.25) !important",
          },
          ":active": {
            borderColor: "#FBA174 !important",
            boxShadow: "0 0 0 0.2rem rgba(251, 161, 116, 0.25) !important",
          },
        };
      },
      clearIndicator: (styles: any) => {
        return {
          ...styles,
          color: "red",
          ":hover": {
            color: "red",
          },
        };
      },
      multiValue: (styles: any) => {
        return {
          ...styles,
          backgroundColor: "#7CBFC5",
          borderRadius: "15px",
        };
      },
      multiValueLabel: (styles: any) => ({
        ...styles,
        color: "white",
      }),
      multiValueRemove: (styles: any) => ({
        ...styles,
        color: "red",
        ":hover": {
          backgroundColor: "#7CBFC5",
          color: "red",
          borderRadius: "15px",
        },
      }),
    };

    return (
      <div className="addFollowUpTemplateView custom-scrollbar-expand">
        <React.Fragment>
          {/* Back */}
          <Neo.Button
            className="btn-width-100 mt-3 marginLeft24"
            variant="light"
            icon={<Icon.ArrowLeft />}
            onClick={async (e: any) => {
              this.props.back();
            }}
          >
            {textConstants.buttonText.Back}
          </Neo.Button>

          <UnsavedChanges
            showConfirm={this.state.showValue}
            showPrompt={this.onEntry()}
            backButtonPath={"ClientCampaignMessages/"}
          />
          <Neo.Card>
            <div className="mt-4 p-24 row ">
              <div className="col-12 ">
                {viewModel.clientFollowUpTemplateId === 0 && (
                  <h2>{textConstants.generalText.AddFollowUpTemplate}</h2>
                )}
                {viewModel.clientFollowUpTemplateId > 0 && (
                  <h2>
                    {textConstants.generalText.EditFollowUpTemplateSpaced}
                  </h2>
                )}
                <div className="col-6 p-0 mt-3">
                  <Neo.GridLayout md={2}>
                    <Neo.FormGroup
                      disabled={!viewModel.isComXUser}
                      label={
                        <>
                          {textConstants.titleText.FollowUpTemplateName}
                          <span className="validationRed">*</span>
                        </>
                      }
                      className={
                        (viewModel.validationFirstCheckDone ||
                          viewModel.draftValidationFirstCheckDone) &&
                          viewModel.followUpTemplateName.length === 0
                          ? "validation-border-red mb-0 CampaignMessageText"
                          : "mb-0 CampaignMessageText"
                      }
                      bind={viewModel.meta.followUpTemplateName}
                      onChange={() => (viewModel.isScreenDirty = true)}
                    />
                  </Neo.GridLayout>
                  {(viewModel.validationFirstCheckDone ||
                    viewModel.draftValidationFirstCheckDone) &&
                    viewModel.followUpTemplateName.length === 0 && (
                      <p className={"validationRed validationFontSize"}>
                        {textConstants.generalText.templateNameMustBeSpecified}
                      </p>
                    )}
                </div>

                <div className="col-6 p-0 mt-3">
                  <Neo.GridLayout md={2}>
                    <Neo.FormGroup
                      bind={viewModel.meta.leadTypeStatusId}
                      placeholder={
                        textConstants.generalText.placeHolderTypeHereText
                      }
                      label={
                        <>
                          {textConstants.titleText.ActionListStatus}
                          <span className="validationRed">*</span>
                        </>
                      }
                      select={{
                        items: viewModel.actionListStatuses,
                        allowNulls: false,
                      }}
                      onChange={() => {
                        viewModel.selectedCategory =
                          viewModel.actionListStatuses.find(
                            (x) =>
                              x.leadTypeStatusId === viewModel.leadTypeStatusId
                          )?.leadTypeStatusName;
                      }}
                      className={
                        (viewModel.validationFirstCheckDone ||
                          viewModel.draftValidationFirstCheckDone) &&
                          viewModel.leadTypeStatusId === 0
                          ? "validation-border-red mb-0 CampaignMessageText"
                          : "mb-0 CampaignMessageText"
                      }
                    />
                  </Neo.GridLayout>
                  {viewModel.validationFirstCheckDone &&
                    !viewModel.selectedCategory && (
                      <p className={"validationRed validationFontSize"}>
                        {textConstants.generalText.selectAStatus}
                      </p>
                    )}
                </div>

                <div className="col-6 p-0 mt-3">
                  <div className="CampaignMessageText">
                    <label>
                      {textConstants.titleText.Summary}
                      <span className="validationRed">*</span>
                    </label>
                  </div>
                  <Neo.Textbox
                    bind={viewModel.meta.summary}
                    placeholder={
                      textConstants.generalText.placeHolderTypeHereText
                    }
                    onChange={() => (viewModel.isScreenDirty = true)}
                    className="inputStyle col-md-12 col-sm-12"
                  ></Neo.Textbox>
                  {viewModel.validationFirstCheckDone &&
                    viewModel.summary === "" && (
                      <p className={"validationRed validationFontSize"}>
                        {textConstants.generalText.fillInSummary}
                      </p>
                    )}
                </div>
              </div>
            </div>

            {/*Mapping of all the follow up templates(Includes the subject)*/}
            <div>
              <div className="row m-0">
                <div className="col-12 p-24">
                  {viewModel.followUpTemplates!.map((fuTemplate) => (
                    <FollowUpTemplateCard
                      key={fuTemplate.ordinal}
                      followUpTemplateLookup={fuTemplate}
                      isComXUser={viewModel.isComXUser}
                      parentViewModel={this.props.viewModel}
                    />
                  ))}
                </div>
              </div>
            </div>

            {/*Save Buttons*/}
            <Neo.GridLayout md={2}>
              <div className={"row p-0 mb-4 mt-4 ml-0"}>
                {/* Save as Draft */}
                {!(
                  (viewModel.messageStatusId ===
                    viewModel.clientReviewStatusId ||
                    viewModel.messageStatusId === viewModel.activeStatusId) &&
                  viewModel.isComXUser
                ) && (
                    <Neo.Button
                      variant="light"
                      isOutline
                      className="btn-light ml-4 mt-auto mb-auto"
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.validateDraftAndSave();
                      }}
                    >
                      {textConstants.buttonText.SaveAsDraft}
                    </Neo.Button>
                  )}

                {/* Ready for Client Review */}
                {viewModel.isComXUser && (
                  <StyledButton
                    className="pull-right ml-4"
                    variant="hasIcon"
                    size="xSmall"
                    onClick={() => {
                      if (viewModel.validationBeforeSave(false, true)) {
                        viewModel.showClientReviewModal = true;
                      }
                    }}
                  >
                    {textConstants.buttonText.ReadyForClientReview}
                  </StyledButton>
                )}

                {/* Publish */}
                <StyledButton
                  className="pull-right ml-4"
                  variant="hasIcon"
                  size="xSmall"
                  onClick={() => {
                    if (viewModel.validationBeforeSave(true, false)) {
                      {
                        viewModel.showPublishModal = true;
                      }
                    }
                  }}
                >
                  {textConstants.buttonText.Publish}
                </StyledButton>

              </div>
            </Neo.GridLayout>
          </Neo.Card>

          {/* Invalid Data Modal */}
          <Neo.Modal
            title={textConstants.titleText.InvalidData}
            show={viewModel.showInvalidDataModal}
            onClose={() => (viewModel.showInvalidDataModal = false)}
            closeButton={{ text: "Close", variant: "light" }}
          >
            {viewModel.invalidDataMessage.split("\n").map((item, i) => {
              return <p key={i}>{item}</p>;
            })}
          </Neo.Modal>

          {/* Client Review or Publish Confirmation Modal */}

          <Neo.Modal
            title={
              viewModel.showClientReviewModal
                ? `Client review confirmation`
                : `Publish confirmation`
            }
            show={viewModel.showClientReviewModal || viewModel.showPublishModal}
            onClose={() => {
              viewModel.showClientReviewModal = false;
              viewModel.showPublishModal = false;
            }}
            closeButton={{ text: "No", variant: "light" }}
            acceptButton={{
              text: "Yes",
              variant: "secondary",
              onClick: (e) => {
                e.preventDefault();
                viewModel.showClientReviewModal
                  ? viewModel.SaveFollowUpTemplates(
                    () => {
                      this.props.back();
                    },
                    false,
                    true
                  )
                  : viewModel.SaveFollowUpTemplates(
                    () => {
                      this.props.back();
                    },
                    true,
                    false
                  );
              },
            }}
          >
            {viewModel.showClientReviewModal && (
              <p>
                {textConstants.generalText.ClientReviewAreYouSure}
                <br />
              </p>
            )}
            {viewModel.showPublishModal && (
              <p>
                {textConstants.generalText.PublishAreYouSure}
                <br />
              </p>
            )}
          </Neo.Modal>

          {/* Delete Comment Modal */}
          <Neo.Modal
            title={`Delete confirmation`}
            show={viewModel.showDeleteModal}
            onClose={() => (viewModel.showDeleteModal = false)}
            closeButton={{ text: "No", variant: "light" }}
            acceptButton={{
              text: "Yes",
              variant: "secondary",
              onClick: () => viewModel.DeleteFollowUpComment(),
            }}
          >
            <p>
              {textConstants.generalText.DeleteCommentAreYouSure}
              <br />
              {textConstants.generalText.FailedToUndoComment}
            </p>
          </Neo.Modal>

          {/* Delete Denied Modal */}
          <Neo.Modal
            title="Delete Denied"
            show={viewModel.showDeleteDeniedModal}
            onClose={() => (viewModel.showDeleteDeniedModal = false)}
            closeButton={{ text: "Close", variant: "light" }}
          >
            <p>
              {textConstants.messageText.validationMessage.commentDeleteDenied}
            </p>
          </Neo.Modal>
        </React.Fragment>
      </div >
    );
  }
}
