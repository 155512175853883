import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';

@NeoModel
export default class InfoVideoVM extends Views.ViewModelBase {
    public url: string="";
    public content: string="";
    public isPassword: boolean=false;
    public password: string="";
    public header: string="";
    
    public about: string | undefined = ""; 
    public transcript: string | undefined = "";
}