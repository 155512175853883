import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class ClientTestEmailLookup extends ModelBase {

    clientTestEmailId : number = 0;

    clientId : number = 0;

    emailAddress : string = "";
}