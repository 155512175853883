import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import DashboardReportConfig from '../Models/Maintenance/DashboardReportConfig';

export interface IDashboardReportConfigApiClient extends Data.IUpdateableApiClient<DashboardReportConfig, number> {
    
}

@injectable()
export default class DashboardReportConfigApiClient extends Data.UpdateableApiClient<DashboardReportConfig, number> implements IDashboardReportConfigApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/dashboardReportConfigs`);
    }
}