import React from "react";
import { textConstants } from "common/textConstants";
import {
  StyledCampaignCard,
  StyledCampaignMessage,
} from "./styles";
import HtmlParser from "react-html-parser";

const NoCampaignCard = () => {

  return (
    <StyledCampaignCard>
      <StyledCampaignMessage>
        {HtmlParser(textConstants.generalText.campaignCard.teamInTouch)}
      </StyledCampaignMessage>
    </StyledCampaignCard>
  );
};

export default NoCampaignCard;
