import { Attributes, List, ModelBase, NeoModel, } from '@singularsystems/neo-core';
import FollowUpTemplateLookup from './FollowUpTemplateLookup';

@NeoModel
export default class ClientFollowUpTemplateLookup extends ModelBase {
    
    public clientId: number = 0;

    @Attributes.Display("Name")
    public followUpTemplateName: string = "";

    public summary: string = "";

    public campaignMessageStatusId: number = 0;

    public clientFollowUpTemplateId: number = 0;

    @Attributes.Display("Follow Up Template")

    public campaignMessageStatus: string = "";
    
    public emailToCopy: string = "";

    public followUpTemplates : List<FollowUpTemplateLookup> | null = null;

    public leadTypeStatusId: number = 0;
}