import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class ClientFollowUpTemplate extends ModelBase {

    public clientFollowUpTemplateId: number = 0;

    public clientId: number = 0;

    public campaignMessageStatusId: number = 0;

    public followUpTemplateCategoryId: number = 0;

    public followUpTemplateName: string = "";

    public summary: string = "";
}