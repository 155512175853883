import "rc-slider/assets/index.css";
import "react-input-range/lib/css/index.css";
import React from "react";
import { observer } from "mobx-react";
import { textConstants } from "../../common/textConstants";
import AddEditTargetMarketVM from "./AddEditTargetMarketVM";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import { UnsavedChanges } from "../../Components/UnsavedChanges";
import { SectionCode } from "Models/Maintenance/SectionCode";
import TMScreen1 from "./Components/TMScreen1";
import TMScreen2 from "./Components/TMScreen2";
import TMScreen3 from "./Components/TMScreen3";
import TMScreen4 from "./Components/TMScreen4";
import TMScreen5 from "./Components/TMScreen5";
import TMScreen6 from "./Components/TMScreen6";
import InfoLabel from "./Functions/InfoLabel";

import { Range } from "rc-slider";
import ScreenNavigator from "Components/ScreenNavigator/ScreenNavigator";
import { isMiniNav } from "common/utils";
import { NavFooter } from "Components/FooterComponent";
import { StyledNavigationContainer } from "Views/IdealCustomerProfiles/Styles/ICPNavigation.styles";
import ListSelectorSelectedItems from "Components/OnboardingComponents/ListSelectorSelectedItems";

interface IAddEditTargetMarketProps {
  viewModel: AddEditTargetMarketVM;
  clientName: string;
  back: () => void;
}
interface IAccountState {
  showValue: boolean | null;
  showPrompt: boolean;
}

@observer
export default class AddEditTargetMarketView extends React.Component<
IAddEditTargetMarketProps,
IAccountState
> {
  constructor(props: IAddEditTargetMarketProps) {
    super(props);
    this.state = {
      showValue: false,
      showPrompt: false,
    };
  }

  public isZendeskVisible: boolean = false;

  componentDidMount() {
    // ensure grey background on reload
    const { viewModel } = this.props;
    const { globalProps } = viewModel.customAuthService;
    viewModel.showAddEditTM = true;

    this.isZendeskVisible = globalProps.isZendeskVisible;
    globalProps.isZendeskVisible = false;

    if (globalProps.showOnboardingSideMenu) {
      viewModel.customAuthService.onboardingFunctions.getRelevantSectionCode();
      globalProps.showOnboardingHelpCenter = true;
    }
  }

  componentWillUnmount() {
    const { viewModel } = this.props;
    viewModel.showAddEditTM = false;
    viewModel.customAuthService.globalProps.showOnboardingHelpCenter =
      false;
    viewModel.customAuthService.globalProps.isZendeskVisible =
      this.isZendeskVisible;
  }

  public onDocumentSelected(fileList: File[] | FileList) {
    const { viewModel } = this.props;
    // If 'fileList' is of type (FileList) then convert to (File[]) because it doesn't persist on the view model :|
    if (!Array.isArray(fileList) && fileList.length > 0) {
      viewModel.fileList = [fileList[0]];
    } else {
      viewModel.fileList = fileList;
    }

    const csvIndex = fileList[0].name.indexOf(".csv");
    if (csvIndex === -1) {
      viewModel.fileList.splice(0, viewModel.fileList.length);
      viewModel.invalidDataMessage = "";
      viewModel.showInvalidDataModal = true;
      viewModel.invalidDataMessage =
        textConstants.messageText.validationMessage.csv;
      viewModel.fileName = textConstants.generalText.none;
      return;
    }

    viewModel.fileName = fileList[0].name;
    viewModel.saveWhitelist = true;
    viewModel.fileUploaded()
  }

  public highlightMarketCriteria() {
    return this.props.viewModel.isMarketCriteria === true
      ? "highlightedText"
      : "";
  }

  public navigateBack = (e: any) => {
    window.history.back();
  };

  onLeave() {
    if (this.props.viewModel.isSaving === true) {
      return false;
    }

    const result = this.props.viewModel.isScreenDirty;

    return result;
  }

  public formatFunctionRole(functionRole: any) {
    try {
      functionRole = functionRole.value;
    } catch (e) {
      functionRole = "";
    }

    if (functionRole.indexOf('"') !== -1) {
      var lastIndexQuote = functionRole.lastIndexOf('"');

      if (lastIndexQuote >= 0) {
        return (
          <div>
            <span className="functRole">
              {functionRole.substring(0, lastIndexQuote)}
            </span>
            <span className="functSubRole">
              {functionRole.substring(lastIndexQuote)}
            </span>
          </div>
        );
      } else {
        return (
          <span className="functRole">
            {functionRole.substring(lastIndexQuote)}
          </span>
        );
      }
    } else {
      var firstBracket = functionRole.indexOf("(");

      if (firstBracket >= 0) {
        return (
          <div className="">
            <span className="functRole">
              {functionRole.substring(0, firstBracket)}
            </span>
            <span className="functSubRole">
              {functionRole.substring(firstBracket)}
            </span>
          </div>
        );
      } else {
        return (
          <span className="functRole">
            {functionRole.substring(firstBracket)}
          </span>
        );
      }
    }
  }

  public isHeaderManagerSelected() {
    const { viewModel } = this.props;

    return (
      viewModel.targetMarket.isSeniorityLevelManager === false &&
      viewModel.targetMarket.isSeniorityLevelHead === false
    );
  }

  public getCurrentSectionCode() {
    let section: string = "";

    if (this.props.viewModel.currentSection === 0) {
      section = SectionCode.TMOverview;
    } else {
      section = SectionCode[`TM${this.props.viewModel.currentSection}`];
    }

    return section;
  }

  private goToPreviousSection() {
    const { viewModel } = this.props;

    viewModel.currentSection = Math.max(
      viewModel.firstStepNumber,
      viewModel.currentSection - 1
    );
    viewModel.customAuthService.onboardingFunctions.getRelevantSectionCode(
      viewModel.currentSection
    );
  }

  public async navigateBackButton() {
    const { viewModel } = this.props;

    if (viewModel.currentSection === viewModel.firstStepNumber) {
      this.props.back();
    }

    if (this.isOverviewScreen || viewModel.currentSection === viewModel.fourthStepNumber) {
      this.goToPreviousSection();

      await viewModel.fetchScreenData(viewModel.currentSection)
    }
    else {
      await viewModel.saveTargetMarketModel(() => { }, false, false);

      if (viewModel.isValidSave !== false) {
        this.goToPreviousSection();

        if (viewModel.currentSection === 0) {
          this.props.viewModel.populateTMOverview();
        }

        await viewModel.fetchScreenData(viewModel.currentSection)
      }
    }
  }

  private goToNextSection() {
    const { viewModel } = this.props;

    const isClientUserAndWhiteListTM = viewModel.isWhitelistTM && viewModel.customAuthService.globalProps.isClientUser

    if (isClientUserAndWhiteListTM && this.getCurrentSectionCode() === SectionCode.TM1) {
      viewModel.currentSection = Math.min(viewModel.lastSection!, viewModel.currentSection + 2);
      viewModel.customAuthService.onboardingFunctions.getRelevantSectionCode(viewModel.currentSection);
    }
    else {
      viewModel.currentSection = Math.min(viewModel.lastSection!, viewModel.currentSection + 1);
      viewModel.customAuthService.onboardingFunctions.getRelevantSectionCode(viewModel.currentSection);
    }
  }

  public async navigationContinueButton() {
    const { viewModel } = this.props;

    if (!this.isOverviewScreen) {
      await viewModel.saveTargetMarketModel(() => { }, false, false);

      if (viewModel.isValidSave !== false) {
        this.goToNextSection();

        await viewModel.fetchScreenData(viewModel.currentSection)
      }
    } else {
      this.goToNextSection();
    }
  }

  public async navigateSaveButton() {

    if (this.props.viewModel.currentSection === 0) {
      this.props.back()
    }
    else {
      await this.props.viewModel.saveTargetMarketModel(() => { this.props.back() }, true, true, true)
    }
  }

  private getCurrentSection() {
    const { viewModel } = this.props

    let selectedSection = 0

    let currentSection = viewModel.relevantNavigationSteps.find(step => step.id === viewModel.currentSection)

    if (currentSection) {
      selectedSection = currentSection.stepNumber
    }

    return selectedSection;
  }
  private isOverviewScreen = false;

  public render() {
    const { viewModel } = this.props;
    const employeeSizes = [
      viewModel.targetMarket.employeeMinSizeId,
      viewModel.setMaxEmployeeSize(),
    ];
    const { isComXUser: isComXUser, isMarketCriteria } = viewModel;

    this.isOverviewScreen =
      this.getCurrentSectionCode() === SectionCode.TMOverview;

    // Name, Country, Regions
    const screen1 = (
      <TMScreen1
        viewModel={viewModel}
        clientName={this.props.clientName}
        isComXUser={isComXUser}
        isNotEditable={this.isOverviewScreen}
      />
    );

    // Employee Size and Industries
    const screen2 = (
      <TMScreen2
        viewModel={viewModel}
        isMarketCriteria={isMarketCriteria}
        isComXUser={isComXUser}
        onDocumentSelected={(file) => this.onDocumentSelected(file)}
      />
    );

    // C-Level Roles
    const screen3 = (
      <TMScreen3
        viewModel={viewModel}
        formatFunctionRole={(roleName) =>
          this.formatFunctionRole(roleName)
        }
      />
    );

    // Head and Manager Roles and Functions
    const screen4 = (
      <TMScreen4
        viewModel={viewModel}
        formatFunctionRole={(roleName) =>
          this.formatFunctionRole(roleName)
        }
        isNotEditable={this.isOverviewScreen}
      />
    );

    // Other Function Keywords (Other Roles and Functions)
    const screen5 = (
      <TMScreen5
        viewModel={viewModel}
        isHeaderManagerSelected={() => this.isHeaderManagerSelected()}
        isNotEditable={this.isOverviewScreen}
      />
    );

    // Advanced
    const screen6 = (
      <>
        {isComXUser && (
          <TMScreen6
            viewModel={viewModel}
            isNotEditable={this.isOverviewScreen}
          />
        )}
      </>
    );

    // Target Market Overview
    const overviewScreen = (
      <>
        {screen1}
        {!this.props.viewModel.isWhitelistTM &&
          <>
            <h4>{textConstants.titleText.NumberOfEmployees}</h4>
            <Neo.GridLayout
              className="pb-4 pt-5 pl-3"
              xl={1}
              lg={1}
              md={1}
              sm={1}
              xs={1}
            >
              {this.props.viewModel.targetMarket.employeeMinSizeId >=
                1 && (
                  <Range
                    defaultValue={[
                      this.props.viewModel.targetMarket
                        .employeeMinSizeId,
                      this.props.viewModel.setMaxEmployeeSize(),
                    ]}
                    min={1}
                    max={8}
                    disabled={true}
                    marks={{
                      1: "11",
                      2: "50",
                      3: "200",
                      4: "500",
                      5: "1000",
                      6: "5000",
                      7: "10000",
                      8: "10001+",
                    }}
                    dots
                    onAfterChange={(pointsOnLine) => {
                      this.props.viewModel.setEmployeeSize(
                        pointsOnLine
                      );
                      this.props.viewModel.isScreenDirty = true;
                      this.props.viewModel.handleMandatoryFieldCompanySize();
                    }}
                  />
                )}
            </Neo.GridLayout>
            <ListSelectorSelectedItems
              key={textConstants.Onboarding.SelectedIndustries}
              title={textConstants.Onboarding.SelectedIndustries}
              btnText={
                textConstants.Onboarding
                  .ListSelectorBtnTextIndustries
              }
              itemType={
                textConstants.Onboarding.ListSelectorTypeIndustries
              }
              list={this.props.viewModel.listSelectedItemsIndustries}
              openModal={() => { }}
              isNotEditable
              estimatedIndustries={this.props.viewModel.potentialAccounts}
            />
          </>
        }
        <ListSelectorSelectedItems
          key={textConstants.Onboarding.SelectedRoles}
          title={textConstants.Onboarding.SelectedRoles}
          btnText={textConstants.Onboarding.ListSelectorBtnTextRoles}
          itemType={textConstants.Onboarding.ListSelectorTypeRoles}
          list={this.props.viewModel.listSelectedItemsCLevelRoles}
          openModal={() => { }}
          isNotEditable
        />
        <ListSelectorSelectedItems
          key={textConstants.Onboarding.SelectedManagementRoles}
          title={textConstants.Onboarding.SelectedManagementRoles}
          btnText={
            textConstants.Onboarding
              .ListSelectorBtnTextRolesAndFunctions
          }
          itemType={
            textConstants.Onboarding
              .ListSelectorTypeRolesAndFunctions
          }
          list={this.props.viewModel.listSelectedItemsManagementRoles}
          isHeadOfSeniority={
            this.props.viewModel.targetMarket.meta
              .isSeniorityLevelHead
          }
          isManagerOfSeniority={
            this.props.viewModel.targetMarket.meta
              .isSeniorityLevelManager
          }
          openModal={() => { }}
          isNotEditable
        />
        {screen5}
        {screen6}
      </>
    );

    return (
      <div className="AddEditTargetMarketsView appFooterRestrictions">
        <UnsavedChanges
          showConfirm={this.state.showValue}
          showPrompt={this.onLeave()}
          backButtonPath={"TargetMarkets/"}
          runFunction={async () => {
            // if the user decides to navigate away we need send this zap
            if (!viewModel.hasSentTMChangeZap) {
              await viewModel.sendTMChangesZap();
              viewModel.hasSentTMChangeZap = true;
            }
          }}
        />

        <StyledNavigationContainer>
          <ScreenNavigator
            steps={viewModel.relevantNavigationSteps}
            goToStep={async (stepNumber: number) => { await viewModel.goToStep(stepNumber, this.isOverviewScreen) }}
            currentStepNumber={this.getCurrentSection()}
            isDisabled={viewModel.targetMarket.targetMarketName === "" || viewModel.targetMarket.countryId === 0}
          />
        </StyledNavigationContainer>

        <Neo.Card className="p-24 pb-4 ml-0 mr-0 cardStyles">
          {!viewModel.displayAddEditGrid && (
            <>
              {this.isOverviewScreen && overviewScreen}
              {this.getCurrentSectionCode() === SectionCode.TM1 &&
                screen1}
              {this.getCurrentSectionCode() === SectionCode.TM2 &&
                screen2}
              {this.getCurrentSectionCode() === SectionCode.TM3 &&
                screen3}
              {this.getCurrentSectionCode() === SectionCode.TM4 &&
                screen4}
              {this.getCurrentSectionCode() === SectionCode.TM5 &&
                screen5}
              {this.getCurrentSectionCode() === SectionCode.TM6 &&
                screen6}
            </>
          )}

          {/* Edit Footer */}
          <div
            className={`row appFooter ${viewModel.customAuthService.onboardingFunctions.isOnboardingHelpCenterVisible()
              ? "helpCenterAllowance"
              : ""
              } 
          ${isMiniNav() ? "miniNav" : ""}`}
          >
            {
              <NavFooter
                currentSection={viewModel.currentSection}
                lastSection={viewModel.lastSection}
                saveAsDraft={async () => {
                  this.props.viewModel.isScreenDirty = false;
                  await this.navigateSaveButton()
                }
                }
                saveAndPublish={async () => {
                  this.props.viewModel.isScreenDirty = false;
                  await viewModel.saveTargetMarketModel(
                    () => {
                      this.props.back();
                    },
                    true,
                    true
                  )
                }
                }
                disabled={
                  viewModel.targetMarket.targetMarketName ===
                  "" ||
                  viewModel.targetMarket.countryId === 0
                }
                continueButton={async () => {
                  this.props.viewModel.isScreenDirty = false;
                  await this.navigationContinueButton()
                }
                }
                backButton={async () => {
                  this.props.viewModel.isScreenDirty = false;
                  await this.navigateBackButton()
                }
                }
              />
            }
          </div>

          {/* "Previous edit is busy" message */}
          {viewModel.displayAddEditGrid && (
            <InfoLabel
              labelType="info"
              labelText={
                textConstants.messageText.validationMessage
                  .cannotEditTargetMarket
              }
              isVisible={viewModel.displayAddEditGrid}
              font="textFontSize20"
            />
          )}
        </Neo.Card>

        {/* Save Target Market Modal */}
        <Neo.Modal
          title={`Save Target Market: ${viewModel.targetMarket.targetMarketName}`}
          show={viewModel.showSaveModal}
          onClose={() => (viewModel.showSaveModal = false)}
          closeButton={{ text: "No", variant: "danger" }}
          acceptButton={{
            text: "Yes",
            variant: "success",
            onClick: (e: any) => {
              e.preventDefault();
              viewModel.saveTargetMarket(() => {
                this.props.back();
              }, true);
            },
          }}
        >
          Target Market needs to be saved. Save now?
        </Neo.Modal>

        {/* Invalid Data Modal */}
        <Neo.Modal
          title={textConstants.titleText.InvalidData}
          show={viewModel.showInvalidDataModal}
          onClose={() => {
            viewModel.showInvalidDataModal = false;
            let findFocusRef = viewModel.findFocus();
            if (findFocusRef) {
              viewModel.handleFocus(findFocusRef);
            }
          }}
          closeButton={{ text: "Close", variant: "light" }}
        >
          {viewModel.invalidDataMessage.split("\n").map((item, i) => {
            return <p key={i}>{item}</p>;
          })}
        </Neo.Modal>

        {/* Target Market Type Modal */}
        <Neo.Modal
          title={textConstants.titleText.TargetMarketType}
          show={viewModel.showTargetMarketTypeModal}
          onClose={() =>
            (viewModel.showTargetMarketTypeModal = false)
          }
          closeButton={{ text: "Close", variant: "light" }}
        >
          {
            <p>
              {
                textConstants.messageText.validationMessage
                  .targetMarketTypeChange
              }
            </p>
          }
        </Neo.Modal>
      </div>
    );
  }
}
