

export enum ChartType
{
  SentOutEmails = 0,

  OpenRate = 1,

  BouncedRate = 2,
    
  // Total Replies
  RepliesOverTime = 3,

  ReplyRate = 4,

  PieChart = 5,
}