import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import { AppService, Types } from '../../Services/AppService';
import DemoClientData from 'Models/Maintenance/DemoClientData';
import { MockDataJSONType } from 'Models/Enums/ApplicationEnums';
import { base64toBlob } from 'common/utils';
import ClientListLookup from 'Models/Client/ClientListLookup';

@NeoModel
export default class DemoClientDataVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private demoClientApiClient = AppService.get(Types.ApiClients.DemoClientsApiClient),
        private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient)) {

        super(taskRunner);
    }

    public demoClientList = new List(DemoClientData);

    public showModal: boolean = false;

    public demoClientId: number = 0;

    public mockDataTitle: string = "";

    public clients = new List(ClientListLookup)

    public jsonType: MockDataJSONType = MockDataJSONType.ReplyExperience;

    public async initialise() {
        await this.getDemoClients()
        await this.getClients()
    }

    public async getDemoClients() {
        const demoClients = await this.taskRunner.waitFor(this.demoClientApiClient.getDemoClients());

        if (demoClients.status === 200) {
            this.demoClientList.set(demoClients.data);
        } 
        else {
            this.notifications.addDanger(textConstants.titleText.FetchFailed, demoClients);
        }
    }

    public async getClients() {
        const reponse = await this.taskRunner.waitFor(this.clientsApiClient.getClients());

        if (reponse.status === 200) {
            this.clients.set(reponse.data);
        } 
        else {
            this.notifications.addDanger(textConstants.titleText.FetchFailed, reponse);
        }
        
    }

    public saveDemoClients() {
        this.taskRunner.run(async () => {
            const response = await this.demoClientApiClient.saveDemoClients(this.demoClientList);

            if (response.data.success) {
                this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.DemoClientMockDataSave);
                this.getDemoClients()
            } 
            else {
                this.notifications.addDanger(textConstants.titleText.SaveFailed, response);
            }
        });
    }

    public uploadJsonFile(fileList: File[] | FileList) {

        this.taskRunner.run(async () => {
            const response = await this.demoClientApiClient.uploadDocument(
                fileList,
                this.demoClientId,
                this.jsonType,
                this.demoClientList.find(demoClient => demoClient.demoClientId === this.demoClientId)?.clientId ?? 0,
            );

            if (response.data.success) {
                this.notifications.addSuccess(
                    textConstants.titleText.UploadDemoClient,
                    textConstants.messageText.saveMessage.DemoClientMockDataUpload
                );
                this.getDemoClients()
            }
            else {
                this.notifications.addDanger(
                    textConstants.titleText.Error,
                    response.data.message
                );
            }
        });

        this.showModal = false;
    }

    public download() {
        this.taskRunner.run(async () => {
            const response = await this.demoClientApiClient.downloadMockData(this.demoClientId, this.jsonType);

            const url = window.URL.createObjectURL(base64toBlob(response.data, 'application/json'));
            const link = document.createElement("a");
            link.setAttribute("download", `${MockDataJSONType[this.jsonType]}`);
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }
}