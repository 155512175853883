function getAutoHeightDuration(height: number): number {
    const MIN_DURATION = 250;
    const MAX_DURATION = 500;
    const HEIGHT_MULTIPLIER = 2;
    const duration = Math.min(
      MAX_DURATION,
      Math.max(MIN_DURATION, height * HEIGHT_MULTIPLIER)
    );
    return duration;
  }
  
  function transitionsCreate(
    props: string | string[],
    options?: {
      duration?: number | string;
      easing?: string;
      delay?: number | string;
    }
  ): string {
    if (!props) {
      props = ["all"];
    }
  
    let transitions = "transition: ";
    for (const prop of props) {
      transitions += prop + " ";
    }
    transitions += ";";
  
    if (options) {
      if (options.duration) {
        transitions += `\n  transition-duration: ${options.duration}ms; `;
      } else if (typeof options.duration === "string") {
        transitions += `\n  transition-duration: ${options.duration}; `;
      }
      if (options.easing) {
        transitions += `\n  transition-easing: ${options.easing}; `;
      } else if (typeof options.easing === "string") {
        transitions += `\n  transition-easing: ${options.easing}; `;
      }
      if (options.delay) {
        transitions += `\n  transition-delay: ${options.delay}ms; `;
      } else if (typeof options.delay === "string") {
        transitions += `\n  transition-delay: ${options.delay}; `;
      }
    }
  
    return transitions + "\n";
  }
  
  const duration = {
    shortest: 150,
    shorter: 200,
    short: 250,
    // most basic recommended timing
    standard: 300,
    // this is to be used in complex animations
    complex: 375,
    // recommended when something is entering screen
    enteringScreen: 225,
    // recommended when something is leaving screen
    leavingScreen: 195
  };
  
  const easing = {
    // This is the most common easing curve.
    easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
    // Objects enter the screen at full velocity from off-screen and
    // slowly decelerate to a resting point.
    easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
    // Objects leave the screen at full velocity. They do not decelerate when off-screen.
    easeIn: "cubic-bezier(0.4, 0, 1, 1)",
    // The sharp curve is used by objects that may return to the screen at any time.
    sharp: "cubic-bezier(0.4, 0, 0.6, 1)"
  };
  
  export const transitions = {
    transitionsCreate,
    getAutoHeightDuration,
    duration,
    easing
  };
  