import { List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';
import ManagementSubRoleLocalisation from './ManagementSubRoleLocalisation';

@NeoModel
export default class ManagementSubRole extends ModelBase {

    public managementSubRoleId: number = 0;

    public managementRoleId: number = 0;

    public subRoleName: string = "";

    public variation: string = "";

    isExpanded: boolean = false

    public managementSubRoleLocalisations = new List(ManagementSubRoleLocalisation)


    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.subRoleName === "", textConstants.messageText.validationMessage.subRole);
    }

    public toString(): string {
        if (this.isNew || !this.subRoleName) {
            return "New Sub Role";
        } else {
            return this.subRoleName;
        }
    }
}