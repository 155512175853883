import { List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import ManagementSubFunction from './ManagementSubFunction';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class ManagementFunction extends ModelBase {

    managementFunctionId: number = 0;

    functionName: string = "";

    isExpanded: boolean = false

    public managementSubFunctions = new List(ManagementSubFunction)

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.functionName === "", textConstants.messageText.validationMessage.managementFunction);
    }

    public toString(): string {
        if (this.isNew || !this.functionName) {
            return "New Sub Function";
        } else {
            return this.functionName;
        }
    }
}