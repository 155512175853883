import {ModelBase, NeoModel, List, Attributes } from '@singularsystems/neo-core';
import SubIndustryLookup from './SubIndustryLookup';
import { Observable } from '@singularsystems/neo-core/dist/Model/Decorators';


@NeoModel
export default class TargetMarketIndustryLookup extends ModelBase {

    // Properties
    public industryId: number = 0;

    public industryName: string = "";

    @Attributes.NullableBoolean()
    public isSelected: Nullable<boolean> = null;

    public firstClick: boolean = false;
    
    public isExpanded: boolean = false;

    public isPriority: Nullable<boolean> = null;

    // Child List
    public subIndustries = new List(SubIndustryLookup);
}