import React from 'react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import ProspectingVM from '../ProspectingVM';
import { observer } from 'mobx-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { textConstants } from '../../../common/textConstants';


interface IProspectingProps {
  viewModel: ProspectingVM,
  showCopySuccess: () => void
}

@observer
export default class IROJobSection extends React.Component<IProspectingProps> {
  public render() {

    return (
      <React.Fragment>
        <div className="IROJobSection">

          {/* Job Title */}
          <h3 className='p-0 mt-4 mb-2'>{textConstants.titleText.JobTitle}</h3>
          <div className="row negSideMargins24">
            <div className="col-lg-12 p-0">
              <NeoGrid.Grid items={this.props.viewModel.IROSearch.lookups}>
                {(template, templateMeta) => (
                  <NeoGrid.Row >
                    <NeoGrid.Column display={templateMeta.searchText} />
                    <NeoGrid.ButtonColumn>
                      <CopyToClipboard text={template.searchText} onCopy={() => this.props.showCopySuccess()}>
                        <Neo.Button variant='light' >{textConstants.buttonText.Copy}</Neo.Button>
                      </CopyToClipboard>
                    </NeoGrid.ButtonColumn>
                  </NeoGrid.Row>
                )}
              </NeoGrid.Grid>
            </div>
          </div>

          {/* Job Levels */}
          <h3 className='p-0 mt-4 pt-2 mb-2'>{textConstants.titleText.JobLevels}</h3>
          <div className="row m-0">
            <NeoGrid.Column
              className={"text-center pb-2"}
            >
              <div className="col-2">{textConstants.titleText.IROCLevel}<span className={`fa ${this.props.viewModel.IROSearch.clevel ? "fa-check text-success ml-3 responsiveMarginLeft" : "fa-times text-danger ml-3 responsiveMarginLeft"}`} /> </div>
            </NeoGrid.Column>

            <NeoGrid.Column
              className={"text-center pb-2"}
            >

              <div className="col-2">{textConstants.titleText.IROVP} <span className={`fa ${this.props.viewModel.IROSearch.vpLevel ? "fa-check text-success ml-3 responsiveMarginLeft" : "fa-times text-danger ml-3 responsiveMarginLeft"}`} /> </div>
            </NeoGrid.Column>

            <NeoGrid.Column
              className={"text-center  pb-2"}
            >
              <div className="col-2">{textConstants.titleText.IROHeadLevel}<span className={`fa ${this.props.viewModel.IROSearch.headLevel ? "fa-check text-success ml-3 responsiveMarginLeft" : "fa-times text-danger ml-3 responsiveMarginLeft"}`} /> </div>
            </NeoGrid.Column>

            <NeoGrid.Column
              className={"text-center pb-2"}
            >
              <div className="col-2">{textConstants.titleText.IRODirector}<span className={`fa ${this.props.viewModel.IROSearch.directorLevel ? "fa-check text-success ml-3 responsiveMarginLeft" : "fa-times text-danger ml-3 responsiveMarginLeft"}`} /> </div>
            </NeoGrid.Column>
            <NeoGrid.Column
              className={"text-center pb-2"}
            >
              <div className="col-2">{textConstants.titleText.IROManagerLevel}<span className={`fa ${this.props.viewModel.IROSearch.managerLevel ? "fa-check text-success ml-3 responsiveMarginLeft" : "fa-times text-danger ml-3 responsiveMarginLeft"}`} /> </div>
            </NeoGrid.Column>
          </div>

          {/* Job Functions */}
          <h3 className='p-0 mt-4 pt-2 mb-2'>{textConstants.titleText.JobFunctions}</h3>
          <NeoGrid.Column
            className={"text-center"}
          >
            <div>{this.props.viewModel.IROSearch.support} </div>
          </NeoGrid.Column>

          {/* Countries */}
          <h3 className='p-0 mt-4 pt-2 mb-2'>{textConstants.titleText.Countries}</h3>
          <div>{this.props.viewModel.IROSearch.country} </div>
        </div>
      </React.Fragment>
    );
  }
}
