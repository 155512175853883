import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';

export interface IOnboardingStepsApiClient extends Data.IUpdateableApiClient<OnboardingStepsApiClient, number> {

}

@injectable()
export default class OnboardingStepsApiClient extends Data.UpdateableApiClient<OnboardingStepsApiClient, number> implements IOnboardingStepsApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/OnboardingSteps`);
    }
}