import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class Industry extends ModelBase{

    public industryId: number = 0;

    @Rules.StringLength(500)
    @Attributes.Display("Name")
    public industryName: string = "";

    public industriesVersionId: number = 1;

    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.industryName === null || c.industryName === '', textConstants.messageText.validationMessage.industryName);
    }

    public toString(): string {
        if (this.isNew || !this.industryName) {
            return "New industry";
        } else {
            return this.industryName;
        }
    }
}