import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import Platform from '../Models/Scraping/Platform';

export interface IPlatformsApiClient extends Data.IUpdateableApiClient<Platform, string> {

}

@injectable()
export default class PlatformsApiClient extends Data.UpdateableApiClient<Platform, string> implements IPlatformsApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/platforms`);
    }
}