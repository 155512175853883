import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftjsToHtml from "draftjs-to-html";
import htmlToDraftjs from "html-to-draftjs"
import { Editor, RawDraftContentState } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState, Modifier } from "draft-js";

interface IRichTextEditor {
  initialString: string,
  getHtmlText?: (html: string) => void,
  override?: any,
  placeholder?: any,
  updatedContent?: string,
  disabled?: boolean
}

class RichTextEditor extends React.Component<IRichTextEditor> {
  constructor(props: any) {
    super(props);
  }

  state = {
    editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraftjs(this.props.initialString ?? "").contentBlocks)),
    htmlContent: "",
    placeholder: this.props.placeholder,
    updatedContent: this.props.updatedContent,
  };

  private newLineReplacements = ["<p></p>", "<h1></h1>", "<h2></h2>", "<h3></h3>", "<h4></h4>", "<h5></h5>", "<h6></h6>"]

  public options: any = {};

  public isUpdated: boolean = this.props.initialString !== ""

  public handlePastedText = (text: string): boolean => {
    const newContent = Modifier.replaceWithFragment(
      this.state.editorState.getCurrentContent(),
      this.state.editorState.getSelection(),
      ContentState.createFromText(text).getBlockMap(),
    )

    this.onChange(EditorState.push(this.state.editorState, newContent, 'insert-characters'));

    return true;
  }

  public onChange = (editorState: EditorState) => {
    // General editor changes
    this.setState(
      { editorState: editorState },
      () => {
        this.setState({ htmlContent: draftjsToHtml(convertToRaw(editorState.getCurrentContent())) });
      }
    );

    this.setLineBreaks();
  };

  public onContentChange = (contentState: RawDraftContentState) => {
    // Used for modal changes
    this.setState({
      htmlContent: draftjsToHtml(contentState),
    });

    this.setLineBreaks()
  };

  private setLineBreaks() {
    let tempHtmlContents = ""

    if (this.props.getHtmlText !== undefined) {
      tempHtmlContents = this.state.htmlContent

      this.newLineReplacements.forEach(tag => {
        tempHtmlContents = tempHtmlContents.replace(new RegExp(tag, "gi"), "<br>")
      })

      if (tempHtmlContents.endsWith('\n')) {
        tempHtmlContents = tempHtmlContents.substring(0, tempHtmlContents.length - 1)
      }

      this.setState({
        htmlContent: tempHtmlContents
      }, () => {
        this.props.getHtmlText!(this.state.htmlContent);
      });
    }
  }

  private stopClickEventPropagation(e: any) {
    e.stopPropagation();
  }

  public default = {
    options: [
      "fontFamily", "fontSize", "colorPicker", "inline",
      "blockType", "list", "textAlign", "link", "emoji",
      "image", "history"
    ],
    inline: {
      options: [
        "bold", "italic", "underline", "strikethrough",
        "monospace", "superscript", "subscript"
      ],
    },
    blockType: {
      inDropdown: true,
      options: [
        "Normal", "H1", "H2", "H3",
        "H4", "H5", "H6"
      ],
    },
    fontFamily: {
      options: ['Roboto', 'Arial', 'Georgia', 'Impact', 'Tahoma',
        'Times New Roman', 'Verdana'
      ],
    },
    image: {
      urlEnabled: true,
      alignmentEnabled: true,
      previewImage: false,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
    },
    colorPicker: {
      colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    },
    list: {
      options: ["unordered", "ordered", "indent", "outdent"],
    },
    link: {
      defaultTargetOption: '_blank',
      showOpenOptionOnHover: true,
      options: ['link'],
    },
    textAlign: {
      inDropdown: false,
      options: ["left", "center", "right", "justify"],
    },
    history: {
      inDropdown: false,
      options: ["undo", "redo"],
    },
    emoji: {
      emojis: [
        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🤙', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
        '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
        '✅', '❎', '💯', '🤖', '🚀', '🎉', '🐢', '🥇', '🔴', '🟡', '🟢'
      ],
    },
  }

  componentDidUpdate(prevProps: IRichTextEditor, prevState: any) {
    if (prevProps.placeholder !== this.props.placeholder) {
      this.setState({ placeholder: this.props.placeholder });
    }
    if (prevProps.updatedContent !== this.props.updatedContent) {
      this.handleOnClickText()
    }

    if (this.props.initialString !== prevProps.initialString && !this.isUpdated) {
      this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraftjs(this.props.initialString).contentBlocks
          )
        )
      });
      this.isUpdated = true;
    }
  }

  handleOnClickText() {
    const updatedContent = this.props.updatedContent ?? "";
    const contentState = ContentState.createFromBlockArray(htmlToDraftjs(updatedContent).contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    this.setState({
      updatedContent: updatedContent,
      editorState: editorState,
      htmlContent: draftjsToHtml(convertToRaw(editorState.getCurrentContent())),
    });

    this.setLineBreaks();
  }

  public render() {

    const options = this.props.override
      ? { ...this.default, inline: { options: this.props.override } }
      : this.default;

    const placeholder = this.props.placeholder ?? this.state.placeholder;

    return (
      <div onClick={this.stopClickEventPropagation} className="richTextEditor">
        <Editor
          editorState={this.state.editorState}
          wrapperClassName={`wrapper ${this.props.disabled ? "disabled" : ""}`}
          editorClassName="editor"
          onEditorStateChange={this.onChange}
          handlePastedText={(text) => this.handlePastedText(text)}
          onContentStateChange={this.onContentChange}
          toolbar={options}
          placeholder={`${this.props.placeholder ? placeholder : ""}`}
          readOnly={this.props.disabled}
        />
      </div>
    );
  }
}

export default RichTextEditor;
