import { RouteSecurityService as NeoRouteSecurityService } from '@singularsystems/neo-core/dist/Routing/RouteSecurityService';
import { AppService, Types } from './AppService';
import { injectable } from 'inversify';
import { IAppRoute } from '../Routes';
import * as Roles from '../Models/Security/Roles';
import { textConstants as tc } from '../common/textConstants';

@injectable()
export class RouteSecurityService extends NeoRouteSecurityService {

    constructor(
        protected authorisationService = AppService.get(Types.Neo.Security.AuthorisationService),
        protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService),
    ) {
        super(authorisationService);
    }

    public routeAllowed(route: IAppRoute): boolean {

        switch (route.name) {
            case tc.Routes.Dashboard:
                if (this.authorisationService.hasRole(Roles.Clients.Manage) || !this.authorisationService.hasRole(Roles.Dashboard.Access)) {
                    return false
                }
                break

            case tc.Routes.Clients:
                if (!this.authorisationService.hasRole(Roles.Clients.Manage)) {
                    return false
                }
                break

            case tc.Routes.ActionList:
                if (!this.authorisationService.hasRole(Roles.ActionList.Access)) {
                    return false
                }
                break

            case tc.Routes.IdealCustomerProfileSpaced:
                if (this.authorisationService.hasRole(Roles.Clients.Manage) || !this.authorisationService.hasRole(Roles.IdealCustomerProfile.Manage)) {
                    return false
                }
                break

            case tc.Routes.TargetMarketsSpaced:
                if (this.authorisationService.hasRole(Roles.Clients.Manage) || !this.authorisationService.hasRole(Roles.TargetMarkets.Manage)) {
                    return false
                }
                break

            case tc.Routes.Blacklist:
                if (this.authorisationService.hasRole(Roles.Clients.Manage) || !this.authorisationService.hasRole(Roles.Blacklist.Manage)) {
                    return false
                }
                break

            case tc.Routes.BatchReviewSpaced:
                if (this.authenticationService.userMenuAccessInfo) {
                    if (this.authorisationService.hasRole(Roles.Clients.Manage) || !this.authorisationService.hasRole(Roles.BatchReview.Manage) ||
                        !this.authenticationService.userMenuAccessInfo.isBatchReview) {
                        return false;
                    }
                }
                else {
                    return false;
                }
                break;

            case tc.Routes.CampaignMessagesSpaced:
                if (this.authenticationService.userMenuAccessInfo) {
                    if (this.authorisationService.hasRole(Roles.Clients.Manage) || !this.authorisationService.hasRole(Roles.CampaignMessages.Manage) ||
                        !this.authenticationService.userMenuAccessInfo.isCampaignMessages) {
                        return false;
                    }
                }
                else {
                    return false;
                }
                break;

            case tc.Routes.Administration:
                if (!this.authorisationService.hasRole(Roles.Maintenance.Manage) &&
                    !this.authorisationService.hasRole(Roles.MasterData.Manage) &&
                    !this.authorisationService.hasRole(Roles.Security.Access) &&
                    !this.authorisationService.hasRole(Roles.Users.Manage)) {
                    return false
                }
                break

            case tc.Routes.Maintenance:
                if (!this.authorisationService.hasRole(Roles.Maintenance.Manage)) {
                    return false
                }
                break

            case tc.Routes.MasterAccountsSpaced:
                if (!this.authorisationService.hasRole(Roles.MasterData.Manage)) {
                    return false
                }
                break

            case tc.Routes.Security:
                if (!this.authorisationService.hasRole(Roles.Security.Access)) {
                    return false
                }
                break

            case tc.Routes.Users:
                if (!this.authorisationService.hasRole(Roles.Users.Manage)) {
                    return false
                }
                break

            case tc.Routes.KnowledgeCenter:
                if (this.authorisationService.hasRole(Roles.Clients.Manage) || !this.authorisationService.hasRole(Roles.Dashboard.Access)) {
                    return false
                }
                break;

            case tc.Routes.Settings:
                if (this.authorisationService.hasRole(Roles.Clients.Manage) ||
                    (!this.authorisationService.hasRole(Roles.Settings.ActionPlanChecklist) &&
                        !this.authorisationService.hasRole(Roles.Settings.CRMIntegration) &&
                        !this.authorisationService.hasRole(Roles.Settings.TermsOfService) &&
                        !this.authorisationService.hasRole(Roles.TI_OutboundEmails.Delete) &&
                        !this.authorisationService.hasRole(Roles.TI_OutboundEmails.Edit) &&
                        !this.authorisationService.hasRole(Roles.TI_OutboundEmails.View) &&
                        !this.authorisationService.hasRole(Roles.TI_AdvancedEmailSettings.Edit) &&
                        !this.authorisationService.hasRole(Roles.TI_AdvancedEmailSettings.View) &&
                        !this.authorisationService.hasRole(Roles.TI_InternalTest.Edit) &&
                        !this.authorisationService.hasRole(Roles.TI_InternalTest.View)
                    )
                ) {
                    return false
                }
                break
            case tc.Routes.Reporting:
                if (!this.authorisationService.hasRole(Roles.Reporting.View)) {
                    return false;
                }
                break;

            default:
                return true
        }
        return true
    }
}
