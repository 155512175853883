import { NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class CustomerProfileLookup extends ValueObject {

    public profileName: string = "";

    public customerProfileId: number = 0; 

    public status: string = "";

    public sequenceNumber: number = 0;
}