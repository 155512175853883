import "rc-slider/assets/index.css";
import "react-input-range/lib/css/index.css";
import React from "react";
import { observer } from "mobx-react";
import { Neo } from "@singularsystems/neo-react";
import { textConstants } from "../../../common/textConstants";
import AddEditTargetMarketVM from "../AddEditTargetMarketVM";
import Tooltip from "@singularsystems/neo-react/dist/ReactComponents/Tooltip";
import * as Icon from "react-feather";
import { Range } from "rc-slider";
import ListSelectorModal from "Components/Modals/ListSelectorModal";
import ListSelectorSelectedItems from "Components/OnboardingComponents/ListSelectorSelectedItems";

interface TMScreen2Props {
    viewModel: AddEditTargetMarketVM;
    isMarketCriteria: boolean;
    isComXUser: boolean;
    onDocumentSelected: (file: FileList | File[]) => void;
}

@observer
export default class TMScreen2 extends React.Component<TMScreen2Props> {
    state = {
        openTMListModal: false,
    };

    public toggleModal() {
        this.setState({
            openTMListModal: !this.state.openTMListModal,
        });
    }

    public render() {
        return (
            <>
                {!this.props.viewModel.isWhitelistTM && (
                    <div className="numberOfEmployeesContainer">
                        {/* Company Headcount */}
                        {this.props.viewModel.isMarketCriteria && (
                            <div className="col-12 pl-0">
                                {this.props.viewModel.isCompanySizeBlank ? (
                                    <span className="formatValidationIcon">
                                        {
                                            textConstants.titleText
                                                .NumberOfEmployees
                                        }
                                        <Tooltip content="This section has mandatory fields that are missing">
                                            {" "}
                                            <i className="fa fa-exclamation-circle ml-1 validationRed" />
                                        </Tooltip>
                                    </span>
                                ) : (
                                    <span className="mt-3 mb-2 targetMarketAddEditSubQuestion">
                                        <h4>
                                            {
                                                textConstants.titleText
                                                    .NumberOfEmployees
                                            }
                                        </h4>
                                    </span>
                                )}

                                {/* Mandatory field warning message*/}

                                <Neo.GridLayout
                                    className="pb-4 pt-5 pl-3"
                                    xl={1}
                                    lg={1}
                                    md={1}
                                    sm={1}
                                    xs={1}
                                >
                                    {this.props.viewModel.targetMarket.employeeMinSizeId >= 1 && (
                                        <>
                                            <Range
                                                defaultValue={[
                                                    this.props.viewModel.targetMarket.employeeMinSizeId,
                                                    this.props.viewModel.setMaxEmployeeSize(),
                                                ]}
                                                min={1}
                                                max={8}
                                                marks={{
                                                    1: "11",
                                                    2: "50",
                                                    3: "200",
                                                    4: "500",
                                                    5: "1000",
                                                    6: "5000",
                                                    7: "10000",
                                                    8: "10001+",
                                                }}
                                                dots
                                                onAfterChange={(
                                                    pointsOnLine
                                                ) => {
                                                    this.props.viewModel.setEmployeeSize(pointsOnLine);
                                                    this.props.viewModel.isScreenDirty = true;
                                                    this.props.viewModel.handleMandatoryFieldCompanySize();
                                                    this.props.viewModel.potentialAccounts =
                                                        this.props.viewModel.calculatePotentialAccounts(this.props.viewModel.selectedModalList)
                                                }}
                                            />
                                        </>
                                    )}
                                </Neo.GridLayout>
                            </div>
                        )}
                    </div>
                )}

                <div
                    className={
                        this.props.isMarketCriteria ? "d-block" : "d-none"
                    }
                    ref={this.props.viewModel.industryRef}>
                    <ListSelectorModal
                        isOpen={this.state.openTMListModal}
                        closeModal={async () => { this.toggleModal(); }}
                        onListReturn={(selectedList, clonedList) => {
                            this.props.viewModel.setSelectedList(selectedList, clonedList)
                            this.props.viewModel.potentialAccounts = this.props.viewModel.calculatePotentialAccounts(selectedList)
                        }}
                        modalList={this.props.viewModel.listSelectorClonedList}
                        modalTitle={textConstants.titleText.Industry}
                        priorityChanged={(priority: boolean) =>
                            (this.props.viewModel.isPriority = priority)
                        }
                        potentialAccounts={this.props.viewModel.potentialAccountsOnModal}
                        calculatePotentialAccounts={(selectedList) => {
                            this.props.viewModel.potentialAccountsOnModal = this.props.viewModel.calculatePotentialAccounts(selectedList)
                        }}
                    />

                    {/* Industries */}
                    {!this.props.viewModel.isPriority &&
                        (this.props.viewModel.isIndustryBlank ||
                            this.props.viewModel.isPriorityIndustryBlank) && (
                            <div className="mandatoryText">
                                {textConstants.messageText.validationMessage.invalidIndustriesAndPriority}
                            </div>
                        )}

                    <ListSelectorSelectedItems
                        title={textConstants.Onboarding.ListSelectorTitleIndustries}
                        btnText={textConstants.Onboarding.ListSelectorBtnTextIndustries}
                        itemType={textConstants.Onboarding.ListSelectorTypeIndustries}
                        list={this.props.viewModel.selectedModalList}
                        isHeadOfSeniority={
                            this.props.viewModel.targetMarket.meta
                                .isSeniorityLevelHead
                        }
                        isManagerOfSeniority={
                            this.props.viewModel.targetMarket.meta
                                .isSeniorityLevelManager
                        }
                        onChange={() => {
                            this.props.viewModel.isScreenDirty = true;
                            this.props.viewModel.potentialAccounts = this.props.viewModel.calculatePotentialAccounts(this.props.viewModel.selectedModalList);
                        }}
                        openModal={() => {
                            this.props.viewModel.listSelectorClonedList = this.props.viewModel.cloneSelectorModalList(this.props.viewModel.modalList)
                            this.props.viewModel.setupListModalData();
                            this.toggleModal();
                        }}
                        priorityChanged={(priority: boolean) => (this.props.viewModel.isPriority = priority)}
                        estimatedIndustries={this.props.viewModel.potentialAccounts}
                        
                    />
                </div>

                {/* Account (Whitelist) Upload */}
                {this.props.viewModel.isWhitelistTM && (
                    <div className="col-sm mt-0 p-0">
                        {this.props.isComXUser && (
                            <Neo.GridLayout
                                xl={1}
                                lg={1}
                                md={1}
                                sm={1}
                                xs={1}
                                className="mt-4"
                            >
                                <div className="row">
                                    <h3 className="col-12">
                                        {textConstants.titleText.AccountUpload}
                                    </h3>
                                    <div className="col-12 mt-2 mb-2">
                                        <span>
                                            <Icon.AlertCircle />{" "}
                                            {
                                                textConstants.titleText
                                                    .PleaseEnsureHeaders
                                            }{" "}
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <ol>
                                            {this.props.viewModel.columnHeadings.map(
                                                (i) => (
                                                    <li
                                                        className="dropZoneListDisplay"
                                                        key={i}
                                                    >
                                                        {" "}
                                                        {i}{" "}
                                                    </li>
                                                )
                                            )}
                                            <a
                                                className="text-right customDownloadLink"
                                                onClick={() =>
                                                    this.props.viewModel.downloadTemplate()
                                                }
                                            >
                                                {
                                                    textConstants.generalText
                                                        .DownloadTemplate
                                                }
                                            </a>
                                        </ol>
                                    </div>

                                    <div className="col-12">
                                        <Neo.FileContext
                                            onFilesSelected={(e) =>
                                                this.props.onDocumentSelected(e)
                                            }
                                        >
                                            <Neo.FileDropArea multiple={false}>
                                                <p className="text-center dropZoneText">
                                                    <Neo.FileUploadButton>
                                                        <Icon.Upload />
                                                        {
                                                            textConstants
                                                                .generalText
                                                                .uploadOrDragFile
                                                        }
                                                    </Neo.FileUploadButton>
                                                </p>
                                            </Neo.FileDropArea>
                                        </Neo.FileContext>
                                        <p className="mt-2 mb-0"> Uploaded File: {this.props.viewModel.fileName} </p>
                                    </div>
                                </div>
                            </Neo.GridLayout>
                        )}
                    </div>
                )}
            </>
        );
    }
}
