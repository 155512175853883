import React from "react";
import { Neo } from "@singularsystems/neo-react";
import CampaignEmailSignatureVM from "./CampaignEmailSignatureVM";
import { observer } from "mobx-react";
import { textConstants } from "../../common/textConstants";
import CampaignEmailSignature from "./CampaignEmailSignature";
import { UnsavedChanges } from "../../Components/UnsavedChanges";
import * as Icon from "react-feather";

interface ICampaignEmailSignatureProps {
  viewModel: CampaignEmailSignatureVM;
  back: () => void;
}

interface IAccountState {
  showValue: boolean | null;
  showPrompt: boolean;
}

@observer
export default class CampaignEmailSignatureView extends React.Component<
  ICampaignEmailSignatureProps,
  IAccountState
> {
  constructor(props: ICampaignEmailSignatureProps) {
    super(props);
    this.state = {
      showValue: false,
      showPrompt: false,
    };
  }

  onLeave() {
    if (this.props.viewModel.campaignEmailSignatureHelper.isSaving === true) {
      return false;
    }

    const result =
      this.props.viewModel.campaignEmailSignatureHelper.isScreenDirty ||
      this.props.viewModel.campaignEmailSignatureHelper.isCommentDirty;

    return result;
  }
  public render() {
    return (
      <div className="CampaignEmailSignatureView">
        {/* Back */}
        <Neo.Button
          className="btn-width-100 mt-3 marginLeft24"
          variant="light"
          icon={<Icon.ArrowLeft />}
          onClick={async (e: any) => {
            this.props.back();
          }}
        >
          {textConstants.buttonText.Back}
        </Neo.Button>

        <Neo.Card>
          <CampaignEmailSignature
            viewModel={this.props.viewModel}
            back={() => {
              this.props.back();
            }}
          />

          <UnsavedChanges
            showConfirm={this.state.showValue}
            showPrompt={this.onLeave()}
            backButtonPath={"ClientCampaignMessages/"}
          />
        </Neo.Card>
      </div>
    );
  }
}
