import { NeoModel, ModelBase} from  '@singularsystems/neo-core';

@NeoModel
export default class UserMenuAccessLookup extends ModelBase {

    public clientId: number = 0;

    public isBatchReview: boolean = false;

    public isCampaignMessages: boolean = false;
}