import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import IndustrySubIndustry from '../Models/Maintenance/IndustrySubIndustry';
import Axios, { AxiosPromise } from 'axios';
import { ResponseResolver } from '../common/utils';

export interface IIndustrySubIndustriesApiClient extends Data.IUpdateableApiClient<IndustrySubIndustry, number> {
    getIndustrySubIndustry(): AxiosPromise<any> 
}

@injectable()
export default class IndustrySubIndustriesApiClient extends Data.UpdateableApiClient<IndustrySubIndustry, number> implements IIndustrySubIndustriesApiClient {
    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/industrySubIndustries`);
    }

    public getIndustrySubIndustry(): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get(`${apiPath}/IndustrySubIndustry/getIndustrySubIndustry`));
    }
}