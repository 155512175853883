import { textConstants } from "common/textConstants";
import { observer } from "mobx-react";
import React from "react";
import * as Icon from 'react-feather';
import PageSizeSelector from "./PageSizeSelector";

interface IPager {
    currentPageNumber: number,
    totalPages: number,
    totalResults: number,
    pageSize: number,
    pageSizeOptions?: [],
    setPageSize: (pageSize: string) => void
    goToNextPage: () => void
    goToPreviousPage: () => void
    goToFirstPage: () => void
    goToLastPage: () => void
    goToSpecificPage: (pageNumber: number) => void
}

@observer
class Pager extends React.Component<IPager> {

    private setShowingMin() {
        return ((this.props.currentPageNumber - 1) * this.props.pageSize) + 1
    }

    private setShowingMax() {
        if (this.props.currentPageNumber !== this.props.totalPages) {
            return (((this.props.currentPageNumber - 1) * this.props.pageSize) + 1) + (this.props.pageSize - 1)
        } else {
            return this.props.totalResults
        }
    }

    private setDownwardsButtonStyle() {
        if (this.props.currentPageNumber > 1) {
            return "navButton"
        } else {
            return "navButtonDisabled"
        }
    }

    private setUpwardsButtonStyle() {
        if (this.props.currentPageNumber !== this.props.totalPages) {
            return "navButton"
        } else {
            return "navButtonDisabled"
        }
    }

    render() {

        let pageSizeOptions: string[] = []
        let defaultPageSizeOptions = ["5", "8", "10", "20", "50", "100"]

        if (this.props.pageSizeOptions === undefined) {
            pageSizeOptions = defaultPageSizeOptions
        } else {
            pageSizeOptions = this.props.pageSizeOptions
        }

        return (
            <div className="row pager">
                {/* First Page button */}
                <Icon.ChevronsLeft
                    className={`mr-1 ${this.setDownwardsButtonStyle()}`}
                    onClick={() => this.props.currentPageNumber > 1 && this.props.goToFirstPage()} aria-disabled={true}
                />

                {/* Previous Page button */}
                <Icon.ChevronLeft
                    className={`mr-1 ${this.setDownwardsButtonStyle()}`}
                    onClick={() => this.props.currentPageNumber > 1 && this.props.goToPreviousPage()}
                />

                {/* Current Page number */}
                <span className="marginTop2"> {textConstants.ActionListText.Page}
                    <span className="ml-1 currentPage">
                        <input
                            type="number"
                            value={this.props.currentPageNumber}
                            onInput={(e: any) => {
                                if (!Number.isNaN(e.target.valueAsNumber) && e.target.valueAsNumber > 0 && e.target.valueAsNumber <= this.props.totalPages) {
                                    this.props.goToSpecificPage(e.target.value)
                                }
                            }}
                            onKeyDown={(e: any) => {
                                if (e.keyCode === 8) { // "backspace" key
                                    if (e.target.value.length === 1) { e.target.value = "" };
                                }
                                if (e.keyCode === 69) { // "e" key
                                    e.preventDefault(); // stop the input
                                }
                            }}
                        />
                    </span>
                </span>

                {/* Total Pages */}
                <span className="ml-1 mr-1 marginTop8"> of {this.props.totalPages}</span>

                {/* Next Page button */}
                <Icon.ChevronRight
                    className={`${this.setUpwardsButtonStyle()}`}
                    onClick={() => this.props.currentPageNumber !== this.props.totalPages && this.props.goToNextPage()}
                />

                {/* Last Page button */}
                <Icon.ChevronsRight
                    className={`${this.setUpwardsButtonStyle()}`}
                    onClick={() => this.props.currentPageNumber !== this.props.totalPages && this.props.goToLastPage()}
                />

                {/* Separator */}
                <div className="ml-2 mr-2 py-1 separator"></div>

                {/* Results per Page selector */}
                <PageSizeSelector
                    pageSize={this.props.pageSize}
                    setPageSize={(pageSize) => this.props.setPageSize(pageSize)}
                />

                {/* Separator */}
                <div className="ml-3 mr-2 separator"></div>

                {/* Showing */}
                <span className="ml-1 marginTop8"> {textConstants.ActionListText.Showing} {this.setShowingMin()}-{this.setShowingMax()} of {this.props.totalResults} </span>

            </div>
        )
    }
}

export default Pager