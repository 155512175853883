import styled from "styled-components";

export interface IWrapperProps {
    isDisabled?: boolean;
}

export const StyledNavigationWrapper = styled.div<IWrapperProps>`
    display: block;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    position: relative;
    border-radius: 8px 0px 0px 8px;
    cursor: pointer;
    overflow: hidden;

    ${({ isDisabled }) => isDisabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
    `};
`;

export interface IProps {
    progress: number;
}

export const ScreenNavigatorBar = styled.div<IProps>`
    background: #E9F2F7;
    border-radius: 8px 0px 0px 8px;
    height: inherit;
    width: ${({ progress }) => `${progress}%`};
`;

interface IPropsNavigationContent {
    width: number;
    isOpen: boolean;
    height: number;
}
export const StyledNavigationContent = styled.div<IPropsNavigationContent>`
    position: absolute;
    width: ${({ width }) => width}px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    height: ${({ isOpen }) => (isOpen ? `auto` : '0')};
    max-height: 400px;
    top: 140px;
    z-index: 999;
    -webkit-transition: height 0.5s ease;
    -moz-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: height 0.5s ease;
    overflow-y: scroll;
 
&::-webkit-scrollbar-track {
  background: #FFFFFF;
}
 
&::-webkit-scrollbar-thumb {
    background: #DCDDDD;
    border-radius: 10px;
}
`;

export const StyledNavigationItem = styled.div`
    display: block;
    padding: 16px;
    border-bottom: 2px solid #F8F8F8;

    cursor: pointer;

    &:hover {
        background-color: #E9F2F7;
    }
`;

export const StyledNavigationItemHeading = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: #818888;
    text-transform: uppercase;
    margin-bottom: 8px;

    svg {
        margin-right: 8px;
    }
`;

export const StyledNavigationItemContent = styled.div`
    p {
    color: #0B0B0C;
    font-weight: 400;
    font-size: 14px;
    font-family: "Lato-Regular";
    }
`;