import React from 'react';
import { Views, Neo } from '@singularsystems/neo-react';
import MaintenanceVM from './MaintenanceVM';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import '../../Views/Maintenance/MaintenanceView.scss';
import { IAppRoute } from '../../Routes';
import { maintenanceRoutes } from '../../RouteConfiguration'
import { NavLink } from 'react-router-dom';

@observer
export default class MaintenanceView extends Views.ViewBase<MaintenanceVM> {

  constructor(props: unknown) {
    super("Maintenance", MaintenanceVM, props);
  }

  onLeave() {
    this.viewModel.removeNotifications();
    return undefined;
  }

  public render() {
    return (
      <Neo.Card>
        <div className="MaintenanceView">
          <div className="row m-0 mt-4 p-24">
            <div className="col-12">
              <h2>{textConstants.generalText.MaintenanceView}</h2>
            </div>
          </div>
          <Neo.GridLayout xs={2} sm={2} md={3} lg={4} xl={4} className="pb-2 text-center m-0">
            {maintenanceRoutes.sortBy("name").map((route: IAppRoute, i) => {
              return (
                <div className="row m-0 mt-4 p-24" key={i}>
                  <div className="col-12">
                    <NavLink key={route.path} to={route.path || ''}>
                      <Neo.Button variant="secondary" icon={`${route.icon} stroke-transparent`} className=" mt-2">
                        <span>{route.name}</span>
                      </Neo.Button>
                    </NavLink>
                  </div>
                </div>
              )
            })}
          </Neo.GridLayout>
        </div>
      </Neo.Card>
    );
  }
}