import React from 'react';
import HtmlParser from 'html-react-parser';
import * as Icon from 'react-feather';
import InfoCardVM from './InfoCardVM';
import { observer } from 'mobx-react';
import TextAsParagraphs from './TextAsParagraphs';

interface InfoCardProps {
    viewModel: InfoCardVM;
    useTextArray: boolean;
    isNewDesign?: boolean;
    isExpanded: boolean;
    openOrClose: () => void;
}

@observer
class InfoCard extends React.Component<InfoCardProps> {

    public viewModel = this.props.viewModel;

    public render() {
        return (
            <>
                {!this.props.isNewDesign &&
                    <div className='legacy'>
                        {!this.props.isExpanded &&
                            <div className="row infoCard infoCardTextPadding ">

                                <div className='col-1 pr-0'>👉</div>

                                <div className="col-10 p-0">
                                    <h5>{HtmlParser(this.viewModel.title)}</h5>
                                    <p className="fontSize16">{HtmlParser(this.viewModel.shortDescription)}</p>
                                </div>

                                <div className="col-1">
                                    <button className="infoCardArrow justify-content-center"
                                        onClick={() => { this.props.openOrClose() }}>
                                        <i><Icon.ChevronDown size={24} /></i>
                                    </button>
                                </div>
                            </div>
                        }

                        {this.props.isExpanded &&
                            <div className="row infoCard infoCardTextPadding">

                                <div className='col-1 pr-0'>👉</div>

                                <div className="col-10 p-0">
                                    <h5>{HtmlParser(this.viewModel.title)}</h5>
                                    {this.props.useTextArray && <TextAsParagraphs paragraphArray={this.viewModel.fullDescription} />}
                                    {!this.props.useTextArray && HtmlParser(this.viewModel.fullDescription)}
                                </div>

                                <div className="col-1">
                                    <button className={`infoCardArrow justify-content-center`}
                                        onClick={() => { this.props.openOrClose() }}>
                                        {this.props.isExpanded &&
                                            <i> <Icon.ChevronUp size={24} /></i>
                                        }
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                }

                {this.props.isNewDesign &&
                    <div className='newCardStyle'>
                        {!this.props.isExpanded &&
                            <div className="row">
                                {/* Header */}
                                <div className="col-11">
                                    <h4>{HtmlParser(this.viewModel.title)}</h4>
                                </div>

                                {/* Expand / Collapse button */}
                                <div className="col-1 buttonColHover">
                                    <button className="infoCardArrow justify-content-center" onClick={() => { this.props.openOrClose() }}>
                                        <i> <Icon.ChevronDown size={32} /></i>
                                    </button>
                                </div>
                            </div>
                        } 

                        {this.props.isExpanded &&
                            <>
                                <div className="row">
                                    {/* Header */}
                                    <div className="col-11">
                                        <h4>{HtmlParser(this.viewModel.title)}</h4>
                                    </div>

                                    {/* Expand / Collapse button */}
                                    <div className="col-1 ">
                                        <button className="infoCardArrow justify-content-center" onClick={() => { this.props.openOrClose() }}>
                                            <i><Icon.ChevronUp size={32} /></i>
                                        </button>
                                    </div>
                                </div>

                                <div className="col-12 bodyText">
                                    {this.props.useTextArray && <TextAsParagraphs paragraphArray={this.viewModel.fullDescription} />}
                                    {!this.props.useTextArray && HtmlParser(this.viewModel.fullDescription)}
                                </div>
                            </>
                        }
                    </div>
                }
            </>
        )
    }
}

export default InfoCard;