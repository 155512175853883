import styled,{ css } from 'styled-components';

export const StyledDrawerContainer = styled.div<IStyledDrawerContentWrapperProps>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    margin-top: 48px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: block;
    transition: display .30s cubic-bezier(0.820, 0.085, 0.395, 0.895);
    svg {
        margin: 0px;
    }
`;

interface IStyledDrawerContentWrapperProps {
    width?: string;
}

export const StyledDrawerContentWrapper = styled.div<IStyledDrawerContentWrapperProps>`
    background: #F8F8F8;
    padding: 24px;
    width: ${({width}) => width};
    max-height: 100%;
    overflow: auto;
    box-sizing: border-box;
    position: fixed;
    right: 0;
    min-height: 100vh;
    border-radius: 8px 0px 0px 8px;
    transition: all 0.3s ease-in-out;
    float: right;
`;

export const StyledDrawerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyledDrawerCloseButton = styled.div`
    position: absolute;
    display: flex;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: #EDEEEE;
    border-radius: 100px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;