import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import { textConstants } from '../../common/textConstants';
import LeadMessageVM from './LeadMessageVM';
import ActionListLeadStatus from '../../Models/ActionList/ActionListLeadStatus';
import List from '@singularsystems/neo-core/dist/Model/List';
import ActionListLeadActivity from '../../Models/ActionList/ActionListLeadActivity';
import ActionListLeadType from '../../Models/ActionList/ActionListLeadType';
import ActionListBulkCommand from '../../Models/ActionList/ActionListBulkCommand';
import ActionListCriteria from 'Models/ActionList/Query/ActionListCriteria';
import ActionListLookupData from 'Models/ActionList/ActionListLookupData';
import ActionListStatus from 'Models/ActionList/ActionListStatus';
import ActionListSummaryLookup from 'Models/ActionList/ActionListSummaryLookup';
import SearchFieldVM from 'Components/SearchFieldVM';
import InfoCardVM from 'Components/InfoCardVM';
import ActionListFilter from 'Models/ActionList/ActionListFilter';
import ActionListConfiguration from 'Models/ActionList/ActionListConfiguration';
import { ActionListTypes, HTTPResponseCodes } from 'Models/Enums/ApplicationEnums';

export default class ActionListVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private authenticationService = AppService.get(Types.Neo.Security.AuthenticationService),
        private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
        public customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
        private actionListApiClient = AppService.get(Types.ApiClients.ActionListApiClient),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        private demoClientApiClient = AppService.get(Types.ApiClients.DemoClientsApiClient),
        private actionListConfigs = AppService.get(Types.ApiClients.ActionListConfigurationsApiClient)
    ) {
        super(taskRunner);
        this.makeObservable();
    }

    public selectedTab: string = textConstants.buttonText.HotLeads
    public leadMessageVM = new LeadMessageVM()

    public LeadMessageViewModel = new LeadMessageVM();
    public showActionListView: boolean | undefined = true;
    public isArchived: boolean = false;
    public leadLookup: ActionListLookupData = new ActionListLookupData();

    public isPageAllSelected?: boolean | null = false

    public tabLeadCount: number = 0;

    public clientId: number = 0;

    public woodpeckerCompanyId: number = 0;
    public totalPages: number = 0;
    public totalResults: number = 0;
    public actionListCriteria: ActionListCriteria = new ActionListCriteria()
    public infoCardVM = new InfoCardVM();

    public clientDisplayName: string = "";

    public leadStatuses: List<ActionListLeadStatus> = new List(ActionListLeadStatus)

    public leadActivities: List<ActionListLeadActivity> = new List(ActionListLeadActivity)

    public leadTypes: List<ActionListLeadType> = new List(ActionListLeadType)

    // Full list of leads
    public leadList: List<ActionListLookupData> = new List(ActionListLookupData)

    // List of leads filtered by Tab and Paged
    public pagedLeadList: List<ActionListLookupData> = new List(ActionListLookupData)

    // Object used to contain the props relating to selections
    public actionListBulkCommand: ActionListBulkCommand = new ActionListBulkCommand()

    public actionListSummaryLookup = new ActionListSummaryLookup()
    public hotLeadTabUnseenTotal: number = 0

    public warmLeadTabUnseenTotal: number = 0

    public coldLeadTabUnseenTotal: number = 0
    public currentMessageIndex: number = 0
    public isMessageIndexSet: boolean = true
    public actionListStatuses = this.appDataCache.actionListStatuses.get().data as List<ActionListStatus>;
    public selectedReplies: string[] = []
    public isSelectAll: boolean | null = false
    public isUnarchive: boolean = false
    public isLeadLoading: boolean = false
    private isDemoClient: boolean = false

    public actionListFilterStatuses: List<ActionListFilter> = new List(ActionListFilter);
    private actionListArchivedFilters: string[] = [];
    public selectedActionListStatusId: number | null = null;

    public searchFieldVM = new SearchFieldVM();
    public actionListConfigurationsList = new List(ActionListConfiguration)

    public actionListLeadType: ActionListTypes = ActionListTypes.HotLead
    public isInitialLoad: boolean = true

    public isLoading = true

    public async initialise() {
        this.actionListCriteria.clientId = this.clientId
        await this.populateStatusesList()
        await this.toggleTab(this.selectedTab, true)
        await this.getIsDemoClient(this.clientId);
        await this.getActionListConfigurations();
    }

    public async getActionListConfigurations() {
        let response = await this.actionListConfigs.get();

        if (response.status === HTTPResponseCodes.Ok) {
            this.actionListConfigurationsList.set(response.data)
        }
    }

    public async getIsDemoClient(clientId: number) {
        var response = await this.demoClientApiClient.isDemoClient(clientId)
        this.isDemoClient = response.data;
    }

    public getCurrentLead() {
        return this.pagedLeadList[this.currentMessageIndex]
    }

    public async archiveOrUnarchiveLeads() {
        let currentLead = this.getCurrentLead()
        this.selectedReplies.push(currentLead.reply_id);
        await this.archiveOrUnarchiveSelectedLeads(true, true);
    }

    public async populateStatusesList() {
        if (this.actionListStatuses.length < 1) {
            this.actionListStatuses = this.appDataCache.actionListStatuses.get().data as List<ActionListStatus>;
        }

        this.actionListStatuses.forEach(status => {
            status.displayMember = `${status.dot} ${status.statusName}`
        });
    }

    public async getActionList(isPostArchive: boolean = false) {
        this.isLoading = true
        await this.taskRunner.run(async () => {
            var response = await this.actionListApiClient.getActionList(this.actionListCriteria);
            if (response.data.success) {
                if (!this.isDemoClient) {
                    await this.getActionListStatuses()
                    await this.getActionListSummary()
                }
                this.pagedLeadList = new List(ActionListLookupData)
                this.pagedLeadList.set(response.data.data.data);
                this.totalResults = response.data.data.meta.total_data
                this.totalPages = response.data.data.meta.total_pages
                this.isSelectAll = false
                if (isPostArchive) {
                    this.selectedReplies = []
                }

                this.markSelectedItems();

            } else {
                this.notifications.addDanger(textConstants.titleText.Error, response.data.message)
            }
        })
        this.isLoading = false
    }

    private markSelectedItems() {
        if (this.selectedReplies.length > 0) {
            this.pagedLeadList.forEach(lead => {
                var selectedLead = this.selectedReplies.find(reply => reply === lead.reply_id);
                if (selectedLead) {
                    lead.isSelected = true;
                }
            });
        }
    }

    public async getActionListStatuses() {
        if (this.actionListCriteria.isArchived) {
            const response = await this.actionListApiClient.getActionListArchivedStatuses(this.clientId);
            this.actionListArchivedFilters = [...response.data.data]
        }
    }

    public async getActionListSummary() {
        const response = await this.actionListApiClient.getActionListSummary(this.clientId);
        this.actionListSummaryLookup.set(response.data.data)

        // Sets the Options for the filter
        this.setFilterOptions(this.actionListSummaryLookup, this.actionListCriteria);
    }

    private setFilterOptions(actionListSummary: ActionListSummaryLookup, criteria: ActionListCriteria) {

        this.actionListFilterStatuses = new List(ActionListFilter);

        if (!this.actionListCriteria.isArchived) {

            var typeStatuses: string[] = []

            // Sets the type Status to the current lead type
            switch (criteria.leadType) {
                case textConstants.ActionListText.LeadTypes.hot:
                    typeStatuses = [...actionListSummary.hotStatuses]
                    break;

                case textConstants.ActionListText.LeadTypes.warm:
                    typeStatuses = [...actionListSummary.warmStatuses]
                    break;

                case textConstants.ActionListText.LeadTypes.cold:
                    typeStatuses = [...actionListSummary.coldStatuses]
                    break;
            }

            // Starts at Id 1 for the filter.
            typeStatuses.forEach((status, id) => {
                var actionListStatusItem = new ActionListFilter()
                actionListStatusItem.actionListStatus = status;
                actionListStatusItem.actionListStatusId = id + 1;
                this.actionListFilterStatuses.push(actionListStatusItem)
            })
        }
        else {
            this.actionListArchivedFilters.forEach((status, id) => {
                var actionListStatusItem = new ActionListFilter()
                actionListStatusItem.actionListStatus = status;
                actionListStatusItem.actionListStatusId = id + 1;
                this.actionListFilterStatuses.push(actionListStatusItem)
            })
        }
    }

    public async getFilteredActionListLeads() {
        // Sets the selectedStatus to the currently selected status.
        this.actionListCriteria.selectedStatus = this.actionListFilterStatuses
            .find(x => x.actionListStatusId === this.selectedActionListStatusId)?.actionListStatus!

        // Gets the data again.
        await this.getActionList()
    }

    public async changePageSize(pageSize: string) {
        this.actionListCriteria.resultsPerPage = parseInt(pageSize)
        this.actionListCriteria.pageNumber = 1
        await this.getActionList()
    }

    public hideLeadMessageSideMenu() {
        this.customAuthService.globalProps.showActionListLeadMessageSideMenu = false
    }

    public showLeadMessageSideMenu() {
        this.customAuthService.globalProps.showActionListLeadMessageSideMenu = true
    }

    public setCurrentMessageIndex(index: number) {
        this.currentMessageIndex = index
    }

    public async archiveOrUnarchiveSelectedLeads(isArchive: boolean, isMessageScreenArchive: boolean = false) {
        this.isLeadLoading = true
        var response = await this.actionListApiClient.archiveOrUnarchiveSelectedLeads(this.clientId, isArchive, this.selectedReplies);

        if (!response.data.success) {
            this.notifications.addDanger(textConstants.titleText.Error, response.data.message)
            return
        }

        if (!isMessageScreenArchive) {
            // Archive from list view
            this.actionListCriteria.pageNumber = 1
            this.archiveItemsResetFilter();
            await this.getActionList(true)
        }

        // Archive from message view
        else {
            this.archiveItemsResetFilter()
            await this.archiveViaMessageView()
        }
        this.isLeadLoading = false
    }

    private async archiveViaMessageView() {
        if (await this.isColdestLead()) {
            // Go to last lead on previous page
            if (this.pagedLeadList.length === 1 && this.totalPages !== 1 && this.totalPages === this.actionListCriteria.pageNumber) {
                this.actionListCriteria.pageNumber--
                await this.getActionList(true)
                this.currentMessageIndex = this.pagedLeadList.length - 1
                await this.updateCurrentReply();
            }

            // Go to Warmer Lead 
            else if (this.pagedLeadList.length === 1 && await this.doesWarmerLeadExist()) {
                await this.navigateToWarmerLeadType();
                this.selectedReplies = []
            }

            // Go back to list view
            else if (this.pagedLeadList.length === 1 && this.totalPages === 1) {
                this.showActionListView = true;
                this.hideLeadMessageSideMenu();
                await this.getActionList(true)
            }

            // Go to previous lead on current page
            else {
                await this.getActionList(true)
                await this.showPreviousMessage()
            }
        }
        // Go to next lead
        else {
            // Go from bottom position of current page
            if (await this.isLastItemOnPage()) {
                await this.showNextMessage(false, true)
                this.selectedReplies = []
                return
            }

            // Go from non-bottom position of current page
            await this.getActionList(true)
            await this.showNextMessage(true)
        }
    }

    private archiveItemsResetFilter() {
        if (this.pagedLeadList.length === this.totalResults) {
            this.resetFilters();
        }
    }

    private async isLastItemOnPage() {
        return this.currentMessageIndex === this.pagedLeadList.length - 1
    }

    private async doesWarmerLeadExist() {
        switch (this.actionListCriteria.leadType) {
            case textConstants.ActionListText.LeadTypes.hot:
                return false

            case textConstants.ActionListText.LeadTypes.warm:
                return this.actionListSummaryLookup.hotTotal > 0

            case textConstants.ActionListText.LeadTypes.cold:
                return this.actionListSummaryLookup.warmTotal > 0 || this.actionListSummaryLookup.hotTotal > 0

            default:
                return false
        }
    }

    private async isColdestLead() {
        switch (this.actionListCriteria.leadType) {
            case textConstants.ActionListText.LeadTypes.hot:
                return await this.isLastItemOnPage() &&
                    this.actionListCriteria.pageNumber === this.totalPages &&
                    this.actionListSummaryLookup.warmTotal === 0 &&
                    this.actionListSummaryLookup.coldTotal === 0
                break;

            case textConstants.ActionListText.LeadTypes.warm:
                return await this.isLastItemOnPage() &&
                    this.actionListCriteria.pageNumber === this.totalPages &&
                    this.actionListSummaryLookup.coldTotal === 0
                break;

            case textConstants.ActionListText.LeadTypes.cold:
                return await this.isLastItemOnPage() &&
                    this.actionListCriteria.pageNumber === this.totalPages
                break;

            default:
                return false
        }
    }

    private async isReplyAlreadySeen() {
        return this.pagedLeadList[this.currentMessageIndex].read
    }

    private async updateCurrentReply() {
        await this.updateLeadMessage();
        await this.reduceSeenCountIfNecessary();
        await this.notifyClusteringAppOfSeenReply();
    }

    public async showPreviousMessage() {
        this.isLeadLoading = true
        // Move to the previous item in the list
        if (this.currentMessageIndex > 0) {
            this.currentMessageIndex--
            await this.updateCurrentReply();
        }

        // Move to the last item on the previous page
        else if (this.currentMessageIndex === 0 && this.actionListCriteria.pageNumber > 1) {
            this.actionListCriteria.pageNumber--
            await this.getActionList()
            this.currentMessageIndex = this.actionListCriteria.resultsPerPage - 1
            await this.updateCurrentReply()
        }

        // Move to the last item on the previous, warmer lead type
        else {
            this.resetFilters();
            await this.navigateToWarmerLeadType();
        }
        this.isLeadLoading = false
    }

    public async showNextMessage(isCurrentIndexReductionRequired: boolean = false, stayOnCurrentPage: boolean = false) {
        this.isLeadLoading = true

        if (isCurrentIndexReductionRequired) { this.currentMessageIndex-- }

        // Move to the next item in the list
        if (this.currentMessageIndex < this.pagedLeadList.length - 1) {
            this.currentMessageIndex++
            await this.updateCurrentReply();
        }

        // Move to the first item on the next page
        else if (await this.isLastItemOnPage() && this.actionListCriteria.pageNumber !== this.totalPages) {
            if (!stayOnCurrentPage) {
                this.actionListCriteria.pageNumber++
                this.currentMessageIndex = -1
                await this.getActionList();
                this.currentMessageIndex = 0
                await this.updateCurrentReply()
            } else {
                var indexToGoTo = this.currentMessageIndex
                this.currentMessageIndex = -1
                await this.getActionList();
                this.currentMessageIndex = indexToGoTo
                await this.updateCurrentReply()
            }
        }

        // Move to the first item on the next, colder lead type
        else {
            this.resetFilters();
            await this.navigateToColderLeadType();
        }
        this.isLeadLoading = false
    }

    public async notifyClusteringAppOfSeenReply() {
        if (!this.pagedLeadList[this.currentMessageIndex].read) {
            await this.LeadMessageViewModel.setReplyAsSeen();
            this.pagedLeadList[this.currentMessageIndex].read = true;
        }
    }

    private async navigateToColderLeadType(isPostArchive: boolean = false) {
        switch (this.actionListCriteria.leadType) {
            case textConstants.ActionListText.LeadTypes.hot:
                if (this.actionListSummaryLookup.warmTotal > 0) {
                    // Setup navigation to go to Warm Leads
                    this.actionListCriteria.leadType = textConstants.ActionListText.LeadTypes.warm;
                    this.selectedTab = textConstants.buttonText.WarmLeads;

                } else if (this.actionListSummaryLookup.warmTotal === 0 && this.actionListSummaryLookup.coldTotal > 0) {
                    // Setup navigation to go to Cold Leads
                    this.actionListCriteria.leadType = textConstants.ActionListText.LeadTypes.cold;
                    this.selectedTab = textConstants.buttonText.ColdLeads;

                } else {
                    this.showPreviousMessage()
                    return
                }

                await this.fetchNextListAndReply(isPostArchive);
                break;

            case textConstants.ActionListText.LeadTypes.warm:
                if (this.actionListSummaryLookup.coldTotal > 0) {
                    // Setup navigation to go to Cold Leads
                    this.actionListCriteria.leadType = textConstants.ActionListText.LeadTypes.cold;
                    this.selectedTab = textConstants.buttonText.ColdLeads;
                    await this.fetchNextListAndReply(isPostArchive);
                } else {
                    this.showPreviousMessage()
                    return
                }
                break;

            case textConstants.ActionListText.LeadTypes.cold:
                // Do nothing, you can't go forward anymore
                break;

            default:
                break;
        }
    }

    private async navigateToWarmerLeadType() {
        switch (this.actionListCriteria.leadType) {
            case textConstants.ActionListText.LeadTypes.hot:
                // Do nothing. We're already at the first item
                break;

            case textConstants.ActionListText.LeadTypes.warm:
                this.actionListCriteria.leadType = textConstants.ActionListText.LeadTypes.hot;
                this.selectedTab = textConstants.buttonText.HotLeads;
                await this.fetchPreviousListAndReply();
                break;

            case textConstants.ActionListText.LeadTypes.cold:
                // Setup nav to go to Warm Leads
                if (this.actionListSummaryLookup.warmTotal > 0) {
                    this.actionListCriteria.leadType = textConstants.ActionListText.LeadTypes.warm;
                    this.selectedTab = textConstants.buttonText.WarmLeads;
                    await this.fetchPreviousListAndReply();
                }

                // Setup nav to go to Hot Leads
                else if (this.actionListSummaryLookup.hotTotal > 0) {
                    this.actionListCriteria.leadType = textConstants.ActionListText.LeadTypes.hot;
                    this.selectedTab = textConstants.buttonText.HotLeads;
                    await this.fetchPreviousListAndReply();
                }

                break;

            default:
                break;
        }
    }

    // Get list for the newly selected lead type
    private async fetchPreviousListAndReply() {
        this.currentMessageIndex = -1
        await this.getActionList();

        // Now that we know how many pages exist, we can fetch the final page and the final item in that page
        this.actionListCriteria.pageNumber = this.totalPages

        // I wanted to call getActionList() again, but it refused to run that code. I don't know why
        var response = await this.actionListApiClient.getActionList(this.actionListCriteria);
        if (response.data.success) {
            this.pagedLeadList.set(response.data.data.data);
            this.totalResults = response.data.data.meta.total_data
            this.totalPages = response.data.data.meta.total_pages
        }

        this.currentMessageIndex = this.pagedLeadList.length - 1
        await this.updateCurrentReply()
    }

    private async fetchNextListAndReply(isPostArchive: boolean = false) {
        this.actionListCriteria.pageNumber = 1
        this.currentMessageIndex = -1
        await this.getActionList(isPostArchive);
        this.currentMessageIndex = 0
        await this.updateCurrentReply()
    }

    private async updateLeadMessage() {
        this.LeadMessageViewModel.selectedLeadType = this.actionListCriteria.leadType
        this.LeadMessageViewModel.prospectId = this.pagedLeadList[this.currentMessageIndex].prospect_id;
        this.LeadMessageViewModel.campaignId = this.pagedLeadList[this.currentMessageIndex].campaign_id;
        this.LeadMessageViewModel.currentReplyId = this.pagedLeadList[this.currentMessageIndex].reply_id
        await this.LeadMessageViewModel.getReply();
    }

    public async updateLeadMessageType(leadType: string) {
        this.actionListCriteria.searchString = "";
        switch (leadType) {
            case textConstants.ActionListText.LeadTypes.hot:
                this.actionListCriteria.leadType = leadType
                this.selectedTab = textConstants.buttonText.HotLeads
                await this.fetchNextListAndReply()
                break;

            case textConstants.ActionListText.LeadTypes.warm:
                this.actionListCriteria.leadType = leadType
                this.selectedTab = textConstants.buttonText.WarmLeads
                await this.fetchNextListAndReply()
                break;

            case textConstants.ActionListText.LeadTypes.cold:
                this.actionListCriteria.leadType = leadType
                this.selectedTab = textConstants.buttonText.ColdLeads
                await this.fetchNextListAndReply()
                break;

            default:
                break;
        }
    }

    public async reduceSeenCountIfNecessary() {
        switch (this.actionListCriteria.leadType) {
            case textConstants.ActionListText.LeadTypes.hot:
                if (!await this.isReplyAlreadySeen()) {
                    this.actionListSummaryLookup.newHotTotal--
                }
                break;

            case textConstants.ActionListText.LeadTypes.warm:
                if (!await this.isReplyAlreadySeen()) {
                    this.actionListSummaryLookup.newWarmTotal--
                }
                break;

            case textConstants.ActionListText.LeadTypes.cold:
                if (!await this.isReplyAlreadySeen()) {
                    this.actionListSummaryLookup.newColdTotal--
                }
                break;
        }
    }

    public isFirstRecord() {
        switch (this.actionListCriteria.leadType) {
            case textConstants.ActionListText.LeadTypes.hot:
                return this.currentMessageIndex === 0 && this.actionListCriteria.pageNumber === 1

            case textConstants.ActionListText.LeadTypes.warm:
                return this.currentMessageIndex === 0 && this.actionListSummaryLookup.hotTotal === 0 && this.actionListCriteria.pageNumber === 1

            case textConstants.ActionListText.LeadTypes.cold:
                return this.currentMessageIndex === 0 && this.actionListSummaryLookup.hotTotal === 0 &&
                    this.actionListSummaryLookup.warmTotal === 0 &&
                    this.actionListCriteria.pageNumber === 1
        }

        return false
    }

    public isLastRecord() {
        switch (this.actionListCriteria.leadType) {
            case textConstants.ActionListText.LeadTypes.hot:
                return this.currentMessageIndex === this.pagedLeadList.length - 1 &&
                    this.actionListCriteria.pageNumber === this.totalPages &&
                    this.actionListSummaryLookup.warmTotal === 0 &&
                    this.actionListSummaryLookup.coldTotal === 0

            case textConstants.ActionListText.LeadTypes.warm:
                return this.currentMessageIndex === this.pagedLeadList.length - 1 &&
                    this.actionListCriteria.pageNumber === this.totalPages &&
                    this.actionListSummaryLookup.coldTotal === 0

            case textConstants.ActionListText.LeadTypes.cold:
                return this.currentMessageIndex === this.pagedLeadList.length - 1 &&
                    this.actionListCriteria.pageNumber === this.totalPages
        }

        return false
    }

    public hasClickedSelectAll() {
        this.isSelectAll = true
        this.selectAllToggle(true)
    }

    public resetBulkControlProperties() {
        this.selectAllToggle(false)
        this.selectedReplies = []
        this.isSelectAll = false
    }

    private selectAllToggle(allSelected: boolean) {
        this.pagedLeadList.forEach(item => {
            item.isSelected = allSelected

            if (allSelected && !this.selectedReplies.includes(item.reply_id)) {
                this.selectedReplies.push(item.reply_id)
            } else if (!allSelected) {
                this.selectedReplies.remove(item.reply_id)
            }
        })
    }

    public hasClickedSelectAllPage() {
        var itemsState = false

        if (this.isSelectAll) {
            itemsState = true
        }
        else {
            // Need this because this state can be both null or false for the select all
            itemsState = false
            this.selectedReplies = []
        }

        this.pagedLeadList.forEach((item) => {
            item.isSelected = itemsState

            this.updateItemInTabList(item)
        })
    }

    private updateItemInTabList(item: ActionListLookupData) {
        let currentItem = this.pagedLeadList.find(f => f.reply_id === item.reply_id)

        if (currentItem) {
            currentItem.isSelected = item.isSelected

            if (currentItem.isSelected && !this.selectedReplies.includes(currentItem.reply_id)) {
                this.selectedReplies.push(currentItem.reply_id)
            } else if (!currentItem.isSelected) {
                this.selectedReplies.remove(currentItem.reply_id)
            }
        }
    }

    public hasToggledItemSelect(item: ActionListLookupData) {
        this.updateItemInTabList(item)

        // PAGE SELECT ALL INDICATOR
        this.setTristateIndictator(true)

        // PAGE SELECT ALL INDICATOR
        this.setTristateIndictator(false)
    }

    public async setTristateIndictator(isPageCheck: boolean) {
        let count = isPageCheck
            ? this.pagedLeadList.length
            : this.pagedLeadList.length

        let selectedCount = isPageCheck
            ? this.pagedLeadList.filter(f => f.isSelected).length
            : this.pagedLeadList.filter(f => f.isSelected).length


        if (isPageCheck) {
            if (count === selectedCount) {
                this.isSelectAll = true
            }
            else if (selectedCount === 0) {
                this.isSelectAll = false
            }
            else {
                this.isSelectAll = null
            }
        }
        else {
            if (count === selectedCount) {
                this.actionListBulkCommand.isSelectAll = true
            }
            else {
                this.actionListBulkCommand.isSelectAll = false
            }

        }
    }

    public async getClientId() {
        const { user, isAuthenticated } = this.authenticationService;

        // Fetch the platformSetup and successTracker urls
        if (isAuthenticated) {
            const response = await this.clientsApiClient.getDashboardInfo(user!.userName);
            this.clientId = response.data.clientId;
            this.woodpeckerCompanyId = response.data.woodpeckerCompanyId
        }

        this.clientId = this.clientId === 0 ? this.customAuthService.globalProps.clientId : this.clientId
    }

    public async toggleTab(tabName: string, initialise: boolean = false) {
        // if the tab hasnt changed, dont run this again (may adversely affect the archived, also inefficient)
        if (this.selectedTab === tabName && !initialise) {
            return
        }
        this.selectedTab = tabName
        this.actionListCriteria.pageNumber = 1
        this.resetFilters();

        switch (tabName) {
            case textConstants.buttonText.HotLeads:
                this.actionListCriteria.isArchived = false
                this.actionListCriteria.leadType = textConstants.ActionListText.LeadTypes.hot
                this.actionListLeadType = ActionListTypes.HotLead
                break

            case textConstants.buttonText.WarmLeads:
                this.actionListCriteria.isArchived = false
                this.actionListCriteria.leadType = textConstants.ActionListText.LeadTypes.warm
                this.actionListLeadType = ActionListTypes.WarmLead
                break

            case textConstants.buttonText.ColdLeads:
                this.actionListCriteria.isArchived = false
                this.actionListCriteria.leadType = textConstants.ActionListText.LeadTypes.cold
                this.actionListLeadType = ActionListTypes.ColdLead
                break

            case textConstants.buttonText.Archive:
                this.actionListCriteria.leadType = ""
                this.actionListCriteria.isArchived = true
                break
        }

        let actionListCriteria = sessionStorage.getItem(textConstants.LocalStorage.ActionListCriteria)

        // Checks if there is anything in storage
        if (actionListCriteria) {
            this.getStoredActionListCriteria(actionListCriteria, this.actionListCriteria)
            this.selectedTab = this.leadTypeToSelectedTab(this.actionListCriteria.leadType)
        }

        await this.getActionList()
    }

    // Converts the leadType to selectedTab
    private leadTypeToSelectedTab(leadType: string | null) {
        let selectedTab: string = ""

        switch (leadType) {
            case textConstants.ActionListText.LeadTypes.hot:
                selectedTab = textConstants.buttonText.HotLeads
                break

            case textConstants.ActionListText.LeadTypes.warm:
                selectedTab = textConstants.buttonText.WarmLeads
                break

            case textConstants.ActionListText.LeadTypes.cold:
                selectedTab = textConstants.buttonText.ColdLeads
                break

            default:
                selectedTab = textConstants.buttonText.Archive
                break;
        }

        return selectedTab;
    }

    public async getClientName(clientId: number) {
        const response = await this.taskRunner.waitFor(this.clientsApiClient.getClient(clientId));
        return response.data.clientName;
    }

    public resetFilters() {
        this.actionListCriteria.searchString = "";
        this.selectedActionListStatusId = null;
        this.actionListCriteria.selectedStatus = null;
    }

    // Converts the stored action List criteria to ActionListCriteria
    public getStoredActionListCriteria(storedCriteria: string, actionListCriteria: ActionListCriteria) {
        let storedObject = JSON.parse(storedCriteria);

        actionListCriteria.isArchived = storedObject.isArchived
        actionListCriteria.resultsPerPage = storedObject.resultsPerPage
        actionListCriteria.leadType = storedObject.leadType
        actionListCriteria.searchString = storedObject.searchString
        actionListCriteria.selectedStatus = storedObject.selectedStatus
        actionListCriteria.pageNumber = storedObject.pageNumber
        this.currentMessageIndex = storedObject.currentMessageIndex
    }

    // Generates a simplified version of the actionListCriteria and converts it to json string
    public generateCriteriaToStore(criteria: ActionListCriteria) {
        let actionListCriteria = {
            isArchived: criteria.isArchived,
            resultsPerPage: criteria.resultsPerPage,
            leadType: criteria.leadType,
            searchString: criteria.searchString,
            selectedStatus: criteria.selectedStatus,
            currentMessageIndex: this.currentMessageIndex,
            pageNumber: criteria.pageNumber
        }

        return JSON.stringify(actionListCriteria)
    }

    public setSessionStorage() {
        sessionStorage.setItem(textConstants.LocalStorage.ActionListCriteria, this.generateCriteriaToStore(this.actionListCriteria));
    }
}