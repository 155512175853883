import { textConstants } from '../../../common/textConstants';
import { ModelBase, NeoModel, Rules, Attributes } from '@singularsystems/neo-core';

@NeoModel
export default class EditTargetMarketAccountCommand extends ModelBase {
  public targetMarketId: number = 0;

  public fixedName: string = ""

  public domain: string = "";

  @Rules.Required()
  @Attributes.Display(textConstants.titleText.Status)
  public accountStatusId: number = 0;

  public originalName: string = ""

  public priority : boolean = false

  public targetMarketAccountId: number = 0

  public masterAccountId: number = 0

  public clientAccountId: number = 0
}