import { Attributes, NeoModel, Validation, ValueObject } from "@singularsystems/neo-core";
import { textConstants } from "common/textConstants";

@NeoModel
export default class LeadType extends ValueObject
{
    public leadTypeId: number = 0;
    
    @Attributes.Display("Lead Type Name")
    public leadTypeName: string = ""

    public uniqueTableKey: string = ""

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.leadTypeName === null || c.leadTypeName === '', textConstants.messageText.validationMessage.statusMustBeSpecified);
    }

    public toString(): string {
        if (this.isNew || !this.leadTypeName) {
            return "New Lead Type";
        } else {
            return this.leadTypeName;
        }
    }
}