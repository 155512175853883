import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import ExcludedKeyword from '../Models/Maintenance/ExcludedKeyword';
import { AppService, Types } from '../Services/AppService';

export interface IExcludedKeywordsApiClient extends Data.IUpdateableApiClient<ExcludedKeyword, number> {

}

@injectable()
export default class ExcludedKeywordsApiClient extends Data.UpdateableApiClient<ExcludedKeyword, number> implements IExcludedKeywordsApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/excludedKeywords`);
    }
}