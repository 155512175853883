/* tslint:disable:max-classes-per-file */
import React from 'react';
import { NavLink } from 'react-router-dom';
import routes from '../Routes';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import AppLayout, { ScreenSize, MenuState } from './AppLayout';
import Scrollbar from 'react-custom-scrollbars';
import { Routing } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import * as Roles from '../Models/Security/Roles';
import { IMenuRoute } from '@singularsystems/neo-core/dist/Routing';
import UploadNotificationLookup from '../Models/UploadNotificationLookup';
import { textConstants as tc } from '../common/textConstants';
import SideBarIcons from './SideBarIcons';
import * as Icon from 'react-feather';
import { isMiniNav, isMobile } from '../common/utils';
import { triggerHotjarEvent } from './Hotjar';

@observer
class Sidebar extends React.Component {

    private menuContainer: React.RefObject<HTMLDivElement>;
    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);
    public uploadNotificationLookup = new UploadNotificationLookup()
    protected authService = AppService.get(Types.Security.CustomAuthenticationService)
    constructor(props: any) {
        super(props);
        this.menuContainer = React.createRef<HTMLDivElement>();
    }

    public componentDidUpdate() {
        if (this.authService.globalProps.showBurgerIcon) {
            // Position the menu relative to the menu anchor
            if (this.menuContainer.current) {
                this.menuContainer.current.style.left = "";
            }
        }
    }

    private hideMainMenu() {
        AppLayout.current.menuVisible = true;
    }

    private isClientUser() {

        // Check if the user has all roles assigned to them. 
        // If they do, they are an admin user.
        let roleCount = 0;

        const roles = [Roles.Clients.Manage, Roles.Dashboard.Access, Roles.ActionList.Access, Roles.Maintenance.Manage, Roles.MasterData.Manage, Roles.Security.Access, Roles.Security.Update, Roles.Users.Manage,
        Roles.TargetMarkets.AddEditTargetMarkets, Roles.TargetMarkets.Clone, Roles.TargetMarkets.Manage, Roles.TargetMarkets.Prospecting, Roles.TargetMarkets.TargetMarketAccounts,
        Roles.TargetMarkets.UpdateTargetMarkets];

        roles.forEach(element => {
            if (this.authorisationService.hasRole(element)) {
                roleCount += 1;
            }
        });

        if (roleCount === roles.length) {
            return false;
        }

        if (this.authorisationService.hasRole(Roles.Dashboard.Access)) {
            return true;
        }

        return false;
    }

    public render() {
        const layout = AppLayout.current;

        return (
            <div ref={this.menuContainer}
                className={"app-left-panel" + (!isMiniNav() ? " show" : "" + isMobile() ? ' mobile' : '')}>
                {/* onMouseLeave={this.hideMainMenu} */}

                <div className="sidebar">
                    {!isMobile() && <div id="menu-anchor" className={"sidebar-toggle " + (!isMiniNav() ? "closed" : "open")} onClick={layout.showMenu.bind(layout)}>
                        <a href="#"><i>{(!isMiniNav()
                            ? <Icon.ChevronsLeft /> : <Icon.ChevronsRight />)}</i></a>
                    </div>}

                    <div className="sidebar-content">
                        {/* Full screen menu */}
                        {layout.currentScreenSize === ScreenSize.Huge &&
                            <Scrollbar height="100%">
                                <Menu items={routes.menuRoutes} level={1} isClientUser={this.isClientUser()} />
                            </Scrollbar>}

                        {/* Small screen menu */}
                        {layout.currentScreenSize !== ScreenSize.Huge &&
                            <Menu items={routes.menuRoutes} level={1} isClientUser={this.isClientUser()} />}
                    </div>
                </div>
            </div>
        );
    }
}

interface IMenuItemProps {
    item: IMenuRoute,
    level: number,
    isClientUser: boolean
};

@observer
class Menu extends React.Component<{ items: Routing.IMenuRoute[], level: number, isClientUser: boolean }> {

    private authService = AppService.get(Types.Neo.Security.AuthenticationService);

    public renderMenuItem(item: Routing.IMenuRoute) {
        if (this.props.isClientUser === false && (item.name === "Dashboard" || item.name === "Campaigns" || item.name === "Action List")) {
            return "";
        }
        if (this.props.isClientUser && (item.name === "Clients" || item.name === "Administration")) {
            return "";
        }
        return <MenuItem key={item.path || item.name} item={item} level={this.props.level} isClientUser={this.props.isClientUser} />
    }

    public render() {
        const menuItemProvider = AppService.get(Types.Neo.Routing.MenuItemProvider);
        const menuItems = menuItemProvider.processRoutes(this.props.items, { collapseSingle: false, hideWhenEmpty: true });

        return (
            <React.Fragment>
                {
                    this.authService.isAuthenticated &&
                    <ul className={"menu-level-" + this.props.level}>
                        {menuItems.map(item => this.renderMenuItem(item))}
                    </ul>
                }
            </React.Fragment>
        )
    }
}

@observer
class MenuItem extends React.Component<IMenuItemProps> {

    @observable.ref
    public isExpanded = false;

    public hideChildren: boolean = this.props.item.name === 'Master Accounts' ||
        this.props.item.name === 'Maintenance' ||
        this.props.item.name === 'Clients'
        ? true : false;

    protected authService = AppService.get(Types.Security.CustomAuthenticationService)

    constructor(props: IMenuItemProps) {
        super(props);

        makeObservable(this);

        if (props.item.children && props.item.expanded) {
            this.isExpanded = true;
        }

        this.onClick = this.onClick.bind(this);
    }

    private onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {

        if (this.props.item.children && !this.hideChildren) {
            e.stopPropagation();
            this.isExpanded = !this.isExpanded;
        }

        if (!this.props.item.children && !this.hideChildren && [ScreenSize.ExtraSmall, ScreenSize.Small].includes(AppLayout.current.currentScreenSize)) {
            AppLayout.current.showMenu(e);
        }

        if (this.props.item.path === tc.links.ComXHub) {
            triggerHotjarEvent(tc.Events.clientOpenHub)
        }

        let event = document.createEvent("HTMLEvents");
        event.initEvent("clearSearchCriteria", true, true);
        event['eventName'] = "clearSearchCriteria";
        document.dispatchEvent(event);

        this.isDashboardCheck(e)
    }

    private isDashboardCheck(e: any) {
        if (e.target.innerText && e.target.innerText === tc.Routes.Dashboard
            || e.target.innerText === tc.Routes.CampaignEmailOverview
            || e.target.innerText === tc.Routes.ActionList
            || e.target.innerText === tc.Routes.LeadMessage) {
        }
    }

    public render() {
        const item = this.props.item;
        const hasChildren = !this.hideChildren && !!item.children;

        var showSeparator = false;
        if (this.props.item.name === tc.Routes.KnowledgeCenter) { showSeparator = true; }

        return (
            <li>
                <div onClick={this.onClick} className={"menu-item" + (hasChildren ? " has-children" : "")}>
                    {showSeparator &&
                        <div className={isMiniNav() ? 'mini-menu-separator' : 'menu-separator'}></div>
                    }

                    <div className={isMiniNav() ? "sidebarIconsMini" : 'sidebarIcons'}>
                        {(item.path && !item.path.includes("http")) ?
                            <NavLink to={item.path}>{item.icon && <i data-tip={AppLayout.current.menuVisible ? null : item.name} />}<SideBarIcons iconName={item.icon} /><div className="responsive-hidden sidebar-menu-item-padding">{item.name}</div></NavLink>
                            :
                            <a href={item.path} target={"_blank"} rel="noreferrer">
                                {item.icon && <i data-tip={AppLayout.current.menuVisible ? null : item.name} />} 
                                <SideBarIcons iconName={item.icon} />
                                <div className="responsive-hidden sidebar-menu-item-padding">{item.name}</div>
                            </a>
                        }

                        {hasChildren &&
                            <div className={(isMiniNav() ? "mini-menu-expander " : "menu-expander ") + (this.isExpanded ? "expanded" : "collapsed")}>
                                <i className={"fa fa-" + (this.isExpanded ? "caret-down" : "caret-right")} />
                            </div>
                        }
                    </div>
                </div>

                {this.isExpanded && item.children && <Menu items={item.children} level={this.props.level + 1} isClientUser={this.props.isClientUser} />}
            </li>)
    }
}

export default Sidebar;