import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService, Types } from './AppService';

export enum LifeTime {
    Short = 30,
    Long = 240
}

@injectable()
export class AppDataService extends Data.CachedDataService {

    constructor(private apiClient = AppService.get(Types.ApiClients.ComXApiClient)) {
        super();
    }

    // Register cached data here:
    public industries = this.register(this.apiClient.industries.get, LifeTime.Short);
    public subIndustries = this.register(this.apiClient.subIndustries.get, LifeTime.Short);
    public countries = this.register(this.apiClient.countries.get, LifeTime.Short);
    public followUpTemplateCategories = this.register(this.apiClient.followUpTemplateCategories.get, LifeTime.Short);
    public targetMarketTypes = this.register(this.apiClient.targetMarketTypes.get, LifeTime.Short);
    public targetMarketStatuses = this.register(this.apiClient.targetMarketStatuses.get,LifeTime.Short);
    public roles = this.register(this.apiClient.roles.get,LifeTime.Short);
    public languages = this.register(this.apiClient.languages.get,LifeTime.Short);
    public iroEditStatuses = this.register(this.apiClient.iroEditStatuses.get,LifeTime.Short);
    public excludedKeywords = this.register(this.apiClient.excludedKeywords.get,LifeTime.Short);
    public employeeSizes = this.register(this.apiClient.employeeSizes.get,LifeTime.Short);
    public accountStatuses = this.register(this.apiClient.accountStatuses.get,LifeTime.Short);
    public targetMarketAccountStatuses = this.register(this.apiClient.targetMarketAccountStatuses.get,LifeTime.Short);
    public subRegions = this.register(this.apiClient.subRegions.get,LifeTime.Short);
    public regions = this.register(this.apiClient.regions.get,LifeTime.Short);
    public platforms = this.register(this.apiClient.platforms.get, LifeTime.Short);
    public targetMarketAccountTabs = this.register(this.apiClient.targetMarketAccountTabs.get,LifeTime.Short);
    public comXConfigurations = this.register(this.apiClient.comXConfigurations.get,LifeTime.Short);
    public dashboardReportConfigs = this.register(this.apiClient.dashboardReportConfigs.get,LifeTime.Short);
    public backgroundJobTypeConfigs = this.register(this.apiClient.backgroundJobTypeConfigs.get,LifeTime.Short);
    public informationManagement = this.register(this.apiClient.informationManagement.get,LifeTime.Short);    
    public campaignMessageStatuses = this.register(this.apiClient.campaignMessageStatuses.get,LifeTime.Short);
    public campaignMessageTypes = this.register(this.apiClient.campaignMessageTypes.get,LifeTime.Short);
    public customerProfileStatuses = this.register(this.apiClient.customerProfileStatuses.get,LifeTime.Short);
    public technicalSettingsConfig = this.register(this.apiClient.technicalSettingsConfig.get,LifeTime.Short);
    public emailProviderTypes = this.register(this.apiClient.emailProviderTypes.get,LifeTime.Short);
    public customerProfileQuestions = this.register(this.apiClient.customerProfileQuestions.get,LifeTime.Short);
    public calendlyLinks = this.register(this.apiClient.calendlyLinks.get, LifeTime.Short);
    public dashboardCards = this.register(this.apiClient.dashboardCards.get, LifeTime.Short);
    public dashboardTooltips = this.register(this.apiClient.dashboardTooltips.get, LifeTime.Short);
    public dashboardTooltipType = this.register(this.apiClient.dashboardTooltipType.get, LifeTime.Short);
    public invalidReasons = this.register(this.apiClient.invalidReasons.get, LifeTime.Short);
    public onboardingMaintenance = this.register(this.apiClient.onboardingMaintenance.get, LifeTime.Short);
    public onboardingSteps = this.register(this.apiClient.onboardingSteps.get, LifeTime.Short);
    public leadTypes = this.register(this.apiClient.leadTypes.get, LifeTime.Short);
    public leadTypeStatuses = this.register(this.apiClient.leadTypeStatuses.get, LifeTime.Long);
    public actionListStatuses = this.register(this.apiClient.actionListStatuses.get, LifeTime.Long);
    public navigationSteps = this.register(this.apiClient.navigationSteps.get, LifeTime.Long);
    public industriesVersions = this.register(this.apiClient.industriesVersions.get, LifeTime.Long);
}