import { Attributes, ModelBase, NeoModel } from "@singularsystems/neo-core";
import OnboardingStepIntroduction from 'Models/Maintenance/OnboardingStepIntroduction';

@NeoModel
export default class ClientOnboardingStepIntroduction extends ModelBase {

    public clientOnboardingStepIntroductionId: number = 0;

    public isComplete: boolean = false;

    public onboardingStepIntroduction: OnboardingStepIntroduction = new OnboardingStepIntroduction();
}