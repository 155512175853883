import React from 'react';
import HtmlParser from 'react-html-parser';

interface ITextAsParagraphsProps {
  paragraphArray?: string[]
}

export const TextAsParagraphs = (props: ITextAsParagraphsProps) => {
  return (<div className="row p-0 m-0">
    {props.paragraphArray!.map(paragraph =>
      <div key={paragraph} className="col-12 p-0">
        <p className="fontSize16">
          {HtmlParser(paragraph)}
        </p>
      </div>)}
  </div>
  )
}

export default TextAsParagraphs