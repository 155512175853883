import { Data, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import ToggleCustomerProfileArchive from "../../Models/IdealCustomerProfiles/Commands/ToggleCustomerProfileArchive";
import CustomerProfileCriteria from "../../Models/IdealCustomerProfiles/CustomerProfileCriteria";
import CustomerProfileLookup from "../../Models/IdealCustomerProfiles/CustomerProfileLookup";
import CloneICPCommand from "../../Models/IdealCustomerProfiles/Commands/CloneICPCommand";
import { AppService, Types } from "../../Services/AppService";
import CustomerProfileQuestionnaireVM from "./CustomerProfileQuestionnaireVM";
import CustomerProfilesVM from "./CustomerProfilesVM";

@NeoModel
export default class CustomerProfilesExternalVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private customerProfilesApiClient = AppService.get(Types.ApiClients.CustomerProfilesApiClient),
    private authenticationService = AppService.get(Types.Neo.Security.AuthenticationService),
    private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
    public customAuthService = AppService.get(Types.Security.CustomAuthenticationService)
  ) {
    super(taskRunner)
    this.customAuthService.globalProps.isOnboarding = false;
  }

  public async initialise() {

  }

  public isComXUser: boolean = false;
  public isArchived: boolean = false;
  public searchCriteria: CustomerProfileCriteria = new CustomerProfileCriteria();
  public hideGrid: boolean = false;
  public toggleArchiveCommand: ToggleCustomerProfileArchive = new ToggleCustomerProfileArchive();
  public clientId: number = 0;
  public clientName: string = "";
  public customerProfileQuestionnaireViewModel: CustomerProfileQuestionnaireVM = new CustomerProfileQuestionnaireVM();
  public customerProfilesViewModel: CustomerProfilesVM = new CustomerProfilesVM();
  public showICPQuestionnaire: boolean = false;
  public customerProfileId: number = 0;
  public showCloneModal: boolean = false;
  public cloneICPCommand = new CloneICPCommand();

  // Pagination
  public pageManager = new Data.PageManager(this.searchCriteria, CustomerProfileLookup, this.customerProfilesApiClient.getCustomerProfiles,
    {
      pageSize: 20,
      fetchInitial: false
    }
  )

  public async getClientId() {
    const { user, isAuthenticated } = this.authenticationService;

    // Fetch the platformSetup and successTracker urls
    if (isAuthenticated) {
      const response = await this.clientsApiClient.getDashboardInfo(user!.userName);
      this.searchCriteria.clientId = response.data.clientId;
    }
  }

  public async getClientName(clientId: number) {
    const response = await this.taskRunner.waitFor(this.clientsApiClient.getClient(clientId));
    this.clientName = response.data.clientName;
  }

}