import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class OnboardingStepIntroduction extends ModelBase {

    public onboardingStepIntroductionId : number = 0;

    public onboardingStepId: number = 0;
    
    public headerText: string = "";

    public bodyText: string = "";

    public aboutText: string = "";

    public transcriptText: string = "";

    public videoURL: string = "";

    public url: string = "";

    public urlTitle: string = "";

    public ordinal: number = 0;

    public isMultiStep: boolean = false;

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.onboardingStepId === null, textConstants.messageText.validationMessage.description);
    }

    public toString(): string {
        if (this.isNew || !this.headerText) {
            return "Header text";
        } else {
            return this.headerText;
        }
    }
}