import { injectable } from 'inversify';
import Axios, { AxiosPromise } from 'axios';

import Types from '../../AuthorisationTypes';
import { Misc, Model } from '@singularsystems/neo-core';
import { UserGroupRolesLookup } from '../../Models/UserRoleLookup';
import { AppService, Types as ServiceTypes } from '../../../Services/AppService';
import ChangePasswordModel from '../../../Models/Security/ChangePasswordModel';
import CommandResult from '../../../Models/InvitedUsers/Commands/CommandResult';

export interface IUsersApiClient {
    /**
     * Will get a list of users
     */
    getLookupAsync(): AxiosPromise<any>;

    /**
     * Will get a list of user roles
     */
    getRoleLookupAsync(userId: string): AxiosPromise<Array<Model.PlainObject<UserGroupRolesLookup>>>;

    /**
     * Gets the user's list of memberships
     */
    getCurrentUserRoles(): AxiosPromise<Array<Model.PlainObject<UserGroupRolesLookup>>>;

    /**
     * Change the current user's password 
     */
    setChangePassword(data: ChangePasswordModel): AxiosPromise<CommandResult>;
}

@injectable()
export default class UsersApiClient implements IUsersApiClient {

    private apiPath: string;

    constructor(
        config = Misc.Globals.appService.get(Types.Config.ConfigModel), 
        private identityConfig = AppService.get(ServiceTypes.Config)) {
        this.apiPath = `${config.authorisationServerApiPath}/Users`;
    }

    public getLookupAsync() {
        return Axios.get(`${this.apiPath}/Lookup/`);
    }
   
    public getRoleLookupAsync(userId: string): AxiosPromise<Array<Model.PlainObject<UserGroupRolesLookup>>> {
        return Axios.get(`${this.apiPath}/Roles/${userId}`);
    }

    public getCurrentUserRoles(): AxiosPromise<Array<Model.PlainObject<UserGroupRolesLookup>>> {
        return Axios.get(`${this.apiPath}/currentUserRoles`);
    }

    public setChangePassword(data: ChangePasswordModel): AxiosPromise<CommandResult> {
        const identityPath = this.identityConfig.identityServer.url;

        return Axios.post(`${identityPath}/api/account/change-password`, data.toJSObject());
    }
}
