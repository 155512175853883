import { List, ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';
import DomainVariation from './DomainVariation';
import Region from './Region';

@NeoModel
export default class Country extends ModelBase {
    public countryId: number = 0;
    
    @Rules.StringLength(500)
    @Attributes.Display("Country")
    public countryName: string = "";

    public languageId: number | null = null

    public countryCode: string = "";

    public regions = new List(Region)

    public domainVariations = new List(DomainVariation)

    isExpanded: boolean = false

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.countryName === null || c.countryName === '', textConstants.messageText.validationMessage.countryName);
    }

    public toString(): string {
        if (this.isNew || !this.countryName) {
            return "New country";
        } else {
            return this.countryName;
        }
    }
}