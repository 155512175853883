import { Views, NeoGrid, Neo } from '@singularsystems/neo-react';
import ClientVM from './ClientVM';
import { observer } from 'mobx-react';
import { textConstants } from './../../common/textConstants';
import ClientLookup from './../../Models/Client/ClientLookup';
import TargetMarketView from '../TargetMarket/TargetMarketView';
import UpdateTargetMarketsView from '../TargetMarket/UpdateTargetMarketsView';
import AddEditTargetMarketView from '../TargetMarket/AddEditTargetMarketView';
import TargetMarketAccountsView from './../TargetMarketAccounts/TargetMarketAccountsView';
import AddEditTargetMarketVM from '../TargetMarket/AddEditTargetMarketVM';
import ProspectingView from '../Prospecting/ProspectingView';
import ProspectingVM from './../Prospecting/ProspectingVM';
import TargetMarketVM from '../TargetMarket/TargetMarketVM';
import UpdateTargetMarketsVM from '../TargetMarket/UpdateTargetMarketsVM';
import TargetMarketAccountsVM from '../TargetMarketAccounts/TargetMarketAccountsVM';
import AddClientListView from '../TargetMarketAccounts/AddClientListView';
import AddClientListVM from '../TargetMarketAccounts/AddClientListVM';
import { AppService, Types } from "../../Services/AppService";
import * as Roles from '../../Models/Security/Roles'
import ClientSettingsView from './ClientSettingsView';
import ClientSettingsVM from './ClientSettingsVM';
import CustomPagingControl from '../../Components/CustomPagingControl';
import AddClientVM from './AddClientVM';
import SearchField from '../../Components/SearchField';
import AddClientView from './AddClientView';
import ClientCampaignMessageVM from '../CampaignMessages/ClientCampaignMessageVM';
import ClientCampaignMessageView from '../CampaignMessages/ClientCampaignMessageView';
import CampaignEmailSignatureVM from '../CampaignMessages/CampaignEmailSignatureVM';
import CampaignEmailSignatureView from '../CampaignMessages/CampaignEmailSignatureView';
import CampaignMessageView from '../CampaignMessages/CampaignMessageView';
import CampaignMessageVM from '../CampaignMessages/CampaignMessageVM';
import React from 'react';
import BatchReviewProspectsVM from '../BatchReview/BatchReviewProspectsVM';
import BatchReviewProspectsView from '../BatchReview/BatchReviewProspectsView';
import CustomerProfilesVM from '../IdealCustomerProfiles/CustomerProfilesVM';
import CustomerProfilesView from '../IdealCustomerProfiles/CustomerProfilesView';
import CustomerProfileQuestionnaireVM from '../IdealCustomerProfiles/CustomerProfileQuestionnaireVM';
import CustomerProfileQuestionnaireView from '../IdealCustomerProfiles/CustomerProfileQuestionnaireView';
import { Navigation } from '../../Models/Enums/ApplicationEnums';
import * as Icon from 'react-feather';
import AddEditFollowUpTemplateView from '../CampaignMessages/AddEditFollowUpTemplateView';
import AddEditFollowUpTemplateVM from '../CampaignMessages/AddEditFollowUpTemplateVM';
import InfoBox from '../../Components/InfoBox';
import DashboardReports from '../../Components/DashboardComponents/DashboardReports';
import DashboardVM from '../Home/DashboardVM';
import ClientCampaignWoodpeckerEmailTable from '../../Components/DashboardComponents/ClientCampaignWoodpeckerEmailTable';
import ActionListVM from 'Views/ActionList/ActionListVM';
import ActionListScreen from 'Views/ActionList/ActionListScreen';
import LeadMessageVM from 'Views/ActionList/LeadMessageVM';
import { StyledButton } from 'Components/Button/styles';
import Button from 'Components/Button';

class ClientViewParams {
	public clientId = {};
	public clientMode = {}; // TargetMarkets , UpdateTargetMarkets , AddTargetMarket , EditClient, AddClient
	public id = {};
	public secondaryMode = {}; // Target Market Accounts , EditTargetMarket , ProspectTargetMarket, AddClientList, LeadMessage
	public campaignId = {};
	public replyId = {};
}

@observer
export default class ClientView extends Views.ViewBase<ClientVM, ClientViewParams> {

	static params = new ClientViewParams();

	private showPageChangeWarning: boolean = false

	private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

	private customAuthService = AppService.get(Types.Security.CustomAuthenticationService)

	constructor(props: unknown) {
		super("Clients", ClientVM, props);
		document.addEventListener('clearSearchCriteria', () => {
			this.viewModel.clientCriteria.clientName = "";
			this.viewModel.savedSearchCriteria = undefined;
		});

		this.customAuthService.globalProps.isOnboarding = false
	}

	onLeave() {
		this.viewModel.removeNotifications();
		return undefined;
	}

	componentDidUpdate() {
		if (this.viewParams.clientMode.value === "AddTargetMarket" && this.viewModel.AddEditTargetMarketViewModel.targetMarket.isDirty) {
			this.viewModel.showWarningMessage = true;
			this.showPageChangeWarning = true;
		}
		else if (this.viewParams.secondaryMode!.value === "EditTargetMarket" && this.viewModel.AddEditTargetMarketViewModel.targetMarket.isDirty) {
			this.viewModel.showWarningMessage = true;
			this.showPageChangeWarning = true;
		}
		else {
			this.viewModel.showWarningMessage = false;
			this.showPageChangeWarning = false;
		}
	}

	public clearClientInformation() {
		this.viewModel.clientId = null;
		this.viewParams.clientId.value = null;
		this.viewModel.targetMarketId = null;
		this.viewModel.TargetMarketWasPrevious = false;
		this.viewModel.CampaignMessageWasPrevious = false;
	}

	public clearTargetMarketInfo() {
		this.viewModel.targetMarketId = null;
		this.viewModel.targetMarketName = "";
		this.viewParams.id.value = null;
		this.viewParams.secondaryMode.value = null;
	}

	public clearCampaignMessageInfo() {
		this.viewModel.campaignMessageName = "";
		this.viewModel.campaignMessageType = "";
		this.viewParams.id.value = null
	}

	public clearAddFollowUpTemplateInfo() {
		this.viewModel.followUpTemplateName = "";
		this.viewParams.id.value = null
	}

	public updateClientBreadcrumb(description: string, description1: string) {
		this.viewModel.navigationList.pop()
		this.viewParams.clientMode.description = description + " " + description1;
	}

	private updateBreadcrumbFromLeadMessageView() {
		let currentItem = this.viewModel.ActionListViewModel.getCurrentLead()
		let leadNameObject = currentItem.prospect_name;
		let leadName = `${leadNameObject?.first_name} ${leadNameObject?.last_name}`;

		this.setLeadViewParams(currentItem.prospect_id, currentItem.campaign_id, currentItem.reply_id)
		this.updateBreadcrumb(textConstants.titleText.ActionList, leadName, false)
	}

	public updateBreadcrumb(description: string, description1: string, isActionList: boolean) {
		if (isActionList) {
			this.viewParams.secondaryMode.description = "";
			this.viewParams.clientId.description = description;
			this.viewParams.clientMode.description = description1;
		}
		else {
			this.viewParams.clientMode.description = description;
			this.viewParams.secondaryMode.description = description1;
		}
	}

	private async archiveCurrentLead() {
		await this.viewModel.ActionListViewModel.archiveOrUnarchiveLeads();

		if (!this.viewModel.ActionListViewModel.showActionListView) {
			this.updateBreadcrumbFromLeadMessageView()
		}
		else {
			this.viewParams.setValues({ clientMode: textConstants.titleText.ActionListView }, true)
		}
	}

	private resetViewParams() {
		this.viewParams.setValues({ clientMode: textConstants.titleText.ActionListView }, true);
		this.viewParams.id.reset();
	}

	private setLeadViewParams(prospectId: number, campaignId: number, replyId: string) {
		this.viewParams.setValues({
			secondaryMode: textConstants.titleText.LeadMessageView,
			id: prospectId,
			campaignId: campaignId,
			replyId: replyId
		}, true);
	}

	public updateTargetMarketBreadcrumb(description: string, description1: string) {
		this.viewParams.secondaryMode.description = description1;
	}

	public securityCheck(role: any, openMethod: () => void) {
		if (this.authorisationService.hasRole(role)) {
			openMethod();
		}
		else {
			this.viewModel.showForbiddenModal = true;
		}
	}

	public openAddClient(mode: string) {
		this.viewParams.clientId.value = mode
	}

	public batchReviewRedirection() {
		if (this.viewModel.batchReviewUploadId === null || this.viewModel.batchReviewUploadId === 0) {
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const data = urlParams.get('brid')

			if (data !== null) {
				this.viewModel.batchReviewUploadId = parseInt(data)
			}
		}
	}

	private clientCampaignMessageBack() {
		this.viewModel.ClientCampaignMessageViewModel.tabManager.selectedTab = textConstants.titleText.CampaignMessage // Ensures that CM tab is selected when re-entering
		this.viewModel.isComingFromFollowUpTemplates = false
		this.viewModel.ClientCampaignMessageViewModel.isComingFromFollowUpTemplates = false
		let navigationString = this.viewModel.navigate(this.viewModel.ClientCampaignMessageViewModel.clientNavigationButtons.navigateTo);

		if (navigationString === null) {
			this.viewParams.clientId.value = null;
		}
		else {
			this.viewParams.clientMode.value = navigationString;
		}
	}

	public async viewParamsUpdated() {
		const clientId = this.viewParams.clientId.asNullableInt();
		const id = this.viewParams.id.asNullableInt(); //This Id could represent either TMId, CustomerProfileId, or CampaignMessageId (Based on URL)

		// necessary to get the user to the correct tab
		// if the browser back button was used
		// check navigation list for BatchReviewProspects
		if (this.viewModel.navigationList[this.viewModel.navigationList.length - 1] === textConstants.generalText.BatchReviewProspects) {
			this.viewModel.showBatchReviewTab = true;
			this.viewModel.navigationList.pop();
		}

		//Check for upload notifications
		this.viewModel.checkUserNotifications();

		if (clientId) {
			this.customAuthService.globalProps.clientId = 0; // This refers to the user's clientId. Only set in reporting mode
			this.viewModel.clientId = clientId;

			const clientName = await this.viewModel.getClientName(clientId);
			this.viewModel.setClientDisplayName(clientName);

			// Determine the ICP mode and see if we need to redirect the user to the ICP list screen... 
			// (if they have been logged out and come from a redirect screen).
			var clientMode = this.viewParams.clientMode.value as string

			if (this.viewModel.currentICPMode === "" && clientMode !== null && clientMode.includes("ICP") && !id) {
				this.viewParams.clientMode.value = textConstants.titleText.IdealCustomerProfile
			}

			// Settings (Edit Client)
			if (this.viewParams.clientMode.value === "EditClient" && (this.viewParams.secondaryMode.value === "" || this.viewParams.secondaryMode!.value === null)) {

				this.viewModel.ClientSettingsViewModel = new ClientSettingsVM();
				this.viewModel.ClientSettingsViewModel.isComXUser = true
				this.viewModel.ClientSettingsViewModel.taskRunner = this.viewModel.taskRunner;
				this.viewModel.ClientSettingsViewModel.clientId = clientId
				this.updateClientBreadcrumb(clientName, textConstants.BreadCrumbText.ClientSettings);
				await this.viewModel.ClientSettingsViewModel.fetchClient(clientId)

				if (this.viewModel.showBatchReviewTab) {
					this.viewModel.ClientSettingsViewModel.tabManager.selectedTab = textConstants.titleText.BatchReviewSpaced
					this.viewModel.showBatchReviewTab = false
				}
			}

			// Target Markets
			else if (this.viewParams.clientMode.value === "TargetMarkets" && (this.viewParams.secondaryMode.value === "" || this.viewParams.secondaryMode!.value === null)) {

				// Initialize ViewModel
				this.viewModel.TargetMarketViewViewModel = new TargetMarketVM();
				this.viewModel.TargetMarketViewViewModel.taskRunner = this.viewModel.taskRunner;

				// Set values & fetch data
				await this.viewModel.TargetMarketViewViewModel.fetchData(clientId);
				this.viewModel.TargetMarketWasPrevious = true;
				this.updateClientBreadcrumb(`${this.viewModel.TargetMarketViewViewModel.client.clientName}`, textConstants.BreadCrumbText.TargetMarkets);
				//Check if search criteria has been saved
				if (this.viewModel.savedSearchCriteria) {
					this.viewModel.TargetMarketViewViewModel.hideGrid = true;
					this.viewModel.TargetMarketViewViewModel.searchCriteria.searchString = this.viewModel.savedSearchCriteria.searchString;

					this.viewModel.TargetMarketViewViewModel.pageManager.refreshData();
					this.viewModel.TargetMarketViewViewModel.hideGrid = false;
				}
			}

			// Update Target Markets
			else if (this.viewParams.clientMode.value === "UpdateTargetMarkets") {

				// Initialize ViewModel
				this.viewModel.UpdateTargetMarketsViewModel = new UpdateTargetMarketsVM();
				this.viewModel.UpdateTargetMarketsViewModel.taskRunner = this.viewModel.taskRunner;

				// Set values & fetch data
				this.viewModel.UpdateTargetMarketsViewModel.clientId = clientId;
				await this.viewModel.UpdateTargetMarketsViewModel.fetchClient();
				this.updateClientBreadcrumb(`${this.viewModel.UpdateTargetMarketsViewModel.client.clientName}`, textConstants.BreadCrumbText.UpdateTargetMarkets);

				//Save target market search
				this.viewModel.savedSearchCriteria = this.viewModel.TargetMarketViewViewModel.searchCriteria;
			}

			// Add Target Market
			else if (this.viewParams.clientMode.value === "AddTargetMarket") {

				// Initialize ViewModel
				this.viewModel.AddEditTargetMarketViewModel = new AddEditTargetMarketVM();
				this.viewModel.AddEditTargetMarketViewModel.taskRunner = this.viewModel.taskRunner;

				// Set values & fetch data
				this.viewModel.AddEditTargetMarketViewModel.pageTitle = textConstants.titleText.SpecifyTargetMarket;
				await this.viewModel.AddEditTargetMarketViewModel.initialise();
				this.viewModel.AddEditTargetMarketViewModel.clientId = clientId;

				//Save target market search
				this.viewModel.savedSearchCriteria = this.viewModel.TargetMarketViewViewModel.searchCriteria;
				this.updateClientBreadcrumb(`${clientName}`, textConstants.BreadCrumbText.AddTargetMarket);

			}

			// Client Campaign Messages
			else if (this.viewParams.clientMode.value === textConstants.titleText.ClientCampaignMessagesValue) {
				this.viewParams.id.value = null
				// Initialize ViewModel
				this.viewModel.ClientCampaignMessageViewModel = new ClientCampaignMessageVM();
				this.viewModel.ClientCampaignMessageViewModel.taskRunner = this.viewModel.taskRunner;
				this.viewModel.ClientCampaignMessageViewModel.clientId = this.viewModel.clientId;
				this.viewModel.ClientCampaignMessageViewModel.isComingFromFollowUpTemplates = this.viewModel.isComingFromFollowUpTemplates

				// Set values & fetch data
				this.viewModel.ClientCampaignMessageViewModel.previousGridPageNumber = this.viewModel.previousClientCampaignMessageGridPage
				await this.viewModel.ClientCampaignMessageViewModel.fetchData(clientId);

				if (this.viewModel.isComingFromFollowUpTemplates) {
					this.viewModel.ClientCampaignMessageViewModel.isComingFromFollowUpTemplates = false
				}

				this.viewModel.CampaignMessageWasPrevious = true;
				this.updateClientBreadcrumb(`${clientName}`, textConstants.BreadCrumbText.CampaignMessages);
				//Save target market search
				this.viewModel.savedSearchCriteria = this.viewModel.TargetMarketViewViewModel.searchCriteria;
			}

			// Add Email Signature
			else if (this.viewParams.clientMode.value === "AddEmailSignature") {
				this.viewParams.id.value = null
				// Initialize ViewModel
				this.viewModel.CampaignEmailSignatureViewModel = new CampaignEmailSignatureVM();

				this.viewModel.CampaignEmailSignatureViewModel.campaignEmailSignatureHelper.taskRunner = this.viewModel.taskRunner;
				// Set values & fetch data
				this.viewModel.CampaignEmailSignatureViewModel.clientId = clientId;
				this.viewModel.CampaignEmailSignatureViewModel.campaignEmailSignatureHelper.clientId = clientId;
				await this.viewModel.CampaignEmailSignatureViewModel.initialise();
				this.updateClientBreadcrumb(`${clientName}`, textConstants.BreadCrumbText.AddEmailSignature);

			}

			// Edit Email Signature
			else if (this.viewParams.clientMode.value === "EditEmailSignature") {

				this.viewModel.previousClientCampaignMessageGridPage = this.viewModel.ClientCampaignMessageViewModel.pageManager.pageNo

				// Initialize ViewModel
				this.viewModel.CampaignEmailSignatureViewModel = new CampaignEmailSignatureVM();
				this.viewModel.CampaignEmailSignatureViewModel.campaignEmailSignatureHelper.taskRunner = this.viewModel.taskRunner;

				// Set values & fetch data
				this.viewModel.CampaignEmailSignatureViewModel.clientId = clientId;
				this.viewModel.CampaignEmailSignatureViewModel.campaignEmailSignatureHelper.clientId = clientId;
				this.viewModel.CampaignEmailSignatureViewModel.clientCampaignMessageId = this.viewModel.clientCampaignMessageId === 0 ? this.viewModel.clientCampaignMessageId : (id ?? 0)
				this.viewModel.CampaignEmailSignatureViewModel.clientCampaignMessageStatusId = this.viewModel.clientCampaignMessageStatusId
				await this.viewModel.CampaignEmailSignatureViewModel.initialise();
				this.updateClientBreadcrumb(`${clientName}`, textConstants.BreadCrumbText.EditEmailSignature);
			}

			// Add Campaign Message
			else if (this.viewParams.clientMode.value === "AddCampaignMessage") {

				// Initialize ViewModel
				this.viewModel.CampaignMessageViewModel = new CampaignMessageVM();

				this.viewModel.CampaignMessageViewModel.taskRunner = this.viewModel.taskRunner;
				// Set values & fetch data
				this.viewModel.CampaignMessageViewModel.isComXUser = true;
				this.viewModel.CampaignMessageViewModel.clientId = clientId;
				await this.viewModel.CampaignMessageViewModel.initialise();

				this.updateClientBreadcrumb(`${clientName}`, textConstants.BreadCrumbText.AddCampaignMessage);

			}

			// Edit Campaign Message
			else if (this.viewParams.clientMode.value === "EditCampaignMessage") {

				this.viewModel.previousClientCampaignMessageGridPage = this.viewModel.ClientCampaignMessageViewModel.pageManager.pageNo

				// Initialize ViewModel
				this.viewModel.CampaignMessageViewModel = new CampaignMessageVM();
				this.viewModel.CampaignMessageViewModel.taskRunner = this.viewModel.taskRunner;

				// Set values & fetch data
				this.viewModel.CampaignMessageViewModel.clientId = clientId;
				this.viewModel.CampaignMessageViewModel.clientCampaignMessageId = this.viewModel.clientCampaignMessageId ? this.viewModel.clientCampaignMessageId : (id ?? 0)
				this.viewModel.CampaignMessageViewModel.isComXUser = true;

				await this.viewModel.CampaignMessageViewModel.initialise();
				this.updateClientBreadcrumb(`${clientName}`, textConstants.BreadCrumbText.EditCampaignMessage);
			}

			//Add follow up template
			else if (this.viewParams.clientMode.value === "AddFollowUpTemplate") {
				this.viewModel.isComingFromFollowUpTemplates = true;

				// Initialize ViewModel
				this.viewModel.AddFollowUpTemplateViewModel = new AddEditFollowUpTemplateVM();

				this.viewModel.AddFollowUpTemplateViewModel.taskRunner = this.viewModel.taskRunner;
				// Set values & fetch data
				this.viewModel.AddFollowUpTemplateViewModel.isComXUser = true;
				this.viewModel.AddFollowUpTemplateViewModel.clientId = clientId;
				await this.viewModel.AddFollowUpTemplateViewModel.initialise();

				this.viewModel.ClientCampaignMessageViewModel.followUpTemplatesVM.populateActionListStatues();
				this.viewModel.AddFollowUpTemplateViewModel.actionListStatuses = this.viewModel.ClientCampaignMessageViewModel.followUpTemplatesVM.filterList

				this.updateClientBreadcrumb(`${clientName}`, textConstants.BreadCrumbText.AddFollowUpTemplate);
			}

			// Edit Follow Up Template
			else if (this.viewParams.clientMode.value === "EditFollowUpTemplate") {

				this.viewModel.isComingFromFollowUpTemplates = true;

				// Initialize ViewModel
				this.viewModel.AddFollowUpTemplateViewModel = new AddEditFollowUpTemplateVM();
				this.viewModel.AddFollowUpTemplateViewModel.taskRunner = this.viewModel.taskRunner;

				// Set values & fetch data
				this.viewModel.AddFollowUpTemplateViewModel.clientId = clientId;
				this.viewModel.AddFollowUpTemplateViewModel.clientFollowUpTemplateId = this.viewModel.clientFollowUpTemplateId ? this.viewModel.clientFollowUpTemplateId : (id ?? 0)
				this.viewModel.AddFollowUpTemplateViewModel.isComXUser = true;

				await this.viewModel.AddFollowUpTemplateViewModel.initialise();

				this.viewModel.ClientCampaignMessageViewModel.followUpTemplatesVM.populateActionListStatues();
				this.viewModel.AddFollowUpTemplateViewModel.actionListStatuses = this.viewModel.ClientCampaignMessageViewModel.followUpTemplatesVM.filterList;

				this.updateClientBreadcrumb(`${clientName}`, textConstants.BreadCrumbText.EditFollowUpTemplate);
			}

			// Batch Review Prospects
			else if (this.viewParams.clientMode.value === textConstants.generalText.BatchReviewProspects) {
				// this.viewModel.showBatchReview = false;

				// Will set the correct BatchReviewId based on the query params found in the URL
				// If nothing, normal process will resume
				this.batchReviewRedirection();

				this.viewModel.BatchReviewProspectsViewModel = new BatchReviewProspectsVM();
				this.viewModel.BatchReviewProspectsViewModel.taskRunner = this.viewModel.taskRunner
				this.viewModel.BatchReviewProspectsViewModel.isComXUser = true;

				this.viewModel.BatchReviewProspectsViewModel.criteria.batchReviewUploadId = this.viewModel.batchReviewUploadId;
				this.viewModel.BatchReviewProspectsViewModel.criteria.clientId = clientId;

				this.viewModel.BatchReviewProspectsViewModel.criteria.isOnEntry = true;
				this.viewModel.BatchReviewProspectsViewModel.clientId = clientId;

				await this.viewModel.BatchReviewProspectsViewModel.fetchData(clientId)

				this.updateClientBreadcrumb(clientName, textConstants.generalText.BatchReview);

				this.viewModel.navigationList.push(textConstants.generalText.BatchReviewProspects);

				this.viewModel.showBatchReviewProspects = true;
			}

			// Ideal Customer Profiles 
			else if (this.viewParams.clientMode.value === textConstants.titleText.IdealCustomerProfile) {
				this.viewModel.showICPQuestionnaire = false;
				this.viewModel.CustomerProfilesViewModel = new CustomerProfilesVM();

				this.viewModel.CustomerProfilesViewModel.taskRunner = this.viewModel.taskRunner;
				this.viewModel.CustomerProfilesViewModel.clientName = this.viewModel.clientDisplayName

				this.viewModel.CustomerProfilesViewModel.searchCriteria.clientId = this.viewModel.clientId;
				this.viewModel.CustomerProfilesViewModel.initialise()

				await this.viewModel.CustomerProfilesViewModel.getVideoURLs();
				this.viewModel.CustomerProfilesViewModel.setNoICPVideo();

				await this.viewModel.CustomerProfilesViewModel.pageManagerRefresh()

				this.updateClientBreadcrumb(clientName, textConstants.titleText.IdealCustomerProfile);
				this.viewModel.setClientDisplayName(clientName);

				this.viewModel.CustomerProfilesViewModel.isComXUser = true
				this.viewModel.showIdealCustomerProfile = true;
			}

			// Add/Edit Profile
			else if (this.viewParams.clientMode.value === "ICPQuestionnaire") {
				this.viewModel.showIdealCustomerProfile = false;
				const clientId = this.viewModel.clientId;

				this.viewModel.CustomerProfileQuestionnaireViewModel = new CustomerProfileQuestionnaireVM();
				this.viewModel.CustomerProfileQuestionnaireViewModel.taskRunner = this.viewModel.taskRunner;
				this.viewModel.CustomerProfileQuestionnaireViewModel.numberOfEmployees = this.viewModel.CustomerProfilesViewModel.numberOfEmployees
				this.viewModel.CustomerProfileQuestionnaireViewModel.productWorth = this.viewModel.CustomerProfilesViewModel.productWorth

				if (this.viewModel.currentICPMode === "Add") {
					this.viewModel.CustomerProfileQuestionnaireViewModel.isEdit = false;
				} else {
					this.viewModel.CustomerProfileQuestionnaireViewModel.isEdit = true;
				}

				this.viewModel.CustomerProfileQuestionnaireViewModel.isComXUser = true;
				this.viewModel.CustomerProfileQuestionnaireViewModel.clientId = clientId
				this.updateClientBreadcrumb(clientName, textConstants.BreadCrumbText.ICPQuestionnaire);
				this.viewModel.CustomerProfileQuestionnaireViewModel.clientId = clientId;
				this.viewModel.CustomerProfileQuestionnaireViewModel.initialise()

				if ((this.viewModel.customerProfileId !== null && this.viewModel.customerProfileId > 0) || id) {
					let customerProfileId = id ?? 0;

					this.viewModel.CustomerProfileQuestionnaireViewModel.customerProfileId = this.viewModel.customerProfileId ? this.viewModel.customerProfileId : customerProfileId
				}

				if (this.viewModel.customerProfileId > 0) {
					// Edit Profile
					this.updateClientBreadcrumb(clientName, textConstants.generalText.EditProfileSpaced);
				} else {
					// Add Profile
					this.updateClientBreadcrumb(clientName, textConstants.generalText.AddProfileSpaced);
				}

				this.viewModel.CustomerProfileQuestionnaireViewModel.fetchData();

				this.viewModel.showICPQuestionnaire = true;
			}
			else if (this.viewParams.clientMode.value === textConstants.titleText.ClientReportView) {
				this.updateClientBreadcrumb(clientName, textConstants.BreadCrumbText.Dashboard);
				let previousScreen = this.viewModel.navigationList.at(this.viewModel.navigationList.length - 2) ?? "ClientView"
				this.viewModel.DashboardViewModel = new DashboardVM();

				// for pagination of client campaigns
				this.viewModel.DashboardViewModel.clientCampaignDataCriteria.clientId = clientId;
				this.viewModel.DashboardViewModel.clientCampaignDataCriteria.isForced = false;
				this.viewModel.DashboardViewModel.clientCampaignDataCriteria.isRetrieveAll = false;
				this.viewModel.DashboardViewModel.taskRunner = this.viewModel.taskRunner;
				this.viewModel.DashboardViewModel.clientId = clientId;
				this.customAuthService.globalProps.clientId = clientId;

				await this.viewModel.taskRunner.run(async () => {
					await this.viewModel.DashboardViewModel.getActionListSummary();
					await this.viewModel.DashboardViewModel.setClientCampaignData();
					await this.viewModel.DashboardViewModel.getDefaultRefreshTime();
					await this.viewModel.DashboardViewModel.getReportsChartSectionData();
					await this.viewModel.DashboardViewModel.getActionListConfigurations();
					await this.viewModel.DashboardViewModel.pageManager.refreshData();
				})

				if (id && id > 0) {
					this.viewModel.DashboardViewModel.clientCampaignId = id
				}
			}
			else if (this.viewParams.clientMode.value === textConstants.titleText.ActionListView) {

				let clientId = this.viewParams.clientId.value as number

				if (this.viewModel.actionListInitialized
					|| this.viewModel.ActionListViewModel.clientId !== clientId
					|| this.viewModel.ActionListSelectedTab !== this.viewModel.ActionListCurrentSelectedTab) {

					this.viewModel.ActionListViewModel = new ActionListVM();

					this.viewModel.ActionListViewModel.selectedTab = this.viewModel.ActionListSelectedTab
					this.viewModel.ActionListViewModel.clientId = clientId;

					await this.taskRunner.run(async () => {
						await this.viewModel.ActionListViewModel.initialise()
					})

					this.viewModel.ActionListViewModel.taskRunner = this.taskRunner

					this.viewModel.shouldInitializeActionList = false;
					this.viewModel.ActionListCurrentSelectedTab = this.viewModel.ActionListSelectedTab
				}

				const actionListViewModel = this.viewModel.ActionListViewModel

				if (this.viewParams.secondaryMode.value === textConstants.titleText.LeadMessageView) {
					let prospectId = this.viewParams.id.asNullableInt()
					let campaignId = this.viewParams.campaignId.asNullableInt()
					let replyId = this.viewParams.replyId.asString()

					if (prospectId && campaignId) {
						actionListViewModel.customAuthService.globalProps.showActionListLeadMessageSideMenu = true
						actionListViewModel.showActionListView = false;

						actionListViewModel.LeadMessageViewModel = new LeadMessageVM();
						actionListViewModel.LeadMessageViewModel.taskRunner = this.taskRunner
						actionListViewModel.LeadMessageViewModel.clientId = clientId
						actionListViewModel.LeadMessageViewModel.prospectId = prospectId
						actionListViewModel.LeadMessageViewModel.campaignId = campaignId
						actionListViewModel.LeadMessageViewModel.currentReplyId = replyId
						actionListViewModel.LeadMessageViewModel.selectedLeadType = actionListViewModel.actionListCriteria.leadType

						actionListViewModel.LeadMessageViewModel.leadLookup = actionListViewModel.currentMessageIndex >= 0 ? actionListViewModel.pagedLeadList[actionListViewModel.currentMessageIndex] : undefined;

						actionListViewModel.setSessionStorage();

						await actionListViewModel.LeadMessageViewModel.initialise()
						await actionListViewModel.reduceSeenCountIfNecessary()
						await actionListViewModel.notifyClusteringAppOfSeenReply()

						let leadNameObject = actionListViewModel.pagedLeadList.find(l => l.prospect_id === prospectId)?.prospect_name;
						let leadName = leadNameObject?.first_name + " " + leadNameObject?.last_name;
						leadName = leadName !== null ? leadName : textConstants.generalText.NoLeadName;
						this.updateBreadcrumb(textConstants.titleText.ActionList, leadName, false)
					}
				}
				else {
					actionListViewModel.hideLeadMessageSideMenu()
					actionListViewModel.showActionListView = true;

					this.updateBreadcrumb("", textConstants.titleText.ActionList, true)
				}
			}

			if (id) {
				this.viewModel.targetMarketId = id;

				// Target Market Accounts
				if (this.viewParams.secondaryMode!.value === "Target Market Accounts") {

					// Initialize ViewModel
					this.viewModel.TargetMarketAccountsViewModel = new TargetMarketAccountsVM();
					this.viewModel.TargetMarketAccountsViewModel.taskRunner = this.viewModel.taskRunner;
					this.viewModel.TargetMarketAccountsViewModel.clientId = clientId

					// Set values & fetch data;
					await this.viewModel.TargetMarketAccountsViewModel.fetchData(id);

					this.viewModel.TargetMarketAccountsViewModel.navigateToAddClientList = () => {
						this.viewParams.secondaryMode!.value = "AddClientList";
					};
					const clientName = await this.viewModel.getClientName(clientId);
					this.updateTargetMarketBreadcrumb("", `${this.viewModel.TargetMarketAccountsViewModel.targetMarketSummary.targetMarketName}`);
				}

				// Edit Target Market
				else if (this.viewParams.secondaryMode!.value === "EditTargetMarket") {

					// Initialize ViewModel
					this.viewModel.AddEditTargetMarketViewModel = new AddEditTargetMarketVM();
					this.viewModel.AddEditTargetMarketViewModel.taskRunner = this.viewModel.taskRunner;

					// Set values & fetch data
					this.viewModel.AddEditTargetMarketViewModel.isEdit = true;
					this.viewModel.AddEditTargetMarketViewModel.clientId = clientId;
					this.viewModel.AddEditTargetMarketViewModel.targetMarketId = id;
					this.viewModel.AddEditTargetMarketViewModel.pageTitle = textConstants.titleText.SpecifyTargetMarket;
					await this.viewModel.AddEditTargetMarketViewModel.initialise();

					//Save target market search
					this.viewModel.savedSearchCriteria = this.viewModel.TargetMarketViewViewModel.searchCriteria;

					this.updateTargetMarketBreadcrumb("", textConstants.BreadCrumbText.EditTargetMarket);
				}
				else if (this.viewParams.secondaryMode.value === "ProspectTargetMarket") {

					// Set values & fetch data
					await this.viewModel.ProspectingViewModel.fetchTargetMarketSummary();
					await this.viewModel.ProspectingViewModel.fetchBatchesInProgress();
					this.updateTargetMarketBreadcrumb(`${this.viewModel.ProspectingViewModel.targetMarketName}`, textConstants.BreadCrumbText.ProspectTargetMarket);

					//Save target market search
					this.viewModel.savedSearchCriteria = this.viewModel.TargetMarketViewViewModel.searchCriteria;
				}
				else if (this.viewParams.secondaryMode.value === "AddClientList") {

					// Initialize ViewModel
					this.viewModel.AddClientListViewModel = new AddClientListVM();
					this.viewModel.AddClientListViewModel.taskRunner = this.viewModel.taskRunner;

					// Set values & fetch data
					await this.viewModel.AddClientListViewModel.fetchData(id);
					const clientName = await this.viewModel.getClientName(clientId);
					this.updateTargetMarketBreadcrumb(`${this.viewModel.TargetMarketAccountsViewModel.targetMarketSummary.targetMarketName}`, textConstants.BreadCrumbText.AddClientList);
				}
			}

			if (clientId &&
				(this.viewParams.clientMode.value === "" || this.viewParams.clientMode.value === null)
			) {
				this.clearClientInformation();
			}

			if (clientId &&
				this.viewParams.clientMode.value === "TargetMarkets" &&
				(this.viewParams.secondaryMode!.value === null || this.viewParams.secondaryMode!.value === "")
			) {
				this.viewModel.TargetMarketViewViewModel.navigateToEditClient = () => {
					this.viewParams.clientId.value = clientId;
					this.viewParams.clientMode.value = "EditClient";
					this.viewModel.navigationList.push("EditClient");
				};

				this.clearTargetMarketInfo();
			} else if (clientId &&
				this.viewParams.clientMode.value === "EditClient" &&
				(this.viewParams.secondaryMode!.value === null || this.viewParams.secondaryMode!.value === "")
			) {
				this.clearTargetMarketInfo();
			}
			else if (clientId &&
				this.viewParams.clientMode.value === textConstants.titleText.ClientCampaignMessagesValue
			) {
				this.viewModel.ClientCampaignMessageViewModel.navigateToEditClient = () => {
					this.viewParams.clientId.value = clientId;
					this.viewParams.clientMode.value = "EditClient";
					// navigation path 
					this.viewModel.navigationList.push("EditClient");
				};

			}
			this.forceUpdate();
		}
		else {
			if (this.viewParams.clientId.value === "AddClient") {
				this.viewModel.AddClientViewModel = new AddClientVM();
				this.viewModel.AddClientViewModel.taskRunner = this.viewModel.taskRunner;
				this.viewModel.AddClientViewModel.initialise()
				this.updateClientBreadcrumb("Clients", textConstants.BreadCrumbText.AddClient);
			}
			else {
				this.viewModel.showBatchReviewProspects = false
				this.viewModel.showICPQuestionnaire = false;
				// so target markets doesn't alway navigate back to edit
				this.viewModel.editClientWasPrevious = false;

				this.viewModel.pageManager.refreshData();
				this.clearClientInformation();
			}
			this.forceUpdate();
		}

		// This has been done to avoid clearing the Notification from the AddEditTargetMarket
		if (
			this.viewParams.clientMode.value === "TargetMarkets" &&
			this.viewModel.clearAddEditNotifications === false
		) {
			this.viewModel.clearAddEditNotifications = true;
		}
		else {
			this.viewModel.removeNotifications();
		}
	}

	public render() {
		return (
			<>
				{this.viewModel.clientId === null && this.viewParams.clientId.value !== 'AddClient' &&
					<Neo.Card>
						<div className="ClientView">
							<div className='row mt-4 mb-0 ml-0 mr-0'>
								<div className='col-12 p-0'>
									<div className='row m-0'>

										{/* Clients / Archived Clients header */}
										<div className='col-3 p-24'>
											{this.viewModel.isArchived &&
												<h2 className='pt-0'>Archived Clients</h2>
											}
											{!this.viewModel.isArchived &&
												<h2 className='pt-0'>Clients</h2>
											}
										</div>

										{/* Search */}
										<div className='col-9 p-24'>
											<Button
												buttonType='primary'
												className="pull-right ml-4 mt-0"
												variant="hasIcon"
												size="xSmall"
												onClick={() => this.securityCheck(Roles.Clients.Manage,
													() => this.openAddClient("AddClient"))}
											>
												<Icon.Plus />
												{textConstants.buttonText.AddClient}
											</Button>

											<SearchField
												onClickFunction={() => { this.viewModel.pageManager.refreshData() }}
												searchBindableProp={this.viewModel.clientCriteria.meta.clientName}
												searchString={this.viewModel.clientCriteria.clientName}
												searchFieldVM={this.viewModel.searchFieldVM}
											/>
										</div>
									</div>

									<div className="col-12 p-0">
										<Neo.TabContainer className="mt-2 mb-2 m-0" variant="flat">
											<Neo.Tab header="Current" onDisplayed={() => {
												this.viewModel.clientCriteria.isDeleted = false;
												this.viewModel.isArchived = false;
											}}><span></span>
											</Neo.Tab>
											<Neo.Tab header="Archived" onDisplayed={() => {
												this.viewModel.clientCriteria.isDeleted = true;
												this.viewModel.isArchived = true;
											}}
											><span></span>
											</Neo.Tab>
										</Neo.TabContainer>
										{/* Tabs End*/}
									</div>

									{this.viewModel.pageManager.totalRecords === 0 &&
										this.viewModel.searchFieldVM.isSearching &&
										<InfoBox header={textConstants.titleText.NoSearchMatches}
											text={textConstants.generalText.NoSearchMatches} />
									}

									{/* Clients grid */}
									{this.viewModel.pageManager.totalRecords !== 0 && !this.viewModel.hideGrid &&
										<React.Fragment>
											<Neo.Pager pageManager={this.viewModel.pageManager} pageControls="none">
												<NeoGrid.Grid<ClientLookup>>
													{(clientLookup, meta) => (
														<NeoGrid.Row >

															{/* Client */}
															<NeoGrid.Column
																className='pb-0 pt-0'
																label={textConstants.titleText.Client}
															>
																<div className='pb-3 pt-3 clickableCursor' onClick={() => {
																	this.securityCheck(Roles.Clients.Settings, () => {
																		this.viewParams.clientId.value = clientLookup.clientId;
																		//Add to navigation path and display component
																		this.viewParams.clientMode.value = this.viewModel.navigate(Navigation.goToClientSettings);
																	})
																}}>
																	{clientLookup.clientName}
																</div>
															</NeoGrid.Column>

															{/* Client Reports */}
															<NeoGrid.Column className="pb-0 pt-0 text-center" width={100}
																label='Client Reports'
																hidden={!this.authorisationService.hasRole(Roles.IdealCustomerProfile.Manage) || this.viewModel.clientCriteria.isDeleted}>
																<StyledButton
																	isIconButton={true}
																	onClick={() => {
																		this.securityCheck(Roles.Clients.Manage, () => {
																			this.viewParams.clientId.value = clientLookup.clientId;
																			//Add to navigation path and display component
																			this.viewParams.clientMode.value = this.viewModel.navigate(Navigation.goToClientReports);
																		})
																	}}
																	className={"client-view-small-button"}
																>
																	<Icon.LogIn size={16} />
																</StyledButton>
															</NeoGrid.Column>

															{/* Ideal Customer Profile */}
															<NeoGrid.Column className="pb-0 pt-0 text-center" width={100}
																label={textConstants.titleText.IdealCustomerProfile}
																hidden={!this.authorisationService.hasRole(Roles.IdealCustomerProfile.Manage) || this.viewModel.clientCriteria.isDeleted}>
																<StyledButton
																	isIconButton={true}
																	onClick={() => {
																		this.securityCheck(Roles.IdealCustomerProfile.Manage, () => {
																			this.viewParams.clientId.value = clientLookup.clientId;
																			//Add to navigation path and display component
																			this.viewParams.clientMode.value = this.viewModel.navigate(Navigation.goToCustomerProfiles);
																		})
																	}}
																	className={"client-view-small-button"}
																>
																	{`${clientLookup.idealCustomerProfileCount}`}
																</StyledButton>
															</NeoGrid.Column>

															{/* Campaign Message */}
															<NeoGrid.Column className="pb-0 pt-0 text-center" width={100}
																label={textConstants.titleText.CampaignMessagesSpaced}
																hidden={!this.authorisationService.hasRole(Roles.CampaignMessages.Manage) || this.viewModel.clientCriteria.isDeleted}>
																<StyledButton
																	isIconButton={true}
																	onClick={() => {
																		this.securityCheck(Roles.CampaignMessages.Manage,
																			() => {
																				this.viewParams.clientId.value = clientLookup.clientId;
																				this.viewModel.isComingFromFollowUpTemplates = false
																				this.viewModel.ClientCampaignMessageViewModel.isComingFromFollowUpTemplates = false
																				this.viewModel.ClientCampaignMessageViewModel.tabManager.selectedTab = textConstants.titleText.CampaignMessage
																				//Add to navigation path and display component
																				this.viewParams.clientMode.value = this.viewModel.navigate(Navigation.goToCampaignMessages);
																			})
																	}}
																	className={"client-view-small-button"}>
																	{`${clientLookup.campaignMessagesCount}`}
																</StyledButton>
															</NeoGrid.Column>

															{/* Target Market */}
															<NeoGrid.Column className="pb-0 pt-0 text-center" width={100}
																label={textConstants.titleText.TargetMarkets}
																hidden={!this.authorisationService.hasRole(Roles.TargetMarkets.Manage) || this.viewModel.clientCriteria.isDeleted}>
																<StyledButton
																	isIconButton={true}
																	onClick={() => {
																		this.securityCheck(Roles.TargetMarkets.Manage,
																			() => {
																				this.viewParams.clientId.value = clientLookup.clientId;
																				//Add to navigation path and display component
																				this.viewParams.clientMode.value = this.viewModel.navigate(Navigation.goToTargetMarkets);
																			})
																	}}
																	className={"client-view-small-button"}>
																	{`${clientLookup.targetMarketCount}`}
																</StyledButton>
															</NeoGrid.Column>

															{/* Client Settings */}
															<NeoGrid.Column className="pb-0 pt-0 text-center" width={100} label={"Client Settings"}
																hidden={this.viewModel.isArchived}>
																<StyledButton
																	isIconButton={true}
																	onClick={() => this.securityCheck(Roles.Clients.Settings,
																		() => {
																			this.viewParams.clientId.value = clientLookup.clientId;
																			//Add to navigation path and display component
																			this.viewParams.clientMode.value = this.viewModel.navigate(Navigation.goToClientSettings);
																		})
																	}
																	className={"client-view-small-button"}>
																	<Icon.Settings size={16} />
																</StyledButton>
															</NeoGrid.Column>

															{/* Archive */}
															<NeoGrid.Column className="pb-0 pt-0" width={100} hidden={this.viewModel.isArchived} label={textConstants.buttonText.Archive}>
																<StyledButton
																	isIconButton={true}
																	onClick={() => this.securityCheck(Roles.Clients.Manage,
																		() => this.viewModel.openDeleteModal(clientLookup))
																	}
																	className={"client-view-small-button"}>
																	<Icon.Archive size={16} />
																</StyledButton>
															</NeoGrid.Column>

															{/* Unarchive */}
															<NeoGrid.Column className="pb-0 pt-0" width={100} hidden={!this.viewModel.isArchived} label={textConstants.buttonText.Unarchive}>
																<StyledButton
																	isIconButton={true}
																	onClick={() => this.securityCheck(Roles.TargetMarkets.Manage,
																		() => this.viewModel.openUndoDeleteClientModal(clientLookup))
																	}
																	className={"client-view-small-button"}>
																	<Icon.RotateCw size={16} />
																</StyledButton>
															</NeoGrid.Column>
														</NeoGrid.Row>
													)}
												</NeoGrid.Grid>
											</Neo.Pager>

											<div className="col-12 p-24 pb-3">
												<CustomPagingControl
													displayLabel={true}
													pageManager={this.viewModel.pageManager} />
											</div>
										</React.Fragment>
									}
								</div>
							</div>

							{/* Edit Client Modal */}
							<Neo.Modal
								title="Edit Client"
								show={this.viewModel.showEditClientModal}
								formProps={{ model: this.viewModel.editClientCommand }}
								acceptButton={{
									text: "Save", variant: "secondary",
									onClick: () => {
										this.viewModel.TargetMarketWasPrevious = false;
										this.viewModel.CampaignMessageWasPrevious = false;
										this.viewModel.editClient();
									}
								}}
								closeButton={{ text: "Cancel", variant: "light" }}
								onClose={() => this.viewModel.showEditClientModal = false}>
								<Neo.GridLayout md={1}>
									<Neo.FormGroup bind={this.viewModel.editClientCommand.meta.clientName} className="mb-1" />
								</Neo.GridLayout>
							</Neo.Modal>

							{/* Delete Client Modal */}
							<Neo.Modal title={`Delete confirmation`} show={this.viewModel.showDeleteModal}
								onClose={() => this.viewModel.showDeleteModal = false}
								closeButton={{ text: "No", variant: "light" }}
								acceptButton={{ text: "Yes", variant: "secondary", onClick: () => this.viewModel.deleteClient() }}>
								Client &#34;{this.viewModel.selectedClientName}&#34; is about to be deleted. Do you wish to continue?
							</Neo.Modal>

							{/* Undo Client Delete Modal */}
							<Neo.Modal
								title={`Undo Archive Confirmation`}
								show={this.viewModel.showUndoDeleteModal}
								onClose={() => this.viewModel.showUndoDeleteModal = false}
								closeButton={{ text: "No", variant: "light" }}
								acceptButton={{ text: "Yes", variant: "secondary", onClick: () => this.viewModel.undoDeleteClient() }}>
								Client &#34;{this.viewModel.selectedClientName}&#34; is about to be unarchived. Do you wish to continue?
							</Neo.Modal>
						</div>
					</Neo.Card >
				}
				{
					(this.viewModel.clientId !== null &&
						this.viewParams.clientMode.value === `TargetMarkets`) &&
					this.viewModel.targetMarketId === null &&
					<TargetMarketView viewModel={this.viewModel.TargetMarketViewViewModel}
						back={() => {
							let temp = this.viewModel.navigate(this.viewModel.TargetMarketViewViewModel.clientNavigationButtons.navigateTo);
							if (temp === null) this.viewParams.clientId.value = null;
							else this.viewParams.clientMode.value = temp;
						}}
						openUpdateTargetMarket={() => {
							this.viewParams.clientId.value = this.viewModel.clientId;
							this.viewParams.clientMode.value = `UpdateTargetMarkets`
						}}
						openAddTargetMarket={() => {
							// this.viewParams.clientId.value = this.viewModel.clientId;
							this.viewParams.clientMode.value = `AddTargetMarket`
						}}
						openTargetMarketAccounts={(targetMarketId: number) => {
							this.viewParams.id.value = targetMarketId;
							this.viewParams.secondaryMode.value = `Target Market Accounts`;
						}}
						openEditTargetMarket={(targetMarketId: number, targetMarketName: string) => {

							this.viewModel.targetMarketName = targetMarketName;

							this.viewParams.id.value = targetMarketId;
							this.viewParams.secondaryMode.value = `EditTargetMarket`;
						}}
						openProspectTargetMarket={(targetMarketId: number, prospectingCount: number) => {

							this.viewModel.ProspectingViewModel = new ProspectingVM();
							this.viewModel.ProspectingViewModel.taskRunner = this.viewModel.taskRunner;
							this.viewModel.ProspectingViewModel.targetMarketId = targetMarketId;
							this.viewModel.ProspectingViewModel.numberOfAccounts = prospectingCount;

							this.viewParams.id.value = targetMarketId;
							this.viewParams.secondaryMode.value = `ProspectTargetMarket`;
							this.viewModel.ProspectingViewModel.initialise();
						}}
					/>
				}
				{
					(this.viewModel.clientId !== null && this.viewParams.clientMode.value === `UpdateTargetMarkets`) && this.viewModel.targetMarketId === null &&
					<UpdateTargetMarketsView viewModel={this.viewModel.UpdateTargetMarketsViewModel} back={() => this.viewParams.clientMode.value = `TargetMarkets`} />
				}
				{
					(this.viewModel.clientId !== null && this.viewParams.clientMode.value === `AddTargetMarket`) && this.viewModel.targetMarketId === null &&
					<AddEditTargetMarketView
						viewModel={this.viewModel.AddEditTargetMarketViewModel}
						back={() => { this.viewModel.clearAddEditNotifications = false; this.viewParams.clientMode.value = `TargetMarkets` }}
						clientName={this.viewModel.clientDisplayName}
					/>
				}
				{
					(this.viewModel.targetMarketId !== null && this.viewParams.secondaryMode.value === `Target Market Accounts`) &&
					<TargetMarketAccountsView viewModel={this.viewModel.TargetMarketAccountsViewModel}
						back={() => {
							this.clearTargetMarketInfo();
							this.viewParams.clientMode.value = `TargetMarkets`
						}}
					/>
				}
				{
					(this.viewModel.targetMarketId !== null && this.viewParams.secondaryMode.value === `EditTargetMarket`) &&
					<AddEditTargetMarketView viewModel={this.viewModel.AddEditTargetMarketViewModel}
						back={() => {
							this.viewModel.clearAddEditNotifications = false;
							this.clearTargetMarketInfo();
							this.viewParams.clientMode.value = `TargetMarkets`
						}
						}
						clientName={this.viewModel.clientDisplayName}
					/>
				}
				{
					(this.viewModel.targetMarketId !== null && this.viewParams.secondaryMode.value === `ProspectTargetMarket`) &&
					<ProspectingView viewModel={this.viewModel.ProspectingViewModel}
						back={() => {
							this.clearTargetMarketInfo();
							this.viewParams.clientMode.value = `TargetMarkets`
						}}
					/>
				}
				{
					(this.viewModel.targetMarketId !== null && this.viewParams.secondaryMode.value === `AddClientList`) &&
					<AddClientListView viewModel={this.viewModel.AddClientListViewModel}
						back={() => {
							this.viewParams.secondaryMode!.value = `Target Market Accounts`;
						}}
					/>
				}
				{
					(this.viewParams.clientId.value !== null && this.viewParams.clientMode.value === 'EditClient') &&
					<ClientSettingsView viewModel={this.viewModel.ClientSettingsViewModel}
						back={() => {
							let temp = this.viewModel.navigate(this.viewModel.ClientSettingsViewModel.clientNavigationButtonVM.navigateTo);
							if (temp === null) this.viewParams.clientId.value = null;
							else this.viewParams.clientMode.value = temp;
						}}
						navigateToTargetMarket={() => {
							this.viewParams.clientId.value = this.viewParams.clientId.value;
							this.viewParams.clientMode.value = "TargetMarkets";
							// navigation path 
							this.viewModel.navigationList.push("TargetMarkets");
						}}
						openBatchReviewProspects={(batchReviewUploadId: number) => {
							this.viewParams.clientMode.value = textConstants.generalText.BatchReviewProspects;
							this.viewModel.batchReviewUploadId = batchReviewUploadId;
						}}
					/>
				}
				{
					(this.viewModel.clientId !== null && this.viewParams.clientMode.value === textConstants.titleText.ClientCampaignMessagesValue) &&
					<ClientCampaignMessageView viewModel={this.viewModel.ClientCampaignMessageViewModel}
						back={() => {
							this.clientCampaignMessageBack()
						}}
						openAddEmailSignature={() => {
							this.viewParams.clientMode.value = 'AddEmailSignature'
						}}
						openEditEmailSignature={(clientCampaignMessageId: number, clientCampaignMessageStatusId: number) => {
							this.viewModel.campaignMessageType = "Email Signature"
							this.viewModel.clientCampaignMessageId = clientCampaignMessageId;
							this.viewModel.clientCampaignMessageStatusId = clientCampaignMessageStatusId;
							this.viewParams.clientMode.value = `EditEmailSignature`;
							this.viewParams.id.value = clientCampaignMessageId;
						}}
						openAddCampaignMessage={() => {
							this.viewParams.clientMode.value = 'AddCampaignMessage'
						}}
						openEditCampaignMessage={(clientCampaignMessageId: number, clientCampaignMessageStatusId: number) => {
							this.viewModel.campaignMessageType = 'Campaign Message'
							this.viewModel.clientCampaignMessageId = clientCampaignMessageId;
							this.viewModel.clientCampaignMessageStatusId = clientCampaignMessageStatusId
							this.viewParams.clientMode.value = `EditCampaignMessage`;
							this.viewParams.id.value = clientCampaignMessageId
						}}
						openEditFollowUpTemplate={(clientFollowUpTemplateId: number, clientCampaignMessageStatusId: number) => {
							this.viewModel.clientFollowUpTemplateId = clientFollowUpTemplateId;
							this.viewModel.clientCampaignMessageStatusId = clientCampaignMessageStatusId
							this.viewParams.clientMode.value = `EditFollowUpTemplate`;
							this.viewParams.id.value = clientFollowUpTemplateId
						}}
						openAddFollowUpTemplate={() => {
							this.viewParams.clientMode.value = "AddFollowUpTemplate"
						}}
					/>
				}
				{
					(this.viewParams.clientId.value === 'AddClient') &&
					<AddClientView viewModel={this.viewModel.AddClientViewModel}
						back={() => {
							this.viewParams.clientId.value = null
						}}
					/>
				}
				{
					(this.viewModel.clientId !== null && this.viewParams.clientMode.value === `AddEmailSignature`) &&
					<CampaignEmailSignatureView viewModel={this.viewModel.CampaignEmailSignatureViewModel}
						back={() => {
							this.clearCampaignMessageInfo();
							this.viewModel.isComingFromFollowUpTemplates = false
							this.viewParams.clientMode.value = textConstants.titleText.ClientCampaignMessagesValue
						}
						}
					/>
				}
				{
					(this.viewModel.clientId !== null && this.viewParams.clientMode.value === `EditEmailSignature`) &&
					<CampaignEmailSignatureView viewModel={this.viewModel.CampaignEmailSignatureViewModel}
						back={() => {
							this.clearCampaignMessageInfo();
							this.viewModel.isComingFromFollowUpTemplates = false
							this.viewParams.clientMode.value = textConstants.titleText.ClientCampaignMessagesValue
						}
						}
					/>
				}
				{
					(this.viewModel.clientId !== null && this.viewParams.clientMode.value === `AddCampaignMessage`) &&
					<CampaignMessageView viewModel={this.viewModel.CampaignMessageViewModel}
						back={() => {
							this.clearCampaignMessageInfo();
							this.viewModel.isComingFromFollowUpTemplates = false
							this.viewParams.clientMode.value = textConstants.titleText.ClientCampaignMessagesValue
						}
						}
					/>
				}
				{
					(this.viewModel.clientId !== null && this.viewParams.clientMode.value === `EditCampaignMessage`) &&
					<CampaignMessageView viewModel={this.viewModel.CampaignMessageViewModel}
						back={() => {
							this.clearCampaignMessageInfo();
							this.viewModel.isComingFromFollowUpTemplates = false
							this.viewParams.clientMode.value = textConstants.titleText.ClientCampaignMessagesValue
							this.viewModel.clientCampaignMessageStatusId = 0;
						}
						}
						statusId={this.viewModel.clientCampaignMessageStatusId}
					/>
				}

				{
					(this.viewModel.clientId !== null && this.viewParams.clientMode.value === `AddFollowUpTemplate`) &&
					<AddEditFollowUpTemplateView viewModel={this.viewModel.AddFollowUpTemplateViewModel}
						back={() => {
							this.clearAddFollowUpTemplateInfo();
							this.viewModel.isComingFromFollowUpTemplates = true
							this.viewParams.clientMode.value = textConstants.titleText.ClientCampaignMessagesValue;
						}
						}
					/>
				}

				{
					(this.viewModel.clientId !== null && this.viewParams.clientMode.value === `EditFollowUpTemplate`) &&
					<AddEditFollowUpTemplateView viewModel={this.viewModel.AddFollowUpTemplateViewModel}
						back={() => {
							this.clearAddFollowUpTemplateInfo();
							this.viewModel.isComingFromFollowUpTemplates = true
							this.viewParams.clientMode.value = textConstants.titleText.ClientCampaignMessagesValue
							this.viewModel.clientCampaignMessageStatusId = 0;
						}
						}
						statusId={this.viewModel.clientCampaignMessageStatusId}
					/>
				}

				{
					this.viewModel.showBatchReviewProspects && this.viewParams.clientMode.value === textConstants.generalText.BatchReviewProspects &&
					<BatchReviewProspectsView
						viewModel={this.viewModel.BatchReviewProspectsViewModel}
						back={() => {
							this.viewParams.clientMode.value = "EditClient"
							this.viewParams.secondaryMode.value = ""
							this.viewParams.secondaryMode.value = null
							this.viewModel.showBatchReviewProspects = false;
							this.viewModel.showBatchReviewTab = true;

							this.viewModel.navigationList.pop();
						}}
					/>
				}

				{
					this.viewParams.clientMode.value === textConstants.titleText.IdealCustomerProfile &&
					<CustomerProfilesView
						viewModel={this.viewModel.CustomerProfilesViewModel}
						back={() => {
							let temp = this.viewModel.navigate(this.viewModel.CustomerProfilesViewModel.clientNavigationButtonVM.navigateTo);
							if (temp === null) this.viewParams.clientId.value = null;
							else this.viewParams.clientMode.value = temp;
						}}
						openCustomerProfileQuestionnaire={(customerProfileId?: number) => {
							this.viewParams.clientMode.value = "ICPQuestionnaire";
							this.viewModel.ICPMode = "ICPQuestionnaire";
							this.viewModel.customerProfileId = customerProfileId;
							this.viewParams.id.value = customerProfileId ?? null

							if (!customerProfileId) {
								this.viewModel.currentICPMode = "Add"
							}
							else {
								this.viewModel.currentICPMode = "Edit"
							}
						}}
						clientName={this.viewModel.clientDisplayName} />
				}

				{
					this.viewModel.showICPQuestionnaire && this.viewParams.clientMode.value === "ICPQuestionnaire" &&
					<CustomerProfileQuestionnaireView
						viewModel={this.viewModel.CustomerProfileQuestionnaireViewModel}
						back={() => {
							this.viewModel.ICPMode = ""
							// Display ICP screen
							this.viewModel.showICPQuestionnaire = false
							this.viewParams.id.value = null
							this.viewParams.clientMode.value = textConstants.titleText.IdealCustomerProfile;
						}}
						downloadProfile={() => this.viewModel.CustomerProfilesViewModel.downloadICP(
							this.viewModel.CustomerProfileQuestionnaireViewModel.customerProfileId,
							this.viewModel.CustomerProfileQuestionnaireViewModel.profileName
						)}
					/>
				}

				{
					this.viewParams.clientMode.value === textConstants.titleText.ClientReportView &&
					<DashboardReports
						viewModel={this.viewModel.DashboardViewModel}
						viewCampaignDetails={(clientCampaignId: number) => {
							this.viewParams.clientMode.value = textConstants.Routes.CampaignEmailOverview
							this.viewParams.id.value = clientCampaignId
							this.viewModel.DashboardViewModel.clientCampaignId = clientCampaignId;
						}}
						showBack={true}
						back={() => {
							this.viewParams.id.value = null
							let temp = this.viewModel.navigate('');
							if (temp === null)
								this.viewParams.clientMode.value = null;
							else this.viewParams.clientMode.value = temp;
						}}
						viewActionList={(clientId: number, selectedTab: string = textConstants.buttonText.HotLeads) => {
							this.viewParams.clientMode.value = textConstants.titleText.ActionListView
							this.viewModel.shouldInitializeActionList = true;

							this.viewModel.ActionListSelectedTab = selectedTab
						}}
					/>
				}

				{
					this.viewParams.clientMode.value === textConstants.Routes.CampaignEmailOverview &&
					<ClientCampaignWoodpeckerEmailTable
						viewModel={this.viewModel.DashboardViewModel}
						campaignData={this.viewModel.DashboardViewModel.campaignData}
						back={() => {
							this.viewParams.clientMode.value = textConstants.titleText.ClientReportView;
							this.viewParams.id.value = null
						}} />
				}

				{
					this.viewParams.clientMode.value === textConstants.titleText.ActionListView &&
					<ActionListScreen
						viewModel={this.viewModel.ActionListViewModel}
						showPreviousMessage={async () => {
							await this.viewModel.ActionListViewModel.showPreviousMessage();
							this.updateBreadcrumbFromLeadMessageView()
						}}
						showNextMessage={async () => {
							await this.viewModel.ActionListViewModel.showNextMessage();
							this.updateBreadcrumbFromLeadMessageView()
						}}
						changeLeadType={async (leadType) => {
							await this.viewModel.ActionListViewModel.updateLeadMessageType(leadType);
							this.updateBreadcrumbFromLeadMessageView()
						}}
						archiveCurrentLead={async () => {
							await this.archiveCurrentLead()
						}}
						backToActionList={() => {
							this.viewModel.ActionListViewModel.showActionListView = true;
							this.viewModel.ActionListViewModel.hideLeadMessageSideMenu();
							this.resetViewParams();
						}}
						openLeadsView={(prospectId: number, campaignId: number, replyId: string) => {
							this.viewModel.ActionListViewModel.showLeadMessageSideMenu();
							this.viewModel.ActionListViewModel.setCurrentMessageIndex(
								this.viewModel.ActionListViewModel.pagedLeadList.indexOf(this.viewModel.ActionListViewModel.pagedLeadList
									.find(c => c.prospect_id === prospectId)!))

							this.setLeadViewParams(prospectId, campaignId, replyId)
						}}
						back={() => {
							this.viewModel.actionListInitialized = false;
							this.viewParams.clientMode.value = textConstants.titleText.ClientReportView;
							this.viewParams.id.value = null;
						}}
					/>
				}

				{/* Forbidden Modal */}
				<Neo.Modal
					title="Forbidden"
					show={this.viewModel.showForbiddenModal}
					onClose={() => this.viewModel.showForbiddenModal = false}
					closeButton={{ text: "Cancel", variant: "light" }}
				>
					<p>You have insufficient privileges to perform this operation.</p>
				</Neo.Modal>
			</>
		);
	}
}
