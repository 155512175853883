import { AxiosPromise, AxiosResponse } from "axios";
import moment from "moment";
import qs from "querystringify";
import AppLayout, { MenuState, ScreenSize } from "../Components/AppLayout";
import ApiResult from "../Models/ApiResult";
import { textConstants } from "./textConstants";
import { IOptions } from "Components/CustomDropDown/CustomDropDown";
import ActionListStatus from "Models/ActionList/ActionListStatus";
import jwtDecode from "jwt-decode";

/**
 * This will parse the query string of the current URL
 * @returns {Object} - Key values pairs if there are matches
 *                   - An empty object if there are no matched
 */
export const parsedQueryString = () => {
  const currentQueryString = window.location.search;
  // Convert the querystring into an object.
  const qsObj = qs.parse(currentQueryString);

  return qsObj;
};

export const getCurrentDateTime = () => {
  return moment().format(textConstants.dateformat.format1).toString();
};

export const sortArrayByArray = (
  obj: Array<any>,
  filterArray: Array<any>,
  searchKey: string
) => {
  obj.sort((pElement: any, nElement: any) => {
    if (
      filterArray.indexOf(pElement[searchKey]) >
      filterArray.indexOf(nElement[searchKey])
    ) {
      return 1;
    } else {
      return -1;
    }
  });
  return obj;
};

export const getObjectKey = (
  object: any,
  searchkey: string,
  searchValue: string
) => {
  return Object.keys(object).filter(
    (x) => object[x][searchkey] === searchValue
  )[0];
};

export const isStringBlank = (str: string) => {
  if (str) {
    return !str.replace(/\s/g, "").length && str.length > 0;
  }
  return false;
};

export const isStringEmpty = (str: string) => {
  return !str || 0 === str.length;
};

export const isStrigBlankOrEmpty = (str: string) => {
  if (isStringBlank(str) || isStringEmpty(str)) {
    return true;
  } else {
    return false;
  }
};

// The following regex was designed to allow the following
// Alphabetic uppercase (A-Z) and lowercase (a-z).
// 		Alphabetic with umlauts (e.g. ä), accents (e.g. é), etc.
// 		Possibly number (0-9).
// 		Spaces ( ).
// 		Hyphens , such as for double-barrel names/surnames.
// 		Single quotes (‘), such as for surnames like “O’Donnell”.
// 		Possibly parenthesis (e.g. (, )).
// 		Possibly forward slashes .
// The last part of the regex expression is used to then allow for umlauts to be accepted
// example of umlauts ä, ö, ü
// In order to allow for these, a range of acceptable umlauts was used, the explanation of the ranges can be found here
// https://www.utf8-zeichentabelle.de/unicode-utf8-table.pl?utf8=dec
// \u00C0 is U+00C0
export const regOtherKeyWords =
  /^$|^[a-zA-Z0-9\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]+$/;

export const ValidateUsingRegex = (value: string, regex: string) => {
  var result = new RegExp(regex);
  return result.test(value);
};

export const SearchStringWithArray = (value: string, textStrings: string[]) => {
  let result = false;
  textStrings.forEach((c) => {
    if (value.toLocaleLowerCase().indexOf(c) >= 0) {
      result = true;
    }
  });
  return result;
};

export const base64toBlob = (base64Data: string, contentType: string) => {
  contentType = contentType || "";
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};

export function ResponseResolver<T>(
  task: AxiosPromise<ApiResult>
): AxiosPromise<T> {
  return new Promise((resolve, reject) => {
    task.then((x) => resolve(ApiResponseMapper<T>(x))).catch((x) => reject(x));
  });
}

function ApiResponseMapper<T>(x: AxiosResponse<ApiResult>) {
  const result: AxiosResponse<T> = {
    config: x.config,
    data: x.data.data,
    headers: x.headers,
    status: x.status,
    statusText: x.statusText,
  };

  const promise: Promise<AxiosResponse<T>> = Promise.resolve(result);
  return promise as AxiosPromise<T>;
}

export const isURL = (urlString: string) => {
  let regex: RegExp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

  if (
    regex.test(urlString) &&
    (urlString.indexOf("https://") !== -1 ||
      urlString.indexOf("http://") !== -1)
  ) {
    return true;
  }

  return false;
};

export const isIPAddress = (IPAddress: string) => {
  let regex: RegExp =
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  if (regex.test(IPAddress)) {
    return true;
  }
  return false;
};

export const setTextForClipboard = (textToCopy: string) => {
  if (textToCopy !== undefined) {
    return textToCopy;
  } else {
    return "Text not found";
  }
};

// Method to validate complete URLs
export const isValidEmail = (str: string) => {
  // Updated Regex to cater for bla+bla@gmail.com
  let regex: RegExp = /^[\w]+([+-._][\w]+)*@([\w-]+\.)+[\w-]{2,}$/;
  if (regex.test(str)) {
    return true;
  } else {
    return false;
  }
};

export const isMobile = () => {
  let isMobile = false;
  if (AppLayout.current.currentScreenSize === ScreenSize.Small || AppLayout.current.currentScreenSize === ScreenSize.ExtraSmall) {
    isMobile = true;

  }
  return isMobile;
};

export const isMiniNav = () => {
  let isMiniNav = AppLayout.current.menuState === MenuState.Collapsed;

  if (AppLayout.current.menuState === MenuState.Collapsed && AppLayout.current.currentScreenSize <= ScreenSize.ExtraLarge) {
    isMiniNav = false;
  }
  else {
    isMiniNav = true;
    if (AppLayout.current.currentScreenSize === ScreenSize.Huge) {
      isMiniNav = AppLayout.current.menuState === MenuState.Collapsed;
    }
  }

  return isMiniNav;
};

export const setUpdateText = (modifiedBy: string | null) => {
  if (modifiedBy === "" || modifiedBy === " " || modifiedBy === null) {
    return "Updated "
  } else {
    return "Updated By "
  }
}

export const convertActionListStatusesToCustomDropdown = (list: ActionListStatus[]) => {
  let options = list!.map(listItem => {
    let option: IOptions = { value: listItem.statusName, label: listItem.displayMember }
    return option
  })

  return options
};

export const getRelativeTimeFromDateString = (dateString: string) => {
  var currentDateTime = moment(new Date())
  var dateToCompare = moment(new Date(dateString))
  var dateDiff = currentDateTime.diff(dateToCompare)

  var yearCount = Math.floor(moment.duration(dateDiff).asYears())
  if (yearCount >= 1) {
    return `${yearCount} ${yearCount === 1 ? 'year' : 'years'} ago`
  }

  var monthCount = Math.floor(moment.duration(dateDiff).asMonths())
  if (monthCount >= 1) {
    return `${monthCount} ${monthCount === 1 ? 'month' : 'months'} ago`
  }

  var weekCount = Math.floor(moment.duration(dateDiff).asWeeks())
  if (weekCount >= 1) {
    return `${weekCount} ${weekCount === 1 ? 'week' : 'weeks'} ago`
  }

  var dayCount = Math.floor(moment.duration(dateDiff).asDays())
  if (dayCount >= 1) {
    return `${dayCount} ${dayCount === 1 ? 'day' : 'days'} ago`
  }

  var hourCount = Math.floor(moment.duration(dateDiff).asHours())
  if (hourCount >= 1) {
    return `${hourCount} ${hourCount === 1 ? 'hour' : 'hours'} ago`
  }

  return "now"
}

export const getEmailAddressFromAccessToken = (token: string | null) => {
  if (token) {
    const decodedToken = jwtDecode(token) as { email: string };
    return decodedToken.email;
  }
  return ""
}