import { Attributes, List, ModelBase, NeoModel, } from '@singularsystems/neo-core';
import CampaignMessageLookup from './CampaignMessageLookup';
import TMLookup from './TMLookup';

@NeoModel
export default class ClientCampaignMessageLookup extends ModelBase {

    public sequenceNumber: number = 0;

    public clientCampaignMessageId: number = 0

    @Attributes.Display("Name")
    public campaignName: string = "";

    @Attributes.Display("Target Markets")
    public targetMarkets: string = "";

    public targetMarketsFull : string = "";

    public campaignMessageStatusId: number = 0

    public campaignMessageStatus: string = ""

    public campaignMessageType: string = ""

    public campaignMessageTypeId: number = 0

    public landingPageUrl: string = "";

    public selectedTargetMarkets : List<TMLookup> | null = null

    public campaignMessages : List<CampaignMessageLookup> | null = null
}