import React from 'react';
import { observer } from 'mobx-react';
import { AppServices, Misc } from '@singularsystems/neo-core';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';

import * as Roles from '../Models/Security/Roles';
import { textConstants } from "../../common/textConstants";
import ResourceRoleTree from "../Components/ResourceRoleTree";

import '../Styles/Authorisation.scss';

import UserGroupsVM, { UserGroupsViewState } from './UserGroupsVM';
import UserGroupLookup from '../Models/UserGroupLookup';
import { IReactionDisposer, reaction } from 'mobx';
import * as Icon from 'react-feather';
import Button from 'Components/Button';

class UserGroupsViewParams {
    public userGroup = {};
    public tab = {};
}

@observer
export default class UserGroupsView extends Views.ViewBase<UserGroupsVM, UserGroupsViewParams> {

    public static params = new UserGroupsViewParams();
    public lastTab = "Members";

    constructor(props: unknown) {
        super("User Access", UserGroupsVM, props);

        this.setupReaction();
    }

    public tabChangeReactionDisposer?: IReactionDisposer;

    private setupReaction() {
        this.tabChangeReactionDisposer = reaction(() => {
            return {
                selectedTab: this.viewModel.selectedTab,
            }
        }, async (result: any) => {
            if (this.viewParams.tab.value !== result.selectedTab) {
                this.viewParams.tab.value = result.selectedTab;
                this.lastTab = result.selectedTab;
            }
        });
    }

    public dispose() {
        if (this.tabChangeReactionDisposer) this.tabChangeReactionDisposer();
    }

    onLeave() {
        this.viewModel.removeNotifications();
        return undefined;
    }

    public async viewParamsUpdated() {
        if (this.viewParams.userGroup.value) {
            await this.viewModel.loadUserGroup(this.viewParams.userGroup.asNullableInt()!);

            if (this.viewModel.userGroup.userGroupId === this.viewParams.userGroup.asNullableInt()) {
                // set the description for the crumb trail
                this.viewParams.userGroup.description = this.viewModel.userGroup.userGroupName;

                if (this.viewParams.tab.value) {
                    this.viewModel.selectedTab = this.viewParams.tab.description = this.viewParams.tab.asString();
                } else {
                    this.viewParams.tab.value = this.lastTab;
                }
            } else {
                // nothing found, so clear the view param
                this.viewParams.userGroup.value = null;
            }
        } else {
            this.viewModel.viewState = UserGroupsViewState.ManageList;
        }
    }

    private authorisationService = Misc.Globals.appService.get(AppServices.NeoTypes.Security.AuthorisationService);

    private canUpdateUserGroupName() {
        return this.authorisationService.hasRole(Roles.UserAccess.Update) && !this.viewModel.userGroup.isAdministratorGroup;
    }

    private canDeleteUserGroup() {
        return this.authorisationService.hasRole(Roles.UserAccess.Update) && !this.viewModel.userGroup.isAdministratorGroup;
    }

    private canUpdateMembers() {
        return this.authorisationService.hasRole(Roles.UserAccess.Update);
    }

    private back() {
        this.viewParams.userGroup.value = null;
        this.viewModel.resetViewState();
    }

    private manageUserGroup(item: UserGroupLookup) {
        this.viewParams.userGroup.value = item.userGroupId;
    }

    public render() {
        return (
            <Neo.Card>
                <div className="UserGroupsView mb-4">
                    {/* Back button */}
                    <div className="row m-0">
                        <div className="col-11 col-md-10 p-0">
                            {(this.viewModel.viewState !== UserGroupsViewState.ManageList) &&
                                <div>
                                    <Neo.Button
                                        className="btn-width-100 mt-3 marginLeft24"
                                        variant="light"
                                        icon={<Icon.ArrowLeft />}
                                        onClick={() => this.back()}
                                    >
                                        {textConstants.buttonText.Back}
                                    </Neo.Button>
                                </div>}
                        </div>
                    </div>
                    {this.viewModel.viewState === UserGroupsViewState.ManageList &&
                        <div>
                            <div className='row m-0'>
                                <h2 className='col-3 p-24 mt-4'>{textConstants.generalText.UserGroups}</h2>
                                {this.authorisationService.hasRole(Roles.UserAccess.Update) &&
                                    <div className='col-9 p-24 mt-4 pull-right'>
                                        <Button
                                            buttonType='primary'
                                            className="pull-right ml-4 mt-0 mb-1"
                                            variant="hasIcon"
                                            size="xSmall"
                                            onClick={() => this.viewModel.addUserGroup()}
                                        >
                                            <Icon.Plus />
                                            {textConstants.generalText.AddNewGroup}
                                        </Button>
                                    </div>}
                            </div>
                            <div className="manage-user-group-list mt-3">
                                {/* LOADING MESSAGE */}
                                {!this.viewModel.userGroupsLoaded && <div className="loading">
                                    <h5 className='p-24'>Loading...</h5> </div>}
                                {/* NO USER GROUPS MESSAGE */}
                                {this.viewModel.userGroupsLoaded && this.viewModel.userGroups.length === 0 &&
                                    <div className="">
                                        {this.authorisationService.hasRole(Roles.UserAccess.Update) &&
                                            <h5>No user groups, click &#39;Add new group&#39; to add your first user group</h5>}
                                        {!this.authorisationService.hasRole(Roles.UserAccess.Update) &&
                                            <h5>No user groups, and you do not have sufficient security privileges to add user groups</h5>}
                                    </div>}
                                {/* USER GROUPS GRID */}
                                {this.viewModel.userGroups.length > 0 &&
                                    <div>
                                        <NeoGrid.Grid
                                            items={this.viewModel.userGroups} >
                                            {item => (
                                                <NeoGrid.Row>
                                                    <NeoGrid.Column className='pb-0 pt-0'>
                                                        <div className='pb-3 pt-3 clickableCursor' onClick={() => { this.manageUserGroup(item) }}>
                                                            {item.userGroupName}
                                                        </div>
                                                    </NeoGrid.Column>
                                                    <NeoGrid.Column display={item.meta.memberCount} />
                                                    <NeoGrid.Column display={item.meta.roleCountDisplay} className="number-col" />
                                                    <NeoGrid.ButtonColumn
                                                        label={textConstants.buttonText.Manage}
                                                        className='pr-0'>
                                                        <Neo.Button variant="light" onClick={() => this.manageUserGroup(item)} >
                                                            {this.authorisationService.hasRole(Roles.UserAccess.Update) ? "Manage" : "View"}
                                                        </Neo.Button>
                                                    </NeoGrid.ButtonColumn>
                                                    <NeoGrid.ButtonColumn
                                                        label={textConstants.buttonText.Delete}>
                                                        {this.canDeleteUserGroup() &&
                                                            <Neo.Button variant="danger"
                                                                icon={<Icon.Trash2 />}
                                                                onClick={() => this.viewModel.openDeleteGroupModal(item)} >
                                                            </Neo.Button>}
                                                    </NeoGrid.ButtonColumn>
                                                </NeoGrid.Row>
                                            )}
                                        </NeoGrid.Grid>
                                    </div>}
                            </div>
                        </div>}
                    {/* MANAGE USER GROUP */}
                    {this.viewModel.viewState === UserGroupsViewState.ManageItem &&
                        <div className='row m-0'>
                            <h2 className='col-12 mt-4 p-24'>{this.viewModel.userGroup.isNew ? "New User Group:" : "User Group: " + this.viewModel.userGroup.userGroupName}</h2>
                            <div className="col-12 p-0">
                                <Neo.Form model={this.viewModel.userGroup} onSubmit={() => this.viewModel.save()}>
                                    <div className="row m-0 mb-3">
                                        <div className="col-9 p-24">
                                            <Neo.FormGroupInline
                                                bind={this.viewModel.userGroup.meta.userGroupName}
                                                disabled={this.viewModel.userGroup.userGroupId > 0}
                                                editorProps={{
                                                    readOnly: !this.canUpdateUserGroupName(),
                                                    autoFocus: this.viewModel.userGroup.isNew,
                                                    placeholder: textConstants.generalText.newUserGroupPlaceholder,
                                                }} />
                                        </div>
                                        <div className="col-3 p-24 text-right">
                                            {this.authorisationService.hasRole(Roles.UserAccess.Update) &&
                                                <Neo.Button
                                                    isSubmit
                                                    className={"mt-4 form-submit"}
                                                    disabled={this.viewModel.canSave()}
                                                    variant="secondary" >
                                                    {textConstants.buttonText.Save}
                                                </Neo.Button>
                                            }
                                        </div>
                                    </div>
                                </Neo.Form>
                                {!this.viewModel.userGroup.isNew &&
                                    <Neo.TabContainer selectedTab={this.viewModel.meta.selectedTab} className={"m-0"}>
                                        {/* GROUP MEMBERS */}
                                        <Neo.Tab header={"Members (" + this.viewModel.groupMemberships.length + ")"} icon="users" name="Members" >
                                            <div className='row negSideMargins24'>
                                                <h2 className='p-24'>{"Add Members"}</h2>
                                                <div className="col-12 p-0">
                                                    {/* ADD MEMBER */}
                                                    {this.canUpdateMembers() &&
                                                        <div className="row m-0">
                                                            <div className="col-9 p-24">
                                                                {<Neo.FormGroup bind={this.viewModel.memberFindCriteria.meta.addUserId}
                                                                    select={{
                                                                        items: this.viewModel.userList,
                                                                        displayMember: "nameAndEmail",
                                                                        valueMember: "userId"
                                                                    }
                                                                    } />}
                                                            </div>
                                                            <div className="col-3 p-24 marginTop31">
                                                                <Button
                                                                    buttonType='primary'
                                                                    className="pull-right ml-4 mt-0 mb-1"
                                                                    variant="hasIcon"
                                                                    size="xSmall"
                                                                    disabled={this.viewModel.isSaving}
                                                                    onClick={() => this.viewModel.addMember()}
                                                                >
                                                                    <Icon.Plus />
                                                                    {textConstants.buttonText.Add}
                                                                </Button>
                                                            </div>
                                                        </div>}
                                                    {/* MEMBER LIST */}
                                                    <div className="row m-0">
                                                        {this.viewModel.groupMemberships.length > 0 &&
                                                            <NeoGrid.Grid
                                                                items={this.viewModel.groupMemberships} >
                                                                {item => (
                                                                    <NeoGrid.Row>
                                                                        <NeoGrid.Column display={item.meta.memberName} />
                                                                        <NeoGrid.Column display={item.meta.userName} />
                                                                        {this.viewModel.addClientsToDisplay() &&
                                                                            <NeoGrid.Column display={item.meta.clientName} />}
                                                                        <NeoGrid.ButtonColumn hidden={!this.canUpdateMembers} label={textConstants.buttonText.Delete}>
                                                                            <Neo.Button variant="danger" icon={<Icon.Trash2 />}
                                                                                className="text-center"
                                                                                onClick={() => this.viewModel.openDeleteModal(item)}
                                                                            >
                                                                            </Neo.Button>
                                                                        </NeoGrid.ButtonColumn>
                                                                    </NeoGrid.Row>)}
                                                            </NeoGrid.Grid>}
                                                    </div>
                                                </div>
                                            </div>
                                        </Neo.Tab>
                                        <Neo.Tab header={"Roles (" + this.viewModel.assignedRolesLength + ")"} icon="user-lock" name="Roles">
                                            <div className='row m-0'>
                                                <h2 className='col-12 p-0'>Change Roles</h2>
                                                {/* LOADING ROLES MESSAGE */}
                                                {this.viewModel.rolesTask.isBusy &&
                                                    <div className="add-first-message"> <h4>Loading available roles...</h4> </div>}
                                                {/* NO ROLES MESSAGE */}
                                                {!this.viewModel.rolesTask.isBusy && this.viewModel.resourceRoles.length === 0 &&
                                                    <div className="add-first-message"> <h4>No roles available, add authorisation to your resources.</h4> </div>}
                                                <div className="col-12 p-0">
                                                    <div className="group-roles">
                                                        <Neo.Loader task={this.viewModel.rolesTask}>
                                                            <ResourceRoleTree items={this.viewModel.resourceRoles} enabled={!this.viewModel.userGroup.isAdministratorGroup} />
                                                        </Neo.Loader>
                                                    </div>
                                                    <div className="role-tools">
                                                        <Neo.Button className="text-center circled" variant="light" icon={<Icon.RotateCcw />} data-tip="Undo role changes" hidden={!this.viewModel.resourceRoles.isDirty}
                                                            onClick={() => this.viewModel.resetAssignedRoles()} >
                                                        </Neo.Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Neo.Tab>
                                    </Neo.TabContainer>}
                            </div>
                        </div>
                    }

                    <Neo.Modal
                        title={`Remove '${this.viewModel.selectedUser}'?`}
                        show={this.viewModel.showDeleteModal}
                        onClose={() => this.viewModel.showDeleteModal = false}
                        closeButton={{ text: "No", variant: "light" }}
                        acceptButton={{
                            text: "Yes", variant: "secondary",
                            onClick: () => this.viewModel.removeMember()
                        }}>
                        Are you sure you want to remove member &#34;{this.viewModel.selectedUser}&#34;?
                    </Neo.Modal>


                    <Neo.Modal
                        title={`Remove '${this.viewModel.selectedGroup}'?`}
                        show={this.viewModel.showDeleteGroupModal}
                        onClose={() => this.viewModel.showDeleteGroupModal = false}
                        closeButton={{ text: "No", variant: "light" }}
                        acceptButton={{
                            text: "Yes", variant: "secondary",
                            onClick: () => this.viewModel.deleteUserGroup()
                        }}>
                        Are you sure you want to delete user group &#34;{this.viewModel.selectedGroup}&#34;?
                    </Neo.Modal>
                </div>
            </Neo.Card>
        )
    }
}