import { NeoModel, List } from '@singularsystems/neo-core';
import Role from './../../Models/Maintenance/Role';
import { AppService, Types } from '../../Services/AppService';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';

@NeoModel
export default class RoleVM extends Views.ViewModelBase {
    
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private roleesApiClient =  AppService.get(Types.ApiClients.RolesApiClient)) {

        super(taskRunner);
    }

    // Properties
    public roleList = new List(Role);

    public async initialise() {
        const response = await this.taskRunner.waitFor(this.roleesApiClient.get());
        this.roleList.set(response.data);
    }

    public saveRoleList(){
        this.taskRunner.run(async () => {
            const response = await this.roleesApiClient.saveList(this.roleList.toJSArray());
            this.roleList.update(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved,textConstants.messageText.saveMessage.RolesSaved);
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}