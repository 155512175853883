import styled from "styled-components";

export const StyledCampaignCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
`;

export const StyledCampaignMessage = styled.div`
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border-radius: 8px;
    background:  #F8F4E9;
    align-self: stretch;
    display: flex;

    p {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        color: #0B0B0C;
        margin: 0;
    }
`;