import { ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import TargetMarket from '../TargetMarket';

@NeoModel
export default class TargetMarketSaveCommand extends ModelBase {
   
  // Properties
  public sectionCode: string = "";

  public targetMarket: TargetMarket = new TargetMarket();

  public isDraftSave: boolean = false;

  public hasCLevelRoles: boolean = false;

  public hasFunctions: boolean = false;
}