import { ModelBase, NeoModel } from '@singularsystems/neo-core';
import { textConstants } from '../../../common/textConstants';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';

@NeoModel
export class TargetMarketUpdateNameCommand extends ModelBase {
    public targetMarketId: number = 0;
  
    @Required(textConstants.messageText.validationMessage.targetMarketName)
    @Display("Name")
    public targetMarketName: string = ""

  }