import React from 'react';
import { observer } from 'mobx-react';
import { OnboardingSteps } from 'Models/Enums/ApplicationEnums';
import MeetingsView from './MeetingsView';
import OnboardingCampaignMessageBaseViewVM from './OnboardingCampaignMessageBaseViewVM';
import CampaignMessageComingView from './CampaignMessageComingView';
import CampaignMessageView from 'Views/CampaignMessages/CampaignMessageView';
import AddEditFollowUpTemplateView from 'Views/CampaignMessages/AddEditFollowUpTemplateView';
import CampaignEmailSignatureView from 'Views/CampaignMessages/CampaignEmailSignatureView';
import ClientCampaignMessageView from 'Views/CampaignMessages/ClientCampaignMessageView';
import { textConstants } from "../../common/textConstants";
import MeetingsVM from './MeetingsVM';
import CampaignMessageComingVM from './CampaignMessageComingVM';
import ClientCampaignMessageVM from 'Views/CampaignMessages/ClientCampaignMessageVM';
import CampaignMessageVM from 'Views/CampaignMessages/CampaignMessageVM';
import AddEditFollowUpTemplateVM from 'Views/CampaignMessages/AddEditFollowUpTemplateVM';
import CampaignEmailSignatureVM from 'Views/CampaignMessages/CampaignEmailSignatureVM';
import MeetingDetails from './CampaignMessage/MeetingDetails';
import { Neo } from '@singularsystems/neo-react';
import HtmlParser from 'react-html-parser';
import { IComXHubInfo } from './CampaignMessage/Components/MeetingDetailsContent/MeetingDetailsContent';
import { observable } from 'mobx';
import { OnboardingGoToEmailSetupFooter, OnboardingNextStepFooter } from 'Components/FooterComponent';

interface IOnboardingCampaignMessageBaseViewProps {
  viewModel: OnboardingCampaignMessageBaseViewVM;
}

@observer
export default class OnboardingCampaignMessageBaseView extends React.Component<IOnboardingCampaignMessageBaseViewProps> {

  constructor(props: IOnboardingCampaignMessageBaseViewProps) {
    super(props);
  }

  state = {
    currentView: ""
  };

  private globalProps = this.props.viewModel.authenticationService.globalProps;

  private onboardingFunctions = this.props.viewModel.authenticationService.onboardingFunctions;

  private cmTextConstants = textConstants.Onboarding.CampaignMessageBaseView;

  public async componentDidMount() {
    let selectedView = await this.props.viewModel.isMeetingScheduled() ? this.cmTextConstants.CMOverview : this.cmTextConstants.CMComing;

    this.setCurrentView(selectedView)
  }

  private setCurrentView(selectedView: string) {

    this.setState(prevState => ({
      currentView: selectedView,
    }));
  }

  private isSubView(currentViewName: string) {
    return currentViewName === this.cmTextConstants.EditSignature
      || currentViewName === this.cmTextConstants.EditCampaignMessage
      || currentViewName === this.cmTextConstants.EditFollowUpTemplate
  }

  private async updateCampaignMessageBaseView(viewName: string) {
    let currentViewName = viewName
    const { viewModel } = this.props;

    let clientId = this.globalProps.clientId;
    if (this.globalProps.onboardingSteps.find(x => x.stepNumber === OnboardingSteps.BookAMeeting)?.isComplete
      && (currentViewName === this.cmTextConstants.BookAMeeting
        || currentViewName === this.cmTextConstants.CMComing)
      && !this.isSubView(currentViewName)) {
      currentViewName = this.cmTextConstants.CMOverview
      await this.updateCampaignMessageBaseView(this.cmTextConstants.CMOverview)
    }

    switch (currentViewName) {
      case this.cmTextConstants.CMComing:
        // we can only update the view to this, if the campaign message is not ready
        viewModel.campaignMessageComingViewModel = new CampaignMessageComingVM();
        await viewModel.campaignMessageComingViewModel.initialise();
        break;

      case this.cmTextConstants.BookAMeeting:
        if (await viewModel.isMeetingScheduled()) {
          this.setCurrentView(this.cmTextConstants.CMOverview)

          await this.updateCampaignMessageBaseView(this.cmTextConstants.CMOverview)
          break
        }

        if (this.isBookAMeeting()) {
          this.setCurrentView(this.cmTextConstants.BookAMeeting)
          viewModel.meetingsViewModel = new MeetingsVM();
          viewModel.meetingsViewModel.taskRunner = viewModel.taskRunner
          viewModel.meetingsViewModel.clientId = clientId;
          await viewModel.meetingsViewModel.initialise();
        }
        break;

      case this.cmTextConstants.CMOverview:
        if (this.globalProps.clientCampaignMessageId === 0
          && this.globalProps.clientFollowUpTemplateId === 0) {
          viewModel.clientCampaignMessagesViewModel = new ClientCampaignMessageVM();
          viewModel.clientCampaignMessagesViewModel.taskRunner = viewModel.taskRunner;
          viewModel.clientCampaignMessagesViewModel.clientId = clientId;
          viewModel.clientCampaignMessagesViewModel.isComingFromFollowUpTemplates = viewModel.isComingFromFollowUpTemplates;

          // Set values & fetch data
          await viewModel.clientCampaignMessagesViewModel.fetchData(clientId);

          await viewModel.meetingsViewModel.populateMeetingLookup()

          // This ensures that navigation from Templates back to Messages loads the messages
          if (viewModel.isComingFromFollowUpTemplates) {
            viewModel.clientCampaignMessagesViewModel.isComingFromFollowUpTemplates = false;
          }

          this.setCurrentView(this.cmTextConstants.CMOverview)
        }
        break;

      case this.cmTextConstants.EditCampaignMessage:
        if (this.globalProps.clientCampaignMessageId > 0) {
          viewModel.campaignMessageViewModel = new CampaignMessageVM();
          viewModel.campaignMessageViewModel.clientCampaignMessageId = this.globalProps.clientCampaignMessageId;
          viewModel.campaignMessageViewModel.taskRunner = viewModel.taskRunner;
          viewModel.campaignMessageViewModel.isComXUser = false;
          viewModel.campaignMessageViewModel.clientId = clientId;
          await viewModel.campaignMessageViewModel.initialise();

          this.setCurrentView(this.cmTextConstants.EditCampaignMessage)
        }

        break;

      case this.cmTextConstants.EditSignature:
        if (this.globalProps.clientCampaignMessageId > 0) {
          viewModel.emailSignatureViewModel = new CampaignEmailSignatureVM();
          viewModel.emailSignatureViewModel.clientCampaignMessageId = this.globalProps.clientCampaignMessageId;
          viewModel.emailSignatureViewModel.taskRunner = viewModel.taskRunner;
          viewModel.emailSignatureViewModel.isComXUser = false;
          viewModel.emailSignatureViewModel.clientId = clientId;

          viewModel.emailSignatureViewModel.campaignEmailSignatureHelper.taskRunner = viewModel.taskRunner;
          viewModel.emailSignatureViewModel.campaignEmailSignatureHelper.clientId = clientId;
          await viewModel.emailSignatureViewModel.initialise();

          this.setCurrentView(this.cmTextConstants.EditSignature)
        }
        break;

      case this.cmTextConstants.EditFollowUpTemplate:
        if (this.globalProps.clientFollowUpTemplateId > 0) {
          viewModel.addEditFollowUpTemplateViewModel = new AddEditFollowUpTemplateVM();
          viewModel.addEditFollowUpTemplateViewModel.clientFollowUpTemplateId = this.globalProps.clientFollowUpTemplateId;
          viewModel.addEditFollowUpTemplateViewModel.taskRunner = viewModel.taskRunner;
          viewModel.addEditFollowUpTemplateViewModel.isComXUser = false;
          viewModel.addEditFollowUpTemplateViewModel.clientId = clientId;
          await viewModel.addEditFollowUpTemplateViewModel.initialise();

          this.setCurrentView(this.cmTextConstants.EditFollowUpTemplate)
        }
        break;
    }
  }

  private isBookAMeeting() {
    return this.globalProps.currentStep === OnboardingSteps.BookAMeeting
  }

  private async cancelMeeting() {
    if (await this.props.viewModel.cancelMeeting()) {
      this.setCurrentView(this.cmTextConstants.BookAMeeting);
      this.globalProps.currentStep = OnboardingSteps.BookAMeeting;
      await this.updateCampaignMessageBaseView(this.cmTextConstants.BookAMeeting)
    }
  }

  private setFooterPadding() {
    if (this.globalProps.onboardingSteps.find(x => x.stepNumber === OnboardingSteps.TechnicalIntegration)?.isComplete === true) {
      return ""
    } else {
      return "pr-0"
    }
  }

  public render() {
    const { viewModel } = this.props;

    return (
      <div className="appFooterRestrictions">

        {/* We need the book a meeting step to go into this until its complete */}
        {(this.state.currentView === this.cmTextConstants.CMComing || this.state.currentView === this.cmTextConstants.BookAMeeting)
          && !viewModel.authenticationService.globalProps.onboardingSteps.find(x => x.stepNumber === OnboardingSteps.BookAMeeting)?.isComplete &&
          <>
            {/* Campaign Message coming */}
            {this.state.currentView === this.cmTextConstants.CMComing
              &&
              <CampaignMessageComingView
                viewModel={viewModel.campaignMessageComingViewModel}
                navigate={async () => {
                  this.setCurrentView(this.cmTextConstants.BookAMeeting);
                  await this.updateCampaignMessageBaseView(this.cmTextConstants.BookAMeeting)
                }}
              />
            }

            {/* Book A Meeting */}
            {(this.state.currentView === this.cmTextConstants.BookAMeeting)
              &&
              <MeetingsView
                viewModel={viewModel.meetingsViewModel}
                navigate={async () => {
                  await this.updateCampaignMessageBaseView(this.cmTextConstants.CMOverview)
                  this.globalProps.currentStep = OnboardingSteps.CampaignMessages
                }} />
            }
          </>
        }

        {/* Campaign Message list*/}
        {(this.state.currentView === this.cmTextConstants.CMOverview || this.globalProps.currentStep === OnboardingSteps.CampaignMessages) &&
          this.state.currentView !== this.cmTextConstants.EditCampaignMessage && this.state.currentView !== this.cmTextConstants.EditSignature
          &&
          <>
            <div className=" noCampaignGridMargin">
              {(viewModel.hasInitiallyLoaded || (viewModel.clientCampaignMessagesViewModel.pageManager.totalRecords > 0
                || viewModel.clientCampaignMessagesViewModel.isArchived
                || viewModel.clientCampaignMessagesViewModel.searchCriteria.searchString
                || viewModel.clientCampaignMessagesViewModel.followUpTemplatesVM.isArchived
                || viewModel.clientCampaignMessagesViewModel.followUpTemplateSearchCriteria.searchString)) &&
                <ClientCampaignMessageView
                  viewModel={viewModel.clientCampaignMessagesViewModel}
                  back={() => { }}
                  openEditCampaignMessage={async (clientCampaignMessageId: number) => {
                    viewModel.authenticationService.globalProps.clientCampaignMessageId = clientCampaignMessageId;
                    viewModel.isComingFromFollowUpTemplates = false;
                    await this.updateCampaignMessageBaseView(this.cmTextConstants.EditCampaignMessage)
                  }}
                  openEditFollowUpTemplate={async (clientFollowUpTemplateId: number) => {
                    viewModel.authenticationService.globalProps.clientFollowUpTemplateId = clientFollowUpTemplateId;
                    viewModel.isComingFromFollowUpTemplates = true;
                    await this.updateCampaignMessageBaseView(this.cmTextConstants.EditFollowUpTemplate)
                  }}
                  openEditEmailSignature={async (clientCampaignMessageId: number) => {
                    viewModel.authenticationService.globalProps.clientCampaignMessageId = clientCampaignMessageId;
                    viewModel.isComingFromFollowUpTemplates = false;
                    await this.updateCampaignMessageBaseView(this.cmTextConstants.EditSignature)
                  }}
                  openAddEmailSignature={() => { }}
                  openAddCampaignMessage={() => { }}
                  openAddFollowUpTemplate={() => { }}
                />
              }
              {viewModel.clientCampaignMessagesViewModel.pageManager.totalRecords === 0 &&
                !(viewModel.clientCampaignMessagesViewModel.isArchived
                  || viewModel.clientCampaignMessagesViewModel.searchCriteria.searchString
                  || viewModel.clientCampaignMessagesViewModel.followUpTemplatesVM.isArchived
                  || viewModel.clientCampaignMessagesViewModel.followUpTemplateSearchCriteria.searchString)
                && !viewModel.hasInitiallyLoaded &&
                <Neo.Card className="mt-4 CMComingOverview">
                  <div className="row ml-0 mr-0 mt-3 mb-4 p-0">
                    <div className="col-12">
                      <h2>{HtmlParser(viewModel.clientCampaignMessagesViewModel.onboardingCampaignIsComing.headerText)}</h2>
                    </div>
                  </div>
                  <div className="row m-0 p-0 bodyContainer-border">
                    <div className="bodyContainer m-3">
                      {/* Message Container row*/}
                      <div className="row m-0 p-0">
                        <div className="col-12">
                          {HtmlParser(viewModel.clientCampaignMessagesViewModel.onboardingCampaignIsComing.bodyText)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Neo.Card>
              }

              <MeetingDetails
                linkList={viewModel.campaignLinks}
                comxHub={viewModel.comXKnowledgeHub as IComXHubInfo}
                onboardingMeeting={viewModel.meetingsViewModel.meetingLookup}
                cancelMeeting={async () => { await this.cancelMeeting() }}
              />
            </div>

            <div className={`row appFooter ${this.setFooterPadding()}`}>
              {this.globalProps.onboardingSteps.find(x => x.stepNumber === OnboardingSteps.TechnicalIntegration)?.isComplete ?

                <OnboardingNextStepFooter
                  title={textConstants.buttonText.FinishOnboarding}
                  onClick={async () => {
                    await this.onboardingFunctions.finishOnboarding()
                    window.location.href = '/';
                  }}
                  disabled={!this.props.viewModel.canCompleteOnboarding()} />

                : <OnboardingGoToEmailSetupFooter
                  title={textConstants.Onboarding.GoToEmailSetup} onClick={() => {
                    this.globalProps.currentStep = OnboardingSteps.TechnicalIntegration;
                    this.onboardingFunctions.navigateToStep();
                  }} />
              }
            </div>
          </>
        }

        {/* Campaign Message single view */}
        {this.state.currentView === this.cmTextConstants.EditCampaignMessage
          &&
          <CampaignMessageView
            viewModel={viewModel.campaignMessageViewModel}
            back={async () => {
              viewModel.authenticationService.globalProps.clientCampaignMessageId = 0;
              viewModel.isComingFromFollowUpTemplates = false;
              await this.updateCampaignMessageBaseView(this.cmTextConstants.CMOverview);
            }}
          />
        }

        {/* Email Signature view */}
        {this.state.currentView === this.cmTextConstants.EditSignature
          &&
          <CampaignEmailSignatureView
            viewModel={viewModel.emailSignatureViewModel}
            back={async () => {
              viewModel.isComingFromFollowUpTemplates = false;
              viewModel.authenticationService.globalProps.clientCampaignMessageId = 0;
              await this.updateCampaignMessageBaseView(this.cmTextConstants.CMOverview)
            }}
          />
        }

        {/* Follow up template - ADD/EDIT VIEW */}
        {this.state.currentView === this.cmTextConstants.EditFollowUpTemplate
          &&
          <AddEditFollowUpTemplateView
            viewModel={viewModel.addEditFollowUpTemplateViewModel}
            back={async () => {
              this.globalProps.clientFollowUpTemplateId = 0;
              viewModel.isComingFromFollowUpTemplates = true;
              viewModel.clientCampaignMessagesViewModel.isComingFromFollowUpTemplates = true;
              await this.updateCampaignMessageBaseView(this.cmTextConstants.CMOverview)
            }}
          />
        }
      </div>
    );
  }
}