import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class ComXConfiguration extends ModelBase {

    public configurationId: number = 0;

    public configCode: string = "";

    @Attributes.Display("Description")
    public friendlyDescription: string = "";

    public value: string = ""

    public isEditable: boolean = false

    public valueType: number = 0

    public valueTypeDescription: string = ""

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.value === "", textConstants.messageText.validationMessage.value);
        rules.failWhen(c => (isNaN(Number(c.value.trim())) && c.valueTypeDescription === "number"), textConstants.messageText.validationMessage.valueMustBeNumber);
        rules.failWhen(c => c.friendlyDescription === "", textConstants.messageText.validationMessage.description);
        rules.failWhen(c => ((c.value.toLocaleLowerCase() !== "true" && c.value.toLocaleLowerCase() !== "false") && c.valueTypeDescription === "boolean"), textConstants.messageText.validationMessage.valueMustBeBool);
        rules.failWhen(c => (c.value !== null && !this.validateJSON(c.value) && c.valueTypeDescription === "json_object"), textConstants.messageText.validationMessage.valueMustBeJSON);
    }

    public validateJSON(value : string) : boolean {
        var isValidJSON: boolean = false;
        
        try { isValidJSON = typeof JSON.parse(value) === 'object' }
        catch { isValidJSON = false }

        return isValidJSON
    }

    public toString(): string {
        if (this.isNew || !this.friendlyDescription) {
            return "New Configuration";
        } else {
            return this.friendlyDescription;
        }
    }
}