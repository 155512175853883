import { Neo } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { textConstants } from "../../common/textConstants";
import AdvancedEmailSettingsVM from "./AdvancedEmailSettingsVM";
import * as Roles from "../../Models/Security/Roles";
import { Validation } from "@singularsystems/neo-core";
import EmailSetup from 'Views/EmailSetup';
import OutboundEmailsModal from 'Components/Modals/OutboundEmailsModal';
import AdvancedEmailSettingSection from "./AdvancedEmailSettingSection";
import { Spinner } from "reactstrap";
import TestEmailSection from "./TestEmailSection";
import Button from "Components/Button/Button";

export interface IAdvancedEmailSettings {
  viewModel: AdvancedEmailSettingsVM;
  isOnboarding?: boolean;
  isExternal?: boolean;
  addPadding?: boolean;
  removePaddingEmailSetup?: boolean
}

@observer
export default class AdvancedEmailSettings extends React.Component<IAdvancedEmailSettings> {
  private securityCheck(role: any) {
    return (
      this.props.viewModel.authorisationService.hasRole(role) ||
      this.props.viewModel.authenticationService.globalProps.isOnboarding
    );
  }

  public render() {
    return (
      <Neo.Form
        onSubmit={() => this.props.viewModel.saveAdvancedEmailSettings()}
        model={this.props.viewModel.advancedEmailSettingsLookup}
        validationDisplayMode={Validation.DisplayMode.AfterSubmit}
      >
        <div className={"AdvancedEmailSettings " + (this.props.isExternal ? "p-0 marginLeft9" : "")} >

          <div className={"row " + (this.props.isOnboarding ? "p-24" : "")}>
            <div className={"col-6 " + (this.props.isOnboarding ? "mt-3" : "")}>
              <h2>
                {textConstants.TechnicalIntegrationSettings}
              </h2>
            </div>
          </div>

          {/*Outbound emails table Section */}
          <div className="row">
            <div className="col-12">
              {this.props.viewModel.dataHasLoaded &&
                (this.securityCheck(Roles.TI_OutboundEmails.Edit)
                  || this.securityCheck(Roles.TI_OutboundEmails.Delete)
                  || this.securityCheck(Roles.TI_OutboundEmails.View))
                &&
                <EmailSetup
                  hasChanged={this.props.viewModel.hasChanged}
                  outboundEmails={this.props.viewModel.outboundEmailList}
                  updateHasChanged={() => this.props.viewModel.updateHasChanged()}
                  openModal={(id?: number) => this.props.viewModel.openOutboundEmailsModal(id)}
                  removePadding={this.props.removePaddingEmailSetup}
                  isExternal={this.props.isExternal}
                  deleteOutboundEmail={(id) => this.props.viewModel.deleteOutBoundEmailFromComponent(id)}
                />
              }
              {!this.props.viewModel.dataHasLoaded &&
                <div className='text-center p-24 mt-4 mb-3'>
                  <Spinner />
                </div>
              }
            </div>
          </div>

          {/*Advanced email settings */}
          {(this.securityCheck(Roles.TI_AdvancedEmailSettings.View)
            || this.securityCheck(Roles.TI_AdvancedEmailSettings.Edit))
            &&
            <AdvancedEmailSettingSection
              viewModel={this.props.viewModel}
              addPadding={this.props.addPadding}
              isOnboarding={this.props.isOnboarding ?? false}
              hasEditPermissions={this.securityCheck(Roles.TI_AdvancedEmailSettings.Edit)}
            />
          }
          {/*Advanced email settings END */}

          {/*Internal Test */}
          {(this.securityCheck(Roles.TI_InternalTest.View)
            || this.securityCheck(Roles.TI_InternalTest.Edit))
            && this.props.viewModel.dataHasLoaded
            &&
            <TestEmailSection
              viewModel={this.props.viewModel}
              isExternalUser={!this.props.viewModel.isComXUser}
              hasEditPermissions={this.securityCheck(Roles.TI_InternalTest.Edit)}
              addPadding={this.props.addPadding}
              isOnboarding={this.props.isOnboarding ?? false}
            />
          }
          {/*Internal Test END*/}

          {/* Save */}
          <div className={"row"}>
            <div className="col-lg-6">
              <Neo.Button
                isSubmit
                className={this.props.isOnboarding ? "hideSubmitButton" : "mt-2 mb-2 pull-left form-submit"}
                variant="secondary"
              >
                {textConstants.generalText.Save}
              </Neo.Button>
            </div>
          </div>

          {/* Add/Edit Outbound Email modal */}
          {!this.props.isOnboarding
            &&
            <OutboundEmailsModal viewModel={this.props.viewModel} />
          }
        </div>
      </Neo.Form >
    );
  }
}
