import { NeoModel, List } from '@singularsystems/neo-core';
import { AppService, Types } from '../../Services/AppService';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';
import Language from '../../Models/Maintenance/Language';

@NeoModel
export default class LanguageVM extends Views.ViewModelBase {
    
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private languagesApiClient =  AppService.get(Types.ApiClients.LanguagesApiClient),
        private appDataCache = AppService.get(Types.Services.AppDataCache)) {

        super(taskRunner);
    }

    // Properties
    public languageList = new List(Language)

    public async initialise() {        
        const languagesResponse = await this.taskRunner.waitFor(this.languagesApiClient.get());
        this.languageList.set(languagesResponse.data);
    }

    public saveLanguageList(){
        this.taskRunner.run(async () => {
            const response = await this.languagesApiClient.saveList(this.languageList.toJSArray());
            this.languageList.update(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved,textConstants.messageText.saveMessage.LanguagesSaved);

            // Refresh the language common data list
            this.appDataCache.languages.expire(true);
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}