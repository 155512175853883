import styled from "styled-components";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

export const StyledModal = styled(Modal)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 64px;
    isolation: isolate;
    position: sticky;
    width: 560px;
    height: auto;
    top: 120px;
    background: #FFFFFF;
    border-radius: 16px;
`;

interface IStyledModalButtonsProps {
    isSingleButton?: boolean;
}

export const StyledModalButtons = styled.div<IStyledModalButtonsProps>`
    display: ${({ isSingleButton }) => (isSingleButton ? 'flex' : 'block')};
    justify-content: ${({ isSingleButton }) => (isSingleButton ? 'center' : 'space-between')};
    align-items: center;
    margin-top: 32px;
`;

export const StyledModalBody = styled(ModalBody)`
    h2 {
        font-weight: 500;
        font-size: 40px;
        line-height: 46px;
        color: #0B0B0C;
    }

    p {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        color: #374242;
    }
`;

export const StyledModalHeader = styled(ModalHeader)`
  button {
    &.close {
        position: absolute !important;
        right: -10px !important;
        top: -20px !important;
        bottom: unset !important;
    }
  }
`;

export const StyledOverlay = styled.div`
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface IModalProps {
    width: number;
    height: number;
}

export const StyledGenericModal = styled.div<IModalProps>`
    display: block;
    background: white;
    width: ${({ width }) => (width && `${width}px`)};
    height: auto;
    padding: 1rem;
    border-radius: 16px;
`;

