import { ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';

@NeoModel
export default class CloneTargetMarketCommand extends ModelBase {
   
  // Properties
  @Rules.Required()
  public targetMarketId: number = 0;

   @Rules.StringLength(500)
   @Rules.Required()
   public targetMarketName: string = "";

   @Rules.Required()
   public countryId: number = 0;

}