import Axios, { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import TargetMarketCriteria from '../Models/TargetMarket/Query/TargetMarketCriteria';
import { Model, Data, Utils, List } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import CloneTargetMarketCommand from '../Models/TargetMarket/Commands/CloneTargetMarket';
import CommandResult from '../Models/InvitedUsers/Commands/CommandResult';
import DeleteTargetMarketCommand from '../Models/TargetMarket/Commands/DeleteTargetMarket';
import TargetMarketRoleLookup from '../Models/TargetMarket/TargetMarketRoleLookup';
import TargetMarketMiniLookup from '../Models/TargetMarket/TargetMarketMiniLookup';
import UpdateTargetMarketStatusCommand from '../Models/TargetMarket/Commands/UpdateTargetMarketStatus';
import TargetMarketRole from '../Models/TargetMarket/TargetMarketRole';
import TargetMarketSubIndustry from '../Models/TargetMarketSubIndustries/TargetMarketSubIndustry';
import TargetMarketManagementRolesLookup from '../Models/TargetMarket/TargetMarketManagementRolesLookup';
import TargetMarketManagementFunction from '../Models/Maintenance/TargetMarketManagementFunction';
import TargetMarketInformation from '../Models/TargetMarket/TargetMarketInformation';
import { TargetMarketUpdateNameCommand } from '../Models/TargetMarket/Commands/TargetMarketUpdateNameCommand';
import TargetMarketRegionLookup from '../Models/TargetMarket/TargetMarketRegionLookup';
import TargetMarketSubRegion from '../Models/TargetMarket/TargetMarketSubRegion';
import ApiResult from '../Models/ApiResult';
import { ResponseResolver } from '../common/utils';
import TargetMarketSpecificRole from '../Models/TargetMarket/TargetMarketSpecificRole';
import TargetMarketSaveCommand from '../Models/TargetMarket/Commands/TargetMarketSaveCommand';
import TargetMarketCommandResult from 'Models/TargetMarket/Commands/TargetMarketCommandResult';
import ClientTMChanges from 'Models/TargetMarket/ClientTMChanges';

export interface ITargetMarkets {
  getTargetMarkets(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TargetMarketCriteria>>): AxiosPromise<any>,
  cloneTargetMarket(targetMarket: CloneTargetMarketCommand): AxiosPromise<Model.PlainObject<ApiResult>>;
  saveTargetMarket(targetMarket: TargetMarketSaveCommand): AxiosPromise<Model.PlainObject<TargetMarketCommandResult>>;
  deleteTargetMarket(targetMarket: DeleteTargetMarketCommand): AxiosPromise<Model.PlainObject<CommandResult>>;
  getIndustriesAsync(targetMarketId?: number): AxiosPromise<ApiResult>;
  getRegionsAsync(countryId : number, targetMarketId?: number): AxiosPromise<Array<Model.PlainObject<TargetMarketRegionLookup>>>;
  getTargetMarketRolesAndRolesAsync(targetMarketId?: number): AxiosPromise<List<Model.PlainObject<TargetMarketRoleLookup>>>;
  getTargetMarket(targetMarketId: number): AxiosPromise<Model.PlainObject<TargetMarketMiniLookup>>;
  saveTargetMarketAndWhitelist(filelist: File[], targetMarketSaveCommand: TargetMarketSaveCommand): AxiosPromise<TargetMarketCommandResult>;
  updateTargetMarketStatus(targetMarket: UpdateTargetMarketStatusCommand): AxiosPromise<Model.PlainObject<CommandResult>>;
  getTargetMarketRoles(targetMarketId: number): AxiosPromise<Array<Model.PlainObject<TargetMarketRole>>>;
  getTargetMarketSubIndustries(targetMarketId: number): AxiosPromise<Array<Model.PlainObject<TargetMarketSubIndustry>>>;
  getTargetMarketSubRegions(targetMarketId: number): AxiosPromise<Array<Model.PlainObject<TargetMarketSubRegion>>>;
  getTargetMarketSpecificRoles(targetMarketId: number): AxiosPromise<Array<Model.PlainObject<TargetMarketSpecificRole>>>;

  // New
  getTargetMarketManagementRolesAsync(languageCountryId: number, targetMarketId?: number): AxiosPromise<Array<Model.PlainObject<TargetMarketManagementRolesLookup>>>;
  getTargetMaketMangementFunctionsAsync(languageCountryId: number, targetMarketId?: number): AxiosPromise<Array<Model.PlainObject<TargetMarketManagementFunction>>>;

  // Priory
  getTargetMarketManagementFunctionlist(targetMarketId: number): AxiosPromise<ApiResult>
  getTargetMarketManagementRoleList(targetMarketId: number): AxiosPromise<ApiResult>

  // Snapshot
  createTargetMarketSnapshot(targetMarketId: number): AxiosPromise<CommandResult>;

  getDefaultTargetMarketIsClientReview(clientId: number): AxiosPromise<boolean>;
  allowAddTM(clientId: number): AxiosPromise<boolean>;
  allowBatchReviewRevoke(clientId: number): AxiosPromise<boolean>;

  undoTargetMarketDelete(targetMarketId: number): AxiosPromise<boolean>;

  targetMarketInformation(clientId: number) : AxiosPromise<Model.PlainObject<TargetMarketInformation>>;

  updateTargetMarketName(targetMarket: TargetMarketUpdateNameCommand): AxiosPromise<Model.PlainObject<CommandResult>>;
  getClientTargetMarketSteps(targetMarketId: number): AxiosPromise<CommandResult>;

  sendTMChangesZaps(targetMarketId: number, tmChanges: ClientTMChanges): AxiosPromise<boolean>;

  getReferenceTable(targetMarketId: number): AxiosPromise<ApiResult>;
}

@injectable()
export default class TargetMarketsApiClient implements ITargetMarkets {

  constructor(public cnfig = AppService.get(Types.Config)) {
  }

  public saveTargetMarketAndWhitelist(filelist: File[] | FileList, targetMarket: TargetMarketSaveCommand) {
    const bodyFormData = new FormData();
    bodyFormData.set('files', filelist[0]);
    const conversion = targetMarket.toJSObject()
    bodyFormData.set('targetMarket', JSON.stringify(conversion));

    return ResponseResolver<TargetMarketCommandResult>(Axios.post(`${this.cnfig.apiPath}/TargetMarket/SaveTargetMarketAndWhitelist`, bodyFormData, {timeout:600000}));
  }

  public getTargetMarket(targetMarketId: number): AxiosPromise<Model.PlainObject<TargetMarketMiniLookup>> {
    return ResponseResolver<TargetMarketMiniLookup>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarket?targetMarketId=${targetMarketId}`));
  }

  public getTargetMarketRolesAndRolesAsync(targetMarketId?: number | undefined): AxiosPromise<List<Model.PlainObject<TargetMarketRoleLookup>>> {
    if (targetMarketId === undefined) {
     return ResponseResolver<List<TargetMarketRoleLookup>>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketRolesAndRoles`));
    } else {
    return ResponseResolver<List<TargetMarketRoleLookup>>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketRolesAndRoles?targetMarketId=${targetMarketId}`));
    }
  }

  public getTargetMarkets(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TargetMarketCriteria>>): AxiosPromise<any> {
    const apiPath = AppService.get(Types.Config).apiPath;
    return Axios.get(`${apiPath}/TargetMarket/GetTargetMarkets?${Utils.getQueryString(request)}`)
  }

  public cloneTargetMarket(targetMarket: CloneTargetMarketCommand): AxiosPromise<ApiResult> {
    return Axios.post(`${this.cnfig.apiPath}/TargetMarket/CloneTargetMarket`, targetMarket.toJSObject());
  }

  public saveTargetMarket(targetMarket: TargetMarketSaveCommand): AxiosPromise<TargetMarketCommandResult> {
    return ResponseResolver<TargetMarketCommandResult>(Axios.post<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/SaveTargetMarket`, targetMarket.toJSObject()));
  }

  public deleteTargetMarket(targetMarket: DeleteTargetMarketCommand): AxiosPromise<CommandResult> {
    return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/DeleteTargetMarket`, targetMarket.toJSObject()));
  }

  public updateTargetMarketStatus(targetMarket: UpdateTargetMarketStatusCommand): AxiosPromise<CommandResult> {
    return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/UpdateTargetMarketStatus`, targetMarket.toJSObject()));
  }

  public getIndustriesAsync(targetMarketId: number): AxiosPromise<ApiResult> {
    if (targetMarketId === undefined) {
      return Axios.get(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketIndustries`);
    } else {
      return Axios.get(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketIndustries?targetMarketId=${targetMarketId}`);
    }
  }

  public getTargetMaketMangementFunctionsAsync(languageCountryId: number, targetMarketId: number): AxiosPromise<Array<Model.PlainObject<TargetMarketManagementFunction>>> {
    if (targetMarketId === undefined) {
      return ResponseResolver<Array<TargetMarketManagementFunction>>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketManagementFunctions?languageCountryId=${languageCountryId}`));
    } else {
      return ResponseResolver<Array<TargetMarketManagementFunction>>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketManagementFunctions?languageCountryId=${languageCountryId}&targetMarketId=${targetMarketId}`));
    }
  }

  public getTargetMarketManagementRolesAsync(languageCountryId:number, targetMarketId: number): AxiosPromise<Array<Model.PlainObject<TargetMarketManagementRolesLookup>>> {
    if (targetMarketId === undefined) {
      return ResponseResolver<Array<TargetMarketManagementRolesLookup>>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketManagementRoles?languageCountryId=${languageCountryId}`));
    } else {
      return ResponseResolver<Array<TargetMarketManagementRolesLookup>>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketManagementRoles?languageCountryId=${languageCountryId}&targetMarketId=${targetMarketId}`));
    }
  }

  public getTargetMarketRoles(targetMarketId: number): AxiosPromise<Array<Model.PlainObject<TargetMarketRole>>> {
    return ResponseResolver<Array<TargetMarketRole>>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketRoles?targetMarketId=${targetMarketId}`));
  }

  public getTargetMarketSpecificRoles(targetMarketId: number): AxiosPromise<Array<Model.PlainObject<TargetMarketSpecificRole>>> {
    return ResponseResolver<Array<TargetMarketSpecificRole>>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketSpecificRoles?targetMarketId=${targetMarketId}`));
  }

  public getTargetMarketSubIndustries(targetMarketId: number): AxiosPromise<Array<Model.PlainObject<TargetMarketSubIndustry>>> {
    return ResponseResolver<Array<TargetMarketSubIndustry>>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketSubIndustries?targetMarketId=${targetMarketId}`));
  }

    public getTargetMarketSubRegions(targetMarketId: number): AxiosPromise<Array<Model.PlainObject<TargetMarketSubRegion>>> {
      return ResponseResolver<Array<TargetMarketSubRegion>>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketSubRegions?targetMarketId=${targetMarketId}`));
  }

  public getTargetMarketManagementRoleList(targetMarketId: number): AxiosPromise<ApiResult> {
    return Axios.get(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketManagementRoleList?targetMarketId=${targetMarketId}`);
  }

  public getTargetMarketManagementFunctionlist(targetMarketId: number): AxiosPromise<ApiResult> {
    return Axios.get(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketManagementFunctionlist?targetMarketId=${targetMarketId}`);
  }

  public createTargetMarketSnapshot(targetMarketId: number): AxiosPromise<CommandResult> {
    return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/CreateTargetMarketSnapshot?targetMarketId=${targetMarketId}`));
  }

  public getDefaultTargetMarketIsClientReview(clientId: number): AxiosPromise<boolean> {
    return ResponseResolver<boolean>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetDefaultTargetMarketIsClientReview?clientId=${clientId}`));
  }

  public allowAddTM(clientId: number): AxiosPromise<boolean> {
    return ResponseResolver<boolean>(Axios.get(`${this.cnfig.apiPath}/TargetMarket/AllowAddTM?clientId=${clientId}`));
  }

  public allowBatchReviewRevoke(clientId: number): AxiosPromise<boolean> {
    return ResponseResolver<boolean>(Axios.get(`${this.cnfig.apiPath}/TargetMarket/AllowBatchReviewRevoke?clientId=${clientId}`));
  }

  public undoTargetMarketDelete(targetMarketId: number): AxiosPromise<boolean>
  {
    return ResponseResolver<boolean>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/UndoTargetMarketDelete?targetmarketId=${targetMarketId}`));
  }

  public targetMarketInformation(clientId: number) : AxiosPromise<Model.PlainObject<TargetMarketInformation>>{
    return ResponseResolver<TargetMarketInformation>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/TargetMarketInformation?clientId=${clientId}`));
  }
  public updateTargetMarketName(targetMarket: TargetMarketUpdateNameCommand) : AxiosPromise<Model.PlainObject<CommandResult>>
  {
    return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/UpdateTargetMarketName`, targetMarket.toJSObject()));
  }
  public getRegionsAsync(countryId:number, targetMarketId: number, ): AxiosPromise<Array<Model.PlainObject<TargetMarketRegionLookup>>> {
    if (targetMarketId === undefined) {
      return ResponseResolver<Array<TargetMarketRegionLookup>>(Axios.get(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketRegions?countryId=${countryId}`));
    } else {
      return ResponseResolver<Array<TargetMarketRegionLookup>>(Axios.get(`${this.cnfig.apiPath}/TargetMarket/GetTargetMarketRegions?targetMarketId=${targetMarketId}&countryId=${countryId}`));
    }
  }

  public getClientTargetMarketSteps(targetMarketId: number):AxiosPromise<CommandResult> {
    return ResponseResolver<CommandResult>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetClientTargetMarketSteps?targetMarketId=${targetMarketId}`))
  }

  public sendTMChangesZaps(targetMarketId: number, tmChanges: ClientTMChanges): AxiosPromise<boolean> {
    return ResponseResolver<boolean>(Axios.post<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/SendTMChangeZaps/${targetMarketId}`, tmChanges.toJSObject()));
  }

  public getReferenceTable(targetMarketId: number): AxiosPromise<ApiResult> {
    return ResponseResolver<ApiResult>(Axios.get<ApiResult>(`${this.cnfig.apiPath}/TargetMarket/GetReferenceTable?targetMarketId=${targetMarketId}`));
  }
}