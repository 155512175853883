import { Attributes, List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import OnboardingMaintenance from 'Models/Maintenance/OnboardingMaintenance';
import InfoVideoVM from '../../Components/InfoVideoVM';
import OnboardingFunctions from 'Models/Onboarding/OnboardingFunctions';

@NeoModel
export default class CampaignMessageComingVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private onboardingMaintenanceApiClient = AppService.get(Types.ApiClients.OnboardingMaintenanceApiClient),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        public authenticationService = AppService.get(Types.Security.CustomAuthenticationService),
        ) {

        super(taskRunner);
    }

    public comXConfig: any = this.appDataCache.comXConfigurations.get().data
    @Attributes.Display("")
    public knowledgeHubPassword: string = ""
    public videoURL: string = ""
    public onboardingMaintenanceList = new List(OnboardingMaintenance);
    public onboardingCampaignIsComing: OnboardingMaintenance = new OnboardingMaintenance
    public showConfirmationModal = false;

    public async initialise() {
        var comXConfig = this.appDataCache.comXConfigurations.get().data

        // Get OnboardingMaintenance
        const onboardingMaintenanceResponse = await this.taskRunner.waitFor(this.onboardingMaintenanceApiClient.get());
        this.onboardingMaintenanceList.set(onboardingMaintenanceResponse.data);
        let onboardingMaintenanceItem = this.onboardingMaintenanceList.find(maintenanceItem => maintenanceItem.pageCode === "CMComing")
        
        if (onboardingMaintenanceItem){
            this.onboardingCampaignIsComing = onboardingMaintenanceItem
        }

        if (comXConfig) {
            this.knowledgeHubPassword = comXConfig.find((config: any) => config.configCode === "KnowledgeHubPassword")?.value as string
            this.videoURL = comXConfig.find((config: any) => config.configCode === "CampaignMessageComingVideo")?.value as string
        }
    }
}