import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import ClientLookup from '../Models/Client/ClientLookup';
import { AppService, Types } from '../Services/AppService';

@NeoModel
export default class ClientNavigationButtonsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner)) {

        super(taskRunner);
    }

    public navigateTo: string = ""
    public clientName: string = "";
    public isCampaignMessages: boolean = false;
    public isTargetMarkets: boolean = false;
    public isClientSettings: boolean = false;
    public isIdealCustomerProfiles: boolean = false;
    public isReporting: boolean = false;

    public async initialise() {
    }
}