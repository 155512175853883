import { Attributes, ModelBase, NeoModel} from '@singularsystems/neo-core';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';
import { IBulkActionGrid, selectedItem } from '../ComxDataGridManager/BulkActionPageManager';


@NeoModel
export default class GreyListedProspectLookup extends ModelBase implements IBulkActionGrid{

    @selectedItem(["greylistedProspectId"])
    @Display("Select")
    public IsSelected : boolean = false
    
    public greylistedProspectId: number = 0;

    @Attributes.Display("Email")
    public emailAddress: string = "";

    @Attributes.Display("Date Added")
    public dateAdded: string = "";

    @Attributes.Display("Added By")
    public addedBy: string = "";
}