import Axios, { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { Model, List } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import ManagementFunction from '../Models/Maintenance/ManagementFunction';
import CommandResult from '../Models/InvitedUsers/Commands/CommandResult';
import { ResponseResolver } from '../common/utils';

export interface IManagementFunctionsApiClient {
    getManagementFunctionsAsync(): AxiosPromise<Array<Model.PlainObject<ManagementFunction>>>;
    insertUpdateManagementFunctions(managementFunctions: List<ManagementFunction>): AxiosPromise<Model.PlainObject<CommandResult>>;
}

@injectable()

export default class ManagementFunctionsApiClient implements IManagementFunctionsApiClient {

    constructor(public cnfig = AppService.get(Types.Config)) {
    }

    public getManagementFunctionsAsync(): AxiosPromise<Array<Model.PlainObject<ManagementFunction>>> {
        return ResponseResolver<Array<Model.PlainObject<ManagementFunction>>>(Axios.get(`${this.cnfig.apiPath}/ManagementFunction/getManagementFunctions`));
    }

    public insertUpdateManagementFunctions(managementFunctions: List<ManagementFunction>): AxiosPromise<{ success: boolean; error: string; id: number; }> {
        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.cnfig.apiPath}/ManagementFunction/SaveManagementFunctions`, managementFunctions.toJSArray()));
    }
}