import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from '../../../common/textConstants';
import { isStringEmpty, isURL } from '../../../common/utils';

@NeoModel
export default class EditClient extends ModelBase {
  
  public clientId: number = 0;
  
  public clientName: string = "";

  @Attributes.Display(textConstants.titleText.ClientReview)
  public isClientReview: Nullable<boolean> = false;

  @Attributes.Display(textConstants.titleText.DoNotBlacklistWoodpecker)
  public doNotBlacklistWoodpecker: boolean = false;

  public platformSetup: string = ""

  public successTracking: string = "https://app.woodpecker.co/"

  public actionPlanChecklist: string = ""

  @Attributes.Display(textConstants.titleText.WoodpeckerAPI)
  public woodpeckerApiKey: string = ""

  @Attributes.Display(textConstants.titleText.CampaignMessagesReview)
  public isCampaignMessagesReview: boolean = true
  
  @Rules.Required()
  public maxTargetMarkets: number = 1

  @Attributes.Display("Customer Success Manager")
  public calendlyLinkId: number = 1

  // Client Rules
  public addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
    rules.failWhen(c => c.clientName === null || c.clientName.trim() === '', textConstants.messageText.validationMessage.clientName);
    rules.failWhen(c => c.maxTargetMarkets === 0  , textConstants.messageText.validationMessage.maxTargetMarkets);
    rules.failWhen(c => isStringEmpty(c.actionPlanChecklist) || !isURL(c.actionPlanChecklist), textConstants.messageText.validationMessage.actionPlanChecklistMandatory);
  }
}