import { Neo } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { textConstants } from './../../common/textConstants';
import CampaignMessageComingVM from "./CampaignMessageComingVM";
import HtmlParser from "react-html-parser";
import { OnboardingSteps } from "Models/Enums/ApplicationEnums";

interface ICampaignMessageComingViewProps {
    navigate: () => void,
    viewModel: CampaignMessageComingVM,
}

@observer
export default class CampaignMessageComingView extends React.Component<ICampaignMessageComingViewProps> {

    public render() {
        return (
            <React.Fragment>
                <div className="row m-0 p-0 CampaignMessageComingView">
                    <Neo.Card>

                        {/* Header row */}
                        <div className="row ml-0 mr-0 mt-3 mb-4 p-0">
                            <div className="col-12">
                                <h2>{HtmlParser(this.props.viewModel.onboardingCampaignIsComing.headerText)}</h2>
                            </div>

                        </div>

                        {/* Body Container row*/}
                        <div className="row m-0 p-0 ">
                            <div className="bodyContainer m-3">
                                {/* Message Container row*/}
                                <div className="row m-0 p-0">
                                    <div className="col-12">
                                        {HtmlParser(this.props.viewModel.onboardingCampaignIsComing.bodyText)}
                                    </div>

                                </div>

                                {/* Button Container row*/}
                                <div className="row ml-0 mr-0 mt-3">
                                    <div className="col-12 buttonContainer">
                                        <button
                                            className="scheduleMeetingButton"
                                            onClick={() => this.props.navigate()}>
                                            {textConstants.Onboarding.ScheduleAMeeting}
                                        </button>
                                    </div>
                                    <a className="m-auto pt-3 p-24 EditLinkButton" onClick={() => this.props.viewModel.showConfirmationModal = true}>
                                        {textConstants.Onboarding.AlreadyScheduledOnboardingMeeting}
                                    </a>
                                </div>
                            </div>
                        </div>

                    </Neo.Card>
                </div>

                {/* Confirmation modal for skipping schedule a meeting */}
                <Neo.Modal
                    title={textConstants.Onboarding.AlreadyScheduledMeeting}
                    show={this.props.viewModel.showConfirmationModal}
                    onClose={() => (this.props.viewModel.showConfirmationModal = false)}
                    closeButton={{ text: "No", variant: "light" }}
                    acceptButton={{
                        text: "Yes",
                        variant: "secondary",
                        onClick: () => {
                            this.props.viewModel.authenticationService.onboardingFunctions.updateOnboardingStep()
                            this.props.viewModel.authenticationService.globalProps.currentStep = OnboardingSteps.CampaignMessages;
                        }
                    }}>
                    {textConstants.Onboarding.SkipScheduleMeeting}
                </Neo.Modal>
            </React.Fragment>
        );
    }

}