import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class DomainVariation extends ModelBase{

    public domainVariationId: number = 0;

    public countryId: number = 0;    

    @Rules.StringLength(500)
    @Attributes.Display("Domain Variation")
    public variation: string = "";

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.variation === null || c.variation === '', textConstants.messageText.validationMessage.regionName);
    }

    public toString(): string {
        if (this.isNew || !this.variation) {
            return "New Domain Variation";
        } else {
            return this.variation;
        }
    }
}