import styled from "styled-components";



export const StyledCompeteYourStepWrapper = styled.div `
      display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SyledCompleteYourStep = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

`;

export const StyledSvgWrapper = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;

    margin-top: -48px;
    img {
        width: 45%;
    }
`;

export const StyledPaper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  @media (min-width: 1024px) {
    width: 40%;
  }
`;

export const StyledStepList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
    font-size: 18px;
    color: #0b0b0c;
`;

export const StyledListItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;

`;