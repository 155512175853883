import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class AccountStatus extends ModelBase {

    public accountStatusId: number = 0;
    
    @Rules.StringLength(500)
    @Attributes.Display("Account Status")
    public accountStatusName: string = "";

    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.accountStatusName === null || c.accountStatusName === '', textConstants.messageText.validationMessage.accountStatusName);
    }

    public toString(): string {
        if (this.isNew || !this.accountStatusName) {
            return "New account status";
        } else {
            return this.accountStatusName;
        }
    }
}