import { NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../Services/AppService";
import CampaignEmailSignatureHelper from "./CampaignEmailSignatureHelper";


@NeoModel
export default class CampaignEmailSignatureVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient),
    private authService = AppService.get(Types.Neo.Security.AuthenticationService),
    private campaignMessagesApiClient = AppService.get(Types.ApiClients.CampaignMessagesApiClient),
    public appDataCache = AppService.get(Types.Services.AppDataCache),
) {
    super(taskRunner);
  }
  
  public clientCampaignMessageId : number = 0

  public clientId : number = 0
  public clientCampaignMessageStatusId : number = 0
  public isComXUser: boolean | null = null  
  public campaignMessageStatuses = this.appDataCache.campaignMessageStatuses.get().data.sortBy("messageStatus");
  public drafCampaignMessageStatusId = this.campaignMessageStatuses.find(cm => cm.uniqueTableKey === "CampaignMessageStatus1")?.campaignMessageStatusId

  public campaignEmailSignatureHelper: CampaignEmailSignatureHelper = new CampaignEmailSignatureHelper(
    this.taskRunner,
    this.campaignMessagesApiClient,
    this.notifications,
    this.clientId    
  )

  public async initialise() {
    const isClientResponse = await this.invitedUsersApiClient.isClientUser(this.authService!.user!.userName);
    this.isComXUser = !isClientResponse.data

    if (this.clientCampaignMessageId > 0) // Edit
    {
        await this.campaignEmailSignatureHelper.fetchCampaignSignature(this.isComXUser, this.clientCampaignMessageId);
    } else  // Add
    {
      await this.campaignEmailSignatureHelper.setupNewCampaignSignature(this.isComXUser, this.clientId);
    }
  }
}