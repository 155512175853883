import { injectable } from 'inversify';
import { Data, List, Model } from '@singularsystems/neo-core';
import Country from '../Models/Maintenance/Country';
import { AppService, Types } from '../Services/AppService';
import Axios, { AxiosPromise } from 'axios';
import CommandResult from '../Models/InvitedUsers/Commands/CommandResult';
import { ResponseResolver } from '../common/utils';

export interface ICountriesApiClient extends Data.IUpdateableApiClient<Country, number> {
    getCountriesAsync(): AxiosPromise<Array<Model.PlainObject<Country>>>;
    insertUpdateCountries(countries: List<Country>): AxiosPromise<Model.PlainObject<CommandResult>>;
    getDomainVariationsAsync(): AxiosPromise<Array<Model.PlainObject<Country>>>;
    saveDomainVariations(countries: List<Country>): AxiosPromise<Model.PlainObject<CommandResult>>;
}

@injectable()
export default class CountriesApiClient extends Data.UpdateableApiClient<Country, number> implements ICountriesApiClient {

    constructor(public config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/country`);
    }

    public getCountriesAsync(): AxiosPromise<Array<Model.PlainObject<Country>>> {
        return ResponseResolver<Array<Model.PlainObject<Country>>>(Axios.get(`${this.config.apiPath}/Country/getCountries`));
    }

    public insertUpdateCountries(countries: List<Country>): AxiosPromise<{ success: boolean; error: string; id: number; }> {
        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.config.apiPath}/Country/SaveCountries`, countries.toJSArray()));
    }

    public getDomainVariationsAsync(): AxiosPromise<Array<Model.PlainObject<Country>>> {
        return ResponseResolver<Array<Model.PlainObject<Country>>>(Axios.get(`${this.config.apiPath}/Country/getDomainVariations`));
    }

    public saveDomainVariations(countries: List<Country>): AxiosPromise<{ success: boolean; error: string; id: number; }> {
        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.config.apiPath}/Country/SaveDomainVariations`, countries.toJSArray()));
    }
}