import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import ReportingVM from './DashboardReportConfigVM';
import { observer } from 'mobx-react';
import DashboardReportConfigVM from './DashboardReportConfigVM';
import GoBack from '../../Components/GoBack';
import { textConstants } from '../../common/textConstants';
import DemoClientDataVM from './DemoClientDataVM';
import { MockDataJSONType } from 'Models/Enums/ApplicationEnums';
import * as Icon from 'react-feather';


@observer
export default class DemoClientDataView extends Views.ViewBase<DemoClientDataVM> {

    constructor(props: unknown) {
        super("Demo Client", DemoClientDataVM, props);
    }

    public async onDocumentSelected(fileList: File[] | FileList) {
        this.viewModel.uploadJsonFile(fileList);
    }

    public openModal(demoClientId: number) {
        this.viewModel.demoClientId = demoClientId;
        this.viewModel.showModal = true;
    }

    public render() {

        return (
            <div className='demoClientMaintenance'>
                <GoBack />
                <Neo.Card>
                    <h2 className='p-24 mt-4'>{textConstants.titleText.DemoClientData}</h2>
                    <Neo.Form model={this.viewModel.demoClientList} onSubmit={() => { this.viewModel.saveDemoClients() }} showSummaryModal>
                        <NeoGrid.Grid items={this.viewModel.demoClientList}
                            showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
                            {(demoClient, demoClientMeta) => (
                                <NeoGrid.Row key={demoClient.demoClientId}>

                                    {/* Client Column */}
                                    <NeoGrid.Column
                                        label={textConstants.titleText.Client}
                                        sort={false}
                                        width={400}
                                        bind={demoClientMeta.clientId}
                                        defaultValue={demoClient.clientId}
                                        select={{ items: this.viewModel.clients }}
                                    />
                                    <NeoGrid.Column />

                                    {/* reply Experience Mock Data */}
                                    <NeoGrid.ButtonColumn
                                        label={textConstants.DemoClients.replyExperience}>
                                        <Neo.Button
                                            disabled={demoClient.clientId === 0}
                                            variant="light" icon={<Icon.Mail size={16} />}
                                            tooltip={textConstants.DemoClients.replyExperience}
                                            onClick={() => {
                                                this.viewModel.mockDataTitle = textConstants.DemoClients.replyExperience
                                                this.viewModel.jsonType = MockDataJSONType.ReplyExperience
                                                this.openModal(demoClient.demoClientId)
                                            }} />
                                    </NeoGrid.ButtonColumn>

                                    {/* Campaign Report Statistics Mock Data */}
                                    <NeoGrid.ButtonColumn
                                        label={textConstants.DemoClients.campaignMessageStatistics}>
                                        <Neo.Button
                                            disabled={demoClient.clientId === 0}
                                            variant="light" icon={<Icon.Edit />}
                                            tooltip={textConstants.DemoClients.campaignMessageStatistics}
                                            onClick={() => {
                                                this.viewModel.mockDataTitle = textConstants.DemoClients.campaignMessageStatistics
                                                this.viewModel.jsonType = MockDataJSONType.CampaignMessageStatistics
                                                this.openModal(demoClient.demoClientId)
                                            }} />
                                    </NeoGrid.ButtonColumn>

                                    {/* Campaign Reports Monthly Mock Data */}
                                    <NeoGrid.ButtonColumn
                                        label={textConstants.DemoClients.campaignReportsMonthly}>
                                        <Neo.Button
                                            disabled={demoClient.clientId === 0}
                                            variant="light" icon={<Icon.BarChart />}
                                            tooltip={textConstants.DemoClients.campaignReportsMonthly}
                                            onClick={() => {
                                                this.viewModel.mockDataTitle = textConstants.DemoClients.campaignReportsMonthly
                                                this.viewModel.jsonType = MockDataJSONType.CampaignGraphMonthly
                                                this.openModal(demoClient.demoClientId)
                                            }} />
                                    </NeoGrid.ButtonColumn>

                                    {/* Campaign Reports Dails Mock Data */}
                                    <NeoGrid.ButtonColumn
                                        label={textConstants.DemoClients.campaignReportsDaily}>
                                        <Neo.Button
                                            disabled={demoClient.clientId === 0}
                                            variant="light" icon={<Icon.BarChart2 />}
                                            tooltip={textConstants.DemoClients.campaignReportsDaily}
                                            onClick={() => {
                                                this.viewModel.mockDataTitle = textConstants.DemoClients.campaignReportsDaily
                                                this.viewModel.jsonType = MockDataJSONType.CampaignGraphDaily
                                                this.openModal(demoClient.demoClientId)
                                            }} />
                                    </NeoGrid.ButtonColumn>

                                    {/* Is Active Column */}
                                    <NeoGrid.Column className={"pr-3"} width={50} alignment={"right"} label={"Active"} bind={demoClientMeta.isActive} />
                                </NeoGrid.Row>
                            )}
                        </NeoGrid.Grid>
                        <div className="my-4 text-right p-24">
                            <Neo.Button className="btn-width-100" isSubmit variant="success">{textConstants.buttonText.Save}</Neo.Button>
                        </div>
                    </Neo.Form>

                    {/* Change the upload  */}
                    <Neo.Modal title={this.viewModel.mockDataTitle} show={this.viewModel.showModal}
                        onClose={() => this.viewModel.showModal = false}
                        closeButton={{ text: "Close", variant: "light" }}
                    >
                        <Neo.GridLayout xl={1} lg={1} md={1} sm={1} xs={1} className="">
                            <ol>
                                <a className="text-right customDownloadLink"
                                    onClick={() => this.viewModel.download()}>
                                    {textConstants.buttonText.Download}
                                </a>
                            </ol>
                            <Neo.FileContext onFilesSelected={(e) => this.onDocumentSelected(e)} allowedExtensions={[".json"]}>
                                <Neo.FileDropArea multiple={false}>
                                    <p className="text-center dropZoneText">{textConstants.titleText.ToUpload}
                                        <Neo.FileUploadButton className="ml-2 mt-2" variant="secondary">{textConstants.generalText.ClickHere}</Neo.FileUploadButton></p>
                                </Neo.FileDropArea>
                            </Neo.FileContext>
                        </Neo.GridLayout>
                    </Neo.Modal>
                </Neo.Card>
            </div >
        );
    }
}