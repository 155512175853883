import { Views } from "@singularsystems/neo-react";
import { NeoModel, Data, List, ModalUtils } from '@singularsystems/neo-core';
import { AppService, Types } from "../../Services/AppService";
import InformationManagementDetail from "../../Models/Maintenance/InformationManagementDetail";
import UpdateShowLeadInfoBarCommand from "../../Models/Client/Commands/UpdateShowLeadInfoBarCommand";
import { textConstants } from "common/textConstants";
import FollowUpTemplateCriteria from 'Models/CampaignMessage/Query/FollowUpTemplateCriteria';
import FollowUpTemplateLookup from 'Models/CampaignMessage/FollowUpTemplateLookup';
import ActionListClientFollowUpTemplateLookup from 'Models/CampaignMessage/ActionListClientFollowUpTemplateLookup';
import ActionListFollowUpTemplateLookup from 'Models/CampaignMessage/ActionListFollowUpTemplateLookup';
import ActionListStatus from 'Models/ActionList/ActionListStatus';
import { IOptions } from 'Components/CustomDropDown/CustomDropDown';
import ActionListLookupData from 'Models/ActionList/ActionListLookupData';
import LeadTypeStatus from 'Models/ActionList/LeadTypeStatus';
import EmailCommand from 'Models/ComXMailer/Email';
import jwtDecode from 'jwt-decode';
import { getEmailAddressFromAccessToken } from 'common/utils';
import ResponseLookup from 'Models/ActionList/ResponseLookup';
import ReminderVM from 'Components/Reminder/ReminderVM';
import ReplyLookupData from "Models/ActionList/ReplyLookupData";

@NeoModel
export default class LeadMessageVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private informationManagementApiClient = AppService.get(Types.ApiClients.InformationManagementApiClient),
        private invitedUserApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient),
        private authenticationService = AppService.get(Types.Neo.Security.AuthenticationService),
        private campaignMessagesApiClient = AppService.get(Types.ApiClients.CampaignMessagesApiClient),
        private actionListApiClient = AppService.get(Types.ApiClients.ActionListApiClient),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        public customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
        private comXConfigApiClient = AppService.get(Types.ApiClients.ComXConfigurationsApiClient),
        private comXHub = AppService.get(Types.ApiClients.ComXHub)
    ) {
        super(taskRunner);
        this.connectToComXHub();
    }

    public selectedLeadType: string | null = ""
    public leadTypeStatus: number = 0;
    public clientId: number = 0;
    public prospectId: number = 0;
    public campaignId: number = 0;
    public messageFrom: string = "";
    private userName = "";
    public infoDetails: List<InformationManagementDetail> = new List(InformationManagementDetail);
    public isInformationBarVisible = false;
    public infoBarContent: string = "";
    public infoBarRedirectUrl: string = "";
    public specificFollowUpTemplates = new List(FollowUpTemplateLookup)
    public templateText: string | undefined = undefined;
    public individualTemplatesList = new List(ActionListFollowUpTemplateLookup)

    public mailToLink: string = "";
    public isAbleToSendLink: boolean = true;

    public emailMessageText: string = "";
    public emailMessageList = new List(FollowUpTemplateLookup);
    public selectedClientFollowUpTemplate: number = 0;
    public selectedClientFollowUpTemplateName: string = "";

    public isMessageCreatorOpen: boolean = false;
    public isSearchingTemplates: boolean = false;
    public isMaximized: boolean | null = null;
    public searchCriteria: FollowUpTemplateCriteria = new FollowUpTemplateCriteria;

    public placeholderText: string = textConstants.LeadEmailCreator.NewMessage;
    public searchString: string = "";
    public followupTemplateRibbonDisplay: boolean = false;
    public currentMessage: string = ""
    public currentSubject: string = ""
    public currentReplyId: string = ""
    public messageContent: string = ""
    public currentMessageIndex: number = 0

    public leadLookup: ActionListLookupData | undefined = new ActionListLookupData();

    public filteredFollowUpTemplateList = new List(ActionListClientFollowUpTemplateLookup)
    public followUpTemplateList = new List(ActionListClientFollowUpTemplateLookup)
    public actionListStatuses = this.appDataCache.actionListStatuses.get().data as Array<ActionListStatus>;
    public leadTypeStatuses = this.appDataCache.leadTypeStatuses.get().data as Array<LeadTypeStatus>;

    public reminderVM: ReminderVM = new ReminderVM();

    public statuses: IOptions[] = []
    public savedEmail = new EmailCommand
    public accessTokenToUse: string | null = null
    public emailFromOptions: any[] = []
    public selectedEmailFrom: any
    public showInvalidDataModal: boolean = false
    public invalidDataMessage: string = ""
    public replyLookup = new ReplyLookupData

    public toggleMenuSelection(categoryName: string) {
        this.selectedLeadType = categoryName
    }

    public async initialise() {
        const infoManagementResponse = await this.taskRunner.waitFor(this.informationManagementApiClient.get());
        this.infoDetails.set(infoManagementResponse.data)
        await this.setInfobar(this.infoDetails);
        this.searchCriteria.clientId = this.clientId
        await this.getFollowUpTemplates()

        await this.cleanOldAccessTokens()

        this.customAuthService.globalProps.isZendeskVisible = false;
        this.customAuthService.globalProps.showActionListLeadMessageSideMenu = true;
        await this.getReply();
        this.generateStatuses();
        if (localStorage.getItem(textConstants.LocalStorage.savedEmail) === null) {
            this.pushToMessageContent()
        }
        this.getReminders();
        this.pushToMessageContent()
        await this.populateEmailsDropdown()
        await this.setPageState()
    }

    // If the base url has changed this will remove the old access tokens.
    private async cleanOldAccessTokens() {

        // Counters to remove the isNewToken stored item.
        let emailCount = 0;
        let deletedEmailCount = 0;

        // Loops through everything in localstorage.
        for (const currentKey in localStorage) {
            let shortenedKey = ""
            let jwt: string | null = null
            let storageKey: string | null = null

            // Checks the starting and ending of the currentKey
            if (currentKey.startsWith(textConstants.LocalStorage.comx) &&
                currentKey.endsWith(textConstants.LocalStorage.AccessToken)) {

                emailCount++;
                storageKey = currentKey
                shortenedKey = currentKey.replace(textConstants.LocalStorage.AccessToken, "")

                // gets the item in storage
                jwt = localStorage.getItem(currentKey);
            }

            if (jwt) {
                // Decodes the jwt
                var decoded = jwtDecode(jwt) as any

                if (decoded) {
                    // ["iss"] is the property for the base url.
                    var baseUrl = decoded["iss"]

                    // Gets the base url from configurations.
                    let comXConfig = (await this.comXConfigApiClient.get()).data;

                    let authServiceUrl = comXConfig.find(x => x.configCode === textConstants.generalText.ComXAuthServiceURL)?.value

                    if (authServiceUrl && `${baseUrl}/` === authServiceUrl) {
                        return
                    }
                    else {
                        // Removes all the specific storage items.
                        localStorage.removeItem(`${shortenedKey}${textConstants.LocalStorage.AccessToken}`)
                        localStorage.removeItem(`${shortenedKey}${textConstants.LocalStorage.RefreshToken}`)

                        deletedEmailCount++;
                    }

                    if (emailCount === deletedEmailCount) {
                        // Remove the isNewToken storage item
                        localStorage.removeItem(textConstants.LocalStorage.isNewToken)
                    }
                }
            }
        }
    }

    // Determine what should happen when the page loads. There are 3 possible outcomes:
    // 1) Keep the email creator closed, because the user has manually navigated to the page
    // 2) Open the email creator, because the user has just clicked Create Message
    // 3) Send an email and keep the email creator closed, because the user has just clicked Send Message, but had an expired token
    private async setPageState() {
        // Check isNewToken. If it has an email address, we know the token has just been refreshed via send email (via refreshToken). 
        // Set this new token as the one to use
        const { isNewToken, savedEmail } = textConstants.LocalStorage

        if (localStorage.getItem(isNewToken)?.includes("@")) {
            this.savedEmail = JSON.parse(localStorage.getItem(savedEmail)!)

            this.setNewestAccessTokenAsTokenToUse();

            localStorage.setItem(isNewToken, textConstants.LocalStorage.false)
            await this.sendEmail(true)
        }

        // A new access token has been received (via Create Message or Add Other Email)
        if (localStorage.getItem(isNewToken) === textConstants.LocalStorage.true) {
            await this.openEmailCreator()
            localStorage.setItem(isNewToken, textConstants.LocalStorage.false)
            let shouldSetDropdownSelection = true
            this.setNewestAccessTokenAsTokenToUse(shouldSetDropdownSelection);
        }
    }

    private setNewestAccessTokenAsTokenToUse(shouldSetDropdownSelection: boolean = false) {
        let existingAccessTokenKeys: string[] = [];

        // Find all of the user's access tokens
        for (const currentKey in localStorage) {
            if (currentKey.startsWith(textConstants.LocalStorage.comx) && currentKey.endsWith(textConstants.LocalStorage.AccessToken)) {
                existingAccessTokenKeys.push(currentKey);
            }
        }

        let newestTokenKey: string = ""
        let newestTokenValue: string | null = null;
        let newestTokenExpirationTime: number = 0;

        // Determine which token is the newest
        for (let key of existingAccessTokenKeys) {
            let accessTokenValue = localStorage.getItem(key);

            if (accessTokenValue) {
                let accessToken = jwtDecode(accessTokenValue) as { exp: number };

                if (accessToken && accessToken.exp) {
                    let currentTokenExpirationTime = accessToken.exp;

                    if (currentTokenExpirationTime > newestTokenExpirationTime) {
                        newestTokenValue = accessTokenValue;
                        newestTokenKey = key
                        newestTokenExpirationTime = currentTokenExpirationTime;
                    }
                }
            }
        }

        // Set the token
        this.accessTokenToUse = newestTokenValue;

        if (shouldSetDropdownSelection) {
            let emailAddress = this.extractEmailFromAccessTokenKey(newestTokenKey)
            this.selectedEmailFrom = this.emailFromOptions.find(option => option.value === emailAddress);
        }
    }

    public async clickCreateMessage() {
        // Determine the "to" (to be used as "from") address
        await this.setToAddressTokenAsTokenToUse();
        // Do first time sign-in if the user doesn't have any access tokens
        if (this.accessTokenToUse === null && this.emailFromOptions.length < 2) {
            await this.beginNewEmailSignIn();
        } else {
            await this.openEmailCreator()
        }
    }

    private async setToAddressTokenAsTokenToUse() {
        // Find access token matching the "to" address
        let toAddress = this.leadLookup?.campaign_email;
        if (toAddress) {
            for (const currentKey in localStorage) {
                if (currentKey.startsWith(textConstants.LocalStorage.comx) &&
                    currentKey.endsWith(textConstants.LocalStorage.AccessToken) &&
                    this.extractEmailFromAccessTokenKey(currentKey) === toAddress) {
                    this.accessTokenToUse = localStorage.getItem(currentKey);
                }
            }

            // Select the relevant email in the dropdown
            this.selectedEmailFrom = this.emailFromOptions.find(option => option.value === toAddress);
        }
    }

    private async beginNewEmailSignIn() {
        let comXConfig = (await this.comXConfigApiClient.get()).data;
        let baseURL = await this.getComXAuthServiceBaseURL(comXConfig);
        let scopes = comXConfig.find((config: any) => config.configCode === textConstants.generalText.ComXAuthServiceScopes)?.value as string;
        let redirectToGatsbyURL = window.location.href;

        localStorage.setItem(textConstants.LocalStorage.isNewToken, textConstants.LocalStorage.true);

        // sign in
        window.location.href = `${baseURL}auth/signin?redirect_uri=${redirectToGatsbyURL}&scopes=${scopes}`;
    }

    private async getComXAuthServiceBaseURL(comXConfig: any) {
        return comXConfig.find((config: any) => config.configCode === textConstants.generalText.ComXAuthServiceURL)?.value as string
    }

    public async openEmailCreator() {
        this.isMessageCreatorOpen = true
        this.isMaximized = null
    }

    public async sendEmail(showModal: boolean = false) {
        // Get a new token if necessary, then send the email after re-direct
        if (await this.isAccessTokenExpired()) {
            await this.saveEmailToLocalStorage()
            await this.refreshAccessToken()
            return
        }

        // Send the email
        await this.taskRunner.run(async () => {
            if (this.accessTokenToUse) {

                let emailAddressFrom = getEmailAddressFromAccessToken(this.accessTokenToUse)

                if (emailAddressFrom) {
                    // note - the rest of the email object is populated in EmailComponent.tsx -> populateEmailToSend()
                    this.savedEmail.from = emailAddressFrom
                    this.savedEmail.accessToken = this.accessTokenToUse

                    let response = await this.actionListApiClient.sendEmail(this.savedEmail, this.currentReplyId)

                    // Failure
                    if (!response.data.success) {
                        if (showModal) {
                            // For some reason, this.notifications doesn't work after re-direct, so use a modal in that case
                            ModalUtils.showMessage(textConstants.titleText.Error, textConstants.ActionListText.EmailFailed)
                        } else {
                            this.notifications.addDanger(textConstants.titleText.Error, response.data.message)
                        }
                        return
                        // Success
                    } else {
                        localStorage.removeItem(textConstants.LocalStorage.savedEmail)
                        this.isMessageCreatorOpen = false
                        if (showModal) {
                            // For some reason, this.notifications doesn't work after re-direct, so use a modal in that case
                            ModalUtils.showMessage(textConstants.titleText.Success, textConstants.ActionListText.EmailSuccessful)
                        } else {
                            this.notifications.addSuccess(textConstants.titleText.Success, response.data.message)
                        }
                        return
                    }
                }
                this.notifications.addDanger("Error", textConstants.generalText.SenderNotFound)
                return
            }
        })
    }

    public isEmailSelected() {
        if (this.selectedEmailFrom === undefined) {
            this.invalidDataMessage = textConstants.ActionListText.PleaseSelectEmail
            this.showInvalidDataModal = true
            return false
        }
        return true
    }

    public connectToComXHub() {
        this.comXHub.UpdateResponse.subscribe(this.updateResponseInformation);
    }

    public updateResponseInformation = (response: ResponseLookup) => {
        if (response.replyId === this.currentReplyId) {
            this.replyLookup.responded_back_at = response.respondedAt;
        }
    }

    private async refreshAccessToken() {
        // Setup
        let comXConfig = (await this.comXConfigApiClient.get()).data
        let baseURL = await this.getComXAuthServiceBaseURL(comXConfig)
        let redirectToGatsbyURL = window.location.href;
        let emailAddress = getEmailAddressFromAccessToken(this.accessTokenToUse)
        localStorage.setItem(textConstants.LocalStorage.isNewToken, emailAddress)
        let refreshToken = null

        // Get the appropriate refresh token
        for (const currentKey in localStorage) {
            if (currentKey.startsWith(textConstants.LocalStorage.comx) &&
                currentKey.endsWith(textConstants.LocalStorage.RefreshToken) &&
                this.extractEmailFromAccessTokenKey(currentKey) === emailAddress) {
                refreshToken = localStorage.getItem(currentKey);
            }
        }

        // Get the new access token
        window.location.href = `${baseURL}auth/token?redirect_uri=${redirectToGatsbyURL}&refresh_token=${refreshToken}`
    }

    private async isAccessTokenExpired() {
        if (this.accessTokenToUse) {
            const decodedToken = jwtDecode(this.accessTokenToUse) as { exp: number };
            const expirationDate = new Date(decodedToken.exp * 1000);
            return new Date() >= expirationDate;
        }
        return null
    }

    private async saveEmailToLocalStorage() {
        let emailObjectString = JSON.stringify(this.savedEmail)
        localStorage.setItem(textConstants.LocalStorage.savedEmail, emailObjectString)
    }

    private async populateEmailsDropdown() {
        // Get emails from localStorage and add them to dropdown list
        for (const currentKey in localStorage) {
            if (currentKey.startsWith(textConstants.LocalStorage.comx) && currentKey.endsWith(textConstants.LocalStorage.AccessToken)) {
                const emailToAdd = {
                    value: this.extractEmailFromAccessTokenKey(currentKey),
                    label: this.extractEmailFromAccessTokenKey(currentKey),
                }
                this.emailFromOptions.push(emailToAdd)
            }
        }

        // Sort the list alphabetically
        this.emailFromOptions.sort((email1, email2) => (email1.label < email2.label ? -1 : 1));
        this.AddDefaultOptionToEmailsDropdown();
    }

    private AddDefaultOptionToEmailsDropdown() {
        const defaultOption = {
            value: textConstants.ActionListText.AddOtherEmail,
            label: textConstants.ActionListText.AddOtherEmail,
        };
        this.emailFromOptions.push(defaultOption);
    }

    private extractEmailFromAccessTokenKey(str: string) {
        const hyphenStart = str.indexOf('-');
        const hyphenEnd = str.lastIndexOf('-');

        if (hyphenStart < 0 || hyphenEnd < 0) {
            return '';
        }

        return str.substring(hyphenStart + 1, hyphenEnd);
    }


    public handleEmailSelection(selectedItem: any | null) {
        let emailAddress = selectedItem.value

        if (emailAddress === textConstants.ActionListText.AddOtherEmail) {
            this.saveEmailToLocalStorage()
            this.beginNewEmailSignIn()
        } else {
            this.setAccessTokenAndDropdown(emailAddress)
        }
    }

    private setAccessTokenAndDropdown(emailAddress: string) {
        this.accessTokenToUse = localStorage.getItem(`${textConstants.LocalStorage.comx}${emailAddress}${textConstants.LocalStorage.AccessToken}`)

        // Select the relevant email in the dropdown
        this.selectedEmailFrom = this.emailFromOptions.find(option => option.value === emailAddress);
    }

    private async getReminders() {
        this.reminderVM.prospectId = this.prospectId;
        this.reminderVM.clientId = this.clientId;
        this.reminderVM.initialise();
    }

    // Pushing the followup template draft text to the message content of the email message.
    private pushToMessageContent() {
        this.populateStatuses()

        let leadStatus = this.leadLookup?.cluster_info.category

        // Links the string to the id
        let currentStatus = this.actionListStatuses.find(x => x.statusName === leadStatus)?.actionListStatusId

        // Links the action list status id to the lead type linking table which is what the followup templates uses
        let linkingId = this.leadTypeStatuses.find(x => x.actionListStatusId === currentStatus)?.leadTypeStatusId

        // Finds the first item that has the correct leadTypeStatus, and takes the first email.
        let currentFollowUpTemplates = this.followUpTemplateList.filter(x => x.leadTypeStatusId === linkingId).sortBy("clientFollowUpTemplateId")

        // Checks if its not null or empty which then takes the newest item created.
        if (currentFollowUpTemplates.length > 0) {
            let lastTemplate = currentFollowUpTemplates.slice(-1)[0];
            if (lastTemplate.followUpTemplates && lastTemplate.followUpTemplates.length > 0) {
                this.messageContent = lastTemplate.followUpTemplates[0].messageText || '';
            }
        }
    }

    // Generates the Status dropdown options on initialize
    private generateStatuses() {
        // Makes sure the appDataCache items are populated
        this.populateStatuses()

        this.statuses = []

        this.actionListStatuses.forEach(x => {
            const option: IOptions = {
                value: x.statusName,
                label: `${x.dot} ${x.statusName}`
            };

            this.statuses.push(option)
        })
    }

    // Populates the appDataCache lists if the lists are empty.
    private populateStatuses() {
        if (this.actionListStatuses.length < 1) {
            this.actionListStatuses = this.appDataCache.actionListStatuses.get().data as Array<ActionListStatus>;
        }

        if (this.leadTypeStatuses.length < 1) {
            this.leadTypeStatuses = this.appDataCache.leadTypeStatuses.get().data as Array<LeadTypeStatus>;
        }
    }

    public async getReply() {
        var response = await this.actionListApiClient.getReply(this.clientId, this.prospectId, this.campaignId, this.currentReplyId);
        if (response.data.success) {
            this.replyLookup.set(response.data.data)

        } else {
            this.notifications.addDanger(textConstants.titleText.Error, response.data.message)
        }
    }

    public async setReplyAsSeen() {
        var response = await this.actionListApiClient.setReplyAsSeen(this.clientId, this.currentReplyId);
        if (!response.data.success) {
            this.notifications.addDanger(textConstants.titleText.Error, textConstants.messageText.saveMessage.UnableToMarkAsSeen)
        }
    }

    public async getSpecificFollowUpTemplates(followupTemplates: List<ActionListFollowUpTemplateLookup>) {
        followupTemplates.forEach(fut => {

            let newFollowUpTemplate = new ActionListFollowUpTemplateLookup();
            newFollowUpTemplate.followUpTemplateId = fut.followUpTemplateId;
            newFollowUpTemplate.followUpTemplateName = fut.followUpTemplateName;
            newFollowUpTemplate.clientFollowUpTemplateId = fut.clientFollowUpTemplateId;
            newFollowUpTemplate.ordinal = fut.ordinal
            newFollowUpTemplate.draftText = fut.draftText;
            newFollowUpTemplate.messageText = fut.messageText;
            this.individualTemplatesList.push(newFollowUpTemplate)
        })
    }

    public async getFollowUpTemplates() {
        await this.pageManager.refreshData();
        this.followUpTemplateList.set(this.pageManager.data as any)
        this.filteredFollowUpTemplateList.set(this.followUpTemplateList as any)

        this.filteredFollowUpTemplateList.forEach(async fut => {
            await this.getSpecificFollowUpTemplates(fut.followUpTemplates)
        })
    }

    public getTemplateMessage(clientFollowUpTemplateId: number, ordinal: number) {
        let message = this.individualTemplatesList.find(t => t.clientFollowUpTemplateId === clientFollowUpTemplateId && t.ordinal === ordinal)?.messageText

        this.placeholderText = message ? message : textConstants.LeadEmailCreator.TemplateNotSet
    }

    public setTemplateMessage(clientFollowUpTemplateId: number, ordinal: number) {
        let message = this.individualTemplatesList.find(t => t.clientFollowUpTemplateId === clientFollowUpTemplateId && t.ordinal === ordinal)?.messageText
        this.templateText = message ? message : textConstants.LeadEmailCreator.TemplateNotSet
        this.isSearchingTemplates = false;
        this.followupTemplateRibbonDisplay = false
        this.selectedClientFollowUpTemplate = 0;
        this.selectedClientFollowUpTemplateName = ""
    }

    public setEmailTemplateRibbon(clientFollowUpTemplateId: number, templateName: string) {
        this.selectedClientFollowUpTemplate = clientFollowUpTemplateId
        this.selectedClientFollowUpTemplateName = templateName
        this.followupTemplateRibbonDisplay = true
    }

    public pageManager = new Data.PageManager(this.searchCriteria, ActionListClientFollowUpTemplateLookup, this.campaignMessagesApiClient.getActionListClientFollowUpTemplatesPaged,
        {
            pageSize: 1000,
            sortBy: "followUpTemplateName" || "ordinal",
            fetchInitial: false
        }
    )

    public async setInfobar(infoDetails: List<InformationManagementDetail>) {
        let infoBarContent = infoDetails.find(info => info.viewName === textConstants.generalText.LeadMessageInfoBar)

        const { user } = this.authenticationService;
        this.userName = user!.userName;

        if (infoBarContent) {
            this.infoBarContent = infoBarContent.displayText;
            this.infoBarRedirectUrl = infoBarContent.videoUrl;

            let displayInfobar = await this.invitedUserApiClient.getLeadInfoBarIndicator(this.userName)

            this.isInformationBarVisible = displayInfobar.data;
        }

    }

    public async UpdateLeadInfoBar() {

        let command = new UpdateShowLeadInfoBarCommand();
        command.clientId = this.clientId;
        command.userName = this.userName;

        await this.invitedUserApiClient.saveShowLeadInfo(command);
    }

    public async SearchList() {
        this.filteredFollowUpTemplateList.set(this.followUpTemplateList as any)
        if (this.searchString && this.followUpTemplateList) {

            let filteredList = this.followUpTemplateList.filter(followUpTemplate => {
                let searchItem = followUpTemplate.followUpTemplateName.toLocaleUpperCase().includes(this.searchString.toLocaleUpperCase())
                return searchItem
            })

            let constList = new List(ActionListClientFollowUpTemplateLookup)

            filteredList.forEach(item => {
                constList.push(item)
            })

            this.filteredFollowUpTemplateList = constList
        }
    }
}