import styled, { keyframes } from "styled-components";


const moveAnimation = keyframes`
    0%,
  100% {
    top: 5px;
  }
  50% {
    top: 0px;
  }
`;

interface StyledDraggableProps {
  isDragging: boolean;
  isDisabled?: boolean;
}

export const StyledDraggable = styled.div<StyledDraggableProps>`
    animation: ${moveAnimation} 3.5s infinite;
    cursor: grab;

    ${({ isDragging }) => isDragging && `
        opacity: 0.5;
    `}

    ${({ isDisabled }) =>
    isDisabled && `
      user-select: none;
      pointer-events: none;
      `}
`;