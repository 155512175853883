import { Neo, NeoGrid } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { textConstants } from "../../common/textConstants";
import * as Roles from "../../Models/Security/Roles";
import CustomPagingControl from "../../Components/CustomPagingControl";
import SearchField from "../../Components/SearchField";
import CustomerProfileLookup from "../../Models/IdealCustomerProfiles/CustomerProfileLookup";
import { AppService, Types } from "../../Services/AppService";
import CustomerProfilesVM from "./CustomerProfilesVM";
import { Navigation } from "../../Models/Enums/ApplicationEnums";
import * as Icon from "react-feather";
import InfoVideo from "../../Components/InfoVideo/InfoVideo";
import InfoBox from "../../Components/InfoBox";
import {
  StyledButton,
  StyledButtonWrapper,
  StyledButtonGroupWrapper,
} from "Components/Button/styles";
import OnboardingModal from "Components/Modals/OnboardingModal";
import HtmlParser from "react-html-parser";
import TrophyWoman from "assets/img/TrophyWoman.png";
import DataTooltip from "Components/DataTooltip/DataTooltip";
import ClientNavigationButtons from "Components/ClientNavigationButtons";

interface ICustomerProfilesProps {
  viewModel: CustomerProfilesVM;
  clientName?: string;
  back: () => void;
  openCustomerProfileQuestionnaire: (customerProfileId?: number) => void;
  isOnboarding?: boolean;
}

@observer
export default class CustomerProfilesView extends React.Component<ICustomerProfilesProps> {
  private authorisationService = AppService.get(
    Types.Neo.Security.AuthorisationService
  );

  protected authenticationService = AppService.get(
    Types.Security.CustomAuthenticationService
  );

  public closeModal() {
    this.props.viewModel.showOnboardingModal = false;
    this.props.viewModel.setStepCompleteModalAsSeen();
  }

  public async cloneICP(iCPId: number) {
    this.props.viewModel.openCloneModal(iCPId);
  }

  public render() {
    const { isComXUser, searchFieldVM } = this.props.viewModel;
    const { clientName } = this.props;

    return (
      <div className="CustomerProfilesView">
        {/* Back button */}
        {!this.props.isOnboarding && isComXUser && (
          <div className="col-12 p-0">
            <Neo.Button
              variant="light"
              className="btn-width-100 mt-3 marginLeft24"
              icon={<Icon.ArrowLeft />}
              onClick={() => {
                this.props.back();
              }}
            >
              {textConstants.buttonText.Back}
            </Neo.Button>
          </div>
        )}

        <Neo.Card>
          <div className="row m-0">
            {/* Header row */}
            <div className="col-12 p-0">
              <div className="row ml-0 mr-0">
                <div className="col-12 p-0">
                  <div className="row mr-0 ml-0 p-24">
                    <div className="col-4 p-0 mt-3">
                      <h2 className="pt-0">Ideal Customer Profile</h2>
                    </div>
                    <div className="col-8 p-0">
                      {/* Add Profile button*/}
                      <StyledButton
                        className="pull-right ml-4"
                        variant="hasIcon"
                        size="xSmall"
                        onClick={() => {
                          this.props.openCustomerProfileQuestionnaire();
                        }}
                      >
                        {<Icon.Plus />}
                        {textConstants.buttonText.AddCustomerProfile}
                      </StyledButton>

                      {/* Search field */}
                      <div className="mt-3">
                        <SearchField
                          onClickFunction={(e) => {
                            this.props.viewModel.pageManagerRefresh();
                            searchFieldVM.hideNoResultDialog = true;
                          }}
                          searchBindableProp={
                            this.props.viewModel.searchCriteria.meta
                              .searchString
                          }
                          disabled={
                            this.props.viewModel.pageManager.totalRecords === 0
                            && this.props.viewModel.isArchived
                          }
                          searchString={
                            this.props.viewModel.searchCriteria.searchString
                          }
                          searchFieldVM={searchFieldVM}
                        />
                      </div>
                    </div>

                    <div className="">
                      <div className={"col-12 p-0 justify-content-left"}>
                        {isComXUser && (
                          <ClientNavigationButtons viewModel={this.props.viewModel.clientNavigationButtonVM} back={this.props.back} />
                        )}
                      </div>
                    </div>

                    {/* Tabs*/}
                    <div className="col-12 p-0">
                      <Neo.TabContainer
                        className="mt-2 mb-2 m-0"
                        variant="flat"
                      >
                        <Neo.Tab
                          header="Current"
                          onDisplayed={() => {
                            this.props.viewModel.isArchived = false;
                            this.props.viewModel.searchCriteria.isDeleted =
                              false;

                            //Make sure this is set before refreshing things
                            if (this.props.viewModel.searchCriteria.clientId) {
                              this.props.viewModel.pageManagerRefresh();
                            }
                          }}
                        ><></></Neo.Tab>
                        <Neo.Tab
                          header="Archived"
                          onDisplayed={() => {
                            this.props.viewModel.searchCriteria.isDeleted =
                              true;
                            this.props.viewModel.isArchived = true;
                            this.props.viewModel.pageManagerRefresh();
                          }}
                        ><></></Neo.Tab>
                      </Neo.TabContainer>
                      {/* Tabs End*/}
                    </div>
                  </div>
                </div>
              </div>

              {this.props.viewModel.pageManager.totalRecords === 0 &&
                searchFieldVM.isSearching && (
                  <InfoBox
                    header={textConstants.titleText.NoSearchMatches}
                    text={textConstants.generalText.NoSearchMatches}
                  />
                )}

              {this.props.viewModel.pageManager.totalRecords === 0 &&
                this.props.viewModel.isArchived === false &&
                !searchFieldVM.isSearching &&
                !searchFieldVM.hideNoResultDialog && (
                  <InfoBox
                    header={textConstants.titleText.NoCustomerProfiles}
                    text={textConstants.generalText.NoICPLearnAbout}
                  />
                )}

              {this.props.viewModel.pageManager.totalRecords === 0 &&
                this.props.viewModel.isArchived === true &&
                !searchFieldVM.isSearching &&
                !searchFieldVM.hideNoResultDialog && (
                  <InfoBox
                    header={textConstants.titleText.NoArchivedICPTitle}
                    text={textConstants.generalText.NoICPArchives}
                  />
                )}

              <OnboardingModal
                isOpen={this.props.viewModel.showOnboardingModal}
                imageSrc={TrophyWoman}
                content={<>{HtmlParser(this.props.viewModel.onboardingPopupModalContent)}</>}
                closeModal={() => {
                  this.closeModal();
                }}
                acceptAndCloseButton={{
                  text: textConstants.generalText.Close,
                  onClose: () => {
                    this.closeModal();
                  },
                }}
              />

              {this.props.viewModel.pageManager.totalRecords > 0 && (
                <div className="m-0">
                  <Neo.Pager
                    pageManager={this.props.viewModel.pageManager}
                    pageControls="none"
                  >
                    <NeoGrid.Grid<CustomerProfileLookup>
                      items={this.props.viewModel.pageManager}
                    >
                      {(CustomerProfileLookup, meta) => (
                        <NeoGrid.Row>
                          <NeoGrid.Column
                            width={1}
                            label={"No."}
                            display={meta.sequenceNumber}
                          />
                          <NeoGrid.Column
                            className="profileName"
                            label={"Profile Name"}
                            sort={false}
                            alignment={"left"}
                          >
                            <div
                              className="pt-3 pb-3 clickableCursor gridName"
                              onClick={() => {
                                if (!this.props.viewModel.isArchived) {
                                  this.props.openCustomerProfileQuestionnaire(
                                    CustomerProfileLookup.customerProfileId
                                  );
                                }
                              }}
                            >
                              {CustomerProfileLookup.profileName}
                            </div>
                          </NeoGrid.Column>
                          <NeoGrid.Column
                            className={"customerProfileStatus"}
                            label={"Status"}
                            display={meta.status}
                          />

                          <NeoGrid.ButtonColumn className="text-center p-0">
                            {/* Edit */}
                            <StyledButtonWrapper>
                              {!this.props.viewModel.isArchived && (
                                <StyledButtonGroupWrapper>
                                  {CustomerProfileLookup.status === textConstants.generalText.Complete &&
                                    <DataTooltip
                                      parent={
                                        <StyledButton
                                          isIconButton={true}
                                          onClick={() =>
                                            this.props.viewModel.downloadICP(CustomerProfileLookup.customerProfileId, CustomerProfileLookup.profileName)
                                          }>
                                          <Icon.Download size={16} />
                                        </StyledButton>
                                      }>
                                      {textConstants.buttonText.Download}
                                    </DataTooltip>
                                  }

                                  <DataTooltip
                                    parent={
                                      <StyledButton
                                        isIconButton={true}
                                        onClick={() => {
                                          this.props.openCustomerProfileQuestionnaire(
                                            CustomerProfileLookup.customerProfileId
                                          );
                                          this.props.viewModel.customAuthService.onboardingFunctions.setHelpCenterSectionCode();
                                        }}
                                      >
                                        <Icon.Edit size={16} />
                                      </StyledButton>
                                    }>
                                    {textConstants.buttonText.Edit}
                                  </DataTooltip>

                                  {/* Clone */}
                                  {isComXUser &&
                                    this.authorisationService.hasRole(
                                      Roles.IdealCustomerProfile.Clone
                                    ) && (
                                      <DataTooltip
                                        parent={
                                          <StyledButton
                                            isIconButton={true}
                                            onClick={() => {
                                              this.cloneICP(
                                                CustomerProfileLookup.customerProfileId
                                              );
                                            }}
                                          >
                                            <Icon.Copy size={16} />
                                          </StyledButton>
                                        }>
                                        {textConstants.buttonText.Clone}
                                      </DataTooltip>
                                    )}
                                </StyledButtonGroupWrapper>
                              )}

                              {/* Archive */}
                              {!this.props.viewModel.isArchived && (
                                <DataTooltip
                                  parent={
                                    <StyledButton
                                      isIconButton={true}
                                      onClick={() => {
                                        this.props.viewModel.toggleArchiveCommand.clientId =
                                          this.props.viewModel.searchCriteria.clientId;
                                        this.props.viewModel.toggleArchiveCommand.customerProfileId =
                                          CustomerProfileLookup.customerProfileId;
                                        this.props.viewModel.toggleArchiveCommand.isArchive =
                                          true;
                                        this.props.viewModel.toggleArchiveCustomerProfile();
                                      }}
                                    >
                                      <Icon.Archive size={16} />
                                    </StyledButton>
                                  }>
                                  {textConstants.buttonText.Archive}
                                </DataTooltip>
                              )}
                              {this.props.viewModel.isArchived && (
                                <DataTooltip
                                  parent={
                                    <StyledButton
                                      isIconButton={true}
                                      onClick={() => {
                                        this.props.viewModel.toggleArchiveCommand.clientId =
                                          this.props.viewModel.searchCriteria.clientId;
                                        this.props.viewModel.toggleArchiveCommand.customerProfileId =
                                          CustomerProfileLookup.customerProfileId;
                                        this.props.viewModel.toggleArchiveCommand.isArchive =
                                          false;
                                        this.props.viewModel.toggleArchiveCustomerProfile();
                                      }}
                                    >
                                      <Icon.RotateCw size={16} />
                                    </StyledButton>
                                  }>
                                  {textConstants.buttonText.Unarchive}
                                </DataTooltip>
                              )}
                            </StyledButtonWrapper>
                          </NeoGrid.ButtonColumn>
                        </NeoGrid.Row>
                      )}
                    </NeoGrid.Grid>
                  </Neo.Pager>

                  <div className="col-12 p-24">
                    <CustomPagingControl
                      displayLabel={true}
                      pageManager={this.props.viewModel.pageManager}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Clones the ICP, after the submit button is pressed.*/}
          <Neo.Modal
            title="Clone the ideal customer profile"
            show={this.props.viewModel.showCloneModal}
            formProps={{ model: this.props.viewModel.cloneICPCommand }}
            onClose={() => (this.props.viewModel.showCloneModal = false)}
            closeButton={{ text: "Cancel", variant: "light" }}
            acceptButton={{
              text: "Submit",
              variant: "secondary",
              onClick: () => this.props.viewModel.cloneICP(),
            }}
          >
            <Neo.GridLayout md={1}>
              <Neo.FormGroup
                bind={this.props.viewModel.cloneICPCommand.meta.iCPName}
                label="Clone Name"
              />
            </Neo.GridLayout>
          </Neo.Modal>
        </Neo.Card>
      </div>
    );
  }
}
