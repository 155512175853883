import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import EmailProviderType from '../Models/Maintenance/EmailProviderType';

export interface IEmailProviderTypesApiClient extends Data.IUpdateableApiClient<EmailProviderType, number> {

}

@injectable()
export default class EmailProviderTypesApiClient extends Data.UpdateableApiClient<EmailProviderType, number> implements IEmailProviderTypesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/EmailProviderTypes`);
    }
}