import { Misc, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import AuthorisationTypes from '../../Authorisation/AuthorisationTypes';
import CommandResult from '../../Models/InvitedUsers/Commands/CommandResult';
import ChangePasswordModel from '../../Models/Security/ChangePasswordModel';
import { AppService, Types } from '../../Services/AppService';

@NeoModel
export default class ChangePasswordVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private apiClient = Misc.Globals.appService.get(AuthorisationTypes.ApiClients.UsersApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
        
        super(taskRunner);
    }

    public showChangePassword: boolean = false;
    public passwords: ChangePasswordModel = new ChangePasswordModel();
    
    public async updatePassword() {
        this.taskRunner.run(async () => {
            let request = this.passwords.clone();
            const response = await this.apiClient.setChangePassword(request);
            const cr: CommandResult = response.data;

            if (cr.success) {
                this.notifications.addSuccess('Password changed', 'Password Update Successfully');                
            }
            else {
                this.notifications.addDanger('Password not Updated', cr.error);
            }
        });
    }
}
