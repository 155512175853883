import React from 'react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import { AppService, Types } from "../../Services/AppService";
import SearchField from '../../Components/SearchField';
import BatchReviewAccountLookup from '../../Models/BatchReview/BatchReviewAccountLookup';
import BatchReviewProspectsVM from './BatchReviewProspectsVM';
import CustomPagingControl from '../../Components/CustomPagingControl';
import * as Roles from '../../Models/Security/Roles';
import * as Icon from 'react-feather';
import InfoBox from '../../Components/InfoBox';
import { StyledButton } from 'Components/Button/styles';

interface IBatchReviewViewParams {
    viewModel: BatchReviewProspectsVM,
    back: () => void
}

@observer
export default class BatchReviewProspectsView extends React.Component<IBatchReviewViewParams> {
    public ToggleRemoveContacts(account: BatchReviewAccountLookup) {
        account.batchReviewAccountContacts.forEach(contact => {
            contact.deleted = account.deleted

            if (account.deleted) {
                // Need to do this in case of user removing then unremoving before saving. Hence will have removed contacts vs a fully removed account
                this.props.viewModel.RemoveAccountContactFromProspects(account.batchReviewAccountId, contact.batchReviewAccountContactId);
            }
            else {
                this.props.viewModel.UnremoveAccountContactFromProspects(account.batchReviewAccountId, contact.batchReviewAccountContactId);
            }
        });
    }

    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

    public UnremoveAccount(account: BatchReviewAccountLookup) {
        let contactCount = account.batchReviewAccountContacts.length

        let contactDeletedCountCount = account.batchReviewAccountContacts.filter(f => f.deleted).length

        if ((contactCount - 1) === contactDeletedCountCount) {
            account.deleted = false
            this.props.viewModel.UnremoveAccountFromProspects(account.batchReviewAccountId)
        }
    }

    public render() {

        var filter = {};

        const { searchFieldVM } = this.props.viewModel

        const ContactRowClassName = (data: any) => {
            if (data.deleted) {
                return 'redRow'
            } else {
                return ''
            }
        }

        const AccountRowClassName = (data: any) => {
            if (data.deleted) {
                return 'redRow'
            } else {
                return ''
            }
        }

        return (

            <div className='BatchReviewProspectView'>
                <Neo.Button
                    className="btn-width-100 mt-3 marginLeft24"
                    variant="light"
                    icon={<Icon.ArrowLeft />}
                    onClick={async (e: any) => {
                        this.props.back()
                    }}
                >
                    {textConstants.buttonText.Back}
                </Neo.Button>

                <Neo.Card>
                    <div className="row m-0">
                        <h2 className="col-6 p-24 mt-4">
                            Review
                        </h2>
                        <div className="col-6 p-24 mt-4 ">
                            <div className='pull-right'>
                                <SearchField
                                    searchStringLabel="Search all Columns"
                                    searchBindableProp={this.props.viewModel.criteria.meta.searchString}
                                    onClickFunction={(e) => {
                                        this.props.viewModel.pageManager.refreshData()
                                    }}
                                    searchString={this.props.viewModel.criteria.searchString}
                                    searchFieldVM={searchFieldVM}
                                />
                            </div>
                        </div>

                        {/* If the batch is completed, Show by who and when */}
                        {this.props.viewModel.batchReviewComplete &&
                            <div className="col-6 p-24">
                                Completed By: {this.props.viewModel.batchFinalReviewer} on {this.props.viewModel.batchFinalReviewDate}
                            </div>
                        }

                    </div>
                    <div className='col-12 heightNone p-24'>
                        <Neo.Button
                            variant="light"
                            isOutline
                            className={`${this.props.viewModel.rotateExpandAll()} singleIconBtn expandGridIcon fa-rotate-90`}
                            icon={"step-forward"}
                            onClick={() => {
                                this.props.viewModel.toggleGridExpand();
                            }}></Neo.Button>
                    </div>

                    {/* Batch Review Grid */}
                    <div>
                        {this.props.viewModel.pageManager.totalRecords === 0 && searchFieldVM.isSearching &&
                            <InfoBox header={textConstants.titleText.NoSearchMatches}
                                text={textConstants.generalText.NoSearchMatches} />
                        }

                        {this.props.viewModel.pageManager.totalRecords > 0 &&
                            <React.Fragment>
                                <Neo.Pager pageManager={this.props.viewModel.pageManager} pageControls="none">
                                    {(data) => (
                                        <NeoGrid.Grid items={this.props.viewModel.pageManager} className="collapseGrid p-24">
                                            {(account, accountMeta) => (
                                                <NeoGrid.RowGroup expandProperty={accountMeta.isExpanded}
                                                >
                                                    {/* Accounts */}
                                                    <NeoGrid.Row
                                                        onClick={() => { this.props.viewModel.gridExpandedCount() }}
                                                        className={AccountRowClassName(account) + " text-center borderless"}>
                                                        <NeoGrid.Column
                                                            className='text-left'
                                                            label={"Account"}
                                                            bind={accountMeta.accountName}
                                                            sort={false}
                                                            isReadOnly={true} />

                                                        <NeoGrid.Column
                                                            className='text-left'
                                                            label={"Location"}
                                                            bind={accountMeta.location}
                                                            isReadOnly={true} />

                                                        <NeoGrid.Column
                                                            className='text-left'
                                                            label={"Company Size"}
                                                            sort={false}
                                                            bind={accountMeta.companySize}
                                                            isReadOnly={true} />

                                                        <NeoGrid.Column
                                                            className='text-left'
                                                            label={"Industry"}
                                                            sort={false}
                                                            bind={accountMeta.subIndustry}
                                                            isReadOnly={true} />

                                                        <NeoGrid.Column
                                                            className='text-left'
                                                            label={"Domain"}
                                                            sort={false}
                                                            bind={accountMeta.domain}
                                                            isReadOnly={true} />

                                                        {!this.props.viewModel.batchReviewComplete &&
                                                            <NeoGrid.Column
                                                                label={"Remove"}
                                                                sort={false}
                                                                className={"neo-grid-button-column text-center"}
                                                            >
                                                                {!account.deleted &&
                                                                    <Neo.Button
                                                                        variant="danger"
                                                                        icon={<Icon.Archive />}
                                                                        className="text-center"
                                                                        onClick={() => {
                                                                            accountMeta.deleted.value = accountMeta.deleted.value ? false : true
                                                                            this.ToggleRemoveContacts(account);
                                                                            this.props.viewModel.RemoveAccountFromProspects(account.batchReviewAccountId)
                                                                        }}
                                                                    />}
                                                                {account.deleted &&
                                                                    <Neo.Button
                                                                        variant="light"
                                                                        className="text-center circled"
                                                                        icon={<Icon.RotateCw />}
                                                                        onClick={() => {
                                                                            accountMeta.deleted.value = accountMeta.deleted.value ? false : true
                                                                            this.ToggleRemoveContacts(account);
                                                                            this.props.viewModel.UnremoveAccountFromProspects(account.batchReviewAccountId)
                                                                        }}
                                                                    >
                                                                    </Neo.Button>}
                                                            </NeoGrid.Column>}
                                                    </NeoGrid.Row>

                                                    {/* Contacts */}

                                                    <NeoGrid.ChildRow className={"childRowStyle"}>
                                                        <NeoGrid.Grid items={account.batchReviewAccountContacts}>
                                                            {(contact, contactMeta) => (

                                                                <NeoGrid.Row className={ContactRowClassName(contact) + " text-center borderless"}>
                                                                    {/* Region Name */}
                                                                    <NeoGrid.Column
                                                                        className='text-left'
                                                                        bind={contactMeta.firstName}
                                                                        sort={false}
                                                                        label={"First Name"}
                                                                        isReadOnly={true}
                                                                    />
                                                                    <NeoGrid.Column
                                                                        className='text-left'
                                                                        bind={contactMeta.lastName}
                                                                        sort={false}
                                                                        label={"Last Name"}
                                                                        isReadOnly={true}
                                                                    />

                                                                    <NeoGrid.Column
                                                                        className='text-left'
                                                                        bind={contactMeta.title}
                                                                        sort={false}
                                                                        label={"Job Title/ Role"}
                                                                        isReadOnly={true}
                                                                    />
                                                                    {!this.props.viewModel.batchReviewComplete &&
                                                                        <NeoGrid.Column
                                                                            label={"Remove"}
                                                                            sort={false}
                                                                            className={"neo-grid-button-column text-center"}
                                                                        >
                                                                            {!contact.deleted &&
                                                                                <Neo.Button
                                                                                    variant="danger"
                                                                                    icon={<Icon.Archive />}
                                                                                    className="text-center"
                                                                                    onClick={() => {
                                                                                        contactMeta.deleted.value = contactMeta.deleted.value ? false : true
                                                                                        this.props.viewModel.RemoveAccountContactFromProspects(contact.batchReviewAccountId, contact.batchReviewAccountContactId)
                                                                                    }}
                                                                                >
                                                                                </Neo.Button>
                                                                            }

                                                                            {contact.deleted &&
                                                                                <Neo.Button
                                                                                    variant="light"
                                                                                    className="text-center circled"
                                                                                    icon={<Icon.RotateCw />}
                                                                                    onClick={() => {
                                                                                        contactMeta.deleted.value = contactMeta.deleted.value ? false : true
                                                                                        this.UnremoveAccount(account)
                                                                                        this.props.viewModel.UnremoveAccountContactFromProspects(contact.batchReviewAccountId, contact.batchReviewAccountContactId)
                                                                                    }}
                                                                                >
                                                                                </Neo.Button>}
                                                                        </NeoGrid.Column>}
                                                                </NeoGrid.Row>
                                                            )}
                                                        </NeoGrid.Grid>
                                                    </NeoGrid.ChildRow>
                                                </NeoGrid.RowGroup>
                                            )}
                                        </NeoGrid.Grid>
                                    )}

                                </Neo.Pager>
                                <div className='pageManager col-12 p-24'>
                                    <CustomPagingControl
                                        displayLabel={true}
                                        pageManager={this.props.viewModel.pageManager} />
                                </div>
                            </React.Fragment>
                        }
                    </div>

                    {/*Save Button*/}
                    {!this.props.viewModel.batchReviewComplete &&
                        this.authorisationService.hasRole(Roles.BatchReview.Manage) &&
                        <Neo.GridLayout md={1}>

                            <div className={"row justify-content-end m-0 mt-4 marginBottom24"}>
                                {/*Save as Draft*/}
                                <Neo.Button
                                    variant="light"
                                    className="ml-2 mt-3 mb-3"
                                    onClick={() => {
                                        this.props.viewModel.saveBatchReviewDraft(() => this.props.back())
                                    }}
                                >
                                    {textConstants.buttonText.SaveAsDraft}
                                </Neo.Button>

                                {/*Complete Review*/}
                                <StyledButton
                                    className="pull-right ml-4"
                                    variant="hasIcon"
                                    size="xSmall"
                                    onClick={() => {
                                        this.props.viewModel.showBatchReviewConfirmationModal = true;
                                    }}
                                >
                                    {textConstants.buttonText.CompleteReview}
                                </StyledButton>
                            </div>
                        </Neo.GridLayout>
                    }
                </Neo.Card>

                <Neo.Modal
                    title={`Complete Batch Review`}
                    show={this.props.viewModel.showBatchReviewConfirmationModal}
                    onClose={() => this.props.viewModel.showBatchReviewConfirmationModal = false}
                    closeButton={{ text: "No", variant: "light" }}
                    acceptButton={{
                        text: "Yes", variant: "secondary",
                        onClick: (e: any) => {
                            e.preventDefault();
                            this.props.viewModel.completeBatchReview(this.props.viewModel.criteria.batchReviewUploadId, this.props.viewModel.clientId, () => { this.props.back() })
                        }
                    }}>
                    {"Are you sure you have completed your review? Please note that clicking 'Yes' will prevent you from reviewing this batch again."}
                </Neo.Modal>
            </div >
        );
    }
}