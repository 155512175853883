import React from 'react';
import { observer } from 'mobx-react';
import * as Icon from 'react-feather';
import HtmlParser from 'react-html-parser';

interface IInfoBoxProps {
  header: string;
  text: string;
}

@observer
export default class InfoBox extends React.Component<IInfoBoxProps> {

  constructor(props: IInfoBoxProps) {
    super(props);
  }

  public render() {
    return (
      <div className='infoBox'>
        <div className="row m-0 p-0 d-flex">
          <div className="emptyState m-3 w-100">
            <h4 className="mb-3 text-center p-0">{this.props.header}</h4>

            {/* Message Container row*/}
            <div className="row m-0 p-0">
              <div className="col-12 text-center">
                {HtmlParser(this.props.text)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}