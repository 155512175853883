import { AppServices, Security } from '@singularsystems/neo-core'
import { UserManagerSettings } from 'oidc-client-ts';
import { injectable } from 'inversify';

@injectable()
export class AppConfig extends AppServices.ConfigModel {

    public static readonly silentSignInRoute = "/OidcSilentLoginRedirect";
    public static readonly loginRedirectRoute = "/OidcLoginRedirect";

    public userManagerSettings = {} as UserManagerSettings;

    public apiPath: string = "";
    public identityServer = { client_id: "", url: "" };
    public authorisationServer = { apiPath: "" };
    public gridLicense: string = ""
    public zendeskKey: string = ""
    public calendlyUrl: string = ""
    public sessionTimeout = { timeoutMinutes: 90, messageTimeout: 60 };
    public hotjarSV: number = 6;
    public hotjarId: number = 2895170;

    /**
     * Transforms property values loaded from config.json
     */
    public initialise() {
        this.apiPath = this.apiPath.replace("{domain}", window.location.hostname);
        this.identityServer.url = this.identityServer.url.replace("{domain}", window.location.hostname);
        this.authorisationServer.apiPath = this.authorisationServer.apiPath.replace("{domain}", window.location.hostname);
        this.loadUserManagerSettings();
    }

    private loadUserManagerSettings() {

        const appUrl = `${window.location.origin}${this.baseUrl}`;

        this.userManagerSettings = {
            client_id: this.identityServer.client_id,
            redirect_uri: `${appUrl}${AppConfig.loginRedirectRoute}`,
            response_type: 'code',
            scope: "openid profile api1 IdentityServerApi Authorisation",
            authority: this.identityServer.url,
            silent_redirect_uri: `${appUrl}${AppConfig.silentSignInRoute}`,
            monitorSession: false,
            metadata: Security.OidcAuthService.createIdentityServerMetadata(this.identityServer.url)
        }
    }

    // Authorisation
    public get authorisationServerApiPath(): string {
        return this.authorisationServer.apiPath;
    }
}