import "rc-slider/assets/index.css";
import "react-input-range/lib/css/index.css";
import React from "react";
import { observer } from "mobx-react";
import AddEditTargetMarketVM from "../AddEditTargetMarketVM";
import { IPropertyInstance } from "@singularsystems/neo-core/dist/Model";
import { textConstants } from "common/textConstants";
import ListSelectorModal from "Components/Modals/ListSelectorModal";
import ListSelectorSelectedItems from "Components/OnboardingComponents/ListSelectorSelectedItems";

interface TMScreen4Props {
    viewModel: AddEditTargetMarketVM;
    formatFunctionRole: (roleName: IPropertyInstance<string | null>) => {};
    isNotEditable?: boolean;
}

@observer
export default class TMScreen4 extends React.Component<TMScreen4Props> {
    state = {
        openTMListModal: false,
    };

    public toggleModal() {
        this.setState({
            openTMListModal: !this.state.openTMListModal,
        });
    }

    public render() {
        return (
            <div className="col-sm">
                <ListSelectorSelectedItems
                    title={textConstants.Onboarding.ListSelectorTitleRolesAndFunctions}
                    btnText={textConstants.Onboarding.ListSelectorBtnTextRolesAndFunctions}
                    itemType={textConstants.Onboarding.ListSelectorTypeRolesAndFunctions}
                    list={this.props.viewModel.selectedModalList}
                    isHeadOfSeniority={
                        this.props.viewModel.targetMarket.meta
                            .isSeniorityLevelHead
                    }
                    isManagerOfSeniority={
                        this.props.viewModel.targetMarket.meta
                            .isSeniorityLevelManager
                    }
                    isNotEditable={this.props.isNotEditable}
                    openModal={() => {
                        this.props.viewModel.listSelectorClonedList =
                            this.props.viewModel.cloneSelectorModalList(this.props.viewModel.modalList)
                        this.props.viewModel.setupListModalData();
                        this.toggleModal();
                    }}
                ></ListSelectorSelectedItems>

                <ListSelectorModal
                    isOpen={this.state.openTMListModal}
                    closeModal={async () => {this.toggleModal();}}
                    onListReturn={(selectedList, clonedList) => this.props.viewModel.setSelectedList(selectedList, clonedList)}
                    modalList={this.props.viewModel.listSelectorClonedList}
                    modalTitle={
                        textConstants.Onboarding
                            .ListSelectorSubTitleRolesAndFunctions
                    }
                    
                />
            </div>
        );
    }
}
