import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import BatchReviewCampaignsVM from './BatchReviewExternalVM';
import { observer } from 'mobx-react';
import DashboardView from '../Home/DashboardView';
import { textConstants } from '../../common/textConstants';
import BatchReviewUploadView from '../BatchReview/BatchReviewUploadView';
import BatchReviewProspectsView from '../BatchReview/BatchReviewProspectsView';
import BatchReviewUploadVM from '../BatchReview/BatchReviewUploadVM';
import BatchReviewProspectsVM from '../BatchReview/BatchReviewProspectsVM';


class BatchReviewCampaignsViewParams {
    public clientId = {}
    public batchReviewMode = {}
}

@observer
export default class BatchReviewCampaignsView extends Views.ViewBase<BatchReviewCampaignsVM, BatchReviewCampaignsViewParams> {

    constructor(props: unknown) {
        super(textConstants.titleText.BatchReviewSpaced, BatchReviewCampaignsVM, props);
    }

    static params = new BatchReviewCampaignsViewParams();

    onLeave() {
        this.viewModel.removeNotifications();
        return undefined;
    }

    public async componentDidMount() {
        await this.viewModel.getClientId()
        this.viewParams.clientId.value = this.viewModel.clientId
        this.viewParams.batchReviewMode.value = textConstants.titleText.BatchReview
        this.viewModel.campaignsMode = textConstants.titleText.BatchReview
        this.viewModel.customAuthService.globalProps.isZendeskVisible = true;
    }

    public updateBreadcrumb(currentScreenName: string) {
        this.viewParams.batchReviewMode.description = currentScreenName;
    }

    public batchReviewRedirection() {
        if (this.viewModel.batchReviewUploadId === null || this.viewModel.batchReviewUploadId === 0) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const data = urlParams.get('brid')

            if (data !== null) {
                this.viewModel.batchReviewUploadId = parseInt(data)

                this.viewModel.showDashboard = false
            }
        }
    }

    public async viewParamsUpdated() {
        let clientId = this.viewParams.clientId.asNullableInt();

        await this.viewModel.customAuthService.reloadUserMenuAccessData()

        let hasBatchReview = this.viewModel.customAuthService.userMenuAccessInfo?.isBatchReview ?? false;

        // Fix naviagtion to Batch Review via breadcrumb. This also fixes navigation to Batch Review via side menu when already in Batch Review
        if (clientId === null) {
            await this.viewModel.getClientId()
            this.viewParams.clientId.value = this.viewModel.clientId
            this.viewParams.batchReviewMode.value = textConstants.titleText.BatchReview
            this.viewModel.campaignsMode = textConstants.titleText.BatchReview
            clientId = this.viewModel.clientId
        }

        if (clientId && hasBatchReview) {

            // Batch Review list screen
            if (this.viewParams.batchReviewMode.value === textConstants.titleText.BatchReview) {
                this.viewModel.showDashboard = false

                this.viewModel.BatchReviewUploadViewModel = new BatchReviewUploadVM();
                this.viewModel.BatchReviewUploadViewModel.BatchReviewUploadCriteria.isComXUser = false;
                this.viewModel.BatchReviewUploadViewModel.BatchReviewUploadCriteria.clientId = clientId;
                const clientName = await this.viewModel.getClientName(clientId);
                this.viewModel.setClientDisplayName(clientName);

                // Clear breadcrumb
                this.viewParams.batchReviewMode.description = ""

                this.viewModel.BatchReviewUploadViewModel.batchReviewUploadPageManager.refreshData();

                this.viewModel.showBatchReview = true
            } else if (this.viewParams.batchReviewMode.value === textConstants.titleText.BatchReviewProspects) {

                // Review Now screen
                this.viewModel.showBatchReview = false;
                // Will set the correct BatchReviewId based on the query params found in the URL
                // If nothing, normal process will resume
                this.batchReviewRedirection();

                this.viewModel.BatchReviewProspectsViewModel = new BatchReviewProspectsVM();
                this.viewModel.BatchReviewProspectsViewModel.isComXUser = false;

                this.viewModel.BatchReviewProspectsViewModel.criteria.batchReviewUploadId = this.viewModel.batchReviewUploadId;
                this.viewModel.BatchReviewProspectsViewModel.criteria.clientId = clientId;
                this.viewModel.BatchReviewProspectsViewModel.taskRunner = this.viewModel.taskRunner;
                this.viewModel.BatchReviewProspectsViewModel.criteria.isOnEntry = true;
                this.viewModel.BatchReviewProspectsViewModel.clientId = clientId;

                await this.viewModel.BatchReviewProspectsViewModel.fetchData(clientId)

                const clientName = await this.viewModel.getClientName(clientId);
                this.viewModel.setClientDisplayName(clientName);
                this.updateBreadcrumb(textConstants.generalText.ReviewNowSpaced);

                this.viewModel.showBatchReviewProspects = true;
            }

        } else {
            this.viewModel.initialCampaignLoad = true;
            this.viewModel.showBatchReview = false
            this.viewModel.showDashboard = true
            this.navigation.navigateToView(DashboardView, { clientId: null });
        }
    }

    public render() {
        return (
            <>
                {this.viewModel.showBatchReview &&
                    <Neo.Card>
                        <BatchReviewUploadView
                            viewModel={this.viewModel.BatchReviewUploadViewModel}
                            back={() => {
                                this.viewParams.clientId.value = null
                                this.viewModel.showBatchReview = false
                            }}
                            openBatchReviewProspects={(batchReviewUploadId: number) => {
                                this.viewModel.campaignsMode = textConstants.titleText.BatchReviewProspects;
                                this.viewModel.batchReviewUploadId = batchReviewUploadId;
                                this.viewParams.batchReviewMode.value = textConstants.titleText.BatchReviewProspects;
                            }}
                            isComXUser={false}
                        />
                    </Neo.Card>
                }

                {this.viewModel.showBatchReviewProspects && this.viewParams.batchReviewMode.value === textConstants.titleText.BatchReviewProspects &&
                    <BatchReviewProspectsView
                        viewModel={this.viewModel.BatchReviewProspectsViewModel}
                        back={() => {
                            this.viewModel.batchReviewUploadId = 0
                            this.viewParams.batchReviewMode.value = textConstants.titleText.BatchReview;
                            this.viewModel.showBatchReviewProspects = false;
                            this.viewModel.showBatchReview = true;
                        }}
                    />
                }
            </>
        );
    }
}