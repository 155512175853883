import React from 'react';
import { Views, Neo, NeoGrid } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import GoBack from '../../Components/GoBack';
import * as Icon from 'react-feather';
import IROIndustryMappingVM from './IROIndustryMappingVM';

@observer
export default class IROIndustryMappingView extends Views.ViewBase<IROIndustryMappingVM> {

	constructor(props: unknown) {
		super("IRO Industries Mapping", IROIndustryMappingVM, props);
	}

	public render() {
		return (
			<>
				<GoBack />

				<Neo.Card>
					{/* HEADING */}
					<h2 className='p-24 mt-4'>
						{textConstants.titleText.IROIndustriesMapping}
					</h2>

					<Neo.Form model={this.viewModel.iroIndustryMappingList} onSubmit={() => { this.viewModel.saveList() }} showSummaryModal>
						<NeoGrid.Grid items={this.viewModel.iroIndustryMappingList} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
							{(industryMapping, industrMappingMeta) => (
								<NeoGrid.Row >

									{/* INDUSTRY VERSION FROM */}
									<NeoGrid.Column
										bind={industrMappingMeta.industriesVersionIdFrom}
										select={{ displayMember: "industryVersionName", items: this.viewModel.industriesVersionList, allowNulls: true }}
									/>

									{/* SUB INDUSTRY FROM */}
									<NeoGrid.Column
										bind={industrMappingMeta.subIndustryIdFrom}
										select={{ displayMember: "subIndustryName", items: this.viewModel.subIndustryList }}
									/>

									{/* INDUSTRY VERSION TO */}
									<NeoGrid.Column
										bind={industrMappingMeta.industriesVersionIdTo}
										select={{ displayMember: "industryVersionName", items: this.viewModel.industriesVersionList }}
									/>

									{/* SUB INDUSTRY TO */}
									<NeoGrid.Column
										bind={industrMappingMeta.subIndustryIdTo}
										select={{ displayMember: "subIndustryName", items: this.viewModel.subIndustryList }}
									/>

									{/* DELETE */}
									<NeoGrid.ButtonColumn
										showDelete
										deleteButton={{
											icon: <Icon.X size={24} />,
											variant: "light",
											className: "circled"
										}} />
								</NeoGrid.Row>
							)}
						</NeoGrid.Grid>

						{/* SAVE */}
						<div className="my-4 text-right p-24">
							<Neo.Button className="btn-width-100" isSubmit variant="success">
								{textConstants.buttonText.Save}
							</Neo.Button>
						</div>
					</Neo.Form>
				</Neo.Card>
			</>
		);
	}
}