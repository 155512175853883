import { ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';

@NeoModel
export default class CloneICPCommand extends ModelBase {

  // Properties
  @Rules.Required()
  public customerProfileId: number = 0;
  
  @Rules.Required()
  public clientId: number = 0;

  @Rules.StringLength(500)
  @Rules.Required("Ideal Customer Profile Name is required.")
  public iCPName: string = "";

}