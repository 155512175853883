import { ModelBase, NeoModel} from '@singularsystems/neo-core';

@NeoModel
export default class ActionListBulkCommand extends ModelBase 
{
    public isSelectAll: boolean = false

    public isPageSelectAll: Nullable<boolean> = false

    public selectedCount: number = 0

    public deselectedLeadIds: number[] = []
}