import React from 'react';
import { Views, Neo, NeoGrid } from '@singularsystems/neo-react';
import ManagementFunctionVM from './ManagementFunctionVM';
import { observer } from 'mobx-react';
import GoBack from '../../Components/GoBack';
import { textConstants } from '../../common/textConstants';
import * as Icon from 'react-feather';

@observer
export default class ManagementFunctionView extends Views.ViewBase<ManagementFunctionVM> {

  constructor(props: unknown) {
    super("Management Function", ManagementFunctionVM, props);
  }

  onLeave() {
    this.viewModel.removeNotifications();
    return undefined;
  }

  public render() {
    return (
      <>
        <GoBack />
        <Neo.Card>
          <h2 className='p-24 mt-4'>{textConstants.titleText.ManagementFunction}</h2>
          <Neo.Form model={this.viewModel.managementFunctionList} onSubmit={() => this.viewModel.saveManagementFunctionList()} showSummaryModal>
            <NeoGrid.Grid className="collapsedButton" items={this.viewModel.managementFunctionList} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
              {(manFunction, meta) => (
                <NeoGrid.RowGroup expandProperty={meta.isExpanded}>

                  {/* Function */}
                  <NeoGrid.Row>
                    {/* Function Name */}
                    <NeoGrid.Column bind={meta.functionName}
                      label={textConstants.titleText.Function}
                    />
                    <NeoGrid.ButtonColumn showDelete
                      deleteButton={{
                        icon: <Icon.X size={24} />,
                        variant: "light",
                        className: "circled"
                      }
                      } />
                  </NeoGrid.Row>

                  {/* SubFunction */}
                  <NeoGrid.ChildRow>
                    <NeoGrid.Grid items={manFunction.managementSubFunctions} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
                      {(subFunction, subFunctionMeta) => (
                        <NeoGrid.RowGroup expandProperty={subFunctionMeta.isExpanded}>
                          <NeoGrid.Row>
                            {/* SubFunction */}
                            <NeoGrid.Column bind={subFunctionMeta.subFunctionName}
                              label={textConstants.titleText.SubFunction}
                            />
                            {/* Variation */}
                            <NeoGrid.Column bind={subFunctionMeta.variation}
                              label={textConstants.titleText.Variation}
                            />
                            <NeoGrid.ButtonColumn showDelete
                              deleteButton={{
                                icon: <Icon.X size={24} />,
                                variant: "light",
                                className: "circled"
                              }
                              } />
                          </NeoGrid.Row>

                          {/* Localisation */}
                          <NeoGrid.ChildRow>
                            <NeoGrid.Grid items={subFunction.managementSubFunctionLocalisations} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
                              {(localisation, localisationMeta) => (
                                <NeoGrid.Row>
                                  {/* Localisation */}
                                  <NeoGrid.Column bind={localisationMeta.localisation}
                                    label={textConstants.titleText.Localisation}
                                  />
                                  {/* Language */}
                                  <NeoGrid.Column bind={localisationMeta.languageId}
                                    select={{ displayMember: "languageName", items: this.viewModel.languages }}
                                  />
                                  <NeoGrid.ButtonColumn showDelete
                                    deleteButton={{
                                      icon: <Icon.X size={24} />,
                                      variant: "light",
                                      className: "circled"
                                    }
                                    } />
                                </NeoGrid.Row>
                              )}
                            </NeoGrid.Grid>
                          </NeoGrid.ChildRow>
                        </NeoGrid.RowGroup>

                      )}
                    </NeoGrid.Grid>
                  </NeoGrid.ChildRow>
                </NeoGrid.RowGroup>
              )}
            </NeoGrid.Grid>
            <div className="my-4 text-right p-24">
              <Neo.Button className="btn-width-100" isSubmit variant="success">{textConstants.buttonText.Save}</Neo.Button>
            </div>
          </Neo.Form>
        </Neo.Card>
      </>
    );
  }
}