import { observer } from "mobx-react";
import React from "react";
import ClientCampaignDataLookup from "../../Models/Dashboard/ClientCampaignDataLookup";
import DashboardCampaignName from "./DashboardCampaignName";
import DashboardVM from "../../Views/Home/DashboardVM";
import { textConstants } from "../../common/textConstants";
import { Neo } from "@singularsystems/neo-react";
import moment from "moment";
import { isMobile } from "../../common/utils";
import DashboardPositive from "./DashboardPositive";
import { Spinner } from "reactstrap";
import * as Icon from 'react-feather';

interface IDashboardTableRow {
  campaignData?: ClientCampaignDataLookup;
  isHeader?: boolean;
  viewModel?: DashboardVM;
  openCampaignsDetailOverview: (clientCampaignId: number) => void;
}

@observer
class DashboardTableRow extends React.Component<IDashboardTableRow> {
  constructor(props: any) {
    super(props);
  }

  public getFailedToLoadCampaignDataComponent(isMobile: boolean) {
    let failedComponent =
      <div>
        <div className={"campaignDataStatusContainer " + (isMobile ? "mb-3" : "m-auto")}>
          <span className="icon">
            ❌
          </span>
          {isMobile &&
            <div className="mt-2">
              {textConstants.generalText.campaignCard.failedToLoadCampaignData}
            </div>
          }
          {!isMobile &&
            <span className="ml-2">
              {textConstants.generalText.campaignCard.failedToLoadCampaignData}
            </span>
          }
        </div>
      </div>

    return failedComponent
  }

  public getUpdatingCampaignDataComponent(isMobile: boolean) {
    let updatingComponent =
      <div className={"campaignDataStatusContainer " + (isMobile ? "mb-3" : "m-auto")}>
        <Spinner animation="border" role="status" />
        {!isMobile &&
          <span className="ml-2 dashboardLoadingText">
            {textConstants.generalText.campaignCard.loadingCampaignData}
          </span>
        }
        {isMobile &&
          <div className="mt-2 dashboardLoadingText">
            {textConstants.generalText.campaignCard.loadingCampaignData}
          </div>
        }
      </div>

    return updatingComponent
  }

  render() {
    return (
      <div className="dashboardRowContainer">
        {/*Header Row*/}
        {this.props.isHeader && (
          <div className="row campaignsTableHeader mr-0 ml-0 mb-2 dashboard-grid-layout non-mobile">
            {/*Name Column*/}
            <div className="" id="nameHeader">
              <div className="row m-0 pl-0 pr-0 text-left refresh">
                {/* REFRESH */}
                {!this.props.viewModel!.isRetrievingCampaignData &&
                  <>
                    <Neo.Button
                      variant="light"
                      className="mr-3 mb-2 pb-2"
                      icon={<Icon.RotateCw size={20} className="mr-2" />}
                      onClick={async () => {
                        await this.props.viewModel?.refreshDashboard()
                      }}
                    >
                      {textConstants.buttonText.Refresh}
                    </Neo.Button>
                    <div className="mt-2">{moment.utc(this.props.viewModel?.lastRefresh).local().parseZone().format("DD/MM/yyyy, HH:mm")}</div>
                  </>
                }

                {/* REFRESHING */}
                {this.props.viewModel!.isRetrievingCampaignData &&
                  <Neo.Button
                    variant="light"
                    className="mr-3 mb-2 pb-2 refreshingButton"
                    icon={<Spinner animation="border" role="status" />}
                  >
                    {textConstants.buttonText.Refreshing}
                  </Neo.Button>
                }

              </div>
            </div>

            {!isMobile() && <>
              {/* Mailed Delivered */}
              <div className="p-0 m-auto">
                {textConstants.Dashboard.mailDelivered}
              </div>

              {/* Opened */}
              <div className="p-0 m-auto">
                {textConstants.Dashboard.open}
              </div>

              {/*Replies Column Header*/}
              <div
                id="replyHeader"
                className="p-0 m-auto">
                {textConstants.Dashboard.replies}
              </div>

              {/*Positive Column Header*/}
              <div
                className="p-0 m-auto"
                id="positiveHeader">
                {textConstants.Dashboard.positive}
              </div>

              {/*Neutral Column Header*/}
              <div
                className="p-0 m-auto"
                id="neutralHeader">
                {textConstants.Dashboard.neutral}
              </div>

              <div
                className="m-auto">
              </div>
            </>}
          </div>
        )}

        {/*Data Row*/}
        {!this.props.isHeader && (
          <div className={`row dashboard-grid-layout ${isMobile() ? 'mobile' : 'non-mobile'}`}>
            {/*Name Column*/}
            <div className={`header-item`}>
              <DashboardCampaignName
                name={this.props.campaignData!.campaignName}
                status={this.props.campaignData!.campaignStatus}
                sendOutEmail={this.props.campaignData?.emailSender}
              />
            </div>

            <>
              {/* Mail Delivered */}
              <div className="m-auto rowFontBig">
                <div className="row mb-3">
                  {this.props.campaignData!.deliveryCount}
                </div>
              </div>

              {/* Opened */}
              <div className="m-auto rowFontBig">
                <div className="row">
                  {this.props.campaignData!.openedCount}
                </div>
                {/* Opened percentage */}
                <div className="row">
                  <span className="generalPercentage">
                    {this.props.campaignData?.openedRate}%
                  </span>
                </div>
              </div>

              {/*Replies Column*/}
              <div
                className="m-auto px-0 rowFontBig replyColor">
                <div className="row">
                  {this.props.campaignData!.repliedCount}
                </div>
                <div className="row">
                  {/* Opened percentage */}
                  <span className="generalPercentage">
                    {this.props.campaignData?.repliedRate}%
                  </span>
                </div>
              </div>

              {/*Positive Column*/}
              <div
                className={`m-auto px-0 rowFontBig`}>
                <DashboardPositive
                  positiveCount={this.props.campaignData!.positiveCount}
                  positivePercentage={this.props.campaignData?.positivity}
                />
              </div>

              {/*Neutral Column*/}
              <div
                className={`m-auto px-0 rowFontBig neutral-color`}>
                <div className="row">
                  {this.props.campaignData!.neutralCount}
                </div>
                {/* Neutral percentage after expanding*/}
                <div className="row">
                  <span className="generalPercentage">
                    {this.props.campaignData?.neutrality}%
                  </span>
                </div>
              </div>

              {/* Navigate to Campaign overview*/}
              {this.props.campaignData !== undefined &&
                <div className="text-center mr-0 m-auto p-0">
                  <Neo.Button
                    className="text-center pull-right campaignEmailOverview"
                    variant="light"
                    disabled={this.props.campaignData.clientCampaignsWoodpeckerEmails.length === 0}
                    onClick={() => {
                      if (this.props.viewModel) {
                        this.props.viewModel.isWoodpeckerEmailOverview = true
                      }
                      this.props.openCampaignsDetailOverview(this.props.campaignData!.clientCampaignId)
                    }}>
                    {<Icon.ArrowRight />}
                  </Neo.Button>
                </div>
              }
            </>
          </div>
        )}
      </div>
    );
  }
}

export default DashboardTableRow;
