import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import ProspectingVM from '../ProspectingVM';
import { observer } from 'mobx-react';
import { textConstants } from '../../../common/textConstants';
import * as Icon from 'react-feather';

interface IUploadProspectsTab {
    viewModel: ProspectingVM
}

@observer
export default class UploadProspectsTab extends React.Component<IUploadProspectsTab> {

    public onDocumentSelected(fileList: File[] | FileList) {
        this.props.viewModel.fileList = fileList
        this.props.viewModel.fileName = fileList[0].name;
        this.props.viewModel.uploadProspectFile(fileList);
    }
    public render() {
        return (
            <Neo.Tab header={textConstants.titleText.UploadProspects}>
                <h3 className='mb-2 p-0 mt-4'>{textConstants.titleText.UploadProspects}</h3>
                <div className='row m-0 uploadProspect'>
                    <div className='col-12 p-0'>
                        <p> {textConstants.titleText.PleaseEnsureHeaders} </p>
                        <ol className='mb-1'>
                            {this.props.viewModel.columnHeadings.map((i) =>
                                <li className="dropZoneListDisplay" key={i}> {i} </li>
                            )}
                            <a className="text-right customDownloadLink"
                                onClick={() => this.props.viewModel.downloadUploadProspectTemplate()}>{textConstants.generalText.DownloadTemplate}</a>
                        </ol>
                        <Neo.FileContext onFilesSelected={(e) => this.onDocumentSelected(e)} allowedExtensions={[".csv"]}>
                            <Neo.FileDropArea multiple={false}>
                                <p className="text-center dropZoneText">
                                    <Neo.FileUploadButton>
                                        <Icon.Upload />
                                        {textConstants.generalText.uploadOrDragFile}
                                    </Neo.FileUploadButton>
                                </p>
                            </Neo.FileDropArea>
                        </Neo.FileContext>
                        <p id="uploadedFileName" className="mt-2 mb-0"> Uploaded File: {this.props.viewModel.fileName} </p>
                    </div>
                </div>
            </Neo.Tab>
        );
    }
}