import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class AdvancedEmailSettingsSaveCommand extends ModelBase {
   
    public clientId : number = 0;
  
    public isFirewall : boolean = false;
  
    public isDKIM : boolean = false;
  
    public isSPF : boolean = false;
  
    public isCNAME : boolean = false;

    public testEmailAddress : string = "";
}