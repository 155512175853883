import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { textConstants } from '../../../common/textConstants';
import GoBack from "../../../Components/GoBack";
import OnboardingPopupMaintenanceVM from './OnboardingPopupMaintenanceVM';
import RichTextEditor from '../../../Components/RichTextEditor';
import TooltipIndividualScreenView from './TooltipIndividualScreenView';
import * as Icon from 'react-feather';

@observer
export default class OnboardingPopupMaintenanceView extends Views.ViewBase<OnboardingPopupMaintenanceVM> {

    constructor(props: unknown) {
        super("Onboarding Popup", OnboardingPopupMaintenanceVM, props);
    }

    onLeave() {
        this.viewModel.removeNotifications();
        return undefined;
    }

    public render() {
        return (
            <div className="popupMaintenance">
                <GoBack />
                <Neo.Card>
                    <h2 className='p-24 mt-4 mb-3'>{textConstants.titleText.Popups}</h2>
                    <div>
                        {this.viewModel.showInitialTooltipTable &&
                            <NeoGrid.Grid items={this.viewModel.dashboardToolTips}>
                                {(item, meta) => (
                                    <NeoGrid.Row >
                                        <NeoGrid.Column display={meta.popupName} />
                                        <NeoGrid.ButtonColumn width={1}>
                                            <Neo.Button variant="light" className="text-center" icon={<Icon.Edit2 />}
                                                onClick={() => {
                                                    this.viewModel.dashboardTooltip = item;
                                                    this.viewModel.navigationList.push("Individual Screen Maintenance");
                                                    this.viewModel.toggleView();
                                                    this.viewModel.showInitialOnboardingTable = false;
                                                }}>
                                            </Neo.Button>
                                        </NeoGrid.ButtonColumn>
                                    </NeoGrid.Row>
                                )}
                            </NeoGrid.Grid>}
                        {this.viewModel.showPerScreenView &&
                            <TooltipIndividualScreenView
                                viewModel={this.viewModel}
                                dashboardTooltip={this.viewModel.dashboardTooltip}
                                save={() => { this.viewModel.saveIndividualTooltipScreenData() }} />
                        }
                    </div>
                </Neo.Card>
            </div>
        );
    }
}