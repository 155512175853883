import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import CustomerProfileQuestion from '../../Models/Maintenance/CustomerProfileQuestion';
import { AppService, Types } from '../../Services/AppService';

@NeoModel
export default class CustomerProfileQuestionVM extends Views.ViewModelBase {


    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private customerProfileQuestionsApiClient = AppService.get(Types.ApiClients.CustomerProfileQuestionsApiClient),
        private appDataCache = AppService.get(Types.Services.AppDataCache)) {

        super(taskRunner);
    }

    public customerProfileQuestions = new List(CustomerProfileQuestion)
    public showEditModal: boolean = false
    public isQuestionText: boolean = false
    public isHelpText: boolean = false
    public isPlaceholderText: boolean = false
    public selectedQuestionId: number = 0
    public selectedText: string = ""
    public textType: number = 0

    public async initialise() {
        const customerProfileQuestionsResponse = await this.taskRunner.waitFor(this.customerProfileQuestionsApiClient.get());
        this.customerProfileQuestions.set(customerProfileQuestionsResponse.data.sortBy(q => q.maintenanceQuestionNumber));
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }


    public async save() {
        this.taskRunner.run(async () => {
            const customerProfileQuestionsResponse = await this.customerProfileQuestionsApiClient.saveList(this.customerProfileQuestions.toJSArray())

            this.customerProfileQuestions.update(customerProfileQuestionsResponse.data)

            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.ConfigSaved)

            // Refresh the comXConfiguration common data list
            this.appDataCache.comXConfigurations.expire();
        });
    }

    public UpdateSelectedPopUpText(updatedText: string) {
        let question = this.customerProfileQuestions.find(f => f.customerProfileQuestionId === this.selectedQuestionId);

        if (question  !== undefined) {
            switch (this.textType) {
                case 0: question.questionText = updatedText;
                    break
                case 1: question.helperCopy = updatedText
                    break
                default: return;
            }
        }
    }
}