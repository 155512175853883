import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import CustomerProfileQuestion from '../Models/Maintenance/CustomerProfileQuestion';

export interface ICustomerProfileQuestionsApiClient extends Data.IUpdateableApiClient<CustomerProfileQuestion, number> {

}

@injectable()
export default class CustomerProfileQuestionsApiClient extends Data.UpdateableApiClient<CustomerProfileQuestion, number> implements ICustomerProfileQuestionsApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/CustomerProfileQuestions`);
    }
}