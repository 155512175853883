import { NeoModel, List } from '@singularsystems/neo-core';
import { AppService, Types } from '../../Services/AppService';
import TargetMarketAccountTab from './../../Models/Maintenance/TargetMarketAccountTab';
import TargetMarketAccountStatus from '../../Models/Maintenance/TargetMarketAccountStatus';
import TargetMarketAccountTabStatus from '../../Models/Maintenance/TargetMarketAccountTabStatus';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';

@NeoModel
export default class TargetMarketAccountTabStatusVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private appDataCache = AppService.get(Types.Services.AppDataCache),
        private targetMarketAccountTabStatusesApiClient = AppService.get(Types.ApiClients.TargetMarketAccountTabStatusesApiClient)) {

        super(taskRunner);
    }

    public TargetMarketAccountTabList = new List(TargetMarketAccountTab)
    public TargetMarketAccountStatusList = new List(TargetMarketAccountStatus);
    public TargetMarketAccountTabStatusList = new List(TargetMarketAccountTabStatus);

    public initialise() {
        this.setUpViewModel();
    }

    public async setUpViewModel() {

        const targetMarketAccountTabs = (await this.taskRunner.waitFor(this.appDataCache.targetMarketAccountTabs.getDataAsync())).sortBy("tabName");
        this.TargetMarketAccountTabList.set(targetMarketAccountTabs);

        const targetMarketAccountStatuses = (await this.taskRunner.waitFor(this.appDataCache.targetMarketAccountStatuses.getDataAsync())).sortBy("targetMarketAccountStatusName")
        this.TargetMarketAccountStatusList.set(targetMarketAccountStatuses);

        this.taskRunner.run(async () => {
            const response = await this.targetMarketAccountTabStatusesApiClient.getTargetMarketAccountTabStatus();
            this.TargetMarketAccountTabStatusList.set(response.data);
        });
    }

    public saveList(){
        this.taskRunner.run(async () => {
            const Data = this.TargetMarketAccountTabStatusList.toJSArray();
            await this.targetMarketAccountTabStatusesApiClient.saveList(Data);
            const response = await this.targetMarketAccountTabStatusesApiClient.getTargetMarketAccountTabStatus();
            this.TargetMarketAccountTabStatusList.set(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved,
                                          textConstants.messageText.saveMessage.IndustrySubIndustriesSaved);
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}