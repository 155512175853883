import { Neo } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import MeditationImg from '../../assets/illustrations/Illustration_Complete_Steps.svg'
import GlobalProps from "../../Models/Onboarding/GlobalProps";
import { textConstants } from './../../common/textConstants';
import OnboardingVM from "./OnboardingVM";
import StyledButton from 'Components/Button';
import CheckedCircle from 'assets/svgs/CheckedCircle';

import {
    StyledTitle,
    StyledParagrapgh
} from './Styles/EmailSetupView.styles'

import {
    StyledCompeteYourStepWrapper,
    StyledSvgWrapper,
    StyledPaper,
    StyledStepList,
    StyledListItem
} from './Styles/CompleteYourSetup'
import { AppService, Types } from "Services/AppService";
import { NavLink } from "react-router-dom";

interface ICompleteYourSetupViewProps {
    viewModel: OnboardingVM,
    globalProps: GlobalProps,
    goToCurrentStep: () => void,
}

@observer
export default class CompleteYourSetupView extends React.Component<ICompleteYourSetupViewProps> {

    private onboardingComplete = false;

    componentDidMount(): void {
        this.props.viewModel.customAuthService.globalProps.isZendeskVisible = false;
        this.onboardingComplete = this.isOnboardingComplete();
    }

    public renderStepIcon = (stepNumber: Number) => {
        if (this.isStepCompleted(stepNumber)) {
            return (
                <CheckedCircle />
            );
        }
        return (
            <div><>{stepNumber}</></div>
        );
    }

    private isOnboardingComplete() {
        const { globalProps } = this.props.viewModel.customAuthService

        if (globalProps.onboardingSteps && globalProps.onboardingSteps.length > 0) {
            return globalProps.onboardingSteps.filter(x => x.isComplete)?.length === globalProps.onboardingSteps.length;
        }
        return false;
    }

    public isStepCompleted(stepNumber: Number): boolean {
        const { onboardingSteps } = this.props.globalProps;
        let foundStep = onboardingSteps.find(os => os.stepNumber === stepNumber);
        let isStepCompleted = foundStep?.isComplete || false;
        return isStepCompleted;
    }

    public render() {
        return (
            <StyledCompeteYourStepWrapper>
                {this.props.viewModel.customAuthService.globalProps.isOnboarding &&
                    <StyledPaper>
                        <StyledSvgWrapper>
                            <img src={MeditationImg} alt="" />
                        </StyledSvgWrapper>

                        <StyledTitle>{textConstants.Onboarding.CompleteYourSetup.Title}</StyledTitle>
                        <StyledParagrapgh>{textConstants.Onboarding.CompleteYourSetup.SubTitle}</StyledParagrapgh>
                        <React.Fragment>
                            <StyledStepList>
                                <StyledListItem>{this.renderStepIcon(1)}
                                    <div>{textConstants.Onboarding.CompleteYourSetup.Step1Title}</div></StyledListItem>
                                <StyledListItem>{this.renderStepIcon(2)}
                                    <div>{textConstants.Onboarding.CompleteYourSetup.Step2Title}</div></StyledListItem>
                                <StyledListItem>{this.renderStepIcon(3)}
                                    <div>{textConstants.Onboarding.CompleteYourSetup.Step3Title}</div></StyledListItem>
                                <StyledListItem>{this.renderStepIcon(4)}
                                    <div>{textConstants.Onboarding.CompleteYourSetup.Step4Title}</div></StyledListItem>
                                <StyledListItem>{this.renderStepIcon(5)}
                                    <div>{textConstants.Onboarding.CompleteYourSetup.Step5Title}</div></StyledListItem>
                                <StyledListItem>{this.renderStepIcon(6)}
                                    <div>{textConstants.Onboarding.CompleteYourSetup.Step6Title}</div></StyledListItem>
                            </StyledStepList>
                        </React.Fragment>
                        {!this.onboardingComplete &&
                            <StyledButton
                                variant="contained"
                                size="large"
                                buttonType="secondary"
                                onClick={() => { this.props.goToCurrentStep() }}>
                                {textConstants.Onboarding.Continue}
                            </StyledButton>
                        }
                        {this.onboardingComplete &&
                            <NavLink key={textConstants.RouteNames.dashboard} to={textConstants.RouteNames.dashboard}>
                                <StyledButton
                                    variant="contained"
                                    size="large"
                                    buttonType="secondary"
                                    onClick={() => { this.props.viewModel.completeClientOnboarding() }}>
                                    {textConstants.buttonText.FinishOnboarding}
                                </StyledButton>
                            </NavLink>
                        }
                    </StyledPaper>
                }
            </StyledCompeteYourStepWrapper>
        );
    }

}