import { List } from "@singularsystems/neo-core";
import ListSelectorModalLookup from "Components/Modals/ListSelectorModalLookup";
import ListSelectorModalSubListLookup from "Components/Modals/ListSelectorModalSubListLookup";
import { SectionCode } from "Models/Maintenance/SectionCode";
import TargetMarketIndustryLookup from "Models/TargetMarket/TargetMarketIndustryLookup";
import TargetMarketManagementRolesLookup from "Models/TargetMarket/TargetMarketManagementRolesLookup";
import TargetMarketManagementFunctionLookup from "Models/TargetMarket/TargetMarketManagementFunctionLookup";

export const validatePriority = (list: List<ListSelectorModalLookup>) => {
    let priorityFound = false;

    for (let item of list) {
        if (item.isPriority || item.isPriority === null) {
            priorityFound = true;
            break;
        }
        for (let subItem of item.subList) {
            if (subItem.isPriority) {
                priorityFound = true;
                break;
            }
        }
    }

    return priorityFound;
};

export const validateNotPriority = (list: List<ListSelectorModalLookup>) => {
    let itemsFound = false;

    itemsFound = list.length > 0 ? true : false;

    return itemsFound;
};

export const togglePriority = (
    item: ListSelectorModalLookup,
    subItem: ListSelectorModalSubListLookup | null
) => {
    if (item.isPriority !== null) {
        // Select All
        if (subItem === null) {
            // we set all the sub items to have been selected
            item.subList.forEach((subItemChanged) => {
                // We only set the selected subItems to priority
                if (subItemChanged.isSelected) {
                    subItemChanged.isPriority = item.isPriority;
                }
            });
        }
        item.isPriority =
            item.subList.find(
                (subItemChanged) => subItemChanged.isPriority === false && subItemChanged.isSelected
            ) === undefined;
    }
};

export const deselectItem = (
    list: List<ListSelectorModalLookup>,
    item: ListSelectorModalLookup,
    subItem: ListSelectorModalSubListLookup | null,
    isClearAll: boolean = false
) => {
    // Unselect All
    if (subItem === null) {
        // we set all the sub items to be unselected
        item.subList.forEach((subItem) => {
            subItem.isSelected = false;
            subItem.isPriority = subItem.isPriority !== null ? false : null;
        });
        item.isSelected = false;
    } else {
        // since we know we are unselecting a subItem (not an unselect all) we set that subItem to be unselected
        subItem.isSelected = false;
        subItem.isPriority = subItem.isPriority !== null ? false : null;
        togglePriority(item, subItem);
    }

    // if the main item does not have any selected then we remove from the selected column
    if (
        item.subList.find((subItem) => subItem.isSelected === true) ===
        undefined
    ) {
        item.isSelected = false;
        item.isSelectedExpanded = false;
        item.isPriority = item.isPriority !== null ? false : null;

        if (!isClearAll) {
            list.remove(item);
        }
    }
};

export const getSelectedNumberOfItems = (
    list: List<ListSelectorModalLookup>
) => {
    let totalNumberOfItems = 0;

    list.forEach((item) => {
        totalNumberOfItems += item.subList.filter(
            (subItem) => subItem.isSelected === true
        ).length;
    });

    return totalNumberOfItems;
};

export const mapSelectedValuesToExistingModel = (
    sectionCode: string,
    selectedList: List<ListSelectorModalLookup>,
    existingList: any
) => {
    switch (sectionCode) {
        // MAPPING INDUSTRIES
        case SectionCode.TM2:
            // Cast the list to its correct type
            let industries = existingList as List<TargetMarketIndustryLookup>;

            // Clear the list to account for existing selections that got removed
            industries.forEach((industry) => {
                industry.isSelected = false;
                industry.isPriority = false;

                industry.subIndustries.forEach((subIndustry) => {
                    subIndustry.isSelected = false;
                    subIndustry.isPriority = false;
                });
            });

            if (selectedList.length) {
                selectedList.forEach((industry) => {
                    // Find the current Industry
                    let existingIndustry = industries.find(
                        (f) => f.industryId === industry.itemId
                    );

                    if (!existingIndustry) {
                        return;
                    }

                    // Update the industry
                    existingIndustry.isSelected = industry.isSelected;
                    existingIndustry.isExpanded = industry.isExpanded;
                    existingIndustry.isPriority = industry.isPriority;

                    industry.subList.forEach((subIndustry) => {
                        // find the sub industry
                        let existingSubIndustry =
                            existingIndustry!.subIndustries.find(
                                (f) => f.subIndustryId === subIndustry.subItemId
                            );

                        if (!existingIndustry) {
                            return;
                        }

                        // then update the existing sub industries
                        existingSubIndustry!.subIndustryId =
                            subIndustry.subItemId;
                        existingSubIndustry!.subIndustryName =
                            subIndustry.subItemName;
                        existingSubIndustry!.isSelected =
                            subIndustry.isSelected as boolean;
                        existingSubIndustry!.isPriority =
                            subIndustry.isPriority as boolean;
                    });
                });
            }

            break;

        // MAPPING MANAGEMENT ROLES
        case SectionCode.TM3:
            let managementRoles = existingList as List<TargetMarketManagementRolesLookup>;

            managementRoles.forEach((role) => {
                role.isSelected = false;
            });

            if (selectedList.length) {
                selectedList[0].subList.forEach((role) => {
                    let existingManagementRole = managementRoles.find(
                        (f) => f.managementSubRoleId === role.subItemId
                    );

                    if (!existingManagementRole) {
                        return;
                    }

                    existingManagementRole.managementSubRoleId = role.subItemId;
                    existingManagementRole.roleName = role.subItemName;
                    existingManagementRole.isSelected =
                        role.isSelected as boolean;
                });
            }
            break;

        // MAPPING MANAGEMENT FUNCTIONS
        case SectionCode.TM4:
            let managementFunctions = existingList as List<TargetMarketManagementFunctionLookup>;

            managementFunctions.forEach((managementFunction) => {
                managementFunction.isSelected = false;
                managementFunction.isPriority = false;

                managementFunction.targetMarketManagementSubFunctionsLookup.forEach(
                    (subFunction) => {
                        subFunction.isSelected = false;
                        subFunction.isPriority = false;
                    }
                );
            });

            if (selectedList.length) {
                selectedList.forEach((managementFunction) => {
                    let existingFunction = managementFunctions.find(
                        (f) =>
                            f.managementFunctionId === managementFunction.itemId
                    );

                    if (!existingFunction) {
                        return;
                    }

                    // Update the industry
                    existingFunction.managementFunctionId =
                        managementFunction.itemId;
                    existingFunction.functionName = managementFunction.itemName;
                    existingFunction.isSelected = managementFunction.isSelected;
                    existingFunction.isExpanded = managementFunction.isExpanded;
                    existingFunction.isPriority = managementFunction.isPriority;

                    managementFunction.subList.forEach((subFunction) => {
                        let existingSubFunction =
                            existingFunction!.targetMarketManagementSubFunctionsLookup.find(
                                (f) =>
                                    f.managementSubFunctionId ===
                                    subFunction.subItemId
                            );

                        if (!existingSubFunction) {
                            return;
                        }

                        existingSubFunction.managementSubFunctionId =
                            subFunction.subItemId;
                        existingSubFunction.functionName =
                            subFunction.subItemName;
                        existingSubFunction.isSelected =
                            subFunction.isSelected as boolean;
                        existingSubFunction.isPriority =
                            subFunction.isPriority as boolean;
                    });
                });
            }
            break;
    }
};
