import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import AddEditTargetMarketVM from '../TargetMarket/AddEditTargetMarketVM';
import TargetMarketVM from '../TargetMarket/TargetMarketVM';
import TargetMarketAccountsVM from '../TargetMarketAccounts/TargetMarketAccountsVM';
import ClientSettingsVM from './ClientSettingsVM';

@NeoModel
export default class BlacklistCampaignsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private authenticationService = AppService.get(Types.Neo.Security.AuthenticationService),
        private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
        public customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
        public blacklistApiClient = AppService.get(Types.ApiClients.BlacklistedDomainsApiClient)) {

        super(taskRunner);
        this.customAuthService.globalProps.isOnboarding = false;
    }

    public showAddTargetMarket: boolean = false;
    public AddEditTargetMarketViewModel = new AddEditTargetMarketVM();
    public showEditTargetMarket: boolean = false;
    public TargetMarketAccountsViewModel = new TargetMarketAccountsVM();
    public showTargetMarketAccounts: boolean = false;

    public showTargetMarkets: boolean = false;
    public clientId: number = 0
    public initialCampaignLoad: boolean = true;
    public showBlacklist: boolean = true
    public showDashboard: boolean = true
    public targetMarketId: number = 0;
    public targetMarketName: string = "";

    public ClientSettingsViewModel = new ClientSettingsVM();
    public TargetMarketViewModel = new TargetMarketVM();

    public async initialise() {
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }

    public async getClientId() {
        const { user, isAuthenticated } = this.authenticationService;

        // Fetch the platformSetup and successTracker urls
        if (isAuthenticated) {
            const response = await this.clientsApiClient.getDashboardInfo(user!.userName);
            this.clientId = response.data.clientId;
        }
    }

    public async getClientName(clientId: number) {
        const response = await this.taskRunner.waitFor(this.clientsApiClient.getClient(clientId));
        return response.data.clientName;
    }
}