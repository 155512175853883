import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class TargetMarketStatus extends ModelBase {

    public targetMarketStatusId: number = 0;
    
    @Rules.StringLength(500)
    @Attributes.Display("Target Market Status")
    public targetMarketStatusName: string = "";

    @Attributes.Display("Order Number")
    public targetMarketOrderNumber: number = 0;

    public uniqueTableKey: string = "";
    
    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.targetMarketStatusName === null || c.targetMarketStatusName === '', textConstants.messageText.validationMessage.targetMarketStatusName);
    }

    public toString(): string {
        if (this.isNew || !this.targetMarketStatusName) {
            return "New target market status";
        } else {
            return this.targetMarketStatusName;
        }
    }
}