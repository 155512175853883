import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class FunctionType extends ModelBase {

    public functionTypeId: number = 0;
    
    @Rules.StringLength(500)
    @Attributes.Display("Function Type")
    public functionTypeName: string = "";

    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.functionTypeName === null || c.functionTypeName === '', textConstants.messageText.validationMessage.functionTypeName);
    }

    public toString(): string {
        if (this.isNew || !this.functionTypeName) {
            return "New function type";
        } else {
            return this.functionTypeName;
        }
    }
}