import { NeoModel, List,} from '@singularsystems/neo-core';
import Country from './../../Models/Maintenance/Country';
import { AppService, Types } from '../../Services/AppService';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';

@NeoModel
export default class CountryVM extends Views.ViewModelBase {
    
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private countriesApiClient =  AppService.get(Types.ApiClients.CountriesApiClient),
        private languagesApiClient = AppService.get(Types.ApiClients.LanguagesApiClient),
        private appDataCache = AppService.get(Types.Services.AppDataCache)) {

        super(taskRunner);
    }

    // Properties
    public countryList = new List(Country);
    public languageList = this.appDataCache.languages.get().data.sortBy("languageName");

    public async initialise() {  
        this.getCountries();
        this.getLanguages();
    }

    public saveCountryList() {

        this.countryList.forEach(country => {
            country.regions.forEach(region => {
                region.countryId = country.countryId
                region.subRegions.forEach(subRegion => {
                    subRegion.regionId = region.regionId
                });
            });
        });
    
        this.taskRunner.run(async () => {
            await this.countriesApiClient.insertUpdateCountries(this.countryList);
            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.CountriesSaved);
            this.getCountries();
        });
    }

    public async getCountries(){
        const countriesResponse = await this.taskRunner.waitFor(this.countriesApiClient.getCountriesAsync());
        this.countryList.set(countriesResponse.data);
    }

    public async getLanguages() {
        const response = await this.taskRunner.waitFor(this.languagesApiClient.get());
        this.languageList = response.data.sortBy("languageName");        
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}