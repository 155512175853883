import { NeoModel, List } from '@singularsystems/neo-core';
import { PartialPlainObject } from '@singularsystems/neo-core/dist/Model';
import { Views } from '@singularsystems/neo-react';
import OnboardingStepCompletionLookup from '../../Models/Onboarding/OnboardingStepCompletionLookup';
import OnboardingStepLookup from '../../Models/Onboarding/OnboardingStepLookup';
import { AppService, Types } from '../../Services/AppService';
import CampaignMessageVM from '../CampaignMessages/CampaignMessageVM';
import ClientSettingsVM from '../Client/ClientSettingsVM';
import DashboardVM from '../Home/DashboardVM';
import CustomerProfileQuestionnaireVM from '../IdealCustomerProfiles/CustomerProfileQuestionnaireVM';
import CustomerProfilesVM from '../IdealCustomerProfiles/CustomerProfilesVM';
import AddEditTargetMarketVM from '../TargetMarket/AddEditTargetMarketVM';
import TargetMarketVM from '../TargetMarket/TargetMarketVM';
import TargetMarketAccountsVM from '../TargetMarketAccounts/TargetMarketAccountsVM';
import EmailSetupVM from './EmailSetupVM';
import ClientCampaignMessageVM from '../CampaignMessages/ClientCampaignMessageVM';
import MeetingsVM from './MeetingsVM';
import TechnicalSettingsVM from '../Client/TechnicalSettingsVM';
import AdvancedEmailSettingsVM from '../../Components/TechnicalIntegrationSettings/AdvancedEmailSettingsVM';
import CampaignMessageComingVM from './CampaignMessageComingVM';
import CampaignEmailSignatureVM from '../CampaignMessages/CampaignEmailSignatureVM';
import AddEditFollowUpTemplateVM from '../CampaignMessages/AddEditFollowUpTemplateVM';
import UpdateClientOnboardingStepIntroductionCommand from 'Models/Onboarding/Commands/UpdateClientOnboardingStepIntroductionCommand';
import ClientOnboardingStepIntroduction from 'Models/Onboarding/ClientOnboardingStepIntroduction';
import { textConstants } from 'common/textConstants';
import OnboardingCampaignMessageBaseView from './OnboardingCampaignMessageBaseView';
import OnboardingCampaignMessageBaseViewVM from './OnboardingCampaignMessageBaseViewVM';
import OnboardingTechnicalSettingsVM from './OnboardingTechnicalSettingsVM';
import { OnboardingSteps } from 'Models/Enums/ApplicationEnums';
import OnboardingFunctions from 'Models/Onboarding/OnboardingFunctions';

@NeoModel
export default class OnboardingVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
        private authenticationService = AppService.get(Types.Neo.Security.AuthenticationService),
        private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient),
        private customerProfilesApiClient = AppService.get(Types.ApiClients.CustomerProfilesApiClient),
        private config = AppService.get(Types.Config),
    ) {

        super(taskRunner);
    }

    // ICP
    public customerProfileQuestionnaireViewModel: CustomerProfileQuestionnaireVM = new CustomerProfileQuestionnaireVM();
    public CustomerProfilesViewModel: CustomerProfilesVM = new CustomerProfilesVM();
    public showICPQuestionnaireView: boolean = false;
    public showQuestionnaireViewEdit: boolean = false;
    public showICPListView: boolean = false;
    public showQuestionnaireViewAdd: boolean = false;
    public customerProfileId: number = 0;
    public customerProfilesCount: number = 0;
    // ICP END

    // Target Markets
    public addEditTargetMarketViewModel: AddEditTargetMarketVM = new AddEditTargetMarketVM();
    public targetMarketViewModel = new TargetMarketVM();
    public targetMarketAccountsViewModel = new TargetMarketAccountsVM();
    public showTMListView: boolean | null = null;
    public showTMAView: boolean = false;
    public showAddEditTMView: boolean = false;
    public targetMarketId: number = 0;
    public canSeeTMListView: boolean = false;
    public canSeeICPListView: boolean = false;

    //Blacklist 
    showBlacklistView: boolean = false;

    public emailSetupViewModel: EmailSetupVM = new EmailSetupVM()
    public dashboardViewModel: DashboardVM = new DashboardVM()
    public clientSettingsViewModel: ClientSettingsVM = new ClientSettingsVM()
    public clientCampaignMessagesViewModel = new ClientCampaignMessageVM();
    public campaignMessageViewModel: CampaignMessageVM = new CampaignMessageVM();
    public addEditFollowUpTemplateViewModel: AddEditFollowUpTemplateVM = new AddEditFollowUpTemplateVM;
    public emailSignatureViewModel: CampaignEmailSignatureVM = new CampaignEmailSignatureVM();
    public meetingsViewModel: MeetingsVM = new MeetingsVM();
    public technicalSettingsViewModel: TechnicalSettingsVM = new TechnicalSettingsVM();
    public advancedEmailSettingsVM = new AdvancedEmailSettingsVM();
    public onboardingTechnicalSettingsVM = new OnboardingTechnicalSettingsVM();
    public campaignMessageComingViewModel = new CampaignMessageComingVM()
    public campaignMessageBaseViewVM = new OnboardingCampaignMessageBaseViewVM();

    public clientCampaignMessageId: number = 0
    public showCampaignMessageListView: boolean | null = null
    public isCampaignMessageReady: boolean = false
    public isComingFromFollowUpTemplates: boolean = false

    public isIntroductionComplete: boolean = false;
    public isAddEditShown: boolean = false;
    public isICPShown: boolean = false;
    public canGoToQuestionnaire = false;

    public zendeskKey: string = this.config.zendeskKey;

    // Invalid data for Technical Settings
    public showInvalidDataModal: boolean = false;
    public invalidDataMessage: String = "";
    public isFooterShown: boolean = false;

    public clientOnboardingStepIntroductionLookup: List<ClientOnboardingStepIntroduction> = new List(ClientOnboardingStepIntroduction);
    public lastActiveStepIntroduction: number = 0;
    public btnStepIntroductionText: string = textConstants.buttonText.DoneGoToNextSTep;
    public saveButtonDisabled: boolean = true;

    public isLetsStart = false;

    public nextStep = ""

    public async initialise() {
    }

    public async setOnboardingData() {

        if (this.customAuthService.globalProps.clientId === 0) {
            await this.setClientInfo();
        }

        this.isNotFinalStep(true)

        await this.setClientOnboardingStepIntroductions();
    }

    public isNotFinalStep(setNextStep = false) {
        let currentOnboardingStep = this.customAuthService.globalProps.currentStep
        let allStepsCount = this.customAuthService.globalProps.onboardingSteps.length
        let completedCount = this.customAuthService.globalProps.onboardingSteps.filter(f => f.isComplete).length

        if (allStepsCount > completedCount) {
            if (setNextStep) {
                let incompleteItems = this.customAuthService.globalProps.onboardingSteps.filter(f => f.stepNumber !== currentOnboardingStep && !f.isComplete)

                incompleteItems.sortBy("stepNumber")
                if (incompleteItems.length > 0) {
                    this.nextStep = incompleteItems[0].stepName
                }
            }

            return true
        }

        return false
    }

    public isFinalStep() {
        let currentOnboardingStep = this.customAuthService.globalProps.currentStep
        let allStepsCount = this.customAuthService.globalProps.onboardingSteps.length
        let completedCount = this.customAuthService.globalProps.onboardingSteps.filter(f => f.isComplete).length

        if (allStepsCount === completedCount) {
            let incompletes = this.customAuthService.globalProps.onboardingSteps.filter(f => f.stepNumber !== currentOnboardingStep && !f.isComplete)

            incompletes.sortBy("stepNumber")

            this.nextStep = incompletes[0].stepName

            return true
        }

        return false
    }

    public async updateClientOnboardingStepIntroduction(stepIntroductionId: number) {
        const command = new UpdateClientOnboardingStepIntroductionCommand();
        command.clientOnboardingStepIntroductionId = stepIntroductionId;

        await this.onboardingApiClient.updateClientOnboardingStepIntroduction(command);
        this.setLastActiveStepIntroduction();
        this.setStepIntroductionButtonText();
        this.setSaveButtonDisabled();
    }

    public setLastActiveStepIntroduction() {
        this.lastActiveStepIntroduction = this.clientOnboardingStepIntroductionLookup
            .filter((stepIntroduction) => stepIntroduction.onboardingStepIntroduction.onboardingStepId === this.customAuthService.globalProps.currentStep)
            .map(clientOnboardingStepIntroduction => clientOnboardingStepIntroduction.isComplete)
            .indexOf(false);
    }

    public setSaveButtonDisabled() {
        if (this.customAuthService.globalProps.currentStep !== 0) {
            this.saveButtonDisabled = this.clientOnboardingStepIntroductionLookup
                .filter(stepIntroduction => stepIntroduction.onboardingStepIntroduction.onboardingStepId === this.customAuthService.globalProps.currentStep && !stepIntroduction.isComplete)!.length > 0;
        }
        else {
            this.saveButtonDisabled = true;
        }
    }

    public setStepIntroductionButtonText() {
        this.btnStepIntroductionText = this.clientOnboardingStepIntroductionLookup
            .filter((stepIntroduction) => {
                return stepIntroduction.onboardingStepIntroduction.onboardingStepId === this.customAuthService.globalProps.currentStep;
            })
            .filter(stepIntroduction => !stepIntroduction.isComplete)
            .length === 1 ? textConstants.buttonText.Done : textConstants.buttonText.DoneGoToNextSTep;
    }

    private async setClientOnboardingStepIntroductions() {
        const response = await this.onboardingApiClient.getClientOnboardingStepIntroduction(this.customAuthService.globalProps.clientId);
        this.clientOnboardingStepIntroductionLookup.set(response.data.data);
    }

    private async setClientInfo() {

        const response = await this.onboardingApiClient.getUserLoginInfo(this.authenticationService.user!.userName);

        this.customAuthService.globalProps.clientId = response.data.data.clientId
        this.customAuthService.globalProps.isFirstOnboardingLogin = response.data.data.isFirstOnboardingLogin
        this.customAuthService.globalProps.hasSeenTutorial = response.data.data.hasSeenTutorial
        this.customAuthService.globalProps.clientHasSeenTutorial = response.data.data.clientHasSeenTutorial
        this.customAuthService.globalProps.hasSeenTechnicalIntegration = response.data.data.hasSeenTechnicalIntegration
        let steps = response.data.data.clientOnboardingSteps as PartialPlainObject<OnboardingStepLookup>[]

        if (steps !== null) {
            this.customAuthService.globalProps.onboardingSteps.set(steps)
        }

        if (!response.data.data.isClientUser) {
            window.location.href = '/';
        }

        if (response.data.data.isClientUser && response.data.data.isOnboardingComplete) {
            this.customAuthService.globalProps.showBurgerIcon = true
            this.customAuthService.globalProps.showBreadCrumb = true

            // Redirect the user because they have completed the onboarding
            window.location.href = '/';
        }
        else if (response.data.data.isClientUser && !response.data.data.isOnboardingComplete) {
            this.customAuthService.globalProps.isOnboarding = true
        }
    }

    public async getCustomerProfilesCount() {
        const response = await this.customerProfilesApiClient.getCustomerProfilesCount(this.customAuthService.globalProps.clientId);
        this.customerProfilesCount = response.data;
    }

    public getCurrentStep(): number {
        let foundStep = this.customAuthService.globalProps.onboardingSteps.find(os => os.isComplete === false);
        return foundStep?.stepNumber || 0;
    }

    public navigateToNextStep() {
        const { globalProps } = this.customAuthService
        this.isFooterShown = false;

        if (globalProps.currentStep === OnboardingSteps.TechnicalIntegration) {
            globalProps.onboardingSteps.find(f => f.stepNumber === OnboardingSteps.TechnicalIntegration)!.isComplete = true

            globalProps.currentStep = globalProps.onboardingSteps.find(f => f.stepName === this.nextStep)!.stepNumber
        }
        else if (globalProps.currentStep === OnboardingSteps.BlackList && globalProps.onboardingSteps.find(f => f.stepNumber === OnboardingSteps.BookAMeeting)?.isComplete) {
            globalProps.currentStep = OnboardingSteps.CampaignMessages;
        }
        else {
            globalProps.currentStep += 1;
        }

        this.customAuthService.onboardingFunctions.navigateToStep();
    }

    public closeOnboardingIntroduction() {
        this.isLetsStart = false;
        this.isIntroductionComplete = true;
        this.canGoToQuestionnaire = false;
        this.saveButtonDisabled = true;
        this.customAuthService.onboardingFunctions.setHelpCenterSectionCode();
    }

    public async completeClientOnboarding() {
        await this.onboardingApiClient.completeClientOnboarding(this.customAuthService.globalProps.clientId)
    }
}