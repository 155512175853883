import { Attributes, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';

@NeoModel
export default class ReminderSaveCommand extends ModelBase {

  public id: string = "";

  @Rules.StringLength(50)
  public title: string = "";

  public description: string = "";

  @Attributes.Date()
  public time: Date = new Date();

  public prospect_id: number = 0;

  public is_active: boolean = true;
}