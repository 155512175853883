import { NeoModel, ValueObject } from '@singularsystems/neo-core';
import { IsGridCriteria } from '../../../Components/ReactDataGrid/DataGridHelper';

@NeoModel
export default class BlacklistedDomainCriteria extends ValueObject implements IsGridCriteria {
  public clientId: number = 0

  public searchString: string = ""

  public domain: string = "";

  public dateAdded: string = "";

  public addedBy: string = "";

  public isCoumnFilter: boolean = false;

  public notFilter: string[] = [];

  public ResetLocalFilters() {
    this.isCoumnFilter = false;
    this.notFilter = []
  }
}