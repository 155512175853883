import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import SeniorityLevel from '../Models/Maintenance/SeniorityLevel';
import { AppService, Types } from '../Services/AppService';

export interface ISeniorityLevelsApiClient extends Data.IUpdateableApiClient<SeniorityLevel, number> {

}

@injectable()
export default class SeniorityLevelsApiClient extends Data.UpdateableApiClient<SeniorityLevel, number> implements ISeniorityLevelsApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/seniorityLevels`);
    }
}