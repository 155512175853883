import { injectable } from 'inversify';
import { Data, Model, Utils, List } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import Axios, { AxiosPromise } from 'axios';
import CommandResult from '../Models/InvitedUsers/Commands/CommandResult';
import GreylistProspectCriteria from '../Models/GreyListedProspect/Query/GreylistProspectCriteria';
import DeleteGreylistProspectCommand from '../Models/GreyListedProspect/Commands/DeleteGreylistProspectCommand';
import GreyListedProspectModel from '../Models/GreyListedProspect/GreyListedProspectModel';
import { ResponseResolver } from '../common/utils';

export interface IGreyListedProspectApiClient {
    getGreylistedProspect(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<GreylistProspectCriteria>>): AxiosPromise<any>,
    getTemplate(): AxiosPromise<any>,
    getEmailRegex(): AxiosPromise<string>,
    uploadDocument(filelist: File[] | FileList, clientId: number): AxiosPromise<any>,
    deleteGreylistedProspect(greylistedProspect: DeleteGreylistProspectCommand): AxiosPromise<Model.PlainObject<CommandResult>>;
    downloadGreylistedProspects(clientId: number): AxiosPromise<any>;
    saveGreylistedProspects(greylistedProspect: GreyListedProspectModel[], clientId: number): AxiosPromise<any>;
    bulkDelete(greylistedProspects: DeleteGreylistProspectCommand): AxiosPromise<{ success: boolean; error: string; id: number }>;
}

@injectable()
export default class GreyListedProspectApiClient implements IGreyListedProspectApiClient {

    constructor(public cnfig = AppService.get(Types.Config)) {
    }

    public getGreylistedProspect(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<GreylistProspectCriteria>>): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/GreylistedProspect/GetGreylistedProspect?${Utils.getQueryString(request)}`);
    }

    public deleteGreylistedProspect(greylistedProspect: DeleteGreylistProspectCommand) : AxiosPromise<{ success: boolean; error: string; id: number; }> {
        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.cnfig.apiPath}/GreylistedProspect/DeleteGreylistedProspect`, greylistedProspect.toJSObject()));
    }

    public getTemplate(): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get(`${apiPath}/GreylistedProspect/DownloadTemplate`));
    }

    public uploadDocument(filelist: File[] | FileList, clientId: number) {
        const apiPath = AppService.get(Types.Config).apiPath;
        
        const bodyFormData = new FormData();
        bodyFormData.set('files', filelist[0]);
        bodyFormData.set('clientId', clientId.toString());

        return Axios.post(`${apiPath}/GreylistedProspect/UploadDocument`, bodyFormData);
    }

    public downloadGreylistedProspects(clientId: number): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get(`${apiPath}/GreylistedProspect/DownloadGreylistedProspects?clientId=${clientId}`));
    }

    public saveGreylistedProspects(greylistedProspect: List<GreyListedProspectModel>, clientId: number){
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.post(`${apiPath}/GreylistedProspect/QuickAddGreyListedPospects?clientId=${clientId}`,greylistedProspect.toJSArray());
    }

    public getEmailRegex(): AxiosPromise<string> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<string>(Axios.get(`${apiPath}/GreylistedProspect/GetEmailRegex`));
    }

    public bulkDelete(greylistedProspects: DeleteGreylistProspectCommand): AxiosPromise<{ success: boolean; error: string; id: number }> {
        return (Axios.post(`${this.cnfig.apiPath}/GreylistedProspect/BulkDelete`, greylistedProspects.toJSObject()));
    }
}