import { ModelBase, NeoModel } from "@singularsystems/neo-core";

@NeoModel
export default class CustomerProfileQuestion extends ModelBase{

    public customerProfileQuestionId : number = 0

    public questionNumber : number = 0

    public questionText : string = ""

    public questionType : number = 0

    public maintenanceQuestionNumber : number = 0

    public helperCopy : string = ""

    public placeholderCopy : string = ""

    public isAIMessageGenerationQuestion: boolean = false
}