import {ModelBase, NeoModel} from '@singularsystems/neo-core';


@NeoModel
export default class TargetMarketManagementSubFunctionsLookup extends ModelBase {
    // Properties
    public managementFunctionId: number = 0;
    public managementSubFunctionId: number= 0
    public functionName: string = "";
    public isSelected: boolean = false;
    public isPriority: boolean = false;
}