import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import { AppService, Types } from '../../Services/AppService';

@NeoModel
export default class EmailSetupVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient),
        private customAuthService = AppService.get(Types.Security.CustomAuthenticationService)) {
        super(taskRunner);
    }

    public clientId: number = 0
    public showTechnicalDetails: boolean = false
    public showEmailSent: boolean = false

    public mailtoLink: string = "";
    public mailToEmailBody: string = "";
    public mailToDisplay: string = "";

    public emailSetupVideo: string = "";

    public async initialise() {
        let complete = await this.getMailToText()

        // Update as soon as user sees page
        if (!this.customAuthService.globalProps.hasSeenTechnicalIntegration) {
            await this.updateTechnicalIntegration()
        }
    }

    public async updateTechnicalIntegration() {
        this.taskRunner.run(async () => {
            await this.onboardingApiClient.updateTechnicalIntegrationIndicator(this.clientId);
        })
    }

    public async getMailToText() {
        if (this.mailToDisplay.length === 0) {
            const response = await this.taskRunner.waitFor(this.onboardingApiClient.getMailToText());
            if (response.data) {
                this.mailToDisplay = response.data;
                this.mailToEmailBody = this.mailToDisplay.split(textConstants.generalText.NewLine).join(textConstants.generalText.MailToNewLine);

                this.mailtoLink = "mailto:?subject=Technical Integration Information Requirements&body=" + this.mailToEmailBody
            }
        }
    }
}