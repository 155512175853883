import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';
import { IBulkActionGrid, selectedItem } from '../ComxDataGridManager/BulkActionPageManager';

@NeoModel
export default class MasterAccountLookup extends ModelBase implements IBulkActionGrid {

    @selectedItem(["masterAccountId"])
    @Display("Select")
    public IsSelected : boolean = false

    @Rules.Required()
    public masterAccountId: number = 0;

    public fixedName: string = "";

    public originalName: string = "";

    public description: string = "";

    public logoUrl: string = "";

    public location: string = "";

    @Attributes.Display("Company Size")
    public employeeSize: string = "";

    @Attributes.Display("Industry")
    public industrySubIndustry: string = "";

    @Attributes.Display("Industries Version")
    public IndustriesVersion: string = "";

    @Attributes.Display("Domain")
    public domain: string = "";

    @Attributes.Display("Status")
    public status: string = "";

    @Attributes.Display("Invalid Reason")
    public invalidReason: string = "";

    @Rules.Required()
    @Attributes.Date()
    @Attributes.Display("Date Added")
    public dateAdded: Date | null = null;

    @Attributes.Display("Re-Opened Count")
    public reOpenedCount: number = 0;

    @Rules.Required()
    public isDeleted: boolean = false

    @Attributes.Display("Region")
    public region: string | null = ""
    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.fixedName) {
            return "New master account";
        } else {
            return this.fixedName;
        }
    }
}