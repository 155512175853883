import { Routing as IRouting } from '@singularsystems/neo-core';
import { Routing } from '@singularsystems/neo-react';
import LoggedOut from './Views/Security/LoggedOut';
import OidcLoginRedirect from './Views/Security/OidcLoginRedirect';
import MaintenanceView from './Views/Maintenance/MaintenanceView';
import InvitedUsersView from './Views/InvitedUsers/InvitedUsersView';
import MasterAccountsView from './Views/MasterAccounts/MasterAccountsView';
import * as Roles from './Models/Security/Roles';
import { IMenuRoute } from '@singularsystems/neo-core/dist/Routing';
import ClientView from './Views/Client/ClientView';
import {
    maintenanceRoutes, updateMasterAccountRoute,
    baseMaintenanceRouteInfo
} from './RouteConfiguration';
import UserGroupsView from './Authorisation/Views/UserGroupsView';
import HomeView from './Views/Home/HomeView';
import DashboardView from './Views/Home/DashboardView';
import { textConstants as tc } from './common/textConstants';
import OnboardingView from './Views/Onboarding/OnboardingView';
import SettingsView from './Views/Home/SettingsView';
import KnowledgeCenterView from './Views/KnowlegdeCenter/KnowledgeCenterView';
import CustomerProfilesCampaignsView from './Views/IdealCustomerProfiles/CustomerProfilesExternalView';
import TargetMarketCampaignsView from './Views/TargetMarket/TargetMarketExternalView';
import BlacklistCampaignsView from './Views/Client/BlacklistExternalView';
import BatchReviewCampaignsView from './Views/Client/BatchReviewExternalView';
import ClientCampaignMessageCampaignsView from './Views/CampaignMessages/CCMExternalView';
import ActionListView from './Views/ActionList/ActionListView';
import NotFound from 'Views/NotFound/NotFound';
import ReportingView from 'Views/Reporting/ReportingView';
import { AppConfig } from 'AppConfig';

export interface IAppRoute extends IRouting.IMenuRoute {
    icon?: string;
    hideChildren?: boolean;
}

const inheritParentRole = (role: string, childElement: any) => {
    if (Array.isArray(childElement)) {
        childElement.forEach((element) => {
            element!.role = role;
        })
    }
    else {
        childElement!.role = role;
    }
    return childElement;
}

const routes = new Routing.RouteProvider(
    [
        {
            name: tc.Routes.Dashboard,
            path: '/Dashboard',
            component: DashboardView,
            icon: "home",
            allowAnonymous: false,
            exact: false,
            role: Roles.Dashboard.Access
        },
        {
            name: tc.Routes.ActionList,
            path: '/ActionList',
            component: ActionListView,
            icon: "check-square",
            allowAnonymous: false,
            exact: false,
            role: Roles.ActionList.Access
        },
        {
            name: tc.Routes.IdealCustomerProfileSpaced,
            path: '/IdealCustomerProfile',
            component: CustomerProfilesCampaignsView,
            icon: "bullseye",
            allowAnonymous: false,
            exact: false,
            role: Roles.Dashboard.Access,

        },
        {
            name: tc.Routes.TargetMarketsSpaced,
            path: '/TargetMarket',
            icon: "flag",
            component: TargetMarketCampaignsView,
            role: Roles.Dashboard.Access,
            allowAnonymous: false,
            exact: true,
        },
        {
            name: tc.Routes.Blacklist,
            path: '/Blacklist',
            icon: "ban",
            component: BlacklistCampaignsView,
            role: Roles.Dashboard.Access,
            allowAnonymous: false,
        },
        {
            name: tc.Routes.BatchReviewSpaced,
            path: '/BatchReview',
            icon: "clipboard-check",
            component: BatchReviewCampaignsView,
            role: Roles.BatchReview.Manage,
            allowAnonymous: false,
        },
        {
            name: tc.Routes.CampaignMessagesSpaced,
            path: '/CampaignMessages',
            icon: "envelope",
            component: ClientCampaignMessageCampaignsView,
            allowAnonymous: false,
            role: Roles.CampaignMessages.Manage
        },
        {
            name: tc.Routes.Clients,
            path: '/Clients',
            component: ClientView,
            icon: "clients",
            allowAnonymous: false,
            exact: false,
            role: Roles.Clients.Manage,
        },
        {
            ...baseMaintenanceRouteInfo,
            component: MaintenanceView,
            icon: "cog",
            allowAnonymous: false,
            exact: true,
            showInBreadCrumb: true,
            children: [

                ...inheritParentRole(Roles.Maintenance.Manage, maintenanceRoutes)

            ],
            role: Roles.Maintenance.Manage
        },
        {
            name: tc.Routes.MasterAccountsSpaced,
            path: '/MasterAccounts',
            component: MasterAccountsView,
            allowAnonymous: false,
            exact: true,
            icon: "globe",
            children: [
                {
                    ...inheritParentRole(Roles.MasterData.Manage, updateMasterAccountRoute)
                }
            ],
            role: Roles.MasterData.Manage
        },
        {
            name: tc.Routes.Reporting,
            path: '/Reporting',
            component: ReportingView,
            allowAnonymous: false,
            exact: true,
            icon: "bar-chart",
            role: Roles.Reporting.View
        },
        {
            name: tc.Routes.Security,
            path: '/Security',
            component: UserGroupsView,
            icon: "lock",
            allowAnonymous: false,
            exact: true,
            role: Roles.Security.Access
        },
        {
            name: tc.Routes.Users,
            path: '/Users',
            component: InvitedUsersView,
            icon: "user",
            allowAnonymous: false,
            exact: true,
            role: Roles.Users.Manage
        },
        {
            name: tc.Routes.KnowledgeCenter,
            path: 'https://hub.comx.io/',
            component: KnowledgeCenterView,
            icon: "life-ring",
            allowAnonymous: false,
            exact: false,
            role: Roles.Dashboard.Access
        },
        {
            name: tc.Routes.Settings,
            path: '/Settings',
            component: SettingsView,
            icon: "cog",
            allowAnonymous: false,
            exact: false,
            role: Roles.Dashboard.Access && (
                Roles.Settings.ActionPlanChecklist ||
                Roles.Settings.CRMIntegration ||
                Roles.Settings.TermsOfService ||
                Roles.TI_OutboundEmails.Delete ||
                Roles.TI_OutboundEmails.Edit ||
                Roles.TI_OutboundEmails.View ||
                Roles.TI_AdvancedEmailSettings.Edit ||
                Roles.TI_AdvancedEmailSettings.View ||
                Roles.TI_InternalTest.Edit ||
                Roles.TI_InternalTest.View)
        },
    ] as IMenuRoute[],
    // Routes which don't appear in the menu.
    [
        {
            path: AppConfig.loginRedirectRoute, component: OidcLoginRedirect, allowAnonymous: true
        },
        {
            path: "/loggedout", component: LoggedOut, allowAnonymous: true
        },
        {
            path: "/", component: HomeView, allowAnonymous: false
        },
        {
            path: "/Onboarding", component: OnboardingView, allowAnonymous: false
        },
    ], NotFound);

export default routes;