import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import DashboardTooltipModel from '../Models/Maintenance/DashboardTooltipModel';
import { AppService, Types } from '../Services/AppService';

export interface IDashboardTooltipApiClient extends Data.UpdateableApiClient<DashboardTooltipModel, number>{

}

@injectable()
export default class DashboardTooltipApiClient extends Data.UpdateableApiClient<DashboardTooltipModel, number> implements DashboardTooltipApiClient{

    constructor(config = AppService.get(Types.Config)){
        super(`${config.apiPath}/DashboardTooltips`);
    }
}