import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import TargetMarketType from '../Models/Maintenance/TargetMarketType';
import { AppService, Types } from '../Services/AppService';

export interface ITargetMarketTypesApiClient extends Data.IUpdateableApiClient<TargetMarketType, number> {

}

@injectable()
export default class TargetMarketTypesApiClient extends Data.UpdateableApiClient<TargetMarketType, number> implements ITargetMarketTypesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/targetMarketTypes`);
    }
}