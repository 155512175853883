import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import ActionListStatus from 'Models/ActionList/ActionListStatus';

export interface IActionListStatusesApiClient extends Data.IUpdateableApiClient<ActionListStatus, number> {

}

@injectable()
export default class ActionListStatusesApiClient extends Data.UpdateableApiClient<ActionListStatus, number> implements IActionListStatusesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/actionListStatuses`);
    }
}