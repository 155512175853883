import { NeoModel, List } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import ManagementRole from '../../Models/Maintenance/ManagementRole';
import { textConstants } from '../../common/textConstants';
import Language from '../../Models/Maintenance/Language';

@NeoModel
export default class ManagementRoleVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private managementRolesApiClient = AppService.get(Types.ApiClients.ManagementRolesApiClient),
        private appDataCache = AppService.get(Types.Services.AppDataCache)) {

        super(taskRunner);
    }

    // Properties
    public managementRoleList = new List(ManagementRole);

    public languages = new List(Language)

    public async initialise() {
        const languages = (await this.taskRunner.waitFor(this.appDataCache.languages.getDataAsync())).sortBy("languageName")
        this.languages.set(languages);

        const managementRolesResponse = await this.taskRunner.waitFor(this.managementRolesApiClient.getManagementRolesAsync());
        this.managementRoleList.set(managementRolesResponse.data);
    }

    public saveManagementRoleList() {

        this.managementRoleList.forEach(manFunc => {
            manFunc.managementSubRoles.forEach(subFun => {
                subFun.managementRoleId = manFunc.managementRoleId
                subFun.managementSubRoleLocalisations.forEach(localisation => {
                    localisation.managementSubRoleId = subFun.managementSubRoleId
                });
            });
        });

        this.taskRunner.run(async () => {
            const response = await this.managementRolesApiClient.insertUpdateManagementRoles(this.managementRoleList);
            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.ManagementRoleSaved);            
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}