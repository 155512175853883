import { Managers, Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../Services/AppService";
import { textConstants } from "../../common/textConstants";
import CRMIntegrationLookup from "../../Models/Client/Query/CRMIntegrationLookup";
import { PartialPlainObject } from "@singularsystems/neo-core/dist/Model";
import { NeoModel } from "@singularsystems/neo-core";
import AdvancedEmailSettingsLookup from "../../Models/Client/Query/AdvancedEmailSettingsLookup";
import AdvancedEmailSettingsSaveCommand from "../../Models/Client/Commands/AdvancedEmailSettingsSaveCommand";
import OutboundEmailTableLookup from "../../Models/Client/Query/OutboundEmailTableLookup";
import OutboundEmailCriteria from "../../Models/Client/Query/OutboundEmailCriteria";
import AdvancedEmailSettingsVM from "../../Components/TechnicalIntegrationSettings/AdvancedEmailSettingsVM";
import * as Roles from '../../Models/Security/Roles';
import InfoVideoVM from "../../Components/InfoVideoVM";
import TermsOfService from "./TermsOfServiceView";
import TermsOfServiceLookup from "Models/Client/Query/TermsOfServiceLookup";
import ActionPlanChecklist from "./ActionPlanChecklistView";
import ActionPlanChecklistLookup from "Models/Client/Query/ActionPlanChecklistLookup";

@NeoModel
export default class TechnicalSettingsVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient),
    private authService = AppService.get(Types.Neo.Security.AuthenticationService),
    private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
    protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService),
    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService)
  ) {
    super(taskRunner);
  }

  public crmIntegrationLookup: CRMIntegrationLookup = new CRMIntegrationLookup()
  public clientId = 0;
  public tabManager = new Managers.TabManager(this.setTab());
  public termsOfServiceLookup: TermsOfServiceLookup = new TermsOfServiceLookup()
  public actionPlanChecklistLookup: ActionPlanChecklistLookup = new ActionPlanChecklistLookup();


  public advancedEmailSettingsLookup = new AdvancedEmailSettingsLookup();
  public advancedEmailSettingsSaveCommand = new AdvancedEmailSettingsSaveCommand();
  public getOutboundEmailsTableData = new OutboundEmailTableLookup();
  public outboundEmailSearchCriteria = new OutboundEmailCriteria();
  public isArchive: boolean = false;
  public showDeleteModal: boolean = false;
  public selectedEmail: string = "";
  public outboundEmailId: number = 0;
  public showUndeleteModal: boolean = false;

  public infoVideoVM = new InfoVideoVM;

  public advancedEmailSettingsVM = new AdvancedEmailSettingsVM();

  public tabs = {
    CRM: "CRM Settings",
    TechnicalIntegration: "Technical Integration Settings",
    ActionPlan: "ActionPlanChecklist",
    TermsOfService: "Terms Of Service"
  }

  public setTab() {
    if (this.authorisationService.hasRole(Roles.TI_AdvancedEmailSettings.View) ||
      this.authorisationService.hasRole(Roles.TI_AdvancedEmailSettings.Edit) ||
      this.authorisationService.hasRole(Roles.TI_InternalTest.View) ||
      this.authorisationService.hasRole(Roles.TI_InternalTest.Edit) ||
      this.authorisationService.hasRole(Roles.TI_OutboundEmails.View) ||
      this.authorisationService.hasRole(Roles.TI_OutboundEmails.Edit) ||
      this.authorisationService.hasRole(Roles.TI_OutboundEmails.Delete)
    ) {
      return textConstants.TechnicalIntegrationSettings
    } else if (this.authorisationService.hasRole(Roles.Settings.CRMIntegration)) {
      return textConstants.CRMIntegrationSettings
    } else if (this.authorisationService.hasRole(Roles.Settings.ActionPlanChecklist)) {
      return textConstants.ActionPlanChecklist
    } else if (this.authorisationService.hasRole(Roles.Settings.TermsOfService)) {
      return textConstants.TermsOfService
    }
    return ""
  }

  public async GetCRMIntegrationData() {

    let response = await this.taskRunner.waitFor(this.clientsApiClient.getCRMIntegrationData(this.clientId));

    if (response.data) {
      var data = response.data as PartialPlainObject<CRMIntegrationLookup>

      this.crmIntegrationLookup.set(data);
    }
    else {
      this.notifications.addDanger("CRM Retrieval Failure", textConstants.generalText.CRMIntegrationFail);
    }
  }

  public async setup(clientID: number) {

    this.clientId = clientID;

    const response = await this.clientsApiClient.getActionPlanChecklist(this.clientId);
    if (response.data) {
      this.actionPlanChecklistLookup = response.data
    }
  }

  public async toggleTab(selectedTab: any, clientId: number) {
    this.clientId = clientId
    if (selectedTab === this.tabs.CRM) {
      let response = await this.taskRunner.run(() => this.clientsApiClient.getCRMIntegrationData(this.clientId));

      if (response.data) {
        var data = response.data as PartialPlainObject<CRMIntegrationLookup>

        this.crmIntegrationLookup.set(data);

        this.infoVideoVM.header = textConstants.titleText.ZapierIntegration;
        this.infoVideoVM.url = this.crmIntegrationLookup.videoUrl;
        this.infoVideoVM.content = this.crmIntegrationLookup.instructionsVideoHtml;
      }
      else {
        this.notifications.addDanger("CRM Retrieval Failure", textConstants.generalText.CRMIntegrationFail);
      }
    }

    if (selectedTab === this.tabs.TechnicalIntegration) {
      this.advancedEmailSettingsVM = new AdvancedEmailSettingsVM();
      await this.advancedEmailSettingsVM.setAdvancedEmailSettings(clientId);
    }

    if (selectedTab === this.tabs.ActionPlan) {
      const response = await this.clientsApiClient.getActionPlanChecklist(this.clientId);
      if (response.data) {
        this.actionPlanChecklistLookup = response.data
      }
    }

    if (selectedTab === this.tabs.TermsOfService) {
      const response = await this.clientsApiClient.getTermsOfServiceData(this.clientId);
      if (response.data) {
        this.termsOfServiceLookup = response.data
      }
    }
  }

  public openLink() {
    if (this.actionPlanChecklistLookup.actionPlanChecklistURL) {
      window.open(this.actionPlanChecklistLookup.actionPlanChecklistURL);
    }
  }
}