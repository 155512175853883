import { NeoModel, List } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import ManagementFunction from '../../Models/Maintenance/ManagementFunction';
import { textConstants } from '../../common/textConstants';
import Language from '../../Models/Maintenance/Language';

@NeoModel
export default class ManagementFunctionVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private managementFunctionsApiClient = AppService.get(Types.ApiClients.ManagementFunctionsApiClient),
        private appDataCache = AppService.get(Types.Services.AppDataCache)) {

        super(taskRunner);
    }

    // Properties
    public managementFunctionList = new List(ManagementFunction);    

    public languages = new List(Language)

    public async initialise() {
        const languages = (await this.taskRunner.waitFor(this.appDataCache.languages.getDataAsync())).sortBy("languageName")
        this.languages.set(languages);

        this.getFunctions()
    }

    public saveManagementFunctionList() {

        this.managementFunctionList.forEach(manFunc => {
            manFunc.managementSubFunctions.forEach(subFun => {
                subFun.managementFunctionId = manFunc.managementFunctionId
                subFun.managementSubFunctionLocalisations.forEach(localisation => {
                    localisation.managementSubFunctionId = subFun.managementSubFunctionId
                });
            });
        });
    
        this.taskRunner.run(async () => {
            const response = await this.managementFunctionsApiClient.insertUpdateManagementFunctions(this.managementFunctionList);
            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.ManagementFunctionSaved);
            this.getFunctions()
        });
    }

    public async getFunctions(){
        const managementFunctionsResponse = await this.taskRunner.waitFor(this.managementFunctionsApiClient.getManagementFunctionsAsync());
        this.managementFunctionList.set(managementFunctionsResponse.data);
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}