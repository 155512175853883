import React from 'react';

const Edit = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6631_212420)">
      <path
        d="M7.33594 2.66406H2.66927C2.31565 2.66406 1.97651 2.80454 1.72646 3.05459C1.47641 3.30464 1.33594 3.64377 1.33594 3.9974V13.3307C1.33594 13.6844 1.47641 14.0235 1.72646 14.2735C1.97651 14.5236 2.31565 14.6641 2.66927 14.6641H12.0026C12.3562 14.6641 12.6954 14.5236 12.9454 14.2735C13.1955 14.0235 13.3359 13.6844 13.3359 13.3307V8.66406"
        stroke="#818888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3359 1.66421C12.6012 1.399 12.9609 1.25 13.3359 1.25C13.711 1.25 14.0707 1.399 14.3359 1.66421C14.6012 1.92943 14.7502 2.28914 14.7502 2.66421C14.7502 3.03929 14.6012 3.399 14.3359 3.66421L8.0026 9.99755L5.33594 10.6642L6.0026 7.99755L12.3359 1.66421Z"
        stroke="#818888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6631_212420">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Edit;
