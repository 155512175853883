import { ModelBase, NeoModel, Rules, List } from '@singularsystems/neo-core';
import ClientTMStepsLookup from '../ClientTMStepsLookup'
import ClientTMChanges from '../ClientTMChanges';

@NeoModel
export default class TargetMarketCommandResult extends ModelBase {
   
  // Properties
  public targetMarketId: number = 0;

  public tmSteps: List<ClientTMStepsLookup> = new List(ClientTMStepsLookup);

  public tmChanges: ClientTMChanges = new ClientTMChanges();

  public isComplete: boolean = false;
}