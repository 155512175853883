import 'rc-slider/assets/index.css';

import React from 'react';
import { observer } from 'mobx-react';

export interface IInforLabelError {
    fieldName: string,
    errorMessage: string,
}

interface IInfoLabelProps {
    isVisible: boolean
    labelType: "primary"|"secondary"|"tertiary"|"info"|"success"|"danger"|"warning"|"light"|"dark"|"default"| ""
    labelText: string
    font: string
}

@observer
export default class InfoLabel extends React.Component<IInfoLabelProps> {

    public determineFont(){
        if (this.props.font === null && this.props.font === ""){
            return `textFontSize mb-2 p-0`;
        }
        else{
            return `${this.props.font} mb-2 p-0`;
        }
    }

    public render() {
        return (
            <React.Fragment>
                <div className={`infoLabel ${this.props.labelType} ${this.props.isVisible ? "" : "displayNone"}`}>   
                <i className="fa fa-info-circle mr-2"/><span className={this.determineFont()}>{this.props.labelText}</span>        
                </div>
            </React.Fragment>
        );
    }
}
