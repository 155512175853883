import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class BackgroundJobTypeConfig extends ModelBase {

    public backgroundJobTypeConfigId: number = 0;

    @Attributes.Display("Background Job Name")
    public backgroundJobTypeName: string = "";

    public maxProcessingDurationMinutes: number = 0

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.maxProcessingDurationMinutes < 30, textConstants.messageText.validationMessage.value);
        rules.failWhen(c => c.backgroundJobTypeName === "", textConstants.messageText.validationMessage.description);
    }

    public toString(): string {
        if (this.isNew || !this.backgroundJobTypeName) {
            return "New BackroundJobName";
        } else {
            return this.backgroundJobTypeName;
        }
    }
}