import { NeoModel, List } from '@singularsystems/neo-core';
import { AppService, Types } from '../../Services/AppService';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';
import ComXConfiguration from '../../Models/Maintenance/ComXConfiguration';

@NeoModel
export default class ComXConfigurationVM extends Views.ViewModelBase {
    
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private comXConfigurationsApiClient =  AppService.get(Types.ApiClients.ComXConfigurationsApiClient),
        private appDataCache = AppService.get(Types.Services.AppDataCache)) {

        super(taskRunner);
    }

    // Properties
    public comXConfigurationList = new List(ComXConfiguration)

    public async initialise() {        
        const comXConfigurationsResponse = await this.taskRunner.waitFor(this.comXConfigurationsApiClient.get());
        this.comXConfigurationList.set(comXConfigurationsResponse.data);
    }

    public saveComXConfigurationList(){
        this.taskRunner.run(async () => {
            const response = await this.comXConfigurationsApiClient.saveList(this.comXConfigurationList.toJSArray());
            this.comXConfigurationList.update(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved,textConstants.messageText.saveMessage.ConfigSaved);

            // Refresh the comXConfiguration common data list
            this.appDataCache.comXConfigurations.expire(true);
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}