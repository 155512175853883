import React from "react";
import { observer } from "mobx-react";
import { List } from "@singularsystems/neo-core";
import ListSelectorModalLookup from "Components/Modals/ListSelectorModalLookup";
import { Neo } from "@singularsystems/neo-react";
import * as Icon from "react-feather";
import {
    deselectItem,
    togglePriority,
    validatePriority,
} from "./ListSelectorHelper";
import { textConstants } from "common/textConstants";
import { IPropertyInstance } from "@singularsystems/neo-core/dist/Model";

interface IListSelectorSelectedItemsProps {
    list: List<ListSelectorModalLookup>;
    title: string;
    itemType: string;
    btnText: string;
    openModal: Function;
    isHeadOfSeniority?: IPropertyInstance<boolean>;
    isManagerOfSeniority?: IPropertyInstance<boolean>;
    onChange?: Function;
    isNotEditable?: boolean;
    isOverview?: boolean;
    priorityChanged?: (prioritySelected: boolean) => void;
    estimatedIndustries?: number;
}

@observer
export default class ListSelectorSelectedItems extends React.Component<IListSelectorSelectedItemsProps> {
    private showSeniorityLevel = false;
    private isOverview = false;
    private showAddButton = false;

    constructor(props: IListSelectorSelectedItemsProps) {
        super(props);

        this.setRenderParams();
    }

    private setRenderParams() {
        if (
            this.props.title ===
            textConstants.Onboarding.ListSelectorTitleRolesAndFunctions ||
            (this.props.title === textConstants.Onboarding.SelectedManagementRoles &&
                (this.props.isHeadOfSeniority!.value ||
                    this.props.isManagerOfSeniority!.value))
        ) {
            this.showSeniorityLevel = true;
        }

        if (
            this.props.title === textConstants.Onboarding.SelectedManagementRoles ||
            this.props.title === textConstants.Onboarding.SelectedIndustries ||
            this.props.title === textConstants.Onboarding.SelectedRoles
        ) {
            this.isOverview = true;
        }

        if (
            this.props.title ===
            textConstants.Onboarding.ListSelectorTitleIndustries ||
            this.props.title === textConstants.Onboarding.ListSelectorTitleRoles
        ) {
            this.showAddButton = true;
        }
    }

    private setSelectedList() {
        let listItems = (
            <div className="mt-3 selectedListContainer mr-0">
                {this.props.list
                    .sortBy((i) => i.itemName)
                    .map((item) => (
                        <React.Fragment key={item.itemId}>
                            <div className="mb-4">
                                <Neo.Card className="cardBackgroundGrey p-3">
                                    {/* List Header */}
                                    <div
                                        className="row p-0 m-0 clickableCursor"
                                        onClick={() =>
                                        (item.isSelectedExpanded =
                                            !item.isSelectedExpanded)
                                        }
                                    >
                                        <div className="col-6 p-0">
                                            {/* Selected Item Name  */}
                                            <div className="itemName">
                                                <b>{item.itemName}</b>
                                            </div>
                                            <div className="mt-2 font12">
                                                {
                                                    item.subList.filter(
                                                        (subItem) =>
                                                            subItem.isSelected
                                                    ).length
                                                }{" "}
                                                {this.props.itemType +
                                                    " SELECTED"}
                                            </div>
                                        </div>
                                        <div className="col-6 pr-0">
                                            {/* OnClick event is occuring in the div rather than button */}
                                            {item.isSelectedExpanded && (
                                                <Icon.ChevronUp className="pull-right mr-2 buttonContainer" />
                                            )}
                                            {!item.isSelectedExpanded && (
                                                <Icon.ChevronDown className="pull-right mr-2 buttonContainer" />
                                            )}

                                            {!this.props.isNotEditable && (
                                                <Icon.Trash2
                                                    className="pull-right mr-3 buttonContainer trash"
                                                    onClick={() => {
                                                        deselectItem(
                                                            this.props.list,
                                                            item,
                                                            null
                                                        );
                                                        this.props.list.remove(
                                                            item
                                                        );
                                                        this.props.onChange &&
                                                            this.props.onChange();
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {item.isSelectedExpanded && (
                                        <div className="row p-0 mt-4 m-0">
                                            <div className="col-6 p-0 selectedSubHeading">
                                                <span>
                                                    {textConstants.Onboarding.Selected.toLocaleUpperCase()}{" "}
                                                    {this.props.itemType}
                                                </span>
                                            </div>

                                            {/* Priority Switch Section */}
                                            {item.isPriority !== null && (
                                                <div className="col-6 p-0">
                                                    <div className="row p-0 m-0 pull-right">
                                                        {!this.isOverview &&
                                                            <span>
                                                                <Icon.AlertCircle className="priorityAllSvg" />
                                                            </span>
                                                        }
                                                        <span className="mr-2 font12 priority">
                                                            Priority all
                                                        </span>
                                                        <Neo.FormGroup
                                                            bind={item.meta.isPriority}
                                                            suppressLabel={true}
                                                            disabled={this.props.isNotEditable}
                                                            input={{ type: "switch" }}
                                                            onChange={() => {
                                                                togglePriority(item, null);
                                                                let priorityChanged = validatePriority(this.props.list);
                                                                this.props.priorityChanged && this.props.priorityChanged(priorityChanged);
                                                                this.props.onChange && this.props.onChange();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {/* Line between items */}

                                    {item.isSelectedExpanded && (
                                        <div className="itemHeaderContainer mb-2"></div>
                                    )}

                                    {/* Selected sub items */}
                                    {item.isSelectedExpanded &&
                                        this.setSelectedSubList(item)}
                                </Neo.Card>
                            </div>
                        </React.Fragment>
                    ))}
            </div>
        );

        return listItems;
    }

    private setSelectedSubList(item: ListSelectorModalLookup) {
        return (
            <div className="mb-3 col-12 p-0">
                {/* ListSubItems */}
                {item.subList.map((subItem) => (
                    <div key={subItem.subItemId} className="mt-3">
                        {subItem.isSelected && (
                            <>
                                <div className="row mr-0 ml-0">
                                    <div className="col-12 p-0">
                                        <span className="subItemSelected">
                                            <Icon.MinusCircle
                                                className={`mr-2 ${this.props.isNotEditable ? "cursorDefault" : ""}`}
                                                onClick={() => {
                                                    if (
                                                        !this.props
                                                            .isNotEditable
                                                    ) {
                                                        deselectItem(
                                                            this.props.list,
                                                            item,
                                                            subItem
                                                        );
                                                        subItem.isPriority =
                                                            false;
                                                        this.props.onChange &&
                                                            this.props.onChange();
                                                    }
                                                }}
                                            />{" "}
                                            {subItem.subItemName}
                                        </span>

                                        {item.isPriority !== null && (
                                            <div className="pull-right">
                                                <div className="row p-0 m-0">
                                                    <span className="mr-2 font12 priority">
                                                        Priority
                                                    </span>
                                                    <Neo.FormGroup
                                                        bind={subItem.meta.isPriority}
                                                        suppressLabel={true}
                                                        input={{ type: "switch" }}
                                                        disabled={this.props.isNotEditable}
                                                        onChange={() => {
                                                            togglePriority(item, subItem);
                                                            let priorityChanged = validatePriority(this.props.list);
                                                            this.props.priorityChanged && this.props.priorityChanged(priorityChanged);
                                                            this.props.onChange && this.props.onChange();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        );
    }

    private hasSubItemsSelected() {
        return this.props.list.some((item) =>
            item.subList.some((subItem) => subItem.isSelected === true)
        );
    }

    public render() {
        const { estimatedIndustries } = this.props

        return (
            <div className="listSelectorSelectedItems">
                <div className="row m-0">
                    <div className="col-6 p-0">
                        <h4 className="mt-3">{this.props.title}</h4>
                    </div>

                    {!this.isOverview &&
                        ((this.showSeniorityLevel && this.props.isHeadOfSeniority?.value)
                            || this.props.isManagerOfSeniority?.value
                            || this.showAddButton) && (
                            <div className="col-6 p-0">
                                <button
                                    className={`addButton ${this.showSeniorityLevel
                                        ? "rolesAndFunctions"
                                        : ""
                                        } pull-right mt-4`}
                                    onClick={() => this.props.openModal()}
                                >
                                    <Icon.Plus className="mr-2"></Icon.Plus>
                                    {this.props.btnText}
                                </button>
                            </div>
                        )}
                </div>

                {this.showSeniorityLevel && (
                    <>
                        <div className="itemHeaderContainer mt-3 mb-3"></div>
                        <div className="listSelectorRolesAndFunctionsContainer p-3">
                            <div className="row p-0 m-0 mb-2">
                                <b>
                                    {
                                        textConstants.Onboarding
                                            .ListSelectorTMSubTitleRolesAndFunctions
                                    }
                                </b>
                            </div>
                            <div className="row p-0 m-0">
                                <Neo.FormGroup
                                    bind={this.props.isHeadOfSeniority}
                                    suppressLabel={true}
                                    input={{ type: "switch" }}
                                    disabled={this.isOverview}
                                    onChange={() =>
                                        this.props.onChange &&
                                        this.props.onChange()
                                    }
                                />
                                <span className="">
                                    <b>
                                        {
                                            textConstants.Onboarding
                                                .ListSelectorHeadOfSeniorityLevelRolesAndFunctions
                                        }
                                    </b>
                                </span>
                                {!this.isOverview && <Icon.AlertCircle className="mr-4 ml-2 functionSVG" />}
                                <Neo.FormGroup
                                    className="ml-3"
                                    bind={this.props.isManagerOfSeniority}
                                    suppressLabel={true}
                                    input={{ type: "switch" }}
                                    disabled={this.isOverview}
                                    onChange={() => {
                                        this.props.onChange &&
                                            this.props.onChange();
                                    }}
                                />
                                <span>
                                    <b>
                                        {
                                            textConstants.Onboarding
                                                .ListSelectorManagerOfSeniorityLevelRolesAndFunctions
                                        }
                                    </b>
                                </span>
                                {!this.isOverview && <Icon.AlertCircle className="mr-4 ml-2 functionSVG" />}
                            </div>
                        </div>
                    </>
                )}
                {this.showSeniorityLevel &&
                    !this.props.isHeadOfSeniority!.value &&
                    !this.props.isManagerOfSeniority!.value && (
                        <>
                            <div className="itemHeaderContainer mt-3 mb-3"></div>
                            <div className="listSelectorRolesAndFunctionsContainer warning p-3">
                                In order to target Functions, you have to select{" "}
                                <b>Head or Manager role levels.</b>
                            </div>
                        </>
                    )}
                {((this.showSeniorityLevel &&
                    this.hasSubItemsSelected() &&
                    (this.props.isHeadOfSeniority!.value ||
                        this.props.isManagerOfSeniority!.value)) ||
                    (!this.showSeniorityLevel &&
                        this.hasSubItemsSelected())) && (
                        <>
                            {estimatedIndustries !== undefined &&
                                <>
                                    <div className='row potentialAccounts mt-3'>
                                        <div className='col-12 m-auto pl-3 pr-3'>
                                            {textConstants.generalText.potentialAccounts}
                                            <span className='pull-right font-weight-bold'>
                                                {this.props.estimatedIndustries}
                                            </span>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="itemHeaderContainer mt-3 mb-2"></div>
                            {this.setSelectedList()}
                        </>
                    )}
            </div>
        );
    }
}
