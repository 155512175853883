import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import SubRegion from '../Models/Maintenance/SubRegion';

export interface ISubRegionApiClient extends Data.IUpdateableApiClient<SubRegion, number> {

}

@injectable()
export default class SubRegionApiClient extends Data.UpdateableApiClient<SubRegion, number> implements ISubRegionApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/SubRegions`);
    }
}