import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import ComXConfiguration from '../Models/Maintenance/ComXConfiguration';
import { AppService, Types } from '../Services/AppService';

export interface IComXConfigurationsApiClient extends Data.IUpdateableApiClient<ComXConfiguration, number> {

}

@injectable()
export default class ComXConfigurationsApiClient extends Data.UpdateableApiClient<ComXConfiguration, number> implements IComXConfigurationsApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/comXConfigurations`);
    }
}