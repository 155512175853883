import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import IndustriesVersion from 'Models/Maintenance/IndustriesVersion';
import { List } from '@singularsystems/neo-core';
import { textConstants } from 'common/textConstants';
import Industry from 'Models/Maintenance/Industry';
import IndustrySubIndustry from 'Models/Maintenance/IndustrySubIndustry';

export default class IndustriesVersionVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private industriesVersionsApiClient = AppService.get(Types.ApiClients.IndustriesVersionsApiClient),
        private industriesApiClient = AppService.get(Types.ApiClients.IndustriesApiClient),
        private industrySubIndustiesApiClient = AppService.get(Types.ApiClients.IndustrySubIndustriesApiClient),
        private appDataCache = AppService.get(Types.Services.AppDataCache)) {

        super(taskRunner);
        this.makeObservable();
    }

    public industriesVersionsList = new List(IndustriesVersion)
    public industriesList = new List(Industry)
    public industrySubIndustriesList = new List(IndustrySubIndustry)

    public newItemCounter = 0;
    public showInvalidModal = false;
    public invalidModalText = ""

    public async initialise() {
        await this.getIndustries()
        await this.getIndustriesVersions();
        await this.getIndustrySubIndustries()
    }

    public async getIndustriesVersions(){
        const response = await this.taskRunner.waitFor(this.industriesVersionsApiClient.get());
        this.industriesVersionsList.set(response.data);
    }

    public async getIndustries(){
        const response = await this.taskRunner.waitFor(this.industriesApiClient.get());
        this.industriesList.set(response.data);        
    }

    public async getIndustrySubIndustries(){
        const response = await this.taskRunner.waitFor(this.industrySubIndustiesApiClient.get());
        this.industrySubIndustriesList.set(response.data);        
    }

    public saveIndustriesVersionList() {

        this.industriesVersionsList.forEach(version => {
            if (version.industryVersionName === ""){
                // If the name is empty, default it
                version.industryVersionName = `V${version.industriesVersionId}`
            }

            if (this.isNewItem(version)){
                // Unlink number - DB will assign Number
                version.industriesVersionId = 0;
            }
        })        
    
        this.taskRunner.run(async () => {
            const response = await this.industriesVersionsApiClient.saveList(this.industriesVersionsList.toJSArray());
        
            if (response.status === 200){
                this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.IndustiesVersionsSaved);
                this.appDataCache.industriesVersions.expire(true);
                this.getIndustriesVersions();
            }
        });
    }

    public hasCompleteIndustryLinkage(industryVersion: number){
        let isComplete = false;

        if (this.industriesList.length > 0)
        {
            isComplete = this.industriesList.some(s => s.industriesVersionId === industryVersion)
        }

        if (!isComplete)
        {
            this.invalidModalText = textConstants.IndustriesVersionsValidations.NoIndustryOrSubIndustryLinked
            return isComplete
        }

        let industries = this.industriesList
            .filter(f => f.industriesVersionId === industryVersion)
            .map(m => m.industryId)

        isComplete = this.industrySubIndustriesList.some(s => industries.some(industryId => s.industryId === industryId))

        if (!isComplete){
            this.invalidModalText = textConstants.IndustriesVersionsValidations.NoSubIndustryLinked
        }

        return isComplete
    }


    public setVersionAsActive(industry: IndustriesVersion){

        if (this.isNewItem(industry)){
            this.invalidModalText = textConstants.IndustriesVersionsValidations.CannotSetNewActive
            this.showInvalidModal = true
            return
        }

        if (!this.hasCompleteIndustryLinkage(industry.industriesVersionId)){
            this.showInvalidModal = true
            return
        }

        let currentActive = this.industriesVersionsList
            .find(f => f.isActive)

        if (currentActive){
            if (currentActive.industriesVersionId === industry.industriesVersionId){
                // user must select a different version to toggle the state, so keep it as is
                return;
            }

            let newActive = this.industriesVersionsList
                .find(f => f.industriesVersionId === industry.industriesVersionId)

            if (newActive){

                industry.isActive = true;
                
                currentActive.isActive = !industry.isActive;
                newActive = industry;
            }
        }
    }

    public removedNew(id:number){
        let removedItem = this.industriesVersionsList.find(f => f.industriesVersionId === id)

        if (removedItem)
        {
            this.industriesVersionsList.remove(removedItem);
            this.newItemCounter -= 1;

            if (this.newItemCounter === 0){
                return
            }

            var otherNewItems = this.industriesVersionsList.filter(f => f.industriesVersionId > id)

            otherNewItems.forEach(item => 
            {
                // Update the version number in the text if it is still there
                item.industryVersionName = item.industryVersionName
                    .replace(`V${item.industriesVersionId}`,`V${id}`)
                    .replace(`v${item.industriesVersionId}`,`v${id}`)

                item.industriesVersionId = id;
                id += 1;
            })
        }
    }

    public isNewItem(industry:IndustriesVersion){
        if (industry instanceof IndustriesVersion)
        {
           return industry.meta.isNew.value
        }
        
        return false;
    }

    public canAddNewItem(){
        let hasDuplicate = this.HasDuplicateVersionName();
        
        let hasEmptyName = this.industriesVersionsList.some(items => items.industryVersionName === "") 

        let canAdd = hasEmptyName || hasDuplicate
        
        return canAdd
    }

    public HasDuplicateVersionName() {
        let hasDuplicate = false;
        let items = this.industriesVersionsList.map(s => s.industryVersionName) as any[];
        let setSize = new Set(items).size;

        if (setSize < this.industriesVersionsList.length) {
            hasDuplicate = true;
        }

        return hasDuplicate;
    }

    public AddNewItem(): void {
        const newId = this.industriesVersionsList.length + 1;
        let newItem = new IndustriesVersion()
        newItem.industriesVersionId = newId;
        newItem.industryVersionName = `V${newId}`
        this.industriesVersionsList.push(newItem)

        this.newItemCounter += 1;
    }
}