import React from 'react';
import { hotjar } from 'react-hotjar';
import { AppService, Types } from '../Services/AppService';

export default class Hotjar extends React.Component {

  constructor(props: any) {
    super(props);
  }

  private async getHotjarData() {
    let invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient)

    let data = await invitedUsersApiClient.hotjarInitialization()

    if (data) {
      let hotjarInitialData = data.data;
        
      let clientActivation = hotjarInitialData.clientActivationDate 
        ? new Date(hotjarInitialData.clientActivationDate).toISOString(): ""

      // Data Collection
      if (hotjar.initialized()) {
        hotjar.identify(hotjarInitialData.userId.toString(), {
          'ClientId': hotjarInitialData.clientId,
          'Username': hotjarInitialData.userName,
          'Company Name': hotjarInitialData.companyName,
          'email': hotjarInitialData.email,
          'User group': hotjarInitialData.userGroups!.toString(),
          'Sign up date': hotjarInitialData.signUpDate,
          'Client Activation Date': clientActivation,
          'Customer Success Manager': hotjarInitialData.customerSuccessManager,
          'Target Market ID': hotjarInitialData.targetMarketIds!.toString(),
        });
      }
    }
  }

  public async componentDidMount() {
    setTimeout(async () => {
      await this.getHotjarData()
    }, 10000);
  }

  public render() {
    return (
      <><div></div></>
    );
  }
}

// Used when triggering a hotjar event, for the Client User (External User)
export function triggerHotjarEvent(event: string){
  
    setTimeout(async () => {
      await hotjarEvent(event)
    }, 1000);

    function hotjarEvent(event: string) {
      let auth = AppService.get(Types.Security.CustomAuthenticationService)
    
      if (auth.globalProps.isClientUser){
        hotjar.event(event)
      }
    }
}