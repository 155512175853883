import { Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../Services/AppService";
import CampaignMessageComingVM from './CampaignMessageComingVM';
import MeetingsVM from './MeetingsVM';
import CampaignEmailSignatureVM from 'Views/CampaignMessages/CampaignEmailSignatureVM';
import AddEditFollowUpTemplateVM from 'Views/CampaignMessages/AddEditFollowUpTemplateVM';
import CampaignMessageVM from 'Views/CampaignMessages/CampaignMessageVM';
import ClientCampaignMessageVM from 'Views/CampaignMessages/ClientCampaignMessageVM';
import SupportLink from 'Models/Maintenance/SupportLink';
import { OnboardingSteps } from 'Models/Enums/ApplicationEnums';
import { ICampaignMessageLink, IComXHubInfo } from './CampaignMessage/Components/MeetingDetailsContent/MeetingDetailsContent';
import { List } from '@singularsystems/neo-core';
import { textConstants } from 'common/textConstants';
import OnboardingFunctions from "Models/Onboarding/OnboardingFunctions";


export default class OnboardingCampaignMessageBaseViewVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    public authenticationService = AppService.get(Types.Security.CustomAuthenticationService),
    private appDataCache = AppService.get(Types.Services.AppDataCache),
    private supportLinksApiClient = AppService.get(Types.ApiClients.SupportLinksApiClient),
    private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient)) {
    super(taskRunner);
    this.makeObservable();
  }

  public clientCampaignMessagesViewModel = new ClientCampaignMessageVM();
  public campaignMessageViewModel: CampaignMessageVM = new CampaignMessageVM();
  public addEditFollowUpTemplateViewModel: AddEditFollowUpTemplateVM = new AddEditFollowUpTemplateVM();
  public emailSignatureViewModel: CampaignEmailSignatureVM = new CampaignEmailSignatureVM();
  public meetingsViewModel: MeetingsVM = new MeetingsVM();
  public campaignMessageComingViewModel = new CampaignMessageComingVM()

  public clientCampaignMessageId: number = 0
  public showCampaignMessageListView: boolean | null = null
  public isCampaignMessageReady: boolean = false
  public isComingFromFollowUpTemplates: boolean = false
  public hasBeenSet: boolean = false
  public onboardingSupportLinkList = new List(SupportLink);
  public hasInitiallyLoaded: boolean = false;
  public isMeetingBooked: boolean = false
  public initialPage: string = ""
  public initialView: string = ""

  public comXKnowledgeHub: IComXHubInfo = {
    url: "",
    password: ""
  };

  public campaignLinks: ICampaignMessageLink[] = [];

  public async initialise() {
    this.taskRunner.run(async () => {
      this.isMeetingBooked = await this.isMeetingScheduled()
      await this.getClientCampaignMessages()
      await this.getSupportLinks()
      this.getKnowledgeHub()
      await this.campaignMessageComingViewModel.initialise();
      await this.meetingsViewModel.initialise()
    })
  }

  public canCompleteOnboarding() {
    let onboardingItemsCount = this.authenticationService.globalProps.onboardingSteps.length

    let completedItemsCount = this.authenticationService.globalProps.onboardingSteps.filter(x => x.isComplete)?.length

    return (onboardingItemsCount - 1 <= completedItemsCount) &&
      this.authenticationService.globalProps.onboardingSteps.find(x => x.stepNumber === OnboardingSteps.CampaignMessages && x.isComplete);
  }

  public getKnowledgeHub() {
    let comXConfig = this.appDataCache.comXConfigurations.get().data

    if (comXConfig) {
      let knowledgeHubPassword = comXConfig.find((config: any) => config.configCode === "KnowledgeHubPassword")?.value as string
      let knowledgeHub = textConstants.links.ComXHub;

      this.comXKnowledgeHub = {
        password: knowledgeHubPassword,
        url: knowledgeHub,
      }
    }
  }

  public async getClientCampaignMessages() {
    let clientId = this.authenticationService.globalProps.clientId;

    if (this.authenticationService.globalProps.clientCampaignMessageId === 0
      && this.authenticationService.globalProps.clientFollowUpTemplateId === 0) {
      this.clientCampaignMessagesViewModel = new ClientCampaignMessageVM();
      this.clientCampaignMessagesViewModel.taskRunner = this.taskRunner;
      this.clientCampaignMessagesViewModel.clientId = clientId;
      this.clientCampaignMessagesViewModel.isComingFromFollowUpTemplates = this.isComingFromFollowUpTemplates;

      // Set values & fetch data
      await this.clientCampaignMessagesViewModel.fetchData(clientId);

      if (this.clientCampaignMessagesViewModel.pageManager.totalRecords > 0) {
        this.hasInitiallyLoaded = true;
      }

      // This ensures that navigation from Templates back to Messages loads the messages
      if (this.isComingFromFollowUpTemplates) {
        this.clientCampaignMessagesViewModel.isComingFromFollowUpTemplates = false;
      }
    }
  }

  public async getSupportLinks() {
    this.campaignLinks = new Array
    let counter = 1;

    const onboardingSupportLinkResponse = await this.taskRunner.waitFor(this.supportLinksApiClient.get());
    this.onboardingSupportLinkList.set(onboardingSupportLinkResponse.data);
    let onboardingSupportLinkItems = this.onboardingSupportLinkList.filter(x => x.onboardingStepId === OnboardingSteps.CampaignMessages)

    onboardingSupportLinkItems.forEach(link => {
      let campaignLink: ICampaignMessageLink = {
        id: counter,
        link: link.url,
        title: link.description
      };

      counter++;

      this.campaignLinks.push(campaignLink)
    })
  }

  public async isMeetingScheduled() {
    let response = await this.onboardingApiClient.getOnboardingMeeting(this.authenticationService.globalProps.clientId)

    if (response.data.success || this.authenticationService.globalProps.onboardingSteps
      .find(x => x.stepNumber === OnboardingSteps.BookAMeeting)?.isComplete) {
      return true
    }

    return false
  }

  public async cancelMeeting() {
    const { globalProps } = this.authenticationService

    let isSuccess = false

    await this.taskRunner.run(async () => {
      let response = await this.onboardingApiClient.cancelCalendlyMeetingAsync(globalProps.clientId);

      if (response && response.data.success) {
        isSuccess = true
        globalProps.isMeetingBooked = false

        let onboardingSteps = globalProps.onboardingSteps.find(x => x.stepNumber === OnboardingSteps.BookAMeeting)!
        onboardingSteps.isComplete = false
      } else {
        this.notifications.addDanger("Error", textConstants.Onboarding.CancellationFailed)
      }
    });
    return isSuccess
  }
}