import { List, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import GreyListedProspectLookup from '../GreyListedProspectLookup';
import GreylistProspectCriteria from '../Query/GreylistProspectCriteria';

@NeoModel
export default class DeleteGreylistProspectCommand extends ModelBase {
   @Rules.Required()
   public GreylistProspectId: number = 0;

   public greyListedProspectLookup? = new List(GreyListedProspectLookup)

   public unselectedGreylistedProspect? = new List(GreyListedProspectLookup)

   public greyListProspectSearchCriteria? = new GreylistProspectCriteria

   public isSelectAll: boolean = false;
}