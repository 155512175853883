import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import Industry from './Industry';
import SubIndustry from './SubIndustry';

@NeoModel
export default class IROIndustryMapping extends ModelBase {

    public iroIndustryMappingId: number = 0;

    @Rules.Required()
    @Attributes.Display("Industries Version From")
    public industriesVersionIdFrom: number | null = null;

    @Rules.Required()
    @Attributes.Display("Sub-Industry From")
    public subIndustryIdFrom: number | null = null;

    @Rules.Required()
    @Attributes.Display("Industries Version To")
    public industriesVersionIdTo: number | null = null;

    @Rules.Required()
    @Attributes.Display("Sub-Industry To")
    public subIndustryIdTo: number | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New IRO Industry Mapping";
        } else {
            return "IRO Industry Mapping";
        }
    }
}