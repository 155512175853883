import { ModelBase, NeoModel } from "@singularsystems/neo-core";

@NeoModel
export default class UpdatePopupDataCommand extends ModelBase {

    public onboardingPopupId : Number = 0;

    public popupName: string = "";

    public text: string = "";

}