import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import BatchReviewProspectsVM from '../BatchReview/BatchReviewProspectsVM';
import BatchReviewUploadVM from '../BatchReview/BatchReviewUploadVM';

@NeoModel
export default class BatchReviewCampaignsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private authenticationService = AppService.get(Types.Neo.Security.AuthenticationService),
        private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
        public customAuthService = AppService.get(Types.Security.CustomAuthenticationService)) {

        super(taskRunner);
        this.customAuthService.globalProps.isOnboarding = false;
    }

    public clientId: number = 0
    public initialCampaignLoad: boolean = true;
    public showDashboard: boolean = true

    public showBatchReview: boolean = true
    public showBatchReviewProspects: boolean = false
    public BatchReviewUploadViewModel = new BatchReviewUploadVM();
    public BatchReviewProspectsViewModel = new BatchReviewProspectsVM();
    public batchReviewUploadId: number = 0
    public campaignsMode: string = ""
    public clientDisplayName: string = "";

    public async initialise() {
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }

    public async setClientDisplayName(clientName: string) {
        this.clientDisplayName = clientName
    }

    public async getClientId() {
        const { user, isAuthenticated } = this.authenticationService;

        // Fetch the platformSetup and successTracker urls
        if (isAuthenticated) {
            const response = await this.clientsApiClient.getDashboardInfo(user!.userName);
            this.clientId = response.data.clientId;
        }
    }

    public async getClientName(clientId: number) {
        const response = await this.taskRunner.waitFor(this.clientsApiClient.getClient(clientId));
        return response.data.clientName;
    }
}