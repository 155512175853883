import { ModelBase, NeoModel, Validation} from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class ManagementSubFunctionLocalisation extends ModelBase {
    
      managementSubFunctionLocalisationId: number = 0;

      managementSubFunctionId: number = 0;

      languageId: number = 0;

      localisation: string = "";

      public addBusinessRules(rules: Validation.Rules<this>) {
            super.addBusinessRules(rules);
            rules.failWhen(c => c.languageId === 0, textConstants.messageText.validationMessage.language);
            rules.failWhen(c => c.localisation === "", textConstants.messageText.validationMessage.localisation);
      }

      public toString(): string {
            if (this.isNew || !this.localisation) {
                  return "New Localisation";
            } else {
                  return this.localisation;
            }
      }
}