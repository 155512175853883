import { List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import BatchReviewDeleteAccountCommand from './BatchReviewDeleteAccountCommand';
import BatchReviewDeleteContactCommand from './BatchReviewDeleteContactCommand';

@NeoModel
export default class BatchReviewSaveCommand extends ModelBase {

    // Properties
    public batchReviewUploadId : number = 0;

    public clientId : number = 0;

    public redirectUrl : string = "";

    public batchReviewAccountDeletions?: List<BatchReviewDeleteAccountCommand> = new List(BatchReviewDeleteAccountCommand);

    public batchReviewContactDeletions?: List<BatchReviewDeleteContactCommand> = new List(BatchReviewDeleteContactCommand);
}