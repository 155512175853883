import { IStep } from "Components/Stepper/Stepper";
import { OnboardingSteps } from "Models/Enums/ApplicationEnums";
import React, { useEffect, useState } from "react";

interface ICircleProps {
  colour?: string;
  pct: number;
  transform?: string;
}

const cleanPercentage = (percentage: number) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct, transform }: ICircleProps) => {
  const r = 80;
  const circ = 2 * Math.PI * r; // Diameter
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      transform={transform}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={"1rem"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="square"
    ></circle>
  );
};

//  @ts-ignore
const Text = ({ percentage, subText }) => {
  return (
    <>
      <text
        x="50%"
        y="45%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize={"1.5em"}
      >
        {percentage.toFixed(0)}%
      </text>
      <text
        x="50%"
        y="55%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize={"0.7em"}
      >
        {subText}
      </text>
    </>
  );
};

interface IPieProps {
  percentage: number;
  colour: string;
  subText: string;
  itemList: any[];
}

const filterSteps = (itemList: IStep[]) => {
  return itemList.filter(x => x.completed && x.step !== OnboardingSteps.TechnicalIntegration)
}

const getCompletedChart = (itemList: IStep[]) => {
  return 20 * filterSteps(itemList).length;
}

const Pie = ({ percentage, colour, subText, itemList }: IPieProps) => {

  const [items, setItems] = useState(itemList);

  useEffect(() => {
    setItems(itemList); // Update the items when itemList changes
  }, [itemList]);

  const [colourValue, setColour] = useState(colour);

  useEffect(() => {
    setColour(colour); // Update the items when itemList changes
  }, [colour]);

  return (
    <svg width={200} height={200}>
      <g transform={`rotate(-90 ${100} ${100})`}>
        <Circle colour={colourValue} pct={0} />
        <Circle colour={"#D2E7F1"} pct={80} />
        {filterSteps(itemList).length > 0 &&
          <Circle colour={"#97CBE6"} pct={getCompletedChart(items)} />}
      </g>

      <Text percentage={percentage} subText={subText} />
    </svg>
  );
};

export default Pie;
