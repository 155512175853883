import { injectable } from 'inversify';
import Axios, { AxiosPromise } from 'axios';
import Types from '../../AuthorisationTypes';
import { Misc } from '@singularsystems/neo-core';

export interface IResourcesApiClient {
    /**
     * Will get a list of Resources
     */
    getRolesAsync(): AxiosPromise<any>;

}

@injectable()
export default class ResourcesApiClient implements IResourcesApiClient {

    private apiPath: string;

    constructor(config = Misc.Globals.appService.get(Types.Config.ConfigModel)) {
        this.apiPath = `${config.authorisationServerApiPath}/Resources`;
    }

    public getRolesAsync(): AxiosPromise<any> {
        return Axios.get(`${this.apiPath}/Lookup/`);
    }
}
