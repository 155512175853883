import { observer } from "mobx-react";
import React from "react";
import AdvancedEmailSettingsVM from "./AdvancedEmailSettingsVM";
import { textConstants } from "common/textConstants";
import { StyledEmailCardHeading, StyledEmailCardText, StyledLabel } from "Views/EmailSetup/styles";
import HtmlParser from "react-html-parser";
import * as Icon from 'react-feather';
import { Neo } from "@singularsystems/neo-react";

export interface ITestEmailSection {
  viewModel: AdvancedEmailSettingsVM;
  hasEditPermissions: boolean;
  isExternalUser: boolean;
  isOnboarding?: boolean;
  addPadding?: boolean
}

@observer
export default class TestEmailSection extends React.Component<ITestEmailSection> {

  private isErrorEmail(email: string) {
    let hasError = this.props.viewModel.testEmailErrorList.some(errorEmail => errorEmail === email)

    return hasError
  }

  public render() {
    return (
      <div className={this.props.addPadding ? "p-24" : "p-0"}>
        {/* Header */}
        <div className="row">
          <div className="col-12">
            {!this.props.isOnboarding &&
              <h3 className="pt-0 mt-4 mb-3">
                {textConstants.InternalTest}
              </h3>
            }
            {this.props.isOnboarding &&
              <StyledEmailCardHeading className="mt-4 mb-3">
                {textConstants.InternalTest}
              </StyledEmailCardHeading>
            }
          </div>
        </div>

        {/* Info Text */}
        {this.props.isExternalUser &&
          <div className="row">
            <div className="col-12">
              <StyledEmailCardText className="testEmailInfoTextContainer">
                {HtmlParser(this.props.viewModel.advancedEmailSettingsLookup.internalEmailText)}
              </StyledEmailCardText>
            </div>
          </div>
        }

        {/* Email Address Label */}
        <div className="row mt-4">
          <div className="col-12">
            <StyledLabel className="testEmailLabel">
              {textConstants.EmailSettings.InternalTest.EmailTestPlaceholder.toUpperCase()}
            </StyledLabel>
          </div>
        </div>

        {/* Add Email textbox and add button row */}
        <div className="row">
          <div className="col-12">
            <div className={`testEmailsContainer ${this.isErrorEmail(this.props.viewModel.testEmailAddition.meta.emailAddress.value) ? "" : "mb-4"}`}>

              {/* Textbox */}
              <div>
                <Neo.FormGroup
                  input={{ type: "text" }}
                  className='testInputBoxStyling'
                  bind={this.props.viewModel.testEmailAddition.meta.emailAddress}
                  placeholder={textConstants.EmailSettings.InternalTest.EmailTestPlaceholder}
                  suppressLabel={true}
                  onKeyUp={(e) => {
                    e.preventDefault()

                    if (e.key === "Enter") {
                      this.props.viewModel.addTestEmail()
                    }
                  }}
                />
              </div>

              {/* Add/Plus button */}
              <div>
                <Neo.Button
                  className='plusButton '
                  variant='light'
                  disabled={this.props.viewModel.testEmailAddition.meta.emailAddress.value === ""}
                  onClick={(e: any) => {
                    e.preventDefault()
                    this.props.viewModel.addTestEmail()
                  }}
                  icon={<Icon.Plus />}>
                </Neo.Button>
              </div>
            </div>
          </div>
        </div>

        {/* Error Message for new Email */}
        {this.isErrorEmail(this.props.viewModel.testEmailAddition.meta.emailAddress.value) &&
          <div className='row mb-4'>
            <div className="col-12">
              <div className="errorMessage">
                {textConstants.messageText.validationMessage.testEmailErrorMessage}
              </div>
            </div>
          </div>
        }
        <div className="mb-4">
          {this.props.viewModel.dataHasLoaded
            && this.props.viewModel.testEmailList.length > 0
            && this.props.viewModel.testEmailList.map((email) => {
              if (email === undefined || email.emailAddress === undefined || email.meta === undefined) {
                return <></>
              }

              return (
                <React.Fragment key={(email.clientTestEmailId === 0) ? email.emailAddress : email.clientTestEmailId}>
                  {email.meta.emailAddress !== undefined &&
                    <>
                      {/* Test Email List Item */}
                      <div className='row mb-1'>
                        <div className="col-12">
                          <div className='testEmailsContainer'>
                            <>
                              {/* Textbox */}
                              <div>
                                <Neo.FormGroup
                                  input={{ type: "text" }}
                                  className='testInputBoxStyling'
                                  bind={email.meta.emailAddress}
                                  suppressLabel={true} />
                              </div>

                              {/* Delete button */}
                              <div>
                                <Neo.Button
                                  className='plusButton'
                                  variant='light'
                                  onClick={(e: any) => {
                                    if (email.clientTestEmailId === 0) {
                                      this.props.viewModel.testEmailList.remove(email);
                                    }
                                    else {
                                      this.props.viewModel.deleteTestEmailFromList(email)
                                    }
                                  }}
                                  icon={<Icon.Trash2 />}>
                                </Neo.Button>
                              </div>
                            </>
                          </div>
                        </div>
                      </div>

                      {/* Test Email List Item Error */}
                      {this.isErrorEmail(email.emailAddress) &&
                        <div className='row mb-4'>
                          <div className="col-12 p-0 ">
                            <div className="errorMessage">
                              {textConstants.messageText.validationMessage.testEmailErrorMessage}
                            </div>
                          </div>
                        </div>
                      }
                    </>
                  }
                </React.Fragment>
              )
            })
          }
        </div>
      </div>
    )
  }
}