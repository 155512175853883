import { NeoModel, List, ModelBase } from '@singularsystems/neo-core';
// import FilterProspectedTimes from './FilterProspectedTimes';
import FilteredEmployeeSize from '../../TargetMarketAccounts/Query/FilteredEmployeeSize';
import FilteredIndustries from '../../TargetMarketAccounts/Query/FilteredIndustries';
import AccountStatusFilter from './AccountStatusFilter';
import DateAddedFilter from './DateAddedFilter';
import IndustryFilter from './IndustryFilter';
import InvalidReasonFilter from './InvalidReasonFilter';
import RegionFilter from './RegionFilter';
import ReOpenedCountFilter from './ReOpenedCountFilter';
import IndustriesVersionFilter from './IndustriesVersionFilter';

@NeoModel
export default class TargetMarketAccountFilterList extends ModelBase {
    // public filterTargetMarketStatuses = new List(FilterTargetMarketStatuses)
    public filteredEmployeeSize = new List(FilteredEmployeeSize)
    public filteredIndustries = new List(FilteredIndustries)
    public filteredindustriesVersions = new List(IndustriesVersionFilter)
    public filteredStatuses = new List(AccountStatusFilter)
    public filteredDateAdded = new List(DateAddedFilter)
    public filteredIndustrySubIndustry = new List(IndustryFilter)
    public filteredInvalidReasons = new List(InvalidReasonFilter)
    public filteredRegions = new List(RegionFilter)
    public filteredReOpenedCount = new List(ReOpenedCountFilter)


    // public filteredProspectedDate = new List(FilteredProspectedDate)
    // public filteredProspectedTimes = new List(FilterProspectedTimes)

    // public get filterTargetMarketStatusesDropdown(): any {
    //     return this.filterTargetMarketStatuses.map((c: FilterTargetMarketStatuses) => {
    //         return { id: c.targetMarketAccountStatusId, label: c.targetMarketAccountStatusName }
    //     })
    // }
}

@NeoModel
class TriStateList {
    id: number = 1
    label: string = ""
}

export var tristateDropDown: TriStateList[] = [
    {
        id: 1,
        label: "All"
    },
    {
        id: 2,
        label: "Yes"
    },
    {
        id: 3,
        label: "No"
    }]

export var YesDropDown: TriStateList[] = [
    {
        id: 2,
        label: "Yes"
    },
    {
        id: 3,
        label: "No"
    }
]