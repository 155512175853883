import React from "react";
import { ModalBody } from "reactstrap";
import Button from "Components/Button";
import {
  StyledModal,
  StyledModalButtons
} from './styles';

interface IOnboardingModal {
  extraClass?: string,
  isOpen: boolean,
  closeModal?: () => void,
  content: JSX.Element,
  imageSrc?: string,
  closeButton?: {
    text: String
  },
  acceptAndCloseButton?: {
    text: String,
    onClose: () => void
  },
  children?: React.ReactNode
}

class OnboardingModal extends React.Component<IOnboardingModal> {

  state = {
    isOpen: this.props.isOpen,
  };

  constructor(props: any) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        isOpen: this.props.isOpen
      })
    }
  }

  toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
    if (this.props.closeModal) this.props.closeModal();
  }

  render() {
    return (
      <>
        <StyledModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          className={`${this.props.extraClass}`}
        >
          <ModalBody className="completeStepModal backgroundColor">
            {this.props.imageSrc && <img className="imgWidth" src={this.props.imageSrc} />}
            {this.props.content}
          </ModalBody>
          {/*Buttons*/}
          <StyledModalButtons
            isSingleButton={this.props.closeButton && !this.props.acceptAndCloseButton}
          >
            {this.props.closeButton && (
              <Button
                variant="outlined"
                buttonType="primary"
                size="large"
                onClick={this.toggleModal}
              >
                {this.props.closeButton.text}
              </Button>
            )}
            {this.props.acceptAndCloseButton && (
              <Button
                variant="contained"
                buttonType="secondary"
                size="large"
                fullWidth
                marginNone
                onClick={() => {
                  this.props.acceptAndCloseButton?.onClose();
                  this.toggleModal();
                }}
              >
                {this.props.acceptAndCloseButton.text}
              </Button>
            )}
          </StyledModalButtons>
        </StyledModal>
      </>
    );
  }
}

export default OnboardingModal;