import "rc-slider/assets/index.css";
import "react-input-range/lib/css/index.css";
import React from "react";
import { observer } from "mobx-react";
import { Neo } from "@singularsystems/neo-react";
import { textConstants } from "../../../common/textConstants";
import AddEditTargetMarketVM from "../AddEditTargetMarketVM";
import * as Icon from "react-feather";
import RegionsSection from "../RegionsSection";
import { CountryCodes } from "Models/Enums/ApplicationEnums";
import SpriteMap from "Components/MapCountryFlags";
import CustomDropDown, {
  IOptions,
} from "Components/CustomDropDown/CustomDropDown";
import DataTooltip from "Components/DataTooltip";
import moment from "moment";

interface TMScreen1Props {
  viewModel: AddEditTargetMarketVM,
  clientName: string,
  isComXUser: boolean,
  isNotEditable?: boolean,
}

@observer
export default class TMScreen1 extends React.Component<TMScreen1Props> {
  private selectedCountry: JSX.Element | string = "";

  private canUpdateState: boolean = true;

  private countries: any = [];

  public condition1(): boolean {
    let result =
      !this.props.viewModel.isComXUser &&
      this.props.viewModel.germanyId > 0 && // Indicates the record has been populated
      ((this.props.viewModel.targetMarket.showGermanRegions &&
        this.props.viewModel.targetMarket.countryId ===
        this.props.viewModel.germanyId) ||
        !(
          this.props.viewModel.targetMarket.countryId ===
          this.props.viewModel.germanyId
        )) &&
      !this.props.viewModel.isWhitelistTM; //Should not show regions when is whitelist only
    return result;
  }

  public condition2(): boolean {
    return (
      this.props.viewModel.isComXUser && !this.props.viewModel.isWhitelistTM
    );
  }

  public highlightMarketCriteria() {
    return this.props.viewModel.isMarketCriteria === true
      ? "highlightedText"
      : "";
  }

  // gets the selected country new flag and name
  private getSelectedCountry = (countryId: number) => {
    // Finds the country
    let currentCountry = this.props.viewModel.countries.find(
      (targetMarket) => targetMarket.countryId === countryId
    );

    // If current country is found then set the flag
    if (currentCountry) {
      let countryFlag =
        CountryCodes[currentCountry.countryCode] > 1 ? (
          <SpriteMap value={CountryCodes[currentCountry.countryCode]} />
        ) : (
          <span></span>
        );

      return (
        <div
          className="row ml-1"
          key={currentCountry.countryId}
        >
          {countryFlag}{" "}
          <span className="ml-2">{currentCountry.countryName}</span>
        </div>
      );
    }

    return <span></span>;
  };

  private handleCountryChange = async (newCountryId: number) => {
    // assigns the countryId of the selected item to the target market
    this.props.viewModel.targetMarket.countryId = newCountryId;

    // Make sure to deal with when the country is changed back to the original country.
    this.props.viewModel.tmCountryChanged = true;

    await this.props.viewModel.setLanguage(
      this.props.viewModel.targetMarket.countryId
    );
    this.props.viewModel.isScreenDirty = true;
    this.props.viewModel.isCountryBlank = false;

    // Sets the new state of the selectedCountry
    this.selectedCountry = this.getSelectedCountry(newCountryId);
  };

  // Gets the list of country flags for the dropdown
  private getCountryFlags = () => {
    let listOfCountries: any[] = [];

    this.props.viewModel.countries.forEach((country) => {
      const countryFlag =
        CountryCodes[country.countryCode] > 1 ? (
          <SpriteMap value={CountryCodes[country.countryCode]} />
        ) : null;

      let fullSelect = (
        <div className="row ml-1" key={country.countryId}>
          {countryFlag} <span className="ml-2">{country.countryName}</span>
        </div>
      );

      let option = {
        value: country.countryId,
        label: fullSelect,
      };

      listOfCountries.push(option);
    });

    return listOfCountries;
  };

  public highlightWhitelist() {
    return this.props.viewModel.isWhitelistTM === true ? "highlightedText" : "";
  }

  // Needs to be here, when you go back it does not trigger the componentDidUpdate
  private setDefaultCountries() {
    if (this.canUpdateState) {
      if (this.props.viewModel.targetMarket.countryId !== 0) {
        this.selectedCountry = this.getSelectedCountry(
          this.props.viewModel.targetMarket.countryId
        );
        this.canUpdateState = false;
      }

      if (this.countries.length === 0) {
        this.countries = this.getCountryFlags();
      }
    }
  }

  public render() {
    this.setDefaultCountries();

    return (
      <div className={`col-sm p-0 ${this.props.isNotEditable ? "" : "screen1"}`} >
        {/* Client Name */}
        <h3>
          {this.props.isNotEditable ? textConstants.titleText.TargetMarketOverview : textConstants.generalText.specifyYourTM}
        </h3>

        {this.props.isNotEditable &&
          <>
            <div><b>{textConstants.generalText.cannotEditTargetMarket}</b></div>
            <div className="target-market-modified mt-1">
              <i>Last modified on: {moment(this.props.viewModel.targetMarket.auditModifiedOn).format(textConstants.dateformat.dayMonthYear)} by {this.props.viewModel.targetMarket.auditModifiedByUsername}</i>
            </div>
          </>
        }

        <div className="row m-0">
          <div className="col-12 mt-5 p-0">
            <div className="row m-0 mb-2">
              <div className="p-0 targetMarketAddEditSubQuestion">
                {textConstants.titleText.TargetMarketNameSpaced.toUpperCase()}
              </div>
            </div>

            {/* Target Market Name */}
            <DataTooltip
              type={"disabledTip"}
              hidden={!(!this.props.isComXUser &&
                (this.props.viewModel.isEdit &&
                  this.props.viewModel.targetMarket.targetMarketStatusId !== this.props.viewModel.draftStatusId &&
                  this.props.viewModel.targetMarket.targetMarketStatusId !== this.props.viewModel.draftPreLaunchId))
                || this.props.isNotEditable}
              parent={
                <Neo.FormGroup
                  bind={this.props.viewModel.targetMarket.meta.targetMarketName}
                  className={`questionTextBox ${this.props.isNotEditable ? "isNotEditable" : ""}`}
                  isDisabled={!this.props.isComXUser &&
                    (this.props.viewModel.isEdit &&
                      this.props.viewModel.targetMarket.targetMarketStatusId !== this.props.viewModel.draftStatusId &&
                      this.props.viewModel.targetMarket.targetMarketStatusId !== this.props.viewModel.draftPreLaunchId)
                    || this.props.isNotEditable}
                  onChange={() => {
                    this.props.viewModel.isScreenDirty = true
                    this.props.viewModel.isTargetMarketNameBlank = false
                  }}
                  suppressLabel={true}
                />}>
              {"This field cannot be edited for active Target Markets."}
            </DataTooltip>
          </div>
        </div>

        <div className='mt-5 col-12 p-0'>
          {/* Country */}
          <div className='row m-0'>
            <div className="col-12 mb-2">
              <div className="row">
                <div className='p-0 targetMarketAddEditSubQuestion'>{textConstants.titleText.Country.toUpperCase()}</div>

                {this.props.viewModel.targetMarket.countryId === 0 &&
                  <div className='p-0 ml-5 m-auto'>
                    <div><Icon.AlertCircle />{textConstants.generalText.PleaseChooseCountry} </div>
                  </div>
                }
              </div>
            </div>

            <div className="col-12 p-0 questionTextBox">
              <DataTooltip
                type={"disabledTip"}
                hidden={!(this.props.viewModel.isEdit &&
                  this.props.viewModel.targetMarket.targetMarketStatusId !== this.props.viewModel.draftStatusId &&
                  this.props.viewModel.targetMarket.targetMarketStatusId !== this.props.viewModel.draftPreLaunchId)
                  || this.props.isNotEditable}
                parent={
                  <CustomDropDown
                    isDisabled={(this.props.viewModel.isEdit &&
                      this.props.viewModel.targetMarket.targetMarketStatusId !== this.props.viewModel.draftStatusId &&
                      this.props.viewModel.targetMarket.targetMarketStatusId !== this.props.viewModel.draftPreLaunchId)
                      || this.props.isNotEditable}
                    options={this.countries}
                    placeHolder={this.selectedCountry}
                    onChange={this.handleCountryChange}
                    variant="contained"
                    isReadOnly={this.props.isNotEditable}
                  />
                }>
                {"This field cannot be edited for active Target Markets."}
              </DataTooltip>

            </div>
          </div>

        </div>

        <div className="col-sm p-0">
          {this.props.viewModel.isComXUser &&
            <Neo.GridLayout md={1} className="mb-4">
              <h4 className='mb-2'>{textConstants.titleText.NewMarket}</h4>
            </Neo.GridLayout>
          }

          {this.props.viewModel.isComXUser &&
            <Neo.GridLayout md={3}>
              <div className="marginAuto">
                <label className={`${this.highlightMarketCriteria()} paddingRight`}> {textConstants.titleText.MarketCriteria} </label>
                <Neo.FormGroup
                  bind={this.props.viewModel.meta.isWhitelistTM}
                  className={`${this.highlightWhitelist()}`}
                  disabled={this.props.isNotEditable}
                  input={{ type: "switch" }}
                  onChange={() => {
                    this.props.viewModel.toggleTargetMarketType();
                    this.props.viewModel.isScreenDirty = true
                  }}
                />
              </div>
            </Neo.GridLayout>
          }
        </div>

        {/* Regions */}
        {this.condition1() &&
          <RegionsSection viewModel={this.props.viewModel} isNotEditable={this.props.isNotEditable} />
        }

        {this.condition2() &&
          <RegionsSection viewModel={this.props.viewModel} isNotEditable={this.props.isNotEditable} />
        }
      </div>
    )
  }
}
