import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';
import FollowUpTemplateCommentLookup from './FollowUpTemplateCommentLookup';

@NeoModel
export default class FollowUpTemplateLookup extends ModelBase {

    // Properties
    public followUpTemplateId: number = 0;

    public clientFollowUpTemplateId: number = 0

    @Attributes.Display("Name")
    public followUpTemplateName: string = "";

    public draftText: string = "";

    public messageText: string = "";

    public currentComment: string = "";

    public summary: string = "";

    public ordinal: number = 0;

    public comments: List<FollowUpTemplateCommentLookup> | null = null

    public clientCampaignMessageStatusId: number = 0

    public draftModifiedBy: string | null = ""

    public draftModifiedOn: string | null = ""

    public messageModifiedBy: string | null = ""
    
    public messageModifiedOn: string | null = ""

    public createdBy: string | null = ""

    // CampaignMessage only properties / methods
    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => this.draftMessageValidation(), textConstants.messageText.validationMessage.comxSuggestion);
    }

    public draftMessageValidation() {

        //Signature
        if (this.ordinal === 0 || this.ordinal === 1) {
            return this.draftText === null || this.draftText === '';
        }

        return false;
    }
}