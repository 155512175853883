import { List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import ManagementSubFunctionLocalisation from './ManagementSubFunctionLocalisation';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class ManagementSubFunction extends ModelBase {

    public managementSubFunctionId: number = 0;   

    public managementFunctionId: number = 0;

    public subFunctionName: string = "";

    public variation: string = "";

    public isExpanded: boolean = false

    public managementSubFunctionLocalisations = new List(ManagementSubFunctionLocalisation)

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.subFunctionName === "", textConstants.messageText.validationMessage.subFunction);
    }

    public toString(): string {
        if (this.isNew || !this.subFunctionName) {
            return "New Sub Function";
        } else {
            return this.subFunctionName;
        }
    }
}