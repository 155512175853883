import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class BatchReviewUploadLookup extends ModelBase {

    // Properties
    public batchReviewUploadId: number = 0;

    public targetMarketName: number = 0;

    public sequenceNumber: number = 0;

    public batchFileName: string = "";

    public dateUploaded: string = '';

    public dateReviewed: string = '';
}