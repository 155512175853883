import styled from "styled-components/macro";
import { IInputProps } from "./types";

export const StyledInputWrapper = styled.div<IInputProps>`
    display: block;
    width: 100%;
    height: 100%;

    label {
        font-size: 14px;
        color: #0B0B0C;
        margin-bottom: 8px;
    }
`;
export const StyledInput = styled.input<IInputProps>`
    ${({ fullWidth }) => fullWidth && 'width: 100%;'}
    ${({ multiline }) => multiline && 'height: 100%;'}
    ${({ rows }) => rows && `height: ${rows * 40}px;`}
    ${({ rowsMax }) => rowsMax && `height: ${rowsMax * 40}px;`}
    ${({ startIcon }) => startIcon && 'padding-left: 40px;'}
    ${({ endIcon }) => endIcon && 'padding-right: 40px;'}
    ${({ error }) => error && 'border: 1px solid #C23734; color: #C23734;'}
    border: none;
    padding: 8px 16px;
    background: #F8F8F8;
    border-radius: 8px;
    color: #0B0B0C;
    height: 50px;
`;

export const StyledFieldWrapper = styled.div<IInputProps>`
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    ${({ startIcon }) => startIcon && 'padding-left: 40px;'}
    ${({ endIcon }) => endIcon && 'padding-right: 40px;'}
`;

export const EndIcon = styled.div`
    position: absolute;
    right: 5%;
    top: 35%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 5px 0px;

    svg {
        margin-bottom: 0px;
    }
`;

export const StartIcon = styled.div`
    position: absolute;
    left: 5%;
    top: 35%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    cursor: pointer;
`;