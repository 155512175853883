import styled from "styled-components";

interface StyledCardWrapperProps {
    isMobile: boolean;
}

export const StyledCardWrapper = styled.div<StyledCardWrapperProps>`
  ${({ theme: { spacing }, isMobile }) => `
    display: flex;
    flex-direction: ${isMobile ? 'column' : 'row'};
    justify-content: space-between;
    padding: ${spacing(3)};
    width: 100%;
    height: 100%;
    gap: ${spacing(2)};
`}`;

export const StyledCardContent = styled.div`${({ theme: { spacing } }) => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: ${spacing(2)};
`}`;

export const StyledCardTitle = styled.h3`${({ theme: { colors } }) => `
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
    color: ${colors.altColor};
    padding-top: 0;
    display: flex;
    justify-content: space-between;

    span {
        font-weight: 500;
        font-size: 12px;
        color: ${colors.coldGrey[500]};
        text-decoration: underline;
    }
`}`;

export const StyledCardText = styled.p`${({ theme: { colors } }) => `
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    padding-top: 0;
    margin: 0;
    color: ${colors.coldGrey[400]};
`}`;

export const StyledWelcomeModalContent = styled.div`
    text-align: center;
    h2 {
        font-weight: 500;
        font-size: 40px;
        line-height: 46px;
        color: #0B0B0C;
    }

    p {
        color: #374242;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 28px */
    }

`;
export const StyledCardTextSpan = styled.span`${({ theme: { colors } }) => `
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    padding-top: 0;
    margin: 0;
    color: ${colors.coldGrey[400]};
`}`;
