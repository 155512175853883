import { ModelBase, NeoModel, Attributes } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class EmployeeSizeRange extends ModelBase {

  @Attributes.Display(textConstants.titleText.AddRange)
  public add: string = ""

  @Attributes.Display(textConstants.titleText.KeepRange)
  public keep: string = "";

  @Attributes.Display(textConstants.titleText.RemoveRange)
  public remove: string = "";
}