import { Attributes, List, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import { textConstants } from '../../../common/textConstants';
import MasterAccountLookup from '../../../Models/MasterAccounts/MasterAccountLookup';
import MasterAccountCriteria from '../../../Models/MasterAccounts/Query/MasterAccountCriteria';

@NeoModel
export default class DeleteMasterAccountCommand extends ModelBase {
   @Rules.Required()
   public masterAccountId: number = 0;

   public masterAccountLookups?= new List(MasterAccountLookup)

   public masterAccountCriteria?: MasterAccountCriteria = new (MasterAccountCriteria)

   public unselectedAccounts?= new List(MasterAccountLookup)

   public isSelectAll: boolean = false;

   @Rules.Required()
   @Attributes.Display(textConstants.titleText.InvalidReason)
   public invalidReasonId: number = 0;
}