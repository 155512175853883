import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import SubIndustry from '../Models/Maintenance/SubIndustry';

export interface ISubIndustriesApiClient extends Data.IUpdateableApiClient<SubIndustry, string> {

    // Client only properties / methods
}

@injectable()
export default class SubIndustriesApiClient extends Data.UpdateableApiClient<SubIndustry, string> implements ISubIndustriesApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/subIndustries`);
    }

    // Client only properties / methods
}