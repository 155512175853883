import { ModelBase, NeoModel, List } from '@singularsystems/neo-core';
import { any } from 'prop-types';
import ListSelectorModalSubListLookup from './ListSelectorModalSubListLookup';

@NeoModel
export default class ListSelectorModalLookup extends ModelBase {

    // Properties
    public itemId: number = 0;

    public itemName: string = "";

    public isSelected: Nullable<boolean> = false;

    public isPriority: Nullable<boolean> = false;

    public isExpanded: boolean = false;

    public isSelectedExpanded: boolean = false;

    // Child List
    public subList = new List(ListSelectorModalSubListLookup);
}