import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import CampaignMessageLookup from '../../Models/CampaignMessage/CampaignMessageLookup';
import { NotifyUtils } from '@singularsystems/neo-core';
import CopyToClipboard from 'react-copy-to-clipboard';
import CampaignMessageVM from './CampaignMessageVM';
import CampaignMessageCommentLookup from '../../Models/CampaignMessage/CampaignMessageCommentLookup';
import * as Icon from 'react-feather';
import { setUpdateText } from '../../common/utils';

interface ICampaignMessageCard {
  campaignMessageLookup: CampaignMessageLookup,
  isComXUser: boolean,
  parentViewModel: CampaignMessageVM,
}


@observer
export default class CampaignMessageCard extends React.Component<ICampaignMessageCard> {

  private showCopySuccess() {
    const variant = "success"
    NotifyUtils.add(variant, textConstants.messageText.saveMessage.TextToClipboard,
      variant as any, 5);
  }

  public render() {

    var viewModel = this.props.parentViewModel;

    return (
      <React.Fragment>
        {(this.props.campaignMessageLookup.isSubject) &&
          <h4 className='mt-4'>Subject</h4>
        }

        {(!this.props.campaignMessageLookup.isSubject) &&
          <div>
            <div className="row mt-4">
              <div className="col-2">
                <h4 className="ExpandHeaderText">Email {this.props.campaignMessageLookup.ordinal}</h4>
                {this.props.campaignMessageLookup.ordinal >= 2 &&
                  <>
                    <button className="ExpandCollapseBtn"
                      onClick={() => {

                        if (viewModel.isExpandedId === this.props.campaignMessageLookup.ordinal) {
                          viewModel.isExpandedId = -1
                        }
                        else {
                          viewModel.isExpandedId = this.props.campaignMessageLookup.ordinal;
                        }
                      }}>

                      {(viewModel.isExpandedId === this.props.campaignMessageLookup.ordinal) &&
                        <i>
                          <Icon.ChevronLeft size={24} />
                        </i>}

                      {(viewModel.isExpandedId  !== this.props.campaignMessageLookup.ordinal) &&
                        <i>
                          <Icon.ChevronDown size={24} />
                        </i>}

                    </button>
                  </>
                }


              </div>
            </div>
          </div>
        }

        {(this.props.campaignMessageLookup.ordinal === 1 || this.props.campaignMessageLookup.isSubject || (viewModel.isExpandedId === this.props.campaignMessageLookup.ordinal)) &&
          <>
            <Neo.GridLayout md={2}>

              <div className={(((this.props.parentViewModel.validationFirstCheckDone)
                && (this.props.campaignMessageLookup.isSubject
                  || (this.props.parentViewModel.firstMessageEmpty && this.props.campaignMessageLookup.ordinal === 1))
                && this.props.campaignMessageLookup.draftText.length === 0) ? "" : "") + "CustomInputLabel"}>
                Example of best practice
              </div>

              <div className={((this.props.parentViewModel.validationFirstCheckDone
                && (this.props.campaignMessageLookup.isSubject
                  || (this.props.parentViewModel.firstMessageEmpty && this.props.campaignMessageLookup.ordinal === 1))
                && (!this.props.isComXUser && this.props.campaignMessageLookup.messageText.length === 0)
                && this.props.parentViewModel.clientCampaignMessageId > 0) ? "" : "") + "CustomInputLabel"}>
                Customize your version
                {/*dead clipboard displayed*/}
                {(this.props.isComXUser && this.props.campaignMessageLookup.campaignMessageId === 0) &&
                  <i className="fa fa-clipboard noClick-placeClipboard"></i>
                }
                {/* clickable clipboard displayed */}
                {(this.props.isComXUser && this.props.campaignMessageLookup.campaignMessageId > 0) &&
                  <CopyToClipboard text={this.props.campaignMessageLookup.messageText} onCopy={() => this.showCopySuccess()}>
                    <i className="fa fa-clipboard placeClipboard"></i>
                  </CopyToClipboard>
                }
              </div>
            </Neo.GridLayout>

            <Neo.GridLayout md={2}>
              <div>
                {/* Example of best practice */}
                <Neo.Textbox
                  className={(((this.props.parentViewModel.validationFirstCheckDone)
                    && (this.props.campaignMessageLookup.isSubject
                      || (this.props.parentViewModel.firstMessageEmpty && this.props.campaignMessageLookup.ordinal === 1))
                    && this.props.campaignMessageLookup.draftText.length === 0) ?
                    "validation-border-red " : "") + "EmailTextArea " + (!this.props.isComXUser ? "yourEditsColumn": "comXEditsColumn")}
                  bind={this.props.campaignMessageLookup.meta.draftText}
                  input={this.props.campaignMessageLookup.isSubject ? { rows: 2 } : { rows: 20 }}
                  disabled={!this.props.isComXUser}
                  onInput={() => { this.props.parentViewModel.isScreenDirty = true }}
                  placeholder={"Not Used"}
                />

                {/* Updated By... */}
                {this.props.campaignMessageLookup.draftModifiedOn !== " @ " &&
                  this.props.campaignMessageLookup.draftModifiedOn !== "" &&
                  <p className={"mb-0 CustomUpdatedLabel"}>{setUpdateText(this.props.campaignMessageLookup.draftModifiedBy)}
                    {this.props.campaignMessageLookup.draftModifiedBy} on {this.props.campaignMessageLookup.draftModifiedOn}
                  </p>
                }
              </div>

              <div>
                {/* Customize your version */}
                <Neo.Textbox
                  className={((this.props.parentViewModel.validationFirstCheckDone
                    && (this.props.campaignMessageLookup.isSubject
                      || (this.props.parentViewModel.firstMessageEmpty && this.props.campaignMessageLookup.ordinal === 1))
                    && (!this.props.parentViewModel.isComXUser && this.props.campaignMessageLookup.messageText.length === 0)
                    && this.props.parentViewModel.clientCampaignMessageId > 0) ?
                    "validation-border-red " : " ") + "EmailTextArea comXEditsColumn"}
                  bind={this.props.campaignMessageLookup.meta.messageText}
                  input={this.props.campaignMessageLookup.isSubject ? { rows: 2 } : { rows: 20 }}
                  disabled={this.props.campaignMessageLookup.campaignMessageId === 0 || this.props.campaignMessageLookup.draftText === ""}
                  onInput={() => { this.props.parentViewModel.isScreenDirty = true }}
                  placeholder={"Not Used"}
                />
                {/* Updated By... */}
                {this.props.campaignMessageLookup.messageModifiedOn !== " @ " &&
                  this.props.campaignMessageLookup.messageModifiedOn !== "" &&
                  <p className={"mb-0 CustomUpdatedLabel"}> {setUpdateText(this.props.campaignMessageLookup.messageModifiedBy)}
                    {this.props.campaignMessageLookup.messageModifiedBy} on {this.props.campaignMessageLookup.messageModifiedOn}
                  </p>

                }
              </div>

              {/*Validation COMX user*/}
              <div>
                {this.props.parentViewModel.validationFirstCheckDone &&
                  this.props.campaignMessageLookup.ordinal === 1 &&
                  this.props.parentViewModel.firstMessageEmpty &&
                  this.props.campaignMessageLookup.draftText.length === 0 &&
                  <p className={"validationRed validationFontSize mb-0"}>Must have at least one message specified. </p>
                }
                {this.props.parentViewModel.validationFirstCheckDone &&
                  this.props.campaignMessageLookup.isSubject &&
                  this.props.campaignMessageLookup.draftText.length === 0 &&
                  <p className={"validationRed validationFontSize mb-0"}>Must specify the subject text</p>
                }
              </div>

              {/*Validation External user*/}
              <div>
                {this.props.parentViewModel.validationFirstCheckDone &&
                  this.props.campaignMessageLookup.ordinal === 1 &&
                  this.props.parentViewModel.firstMessageEmpty &&
                  (!this.props.isComXUser && this.props.campaignMessageLookup.messageText.length === 0) &&
                  this.props.parentViewModel.clientCampaignMessageId > 0 &&
                  <p className={"validationRed validationFontSize mb-0"}>Must have at least one message specified. </p>
                }
                {this.props.parentViewModel.validationFirstCheckDone &&
                  this.props.campaignMessageLookup.isSubject &&
                  (!this.props.isComXUser && this.props.campaignMessageLookup.messageText.length === 0) &&
                  this.props.parentViewModel.clientCampaignMessageId > 0 &&
                  <p className={"validationRed validationFontSize mb-0"}>Must specify the subject text</p>
                }
              </div>


            </Neo.GridLayout>
            <Neo.GridLayout md={2}>
              {/*New Comment*/}

              {/*div used to scroll to once a new comment is made*/}
              {(this.props.campaignMessageLookup.campaignMessageId > 0 && this.props.campaignMessageLookup.draftText  !== ""
                || (this.props.campaignMessageLookup.comments !== null && this.props.campaignMessageLookup.comments.length > 0)) &&
                <div>
                  <div className={"CustomInputLabel"}>
                    Write a comment
                  </div>
                  <Neo.FormGroup
                    className="newCommentStyling"
                    suppressLabel={true}
                    bind={this.props.campaignMessageLookup.meta.currentComment}
                    onKeyPress={() => this.props.parentViewModel.isCommentDirty = true}
                    input={{ rows: 2 }}
                    placeholder={"Type here"}
                    hidden={this.props.campaignMessageLookup.campaignMessageId === 0
                      || (this.props.campaignMessageLookup.draftText === ""
                        && (this.props.campaignMessageLookup.comments === null || this.props.campaignMessageLookup.comments.length === 0))}
                  />
                </div>
              }
            </Neo.GridLayout>

            {(this.props.campaignMessageLookup.campaignMessageId > 0 && this.props.campaignMessageLookup.draftText  !== ""
              || (this.props.campaignMessageLookup.comments !== null && this.props.campaignMessageLookup.comments.length > 0)) &&
              <Neo.Button
                className="btn btn-light" onClick={() => this.props.parentViewModel.SaveComment(this.props.campaignMessageLookup.campaignMessageId, this.props.campaignMessageLookup.currentComment)}
              >
                Comment
              </Neo.Button>
            }
            {/* Comments */}
            {this.props.campaignMessageLookup.campaignMessageId > 0 && this.props.campaignMessageLookup.comments !== null && this.props.campaignMessageLookup.comments.length  !== 0 &&
              <Neo.GridLayout md={2}>
                <div className="commentContainerStyle">
                  {
                    this.props.campaignMessageLookup.comments.map((comment: CampaignMessageCommentLookup, i) =>
                      <div className="commentValue" key={i}>
                        <p className="commentorTimeValue">
                          <span className={comment.isInternalUser ? "commentorInternalNameValue" : "commentorExternalNameValue"}>
                            {comment.commenterName}
                          </span> {comment.createdOn}

                          {/* Delete comment */}
                          {comment.isCurrentUser && <button className="commentDeleteBtn"
                            onClick={() => this.props.parentViewModel.AllowDeleteCheck(comment.campaignMessageCommentId, this.props.campaignMessageLookup.campaignMessageId)}>
                            <i><Icon.Trash2 size={16} /></i>
                          </button>}
                        </p>
                        <span className="commentText">
                          {comment.comment}
                        </span>
                      </div>
                    )
                  }
                </div>
              </Neo.GridLayout>
            }

          </>}
        {/*   </CollapsibleCard> */}
      </React.Fragment>
    );
  }
}