import React from "react";

const StepActive = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="16" height="16" rx="8" fill="#32914D" />
    <path
      d="M11.8777 5.83594L6.54773 11.1659L4.125 8.74321"
      stroke="#E9F2F7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StepActive;
