import { List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { ClientCampaignDataStatus } from '../Enums/ApplicationEnums'
import ClientCampaignWoodpeckerEmailLookup from './ClientCampaignWoodpeckerEmailLookup';

@NeoModel
export default class ClientCampaignDataLookup extends ModelBase {

    public clientId: number = 0

    public clientCampaignId: number = 0;

    public woodpeckerCampaignId: number = 0;

    public campaignName: string = "";

    public campaignStatus: string = "";

    public campaignStatusId: number = 0;

    public isOpenStatus: boolean = false;

    public repliedCount: number = 0;

    public deliveryCount: number = 0;

    public deliveryRate: number = 0;

    public openedRate: number = 0;

    public repliedRate: number = 0;

    public bouncedRate: number = 0;

    public bouncedCount: number = 0;

    public positivity: number = 0;

    public positiveCount: number = 0;

    public neutrality: number = 0;

    public neutralCount: number = 0;

    public openedCount: number = 0;

    public negativeCount: number = 0;

    public negativity: number = 0;

    public month: number = 0;

    public year: number = 0;

    public emailSender: string = "";

    public campaignDataStatus: ClientCampaignDataStatus = ClientCampaignDataStatus.Updating;

    public clientCampaignsWoodpeckerEmails: List<ClientCampaignWoodpeckerEmailLookup> = new List(ClientCampaignWoodpeckerEmailLookup);
}