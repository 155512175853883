import { ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';

@NeoModel
export default class cloneFollowUpTemplates extends ModelBase {
   
  // Properties
  @Rules.Required()
  public clientFollowUpTemplateId: number = 0;
  
  @Rules.Required()
  public clientId: number = 0;

  @Rules.StringLength(500)
  @Rules.Required("Client Follow Up Template Name is required.")
  public clientFollowUpTemplateName: string = "";

  public isComXUser : boolean = false
}