import { NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class ClientCampaignDataCriteria extends ValueObject {
    public clientId : number = 0;
    
    public clientCampaignId: number = 0;

    public woodpeckerCampaignId: number = 0;
    
    public campaignName: string = "";

    public campaignStatus: string = "";

    public campaignStatusId: number = 0;

    public filterStatusId: number = -1;

    public filterClientCampaignEmailId: number = -1;  

    public isRetrieveAll = true;

    public isForced = false

    public isOpenStatus: boolean = false;
}