import { NeoModel, List} from '@singularsystems/neo-core';
import AccountStatus from './../../Models/Maintenance/AccountStatus';
import { AppService, Types } from '../../Services/AppService';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';

@NeoModel
export default class AccountStatusVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private accountStatusesApiClient = AppService.get(Types.ApiClients.AccountStatusesApiClient)) {

        super(taskRunner);
    }

    // Properties
    public accountStatusList = new List(AccountStatus);

    public async initialise() {
        const response = await this.taskRunner.waitFor(this.accountStatusesApiClient.get());
        this.accountStatusList.set(response.data);
    }

    public saveAccountStatusList() {
        this.taskRunner.run(async () => {
            const response = await this.accountStatusesApiClient.saveList(this.accountStatusList.toJSArray());
            this.accountStatusList.update(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.AccountStatusesSaved);
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}