import styled from 'styled-components';


export const StyledQuestionaireContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 24px;
`;

export const StyledQuestionaireFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    width: 100%;
`;

export const StyledQuestionaireFooterContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
`;

export const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  margin-bottom: 32px;
  color: #0b0b0c;
`;
export const StyledLessonCard = styled.div`
    width: 100%;
    background: #F8F8F8;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 24px;
`;

export interface StyledLessonCardHeader {
    completed?: boolean;
}
export const StyledLessonCardHeader = styled("div") <StyledLessonCardHeader>`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: ${({ completed }) => (completed ? '24px' : '0px')};

`;
export const StyledLessonCardTitle = styled.div<StyledLessonCardHeader>`
    font-size: 18px;
    color: #0B0B0C;
    text-decoration: ${({ completed }) => (completed ? 'line-through' : 'none')};
`;

export interface StyledLessonCardDescription {
    isEmailSetup?: boolean;
}

export const StyledLessonCardDescription = styled("div") <StyledLessonCardDescription>`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #0B0B0C;
    margin-bottom: 24px;
    ${({ isEmailSetup }) => (isEmailSetup && `
    background-color: #f5ebd0;
    border-radius: 8px;
    padding: 16px;

    a {
        color: black;
        font-weight: bold;
    }   
    `)};
`;

export const StyledLessonLink = styled.a`
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #2B4B67;
`;
export const StyledLessonCardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
`;

export const StyledLine = styled.div`
    width: 100%;
    height: 1px;
    background: #EDEEEE;
`;

export const StyledVideoContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
`;

export const StyledVideoContent = styled.div`
    width: 60%;
`;