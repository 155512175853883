import { NeoModel, Data, List } from '@singularsystems/neo-core';
import { Managers, Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import CommandResult from '../../Models/InvitedUsers/Commands/CommandResult';
import { textConstants } from '../../common/textConstants';
import ClientLookup from '../../Models/Client/ClientLookup';
import { reaction } from 'mobx';
import ClientCampaignMessageLookup from '../../Models/CampaignMessage/ClientCampaignMessageLookup';
import CampaignMessageCriteria from '../../Models/CampaignMessage/Query/CampaignMessageCriteria';
import CampaignMessageStatus from '../../Models/Maintenance/CampaignMessageStatus';
import { UpdateCampaignMessageNameCommand } from '../../Models/CampaignMessage/Commands/UpdateCampaignNameCommand';
import CampaignMessageDeletionCommand from '../../Models/CampaignMessage/Commands/CampaignMessageDeletionCommand';
import UpdateCampaignMessageStatusCommand from '../../Models/CampaignMessage/Commands/UpdateCampaignMessageStatusCommand';
import CloneClientCampaignMessageCommand from '../../Models/CampaignMessage/Commands/CloneClientCampaignMessageCommand';
import TMLookup from '../../Models/CampaignMessage/TMLookup';
import OnboardingSideMenu from '../../Components/OnboardingSideMenu';
import InfoVideoVM from '../../Components/InfoVideoVM';
import UserGroupLookup from '../../Authorisation/Models/UserGroupLookup';
import FollowUpTemplatesVM from './FollowUpTemplatesVM';
import ClientNavigationButtonsVM from '../../Components/ClientNavigationButtonsVM';
import FollowUpTemplateCriteria from '../../Models/CampaignMessage/Query/FollowUpTemplateCriteria';
import SearchFieldVM from '../../Components/SearchFieldVM';
import AddEditFollowUpTemplateVM from './AddEditFollowUpTemplateVM';
import ClientICPLookup from 'Models/CampaignMessage/ClientICPLookup';
import CreateAICampaignMessageCommand from 'Models/CampaignMessage/Commands/CreateAICampaignMessage';
import MessageTemplate from 'Models/CampaignMessage/MessageTemplate';
import SaveQuestionnaireAnswersCommand from 'Models/IdealCustomerProfiles/Commands/SaveQuestionnaireAnswersCommand';
import OnboardingMaintenance from 'Models/Maintenance/OnboardingMaintenance';
import OnboardingMeetingLookup from 'Models/Onboarding/OnboardingMeetingLookup';
import { OnboardingSteps } from 'Models/Enums/ApplicationEnums';

@NeoModel
export default class ClientCampaignMessageVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private campaignMessagesApiClient = AppService.get(Types.ApiClients.CampaignMessagesApiClient),
    private clientApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
    private campaignMessageStatusesApiClient = AppService.get(Types.ApiClients.CampaignMessageStatusesApiClient),
    private authService = AppService.get(Types.Neo.Security.AuthenticationService),
    private invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient),
    public authenticationService = AppService.get(Types.Security.CustomAuthenticationService),
    private onboardingMaintenanceApiClient = AppService.get(Types.ApiClients.OnboardingMaintenanceApiClient),
    private appDataCache = AppService.get(Types.Services.AppDataCache),
    private comXHub = AppService.get(Types.ApiClients.ComXHub),
    private customerProfilesApiClient = AppService.get(Types.ApiClients.CustomerProfilesApiClient)
  ) {
    super(taskRunner);
    this.connectToComXHub();
  }

  // Properties
  public searchCriteria: CampaignMessageCriteria = new CampaignMessageCriteria();
  public followUpTemplateSearchCriteria: FollowUpTemplateCriteria = new FollowUpTemplateCriteria();
  public client = new ClientLookup();
  public clientName: string = "";
  public showSignatureCloneModal: boolean = false;
  public showCampaignMessagesCloneModal: boolean = false;

  public comXConfig: any = this.appDataCache.comXConfigurations.get().data

  public previousGridPageNumber: number = 1;

  public showCloneTargetMarketError: boolean = false;
  public clientId: number = 0;
  public showDeleteModal: boolean = false;
  public showUndoDeleteModal: boolean = false;
  public showWhitelistModal: boolean = false;
  public selectedCampaignMessageName: string = "";

  public selectedTargetMarkets: List<TMLookup> | null = null
  public targetMarketOptions: any[] = []
  public allTargetMarkets: List<TMLookup> = new List(TMLookup);

  public navigateTo: string = "";
  public videoURL: string = "";
  public followUpTemplatesVM: FollowUpTemplatesVM = new FollowUpTemplatesVM();
  public addEditFollowUpTemplateVM: AddEditFollowUpTemplateVM = new AddEditFollowUpTemplateVM();

  public showForbiddenModal: boolean = false;

  public isComXUser: boolean = false

  public showSpinner: boolean | undefined = true;

  public isArchived: boolean = false;
  public previousName: string = "";

  public campaignMessageStatusList = new List(CampaignMessageStatus);
  public userGroups = new List(UserGroupLookup);

  public displayOverlay: boolean = false;

  public hideGrid: boolean = false;

  public isComingFromFollowUpTemplates: boolean = false;
  public draftStatusId: number | undefined = 0;
  public activeStatusId: number | undefined = 0;
  public clientReviewStatusId: number | undefined = 0;
  public onboardingProps: any;
  public tabManager = new Managers.TabManager(textConstants.titleText.CampaignMessage);
  public selectedTab: string = ""
  public campaignMessageTabSelect: boolean = true;
  public clientNavigationButtons: ClientNavigationButtonsVM = new ClientNavigationButtonsVM;
  public clientICPList = new List(ClientICPLookup)
  public showAIModal: boolean = false
  public templateList = new List(MessageTemplate)
  public onboardingMaintenanceList = new List(OnboardingMaintenance);
  public onboardingCampaignIsComing: OnboardingMaintenance = new OnboardingMaintenance

  // Commands
  public cloneCampaignMessageCommand = new CloneClientCampaignMessageCommand();
  public campaignMessageDeletionCommand = new CampaignMessageDeletionCommand();
  public updateCampaignMessageStatusCommand = new UpdateCampaignMessageStatusCommand();
  public createAICampaignMessageCommand = new CreateAICampaignMessageCommand();

  // Edit Campaign Message Name
  public showCampaignNameUpdate: boolean = false
  public UpdateCampaignMessageNameCommand = new UpdateCampaignMessageNameCommand();

  public infoVideoVM = new InfoVideoVM();

  public searchFieldVM = new SearchFieldVM();

  public isActiveNameEditCampaignId: number = 0

  // Pagination
  public pageManager = new Data.PageManager(this.searchCriteria, ClientCampaignMessageLookup, this.campaignMessagesApiClient.getCampaignMessagesPaged,
    {
      pageSize: 20,
      sortBy: "sequenceNumber" || "campaignName",
      fetchInitial: false
    }
  )

  public async initialise() {
    const response = await this.taskRunner.waitFor(this.campaignMessageStatusesApiClient.get());
    this.campaignMessageStatusList.set(response.data);
  }

  public async campaignMessageComing() {
    const onboardingMaintenanceResponse = await this.taskRunner.waitFor(this.onboardingMaintenanceApiClient.get());
    this.onboardingMaintenanceList.set(onboardingMaintenanceResponse.data);
    let onboardingMaintenanceItem = this.onboardingMaintenanceList.find(maintenanceItem => maintenanceItem.pageCode === "CMComingOverview")

    if (onboardingMaintenanceItem) {
      this.onboardingCampaignIsComing = onboardingMaintenanceItem
    }
  }

  public preUpdate = (clientId: number) => {
    this.updateCampaignMessages(clientId)
  }

  public connectToComXHub() {
    this.comXHub.RefreshClientCampaignMessages.subscribe(this.preUpdate);
  }

  public updateCampaignMessages(clientId: number) {
    if (clientId === this.searchCriteria.clientId) {
      this.refreshPageManagerWithoutLoadingBar();
    }
  }

  // Methods
  public openDeleteModal(campaignMessage: ClientCampaignMessageLookup) {
    this.campaignMessageDeletionCommand.clientCampaignMessageId = campaignMessage.clientCampaignMessageId;
    // Show the modal
    this.showDeleteModal = true;
    this.selectedCampaignMessageName = campaignMessage.campaignName;
  }


  public deleteClientCampaignMessage() {
    this.showDeleteModal = false;

    this.taskRunner.run(async () => {
      const response = await this.campaignMessagesApiClient.deleteClientCampaignMessage(this.campaignMessageDeletionCommand);

      const cr: CommandResult = response.data as CommandResult;
      if (cr.success) {
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.CampaignMessageArchived);

        // Mark the Onboarding step as incomplete if the last campaign message is being deleted
        if (this.authenticationService.globalProps.isOnboarding && this.pageManager.data.length === 1) {
          this.authenticationService.globalProps.onboardingSteps.find(step => step.stepNumber === OnboardingSteps.CampaignMessages)!.isComplete = false
        }

        this.pageManagerRefresh();
      }
      else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
      }

      this.updateOnboarding()

    });
  }

  public updateOnboarding() {
    if (this.authenticationService.globalProps.isOnboarding) {
      var onboardingMenu = new OnboardingSideMenu(this.onboardingProps)
      onboardingMenu.checkCanGoNext()
    }
  }

  public openCloneModal(clientCampaignMessageId: number, campaignMessageType: string) {
    this.cloneCampaignMessageCommand = new CloneClientCampaignMessageCommand();
    this.cloneCampaignMessageCommand.clientCampaignMessageId = clientCampaignMessageId;

    if (campaignMessageType.toLocaleLowerCase().includes("signature")) {
      this.showSignatureCloneModal = true
    }
    else {
      this.setupNewTargetMarketList();
      this.showCampaignMessagesCloneModal = true
    }
  }

  public cloneCampaignMessageOrEmailSignature(isEmailSignature: boolean = false) {
    if (isEmailSignature) {
      this.showCloneTargetMarketError = false
      this.showSignatureCloneModal = false;

      this.cloneCampaignMessageCommand.isEmailSignature = true
    }
    else {
      if (this.selectedTargetMarkets === null || this.selectedTargetMarkets.length === 0) {
        this.showCloneTargetMarketError = true
      }
      else {
        this.showCampaignMessagesCloneModal = false;
        this.showCloneTargetMarketError = false
        this.cloneCampaignMessageCommand.targetMarkets = this.selectedTargetMarkets
        this.selectedTargetMarkets = new List(TMLookup)
      }
    }

    this.cloneCampaignMessageCommand.isComXUser = this.isComXUser

    if (!this.showCloneTargetMarketError) {
      this.taskRunner.run(async () => {
        const response = await this.campaignMessagesApiClient.cloneCampaignMessageOrEmailSignature(this.cloneCampaignMessageCommand);
        const cr: CommandResult = response.data.data as CommandResult;
        if (cr.success) {
          this.notifications.addSuccess(textConstants.titleText.Saved, isEmailSignature ? textConstants.messageText.saveMessage.EmailSignatureCloneSuccessful : textConstants.messageText.saveMessage.CampaignMessageCloneSuccessful);
          this.pageManagerRefresh();
        } else {
          this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
        }
      })
    }
  }

  public getMessageStyleClass(statusId: number | undefined) {
    // status will be in client review for an external user but will display as draft 
    if (!this.isComXUser && statusId === this.clientReviewStatusId) {
      statusId = this.draftStatusId;
    }

    switch (statusId) {
      case this.draftStatusId:
        return "CampainMessageStatusColumnDraft";

      case this.activeStatusId:
        return "CampainMessageStatusColumnActive";

      case this.clientReviewStatusId:
        return "CampainMessageStatusColumnClientReview";

      default:
        return "CampainMessageStatusColumnDraft";
    }

  }

  public setTargetMarketList() {
    this.targetMarketOptions = []

    this.allTargetMarkets.forEach(tm => {
      if (tm.targetMarketName !== "") {
        const targetMarketItem = {
          value: tm.targetMarketId.toString(),
          label: tm.targetMarketName,
        }

        // Check for already selected options Dont push to dropdown
        if (this.selectedTargetMarkets === null
          || this.selectedTargetMarkets === undefined
          || !this.selectedTargetMarkets.find(f => f.targetMarketId.toString() === targetMarketItem.value)) {
          this.targetMarketOptions.push(targetMarketItem);
        }
      }
    });
  }

  public async setupNewTargetMarketList() {
    let response = await this.taskRunner.waitFor(
      this.campaignMessagesApiClient.getClientTargetMarkets(this.clientId)
    );

    if (response) {
      this.allTargetMarkets.set(response.data);

      this.setTargetMarketList();
    }
  }

  public async fetchData(clientId: number) {
    if (clientId > 0) {

      this.searchCriteria.clientId = clientId;
      this.searchCriteria.isDeleted = this.isArchived;

      const isClientResponse = await this.invitedUsersApiClient.isClientUser(this.authService!.user!.userName);
      this.isComXUser = !isClientResponse.data

      reaction(() => this.searchCriteria.isDeleted, () => this.pageManagerRefresh(), { fireImmediately: false })

      // Load client details 
      const response = await this.clientApiClient.getClient(clientId);

      if (this.isComingFromFollowUpTemplates) {
        await this.toggleTab("", textConstants.titleText.FollowUpTemplates, clientId)
      } else {
        await this.toggleTab("", textConstants.titleText.CampaignMessage, clientId)
      }

      const cmStatusResponse = await this.taskRunner.waitFor(this.campaignMessageStatusesApiClient.get());
      this.campaignMessageStatusList.set(cmStatusResponse.data);
      this.draftStatusId = this.campaignMessageStatusList.find(cm => cm.uniqueTableKey === "CampaignMessageStatus1")?.campaignMessageStatusId
      this.activeStatusId = this.campaignMessageStatusList.find(cm => cm.uniqueTableKey === "CampaignMessageStatus2")?.campaignMessageStatusId
      this.clientReviewStatusId = this.campaignMessageStatusList.find(cm => cm.uniqueTableKey === "CampaignMessageStatus3")?.campaignMessageStatusId
      this.client.set(response.data);


      if (this.comXConfig) {
        this.videoURL = this.comXConfig.find((config: any) => config.configCode === "CampaignMessageComingVideo")?.value as string
      }
      this.infoVideoVM.header = textConstants.generalText.CreateCampaignMessage;
      this.infoVideoVM.content = textConstants.generalText.CreateCampaignMessageText;
      this.infoVideoVM.url = this.videoURL;

      this.clientNavigationButtons.clientName = this.client.clientName
      this.clientNavigationButtons.navigateTo = this.navigateTo
      this.clientNavigationButtons.isCampaignMessages = true

      await this.getClientICPList(clientId)
      await this.populateTemplateList()

      if (this.authenticationService.globalProps.isOnboarding) {
        await this.campaignMessageComing()
      }
    }
  }

  public updateCampaignMessageStatus(clientCampaignMessageId: number, CampaignMessageStatusId: number) {

    this.updateCampaignMessageStatusCommand.clientCampaignMessageId = clientCampaignMessageId;
    this.updateCampaignMessageStatusCommand.campaignMessageStatusId = CampaignMessageStatusId;

    this.taskRunner.run(async () => {
      const response = await this.campaignMessagesApiClient.updateClientCampaignMessageStatus(this.updateCampaignMessageStatusCommand);
      const cr: CommandResult = response.data as CommandResult;
      if (cr.success) {
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.CampaignMessageStatus);
        this.pageManager.refreshData();
      } else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
      }
    });

  }

  public getStatusColor(status: number) {
    switch (status) {
      case 1: {
        return "#808080"
      }
      case 2: {
        return "#8cb08c"
      }
      default: {
        return "#000000"
      }
    }
  }

  public getTypeColor(status: number) {
    switch (status) {
      case 1: {
        return "#8cb08c"
      }
      case 2: {
        return "#ff891f"
      }
      default: {
        return "#000000"
      }
    }
  }

  public async openUndoCampaignMessageDeleteModal(campaignMessage: ClientCampaignMessageLookup) {
    this.campaignMessageDeletionCommand = new CampaignMessageDeletionCommand();
    this.campaignMessageDeletionCommand.clientCampaignMessageId = campaignMessage.clientCampaignMessageId;
    this.showUndoDeleteModal = true;
    this.selectedCampaignMessageName = campaignMessage.campaignName;
  }

  public navigateToEditClient: () => void = () => { };

  public async undoCampaignMessageDelete() {
    this.taskRunner.run(async () => {
      this.showUndoDeleteModal = false;

      const response = await this.campaignMessagesApiClient.undeleteClientCampaignMessage(this.campaignMessageDeletionCommand);
      const result = response.data

      if (result.success) {
        //  Refresh
        this.searchCriteria.isDeleted = true;
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.CampaignMessageUnarchived);
        this.pageManagerRefresh();
      }
      else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, 'Failed to undo Campaign Message delete.');
      }

      this.updateOnboarding()
    })
  }

  public async refreshPageManagerWithoutLoadingBar() {

    let isPopulated: boolean = false;

    let response = await this.campaignMessagesApiClient.clientHasAnyCampaignMessages(this.clientId)

    if (response) {
      isPopulated = response.data;
    }

    this.displayOverlay = false;
    await this.pageManager.refreshData(undefined).then(() => {
      this.searchFieldVM.hideNoResultDialog = false;

      this.displayOverlay = this.pageManager.totalRecords === 0 &&
        !this.isArchived && !isPopulated
    });

    this.pageManager.pageNo = this.previousGridPageNumber
  }

  public async pageManagerRefresh() {

    this.hideGrid = true;

    // Hide the overlay as it can only be displayed once we have determined 
    // that the are no current or archived campaign messages.
    let isPopulated: boolean = false;

    let response = await this.taskRunner.waitFor(
      this.campaignMessagesApiClient.clientHasAnyCampaignMessages(this.clientId)
    );

    if (response) {
      isPopulated = response.data;
    }

    this.displayOverlay = false;
    await this.pageManager.refreshData(undefined, this.taskRunner).then(() => {
      this.hideGrid = false;
      this.searchFieldVM.hideNoResultDialog = false;

      this.displayOverlay = this.pageManager.totalRecords === 0 &&
        !this.isArchived && !isPopulated
    });

    this.pageManager.pageNo = this.previousGridPageNumber
  }

  public async updateCampaignMessageName(campaignMessageLookup: ClientCampaignMessageLookup) {
    this.UpdateCampaignMessageNameCommand.clientCampaignMessageId = campaignMessageLookup.clientCampaignMessageId
    this.UpdateCampaignMessageNameCommand.campaignName = campaignMessageLookup.campaignName

    this.taskRunner.run(async () => {
      const response = await this.campaignMessagesApiClient.updateCampaignName(this.UpdateCampaignMessageNameCommand);

      const result: CommandResult = response.data as CommandResult;

      if (result.success) {
        this.showCampaignNameUpdate = false;
        this.pageManager.refreshData()
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.CampaignMessageNameUpdated);
      }
      else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, result.error);
      }
    })
  }

  public isNameEditing(campaignId: number) {
    return this.isActiveNameEditCampaignId === campaignId;
  }

  public isNameEditable(campaign: ClientCampaignMessageLookup) {
    if (this.isComXUser) {
      return true;
    }

    let activeStatus = this.campaignMessageStatusList.find(f => f.uniqueTableKey === textConstants.CampaignMessageStatusKeys.ActiveStatus);

    if (activeStatus) {
      return activeStatus.campaignMessageStatusId !== campaign.campaignMessageStatusId
    }

    return true;
  }

  public toggleNameEdit(campaignId: number) {
    return this.isActiveNameEditCampaignId = campaignId;
  }

  public async toggleTab(
    old_selectedTab: string,
    new_selectedTab: any,
    clientID: number
  ) {
    const selectedValue = String(new_selectedTab).toLocaleUpperCase();
    if (clientID > 0) {
      // Campaign Messages Tab
      if (selectedValue === textConstants.titleText.CampaignMessage.toLocaleUpperCase()) {
        this.pageManagerRefresh();
        this.tabManager.selectedTab = textConstants.titleText.CampaignMessage
      }

      // Follow Up Templates Tab
      if (selectedValue === textConstants.titleText.FollowUpTemplates.toLocaleUpperCase()) {
        this.tabManager.selectedTab = textConstants.titleText.FollowUpTemplates
        await this.followUpTemplatesVM.fetchData(clientID);
        this.followUpTemplatesVM.pageManagerRefresh();
      }
    }
  }

  private async getClientICPList(clientID: number) {
    let icpListResponse = await this.customerProfilesApiClient.getClientICPList(clientID)
    this.clientICPList.set(icpListResponse.data)
  }

  private async populateTemplateList() {
    let templates: string[] = ["A", "B", "C", "D", "E", "F", "G"];

    for (let i = 0; i < templates.length; i++) {
      let listItem = new MessageTemplate()
      listItem.templateNameId = i + 1
      listItem.templateName = templates[i]
      this.templateList.push(listItem)
    }
  }

  public openAIModal() {
    this.createAICampaignMessageCommand.customerProfileId = 0
    this.createAICampaignMessageCommand.templateNameId = 0
    this.showAIModal = true
  }

  public async generateCampaignMessageViaAIAsync() {
    let templateName = this.templateList.find(t => t.templateNameId === this.createAICampaignMessageCommand.templateNameId)!.templateName
    let command = new SaveQuestionnaireAnswersCommand()
    command.customerProfileId = this.createAICampaignMessageCommand.customerProfileId
    command.variations.push(templateName)

    this.taskRunner.run(async () => {
      let result = await this.customerProfilesApiClient.generateCampaignMessageViaAIAsync(command);

      if (result.data.success) {
        this.showAIModal = false
      }
    })
  }
}