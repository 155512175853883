import {
    NeoModel,
    List,
    ModelBase,
    Attributes,
    Model,
} from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../Services/AppService";
import TargetMarketSubIndustry from "../../Models/TargetMarketSubIndustries/TargetMarketSubIndustry";
import TargetMarket from "../../Models/TargetMarket/TargetMarket";
import TargetMarketSaveCommand from "../../Models/TargetMarket/Commands/TargetMarketSaveCommand";
import { textConstants } from "../../common/textConstants";
import TargetMarketFunction from "../../Models/Maintenance/TargetMarketFunction";
import TargetMarketIndustryLookup from "../../Models/TargetMarket/TargetMarketIndustryLookup";
import TargetMarketRoleLookup from "../../Models/TargetMarket/TargetMarketRoleLookup";
import TargetMarketRole from "../../Models/TargetMarket/TargetMarketRole";
import TargetMarketMiniLookup from "../../Models/TargetMarket/TargetMarketMiniLookup";
import SubIndustryLookup from "../../Models/TargetMarket/SubIndustryLookup";
import TargetMarketSummary from "./../../Models/TargetMarketAccounts/TargetMarketSummary";
import TargetMarketStatus from "../../Models/Maintenance/TargetMarketStatus";
import TargetMarketType from "../../Models/Maintenance/TargetMarketType";
import { base64toBlob, getCurrentDateTime } from "../../common/utils";
import TargetMarketManagementRolesLookup from "../../Models/TargetMarket/TargetMarketManagementRolesLookup";
import TargetMarketManagementFunctionLookup from "../../Models/TargetMarket/TargetMarketManagementFunctionLookup";
import TargetMarketManagementFunction from "../../Models/Maintenance/TargetMarketManagementFunction";
import TargetMarketManagementRole from "../../Models/Maintenance/TargetMarketManagementRole";
import TargetMarketManagementSubFunctionsLookup from "../../Models/TargetMarket/TargetMarketManagementSubFunctionsLookup";
import TargetMarketRegionLookup from "../../Models/TargetMarket/TargetMarketRegionLookup";
import SubRegionLookup from "../../Models/TargetMarket/SubRegionLookup";
import TargetMarketSubRegion from "../../Models/TargetMarket/TargetMarketSubRegion";
import InformationManagementDetail from "../../Models/Maintenance/InformationManagementDetail";
import TargetMarketSpecificRole from "../../Models/TargetMarket/TargetMarketSpecificRole";
import ClientSettingsVM from "../Client/ClientSettingsVM";
import React, { RefObject } from "react";
import ManagementRole from "../../Models/Maintenance/ManagementRole";
import InfoVideoVM from "../../Components/InfoVideoVM";
import { triggerHotjarEvent } from "../../Components/Hotjar";
import ListSelectorModalLookup from "Components/Modals/ListSelectorModalLookup";
import ListSelectorModalSubListLookup from "Components/Modals/ListSelectorModalSubListLookup";
import NavigationStep from "Models/Navigation/NavigationStep";
import NavigatorStep from "Models/Navigation/NavigatorStep";
import { SectionCode } from "Models/Maintenance/SectionCode";
import TargetMarketCommandResult from "Models/TargetMarket/Commands/TargetMarketCommandResult";
import ClientTMChanges from "Models/TargetMarket/ClientTMChanges";
import {
    mapSelectedValuesToExistingModel,
} from "Components/OnboardingComponents/ListSelectorHelper";
import OnboardingFunctions from "Models/Onboarding/OnboardingFunctions";

@NeoModel
class SavedRoles extends ModelBase {
    // Properties
    public value: string = "";
    public label: string = "";
}

@NeoModel
export default class AddEditTargetMarketVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(
            Types.Neo.UI.GlobalNotifications
        ),
        private targetMarketsApiClient = AppService.get(
            Types.ApiClients.TargetMarketsApiClient
        ),
        private targetMarketAccountsApiClient = AppService.get(
            Types.ApiClients.TargetMarketAccountsApiClient
        ),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        private rolesApiClient = AppService.get(
            Types.ApiClients.RolesApiClient
        ),
        private authService = AppService.get(
            Types.Neo.Security.AuthenticationService
        ),
        private invitedUsersApiClient = AppService.get(
            Types.ApiClients.InvitedUsersApiClient
        ),
        private comXConfigApiClient = AppService.get(
            Types.ApiClients.ComXConfigurationsApiClient
        ),
        private informationManagementApiClient = AppService.get(
            Types.ApiClients.InformationManagementApiClient
        ),
        private managementRolesApiClient = AppService.get(
            Types.ApiClients.ManagementRolesApiClient
        ),
        public customAuthService = AppService.get(
            Types.Security.CustomAuthenticationService
        )
    ) {
        super(taskRunner);
    }

    displayName: string = "";
    back: () => void = () => { };

    public excludedKeywordStringValues: string[] = [];

    // Parent Object
    public targetMarket = new TargetMarket();
    public targetMarketMini = new TargetMarketMiniLookup();

    // Child objects
    public targetMarketSubIndustries = new List(TargetMarketSubIndustry);
    public targetMarketFunctions = new List(TargetMarketFunction);
    public oldTargetMarketRoles = new List(TargetMarketRole);
    public savedTargetMarketRoles = new List(TargetMarketRoleLookup);
    public onScreenTargetMarketRoles = new List(TargetMarketRole);
    public onScreenTargetMarketSpecificRoles = new List(
        TargetMarketSpecificRole
    );
    public TargetMarketSpecificRoles = new List(TargetMarketSpecificRole);
    public clientSettingsViewModel = new ClientSettingsVM();

    // Properties
    public targetMarketId: number = 0;
    public clientId: number = 0;
    public columnHeadings: string[] = [
        "- Fixed Name",
        "- Original Name - ",
        "Location - ",
        "Domain",
    ];
    public isMarketCriteria: boolean = true;
    public targetMarketSummary: TargetMarketSummary = new TargetMarketSummary();
    public targetMarketName: string = "";
    public goBackUrl: string = "";
    public canViewFunctions: boolean = false;
    public canViewRegions: boolean = false;
    public isSaving: boolean = false;
    public hasSentTMChangeZap: boolean = false;
    public informationManagementDetailsElement =
        new InformationManagementDetail();
    public isVideoExpand: boolean = false;
    public isSaveClicked: boolean = false;
    public isAdvancedExpanded: boolean = false;
    public isRegionsExpanded: boolean = false;

    // Roles which the user can pick from
    public roleListOptions: any[] = [];
    public emptyList: any[] = [];

    // User-selected and created roles as seen on screen
    public savedListOptions: { value: string; label: string }[] = [];
    public specificRoleSavedList: { value: string; label: string }[] = [];

    public allowSubIndustryPriorityChange: boolean = false;
    public isEdit: boolean = false;
    public pageTitle: string = "";
    public showSaveModal: boolean = false;
    public fileList: any;
    public showRoles: boolean = false;
    public saveWhitelist: boolean = false;
    public fileName: string = "none";
    public clientName: string = "";
    public targetMarketStatuses: List<TargetMarketStatus> = new List(
        TargetMarketStatus
    );
    public draftStatusId: number = 0;
    public draftPreLaunchId: number = 0;
    public targetMarketTypes: List<TargetMarketType> = new List(
        TargetMarketType
    );
    public marketCriteriaTypeId: number = 0;
    public whitelistTypeId: number = 0;
    public initilLoadComplete: boolean = false;
    public showInvalidDataModal: boolean = false;
    public showTargetMarketTypeModal: boolean = false;
    public previousEmployeeMinSizeId: number | null = null;
    public previousEmployeeMaxSizeId: number | null = null;
    public invalidDataMessage: string = "";
    public countries = this.appDataCache.countries.get().data.sortBy("countryName");
    public iroEditStatuses = this.appDataCache.iroEditStatuses.get().data;
    public displayAddEditGrid: boolean = false;
    public excludedKeywords = this.appDataCache.excludedKeywords.get().data;
    public germanyId: number = 0;
    public informationManagementDetails = new List(InformationManagementDetail);
    public isFirstFetch: boolean = false;
    public isScreenDirty: boolean = false;
    public infoVideoVM = new InfoVideoVM();
    public videoURL: string = "";

    //List Modal Properties
    public modalList = new List(ListSelectorModalLookup);
    public selectedModalList = new List(ListSelectorModalLookup);
    public openTMListModal: boolean = false;

    public showBackButton: boolean = true;

    public clientTMChanges: ClientTMChanges = new ClientTMChanges();

    public tmCommandResult: TargetMarketCommandResult =
        new TargetMarketCommandResult();

    // Properties
    public RolesAndSubRolesList = new List(ManagementRole);

    @Attributes.Display(textConstants.titleText.OnlyDoWhitelist)
    public isWhitelistTM: boolean = false;

    // Lookups
    public industryList = new List(TargetMarketIndustryLookup);
    public regionList = new List(TargetMarketRegionLookup);

    // Contains all C-Level Roles (selected and unselected)
    public managementRoleList = new List(TargetMarketManagementRolesLookup);

    public managementFunctionList = new List(TargetMarketManagementFunctionLookup);

    public roleList = new List(TargetMarketRoleLookup);
    public isComXUser: boolean = false;
    public tmChanges: ClientTMChanges = new ClientTMChanges();

    // Navigation
    public allTMNavigationSteps = new List(NavigationStep);
    public relevantNavigationSteps: NavigatorStep[] = [];
    public sectionCode = SectionCode.TM1;

    public firstStepNumber = this.convertSectionCodeToNumber(SectionCode.TM1);
    public currentSection = 1;
    public lastSection = 5;
    public fourthStepNumber = this.convertSectionCodeToNumber(SectionCode.TM4);

    public dictPairResult: Map<number, Map<number, number>> = new Map();

    public potentialAccounts: number | undefined = undefined
    public potentialAccountsOnModal: number | undefined = undefined

    public isValidSave: boolean = true;
    public showAddEditTM: boolean = false;
    public areRegionsLoading: boolean = false;
    public targetMarketInitiallyComplete = false;
    public hasCLevelRoles: boolean = false
    public hasFunctions: boolean = false
    public tmCountryChanged: boolean = false;
    public isPriority: boolean = false;

    public listSelectedItemsIndustries: List<ListSelectorModalLookup> =
        new List(ListSelectorModalLookup);
    public listSelectedItemsCLevelRoles: List<ListSelectorModalLookup> =
        new List(ListSelectorModalLookup);
    public listSelectedItemsManagementRoles: List<ListSelectorModalLookup> =
        new List(ListSelectorModalLookup);

    public listSelectorClonedList = new List(ListSelectorModalLookup);

    public convertSectionCodeToNumber(code: string) {
        const lastChar = code.charAt(code.length - 1);
        
        if (!isNaN(Number(lastChar))) {
            return Number(lastChar)
        }

        return 0
    }

    // Methods
    public async initialise() {
        const isClientResponse = await this.invitedUsersApiClient.isClientUser(
            this.authService!.user!.userName
        );
        this.isComXUser = !isClientResponse.data;

        const navigationSteps = await (
            await this.appDataCache.navigationSteps.getDataAsync()
        ).filter(
            (ns) =>
                ns.systemArea === textConstants.generalText.TargetMarketsSpaced
        );
        this.allTMNavigationSteps.set(navigationSteps);

        if (this.targetMarketId > 0) {
            // Edit
            await this.fetchTargetMarket(this.targetMarketId);
        } // Add
        else {
            await this.setupNewTargetMarket();
            if (this.isComXUser) {
                this.lastSection = 6
            }
        }

        //excluded keywords list
        this.initialiseManagementRoles();
        this.initialiseInformationManagement();
        this.isOnboardingTM();
    }

    public async filterNavigationSteps() {
        this.relevantNavigationSteps = []

        if (!this.isComXUser) {
            // Make list for External users
            let filteredSteps = this.allTMNavigationSteps.filter(s => s.isInternalOnly === false) as List<NavigationStep>

            if (this.isWhitelistTM) {
                filteredSteps = filteredSteps.filter(step => step.stepNumber !== this.convertSectionCodeToNumber(SectionCode.TM2)) as List<NavigationStep>
            }

            this.populateNavigator(filteredSteps);
        }
        else {
            if (this.isWhitelistTM) {
                // Make whitelist list for Internal users
                let filteredSteps = this.allTMNavigationSteps.filter(s => s.isInternalOnly || (s.stepNumber >= 3 && s.stepNumber <= 4)) as List<NavigationStep>
                this.populateNavigator(filteredSteps);
            }
            else {
                // Make market criteria list for Internal users
                let filteredSteps = this.allTMNavigationSteps.filter(s => (s.currentStep.indexOf("WL") < 0 && s.nextStep?.indexOf("WL") < 0) || s.stepNumber === 6) as List<NavigationStep>
                this.populateNavigator(filteredSteps);
            }
        }
    }

    private async StepIsCompleteOnboarding() {
        if (this.customAuthService.globalProps.isOnboarding) {
            await this.customAuthService.onboardingFunctions.updateOnboardingStep();
        }
    }

    private populateNavigator(filteredSteps: List<NavigationStep>) {
        if (this.targetMarket.isComplete && this.targetMarketInitiallyComplete) {
            let newStep: NavigatorStep = {
                id: 0,
                stepNumber: 0,
                stepText: `<h5>${textConstants.titleText.TargetMarketOverview}</h5>`,
                isComplete: true,
            };

            this.relevantNavigationSteps.push(newStep);
        }

        filteredSteps.forEach((step, key) => {
            let newStep: NavigatorStep = {
                id: step.stepNumber,
                stepNumber: (key + 1),
                stepText: step.navigationHeaderText,
                isComplete: this.isNavigatorStepComplete(step.stepNumber),
            };

            this.relevantNavigationSteps.push(newStep);
        });
    }

    private isNavigatorStepComplete(stepNumber: number) {
        return (
            this.tmCommandResult.tmSteps.find(
                (step) => step.stepNumber === stepNumber
            )?.isComplete || false
        );
    }

    public async goToStep(stepNumber: number, targetMarketOverview: boolean) {
        if (targetMarketOverview) {
            this.currentSection = stepNumber;
        } else {
            await this.saveTargetMarketModel(() => { }, false, false, true);

            // Only allow the navigation to occur if there are no validation errors
            if (this.invalidDataMessage === "") {
                this.currentSection = stepNumber;

                if (this.currentSection === 0) {
                    this.populateTMOverview();
                }

            }
        }

        await this.fetchScreenData(stepNumber)
    }

    public async fetchScreenData(stepNumber: number) {
        this.taskRunner.run(async () => {
            // TM Name, Country and Regions (Specify your Target Market)
            if (stepNumber == this.convertSectionCodeToNumber(SectionCode.TM1)) {
                // TM Name and Country (among other things)
                const targetMarketResponse = await this.targetMarketsApiClient.getTargetMarket(this.targetMarketId);
                this.targetMarket.set(targetMarketResponse.data);
                this.setInitialTargetMarketType();

                // Regions
                this.fetchRegions(this.targetMarket.countryId);
            }

            // Industries and Employee size
            if (stepNumber == this.convertSectionCodeToNumber(SectionCode.TM2)) {
                // Employee Size (among other things)
                const targetMarketResponse = await this.targetMarketsApiClient.getTargetMarket(this.targetMarketId);
                this.targetMarket.set(targetMarketResponse.data);
                let minSize = this.targetMarket.employeeMinSizeId;
                this.targetMarket.employeeMinSizeId = 0; // Hide slider. It will re-render after employeeMinSizeId is set
                this.targetMarket.employeeMaxSizeId++; // Correct for Employee Size mismatch (8 on screen, 7 in db)
                this.targetMarket.employeeMinSizeId = minSize; // Re-render the slider
                this.setInitialTargetMarketType();

                // Industries selected by user
                const targetMarketSubIndustries = await this.targetMarketsApiClient.getTargetMarketSubIndustries(this.targetMarketId);
                this.targetMarket.targetMarketSubIndustries.set(targetMarketSubIndustries.data);

                // Industries
                const industriesResponse = await this.targetMarketsApiClient.getIndustriesAsync(this.targetMarketId);
                const industryLookup = <Array<Model.PlainObject<TargetMarketIndustryLookup>>>industriesResponse.data.data;
                this.industryList.set(industryLookup);
                this.unpackSelectedIndustries();
            }

            // C-Level Roles
            if (stepNumber == this.convertSectionCodeToNumber(SectionCode.TM3)) {
                const targetMarketManagementRolesResponse = await this.targetMarketsApiClient.getTargetMarketManagementRoleList(this.targetMarketId);
                const managementRoles = <Array<Model.PlainObject<TargetMarketManagementRole>>>targetMarketManagementRolesResponse.data.data;
                this.targetMarket.targetMarketManagementRoles.set(managementRoles);

                const managementRolesResponse = await this.targetMarketsApiClient.getTargetMarketManagementRolesAsync(this.targetMarket.countryId, this.targetMarketId);
                this.managementRoleList.set(managementRolesResponse.data);

                this.unpackManagementRoles();
            }

            // Head and Manager Roles and Functions
            if (stepNumber == this.convertSectionCodeToNumber(SectionCode.TM4)) {
                const targetMarketManagementFunctionsResponse = await this.targetMarketsApiClient.getTargetMarketManagementFunctionlist(this.targetMarketId);
                const managementFunctions = <Array<Model.PlainObject<TargetMarketManagementFunction>>>targetMarketManagementFunctionsResponse.data.data;
                this.targetMarket.targetMarketManagementFunctions.set(managementFunctions);

                const functionsResponse = await this.targetMarketsApiClient.getTargetMaketMangementFunctionsAsync(this.targetMarket.countryId, this.targetMarketId);
                this.managementFunctionList.set(functionsResponse.data);

                this.unpackManagementFunctions();
            }

            // Other function keywords (Other Roles and Functions)
            if (stepNumber == this.convertSectionCodeToNumber(SectionCode.TM5)) {
                const rolesResponse = await this.targetMarketsApiClient.getTargetMarketRolesAndRolesAsync(this.targetMarketId);
                this.savedTargetMarketRoles.set(rolesResponse.data as any);
                this.unpackSelectedRoles(rolesResponse.data);
            }

            // Advanced Settings
            if (stepNumber == this.convertSectionCodeToNumber(SectionCode.TM6)) {
                const targetMarketSpecificRolesResponse = await this.targetMarketsApiClient.getTargetMarketSpecificRoles(this.targetMarketId);
                this.targetMarket.targetMarketSpecificRoles.set(targetMarketSpecificRolesResponse.data);
                this.unpackSpecificRoles(targetMarketSpecificRolesResponse.data);
            }

            this.setupListModalData(true)
        });
    }

    public isOnboardingTM() {
        if (this.customAuthService.globalProps.isOnboarding) {
            this.showBackButton = this.customAuthService.globalProps.canGoNext;
        }
    }

    public initialiseAddTMFunctionVideo() {
        this.infoVideoVM.header = textConstants.generalText.TMFunctionVideoTitle;
        this.infoVideoVM.content = textConstants.generalText.TMFunctionVideoBody;
        this.infoVideoVM.url = this.informationManagementDetailsElement.videoUrl;
    }

    public setdisplayAddEditGrid() {
        if (this.targetMarket !== null) {
            if (this.targetMarket.isIROStage === false) {
                this.displayAddEditGrid = false;
            } else {
                let noEdit = this.iroEditStatuses.find((c) => c.iroEditStatusName === "No edits");
                let busyEditing = this.iroEditStatuses.find((c) => c.iroEditStatusName === "Busy editing");
                let editToDo = this.iroEditStatuses.find((c) => c.iroEditStatusName === "Edit to-do");

                if (editToDo === undefined) {
                    let source = this.appDataCache.iroEditStatuses.get().data;

                    let countrySource = this.appDataCache.countries.get().data;

                    if (source !== undefined) {
                        this.iroEditStatuses = source;

                        this.countries = countrySource;

                        noEdit = this.iroEditStatuses.find((c) => c.iroEditStatusName === "No edits");
                        busyEditing = this.iroEditStatuses.find((c) => c.iroEditStatusName === "Busy editing");
                        editToDo = this.iroEditStatuses.find((c) => c.iroEditStatusName === "Edit to-do");
                    }
                }

                if (
                    this.targetMarket.iroEditStatusId ===
                    busyEditing!.iroEditStatusId
                ) {
                    this.displayAddEditGrid = true;
                }
            }
        }
    }

    // this will handle the returned list from modal
    public setSelectedList(selectedList: List<ListSelectorModalLookup>, fullClonedList: List<ListSelectorModalLookup>) {

        this.selectedModalList = new List(ListSelectorModalLookup);
        selectedList.forEach((selectedItem) =>
            this.selectedModalList.push(selectedItem)
        );
        this.modalList = fullClonedList
    }

    public async initialiseManagementRoles() {
        const response =
            await this.managementRolesApiClient.getManagementRolesAsync();
        this.RolesAndSubRolesList.set(response.data);
        let tempSubFunction: string[] = [];
        let tempLocalisation: string[] = [];

        //get every word from management function and add to excluded list
        this.RolesAndSubRolesList.forEach((manFunc) => {
            manFunc.managementSubRoles.forEach((subFun) => {
                tempSubFunction = subFun.subRoleName.split(",");
                tempSubFunction.forEach((el) =>
                    this.excludedKeywordStringValues.push(el)
                );
                subFun.managementSubRoleLocalisations.forEach(
                    (localisation) => {
                        tempLocalisation = localisation.localisation.split(",");
                        tempLocalisation.forEach((el) =>
                            this.excludedKeywordStringValues.push(el)
                        );
                    }
                );
            });
        });

        //add original excluded keywords
        this.excludedKeywords.forEach((kw) =>
            this.excludedKeywordStringValues.push(kw.keyword)
        );
    }

    public populateTMOverview() {
        this.listSelectedItemsIndustries = new List(ListSelectorModalLookup);
        this.listSelectedItemsCLevelRoles = new List(ListSelectorModalLookup);
        this.listSelectedItemsManagementRoles = new List(
            ListSelectorModalLookup
        );

        this.populateModalListIndustries(
            undefined,
            this.listSelectedItemsIndustries
        );

        this.potentialAccounts = this.calculatePotentialAccounts(this.listSelectedItemsIndustries)

        this.populateModalListCLevelRoles(
            undefined,
            this.listSelectedItemsCLevelRoles
        );
        this.populateModalListManagementRoles(
            undefined,
            this.listSelectedItemsManagementRoles
        );

        this.currentSection = 0;
        this.firstStepNumber = 0;
    }

    private populateModalListIndustries(
        modalList: List<ListSelectorModalLookup> | undefined,
        selectedModalList: List<ListSelectorModalLookup>
    ) {
        this.industryList.forEach((industry) => {
            let modalListItem = new ListSelectorModalLookup();
            modalListItem.itemId = industry.industryId;
            modalListItem.itemName = industry.industryName;
            modalListItem.isSelected = industry.isSelected;
            modalListItem.isExpanded = industry.isExpanded;
            modalListItem.isPriority = industry.isPriority;

            industry.subIndustries.forEach((subIndustry) => {
                let subItem = new ListSelectorModalSubListLookup();
                subItem.subItemId = subIndustry.subIndustryId;
                subItem.subItemName = subIndustry.subIndustryName;
                subItem.subItemDetails = subIndustry.subIndustryDetails;
                subItem.isSelected = subIndustry.isSelected;
                subItem.isPriority = subIndustry.isPriority;

                modalListItem.subList.push(subItem);
            });

            modalList && modalList.push(modalListItem);

            if (
                modalListItem.subList.some(
                    (subItem) => subItem.isSelected === true
                )
            ) {
                selectedModalList.push(modalListItem);
            }
        });
    }

    private populateModalListCLevelRoles(
        modalList: List<ListSelectorModalLookup> | undefined,
        selectedModalList: List<ListSelectorModalLookup>
    ) {
        let modalListItem = new ListSelectorModalLookup();
        modalListItem.itemId = 1;
        modalListItem.itemName = "C-Level Roles";
        modalListItem.isSelected = null;
        modalListItem.isExpanded = true;
        modalListItem.isSelectedExpanded = true;
        modalListItem.isPriority = null;

        if (this.managementRoleList.length > 0) {
            // when its an exisiting TM
            this.managementRoleList.forEach((role) => {
                let subItem = new ListSelectorModalSubListLookup();
                subItem.subItemId = role.managementSubRoleId;
                subItem.subItemName = role.roleName
                    ? role.roleName
                    : "No Role Name";
                subItem.isSelected = role.isSelected;
                subItem.isPriority = null;
                modalListItem.subList.push(subItem);
            });
        } else if (this.roleListOptions.length > 0) {
            // when its a new TM
            this.roleListOptions.forEach((role) => {
                let subItem = new ListSelectorModalSubListLookup();
                subItem.subItemId = role.managementSubRoleId;
                subItem.subItemName = role.roleName
                    ? role.roleName
                    : "No Role Name";
                subItem.isSelected = role.isSelected;
                subItem.isPriority = null;
                modalListItem.subList.push(subItem);
            });
        }

        modalList && modalList.push(modalListItem);

        selectedModalList.push(modalListItem);
    }

    private populateModalListManagementRoles(
        modalList: List<ListSelectorModalLookup> | undefined,
        selectedModalList: List<ListSelectorModalLookup>
    ) {
        this.managementFunctionList.forEach((managementFunction) => {
            let modalListItem = new ListSelectorModalLookup();
            modalListItem.itemId = managementFunction.managementFunctionId || 0;
            modalListItem.itemName = managementFunction.functionName;
            modalListItem.isSelected = managementFunction.isSelected;
            modalListItem.isExpanded = managementFunction.isExpanded;
            modalListItem.isPriority = managementFunction.isPriority;

            managementFunction.targetMarketManagementSubFunctionsLookup.forEach(
                (managementSubFunction) => {
                    let subItem = new ListSelectorModalSubListLookup();
                    subItem.subItemId = managementSubFunction.managementSubFunctionId;
                    subItem.subItemName = managementSubFunction.functionName;
                    subItem.isSelected = managementSubFunction.isSelected;
                    subItem.isPriority = managementSubFunction.isPriority;

                    modalListItem.subList.push(subItem);
                }
            );

            modalList && modalList.push(modalListItem);

            if (modalListItem.subList.some((subItem) => subItem.isSelected === true)
            ) {
                selectedModalList.push(modalListItem);
            }
        });
    }

    public setupListModalData(forceUpdate = false) {
        let currentSectionCode: string = this.sectionCode;
        if (this.modalList.length > 0 && !forceUpdate) {
            return;
        }

        currentSectionCode = this.getCurrentSectionCodeBySectionNumber();

        this.modalList = new List(ListSelectorModalLookup);
        this.selectedModalList = new List(ListSelectorModalLookup);

        switch (currentSectionCode) {
            case SectionCode.TM2: {
                this.populateModalListIndustries(this.modalList, this.selectedModalList);
                break;
            }

            case SectionCode.TM3: {
                this.populateModalListCLevelRoles(this.modalList, this.selectedModalList);
                break;
            }

            case SectionCode.TM4: {
                this.populateModalListManagementRoles(this.modalList, this.selectedModalList);
                break;
            }
        }
    }

    private getCurrentSectionCodeBySectionNumber() {
        let currentSectionCode = this.sectionCode;

        // The SectionCode will always be lagging 1 behind (how the save functionality works)
        switch (this.currentSection) {
            case 2:
                currentSectionCode = SectionCode.TM2;
                break;

            case 3:
                currentSectionCode = SectionCode.TM3;
                break;

            case 4:
                currentSectionCode = SectionCode.TM4;
                break;

            default:
                break;
        }

        return currentSectionCode;
    }

    public async setupNewTargetMarket() {
        this.taskRunner.run(async () => {
            const industriesResponse = await this.targetMarketsApiClient.getIndustriesAsync();
            const rolesResponse = await this.targetMarketsApiClient.getTargetMarketRolesAndRolesAsync();
            const targetMarketStatuses = await this.appDataCache.targetMarketStatuses.getDataAsync();
            const targetMarketTypes = await this.appDataCache.targetMarketTypes.getDataAsync();

            const resp = await this.targetMarketsApiClient.getDefaultTargetMarketIsClientReview(this.clientId);
            this.targetMarket.isClientReview = resp.data;

            this.setDefaultProperties();
            const industryLookup = <
                Array<Model.PlainObject<TargetMarketIndustryLookup>>
                >industriesResponse.data.data;
            this.industryList.set(industryLookup);
            this.unpackRoles(rolesResponse.data);
            this.setBackURL();
            this.targetMarketStatuses.set(targetMarketStatuses);
            this.setTargetMarketStatuses();
            this.targetMarketTypes.set(targetMarketTypes);
            this.setInitialTargetMarketType();
            this.setdisplayAddEditGrid();
            await this.filterNavigationSteps();
            this.setupListModalData();
        });
    }

    public setupNewRegions() { }

    public setDefaultProperties() {
        // Set defaults
        this.targetMarket.employeeMinSizeId = 1;
        this.targetMarket.employeeMaxSizeId = 2;

        let editToDo = this.iroEditStatuses.find(
            (c) => c.iroEditStatusName === "Edit to-do"
        );

        if (editToDo === undefined) {
            let source = this.appDataCache.iroEditStatuses.get().data;

            let countrySource = this.appDataCache.countries.get().data;

            if (source !== undefined) {
                this.iroEditStatuses = source;
                this.countries = countrySource;
                editToDo = this.iroEditStatuses.find(
                    (c) => c.iroEditStatusName === "Edit to-do"
                );
            }
        }

        this.targetMarket.isIROStage = true;
        this.targetMarket.iroEditStatusId = editToDo!.iroEditStatusId;
        this.targetMarket.isFillerStage = true;
    }

    public setTargetMarketStatuses() {
        const draft = this.targetMarketStatuses.find((tms) => {
            return tms.targetMarketStatusName === textConstants.titleText.Draft;
        });
        if (draft !== undefined) {
            this.draftStatusId = draft.targetMarketStatusId;
        }

        const draftPreLaunch = this.targetMarketStatuses.find((tms) => {
            return (
                tms.targetMarketStatusName ===
                textConstants.titleText.DraftPreLaunch
            );
        });
        if (draftPreLaunch !== undefined) {
            this.draftPreLaunchId = draftPreLaunch.targetMarketStatusId;
        }
    }

    public setInitialTargetMarketType() {
        const martketCriteria = this.targetMarketTypes.find((tmType) => {
            return (tmType.targetMarketTypeName === textConstants.titleText.MarketCriteria);
        });

        if (martketCriteria !== undefined) {
            this.marketCriteriaTypeId = martketCriteria.targetMarketTypeId;
        }

        const whitelist = this.targetMarketTypes.find((tmType) => {
            return (tmType.targetMarketTypeName.toLocaleUpperCase() === textConstants.titleText.OnlyDoWhitelist.toLocaleUpperCase());
        });

        if (whitelist !== undefined) {
            this.whitelistTypeId = whitelist.targetMarketTypeId;
        }
    }

    public isTargetMarketNameBlank: boolean = false;
    public isCountryBlank: boolean = false;
    public isFunctionsBlank: boolean = false;
    public isFunctionsPriorityBlank: boolean = false;
    public isCompanySizeBlank: boolean = false;
    public isIndustryBlank: boolean = false;
    public isPriorityIndustryBlank: boolean = false;
    //this must be true as it links to the validation message for functions section
    public isOtherFunctionsBlank: boolean = true;

    public nameOrCountryRef = React.createRef<HTMLDivElement>();
    public industryRef = React.createRef<HTMLDivElement>();
    public functionsRef = React.createRef<HTMLDivElement>();
    public otherFunctionsRef = React.createRef<HTMLDivElement>();
    public companySizeRef = React.createRef<HTMLDivElement>();
    public cLevelRef = React.createRef<HTMLDivElement>();

    public functionsIsOpen: boolean = false;
    public companySizeIsOpen: boolean = false;
    public industriesIsOpen: boolean = false;

    public mandatoryFieldsInitialize() {
        this.isTargetMarketNameBlank = false;
        this.isCountryBlank = false;
        this.isFunctionsBlank = false;
        this.isFunctionsPriorityBlank = false;
        this.isCompanySizeBlank = false;
        this.isIndustryBlank = false;
        this.isPriorityIndustryBlank = false;
        this.isOtherFunctionsBlank = false;
    }

    public handleMandatoryFieldCompanySize() {
        if (!this.isSaveClicked) return;
        if (this.targetMarket.employeeMaxSizeId !== 0)
            this.isCompanySizeBlank = false;
        else this.isCompanySizeBlank = true;
    }

    public handleMandatoryFieldIndustry() {
        if (!this.isSaveClicked) return;
        let priorityCount = 0;
        let selectedIndustryCount = 0;

        this.industryList.forEach((industry) => {
            // Industry
            const selectedSubIndustry = industry.subIndustries.find(
                (subIndustry) => {
                    return subIndustry.isSelected;
                }
            );

            if (selectedSubIndustry !== undefined) {
                selectedIndustryCount += 1;
            }

            // Priority Industry
            if (priorityCount === 0) {
                const prioritySubIndustry = industry.subIndustries.find(
                    (subIndustry) => {
                        return subIndustry.isPriority;
                    }
                );

                if (prioritySubIndustry !== undefined) {
                    priorityCount += 1;
                }
            }
        });

        if (selectedIndustryCount === 0) {
            this.isIndustryBlank = true;
        } else {
            this.isIndustryBlank = false;
        }

        if (priorityCount === 0) {
            this.isPriorityIndustryBlank = true;
        } else {
            this.isPriorityIndustryBlank = false;
        }
    }

    public handleMandatoryFieldsFunctions() {
        if (!this.isSaveClicked) return;
        // Functions
        if (
            this.targetMarket.isSeniorityLevelHead ||
            this.targetMarket.isSeniorityLevelManager
        ) {
            var managementFunctionPriorityTest =
                this.managementFunctionList.filter(
                    (m) =>
                        (m.isSelected || m.isSelected === null) && m.isPriority
                ).length <= 0;

            var managementSubFunctionTest =
                this.managementFunctionList.filter(
                    (m) =>
                        m.targetMarketManagementSubFunctionsLookup.filter(
                            (tm) => tm.isPriority && tm.isSelected
                        ).length > 0
                ).length <= 0;

            var managementFunctionTest =
                this.managementFunctionList.filter(
                    (m) => m.isSelected || m.isSelected === null
                ).length <= 0;

            const tmCheck = this.savedListOptions
                ? this.savedListOptions.length <= 0
                    ? true
                    : false
                : true;

            var targetMarketRoleList =
                tmCheck ||
                this.savedListOptions === null ||
                !this.savedListOptions;

            if (
                managementFunctionPriorityTest &&
                managementSubFunctionTest &&
                !managementFunctionTest
            ) {
                this.isFunctionsPriorityBlank = true;
            } else if (managementFunctionTest && targetMarketRoleList) {
                this.isFunctionsBlank = true;
            } else {
                this.isFunctionsBlank = false;
                this.isFunctionsPriorityBlank = false;
            }
        }
    }

    public openCards() {
        if (this.isCompanySizeBlank) {
            this.companySizeIsOpen = true;
        }
        if (!this.targetMarket.isSeniorityLevelC) {
            this.functionsIsOpen = true;
        }
        if (this.isIndustryBlank) {
            this.industriesIsOpen = true;
        }
        if (this.isPriorityIndustryBlank) {
            this.industriesIsOpen = true;
        }
        if (
            (this.isFunctionsBlank || this.isFunctionsPriorityBlank) &&
            this.isOtherFunctionsBlank
        ) {
            this.functionsIsOpen = true;
        }
    }

    public handleFocus(ref: RefObject<HTMLDivElement>): void {
        let isFunctions =
            this.functionsRef === ref && this.targetMarket.isSeniorityLevelC;
        const node = ref.current;

        if (node) {
            if (isFunctions) {
                window.scrollTo(0, node.offsetTop + 850);
            } else {
                window.scrollTo(0, node.offsetTop - 100);
            }
        }
    }

    public findFocus(): RefObject<HTMLDivElement> | null {
        {
            /*Get the reference for the first element with mandatory fields not entered*/
        }
        if (this.isTargetMarketNameBlank) {
            return this.nameOrCountryRef;
        }
        if (this.isCountryBlank) {
            return this.nameOrCountryRef;
        }
        if (this.isCompanySizeBlank) {
            return this.companySizeRef;
        }
        if (!this.targetMarket.isSeniorityLevelC) {
            return this.functionsRef;
        }
        if (this.isIndustryBlank) {
            return this.industryRef;
        }
        if (this.isPriorityIndustryBlank) {
            return this.industryRef;
        }
        if (this.isFunctionsBlank) {
            return this.functionsRef;
        }
        if (this.isFunctionsPriorityBlank) {
            return this.functionsRef;
        }
        if (this.isOtherFunctionsBlank) {
            return this.otherFunctionsRef;
        }

        return null;
    }

    public setBackURL() {
        this.goBackUrl = `/TargetMarkets?id=${this.clientId}&clientName=${this.clientName}`;
    }

    public async fetchTargetMarket(targetMarketId: number) {
        this.taskRunner.run(async () => {
            const targetMarketResponse = await this.targetMarketsApiClient.getTargetMarket(targetMarketId);
            this.targetMarket.set(targetMarketResponse.data);
            // Correct for Employee Size mismatch (8 on screen, 7 in db)
            this.targetMarket.employeeMaxSizeId++

            // Industries
            const industriesResponse = await this.targetMarketsApiClient.getIndustriesAsync(targetMarketId);
            const industryLookup = <Array<Model.PlainObject<TargetMarketIndustryLookup>>>industriesResponse.data.data;
            this.industryList.set(industryLookup);
            this.unpackSelectedIndustries();

            // Other function keywords (Created by user)
            const targetMarketRolesResponse = await this.targetMarketsApiClient.getTargetMarketRoles(targetMarketId);
            this.targetMarket.targetMarketRoles.set(targetMarketRolesResponse.data);

            // Specific Keyword Roles (in Advanced Settings)
            const targetMarketSpecificRolesResponse = await this.targetMarketsApiClient.getTargetMarketSpecificRoles(targetMarketId);
            this.targetMarket.targetMarketSpecificRoles.set(targetMarketSpecificRolesResponse.data);
            this.unpackSpecificRoles(targetMarketSpecificRolesResponse.data);

            // Other function keywords (Created by user and selected from the ones available to all users)
            const rolesResponse = await this.targetMarketsApiClient.getTargetMarketRolesAndRolesAsync(targetMarketId);
            this.savedTargetMarketRoles.set(rolesResponse.data as any);
            this.unpackSelectedRoles(rolesResponse.data);

            // Industries selected by user
            const targetMarketSubIndustries = await this.targetMarketsApiClient.getTargetMarketSubIndustries(targetMarketId);
            this.targetMarket.targetMarketSubIndustries.set(targetMarketSubIndustries.data);

            const tMSummary = await this.targetMarketAccountsApiClient.getTargetMarketSummary(targetMarketId);
            this.targetMarketSummary.set(tMSummary.data);

            const targetMarketStatuses = await this.appDataCache.targetMarketStatuses.getDataAsync();

            const targetMarketTypes = await this.appDataCache.targetMarketTypes.getDataAsync();
            this.targetMarketTypes.set(targetMarketTypes);
            this.setInitialTargetMarketType();

            const getClientTMLookup = await this.targetMarketsApiClient.getClientTargetMarketSteps(targetMarketId);

            this.tmCommandResult.tmSteps.set(getClientTMLookup.data as any);

            this.lastSection = this.tmCommandResult.tmSteps.length;


            if (this.targetMarket.isComplete) {
                this.targetMarketInitiallyComplete = true
            }


            // set Management Roles and Function List
            const managementRolesResponse = await this.targetMarketsApiClient.getTargetMarketManagementRolesAsync(this.targetMarket.countryId, targetMarketId);
            this.managementRoleList.set(managementRolesResponse.data);
            const functionsResponse = await this.targetMarketsApiClient.getTargetMaketMangementFunctionsAsync(this.targetMarket.countryId, targetMarketId);
            this.managementFunctionList.set(functionsResponse.data);

            // Setting up target market management roles and functions
            const targetMarketManagementFunctionsResponse = await this.targetMarketsApiClient.getTargetMarketManagementFunctionlist(targetMarketId);
            const managementFunctions = <Array<Model.PlainObject<TargetMarketManagementFunction>>>targetMarketManagementFunctionsResponse.data.data;
            this.targetMarket.targetMarketManagementFunctions.set(managementFunctions);

            const targetMarketManagementRolesResponse = await this.targetMarketsApiClient.getTargetMarketManagementRoleList(targetMarketId);
            const managementRoles = <Array<Model.PlainObject<TargetMarketManagementRole>>>targetMarketManagementRolesResponse.data.data;
            this.targetMarket.targetMarketManagementRoles.set(managementRoles);

            if (this.targetMarket.targetMarketId && !this.isWhitelistTM) {
                await this.getReferenceTable()
            }

            this.setTargetMarketType(targetMarketResponse.data.targetMarketTypeId);
            this.targetMarketName = this.targetMarketSummary.targetMarketName;
            this.clientId = this.targetMarketSummary.clientId;
            this.goBackUrl = `/TargetMarkets?id=${this.targetMarketSummary.clientId}&clientName=${this.targetMarketSummary.clientName}`;
            // this.openIndustries();
            this.targetMarketStatuses.set(targetMarketStatuses);
            this.setTargetMarketStatuses();
            this.unpackManagementRoles();
            this.unpackManagementFunctions();
            this.setLanguage(this.targetMarket.countryId, true, true);
            this.setdisplayAddEditGrid();
            await this.filterNavigationSteps();

            await this.navigateToAppropriateScreen();
            this.setupListModalData();

            if (this.targetMarket.isComplete) {
                this.populateTMOverview();
            }

            if (!this.isComXUser) {
                this.lastSection = this.isWhitelistTM ? this.relevantNavigationSteps.length
                    : this.relevantNavigationSteps.length - 1
            }
        });
    }

    public async getReferenceTable() {
        let result = undefined;

        if (this.targetMarket.targetMarketId || this.targetMarketId === 0) {
            result = (await this.targetMarketsApiClient.getReferenceTable(this.targetMarket.targetMarketId)).data as unknown as Map<number, Map<number, number>>;
            if (this.targetMarket.targetMarketId) {
                this.targetMarketId = this.targetMarket.targetMarketId
            }

            this.dictPairResult = result
        }
    }

    public calculatePotentialAccounts(selectedIndustries: List<ListSelectorModalLookup>) {

        let potentialAccountsCounter: number = 0;

        // Checks if there are selectedIndustries
        if (selectedIndustries) {

            // An array to store already counted subIndustries
            let subIndustryAlreadyCounted: number[] = []

            // Looping through the selected industries
            selectedIndustries.forEach(industry => {

                // Looping through the selected sub industries
                industry.subList.forEach(subIndustry => {

                    // Checks if the subindustry is selected
                    if (subIndustry.isSelected) {

                        // Getting the specific sub Industry data set
                        let currentSubIndustryEmployeeSizes: Map<number, number> = this.dictPairResult[subIndustry.subItemId]

                        // Checks if it has something for the currentSubIndustryEmployeeSize data set
                        // Checks if the sub industry has already been calculated
                        if (currentSubIndustryEmployeeSizes && !subIndustryAlreadyCounted.find(subIndustryId => subIndustryId == subIndustry.subItemId)) {

                            // Counts the number of items from the employeeSize data set
                            potentialAccountsCounter += currentSubIndustryEmployeeSizes[(this.targetMarket.employeeMaxSizeId - 2)]

                            // If the employeeMinSizeId is larger than 0 we have to make sure the aggreggated data is removed.
                            if ((this.targetMarket.employeeMinSizeId - 1 > 0)) {
                                potentialAccountsCounter -= currentSubIndustryEmployeeSizes[(this.targetMarket.employeeMinSizeId - 2)]
                            }

                            // Adds the subIndustryId so it is not calculated again.
                            subIndustryAlreadyCounted.push(subIndustry.subItemId)
                        }
                    }
                })
            })

            return potentialAccountsCounter;
        }

        return undefined;
    }

    public async navigateToAppropriateScreen() {
        if (!this.targetMarket.isComplete) {
            let firstIncompleteStep = this.tmCommandResult.tmSteps
                .sortBy((s) => s.stepNumber)
                .find((step) => !step.isComplete)?.stepNumber;
            this.currentSection = firstIncompleteStep ?? 1;

            if (this.customAuthService.globalProps.isOnboarding) {
                // Set SectionCode to ensure that the correct help center text is shown
                await this.customAuthService.onboardingFunctions.getRelevantSectionCode(
                    this.currentSection
                );
            }
        }
    }

    public openIndustries() {
        this.industriesIsOpen = true;
    }

    public unpackSelectedIndustries() {
        // This function will set an Industry's Selected property to true if all its SubIndustries are selected

        this.industryList.forEach((industry) => {
            let subIndustryCount: number = 0;
            let selectedSubIndustryCount: number = 0;

            industry.subIndustries.forEach((subIndustry) => {
                subIndustryCount += 1;

                if (subIndustry.isSelected) {
                    selectedSubIndustryCount += 1;
                }
            });

            if (
                industry.subIndustries.length > 0 &&
                subIndustryCount === selectedSubIndustryCount
            ) {
                industry.isSelected = true;
            }
        });
    }

    // This will add the selected sub industries to the TargetMarket model.
    public async PopulateNewSubIndustries() {
        // Goes through the industries.
        this.industryList.forEach((industry) => {
            // Goes through the sub industries and finds the selected sub industries and priorities and adds them to the target market model.
            industry.subIndustries.forEach((subIndustry) => {
                if (subIndustry.isSelected) {
                    const subIndustryToSave = new TargetMarketSubIndustry();

                    subIndustryToSave.industrySubIndustryId =
                        subIndustry.industrySubIndustryId;
                    subIndustryToSave.isPriority = subIndustry.isPriority;

                    this.targetMarket.targetMarketSubIndustries.push(
                        subIndustryToSave
                    );
                }
            });
        });
    }

    // Adds the new sub region to the Target Market Model
    public async PopulateNewSubRegions() {
        // Goes through the regions list.
        this.regionList.forEach((region) => {
            //Goes through the sub regions list and pushes the selected sub regions to Target Market Model.
            region.subRegions.forEach((subRegion) => {
                if (subRegion.isSelected) {
                    const subRegionToSave = new TargetMarketSubRegion();
                    subRegionToSave.subRegionId = subRegion.subRegionId;
                    this.targetMarket.targetMarketSubRegions.push(
                        subRegionToSave
                    );
                }
            });
        });
    }

    // Adds the new functions to the target market model.
    public async PopulateNewFunctions() {
        // Goes through the management functions
        this.managementFunctionList.forEach((func) => {
            // Makes sure the isSelected is not false.
            if (func.isSelected !== false) {
                // Checks the length of the sub functions
                if (func.targetMarketManagementSubFunctionsLookup.length > 0) {
                    // gets the selected sub functions.
                    var selectedSubFunctions =
                        func.targetMarketManagementSubFunctionsLookup.filter(
                            (c) => {
                                return c.isSelected;
                            }
                        );

                    // Checks if there are any selected sub functions.
                    if (selectedSubFunctions.length > 0) {
                        // Goes through the selected sub functions and pushes them to the target market model.
                        selectedSubFunctions.forEach((subFunc) => {
                            const functionToSave =
                                new TargetMarketManagementFunction();

                            functionToSave.targetMarketId =
                                this.targetMarket.targetMarketId;

                            functionToSave.managementFunctionId =
                                func.managementFunctionId;

                            functionToSave.managementSubFunctionId =
                                subFunc.managementSubFunctionId;

                            functionToSave.isPriority = subFunc.isPriority
                                ? true
                                : false;

                            this.targetMarket.targetMarketManagementFunctions.push(
                                functionToSave
                            );
                        });
                    }
                } else {
                    const functionToSave = new TargetMarketManagementFunction();

                    functionToSave.targetMarketId =
                        this.targetMarket.targetMarketId;

                    functionToSave.managementFunctionId =
                        func.managementFunctionId;

                    functionToSave.managementSubFunctionId =
                        func.managementSubFunctionId;

                    functionToSave.isPriority = func.isPriority ? true : false;
                    this.targetMarket.targetMarketManagementFunctions.push(
                        functionToSave
                    );
                }
            }
        });
    }

    // Populate C-level Roles
    public PopulateManagementRoles() {
        // Goes through the management roles list.
        this.managementRoleList.forEach((item) => {
            // If item is selected add it to the target market model.
            if (item.isSelected) {
                this.hasCLevelRoles = true
                const functionToSave = new TargetMarketManagementRole();
                functionToSave.targetMarketId =
                    this.targetMarket.targetMarketId;
                functionToSave.managementSubRoleId = item.managementSubRoleId;
                this.targetMarket.targetMarketManagementRoles.push(
                    functionToSave
                );
            }
        });
    }

    // Populate Other Functions
    public async PopulateTargetMarketRoles() {
        if (!this.isEdit) {
            this.targetMarket.targetMarketRoles.splice(
                0,
                this.targetMarket.targetMarketRoles.length
            );
        }

        // Goes through the roles and pushes them into the target market model.
        this.onScreenTargetMarketRoles.forEach(
            (onScreenRole: TargetMarketRole) => {
                if (
                    this.isEdit &&
                    this.targetMarket.targetMarketRoles.length > 0
                ) {
                    const foundRole = this.targetMarket.targetMarketRoles.find(
                        (savedRole) => {
                            // Check if it has an onScreenRole with the same details
                            return (
                                savedRole.targetMarketId ===
                                onScreenRole.targetMarketId &&
                                savedRole.targetMarketRoleName ===
                                onScreenRole.targetMarketRoleName
                            );
                        }
                    );

                    if (!foundRole) {
                        if (isNaN(Number(onScreenRole.roleId))) {
                            onScreenRole.roleId = 0;
                        }
                        this.targetMarket.targetMarketRoles.push(onScreenRole);
                    }
                } else {
                    if (isNaN(Number(onScreenRole.roleId))) {
                        onScreenRole.roleId = 0;
                    }
                    this.targetMarket.targetMarketRoles.push(onScreenRole);
                }
            }
        );
    }

    public async PopulateTargetMarketSpecificRoles() {
        // If the target market roles are being added
        if (!this.isEdit) {
            this.targetMarket.targetMarketSpecificRoles.splice(
                0,
                this.targetMarket.targetMarketSpecificRoles.length
            );
        }

        this.onScreenTargetMarketSpecificRoles.forEach(
            (onScreenRole: TargetMarketSpecificRole) => {
                // If the target market is being edited check the current target market length
                if (
                    this.isEdit &&
                    this.targetMarket.targetMarketSpecificRoles.length > 0
                ) {
                    const foundRole =
                        this.targetMarket.targetMarketSpecificRoles.find(
                            (savedRole) => {
                                // Returns whether the role has been found or not.
                                return (
                                    savedRole.targetMarketId ===
                                    onScreenRole.targetMarketId &&
                                    savedRole.targetMarketSpecificRoleName ===
                                    onScreenRole.targetMarketSpecificRoleName
                                );
                            }
                        );

                    // Checks if the roles has been found then adds it to the target market model.
                    if (!foundRole) {
                        if (
                            isNaN(
                                Number(onScreenRole.targetMarketSpecificRoleId)
                            )
                        ) {
                            onScreenRole.targetMarketSpecificRoleId = 0;
                        }

                        this.targetMarket.targetMarketSpecificRoles.push(
                            onScreenRole
                        );
                    }
                } else {
                    if (
                        isNaN(Number(onScreenRole.targetMarketSpecificRoleId))
                    ) {
                        onScreenRole.targetMarketSpecificRoleId = 0;
                    }

                    this.targetMarket.targetMarketSpecificRoles.push(
                        onScreenRole
                    );
                }
            }
        );
    }

    // Updates the current industries in the target market
    public async UpdateExistingSubIndustries() {
        this.industryList.forEach((industry) => {
            // Goes through the sub industries and changes the sub Industries list and checks which items have been deselected or selected
            industry.subIndustries.forEach((subIndustry) => {
                const foundSubIndustry =
                    this.targetMarket.targetMarketSubIndustries.find(
                        (savedSubIndustry) => {
                            return (
                                savedSubIndustry.industrySubIndustryId ===
                                subIndustry.industrySubIndustryId
                            );
                        }
                    );

                // If the sub industry is already in the target market then check if it is still selected or change the priority.
                if (foundSubIndustry) {
                    if (!subIndustry.isSelected) {
                        this.targetMarket.targetMarketSubIndustries.remove(
                            foundSubIndustry
                        );
                    } else {
                        foundSubIndustry.isPriority = subIndustry.isPriority;
                    }
                } else if (!foundSubIndustry && subIndustry.isSelected) {
                    // Adds the new sub industry to the target market model
                    const subIndustryToSave = new TargetMarketSubIndustry();
                    subIndustryToSave.industrySubIndustryId =
                        subIndustry.industrySubIndustryId;
                    subIndustryToSave.isPriority = subIndustry.isPriority;
                    this.targetMarket.targetMarketSubIndustries.push(
                        subIndustryToSave
                    );
                }
            });
        });
    }

    // Update the model based on the changes to the sub regions.
    public async UpdateExistingSubRegions() {
        // Goes through the regions and then all the sub regions.
        this.regionList.forEach((region) => {
            region.subRegions.forEach((subRegion) => {
                const foundSubRegion =
                    this.targetMarket.targetMarketSubRegions.find(
                        (savedSubRegion) => {
                            return (
                                savedSubRegion.subRegionId ===
                                subRegion.subRegionId
                            );
                        }
                    );

                // Checks if the sub region is already there.
                if (foundSubRegion) {
                    // remove sub region if its not selected.
                    if (!subRegion.isSelected) {
                        this.targetMarket.targetMarketSubRegions.remove(
                            foundSubRegion
                        );
                    }
                } else if (!foundSubRegion && subRegion.isSelected) {
                    // Adds the new sub region.
                    const subRegionToSave = new TargetMarketSubRegion();
                    subRegionToSave.subRegionId = subRegion.subRegionId;
                    this.targetMarket.targetMarketSubRegions.push(
                        subRegionToSave
                    );
                }
            });
        });
    }

    // Update the model based on the changes to the functions.
    public async UpdateExistingFunctions() {
        this.managementFunctionList.forEach((onScreenFunction) => {
            if (onScreenFunction.targetMarketManagementSubFunctionsLookup.length > 0) {
                onScreenFunction.targetMarketManagementSubFunctionsLookup.forEach(
                    (subf) => {
                        const foundFunction =
                            this.targetMarket.targetMarketManagementFunctions.find(
                                (tmf) =>
                                    tmf.managementSubFunctionId ===
                                    subf.managementSubFunctionId
                            );

                        // Checks if the function already exists
                        if (foundFunction) {
                            // If its no longer selected remove it, else check the priority
                            if (!subf.isSelected) {
                                foundFunction.targetMarketId =
                                    this.targetMarketId;
                                this.targetMarket.targetMarketManagementFunctions.remove(
                                    foundFunction
                                );
                            } else {
                                this.hasFunctions = true
                                this.targetMarket.targetMarketManagementFunctions
                                    .filter(
                                        (x) =>
                                            x.managementSubFunctionId ===
                                            subf.managementSubFunctionId
                                    )
                                    .forEach((x) => {
                                        x.isPriority = subf.isPriority
                                            ? true
                                            : false;
                                    });
                            }
                        }
                        // Adds the new functions with the specific priority.
                        else if (!foundFunction && subf.isSelected) {
                            this.hasFunctions = true
                            var newFunction =
                                new TargetMarketManagementFunction();

                            newFunction.targetMarketId =
                                this.targetMarket.targetMarketId;

                            newFunction.managementFunctionId =
                                subf.managementFunctionId;

                            newFunction.managementSubFunctionId =
                                subf.managementSubFunctionId;

                            newFunction.isPriority = subf.isPriority
                                ? true
                                : false;

                            this.targetMarket.targetMarketManagementFunctions.push(
                                newFunction
                            );
                        }
                    }
                );
            } else {
                // Checks if function already exists and it has changed
                const foundFunction =
                    this.targetMarket.targetMarketManagementFunctions.find(
                        (tmf) => {
                            return (
                                tmf.managementSubFunctionId ===
                                onScreenFunction.managementSubFunctionId
                            );
                        }
                    );

                // If it does exist
                if (foundFunction) {
                    // No longer selected remove it.
                    if (!onScreenFunction.isSelected) {
                        foundFunction.targetMarketId = this.targetMarketId;
                        this.targetMarket.targetMarketManagementFunctions.remove(
                            foundFunction
                        );
                    }
                    // Add the new additional item and check if the priority has changed, to the target market model.
                    else {
                        this.hasFunctions = true
                        this.targetMarket.targetMarketManagementFunctions
                            .filter(
                                (x) =>
                                    x.managementSubFunctionId ===
                                    onScreenFunction.managementSubFunctionId
                            )
                            .forEach((x) => {
                                x.isPriority = onScreenFunction.isPriority
                                    ? true
                                    : false;
                            });
                        this.targetMarket.targetMarketManagementFunctions
                            .filter(
                                (x) =>
                                    x.managementFunctionId ===
                                    onScreenFunction.managementFunctionId
                            )
                            .forEach((x) => {
                                x.isPriority = onScreenFunction.isPriority
                                    ? true
                                    : false;
                            });
                    }
                }
                // Not found but is selected add the new item to the target market model.
                else if (!foundFunction && onScreenFunction.isSelected) {
                    this.hasFunctions = true
                    var newFunction = new TargetMarketManagementFunction();
                    newFunction.targetMarketId =
                        this.targetMarket.targetMarketId;

                    newFunction.managementFunctionId =
                        onScreenFunction.managementFunctionId;

                    newFunction.managementSubFunctionId =
                        onScreenFunction.managementSubFunctionId;

                    newFunction.isPriority = onScreenFunction.isPriority
                        ? true
                        : false;

                    this.targetMarket.targetMarketManagementFunctions.push(
                        newFunction
                    );
                }
            }
        });

        // Check if C-Level Roles are present 
        if (this.targetMarket.targetMarketManagementRoles.length > 0) {
            this.hasCLevelRoles = true
        }
    }

    // Update C-level roles
    public UpdateManagementRoles() {
        this.managementRoleList.forEach((onScreenRole) => {
            // Checks if the item aleady exists and it has changed
            const foundRole =
                this.targetMarket.targetMarketManagementRoles.find(
                    (savedRole) => {
                        return (
                            savedRole.managementSubRoleId ===
                            onScreenRole.managementSubRoleId
                        );
                    }
                );

            // If it already exists
            if (foundRole) {
                // Remove the item if it is no longer selected
                if (!onScreenRole.isSelected) {
                    foundRole.targetMarketId = this.targetMarketId;
                    this.targetMarket.targetMarketManagementRoles.remove(
                        foundRole
                    );
                } else {
                    this.hasCLevelRoles = true
                }
            }
            // Add the new item to target market model.
            else if (!foundRole && onScreenRole.isSelected) {
                this.hasCLevelRoles = true
                var newRole = new TargetMarketManagementRole();
                newRole.managementSubRoleId =
                    onScreenRole.managementSubRoleId;
                newRole.targetMarketId = this.targetMarket.targetMarketId;
                newRole.targetMarketManagementRoleId = 0;
                this.targetMarket.targetMarketManagementRoles.push(newRole);
            }
        });
    }

    // Populates the specific sections to the Model.
    public async saveTargetMarketModel(
        navigate?: () => void,
        isFullSave: boolean = true,
        canValidateAll: Boolean = false,
        isDraft: boolean = false,
        isFromDropdownNavigator: boolean = false
    ) {

        // Ensure that the 'Save' button will trigger TM calc if the TM is complete and country/industry/size change
        if (
            (this.sectionCode === SectionCode.TM1 ||
                this.sectionCode === SectionCode.TM2) &&
            this.targetMarket.isComplete
        ) {
            isDraft = false;
        }

        this.isSaveClicked = true;

        this.sectionCode = SectionCode[`TM${this.currentSection}`];

        this.updateCurrentLists(this.sectionCode);

        // Checks if the target market is complete, currently hardcoded to true, this will be false on the initial adding of the Target market
        let isComplete = this.targetMarket.isComplete;

        // Checks if you can validate all the sections
        if (canValidateAll) {
            // if it is not draft or the target market is complete all validations will run.
            if (!isDraft || isComplete) {
                this.runAllValidationChecks();
            }
        } else {
            // Runs the individual validation checks
            this.runIndividualValidationChecks(
                this.sectionCode,
                isFromDropdownNavigator
            );
        }

        // If there are any invalid items then don't continue.
        if (this.showInvalidDataModal) {
            return;
        }

        // Checks if the employeeSize is = 0 if it is set it to 1 as a minimum
        if (this.targetMarket.employeeMaxSizeId === 0) {
            this.targetMarket.employeeMaxSizeId = 1;
        }

        // Checks if it is a whitelist target market.
        if (this.isWhitelistTM) {
            // If it is a whitelist target market then set the target market type to whitelist.
            this.targetMarket.targetMarketTypeId = this.whitelistTypeId;
        } else {
            // set the target market type id to market criteria.
            this.targetMarket.targetMarketTypeId = this.marketCriteriaTypeId;
        }

        // Initial data
        if (!this.isEdit) {
            this.targetMarket.clientId = this.clientId;
            this.targetMarket.targetMarketStatusId = this.draftStatusId;
        }

        let saveWhitelist = this.saveWhitelist;

        let isStepComplete = this.relevantNavigationSteps.find(x => x.id === this.currentSection)?.isComplete

        // Only populates specific sections based on the section code.
        switch (this.sectionCode) {
            case SectionCode.TM1:
                // Going to be removed eventually
                this.isEdit || isStepComplete
                    ? await this.UpdateExistingSubRegions()
                    : await this.PopulateNewSubRegions();
                break;

            case SectionCode.TM2:
                this.isEdit || isStepComplete
                    ? await this.UpdateExistingSubIndustries()
                    : await this.PopulateNewSubIndustries();

                break;

            case SectionCode.TM3:
                this.isEdit || isStepComplete
                    ? await this.UpdateManagementRoles()
                    : await this.PopulateManagementRoles();
                break;

            case SectionCode.TM4:
                this.isEdit || isStepComplete
                    ? await this.UpdateExistingFunctions()
                    : await this.PopulateNewFunctions();
                break;

            case SectionCode.TM5:
                await this.PopulateTargetMarketRoles();
                break;

            case SectionCode.TM6:
                await this.PopulateTargetMarketSpecificRoles();
                break;
        }

        // If there is no invalid data model save the target market.
        if (!this.showInvalidDataModal) {
            this.removeManagementFunctions()

            await this.saveTargetMarket(navigate, saveWhitelist, isFullSave, isDraft);
        }
    }

    private removeManagementFunctions() {
        if (!this.targetMarket.isSeniorityLevelHead && !this.targetMarket.isSeniorityLevelManager) {
            this.managementFunctionList.forEach(managementFunction => {
                managementFunction.targetMarketManagementSubFunctionsLookup.forEach(subFunctions => {
                    const foundSubItem = this.targetMarket.targetMarketManagementFunctions
                        .find(x => x.managementSubFunctionId === subFunctions.managementSubFunctionId)

                    if (foundSubItem) {
                        this.targetMarket.targetMarketManagementFunctions.remove(foundSubItem)
                    }
                })
            })

            this.managementFunctionList = new List(TargetMarketManagementFunctionLookup);
        }
    }

    private updateCurrentLists(sectionCode: string) {
        switch (sectionCode) {
            case SectionCode.TM2:
                mapSelectedValuesToExistingModel(sectionCode, this.selectedModalList, this.industryList);
                break;

            case SectionCode.TM3:
                if (this.managementRoleList.length > 0) {
                    mapSelectedValuesToExistingModel(sectionCode, this.selectedModalList, this.managementRoleList);

                    this.targetMarket.isSeniorityLevelC = this.managementRoleList.some((s) => s.isSelected);
                } else if (this.roleListOptions.length > 0) {
                    mapSelectedValuesToExistingModel(sectionCode, this.selectedModalList, this.roleListOptions);

                    this.targetMarket.isSeniorityLevelC = this.roleListOptions.some((s) => s.isSelected);
                }

                break;

            case SectionCode.TM4:
                mapSelectedValuesToExistingModel(sectionCode, this.selectedModalList, this.managementFunctionList);
                break;
        }
    }

    // Saves the target market and sends the target market model to the api.
    public async saveTargetMarket(navigate?: () => void, saveWhitelist?: boolean, isFullSave: boolean = true, isDraft: boolean = false) {
        let saveCommand = new TargetMarketSaveCommand();
        saveCommand.sectionCode = this.sectionCode;
        saveCommand.targetMarket = this.targetMarket;
        saveCommand.isDraftSave = isDraft;
        saveCommand.hasCLevelRoles = this.hasCLevelRoles
        saveCommand.hasFunctions = this.hasFunctions
        saveCommand.targetMarket.targetMarketTypeId = this.targetMarket.targetMarketTypeId

        this.isValidSave = false;

        if (saveWhitelist && this.fileList !== undefined) {
            // Save Target Market and Whitelist
            await this.taskRunner.run(async () => {
                // Saves target market as whitelist
                const response = await this.targetMarketsApiClient.saveTargetMarketAndWhitelist(this.fileList, saveCommand);

                // checks if the response is successful.
                if (response.status === 200) {
                    this.isSaving = true;
                    this.isValidSave = true;
                    this.tmCommandResult = response.data;
                    this.targetMarket.targetMarketId = this.tmCommandResult.targetMarketId;
                    this.targetMarket.isComplete = this.tmCommandResult.isComplete;

                    this.handleTMChanges(saveCommand.sectionCode, this.tmCommandResult.tmChanges);

                    // Changes the navigation bar based on what type of target market it is and if the user is an internal or external user.
                    this.filterNavigationSteps();

                    // Only triggers the hotjar event if it is the fullSave and not a draft
                    if (isFullSave) {
                        await this.completeTheSave(isDraft, navigate);
                        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.WhitelistUpload);
                    }
                }
            });
        } else {
            // Save Target Market
            await this.taskRunner.run(async () => {
                const response = await this.targetMarketsApiClient.saveTargetMarket(saveCommand);

                if (response.status === 200) {
                    this.isSaving = true;
                    this.isValidSave = true;
                    this.tmCommandResult =
                        response.data as TargetMarketCommandResult;
                    this.targetMarket.isComplete = this.tmCommandResult.isComplete;

                    if ((((!this.targetMarket.targetMarketId && this.tmCommandResult.targetMarketId) || this.tmCountryChanged))) {

                        this.targetMarket.targetMarketId = this.tmCommandResult.targetMarketId;

                        await this.getReferenceTable()
                        this.tmCountryChanged = false;
                    }

                    this.handleTMChanges(saveCommand.sectionCode, this.tmCommandResult.tmChanges);

                    // Changes the navigation bar based on what type of target market it is and if the user is an internal or external user.
                    this.filterNavigationSteps();

                    // Only triggers the hotjar event if it is the fullSave and not a draft
                    if (isFullSave) {
                        await this.completeTheSave(isDraft, navigate);
                        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.TargetMarketSaved);
                    }
                }
            });
        }
    }

    private async completeTheSave(isDraft: boolean, navigate?: () => void) {
        !isDraft && triggerHotjarEvent(textConstants.Events.clientTMSave);
        if (this.customAuthService.globalProps.isOnboarding) {
            this.customAuthService.globalProps.canGoNext = true;
        }

        // Sets the step to complete as soon as the save has commenced
        !isDraft && (await this.StepIsCompleteOnboarding());

        // navigates to the target market overview
        if (navigate) {
            navigate();
        }
    }

    private handleTMChanges(tmStep: string, clientTMChanges: ClientTMChanges) {
        // If no changes, we dont have to do anything else.
        if (!clientTMChanges.hasTMChanged) {
            return;
        }

        this.tmChanges.hasTMChanged = clientTMChanges.hasTMChanged;
        this.tmChanges.hasIROStatusChanged = this.tmChanges.hasIROStatusChanged
            ? this.tmChanges.hasIROStatusChanged
            : clientTMChanges.hasIROStatusChanged;

        switch (tmStep) {
            case SectionCode.TM1:
                this.tmChanges.targetMarketName =
                    clientTMChanges.targetMarketName;
                this.tmChanges.regionsChanged = clientTMChanges.regionsChanged;
                break;

            case SectionCode.TM2:
                if (!this.isWhitelistTM) {
                    // Add to changes because there might already have been things. Now lets replace
                    this.tmChanges.employeeSizeAdded += clientTMChanges.employeeSizeAdded;
                    this.tmChanges.employeeSizeRemoved += clientTMChanges.employeeSizeRemoved;
                    this.tmChanges.industryAdded += clientTMChanges.industryAdded;
                    this.tmChanges.industryRemoved += clientTMChanges.industryRemoved;
                }
                break;

            case SectionCode.TM3:
                // maybe in future
                break;

            case SectionCode.TM4:
                this.tmChanges.functionsAdded += clientTMChanges.functionsAdded;
                this.tmChanges.functionsRemoved += clientTMChanges.functionsRemoved;
                break;

            case SectionCode.TM5:
                this.tmChanges.otherFunctionsChanged = clientTMChanges.otherFunctionsChanged;
                break;
        }
    }

    public async sendTMChangesZap() {
        if (!this.tmChanges.hasTMChanged) {
            return;
        }

        await this.targetMarketsApiClient.sendTMChangesZaps(this.targetMarket.targetMarketId, this.tmChanges);
    }

    private async assignUserRoles() {
        // var allowBatchReviewRevoke = await this.allowBatchReviewRevoke()

        const response =
            await this.targetMarketsApiClient.allowBatchReviewRevoke(
                this.clientId
            );
        const result = response.data as boolean;

        if (!this.targetMarket.isClientReview && result) {
            const userGroups =
                await this.clientSettingsViewModel.authApiClient.userGroups.getLookupAsync();
            this.clientSettingsViewModel.userGroups.set(userGroups.data);
            this.clientSettingsViewModel.clientDetails.isClientReview = false;
            this.clientSettingsViewModel.clientId = this.clientId;

            this.clientSettingsViewModel.assignAppropriateUserRoles(false);
        }

        if (this.targetMarket.isClientReview) {
            const userGroups =
                await this.clientSettingsViewModel.authApiClient.userGroups.getLookupAsync();
            this.clientSettingsViewModel.userGroups.set(userGroups.data);
            this.clientSettingsViewModel.clientDetails.isClientReview = true;
            this.clientSettingsViewModel.clientId = this.clientId;

            this.clientSettingsViewModel.assignAppropriateUserRoles(false);
        }
    }

    public async allowBatchReviewRevoke() {
        const response =
            await this.targetMarketsApiClient.allowBatchReviewRevoke(
                this.clientId
            );
        const result = response.data as boolean;
        return result;
    }

    public unpackRoles(
        roles: List<{
            roleId: number;
            targetMarketRoleName: string;
            roleName: string;
        }>
    ) {
        roles.forEach((role) => {
            if (role.targetMarketRoleName === "") {
                const roleToAdd = {
                    value: role.roleId,
                    label: role.roleName,
                };
                this.roleListOptions.push(roleToAdd);
            }
        });
    }

    public unpackSpecificRoles(specificRoles: any) {
        // Get roles from TargetMarketSpecificRoles
        this.specificRoleSavedList = []
        specificRoles.forEach(
            (specificRoles: {
                targetMarketSpecificRoleName: string;
                targetMarketSpecificRoleId: number;
            }) => {
                const roleToAdd = { value: "", label: "" };

                roleToAdd.value = specificRoles.targetMarketSpecificRoleName;
                roleToAdd.label = specificRoles.targetMarketSpecificRoleName;

                this.specificRoleSavedList.push(roleToAdd);
            }
        );
    }

    public unpackSelectedRoles(
        roles: List<{
            roleId: number;
            targetMarketRoleName: string;
            roleName: string;
        }>
    ) {
        // The roles sent into this function are a combination of Roles and TargetMarketRoles. This function splits them up.

        const savedRoles: any[] = [];
        const standardRoles: any[] = [];

        // Get roles from Roles table and format them to be usable in the control
        roles.forEach((role) => {
            if (role.roleName !== "") {
                const roleToAdd = {
                    value: role.roleId.toString(),
                    label: role.roleName,
                };
                standardRoles.push(roleToAdd);
            }
        });

        // Get roles from TargetMarketRoles
        roles.forEach((role) => {
            if (role.roleName === "") {
                const roleToAdd = { value: "", label: "" };

                if (role.roleId === 0) {
                    roleToAdd.value = role.targetMarketRoleName;
                } else {
                    roleToAdd.value = role.roleId.toString();
                }
                roleToAdd.label = role.targetMarketRoleName;

                savedRoles.push(roleToAdd);
                this.savedListOptions.push(roleToAdd);
            }
        });

        if (this.savedListOptions !== null) this.isOtherFunctionsBlank = true;

        // Mark already saved roles as "alreadySelected"
        standardRoles.forEach((standardRole) => {
            savedRoles.forEach((savedRole) => {
                if (standardRole.value === savedRole.value) {
                    const index = standardRoles.indexOf(standardRole);

                    if (index > -1) {
                        standardRole.alreadySelected = true;
                    }
                }
            });
        });

        // Make roleListOptions only show roles which have not been selected
        this.roleListOptions = standardRoles.filter(
            (standardRole) => standardRole.alreadySelected !== true
        );
    }

    public async setSpecificRoles(selectedItems: any) {
        if (this.isEdit) {
            // Delete Role if necessary
            if (selectedItems !== null && selectedItems.length > 0) {
                this.targetMarket.targetMarketSpecificRoles.forEach(
                    (existingRole) => {
                        const roleStillExists = selectedItems.find(
                            (currentRole: any) => {
                                return (
                                    currentRole.label ===
                                    existingRole.targetMarketSpecificRoleName
                                );
                            }
                        );

                        if (!roleStillExists) {
                            this.targetMarket.targetMarketSpecificRoles.remove(
                                existingRole
                            );
                        }
                    }
                );
            } else {
                var tm = this.targetMarket.targetMarketSpecificRoles;
                for (var i = 0; i < tm.length + 4; i += 1) {
                    this.targetMarket.targetMarketSpecificRoles.remove(tm[i]);
                }

                if (tm.length === 1) {
                    var lastItem = tm[0];
                    this.targetMarket.targetMarketSpecificRoles.remove(
                        lastItem
                    );
                }
            }
        }

        this.specificRoleSavedList = selectedItems;
        // Empty out the onScreenTargetMarketRoles list
        this.onScreenTargetMarketSpecificRoles.splice(
            0,
            this.onScreenTargetMarketSpecificRoles.length
        );

        // Populated the targetMarketSpecificRoles list with selected items
        if (selectedItems !== null) {
            selectedItems.forEach((role: any) => {
                const targetMarketSpecificRole = new TargetMarketSpecificRole();

                if (role.__isNew__ !== undefined) {
                    if (this.isEdit) {
                        targetMarketSpecificRole.targetMarketId =
                            this.targetMarketId;
                    }
                    targetMarketSpecificRole.targetMarketSpecificRoleId = 0;
                    targetMarketSpecificRole.targetMarketSpecificRoleName =
                        role.label;
                } else {
                    if (this.isEdit) {
                        targetMarketSpecificRole.targetMarketId =
                            this.targetMarketId;
                    }
                    targetMarketSpecificRole.targetMarketSpecificRoleId =
                        role.value;
                    targetMarketSpecificRole.targetMarketSpecificRoleName =
                        role.label;
                }
                this.onScreenTargetMarketSpecificRoles.push(
                    targetMarketSpecificRole
                );
            });
        }
    }

    public async setRoles(selectedItems: any) {
        // In Edit mode, check if the user has removed any previously saved roles and add them back into roleListOptions
        if (this.isEdit) {
            // Delete Role if necessary
            if (selectedItems !== null && selectedItems.length > 0) {
                this.targetMarket.targetMarketRoles.forEach((existingRole) => {
                    const roleStillExists = selectedItems.find(
                        (currentRole: any) => {
                            return (
                                currentRole.label ===
                                existingRole.targetMarketRoleName
                            );
                        }
                    );

                    if (!roleStillExists) {
                        this.targetMarket.targetMarketRoles.remove(
                            existingRole
                        );
                    }
                });
            } else {
                var tm = this.targetMarket.targetMarketRoles;
                for (var i = 0; i < tm.length + 4; i += 1) {
                    this.targetMarket.targetMarketRoles.remove(tm[i]);
                }

                if (tm.length === 1) {
                    var lastItem = tm[0];
                    this.targetMarket.targetMarketRoles.remove(lastItem);
                }
            }

            // Get the current Roles list from db
            const dbRoles = await this.taskRunner.waitFor(
                this.rolesApiClient.get()
            );

            if (selectedItems !== null) {
                // Count how many user-selected roles came from db Roles table
                let originalRoleCount: number = 0;

                selectedItems.forEach((selectedRole: any) => {
                    if (isNaN(Number(selectedRole.value))) {
                        originalRoleCount = originalRoleCount + 1;
                    }
                });

                if (
                    this.roleListOptions.length + originalRoleCount <
                    dbRoles.data.length
                ) {
                    const standardRoles: any[] = [];
                    // Get full roles list from Roles table in databse and format it to make it usable in the control
                    dbRoles.data.forEach((role: any) => {
                        const roleToAdd = {
                            value: role.roleId.toString(),
                            label: role.roleName,
                        };
                        standardRoles.push(roleToAdd);
                    });

                    // Mark already saved roles as "alreadySelected"
                    standardRoles.forEach((standardRole) => {
                        selectedItems.forEach((savedRole: any) => {
                            if (standardRole.value === savedRole.value) {
                                const index =
                                    standardRoles.indexOf(standardRole);
                                if (index > -1) {
                                    standardRole.alreadySelected = true;
                                }
                            }
                        });
                    });

                    // Make roleListOptions only show roles which have not been selected
                    this.roleListOptions = standardRoles.filter(
                        (standardRole) => standardRole.alreadySelected !== true
                    );
                    this.savedListOptions = selectedItems;
                    this.showRoles = true;
                }
            } else {
                // Double check in case the last removed item was originally from db
                if (this.roleListOptions.length < dbRoles.data.length) {
                    const standardRoles: any[] = [];
                    // Get full roles list from Roles table in databse and format it to make it usable in the control
                    dbRoles.data.forEach((role: any) => {
                        const roleToAdd = {
                            value: role.roleId.toString(),
                            label: role.roleName,
                        };
                        standardRoles.push(roleToAdd);
                    });
                    // Make roleListOptions only show roles which have not been selected
                    this.roleListOptions = standardRoles;
                    this.showRoles = true;
                }
            }
        }

        this.savedListOptions = selectedItems;
        // Empty out the onScreenTargetMarketRoles list
        this.onScreenTargetMarketRoles.splice(
            0,
            this.onScreenTargetMarketRoles.length
        );

        // Populated the targetMarketRoles list with selected items
        if (selectedItems !== null) {
            selectedItems.forEach((role: any) => {
                const targetMarketRole = new TargetMarketRole();

                if (role.__isNew__ !== undefined) {
                    if (this.isEdit) {
                        targetMarketRole.targetMarketId = this.targetMarketId;
                    }

                    targetMarketRole.roleId = 0;
                    targetMarketRole.targetMarketRoleName = role.label;
                } else {
                    if (this.isEdit) {
                        targetMarketRole.targetMarketId = this.targetMarketId;
                    }

                    targetMarketRole.roleId = role.value;
                    targetMarketRole.targetMarketRoleName = role.label;
                }
                this.onScreenTargetMarketRoles.push(targetMarketRole);
            });
        }
    }

    public setEmployeeSize(pointsOnLine: number[]) {
        this.targetMarket.employeeMinSizeId = pointsOnLine[0];
        this.targetMarket.employeeMaxSizeId = pointsOnLine[1];
    }

    public toggleSelect(
        category:
            | TargetMarketIndustryLookup
            | TargetMarketManagementFunctionLookup,
        subCategory: List<any>,
        isIndustry: boolean = false
    ) {
        let totalSelect = 0;
        let repeatedSubIndustry: SubIndustryLookup = new SubIndustryLookup();
        // Count so that validation flags aren't toggled if some items already selected

        if (isIndustry)
            this.industryList.forEach((i) => {
                i.isSelected ? totalSelect++ : (totalSelect = totalSelect);
            });
        else
            this.managementFunctionList.forEach((i) => {
                i.isSelected ? totalSelect++ : (totalSelect = totalSelect);
            });

        if (category.isSelected === false) {
            subCategory.forEach((si) => {
                si.isSelected = false;
            });

            if ("industryId" in category) {
                this.industryList.forEach((ind) => {
                    ind.subIndustries.forEach((si) => {
                        // Find any duplicate entries in the entire industry list and also set to false
                        if (
                            subCategory.find(
                                (sc) => sc.subIndustryId === si.subIndustryId
                            ) &&
                            ind.industryName !== category.industryName
                        ) {
                            si.isSelected = false;
                            repeatedSubIndustry = si;
                        }
                    });
                    if (repeatedSubIndustry.subIndustryId) {
                        this.toggleSubSelect(
                            repeatedSubIndustry,
                            ind.subIndustries,
                            ind,
                            true
                        );
                        repeatedSubIndustry = new SubIndustryLookup();
                    }
                });
            }
            category.isPriority = false;

            if (isIndustry) {
                this.togglePriority(category, subCategory, true);
            } else this.togglePriority(category, subCategory);
        } else {
            let oneSelected = subCategory.find((si) => si.isSelected);

            if (oneSelected) {
                subCategory.forEach((si) => {
                    si.isSelected = true;
                });
            } else {
                subCategory.forEach((si) => (si.isSelected = true));
            }
        }
        this.handleMandatoryFieldIndustry();
        this.handleMandatoryFieldsFunctions();
    }

    public toggleRegionSelect(region: TargetMarketRegionLookup) {
        if (region.isSelected === null) {
            region.isSelected = true;
        }

        if (region.isSelected === true) {
            region.subRegions.forEach((subRegion) => {
                subRegion.isSelected = true;
            });
        } else {
            region.subRegions.forEach((subRegion) => {
                subRegion.isSelected = false;
            });
        }
    }

    public toggleSubSelect(
        subCategory:
            | SubIndustryLookup
            | TargetMarketManagementSubFunctionsLookup,
        categoryList: List<any>,
        category:
            | TargetMarketIndustryLookup
            | TargetMarketManagementFunctionLookup,
        isIndustry: boolean = false
    ) {
        let countSelected = 0;
        let countPriority = 0;
        let countTotal = 0;
        // if same sub industry toggles off - all areas where it exists should be removed
        let repeatedSubIndustry: SubIndustryLookup = new SubIndustryLookup();
        let repeatedIndustry: TargetMarketIndustryLookup =
            new TargetMarketIndustryLookup();

        let totalSelect = 0;

        if (isIndustry)
            this.industryList.forEach((ind) =>
                ind.subIndustries.forEach((si) => {
                    si.isSelected ? totalSelect++ : (totalSelect = totalSelect);
                    //check for matching sub industries elsewhere
                    if (
                        "industryName" in category &&
                        "subIndustryName" in subCategory
                    ) {
                        //the last condition is to ensure we don't count the industry we are currently on
                        if (
                            subCategory.subIndustryId === si.subIndustryId &&
                            si.isSelected &&
                            ind.industryName !== category.industryName
                        ) {
                            si.isSelected = subCategory.isSelected
                                ? si.isSelected
                                : false;
                            repeatedSubIndustry = si;
                            repeatedIndustry = ind;
                        }
                    }
                })
            );
        else {
            this.managementFunctionList.forEach((i) =>
                i.targetMarketManagementSubFunctionsLookup.forEach((si) => {
                    si.isSelected ? totalSelect++ : (totalSelect = totalSelect);
                })
            );
            //count select and priority toggles
            categoryList.forEach((si) => {
                if (si.isSelected) countSelected++;
                if (si.isPriority) countPriority++;
                countTotal++;
            });
        }

        // The total can only be all subindustries on a selected entry
        if (subCategory.isSelected) {
            if (countTotal === countSelected) {
                category.isSelected = true;
            } else if (countSelected < countTotal) {
                category.isSelected = null;
            }
        } else {
            // if NOT SELECTED check if some of the other subindustries are selected
            // then make sure the selected checkbox indicates not all entries selected
            subCategory.isPriority = false;

            //remove repeated subindustry in the Industry list
            if (
                repeatedSubIndustry.subIndustryName &&
                repeatedIndustry.industryName
            ) {
                //repeatedSubIndustry.isSelected = false;
                this.toggleSubSelect(
                    repeatedSubIndustry,
                    repeatedIndustry.subIndustries,
                    repeatedIndustry,
                    true
                );
            }

            // subCategory.isPriority = false;
            this.toggleSubPriority(subCategory, categoryList, category, true);

            if (countSelected === 0) {
                category.isSelected = false;
            } else {
                //not all entries selected
                category.isSelected = null;
            }
        }
        this.handleMandatoryFieldIndustry();
        this.handleMandatoryFieldsFunctions();
    }

    public toggleSubRegionSelect(
        subRegion: SubRegionLookup,
        region: TargetMarketRegionLookup
    ) {
        let subRegionSelectedCount = 0;
        let subRegionCount = 0;

        region.subRegions.forEach((sr) => {
            subRegionCount++;

            if (sr.isSelected) {
                subRegionSelectedCount++;
            }
        });

        if (subRegion.isSelected === true) {
            if (subRegionCount === subRegionSelectedCount) {
                region.isSelected = true;
            }

            if (subRegionSelectedCount < subRegionCount) {
                region.isSelected = null;
            }
        } else {
            if (subRegionSelectedCount === 0) {
                region.isSelected = false;
            } else region.isSelected = null;
        }
    }

    public changeIndustrySelect() {
        this.industryList.forEach((industry) => {
            // Need to also set the checkbox to indeterminate if
            const totalSubIndustries = industry.subIndustries.length;

            let selectedSubIndustryCount = 0;
            industry.subIndustries.forEach((e) => {
                if (e.isSelected === true) {
                    selectedSubIndustryCount += 1;
                }
            });

            // const meta = `Total:${totalSubIndustries} - Selected:${selectedSubIndustryCount} - Industry:${industry.industryName}`

            if (totalSubIndustries === 0) {
                if (industry.isSelected !== false) {
                    industry.isSelected = false;
                }
            } else if (
                totalSubIndustries === selectedSubIndustryCount &&
                totalSubIndustries !== 0
            ) {
                if (industry.isSelected !== true) {
                    industry.isSelected = true;
                }
            } else if (
                totalSubIndustries !== selectedSubIndustryCount &&
                selectedSubIndustryCount > 0
            ) {
                if (industry.isSelected !== null) {
                    industry.isSelected = null;
                }
            } else {
                if (industry.isSelected !== false) {
                    industry.isSelected = false;
                }
            }
        });
    }

    public downloadTemplate() {
        this.taskRunner.run(async () => {
            const response =
                await this.targetMarketAccountsApiClient.getTemplate();
            const url = window.URL.createObjectURL(
                base64toBlob(
                    response.data.fileContents,
                    response.data.contentType
                )
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                "Template_" + getCurrentDateTime() + ".csv"
            );
            document.body.appendChild(link);
            link.click();
        });
    }

    public toggleSubPriority(
        subCategory:
            | SubIndustryLookup
            | TargetMarketManagementSubFunctionsLookup,
        categoryList: List<any>,
        category:
            | TargetMarketIndustryLookup
            | TargetMarketManagementFunctionLookup,
        isIndustry: boolean = false
    ) {
        let countSelected = 0;
        let countTotal = 0;
        categoryList.forEach((si) => {
            if (si.isPriority) {
                countSelected++;
            }
            countTotal++;
        });

        let totalSelect = 0;

        if (isIndustry) {
            this.industryList.forEach((i) =>
                i.subIndustries.forEach((si) => {
                    si.isPriority ? totalSelect++ : (totalSelect = totalSelect);
                })
            );
        } else {
            this.managementFunctionList.forEach((i) =>
                i.targetMarketManagementSubFunctionsLookup.forEach((si) => {
                    si.isPriority ? totalSelect++ : (totalSelect = totalSelect);
                })
            );
        }

        if (subCategory.isPriority) {
            if (countTotal === countSelected) {
                category.isPriority = true;
            } else if (countSelected < countTotal) {
                category.isPriority = null;
            }

            subCategory.isSelected = true;
            this.toggleSubSelect(
                subCategory,
                categoryList,
                category,
                isIndustry
            );
        } else {
            if (countSelected === 0) {
                category.isPriority = false;
            } else {
                category.isPriority = null;
            }
        }
        this.handleMandatoryFieldIndustry();
        this.handleMandatoryFieldsFunctions();
    }

    public togglePriority(
        category:
            | TargetMarketIndustryLookup
            | TargetMarketManagementFunctionLookup,
        subCategory: List<any>,
        isIndustry: boolean = false
    ) {
        let totalSelect = 0;

        if (isIndustry) {
            this.industryList.forEach((i) => {
                i.isPriority ? totalSelect++ : (totalSelect = totalSelect);
                i.isPriority === null
                    ? totalSelect++
                    : (totalSelect = totalSelect);
            });
        } else {
            this.managementFunctionList.forEach((i) => {
                i.isPriority ? totalSelect++ : (totalSelect = totalSelect);
                i.isPriority === null
                    ? totalSelect++
                    : (totalSelect = totalSelect);
            });
        }

        //priority checked
        if (category.isPriority === false) {
            subCategory.forEach((si) => {
                si.isPriority = false;
            });
            category.isPriority = false;
        }
        //priority unchecked
        else {
            let findOne = subCategory.find((si) => si.isPriority);
            //one or more priority toggles already checked
            if (!category.isSelected && findOne) {
                subCategory.forEach((si) => (si.isPriority = false));
                category.isPriority = false;
                //priority toggles all unchecked
            } else {
                //toggle all priorities and all selected
                if (category.isSelected === false) {
                    subCategory.forEach((si) => (si.isPriority = true));
                    category.isSelected = true;
                    if (isIndustry)
                        this.toggleSelect(category, subCategory, true);
                    else this.toggleSelect(category, subCategory, false);
                }
                //only toggle those which selected has already checked
                else
                    subCategory.forEach(
                        (si) => (si.isPriority = si.isSelected)
                    );

                //make sure a dash shows when not all the sub toggles have been checked
                let oneEmpty = subCategory.find(
                    (si) => si.isPriority === false
                );
                if (oneEmpty) category.isPriority = null;
                else category.isPriority = true;
            }
        }
        this.handleMandatoryFieldIndustry();
        this.handleMandatoryFieldsFunctions();
    }

    public setSaveModal() {
        this.showSaveModal = true;
    }

    public setTargetMarketType(targetMarketTypeId: number) {
        if (targetMarketTypeId === this.marketCriteriaTypeId) {
            this.isWhitelistTM = false;
        } else if (targetMarketTypeId === this.whitelistTypeId) {
            this.isWhitelistTM = true;
        }

        this.toggleTargetMarketType();
    }

    public toggleFunctionSelect(funct: TargetMarketManagementFunctionLookup) {
        this.isScreenDirty = true;

        if (funct.isSelected === false) {
            funct.targetMarketManagementSubFunctionsLookup.forEach((sf) => {
                sf.isSelected = false;
            });
            this.isFunctionsBlank = true;
            funct.isPriority = false;
        } else {
            let oneSelected =
                funct.targetMarketManagementSubFunctionsLookup.find(
                    (sf) => sf.isSelected
                );

            if (oneSelected) {
                funct.targetMarketManagementSubFunctionsLookup.forEach((sf) => {
                    sf.isSelected = true;
                });
                this.isFunctionsBlank = false;
            } else {
                funct.targetMarketManagementSubFunctionsLookup.forEach(
                    (sf) => (sf.isSelected = true)
                );
                this.isFunctionsBlank = false;
            }
        }
    }

    public toggleManagementRoleSelect(
        funct: TargetMarketManagementRolesLookup
    ) {
        if (this.managementRoleList.filter((x) => x.isSelected).length === 0) {
            this.targetMarket.isSeniorityLevelC = false;
        } else {
            this.targetMarket.isSeniorityLevelC = true;
        }

        funct = this.manageStateChange(funct, "isSelected");
    }

    public unpackManagementFunctions() {
        // set those boxes who empty subfunctions to false & those with subfunctions < total
        // to null (dash in box)
        this.managementFunctionList.forEach((f) => {
            let subFunctionCount: number = 0;
            let selectedSubFunctionCount: number = 0;
            let prioritySubFunctionCount: number = 0;

            f.targetMarketManagementSubFunctionsLookup.forEach((subFunc) => {
                subFunctionCount += 1;
                if (subFunc.isSelected) {
                    selectedSubFunctionCount++;
                }

                if (subFunc.isPriority) {
                    prioritySubFunctionCount++;
                }
            });

            if (selectedSubFunctionCount === 0) {
                f.isSelected = false;
            } else if (selectedSubFunctionCount !== subFunctionCount) {
                f.isSelected = null;
            }

            if (prioritySubFunctionCount === 0) {
                f.isPriority = false;
            } else if (prioritySubFunctionCount !== subFunctionCount) {
                f.isPriority = false;
            }
        });
    }

    public unpackManagementRoles() {
        this.managementRoleList.forEach((x) => this.toggleManagementRoleSelect(x));
    }

    private manageStateChange(funct: any, isSelected: any) {
        if (funct[isSelected]) {
            funct[isSelected] = true;
        } else {
            funct[isSelected] = false;
        }
        return funct;
    }

    public toggleFunctionPriority(funct: TargetMarketManagementFunctionLookup) {
        if (funct.isPriority === false) {
            this.isFunctionsPriorityBlank = true;
            funct.targetMarketManagementSubFunctionsLookup.forEach((sf) => {
                sf.isPriority = false;
            });
            funct.isPriority = false;
        } else {
            this.isFunctionsPriorityBlank = false;
            funct.targetMarketManagementSubFunctionsLookup.forEach(
                (sf) => (sf.isPriority = sf.isSelected)
            );
        }
    }

    public setIcon() {
        if (this.isEdit) {
            return "edit";
        } else {
            return "funnel-dollar";
        }
    }

    public setMaxEmployeeSize() {
        if (
            this.isWhitelistTM ||
            this.targetMarket.targetMarketTypeId === this.whitelistTypeId
        ) {
            return 1;
        } else {
            return this.targetMarket.employeeMaxSizeId;
        }
    }

    public toggleTargetMarketType() {
        // Switch to Market Criteria (this is always allowed)
        if (this.isWhitelistTM === false) {
            this.isMarketCriteria = !this.isWhitelistTM;
            this.resetWhitelist();
        } else {
            // Switch to Whitelist

            // If TM is of type Whitelist or new, let the switch happen
            if (
                this.targetMarket.targetMarketTypeId === this.whitelistTypeId ||
                this.targetMarket.targetMarketTypeId === 0
            ) {
                this.isWhitelistTM = true;
                this.isMarketCriteria = false;
                this.resetMarketCriteria();
            }

            // if TM is of type Market Criteria and its status is Draft or Draft-Prelaunch, allow switch
            if (
                this.isMarketCriteria &&
                (this.targetMarket.targetMarketStatusId ===
                    this.draftStatusId ||
                    this.targetMarket.targetMarketStatusId ===
                    this.draftPreLaunchId ||
                    this.targetMarket.targetMarketStatusId === 0)
            ) {
                this.isMarketCriteria = false;
                this.isWhitelistTM = !this.isMarketCriteria;
                this.resetMarketCriteria();
            }

            // if TM is of type Market Criteria and its status is neither Draft nor Draft-Prelaunch, deny switch
            if (
                this.isMarketCriteria &&
                this.targetMarket.targetMarketStatusId !== this.draftStatusId &&
                this.targetMarket.targetMarketStatusId !== this.draftPreLaunchId
            ) {
                this.isWhitelistTM = false;
                this.showTargetMarketTypeModal = true;
            }
        }

        // Display the correct navigation steps
        this.filterNavigationSteps();
    }

    public isCountryComplete = false;

    // All validations
    public runAllValidationChecks() {
        this.showInvalidDataModal = false;
        this.invalidDataMessage = "";

        if (this.targetMarket.targetMarketName === "") {
            this.invalidDataMessage =
                textConstants.messageText.validationMessage.targetMarketName +
                "\n";
            this.isTargetMarketNameBlank = true;
            this.showInvalidDataModal = true;
        } else {
            this.isTargetMarketNameBlank = false;
        }

        // Country
        if (
            this.targetMarket.countryId === 0 ||
            this.targetMarket.countryId === undefined
        ) {
            this.invalidDataMessage +=
                textConstants.messageText.validationMessage.mustCountry + "\n";
            this.isCountryBlank = true;
            this.showInvalidDataModal = true;
        } else {
            this.isCountryBlank = false;
        }

        if (!this.isWhitelistTM) {
            this.MarketCriteriaValidation();
        }

        // This will force the user the select a seniortiy when the TM is completed
        if (
            !this.targetMarket.isSeniorityLevelC &&
            !this.targetMarket.isSeniorityLevelHead &&
            !this.targetMarket.isSeniorityLevelManager &&
            this.targetMarket.isComplete
        ) {
            this.invalidDataMessage +=
                textConstants.messageText.validationMessage.mustSeniorityLevel +
                "\n";
            this.showInvalidDataModal = true;
        }

        if (
            this.targetMarket.isSeniorityLevelHead ||
            this.targetMarket.isSeniorityLevelManager
        ) {
            this.FunctionsValidation();
        }
    }

    // Individual validations
    public runIndividualValidationChecks(
        sectionCode: SectionCode,
        isFromDropdownNavigator: boolean = false
    ) {
        this.showInvalidDataModal = false;
        this.invalidDataMessage = "";

        switch (sectionCode) {
            case SectionCode.TM1:
                if (this.targetMarket.targetMarketName === "") {
                    this.invalidDataMessage =
                        textConstants.messageText.validationMessage
                            .targetMarketName + "\n";
                    this.isTargetMarketNameBlank = true;
                    this.showInvalidDataModal = true;
                } else {
                    this.isTargetMarketNameBlank = false;
                }

                // Country
                if (
                    this.targetMarket.countryId === 0 ||
                    this.targetMarket.countryId === undefined
                ) {
                    this.invalidDataMessage +=
                        textConstants.messageText.validationMessage
                            .mustCountry + "\n";
                    this.isCountryBlank = true;
                    this.showInvalidDataModal = true;
                } else {
                    this.isCountryBlank = false;
                }
                break;

            case SectionCode.TM2 || SectionCode.TM2WL:
                if (!this.isWhitelistTM) {
                    this.MarketCriteriaValidation();
                }
                break;

            case SectionCode.TM3:
                // IF NO ROLE OR HEAD IS SELECTED AND THE TARGET MARKET IS COMPLETE
                if (
                    !this.targetMarket.isSeniorityLevelC &&
                    !this.targetMarket.isSeniorityLevelHead &&
                    !this.targetMarket.isSeniorityLevelManager &&
                    this.targetMarket.isComplete
                ) {
                    this.invalidDataMessage +=
                        textConstants.messageText.validationMessage
                            .mustSeniorityLevel + "\n";
                    this.showInvalidDataModal = true;
                }
                break;

            case SectionCode.TM4 || SectionCode.TM5:
                if (
                    !this.targetMarket.isSeniorityLevelC &&
                    !this.targetMarket.isSeniorityLevelHead &&
                    !this.targetMarket.isSeniorityLevelManager
                ) {
                    this.invalidDataMessage +=
                        textConstants.messageText.validationMessage
                            .mustSeniorityLevel + "\n";
                    this.showInvalidDataModal = true;
                }

                if (
                    this.targetMarket.isSeniorityLevelHead ||
                    this.targetMarket.isSeniorityLevelManager
                ) {
                    this.FunctionsValidation();
                }

                break;

            default:
                break;
        }

        this.isValidSave = !(
            this.showInvalidDataModal && !isFromDropdownNavigator
        );
    }

    // No whitelist validation in the model
    public MarketCriteriaValidation() {
        // Employee Size
        if (
            this.targetMarket.employeeMinSizeId ===
            this.targetMarket.employeeMaxSizeId ||
            this.targetMarket.employeeMaxSizeId === 0
        ) {
            this.invalidDataMessage +=
                textConstants.messageText.validationMessage.mustCompanySize +
                "\n";
            this.isCompanySizeBlank = true;
            this.showInvalidDataModal = true;
        } else {
            this.isCompanySizeBlank = false;
        }

        let hasPriority = false;

        this.industryList.forEach((industry) => {
            if (hasPriority) {
                return;
            } else {
                hasPriority = industry.subIndustries.some((s) => s.isPriority);
            }
        });

        if (!hasPriority) {
            this.invalidDataMessage +=
                textConstants.messageText.validationMessage
                    .mustPriorityIndustry + "\n";
            this.isPriorityIndustryBlank = true;
            this.showInvalidDataModal = true;
        }
    }

    public FunctionsValidation() {
        var managementFunctionPriorityTest =
            this.managementFunctionList.filter(
                (m) => (m.isSelected || m.isSelected === null) && m.isPriority
            ).length <= 0;

        var managementSubFunctionTest =
            this.managementFunctionList.filter(
                (m) =>
                    m.targetMarketManagementSubFunctionsLookup.filter(
                        (tm) => tm.isPriority && tm.isSelected
                    ).length > 0
            ).length <= 0;

        var managementFunctionTest =
            this.managementFunctionList.filter(
                (m) => m.isSelected || m.isSelected === null
            ).length <= 0;

        const tmCheck = this.savedListOptions
            ? this.savedListOptions.length <= 0
                ? true
                : false
            : true;

        var targetMarketRoleList =
            tmCheck || this.savedListOptions === null || !this.savedListOptions;

        if (
            managementFunctionPriorityTest &&
            managementSubFunctionTest &&
            !managementFunctionTest
        ) {
            this.invalidDataMessage +=
                textConstants.messageText.validationMessage
                    .atleastMandatoryFunction + "\n";
            this.isFunctionsPriorityBlank = true;
            this.showInvalidDataModal = true;
        } else if (managementFunctionTest && targetMarketRoleList) {
            this.invalidDataMessage +=
                textConstants.messageText.validationMessage
                    .manadoryFunctionRequired + "\n";
            this.isFunctionsBlank = true;
            this.showInvalidDataModal = true;
        } else {
            this.isFunctionsBlank = false;
            this.isFunctionsPriorityBlank = false;
        }
    }

    public resetMarketCriteria() {
        // Reset Employee Size
        this.targetMarket.employeeMinSizeId = 1;
        this.targetMarket.employeeMaxSizeId = 0;

        // Reset Industries
        this.industryList.forEach((industry) => {
            industry.isSelected = false;
            this.changeIndustryHandler(industry);
            this.changeIndustrySelect();
        });
    }

    public resetWhitelist() {
        if (this.fileList !== undefined) {
            this.fileName = "none";
        }
    }

    public changeIndustryHandler(industry: TargetMarketIndustryLookup) {
        if (industry.firstClick === false) {
            industry.firstClick = true;
        }
        industry.isSelected = false;
        this.toggleSelect(industry, industry.subIndustries, true);
    }

    public async setLanguage(countryId: number, excludeFetch?: boolean, isFectTm?: boolean) {
        this.areRegionsLoading = true;

        if (!isFectTm) {
            this.targetMarket.languageCountryId = countryId;
        }

        if (!excludeFetch) {
            this.isFirstFetch = true;
            await this.setNewRolesList(countryId);

            await this.setNewFunctionList(countryId);

            this.unpackManagementRoles();
            this.unpackManagementFunctions();
        }

        if (countryId > 0) {
            await this.fetchRegions(countryId);
        }
        this.areRegionsLoading = false;
    }

    private async setNewRolesList(countryId: number) {
        const rolesResponse = await this.taskRunner.waitFor(
            this.targetMarketsApiClient.getTargetMarketManagementRolesAsync(
                countryId
            )
        );

        let tempRolesList = new List(TargetMarketManagementRolesLookup);

        tempRolesList.set(rolesResponse.data);

        if (this.managementRoleList.length === 0) {
            this.managementRoleList.set(rolesResponse.data);
        } else {
            // to maintain previous selections and allow for the changes in langauge of the roles

            this.managementRoleList.forEach((r) => {
                let currentRole = tempRolesList.find(
                    (f) => f.managementSubRoleId === r.managementSubRoleId
                );

                r.roleName = currentRole!.roleName;
            });
        }
    }

    private async setNewFunctionList(countryId: number) {
        const functionsResponse = await this.taskRunner.waitFor(
            this.targetMarketsApiClient.getTargetMaketMangementFunctionsAsync(
                countryId
            )
        );

        let tempFunctions = new List(TargetMarketManagementFunctionLookup);

        tempFunctions.set(functionsResponse.data);

        if (this.managementFunctionList.length === 0) {
            this.managementFunctionList.set(functionsResponse.data);
        } else {
            this.managementFunctionList.forEach((fe) => {
                let currentFunction = tempFunctions.find(
                    (tf) => tf.managementFunctionId === fe.managementFunctionId
                );

                fe.targetMarketManagementSubFunctionsLookup.forEach((sf) => {
                    // to maintain previous selections and allow for the changes in langauge of the functions

                    let currentSubFunction =
                        currentFunction!.targetMarketManagementSubFunctionsLookup.find(
                            (f) =>
                                f.managementSubFunctionId ===
                                sf.managementSubFunctionId
                        );

                    sf.functionName = currentSubFunction!.functionName;
                });
            });
        }
    }

    private async fetchRegions(countryId: number) {
        const regionsResponse = await this.taskRunner.waitFor(
            this.targetMarketsApiClient.getRegionsAsync(countryId, this.targetMarket.targetMarketId)
        );
        this.regionList.set(regionsResponse.data);

        if (!this.isEdit) {
            // set all regions to selected
            this.regionList.forEach((region) => {
                region.isSelected = true;
                region.subRegions.forEach((subRegion) => {
                    subRegion.isSelected = true;
                });
            });
        } else {
            var isregionsSelected: boolean = false;

            this.regionList.forEach((region) => {
                if (region.isSelected || region.isSelected === null)
                    isregionsSelected = true;
            });

            if (isregionsSelected) {
                const targetMarketSubRegions = await this.taskRunner.waitFor(
                    this.targetMarketsApiClient.getTargetMarketSubRegions(
                        this.targetMarket.targetMarketId
                    )
                );
                this.targetMarket.targetMarketSubRegions.set(
                    targetMarketSubRegions.data
                );
            } else {
                this.regionList.forEach((region) => {
                    region.isSelected = true;
                    region.subRegions.forEach((subRegion) => {
                        subRegion.isSelected = true;
                    });
                });
            }
        }

        this.setGermanyId();
    }

    private setGermanyId() {
        const country = this.countries.find(
            (c) => c.countryName.toLocaleLowerCase() === "germany"
        );

        if (country !== undefined) {
            this.germanyId = country.countryId;
        }
    }

    public clearIfNonNumeric(clientMax: number | null) {
        if (clientMax !== null && isNaN(clientMax)) {
            this.targetMarket.clientSpecificMax = null;
        } else {
            this.isScreenDirty = true;
        }
    }

    public clearDaysToAutoCompletion(daysBeforeAutoCompletion: number | null) {
        if (daysBeforeAutoCompletion !== null && isNaN(daysBeforeAutoCompletion)) {
            this.targetMarket.daysBeforeAutoCompletion = null;
        } else {
            this.isScreenDirty = true;
        }
    }

    public manageRolesSwitches() {
        var allFalse =
            this.targetMarket.isSeniorityLevelC === false &&
            this.targetMarket.isSeniorityLevelManager === false &&
            this.targetMarket.isSeniorityLevelHead === false;

        this.targetMarket.isFillerStage =
            this.targetMarket.isSeniorityLevelManager;

        if (allFalse) {
            this.invalidDataMessage =
                textConstants.messageText.validationMessage.mandatoryTohave1selected;
            // this.showInvalidDataModal = true;
        }
    }

    public manageClevelSelect() {
        this.managementRoleList
            .filter(
                (x) =>
                    x.roleKey.toLocaleLowerCase() ===
                    textConstants.funtionsFilterKeys.clevel.toLocaleLowerCase()
            )
            .forEach(
                (role) =>
                    (role.isSelected = this.targetMarket.isSeniorityLevelC)
            );
    }

    public hideCard() {
        if (!this.targetMarket.countryId) {
            this.invalidDataMessage =
                textConstants.messageText.validationMessage
                    .countryMustBeSelected + "\n";
            this.showInvalidDataModal = true;
        } else {
            this.functionsIsOpen = !this.functionsIsOpen;
        }
    }

    public hideRegionsCard() {
        if (!this.targetMarket.countryId) {
            this.invalidDataMessage =
                textConstants.messageText.validationMessage
                    .countryMustBeSelectedForRegions + "\n";
            this.showInvalidDataModal = true;
            this.canViewRegions = false;
        } else {
            this.canViewRegions = true;
        }
    }

    public disableSelect(funct: TargetMarketManagementFunctionLookup) {
        funct.isSelected = false;
        funct.isPriority = false;
        this.isFunctionsBlank = true;
        funct.targetMarketManagementSubFunctionsLookup.forEach((f) => {
            f.isPriority = false;
            f.isSelected = false;
        });
        return funct;
    }

    public async initialiseInformationManagement() {
        const informationManagementResponse = await this.taskRunner.waitFor(
            this.informationManagementApiClient.get()
        );
        this.informationManagementDetails.set(
            informationManagementResponse.data
        );
        this.informationManagementDetailsElement =
            this.informationManagementDetails.find(
                (x) => x.viewName === "Add/Edit Target Market - Functions"
            )!;
        this.initialiseAddTMFunctionVideo();
    }

    public fileUploaded() {
        this.notifications.addSuccess(textConstants.titleText.Uploaded,
            textConstants.messageText.saveMessage
                .WhitelistUploaded)
    }

    public cloneSelectorModalList(modalList: List<ListSelectorModalLookup>) {
        let clonedList = new List(ListSelectorModalLookup);

        modalList.forEach(item => {
            let subItemList = new List(ListSelectorModalSubListLookup);

            item.subList.forEach(subItem => {
                const newSubItem = new ListSelectorModalSubListLookup();
                // Create a deep copy of subItem by copying its properties
                newSubItem.subItemId = subItem.subItemId;
                newSubItem.subItemName = subItem.subItemName;
                newSubItem.subItemDetails = subItem.subItemDetails;
                newSubItem.isSelected = subItem.isSelected;
                newSubItem.isPriority = subItem.isPriority;

                subItemList.push(newSubItem);
            });

            let newItem = new ListSelectorModalLookup();
            // Create a deep copy of item by copying its properties
            newItem.itemId = item.itemId;
            newItem.itemName = item.itemName;
            newItem.isSelected = item.isSelected;
            newItem.isPriority = item.isPriority;
            newItem.isExpanded = item.isExpanded;
            newItem.isSelectedExpanded = item.isSelectedExpanded;
            // Assign the cloned sub List to cloned Item
            newItem.subList = subItemList;

            clonedList.push(newItem);
        });

        return clonedList;
    }
}
