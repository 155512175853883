import { Attributes, Data, List, Model, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import ClientDashboardInfoLookup from '../../Models/Client/ClientDashboardInfoLookup';
import ClientSettingsVM from '../Client/ClientSettingsVM';
import DashboardLookup from '../../Models/BatchReview/DashboardLookup';
import BatchReviewUploadVM from '../BatchReview/BatchReviewUploadVM';
import BatchReviewProspectsVM from '../BatchReview/BatchReviewProspectsVM';
import CustomerProfilesVM from '../IdealCustomerProfiles/CustomerProfilesVM';
import UpdateTutorialSeenCommand from '../../Models/Onboarding/Commands/UpdateTutorialSeenCommand';
import OnboardingStepLookup from '../../Models/Onboarding/OnboardingStepLookup';
import { PartialPlainNonTrackedObject, PartialPlainObject } from '@singularsystems/neo-core/dist/Model';
import { textConstants } from '../../common/textConstants';
import OnboardingPopupLookup from '../../Models/Onboarding/Queries/OnboardingPopupLookup';
import DashboardCard from '../../Models/Maintenance/DashboardCard';
import ClientCampaignDataLookup from '../../Models/Dashboard/ClientCampaignDataLookup';
import HideHighlyRecommendedCommand from '../../Models/Client/Commands/HideHighlyRecommendedCommand';
import SaveRefreshTimeCommand from '../../Models/Client/Commands/SaveRefreshTimeCommand';
import ClientCampaignQueryLookup from '../../Models/Dashboard/ClientCampaignQueryLookup';
import WoodpeckerCampaignStatus from '../../Models/Dashboard/WoodpeckerCampaignStatus';
import WoodpeckerCampaignEmail from '../../Models/Dashboard/WoodpeckerCampaignEmail';
import ReportingChartsFilter from '../../Models/Dashboard/ReportingChartsFilter';
import ReportingClientCampaignFilter from '../../Models/Dashboard/ReportingClientCampaignFilter';
import { ChartType } from '../../Models/Dashboard/ChartType';
import ReportingCampaignFilter from '../../Models/Dashboard/ReportingCampaignFilter';
import ClientCampaignDataCriteria from '../../Models/Client/Query/ClientCampaignDataCriteria';
import { AxiosPromise } from 'axios';
import { triggerHotjarEvent } from '../../Components/Hotjar';
import TargetMarketPieChartLookup from '../../Models/Dashboard/TargetMarketPieChartLookup';
import moment from 'moment';
import ActionListSummaryLookup from 'Models/ActionList/ActionListSummaryLookup';
import ReportingClientCampaignStatusFilter from 'Models/Dashboard/ReportingClientCampaignStatusFilter';
import ActionListConfiguration from 'Models/ActionList/ActionListConfiguration';
import { DashboardCards, HTTPResponseCodes } from 'Models/Enums/ApplicationEnums';
import { isMobile } from 'common/utils';

@NeoModel
export default class DashboardVM extends Views.ViewModelBase {
    [x: string]: any;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private authenticationService = AppService.get(Types.Neo.Security.AuthenticationService),
        private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
        private dashboardCardsApiClient = AppService.get(Types.ApiClients.DashboardCardsApiClient),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private comxHub = AppService.get(Types.ApiClients.ComXHub),
        private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient),
        private comXConfigApiClient = AppService.get(Types.ApiClients.ComXConfigurationsApiClient),
        public customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
        private dashboardReportConfigApiClient = AppService.get(Types.ApiClients.DashboardReportConfigApiClient),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        private actionListApiClient = AppService.get(Types.ApiClients.ActionListApiClient),
        private actionListConfigurations = AppService.get(Types.ApiClients.ActionListConfigurationsApiClient),
    ) {
        super(taskRunner);
        this.connectToComXHub()
        this.selfDashboardContext = this;
    }

    public selfDashboardContext: any
    public hasCalledApiForCampaignData: boolean = false;
    public selectedEmailerClientCampaignId: number = 0;
    public selectedCampaignStatusId: number = 0;
    public platformSetupUrl: string = ""
    public successTrackerUrl: string = ""
    public clientId: number = 0
    public showDashboard: boolean = true
    public showBatchReview: boolean = false
    public showBatchReviewProspects = false;
    public showBlacklist: boolean = false
    public clientDisplayName: string = "";
    public initialDashboardLoad: boolean = true;
    public clearAddEditNotifications: boolean = true;
    public ClientSettingsViewModel = new ClientSettingsVM();
    public dashboardLookup = new DashboardLookup()
    public showBlacklistToClient: boolean = false
    public batchReviewUploadId: number = 0;
    public CustomerProfileVM = new CustomerProfilesVM();
    public customerProfileId?: number = 0;
    public showPassword: boolean = true;
    public stringProperty: string = "";
    public dashboardCard: DashboardCard = new DashboardCard;
    public hideHighlyRecommendedCommand = new HideHighlyRecommendedCommand();
    // Sprint 31
    public navigateList = ["DashboardView"];

    // Show all batch reviews available
    public showBatchReviewToClient: boolean = false

    //Show Review Data for specific batch
    public showBatchReviewDataToClient: boolean = false
    public BatchReviewUploadViewModel = new BatchReviewUploadVM();
    public BatchReviewProspectsViewModel = new BatchReviewProspectsVM();
    public isEntryViaLink: boolean = false
    public isClientReview: boolean = false
    public campaignStatus = new WoodpeckerCampaignStatus();
    public foundersVideoURL: string = "";
    public knowledgeHubVideoURL: string = "";

    public showNewMenuAccessModal: boolean = false;
    public showFoundersVideo: boolean = false;
    public showKnowledgeCenterModal: boolean = false;
    public showActiveStyle: boolean = false;
    public showFoundersVideoNewUser: boolean = false;

    // onboarding
    public onboardingPopupLookup: OnboardingPopupLookup = new OnboardingPopupLookup();
    public onboardingPopupKnowledgeCenterLookup: OnboardingPopupLookup = new OnboardingPopupLookup();

    // Dashboard 
    // For Pie Chart
    public pieChartSeriesColours: any = {};
    public pieChartSeriesData: any = {};
    public pieChartSeriesNames: any = {};
    public pieChartLegendSubtexts: any = {};
    public pieChartTargetMarkets: List<TargetMarketPieChartLookup> = new List(TargetMarketPieChartLookup);
    public pieChartAggregatedDataValues: any = {};
    public pieChartActive: boolean = false;
    public selectedPieChartTargetMarketId: number = 0;

    // For client camapaigns
    public runningCampaignCount: number = 0
    public pageSizeNumber: number = 5;
    public isRetrievingCampaignData: boolean = false
    public isCampaignDataRetrievalFailure: boolean = false
    public campaignData: List<ClientCampaignDataLookup> = new List(ClientCampaignDataLookup) // used to set pageResult (filtered original client campaigns and client campaigns pulled through signalR)
    public originalCampaigns: List<ClientCampaignDataLookup> = new List(ClientCampaignDataLookup) // always contains the original client campaigns from the api
    public actionListConfigurationsList = new List(ActionListConfiguration)

    public hasSeenFirstCampaign: boolean = false
    public archivedCampaignData: any[] = new Array
    public campaignMessageReportingData: any[] = new Array
    public blacklistErrorMessage: string = ""
    public hasSeenHighlyRecommended = false;
    public lastRefresh: string = "";
    public showRefreshModal = false;

    public defaultRefreshTime: number = 0;
    public marketFeedbackTrackingLink: string = "";
    public isCSTeamVideoExpanded: boolean = true;
    public isGridSelected: boolean = true // Toggle to display campaigns in a grid or bar chart
    public highchartOptions: any = {}
    public displayPieChart: boolean = false

    public monthConversion = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    public chartSeries = new Array
    public notEnoughDataOnChart = textConstants.generalText.enoughChartData;
    public noActiveTargetMarkets = textConstants.generalText.noActiveTargetMarkets
    public campaignSeriesNumber = textConstants.GraphData.allCampaignsNumber
    public campaignSeriesPercent = textConstants.GraphData.allCampaignsPercent

    public isEnoughData = false;
    public minReportingLength = 0;
    public chartDataPropertyName = textConstants.GraphData.campaignMessageLineChartPropertyName

    public reportingCharts = textConstants.GraphData.chartTypes
    public chartType: ChartType = ChartType.SentOutEmails;
    public selectedClientCampaignId: number = 0;
    public reportingChartSelections = new List(ReportingChartsFilter)
    public reportingCampaignSelections = new List(ReportingClientCampaignFilter)
    public reportingCampaignStatusSelections = new List(ReportingClientCampaignStatusFilter)

    public selectedReportingStatusId: number = 0;
    public isPercent: boolean = false;
    public isPercentReporting: boolean = false;

    public clientCampaignDataCriteria: ClientCampaignDataCriteria = new ClientCampaignDataCriteria();

    public isCampaignRetrievalFailure: boolean = false;
    public openCampaignCount: number = 0;
    public actionListSummaryLookup = new ActionListSummaryLookup()

    //password handling
    public comXConfig: any = this.appDataCache.comXConfigurations.get().data
    @Attributes.Display("")
    public knowledgeHubPassword: string = ""

    //Dashboard Card data
    public visitComXBody: string = ""
    public visitComXCardRedirect: string = ""
    public bookSupportBody: string = ""
    public bookSupportRedirect: string = ""
    public highlyRecommendedHeader: string = ""
    public highlyRecommendedBody: string = ""
    public highlyRecommendedVideoLink: string = ""
    public highlyRecommendedRedirect: string = ""
    public blacklistBody: string = ""
    public blacklistDomain: string = "";
    public showGetCompletedCampaigns: boolean = true
    public hasNoCompletedCampaigns: boolean = false;
    public actionListGrandTotal: number = 0;
    public showDashboardNews: boolean = false;
    public imageData: any = null;
    public imageName?: string = "";

    // dashboard campaign filter properties
    public clientCampaignStatuses = new List(WoodpeckerCampaignStatus);
    public clientCampaignEmails = new List(WoodpeckerCampaignEmail);
    public filterApplied: boolean = false;
    public emailChange: boolean = false;
    public firstFetch: boolean = true

    // Client Campaign Email Overview
    public clientCampaignId = 0;
    public showCampaignOverview = false;
    public isWoodpeckerEmailOverview = false;

    //Linechart variables
    public filteredQueryList: any[] = new Array
    public dataHasChanged: boolean = false;
    public filteredByChartList: any[] = new Array
    public hasCampaignChanged: boolean = false;
    public seriesNames: string[] = new Array;
    public dailySeriesNames: string[] = [];

    public chartStatus = textConstants.titleText.Running;

    @Attributes.Date()
    public minDate = new Date()

    @Attributes.Date()
    public globalMinimum = new Date()

    @Attributes.Date()
    public maxDate = new Date()

    @Attributes.Date()
    public globalMaximum = new Date()

    public async initialise() {
        this.customAuthService.globalProps.isZendeskVisible = true;
        this.customAuthService.globalProps.isOnboarding = false;

        const { user, isAuthenticated } = this.authenticationService;

        // Fetch the platformSetup and successTracker urls
        if (isAuthenticated) {
            this.taskRunner.run(async () => {
                await this.getClientDashboardInfo(user)
                await this.getActionListSummary()
                await this.getActionListConfigurations()
                await this.getOnboardingData(user)
                this.marketFeedbackTrackingLink = await this.getMarketFeedbackTrackingLink();
                await this.getVideoUrls()
                await this.getPopupData()
                this.knowledgeHubPassword = await this.getKnowledgeHubPassword();
                await this.initialiseDashboardCards();
                await this.getDefaultRefreshTime();
                await this.getReportsChartSectionData()
                await this.pageManager.refreshData()

                if (!this.highlyRecommendedVideoLink && this.imageName) {
                    await this.getImage(this.imageName)
                }
            })
        }
    }

    public async getActionListConfigurations() {
        let response = await this.actionListConfigurations.get();

        if (response.status === HTTPResponseCodes.Ok) {
            this.actionListConfigurationsList.set(response.data)
        }
    }

    public async getActionListSummary() {
        const response = await this.actionListApiClient.getActionListSummary(this.clientId);

        if (response.status === 200) {
            if (response.data.success) {
                this.actionListSummaryLookup.set(response.data.data)
                this.actionListGrandTotal = this.actionListSummaryLookup.hotTotal
                    + this.actionListSummaryLookup.warmTotal
                    + this.actionListSummaryLookup.coldTotal
            }
            else {
                this.notifications.addDanger(textConstants.titleText.FetchFailed, response.data.message);
            }
        }
    }

    public async markDashboardNewsAsSeen() {
        this.taskRunner.run(async () => {
            await this.clientsApiClient.markDashboardNewsAsSeen()
        })
    }

    public async getImage(dashboardCardUniqueIdentifier: string) {
        const response = await this.dashboardCardsApiClient.getImage(dashboardCardUniqueIdentifier);

        if (response.data.success) {
            this.imageData = response.data.data
        }
        else {
            this.notifications.addDanger(
                textConstants.titleText.Error,
                response.data.message
            );
        }
    }

    // Initialise the page manager (also calling the getPagedList function to get paginated data from the front end.)
    public pageManager = new Data.PageManager(
        this.clientCampaignDataCriteria,
        ClientCampaignDataLookup,
        (request: PartialPlainNonTrackedObject<Data.PageRequest<ClientCampaignDataCriteria>>) => {
            return this.getPagedList(request, this.selfDashboardContext) // parsing in the context of the dashboardVM to that of the pageManager using the selfDashboardContext
        },
        {
            sortBy: "campaignName",
            sortAscending: false,
            fetchInitial: true,
            initialTaskRunner: this.taskRunner,
            pageSize: 8
        });

    // Sets the client campaign dashboard filter to 'running' when the user first loads the dashboardView
    public filterInitialClientCampaignData(clientCampaigns: List<ClientCampaignDataLookup>) {
        let runningStatusId = clientCampaigns.find(c => c.campaignStatus === textConstants.titleText.RunningClientCampaignStatus)?.campaignStatusId

        if (runningStatusId) {
            let filteredCampaigns = clientCampaigns.filter(c => c.campaignStatus === textConstants.titleText.RunningClientCampaignStatus)
            this.selectedCampaignStatusId = runningStatusId

            return filteredCampaigns
        } else {
            return clientCampaigns
        }
    }

    public async getPagedList(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<ClientCampaignDataCriteria>>, dashboardContext: any): Promise<Data.PageResult<Model.PlainObject<ClientCampaignDataLookup>>> {

        // For when we haven't gotten data from the API. Initial fetch.
        if (dashboardContext.campaignData.length === 0) {

            if (!dashboardContext.hasCalledApiForCampaignData) {
                let contextResultData = dashboardContext.clientsApiClient.getClientCampaigns(request.criteria!.clientId, request.criteria!.isForced, request.criteria!.isRetrieveAll) as AxiosPromise<ClientCampaignQueryLookup>

                dashboardContext.hasCalledApiForCampaignData = true

                await dashboardContext.getLastRefreshTimestamp()

                dashboardContext.campaignData = new List(ClientCampaignDataLookup)

                return contextResultData.then((clientCampaignQueryLookup) => {

                    dashboardContext.originalCampaigns.set(clientCampaignQueryLookup.data.clientCampaigns)

                    dashboardContext.campaignData.set(dashboardContext.filterInitialClientCampaignData(clientCampaignQueryLookup.data.clientCampaigns))

                    dashboardContext.isRetrievingCampaignData = clientCampaignQueryLookup.data.isRetrievingData
                    dashboardContext.isCampaignRetrievalFailure = clientCampaignQueryLookup.data.isCampaignRetrievalFailure
                    dashboardContext.openCampaignCount = clientCampaignQueryLookup.data.openCampaignCount
                    dashboardContext.hasSeenFirstCampaign = clientCampaignQueryLookup.data.hasSeenFirstCampaign
                    dashboardContext.runningCampaignCount = dashboardContext.originalCampaigns.length
                    dashboardContext.setClientCampaignData();

                    let result = new Data.PageResult<Model.PlainObject<ClientCampaignDataLookup>>();
                    result.entityList = dashboardContext.campaignData.slice(request.pageIndex! * request.pageSize!, request.pageIndex! * request.pageSize! + request.pageSize!);
                    result.total = dashboardContext.campaignData.length;
                    return result;
                });
            }
        }

        // when we are paginating or filtering and there is no need to get data back from the API
        return new Promise((resolve, reject) => {

            request.pageSize = this.pageManager.pageSize;

            let result = new Data.PageResult<Model.PlainObject<ClientCampaignDataLookup>>();
            dashboardContext.setClientCampaignData();

            if (dashboardContext.firstFetch) {
                dashboardContext.campaignData.set(dashboardContext.filterInitialClientCampaignData(dashboardContext.originalCampaigns))
            }

            result.entityList = dashboardContext.campaignData.slice(request.pageIndex! * request.pageSize!, request.pageIndex! * request.pageSize! + request.pageSize!);
            result.total = dashboardContext.campaignData.length;
            return resolve(result);
        });
    }

    public async getDefaultRefreshTime() {
        let configList = await (await this.comXConfigApiClient.get()).data;
        let defaultRefreshTime = Number(configList.find(config => config.configCode === "DashboardRefreshTime")?.value);
        this.defaultRefreshTime = defaultRefreshTime;
    }

    public async getMarketFeedbackTrackingLink() {
        let configList = await (await this.comXConfigApiClient.get()).data;
        let marketLink = configList.find((config: any) => config.configCode === textConstants.links.MarketFeedbackTrackingRedirectConfigCode)?.value as string;
        return marketLink;
    }

    public async getClientDashboardInfo(user: any) {

        const response = await this.clientsApiClient.getDashboardInfo(user!.userName);
        const dashboardInfo = response.data as ClientDashboardInfoLookup;
        this.pageManager.criteria!.clientId = dashboardInfo.clientId
        this.hasSeenHighlyRecommended = dashboardInfo.hasSeenRecommendations;
        this.showDashboardNews = dashboardInfo.showDashboardNews;
        this.platformSetupUrl = dashboardInfo.platformSetup;
        this.successTrackerUrl = dashboardInfo.successTracker;
        this.clientId = dashboardInfo.clientId;
        this.isClientReview = dashboardInfo.isClientReview;
        var batchReview = new DashboardLookup()
        batchReview.batchCount = dashboardInfo.batchReviewCount
        this.dashboardLookup = batchReview
        this.dashboardLookup.isCampaignMessagesReview = dashboardInfo.isCampaignMessagesReview
    }

    private async getOnboardingData(user: any) {
        const onboardingResponse = await this.onboardingApiClient.getUserLoginInfo(user!.userName);

        this.customAuthService.globalProps.clientId = onboardingResponse.data.data.clientId
        this.customAuthService.globalProps.isFirstOnboardingLogin = onboardingResponse.data.data.isFirstOnboardingLogin
        this.customAuthService.globalProps.hasSeenTutorial = onboardingResponse.data.data.hasSeenTutorial
        this.customAuthService.globalProps.clientHasSeenTutorial = onboardingResponse.data.data.clientHasSeenTutorial
        this.customAuthService.globalProps.hasSeenTechnicalIntegration = onboardingResponse.data.data.hasSeenTechnicalIntegration
        let steps = onboardingResponse.data.data.clientOnboardingSteps as PartialPlainObject<OnboardingStepLookup>[]

        if (steps !== null) {
            this.customAuthService.globalProps.onboardingSteps.set(steps)
        }
    }

    public async setClientCampaignData() {
        await this.getClientCampaignDataRetrieval()
    }

    private async getClientCampaignDataRetrieval() {
        let data = this.originalCampaigns

        if (this.firstFetch) {
            this.updateFilterEmailDropdown(this.campaignData)
            this.updateStatusFilterDropdown(data)
        }

        // Checks if the client has seen the first campaign
        if (this.clientId && data.length > 0 && !this.hasSeenFirstCampaign && this.customAuthService.globalProps.isClientUser) {
            let response = await this.clientsApiClient.setSeenFirstCampaign(this.clientId);

            if (response.data.success) {
                triggerHotjarEvent(textConstants.Events.clientFirstCampaign)
            }
        }

        this.updateClientCampaignData(this.isRetrievingCampaignData, this.openCampaignCount, this.isCampaignRetrievalFailure, data)
    }

    private async updateClientCampaignData(isRetrievingData: boolean, openCampaignCount: number, isCampaignRetrievalFailure: boolean, data: List<ClientCampaignDataLookup>) {
        this.runningCampaignCount = this.originalCampaigns.filter(f => f.campaignStatus === textConstants.titleText.RunningClientCampaignStatus).length

        if (data) {
            if (isCampaignRetrievalFailure) {
                this.isCampaignDataRetrievalFailure = true;
                this.isRetrievingCampaignData = false;
            }
            else {
                // set
                this.isRetrievingCampaignData = isRetrievingData;
                this.isCampaignDataRetrievalFailure = false;

                if (this.originalCampaigns.length > 0) {
                    let campaigns = data as any

                    if (!this.filterApplied) {
                        this.campaignData.set(campaigns);
                    }
                }
            }
        }
    }

    public async getClientName(clientId: number) {
        const response = await this.taskRunner.waitFor(this.clientsApiClient.getClient(clientId));
        return response.data.clientName;
    }

    public async setClientDisplayName(clientName: string) {
        this.clientDisplayName = clientName
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }

    public async showQuickAdd() {
        this.ClientSettingsViewModel = new ClientSettingsVM();
        this.ClientSettingsViewModel.blacklistedDomainHelper.taskRunner = this.taskRunner;
        this.ClientSettingsViewModel.blacklistedDomainHelper.clientId = this.clientId
        const regexResponse = await this.ClientSettingsViewModel.blacklistedDomainHelper.blacklistedDomainsApiClient.getDomainRegex()
        this.ClientSettingsViewModel.blacklistedDomainHelper.domainRegex = regexResponse.data
        this.ClientSettingsViewModel.blacklistedDomainHelper.showQuickAddModal = true;
    }

    //Add to blacklist function
    public async addToBlacklist() {
        this.ClientSettingsViewModel = new ClientSettingsVM();
        this.ClientSettingsViewModel.blacklistedDomainHelper.taskRunner = this.taskRunner;
        this.ClientSettingsViewModel.blacklistedDomainHelper.clientId = this.clientId
        const regexResponse = await this.ClientSettingsViewModel.blacklistedDomainHelper.blacklistedDomainsApiClient.getDomainRegex()
        this.ClientSettingsViewModel.blacklistedDomainHelper.domainRegex = regexResponse.data
        this.ClientSettingsViewModel.blacklistedDomainHelper.QuickAddString.item = this.blacklistDomain;
        const response = await this.ClientSettingsViewModel.blacklistedDomainHelper.saveQuickAdd(true);

        if (this.ClientSettingsViewModel.blacklistedDomainHelper.QuickAddErrorDomains[0] !== undefined) {
            this.blacklistErrorMessage = textConstants.messageText.validationMessage.invalidDomain + this.ClientSettingsViewModel.blacklistedDomainHelper.QuickAddErrorDomains[0];
        }
        else {
            this.blacklistErrorMessage = ""
        }
    }

    public connectToComXHub() {
        this.comxHub.UpdateDashboardAccess.subscribe(this.updateDashboardLookup);
        this.comxHub.UpdateClientCampaignsData.subscribe(this.updateDashboardData);
    }

    public updateDashboardLookup = (dashboardLookup: DashboardLookup) => {

        this.dashboardLookup = dashboardLookup
    }

    public updateDashboardData = async (dashboardLookup: ClientCampaignQueryLookup) => {

        var currentClientId = this.customAuthService.globalProps.clientId
        this.hasCalledApiForCampaignData = true

        if (dashboardLookup
            && dashboardLookup.clientCampaigns.length > 0
            && dashboardLookup.clientId === currentClientId) {
            this.clientId = currentClientId
            let campaigns = dashboardLookup.clientCampaigns as any

            this.isRetrievingCampaignData = dashboardLookup.isRetrievingData

            this.originalCampaigns.set(campaigns)
            this.updateClientCampaignData(dashboardLookup.isRetrievingData, dashboardLookup.openCampaignCount, dashboardLookup.isCampaignRetrievalFailure, dashboardLookup.clientCampaigns)

            this.pageManager.refreshData()
            await this.getReportsChartSectionData()
        }
        else if (dashboardLookup.clientId === currentClientId
            && dashboardLookup.isCampaignRetrievalFailure) {
            this.isCampaignDataRetrievalFailure = true;
            this.isRetrievingCampaignData = false;
        }
        else {
            this.isRetrievingCampaignData = false;
            this.isCampaignDataRetrievalFailure = false;
        }
    }

    public async UpdateTutorialSeen() {
        const { user } = this.authenticationService;

        let command = new UpdateTutorialSeenCommand();
        command.clientId = this.clientId;
        command.userName = user!.userName;

        await this.onboardingApiClient.updateTutorialSeenIndicator(command);
    }

    //Knowledge hub Password handling (getting it from the DB)
    public async getKnowledgeHubPassword() {
        var comXConfig = this.appDataCache.comXConfigurations.get().data
        let password = "";
        if (comXConfig[0]) {
            password = comXConfig.find((config: any) => config.configCode === textConstants.titleText.KnowledgeHubPasswordDbTitle)?.value as string
        }
        else {
            let config = await this.comXConfigApiClient.get()
            if (config) {
                password = config.data.find((config: any) => config.configCode === textConstants.titleText.KnowledgeHubPasswordDbTitle)?.value as string
            }
        }
        return password;
    }

    public async initialiseDashboardCards() {
        let visitComXCard = await this.getDashboardCardBody(textConstants.titleText.VisitComXDbTitle);
        this.visitComXBody = visitComXCard!.cardText;
        this.visitComXCardRedirect = visitComXCard!.redirectLink;

        let bookSupportCard = await this.getDashboardCardBody(textConstants.titleText.BookSupportDbTitle);
        this.bookSupportBody = bookSupportCard!.cardText;
        this.bookSupportRedirect = bookSupportCard!.redirectLink

        let blacklistCard = await this.getDashboardCardBody(textConstants.titleText.DashboardBlacklist);
        this.blacklistBody = blacklistCard!.cardText;
        this.blacklistURL = blacklistCard!.videoLink;
        let highlyRecommendedCard = await this.getDashboardCardBody(textConstants.titleText.HighlyRecommended);
        this.highlyRecommendedHeader = highlyRecommendedCard!.headerText;
        this.highlyRecommendedBody = highlyRecommendedCard!.cardText;
        this.highlyRecommendedRedirect = highlyRecommendedCard!.redirectLink;
        this.highlyRecommendedVideoLink = highlyRecommendedCard!.videoLink;
        this.imageName = highlyRecommendedCard?.imageName;
    }

    //Getting dashboard data from the DB
    public async getDashboardCardBody(dashboardCardTitle: string) {
        var dashboardConfig = this.appDataCache.dashboardCards.get().data
        let dashboardBody;
        if (dashboardConfig.length > 0 && dashboardConfig[0]) {
            dashboardBody = dashboardConfig.find((config: any) => config.cardName === dashboardCardTitle)
        } else {
            let config = await this.dashboardCardsApiClient.get()
            if (config) {
                dashboardBody = config.data.find((config: any) => config.cardName === dashboardCardTitle)
            }
        }
        return dashboardBody;
    }

    private async getPopupData() {
        this.showFoundersVideo = !this.customAuthService.globalProps.hasSeenTutorial

        if (this.showFoundersVideo) {
            let popupResponse;

            if (this.customAuthService.globalProps.clientHasSeenTutorial) {
                this.showFoundersVideoNewUser = true
                popupResponse = await this.onboardingApiClient.getOnboardingPopupData(textConstants.popUps.foundersPopUpSecondary);
            }
            else {
                popupResponse = await this.onboardingApiClient.getOnboardingPopupData(textConstants.popUps.foundersPopUpPrimary);
            }

            const popupKnowledgeCenterResponse = await this.onboardingApiClient.getOnboardingPopupData(textConstants.popUps.knowledgeHubPopup);
            let data = popupResponse.data;

            if (data.success) {
                this.onboardingPopupLookup = popupResponse.data.data[0];
                this.onboardingPopupKnowledgeCenterLookup = popupKnowledgeCenterResponse.data.data[0];
            }
            else {
                this.notifications.addDanger(textConstants.titleText.SaveFailed, "Failed to retrieve data");
            }
        }
    }

    public async toggleHighlyRecommended(): Promise<boolean> {

        this.hasSeenHighlyRecommended = !this.hasSeenHighlyRecommended
        this.hideHighlyRecommendedCommand.clientId = this.clientId;
        const response = await this.clientsApiClient.hideHighlyRecommended(this.hideHighlyRecommendedCommand);

        if (response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    private async getVideoUrls() {
        var comXConfig = this.appDataCache.comXConfigurations.get().data

        if (comXConfig[0] !== undefined) {
            this.foundersVideoURL = comXConfig.find((config: any) => config.configCode === textConstants.generalText.FoundersVideo)?.value as string
            this.knowledgeHubVideoURL = comXConfig.find((config: any) => config.configCode === textConstants.generalText.knowledgeHubVideo)?.value as string
        }
        else {
            comXConfig = (await this.comXConfigApiClient.get()).data
            this.foundersVideoURL = comXConfig.find((config: any) => config.configCode === textConstants.generalText.FoundersVideo)?.value as string
            this.knowledgeHubVideoURL = comXConfig.find((config: any) => config.configCode === textConstants.generalText.knowledgeHubVideo)?.value as string
        }
    }

    public async triggerRequestFullAnalysis() {

        this.taskRunner.run(async () => {
            let requestAnalysis = (await this.clientsApiClient.RequestFullAnalysis(this.clientId)).data as boolean
            if (requestAnalysis) {
                this.notifications.addSuccess(textConstants.messageText.saveMessage.RequestFullAnalysisSuccess, textConstants.messageText.saveMessage.RequestFullAnalysisSuccessMessage)
            } else {
                this.notifications.addDanger(textConstants.messageText.saveMessage.RequestFullAnalysisFail, textConstants.messageText.saveMessage.RequestFullAnalysisFailMessage)
            }
        });

    }

    public updateStatusFilterDropdown(filteredCampaignData: List<ClientCampaignDataLookup>) {
        this.clientCampaignStatuses = new List(WoodpeckerCampaignStatus);

        // Populating status filter dropdown
        filteredCampaignData.forEach(campaign => {

            let status = new WoodpeckerCampaignStatus()

            status.woodpeckerCampaignStatusId = campaign.campaignStatusId
            status.woodpeckerCampaignStatusName = campaign.campaignStatus
            status.isOpenStatus = campaign.isOpenStatus

            let tempStatus = this.clientCampaignStatuses.find(e => e.woodpeckerCampaignStatusId === status.woodpeckerCampaignStatusId)?.woodpeckerCampaignStatusName

            if (!tempStatus) {
                status.woodpeckerCampaignStatusName = status.woodpeckerCampaignStatusName.toLowerCase()
                status.woodpeckerCampaignStatusName = status.woodpeckerCampaignStatusName.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
                this.clientCampaignStatuses.push(status);
            }
        })
    }

    public updateFilterEmailDropdown(data: List<ClientCampaignDataLookup>) {
        this.clientCampaignEmails = new List(WoodpeckerCampaignEmail);
        // Populating email filter dropdown
        if (data) {
            data.forEach(c => {
                if (c.emailSender !== null && c.emailSender !== '') {
                    let tempEmail = this.clientCampaignEmails.find(e => e.emailSender === c.emailSender)?.emailSender

                    if (!tempEmail) {
                        let campaignEmail = new WoodpeckerCampaignEmail()
                        campaignEmail.emailSender = c.emailSender
                        campaignEmail.clientCampaignId = c.clientCampaignId
                        this.clientCampaignEmails.push(campaignEmail)
                    }
                }

            })
        }
    }

    // Sets the client campaign dashboard filter to 'running' when the user first loads the dashboardView
    public filterInitialClientCampaignReportingData() {
        let runningStatusId = this.clientCampaignStatuses.find(c => c.woodpeckerCampaignStatusName === textConstants.generalText.runningClientCampaignReportStatus)?.woodpeckerCampaignStatusId

        if (runningStatusId) {
            this.selectedReportingStatusId = runningStatusId
        }
    }

    public getFilteredCampaigns() {
        let filteredCampaignData;

        if (!this.selectedEmailerClientCampaignId) {
            this.selectedEmailerClientCampaignId = 0
        }

        if (!this.selectedCampaignStatusId) {
            this.selectedCampaignStatusId = 0
        }

        if (this.filterApplied && (this.selectedCampaignStatusId !== 0 && this.selectedEmailerClientCampaignId !== 0)) {
            let tempEmail = this.originalCampaigns.find(x => x.clientCampaignId === this.selectedEmailerClientCampaignId)?.emailSender

            // when both status and email filters are used
            filteredCampaignData = this.originalCampaigns.filter(x => (x.campaignStatusId === this.selectedCampaignStatusId && x.emailSender === tempEmail)) as any

            if (this.emailChange) {
                this.updateStatusFilterDropdown(filteredCampaignData)
                this.emailChange = false
            }

            this.campaignData.set(filteredCampaignData)

        } else if ((this.selectedCampaignStatusId !== 0 && this.selectedEmailerClientCampaignId === 0)) {

            // when status filter only is being used
            filteredCampaignData = this.originalCampaigns.filter(x => x.campaignStatusId === this.selectedCampaignStatusId) as any
            this.updateFilterEmailDropdown(filteredCampaignData)
            this.updateStatusFilterDropdown(this.originalCampaigns)

            this.campaignData.set(filteredCampaignData)

        } else if ((this.selectedCampaignStatusId === 0 && this.selectedEmailerClientCampaignId !== 0)) {

            // when email filter only is being used
            let tempEmail = this.originalCampaigns.find(x => x.clientCampaignId === this.selectedEmailerClientCampaignId)?.emailSender
            filteredCampaignData = this.originalCampaigns.filter(x => x.emailSender === tempEmail) as any

            if (this.emailChange) {
                this.updateStatusFilterDropdown(filteredCampaignData)
                this.updateFilterEmailDropdown(this.originalCampaigns)
                this.emailChange = false
            }

            this.campaignData.set(filteredCampaignData)

        } else {

            // when no filter is being used
            this.updateFilterEmailDropdown(this.originalCampaigns)
            this.updateStatusFilterDropdown(this.originalCampaigns)

            this.campaignData.set(this.originalCampaigns as any)
        }

        this.dataHasChanged = true;
        this.pageManager.refreshData()
    }

    public async refreshDashboard() {
        let isRefreshAllowed = (await this.clientsApiClient.IsRefreshAllowed(this.clientId)).data.data as boolean

        if (isRefreshAllowed) {
            // Put this back to initial state, so that chart doesnt break.
            this.chartType = ChartType.SentOutEmails
            this.selectedClientCampaignId = 0
            this.filteredQueryList = this.campaignMessageReportingData
            this.chartDataPropertyName = textConstants.GraphData.campaignMessageLineChartPropertyName

            this.taskRunner.run(async () => {
                // Refresh data
                this.pageManager.criteria!.isForced = true

                this.filterApplied = true
                this.firstFetch = false

                this.campaignData = new List(ClientCampaignDataLookup)

                this.hasCalledApiForCampaignData = false
                await this.pageManager.refreshData()

                // Save the Last Refresh timestamp
                let setRefreshCommand = new SaveRefreshTimeCommand
                setRefreshCommand.clientId = this.clientId
                await this.clientsApiClient.saveRefreshTime(setRefreshCommand)

                // Display the new Last Refresh timestamp
                await this.getLastRefreshTimestamp()
            })
        } else {
            await this.getDefaultRefreshTime()
            this.showRefreshModal = true
        }
    }

    public async getLastRefreshTimestamp() {
        // need client ID before running page manager
        this.lastRefresh = (await this.clientsApiClient.getLastRefreshTimestamp(this.clientId)).data.data as string
    }

    // ######################################################################################################
    // #                           REPORTING CHART FUNCTIONS START                                          #
    // ######################################################################################################

    // Reports Section Data Retrieval
    public async getReportsChartSectionData() {
        await this.filterInitialClientCampaignReportingData();
        // Set up Drop Down of Chart Types
        this.setReportingChartTypeFilterSelections();

        // Get pie chart data
        await this.getPieChartReportingData();

        if (this.clientCampaignStatuses && !this.clientCampaignStatuses.find(status => status.woodpeckerCampaignStatusName === textConstants.titleText.Running)) {
            this.chartStatus = "";
        }

        await this.getRepliesOverTimeData();

        // Get data for multiple report types: Sent Count, Bounce Rate, Open Rate
        await this.getDailyCampaignMessageReportingData()

        // Get the minimum data length at which charts will be displayed
        await this.setReportingMinLengthValues();

        // Check if the dataset has met the standard provided above
        this.CheckMinimumReportingRequirement();
    }

    private CheckMinimumReportingRequirement() {
        if (this.filteredQueryList.length >= this.minReportingLength) {
            this.isEnoughData = true;
        }
        else {
            this.filteredQueryList = new Array;
        }
    }

    // For getting pie chart subtexts from maintenance.
    public async getPieChartSubtext() {
        var dashboardReportConfig = this.appDataCache.dashboardReportConfigs.get().data

        var chartSubtexts =
        {
            ReadyToRetargetSubText: '',
            NotYetContactedSubText: '',
            ReadyForSendOutSubText: ''
        }

        if (dashboardReportConfig.length > 0 && dashboardReportConfig[0]) {
            chartSubtexts.ReadyToRetargetSubText = dashboardReportConfig.find((config: any) => config.configCode === textConstants.GraphData.pieChartLegendSubtext.ReadyToRetarget)?.value as unknown as string
            chartSubtexts.NotYetContactedSubText = dashboardReportConfig.find((config: any) => config.configCode === textConstants.GraphData.pieChartLegendSubtext.NotYetContacted)?.value as unknown as string
            chartSubtexts.ReadyForSendOutSubText = dashboardReportConfig.find((config: any) => config.configCode === textConstants.GraphData.pieChartLegendSubtext.ReadyForSendOut)?.value as unknown as string

        }
        else {
            let config = await this.dashboardReportConfigApiClient.get()

            if (config) {
                chartSubtexts.ReadyToRetargetSubText = config.data.find((config: any) => config.configCode === textConstants.GraphData.pieChartLegendSubtext.ReadyToRetarget)?.value as unknown as string
                chartSubtexts.NotYetContactedSubText = config.data.find((config: any) => config.configCode === textConstants.GraphData.pieChartLegendSubtext.NotYetContacted)?.value as unknown as string
                chartSubtexts.ReadyForSendOutSubText = config.data.find((config: any) => config.configCode === textConstants.GraphData.pieChartLegendSubtext.ReadyForSendOut)?.value as unknown as string
            }
        }

        return chartSubtexts;
    }

    // Pie Chart getting data
    public async getPieChartReportingData() {
        this.pieChartSeriesData = (await this.clientsApiClient.getPieChartReportingData(this.clientId)).data as any

        this.pieChartSeriesNames = textConstants.GraphData.pieChartSeriesNames;
        this.pieChartLegendSubtexts = await this.getPieChartSubtext() as any;
        this.pieChartSeriesColours = textConstants.GraphData.pieChartColours

        this.setPieChartDataValues()
    }

    public setPieChartDataValues() {
        this.pieChartAggregatedDataValues = {
            ReadyToRetarget: this.pieChartSeriesData.aggregatedProgressProspected,
            NotYetContacted: this.pieChartSeriesData.aggregatedProgressToDo,
            ReadyForSendOut: this.pieChartSeriesData.aggregatedProgressDone
        }
        this.pieChartTargetMarkets.set(this.pieChartSeriesData.targetMarketReportingDataLookups)
    }

    public relevantPieChartDataSelection() {
        if (this.selectedPieChartTargetMarketId === 0 || this.selectedPieChartTargetMarketId === null) {
            return this.populatePieChartDataSection(this.pieChartAggregatedDataValues.ReadyToRetarget, this.pieChartAggregatedDataValues.NotYetContacted, this.pieChartAggregatedDataValues.ReadyForSendOut)
        } else {
            let filterTargetMarket = this.pieChartTargetMarkets.find(c => c.targetMarketId === this.selectedPieChartTargetMarketId)
            return this.populatePieChartDataSection(filterTargetMarket!.progressProspected, filterTargetMarket!.progressToDo, filterTargetMarket!.progressDone)
        }
    }

    public populatePieChartDataSection(readyToRetarget: number, notYetContacted: number, readyForSendOut: number) {

        var pieReturnData: any = [{
            name: `<b>${this.pieChartSeriesNames.ReadyToRetarget} (${readyToRetarget}%): </b> </br><i>${this.pieChartLegendSubtexts.ReadyToRetargetSubText}</i>`,
            y: readyToRetarget,
            color: this.pieChartSeriesColours.ReadyToRetarget,
            selected: true,
            custom: textConstants.GraphData.pieChartSeriesNames.ReadyToRetarget,
        },
        {
            name: `<b>${this.pieChartSeriesNames.ReadyForSendOut} (${readyForSendOut}%): </b> </br><i>${this.pieChartLegendSubtexts.ReadyForSendOutSubText}</i>`,
            y: readyForSendOut,
            color: this.pieChartSeriesColours.ReadyForSendOut,
            selected: true,
            custom: textConstants.GraphData.pieChartSeriesNames.ReadyForSendOut,
        },
        {
            name: `<b>${this.pieChartSeriesNames.NotYetContacted} (${notYetContacted}%): </b> </br><i>${this.pieChartLegendSubtexts.NotYetContactedSubText}</i>`,
            y: notYetContacted,
            color: this.pieChartSeriesColours.NotYetContacted,
            selected: true,
            custom: textConstants.GraphData.pieChartSeriesNames.NotYetContacted,
        },]

        return pieReturnData
    }

    // COndition to check if the charts are monthly
    public monthlyCharts(chartType: ChartType) {
        return chartType === ChartType.ReplyRate || chartType === ChartType.RepliesOverTime;
    }

    // Condition to check if the charts are daily.
    public dailyCharts(chartType: ChartType) {
        return chartType === ChartType.BouncedRate || chartType === ChartType.OpenRate || chartType === ChartType.SentOutEmails
    }

    // Needs to get the data again
    public ReportingStatusChanged() {

        let status = this.clientCampaignStatuses.find(x => x.woodpeckerCampaignStatusId === this.selectedReportingStatusId)

        this.chartStatus = status ? status.woodpeckerCampaignStatusName : "";

        this.reinitialize();
    }

    // Full Reinitialize
    public async reinitialize() {
        this.selectedClientCampaignId = 0;
        this.filteredQueryList = []
        this.chartSeries = []

        await this.getRepliesOverTimeData()
        await this.getDailyCampaignMessageReportingData()
        this.CheckMinimumReportingRequirement();
    }

    // The Chart Type filter selection setup (Drop down values)
    public async setReportingChartTypeFilterSelections() {
        this.reportingChartSelections = new List(ReportingChartsFilter)

        // Pushing the list of chart types for filter
        for (let i = 1; i < this.reportingCharts.length; i++) {
            let currentChartType = new ReportingChartsFilter()
            currentChartType.chartName = this.reportingCharts[i]
            currentChartType.chartId = i;

            this.reportingChartSelections.push(currentChartType)
        }
    }

    // Gets Data for replies over time chart (Campaign Report)
    public async getRepliesOverTimeData() {
        let response = (await this.clientsApiClient.getArchivedMonthlyClientCampaigns(this.clientId, (!this.chartStatus) ? textConstants.Dashboard.allStatusesFilter : this.chartStatus)).data as any;
        let allArchivedCampaigns = response
        this.archivedCampaignData = (allArchivedCampaigns.archiveCampaigns);

        if (this.monthlyCharts(this.chartType)) {
            this.chartDataPropertyName = textConstants.GraphData.repliesChartPropertyName

            this.initializeChartData(this.archivedCampaignData)
        }
    }

    // Gets Data for Email Charts:
    // Sent Count, Bounce Rate, Open Rate
    // Is per day values for emails of each campaign
    public async getDailyCampaignMessageReportingData() {
        let response = (await this.clientsApiClient.getLineChartReportingData(this.clientId, (!this.chartStatus) ? textConstants.Dashboard.allStatusesFilter : this.chartStatus)).data as any
        let allSentOutEmails = response;
        this.campaignMessageReportingData = allSentOutEmails.dailyCampaignMessageReportingDataLookups;

        this.getSeriesNames(textConstants.GraphData.repliesOverTimeNumber, this.campaignMessageReportingData, true);

        if (this.dailyCharts(this.chartType)) {
            this.chartDataPropertyName = textConstants.GraphData.campaignMessageLineChartPropertyName

            this.initializeChartData(this.campaignMessageReportingData)

        }
    }

    // Initial charts when the charts are set it checks what type it is.
    public initializeChartData(chartArray: any[]) {
        this.filteredQueryList = chartArray
        this.filteredByChartList = Array.from(chartArray)

        this.setDateMinMax(chartArray)
        this.datePicker(0, chartArray)
        this.setReportingCampaignsFilterSelections(chartArray)
    }

    // The Campaign Selection Filter
    public setReportingCampaignsFilterSelections(campaignData: any) {
        this.reportingCampaignSelections = new List(ReportingClientCampaignFilter)

        // Gets the individual campaigns
        let distinctCampaigns = this.getDistinctCampaigns(campaignData, this.chartDataPropertyName)

        // Gets the campaigns Name and campaigns Ids for filter
        distinctCampaigns.forEach(selectedCampaign => {
            let reportingFilter: ReportingClientCampaignFilter = new ReportingClientCampaignFilter
            reportingFilter.clientCampaignId = selectedCampaign[0]
            reportingFilter.campaignName = selectedCampaign[1]

            let clientCampaignMatch = this.reportingCampaignSelections.find(x => x.clientCampaignId === reportingFilter.clientCampaignId)

            if (clientCampaignMatch?.clientCampaignId !== reportingFilter.clientCampaignId) {
                this.reportingCampaignSelections.push(reportingFilter)
            }
        })
    }

    // Gets the minimum length needed for the chart to be displayed correctly
    public async setReportingMinLengthValues() {
        this.notEnoughDataOnChart = textConstants.generalText.enoughChartData

        // Gets the minimum length from the configurations.
        let comXConfig: any = this.appDataCache.comXConfigurations.get().data
        if (comXConfig[0]) {
            var reportingDataConfig = comXConfig.find((config: any) => config.configCode === textConstants.generalText.reportingDataMininumLength)
            this.minReportingLength = reportingDataConfig.value;
        }
    }

    // Gets the clientCampaignId and Name
    public getDistinctCampaigns(campaignData: any, propertyName: string) {
        let clientCampaigns = new Array;

        campaignData.forEach((dailyCampaigns: any) => {
            // Gets the data from the campaign, after grouping the clientId
            let grouped = dailyCampaigns[propertyName].groupBy((groupedCampaign: any) => groupedCampaign.clientCampaignId)

            // For each item in grouped push the Id and name for the filters
            grouped.forEach((campaign: any) => {
                clientCampaigns.push([(campaign.details[0].clientCampaignId), (campaign.details[0].campaignName)])
            })
        })

        return clientCampaigns
    }

    // Sets the content for the charts based on which chart has been selected.
    public setSelectedChart(chartType: number) {
        let isNotCampaignEmailsChart = (chartType !== ChartType.PieChart
            && chartType !== ChartType.RepliesOverTime
            && chartType !== ChartType.ReplyRate)

        if (!chartType) {
            this.chartType = ChartType.SentOutEmails;
        }

        if (isNotCampaignEmailsChart) {
            chartType = this.setSelectedLineChartDefaultValues(chartType)
        }

        this.pieChartActive = false
        this.selectedPieChartTargetMarketId = 0
        this.isPercentReporting = false

        switch (chartType) {
            case ChartType.BouncedRate:
                this.isPercentReporting = true
                break

            case ChartType.OpenRate:
                this.isPercentReporting = true
                break

            case ChartType.RepliesOverTime:
                this.chartType = chartType;
                this.chartDataPropertyName = textConstants.GraphData.repliesChartPropertyName
                this.setDateMinMax(this.archivedCampaignData)
                this.filteredByChartList = Array.from(this.archivedCampaignData)
                this.setReportingCampaignsFilterSelections(this.archivedCampaignData)
                this.datePicker(this.selectedClientCampaignId, this.archivedCampaignData);
                break

            case ChartType.PieChart:
                this.pieChartActive = true
                break;

            case ChartType.ReplyRate:
                this.isPercentReporting = true;
                this.chartType = chartType;
                this.chartDataPropertyName = textConstants.GraphData.repliesChartPropertyName
                this.setDateMinMax(this.archivedCampaignData)
                this.filteredByChartList = Array.from(this.archivedCampaignData)
                this.setReportingCampaignsFilterSelections(this.archivedCampaignData)
                this.datePicker(this.selectedClientCampaignId, this.archivedCampaignData);
                break;

            default:
                // ChartType.SentOutEmails will come in here as all default values above apply to it
                break;
        }
    }

    private setSelectedLineChartDefaultValues(chartType: number) {
        // for some reason the first value binds as null, thus we need to default it
        if (!chartType) {
            chartType = chartType === null ? ChartType.SentOutEmails : chartType
        }

        // Default Values for line chart
        this.hasDateChanged = false
        this.isPercentReporting = false

        //Property which is used to identify where to look for data for selected chart 
        this.chartDataPropertyName = textConstants.GraphData.campaignMessageLineChartPropertyName
        this.notEnoughDataOnChart = textConstants.generalText.enoughChartData

        this.setDateMinMax(this.campaignMessageReportingData)

        // Getting the correct data for the chart
        this.filteredByChartList = Array.from(this.campaignMessageReportingData)

        // If a campaign is selected the data selection is handled here
        this.setReportingCampaignsFilterSelections(this.campaignMessageReportingData)

        // Set min and max date values for chart
        this.datePicker(this.selectedClientCampaignId, this.campaignMessageReportingData);

        if (!this.selectedClientCampaignId) {
            this.selectedClientCampaignId = 0
        }

        return chartType
    }

    // Sets up the reporting data based on filters applied on 
    public getSelectedCampaignData(clientCampaignId: number = 0, campaignData?: any[]) {

        if (!clientCampaignId) {
            this.selectedClientCampaignId = 0
        }

        if (clientCampaignId && campaignData) {
            //Gets the specific campaign to display

            let filteredData = campaignData.filter(campaign => {
                return campaign[this.chartDataPropertyName].some((selectedCampaign: any) => selectedCampaign.clientCampaignId === clientCampaignId);
            });

            this.filteredQueryList = filteredData.map(campaign => {
                const filteredLookup = campaign[this.chartDataPropertyName].find((selectedCampaign: any) => selectedCampaign.clientCampaignId === clientCampaignId)!;
                const filteredQuery = new ReportingCampaignFilter;

                filteredQuery.reportingData.push(filteredLookup);
                filteredQuery.month = campaign.month;
                filteredQuery.year = campaign.year;

                if (campaign.day !== null) {
                    filteredQuery.day = campaign.day;
                }

                return filteredQuery;
            });
        }
        else {
            //Gets all data
            this.filteredQueryList = Array.from(campaignData!)
        }

        if (this.hasCampaignChanged) {
            this.setDateMinMax(this.filteredQueryList);
            this.hasCampaignChanged = false;
        }

        if (this.filteredQueryList.length <= (this.minReportingLength - 1)) {
            // if minimum requirement is not met, reset to display empty graph
            this.filteredQueryList = new Array;
            this.isEnoughData = false;
        }
        else {
            this.isEnoughData = true;
        }
    }

    // Gets the specific month and year for the x-axis.
    public getMonthYear(campaignData: any[]) {
        var monthYear: string[] = []

        campaignData.forEach(x => {
            monthYear.push(`${this.monthConversion[x.month - 1]} ${x.year}`)
        });

        return monthYear
    }

    // Gets the specific day and month for the x-axis.
    public getDayMonth(campaignData: any[]) {
        var dayMonth: string[] = []

        campaignData.forEach(x => {
            dayMonth.push(`${x.day} ${this.monthConversion[x.month - 1]}`)
        });

        return dayMonth
    }

    // Sets the datapicker min data and max date based on the data series coming through.
    public setDateMinMax(campaignData: any) {
        let lastIndex: any = undefined
        let firstIndex: any = undefined
        let isMonthlyReport: boolean = false;

        if (campaignData.length > 0) {
            lastIndex = campaignData[campaignData.length - 1]
            firstIndex = campaignData[0]
        }

        isMonthlyReport = this.chartType === ChartType.RepliesOverTime || this.chartType === ChartType.ReplyRate

        if (!lastIndex) {
            // If no data comes through (return as defaults are already set)
            return;
        }
        else if (isMonthlyReport) {
            // happens when data is monthly (no day value)
            firstIndex.day = 1
            lastIndex.day = 1
        }

        this.maxDate = this.GetDateFromDataObject(lastIndex)
        this.globalMaximum = this.GetDateFromDataObject(lastIndex)

        let originalfirstIndex = firstIndex
        if (!isMonthlyReport) {
            // We need to run this to only select 2 months worth of data initially (Dont want to show too much data initially)
            firstIndex = this.SetNewFirstDate(campaignData, firstIndex, this.maxDate);
        }

        this.minDate = this.GetDateFromDataObject(firstIndex)
        this.globalMinimum = this.GetDateFromDataObject(originalfirstIndex)
    }

    private GetDateFromDataObject(data: any) {
        if (data) {
            if (data.year && data.month && data.day) {
                //Only do it when all these factors are met.
                return new Date(`${data.year}-${(data.month).toString().padStart(2, '0')}-${(data.day).toString().padStart(2, '0')}`)
            }
        }

        // else return the current date
        return new Date()
    }

    private SetNewFirstDate(campaignData: any, firstIndex: any, maxDate: Date, recursionCounter: number = 0) {
        // We will only try to run this 3 times deep, then we will return the original first date
        if (recursionCounter === 3) {
            return firstIndex;
        }

        let dateFrom = new Date(maxDate); // Set date to max date

        dateFrom.setDate(dateFrom.getDate() - 61); // Go back 2 months
        let dateFromMoment = moment(dateFrom);

        let newCampaignRange = campaignData.filter((f: any) => {
            let currentItemDate = moment(new Date(f.year, f.month - 1, f.day));
            return currentItemDate.isAfter(dateFromMoment);
        });

        if (newCampaignRange[0]) {
            //Only if it has items, return this.
            firstIndex = newCampaignRange[0];
        }
        else {
            recursionCounter++
            firstIndex = this.SetNewFirstDate(campaignData, firstIndex, dateFrom, recursionCounter)
        }

        return firstIndex;
    }

    // Method Called in Dashboard Reports View (by to get the labels and display data)
    public setLineChartSeriesValues(series: string[], filteredCampaignData: any[]) {
        let chartSeries: number[][] = []

        let currentDataFilter = filteredCampaignData;

        // If it is all campaigns then changes series to total values
        let outcome = this.getSeriesProperties(currentDataFilter, series)

        currentDataFilter = outcome[0]
        series = outcome[1]

        series.forEach(property => {
            chartSeries.push(this.setLineChartValues(property, currentDataFilter))
        })
        return chartSeries!
    }

    // Gets the name of the different chart series names.
    public getSeriesNames(series: any, currentDataFilter: any, isOnlySeriesName: boolean = false) {

        this.seriesNames = new Array;

        var totalSeries = this.getSeriesProperties(currentDataFilter, series, isOnlySeriesName)

        let seriesLength = totalSeries[1].length - 1

        if (seriesLength > 0) {
            for (var i = 1; i <= seriesLength; i++) {
                this.seriesNames.push(`Email ${i}`)
            }
        }
    }

    private getSeriesProperties(currentDataFilter: any, series: any, isOnlySeriesName: boolean = false) {
        if (this.chartType === ChartType.RepliesOverTime && !isOnlySeriesName) {
            series = this.selectedClientCampaignId === 0
                ? textConstants.GraphData.totalRepliesOverTimeNumber
                : textConstants.GraphData.repliesOverTimeNumber

            return [currentDataFilter, series]!
        }
        else if (this.chartType === ChartType.ReplyRate && !isOnlySeriesName) {
            series = this.selectedClientCampaignId === 0
                ? textConstants.GraphData.totalReplyRateOverTimeNumber
                : textConstants.GraphData.replyRateOverTime

            return [currentDataFilter, series]!
        }

        // If there is no data, dont bother doing any of the encapsulated functions
        // It will break since the data is required to get the keys (series) and the values for the chart
        if (currentDataFilter.length > 0 && currentDataFilter[0]) {
            let seriesValuesObject: any

            if (this.chartType === ChartType.SentOutEmails || isOnlySeriesName) {
                seriesValuesObject = this.selectedClientCampaignId === 0
                    ? currentDataFilter[0].sentCountTotals
                    : currentDataFilter[0].reportingData[0].campaignSentCountValues

                currentDataFilter = this.selectedClientCampaignId === 0
                    ? currentDataFilter.map((x: any) => x.sentCountTotals)
                    : currentDataFilter.map((x: any) => x.reportingData[0].campaignSentCountValues)

            }
            else if (this.chartType === ChartType.BouncedRate) {
                seriesValuesObject = this.selectedClientCampaignId === 0
                    ? currentDataFilter[0].bouncedRateTotals
                    : currentDataFilter[0].reportingData[0].campaignBouncedRateValues

                currentDataFilter = this.selectedClientCampaignId === 0
                    ? currentDataFilter.map((x: any) => x.bouncedRateTotals)
                    : currentDataFilter.map((x: any) => x.reportingData[0].campaignBouncedRateValues)
            }
            else if (this.chartType === ChartType.OpenRate) {
                seriesValuesObject = this.selectedClientCampaignId === 0
                    ? currentDataFilter[0].openRateTotals
                    : currentDataFilter[0].reportingData[0].campaignOpenRateValues

                currentDataFilter = this.selectedClientCampaignId === 0
                    ? currentDataFilter.map((x: any) => x.openRateTotals)
                    : currentDataFilter.map((x: any) => x.reportingData[0].campaignOpenRateValues)
            }

            series = Object.keys(seriesValuesObject)
            series.remove("$id")    // These keys will be values straight from the object coming from the backend. Those contain Ids
        }

        return [currentDataFilter, series]!
    }

    // Sets the Line chart series
    public setLineChartValues(propertyName: string, campaignData: any[]) {
        let values: any[] = []

        var isNotReportingDataCheckChartTypes = this.chartType !== ChartType.RepliesOverTime && this.chartType !== ChartType.ReplyRate

        campaignData.forEach(campaign => {
            if (this.selectedClientCampaignId === 0 || isNotReportingDataCheckChartTypes) {
                values.push(campaign[propertyName])
            }
            else {
                //Checks the archived campaigns and pushes the values.
                campaign.reportingData.forEach((selectedCampaign: any) => {
                    values.push(selectedCampaign[propertyName])
                })
            }
        })

        return values!
    }

    // Used by Column charts and also Replies over time line chart to set the values in the chart
    public setSeriesValues(series: string[], filterCampaignData: any = this.campaignData, showGrandTotal: boolean = false) {
        var chartSeries: number[][] = []

        series.forEach(x => {
            chartSeries.push(this.setChartValues(x, filterCampaignData, showGrandTotal));
        })

        if (this.dataHasChanged) {
        }

        return chartSeries!
    }

    // Sets the chart values for the column chart
    public setChartValues(propertyName: string, filterCampaignData: any = this.campaignData, showGrandTotal: boolean = false) {
        let values = []

        for (let i = 0; i < filterCampaignData.length; i++) {
            let propertyValue = filterCampaignData[i][propertyName];

            if (propertyName === "emailNumber") {
                propertyValue = "Email " + propertyValue
            }

            values.push(propertyValue)
        }

        if (showGrandTotal) {
            if (!this.isPercent && filterCampaignData.length > 1) {
                // Create 'Grand Total' category
                if (propertyName === "campaignName") {
                    values.push("Grand Total")
                }

                // Calculate Grand Totals
                if (propertyName !== "campaignName") {
                    var total = 0
                    for (let i = 0; i < filterCampaignData.length; i++) {
                        total += filterCampaignData[i][propertyName]
                    }
                    values.push(total)
                }
            }
        }

        return values!
    }

    // Checks if the data has changed, this will stop the constant rerender.
    public hasDataChanged() {
        if (this.dataHasChanged) {
            this.dataHasChanged = false;
            return true
        }
        return false
    }

    //Converts the data to a number for comparisons
    public getDateConverted(date: Date) {
        return Number(`${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${(date.getDate()).toString().padStart(2, '0')}`);
    }

    // Organizes the dates for the charts.
    public datePicker(clientCampaignId: number, campaignFiltered: any[]) {
        let datePickerFilteredList: any[] = new Array;

        var minDate = this.getDateConverted(this.minDate)
        var maxDate = this.getDateConverted(this.maxDate)

        // Checks if min and max date are equal
        if (maxDate !== minDate && campaignFiltered !== null) {

            campaignFiltered.forEach(archivedLookup => {

                // Sets the date to a number for comparison
                var dateNumber = ((archivedLookup.year * 10000) + (archivedLookup.month * 100))

                // Makes sure there is a day in the archived lookup
                if (archivedLookup.day == undefined) {
                    dateNumber += 1
                }
                else if (archivedLookup.day !== null) {
                    dateNumber += archivedLookup.day
                }
                else {
                    dateNumber++;
                }

                if (dateNumber >= minDate && dateNumber <= maxDate) {
                    // If in range push the lookup
                    datePickerFilteredList.push(archivedLookup)
                }
            })
        }

        this.getSelectedCampaignData(clientCampaignId, datePickerFilteredList)
    }

    public additionalOptionsColumnChart() {
        const options: any =
            { yAxis: { min: undefined, max: undefined }, isWoodpeckerEmailOverview: false }

        options.isWoodpeckerEmailOverview = this.isWoodpeckerEmailOverview

        if (this.isPercent) {
            options.yAxis.max = 100;
            options.yAxis.min = 0;
        }
        else {
            options.yAxis.max = undefined;
            options.yAxis.min = undefined;
        }
        return options;
    }

    public noDataPointsRendered() {
        const options: any = { yAxis: { min: undefined, max: undefined }, xAxis: { min: undefined, max: undefined, labels: { enabled: true } }, legend: { enabled: true } }

        // This switches the chart to have a base layout when no data is available.
        if (this.isEnoughData !== null) {
            var isEnoughData = this.isEnoughData

            if (isMobile()) {
                options.legend.enabled = false;
            }

            if (!isEnoughData || this.isPercentReporting) {
                options.yAxis.max = 100;
                options.yAxis.min = 0;

                if (!isEnoughData) {
                    options.xAxis.max = 10;
                    options.xAxis.min = 0;
                    options.xAxis.labels.enabled = false;
                }
                else {
                    options.xAxis.max = undefined;
                    options.xAxis.min = undefined;
                    options.xAxis.labels.enabled = true;
                }
            }
            else {
                options.yAxis.max = undefined;
                options.yAxis.min = undefined;

                options.xAxis.max = undefined;
                options.xAxis.min = undefined;
                options.xAxis.labels.enabled = true;
            }
        }

        return options
    }

    // ######################################################################################################
    // #                           REPORTING CHART FUNCTIONS END                                            #
    // ######################################################################################################
}