import {ModelBase, NeoModel, Rules} from '@singularsystems/neo-core';

@NeoModel
export default class TargetMarketSubRegion extends ModelBase {

    public targetMarketSubRegionId: number = 0;   
    
    public targetMarketId: number = 0;

    @Rules.Required()
    public subRegionId: number = 0;  
}