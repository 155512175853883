import React from 'react';
import * as Highcharts from 'highcharts/highstock'
import { observer } from 'mobx-react';
import HighchartsReact from 'highcharts-react-official';
import { textConstants } from '../common/textConstants';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

interface IColumnChartProps {
  title?: string;
  SeriesData: number[][];
  SeriesNames: string[];
  SeriesColours?: string[];
  XAxisCategories: string[];
  additionalOptions?: any;
  hasDataChanged: () => boolean;
  children?: React.ReactNode;
  visibleSeries?: number[];
}

class SeriesDetails {
  name: string = "";
  data: number[] = [];
  colour: string = "";
  visible: boolean = false;
}

@observer
export default class ColumnChart extends React.Component<IColumnChartProps> {
  public chartRef: React.RefObject<HighchartsReact.RefObject>;

  constructor(props: any) {
    super(props);
    this.chartRef = React.createRef();
  }

  public seriesCollection: Array<SeriesDetails> = new Array<SeriesDetails>();

  public highchartOptions: any;

  public hasDataChanged: boolean = false;

  public Options: any;

  state = {
    isMouseOver: false,
    isInitialRender: true,
  }

  public EmailOverviewExporting() {
    //Styling for the x value of the highcharts for specifically the WoodpeckerEmailOverview
    if (this.props.additionalOptions) {
      if (this.props.additionalOptions.isWoodpeckerEmailOverview) {
        return -24
      }
    }
    return 0
  }

  // Populates the data into the chart
  public populateData() {
    this.seriesCollection = new Array<SeriesDetails>();
    const { SeriesNames, SeriesData, SeriesColours } = this.props;

    for (var i = 0; i < SeriesData.length; i++) {

      var seriesDetails = new SeriesDetails()
      seriesDetails.name = SeriesNames[i]
      seriesDetails.data = SeriesData[i]
      seriesDetails.colour = SeriesColours ? SeriesColours[i] : "#818888"

      // Checks if there is a visibleSeries and if there is finds the specified indexes, else mark them false, BUT if there is no visibleSeries sets it to true.
      seriesDetails.visible = (this.props.visibleSeries && (!this.props.visibleSeries?.find(visibleMarker => visibleMarker === i) ?? false)) ?? true

      this.seriesCollection.push(seriesDetails)
    }
  }

  public setupSeriesData(fullSeries: SeriesDetails[]) {

    var series: any = []
    for (var i = 0; i < this.seriesCollection.length; i++) {
      series.push({ name: fullSeries[i].name, data: fullSeries[i].data, color: fullSeries[i].colour!, visible: fullSeries[i].visible })
    }

    return series
  }

  private calculateXAxisMaxIdex() {
    if (this.props.XAxisCategories!.length > 5) {
      return 4
    } else {
      return this.props.XAxisCategories!.length - 1
    }
  }

  private scrollBarPresent() {
    return (this.props.XAxisCategories!.length > 5)
  }

  public highChartsOptions() {

    if (this.props.hasDataChanged()) {
      this.populateData()

      var series = this.setupSeriesData(this.seriesCollection)
      this.highchartOptions =
      {
        credits: {
          enabled: false
        },
        chart: {
          type: 'column',
          scrollablePlotArea: {
            minWidth: 700,
            scrollPositionX: 1
          }
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'category',
          categories: this.props.XAxisCategories,
          min: 0,
          max: this.calculateXAxisMaxIdex(),
          tickWidth: 1,
          scrollbar: {
            enabled: this.scrollBarPresent(),
          },
          crosshair: true,
        },

        tooltip: {
          shared: true,
          enabled: true,
          useHTML: true,
          formatter: function () {
            return
          },
          className: "hideTooltip",
        },

        yAxis: {
          title: {
            text: '',
          },
          lineWidth: 1,
        },
        legend: {
          verticalAlign: 'top',
          align: 'left',
          margin: 30,
          x: 100,
          y: -2,
        },
        plotOptions: {
          column: {
            padding: 5,
          },
          series: {
            dataLabels: {
              enabled: true,
            },
            point: {
              events: {
                mouseOver: this.onMouseOver,
                mouseOut: this.onMouseOut
              },
            },

            states: {
              hover: {
                brightness: 0,
                opacity: 1,
                enabled: true,
              },
            },
          },
        },
        series: series,

        // EXPORT start - - - EXPORT start - - - EXPORT start - - - EXPORT start
        navigation: {
          buttonOptions: {
            useHTML: true, // Necessary for custom export button
            align: 'right',
            verticalAlign: 'top'
          }
        },
        exporting: {
          categories: this.props.XAxisCategories,
          sourceWidth: 1300,
          sourceHeight: 600,
          scale: 1,
          chartOptions: {
            xAxis: {
              min: 0,
              minRange: this.props.XAxisCategories!.length - 1, // Necessary to ensure full chart is always printed, regardless of scrollbar position
              max: this.props.XAxisCategories!.length - 1,
            },
            scrollbar: {
              enabled: false
            }
          },
          buttons: {
            contextButton: {
              enabled: false,
            },
            exportButton: {
              x: this.EmailOverviewExporting(),
              text: `${textConstants.SVG.Download}`,
              // Full list of menu items - https://api.highcharts.com/highcharts/exporting.buttons.contextButton.menuItems
              menuItems: [
                'viewFullscreen',
                'separator',
                'downloadPDF',
                'downloadJPEG',
                'downloadXLS',
                'downloadCSV'
              ]
            },
          }
        }
        // EXPORT end - - - EXPORT end - - - EXPORT end - - - EXPORT end
      }

      const yAxis = this.props.additionalOptions && this.props.additionalOptions.yAxis
        ? { ...this.highchartOptions.yAxis, ...this.props.additionalOptions.yAxis }
        : this.highchartOptions.yAxis

      const legend = this.props.additionalOptions && this.props.additionalOptions.legend
        ? { ...this.highchartOptions.legend, ...this.props.additionalOptions.legend }
        : this.highchartOptions.legend

      const exporting = this.props.additionalOptions
        ? { ...this.highchartOptions.exporting, ...this.props.additionalOptions.exporting }
        : this.highchartOptions.exporting

      this.Options = this.props.additionalOptions
        ? { ...this.props.additionalOptions, ...this.highchartOptions, yAxis, legend, exporting }
        : this.highchartOptions
    }
  }

  onMouseOver = (event: any) => {
    this.setState({ isMouseOver: true });
  }

  onMouseOut = (event: any) => {
    this.setState({ isMouseOver: false });
  }

  componentDidMount(): void {
    this.highChartsOptions()
  }

  componentDidUpdate(prevProps: any): void {
    this.highChartsOptions()

    if (prevProps.SeriesData !== this.props.SeriesData && this.chartRef) {
      if (this.chartRef.current) {
        const chart = this.chartRef.current!.chart;

        let maxSize = 4;
        let difference = Math.min(maxSize, this.props.XAxisCategories.length - 1)

        if (chart) {
          chart.xAxis[0].setExtremes(0, difference, true);
        }
      }
    }
  }

  public render() {
    const { isMouseOver } = this.state;

    return (
      <div className="p-24 columnChartStyling">
        <div className={`${isMouseOver ? "hoverEffect" : "noHoverEffect"}`}>
          <HighchartsReact
            highcharts={Highcharts}
            options={this.Options}
            ref={this.chartRef}
          />
        </div >
      </div>
    );
  }
}