import React from 'react';
import { observer } from 'mobx-react';
import ClientCampaignWoodpeckerEmailLookup from '../../Models/Dashboard/ClientCampaignWoodpeckerEmailLookup';
import * as Icon from 'react-feather';
import { textConstants } from '../../common/textConstants';
import { isMobile } from 'common/utils';

interface IClientCampaignWoodpeckerEmailsProps {
    isHeader: boolean,
    campaignEmailData?: ClientCampaignWoodpeckerEmailLookup,
}

@observer
export default class ClientCampaignWoodpeckerEmails extends React.Component<IClientCampaignWoodpeckerEmailsProps> {
    public render() {
        const { campaignEmailData } = this.props;
        return (
            <>
                {/* Header */}
                {this.props.isHeader && !isMobile() &&
                    <>
                        <div className='row m-0 emailsTableHeader pb-0 pt-0 overview-grid-layout non-mobile'>
                            {/* Email */}
                            <div className=''></div>

                            {/* Sent/Delivered */}
                            <div className='mr-auto mt-2 mb-3'>{textConstants.Dashboard.sentDelivered}</div>

                            {/* Bounced */}
                            <div className='mr-auto mt-2 mb-3'>{textConstants.Dashboard.bounced}</div>

                            {/* Opened */}
                            <div className='mr-auto mt-2 mb-3'>{textConstants.Dashboard.open}</div>

                            {/* Replied */}
                            <div className='mr-auto mt-2 mb-3'>{textConstants.Dashboard.replied.toUpperCase()}</div>
                        </div>
                    </>
                }

                {/* Data */}
                {!this.props.isHeader &&
                    <div className={`row dataRow m-0 pt-2 pb-2 bottomBorder overview-grid-layout ${isMobile() ? 'mobile' : 'non-mobile'}`}>

                        {/* Email */}
                        <div className='p-0 mt-3 mb-2 header-item'>
                            <div className='row m-0'>
                                <div className='backgroundMail mr-3'>
                                    <div className='text-center m-auto'><Icon.Mail size={24} /></div>
                                </div>
                                <div className='emails'>EMAIL {campaignEmailData?.emailNumber}</div>
                            </div>
                        </div>

                        {/* Sent/Delivered */}
                        <div className='mr-auto m-0'>
                            <div className='row m-0'>
                                {/* Sent/Delivered */}
                                {`${campaignEmailData?.mailSentCount}/${campaignEmailData?.deliveryCount}`}
                            </div>
                            <div className='row m-0'>
                                {/* Delivery Rate */}
                                <span>{campaignEmailData?.deliveryRate}% {textConstants.Dashboard.delivered}</span>
                            </div>
                        </div>

                        {/* Bounced */}
                        <div className='mr-auto m-0'>
                            <div className='row m-0'>
                                {/* Bounced */}
                                {campaignEmailData?.bouncedCount}
                            </div>
                            <div className='row m-0'>
                                {/* Bounced Rate */}
                                <span>{campaignEmailData?.bouncedRate}%</span>
                            </div>
                        </div>

                        {/* Opened */}
                        <div className='mr-auto m-0'>
                            <div className='row m-0'>
                                {/* Opened */}
                                {campaignEmailData?.openedCount}
                            </div>
                            <div className='row m-0'>
                                {/* Opened Rate */}
                                <span>{campaignEmailData?.openedRate}%</span>
                            </div>
                        </div>

                        {/* Replied */}
                        <div className='mr-auto m-0'>
                            <div className='row m-0'>
                                {/* Replied */}
                                {campaignEmailData?.repliedCount}
                            </div>
                            <div className='row m-0'>
                                {/* Replied Rate */}
                                <span>{campaignEmailData?.repliedRate}%</span>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}