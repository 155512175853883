import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import TargetMarketAccountTab from '../Models/Maintenance/TargetMarketAccountTab';
import { AppService, Types } from '../Services/AppService';

export interface ITargetMarketAccountTabsApiClient extends Data.IUpdateableApiClient<TargetMarketAccountTab, number> {
}

@injectable()
export default class TargetMarketAccountTabsApiClient extends Data.UpdateableApiClient<TargetMarketAccountTab, number> implements ITargetMarketAccountTabsApiClient {

    constructor(public config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/targetMarketAccountTab`);
    }
}