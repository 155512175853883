import { observer } from "mobx-react";
import React from "react";

interface ILeadMessageSideMenuButtonProps {
    unseenCount?: number,
    icon: any,
    isSelected: boolean,
    onClick: () => void,
    isDisabled: boolean
}

@observer
class LeadMessageSideMenuButton extends React.Component<ILeadMessageSideMenuButtonProps> {

    private setSelectedStyle() {
        if (this.props.isSelected) {
            return "selectedButton"
        } else {
            return "unselectedButton"
        }
    }

    public render() {
        return (
            <span className="LeadMessageSideMenuButton">
                <button
                    disabled={this.props.isDisabled}
                    className={`${this.setSelectedStyle()}`}
                    onClick={() => this.props.onClick()}
                >
                    <span>
                        {this.props.icon}
                        <>
                            {this.props.unseenCount! > 99 &&
                                <span className='unseenCount plus'>99+</span>
                            }
                            {this.props.unseenCount! > 0 && this.props.unseenCount! < 100 &&
                                <span className='unseenCount'>{this.props.unseenCount}</span>
                            }
                            {/* FUTURE WORK */}
                            {/* {this.props.unseenCount! < 1 &&
                                <span className='allSeen'><Icon.Check /></span>
                            } */}
                        </>
                    </span>
                </button>
            </span>
        )
    }
}

export default LeadMessageSideMenuButton;