import { observer } from "mobx-react";
import React from "react";
import { ListGroup, ListGroupItem, TabContent, TabPane } from "reactstrap";
import { OnboardingSteps } from "../Models/Enums/ApplicationEnums";
import { AppService, Types } from "../Services/AppService";
import * as Icon from 'react-feather';

@observer
export default class OnboardingStepsMenu extends React.Component {

    protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService)
    public onboardingProps: any
    private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient);

    constructor(props: any) {
        super(props);

        this.state = {
            activeTab: '1',
        };
    }

    public async navigateToStep(stepNumber: number) {
        this.authenticationService.globalProps.currentStep = stepNumber
        this.authenticationService.globalProps.canGoNext = true

        if (stepNumber === OnboardingSteps.CampaignMessages) {
            var response = (await this.onboardingApiClient.IsCampaignMessageReady(this.authenticationService.globalProps.clientId)).data.data
            this.authenticationService.globalProps.clientCampaignMessageId = response.clientCampaignMessageId as number
            this.authenticationService.globalProps.isCampaignMessageReady = response.isCampaignMessageReady as boolean
        }

        let event = new Event("stepChange", { "bubbles": true, "cancelable": false })
        document.dispatchEvent(event)

        //This even is for the sidemenu to change
        let event2 = new Event('stepChangeDropDown', { "bubbles": true, "cancelable": false })
        document.dispatchEvent(event2)

        this.authenticationService.globalProps.showOnboardingStepsMenu = false
    }

        public disableMenuItem(stepNumber: number) {
        var relevantStep = this.authenticationService.globalProps.onboardingSteps.find(step => step.stepNumber === stepNumber)
        var previousStep = this.authenticationService.globalProps.onboardingSteps.find(step => step.stepNumber === stepNumber - 1)
        if (relevantStep?.isComplete || previousStep?.isComplete) {
            return false
        } else {
            return true
        }
    }

    private highlightCurrentStep(stepNumber: number) {
        if (this.authenticationService.globalProps.currentStep === stepNumber) {
            return "active list-group-item-divider"
        } else {
            return "list-group-item-divider"
        }
    }

    private makeAvalableStepIconOrange(stepNumber:number) {
        var relevantStep = this.authenticationService.globalProps.onboardingSteps.find(step => step.stepNumber === stepNumber)
        var previousStep = this.authenticationService.globalProps.onboardingSteps.find(step => step.stepNumber === stepNumber - 1)
        if (relevantStep?.isComplete || previousStep?.isComplete) {
            return "#111e1e"
        } else {
            return ""
        }
    }

    public renderStepIcon = (stepNumber: Number) => {
        if (this.isStepCompleted(stepNumber)) {
            return (<Icon.Check className={"greenCheck"}/>);
        }
        else if(this.authenticationService.globalProps.currentStep === stepNumber)
        {   
            return (<Icon.Edit2 />);
        }
        return ;
    }

    public isStepCompleted(stepNumber: Number): boolean {
        const { onboardingSteps } = this.authenticationService.globalProps;
        let foundStep = onboardingSteps.find(os => os.stepNumber === stepNumber);
        let isStepCompleted = foundStep?.isComplete || false;
        return isStepCompleted;
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="onboardingStepsMenu" onMouseLeave={() => {
                  this.authenticationService.globalProps.showOnboardingStepsMenu = false
                }}>
                    <div id="triangle-up"></div>
                    <TabContent activeTab={"1"}>
                        <TabPane tabId="1">
                            <ListGroup className="list-group-accent" tag={'div'}>
                                {/* Header */}
                                <ListGroupItem className="bg-light font-weight-bold text-muted text-uppercase small">
                                    {this.authenticationService.globalProps.currentStep !== OnboardingSteps.TechnicalIntegration &&
                                    <div className="smallText">Step {this.authenticationService.globalProps.currentStep} of 5</div>}
                                    <div className="largeText"> Your Onboarding</div></ListGroupItem>

                                {/* Ideal Customer Profile */}
                                <ListGroupItem action tag="a" href="#"
                                    className={this.highlightCurrentStep(OnboardingSteps.IdealCustomerProfiles)}
                                    onClick={() => this.navigateToStep(OnboardingSteps.IdealCustomerProfiles)}>
                                    <div className="row">
                                    <i className="mr-3">{<Icon.Target />}</i><div className="marginTop2">Ideal Customer Profile</div>
                                    {
                                        <i className="ml-5">{this.renderStepIcon(OnboardingSteps.IdealCustomerProfiles)}</i>
                                        }
                                    </div>
                                </ListGroupItem>

                                {/* Target Markets */}
                                <ListGroupItem action tag="a" href="#" className={this.highlightCurrentStep(OnboardingSteps.TargetMarkets)}
                                    disabled={this.disableMenuItem(OnboardingSteps.TargetMarkets)}
                                    onClick={() => this.navigateToStep(OnboardingSteps.TargetMarkets)}>
                                    <div className="row">
                                        <i className={`mr-3 ${this.makeAvalableStepIconOrange(OnboardingSteps.TargetMarkets)}`}>{<Icon.Flag />}</i><div className="marginTop2" >Target Markets</div>
                                        {
                                           <i className="marginLeft100">{this.renderStepIcon(OnboardingSteps.TargetMarkets)}</i>
                                        }
                                    </div>
                                </ListGroupItem>

                                {/* Blacklist */}
                                <ListGroupItem action tag="a" href="#" className={this.highlightCurrentStep(OnboardingSteps.BlackList)}
                                    disabled={this.disableMenuItem(OnboardingSteps.BlackList)}
                                    onClick={() => this.navigateToStep(OnboardingSteps.BlackList)}>
                                    <div className="row">
                                        <i className={`mr-3 ${this.makeAvalableStepIconOrange(OnboardingSteps.BlackList)}`}>{<Icon.Slash />}</i><div className="marginTop2">Blacklist</div>
                                        {
                                            <i className="marginLeft146">{ this.renderStepIcon(OnboardingSteps.BlackList) }</i>
                                        }
                                    </div>
                                </ListGroupItem>

                                {/* Book a meeting */}
                                <ListGroupItem action tag="a" href="#" className={this.highlightCurrentStep(OnboardingSteps.BookAMeeting)} 
                                disabled={this.disableMenuItem(OnboardingSteps.BookAMeeting)}
                                    onClick={() => this.navigateToStep(OnboardingSteps.BookAMeeting)}>
                                    <div className="row">
                                        <i className={`mr-3 ${this.makeAvalableStepIconOrange(OnboardingSteps.BookAMeeting)}`}>{<Icon.Video />}</i><div className="marginTop2">Book a meeting</div>
                                        {
                                            <i className="ml-6">{this.renderStepIcon(OnboardingSteps.BookAMeeting)}</i>
                                        }
                                    </div>
                                </ListGroupItem>

                                {/* Campaign Message */}
                                <ListGroupItem action tag="a" href="#" className={this.highlightCurrentStep(OnboardingSteps.CampaignMessages)} disabled={this.disableMenuItem(OnboardingSteps.CampaignMessages)}
                                    onClick={() => this.navigateToStep(OnboardingSteps.CampaignMessages)}>
                                    <div className="row">
                                    <i className={`mr-3 ${this.makeAvalableStepIconOrange(OnboardingSteps.CampaignMessages)}`}>{<Icon.Mail />}</i><div className="marginTop2">Campaign Message</div>
                                        {
                                            <i className="marginLeft67">{this.renderStepIcon(OnboardingSteps.CampaignMessages)}</i>
                                        }
                                    </div>
                                </ListGroupItem>
                                
                                {/* Technical Integration */}
                                <ListGroupItem action tag="a" href="#" className={"technical-integration-divider " + this.highlightCurrentStep(OnboardingSteps.TechnicalIntegration)}
                                    onClick={() => this.navigateToStep(OnboardingSteps.TechnicalIntegration)}>
                                    <div className="row">
                                        <i className="mr-3" >{<Icon.Settings />}</i><div className="marginTop2">Technical Integration</div>
                                        {
                                            <i className="marginLeft55">{this.renderStepIcon(OnboardingSteps.TechnicalIntegration)}</i>
                                        }
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </TabPane>
                    </TabContent>
                </div>
            </React.Fragment >
        )
    }
}