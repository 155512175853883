import React from 'react';
import { observer } from 'mobx-react';
import { textConstants } from 'common/textConstants';
import { Neo } from '@singularsystems/neo-react';
import * as Icon from 'react-feather';
import DashboardVM from 'Views/Home/DashboardVM';
import { actionListRoute } from 'RouteConfiguration';
import { NavLink } from 'react-router-dom';
import DataTooltip from './DataTooltip/DataTooltip';
import { ActionListTypes } from 'Models/Enums/ApplicationEnums';

interface IDashboardActionListCardProps {
    viewModel: DashboardVM
    viewActionList?: (clientId: number, selectedTab: string) => void
    back?: () => void;
}

@observer
export default class DashboardActionListCard extends React.Component<IDashboardActionListCardProps> {

    constructor(props: IDashboardActionListCardProps) {
        super(props);
    }

    private renderCardSegments(leadName: string, leadAmount: number, selectedLead: string, leadDescription: string) {
        const { viewActionList, viewModel } = this.props

        let setupCards = (
            <div className='dashboard-actionlist-item mb-3 clickableCursor'
                onClick={() => {
                    viewActionList && viewActionList(viewModel.customAuthService.globalProps.clientId, selectedLead);
                }}>

                <div className='pl-3 pr-1'>
                    {leadName}
                </div>
                <DataTooltip parent={<Icon.AlertCircle size={20} />}
                    direction='down'
                    type='description'
                    variant='dark'
                    className="actionlist-description">
                    {viewModel.actionListConfigurationsList.find(config => config.uniqueTableKey === leadDescription)?.description ?? ""}
                </DataTooltip>
                <div className='actionlist-cardblock pull-right'>
                    <div className='m-auto'>
                        {leadAmount}
                    </div>
                </div>
            </div>
        )

        if (viewModel.customAuthService.globalProps.isClientUser) {
            setupCards = (
                <NavLink key={actionListRoute.path}
                    to={{
                        pathname: `${actionListRoute.path}`,
                        search: `?${textConstants.ActionListText.Tab}=${selectedLead}`
                    }}
                    className={"actionlist-nav-link"}>
                    {setupCards}
                </NavLink>
            )
        }

        return setupCards;
    }

    public render() {
        const { viewModel, viewActionList } = this.props

        return (
            <div className='dashboardActionListCard'>
                <Neo.Card>
                    <div>
                        {/* Header and Action List Button row */}
                        <div className='row m-0 col-12 mb-3 mt-3'>

                            {/* Number of new activities header */}
                            <div className='col-8'>
                                <div className='headerText'>{viewModel.actionListGrandTotal} {textConstants.titleText.Replies} <span>{textConstants.titleText.AreWaiting}</span></div>
                            </div>

                            {/* Go to action list button */}
                            <div className='actionListButtonDiv col-4'>
                                {viewModel.customAuthService.globalProps.isClientUser &&
                                    <NavLink
                                        key={actionListRoute.path}
                                        to={{
                                            pathname: `${actionListRoute.path}`
                                        }}>
                                        <Neo.Button className='goToActionListButton'>
                                            <div className='btnText'>
                                                {textConstants.buttonText.goToActionList}<Icon.ArrowRight className='ml-2' />
                                            </div>
                                        </Neo.Button>
                                    </NavLink>
                                }
                                {!viewModel.customAuthService.globalProps.isClientUser &&
                                    <>
                                        <Neo.Button className='goToActionListButton' onClick={() => {
                                            viewActionList && viewActionList(viewModel.customAuthService.globalProps.clientId, textConstants.buttonText.HotLeads);
                                        }}>
                                            <div className='btnText'>
                                                {textConstants.buttonText.goToActionList}<Icon.ArrowRight className='ml-2' />
                                            </div>
                                        </Neo.Button>
                                    </>
                                }
                            </div>
                        </div>

                        {/* Action List numbers row*/}
                        <div className='row m-0 col-12 mb-3'>
                            {/* HotLeads */}
                            <div className='col-4 pb-3'>
                                {this.renderCardSegments(textConstants.generalText.HotLeads,
                                    viewModel.actionListSummaryLookup.hotTotal,
                                    textConstants.buttonText.HotLeads, ActionListTypes.HotLead)}
                            </div>

                            {/* Warm Leads */}
                            <div className='col-4 pb-3'>
                                {this.renderCardSegments(textConstants.generalText.WarmLeads,
                                    viewModel.actionListSummaryLookup.warmTotal,
                                    textConstants.buttonText.WarmLeads, ActionListTypes.WarmLead)}
                            </div>

                            {/* Cold Leads */}
                            <div className='col-4 pb-3'>
                                {this.renderCardSegments(textConstants.generalText.ColdLeads,
                                    viewModel.actionListSummaryLookup.coldTotal,
                                    textConstants.buttonText.ColdLeads, ActionListTypes.ColdLead)}
                            </div>
                        </div>
                    </div>
                </Neo.Card>
            </div>
        );
    }
}