import React from 'react';
import Highcharts, { Options } from 'highcharts/highstock'
import { observer } from 'mobx-react';
import HighchartsReact from 'highcharts-react-official';
import { textConstants } from '../common/textConstants';

interface ILineChart {
  title?: string;
  SeriesData: number[][];
  SeriesNames: string[];
  SeriesColours?: string[];
  XAxisCategories?: string[];
  additionalOptions?: any;
  visibleSeries?: number[];
}

class LineGraphData {
  name: string = "";
  data: Array<number> = [];
  color: string = "";
  id: number = 0;
}

@observer
export default class LineChart extends React.Component<ILineChart> {
  public grouped: Array<LineGraphData> = new Array<LineGraphData>();
  public highChartOptions: Options = {};
  public Options: any;

  // Populates the data into the LineGraphData
  public populate() {
    this.grouped = new Array<LineGraphData>();
    const { SeriesNames, SeriesData, SeriesColours } = this.props;

    for (var i = 0; i < SeriesData.length; i++) {
      var graphData = new LineGraphData()
      if (SeriesColours != null) {
        graphData.color = SeriesColours[i]
      }
      graphData.data = SeriesData[i]
      graphData.name = SeriesNames[i]
      this.grouped.push(graphData)
    }
  }

  public SeriesDataSetup(fullSeries: Array<LineGraphData>) {
    var series: any = []
    // These 2 for loops are for the export. They link the data and leave the legend unchanged.
    // Pushes the id for the data to be linked.

    for (var i = 0; i < this.grouped.length; i++) {

      let isVisible = false;

      if (!this.props.visibleSeries
        || (this.props.visibleSeries && this.props.visibleSeries.indexOf(i) !== -1)) {
        isVisible = true
      }

      series.push({ data: fullSeries[i].data, name: fullSeries[i].name, color: fullSeries[i].color!, visible: isVisible })
    }

    return series
  }

  public highChartsOptions() {
    this.populate()
    var series = this.SeriesDataSetup(this.grouped)
    this.highChartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'spline',
        margin: [64, 10, 50, 40],
      },
      title: {
        text: '',
        y: 6,
      },
      yAxis: {
        title: {
          text: ""
        },
        showFirstLabel: false,
      },
      xAxis: {
        gridLineWidth: 1,
        categories: this.props.XAxisCategories,
        tickmarkPlacement: 'on',
      },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        margin: 30,
        y: -2,
        symbolWidth: 6,
        enabled: true,
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          connectNulls: true,
          gapSize: 5,
          lineWidth: 2,
          marker: {
            enabled: true,
            radius: 6,
            symbol: 'circle',
          },
          pointPlacement: 'on'
        },
      },
      series: series,
      // EXPORT start - - - EXPORT start - - - EXPORT start - - - EXPORT start
      navigation: {
        buttonOptions: {
          useHTML: true, // Necessary for custom export button
          align: 'right',
          verticalAlign: 'top'
        }
      },
      exporting: {
        sourceWidth: 1300,
        sourceHeight: 600,
        scale: 1,
        chartOptions: {
          xAxis: {
            min: 0,
            max: this.props.XAxisCategories!.length - 1,
          },
          plotOptions: {
            series: {
              marker: {
                enabled: false
              }
            }
          }
        },
        buttons: {
          contextButton: {
            // Hide default menu button
            enabled: false,
          },
          exportButton: {
            text: `${textConstants.SVG.Download}`,
            // Full list of menu items - https://api.highcharts.com/highcharts/exporting.buttons.contextButton.menuItems
            menuItems: [
              'viewFullscreen',
              'separator',
              'downloadPDF',
              'downloadJPEG',
              'downloadXLS',
              'downloadCSV'
            ]
          },
        }
      }
      // EXPORT end - - - EXPORT end - - - EXPORT end - - - EXPORT end
    }
    const yAxis = this.props.additionalOptions && this.props.additionalOptions.yAxis
      ? { ...this.highChartOptions.yAxis, ...this.props.additionalOptions.yAxis }
      : this.highChartOptions.yAxis

    const xAxis = this.props.additionalOptions && this.props.additionalOptions.xAxis
      ? { ...this.highChartOptions.xAxis, ...this.props.additionalOptions.xAxis }
      : this.highChartOptions.xAxis

    const legend = this.props.additionalOptions && this.props.additionalOptions.legend
      ? { ...this.highChartOptions.legend, ...this.props.additionalOptions.legend }
      : this.highChartOptions.legend

    this.Options = this.props.additionalOptions
      ? { ...this.props.additionalOptions, ...this.highChartOptions, yAxis, xAxis, legend }
      : this.highChartOptions
  }

  public render() {
    this.highChartsOptions()
    return (
      <div className="p-24">
        <HighchartsReact
          highcharts={Highcharts}
          options={this.Options}
        />
      </div>
    );
  }
}