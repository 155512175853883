import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';

@NeoModel
export default class MaintenanceVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private customAuthService = AppService.get(Types.Security.CustomAuthenticationService)) {

        super(taskRunner);
        this.customAuthService.globalProps.isOnboarding = false;
    }

    public async initialise() {
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}