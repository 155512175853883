import React from 'react';
import { withRouter } from 'react-router-dom';
import { Neo } from '@singularsystems/neo-react';
import { textConstants } from './../common/textConstants';
import * as Icon from 'react-feather';

class GoBack extends React.Component<any> {
    constructor(props: any) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    public goBack = () => {
        this.props.history.goBack();
    }

    public render() {
        return (
            <Neo.Button
                variant="light"
                className="btn-width-100 mt-3 marginLeft24"
                icon={<Icon.ArrowLeft />}
                onClick={() => this.goBack()}
            >
                {textConstants.buttonText.Back}
            </Neo.Button>
        )
    }
}

export default withRouter(GoBack);