import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import React from 'react';
import * as Icon from 'react-feather';
import AdvancedEmailSettingsVM from 'Components/TechnicalIntegrationSettings/AdvancedEmailSettingsVM';
import { StyledFormInputWrapper, StyledEmailContentButton } from 'Views/EmailSetup/styles';
import CustomDropDown from 'Components/CustomDropDown';
import Mail from 'assets/svgs/Mail';
import User from 'assets/svgs/User';
import { textConstants } from 'common/textConstants';

interface IOutboundEmailsModal {
  viewModel: AdvancedEmailSettingsVM
}

@observer
export default class OutboundEmailsModal extends React.Component<IOutboundEmailsModal> {

  
  private handleChange(selectedTypeId:any){

    this.props.viewModel.outboundEmailLookup.emailProviderTypeId = selectedTypeId
  }
  
  private getPasswordBoxType(){
    return (this.props.viewModel.showPassword ? "text": "password")
  }

  private setShowPassword(){
    this.props.viewModel.showPassword = !this.props.viewModel.showPassword
  }


  public render() {
    return (
      <Neo.Modal
        className="AdvancedEmailSettings"
        title={textConstants.EmailSettings.AddEmailModal}
        show={this.props.viewModel.showAddEditModal}
        onClose={() => (this.props.viewModel.showAddEditModal = false)}
        closeButton={{ text: "Cancel", variant: "light" }}
      >

      {/* Email Provider Types */}
      <StyledFormInputWrapper>
        <label className="dropdownLabel">{textConstants.EmailSettings.SelectProviderEmailLabel}*</label>
        <CustomDropDown
          className={"dropDownStyle"}
          options={this.props.viewModel.emailProviderList}
          placeHolder={this.props.viewModel.outboundEmailLookup.outboundEmailId !== 0 
            ? this.props.viewModel.emailProviderList.find(f => f.value === this.props.viewModel.outboundEmailLookup.emailProviderTypeId)!.label
            : <span className="dropdownDefault">{textConstants.EmailSettings.SelectProviderEmailLabel}</span>
          }
          onChange={(selectedId) => this.handleChange(selectedId)}
          variant="contained"
        />
      </StyledFormInputWrapper>

      {this.props.viewModel.outboundEmailValidationChecks(0) && (
        <div className="validationRed validationFontSize">
          Please select a provider
        </div>
      )}

      {/* Weblink to Email Account */}
      <Neo.FormGroup
        bind={
          this.props.viewModel.outboundEmailLookup.meta.emailAccountURL
        }
        className={"mt-4"}
        placeholder={"e.g. https://mail.google.com/mail"}
        label={
          <StyledFormInputWrapper>
            <label>{textConstants.EmailSettings.WebLinkLabel}*</label>
          </StyledFormInputWrapper>
          
        }
      />
      {this.props.viewModel.outboundEmailValidationChecks(1) && (
        <div className="validationRed validationFontSize">
          {textConstants.messageText.validationMessage.thisFieldIsRequiredOrHasInvalidUrl}
        </div>
      )}

      {/* Outbound Sales Email */}
      <Neo.FormGroup
        placeholder={textConstants.EmailSettings.OutboundEmailLabel}
        bind={this.props.viewModel.outboundEmailLookup.meta.emailAddress}
        className={"mt-4"}
        label={
          <StyledFormInputWrapper>
            <label>{textConstants.EmailSettings.OutboundEmailLabel}*</label>
          </StyledFormInputWrapper>
        }
        append={<span className="appendIcons"><Mail /></span>}
      />
      {this.props.viewModel.outboundEmailValidationChecks(2) && (
        <div className="validationRed validationFontSize">
          {textConstants.messageText.validationMessage.thisFieldIsRequiredOrHasInvalidEmail}
        </div>
      )}

      {/* Username */}
      <Neo.FormGroup
        bind={this.props.viewModel.outboundEmailLookup.meta.username}
        placeholder={textConstants.EmailSettings.UsernameLabel}
        className={"mt-4"}
        label={
          <StyledFormInputWrapper>
            <label>{textConstants.EmailSettings.UsernameLabel}*</label>
          </StyledFormInputWrapper>
        }
        append={<span className="appendIcons"><User /></span>}
      />
      {this.props.viewModel.outboundEmailValidationChecks(3) && (
        <div className="validationRed validationFontSize">
          {textConstants.messageText.validationMessage.thisFieldIsRequired}
        </div>
      )}

      {/* Password */}
      <Neo.FormGroup
        className="mt-4 passwordShow"
        bind={this.props.viewModel.outboundEmailLookup.meta.password}
        placeholder={textConstants.EmailSettings.PasswordLabel}
        label={
          <StyledFormInputWrapper>
            <label>{textConstants.EmailSettings.PasswordLabel}*</label>
          </StyledFormInputWrapper>
        }
        input={{ type: this.getPasswordBoxType()}}
        append={
        <button className={"passwordBox"} onClick={() => this.setShowPassword()}>
          {this.getPasswordBoxType() === "password" 
            ? <Icon.EyeOff/>
            : <Icon.Eye/>}
        </button>}
      />
      {this.props.viewModel.outboundEmailValidationChecks(4) && (
        <div className="validationRed validationFontSize">
          {textConstants.messageText.validationMessage.thisFieldIsRequired}
        </div>
      )}

      {/* SMPT AND IMAP SETTINGS (Only Show for certain providers) */}
      {(this.props.viewModel.outboundEmailLookup.emailProviderTypeId === this.props.viewModel.microsoft?.emailProviderTypeId 
          || this.props.viewModel.outboundEmailLookup.emailProviderTypeId === this.props.viewModel.other?.emailProviderTypeId) 
          && (
        <div>
          <div className="mt-4 row">
            {/* IMAP Server */}
            <div className="col-md-9">
              <Neo.FormGroup
                bind={
                  this.props.viewModel.outboundEmailLookup.meta.imapServer
                }
                placeholder={
                  textConstants.generalText.placeHolderTypeHereText
                }
                label={
                  <StyledFormInputWrapper>
                    <label>{textConstants.EmailSettings.imapServerLabel}*</label>
                  </StyledFormInputWrapper>
                }
              />
            </div>

            {/* IMAP Port */}
            <div className="col-md-3">
              <Neo.FormGroup
                bind={
                  this.props.viewModel.outboundEmailLookup.meta.imapPort
                }
                label={
                  <StyledFormInputWrapper>
                    <label>{textConstants.EmailSettings.imapPortLabel}*</label>
                  </StyledFormInputWrapper>
                }
              />
            </div>
          </div>
          <div className="mt-4 row">
            {/* SMTP Server */}
            <div className="col-md-9">
              <Neo.FormGroup
                bind={
                  this.props.viewModel.outboundEmailLookup.meta.smtpServer
                }
                placeholder={
                  textConstants.generalText.placeHolderTypeHereText
                }
                label={
                  <StyledFormInputWrapper>
                    <label>{textConstants.EmailSettings.smtpServerLabel}*</label>
                  </StyledFormInputWrapper>
                }
              />
            </div>

            {/* SMTP Port */}
            <div className="col-md-3">
              <Neo.FormGroup
                bind={
                  this.props.viewModel.outboundEmailLookup.meta.smtpPort
                }
                label={
                  <StyledFormInputWrapper>
                    <label>{textConstants.EmailSettings.smtpPortLabel}*</label>
                  </StyledFormInputWrapper>
                }
              />
            </div>
          </div>
        </div>
      )}

      {/* Save Button */}
      <StyledEmailContentButton className={"mt-5 saveButton"} onClick={() => this.props.viewModel.saveOutboundEmail()}>
        {textConstants.EmailSettings.AddEmailButton}
      </StyledEmailContentButton>
    </Neo.Modal>
    )
  }
}