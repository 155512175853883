import { ModelBase, NeoModel } from "@singularsystems/neo-core";

@NeoModel
export default class OnboardingSideMenuDataLookup extends ModelBase
{
    public stepNumber : number = 0;

    public headerText : string = "";

    public screenName : string = "";

    public lessonText : string = "";

    public transcriptText : string = "";

    public videoUrl : string = "";
}