import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import NavigationStep from 'Models/Navigation/NavigationStep';

export interface INavigationStepsApiClient extends Data.IUpdateableApiClient<NavigationStep, number> {

}

@injectable()
export default class NavigationStepsApiClient extends Data.UpdateableApiClient<NavigationStep, number> implements INavigationStepsApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/NavigationSteps`);
    }
}