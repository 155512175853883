import { Security, Utils, ModalUtils } from "@singularsystems/neo-core";

export class LoginErrorHandler extends Security.NeoLoginErrorHandler {

    protected async onNotLoggedIn() {
        // Redirect to home page. This will cause a login redirect if the home page requires authentication.
        try {
            this.redirectToHome();
        } catch (error) {
            // In the event of a failure, show the generic "Old login request" message
            super.onNotLoggedIn()
        }
    }

    // Needed to overwrite the already logged in modal
    protected onAlreadyLoggedIn() {
        const userName = this.authenticationService.user!.displayName;

        const displayHtml = Utils.suppressHtmlEncode(`<p>You are attempting to login, but are already logged in as ${userName}.</p><p>Do you want to continue as ${userName}, or logout?</p>`);

        ModalUtils.showModal("Login", displayHtml, 
        { 
            acceptButton: { text: "Continue", variant: "light", icon: "arrow-right", onClick: () => this.redirectToHome() }, 
            closeButton: { text: "Logout", variant: "secondary", icon: "sign-out-alt", onClick: () => { this.authenticationService.beginSignOut() }},
            showCancelButton: false 
        });   
    }
}