import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import FunctionType from '../Models/Maintenance/FunctionType';
import { AppService, Types } from '../Services/AppService';

export interface IFunctionTypesApiClient extends Data.IUpdateableApiClient<FunctionType, number> {

}

@injectable()
export default class FunctionTypesApiClient extends Data.UpdateableApiClient<FunctionType, number> implements IFunctionTypesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/functionTypes`);
    }
}