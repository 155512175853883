import { NeoModel, ValueObject, Attributes} from  '@singularsystems/neo-core';

@NeoModel
export default class RoInvitedUserCriteria extends ValueObject {

    constructor(){
        super();
    }
    
    @Attributes.Integer()
    public id: number = 0;

    public search: string = "";

    public inviteEmail: string = "";

    public clientId: number = 0;
   
    @Attributes.Integer()
    public invitedUserStatus: number = 0; 
}