import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import CustomerProfileStatus from '../../Models/Maintenance/CustomerProfileStatus';
import { AppService, Types } from '../../Services/AppService';

@NeoModel
export default class CustomerProfileMaintenanceVM extends Views.ViewModelBase {

    public CustomerProfileStatusList = new List(CustomerProfileStatus);

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private customerProfileStatusesApiClient = AppService.get(Types.ApiClients.CustomerProfileStatusesApiClient)) {

        super(taskRunner);
    }

    public async initialise() {
        const response = await this.taskRunner.waitFor(this.customerProfileStatusesApiClient.get());
        this.CustomerProfileStatusList.set(response.data);
    }


    public async updateStatuses() {
        this.taskRunner.run(async () => {
            const response = await this.customerProfileStatusesApiClient.saveList(this.CustomerProfileStatusList.toJSArray())
            this.CustomerProfileStatusList.update(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.ConfigSaved);
        });
    }

}