import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class Role extends ModelBase {

    public roleId: number = 0;
    
    @Rules.StringLength(500)
    @Attributes.Display("Role")
    public roleName: string = "";

    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.roleName === null || c.roleName === '', textConstants.messageText.validationMessage.roleName);
    }

    public toString(): string {
        if (this.isNew || !this.roleName) {
            return "New role";
        } else {
            return this.roleName;
        }
    }
}