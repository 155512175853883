import 'core-js/stable';
import * as React from 'react';
import './AppSetup'
import App from './App';
import { unregister } from './registerServiceWorker';
import { AppService, Types } from './Services/AppService';
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from "styled-components";
import { theme } from './Globals/Theme/theme';
import { OidcAuthService } from 'Services/AuthenticationService';
import { AppConfig } from 'AppConfig';

(async function init() {
  if (OidcAuthService.isSilentSignIn(AppConfig.silentSignInRoute)) {
    return;
  }

  AppService.get(Types.Neo.Config.ConfigService).loadConfig().then(() => {

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

    // React init
    root.render(
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    );
  });
})();

// Service Worker disabled. 
// We have multiple applications running off the same base URL (E.g. /identityserver, /clients, /admin, etc),
// and the service worker is causing problems by always returning a cached copy of index.html for any page 
// requested after the initial load of participants app. Redirects to identityserver fail because instead of
// calling the IDS service, a call to /identityserver/??? results in the app's index.html being returned. 
// While it is possible to add the URLs to a blacklist, you need to have ejected create-react-app and also
// need to edit configuration inside the webpack.config.js file to add the various URLs to an 'exclude' list,
// which is then something we'd have to remember to maintain in the case of additional services being added.
// registerServiceWorker();

unregister();