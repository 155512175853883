import * as React from "react";
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from "react-router";
import { AppService, Types } from '../../Services/AppService';
import { Spinner } from 'reactstrap';
import ModalOverlay from '../../Components/ModalOverlay';
import { PartialPlainObject } from "@singularsystems/neo-core/dist/Model";
import OnboardingStepLookup from "../../Models/Onboarding/OnboardingStepLookup";
import { textConstants } from "../../common/textConstants";

class OidcLoginRedirect extends React.Component<RouteComponentProps> {

    public async componentDidMount() {
        const result = await AppService.get(Types.Neo.Security.AuthenticationService).tryCompleteSignIn();
        if (result.success) {
            await AppService.get(Types.Neo.Security.AuthorisationService).loadRoles();

            let config = AppService.get(Types.Config)
            let invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient)

            await this.setConfigKeys(config, invitedUsersApiClient)

            if (result.redirectPath) {
                await this.setGlobalProps(result.redirectPath);
                if (result.redirectPath.indexOf('http') < 0) {
                    this.props.history.push(result.redirectPath);
                }
                else {
                    window.location.href = result.redirectPath;
                }
            }
            else {
                this.props.history.push("/");
            }
        }
    }

    private async setGlobalProps(route: string) {
        const onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient);
        const authService = AppService.get(Types.Neo.Security.AuthenticationService);
        const authenticationService = AppService.get(Types.Security.CustomAuthenticationService)

        const response = await onboardingApiClient.getUserLoginInfo(authService!.user!.userName);
        await authenticationService.reloadUserMenuAccessData();

        authenticationService.globalProps.clientId = response.data.data.clientId
        authenticationService.globalProps.isClientUser = response.data.data.isClientUser
        authenticationService.globalProps.isFirstOnboardingLogin = response.data.data.isFirstOnboardingLogin
        authenticationService.globalProps.hasSeenTutorial = response.data.data.hasSeenTutorial
        authenticationService.globalProps.clientHasSeenTutorial = response.data.data.clientHasSeenTutorial
        authenticationService.globalProps.hasSeenTechnicalIntegration = response.data.data.hasSeenTechnicalIntegration
        authenticationService.globalProps.clientCampaignMessageId = response.data.data.clientCampaignMessageId
        authenticationService.globalProps.isCampaignMessageReady = response.data.data.isCampaignMessageReady

        if (authenticationService.globalProps.clientId
            && (route === textConstants.RouteNames.dashboard || route === textConstants.RouteNames.actionList)) {
        }
        authenticationService.globalProps.joinSupportCallURL = response.data.data.joinSupportCallURL
        authenticationService.globalProps.joinSupportCallURLOnboarding = response.data.data.joinSupportCallURLOnboarding
        let steps = response.data.data.clientOnboardingSteps as PartialPlainObject<OnboardingStepLookup>[]

        if (steps !== null) {
            authenticationService.globalProps.onboardingSteps.set(steps)
        }

        // Need to hide or show side menu
        if (response.data.data.isClientUser && response.data.data.isOnboardingComplete) {
            authenticationService.globalProps.showBurgerIcon = true
            authenticationService.globalProps.showBreadCrumb = true
            authenticationService.globalProps.isOnboarding = false
        }
        else if (response.data.data.isClientUser && !response.data.data.isOnboardingComplete) {
            authenticationService.globalProps.isOnboarding = true
        }
        else {
            authenticationService.globalProps.isOnboarding = false
            authenticationService.globalProps.showBurgerIcon = true
            authenticationService.globalProps.showBreadCrumb = true
        }
    }

    private async setConfigKeys(config: any, invitedUsersApiClient: any) {
        let data = await invitedUsersApiClient.getFrontEndKeys()

        if (data) {
            let keyData = data.data;
            config.zendeskKey = keyData.zendeskKey;
            config.gridLicense = keyData.reactDataGridLicense;
        }
    }

    public render() {
        return (
            <div className="OidcLoginRedirect">
                <ModalOverlay isHidden className="overlayPosition">
                    <Spinner animation="border" role="status" />
                </ModalOverlay>
            </div>
        )
    }
}


export default withRouter(OidcLoginRedirect);