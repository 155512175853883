import styled from "styled-components";

export const StyledMain = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px;
`;

export const StyledCard = styled.div`
  border-radius: 16px;
  background: #ffffff;
  padding: 16px 0;
`;

export const StyledHeaderWrapper = styled.div`
  border-bottom: 2px solid #f8f8f8;
  margin-bottom: 16px;
`;

export const StyledHeader = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #0b0b0c;
  margin: 16px;
`;

export const StyledCardSubHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 16px;

  h6 {
    margin: 0;
    font-size: 20px;
  }
`;

export const StyledCardContent = styled.div`
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  background: #f8f8f8;
  margin: 0 16px;

  p {
    margin: 0;
    color: #0b0b0c;
  }
`;

export const StyledMeetingCard = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  background: #f2f5f5;
  margin: 16px;
  margin-top: 0;
`;

export const StyledMeetingCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;

  h6 {
    font-size: 18px;
    color: #0b0b0c;
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #374242;
  }
`;

export const StyledMeetingCardLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #7f92a3;
  cursor: pointer;

  &:active {
    color: #222222;
  }
`;

export const StyledLine = styled.hr`
  height: 1px;
  align-self: stretch;
  background: #edeeee;
  border-radius: 100px;
  display: block;
  margin: 0 16px;
`;

export const StyledVideoLinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin: 0 16px;
`;

export const StyledVideoLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 70%;
`;

export const StyledVideoLink = styled.a`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  background: #f8f8f8;
  border-radius: 16px;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
  scale: 1;

  &:hover {
    opacity: 1;
    text-decoration: none;
    transform: scale(1.01);
  }
  transition: all 0.2s ease-in-out;
`;

export const StyledVideoLinkNumber = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #edeeee;
  color: #0b0b0c;
`;

export const StyledVideoLinkText = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #374242;
  margin: 8px 0;
`;

export const StyledHubCard = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: #f8f8f8;
  gap: 8px;
  border-radius: 16px;
  width: 30%;

  h5 {
    font-size: 18px;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    color: #0b0b0c;
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #0b0b0c;
    margin: 0;
  }
`;

export const StyledVideoExternalLink = styled.a`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #ffffff;
`;

export const StyledHubPasswordLabel = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #818888;
  margin-bottom: -8px;
`;

export const StyledPasswordWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

export const StyledHubPassword = styled.div`
  height: 40px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  width: 80%;

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #818888;
    margin: 0;
  }
`;

// Footer

export const StyledAltFooter = styled.div`
  display: flex;
  background: #fade9c;
  padding: 24px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-top: 1px solid #edeeee;
  justify-content: space-between;
`;

export const StyledFooterContent = styled.div`
  p {
    color: #002648;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }

  h5 {
    color: #002648;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }
`;

export const StyledButton = styled.div`
  display: flex;
  width: 320px;
  height: 48px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #002648;
  color: #ffffff;
  cursor: pointer;
  margin-right: 24px;

  :hover {
    opacity: 0.8;
  }
`;

export const StyledCopyToClipboard = styled.div`
  cursor: pointer;

  svg {
    margin-bottom: 0px;
  }
`;

export const StyledExternalLink = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #ffffff;
`;