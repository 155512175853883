import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { textConstants } from '../../../common/textConstants';
import OnboardingSideMenuMaintenanceVM from './OnboardingSideMenuMaintenanceVM';
import OnboardingIndividualScreenView from './OnboardingIndividualScreenView';
import GoBack from "../../../Components/GoBack";
import * as Icon from 'react-feather';

@observer
export default class OnboardingSideMenuMaintenanceView extends Views.ViewBase<OnboardingSideMenuMaintenanceVM> {

    constructor(props: unknown) {
        super("Onboarding Side Menu", OnboardingSideMenuMaintenanceVM, props);
    }

    onLeave() {
        this.viewModel.removeNotifications();
        return undefined;
    }

    public render() {
        return (
            <div className='popupMaintenance'>
                <GoBack />
                <Neo.Card>
                    <h2 className='p-24 mt-4'>{textConstants.titleText.OnboardingSideMenuMaintenance}</h2>
                    <div className='pb-3'>
                        {this.viewModel.showInitialTable &&
                            <NeoGrid.Grid items={this.viewModel.onboardingSideMenuData}>
                                {(item, meta) => (
                                    <NeoGrid.Row >
                                        <NeoGrid.Column display={meta.screenName} />
                                        <NeoGrid.ButtonColumn>
                                            <Neo.Button variant="light" className="text-center" icon={<Icon.Edit3 />}
                                                onClick={() => {
                                                    this.viewModel.onboardingSideMenuItem = item;
                                                    this.viewModel.navigationList.push("Individual Screen Maintenance");
                                                    this.viewModel.toggleView();
                                                }}>
                                            </Neo.Button>
                                        </NeoGrid.ButtonColumn>
                                    </NeoGrid.Row>
                                )}
                            </NeoGrid.Grid>}

                        {this.viewModel.showPerScreenView &&
                            <OnboardingIndividualScreenView
                                viewModel={this.viewModel}
                                onboardingSideMenuItem={this.viewModel.onboardingSideMenuItem}
                                save={() => { this.viewModel.saveIndividualScreenData() }}
                            />
                        }
                    </div>

                    {/* Invalid Data Modal */}
                    <Neo.Modal title={textConstants.titleText.InvalidData} show={this.viewModel.showInvalidDataModal}
                        onClose={() => this.viewModel.showInvalidDataModal = false}
                        closeButton={{ text: "Close", variant: "light" }}
                    >
                        {textConstants.generalText.enterValidURL}
                    </Neo.Modal>
                </Neo.Card>
            </div>
        );
    }
}