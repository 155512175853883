import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import CampaignMessageStatus from '../Models/Maintenance/CampaignMessageStatus';
import { AppService, Types } from '../Services/AppService';

export interface ICampaignMessageTypesApiClient extends Data.IUpdateableApiClient<CampaignMessageStatus, number> {

}

@injectable()
export default class CampaignMessageTypesApiClient extends Data.UpdateableApiClient<CampaignMessageStatus, number> implements ICampaignMessageTypesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/campaignMessageTypes`);
    }
}