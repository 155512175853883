import { observer } from "mobx-react";
import React from "react";
import ActionListTableRow from "./ActionListTableRow";
import ActionListVM from "../../Views/ActionList/ActionListVM";
import ActionListTableHeader from "./ActionListTableHeader";
import Pager from "Components/Pager/Pager";
import ActionListLookupData from "Models/ActionList/ActionListLookupData";

interface IActionListTable {
    viewModel: ActionListVM,
    openLeadView: (prospectId: number, campaignId: number, replyId: string) => void,
    archiveOrUnarchiveSelectedLeads: (isArchive: boolean) => void,
}

@observer
class ActionListTable extends React.Component<IActionListTable> {

    render() {
        let leads = this.props.viewModel.pagedLeadList;

        return (
            <div className="actionListTableContainer ">

                {/* Header Row */}
                <ActionListTableHeader
                    viewModel={this.props.viewModel}
                    archiveOrUnarchiveSelectedLeads={(isArchive) => { this.props.archiveOrUnarchiveSelectedLeads(isArchive) }}
                />

                {/* Data Rows */}
                {leads.length > 0 &&
                    leads.map((lead: ActionListLookupData, key) => {
                        return <ActionListTableRow
                            key={key}
                            lead={lead}
                            viewModel={this.props.viewModel}
                            openLeadView={() => this.props.openLeadView(lead.prospect_id, lead.campaign_id, lead.reply_id)}
                        />
                    })
                }
                <Pager
                    currentPageNumber={this.props.viewModel.actionListCriteria.pageNumber}
                    totalPages={this.props.viewModel.totalPages}
                    totalResults={this.props.viewModel.totalResults}
                    pageSize={this.props.viewModel.actionListCriteria.resultsPerPage}
                    setPageSize={async (pageSize) => { await this.props.viewModel.changePageSize(pageSize); await this.props.viewModel.setTristateIndictator(true) }}
                    goToFirstPage={async () => { this.props.viewModel.actionListCriteria.pageNumber = 1; await this.props.viewModel.getActionList() }}
                    goToLastPage={async () => { this.props.viewModel.actionListCriteria.pageNumber = this.props.viewModel.totalPages; await this.props.viewModel.getActionList() }}
                    goToNextPage={async () => { this.props.viewModel.actionListCriteria.pageNumber++; await this.props.viewModel.getActionList() }}
                    goToPreviousPage={async () => { this.props.viewModel.actionListCriteria.pageNumber--; await this.props.viewModel.getActionList() }}
                    goToSpecificPage={async (pageNumber) => { this.props.viewModel.actionListCriteria.pageNumber = pageNumber; await this.props.viewModel.getActionList() }}
                />
            </div>
        )
    }
}

export default ActionListTable;