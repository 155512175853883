import React from "react";

const CircleChecked = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#E5EED5" />
    <path
      d="M21.3327 12L13.9993 19.3333L10.666 16"
      stroke="#32914D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleChecked;
