import React from "react";
import { observer } from "mobx-react";
import * as Icon from 'react-feather';
import Button from "./Button";
import { textConstants } from "common/textConstants";
import { StyledLessonCardDescription, StyledQuestionaireFooter, StyledQuestionaireFooterContent } from "Views/Onboarding/OnboardingIntroduction/OnboardingIntroduction.styles";
import ClockIcon from "assets/svgs/Clock";
import { StyledAltFooter, StyledFooterContent, StyledButton } from 'Views/Onboarding/CampaignMessage/styles';
import { OnboardingSteps } from "Models/Enums/ApplicationEnums";

interface INavFooter {
  disabled?: boolean;
  lastSection?: number;
  currentSection?: number;
  backButton?: () => void;
  continueButton?: () => void;
  saveAsDraft?: () => void;
  saveAndPublish?: () => Promise<void>;
}

@observer
export class NavFooter extends React.Component<INavFooter> {

  public render() {

    return (
      <div className="col">
        {/* Default footer buttons */}
        <div className="row">

          {/* Previous button */}
          <div className="p-24">
            <button className="EditBackButton" onClick={async () => {
              // If this is the final question the back button returns to the ICP grid.              
              this.props.backButton && this.props.backButton();
            }}>
              <Icon.ChevronLeft />
            </button>
          </div>

          <div className="col"></div>

          {/* Save and do later */}
          <a className="pull-right m-auto p-24 EditLinkButton"
            onClick={this.props.saveAsDraft ?? this.props.saveAsDraft}>{textConstants.buttonText.Save}
          </a>

          {/* Next button */}
          <div className="pull-right EditContinueButton">
            {this.props.currentSection! < this.props.lastSection! &&
              <Button
                disabled={this.props.disabled}
                variant="contained"
                size='medium'
                buttonType="secondary"
                onClick={async () => {
                  this.props.continueButton && this.props.continueButton();
                }}>
                <span>{textConstants.Onboarding.Continue}</span>
              </Button>
            }
            {this.props.currentSection === this.props.lastSection! &&
              <Button
                disabled={this.props.disabled}
                variant="contained"
                size='medium'
                buttonType="secondary"
                onClick={this.props.saveAndPublish ?? this.props.saveAndPublish}>
                <span>{textConstants.buttonText.SaveAndFinish}</span>
              </Button>
            }
          </div>
        </div>
      </div>
    );
  }
}

interface ILetsStart {
  letsStartDisable?: boolean;
  letsStartButton?: () => void;
  currentStep?: number;
}

@observer
export class OnboardingFooter extends React.Component<ILetsStart> {

  private setCompletionTime() {
    switch (this.props.currentStep) {
      case OnboardingSteps.IdealCustomerProfiles:
        return "2–4 hours"
      case OnboardingSteps.TargetMarkets:
        return "30 Minutes"
      case OnboardingSteps.BlackList:
        return "15 Minutes"
      case OnboardingSteps.CampaignMessages:
        return "30 Minutes"
      case OnboardingSteps.TechnicalIntegration:
        return "1–2 hours"
      default:
        return ""
    }
  }

  public render() {
    return (
      <StyledQuestionaireFooter>
        {/* Default footer buttons */}
        <StyledQuestionaireFooterContent>
          <StyledLessonCardDescription
            style={{
              marginBottom: "0px",
            }}
          >
            <ClockIcon /> {this.setCompletionTime()}
          </StyledLessonCardDescription>
          <Button
            variant="contained"
            buttonType="secondary"
            size={"large"}
            style={{ width: "320px" }}
            disabled={this.props.letsStartDisable!}
            onClick={() => {
              this.props.letsStartButton && this.props.letsStartButton();
            }}
          >
            {textConstants.buttonText.LetsStart}
          </Button>
        </StyledQuestionaireFooterContent>
      </StyledQuestionaireFooter>
    );
  }
}

interface IOnboardingNextStep {
  onClick: () => void,
  title: string,
  disabled?: boolean;
}

export class OnboardingGoToEmailSetupFooter extends React.Component<IOnboardingNextStep> {
  public render() {
    const { onClick, title } = this.props;

    return (
      <StyledAltFooter>
        <StyledFooterContent>
          <p>{textConstants.Onboarding.FinalOnboardingStep}</p>
          <h5>{textConstants.Onboarding.GetEmailsReady}</h5>
        </StyledFooterContent>

        <StyledButton onClick={onClick}>
          {title}
        </StyledButton>
      </StyledAltFooter>);
  }
}

export class OnboardingNextStepFooter extends React.Component<IOnboardingNextStep> {
  public render() {
    const { onClick, title, disabled } = this.props;

    return (
      <div>
        <StyledQuestionaireFooter>
          {/* Default footer buttons */}
          <StyledQuestionaireFooterContent>
            <Button
              disabled={disabled}
              variant="contained"
              buttonType={"secondary"}
              size={"large"}
              style={{ width: "320px" }}
              onClick={() => onClick()}
            >
              {title}
            </Button>
          </StyledQuestionaireFooterContent>
        </StyledQuestionaireFooter>
      </div>
    );
  }
}