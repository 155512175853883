import React from "react";
import EmailSetupVM from "./EmailSetupVM";
import { observer } from "mobx-react";
import { textConstants } from "../../common/textConstants";
import BusinessSvg from "assets/illustrations/Business.svg";
import Modal from "./components/Modal";

import {
  StyledBox,
  StyledPaper,
  StyledSvgWrapper,
  StyledSubTitle,
  StyledTitle,
  StyledParagrapgh,
  StyledLink,
  StyledButton,
  StyledStepCard,
  StyledStepCardTitle,
  StyledStepCardHeading,
  StyledStepCardDescription,
} from "Views/Onboarding/Styles/EmailSetupView.styles";
import { AppService, Types } from "Services/AppService";

interface IEmailSetupViewProps {
  viewModel: EmailSetupVM;
  navigate: (x: string) => void;
  navigateToTechnicalIntegration: () => void;
}

@observer
export default class EmailSetupView extends React.Component<IEmailSetupViewProps> {
  state = {
    emailDetail: "",
    showTechnicalDetails: false,
    showEmailSent: false,
    open: false,
  };

  private comXConfigApiClient = AppService.get(Types.ApiClients.ComXConfigurationsApiClient)

  private emailSetupVideo: string = "";

  private async getEmailURL() {
    let config = await this.comXConfigApiClient.get()
    if (config) {
      this.emailSetupVideo = config.data.find((config: any) => config.configCode === textConstants.generalText.EmailSetupVideo)?.value as string
    }
  }

  public getEmailTemplate() {
    this.props.viewModel.getMailToText().then(() => {
      let emailDetail = `mailto:?subject=Technical Integration Information Requirements&body=${this.props.viewModel.mailToEmailBody}`;
      return emailDetail;
    });

    return `mailto:?subject=Technical Integration Information Requirements&body=${textConstants.Onboarding.EmailSetup.EmailBody}`;
  }

  async componentDidMount() {
    await this.getEmailURL()
  }

  public render() {

    return (
      <StyledBox>
        <StyledPaper>
          {!this.props.viewModel.showEmailSent && (
            <React.Fragment>
              <StyledSvgWrapper>
                <img src={BusinessSvg} alt="" />
              </StyledSvgWrapper>
              <StyledSubTitle>
                {textConstants.Onboarding.EmailSetup.EmailSubHeading}
              </StyledSubTitle>
              <StyledTitle>
                {textConstants.Onboarding.EmailSetup.EmailSetupHeading}
              </StyledTitle>
              <StyledParagrapgh>
                {textConstants.Onboarding.EmailSetup.EmailSetupParagraph1}
              </StyledParagrapgh>
              {!this.props.viewModel.showTechnicalDetails && (
                <React.Fragment>
                  <StyledParagrapgh>
                    <strong>
                      {textConstants.Onboarding.EmailSetup.EmailSetupParagraph2}
                    </strong>
                  </StyledParagrapgh>

                  <StyledLink onClick={() => this.setState({ open: true })}>
                    {textConstants.Onboarding.EmailSetup.EmailSpecs}
                  </StyledLink>
                </React.Fragment>
              )}
              <StyledButton color="primary" onClick={() => {
                this.props.navigateToTechnicalIntegration();
              }}>
                {textConstants.Onboarding.EmailSetup.EmailSetupCTA}
              </StyledButton>
            </React.Fragment>
          )}
        </StyledPaper>

        {this.state.open &&
          <Modal
            title={textConstants.Onboarding.EmailModal.EmailSetupHeading}
            isOpen={this.state.open}
            setIsOpen={(e) => this.setState({ open: e })}
          >
            <StyledStepCard>
              <StyledStepCardTitle>
                {textConstants.Onboarding.EmailModal.EmailStep1}
              </StyledStepCardTitle>
              <StyledStepCardHeading>
                {textConstants.Onboarding.EmailModal.EmailStep1Paragraph1}
              </StyledStepCardHeading>
              <StyledStepCardDescription>
                <a href={this.emailSetupVideo} target="_blank" rel="noreferrer">Check this video</a> to understand how to choose the
                right outbound profile.
              </StyledStepCardDescription>
            </StyledStepCard>
            <StyledStepCard>
              <StyledStepCardTitle>
                {textConstants.Onboarding.EmailModal.EmailStep2}
              </StyledStepCardTitle>
              <StyledStepCardHeading>
                {textConstants.Onboarding.EmailModal.EmailStep2Paragraph1}
              </StyledStepCardHeading>
              <StyledStepCardDescription>
                {textConstants.Onboarding.EmailModal.EmailStep1Paragraph2}
              </StyledStepCardDescription>
              <StyledStepCardDescription>
                <p>{textConstants.Onboarding.EmailModal.EmailStep1List1}</p>
                <p>{textConstants.Onboarding.EmailModal.EmailStep1List2}</p>
                <p>
                  <strong>
                    {textConstants.Onboarding.EmailModal.EmailStep1List3}
                  </strong>
                </p>
                <p>{textConstants.Onboarding.EmailModal.EmailStep1List4}</p>
                <p>{textConstants.Onboarding.EmailModal.EmailStep1List5}</p>
              </StyledStepCardDescription>
            </StyledStepCard>
            <StyledButton
              color="secondary"
              href={this.props.viewModel.mailtoLink}
              target="_blank"
              onClick={() => {
                this.props.navigate(textConstants.Onboarding.Navigation.ICP)
                this.setState({ open: false });
              }}
            >
              {textConstants.Onboarding.EmailModal.EmailSpecsToIT}
            </StyledButton>
          </Modal>
        }
      </StyledBox>
    );
  }
}
