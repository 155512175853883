import TargetMarketAccountsVM, { tabNameLabels, targetMarketAccountTabs } from '../TargetMarketAccountsVM';
import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
// Grid
import { AppService, Types } from "../../../Services/AppService";
import TargetMarketAccountFilterList from '../../../Models/TargetMarketAccounts/Query/TargetMarketAccountFilterList';
import ComXReactDataGrid from '../../../Components/ReactDataGrid/ComXReactDataGrid';
import TargetMarketLookup from '../../../Models/TargetMarket/TargetMarketLookup';
import { TabHeaderBadge } from '../../../Components/TabHeaderBag';
import { CreateComxUserColumns, CreateClientUserColumns, TargetMarketFilterColumns, helperConst, TargetMarketfilterTypes } from './TargetMarketAccountGridHelper';
import * as Icon from 'react-feather';



interface ITargetMarketAccountDataGridProps {
    viewModel: TargetMarketAccountsVM,
    tabs: tabNameLabels
    back: () => void
}

interface ITargetMarketAccountDataGridState {
    filterList: TargetMarketAccountFilterList
}



@observer
export default class TargetMarketAccountDataGrid extends React.Component<ITargetMarketAccountDataGridProps, ITargetMarketAccountDataGridState> {
    constructor(props: ITargetMarketAccountDataGridProps) {
        super(props)
        this.state = {
            filterList: new TargetMarketAccountFilterList()
        }
    }
    private config = AppService.get(Types.Config)
    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

    public securityCheck(role: any, openMethod: () => void) {
        if (this.authorisationService.hasRole(role)) {
            openMethod();
        }
        else {
            this.props.viewModel.showForbiddenModal = true;
        }
    }
    public refreshMethod: any
    public GetRefreshMethod = (data: (props: any) => Promise<void>) => {
        this.refreshMethod = data
    }

    public styleFilters(filterData: any) {
        this.styleComboBoxFilters(filterData);

        this.styleTextFilters(filterData);
    }

    private styleComboBoxFilters(filterData: any) {
        if (filterData.filterValue.value !== null && filterData.filterValue.type === "inlistFilerType") {
            let filters = document.getElementsByClassName("inovua-react-toolkit-combo-box__clear-icon");

            for (let x = 0; filters.length > x; x++) {
                document.getElementsByClassName("inovua-react-toolkit-combo-box__clear-icon")[x].parentNode!.parentElement!.style.borderColor = "#FBA174";
            }
        }
        else {
            let filters = document.getElementsByClassName("inovua-react-toolkit-combo-box__tools")

            if (filters !== undefined) {
                for (let x = 0; filters.length > x; x++) {
                    let itemValue = document.getElementsByClassName("inovua-react-toolkit-combo-box__tools")[x]

                    if (itemValue.firstElementChild!.className !== "inovua-react-toolkit-combo-box__clear-icon") {
                        document.getElementsByClassName("inovua-react-toolkit-combo-box__tools")[x].parentElement!.style.borderColor = "#c5c5c5";
                    }
                }
            }
        }
    }

    private styleTextFilters(filterData: any) {
        if (filterData.filterValue.value !== null && filterData.filterValue.value !== '' && filterData.filterValue.type === "textFreeFilter") {
            let filters = document.getElementsByClassName("inovua-react-toolkit-text-input__clear-button")

            for (let x = 0; filters.length > x; x++) {
                let itemValue = document.getElementsByClassName("inovua-react-toolkit-text-input__clear-button")[x]

                if (!itemValue.parentElement!.className.includes("--hidden")) {
                    document.getElementsByClassName("inovua-react-toolkit-text-input__clear-button-wrapper")[x].parentElement!.style.borderColor = "#FBA174";
                }
            }
        }
        else {
            let filters = document.getElementsByClassName("inovua-react-toolkit-text-input__clear-button-wrapper--hidden")

            if (filters !== undefined) {
                for (let x = 0; filters.length > x; x++) {
                    document.getElementsByClassName("inovua-react-toolkit-text-input__clear-button-wrapper--hidden")[x].parentElement!.style.borderColor = "#c5c5c5";
                }
            }
        }
    }

    public render() {
        const rowClassName = () => {
            return {
                color: '#FFFFFF'
            }
        }


        var columns: any = this.props.viewModel.isComXUser && CreateComxUserColumns(this.props.viewModel, this.props.tabs.tabName) || !this.props.viewModel.isComXUser && CreateClientUserColumns(this.props.viewModel, this.props.tabs.tabName)
        const filterValue = TargetMarketFilterColumns()
        const buttonRemoveBulk: targetMarketAccountTabs[] = ["To-do", "All", "Other & System"]
        const buttonUnDoRemoveBulk: targetMarketAccountTabs[] = ["Removed"]
        const buttonReprospectBulk: targetMarketAccountTabs[] = ["Active", "Other & System"]
        const buttonUnblacklistBulk: targetMarketAccountTabs[] = ["Blacklisted"]
        const reprospect = ((this.props.viewModel.isComXUser && (this.props.tabs.tabName === "Other & System" || this.props.tabs.tabName === "Active"))
            || (!this.props.viewModel.isComXUser && this.props.tabs.tabName !== "Other & System") && (!this.props.viewModel.isComXUser && this.props.tabs.tabName === "Active"))
        return (
            <div id={this.props.tabs.tabName}>
                <Neo.Tab key={this.props.tabs.tabName}
                    onDisplayed={() => { this.props.viewModel.toggleTab("", this.props.tabs.tabName) }}
                    header={<div><TabHeaderBadge tabs={this.props.tabs} /></div> as any}
                    name={this.props.tabs.tabName}
                >
                    <div className="col-sm-12 pt-3 pb-3 pl-0 pr-0">
                        <div className="row pl-2">
                            {/* Bulk Delete button */}
                            {buttonRemoveBulk.filter(x => x === this.props.tabs.tabName).length > 0 && <div className="pl-2">
                                <Neo.Button variant="danger" className="text-center circled buttonHover bulkAction" icon={<Icon.Archive />}
                                    onClick={() => this.props.viewModel.OpenBulkActionModal("BulkDelete")}
                                    disabled={this.props.viewModel.selectedItemsCount < 1}>
                                    <span className="hoverText">Remove Selected</span>
                                </Neo.Button>
                            </div>}

                            {/* Bulk Undelete button */}
                            {buttonUnDoRemoveBulk.filter(x => x === this.props.tabs.tabName).length > 0 && <div className="pl-2">
                                <Neo.Button variant="light" className="text-center buttonHover circled bulkAction"
                                    onClick={() => this.props.viewModel.OpenBulkActionModal("BulkUndo")}
                                    disabled={this.props.viewModel.selectedItemsCount < 1}
                                    icon={<Icon.RotateCw />}>
                                    <span className="hoverText">Un-remove selected</span>
                                </Neo.Button>
                            </div>}

                            {/* Bulk Reprospect button */}
                            {buttonReprospectBulk.filter(x => x === this.props.tabs.tabName).length > 0 && reprospect && <div className="pl-2">
                                <Neo.Button variant="light" className="text-center customGridBulkButtonPadding circled buttonHover bulkAction" icon={"retweet"}
                                    onClick={() => this.props.viewModel.OpenBulkActionModal("BulkReprospect")}
                                    disabled={this.props.viewModel.selectedItemsCount < 1}>
                                    <span className="hoverText">Re-prospect selected</span>
                                </Neo.Button>
                            </div>}

                            {/* Bulk Unblacklist button (1 or more selected) */}
                            {buttonUnblacklistBulk.filter(x => x === this.props.tabs.tabName).length > 0 &&
                                this.props.viewModel.selectedItemsCount > 0 &&
                                <div className="pl-2">
                                    <Neo.Button
                                        variant="light"
                                        className="text-center buttonHover circled bulkAction"
                                        icon={<Icon.RotateCw />}
                                        onClick={() => this.props.viewModel.OpenBulkActionModal("BulkUnblacklist")}
                                        disabled={this.props.viewModel.selectedItemsCount < 1}>
                                        <span className="hoverText">Remove selected from blacklist</span>
                                    </Neo.Button>
                                </div>}

                            {/* Bulk Unblacklist button (less than 1 selected) */}
                            {buttonUnblacklistBulk.filter(x => x === this.props.tabs.tabName).length > 0 &&
                                this.props.viewModel.selectedItemsCount < 1 &&
                                <div className="pl-2">
                                    <Neo.Button
                                        variant="light"
                                        className="text-center buttonHover circled bulkAction"
                                        icon={<Icon.RotateCw />}
                                        onClick={() => this.props.viewModel.OpenBulkActionModal("BulkUnblacklist")}
                                        disabled={this.props.viewModel.selectedItemsCount < 1}>
                                        <span className="hoverText">Remove selected from Blacklist</span>
                                    </Neo.Button>
                                </div>}
                            <div className="pl-2 verticalAlignCenter accountSelectTax">
                                {this.props.viewModel.selectedItemsCount} records selected
                            </div>
                        </div>
                    </div>
                    {
                        this.props.viewModel.criteria.targetMarketId > 0 && this.props.viewModel.isComXUser !== null &&
                        <ComXReactDataGrid
                            licence={this.config.gridLicense}
                            columns={columns}
                            defaultFilterValue={filterValue}
                            criteria={this.props.viewModel.criteria}

                            idProperty={helperConst.idProperty}
                            style={helperConst.gridStyle}
                            emptyText={helperConst.emptyText}
                            externalSearchStringName={helperConst.searchString}
                            initialPageSize={helperConst.initialPageSize}
                            filterTypes={TargetMarketfilterTypes}
                            pagination
                            enableFiltering
                            checkboxColumn
                            className="ComxDataGrid"
                            cellSelection={{ "2,targetMarketAccountStatus": true, "2,priority": true }}
                            selected={this.props.viewModel.selected}
                            modelType={TargetMarketLookup}
                            TFilter={TargetMarketAccountFilterList}
                            onFilterChange={(x) => this.styleFilters(x)}
                            setReactPageGridAndList={async (x) => await this.props.viewModel.setReactPageGridAndList(x)}
                            refreshDataGrid={(data: (props: any) => Promise<void>) => this.props.viewModel.GetRefreshMethod(data)}
                            onSelectionChange={(selectionData, dataSource) => this.props.viewModel.onSelectionChange(selectionData, dataSource)}
                            rowClassName={rowClassName}
                        />
                    }
                </Neo.Tab >
            </div>
        );
    }
}

