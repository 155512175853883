import { NeoModel, ModelBase, List } from "@singularsystems/neo-core";
import GreyListedProspectModel from './GreyListedProspectModel';

@NeoModel
export default class QuickAddEmail extends ModelBase {

    // Properties
    public Email: string = "";
}

export class QuickAddVerificationResult{
    isSuccess: boolean = true
    failMessage: string[] = []
    saveList: List<GreyListedProspectModel> = new List(GreyListedProspectModel)
}