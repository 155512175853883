import React from "react";

const DeleteIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4H3.33333H14"
      stroke="#2B4B67"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33203 4.0026V2.66927C5.33203 2.31565 5.47251 1.97651 5.72256 1.72646C5.9726 1.47641 6.31174 1.33594 6.66536 1.33594H9.33203C9.68565 1.33594 10.0248 1.47641 10.2748 1.72646C10.5249 1.97651 10.6654 2.31565 10.6654 2.66927V4.0026M12.6654 4.0026V13.3359C12.6654 13.6896 12.5249 14.0287 12.2748 14.2787C12.0248 14.5288 11.6857 14.6693 11.332 14.6693H4.66536C4.31174 14.6693 3.9726 14.5288 3.72256 14.2787C3.47251 14.0287 3.33203 13.6896 3.33203 13.3359V4.0026H12.6654Z"
      stroke="#2B4B67"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66797 7.33594V11.3359"
      stroke="#2B4B67"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33203 7.33594V11.3359"
      stroke="#2B4B67"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DeleteIcon;
