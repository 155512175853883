import { LookupBase, NeoModel, Attributes } from '@singularsystems/neo-core';

@NeoModel
export default class RoInvitedUserLookup extends LookupBase {

    @Attributes.Integer()
    public id: number = 0;

    public firstName: string = "";

    public lastName: string = "";

    public inviteEmail: string = "";

    public clientId: number = 0; 

    public clientName: string = "";

    public registeredUserId : string = "";

    @Attributes.Integer()
    public invitedUserStatus: number = 1;
}