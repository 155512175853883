import { Views } from "@singularsystems/neo-react";
import { NeoModel } from "@singularsystems/neo-core";


@NeoModel
export default class InfoCardVM extends Views.ViewModelBase {

  public isExpanded: boolean = false;
  public title: string = "";
  public shortDescription: string = "";
  public fullDescription: any; 

  public async initialise() {

  }

  public setExpanded(){
    this.isExpanded = !this.isExpanded
  }
}
