import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../../common/textConstants';
import OnboardingSideMenuLookup from '../../../Models/Onboarding/Queries/OnboardingSideMenuLookup';
import UpdateSideMenuDataCommand from '../../../Models/Onboarding/Commands/UpdateSideMenuDataCommand';
import { AppService, Types } from '../../../Services/AppService';
import { isURL } from '../../../common/utils';

@NeoModel
export default class OnboardingSideMenuMaintenanceVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient)) {

        super(taskRunner);
    }

    //Properties
    public onboardingSideMenuData = new List(OnboardingSideMenuLookup);
    public onboardingSideMenuItem : OnboardingSideMenuLookup = new OnboardingSideMenuLookup;
    public showInitialTable: boolean = true;
    public showPerScreenView: boolean = false;
    public showUpdateTextModal: boolean = false;
    public navigationList : String[] = [];
    public updateSideMenuDataCommand : UpdateSideMenuDataCommand = new UpdateSideMenuDataCommand
    public showInvalidDataModal: boolean = false;

    public async initialise() {
        const response = await this.onboardingApiClient.getOnboardingSideMenuData();
        let data = response.data;
        if (data.success) {
            this.onboardingSideMenuData.set(response.data.data);
        } else {
            this.notifications.addDanger(textConstants.titleText.SaveFailed, "Failed to retrieve data");
        }
    }

    public saveIndividualScreenData() {
        let isValidURL = isURL(this.onboardingSideMenuItem.videoURL as string);
        if (!isValidURL) {
            this.showInvalidDataModal = true;
            return;
        }

        this.updateSideMenuDataCommand.onboardingSideMenuId = this.onboardingSideMenuItem.onboardingSideMenuId;
        this.updateSideMenuDataCommand.headerText = this.onboardingSideMenuItem.headerText;
        this.updateSideMenuDataCommand.lessonText = this.onboardingSideMenuItem.lessonText;
        this.updateSideMenuDataCommand.transcriptText = this.onboardingSideMenuItem.transcriptText;
        this.updateSideMenuDataCommand.screenName = this.onboardingSideMenuItem.screenName;
        this.updateSideMenuDataCommand.videoURL = this.onboardingSideMenuItem.videoURL;

        this.taskRunner.run(async () => {
            const response = await this.onboardingApiClient.updateOnboardingSideMenuData(this.updateSideMenuDataCommand);

            if (response.data.success)
            {
                this.notifications.addSuccess(textConstants.titleText.Onboarding,textConstants.messageText.saveMessage.SideMenuDataSaved);
            }
            else {
                this.notifications.addDanger(textConstants.titleText.Error, response.data.message);
            }
            
             this.goBack();
        })
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }

    public toggleView(item: OnboardingSideMenuLookup | null = null) {
        this.showInitialTable = !this.showInitialTable;
        this.showPerScreenView = !this.showPerScreenView;
    }

    public goBack() : void {        
        if (!this.navigationList[this.navigationList.length - 1]) {
            window.history.back();
        } else {
            this.navigationList.pop();
            this.toggleView();
        }
    }
}