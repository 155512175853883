import { AppServices, Components } from '@singularsystems/neo-core';
import { ErrorDisplayType, IErrorData } from '@singularsystems/neo-core/dist/Components/ErrorHandler'
import { AxiosError } from 'axios';
import { AppService, Types } from '../Services/AppService';
import { injectable } from 'inversify';
import { INotificationHelper } from '@singularsystems/neo-core/dist/Components';
import { textConstants } from '../common/textConstants';

@injectable()
export default class ErrorHandler extends Components.ErrorHandler {
    private notification : INotificationHelper;
    private healthCheckApiClient = AppService.get(Types.ApiClients.HealthCheckApiClient);

    constructor(notifications = AppService.get(AppServices.NeoTypes.UI.GlobalNotifications))
    {
        super(notifications);
        this.notification = notifications;
    }
   
    public showError(error: any, displayType: ErrorDisplayType) : any
    {
        if (displayType !== ErrorDisplayType.ThrowError) {
            if (error.isAxiosError) {
                this.showAxiosError(error, displayType);
            }
            else {
                this.showGeneralError(error, displayType);
            }
        }
    }

    protected showAxiosError(error: AxiosError, displayType: ErrorDisplayType): void
    {
        if (error.response) {
            let errorDisplay : any;

            switch (error.response.status) {
                case 400:
                    errorDisplay = this.get400Error(error);
                    break;
                case 401:
                    errorDisplay = this.get401Error(error);
                    break;
                case 403:
                    errorDisplay = this.get403Error(error);
                    break;
                case 409:
                    errorDisplay = this.get409Error(error);
                    break;
                case 500:
                    errorDisplay = this.get500Error(error);
                    break;
                default:
                    errorDisplay = this.parseAxiosError(error.response.statusText, error.message, error);
            }
            
            this.displayError(displayType, error, errorDisplay);
        }
        else {
            // If it has a request but no response, this indicates a timeout error. Else its likely a CORS error
            if(error.request){
                this.get504Error(error);
            }
            else{
                this.showGeneralError(error, displayType);
            }            
        }
    }

    protected get400Error(error: AxiosError): any
    {
        return this.parseAxiosError("Invalid data", "Cannot continue due to the following validation errors:", error);
    }

    protected get401Error(error: AxiosError): any
    {
        return this.parseAxiosError("Not authenticated", "You are not logged in. Please reload the page to login again.", error);
    }

    protected get403Error(error: AxiosError): any
    {
        return this.parseAxiosError("Unauthorised", "You do not have sufficient security privileges to perform this action.", error);
    }

    protected get409Error(error: AxiosError): any
    {
        return this.parseAxiosError("Concurrency error", "Someone else modified the record before you saved it. Please reload it and try again.", error);
    }

    protected get500Error(error: AxiosError<any>) : any
    {
       
        if (error.config && error.config.url?.includes("InvalidReason")){
            return this.parseAxiosError("Error", textConstants.messageText.validationMessage.invalidReasonErrorMessage,error);
        }
       
        return this.parseAxiosError("Error", error.response!.data["Message"], error);
    }

    protected async get504Error(error: AxiosError<any>) 
    {
        let currentWindow = window.location.pathname;

        let uploadedFile = "";

        if (currentWindow.endsWith("ProspectTargetMarket"))
        {
            uploadedFile = document.getElementById("uploadedFileName")!.innerHTML;
        }

        let requestedURL = error?.config?.url;

        let exceptMessage = error.message;

        let message = this.getMessageForWindow(currentWindow)

        this.notification.addSuccess("Processing", message)

        let exceptionObject = {
            fileName: uploadedFile,
            requestUrl: requestedURL,
            frontEndUrl: currentWindow,
            errorMessage: exceptMessage
        };

        await this.healthCheckApiClient.SendTimeoutNotification(exceptionObject);
    }

    private getMessageForWindow(currentLocation : string) : string
    {
        // Batch Review Complete
        if (currentLocation.endsWith("BatchReviewProspects"))
        {
            return "Your batch will continue to process in the background.";
        }

        // Prospect Upload
        if (currentLocation.endsWith("ProspectTargetMarket"))
        {
            return "Your prospects are still busy processing.";
        }

        // blacklist and greylist
        if (currentLocation.endsWith("Blacklist") || currentLocation.endsWith("EditClient"))
        {
            return "Your list is still busy uploading.";
        }

        if (currentLocation.endsWith("UpdateMasterAccounts"))
        {
            return "Your Master Accounts are still busy uploading.";
        }

        return "The process is still in progress.";
    }

    /**
     * Parses an axios response into an IErrorDisplay object.
     * @param header Header to include in result.
     * @param body Body to include if the responses title is missing.
     * @param error Axios error.
     */
    public parseAxiosError(header: string, body: string, error: AxiosError<any>)
    {
        let errorDisplay: any = {
            body: body,
            header: header,
            statusText: error.message
        };

        if (error.response!.data !== null) {

            let rawErrorData_1 = error.response!.data;

            if (rawErrorData_1.title) 
            {
                errorDisplay.body = rawErrorData_1.title;
                rawErrorData_1 = rawErrorData_1.errors;
            }

            if (rawErrorData_1) {
                let errorData_1: IErrorData = { errors: [], properties: [] };

                errorDisplay.data = errorData_1;

                if (rawErrorData_1["ClientMessage"] !== null) {
                    errorData_1.errors = rawErrorData_1[""];
                }

                Object.keys(rawErrorData_1).forEach(function (key) {
                    if (key !== "$id" && key !== "") {
                        var propertyErrors = rawErrorData_1[key];
                        
                        if (propertyErrors && propertyErrors instanceof Array) {
                            errorData_1.properties.push(
                                { 
                                    key: key, 
                                    errors: propertyErrors.join(", ") 
                                });
                        }
                    }
                });
            }
        }

        return errorDisplay;
    }
    
    protected showGeneralError(error: any, displayType: ErrorDisplayType)
    {
        var errorDescription = error.message ? error.message : error;
        this.displayError(displayType, error, { header: "Error", body: errorDescription, error: error });
    }

}
