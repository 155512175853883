import { Neo } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import WelcomeImage from '../../assets/img/sprinting.png';
import { textConstants } from './../../common/textConstants';

interface IWelcomeViewProps {
    openTechnicalIntegration: () => void,
}

@observer
export default class WelcomeView extends React.Component<IWelcomeViewProps> {

    public render() {
        return (
            <React.Fragment>
                <div className="WelcomeView">
                    <div className="row container p-0">
                        <div className="col-lg-3">
                            <img src={WelcomeImage} />
                            <div className="text-left">
                            <h1>{textConstants.Onboarding.Welcome}</h1>
                            <h1 className="p-0">{textConstants.Onboarding.GetStarted}</h1>
                            <p>{textConstants.Onboarding.GreatToHaveYou}</p>
                            </div>
                            <Neo.Button variant="secondary" className="custom-btn" onClick={() => this.props.openTechnicalIntegration()}>
                                {textConstants.Onboarding.StartNow}
                            </Neo.Button>                               
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}