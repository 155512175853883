import type { IGreyListedProspectApiClient } from "../../../ApiClients/GreyListedProspectApiClient";
import CommandResult from '../../../Models/InvitedUsers/Commands/CommandResult';
import { textConstants } from '../../../common/textConstants';
import { getCurrentDateTime, base64toBlob, ValidateUsingRegex } from '../../../common/utils';
import type { INotificationHelper } from '@singularsystems/neo-core/dist/Components';
import QuickAddEmail, { QuickAddVerificationResult } from '../../../Models/GreyListedProspect/QuickAddEmail';
import DeleteGreylistProspectCommand from '../../../Models/GreyListedProspect/Commands/DeleteGreylistProspectCommand';
import GreylistProspectCriteria from '../../../Models/GreyListedProspect/Query/GreylistProspectCriteria';
import GreyListedProspectModel from '../../../Models/GreyListedProspect/GreyListedProspectModel';
import { NeoModel, ModelBase, List, Model, Data } from '@singularsystems/neo-core';
import type { ITaskRunner } from '@singularsystems/neo-core'
import { AppService, Types } from "../../../Services/AppService";
import TypeRowSelection from '@inovua/reactdatagrid-community/types/TypeSelected';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import GreyListedProspectLookup from "../../../Models/GreyListedProspect/GreyListedProspectLookup";
import QuickAddItem from "Models/BlacklistedDomains/QuickAddDomain";
import { QuickAddedItem } from "Models/Interfaces/ApplicationInterfaces";
type bulkActions = "BulkDelete" | ""

@NeoModel
export default class GreyListedProspectHelper extends ModelBase {
  constructor(
    public taskRunner: ITaskRunner,
    public greyListedProspectApiClient: IGreyListedProspectApiClient,
    public notifications: INotificationHelper,
    public clientId: number,
    public clientDetails: any,
    public isComXUser: boolean,
    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService)
  ) {
    super();
    this.taskRunner = taskRunner;
    this.greyListedProspectApiClient = greyListedProspectApiClient;
    this.notifications = notifications;
    this.clientId = clientId;
    this.clientDetails = clientDetails;
    this.isComXUser = isComXUser;
  }

  public deleteGreylistProspectCommand = new DeleteGreylistProspectCommand();
  public showGreylistDeleteModal: boolean = false;
  public showForbiddenModal: boolean = false;
  public showGreyListUploadModal: boolean = false;
  public selectedEmailName: string = "";
  public columnHeadings: string[] = ["Email Address"];
  public clientName: string = "";
  public emailRegex: string = "";

  public showGreyListQuickAddModal: boolean = false;
  public showBulkActionModal: boolean = false;
  public isInfoCardExpanded: boolean = false;

  public selected?: TypeRowSelection
  public selectedItems?: TypeOnSelectionChangeArg
  public refreshMethod: any
  public selectedItemsCount: number = 0;
  public unSelectedGreylistedProspects = new List(GreyListedProspectLookup);
  public isRefreshCount: boolean = false;

  public greyListProspectSearchCriteria: GreylistProspectCriteria = new GreylistProspectCriteria();
  public bulkActions: bulkActions | "" = ""
  public bulkActionMessage: string = ""

  public fileName: string = ""
  public fileList: File[] = []

  public QuickAddString: QuickAddItem = new QuickAddItem();
  public QuickAddStringList = new List(QuickAddItem);
  public QuickAddErrorEmails: string[] = [];

  private splitEmails: QuickAddedItem[] = []
  public hasValidationFailed: boolean = false;

  public async setReactPageGridAndList(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<GreylistProspectCriteria>>) {
    let responseModel = await this.greyListedProspectApiClient.getGreylistedProspect(request)

    if (this.isRefreshCount) {
      this.calculateSelectedItems(responseModel.data.pageResult.total);
    }

    this.isRefreshCount = false;
    return responseModel
  }

  public getRefreshMethod = (dataSource: (props: any) => Promise<void>) => {
    this.refreshMethod = dataSource
  }

  private handleSelectAll(data: any) {
    if (this.selectedItems !== undefined) {
      if (this.selectedItems.selected === true) {
        let gridUnSelectedItems = this.selectedItems.unselected;

        if (gridUnSelectedItems) {
          let gridUnselectedGreylistedProspects = Object.keys(gridUnSelectedItems);

          gridUnselectedGreylistedProspects.forEach(unselectedGreylistedProspectId => {

            let existingUnselectedGreylistedProspect = this.unSelectedGreylistedProspects.find(prospect => prospect.greylistedProspectId.toString() === unselectedGreylistedProspectId);

            if (existingUnselectedGreylistedProspect === undefined) {
              let greylistedProspectToAdd = new GreyListedProspectLookup();

              if (unselectedGreylistedProspectId === data.data.greylistedProspectId.toString()) {
                this.CreateLookup(greylistedProspectToAdd, data);
              }

              this.unSelectedGreylistedProspects.push(greylistedProspectToAdd);
            }
          });

          // Remove any reselected greylist prospects
          this.unSelectedGreylistedProspects.forEach(deselectedGreylistedProspect => {
            const greylistedProspectStillUnselected = (gridUnselectedGreylistedProspects.indexOf(deselectedGreylistedProspect.greylistedProspectId.toString()) > -1);
            if (!greylistedProspectStillUnselected) {
              this.unSelectedGreylistedProspects.remove(deselectedGreylistedProspect);
            }
          });
        }

        // Clear unselected greylist prospects if user has reselected everything
        if (gridUnSelectedItems === null && this.unSelectedGreylistedProspects.length > 0) {
          this.unSelectedGreylistedProspects.length = 0;
        }
      }
    }
  }

  private CreateLookup(greylistedProspectToAdd: GreyListedProspectLookup, data: any) {
    greylistedProspectToAdd.greylistedProspectId = data.data.greylistedProspectId;
    greylistedProspectToAdd.emailAddress = data.data.emailAddress;
    greylistedProspectToAdd.dateAdded = data.data.dateAdded;
    greylistedProspectToAdd.addedBy = data.data.addedBy;
  }


  public onSelectionChange(data: any, totalRecords?: number) {

    this.selectedItems = data;

    this.handleSelectAll(data);

    this.calculateSelectedItems(totalRecords);
  }

  private calculateSelectedItems(totalRecords: number | undefined) {
    if (totalRecords !== 0) {
      if (this.selectedItems && this.selectedItems.selected && totalRecords) {
        var selected = this.selectedItems.selected;
        this.selected = this.selectedItems.selected

        if (selected === true) {
          let recordCount = totalRecords - (this.unSelectedGreylistedProspects ? this.unSelectedGreylistedProspects.length : 0);
          this.selectedItemsCount = recordCount
        }
        else if (selected) {
          var manuallySelectedItems = Object.keys(this.selectedItems.selected);
          this.selectedItemsCount = manuallySelectedItems.length;
        }
      }
    } else {
      this.selectedItemsCount = 0;
    }
  }

  public openDeleteModal(greyListLookUp: GreyListedProspectLookup) {
    this.deleteGreylistProspectCommand = new DeleteGreylistProspectCommand();
    this.deleteGreylistProspectCommand.GreylistProspectId = greyListLookUp.greylistedProspectId;

    // Show the modal
    this.showGreylistDeleteModal = true;
    this.selectedEmailName = greyListLookUp.emailAddress;
  }

  public securityCheck(role: any, openMethod: () => void) {
    if (this.authorisationService.hasRole(role)) {
      openMethod();
    }
    else {
      this.showForbiddenModal = true;
    }
  }

  public deleteGreylistedEmail() {
    this.showGreylistDeleteModal = false;
    this.taskRunner.run(async () => {
      const response = await this.greyListedProspectApiClient.deleteGreylistedProspect(
        this.deleteGreylistProspectCommand
      );
      const cr: CommandResult = response.data as CommandResult;
      if (cr.success) {
        this.notifications.addSuccess(
          textConstants.titleText.Saved,
          textConstants.messageText.saveMessage.GreylistlistSaved
        );
        this.refreshMethod();
      }
    });
  }

  public downloadTemplateForGreylistUpload() {
    this.taskRunner.run(async () => {
      const response = await this.greyListedProspectApiClient.getTemplate();

      const url = window.URL.createObjectURL(base64toBlob(response.data.fileContents, response.data.contentType));
      const link = document.createElement("a");
      link.href = url;
      const datetime = getCurrentDateTime();
      link.setAttribute("download", "Greylist Template_" + datetime + ".csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  public openGreyListUploadModal() {
    this.showGreyListUploadModal = true;
  }

  public documentUpload(fileList: File[] | FileList) {
    this.taskRunner.run(async () => {
      const response = await this.greyListedProspectApiClient.uploadDocument(
        fileList,
        this.clientId
      );
      if (response.data.success) {
        this.notifications.addSuccess(
          textConstants.titleText.AddGreylist,
          textConstants.messageText.saveMessage.UploadSuccess
        );
        this.refreshMethod();
      } else {
        this.notifications.addDanger(
          textConstants.titleText.Error,
          response.data.message
        );
      }
    });

    this.showGreyListUploadModal = false;
  }

  public downloadGreyListProspect() {
    this.taskRunner.run(async () => {
      const response = await this.greyListedProspectApiClient.downloadGreylistedProspects(
        this.clientId
      );

      const url = window.URL.createObjectURL(base64toBlob(response.data.fileContents, response.data.contentType));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "GreyListDownload_" +
        this.clientDetails.clientName +
        "_" +
        getCurrentDateTime() +
        ".csv"
      );
      document.body.appendChild(link);
      link.click();
    });
  }

  // Validates the new email entry
  public validateNewEmailEntry(email: QuickAddItem, isNewEntry: boolean) {
    this.QuickAddGreylistEmailValidation(email.item);

    this.hasValidationFailed = false;
    this.QuickAddErrorEmails = []

    this.splitEmails.forEach(emailItem => {
      if (!emailItem.isSuccessful) {
        this.QuickAddErrorEmails.push(emailItem.failedMessage);
      }
      else {
        if (isNewEntry && this.QuickAddStringList.find(emailList => emailList.item === emailItem.quickAddedItem.item) === undefined) {
          let newQuickAddEmail = new QuickAddItem
          newQuickAddEmail = emailItem.quickAddedItem

          this.QuickAddStringList.unshift(newQuickAddEmail)
          this.QuickAddString = new QuickAddItem
        }
      }
    })

    if (this.QuickAddErrorEmails && this.QuickAddErrorEmails.length > 0) {
      this.hasValidationFailed = true
      this.QuickAddString.item = this.QuickAddErrorEmails.join(', ')
    }
  }

  public cleanUpProcess(canCleanUp: boolean) {
    if (this.hasValidationFailed && canCleanUp) {
      this.hasValidationFailed = false
      this.QuickAddString = new QuickAddItem();
    }
  }

  public async saveGreylistProspect() {
    // To cater for blacklists from TM and the Dashboard
    let emailString = this.QuickAddString.item === "" ? "" : this.QuickAddString.item + "\n"

    this.QuickAddStringList.forEach(quickAddDom => {
      emailString += quickAddDom.item + "\n"
    })

    const validationValue = this.QuickAddGreylistEmailValidation(
      emailString
    );

    this.cleanUpProcess(validationValue.isSuccess)

    if (validationValue.isSuccess) {
      this.taskRunner.run(async () => {
        const saveResponse = await this.greyListedProspectApiClient.saveGreylistedProspects(
          validationValue.saveList,
          this.clientId
        );
        if (saveResponse.data.success) {
          this.showGreyListQuickAddModal = false;
          this.QuickAddString = new QuickAddItem();
          this.QuickAddErrorEmails = [];
          this.notifications.addSuccess(
            textConstants.titleText.Saved,
            textConstants.messageText.saveMessage.QuickAddSuccess
          );
          this.refreshMethod();
        } else if (
          !saveResponse.data.success &&
          saveResponse.data.data !== null
        ) {
          this.showGreyListQuickAddModal = false;
          this.QuickAddString = new QuickAddItem();
          this.QuickAddErrorEmails = [];
          this.notifications.addDanger(
            textConstants.titleText.Error,
            saveResponse.data.message
          );
          const url = window.URL.createObjectURL(
            base64toBlob(saveResponse.data.data.fileContents, "text/csv")
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "GreyList_Invalid_Emails_" +
            this.clientName +
            "_" +
            getCurrentDateTime() +
            ".csv"
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
    } else {
      this.QuickAddErrorEmails = validationValue.failMessage;
    }
  }

  private QuickAddGreylistEmailValidation = (
    email: string
  ): QuickAddVerificationResult => {
    const dv = new QuickAddVerificationResult();

    this.splitEmails = []

    const emails = email.split(/\n|\s|,|,\s/g).filter(email => email !== "");

    emails.forEach((splitEmail) => {
      const quickAddedItem: QuickAddItem = new QuickAddItem
      let isSuccessful = false;
      let failedMessage = ""

      if (!ValidateUsingRegex(splitEmail, this.emailRegex)) {
        dv.isSuccess = false;

        failedMessage = splitEmail;
        dv.failMessage.push(splitEmail);
      }
      else {
        const emailSave = new GreyListedProspectModel();

        isSuccessful = true

        emailSave.clientId = this.clientId;
        emailSave.emailAddress = splitEmail;

        dv.saveList.push(emailSave);
      }

      quickAddedItem.item = splitEmail

      let individualEmail: QuickAddedItem = {
        quickAddedItem: quickAddedItem,
        isSuccessful: isSuccessful,
        failedMessage: failedMessage
      }

      this.splitEmails.push(individualEmail)
    });
    return dv;
  };

  public uploadGreylistProspect(fileList: File[] | FileList) {
    this.taskRunner.run(async () => {
      const response = await this.greyListedProspectApiClient.uploadDocument(
        fileList,
        this.clientId
      );
      if (response.data.success) {
        this.notifications.addSuccess(
          textConstants.titleText.GreyList,
          textConstants.messageText.saveMessage.UploadSuccess
        );
        this.refreshMethod();
      } else if (!response.data.success && response.data.data !== null) {
        this.notifications.addDanger(
          textConstants.titleText.Error,
          response.data.message
        );
        const url = window.URL.createObjectURL(
          base64toBlob(response.data.data, "text/csv")
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "GreyList_Invalid_Emails_" +
          this.clientName +
          "_" +
          getCurrentDateTime() +
          ".csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        this.notifications.addDanger(
          textConstants.titleText.Error,
          response.data.message
        );
      }
    });

    this.showGreyListUploadModal = false;
  }

  public OpenBulkActionModal(action: bulkActions) {
    this.bulkActions = action
    this.bulkActionMessage = ""
    if (this.bulkActions === textConstants.generalText.BulkDelete) {
      this.bulkActionMessage = textConstants.messageText.validationMessage.bulkGreylistDeleteConfirmation
      this.showBulkActionModal = true
    }
  }

  public PerformBulkAction() {
    this.showBulkActionModal = false;
    if (this.bulkActions === textConstants.generalText.BulkDelete) {
      this.bulkGreylistDelete()
      this.selectedItemsCount = 0;
      this.selected = false;
    }
  }

  private async bulkGreylistDelete() {
    this.deleteGreylistProspectCommand.isSelectAll = false
    this.deleteGreylistProspectCommand.greyListProspectSearchCriteria = this.greyListProspectSearchCriteria

    this.taskRunner.run(async () => {

      var greylistedProspectLookup = new List(GreyListedProspectLookup)

      if (this.selectedItems && this.selectedItems.selected) {

        var selected = this.selectedItems.selected

        if (selected === true) {
          this.deleteGreylistProspectCommand.unselectedGreylistedProspect = this.unSelectedGreylistedProspects
          this.deleteGreylistProspectCommand.isSelectAll = true
        }

        else if (selected) {
          var keysMap = Object.keys(selected).map(email => selected[email])
          this.populateGreylistedProspectLookups(keysMap, greylistedProspectLookup)
          this.deleteGreylistProspectCommand.greyListedProspectLookup = greylistedProspectLookup
        }

        this.showGreylistDeleteModal = false;
        const response = await this.greyListedProspectApiClient.bulkDelete(this.deleteGreylistProspectCommand)
        const cr: CommandResult = response.data as CommandResult;

        if (cr.success) {
          this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.BulkDeleteProspects);
        } else {
          this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
        }
        this.refreshMethod()
      }
    });
  }

  private populateGreylistedProspectLookups(keysMap: any[], greylistedProspectsLookups: List<GreyListedProspectLookup>) {
    keysMap.forEach(key => {
      var newLookup = new (GreyListedProspectLookup);
      newLookup.greylistedProspectId = key.greylistedProspectId;
      newLookup.emailAddress = key.emailAddress;
      newLookup.dateAdded = key.dateAdded;
      newLookup.addedBy = key.addedBy;
      greylistedProspectsLookups.push(newLookup);
    });
  }
}