import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import Industry from './Industry';
import SubIndustry from './SubIndustry';

@NeoModel
export default class IndustrySubIndustry extends ModelBase {

    public industrySubIndustryId: number = 0;

    @Rules.Required()
    public industryId: number | null = null;

    @Attributes.ChildObject(Industry)
    public industry: Industry | null = null;

    @Rules.Required()
    @Attributes.Display("Sub-Industry")
    public subIndustryId: number | null = null;

    @Attributes.ChildObject(SubIndustry)
    public subIndustry: SubIndustry | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New industry sub industry";
        } else {
            return "Industry Sub Industry";
        }
    }
}