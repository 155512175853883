import {ModelBase, NeoModel} from '@singularsystems/neo-core';


@NeoModel
export default class TargetMarketMiniLookup extends ModelBase {

    // Properties
    public targetMarketId: number = 0;
    public clientId: number = 0;
    public targetMarketName: string = "";
    public countryId:number = 0;
    public sequenceNumber: number = 0;
    public targetMarketStatusId: number = 0;
    public employeeMinSizeId:number = 0;
    public employeeMaxSizeId:number = 0;
    public targetMarketTypeId:number = 0;
    public isSeniorityLevelC:boolean = false;
    public isSeniorityLevelHead:boolean = false;
    public isSeniorityLevelManager:boolean = false;
    public previousEmployeeMinSizeId: number | null = null;
    public previousEmployeeMaxSizeId: number | null = null;
    public iroEditStatusId: number = 0;
    public isIROStage: boolean = false;
    public showGermanRegions: boolean = false;
    public isComplete: boolean = false;
}