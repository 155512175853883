import styled from 'styled-components';

export interface StyledTabsProps {
    active?: boolean;
}

export const StyledTabs = styled.div`
    display: flex;
`;

export const StyledTab = styled.div<StyledTabsProps>`
    justify-content: center;
    align-items: center;
    padding: 8px;  
    border: none;
    cursor: pointer;
    border-bottom: ${({ active }) => (active ? '2px solid #002648' : '3px solid #EDEEEE')};
`;

export const StyledTabContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    max-height: 350px;
    word-wrap: break-word;
    overflow: auto;
    padding-top: 16px; 
`;