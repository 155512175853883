import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class BatchInProgressLookup extends ModelBase {

  public batchCount: number = 0

  public downloadedBy: string = ""

  public userId: number = 0
}