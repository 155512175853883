import { IExcludedKeywordsApiClient } from './../ApiClients/ExcludedKeywordsApiClient';
import { ITargetMarkets } from './../ApiClients/TargetMarketsApiClient';
import { IClientsApiClient } from './../ApiClients/ClientsApiClient';
import { ITargetMarketStatusesApiClient } from './../ApiClients/TargetMarketStatusesApiClient';
import { ISeniorityLevelsApiClient } from './../ApiClients/SeniorityLevelsApiClient';
import { IRolesApiClient } from './../ApiClients/RolesApiClient';
import { IReProspectingStatusesApiClient } from './../ApiClients/ReProspectingStatusesApiClient';
import { IMasterAccountStatusesApiClient } from './../ApiClients/MasterAccountStatusesApiClient';
import { IFunctionTypesApiClient } from './../ApiClients/FunctionTypesApiClient';
import { IEmployeeSizesApiClient } from '../ApiClients/EmployeeSizesApiClient';
import { IAccountStatusesApiClient } from '../ApiClients/AccountStatusesApiClient';
import { AppServices } from '@singularsystems/neo-core';
import { IComXApiClient } from '../ApiClients/ComXApiClient';
import { AppConfig } from '../AppConfig';
import { AppDataService } from '../Services/AppDataService';
import { ICountriesApiClient } from '../ApiClients/CountriesApiClient';
import { IIndustriesApiClient } from './../ApiClients/IndustriesApiClient';
import { ISubIndustriesApiClient } from '../ApiClients/SubIndustriesApiClient';
import { IIndustrySubIndustriesApiClient } from '../ApiClients/IndustrySubIndustriesApiClient';
import { ISourceTypesApiClient } from '../ApiClients/SourceTypesApiClient';
import { ITargetMarketAccountStatusesApiClient } from '../ApiClients/TargetMarketAccountStatusesApiClient';
import { ITargetMarketFunctionsApiClient } from '../ApiClients/TargetMarketFunctionsApiClient';
import { ITargetMarketTypesApiClient } from '../ApiClients/TargetMarketTypesApiClient';
import { IInvitedUsersApiClient } from './../ApiClients/InvitedUsersApiClient';
import { IMasterAccounts } from '../ApiClients/MasterAccountsApiClient';
import { ITargetMarketAccounts } from '../ApiClients/TargetMarketAccountApiClient';
import { IProspecting } from './../ApiClients/ProspectingApiClient';
import { ILanguagesApiClient } from '../ApiClients/LanguagesApiClient';
import { IManagementFunctionsApiClient } from './../ApiClients/ManagementFunctionsApiClient';
import { IManagementRolesApiClient } from '../ApiClients/ManagementRolesApiClient';
import { IIROEditStatusApiClient } from './../ApiClients/IROEditStatusApiClient';
import { IBlacklistedDomainsApiClient } from '../ApiClients/BlacklistedDomainsApiClient';
import { IGreyListedProspectApiClient } from '../ApiClients/GreyListedProspectApiClient';
import { IScrapingApiClient } from '../ApiClients/ScrapingApiClient';
import { ISubRegionApiClient } from '../ApiClients/SubRegionApiClient';
import { IRegionApiClient } from '../ApiClients/RegionApiClient';
import { IPlatformsApiClient } from '../ApiClients/PlatformsApiClient';
import { ITargetMarketAccountTabsApiClient } from '../ApiClients/TargetMarketAccountTabsApiClient';
import { ITargetMarketAccountTabStatusesApiClient } from '../ApiClients/TargetMarketAccountTabStatusesApiClient';
import { IComXConfigurationsApiClient } from '../ApiClients/ComXConfigurationsApiClient';
import { IInformationManagementApiClient } from '../ApiClients/InformationManagementApiClient';
import { ICampaignMessagesApiClient } from '../ApiClients/CampaignMessagesApiClient';
import { ICampaignMessageStatusesApiClient } from './../ApiClients/CampaignMessageStatusesApiClient';
import { ICampaignMessageTypesApiClient } from '../ApiClients/CampaignMessageTypesApiClient';
import { IBatchReviewApiClient } from '../ApiClients/BatchReviewApiClient';
import { IComXHub } from '../ApiClients/Hubs/ComXHub';
import { ICustomerProfilesApiClient } from '../ApiClients/CustomerProfilesApiClient';
import { ICustomerProfileStatusesApiClient } from '../ApiClients/CustomerProfileStatusesApiClient';
import { IBuyingCentersApiClient } from '../ApiClients/BuyingCentersApiClient';
import { OidcAuthService } from '../Services/AuthenticationService';
import { IHealthCheckApiClient } from '../ApiClients/HealthCheckApiClient';
import { IOnboardingApiClient } from '../ApiClients/OnboardingApiClient';
import { ITechnicalSettingsConfigApiClient } from '../ApiClients/TechnicalSettingsConfigApiClient';
import { IEmailProviderTypesApiClient } from '../ApiClients/EmailProviderTypesApiClient';
import { ICustomerProfileQuestionsApiClient } from '../ApiClients/CustomerProfileQuestionsApiClient';
import { ICalendlyLinksApiClient } from '../ApiClients/CalendlyLinksApiClient';
import { IDashboardCardsApiClient } from '../ApiClients/DashboardCardsApiClient';
import { IDashboardTooltipApiClient } from '../ApiClients/DashboardTooltipsApiClient';
import { IDashboardTooltipTypeApiClient } from '../ApiClients/DashboardTooltipTypeApiClient';
import { IInvalidReasonsApiClient } from '../ApiClients/InvalidReasonsApiClient';
import { IFollowUpTemplateCategories } from '../ApiClients/FollowUpTemplateCategoriesApiClient';
import { IDashboardReportConfigApiClient } from '../ApiClients/DashboardReportConfigApiClient';
import { IBackgroundJobTypeApiClient } from '../ApiClients/BackgroundJobTypeApiClient';
import { IActionListApiClient } from 'ApiClients/ActionListApiClient';
import { IOnboardingMaintenanceApiClient } from '../ApiClients/OnboardingMaintenanceApiClient';
import { IHelpCenterTextsApiClient } from 'ApiClients/HelpCenterTextsApiClient';
import { ISupportLinksApiClient } from 'ApiClients/SupportLinksApiClient';
import { IOnboardingStepsApiClient } from 'ApiClients/OnboardingStepsApiClient';
import { IOnboardingStepIntroductionApiClient } from 'ApiClients/OnboardingStepIntroductionApiClient';
import { ILeadTypesApiClient } from 'ApiClients/LeadTypesApiClient';
import { ILeadTypeStatusesApiClient } from 'ApiClients/LeadTypeStatusesApiClient';
import { IActionListStatusesApiClient } from 'ApiClients/ActionListStatusesApiClient';
import { INavigationStepsApiClient } from 'ApiClients/NavigationStepsApiClient';
import { IIndustriesVersionApiClient } from 'ApiClients/IndustriesVersionApiClient';
import { IDemoClientsApiClient } from 'ApiClients/DemoClientsApiClient';
import { IIROIndustryMappingApiClient } from 'ApiClients/IROIndustryMappingApiClient';
import { IEmailSignatureTemplateApiClient } from 'ApiClients/CampaignMessageMaintenanceApiClient';
import { IActionListConfigurationsApiClient } from 'ApiClients/ActionListConfigurationsApiClient';
import { IReportingApiClient } from 'ApiClients/ReportingApiClient';
import { NeoReactTypes } from '@singularsystems/neo-react';

const Types = {
    ApiClients: {
        ComXApiClient: new AppServices.ServiceIdentifier<IComXApiClient>("ApiClient.ComXApiClient"),

        AccountStatusesApiClient: new AppServices.ServiceIdentifier<IAccountStatusesApiClient>("ApiClient.AccountStatusesApiClient"),
        CountriesApiClient: new AppServices.ServiceIdentifier<ICountriesApiClient>("ApiClient.CountriesApiClient"),
        IndustriesApiClient: new AppServices.ServiceIdentifier<IIndustriesApiClient>("ApiClient.IndustriesApiClient"),
        IndustrySubIndustriesApiClient: new AppServices.ServiceIdentifier<IIndustrySubIndustriesApiClient>("ApiClient.IndustrySubIndustriesApiClient"),
        SubIndustriesApiClient: new AppServices.ServiceIdentifier<ISubIndustriesApiClient>("ApiClient.SubIndustriesApiClient"),
        EmployeeSizesApiClient: new AppServices.ServiceIdentifier<IEmployeeSizesApiClient>("ApiClient.EmployeeSizesApiClient"),
        FunctionTypesApiClient: new AppServices.ServiceIdentifier<IFunctionTypesApiClient>("ApiClient.FunctionTypesApiClient"),
        MasterAccountStatusesApiClient: new AppServices.ServiceIdentifier<IMasterAccountStatusesApiClient>("ApiClient.MasterAccountStatusesApiClient"),
        ReProspectingStatusesApiClient: new AppServices.ServiceIdentifier<IReProspectingStatusesApiClient>("ApiClient.ReProspectingStatusesApiClient"),
        RolesApiClient: new AppServices.ServiceIdentifier<IRolesApiClient>("ApiClient.RolesApiClient"),
        SeniorityLevelsApiClient: new AppServices.ServiceIdentifier<ISeniorityLevelsApiClient>("ApiClient.SeniorityLevelsApiClient"),
        SourceTypesApiClient: new AppServices.ServiceIdentifier<ISourceTypesApiClient>("ApiClient.SourceTypesApiClient"),
        TargetMarketAccountStatusesApiClient: new AppServices.ServiceIdentifier<ITargetMarketAccountStatusesApiClient>("ApiClient.TargetMarketAccountStatusesApiClient"),
        TargetMarketFunctionsApiClient: new AppServices.ServiceIdentifier<ITargetMarketFunctionsApiClient>("ApiClient.TargetMarketFunctionsApiClient"),
        TargetMarketStatusesApiClient: new AppServices.ServiceIdentifier<ITargetMarketStatusesApiClient>("ApiClient.TargetMarketStatusesApiClient"),
        TargetMarketTypesApiClient: new AppServices.ServiceIdentifier<ITargetMarketTypesApiClient>("ApiClient.TargetMarketTypesApiClient"),
        ClientsApiClient: new AppServices.ServiceIdentifier<IClientsApiClient>("ApiClient.ClientsApiClient"),
        InvitedUsersApiClient: new AppServices.ServiceIdentifier<IInvitedUsersApiClient>("ApiClient.InvitedUsersApiClient"),
        MasterAccountsApiClient: new AppServices.ServiceIdentifier<IMasterAccounts>("ApiClient.MasterAccountsApiClient"),
        TargetMarketsApiClient: new AppServices.ServiceIdentifier<ITargetMarkets>("ApiClient.TargetMarketsApiClient"),
        TargetMarketAccountsApiClient: new AppServices.ServiceIdentifier<ITargetMarketAccounts>("ApiClient.TargetMarketAccountsApiClient"),
        ProspectingApiClient: new AppServices.ServiceIdentifier<IProspecting>("ApiClient.ProspectingApiClient"),
        ManagementFunctionsApiClient: new AppServices.ServiceIdentifier<IManagementFunctionsApiClient>("ApiClient.ManagementFunctionsApiClient"),
        LanguagesApiClient: new AppServices.ServiceIdentifier<ILanguagesApiClient>("ApiClient.LanguagesApiClient"),
        ManagementRolesApiClient: new AppServices.ServiceIdentifier<IManagementRolesApiClient>("ApiClient.ManagementRolesApiClient"),
        IROEditStatusApiClient: new AppServices.ServiceIdentifier<IIROEditStatusApiClient>("ApiClient.IROEditStatusApiClient"),
        ExcludedKeywordsApiClient: new AppServices.ServiceIdentifier<IExcludedKeywordsApiClient>("ApiClient.ExcludedKeywordsApiClient"),
        BlacklistedDomainsApiClient: new AppServices.ServiceIdentifier<IBlacklistedDomainsApiClient>("ApiClient.BlacklistedDomainsApiClient"),
        GreyListedProspectApiClient: new AppServices.ServiceIdentifier<IGreyListedProspectApiClient>("ApiClient.IGreyListedProspectApiClient"),
        ScrapingApiClient: new AppServices.ServiceIdentifier<IScrapingApiClient>("ApiClient.IScrapingApiClient"),        
        SubRegionApiClient: new AppServices.ServiceIdentifier<ISubRegionApiClient>("ApiClient.ISubRegionApiClient"),
        RegionApiClient: new AppServices.ServiceIdentifier<IRegionApiClient>("ApiClient.IRegionApiClient"),
        PlatformsApiClient: new AppServices.ServiceIdentifier<IPlatformsApiClient>("ApiClient.IPlatformsApiClient"),
        TargetMarketAccountTabsApiClient: new AppServices.ServiceIdentifier<ITargetMarketAccountTabsApiClient>("ApiClient.TargetMarketAccountTabsApiClient"),
        TargetMarketAccountTabStatusesApiClient: new AppServices.ServiceIdentifier<ITargetMarketAccountTabStatusesApiClient>("ApiClient.TargetMarketAccountTabStatusesApiClient"),
        InformationManagementApiClient: new AppServices.ServiceIdentifier<IInformationManagementApiClient>("Apiclient.InformationManagementApiClient"),
        ComXConfigurationsApiClient: new AppServices.ServiceIdentifier<IComXConfigurationsApiClient>("ApiClient.ComXConfigurationsApiClient"),
        DashboardReportConfigApiClient: new AppServices.ServiceIdentifier<IDashboardReportConfigApiClient>("ApiClient.DashboardReportConfigApiClient"),
        BackgroundJobTypeApiClient: new AppServices.ServiceIdentifier<IBackgroundJobTypeApiClient>("ApiClient.BackgroundJobTypeApiClient"),
        CampaignMessagesApiClient: new AppServices.ServiceIdentifier<ICampaignMessagesApiClient>("ApiClient.CampaignMessagesApiClient"),
        CampaignMessageStatusesApiClient: new AppServices.ServiceIdentifier<ICampaignMessageStatusesApiClient>("ApiClient.CampaignMessageStatusesApiClient"),
        EmailSignatureTemplateApiClient: new AppServices.ServiceIdentifier<IEmailSignatureTemplateApiClient>("ApiClient.CampaignMessageMaintenanceApiClient"),
        CampaignMessageTypesApiClient: new AppServices.ServiceIdentifier<ICampaignMessageTypesApiClient>("ApiClient.CampaignMessageTypesApiClient"),
        BatchReviewApiClient: new AppServices.ServiceIdentifier<IBatchReviewApiClient>("ApiClient.BatchReviewApiClient"),        
        CustomerProfilesApiClient: new AppServices.ServiceIdentifier<ICustomerProfilesApiClient>("ApiClient.ICustomerProfilesApiClient"),
        CustomerProfileStatusesApiClient: new AppServices.ServiceIdentifier<ICustomerProfileStatusesApiClient>("ApiClient.CustomerProfileStatusesApiClient"),
        BuyingCentersApiClient: new AppServices.ServiceIdentifier<IBuyingCentersApiClient>("ApiClient.BuyingCentersApiClient"),        
        HealthCheckApiClient: new AppServices.ServiceIdentifier<IHealthCheckApiClient>("ApiClient.IHealthCheckApiClient"),
        OnboardingApiClient: new AppServices.ServiceIdentifier<IOnboardingApiClient>("ApiClient.IOnboardingApiClient"),
        TechnicalSettingsConfigApiClient: new AppServices.ServiceIdentifier<ITechnicalSettingsConfigApiClient>("ApiClient.ITechnicalSettingsConfigApiClient"),
        EmailProviderTypesApiClient: new AppServices.ServiceIdentifier<IEmailProviderTypesApiClient>("ApiClient.IEmailProviderTypesApiClient"),
        CustomerProfileQuestionsApiClient: new AppServices.ServiceIdentifier<ICustomerProfileQuestionsApiClient>("ApiClient.ICustomerProfileQuestionsApiClient"),
        CalendlyLinksApiClient: new AppServices.ServiceIdentifier<ICalendlyLinksApiClient>("ApiClient.ICalendlyLinksApiClient"),
        DashboardCardsApiClient: new AppServices.ServiceIdentifier<IDashboardCardsApiClient>("ApiClient.IDashboardCardsApiClient"),
        DashboardTooltipApiClient: new AppServices.ServiceIdentifier<IDashboardTooltipApiClient>("ApiClient.IDashboardTooltipApiClient"),
        FollowUpTemplateCategoriesApiClient: new AppServices.ServiceIdentifier<IFollowUpTemplateCategories>("ApiClient.IFollowUpTemplateCategories"),
        DashboardTooltipTypeApiClient: new AppServices.ServiceIdentifier<IDashboardTooltipTypeApiClient>("ApiClient.IDashboardTooltipTypeApiClient"),
        InvalidReasonsApiClient: new AppServices.ServiceIdentifier<IInvalidReasonsApiClient>("ApiClient.IInvalidReasonsApiClient"),
        OnboardingMaintenanceApiClient: new AppServices.ServiceIdentifier<IOnboardingMaintenanceApiClient>("ApiClient.IOnboardingMaintenanceApiClient"),
        HelpCenterTextsApiClient: new AppServices.ServiceIdentifier<IHelpCenterTextsApiClient>("ApiClient.IHelpCenterTextsApiClient"),
        SupportLinksApiClient: new AppServices.ServiceIdentifier<ISupportLinksApiClient>("ApiClient.ISupportLinksApiClient"),
        OnboardingStepIntroductionApiClient: new AppServices.ServiceIdentifier<IOnboardingStepIntroductionApiClient>("ApiClient.IOnboardingStepIntroductionApiClient"),
        OnboardingStepsApiClient: new AppServices.ServiceIdentifier<IOnboardingStepsApiClient>("ApiClient.IOnboardingStepsApiClient"),
        ActionListApiClient: new AppServices.ServiceIdentifier<IActionListApiClient>("ApiClient.IActionListApiClient"),
        LeadTypesApiClient: new AppServices.ServiceIdentifier<ILeadTypesApiClient>("ApiClient.ILeadTypesApiClient"),
        LeadTypeStatusesApiClient: new AppServices.ServiceIdentifier<ILeadTypeStatusesApiClient>("ApiClient.ILeadTypeStatusesApiClient"),
        ActionListStatusesApiClient: new AppServices.ServiceIdentifier<IActionListStatusesApiClient>("ApiClient.IActionListStatusesApiClient"),
        NavigationStepsApiClient: new AppServices.ServiceIdentifier<INavigationStepsApiClient>("ApiClient.INavigationStepsApiClient"),
        IndustriesVersionsApiClient: new AppServices.ServiceIdentifier<IIndustriesVersionApiClient>("ApiClient.IIndustriesVersionApiClient"),
        DemoClientsApiClient: new AppServices.ServiceIdentifier<IDemoClientsApiClient>("ApiClient.DemoClientsApiClient"),
        IROIndustryMappingApiClient: new AppServices.ServiceIdentifier<IIROIndustryMappingApiClient>("ApiClient.IROIndustryMappingApiClient"),
        ActionListConfigurationsApiClient: new AppServices.ServiceIdentifier<IActionListConfigurationsApiClient>("ApiClient.IActionListConfigurationsApiClient"),
        ReportingApiClient: new AppServices.ServiceIdentifier<IReportingApiClient>("ApiClient.IReportingApiClient"),

        //Hubs
        ComXHub: new AppServices.ServiceIdentifier<IComXHub>("ApiClient.IComXHub"),
    },
    Services: {
        AppDataCache: new AppServices.ServiceIdentifier<AppDataService>("Services.AppDataCache"),
    },
    Security: {
        CustomAuthenticationService: AppServices.NeoTypes.Security.AuthenticationService.asType<OidcAuthService>(),
    },
    Neo: NeoReactTypes,
    Config: AppServices.NeoTypes.Config.ConfigModel.asType<AppConfig>()
};

export default Types;