import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import ClientSettingsVM from '../ClientSettingsVM';
import { observer } from 'mobx-react';
import * as Roles from '../../../Models/Security/Roles';
import Types from '../../../Modules/AppTypes';
import { textConstants } from '../../../common/textConstants';
import { AppService } from '../../../Services/AppService';
import SearchField from '../../../Components/SearchField';
import GreylistedProspectGrid from './GreylistedProspectGrid/GreylistedProspectGrid';
import * as Icon from "react-feather";
import SearchFieldVM from '../../../Components/SearchFieldVM';
import { StyledButton } from 'Components/Button/styles';
import BlacklistAndGreyListUploadModal from 'Components/Modals/BlacklistAndGreylistUploadModal';
import QuickAddModal from 'Components/QuickAddModal';
import QuickAddItem from 'Models/BlacklistedDomains/QuickAddDomain';
import { List } from '@singularsystems/neo-core';

interface IGreyListedProspect {
  viewModel: ClientSettingsVM
}

@observer
export default class GreyListedProspect extends React.Component<IGreyListedProspect> {
  private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

  public securityCheck(role: any, openMethod: () => void) {
    if (this.authorisationService.hasRole(role)) {
      openMethod();
    }
    else {
      this.props.viewModel.showForbiddenModal = true;
    }
  }

  public async onDocumentSelected(fileList: File[] | FileList) {

    this.props.viewModel.greyListProspectHelper.fileName = fileList[0].name;

    this.props.viewModel.greyListProspectHelper.fileList = [fileList[0]];
  }

  public searchFieldVM = new SearchFieldVM();

  public render() {
    const searchFieldVM = this.searchFieldVM;
    // Client

    return (
      <Neo.Tab
        onDisplayed={() => { this.props.viewModel.toggleTab("", textConstants.titleText.GreyList, this.props.viewModel.clientId) }}
        header={textConstants.titleText.GreyList}>
        <div className="internalViewStyle GreyListedProspect">
          <Neo.Card className='cardPadding'>
            {/* Greylist card*/}
            <div className="row m-0">
              <div className="col-lg-12 p-0 text-justify">
                <div className="row m-0">
                  {/* GreyList title */}
                  <div className="p-0 col-2">
                    <h2>{textConstants.titleText.GreyList}</h2>
                  </div>

                  <div className="col-10 p-0">
                    {/* Quick Add button */}
                    <div className="text-center p-0 pull-right ml-4 negativeMargins mb-1">
                      <StyledButton variant="hasIcon" size="xSmall"
                        onClick={() => this.props.viewModel.greyListProspectHelper.showGreyListQuickAddModal = true}>
                        {<Icon.Plus />}
                        {textConstants.titleText.Email}
                      </StyledButton>
                    </div>

                    {/* Download button */}
                    <div className="text-center pull-right ml-4 extraLightButton mb-1">
                      <Neo.Button
                        icon={<Icon.Download size={24} />}
                        onClick={() => this.props.viewModel.greyListProspectHelper.downloadGreyListProspect()}
                        variant='light'
                        className="buttonWidthBlacklist buttonWidthResponsive">{textConstants.buttonText.Download}</Neo.Button>
                    </div>

                    {/* Upload button */}
                    <div className="text-center pull-right ml-4 extraLightButton mb-1">
                      <Neo.Button
                        icon={<Icon.Upload size={24} />}
                        variant='light'
                        className="buttonWidthBlacklist buttonWidthResponsive"
                        onClick={() => this.securityCheck(Roles.Greylist.Manage,
                          () => this.props.viewModel.greyListProspectHelper.openGreyListUploadModal())}
                      >{textConstants.titleText.Upload}</Neo.Button>
                    </div>

                    {/* Search field */}
                    <div className="p-0 pull-right ml-4">
                      <SearchField
                        searchBindableProp={this.props.viewModel.greyListProspectHelper.greyListProspectSearchCriteria.meta.searchString}
                        onClickFunction={(e) => {
                          this.props.viewModel.greyListProspectHelper.refreshMethod()
                          this.props.viewModel.greyListProspectHelper.isRefreshCount = true
                          searchFieldVM.hideNoResultDialog = true
                        }}
                        searchString={this.props.viewModel.greyListProspectHelper.greyListProspectSearchCriteria.searchString}
                        searchFieldVM={searchFieldVM}
                      />
                    </div>
                  </div>
                </div>
                <div>
                </div>
              </div>
            </div>

            {/* GreyList? Grid */}
            <GreylistedProspectGrid viewModel={this.props.viewModel.greyListProspectHelper} />

            {/* Delete Client Modal */}
            <Neo.Modal title={`Delete confirmation`} show={this.props.viewModel.greyListProspectHelper.showGreylistDeleteModal}
              onClose={() => this.props.viewModel.greyListProspectHelper.showGreylistDeleteModal = false}
              closeButton={{ text: "No", variant: "light" }}
              acceptButton={{
                text: "Yes", variant: "secondary", onClick: () => {
                  this.props.viewModel.greyListProspectHelper.deleteGreylistedEmail()
                  searchFieldVM.hideNoResultDialog = false;
                }
              }}>
              The following Greylisted Email Address &#34;{this.props.viewModel.greyListProspectHelper.selectedEmailName}&#34; is about to be deleted. Do you wish to continue?
            </Neo.Modal>

            {/* Upload Modal */}
            <BlacklistAndGreyListUploadModal
              title={textConstants.generalText.UploadGreylist}
              show={this.props.viewModel.greyListProspectHelper.showGreyListUploadModal}
              onClose={() => this.props.viewModel.greyListProspectHelper.showGreyListUploadModal = false}
              onDocumentSelected={(e) => this.onDocumentSelected(e)}
              downloadTemplate={() => this.props.viewModel.greyListProspectHelper.downloadTemplateForGreylistUpload()}
              fileName={this.props.viewModel.greyListProspectHelper.fileName}
              clearFileList={() => { this.props.viewModel.greyListProspectHelper.fileList = []; this.props.viewModel.greyListProspectHelper.fileName = "" }}
              disableAdd={this.props.viewModel.greyListProspectHelper.fileList.length === 0}
              addFile={async () => {
                this.props.viewModel.greyListProspectHelper.uploadGreylistProspect(this.props.viewModel.greyListProspectHelper.fileList);
                this.props.viewModel.greyListProspectHelper.fileList = [];
                this.props.viewModel.greyListProspectHelper.fileName = "";
              }}
              uploadTitle={textConstants.buttonText.AddCSVFile}
              description={textConstants.generalText.GreylistUploadText}
            />

            {/* Quick Add Modal */}
            <QuickAddModal
              title={textConstants.titleText.QuickAddEmail}
              show={this.props.viewModel.greyListProspectHelper.showGreyListQuickAddModal}
              onClose={() => {
                this.props.viewModel.greyListProspectHelper.cleanUpProcess(true)
                this.props.viewModel.greyListProspectHelper.showGreyListQuickAddModal = false
                this.props.viewModel.greyListProspectHelper.QuickAddStringList = new List(QuickAddItem)
              }}
              onSubmit={async () => {
                await this.props.viewModel.greyListProspectHelper.saveGreylistProspect()
              }}
              quickAddItem={this.props.viewModel.greyListProspectHelper.QuickAddString}
              addPlaceholder={textConstants.titleText.AddGreylistEmail}
              addOnClick={(e: any) => {
                this.props.viewModel.greyListProspectHelper.validateNewEmailEntry(this.props.viewModel.greyListProspectHelper.QuickAddString, true)
              }}
              validateQuickAddItem={this.props.viewModel.greyListProspectHelper.QuickAddErrorEmails}
              addedItemsList={this.props.viewModel.greyListProspectHelper.QuickAddStringList}
              removeItem={(e: any, domain: any) => { this.props.viewModel.greyListProspectHelper.QuickAddStringList.remove(domain); }}
              validationMessage={textConstants.messageText.validationMessage.quickAddEmailMessage}
              description={textConstants.generalText.GreylistQuickAddText}
              validationUnsuccessful={this.props.viewModel.greyListProspectHelper.hasValidationFailed}
            />

            {/* Bulk Action Modal */}
            <Neo.Modal title={`Bulk Action Confirmation`} show={this.props.viewModel.greyListProspectHelper.showBulkActionModal}
              onClose={() => this.props.viewModel.greyListProspectHelper.showBulkActionModal = false}
              closeButton={{ text: "No", variant: "light" }}
              acceptButton={{
                text: "Yes", variant: "secondary", onClick: () => {
                  this.props.viewModel.greyListProspectHelper.PerformBulkAction();
                  searchFieldVM.hideNoResultDialog = false;
                }
              }}>
              {this.props.viewModel.greyListProspectHelper.bulkActionMessage}
            </Neo.Modal>
          </Neo.Card>
        </div>

      </Neo.Tab>
    );
  }
}