import React from 'react';
import { StyledButton } from './styles';
import { IButtonProps } from './types';

const Button = ({ children, variant, size, buttonType, disabled, marginNone, fillAvailable, className, ...rest }: IButtonProps) => {
  return (
    <StyledButton
      className={`${className} btn-styled`}
      variant={variant}
      size={size}
      buttonType={buttonType}
      disabled={disabled}
      marginNone={marginNone}
      fillAvailable={fillAvailable}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default Button;