import { NeoModel, List, ModelBase } from '@singularsystems/neo-core';
import FilteredEmployeeSize from './FilteredEmployeeSize';
import FilteredIndustries from './FilteredIndustries';
import FilterTargetMarketStatuses from './FilterTargetMarketStatuses';
import FilteredProspectedDate from './FilteredProspectedDate';
import { FilteredWhiteListFilterList } from './FilteredWhiteListFilterList';
import FilterProspectedTimes from './FilterProspectedTimes';

@NeoModel
export default class TargetMarketAccountFilterList extends ModelBase {
    public filterTargetMarketStatuses = new List(FilterTargetMarketStatuses)
    public filteredEmployeeSize = new List(FilteredEmployeeSize)
    public filteredIndustries = new List(FilteredIndustries)

    public filteredProspectedDate = new List(FilteredProspectedDate)
    public filteredProspectedTimes = new List(FilterProspectedTimes)
    public filteredWhiteListFilterList = new List(FilteredWhiteListFilterList)
    
    public get filterTargetMarketStatusesDropdown(): any {
        return this.filterTargetMarketStatuses.map((c: FilterTargetMarketStatuses) => {
            return { id: c.targetMarketAccountStatusId, label: c.targetMarketAccountStatusName }
        })
    }
}

@NeoModel
class TriStateList {
    id: number = 1
    label: string = ""
}

export var tristateDropDown: TriStateList[] = [
    {
        id: 1,
        label: "All"
    },
    {
        id: 2,
        label: "Yes"
    },
    {
        id: 3,
        label: "No"
    }]

export var YesDropDown: TriStateList[] = [
    {
        id: 2,
        label: "Yes"
    },
    {
        id: 3,
        label: "No"
    }
]