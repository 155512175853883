import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import BackgroundJobConfigVM from './BackgroundJobConfigVM';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import GoBack from '../../Components/GoBack';

@observer
export default class BackgroundJobConfigView extends Views.ViewBase<BackgroundJobConfigVM> {

	constructor(props: unknown) {
		super("BackgroundJobConfig", BackgroundJobConfigVM, props);
	}

	onLeave() {
		this.viewModel.removeNotifications();
		return undefined;
	}

	public render() {
		return (
			<>
				<GoBack />
				<Neo.Card>
					<h2 className='p-24 mt-4'>{textConstants.titleText.BackgroundJobConfig}</h2>
					<Neo.Form model={this.viewModel.backgroundJobConfigList} onSubmit={() => this.viewModel.saveBackgroundJobConfigList()} showSummaryModal>
						<NeoGrid.Grid items={this.viewModel.backgroundJobConfigList}>
							{(backgroundJobConfig, backgroundJobConfigMeta) => (
								<NeoGrid.Row key={backgroundJobConfig.backgroundJobTypeConfigId}>
									<NeoGrid.Column display={backgroundJobConfigMeta.backgroundJobTypeName} disabled={true} />
									<NeoGrid.Column bind={backgroundJobConfigMeta.maxProcessingDurationMinutes} />
								</NeoGrid.Row>
							)}
						</NeoGrid.Grid>
						<div className="my-4 text-right p-24">
							<Neo.Button className="btn-width-100" isSubmit variant="success">{textConstants.buttonText.Save}</Neo.Button>
						</div>
					</Neo.Form>
				</Neo.Card>
			</>
		);

	}
}