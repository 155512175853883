import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import MasterAccountStatus from '../Models/Maintenance/MasterAccountStatus';
import { AppService, Types } from '../Services/AppService';

export interface IMasterAccountStatusesApiClient extends Data.IUpdateableApiClient<MasterAccountStatus, number> {

}

@injectable()
export default class MasterAccountStatusesApiClient extends Data.UpdateableApiClient<MasterAccountStatus, number> implements IMasterAccountStatusesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/masterAccountStatuses`);
    }
}