import { ModelBase, NeoModel, } from '@singularsystems/neo-core';

@NeoModel
export default class FollowUpTemplateCommentLookup extends ModelBase {

    public followUpTemplateCommentId: number = 0;

    public comment: string = ""
    
    public commenterName: string = "";

    public createdOn: string = "";

    public isInternalUser: boolean = false;

    public isCurrentUser: boolean = false;
}