import { Attributes, ModelBase, NeoModel } from "@singularsystems/neo-core";

@NeoModel
export default class DashboardTooltipModel extends ModelBase{
    public dashboardTooltipId : number = 0;
    
    @Attributes.Display("Dashboard Popup Name")
    public popupName : string = "";

    public header : string = "";

    public subheader : string = "";

    public body : string = "";
    
    public subBody : string = "";

    public link : string = "";

    public articleLinkText : string = "";

    public minValue : number = 0;

    public maxValue : number = 0;

    public dashboardTooltipTypeId : number = 0;

    public performanceLevel : number = 0;
}