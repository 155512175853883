import React from 'react';
import { observer } from 'mobx-react';
import {
  NeoGrid,
} from '@singularsystems/neo-react';
import * as Icon from "react-feather";

import { textConstants } from '../../common/textConstants';
import AddEditTargetMarketVM from './AddEditTargetMarketVM';

interface IAddEditTargetMarketProps {
  viewModel: AddEditTargetMarketVM;
  isNotEditable?: boolean
}

@observer
export default class RegionsSection extends React.Component<
  IAddEditTargetMarketProps
> {
  private setChevron(isExpanded: boolean) {
    if (isExpanded) {
      return <i> <Icon.ChevronUp size={24} /> </i>
    }
    return <i> <Icon.ChevronDown size={24} /> </i>
  }

  public render() {
    return (
      <React.Fragment>
        <div className="RegionsSection">
          <div>
            {/* Regions */}
            {/* Read 'ClientUser' as 'ComXUser' */}
            {((!this.props.viewModel.isComXUser &&
              (this.props.viewModel.targetMarket.showGermanRegions && this.props.viewModel.targetMarket.countryId === this.props.viewModel.germanyId))
              || (!this.props.viewModel.isComXUser && this.props.viewModel.targetMarket.countryId !== this.props.viewModel.germanyId)
              || (this.props.viewModel.isComXUser))
              && (this.props.viewModel.regionList.length > 0 && !this.props.viewModel.areRegionsLoading) &&
              <div className="FunctionsSection">
                <div>
                  <div className='row ml-0'>
                    <h4>{textConstants.titleText.Regions}</h4>
                    <button
                      className="expandRegionAdvancedSection"
                      onClick={() => { this.props.viewModel.isRegionsExpanded = !this.props.viewModel.isRegionsExpanded }}>
                      {this.setChevron(this.props.viewModel.isRegionsExpanded)}
                    </button>
                  </div>

                  {this.props.viewModel.isRegionsExpanded &&
                    <div className='mt-3'>
                      <NeoGrid.Grid items={this.props.viewModel.regionList}
                        className="w-xl-50 w-lg-100 w-md-100 w-sm-100 w-xs-100"
                        disabled={true}>
                        {(region, meta) => (
                          <NeoGrid.RowGroup expandProperty={meta.isExpanded}>

                            <NeoGrid.Row>
                              {/* Select */}
                              <NeoGrid.Column bind={meta.isSelected}
                                label={textConstants.titleText.Select}
                                input={{ type: "checkbox" }}
                                disabled={this.props.isNotEditable}
                                className="width10Percent"
                                onChange={(e: any) => {
                                  this.props.viewModel.toggleRegionSelect(region);
                                  this.props.viewModel.isScreenDirty = true
                                }}
                              />

                              {/* Region */}
                              <NeoGrid.Column display={meta.regionName}
                                label={textConstants.titleText.Region}
                                className="width91Percent"
                              />
                            </NeoGrid.Row>

                            {/* SubRegion */}
                            <NeoGrid.ChildRow>
                              <NeoGrid.Grid items={region.subRegions}
                                className="subRegionHide">
                                {(child, childMeta) => (
                                  <NeoGrid.Row>

                                    {/* Select */}
                                    <NeoGrid.Column bind={childMeta.isSelected}
                                      label={textConstants.titleText.Select}
                                      input={{ type: "checkbox" }}
                                      disabled={this.props.isNotEditable}
                                      className="width10Percent tableHeaderRegions"
                                      onChange={() => {
                                        this.props.viewModel.toggleSubRegionSelect(child, region);
                                        this.props.viewModel.isScreenDirty = true
                                      }} />

                                    {/* SubRegion */}
                                    <NeoGrid.Column
                                      display={childMeta.subRegionName}
                                      className="width91Percent tableHeaderRegions"
                                    />
                                  </NeoGrid.Row>
                                )}
                              </NeoGrid.Grid>
                            </NeoGrid.ChildRow>
                          </NeoGrid.RowGroup>
                        )}
                      </NeoGrid.Grid>
                    </div>
                  }

                </div>
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}
