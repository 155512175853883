import React from 'react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import InvitedUsersViewModel from '../Component/InvitedUserViewModel';
import { observer } from 'mobx-react';
import { textConstants } from '../../../common/textConstants';
import RoInvitedUserLookup from '.././LookUps/RoInvitedUserLookup';
import InfoLabel from '../../TargetMarket/Functions/InfoLabel';
import CustomPagingControl from '../../../Components/CustomPagingControl';
import SearchField from '../../../Components/SearchField';
import * as Icon from 'react-feather';
import InfoBox from '../../../Components/InfoBox';
import Button from 'Components/Button/Button';

/**
 *  Interface for the InvitedUsersComponent
 */
interface IInvitedUsersComponentProps {
	viewModel: InvitedUsersViewModel,
	title: string,
	addHeaderPadding: boolean
}

@observer
export default class InvitedUsersComponent extends React.Component<IInvitedUsersComponentProps>{

	private applyAppropriatePadding() {
		if (this.props.addHeaderPadding) {
			return "p-0 marginTop24"
		} else {
			return "p-0"
		}
	}

	public render() {
		const { searchFieldVM } = this.props.viewModel

		{/*Invited Users*/ }
		const t1 =
			<Neo.Tab header={textConstants.titleText.InvitedUsers} onDisplayed={() => this.props.viewModel.toggleTab(this.props.viewModel.clientId, this.props.viewModel.InviteUsersTab.value)}>
				<div className='negSideMargins24'>
					{/* Invited User Email header */}
					<div className='row m-0 p-24 pr-0'>
						<h4 className='col-3 p-0'>{textConstants.titleText.InviteEmail}</h4>
						<div className="col-9 pull-right p-0 ">
							<div className='marginRight24'>
								<SearchField
									onClickFunction={() => { this.props.viewModel.pageManagerInvited.refreshData() }}
									searchBindableProp={this.props.viewModel.pageManagerInvited.criteria!.meta.search}
									searchString={this.props.viewModel.pageManagerInvited.criteria!.search}
									searchFieldVM={searchFieldVM}
								/>
							</div>
						</div>
					</div>

					{this.props.viewModel.pageManagerInvited.totalRecords === 0 &&
						this.props.viewModel.searchFieldVM.isSearching &&
						<InfoBox header={textConstants.titleText.NoSearchMatches}
							text={textConstants.generalText.NoSearchMatches} />
					}

					{this.props.viewModel.pageManagerInvited.totalRecords !== 0 &&
						<React.Fragment>
							<Neo.Pager className="m-0" pageManager={this.props.viewModel.pageManagerInvited} pageControls="none">
								{(data) => (
									<NeoGrid.Grid<RoInvitedUserLookup> items={data}>
										{(item) => (
											<NeoGrid.Row>
												<NeoGrid.Column display={item.meta.inviteEmail} />
												<NeoGrid.Column display={item.meta.clientName} />
												<NeoGrid.Column>
													<Neo.Button variant="light" className="text-center pull-right" text="Re-invite" onClick={() => this.props.viewModel.reinviteUser(item.id)} />
												</NeoGrid.Column>
												<NeoGrid.Column width={1}>
													<Neo.Button variant="danger" className="text-center" icon={<Icon.Trash2 className='mr-1' />} onClick={() => this.props.viewModel.displayDeleteModal(true, item.id)} />
												</NeoGrid.Column>
											</NeoGrid.Row>
										)}
									</NeoGrid.Grid>
								)}
							</Neo.Pager>
							<div className="col-12 p-24">
								<CustomPagingControl
									displayLabel={true}
									pageManager={this.props.viewModel.pageManagerInvited} />
							</div>
						</React.Fragment>
					}
				</div>
			</Neo.Tab>;

		{/*Active Users*/ }
		const t2 =
			<Neo.Tab header={textConstants.titleText.ActiveUsers} onDisplayed={() => this.props.viewModel.toggleTab(this.props.viewModel.clientId, this.props.viewModel.ActiveUsersTab.value)}>
				<div className='negSideMargins24'>
					<div className='row m-0 p-24 pr-0'>
						<h4 className='col-3 fontSize p-0'>{textConstants.titleText.ActiveUserEmail}</h4>
						<div className="col-9 pull-right p-0">
							<div className='marginRight24'>
								<SearchField
									onClickFunction={() => { this.props.viewModel.pageManagerActive.refreshData() }}
									searchBindableProp={this.props.viewModel.pageManagerActive.criteria!.meta.search}
									searchString={this.props.viewModel.pageManagerActive.criteria!.search}
									searchFieldVM={searchFieldVM}
								/>
							</div>
						</div>
					</div>
					{this.props.viewModel.pageManagerActive.totalRecords === 0 &&
						this.props.viewModel.searchFieldVM.isSearching &&
						<InfoBox header={textConstants.titleText.NoSearchMatches}
							text={textConstants.generalText.NoSearchMatches} />
					}
					
					{/* Active Users grid */}
					{this.props.viewModel.pageManagerActive.totalRecords !== 0 &&
						<React.Fragment>
							<Neo.Pager className="m-0" pageManager={this.props.viewModel.pageManagerActive} pageControls="none">
								{(data) =>
									<NeoGrid.Grid<RoInvitedUserLookup> items={data}>
										{(item) => (
											<NeoGrid.Row>
												<NeoGrid.Column display={item.meta.firstName} />
												<NeoGrid.Column display={item.meta.lastName} />
												<NeoGrid.Column display={item.meta.inviteEmail} />
												<NeoGrid.Column display={item.meta.clientName} />
												<NeoGrid.Column width={125}>
													<Neo.Button variant='light' text="Edit" onClick={() => this.props.viewModel.displayEditUserModal(true, item)} />
												</NeoGrid.Column>
												<NeoGrid.Column width={125}>
													<Neo.Button text="Block" variant='light' onClick={() => this.props.viewModel.displayBlockModal(true, item.meta.id.value)} />
												</NeoGrid.Column>
											</NeoGrid.Row>
										)}
									</NeoGrid.Grid>
								}
							</Neo.Pager>
							<div className="col-12 p-24">
								<CustomPagingControl
									displayLabel={true}
									pageManager={this.props.viewModel.pageManagerActive} />
							</div>
						</React.Fragment>
					}
				</div>
			</Neo.Tab>;

		{/*Blocked Users*/ }
		const t3 =
			<Neo.Tab header={textConstants.titleText.BlockedUsers} onDisplayed={() => this.props.viewModel.toggleTab(this.props.viewModel.clientId, this.props.viewModel.BlockedUsersTab.value)}>
				<div className='negSideMargins24'>
					<div className='row m-0 p-24 pr-0'>
						<h4 className='fontSize col-3 p-0'>{textConstants.titleText.BlockUsers}</h4>
						<div className="col-9 pull-right p-0">
							<div className='marginRight24'>
								<SearchField
									onClickFunction={() => { this.props.viewModel.pageManagerBlocked.refreshData() }}
									searchBindableProp={this.props.viewModel.pageManagerBlocked.criteria!.meta.search}
									searchString={this.props.viewModel.pageManagerBlocked.criteria!.search}
									searchFieldVM={searchFieldVM}
								/>
							</div>
						</div>
					</div>

					{this.props.viewModel.pageManagerBlocked.totalRecords === 0 &&
						this.props.viewModel.searchFieldVM.isSearching &&
						<InfoBox header={textConstants.titleText.NoSearchMatches}
							text={textConstants.generalText.NoSearchMatches} />
					}
					{this.props.viewModel.pageManagerBlocked.totalRecords !== 0 &&
						<React.Fragment>
							<Neo.Pager className="m-0" pageManager={this.props.viewModel.pageManagerBlocked} pageControls="none">
								{(data) =>
									<NeoGrid.Grid<RoInvitedUserLookup> items={data}>
										{(item, meta) => (
											<NeoGrid.Row>
												<NeoGrid.Column display={item.meta.firstName} />
												<NeoGrid.Column display={item.meta.lastName} />
												<NeoGrid.Column display={item.meta.inviteEmail} />
												<NeoGrid.Column display={item.meta.clientName} />
												<NeoGrid.Column width={125}>
													<Neo.Button text="Unblock" variant='light' onClick={() => this.props.viewModel.displayUnblockModal(true, meta.id.value)} />
												</NeoGrid.Column>
											</NeoGrid.Row>
										)}
									</NeoGrid.Grid>
								}
							</Neo.Pager>
							<div className="col-12 p-24">
								<CustomPagingControl
									displayLabel={true}
									pageManager={this.props.viewModel.pageManagerBlocked} />
							</div>
						</React.Fragment>
					}
				</div>
			</Neo.Tab>;

		{/*Locked Users*/ }
		const t4 =
			<Neo.Tab header={textConstants.titleText.LockedUsers} onDisplayed={() => this.props.viewModel.toggleTab(this.props.viewModel.clientId, this.props.viewModel.LockedUsersTab.value)}>
				<div className='negSideMargins24'>
					<div className='row m-0 p-24 pr-0'>
						<h4 className='col-3 fontSize p-0'>{textConstants.titleText.LockedUserEmail}</h4>
						<div className="col-9 pull-right p-0">
							<div className='marginRight24'>
								<SearchField
									onClickFunction={() => { this.props.viewModel.pageManagerLocked.refreshData() }}
									searchBindableProp={this.props.viewModel.pageManagerLocked.criteria!.meta.search}
									searchString={this.props.viewModel.pageManagerLocked.criteria!.search}
									searchFieldVM={searchFieldVM}
								/>
							</div>
						</div>
					</div>

					{this.props.viewModel.pageManagerLocked.totalRecords === 0 &&
						this.props.viewModel.searchFieldVM.isSearching &&
						<InfoBox header={textConstants.titleText.NoSearchMatches}
							text={textConstants.generalText.NoSearchMatches} />
					}

					{this.props.viewModel.pageManagerLocked.totalRecords === 0 &&
						this.props.viewModel.selectedTab === this.props.viewModel.LockedUsersTab.value &&
						!this.props.viewModel.searchFieldVM.isSearching &&
						<InfoBox header={textConstants.titleText.NoLockedUsers}
							text={textConstants.generalText.NoLockedUsers} />
					}

					{this.props.viewModel.pageManagerLocked.totalRecords !== 0 &&
						<React.Fragment>
							<Neo.Pager className="m-0" pageManager={this.props.viewModel.pageManagerLocked} pageControls="none">
								{(data) =>
									<NeoGrid.Grid<RoInvitedUserLookup> items={data}>
										{(item, meta) => (
											<NeoGrid.Row>
												<NeoGrid.Column display={item.meta.firstName} />
												<NeoGrid.Column display={item.meta.lastName} />
												<NeoGrid.Column display={item.meta.inviteEmail} />
												<NeoGrid.Column display={item.meta.clientName} />
												<NeoGrid.Column width={125}>
													<Neo.Button text="Unlock" variant='light' onClick={() => this.props.viewModel.unlockLockedUser(meta.registeredUserId.value)} />
												</NeoGrid.Column>
											</NeoGrid.Row>
										)}
									</NeoGrid.Grid>
								}
							</Neo.Pager>
							<div className="col-12 p-24">
								<CustomPagingControl
									displayLabel={true}
									pageManager={this.props.viewModel.pageManagerLocked} />
							</div>
						</React.Fragment>
					}
				</div>
			</Neo.Tab>;

		return (
			<div className={this.props.viewModel.invitedUserPage ? "InvitedUsersView negSideMargins24" : "InvitedUsersView m-0"}>
				<div className={`row m-0 ${this.applyAppropriatePadding()}`}>

					{/* Users header */}
					<div className='col-2'>
						<h2 className='pt-0'>{this.props.title}</h2>
					</div>

					{/* Invite User button */}
					<div className="col-10 p-24">
						<Button
							buttonType='primary'
							className="pull-right ml-4 mt-0 mb-1"
							variant="hasIcon"
							size="xSmall"
							onClick={() => this.props.viewModel.displayInviteModal(true)}
						>
							<Icon.Plus />
							{textConstants.buttonText.InviteUser}
						</Button>
					</div>
				</div>
				<div className='invitedUsers'>
					<Neo.TabContainer selectedTab={this.props.viewModel.meta.selectedTab} className='m-0 marginTop24'>
						{t1} {t2} {t3} {t4}
					</Neo.TabContainer>
				</div>

				{/* Delete Modal */}
				<Neo.Modal title={`Delete confirmation`} show={this.props.viewModel.showDeleteModal}
					onClose={() => this.props.viewModel.showDeleteModal = false}
					closeButton={{ text: "No", variant: "light" }}
					acceptButton={{ text: "Yes", variant: "secondary", onClick: () => this.props.viewModel.deleteUser() }}>
					Invited User {this.props.viewModel.InvitedUserToDelete()} is about to be deleted. Do you wish to continue?
				</Neo.Modal>

				{/* Invited Users Modal */}
				<Neo.Modal
					className='submitButtonStyles'
					title={textConstants.titleText.InvitedUsers}
					show={this.props.viewModel.showInviteModal}
					onClose={() => this.props.viewModel.displayInviteModal(false)}
					formProps={{ model: this.props.viewModel.inviteCommand }}
					acceptButton={{ text: textConstants.buttonText.Invite, variant: "secondary", onClick: () => this.props.viewModel.inviteUser(), }}
					closeButton={{ text: textConstants.buttonText.Cancel, variant: "light" }} >
					<div>
						<InfoLabel
							labelType="danger"
							labelText={textConstants.messageText.validationMessage.usergroupErrorMessage}
							isVisible={this.props.viewModel.displayUserGroupValidationMessage}
							font=""
						/>
						<p>
							{textConstants.titleText.EmailAndInvite}
						</p>
						{/* {this.props.viewModel.commandResult !== null && !this.props.viewModel.commandResult.success &&
							<Neo.Alert variant={"warning"}>{this.props.viewModel.commandResult.error}</Neo.Alert>} */}
						<Neo.Input bind={this.props.viewModel.inviteCommand!.meta.inviteEmail} />
						<br />
						{
							this.props.viewModel.clientId === 0 &&
							<NeoGrid.Grid items={this.props.viewModel.userGroupsToAdd} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary", className: 'addUserGroup' }}>
								{(ug, meta) => (
									<NeoGrid.Row >
										<NeoGrid.Column
											bind={meta.userGroupId}
											select={{
												displayMember: "userGroupName", items: this.props.viewModel.userGroups.sortBy("userGroupName")
											}}
										/>
										<NeoGrid.ButtonColumn
											showDelete
											deleteButton={{
												icon: <Icon.X size={24} />,
												variant: "light",
												className: "circled"
											}
											} />
									</NeoGrid.Row>
								)}
							</NeoGrid.Grid>
						}
					</div>
				</Neo.Modal>

				{/* Blocked Users Modal */}
				<Neo.Modal title={textConstants.titleText.BlockUsers}
					show={this.props.viewModel.showBlockModal}
					onClose={() => this.props.viewModel.displayBlockModal(false, 0)}
					formProps={{ model: this.props.viewModel.blockCommand, showSummaryModal: true }}
					acceptButton={{ text: textConstants.buttonText.Block, variant: "secondary", onClick: () => this.props.viewModel.blockUser(), }}
					closeButton={{ text: textConstants.buttonText.Cancel, variant: "light" }} >
					<div>
						<p>
							{textConstants.titleText.ReasonAndBlock}
						</p>
						{this.props.viewModel.commandResult !== null && !this.props.viewModel.commandResult.success &&
							<Neo.Alert variant={"warning"}>{this.props.viewModel.commandResult.error}</Neo.Alert>}
						<Neo.Input bind={this.props.viewModel.blockCommand!.meta.reason} />
					</div>
				</Neo.Modal>

				{/* Unblock Users Modal */}
				<Neo.Modal title={textConstants.titleText.UnblockUsers}
					show={this.props.viewModel.showUnblockModal}
					onClose={() => this.props.viewModel.displayUnblockModal(false, 0)}
					formProps={{ model: this.props.viewModel.unblockCommand, showSummaryModal: true }}
					acceptButton={{ text: textConstants.buttonText.Unblock, variant: "secondary", onClick: () => this.props.viewModel.unblockUser(), }}
					closeButton={{ text: textConstants.buttonText.Cancel, variant: "light" }} >
					<div>
						<p>
							{textConstants.titleText.ReasonAndUnblock}
						</p>
						{this.props.viewModel.commandResult !== null && !this.props.viewModel.commandResult.success &&
							<Neo.Alert variant={"warning"}>{this.props.viewModel.commandResult.error}</Neo.Alert>}
						<Neo.Input bind={this.props.viewModel.blockCommand!.meta.reason} />
					</div>
				</Neo.Modal>

				{/* Edit Users Modal */}
				<Neo.Modal title={textConstants.titleText.EditUser}
					show={this.props.viewModel.showEditModal}
					onClose={() => this.props.viewModel.displayEditUserModal(false, undefined)}
					formProps={{ model: this.props.viewModel.editUserCommand, showSummaryModal: true }}
					acceptButton={{ text: textConstants.buttonText.Save, variant: "secondary", onClick: async () => this.props.viewModel.updateUserDetails(), }}
					closeButton={{ text: textConstants.buttonText.Cancel, variant: "light" }} >
					<div className='row'>
						<div className='col-6'>
							<Neo.FormGroup bind={this.props.viewModel.editUserCommand.meta.firstName} />
						</div>
						<div className='col-6'>
							<Neo.FormGroup bind={this.props.viewModel.editUserCommand.meta.lastName} />
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<Neo.FormGroup bind={this.props.viewModel.editUserCommand.meta.email} disabled={true} />
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<Neo.FormGroup
								bind={this.props.viewModel.editUserCommand.meta.clientid}
								select={{ items: this.props.viewModel.clientList }} />
						</div>
					</div>
				</Neo.Modal>
			</div>
		);
	}
}