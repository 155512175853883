import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { AppService, Types, } from '../../Services/AppService';
import TechnicalSettingsVM from './TechnicalSettingsVM';
import CRMIntegration from './CRMIntegration';
import TermsOfService from './TermsOfServiceView';
import ActionPlanChecklist from './ActionPlanChecklistView';
import { textConstants } from '../../common/textConstants';
import AdvancedEmailSettings from '../../Components/TechnicalIntegrationSettings/AdvancedEmailSettings';
import "./Styles/TechnicalSettingsContainer.scss";
import * as Roles from '../../Models/Security/Roles';

interface ITechnicalSettingsProps {
    viewModel: TechnicalSettingsVM,
    clientID: number
}

@observer
export default class TechnicalSettingsContainer extends React.Component<ITechnicalSettingsProps> {

    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);
    protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService)

    private securityCheck(role: any) {
        if (role.includes("crm") || role.includes("action")) {
            return this.authorisationService.hasRole(role)
        }

        return this.authorisationService.hasRole(role) || this.authenticationService.globalProps.isOnboarding;
    }

    public render() {
        const technicalIntegrationSettings =
            <Neo.Tab
                onDisplayed={() => {
                    this.props.viewModel.toggleTab(this.props.viewModel.tabs.TechnicalIntegration, this.props.clientID)
                }}
                header={textConstants.TechnicalIntegrationSettings}>
                <AdvancedEmailSettings
                    addPadding={false}
                    isExternal={true}
                    viewModel={this.props.viewModel.advancedEmailSettingsVM} />
            </Neo.Tab>

        const CRMIntegrationSettings =
            <Neo.Tab
                onDisplayed={() => {
                    this.props.viewModel.toggleTab(this.props.viewModel.tabs.CRM, this.props.clientID)
                }}
                header={textConstants.CRMIntegrationSettings}>

                <CRMIntegration crmIntegrationLookup={this.props.viewModel.crmIntegrationLookup} viewModel={this.props.viewModel.infoVideoVM} />
            </Neo.Tab>

        const actionPlanChecklist =
            <Neo.Tab
                onDisplayed={() => {
                    this.props.viewModel.toggleTab(this.props.viewModel.tabs.ActionPlan, this.props.clientID)
                }}
                header={textConstants.ActionPlanChecklist}>
                <ActionPlanChecklist actionPlanChecklistLookup={this.props.viewModel.actionPlanChecklistLookup} viewModel={this.props.viewModel}/>

            </Neo.Tab>

        const termsOfService =
            <Neo.Tab
                onDisplayed={() => {
                    this.props.viewModel.toggleTab(this.props.viewModel.tabs.TermsOfService, this.props.clientID)
                }}
                header={textConstants.TermsOfService}>

                <TermsOfService termsOfServiceLookup={this.props.viewModel.termsOfServiceLookup} />
            </Neo.Tab>

        return (
            <div className='row m-0'>
                <div className='col-12 p-0'>
                    <Neo.TabContainer
                        className='m-0 p-0'
                        onSelect={() => { }}
                        tabManager={this.props.viewModel.tabManager}>

                        {/*Technical Settings*/}
                        {
                            (this.securityCheck(Roles.TI_AdvancedEmailSettings.View) ||
                                this.securityCheck(Roles.TI_AdvancedEmailSettings.Edit) ||
                                this.securityCheck(Roles.TI_InternalTest.View) ||
                                this.securityCheck(Roles.TI_InternalTest.Edit) ||
                                this.securityCheck(Roles.TI_OutboundEmails.View) ||
                                this.securityCheck(Roles.TI_OutboundEmails.Edit) ||
                                this.securityCheck(Roles.TI_OutboundEmails.Delete)) &&
                            technicalIntegrationSettings
                        }
                        {/*CRM Settings*/}
                        {
                            !this.authenticationService.globalProps.isOnboarding &&
                            this.securityCheck(Roles.Settings.CRMIntegration) &&
                            CRMIntegrationSettings
                        }

                        {/*Action Plan Checklist*/}
                        {
                            !this.authenticationService.globalProps.isOnboarding &&
                            this.securityCheck(Roles.Settings.ActionPlanChecklist) &&
                            actionPlanChecklist
                        }
                        {/*Terms of Service*/}
                        {
                            !this.authenticationService.globalProps.isOnboarding &&
                            this.securityCheck(Roles.Settings.TermsOfService) &&
                            termsOfService
                        }
                    </Neo.TabContainer>
                </div>

            </div>
        );
    }
}