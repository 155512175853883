import { NeoModel, List } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import SubIndustry from './../../Models/Maintenance/SubIndustry';
import { AppService } from '../../Services/AppService';
import Types from './../../Modules/AppTypes';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class SubIndustryVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private subIndustriesApiClient = AppService.get(Types.ApiClients.SubIndustriesApiClient)) {
        super(taskRunner);
    }

    public subIndustryList = new List(SubIndustry);

    public initialise() {
        this.taskRunner.run(async () => {
             const response = await this.subIndustriesApiClient.get();
             this.subIndustryList.set(response.data);
        });
    }

    public saveSubIndustryList(){
        this.taskRunner.run(async () => {
            const Data = this.subIndustryList.toJSArray();
            const response = await this.subIndustriesApiClient.saveList(Data);
            this.subIndustryList.update(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved,textConstants.messageText.saveMessage.SubIndustrySaved);
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}