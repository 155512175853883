import {Attributes, ModelBase, NeoModel, Validation} from '@singularsystems/neo-core';
import { textConstants } from '../../../common/textConstants';

@NeoModel
export default class NumberOnlyAnswerType extends ModelBase {
    // Properties
    @Attributes.Integer()
    public answer : number = 0

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.answer <= 0 , textConstants.messageText.validationMessage.questionRequired);
        rules.failWhen(c => isNaN(c.answer), textConstants.messageText.validationMessage.onlyNumbersAllowed);
    }
}