import { ModelBase, NeoModel } from "@singularsystems/neo-core";

@NeoModel
export default class EmailProviderTypeLookup extends ModelBase{

    public emailProviderTypeId : number = 0

    public providerName : string = ""

    public isActive : boolean = false

    public uniqueTableKey : string = ""
}