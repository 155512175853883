import React, { useState, useEffect, useRef } from "react";
import {
  StyledDropDownContainer,
  StyledDropDownInput,
  StyledDropDownMenu,
  StyledDropDownMenuItem,
  StyledDropDownTags,
  StyledDropDownTag,
  StyledDropDownTagClose,
} from "./styles";
import ChevronDown from "assets/svgs/ChevronDown";
import ChevronUp from "assets/svgs/ChevronUp";
import HtmlParser from "react-html-parser";

import useClickOutside from "hooks/useClickOutSide";

export interface IOptions {
  value: number | string;
  label: string;
}

export interface ICustomDropDownProps {
  placeHolder: string | string[] | JSX.Element;
  options: IOptions[];
  isMulti?: boolean;
  isDisabled?: boolean;
  onChange?: (itemId: number) => void;
  variant?: "contained";
  className?: string,
  isReadOnly?: boolean
}

const CustomDropDown = ({
  placeHolder,
  options,
  isMulti,
  isDisabled,
  onChange,
  variant,
  className,
  isReadOnly,
}: ICustomDropDownProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [y, setY] = useState(0);
  const [topPositioning, setPosition] = useState(true);
  const [selectedValue, setSelectedValue] = useState<IOptions | [] | null>(
    isMulti ? [] : null
  );
  const dropdownRef = useRef();
  const dropDownContainer = useRef<HTMLDivElement>(null);

  let currentDisplay;

  const handleInputClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    const rect = (dropdownRef.current as any).getBoundingClientRect();
    const clientHeight = document.documentElement.clientHeight;
    const bottomSpace = clientHeight - rect.bottom;
    const topSpace = rect.top;

    // Calculate the offset of the dropdown menu
    const offset = 60;

    const bottomGreaterThanTop = bottomSpace > topSpace

    // Set the position of the dropdown menu
    setY(offset);
    setPosition(bottomGreaterThanTop)

    // Toggle the visibility of the dropdown menu
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    // @ts-ignore
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    }
    if (isMulti) {
      return (
        <StyledDropDownTags>
          {/* @ts-ignore */}
          {selectedValue.map((option) => (
            <StyledDropDownTag key={option.value} className="dropdown-tag-item">
              {option.label}
              <StyledDropDownTagClose
                onClick={(e) => onTagRemove(e, option)}
                className="dropdown-tag-close"
              >
                {y < 0 ? <ChevronUp /> : <ChevronDown />}
              </StyledDropDownTagClose>
            </StyledDropDownTag>
          ))}
        </StyledDropDownTags>
      );
    }
    // @ts-ignore
    return typeof selectedValue.label === 'string' ? HtmlParser(selectedValue.label) : selectedValue.label;
  };

  const removeOption = (option: IOptions) => {
    // @ts-ignore
    return selectedValue.filter((o) => o.value !== option.value);
  };
  const onTagRemove = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    option: any
  ) => {
    e.stopPropagation();
    setSelectedValue(removeOption(option));
  };

  const onItemClick = (option: IOptions) => {
    let newValue;
    if (isMulti) {
      // @ts-ignore
      if (selectedValue.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        // @ts-ignore
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
      onChange && onChange(newValue.value as number);
    }
    setSelectedValue(newValue);
  };

  const isSelected = (option: IOptions) => {
    if (isMulti) {
      // @ts-ignore
      return selectedValue.filter((o) => o.value === option.value).length > 0;
    }
    if (!selectedValue) {
      return false;
    }
    // @ts-ignore
    return selectedValue.value === option.value;
  };

  // REMOVED FOR NOW, WHILE DROPDOWN IS NOT FUNCTIONAL
  // useEffect(() => {
  //   const handler = () => setShowMenu(!showMenu);

  //   window.addEventListener("click", handler);

  //   return () => {
  //     window.removeEventListener("click", handler);
  //   };
  // }, []);

  useClickOutside(dropdownRef, () => {
    setShowMenu(false);
  });

  const getDisplayValue = () => {
    currentDisplay = getDisplay()
    let placeholder = typeof currentDisplay === 'string' ? HtmlParser(currentDisplay) : currentDisplay
    return placeholder;
  }

  return (
    <StyledDropDownContainer className={className} isDisabled={isDisabled} ref={dropDownContainer}
      variant={variant} isReadOnly={isReadOnly}>
      <StyledDropDownInput
        // @ts-ignore
        ref={dropdownRef}
        onClick={handleInputClick}
      >
        <div className="dropdown-selected-value">
          {getDisplayValue()}
        </div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            {!isDisabled && <>{showMenu ? <ChevronUp /> : <ChevronDown />}</>}
          </div>
        </div>
      </StyledDropDownInput>
      {showMenu && (
        <StyledDropDownMenu
          topPositioning={topPositioning}
          offset={y}
        >
          {options.map((option: IOptions, key) => {
            return (
              <StyledDropDownMenuItem
                onClick={() => onItemClick(option)}
                key={key}
                selected={isSelected(option)}
              >
                {typeof option.label === 'string' ? HtmlParser(option.label) : option.label}
              </StyledDropDownMenuItem>
            );
          })}
        </StyledDropDownMenu>
      )}
    </StyledDropDownContainer>
  );
};

export default CustomDropDown;
