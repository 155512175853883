import '@inovua/reactdatagrid-enterprise/index.css';
import React, { useCallback, useState, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import { ReactDataGridManager } from '../../Models/ComxDataGridManager/ReactDataGridManager';

import { ModelBase, ValueObject } from '@singularsystems/neo-core';
import { TypeSortInfo, TypeFilterValue, TypeRowSelection } from '@inovua/reactdatagrid-enterprise/types';
import { GridProps, createFilterDataColumn, externalFilterDataColumn, numberFilterDataColumn, GridManagerConst } from './DataGridHelper';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import { debounce } from 'lodash';


interface IComXReactDataGridProps<TCriteria, TModel, TFilter> {
    idProperty: string
    licence: string
    columns: any
    style?: any
    pagination: boolean | "remote" | undefined
    defaultFilterValue?: any
    enableFiltering?: any
    checkboxColumn?: any
    emptyText?: any
    className?: string
    cellSelection?: any
    criteria: TCriteria
    externalSearchStringName: string // searchString
    initialPageSize: number
    filterTypes?: any
    refreshDataGrid: any
    selected?: TypeRowSelection
    defaultGroupBy?: any
    onFilterChange?: (x: any) => any
    setReactPageGridAndList: (x: any) => any
    onSelectionChange: (x: TypeOnSelectionChangeArg, totalRecords?: number) => void | undefined
    modelType: new () => TModel
    TFilter: new () => TFilter
    rowClassName: (data: any, props: any, style: any) => any
}
/** @template T */
class TCriteria extends ValueObject {

    /** @param {T} t */
    set(t: any) { };
}
class TModel extends ModelBase {

    /** @param {T} t */
    set(t: any) { };
}

class TFilter extends ModelBase {
    /** @param {T} t */
    set(t: any) { };
}
const ComXReactDataGrid = (props: IComXReactDataGridProps<TCriteria, TModel, TFilter>) => {

    var reactDataGridManager = new ReactDataGridManager<TCriteria, TModel, TFilter>(
        props.criteria,
        props.modelType,
        (x) => props.setReactPageGridAndList(x),
        props.TFilter,
        {
            pageSize: props.initialPageSize,
            fetchInitial: false
        }
    )
    var filterColumns: any = props.columns

    const [filterOptions, setFilterOptions]: [any, any] = useState(null);
    const [gridColumns, setColumns]: [any, any] = useState(filterColumns);
    const [gridRef, setGridRef]: [any, any] = useState(null);
    const [loading, setLoading] = useState(false);

    const setGridSkip = (skip: number) => {
        gridProps.skip = skip
        dataSource(gridProps)
    }

    const setGridLimit = (limit: number) => {
        gridProps.limit = limit
        dataSource(gridProps)
    }

    const setGridSortInfo = (sortInfo: TypeSortInfo, gridProps: any) => {
        gridProps.sortInfo = sortInfo
        dataSource(gridProps)
    }
    const delayFetch = debounce(() => dataSource(gridProps), 1000);
    const setGridFilterValue = (filterValue: TypeFilterValue) => {
        gridProps.filterValue = filterValue
        deselectAll()
        delayFetch()
    }

    const [gridProps, setGridProps]: [GridProps, any] = useState({
        currentData: [],
        filterValue: [],
        limit: reactDataGridManager.pageSize,
        skip: 0,
    });

    const [dataSource1, setDataSource]: [any, any] = useState([{ data: [], count: 0 }]);

    const dataSource = useCallback((props: any) => getData(props), []);
 
    const deselectAll = () => {
        gridRef.current.deselectAll();
    }

    const getData = async (props1?: GridProps) => {
        try {
            setLoading(true)
            var value = await reactDataGridManager.getPageData(props1);
            if (value.filter[GridManagerConst.columnId] !== reactDataGridManager.filterOptions[GridManagerConst.columnId] && !filterOptions) {
                reactDataGridManager.setFilter(value.filter)
                setFilterOptions(value.filter)
                setColumns(createColumns(value.filter))
            }
            gridProps.totalRecords = value.dataGrid.count

            setDataSource(Promise.resolve(value.dataGrid))
            setLoading(false)
        } catch (error) {
            throw error
        }
    }

    useEffect(() => {
        dataSource(gridProps)

    }, []);

    const createColumns = (filterOption: any) => {
        return props.columns.map((column: any) => {

            if (column.isFilter && filterOption) {
                return createFilterDataColumn(filterOption, column)
            }
            if (column.isExternalFilter) {
                return externalFilterDataColumn(column.filterData, column)
            }
            if (column.type === 'number') {
                return numberFilterDataColumn(column.filterData, column)
            }
            return column
        })
    }

    props.refreshDataGrid((resetFilter?: boolean) => {
        if (resetFilter) {
            gridProps.filterValue = null
        }
        deselectAll();
        dataSource(gridProps)
    }
    )

    return (
        <div>
            {
                <ReactDataGrid
                    licenseKey={props.licence}
                    onReady={setGridRef}
                    rowHeight={50}

                    // Grid
                    idProperty={props.idProperty}
                    columns={gridColumns}
                    emptyText={props.emptyText}
                    className={props.className}
                    dataSource={dataSource1}
                    style={props.style}
                    loading={loading}
                    showColumnMenuTool={false}
                    showZebraRows={false}

                    // Pagination
                    pagination={props.pagination}
                    defaultLimit={reactDataGridManager.pageSize}
                    onLimitChange={(limit) => setGridLimit(limit)}
                    onSkipChange={(skip) => setGridSkip(skip)}

                    // Sorting
                    allowUnsort={false}
                    sortInfo={gridProps.sortInfo}
                    onSortInfoChange={(sortInfo) => setGridSortInfo(sortInfo, gridProps)}

                    // Filtering
                    filterTypes={props.filterTypes}
                    enableFiltering={props.enableFiltering && filterOptions}
                    defaultFilterValue={props.defaultFilterValue}
                    onFilterValueChange={(filter) => setGridFilterValue(filter)}
                    onColumnFilterValueChange={(value) => {
                        if (props.onFilterChange) { props.onFilterChange(value) }
                    }}
                    // selection
                    checkboxColumn={props.checkboxColumn}
                    cellSelection={props.cellSelection}
                    checkboxOnlyRowSelect={true}
                    selected={props.selected}
                    onSelectionChange={(config: TypeOnSelectionChangeArg) => props.onSelectionChange(config, gridProps.totalRecords)}
                    rowClassName={props.rowClassName} />
            }
        </div>
    );
};

export default ComXReactDataGrid