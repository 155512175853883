import { injectable } from 'inversify';
import { Data, List, Model, Utils } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import Axios, { AxiosPromise } from 'axios';
import CampaignMessageCriteria from './../Models/CampaignMessage/Query/CampaignMessageCriteria';
import ApiResult from '../Models/ApiResult';
import { ResponseResolver } from '../common/utils';
import CloneClientCampaignMessageCommand from '../Models/CampaignMessage/Commands/CloneClientCampaignMessageCommand';
import { UpdateCampaignMessageNameCommand } from '../Models/CampaignMessage/Commands/UpdateCampaignNameCommand';
import CommandResult from '../Models/InvitedUsers/Commands/CommandResult';
import CampaignMessageDeletionCommand from '../Models/CampaignMessage/Commands/CampaignMessageDeletionCommand';
import UpdateCampaignMessageStatusCommand from '../Models/CampaignMessage/Commands/UpdateCampaignMessageStatusCommand';
import SaveSignatureCommand from '../Models/CampaignMessage/Commands/SaveSignatureCommand';
import CampaignMessageLookup from '../Models/CampaignMessage/CampaignMessageLookup';
import SaveCommentCommand from '../Models/CampaignMessage/Commands/SaveCommentCommand';
import CampaignMessageCommentLookup from '../Models/CampaignMessage/CampaignMessageCommentLookup';
import DeleteCommentCommand from '../Models/CampaignMessage/Commands/DeleteCommentCommand';
import TMLookup from '../Models/CampaignMessage/TMLookup';
import SaveEmailCommand from '../Models/CampaignMessage/Commands/SaveEmailCommand';
import ClientCampaignMessageLookup from '../Models/CampaignMessage/ClientCampaignMessageLookup';
import { UpdateFollowUpTemplateNameCommand } from '../Models/CampaignMessage/Commands/UpdateFollowUpTemplateNameCommand';
import FollowUpTemplateCriteria from '../Models/CampaignMessage/Query/FollowUpTemplateCriteria';
import FollowUpTemplateLookup from '../Models/CampaignMessage/FollowUpTemplateLookup';
import SaveFollowUpCommentCommand from '../Models/CampaignMessage/Commands/SaveFollowUpCommentCommand';
import ClientFollowUpTemplateLookup from '../Models/CampaignMessage/ClientFollowUpTemplateLookup';
import DeleteFollowUpCommentCommand from '../Models/CampaignMessage/Commands/DeleteFollowUpCommentCommand';
import SaveFollowUpTemplateCommand from '../Models/CampaignMessage/Commands/SaveFollowUpTemplateCommand';
import CloneFollowUpTemplateCommand from '../Models/CampaignMessage/Commands/CloneFollowUpTemplateCommand';
import DeleteFollowUpTemplateCommand from '../Models/CampaignMessage/Commands/DeleteFollowUpTemplateCommand';
import FollowUpTemplateCommentLookup from '../Models/CampaignMessage/FollowUpTemplateCommentLookup';
import ActionListClientFollowUpTemplateLookup from 'Models/CampaignMessage/ActionListClientFollowUpTemplateLookup';
import SaveQuestionnaireAnswersCommand from 'Models/IdealCustomerProfiles/Commands/SaveQuestionnaireAnswersCommand';

export interface ICampaignMessagesApiClient {
    getCampaignMessagesPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<CampaignMessageCriteria>>): AxiosPromise<any>,
    updateCampaignName(clientCampaignMessage: UpdateCampaignMessageNameCommand): AxiosPromise<Model.PlainObject<CommandResult>>,
    updateTemplateName(clientCampaignMessage: UpdateFollowUpTemplateNameCommand): AxiosPromise<Model.PlainObject<CommandResult>>,
    cloneCampaignMessageOrEmailSignature(clientCampaignMessage: CloneClientCampaignMessageCommand): AxiosPromise<any>,
    cloneFollowUpTemplate(clientFollowUpTemplate: CloneFollowUpTemplateCommand): AxiosPromise<any>,
    deleteClientCampaignMessage(clientCampaignMessage: CampaignMessageDeletionCommand): AxiosPromise<CommandResult>,
    deleteClientFollowUpTemplate(clientFollowUpTemplate: DeleteFollowUpTemplateCommand): AxiosPromise<CommandResult>,
    undeleteClientCampaignMessage(clientCampaignMessage: CampaignMessageDeletionCommand): AxiosPromise<CommandResult>,
    undeleteClientFollowUpTemplate(clientFollowUpTemplate: DeleteFollowUpTemplateCommand): AxiosPromise<CommandResult>,
    updateClientCampaignMessageStatus(clientCampaignMessage: UpdateCampaignMessageStatusCommand): AxiosPromise<CommandResult>,
    saveEmailSignature(saveSignature: SaveSignatureCommand): AxiosPromise<CommandResult>
    getEmailSignature(clientCampaignMessageId: number): AxiosPromise<Model.PlainObject<CampaignMessageLookup>>
    getSpecificCampaignMessage(clientCampaignMessageId: number): AxiosPromise<Model.PlainObject<ClientCampaignMessageLookup>>
    getSpecificFollowUpTemplate(clientFollowUpTemplateId: number): AxiosPromise<Model.PlainObject<ClientFollowUpTemplateLookup>>
    saveComment(saveSignature: SaveCommentCommand): AxiosPromise<CommandResult>
    saveFollowUpComment(saveSignature: SaveFollowUpCommentCommand): AxiosPromise<CommandResult>
    getComments(clientCampaignMessageId: number): AxiosPromise<Array<Model.PlainObject<CampaignMessageCommentLookup>>>;
    getFollowUpTemplateComments(clientFollowUpTemplateId: number): AxiosPromise<Array<Model.PlainObject<FollowUpTemplateCommentLookup>>>;
    deleteComment(deleteCommentCommand: DeleteCommentCommand): AxiosPromise<CommandResult>
    deleteFollowUpComment(deleteFollowUpCommentCommand: DeleteFollowUpCommentCommand): AxiosPromise<CommandResult>
    allowCommentDeletion(campaignMessageCommentId: number): AxiosPromise<boolean>;
    clientHasAnyCampaignMessages(clientId: number): AxiosPromise<boolean>;
    getClientTargetMarkets(clientId: number): AxiosPromise<Array<Model.PlainObject<TMLookup>>>
    saveCampaignMessagesCommand(saveCommand: SaveEmailCommand[]): AxiosPromise<CommandResult>;
    saveFollowUpTemplatesCommand(saveFollowUpTemplatesCommand: SaveFollowUpTemplateCommand[]): AxiosPromise<CommandResult>;
    getCampaignMessages(clientCampaignMessageId: number): AxiosPromise<Array<Model.PlainObject<CampaignMessageLookup>>>;
    getFollowUpTemplatesPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<CampaignMessageCriteria>>) : AxiosPromise<any>;
    getActionListClientFollowUpTemplatesPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<FollowUpTemplateCriteria>>) : AxiosPromise<any>;
    clientHasAnyFollowUpTemplates(clientId: number): AxiosPromise<boolean>;
    getFollowUpTemplates(clientFollowUpTemplateId: number): AxiosPromise<Array<Model.PlainObject<FollowUpTemplateLookup>>>;
    getActionListClientFollowUpTemplate(clientFollowUpTemplateId: number): AxiosPromise<Array<Model.PlainObject<ActionListClientFollowUpTemplateLookup>>>;
}

@injectable()
export default class CampaignMessagesApiCampaignMessage implements ICampaignMessagesApiClient {

    constructor(public config = AppService.get(Types.Config)) {
    }

    public getCampaignMessagesPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<CampaignMessageCriteria>>): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/CampaignMessages?${Utils.getQueryString(request)}`);
    }
    
    public getFollowUpTemplatesPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<FollowUpTemplateCriteria>>): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/CampaignMessages/GetClientFollowUpTemplates?${Utils.getQueryString(request)}`);
    }

    public getActionListClientFollowUpTemplatesPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<FollowUpTemplateCriteria>>): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/CampaignMessages/GetActionListClientFollowUpTemplatesPaged?${Utils.getQueryString(request)}`);
    }

    public cloneCampaignMessageOrEmailSignature(clientCampaignMessage: CloneClientCampaignMessageCommand): AxiosPromise<any> {
        return Axios.post(`${this.config.apiPath}/CampaignMessages/Clone`, clientCampaignMessage.toJSObject());
    }

    public cloneFollowUpTemplate(clientFollowUpTemplate: CloneFollowUpTemplateCommand): AxiosPromise<any> {
        return Axios.post(`${this.config.apiPath}/CampaignMessages/CloneClientFollowUpTemplate`, clientFollowUpTemplate.toJSObject());
    }

    public updateCampaignName(clientCampaignMessage: UpdateCampaignMessageNameCommand): AxiosPromise<Model.PlainObject<CommandResult>> {
        return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${this.config.apiPath}/CampaignMessages/UpdateClientCampaignName`, clientCampaignMessage.toJSObject()));
    }
    
    public updateTemplateName(clientTemplateName: UpdateFollowUpTemplateNameCommand): AxiosPromise<Model.PlainObject<CommandResult>> {
        return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${this.config.apiPath}/CampaignMessages/UpdateFollowUpTemplateName`, clientTemplateName.toJSObject()));
    }

    public deleteClientCampaignMessage(clientCampaignMessage: CampaignMessageDeletionCommand): AxiosPromise<CommandResult> {
        return ResponseResolver<CommandResult>(Axios.post(`${this.config.apiPath}/CampaignMessages/DeleteClientCampaignMessage`, clientCampaignMessage.toJSObject()));
    }

    public deleteClientFollowUpTemplate(clientFollowUpTemplate: DeleteFollowUpTemplateCommand): AxiosPromise<CommandResult> {
        return ResponseResolver<CommandResult>(Axios.post(`${this.config.apiPath}/CampaignMessages/DeleteFollowUpTemplate`, clientFollowUpTemplate.toJSObject()));
    }

    public undeleteClientCampaignMessage(clientCampaignMessage: CampaignMessageDeletionCommand): AxiosPromise<CommandResult> {
        return ResponseResolver<CommandResult>(Axios.post(`${this.config.apiPath}/CampaignMessages/UndeleteClientCampaignMessage`, clientCampaignMessage.toJSObject()));
    }

    public undeleteClientFollowUpTemplate(clientFollowUpTemplate: DeleteFollowUpTemplateCommand): AxiosPromise<CommandResult> {
        return ResponseResolver<CommandResult>(Axios.post(`${this.config.apiPath}/CampaignMessages/UndeleteFollowUpTemplate`, clientFollowUpTemplate.toJSObject()));
    }

    public updateClientCampaignMessageStatus(clientCampaignMessage: UpdateCampaignMessageStatusCommand): AxiosPromise<CommandResult> {
        return ResponseResolver<CommandResult>(Axios.post(`${this.config.apiPath}/CampaignMessages/UpdateClientCampaignMessageStatus`, clientCampaignMessage.toJSObject()));
    }

    public clientHasAnyCampaignMessages(clientId: number): AxiosPromise<boolean> {
        return ResponseResolver<boolean>(Axios.get(`${this.config.apiPath}/CampaignMessages/ClientHasAnyCampaignMessages?clientId=${clientId}`));
    }

    public clientHasAnyFollowUpTemplates(clientId: number): AxiosPromise<boolean> {
        return ResponseResolver<boolean>(Axios.get(`${this.config.apiPath}/CampaignMessages/ClientHasAnyFollowUpTemplates?clientId=${clientId}`));
    }

    public saveEmailSignature(saveSignature: SaveSignatureCommand): AxiosPromise<CommandResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${apiPath}/CampaignMessages/SaveEmailSignature`, saveSignature.toJSObject()));
    }

    public getSpecificCampaignMessage(clientCampaignMessageId: number): AxiosPromise<Model.PlainObject<ClientCampaignMessageLookup>> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<ClientCampaignMessageLookup>(Axios.get<ApiResult>(`${apiPath}/CampaignMessages/GetClientCampaignMessage/${clientCampaignMessageId}`));
    }

    public getSpecificFollowUpTemplate(clientFollowUpTemplateId: number): AxiosPromise<Model.PlainObject<ClientFollowUpTemplateLookup>> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<ClientFollowUpTemplateLookup>(Axios.get<ApiResult>(`${apiPath}/CampaignMessages/GetClientFollowUpTemplate/${clientFollowUpTemplateId}`));
    }

    public getCampaignMessages(clientCampaignMessageId: number): AxiosPromise<Array<Model.PlainObject<CampaignMessageLookup>>> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<Array<CampaignMessageLookup>>(Axios.get<ApiResult>(`${apiPath}/CampaignMessages/GetCampaignMessages/${clientCampaignMessageId}`));
    }

    public getFollowUpTemplates(clientFollowUpTemplateId: number): AxiosPromise<Array<Model.PlainObject<FollowUpTemplateLookup>>> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<Array<FollowUpTemplateLookup>>(Axios.get<ApiResult>(`${apiPath}/CampaignMessages/GetFollowUpTemplates/${clientFollowUpTemplateId}`));
    }

    public getEmailSignature(clientCampaignMessageId: number): AxiosPromise<Model.PlainObject<CampaignMessageLookup>> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<CampaignMessageLookup>(Axios.get<ApiResult>(`${apiPath}/CampaignMessages/GetEmailSignature/${clientCampaignMessageId}`));
    }

    public saveComment(saveCommentCommand: SaveCommentCommand): AxiosPromise<CommandResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${apiPath}/CampaignMessages/SaveComment`, saveCommentCommand.toJSObject()));
    }

    public saveFollowUpComment(saveFollowUpCommentCommand: SaveFollowUpCommentCommand): AxiosPromise<CommandResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${apiPath}/CampaignMessages/SaveFollowUpComment`, saveFollowUpCommentCommand.toJSObject()));
    }

    public deleteComment(deleteCommentCommand: DeleteCommentCommand): AxiosPromise<CommandResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${apiPath}/CampaignMessages/DeleteComment`, deleteCommentCommand.toJSObject()));
    }
    
    public deleteFollowUpComment(deleteFollowUpCommentCommand: DeleteFollowUpCommentCommand): AxiosPromise<CommandResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${apiPath}/CampaignMessages/DeleteFollowUpComment`, deleteFollowUpCommentCommand.toJSObject()));
    }


    public getComments(clientCampaignMessageId: number): AxiosPromise<Array<Model.PlainObject<CampaignMessageCommentLookup>>> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<Array<CampaignMessageCommentLookup>>(Axios.get(`${apiPath}/CampaignMessages/GetComments/${clientCampaignMessageId}`));
    }

    public getFollowUpTemplateComments(clientFollowUpTemplateId: number): AxiosPromise<Array<Model.PlainObject<FollowUpTemplateCommentLookup>>> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<Array<FollowUpTemplateCommentLookup>>(Axios.get(`${apiPath}/CampaignMessages/GetFollowUpTemplateComments/${clientFollowUpTemplateId}`));
    }

    public getActionListClientFollowUpTemplate(clientFollowUpTemplateId: number): AxiosPromise<Array<Model.PlainObject<ActionListClientFollowUpTemplateLookup>>> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<Array<ActionListClientFollowUpTemplateLookup>>(Axios.get<ApiResult>(`${apiPath}/CampaignMessages/GetActionListClientFollowUpTemplate/${clientFollowUpTemplateId}`));
    }

    public allowCommentDeletion(campaignMessageCommentId: number): AxiosPromise<boolean> {
        return ResponseResolver<boolean>(Axios.get(`${this.config.apiPath}/CampaignMessages/AllowCommentDeletion/${campaignMessageCommentId}`));
    }
    
    public getClientTargetMarkets(clientId: number): AxiosPromise<Array<Model.PlainObject<TMLookup>>> {
        const apiPath = AppService.get(Types.Config).apiPath
        return ResponseResolver<Array<TMLookup>>(Axios.get(`${apiPath}/CampaignMessages/GetAllClientTargetMarkets/${clientId}`));
    }

    public saveCampaignMessagesCommand(saveCommand: List<SaveEmailCommand>): AxiosPromise<CommandResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<CommandResult>(Axios.post(`${apiPath}/CampaignMessages/SaveCampaignMessage`, saveCommand.toJSArray()));
    }

    public saveFollowUpTemplatesCommand(saveFollowUpTemplatesCommand: List<SaveFollowUpTemplateCommand>): AxiosPromise<CommandResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<CommandResult>(Axios.post(`${apiPath}/CampaignMessages/SaveFollowUpTemplate`, saveFollowUpTemplatesCommand.toJSArray()));
    }
}