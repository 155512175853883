import { PageManager, PageRequest, PageResult, IPageManagerOptions } from '@singularsystems/neo-core/dist/Data';
import { ValueObject, Model, ITaskRunner, NeoModel } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { ModelBase } from '@singularsystems/neo-core';
import { observable, makeObservable } from 'mobx';
import { Nullable } from '@singularsystems/neo-core/dist/Utils';

/// Rename Grid to manager
/// Rename data source

const selectMetadataKey = Symbol("select");

export const selectedItem = (formatString: string[]) => {
    return Reflect.metadata(selectMetadataKey, formatString);
}


export const getSelectedItem = (target: any, propertyKey: string) => {
    return Reflect.getMetadata(selectMetadataKey, target, propertyKey);
}

/**
 * 
 * 
 * @export
 * @interface IBulkActionGrid
 * @extends {ModelBase}
 */
export interface IBulkActionGrid extends ModelBase {
    IsSelected: boolean;
}

/**
 * Extends the Neo PageManger to allow for 
 * 
 * @export
 * @class ComxBulkActionGrid
 * @extends {PageManager<TCriteria, TModel>}
 * @template TCriteria 
 * @template TModel 
 */

export class BulkActionPageManager<TCriteria extends ValueObject, TModel extends IBulkActionGrid>
    extends PageManager<TCriteria, TModel>
{
    /**
     * 
     * 
     * @private
     * 
     * @memberOf ComxBulkActionGrid
     */
    private bulkActionDataSource: (criteria: Model.PartialPlainNonTrackedObject<PageRequest<TCriteria>>) => AxiosPromise<PageResult<Model.PlainObject<TModel>>>

    constructor(
        criteria: TCriteria,
        modelType: new () => TModel,
        dataSource: (criteria: Model.PartialPlainNonTrackedObject<PageRequest<TCriteria>>) => AxiosPromise<PageResult<Model.PlainObject<TModel>>>,
        options?: IPageManagerOptions<TCriteria, TModel>) {
        
        super(criteria, modelType, (x) => this.bulkActionDataSourceIntercept(x), options)
        
        this.bulkActionDataSource = dataSource
        this.bindableProp = new SelectedStateManager()

    }
    private selectedColumnName = "IsSelected"

    @observable
    public bindableProp: SelectedStateManager = new SelectedStateManager();
    /**
     * Refreshes the data list
     * 
     * 
     * @memberOf ComxBulkActionGrid
     */
    public refreshDataPage = async (refreshSelectList?: boolean, getCount?: boolean, taskRunner?: ITaskRunner) => {
        if (refreshSelectList) {
            this.bindableProp.SelectedItemsList = []
            this.bindableProp.AllItemsSelected = false
            this.bindableProp.ItemsSelected = false
        }
        await super.refreshData(getCount, taskRunner)
    }

    /**
     * Gets the current list of selected Items
     * 
     * 
     * @memberOf ComxBulkActionGrid
     */
    public GetSelectItems = () => {
        if (this.bindableProp.SelectedItemsList.length > 0) {
            return this.bindableProp.SelectedItemsList;
        }
        else {
            return this.getItems().filter(x => x.IsSelected);
        }
    }

    public AreItemsSelected = (event: any) => {
        this.setSelectedItems()
        const onScreenItems =  event.filter((x: any) => x.IsSelected).length
        if (this.bindableProp.SelectedItemsList.length > 0) {
            this.bindableProp.ItemsSelected = true
            if(onScreenItems === 0){
                this.bindableProp.AllItemsSelected = false
            }
            if (event.length === onScreenItems) {
                this.bindableProp.AllItemsSelected = true
            }
            else {
                this.bindableProp.AllItemsSelected = null
            }
        }
        else {
            this.bindableProp.ItemsSelected = false
            this.bindableProp.AllItemsSelected = false 
        }
    }


    public selectAll = (value: any) => {   
        this.bindableProp.AllItemsSelected = value
        this.bindableProp.ItemsSelected = this.bindableProp.AllItemsSelected ? true : false;
        this.getItems().forEach(element => { element.IsSelected = this.bindableProp.AllItemsSelected ? true : false; });
        this.setSelectedItems()
    }

    /**
     * Intercepts the data source functions 
     * made to the database
     * @private
     * 
     * @memberOf ComxBulkActionGrid
     */
    private bulkActionDataSourceIntercept = async (criteria: Model.PartialPlainNonTrackedObject<PageRequest<TCriteria>>) => {
        const responseModel = await this.bulkActionDataSource(criteria)
        const allSelected = this.getItems().filter(x => x.IsSelected).length === this.getItems().length
        allSelected ? this.bindableProp.AllItemsSelected = null : this.bindableProp.AllItemsSelected = this.bindableProp.AllItemsSelected                

        var count = 0
        if (this.bindableProp.SelectedItemsList.length > 0 && this.bindableProp.AllItemsSelected && !allSelected) {
            const columnsNames = getSelectedItem(this.bindableProp.SelectedItemsList[0], this.selectedColumnName)
            responseModel.data.entityList.forEach((item: any) => {
                let selectValue = this.bindableProp.SelectedItemsList.map(s => { return this.getItemsByName(columnsNames, s) })
                if (selectValue.filter(sel => sel === this.getItemsByName(columnsNames, item)).length > 0) {
                    item.IsSelected = false
                }
                return item
            })

        }
        else if (this.bindableProp.SelectedItemsList.length > 0 && !this.bindableProp.AllItemsSelected) {
            const columnsNames = getSelectedItem(this.bindableProp.SelectedItemsList[0], this.selectedColumnName)
            responseModel.data.entityList.forEach((item: any) => {
                let selectValue = this.bindableProp.SelectedItemsList.map(s => { return this.getItemsByName(columnsNames, s) })
                if (selectValue.filter(sel => sel === this.getItemsByName(columnsNames, item)).length > 0) {
                    item.IsSelected = true
                    count++
                }
                else {
                    item.IsSelected = false
                }
                return item
            })
        }

        const entireList = responseModel.data.entityList.length

        if (count === 0) {
            this.bindableProp.AllItemsSelected = false
        }
        else if (count < entireList) {
            this.bindableProp.AllItemsSelected = null
        }
        else {
            this.bindableProp.AllItemsSelected = true
        }
        return responseModel
    }

    /**
     * Manages the state of the selected Items list
     * 
     * @private
     * 
     * @memberOf ComxBulkActionGrid
     */
    private setSelectedItems = () => {
        let selectItems = this.getItems().filter(x => x.IsSelected)
        let notSelectedItems = this.getItems().filter(x => !x.IsSelected)
        if (selectItems.length > 0 && this.bindableProp.SelectedItemsList.length === 0) {
            this.bindableProp.SelectedItemsList = selectItems
        }
        else if (selectItems.length > 0 && this.bindableProp.SelectedItemsList.length > 0) {
            // Manage Selection and Deselection of Items
            this.addItemsToList(selectItems, notSelectedItems)
        }
        else if (selectItems.length === 0 && this.bindableProp.SelectedItemsList.length > 0) {
            // Deselect items that were select previous
            this.noneSelected(notSelectedItems)
            if(this.bindableProp.SelectedItemsList.length > 0){
                this.bindableProp.ItemsSelected = true
            }
        }
    }

    /**
     * If no items are select on the current page
     * but still exist in the selected list
     * this method will remove them
     * @private
     * 
     * @memberOf ComxBulkActionGrid
     */
    private noneSelected = (notSelectedItems: TModel[]) => {
        const columnsNames = getSelectedItem(this.bindableProp.SelectedItemsList[0], this.selectedColumnName)
        const notSelect: number[] = notSelectedItems.map(sel => { return this.getItemsByName(columnsNames, sel) })

        // remove duplicates
        let finalList = this.bindableProp.SelectedItemsList.filter(sel => notSelect.indexOf(this.getItemsByName(columnsNames, sel)) < 0)
        this.bindableProp.SelectedItemsList = finalList
    }

    /**
     * Manages selection and deselection of items 
     * in the selected list by remove those removed
     * and adding those added
     * @private
     * 
     * @memberOf ComxBulkActionGrid
     */
    private addItemsToList = (selectItems: TModel[], notSelectedItems: TModel[]) => {

        let copySelectedList = this.bindableProp.SelectedItemsList

        // Find Unique Ids
        const columnsNames = getSelectedItem(this.bindableProp.SelectedItemsList[0], this.selectedColumnName)
        const IdList: number[] = copySelectedList.map(sel => { return this.getItemsByName(columnsNames, sel) })
        const notSelect: number[] = notSelectedItems.map(sel => { return this.getItemsByName(columnsNames, sel) })

        // remove duplicates
        const uniqueList = selectItems.filter(sel => IdList.indexOf(this.getItemsByName(columnsNames, sel)) < 0)
        let newList = [...uniqueList, ...copySelectedList]
        let finalList = newList.filter(sel => notSelect.indexOf(this.getItemsByName(columnsNames, sel)) < 0)
        this.bindableProp.SelectedItemsList = finalList
    }

    private getItemsByName = (columnsNames: string[], object: TModel) => {
        const columnValue: string = columnsNames.filter(x => { if (object[x] !== 0 || object[x] !== null || object[x] !== undefined) return object[x] })[0]
        return object[columnValue]
    }
}


@NeoModel
class SelectedStateManager extends ValueObject {
    public AllItemsSelected: Nullable<boolean> = false; // tristate checkbox
    public SelectedItemsList: Array<any> = [];  // Array of items selected
    public ItemsSelected: boolean = false; // Are any items selected?
}