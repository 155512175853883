import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import { isURL } from '../../common/utils';
import { triggerHotjarEvent } from '../../Components/Hotjar';
import OnboardingSideMenu from '../../Components/OnboardingSideMenu';
import CampaignMessageCommentLookup from '../../Models/CampaignMessage/CampaignMessageCommentLookup';
import CampaignMessageLookup from '../../Models/CampaignMessage/CampaignMessageLookup';
import DeleteCommentCommand from '../../Models/CampaignMessage/Commands/DeleteCommentCommand';
import SaveCommentCommand from '../../Models/CampaignMessage/Commands/SaveCommentCommand';
import SaveEmailCommand from '../../Models/CampaignMessage/Commands/SaveEmailCommand';
import TMLookup from '../../Models/CampaignMessage/TMLookup';
import { AppService, Types } from '../../Services/AppService';
import { OnboardingSteps } from 'Models/Enums/ApplicationEnums';

@NeoModel
export default class CampaignMessageVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private campaignMessagesApiClient = AppService.get(Types.ApiClients.CampaignMessagesApiClient),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService)) {

        super(taskRunner);
    }

    //Properties
    public clientId: number = 0;

    public campaignName: string = "";

    public landingPageUrl: string = "";

    public isComXUser: boolean = false;

    public clientCampaignMessageId: number = 0;

    public commentDeleteCampaignMessageId: number = 0;

    public campaignMessages: List<CampaignMessageLookup> | null = new List(CampaignMessageLookup);

    public allTargetMarkets: List<TMLookup> = new List(TMLookup);

    public selectedTargetMarkets: List<TMLookup> | null = new List(TMLookup);

    public PreviousTargetMarkets: any[] = [];

    public targetMarketOptions: any[] = [];

    public saveMessagesCommand: List<SaveEmailCommand> = new List(SaveEmailCommand);

    public showInvalidDataModal: boolean = false

    public invalidDataMessage: string = ""

    public currentComment: string = "";

    public showDeleteModal: boolean = false

    public showDeleteDeniedModal: boolean = false

    public deleteCommentCommand: DeleteCommentCommand = new DeleteCommentCommand();

    public isSaving: boolean = false;

    public messageStatusId: number | undefined = 0;

    public campaignMessageStatuses = this.appDataCache.campaignMessageStatuses.get().data.sortBy("messageStatus");
    public draftStatusId = this.campaignMessageStatuses.find(cm => cm.uniqueTableKey === "CampaignMessageStatus1")?.campaignMessageStatusId
    public activeStatusId = this.campaignMessageStatuses.find(cm => cm.uniqueTableKey === "CampaignMessageStatus2")?.campaignMessageStatusId
    public clientReviewStatusId = this.campaignMessageStatuses.find(cm => cm.uniqueTableKey === "CampaignMessageStatus3")?.campaignMessageStatusId

    public showPublishModal: boolean = false;
    public showClientReviewModal: boolean = false;

    public isScreenDirty: boolean = false;
    public isCommentDirty: boolean = false;
    public onboardingProps: any

    public validationFirstCheckDone: boolean = false;
    public draftValidationFirstCheckDone: boolean = false;
    public firstMessageEmpty: boolean = false;
    public invalidLandingPageUrl: boolean = false;
    public validationSubject: boolean = false;

    public isExpandedId: number = 0;

    public async initialise() {
        if (this.clientCampaignMessageId === 0) {
            await this.newCampaignMessagesSetup()
        }
        else {
            await this.getCurrentCampaignMessages();
        }
    }

    public setExpandedId(campaignMessageId: number) {
        this.isExpandedId = campaignMessageId
    }

    public setTargetMarketList() {
        this.targetMarketOptions = []

        this.allTargetMarkets.forEach(tm => {
            if (tm.targetMarketName !== "") {
                const targetMarketItem = {
                    value: tm.targetMarketId.toString(),
                    label: tm.targetMarketName,
                }

                // Check for already selected options Dont push to dropdown
                if (this.selectedTargetMarkets === null
                    || this.selectedTargetMarkets === undefined
                    || !this.selectedTargetMarkets.find(f => f.targetMarketId.toString() === targetMarketItem.value)) {
                    this.targetMarketOptions.push(targetMarketItem);
                }
            }
        });
    }

    public setupNewCampaignMessages() {
        let emptySubject = new CampaignMessageLookup();
        emptySubject.isSubject = true;
        emptySubject.ordinal = 0;
        this.campaignMessages!.push(emptySubject);

        for (let i = 1; i <= 4; i++) {
            let emptyMessage = new CampaignMessageLookup();
            emptyMessage.ordinal = i;

            this.campaignMessages!.push(emptyMessage);
        }
    }

    public async getCurrentCampaignMessages() {
        let clientCampaignMessages = await this.taskRunner.waitFor(this.campaignMessagesApiClient.getSpecificCampaignMessage(this.clientCampaignMessageId))

        this.campaignName = clientCampaignMessages.data.campaignName
        this.landingPageUrl = clientCampaignMessages.data.landingPageUrl

        var tempList: any[] = [];

        this.setCampaignMessages(clientCampaignMessages.data.campaignMessages)

        clientCampaignMessages.data.selectedTargetMarkets!.forEach(targetMarket => {
            var newTMLookup = new TMLookup();
            newTMLookup.targetMarketId = targetMarket.targetMarketId;
            newTMLookup.targetMarketName = targetMarket.targetMarketName;

            this.selectedTargetMarkets!.push(newTMLookup);
        });

        await this.setupNewTargetMarketList();
    }

    public async setCampaignMessages(campaignMessages: List<CampaignMessageLookup> | null) {
        this.campaignMessages = new List(CampaignMessageLookup)

        if (campaignMessages !== null) {
            campaignMessages.forEach(async cm => {
                let newCampaignMessage = new CampaignMessageLookup();
                newCampaignMessage.campaignMessageId = cm.campaignMessageId;
                newCampaignMessage.campaignName = cm.campaignName;
                newCampaignMessage.clientCampaignMessageId = cm.clientCampaignMessageId;
                newCampaignMessage.isSubject = cm.isSubject;
                newCampaignMessage.ordinal = cm.ordinal
                newCampaignMessage.draftText = cm.draftText;
                newCampaignMessage.messageText = cm.messageText;
                newCampaignMessage.draftModifiedBy = cm.draftModifiedBy
                newCampaignMessage.draftModifiedOn = cm.draftModifiedOn
                newCampaignMessage.messageModifiedOn = cm.messageModifiedOn
                newCampaignMessage.messageModifiedBy = cm.messageModifiedBy
                newCampaignMessage.comments = new List(CampaignMessageCommentLookup)

                cm.comments!.forEach(cc => {
                    let newComment = new CampaignMessageCommentLookup();

                    newComment.campaignMessageCommentId = cc.campaignMessageCommentId;
                    newComment.comment = cc.comment;
                    newComment.commenterName = cc.commenterName;
                    newComment.createdOn = cc.createdOn;
                    newComment.isInternalUser = cc.isInternalUser;
                    newComment.isCurrentUser = cc.isCurrentUser;

                    newCampaignMessage.comments!.push(newComment)
                })

                this.campaignMessages!.push(newCampaignMessage);
            });
        }
    }

    public async setupNewTargetMarketList() {
        let response = await this.taskRunner.waitFor(
            this.campaignMessagesApiClient.getClientTargetMarkets(this.clientId)
        );

        if (response) {
            this.allTargetMarkets.set(response.data);

            this.setTargetMarketList();
        }
    }

    public async newCampaignMessagesSetup() {
        this.setupNewCampaignMessages();
        await this.setupNewTargetMarketList();
    }

    public validationBeforeSave(isPublish: boolean = false, isClientReview: boolean = false): boolean {
        // If it is Save as Draft
        if ((isClientReview === false && isPublish === false && this.isComXUser) || (isClientReview === true && isPublish === false && !this.isComXUser)) {
            // And an Internal user - external user has no validation
            this.runDraftValidationCheck();
        } else {
            this.runAllValidationChecks();
        }
        if (this.showInvalidDataModal) return false;
        else return true;
    }

    public async SaveCampaignMessages(navigate: () => void, isPublish: boolean = false, isClientReview: boolean = false) {

        if (this.showInvalidDataModal) { return }
        this.saveMessagesCommand = new List(SaveEmailCommand);
        this.populateSaveCommand(isPublish, isClientReview);

        this.taskRunner.run(async () => {
            const saveResponse = await this.campaignMessagesApiClient.saveCampaignMessagesCommand(this.saveMessagesCommand);

            if (saveResponse.data.success) {
                this.isSaving = true;
                if (isClientReview && this.isComXUser) {
                    this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.ClientReviewMessageSaved);
                } else if (isPublish) {
                    if (this.authenticationService.globalProps.isOnboarding) {
                        this.authenticationService.globalProps.onboardingSteps.find(step => step.stepNumber === OnboardingSteps.CampaignMessages)!.isComplete = true
                    }

                    triggerHotjarEvent(textConstants.Events.clientCampaignMessagePublish)
                    this.showPublishModal = false
                    this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.PublishedMessageSaved);
                }
                else {
                    triggerHotjarEvent(textConstants.Events.clientCampaignMessageDraft)

                    this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.CampaignMessageSaved);
                }

                this.isScreenDirty = false;
                navigate();

                // Update onboarding
                if (this.authenticationService.globalProps.isOnboarding && !this.isComXUser) {
                    this.showPublishModal = false
                    var onboardingSideMenu = new OnboardingSideMenu(this.onboardingProps)
                    onboardingSideMenu.checkCanGoNext()
                }
            }
        });
    }

    private populateSaveCommand(isPublish: boolean, isClientReview: boolean) {

        this.campaignMessages!.forEach(c => {
            let saveCommand: SaveEmailCommand = new SaveEmailCommand();
            saveCommand.ordinal = c.ordinal;
            saveCommand.campaignName = this.campaignName;
            saveCommand.landingPageUrl = this.landingPageUrl;
            saveCommand.draftText = c.draftText;
            saveCommand.messageText = (c.messageText === '' && (isPublish || isClientReview)) ? c.draftText : c.messageText;
            saveCommand.clientId = this.clientId;
            saveCommand.campaignMessageId = c.campaignMessageId;
            saveCommand.clientCampaignMessageId = this.clientCampaignMessageId;
            saveCommand.isPublish = isPublish;
            saveCommand.isClientReview = isClientReview;
            saveCommand.isSubject = c.isSubject;

            if (c.isSubject) {
                saveCommand.targetMarkets = this.selectedTargetMarkets;
            }

            this.saveMessagesCommand.push(saveCommand);
        })
    }

    public async SaveComment(campaignMessageId: number, currentComment: string) {
        this.currentComment = currentComment

        this.runAllValidationChecks(true);

        if (this.showInvalidDataModal) { return; }

        var newComment = new SaveCommentCommand();
        newComment.campaignMessageId = campaignMessageId;
        newComment.comment = currentComment;
        newComment.campaignName = this.campaignName
        newComment.isEmailSignature = false

        this.taskRunner.run(async () => {
            await this.campaignMessagesApiClient.saveComment(newComment);

            var response = await this.campaignMessagesApiClient.getCampaignMessages(this.clientCampaignMessageId)

            var result = response.data as List<CampaignMessageLookup>

            var updatedComments = result.find(f => f.campaignMessageId === campaignMessageId)!.comments as List<CampaignMessageCommentLookup>

            this.campaignMessages!.find(f => f.campaignMessageId === campaignMessageId)!.comments = updatedComments;
            this.campaignMessages!.find(f => f.campaignMessageId === campaignMessageId)!.currentComment = ""
            this.isCommentDirty = false
        });
    }

    public async AllowDeleteCheck(commentId: number, campaignMessageId: number) {
        let isAllowed = false;
        this.taskRunner.run(async () => {
            const response = await this.campaignMessagesApiClient.allowCommentDeletion(commentId)

            if (response) {
                isAllowed = response.data
            }

            if (isAllowed) {
                this.openDeleteModal(commentId)
                this.commentDeleteCampaignMessageId = campaignMessageId;
            }
            else {
                // Deny Add
                this.showDeleteDeniedModal = true
            }
        });

        return false;
    }

    public async DeleteComment() {
        this.taskRunner.run(async () => {
            let response = await this.campaignMessagesApiClient.deleteComment(this.deleteCommentCommand);

            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.CommentDelete);
            this.showDeleteModal = false

            var commentsResponse = await this.taskRunner.waitFor(this.campaignMessagesApiClient.getCampaignMessages(this.clientCampaignMessageId))

            var allData = commentsResponse.data as List<CampaignMessageLookup>

            var updatedComments = allData.find(f => f.campaignMessageId === this.commentDeleteCampaignMessageId)!.comments as List<CampaignMessageCommentLookup>

            this.campaignMessages!.find(f => f.campaignMessageId === this.commentDeleteCampaignMessageId)!.comments = updatedComments;

            this.commentDeleteCampaignMessageId = 0
            this.showDeleteModal = false;
        });
    }

    public runAllValidationChecks(isComment?: boolean) {
        this.validationFirstCheckDone = true
        this.showInvalidDataModal = false
        this.invalidDataMessage = ""

        if (isComment) {
            if (this.currentComment === "" || this.currentComment === null) {
                this.showInvalidDataModal = true
                this.invalidDataMessage = "Comment must be specified"
            }
        }
        else {
            if (this.campaignName === "" || this.campaignName === null) {
                this.showInvalidDataModal = true
                this.invalidDataMessage = "Campaign Name must be specified" + "\n"
            }

            if (this.selectedTargetMarkets === null || this.selectedTargetMarkets.length === 0) {
                this.showInvalidDataModal = true
                this.invalidDataMessage += "Must select at least one Target Market" + "\n"
            }

            if (this.landingPageUrl !== null && this.landingPageUrl !== "") {
                let isValid = isURL(this.landingPageUrl);

                if (!isValid) {
                    this.invalidLandingPageUrl = true;
                    this.showInvalidDataModal = true;
                    this.invalidDataMessage += "The provided landing page URL is not valid a valid URL" + "\n";
                }
            }

            let subject = this.campaignMessages!.find(f => f.isSubject);

            if (subject === undefined || subject === null || subject.draftText === "") {
                this.validationSubject = true
                this.showInvalidDataModal = true
                this.invalidDataMessage += "Must specify the subject message\n"
            }

            let campaignMessagesSpecified = this.campaignMessages!.filter(f => f.draftText !== null && f.draftText !== "" && !f.isSubject)!.length

            if (campaignMessagesSpecified === undefined || campaignMessagesSpecified === 0) {
                this.firstMessageEmpty = true
                this.showInvalidDataModal = true
                this.invalidDataMessage += "Must specify at least one campaign message"
            }
        }
    }


    public runDraftValidationCheck() {
        this.draftValidationFirstCheckDone = true
        this.showInvalidDataModal = false
        this.invalidDataMessage = ""

        if (this.campaignName === "" || this.campaignName === null) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = "Campaign Name must be specified" + "\n"
        }

        if (this.landingPageUrl !== null && this.landingPageUrl !== "") {
            let isValid = isURL(this.landingPageUrl);

            if (!isValid) {
                this.showInvalidDataModal = true;
                this.invalidDataMessage += "The provided landing page URL is not a valid URL" + "\n";
            }
        }
    }

    public openDeleteModal(commentId: number) {
        this.deleteCommentCommand.campaignMessageCommentId = commentId;
        this.showDeleteModal = true
    }
}