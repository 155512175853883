/* tslint:disable:max-classes-per-file */
import React from "react";
import { observer } from "mobx-react";
import { List } from "@singularsystems/neo-core";
import { AppService, Types } from "../Services/AppService";
import UploadNotificationLookup from "../Models/UploadNotificationLookup";
import OnboardingSideMenuDataLookup from "../Models/Onboarding/OnboardingSideMenuDataLookup";
import OnboardingStepLookup from "../Models/Onboarding/OnboardingStepLookup";
import { PartialPlainObject } from "@singularsystems/neo-core/dist/Model";
import OnboardingStepCompletionLookup from "../Models/Onboarding/OnboardingStepCompletionLookup";
import { textConstants } from "../common/textConstants";
import OnboardingModal from "./Modals/OnboardingModal";
import clumsyImage from "../assets/img/clumsy.png";
import { OnboardingSteps } from "../Models/Enums/ApplicationEnums";
import * as Icon from "react-feather";
import Tool from "assets/svgs/Tool";
import Stepper from "./Stepper";
import Pie from "./Circle";
import {
  StyledSideMenu,
  StyledSideMenuHeader,
  StyledTitle,
  StyledProgressCard,
  StyledProgressCardTitle,
} from "./OnboardingSideMenu.styles";
import { StyledStep, StyledStepIcon } from "./Stepper/styles";
import { observable } from "mobx";

interface IStep {
  id: number;
  title: string;
  active: boolean;
  completed: boolean;
  step: OnboardingSteps;
  unlocked: boolean;
}

export interface ILoaded {
  loaded: boolean
}

@observer
class OnboardingSideMenu extends React.Component {
  private customAuthenticationService = AppService.get(
    Types.Security.CustomAuthenticationService
  );
  private authenticationService = AppService.get(
    Types.Neo.Security.AuthenticationService
  );
  public uploadNotificationLookup = new UploadNotificationLookup();
  private onboardingApiClient = AppService.get(
    Types.ApiClients.OnboardingApiClient
  );

  state = {
    isTranscriptText: false,
    isLastStep: false,
    isFirstStep: false,
    paragraphText: "",
    lessonButtonStyle: "btn tab-buttons btn-block selected-btn",
    transcriptButtonStyle: "btn tab-buttons btn-block",
    icpModalShow: false,
    icpModalHtml: <></>,
    icpModalText: "",
    activeStep: 0,
    isLoaded: false,
  };
  setActiveStep: any;

  constructor(props: any
  ) {
    super(props);
    document.addEventListener("stepChangeDropDown", () => {
      this.getThisMenuItem(this.customAuthenticationService.globalProps.currentStep);
    });
  }

  public async componentDidMount() {
    await this.initialization();

    if (
      this.customAuthenticationService.globalProps.currentStep ===
      OnboardingSteps.TechnicalIntegration
    ) {
      let complete = await this.getThisMenuItem(
        this.customAuthenticationService.globalProps.currentStep
      );
    } else {
      let complete = await this.getThisMenuItem();
    }
  }

  private isTranscriptText: boolean = false;
  private isLastStep: boolean = false;
  private isFirstStep: boolean = false;
  private paragraphText: string = "";
  private lessonButtonStyle: string = "btn tab-buttons btn-block selected-btn";
  private transcriptButtonStyle: string = "btn tab-buttons btn-block";
  private onboardingMenuData: OnboardingSideMenuDataLookup =
    new OnboardingSideMenuDataLookup();
  private steps: List<OnboardingStepLookup> = new List(OnboardingStepLookup);
  private mailToEmailBody: string = "";

  public stepList: List<OnboardingStepLookup> = new List(OnboardingStepLookup);

  public stepItems: IStep[] = []

  public stepsComplete: number = 0

  public isActive: boolean | undefined = false;
  public isComplete: boolean | undefined = false;

  private loadSideMenu(isLoaded: boolean) {
    this.setState({ isLoaded: isLoaded })
  }

  public async getThisMenuItem(step: number = 0) {
    await this.getThisStep(step);

    let currentMenuData =
      await this.onboardingApiClient.getOnboardingSideMenuStepData(
        this.customAuthenticationService.globalProps.currentStep,
        ""
      );

    if (currentMenuData.data) {
      this.onboardingMenuData = currentMenuData.data;
      this.paragraphText = this.isTranscriptText
        ? currentMenuData.data.transcriptText
        : currentMenuData.data.lessonText;

      this.setState({
        onboardingMenuData: currentMenuData.data,
        paragraphText: currentMenuData.data.lessonText,
      });
    }

    if (!this.mailToEmailBody) {
      const response = await this.onboardingApiClient.getMailToText();

      if (response.data) {
        this.mailToEmailBody = response.data
          .split(textConstants.generalText.NewLine)
          .join(textConstants.generalText.MailToNewLine);
      }
    }

    return true;
  }

  private async getThisStep(step: number = 0) {
    this.steps = this.customAuthenticationService.globalProps.onboardingSteps;

    // Find the first step (in numeric order) that is not yet complete
    // If the step is provided, then we dont have find the step
    if (step === 0) {
      let currentStep = this.steps
        .sortBy("stepNumber", "asc")
        .find((f) => !f.isComplete);

      if (currentStep) {
        this.customAuthenticationService.globalProps.currentStep =
          currentStep.stepNumber;
      }
    }

    await this.checkCanGoNext();

    this.setEdgeStates();
  }

  // Checks if the user can go next according to their progress
  public async checkCanGoNext() {
    if (this.isLastStep) {
      // If its the last step we need to make sure that all of this is complete.
      // First make sure this is the last step though.

      let thisStep =
        this.customAuthenticationService.globalProps.onboardingSteps.find(
          (f) =>
            f.stepNumber ===
            this.customAuthenticationService.globalProps.currentStep
        );

      if (thisStep && !thisStep.isComplete) {
        var query = new OnboardingStepCompletionLookup();
        query.clientId = this.customAuthenticationService.globalProps.clientId;

        let response =
          await this.onboardingApiClient.canCompleteOnboardingCheck(query);

        if (response) {
          let canComplete = response.data.data as boolean;

          this.customAuthenticationService.globalProps.canGoNext = canComplete;
        } else {
          this.customAuthenticationService.globalProps.canGoNext = false;
        }
      } else {
        //Will always be true
        this.customAuthenticationService.globalProps.canGoNext = true;
      }
    } else {
      let thisStep =
        this.customAuthenticationService.globalProps.onboardingSteps.find(
          (f) =>
            f.stepNumber ===
            this.customAuthenticationService.globalProps.currentStep
        );

      if (
        thisStep &&
        !thisStep.isRequired &&
        thisStep.stepNumber !== OnboardingSteps.TechnicalIntegration
      ) {
        //Step 6 is technical integration. It should be validated even though it is not required
        this.customAuthenticationService.globalProps.canGoNext = true;
      } else if (thisStep !== undefined && !thisStep.isComplete) {
        var query = new OnboardingStepCompletionLookup();
        query.clientId = this.customAuthenticationService.globalProps.clientId;
        query.stepNumber =
          this.customAuthenticationService.globalProps.currentStep;

        //Check if this is true based on the step
        let response = await this.onboardingApiClient.IsOnboardingStepComplete(
          query
        );

        this.customAuthenticationService.globalProps.canGoNext = false;

        if (response.data.success) {
          this.customAuthenticationService.globalProps.canGoNext =
            response.data.data;
        }
      } else if (thisStep !== undefined && thisStep.isComplete) {
        this.customAuthenticationService.globalProps.canGoNext = true;
      }
    }
  }

  private setEdgeStates() {
    let lastStepComplete = true;

    let allStepsCount =
      this.customAuthenticationService.globalProps.onboardingSteps.length;
    let completed =
      this.customAuthenticationService.globalProps.onboardingSteps.filter(
        (f) => f.isComplete
      ).length;

    let currentStep =
      this.customAuthenticationService.globalProps.onboardingSteps.find(
        (f) =>
          f.stepNumber ===
          this.customAuthenticationService.globalProps.currentStep
      );

    if (currentStep) {
      lastStepComplete = currentStep.isComplete;
    }

    if (allStepsCount === completed + 1 && !lastStepComplete) {
      // all steps must be completed, one remaining completed step is the last step
      this.isLastStep = true;
      this.isFirstStep = false;
    } else if (
      completed === 0 ||
      currentStep!.stepNumber === OnboardingSteps.IdealCustomerProfiles
    ) {
      this.isFirstStep = true;
      this.isLastStep = false;
    } else {
      this.isFirstStep = false;
      this.isLastStep = false;
    }
  }

  public async IsCampaignMessageReady() {
    var response = (
      await this.onboardingApiClient.IsCampaignMessageReady(
        this.customAuthenticationService.globalProps.clientId
      )
    ).data.data;
    this.customAuthenticationService.globalProps.clientCampaignMessageId =
      response.clientCampaignMessageId as number;
    this.customAuthenticationService.globalProps.isCampaignMessageReady =
      response.isCampaignMessageReady as boolean;
  }

  public getEmailTemplate() {
    let emailDetail = `mailto:?subject=Technical Integration Information Requirements&body=${this.mailToEmailBody}`;

    return emailDetail;
  }

  private navigateToStep() {
    let event = new Event("stepChange", { bubbles: true, cancelable: false });
    document.dispatchEvent(event);
  }

  public async getOnboardingProps() {
    let response = await this.onboardingApiClient.getUserLoginInfo(
      this.authenticationService!.user!.userName
    );

    this.customAuthenticationService.globalProps.showOnboardingSideMenu = true;

    let steps = response.data.data
      .clientOnboardingSteps as PartialPlainObject<OnboardingStepLookup>[];

    if (steps !== null) {
      this.customAuthenticationService.globalProps.onboardingSteps.set(steps);
    }

    this.customAuthenticationService.globalProps.clientId =
      response.data.data.clientId;
    this.customAuthenticationService.globalProps.isFirstOnboardingLogin =
      response.data.data.isFirstOnboardingLogin;
    this.customAuthenticationService.globalProps.hasSeenTutorial =
      response.data.data.hasSeenTutorial;
    this.customAuthenticationService.globalProps.clientHasSeenTutorial =
      response.data.data.clientHasSeenTutorial;
  }

  public setSelectedLessonButtonStyle() {
    this.lessonButtonStyle = this.isTranscriptText
      ? "btn tab-buttons btn-block"
      : "btn tab-buttons btn-block selected-btn";
    this.transcriptButtonStyle = this.isTranscriptText
      ? "btn tab-buttons btn-block selected-btn"
      : "btn tab-buttons btn-block";
  }

  public toggleModal() {
    this.setState({
      icpModalShow: !this.state.icpModalShow,
    });
  }

  public styleTechIntegration() {
    if (
      this.customAuthenticationService.globalProps.currentStep ===
      OnboardingSteps.TechnicalIntegration
    ) {
      return "technical-integration";
    } else {
      return "";
    }
  }

  public getCurrentView(mappingStep: OnboardingSteps) {
    let steps = this.customAuthenticationService.globalProps.onboardingSteps
    let step = steps.find(os => os.stepNumber === mappingStep)!

    return step ? step.isComplete : false;
  }

  // Gets the initial data from the database, whether steps are completed
  public async initialization() {

    // Makes sure there is an id and the list has not already been populated
    if (this.customAuthenticationService.globalProps.clientId && this.stepList.length === 0) {
      let currentStep = this.customAuthenticationService.globalProps.currentStep;

      // Gets the onboarding steps from the backend.
      this.stepList = (await this.onboardingApiClient.getClientOnboardingSteps(this.customAuthenticationService.globalProps.clientId)).data.data;

      // Maps the onboarding steps from the backend to the IStep interface
      var mapToStepItems = this.stepList.map(step => {
        let activeStep: IStep = {
          id: step.stepNumber,
          title: step.stepName,
          completed: step.isComplete,
          step: step.stepNumber,
          active: step.stepNumber === currentStep,
          // This will be completed based on if there is data or not
          unlocked: step.isUnlocked,
        };
        return activeStep
      });

      this.stepItems = mapToStepItems.filter(x => x.step !== OnboardingSteps.BookAMeeting).sortBy("id");
    }
  }

  // This is manipulating the initial IStep array and is passed to the SideMenu
  public getCurrentStep() {
    let currentStep = this.customAuthenticationService.globalProps.currentStep;

    let remapped: IStep[] = []

    // Each item in stepItems is manipulated based on what the user is doing, ignoring step 4 and 6 due to the technical integration
    // and the book a meeting so it is not populated on the sidemenu.
    this.stepItems.forEach(step => {
      var isActive = false;
      var isUnlocked = false;

      if (currentStep === OnboardingSteps.BookAMeeting || currentStep === OnboardingSteps.CampaignMessages) {
        isActive = step.id === OnboardingSteps.BookAMeeting || step.id === OnboardingSteps.CampaignMessages;
        isUnlocked = isActive;
      }
      else if (step.id !== currentStep && step.id === OnboardingSteps.IdealCustomerProfiles) {
        isUnlocked = true;
        isActive = false;
      }
      else {
        isActive = (step.id === currentStep);
        isUnlocked = isActive;
      }

      let activeStep: IStep = {
        id: step.id,
        title: step.title,
        // Checks if the step is complete.
        completed: step.completed || this.getCurrentView(step.id),
        step: step.id,
        active: isActive,
        // Currently not complete, this currently will unlock the items for a single session rather than whenever you come back
        unlocked: step.unlocked || isUnlocked,
      };

      // Repopulates the stepItems based on the new settings.
      remapped.push(activeStep);
    });

    // Remaps the stepItems to the new array sorted by Id.
    this.stepItems = remapped.sortBy("id");
  }


  public getCompletedPercentage(): | number {
    let totalItems = this.stepItems.length
    let completedItems = this.stepItems.filter(f => f.completed).length;

    let percentage = 0

    if (completedItems !== 0) {
      percentage = 100 * (completedItems / totalItems)
    }

    this.stepsComplete = totalItems - completedItems

    return percentage
  }

  public getPercentageColor(percentage: number): string {
    const colors = {
      0: "#97CBE6",
      25: "#97CBE6",
      50: "#97CBE6",
      75: "#97CBE6",
      100: "#97CBE6",
    };

    return colors[percentage] ?? "#97CBE6";
  }
  public goToSelectedStep(step: OnboardingSteps) {
    if (step === OnboardingSteps.CampaignMessages) {
      var bookAMeetingIncomplete = this.customAuthenticationService.globalProps.onboardingSteps.find(x => x.stepNumber === OnboardingSteps.BookAMeeting && !x.isComplete)

      // If the book a meeting step is incomplete set the current step to BookAMeeting
      if (bookAMeetingIncomplete) {
        this.customAuthenticationService.globalProps.currentStep = OnboardingSteps.BookAMeeting;
        return;
      }
    }
    this.customAuthenticationService.globalProps.currentStep = step;
    this.customAuthenticationService.onboardingFunctions.getRelevantSectionCode()
  }

  public isTechnicalIntegrationComplete() {
    this.isComplete = this.customAuthenticationService.globalProps.onboardingSteps.find(x => x.stepNumber === OnboardingSteps.TechnicalIntegration)?.isComplete
    this.isActive = this.customAuthenticationService.globalProps.currentStep === OnboardingSteps.TechnicalIntegration

    return this.isComplete;
  }

  public render() {
    this.getCurrentStep();

    return (
      <React.Fragment>
        {this.customAuthenticationService.globalProps.showOnboardingSideMenu &&
          this.customAuthenticationService.globalProps.currentStep > 0 && (
            <div className="side-menu-container">
              <StyledSideMenu open={true}>
                <div>
                  <StyledSideMenuHeader>
                    {textConstants.Onboarding.OnboardingSideMenu.SalesTeam}
                  </StyledSideMenuHeader>
                  <Stepper
                    steps={this.stepItems.filter(x => x.step !== OnboardingSteps.TechnicalIntegration)!}
                    activeStep={this.state.activeStep}
                    setActiveStep={(step: number) => {
                      this.setState({ activeStep: step });
                    }}
                    navigateToStep={(step: OnboardingSteps) => {
                      if (this.customAuthenticationService.globalProps.currentStep !== step) {
                        this.goToSelectedStep(step)
                        this.navigateToStep()
                      }
                    }}
                    sideMenuLoading={this.state.isLoaded}
                    hasLoaded={(loaded: boolean) => this.loadSideMenu(loaded)}
                  />
                  <StyledSideMenuHeader>
                    {textConstants.Onboarding.OnboardingSideMenu.ITTeam}
                  </StyledSideMenuHeader>
                  {this.state.isLoaded &&
                    <StyledStep
                      variant={"secondary"}
                      completed={this.isTechnicalIntegrationComplete()}
                      active={this.isActive}
                      unlocked={true}
                      onClick={() => {
                        this.customAuthenticationService.globalProps.currentStep = OnboardingSteps.TechnicalIntegration;
                        this.customAuthenticationService.onboardingFunctions.setHelpCenterSectionCode()
                        this.navigateToStep();
                      }}>
                      <StyledStepIcon
                        variant="secondary"
                        active={this.isActive}
                        completed={this.isComplete}>
                        {this.isComplete ? <Icon.Check size={16} /> : <Tool />}
                      </StyledStepIcon>

                      <StyledTitle>
                        {textConstants.Onboarding.OnboardingSideMenu.EmailTeam}
                      </StyledTitle>
                    </StyledStep>
                  }
                </div>
                <div>
                  <StyledProgressCard>
                    <StyledProgressCardTitle>
                      {textConstants.Onboarding.OnboardingSideMenu.Progress}
                    </StyledProgressCardTitle>
                    <Pie
                      itemList={this.stepItems}
                      percentage={this.getCompletedPercentage()}
                      colour={`${this.stepItems.find(x => x.step === OnboardingSteps.TechnicalIntegration)?.completed ? "#F6BD3A" : "#FADE9C"}`}
                      subText={`${this.stepsComplete > 1 ? `${this.stepsComplete} ${textConstants.Onboarding.StepsLeft}` : `${this.stepsComplete} ${textConstants.Onboarding.StepLeft}`}`}
                    />
                  </StyledProgressCard>
                </div>
              </StyledSideMenu>
            </div>
          )}

        <OnboardingModal
          isOpen={this.state.icpModalShow}
          imageSrc={clumsyImage}
          content={this.state.icpModalHtml}
          closeModal={async () => {
            this.toggleModal();
            this.customAuthenticationService.globalProps.currentStep =
              OnboardingSteps.IdealCustomerProfiles;

            this.navigateToStep();

            if (!this.isLastStep) {
              this.customAuthenticationService.globalProps.canGoNext = true;
            }

            await this.getThisMenuItem(1); //Redirect to ICP
          }}
          acceptAndCloseButton={{
            text: "Go to ICP",
            onClose: async () => {
              this.toggleModal();
              this.customAuthenticationService.globalProps.currentStep =
                OnboardingSteps.TechnicalIntegration;

              this.navigateToStep();

              if (!this.isLastStep) {
                this.customAuthenticationService.globalProps.canGoNext = true;
              }

              await this.getThisMenuItem(1); //Redirect to ICP
            },
          }}
        />
      </React.Fragment>
    );
  }
}

export default OnboardingSideMenu;
