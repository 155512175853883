import {List, ModelBase, NeoModel} from '@singularsystems/neo-core';
import BatchReviewAccountContactLookup from './BatchReviewAccountContactLookup';
  
@NeoModel
export default class BatchReviewAccountLookup extends ModelBase {
  public isCompleteReview: boolean = false 
  
  public clientId: number = 0;

  public batchReviewUploadId : number = 0;

  public batchReviewAccountId : number = 0;

  public accountName : string = "";

  public location : string = "";

  public companySize : string = "";

  public subIndustry : string = "";

  public domain : string = "";

  public deleted : boolean = false;

  public batchReviewAccountContacts : List<BatchReviewAccountContactLookup> = new List(BatchReviewAccountContactLookup)

  isExpanded: boolean = false
}