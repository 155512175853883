/**
 * Move an array to different position.
 *
 * @param  arr
 * @param  oldIndex
 * @param  newIndex
 */
 export function arrayMove(arr: Array<any>, oldIndex: number, newIndex: number) {
  if (newIndex >= arr.length) {
    arr.length = newIndex + 1;
  }

  const item = arr.splice(oldIndex, 1)[0];
  arr.splice(newIndex, 0, item);

  return arr;
  }
  