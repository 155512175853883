import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import { triggerHotjarEvent } from '../../Components/Hotjar';
import ClientFollowUpTemplate from '../../Models/CampaignMessage/ClientFollowUpTemplate';
import DeleteFollowUpCommentCommand from '../../Models/CampaignMessage/Commands/DeleteFollowUpCommentCommand';
import SaveFollowUpCommentCommand from '../../Models/CampaignMessage/Commands/SaveFollowUpCommentCommand';
import SaveFollowUpTemplateCommand from '../../Models/CampaignMessage/Commands/SaveFollowUpTemplateCommand';
import FollowUpTemplateCommentLookup from '../../Models/CampaignMessage/FollowUpTemplateCommentLookup';
import FollowUpTemplateLookup from '../../Models/CampaignMessage/FollowUpTemplateLookup';
import { AppService, Types } from '../../Services/AppService';
import LeadTypeStatusFilter from 'Models/ActionList/LeadTypeStatusFilter';

@NeoModel
export default class AddEditFollowUpTemplateVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private campaignMessagesApiClient = AppService.get(Types.ApiClients.CampaignMessagesApiClient),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService)) {

        super(taskRunner);
    }

    // Properties
    public messageStatusId: number | undefined = 0;

    public isComXUser: boolean = false;

    public campaignMessageStatuses = this.appDataCache.campaignMessageStatuses.get().data.sortBy("messageStatus");

    public clientReviewStatusId = this.campaignMessageStatuses.find(cm => cm.uniqueTableKey === "CampaignMessageStatus3")?.campaignMessageStatusId

    public draftStatusId = this.campaignMessageStatuses.find(cm => cm.uniqueTableKey === "CampaignMessageStatus1")?.campaignMessageStatusId

    public activeStatusId = this.campaignMessageStatuses.find(cm => cm.uniqueTableKey === "CampaignMessageStatus2")?.campaignMessageStatusId

    public clientId: number = 0;

    public isSaving: boolean = false;

    // User/Screen changes tracking
    public isScreenDirty: boolean = false;

    public isCommentDirty: boolean = false;

    // general properties

    public followUpTemplateName: string = "";

    public summary: string = "";

    public selectedCategory: string | undefined = undefined;

    public followUpTemplates: List<FollowUpTemplateLookup> | null = new List(FollowUpTemplateLookup);

    public clientFollowUpTemplate = new ClientFollowUpTemplate();

    public saveFollowUpTemplatesCommand: List<SaveFollowUpTemplateCommand> = new List(SaveFollowUpTemplateCommand);

    public deleteFollowUpCommentCommand: DeleteFollowUpCommentCommand = new DeleteFollowUpCommentCommand();

    public clientFollowUpTemplateId: number = 0;

    public campaignStatusId: number = 0;

    public validationFirstCheckDone: boolean = false;

    public draftValidationFirstCheckDone: boolean = false;

    public showInvalidDataModal: boolean = false

    public invalidDataMessage: string = ""

    public currentComment: string = "";

    public isExpandedId: number = 0;

    public showClientReviewModal: boolean = false;

    public showPublishModal: boolean = false;

    public validationSubject: boolean = false;

    public firstMessageEmpty: boolean = false;

    public showDeleteModal: boolean = false

    public showDeleteDeniedModal: boolean = false

    public commentDeleteFollowUpTemplateId: number = 0;

    public selectedModalOpenTemplate: number = 1;

    public selectedModalOpenTemplateDraftText: string = '';

    public createdBy: string | null = ""

    public openModalClientFollowUpTemplateId: number = 0;
    public actionListStatuses: List<LeadTypeStatusFilter> = new List(LeadTypeStatusFilter)
    public leadTypeStatusId: number = 0;

    public async initialise() {
        if (this.clientFollowUpTemplateId === 0) {
            await this.newFollowUpTemplateSetup()
        }
        else {
            await this.getCurrentFollowUpTemplates();
        }
    }

    public setExpandedId(followUpTemplateId: number) {
        this.isExpandedId = followUpTemplateId
    }

    public async newFollowUpTemplateSetup() {
        this.setupNewFollowUpTemplates()
    }

    public async getCurrentFollowUpTemplates() {
        let clientFollowUpTemplates = await this.taskRunner.waitFor(this.campaignMessagesApiClient.getSpecificFollowUpTemplate(this.clientFollowUpTemplateId))

        this.followUpTemplateName = clientFollowUpTemplates.data.followUpTemplateName
        this.leadTypeStatusId = clientFollowUpTemplates.data.leadTypeStatusId

        this.summary = clientFollowUpTemplates.data.summary

        this.setFollowUpTemplates(clientFollowUpTemplates.data.followUpTemplates)
    }

    public async setFollowUpTemplates(followUpTemplates: List<FollowUpTemplateLookup> | null) {
        this.followUpTemplates = new List(FollowUpTemplateLookup)

        if (followUpTemplates !== null) {
            followUpTemplates.forEach(async fut => {
                let newFollowUpTemplate = new FollowUpTemplateLookup();
                newFollowUpTemplate.followUpTemplateId = fut.followUpTemplateId;
                newFollowUpTemplate.followUpTemplateName = fut.followUpTemplateName;
                newFollowUpTemplate.clientFollowUpTemplateId = fut.clientFollowUpTemplateId;
                newFollowUpTemplate.ordinal = fut.ordinal
                newFollowUpTemplate.draftText = fut.draftText;
                newFollowUpTemplate.summary = fut.summary;
                newFollowUpTemplate.messageText = fut.messageText;
                newFollowUpTemplate.draftModifiedBy = fut.draftModifiedBy
                newFollowUpTemplate.draftModifiedOn = fut.draftModifiedOn
                newFollowUpTemplate.messageModifiedOn = fut.messageModifiedOn
                newFollowUpTemplate.messageModifiedBy = fut.messageModifiedBy
                newFollowUpTemplate.createdBy = fut.createdBy
                newFollowUpTemplate.comments = new List(FollowUpTemplateCommentLookup)

                fut.comments!.forEach(cc => {
                    let newComment = new FollowUpTemplateCommentLookup();

                    newComment.followUpTemplateCommentId = cc.followUpTemplateCommentId;
                    newComment.comment = cc.comment;
                    newComment.commenterName = cc.commenterName;
                    newComment.createdOn = cc.createdOn;
                    newComment.isInternalUser = cc.isInternalUser;
                    newComment.isCurrentUser = cc.isCurrentUser;

                    newFollowUpTemplate.comments!.push(newComment)
                })

                this.followUpTemplates!.push(newFollowUpTemplate);
            });
        }
    }

    public setupNewFollowUpTemplates() {

        for (let i = 1; i <= 4; i++) {
            let emptyMessage = new FollowUpTemplateLookup();
            emptyMessage.ordinal = i;

            this.followUpTemplates!.push(emptyMessage);
        }
    }

    public async AllowDeleteCheck(commentId: number, followUpTemplateId: number) {
        this.taskRunner.run(async () => {
            this.openDeleteModal(commentId)
            this.commentDeleteFollowUpTemplateId = followUpTemplateId;
        });

        return false;
    }

    public async SaveComment(followUpTemplateId: number, currentComment: string) {
        this.currentComment = currentComment

        this.runAllValidationChecks(true);

        if (this.showInvalidDataModal) { return; }

        var newComment = new SaveFollowUpCommentCommand();
        newComment.followUpTemplateId = followUpTemplateId;
        newComment.comment = currentComment;
        newComment.followUpTemplateName = this.followUpTemplateName

        this.taskRunner.run(async () => {
            await this.campaignMessagesApiClient.saveFollowUpComment(newComment);

            var response = await this.campaignMessagesApiClient.getFollowUpTemplates(this.clientFollowUpTemplateId)

            var result = response.data as List<FollowUpTemplateLookup>

            var updatedComments = result.find(f => f.followUpTemplateId === followUpTemplateId)!.comments as List<FollowUpTemplateCommentLookup>

            this.followUpTemplates!.find(f => f.followUpTemplateId === followUpTemplateId)!.comments = updatedComments;
            this.followUpTemplates!.find(f => f.followUpTemplateId === followUpTemplateId)!.currentComment = ""
            this.isCommentDirty = false
        });
    }

    public validationBeforeSave(isPublish: boolean = false, isClientReview: boolean = false): boolean {
        // If it is Save as Draft
        if ((isClientReview === false && isPublish === false && this.isComXUser) || (isClientReview === true && isPublish === false && !this.isComXUser)) {
            // And an Internal user - external user has no validation
            this.runDraftValidationCheck();
        } else {
            this.runAllValidationChecks();
        }
        if (this.showInvalidDataModal) return false;
        else {
            this.isScreenDirty = false;
            return true;
        }
    }

    public runDraftValidationCheck() {
        this.draftValidationFirstCheckDone = true
        this.showInvalidDataModal = false
        this.invalidDataMessage = ""

        if (this.followUpTemplateName === "" || this.followUpTemplateName === null) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = "Follow up template name must be specified" + "\n"
        }
    }

    public runAllValidationChecks(isComment?: boolean) {
        this.validationFirstCheckDone = true
        this.showInvalidDataModal = false
        this.invalidDataMessage = ""

        if (isComment) {
            if (this.currentComment === "" || this.currentComment === null) {
                this.showInvalidDataModal = true
                this.invalidDataMessage = "Comment must be specified"
            }
        }
        else {
            if (this.followUpTemplateName === "" || this.followUpTemplateName === null) {
                this.showInvalidDataModal = true
                this.invalidDataMessage = "Follow up template name must be specified" + "\n"
            }
            // must be changed to categories (dropdown)
            if (!this.leadTypeStatusId) {
                this.showInvalidDataModal = true
                this.invalidDataMessage += "Must select an Action List Status" + "\n"
            }

            if (this.summary === "") {
                this.showInvalidDataModal = true
                this.invalidDataMessage += "Please write a summary" + "\n"
            }

            let followUpTemplatesSpecified = this.followUpTemplates!.filter(f => f.draftText !== null && f.draftText !== "")!.length

            if (followUpTemplatesSpecified === undefined || followUpTemplatesSpecified === 0) {
                this.firstMessageEmpty = true
                this.showInvalidDataModal = true
                this.invalidDataMessage += "Must specify at least one follow up template"
            }
        }
    }

    public async SaveFollowUpTemplates(navigate: () => void, isPublish: boolean = false, isClientReview: boolean = false) {

        if (this.showInvalidDataModal) { return }
        this.saveFollowUpTemplatesCommand = new List(SaveFollowUpTemplateCommand);
        this.populateSaveCommand(isPublish, isClientReview);

        this.taskRunner.run(async () => {
            const saveResponse = await this.campaignMessagesApiClient.saveFollowUpTemplatesCommand(this.saveFollowUpTemplatesCommand);

            if (saveResponse.data.success) {
                this.isSaving = true;
                if (isClientReview && this.isComXUser) {
                    this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.ClientReviewMessageSaved);
                } else if (isPublish) {
                    triggerHotjarEvent(textConstants.Events.clientTemplatePublish)

                    this.showPublishModal = false
                    this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.PublishedFollowUpTemplateSaved);
                }
                else {
                    triggerHotjarEvent(textConstants.Events.clientTemplateDraft)

                    this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.FollowUpTemplateSaved);
                }

                this.isScreenDirty = false;
                navigate();
            }
        });
    }

    private populateSaveCommand(isPublish: boolean, isClientReview: boolean) {

        this.followUpTemplates!.forEach(c => {
            let saveCommand: SaveFollowUpTemplateCommand = new SaveFollowUpTemplateCommand();
            saveCommand.ordinal = c.ordinal;
            saveCommand.followUpTemplateName = this.followUpTemplateName;
            saveCommand.draftText = c.draftText;
            saveCommand.messageText = (c.messageText === '' && (isPublish || isClientReview)) ? c.draftText : c.messageText;
            saveCommand.clientId = this.clientId;
            saveCommand.followUpTemplateId = c.followUpTemplateId;
            saveCommand.clientFollowUpTemplateId = this.clientFollowUpTemplateId;
            saveCommand.leadTypeStatusId = this.leadTypeStatusId;
            saveCommand.isPublish = isPublish;
            saveCommand.isClientReview = isClientReview;
            saveCommand.summary = this.summary;
            saveCommand.campaignMessageStatusId = c.clientCampaignMessageStatusId;

            this.saveFollowUpTemplatesCommand.push(saveCommand);
        })
    }

    public async DeleteFollowUpComment() {
        this.taskRunner.run(async () => {
            let response = await this.campaignMessagesApiClient.deleteFollowUpComment(this.deleteFollowUpCommentCommand);

            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.CommentDelete);
            this.showDeleteModal = false

            var commentsResponse = await this.taskRunner.waitFor(this.campaignMessagesApiClient.getFollowUpTemplates(this.clientFollowUpTemplateId))

            var allData = commentsResponse.data as List<FollowUpTemplateLookup>

            var updatedComments = allData.find(f => f.followUpTemplateId === this.commentDeleteFollowUpTemplateId)!.comments as List<FollowUpTemplateCommentLookup>

            this.followUpTemplates!.find(f => f.followUpTemplateId === this.commentDeleteFollowUpTemplateId)!.comments = updatedComments;

            this.commentDeleteFollowUpTemplateId = 0
            this.showDeleteModal = false;
        });
    }

    public openDeleteModal(commentId: number) {
        this.deleteFollowUpCommentCommand.followUpTemplateCommentId = commentId;
        this.showDeleteModal = true
    }

}