import React from 'react';
import { Views, NeoGrid, Neo } from '@singularsystems/neo-react';
import CountryVM from './CountryVM';
import { observer } from 'mobx-react';
import { textConstants } from './../../common/textConstants';
import GoBack from '../../Components/GoBack';
import * as Icon from 'react-feather';

@observer
export default class CountryView extends Views.ViewBase<CountryVM> {
	constructor(props: unknown) {
		super("Country", CountryVM, props);
	}

	onLeave() {
		this.viewModel.removeNotifications();
		return undefined;
	}

	public render() {
		return (
			<>
				<GoBack />
				<Neo.Card>
					<h2 className='p-24 mt-4'>{textConstants.titleText.Country}</h2>
					<Neo.Form model={this.viewModel.countryList} onSubmit={() => this.viewModel.saveCountryList()} showSummaryModal>
						<NeoGrid.Grid className="collapsedButton"
							items={this.viewModel.countryList} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
							{(country, countryMeta) => (
								<NeoGrid.RowGroup expandProperty={countryMeta.isExpanded}>
									{/* Country */}
									<NeoGrid.Row >
										<NeoGrid.Column bind={countryMeta.countryName} />
										<NeoGrid.Column width={100} bind={countryMeta.countryCode} />
										<NeoGrid.Column bind={countryMeta.languageId} select={{ items: this.viewModel.languageList }} />
										<NeoGrid.ButtonColumn showDelete
											deleteButton={{
												icon: <Icon.X size={24} />,
												variant: "light",
												className: "circled"
											}
											} />
									</NeoGrid.Row>

									{/* Region */}
									<NeoGrid.ChildRow>
										<NeoGrid.Grid items={country.regions} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
											{(region, regionMeta) => (
												<NeoGrid.RowGroup expandProperty={regionMeta.isExpanded}>
													<NeoGrid.Row>
														{/* Region Name */}
														<NeoGrid.Column bind={regionMeta.regionName}
															label={textConstants.titleText.Region}
														/>
														<NeoGrid.ButtonColumn showDelete
															deleteButton={{
																icon: <Icon.X size={24} />,
																variant: "light",
																className: "circled"
															}
															} />
													</NeoGrid.Row>

													{/* Sub Region */}
													<NeoGrid.ChildRow>
														<NeoGrid.Grid items={region.subRegions} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
															{(subRegion, subRegionMeta) => (
																<NeoGrid.Row>
																	{/* Sub Region */}
																	<NeoGrid.Column bind={subRegionMeta.subRegionName}
																		label={textConstants.titleText.SubRegion}
																	/>
																	<NeoGrid.ButtonColumn showDelete
																		deleteButton={{
																			icon: <Icon.X size={24} />,
																			variant: "light",
																			className: "circled"
																		}
																		} />
																</NeoGrid.Row>
															)}
														</NeoGrid.Grid>
													</NeoGrid.ChildRow>

												</NeoGrid.RowGroup>
											)}
										</NeoGrid.Grid>
									</NeoGrid.ChildRow>

								</NeoGrid.RowGroup>
							)}
						</NeoGrid.Grid>
						<div className="my-4 text-right p-24">
							<Neo.Button className="btn-width-100" isSubmit variant="success">{textConstants.buttonText.Save}</Neo.Button>
						</div>
					</Neo.Form>
				</Neo.Card>
			</>
		);
	}
}