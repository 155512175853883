import { NeoModel, ModelBase, List } from '@singularsystems/neo-core';
import { AppService, Types } from 'Services/AppService';
import UpdateOnboardingStepCommand from './UpdateOnboardingStepCommand';
import { OnboardingSteps, OnboardingPopups } from 'Models/Enums/ApplicationEnums';
import OnboardingStepLookup from './OnboardingStepLookup';
import OnboardingPopupLookup from './Queries/OnboardingPopupLookup';
import { textConstants } from 'common/textConstants';
import UpdateHasSeenOnboardingStepPopupCommand from './Commands/UpdateHasSeenOnboardingStepPopupCommand';
import { OidcAuthService } from 'Services/AuthenticationService';
import GlobalProps from './GlobalProps';

interface OnboardingData {
  onboardingStepId: number | undefined;
  showOnboardingModal: boolean;
  onboardingPopupModalContent: string;
}

@NeoModel
export default class OnboardingFunctions extends ModelBase {

  private globalProps = new GlobalProps()

  private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient);

  private notifications = AppService.get(Types.Neo.UI.GlobalNotifications);

  constructor(
    globalProps: GlobalProps
  ) {
    super()
    this.globalProps = globalProps;
  }


  public updateOnboardingStep = async () => {

    const onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient);

    const { onboardingSteps, currentStep, clientId } = this.globalProps;

    let step = onboardingSteps.find(os => os.stepNumber === currentStep)

    if (!step?.isComplete || currentStep === OnboardingSteps.CampaignMessages) {
      let updateCommand = new UpdateOnboardingStepCommand();
      updateCommand.clientId = clientId;
      updateCommand.stepNumber = currentStep;
      updateCommand.isComplete = true;

      await onboardingApiClient.updateOnboardingClientStep(updateCommand);

      let steps = await (await onboardingApiClient.getClientOnboardingSteps(updateCommand.clientId)).data.data;

      if (steps !== null) {
        this.globalProps.onboardingSteps.set(steps)
      }
    }
  }

  public setHelpCenterSectionCode() {
    if (this.globalProps.isOnboarding) {
      this.getRelevantSectionCode(1)
    }
  }

  public getRelevantSectionCode(questionNumber: number = 1, variation: string = "") {
    // This function is to aid the help center in terms of displaying the correct information for the relevant onboarding step and question number.

    let stepNumber = this.globalProps.currentStep

    let sectionText = "";

    switch (stepNumber) {
      case 1: sectionText = "ICPQuestion"
        break;
      case 2: sectionText = "TMSection"
        break;
      case 3: sectionText = "BLSection"
        break;
      case 5: sectionText = "CMSection"
        break;
      case 6: sectionText = "TechSettingSection"
        break;
      default: sectionText = "default"
        break;
      // Other step cases to be added once the database has been updated with their section codes
    }

    this.globalProps.sectionCode = sectionText + variation + questionNumber;
  }

  public isOnboardingHelpCenterVisible() {

    return (this.globalProps.showOnboardingSideMenu
      && this.globalProps.sectionCode != ""
      && this.globalProps.showOnboardingHelpCenter)
  }

  // Gets the specific step popup name
  public getCorrectPopup(stepNumber: number) {
    switch (stepNumber) {
      case OnboardingSteps.BlackList:
        return OnboardingPopups.BlacklistOnboardingFinished;
      case OnboardingSteps.TargetMarkets:
        return OnboardingPopups.TargetMarketOnboardingFinished;
      case OnboardingSteps.IdealCustomerProfiles:
        return OnboardingPopups.ICPOnboardingFinished;
      case OnboardingSteps.CampaignMessages:
        return OnboardingPopups.CampaignMessageOnboardingFinished;
      case OnboardingSteps.TechnicalIntegration:
        return OnboardingPopups.TechnicalIntegrationOnboardingFinished;
      default:
        return;
    }
  }

  // Gets the onboarding data that is needed to check if the step is Complete.
  public async getOnboardingData(): Promise<OnboardingData> {
    let { isOnboarding, currentStep, clientId, onboardingSteps } = this.globalProps

    let showOnboardingModal: boolean = false;

    let onboardingStepId: number | undefined = 0;

    let onboardingPopupModalContent = "";

    let hasNotSeenPopup = onboardingSteps.find(onboardingStep => onboardingStep.stepNumber === currentStep && !onboardingStep.hasSeenStepCompletedPopup)?.isComplete

    // Makes sure its onboarding
    if (isOnboarding && hasNotSeenPopup) {
      const onboardingResponse = await this.onboardingApiClient.getClientOnboardingSteps(clientId);

      if (onboardingResponse.data.success) {
        onboardingSteps.set(onboardingResponse.data.data)
      }
      else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, textConstants.messageText.saveMessage.SaveFailedMessage);
      }

      // Gets the specific popup data for that current step
      onboardingPopupModalContent = await this.getPopupData(currentStep)

      // Checks if you have seen the popup
      let hasSeenPopup = onboardingSteps.find(onboardingStep =>
        onboardingStep.stepNumber === currentStep && onboardingStep.isComplete === true)?.hasSeenStepCompletedPopup

      showOnboardingModal = (hasSeenPopup === false)

      onboardingStepId = onboardingSteps.find(onboardingStep =>
        onboardingStep.stepNumber === currentStep)?.stepNumber
    }

    // Returns as an interface
    return {
      onboardingStepId,
      showOnboardingModal,
      onboardingPopupModalContent,
    };
  }

  // Gets the popup data for the current step
  public async getPopupData(currentStep: number) {

    let onboardingPopupModalContent: string = "";

    let onboardingPopupData: List<OnboardingPopupLookup> = new List(OnboardingPopupLookup);

    const response = await this.onboardingApiClient.getOnboardingPopupData();

    let data = response.data;

    if (data.success) {
      onboardingPopupData.set(data.data);
    } else {
      this.notifications.addDanger(textConstants.titleText.SaveFailed, textConstants.messageText.saveMessage.SaveFailedMessage);
    }

    // Finds the specific popup text.
    let modalContent = onboardingPopupData.find(popUp =>
      popUp.popupName === this.getCorrectPopup(currentStep))?.text

    modalContent = modalContent ? modalContent : textConstants.generalText.congratulations;

    onboardingPopupModalContent = modalContent;

    return onboardingPopupModalContent;
  }

  public async setStepCompleteModalAsSeen(stepId?: number) {
    let command = new UpdateHasSeenOnboardingStepPopupCommand()
    command.clientId = this.globalProps.clientId
    command.onboardingStepId = stepId ? stepId : 0
    await this.onboardingApiClient.updateHasSeenOnboardingStepPopup(command)
  }

  public navigateToStep() {
    let event = new Event("stepChange", { bubbles: true, cancelable: false });
    document.dispatchEvent(event);

    this.getRelevantSectionCode();
  }

  public async finishOnboarding() {
    await this.updateOnboardingStep()
    this.globalProps.isOnboarding = false;
  }
}