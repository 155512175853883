import { Observable } from '@singularsystems/neo-core/dist/Model/Decorators';
import { UserManager, User, UserManagerSettings } from 'oidc-client-ts';
import { Security, NeoModel, Attributes } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService, Types } from './AppService';
import UserMenuAccessLookup from '../Views/InvitedUsers/LookUps/UserMenuAccessLookup';
import { observable, makeObservable } from 'mobx';
import GlobalProps from '../Models/Onboarding/GlobalProps';
import OnboardingFunctions from 'Models/Onboarding/OnboardingFunctions';

@injectable()
@NeoModel
export class OidcAuthService extends Security.OidcAuthService {

    @Attributes.Observable()
    public userMenuAccessInfo: UserMenuAccessLookup | null = null;

    @Attributes.Observable()
    public globalProps: GlobalProps = new GlobalProps();
    
    @Attributes.Observable()
    public onboardingFunctions: OnboardingFunctions = new OnboardingFunctions(this.globalProps)

    constructor(
        axios = AppService.get(Types.Neo.Axios),
        config = AppService.get(Types.Config),
        private invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient),
    ) {
        super(
            new UserManager(config.userManagerSettings),
            axios);
        this.loadUserFromToken()
    }

    private comxHub: any


    protected async afterUserLoaded() {
        this.comxHub = AppService.get(Types.ApiClients.ComXHub)
        this.connectToComXHub()
    }

    public async reloadUserMenuAccessData() {
        let response = await this.invitedUsersApiClient.getUserMenuAccess();
        if (response.data !== null) {
            this.userMenuAccessInfo = UserMenuAccessLookup.fromJSObject<UserMenuAccessLookup>(response.data);
        }
    }

    public connectToComXHub() {
        this.comxHub.UpdateUserMenuAccess.subscribe(this.updateUserMenuAccess);
    }

    public updateUserMenuAccess = (userMenuAccessLookup: UserMenuAccessLookup) => {
        let clientId = userMenuAccessLookup.clientId;

        if (this.globalProps.clientId === clientId) {
            this.userMenuAccessInfo = userMenuAccessLookup
        }
    }
}