import React from "react";
import { SetStateAction, useState } from "react";
import { arrayMove } from "./utils/ArrayMove";
import Draggable from "./Draggable";

export interface DraggableItem {
  id: string | number;
}

export interface DraggableItems {
  items: Array<DraggableItem | any>;
  setItems: (items: any) => void;

  children?: React.ReactNode;

  component: (item: any) => JSX.Element;
  isDisabled?: boolean;
}

const SortableList = ({ items, setItems, component, isDisabled }: DraggableItems) => {
  const [dragId, setDragId] = useState("");
  const [isDragging, setIsDragging] = useState(false);

  const onDrop = (ev: { currentTarget: { id: any; }; }, item: any) => {

    if (isDisabled) {
      return
    }

    setIsDragging(false);
    let currentPos = 0,
      droppedPos = ev.currentTarget.id;

    for (let i = 0; i < items.length; i++) {
      if (dragId === items[i].id) {
        currentPos = i;
      }

      if (ev.currentTarget.id === items[i].id) {
        droppedPos = i;
      }
    }

    const newItems = arrayMove([...items], currentPos, droppedPos);
    setItems(newItems);
  };

  const onDragStart = (ev: any, item: { id: SetStateAction<string>; }) => {

    if (isDisabled) {
      return
    }

    setDragId(item.id);
    setIsDragging(true);
  };

  const onDragEnd = () => {

    if (isDisabled) {
      return
    }

    setIsDragging(false)
  }

  const renderComponent = (componentJsx: (item: any) => JSX.Element, item: any, index: number) => {
    const Component = componentJsx;

    return <Component item={item} index={index} />;
  };

  return (
    <>
      {items.length > 0 && items.map((item, index) => (
        <Draggable
          key={index}
          id={index}
          onDrop={onDrop}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          item={item}
          isDragging={isDragging}
          isDisabled={isDisabled}
        >
          {renderComponent(component, item, index)}
        </Draggable>
      ))}
    </>
  );
};

export default SortableList;
