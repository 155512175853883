import { observable } from 'mobx';
import { PageRequest, PageResult, IPageManagerOptions, SortOperation, DataViewBase } from '@singularsystems/neo-core/dist/Data';
import { ValueObject, Model, NeoModel, Misc, AppServices, ITaskRunner, List } from '@singularsystems/neo-core';
import { ModelBase } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { PartialPlainObject, NeoModelConstructor } from '@singularsystems/neo-core/dist/Model';
import { Observable } from '@singularsystems/neo-core/dist/Model/Decorators';
import PropertyInfo from '@singularsystems/neo-core/dist/Model/PropertyInfo';
import DataGridResult from '../../Components/ReactDataGrid/DataGridResult';
import { TypeSingleSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { GridProps, GridManagerConst } from '../../Components/ReactDataGrid/DataGridHelper';

export interface IGridDataSource<TModel> {
    data: TModel[],
    count: number
}

@NeoModel
class GridDataSource<TModel> implements IGridDataSource<TModel>
{
    constructor() {
    }
    data: TModel[] = [];
    count: number = 0;
}



/**
 * React Data Grid Manager, Used to manage
 * react data grids throughout the systems
 * @export
 * @class ReactDataGridManager
 * @extends {PageManager<TCriteria, TModel, TFilter>}
 * @template TCriteria 
 * @template TModel 
 */

export class ReactDataGridManager<TCriteria extends ValueObject, TModel extends ModelBase, TFilter extends ModelBase> extends DataViewBase<TModel>
{
    private options: IPageManagerOptions<TCriteria, TModel>;

    constructor(
        private criteria: TCriteria,
        private modelType: new () => TModel,
        private dataSource: (criteria: Model.PartialPlainNonTrackedObject<PageRequest<TCriteria>>) => AxiosPromise<DataGridResult<PageResult<Model.PlainObject<TModel>>, TFilter>>,
        private filterType: new () => TFilter,
        options?: IPageManagerOptions<TCriteria, TModel>) {

        super([]);
        this.options = options || {};
        this.allowSort = this.options.allowSort !== false;

        this.pageRequest = new PageRequest<TCriteria>(this.criteria);
        this.pageRequest.pageSize = this.options.pageSize || 20;

        // Create an instance of model type so that we know about its properties.
        void new modelType();
        void new filterType();

        if (options) {
            if (options.sortBy) {
                this.setSortOperations(options.sortBy, options.sortAscending);
            }
            this.taskRunner = options.taskRunner!;
        }

        if (!options || !options.taskRunner) {
            this.taskRunner = Misc.Globals.appService.get(AppServices.NeoTypes.TaskRunner);
        }

        if (!this.options.pageSizeOptions) {
            this.options.pageSizeOptions = Misc.Settings.pager.pageSizeOptions;
        }
        this.reactDataSource = new GridDataSource<TModel>();
        this.filterOptions = new this.filterType();
    }

    @Observable()
    public reactDataSource: IGridDataSource<TModel>;

    @Observable()
    public filterOptions: TFilter

    public taskRunner!: ITaskRunner;
    private hasInitialised: boolean = false;
    private pageRequest: PageRequest<TCriteria>;

    @Observable()
    private _totalRecords: number = -1;

    @Observable()
    private currentData = new List(this.modelType);

    @Observable()
    private initialFetchState: "none" | "loading" | "complete" = "none";

    @Observable()
    public loading: boolean = true
    /**
     * Returns the state of the initial data request. Does not change after the first request has been made.
     * Note, this will return null while loading, so you should check against a boolean value. E.g. if (hasFetched === false)
     */
    public get hasFetched(): boolean | null {
        if (this.initialFetchState === "loading") {
            return null;
        }
        return this.initialFetchState === "complete";
    }

    /**
      * Returns the state of the initial data request. Does not change after the first request has been made.
      * Note, this will return null while loading, so you should check against a boolean value. E.g. if (hasFetched === false)
      */
    public get criteriaKey(): string[] {
        const criteria = Object.keys(this.criteria)
        return criteria
    }


    /**
     * Gets or sets the 1 based page number.
     */
    public get pageNo() {
        return this.pageRequest.pageIndex + 1;
    }
    public set pageNo(value: number) {
        const newIndex = Math.max(1, Math.min(this.totalPages, value)) - 1;
        if (this.pageRequest.pageIndex !== newIndex) {
            this.pageRequest.pageIndex = newIndex;
            this.getPageData(undefined, false, this.options.initialTaskRunner);
        }
    }

    public get pageSize() {
        return this.pageRequest.pageSize;
    }

    public set pageSize(value: number) {
        const prevSize = this.pageRequest.pageSize;
        const prevPageNo = this.pageNo;
        if (value !== prevSize) {
            this.pageRequest.pageSize = value;
            // Adjust the page no so we show the same records, this will re-fetch data.
            this.pageNo = Math.floor((this.pageRequest.pageIndex * prevSize + 1) / value) + 1;
            if (this.pageNo === prevPageNo) {
                this.getPageData(undefined, false, this.options.initialTaskRunner);
            }
        }
    }

    public get totalRecords() {
        return this._totalRecords;
    }

    /** Gets the total number of pages based on the total number of records. */
    public get totalPages() {
        if (this.totalRecords <= 0) {
            return 1;
        } else {
            return Math.ceil(this.totalRecords / this.pageRequest.pageSize);
        }
    }

    /**
     * Called by the pager component. Will fetch data if options.fetchInitial is set to true.
     */
    public initialise() {
        if (!this.hasInitialised) {
            this.hasInitialised = true;
            if (this.options.fetchInitial) {
                this.getPageData(undefined, true, this.options.initialTaskRunner);
            }
        }
    }

    public getDefault(value: any) {
        return typeof value === "string" ? "" :  typeof value === "object" ? [] : typeof value === "boolean" ?  false: typeof value === "number" ? null :1
    }

    public async getPageData(props?: GridProps, getCount = true, taskRunner?: ITaskRunner): Promise<{ dataGrid: IGridDataSource<TModel>, filter: TFilter }> {
        if (getCount) {
            // Reset the page index when the no of records returned is expected to change (e.g. when filtering).
            this.pageRequest.pageIndex = 0;
        }

        if (props && props.filterValue) {
            const isColumnFilter = props.filterValue.filter((element: any) => element.value)

            if (isColumnFilter.length > 0) {
                this.criteria[GridManagerConst.filterColumnInd] = true
            }
            else {
                this.criteria[GridManagerConst.filterColumnInd] = false
            }

            this.criteria[GridManagerConst.notFilter] = []
            props.filterValue.forEach((element : any) => {
                const value = this.criteriaKey.filter(x => x === element.name)[0]
                
                if (value) {
                    if (element.value) {                        
                        this.criteria[value] = element.value
                        if (element.operator === GridManagerConst.notinlist || element.operator === GridManagerConst.notEqual) {
                            this.criteria[GridManagerConst.notFilter].push(element.name)
                        }                    
                    }
                    else {
                        this.criteria[value] = this.getDefault(this.criteria[value])
                    }
                }
            });

        }

        if (props && props.sortInfo) {
            const prop = props.sortInfo as TypeSingleSortInfo
            const propertyInfo = new PropertyInfo(prop.name as string)
            const sortOperation = new SortOperation(propertyInfo, prop.dir >= 0 ? true : false)
            this.pageRequest.sortColumns = [sortOperation]
        }
        this.pageRequest.noCount = !getCount;

        if (props) {
            this.pageRequest.pageSize = props.limit
            this.pageRequest.pageIndex = props.skip / props.limit;
        }

        const request = this.pageRequest.toQueryObject() as Model.PlainObject<PageRequest<TCriteria>>;

        if (this.options.beforeFetch) this.options.beforeFetch(request);

        if (!taskRunner) taskRunner = this.taskRunner;

        if (this.initialFetchState === "none") {
            this.initialFetchState = "loading";
        }

        const response = await this.dataSource(request)
        this.setData(response.data.pageResult.entityList, response.data.pageResult.total, response.data.filterEntity);

        return { dataGrid: this.reactDataSource, filter: response.data.filterEntity }
    }

    /* Will set the data to the provided list. This will still invoke the `afterFetch` */
    public setData(pageRecords: PartialPlainObject<TModel>[], totalRecords: number = 0, filterList: any) {
        if (totalRecords >= 0) {
            this._totalRecords = totalRecords;
        }

        this.reactDataSource.data = pageRecords as [];
        this.reactDataSource.count = totalRecords;
        this.currentData.set(pageRecords);

        if (this.options.afterFetch) this.options.afterFetch(this.currentData);

        this.initialFetchState = "complete";
    }

    public setFilter(filter: TFilter) {
        this.filterOptions = filter
    }
    /**
     * Resets the page manager to its initial state and clears the current data.
     */
    public reset() {
        this.initialFetchState = "none";
        this.data.set([]);
    }

    public getFilter() {
        return this.filterOptions
    }
    /**
     * Resets the sort properties. A new sort property can be provided. 
     * If the provided sort property matches the first existing sort property, and sortAscending is undefined, then the current sort order will be reversed.
     */
    public resetSort(property?: PropertyInfo, sortAscending?: boolean) {
        super.resetSort(property, sortAscending)
        this.getPageData(undefined, true, this.options.initialTaskRunner);
    }

    /**
     * Adds a sort property.
     * If the property already exists, the sort direction of the property will be reversed.
     */
    public addSort(property: PropertyInfo, sortAscending?: boolean) {
        super.addSort(property, sortAscending);
        this.getPageData(undefined, true, this.options.initialTaskRunner);
    }

    /** Sets a single or multiple sort operations. Does not refresh data. */
    public setSortOperations(sortBy: keyof TModel | (keyof TModel)[], sortAscending?: boolean | boolean[]) {
        this.sortInfo = [];

        const metaData = (this.modelType as unknown as NeoModelConstructor).neo.meta;

        if (sortBy instanceof Array) {
            for (let i = 0; i < sortBy.length; i++) {
                this.sortInfo.push(new SortOperation(metaData[sortBy[i] as string].propertyInfo, sortAscending === undefined || sortAscending[i] !== false));
            }
        } else {
            this.sortInfo.push(new SortOperation(metaData[sortBy as string].propertyInfo, sortAscending !== false));
        }
    }

    public get rawItems() {
        return this.currentData;
    }

    public getItems(): TModel[] {
        return this.currentData;
    }

    /**
     * Returns the current list.
     */
    public get data(): List<TModel> {
        return this.currentData;
    }

    public get pageSizeOptions() {
        return this.options.pageSizeOptions!;
    }

    public getMetaItem() {
        return new this.modelType();
    }

    public firstPage(): void {
        this.pageNo = 1;
    }

    public previousPage(): void {
        this.pageNo -= 1;
    }

    public nextPage(): void {
        this.pageNo += 1;
    }

    public lastPage(): void {
        this.pageNo = this.totalPages;
    }

    public getData() {
        return this.filterOptions
    }
}
