import React from "react";
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from "../../Services/AppService";

export default class LoggedOutView extends Views.ViewBase {
    private authService = AppService.get(Types.Neo.Security.AuthenticationService);

    constructor(props: unknown) {
        super("Logged out", Views.EmptyViewModel, props);
    }

    public componentDidMount() {
        this.authService.beginSignOut();
    }

    public render() {
        return (
            <div>
                <h2>Logged out</h2>

                <p>You have been logged out of the application.</p>
            </div>
        )
    }
}