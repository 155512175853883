import styled from "styled-components";
import { IButtonProps } from "./types";
import { theme } from 'Globals/Theme/theme';

const buttonSize = (size: string) => {
  switch (size) {
    case "xSmall":
      return "8px 24px";
    case "small":
      return `10px ${theme.spacing(2)}`;
    case "medium":
      return `13px ${theme.spacing(2)}`;
    case "large":
      return `15px ${theme.spacing(3)}`;
    default:
      return `15px ${theme.spacing(3)}`;
  }
};

const buttonVariant = (variant: string, buttonType: string,) => {
  switch (variant) {
    case "contained":
      return `
          background-color: ${getButtonTypeColor(buttonType)};
          padding: 15px ${theme.spacing(3)};
          border-radius: 8px;
          color: #fff;
          &:hover {
            background-color: ${getButtonTypeColor(buttonType)};
            opacity: 0.8;
          }
        `;
    case "outlined":
      return `
            border: 1px solid ${getButtonTypeColor(buttonType)};
            padding: 15px ${theme.spacing(2)};
            background-color: none;
            border-radius: 8px;
            color: ${getButtonTypeColor(buttonType)};
            &:hover {
              border: 1px solid ${getButtonTypeColor(buttonType)};
              opacity: 0.8;
            }
        `;
    case "hasIcon":
      return `
        background-color: ${getButtonTypeColor(buttonType)};
        border-radius: 100px;
        padding: 6px 24px;
        color: #fff;
        height: 40px;
        svg {
          margin-right: 8px;
        }
      `;
    default:
      return `
        border: none;
        padding: 15px ${theme.spacing(2)};
        background-color: none;
        border-radius: 8px;
        color: ${theme.palette.primary.dark};
        &:hover {
          border:  none;
          opacity: 0.8;
        }    
        `;
  }
};

const getButtonTypeColor = (type: string) => {
  switch (type) {
    case "primary":
      return `${theme.palette.primary.dark}`;
    case "secondary":
      return `${theme.palette.primary.light}`;
    default:
      return `${theme.palette.primary.dark}`;
  }
};

export const StyledButton = styled("div") <IButtonProps>`
  padding: ${({ size }) => buttonSize(size)};
  text-align: center;
  
  ${({ marginNone }) => `${marginNone ? "margin: 0;" : "margin: 16px 0;"}`}

  ${({ fillAvailable }) => fillAvailable && `width: -webkit-fill-available;`}

  background-position: center;
  transition: background 0.8s;
  cursor: pointer;
  user-select: none;

  ${({ variant, buttonType }) => buttonVariant(variant, buttonType)}

  ${({ isIconButton }) =>
    isIconButton && `
    padding: 8px 0px;
    background-color: #EDEEEE;
    border-radius: 8px;
    font-weight: bold;
    color: #111e1e;
    margin: 24px 12px;
    height: 32px;
    width: 32px;
    `}

  &:active {
    background-size: 100%;
    transition: background 0s;
    opacity: 0.8;
  }

  &:hover {
    opacity: 0.8;
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: ${theme.colors.white};
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  `}

  .className{}
`;

export const StyledButtonWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-right: 12px;
  float: right;
`;

export const StyledButtonGroupWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;  