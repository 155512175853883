import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import InformationManagementDetail from '../Models/Maintenance/InformationManagementDetail';

export interface IInformationManagementApiClient extends Data.IUpdateableApiClient<InformationManagementDetail, number> {

}

@injectable()
export default class InformationManagementApiClient extends Data.UpdateableApiClient<InformationManagementDetail, number> implements IInformationManagementApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/informationManagementDetails`);
    }

}