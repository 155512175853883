import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import InvalidReason from '../../Models/Maintenance/InvalidReason';
import { AppService, Types } from '../../Services/AppService';

@NeoModel
export default class InvalidReasonVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private invalidReasonsApiClient = AppService.get(Types.ApiClients.InvalidReasonsApiClient)) {

        super(taskRunner);
    }

    public async initialise() {
        const response = await this.taskRunner.waitFor(this.invalidReasonsApiClient.get());
        this.invalidReasonsList.set(response.data);
    }

    public invalidReasonsList = new List(InvalidReason);

    public async updateReason() {
        this.taskRunner.run(async () => {
            const response = await this.invalidReasonsApiClient.saveList(this.invalidReasonsList.toJSArray());
            this.invalidReasonsList.update(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.InvalidReasonSaved)
        });
    }
}