import { List, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import QuestionnaireAnswerLookup from '../QuestionnaireAnswerLookup';

@NeoModel
export default class SaveQuestionnaireAnswersCommand extends ModelBase {

    // Properties
    @Rules.Required()
    public clientId: number = 0;

    @Rules.Required()
    public customerProfileId: number = 0;

    @Rules.StringLength(500)
    public profileName: string = "";

    public customerProfileStatusId : number = 0

    public answers = new List(QuestionnaireAnswerLookup)

    public isAutoSave: boolean = false;

    public variations: string[] = []
}