import { Data, Model, Utils } from "@singularsystems/neo-core";
import Axios, { AxiosPromise } from "axios";
import { injectable } from "inversify";
import { ResponseResolver } from "../common/utils";
import ArchiveBatchReviewCommand from "../Models/BatchReview/Command/ArchiveBatchReviewCommand";
import BatchReviewDeleteAccountCommand from "../Models/BatchReview/Command/BatchReviewDeleteAccountCommand";
import BatchReviewDeleteContactCommand from "../Models/BatchReview/Command/BatchReviewDeleteContactCommand";
import BatchReviewSaveCommand from "../Models/BatchReview/Command/BatchReviewSaveCommand";
import BatchReviewProspectsGridCriteria from "../Models/BatchReview/Query/BatchReviewGridCriteria";
import BatchReviewUploadCriteria from "../Models/BatchReview/Query/BatchReviewUploadCriteria";
import CommandResult from "../Models/InvitedUsers/Commands/CommandResult";
import { AppService, Types } from "../Services/AppService";
import BatchReviewCompletionDetailsLookup from "../Models/BatchReview/Query/BatchReviewCompletionDetailsLookup";

export interface IBatchReviewApiClient {
    getBatchReviewUploadLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BatchReviewUploadCriteria>>) : AxiosPromise<any>,
    completeBatchReview(batchReviewUploadId : number, command: BatchReviewSaveCommand) : AxiosPromise<Model.PlainObject<CommandResult>>,
    SaveBatchAsDraft(batchReviewUploadId : number, command: BatchReviewSaveCommand): AxiosPromise<any>,
    removeOrUnremoveBatchReviewAccount(command: BatchReviewDeleteAccountCommand): AxiosPromise<any>,
    removeOrUnremoveBatchReviewContact(command: BatchReviewDeleteContactCommand): AxiosPromise<any>,
    getBatchReviewDataLookups(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BatchReviewProspectsGridCriteria>>): AxiosPromise<any>,
    archiveOrUnarchiveBatchReview(command: ArchiveBatchReviewCommand): AxiosPromise<any>,
    downloadBatchReview(batchReviewUploadId : number, clientId: number) : AxiosPromise<any>,
    isClientReviewOpen(batchReviewUploadId : number, clientId: number) : AxiosPromise<any>,
    getBatchReviewCompletionDetails(clientId: number, batchReviewUploadId : number) : AxiosPromise<BatchReviewCompletionDetailsLookup>
}

@injectable()
export default class BatchReviewApiClient implements IBatchReviewApiClient {

    constructor(public config = AppService.get(Types.Config)) {
    }

    public getBatchReviewUploadLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BatchReviewUploadCriteria>>) : AxiosPromise<any>{
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.get(`${apiPath}/BatchReview/GetBatchReviewUploadLookup?${Utils.getQueryString(request)}`);
    }

    public getBatchReviewDataLookups(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BatchReviewProspectsGridCriteria>>): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath
        return Axios.get(`${apiPath}/BatchReview/GetBatchReviewDataLookups?${Utils.getQueryString(request)}`);
    }

    public removeOrUnremoveBatchReviewAccount(command: BatchReviewDeleteAccountCommand): AxiosPromise<any> {
        return ResponseResolver<boolean>(Axios.post(`${this.config.apiPath}/BatchReview/RemoveOrUnremoveBatchReviewAccount`, command.toJSObject()));
    }

    public removeOrUnremoveBatchReviewContact(command: BatchReviewDeleteContactCommand): AxiosPromise<any> {
        return ResponseResolver<boolean>(Axios.post(`${this.config.apiPath}/BatchReview/RemoveOrUnremoveBatchReviewContact`, command.toJSObject()));
    }

    public archiveOrUnarchiveBatchReview(command: ArchiveBatchReviewCommand): AxiosPromise<any> {
        return ResponseResolver<boolean>(Axios.post(`${this.config.apiPath}/BatchReview/ArchiveOrUnarchiveBatchReview`, command.toJSObject()));
    }

    public SaveBatchAsDraft(batchReviewUploadId : number, command: BatchReviewSaveCommand): AxiosPromise<any>{
         return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.config.apiPath}/BatchReview/SaveBatchAsDraft/${batchReviewUploadId}`, command.toJSObject()));
    }

    public completeBatchReview(batchReviewUploadId : number, command: BatchReviewSaveCommand) : AxiosPromise<{ success: boolean; error: string; id: number}> {
        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.config.apiPath}/BatchReview/CompleteBatchReview/${batchReviewUploadId}`, command.toJSObject()));
    }

    public downloadBatchReview(batchReviewUploadId : number, clientId: number) : AxiosPromise<any> {
        return ResponseResolver<any>(Axios.get(`${this.config.apiPath}/BatchReview/DownloadBatchReview/${clientId}?batchReviewUploadId=${batchReviewUploadId}`));
    }

    public isClientReviewOpen(batchReviewUploadId : number, clientId: number) : AxiosPromise<any> {
        return ResponseResolver<any>(Axios.get(`${this.config.apiPath}/BatchReview/IsBatchReviewOpen/${clientId}?batchReviewUploadId=${batchReviewUploadId}`));
    }

    public getBatchReviewCompletionDetails(clientId: number, batchReviewUploadId : number) : AxiosPromise<BatchReviewCompletionDetailsLookup> {
        return ResponseResolver<BatchReviewCompletionDetailsLookup>(Axios.get(`${this.config.apiPath}/BatchReview/GetBatchReviewCompletionDetails/${clientId}?batchReviewUploadId=${batchReviewUploadId}`));
    }
}

