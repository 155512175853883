import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from "@singularsystems/neo-react";
import ReactPlayer from "react-player";
import * as Icon from "react-feather";
import { textConstants } from 'common/textConstants';
import CopyToClipboard from "react-copy-to-clipboard";
import { setTextForClipboard } from 'common/utils';
import Tabs from 'Views/Onboarding/OnboardingIntroduction/components/Tabs';
import {
    StyledInfoVideoContainer,
    StyledInfoVideoTitle,
    StyledInfoVideoHolder,
    StyledVideoContent,
} from './styles';
import InfoVideoVM from 'Components/InfoVideoVM';

interface IInfoVideoProps {
    viewModel: InfoVideoVM,
}

@observer
export default class InfoVideo extends React.Component<IInfoVideoProps> {

    constructor(props: IInfoVideoProps) {
        super(props);
    }

    public render() {
        const { transcript, about, password, url, header, isPassword, meta } = this.props.viewModel

        const tabs = [textConstants.video.About, textConstants.video.Transcript];
        const tabsContent = [
            about ?? "",
            transcript ?? ""
        ];

        return (
            <StyledInfoVideoContainer>
                <StyledInfoVideoTitle>
                    {header ? header : textConstants.video.VideoTitleFallback}
                </StyledInfoVideoTitle>
                <StyledInfoVideoHolder>
                    {/*The Video*/}
                    <ReactPlayer
                        width="100%"
                        height={"100%"}
                        controls={true}
                        url={url}
                    ></ReactPlayer>
                </StyledInfoVideoHolder>
                {transcript && about &&
                    <StyledVideoContent>
                        <Tabs tabs={tabs} tabsContent={tabsContent} />
                    </StyledVideoContent>
                }

                {isPassword &&
                    <div className="col-6 pl-0 pr-3 pt-3 pb-5">
                        <>
                            <p className="password">{textConstants.generalText.DashboardPasswordLabel}</p>
                            <div className="row m-0">
                                <Neo.FormGroup
                                    display={meta.password}
                                    suppressLabel={true}
                                    disabled={true}
                                    append={
                                        <Neo.Button variant="light">{
                                            <CopyToClipboard text={setTextForClipboard(password)} >
                                                <Icon.Copy size={16} color="#818888" />
                                            </CopyToClipboard>}
                                        </Neo.Button>}
                                />
                            </div>
                        </>
                    </div>
                }
            </StyledInfoVideoContainer>
        );
    }
}