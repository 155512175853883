import React from 'react';
import { Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import DashboardView from '../Home/DashboardView';
import ClientCampaignMessageVM from './ClientCampaignMessageVM';
import ClientCampaignMessageView from './ClientCampaignMessageView';
import ClientCampaignMessageCampaignsVM from './CCMExternalVM';
import CampaignEmailSignatureView from './CampaignEmailSignatureView';
import CampaignEmailSignatureVM from './CampaignEmailSignatureVM';
import CampaignMessageView from './CampaignMessageView';
import { textConstants } from '../../common/textConstants';
import CampaignMessageVM from './CampaignMessageVM';
import AddEditFollowUpTemplateVM from './AddEditFollowUpTemplateVM';
import AddEditFollowUpTemplateView from './AddEditFollowUpTemplateView';

class ClientCampaignMessageCampaignsViewParams {
    public clientId = {}
    public campaignsMode = {} // ClientCampaignMessages AddClientCampaignMessage
    public clientCampaignMessageId = {}
    public clientCampaignMessageMode = {} // Target Market Accounts  EditClientCampaignMessage
}

@observer
export default class ClientCampaignMessageCampaignsView extends Views.ViewBase<ClientCampaignMessageCampaignsVM, ClientCampaignMessageCampaignsViewParams> {

    constructor(props: unknown) {
        super(textConstants.titleText.CampaignMessagesSpaced, ClientCampaignMessageCampaignsVM, props);
    }

    onLeave() {
        this.viewModel.removeNotifications();
        return undefined;
    }

    static params = new ClientCampaignMessageCampaignsViewParams();

    public async componentDidMount() {
        await this.viewModel.getClientId()
        this.viewParams.clientId.value = this.viewModel.clientId
        this.viewParams.campaignsMode.value = "ClientCampaignMessages"
        this.viewModel.customAuthService.globalProps.isZendeskVisible = true;
    }

    public updateBreadcrumb(currentScreenName: string) {
        this.viewParams.campaignsMode.description = currentScreenName;
    }

    public clearClientCampaignMessageInfo() {
        this.viewModel.clientCampaignMessageId = null;
        this.viewModel.clientCampaignMessageName = "";
        this.viewParams.clientCampaignMessageId.value = null;
        this.viewParams.clientCampaignMessageMode.value = null;
    }

    public updateClientCampaignMessageParams(description: string, description1: string) {
        this.viewParams.clientCampaignMessageId.description = description;
        this.viewParams.clientCampaignMessageMode.description = description1;
    }


    public clearCampaignMessageInfo() {
        this.viewModel.campaignMessageName = "";
        this.viewModel.campaignMessageType = "";
    }

    public clearAddFollowUpTemplateInfo() {
        this.viewModel.followUpTemplateName = "";
    }

    public async viewParamsUpdated() {
        const clientCampaignMessageId = this.viewModel.clientCampaignMessageId
        const clientFollowUpTemplateId = this.viewModel.clientFollowUpTemplateId
        let clientId = this.viewParams.clientId.asNullableInt();

        // Fix naviagtion to Campaign Messages via breadcrumb. This also fixes navigation to Campaign Messages via side menu when already in Campaign Messages
        if (clientId === null) {
            await this.viewModel.getClientId()
            this.viewParams.clientId.value = this.viewModel.clientId
            this.viewParams.campaignsMode.value = "ClientCampaignMessages"
            clientId = this.viewModel.clientId
        }

        if (clientId) {

            // Campaign Messages
            if (this.viewParams.campaignsMode.value === "ClientCampaignMessages") {
                // Initialize ViewModel
                this.viewModel.ClientCampaignMessageViewModel = new ClientCampaignMessageVM();
                this.viewModel.ClientCampaignMessageViewModel.taskRunner = this.viewModel.taskRunner;

                // Set values & fetch data
                this.viewModel.ClientCampaignMessageViewModel.clientId = clientId
                this.viewModel.ClientCampaignMessageViewModel.isComingFromFollowUpTemplates = this.viewModel.isComingFromFollowUpTemplates
                this.viewModel.isComingFromFollowUpTemplates = false
                this.viewModel.ClientCampaignMessageViewModel.previousGridPageNumber = this.viewModel.previousClientCampaignMessageGridPage
                await this.viewModel.ClientCampaignMessageViewModel.fetchData(clientId);

                if (this.viewModel.ClientCampaignMessageViewModel.isComingFromFollowUpTemplates) {
                    this.viewModel.ClientCampaignMessageViewModel.isComingFromFollowUpTemplates = false
                }

                this.viewModel.showDashboard = false
                this.viewModel.showClientCampaignMessages = true
                this.viewModel.showCampaignMessage = false
                this.viewModel.showFollowUpTemplate = false

                // Clear the breadcumb
                this.viewParams.clientId.description = ""

                //Check if search criteria has been saved
                if (this.viewModel.savedSearchCriteria) {
                    this.viewModel.ClientCampaignMessageViewModel.hideGrid = true;
                    this.viewModel.ClientCampaignMessageViewModel.searchCriteria.searchString = this.viewModel.savedSearchCriteria.searchString;

                    this.viewModel.ClientCampaignMessageViewModel.pageManager.refreshData();
                    this.viewModel.ClientCampaignMessageViewModel.hideGrid = false;
                }
            }

            // Edit Follow Up Template
            if (clientFollowUpTemplateId !== 0) {
                this.viewModel.clientFollowUpTemplateId = clientFollowUpTemplateId;

                if (this.viewParams.campaignsMode.value === textConstants.generalText.EditFollowUpTemplate) {
                    this.viewModel.addEditFollowUpTemplateViewModel = new AddEditFollowUpTemplateVM();
                    this.viewModel.addEditFollowUpTemplateViewModel.clientFollowUpTemplateId = this.viewModel.clientFollowUpTemplateId;
                    this.viewModel.addEditFollowUpTemplateViewModel.taskRunner = this.taskRunner
                    this.viewModel.addEditFollowUpTemplateViewModel.isComXUser = false;
                    this.viewModel.addEditFollowUpTemplateViewModel.clientId = this.viewModel.clientId;
                    await this.viewModel.addEditFollowUpTemplateViewModel.initialise();

                    this.viewModel.ClientCampaignMessageViewModel.followUpTemplatesVM.populateActionListStatues();
                    this.viewModel.addEditFollowUpTemplateViewModel.actionListStatuses = this.viewModel.ClientCampaignMessageViewModel.followUpTemplatesVM.filterList;

                    const clientName = await this.viewModel.getClientName(clientId);
                    this.viewModel.setClientDisplayName(clientName);
                    this.updateBreadcrumb(textConstants.generalText.AddFollowUpTemplate);
                    this.viewModel.showFollowUpTemplate = true
                    this.viewModel.showClientCampaignMessages = false
                }
            }

            if (clientCampaignMessageId) {

                this.viewModel.clientCampaignMessageId = clientCampaignMessageId;

                // Edit Email Signature
                if (this.viewParams.campaignsMode.value === textConstants.generalText.EditEmailSignature) {
                    this.viewModel.previousClientCampaignMessageGridPage = this.viewModel.ClientCampaignMessageViewModel.pageManager.pageNo

                    this.viewModel.showClientCampaignMessages = false
                    this.viewModel.showDashboard = false

                    // Initialize ViewModel
                    this.viewModel.EditEmailSignatureViewModel = new CampaignEmailSignatureVM();
                    this.viewModel.EditEmailSignatureViewModel.taskRunner = this.viewModel.taskRunner;

                    // Set values & fetch data
                    this.viewModel.EditEmailSignatureViewModel.clientId = clientId;
                    this.viewModel.EditEmailSignatureViewModel.campaignEmailSignatureHelper.clientId = clientId;
                    this.viewModel.EditEmailSignatureViewModel.clientCampaignMessageId = clientCampaignMessageId;
                    this.viewModel.EditEmailSignatureViewModel.clientCampaignMessageStatusId = this.viewModel.clientCampaignMessageStatusId
                    await this.viewModel.EditEmailSignatureViewModel.initialise();

                    const clientName = await this.viewModel.getClientName(clientId);
                    this.viewModel.setClientDisplayName(clientName);
                    this.updateBreadcrumb(textConstants.generalText.EditEmailSignatureSpaced);

                    if (this.viewParams.clientCampaignMessageMode.value === "" || this.viewParams.clientCampaignMessageMode.value === null) {
                        this.viewParams.clientCampaignMessageId.value = null;
                    }
                }

                // Edit Campaign Message
                else if (this.viewParams.campaignsMode.value === textConstants.generalText.EditCampaignMessage) {
                    this.viewModel.previousClientCampaignMessageGridPage = this.viewModel.ClientCampaignMessageViewModel.pageManager.pageNo
                    this.viewModel.campaignMessageViewModel = new CampaignMessageVM();
                    this.viewModel.campaignMessageViewModel.clientCampaignMessageId = this.viewModel.clientCampaignMessageId;
                    this.viewModel.campaignMessageViewModel.taskRunner = this.taskRunner
                    this.viewModel.campaignMessageViewModel.isComXUser = false;
                    this.viewModel.campaignMessageViewModel.clientId = this.viewModel.clientId;
                    await this.viewModel.campaignMessageViewModel.initialise();

                    const clientName = await this.viewModel.getClientName(clientId);
                    this.viewModel.setClientDisplayName(clientName);
                    this.updateBreadcrumb(textConstants.generalText.EditCampaignMessageSpaced);
                    this.viewModel.showCampaignMessage = true
                    this.viewModel.showClientCampaignMessages = false
                }
            }
        }
        else {
            this.viewModel.initialCampaignLoad = true;
            this.viewModel.showClientCampaignMessages = false
            this.viewModel.showDashboard = true
            this.viewModel.showAddEditClientCampaignMessage = false
            this.navigation.navigateToView(DashboardView, { clientId: null });
        }

        // This has been done to avoid clearing the Notification from the AddEditClientCampaignMessage
        if (
            this.viewParams.campaignsMode.value === "ClientCampaignMessages" &&
            this.viewModel.clearAddEditNotifications === false
        ) {
            this.viewModel.clearAddEditNotifications = true;
        }
        else {
            this.viewModel.removeNotifications();
        }
    }

    public openUrl(url: string) {
        if (url === "") {
            this.viewModel.notifications.addDanger("Error", `No url has been specified.`, 5);
        } else {
            window.open(url, '_blank');
        }
    }

    public checkUrlValid(url: string) {
        if (url) {
            if (url.length > 0) {
                return true;
            }
        }
        return false;
    }

    public render() {
        return (
            <React.Fragment>
                {this.viewModel.showClientCampaignMessages === true &&
                    <ClientCampaignMessageView viewModel={this.viewModel.ClientCampaignMessageViewModel}
                        back={() => { this.viewParams.clientId.value = null }}

                        openEditEmailSignature={(clientCampaignMessageId: number) => {
                            this.viewModel.clientCampaignMessageId = clientCampaignMessageId;
                            this.viewModel.savedSearchCriteria = this.viewModel.ClientCampaignMessageViewModel.searchCriteria;
                            this.viewParams.campaignsMode.value = textConstants.generalText.EditEmailSignature;
                        }}

                        openEditCampaignMessage={(clientCampaignMessageId: number) => {
                            this.viewModel.clientCampaignMessageId = clientCampaignMessageId;
                            this.viewModel.savedSearchCriteria = this.viewModel.ClientCampaignMessageViewModel.searchCriteria;
                            this.viewParams.campaignsMode.value = textConstants.generalText.EditCampaignMessage;
                        }}

                        openEditFollowUpTemplate={(clientFollowUpTemplateId: number) => {
                            this.viewModel.clientFollowUpTemplateId = clientFollowUpTemplateId;
                            this.viewModel.savedSearchCriteria = this.viewModel.ClientCampaignMessageViewModel.followUpTemplateSearchCriteria;
                            this.viewParams.campaignsMode.value = textConstants.generalText.EditFollowUpTemplate;
                            this.viewModel.isComingFromFollowUpTemplates = true
                        }}

                        openAddEmailSignature={() => { }}
                        openAddCampaignMessage={() => { }}
                        openAddFollowUpTemplate={() => { }}

                    />
                }

                {
                    this.viewModel.clientCampaignMessageId !== null &&
                    this.viewParams.campaignsMode.value === `EditEmailSignature` &&
                    <CampaignEmailSignatureView viewModel={this.viewModel.EditEmailSignatureViewModel}
                        back={() => {
                            this.clearCampaignMessageInfo();
                            this.viewParams.campaignsMode.value = `ClientCampaignMessages`
                        }
                        }
                    />
                }

                {
                    this.viewModel.showCampaignMessage === true &&
                    this.viewModel.clientCampaignMessageId !== null &&
                    <CampaignMessageView viewModel={this.viewModel.campaignMessageViewModel}
                        back={() => {
                            this.viewModel.showCampaignMessage = false
                            this.clearCampaignMessageInfo();
                            this.viewParams.campaignsMode.value = `ClientCampaignMessages`
                        }}
                    />
                }

                {
                    this.viewModel.showFollowUpTemplate === true &&
                    this.viewModel.clientFollowUpTemplateId !== null &&
                    <AddEditFollowUpTemplateView viewModel={this.viewModel.addEditFollowUpTemplateViewModel}
                        back={() => {
                            this.viewModel.showFollowUpTemplate = false
                            this.clearAddFollowUpTemplateInfo();
                            this.viewParams.campaignsMode.value = `ClientCampaignMessages`
                        }}
                    />
                }

            </React.Fragment>
        );
    }
}