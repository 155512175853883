import React from "react";
import {
  StyledModal,
  StyledModalContent,
  StyledModalClose,
  StyledModalTitle,
} from "./styles";
import CloseIcon from "assets/svgs/Close";

interface IModalProps {
  children: React.ReactNode;
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  title: string;
}

const Modal = ({ children, setIsOpen, isOpen, title }: IModalProps) => {
  return (
    <StyledModal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModalContent>
        <StyledModalTitle id="modal-modal-title">
          {title}{" "}
          <StyledModalClose onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </StyledModalClose>
        </StyledModalTitle>
        {children}
      </StyledModalContent>
    </StyledModal>
  );
};

export default Modal;
