import { ModelBase, NeoModel } from '@singularsystems/neo-core';
import { textConstants } from '../../../common/textConstants';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';

@NeoModel
export class UpdateCampaignMessageNameCommand extends ModelBase {
    public clientCampaignMessageId: number = 0;
  
    @Required(textConstants.messageText.validationMessage.clientCampaignMessageName)
    @Display("Name")
    public campaignName: string = ""

  }