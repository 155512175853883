import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class HelpCenterText extends ModelBase {

    public helpCenterTextId: number = 0;

    public onboardingStepId: number = 0;
    
    @Attributes.Display("Page")
    public sectionCode: string = "";

    public headerText: string = "";

    public bodyText: string = "";

    public aboutText: string = "";

    public transcriptText: string = "";

    public videoURL: string = "";

    public uRL: string = "";

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.bodyText === null || c.bodyText === '', textConstants.messageText.validationMessage.description);
    }

    public toString(): string {
        if (this.isNew || !this.bodyText) {
            return "New onboarding maintenance";
        } else {
            return this.bodyText;
        }
    }
}