import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import DashboardTooltipType from '../Models/Maintenance/DashboardTooltipType';
import { AppService, Types } from '../Services/AppService';

export interface IDashboardTooltipTypeApiClient extends Data.UpdateableApiClient<DashboardTooltipType, number>{

}

@injectable()
export default class DashboardTooltipTypeApiClient extends Data.UpdateableApiClient<DashboardTooltipType, number> implements DashboardTooltipTypeApiClient{

    constructor(config = AppService.get(Types.Config)){
        super(`${config.apiPath}/DashboardTooltipType`);
    }
}