import { NeoModel, List, ModelBase, Attributes, Model } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import { textConstants } from '../../common/textConstants';
import { base64toBlob } from '../../common/utils';
import SaveQuestionnaireAnswersCommand from '../../Models/IdealCustomerProfiles/Commands/SaveQuestionnaireAnswersCommand';
import QuestionnaireAnswerLookup from '../../Models/IdealCustomerProfiles/QuestionnaireAnswerLookup';
import NumberOnlyAnswerType from '../../Models/IdealCustomerProfiles/AnswerTypes/NumberOnlyAnswerType';
import TwoPointSliderAnswerType from '../../Models/IdealCustomerProfiles/AnswerTypes/TwoPointSliderAnswerType';
import TextOnlyAnswerType from '../../Models/IdealCustomerProfiles/AnswerTypes/TextOnlyAnswerType';
import CreatableDropDownAnswerType from '../../Models/IdealCustomerProfiles/AnswerTypes/CreatableDropDownAnswerType';
import MultiCheckBoxWithOtherStringAnswerType from '../../Models/IdealCustomerProfiles/AnswerTypes/MultiCheckBoxWithOtherStringAnswerType';
import OnePointSliderWithCommentAnswerType from '../../Models/IdealCustomerProfiles/AnswerTypes/OnePointSliderWithCommentAnswerType';
import { CustomerQuestionType } from '../../Models/IdealCustomerProfiles/CustomerQuestionType';
import KeyValuePair from '../../Models/IdealCustomerProfiles/AnswerTypes/KeyValuePair';
import BuyingCenterQuery from '../../Models/IdealCustomerProfiles/Queries/BuyingCenterQuery';
import HtmlParser from 'html-react-parser';
import CustomerProfileAnswersLookup from '../../Models/IdealCustomerProfiles/CustomerProfileAnswersLookup';
import InfoVideoVM from "../../Components/InfoVideoVM";
import { triggerHotjarEvent } from '../../Components/Hotjar';
import CustomerProfileQuestionCompleted from './CustomerProfileQuestionCompleted';
import NavigatorStep from 'Models/Navigation/NavigatorStep';
import ICPCompletionLookup from 'Models/IdealCustomerProfiles/ICPCompletionLookup';
import { ICPStatus } from 'Models/Enums/ApplicationEnums';
import OnboardingFunctions from 'Models/Onboarding/OnboardingFunctions';

@NeoModel
class SavedRoles extends ModelBase {

    // Properties
    public value: string = "";
    public label: string = "";
}

@NeoModel
export default class CustomerProfileQuestionnaireVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        private comXConfigApiClient = AppService.get(Types.ApiClients.ComXConfigurationsApiClient),
        private invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient),
        private authService = AppService.get(Types.Neo.Security.AuthenticationService),
        private customerProfilesApiClient = AppService.get(Types.ApiClients.CustomerProfilesApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public customAuthService = AppService.get(Types.Security.CustomAuthenticationService),

    ) {
        super(taskRunner);
    }

    displayName: string = ""
    back: () => void = () => { }

    private onboardingApiClient = AppService.get(
        Types.ApiClients.OnboardingApiClient
    );

    public async initialise() {
        const isClientResponse = await this.invitedUsersApiClient.isClientUser(this.authService!.user!.userName);
        this.isComXUser = !isClientResponse.data
        var customerProfileStatuses = await this.appDataCache.customerProfileStatuses.getDataAsync();

        this.ICPCompleteStatusId = customerProfileStatuses.find(status => status.uniqueTableKey === ICPStatus.Complete)!.customerProfileStatusId
        this.ICPDraftStatusId = customerProfileStatuses.find(status => status.uniqueTableKey === ICPStatus.Draft)!.customerProfileStatusId
        this.questions = this.appDataCache.customerProfileQuestions.get().data

        var buyingCenterQuery = new BuyingCenterQuery()
        buyingCenterQuery.clientId = this.clientId

        if (this.customerProfileId > 0) {
            buyingCenterQuery.customerProfileId = this.customerProfileId
            await this.fetchData()
        }

        const buyingCentersResponse = await this.customerProfilesApiClient.getBuyingCenters(buyingCenterQuery)
        this.unpackBuyingCenters(buyingCentersResponse.data);
        await this.getVideoURLs()
        this.infoVideoVM.content = textConstants.generalText.ICPVideoCardBody;
        this.infoVideoVM.header = textConstants.generalText.ICPVideoCardTitle;
        this.infoVideoVM.url = this.videoURL;

        // Checks if there are no items in this.navigationQuestions.
        if (this.navigationQuestions && this.navigationQuestions.length === 0) {
            this.getQuestions()
        }

        this.isComplete = this.customerProfileStatusId === this.ICPCompleteStatusId

        if (this.isComplete) {
            this.currentSection -= 1;
        }

        await this.updateNavigator();
    }

    // Properties
    public isComXUser: boolean | null = false
    public fileList: any;
    public invalidDataMessage: string = ""
    public showInvalidDataModal: boolean = false
    public fileName: string = ""
    public saveFile: boolean = false
    public saveFileSuccess: boolean = false
    public testText: string = ""
    public testNumber: number = 0
    public showQ2Tooltip: boolean = false
    public isVideoExpand: boolean = false;
    public videoURL = ""
    public clientId: number = 0
    public customerProfileId: number = 0
    public profileName: string = ""
    public customerProfileStatusId: number = 0
    public buyingCenterOptions: any[] = []
    public ICPCompleteStatusId: number = 0
    public ICPDraftStatusId: number = 0
    public initialComparisonValue: string = "";
    public finalComparisonValue: string = "";
    public isSaveComplete: boolean = true;
    public isEdit: boolean = false;
    public infoVideoVM = new InfoVideoVM();
    public isPlaceHolder: boolean = false;
    public currentSection: number = 0;
    public lastSection: number = 19;
    public ICPCompletionList = new List(ICPCompletionLookup);
    public navigatorSteps: NavigatorStep[] = [];
    public isComplete: boolean = false;
    public clientName: string = "";

    public numberOfEmployees: any = null
    public productWorth: any = null

    public oldCustomerProfileData: CustomerProfileAnswersLookup = new CustomerProfileAnswersLookup();

    private numbersOnlyAnswers = [1]
    private textOnlyAnswers = [3, 4, 5, 7, 9, 10, 13, 15, 16, 17, 18, 19, 20, 21, 22, 23]
    private dropDownAnswers = [6, 14]
    private checkBoxAnswers = [8, 11]
    private twoPointSliderAnswers = [2, 12]

    // Questions
    public questions = this.appDataCache.customerProfileQuestions.get().data

    //Save button flag
    public saveClicked: boolean = false;

    // Answers
    public answer1 = new NumberOnlyAnswerType()
    public answer2 = new TwoPointSliderAnswerType()
    public answer3 = new TextOnlyAnswerType()
    public answer4 = new TextOnlyAnswerType()
    public answer5 = new TextOnlyAnswerType()
    // public answer6 = new CreatableDropDownAnswerType()
    public answer6: { value: string, label: string }[] = []
    public answer7 = new TextOnlyAnswerType()
    public answer8 = new MultiCheckBoxWithOtherStringAnswerType()
    @Attributes.Display("No budget")
    public answer8Box1: boolean = false
    @Attributes.Display("No contact to direct decision maker")
    public answer8Box2: boolean = false
    @Attributes.Display("Wrong industry")
    public answer8Box3: boolean = false
    @Attributes.Display("Wrong company size")
    public answer8Box4: boolean = false
    @Attributes.Display("Other")
    public answer8Box5: boolean = false
    public answer9 = new TextOnlyAnswerType()
    public answer10 = new TextOnlyAnswerType()
    public answer11 = new MultiCheckBoxWithOtherStringAnswerType()
    @Attributes.Display("No budget")
    public answer11Box1: boolean = false
    @Attributes.Display("No contact")
    public answer11Box2: boolean = false
    @Attributes.Display("Did not map buying process")
    public answer11Box3: boolean = false
    @Attributes.Display("Bad Target Market fit")
    public answer11Box4: boolean = false
    @Attributes.Display("Other")
    public answer11Box5: boolean = false
    public answer12 = new TwoPointSliderAnswerType()
    public answer13 = new TextOnlyAnswerType()
    public answer14: { value: string, label: string }[] = []
    public answer15 = new TextOnlyAnswerType()
    public answer16 = new TextOnlyAnswerType()
    public answer17 = new TextOnlyAnswerType()
    public answer18 = new TextOnlyAnswerType()
    public answer19 = new TextOnlyAnswerType()
    public answer20 = new TextOnlyAnswerType()
    public answer21 = new TextOnlyAnswerType()
    public answer22 = new TextOnlyAnswerType()
    public answer23 = new TextOnlyAnswerType()

    public navigationQuestions: List<CustomerProfileQuestionCompleted> = new List(CustomerProfileQuestionCompleted)

    // Lookups & Commands
    public saveCommand = new SaveQuestionnaireAnswersCommand()

    public setQuestionText(questionNumber: number) {
        var text = this.questions.find(q => q.maintenanceQuestionNumber === questionNumber)?.questionText
        if (text) {
            return HtmlParser(text)
        } else
            return ""
    }

    public async getVideoURLs() {
        var comXConfig = this.appDataCache.comXConfigurations.get().data;

        if (comXConfig[0] !== undefined) {
            this.videoURL = comXConfig.find((config: any) => config.configCode === textConstants.generalText.ICPVideo)?.value as string
        }
        else {
            comXConfig = (await this.comXConfigApiClient.get()).data
            this.videoURL = comXConfig.find((config: any) => config.configCode === textConstants.generalText.ICPVideo)?.value as string
        }
    }

    public async fetchData() {
        if (this.customerProfileId) {
            let response = await this.taskRunner.waitFor(this.customerProfilesApiClient.getCustomerSpecificProfile(this.clientId, this.customerProfileId));

            if (response.data) {
                let profile = response.data

                this.updateSnapshotICPData(true, undefined, response.data);

                this.profileName = profile.profileName
                this.customerProfileStatusId = profile.customerProfileStatus.customerProfileStatusId

                if (profile.fileName) {
                    this.fileName = profile.fileName
                    this.saveFileSuccess = true
                }

                profile.profileAnswers.forEach(answer => {
                    if (answer.questionType === CustomerQuestionType.TextOnly) {
                        this.MapTextAnswersAnswers(answer)
                    }
                    else if (answer.questionType === CustomerQuestionType.Number) {
                        this.MapNumberAnswer(answer)
                    }
                    else if (answer.questionType === CustomerQuestionType.MultiCheckBoxWithOtherString) {
                        this.MapMultiCheckBoxWithOtherStringAnswer(answer)
                    }
                    else if (answer.questionType === CustomerQuestionType.CreateableDropDown) {
                        this.MapCreatableDropDownAnswer(answer)
                    }
                    else if (answer.questionType === CustomerQuestionType.TwoPointSlider) {
                        this.MapTwoPointSliderAnswer(answer)
                    }
                    else if (answer.questionType === CustomerQuestionType.NumOnePointSliderWithComment) {
                        this.MapOnePointSliderWithCommentAnswer(answer)
                    }
                })

                // Populates the getQuestions using the answers retrieved from backend
                this.getQuestions(profile.profileAnswers)
            }
        } else { this.answer12.maxPointId = 1 }
    }

    public getQuestions(answers: List<QuestionnaireAnswerLookup> = new List(QuestionnaireAnswerLookup)) {
        const questions = this.questions;

        this.navigationQuestions = new List(CustomerProfileQuestionCompleted)

        // Foreach question it creates an object and pushes it to a list
        questions.forEach(question => {

            // Gets object for the question number.
            let isAnswerComplete = answers.find(answer => { return answer.questionNumber === question.questionNumber })

            // Creates an object with specific properties
            let navigationObject = new CustomerProfileQuestionCompleted
            navigationObject.id = question.questionNumber;
            navigationObject.questionNumber = question.questionNumber;
            navigationObject.questionText = question.questionText;

            const answerData = isAnswerComplete?.answerData;

            // Checks if the answerData is empty, if it is not set the questionCompleted to true.
            if (answerData && answerData.answer !== ""
                && answerData.answer !== 0 && (answerData.answer
                    || answerData.minPointId
                    || (answerData.selectedValues && answerData.selectedValues.length > 0)
                    || (answerData.dropDownItems && answerData.dropDownItems.length > 0))) {
                navigationObject.completed = true;
            }
            else {
                navigationObject.completed = false;
            }

            //Pushes the Navigation object onto the global list.
            this.navigationQuestions.push(navigationObject)
        })
    }

    private MapTextAnswersAnswers(answer: QuestionnaireAnswerLookup) {
        let typedAnswer = answer.answerData as Model.PlainObject<TextOnlyAnswerType>

        switch (answer.questionNumber) {

            case 3:
                this.answer3.set(typedAnswer)
                break;

            case 4:
                this.answer4.set(typedAnswer)
                break;

            case 5:
                this.answer5.set(typedAnswer)
                break;

            case 7:
                this.answer7.set(typedAnswer)
                break;

            case 9:
                this.answer9.set(typedAnswer)
                break;

            case 10:
                this.answer10.set(typedAnswer)
                break;

            case 13:
                this.answer13.set(typedAnswer)
                break;

            case 15:
                this.answer15.set(typedAnswer)
                break;

            case 16:
                this.answer16.set(typedAnswer)
                break;

            case 17:
                this.answer17.set(typedAnswer)
                break;

            case 18:
                this.answer18.set(typedAnswer)
                break;

            case 19:
                this.answer19.set(typedAnswer)
                break;

            case 20:
                this.answer20.set(typedAnswer)
                break;

            case 21:
                this.answer21.set(typedAnswer)
                break;

            case 22:
                this.answer22.set(typedAnswer)
                break;

            case 23:
                this.answer23.set(typedAnswer)
                break;
        }
    }

    private MapNumberAnswer(answer: QuestionnaireAnswerLookup) {
        let typedAnswer = answer.answerData as Model.PlainObject<NumberOnlyAnswerType>

        switch (answer.questionNumber) {
            case 1:
                this.answer1.set(typedAnswer)
                break
        }
    }

    private MapCreatableDropDownAnswer(answer: QuestionnaireAnswerLookup) {
        let typedAnswer = answer.answerData as Model.PlainObject<CreatableDropDownAnswerType>

        switch (answer.questionNumber) {
            case 6:
                var result = typedAnswer.dropDownItems.map(element => {
                    var test = { value: String(element.itemId), label: element.value }
                    return test
                });
                this.answer6 = result
                break

            case 14:
                var result = typedAnswer.dropDownItems.map(element => {
                    var test = { value: String(element.itemId), label: element.value }
                    return test
                });
                this.answer14 = result
                break
        }
    }

    private MapOnePointSliderWithCommentAnswer(answer: QuestionnaireAnswerLookup) {
        let typedAnswer = answer.answerData as Model.PlainObject<OnePointSliderWithCommentAnswerType>

        // None at the moment. Could have in the future
    }

    private MapMultiCheckBoxWithOtherStringAnswer(answer: QuestionnaireAnswerLookup) {
        let typedAnswer = answer.answerData as Model.PlainObject<MultiCheckBoxWithOtherStringAnswerType>

        switch (answer.questionNumber) {
            case 8:
                this.answer8.set(typedAnswer)

                this.answer8.selectedValues.forEach(ans => {
                    if (ans.toLocaleUpperCase().includes("No budget".toLocaleUpperCase())) {
                        this.answer8Box1 = true
                    }
                    else if (ans.toLocaleUpperCase().includes("No contact to direct decision maker".toLocaleUpperCase())) {
                        this.answer8Box2 = true
                    }
                    else if (ans.toLocaleUpperCase().includes("Wrong industry".toLocaleUpperCase())) {
                        this.answer8Box3 = true
                    }
                    else if (ans.toLocaleUpperCase().includes("Wrong company size".toLocaleUpperCase())) {
                        this.answer8Box4 = true
                    }
                    else if (ans.toLocaleUpperCase().includes("Other".toLocaleUpperCase())) {
                        this.answer8Box5 = true
                    }
                })
                break

            case 11:
                this.answer11.set(typedAnswer)

                this.answer11.selectedValues.forEach(ans => {
                    if (ans.toLocaleUpperCase().includes("No budget".toLocaleUpperCase())) {
                        this.answer11Box1 = true
                    }
                    else if (ans.toLocaleUpperCase().includes("No contact to direct decision maker".toLocaleUpperCase())) {
                        this.answer11Box2 = true
                    }
                    else if (ans.toLocaleUpperCase().includes("Wrong industry".toLocaleUpperCase())) {
                        this.answer11Box3 = true
                    }
                    else if (ans.toLocaleUpperCase().includes("Wrong company size".toLocaleUpperCase())) {
                        this.answer11Box4 = true
                    }
                    else if (ans.toLocaleUpperCase().includes("Other".toLocaleUpperCase())) {
                        this.answer11Box5 = true
                    }
                })
                break
        }
    }

    private MapTwoPointSliderAnswer(answer: QuestionnaireAnswerLookup) {
        let typedAnswer = answer.answerData as Model.PlainObject<TwoPointSliderAnswerType>

        switch (answer.questionNumber) {
            case 2:
                this.answer2.set(typedAnswer)
                break
            case 12:
                this.answer12.set(typedAnswer)
                break
        }
    }

    public async DelayedAutoSave(recursionCount: number = 0) {
        if (this.isSaveComplete) {
            await this.SaveAnswers(false, () => { });

            return
        }

        // Prevent the continouos requests to this method
        if (recursionCount <= 100) {
            recursionCount++;
            setTimeout(async () => { await this.DelayedAutoSave(recursionCount); }, 20);
        }
        else {
            // Reset the circuit so that new saves can still occur (no infinite recursion)
            this.isSaveComplete = true;
        }
    }

    // Methods
    public async SaveAnswers(isComplete: boolean, navigate: () => void, isDraftSave: boolean = false) {
        this.isSaveComplete = false;

        let isAutoSave = !isComplete && !isDraftSave    //If neither button was clicked then its autosave

        if (!isComplete && !isDraftSave) {
            // This ensures that a published ICP will remain published when auto-saving
            isComplete = this.customerProfileStatusId === this.ICPCompleteStatusId
        }

        if (isComplete) {
            this.runValidationChecks();

            if (this.showInvalidDataModal) {
                this.isSaveComplete = true;
                return
            }
        }

        let shouldSave = this.populateSaveCommand(isComplete, isAutoSave);

        if (!shouldSave) {
            this.isSaveComplete = true;
            return
        }

        if (!isAutoSave) {
            this.taskRunner.run(async () => {
                const saveResponse = await this.customerProfilesApiClient.saveCustomerProfile(this.saveCommand);

                const cr = saveResponse.data as unknown as number;

                if (cr) {
                    this.customerProfileId = cr;

                    this.updateSnapshotICPData(false, this.saveCommand)

                    this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.ICPSaved);

                    if (isDraftSave) {
                        triggerHotjarEvent(textConstants.Events.clientICPDraft)
                    }
                    else {
                        if (this.customAuthService.globalProps.isOnboarding) {
                            await this.customAuthService.onboardingFunctions.updateOnboardingStep();
                        }
                        triggerHotjarEvent(textConstants.Events.clientICPPublish)
                    }

                    // Give time to see message then navigate
                    setTimeout(() => { navigate(); }, 750);
                } else {
                    this.notifications.addDanger(textConstants.titleText.SaveFailed, "");
                }
            });
        }
        else {
            const saveResponse = await this.customerProfilesApiClient.saveCustomerProfile(this.saveCommand);

            const cr = saveResponse.data as unknown as number;

            if (cr) {
                this.customerProfileId = cr
                this.updateSnapshotICPData(false, this.saveCommand)

            } else {
                this.notifications.addDanger(textConstants.titleText.SaveFailed, "");
            }
        }

        this.isSaveComplete = true;
    }

    private updateSnapshotICPData(isFetch: boolean, saveCommand?: SaveQuestionnaireAnswersCommand, fetchProfileData?: CustomerProfileAnswersLookup) {
        if (this.oldCustomerProfileData.customerProfileId === 0) {
            this.oldCustomerProfileData.customerProfileId = isFetch ? fetchProfileData!.customerProfileId : saveCommand!.customerProfileId
        }

        this.oldCustomerProfileData.profileName = isFetch ? fetchProfileData!.profileName : saveCommand!.profileName;

        this.oldCustomerProfileData.customerProfileStatus.customerProfileStatusId = isFetch
            ? fetchProfileData!.customerProfileStatus.customerProfileStatusId
            : saveCommand!.customerProfileStatusId

        for (let i = 1; i <= 23; i++) {
            this.updateSnapShotAnswerData(i, isFetch, saveCommand, fetchProfileData);
        }
    }

    private updateSnapShotAnswerData(answerNumber: number, isFetch: boolean, saveCommand?: SaveQuestionnaireAnswersCommand, fetchProfileData?: CustomerProfileAnswersLookup) {
        let answer = isFetch ? fetchProfileData!.profileAnswers.find(f => f.questionNumber === answerNumber) : saveCommand!.answers.find(f => f.questionNumber === answerNumber);

        if (answer) {
            let saveAnswer = this.mapSnapShotAnswerData(isFetch, answer)

            let existing = this.oldCustomerProfileData.profileAnswers.find(f => f.questionNumber === answerNumber)

            if (!existing) {
                this.oldCustomerProfileData.profileAnswers.push(saveAnswer)
            }
            else {
                this.oldCustomerProfileData.profileAnswers.remove(existing)
                this.oldCustomerProfileData.profileAnswers.push(saveAnswer)
            }
        }
    }

    private mapSnapShotAnswerData(isFetch: boolean, answer: QuestionnaireAnswerLookup) {
        let answerObject: any;

        if (!isFetch) {
            let stringAnswer = JSON.stringify(answer.toJSObject()); // Making object plain string so that no tracking occurs
            answerObject = JSON.parse(stringAnswer);            // Convert back to normal Object
        }
        else {
            answerObject = answer
        }

        // Remap everything
        let resultAnswer = new QuestionnaireAnswerLookup()
        resultAnswer.questionType = answer.questionType
        resultAnswer.questionNumber = answer.questionNumber

        switch (answer.questionType) {
            case CustomerQuestionType.TextOnly:
                resultAnswer.answerData = new TextOnlyAnswerType();
                resultAnswer.answerData.answer = answerObject.answerData.answer;
                break;

            case CustomerQuestionType.Number:
                resultAnswer.answerData = new NumberOnlyAnswerType();
                resultAnswer.answerData.answer = answerObject.answerData.answer;
                break;

            case CustomerQuestionType.NumOnePointSliderWithComment:
                resultAnswer.answerData = new OnePointSliderWithCommentAnswerType();
                resultAnswer.answerData.maxPointId = answerObject.answerData.maxPointId;
                break;

            case CustomerQuestionType.TwoPointSlider:
                resultAnswer.answerData = new TwoPointSliderAnswerType();
                resultAnswer.answerData.maxPointId = answerObject.answerData.maxPointId;
                resultAnswer.answerData.minPointId = answerObject.answerData.minPointId;
                resultAnswer.answerData.comment = answerObject.answerData.comment;
                break;

            case CustomerQuestionType.MultiCheckBoxWithOtherString:
                resultAnswer.answerData = new MultiCheckBoxWithOtherStringAnswerType();
                resultAnswer.answerData.selectedValues = answerObject.answerData.selectedValues;
                resultAnswer.answerData.otherSelected = answerObject.answerData.otherSelected;
                resultAnswer.answerData.otherString = answerObject.answerData.otherString;
                break;

            case CustomerQuestionType.CreateableDropDown:
                resultAnswer.answerData = new CreatableDropDownAnswerType();

                let dropDownData = new List(KeyValuePair)

                answerObject.answerData.dropDownItems.forEach((element: any) => {
                    let data = new KeyValuePair()
                    data.itemId = Number(element.itemId)
                    data.value = element.value
                    dropDownData.push(data)
                });

                resultAnswer.answerData.dropDownItems.set(dropDownData);
                break;
        }

        return resultAnswer;
    }

    public async OnElementBlur() {
        let hasChanged = this.initialComparisonValue !== this.finalComparisonValue;
        if (hasChanged) {
            await this.DelayedAutoSave();
        }

        this.finalComparisonValue = "";
        this.initialComparisonValue = "";
    }

    public async saveAndNavigateBack(isPublish: boolean, navigate: (isPublish?: boolean) => void, recursionCount: number = 0) {
        if (this.isSaveComplete) {
            if (isPublish) {
                await this.SaveAnswers(isPublish, () => navigate(true))
            } else {
                // Draft save 
                await this.SaveAnswers(isPublish, () => navigate(), true)
            }

            return
        }

        if (recursionCount <= 100) {
            setTimeout(async () => { await this.saveAndNavigateBack(isPublish, navigate) }, 20);
        }
        else {
            // Reset the circuit so that new saves can still occur
            this.isSaveComplete = true
        }
    }

    public async navigateBack(navigate: () => void) {

        if (this.isSaveComplete) {
            navigate();
            return
        }
        //The setTimeout is to give the autosave some time to complete before 
        // navigating back to the previos screen that requires that saved name
        setTimeout(async () => { await this.navigateBack(navigate) }, 20);
    }

    //Validation checks for mandatory field messages
    // where 0 is for the Questionnaire Name field
    public validationChecks(questionNumber: number, shouldValidate: boolean | undefined = false) {
        let forceValidation = false

        if (shouldValidate) {
            forceValidation = true
        }

        function checkEmpty(value: any) {
            return value === undefined || value === null || value === "" || value.length === 0
        }

        // only works after save clicked
        if (this.saveClicked || forceValidation) {
            switch (questionNumber) {
                case 0: return checkEmpty(this.profileName);
                case 1.1: return (this.answer1.answer <= 0 || isNaN(this.answer1.answer));
                case 1.2: return (this.answer1.answer <= 30 && !this.saveFileSuccess);
                case 2.1: return (this.answer2.maxPointId <= 1);
                case 2.2: return checkEmpty(this.answer3.answer);
                case 2.3: return checkEmpty(this.answer4.answer);
                case 2.4: return checkEmpty(this.answer5.answer);
                case 2.5: return checkEmpty(this.answer6);
                case 3: return checkEmpty(this.answer7.answer);
                case 4.1: return (!this.answer8Box1 && !this.answer8Box2 && !this.answer8Box3 && !this.answer8Box4 && !this.answer8Box5);
                case 4.2: return (this.answer8Box5 && checkEmpty(this.answer8.otherString));
                case 5: return checkEmpty(this.answer9.answer);
                case 6: return checkEmpty(this.answer10.answer);
                case 7.1: return (!this.answer11Box1 && !this.answer11Box2 && !this.answer11Box3 && !this.answer11Box4 && !this.answer11Box5);
                case 7.2: return (this.answer11Box5 && checkEmpty(this.answer11.otherString));
                case 8: return (this.answer12.maxPointId <= 1);
                case 9: return checkEmpty(this.answer13.answer);
                case 10: return checkEmpty(this.answer14);
                case 11: return checkEmpty(this.answer15.answer);
                case 12: return checkEmpty(this.answer16.answer);
                case 13: return checkEmpty(this.answer17.answer);
                case 14: return checkEmpty(this.answer18.answer);
                case 15: return checkEmpty(this.answer19.answer);
                case 16: return checkEmpty(this.answer20.answer);
                case 17: return checkEmpty(this.answer21.answer);
                case 18: return checkEmpty(this.answer22.answer);
                case 19: return checkEmpty(this.answer23.answer);
                default: return false;
            }
        }
        else return false;
    }

    private runValidationChecks() {
        this.invalidDataMessage = "";
        this.saveClicked = true;

        if (this.validationChecks(0)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify an Ideal Customer Profile name" + "\n"
        }

        if (this.validationChecks(1.1)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify number of customers (Question 2)" + "\n"
        }

        if (this.validationChecks(1.2)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must upload a file (30 Customers or less)" + "\n"
        }

        if (this.validationChecks(2.1)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify a valid range for number of employees (Question 3)" + "\n"
        }

        if (this.validationChecks(2.2)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify the types of industries (Question 3)\n"
        }

        if (this.validationChecks(2.3)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify the customer situation (Question 3)\n"
        }

        if (this.validationChecks(2.4)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify the Key Decision Makers (Question 3)\n"
        }

        if (this.validationChecks(2.5)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify the Buying Centers (Question 3)\n"
        }

        if (this.validationChecks(3)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify a main problem (Question 4)\n"
        }

        if (this.validationChecks(4.1)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify reason why you could not win potential customers (Question 5)\n"
        }

        if (this.validationChecks(4.2)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify reason why you could not win potential customers in the other text box (Question 5)\n"
        }

        if (this.validationChecks(5)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify the kind of customers you are able to close in predictable time (Question 6)\n"
        }

        if (this.validationChecks(6)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify the kind of customers you are never able to close in predictable time (Question 7)\n"
        }

        if (this.validationChecks(7.1)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify the reason for your landmines (Question 8)\n"
        }

        if (this.validationChecks(7.2)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify the reason for your landmines in the other text box (Question 8)\n"
        }

        if (this.validationChecks(8)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify a valid range for product/service worth (Question 9)\n"
        }

        if (this.validationChecks(9)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify customer characteristics (Question 10)\n"
        }

        if (this.validationChecks(10)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify customer stakeholder titles (Question 11)\n"
        }

        if (this.validationChecks(11)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify desired activities of your customer (Question 12)\n"
        }

        if (this.validationChecks(12)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify the roadblocks with your customers (Question 13)\n"
        }

        if (this.validationChecks(13)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify how you solve your customer's problem (Question 14)\n"
        }

        if (this.validationChecks(14)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify your customers' alternatives (Question 15)\n"
        }

        if (this.validationChecks(15)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify your super powers (Question 16)\n"
        }

        if (this.validationChecks(16)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify case studies (Question 17)\n"
        }

        if (this.validationChecks(17)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify the top 3 reasons your customers choose you (Question 18)\n"
        }

        if (this.validationChecks(18)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify the slang you use with customers (Question 19)\n"
        }

        if (this.validationChecks(19)) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = this.invalidDataMessage + "Must specify your key words and buzzwords (Question 20)\n"
        }
    }

    public setEmployeeSize(pointsOnLine: number[]) {
        this.answer2.minPointId = pointsOnLine[0];
        this.answer2.maxPointId = pointsOnLine[1];
    }

    public setProductServiceWorth(pointsOnLine: number[]) {
        this.answer12.minPointId = pointsOnLine[0];
        this.answer12.maxPointId = pointsOnLine[1];
    }

    private populateSaveCommand(isComplete: boolean, isAutoSave: boolean = true) {
        let shouldSave = false;

        // Make a new Object to ensure we dont send duplicate data in answers
        this.saveCommand = new SaveQuestionnaireAnswersCommand();
        this.saveCommand.clientId = this.clientId
        this.saveCommand.customerProfileId = this.customerProfileId
        this.saveCommand.customerProfileStatusId = isComplete ? this.ICPCompleteStatusId : this.ICPDraftStatusId
        this.saveCommand.isAutoSave = isAutoSave

        //---------------------------------------- ICP Name --------------------------------------------------------

        this.saveCommand.profileName = this.profileName

        let currentProfileName = this.oldCustomerProfileData.profileName;

        if (!isAutoSave || currentProfileName === undefined || currentProfileName !== this.profileName) {
            shouldSave = true;
        }

        for (let questionNumber = 1; questionNumber <= 23; questionNumber++) {
            let hasChange = this.pushAnswersDataToSaveCommand(questionNumber, isAutoSave)

            if (hasChange) {
                shouldSave = true
            }
        }

        return shouldSave;
    }

    private pushAnswersDataToSaveCommand(questionNumber: number, isAutoSave: boolean) {

        let shouldSave = false

        let currentAnswer = this.oldCustomerProfileData.profileAnswers.find(f => f.questionNumber === questionNumber);

        //---------------------------------------- Numbers Only -------------------------------------------------------

        let isAnswerType = this.numbersOnlyAnswers.find(f => f === questionNumber)

        if (isAnswerType) {
            shouldSave = this.pushNumberOnlyAnswersToSaveCommand(questionNumber, isAutoSave, currentAnswer)

            return shouldSave
        }

        //---------------------------------------- Text Only -------------------------------------------------------

        isAnswerType = this.textOnlyAnswers.find(f => f === questionNumber)

        if (isAnswerType) {
            shouldSave = this.pushTextOnlyAnswersToSaveCommand(questionNumber, isAutoSave, currentAnswer)

            return shouldSave
        }

        //---------------------------------------- Createable Drop Down ---------------------------------------------------

        isAnswerType = this.dropDownAnswers.find(f => f === questionNumber)

        if (isAnswerType) {
            shouldSave = this.pushDropDownAnswersToSaveCommand(questionNumber, isAutoSave, currentAnswer)

            return shouldSave
        }

        //---------------------------------------- Check Box -------------------------------------------------------

        isAnswerType = this.checkBoxAnswers.find(f => f === questionNumber)

        if (isAnswerType) {
            shouldSave = this.pushCheckBoxAnswersToSaveCommand(questionNumber, isAutoSave, currentAnswer)

            return shouldSave
        }

        //---------------------------------------- Two Point Slider ----------------------------------------------------

        isAnswerType = this.twoPointSliderAnswers.find(f => f === questionNumber)

        if (isAnswerType) {
            shouldSave = this.pushTwoPointSliderAnswerToSaveCommand(questionNumber, isAutoSave, currentAnswer)

            return shouldSave
        }

        return shouldSave;
    }

    private updateSaveList(questionNumber: number, isComplete: boolean) {
        // Finds the index of the questionNumber being saved.
        const index = this.navigationQuestions.findIndex(answer => answer.questionNumber === questionNumber);

        // If index is found
        if (index !== -1) {
            // Get the item from the list
            const item = this.navigationQuestions[index];

            //Remove the item from the list
            this.navigationQuestions.splice(index, 1);

            //Set it to complete
            item.completed = isComplete;

            //Return the item to the list
            this.navigationQuestions.push(item);
        }
    }

    private pushNumberOnlyAnswersToSaveCommand(questionNumber: number, isAutoSave: boolean, currentAnswer: QuestionnaireAnswerLookup | undefined) {
        let shouldSave = false;

        let answer = new QuestionnaireAnswerLookup();
        answer.questionType = CustomerQuestionType.Number;
        answer.questionNumber = questionNumber;

        switch (questionNumber) {
            case 1:

                if (!isAutoSave || !currentAnswer || (currentAnswer.answerData.answer !== this.answer1.answer)) {
                    shouldSave = true;

                    answer.answerData = this.answer1
                    answer.isComplete = !this.validationChecks(1.1, true)
                    this.updateSaveList(questionNumber, answer.isComplete)
                    this.saveCommand.answers.push(answer)
                }
                break;
        }

        return shouldSave;
    }

    private pushTextOnlyAnswersToSaveCommand(questionNumber: number, isAutoSave: boolean, currentAnswer: QuestionnaireAnswerLookup | undefined) {
        let shouldSave = false;

        let answer = new QuestionnaireAnswerLookup()
        answer.questionType = CustomerQuestionType.TextOnly
        answer.questionNumber = questionNumber

        let shouldPush = !isAutoSave || !currentAnswer

        switch (questionNumber) {
            case 3:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer3.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(2.2, true)
                    answer.answerData = this.answer3
                }
                break;

            case 4:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer4.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(2.3, true)
                    answer.answerData = this.answer4
                }
                break;

            case 5:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer5.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(2.4, true)
                    answer.answerData = this.answer5
                }
                break;

            case 7:
                if (!isAutoSave || !currentAnswer
                    || (currentAnswer.answerData.answer !== this.answer7.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(3, true)
                    answer.answerData = this.answer7
                }
                break;

            case 9:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer9.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(5, true)
                    answer.answerData = this.answer9
                }
                break;

            case 10:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer10.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(6, true)
                    answer.answerData = this.answer10
                }
                break;

            case 13:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer13.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(9, true)
                    answer.answerData = this.answer13
                }
                break;

            case 15:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer15.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(11, true)
                    answer.answerData = this.answer15
                }
                break;

            case 16:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer16.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(12, true)
                    answer.answerData = this.answer16
                }
                break;

            case 17:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer17.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(13, true)
                    answer.answerData = this.answer17
                }
                break;

            case 18:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer18.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(14, true)
                    answer.answerData = this.answer18
                }
                break;

            case 19:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer19.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(15, true)
                    answer.answerData = this.answer19
                }
                break;

            case 20:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer20.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(16, true)
                    answer.answerData = this.answer20
                }
                break;

            case 21:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer21.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(17, true)
                    answer.answerData = this.answer21
                }
                break;

            case 22:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer22.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(18, true)
                    answer.answerData = this.answer22
                }
                break;

            case 23:
                if (shouldPush || (currentAnswer!.answerData.answer !== this.answer23.answer)) {
                    shouldSave = true;
                    answer.isComplete = !this.validationChecks(19, true)
                    answer.answerData = this.answer23
                }
                break;
        }

        if (shouldSave) {
            this.updateSaveList(questionNumber, answer.isComplete)
            this.saveCommand.answers.push(answer)
        }

        return shouldSave;
    }

    private pushDropDownAnswersToSaveCommand(questionNumber: number, isAutoSave: boolean, currentAnswer: QuestionnaireAnswerLookup | undefined) {
        let shouldSave = false;

        let answer = new QuestionnaireAnswerLookup()
        answer.questionType = CustomerQuestionType.CreateableDropDown
        answer.questionNumber = questionNumber

        let finalAnswer = new CreatableDropDownAnswerType()

        // 6, 14
        let questionValidationNumber = 0
        switch (questionNumber) {
            case 6:
                questionValidationNumber = 2.5
                finalAnswer = this.mapDropDownAnswers(this.answer6)
                break;

            case 14:
                questionValidationNumber = 10
                finalAnswer = this.mapDropDownAnswers(this.answer14)
                break;
        }

        // If these it is not autosave or there is no answer in the snapshot, then save
        let shouldUpdate = !isAutoSave || !currentAnswer;

        // If neither are true, then we need to compare to see if anything has changed
        if (isAutoSave && currentAnswer) {
            shouldUpdate = this.shouldSaveDropDownAnswers(finalAnswer, currentAnswer);
        }

        if (shouldUpdate) {
            shouldSave = true;
            answer.isComplete = !this.validationChecks(questionValidationNumber, true)
            answer.answerData = finalAnswer

            this.updateSaveList(questionNumber, answer.isComplete)
            this.saveCommand.answers.push(answer)
        }

        return shouldSave
    }

    private pushCheckBoxAnswersToSaveCommand(questionNumber: number, isAutoSave: boolean, currentAnswer: QuestionnaireAnswerLookup | undefined) {
        let shouldSave = false;

        this.mapCheckBoxAnswersForQuestion(questionNumber);

        let answer = new QuestionnaireAnswerLookup()
        answer.questionType = CustomerQuestionType.MultiCheckBoxWithOtherString
        answer.questionNumber = questionNumber

        let shouldPush = !isAutoSave || !currentAnswer

        switch (questionNumber) {
            case 8:
                if (shouldPush || this.shouldSaveCheckBoxAnswers(this.answer8, currentAnswer)) {
                    shouldSave = true;

                    answer.isComplete = this.answer8.selectedValues.length > 0

                    if (this.answer8.otherSelected) {
                        answer.isComplete = !this.validationChecks(4.2, true)
                    }

                    answer.answerData = this.answer8
                }
                break;

            case 11:
                if (shouldPush || this.shouldSaveCheckBoxAnswers(this.answer11, currentAnswer)) {
                    shouldSave = true;

                    answer.isComplete = this.answer11.selectedValues.length > 0

                    if (this.answer11.otherSelected) {
                        answer.isComplete = !this.validationChecks(7.2, true)
                    }

                    answer.answerData = this.answer11
                }
                break;
        }

        if (shouldSave) {
            this.updateSaveList(questionNumber, answer.isComplete)
            this.saveCommand.answers.push(answer)
        }

        return shouldSave;
    }

    private pushTwoPointSliderAnswerToSaveCommand(questionNumber: number, isAutoSave: boolean, currentAnswer: QuestionnaireAnswerLookup | undefined) {
        let shouldSave = false

        let answer = new QuestionnaireAnswerLookup()
        answer.questionType = CustomerQuestionType.TwoPointSlider
        answer.questionNumber = questionNumber

        let shouldPush = !isAutoSave || !currentAnswer

        switch (questionNumber) {
            case 2:
                if (shouldPush
                    || (currentAnswer!.answerData.maxPointId !== this.answer2.maxPointId
                        || currentAnswer!.answerData.minPointId !== this.answer2.minPointId
                        || currentAnswer!.answerData.comment !== this.answer2.comment)) {
                    shouldSave = true;

                    answer.isComplete = !this.validationChecks(2.1, true)
                    answer.answerData = this.answer2
                }
                break;

            case 12:
                if (shouldPush
                    || (currentAnswer!.answerData.maxPointId !== this.answer12.maxPointId
                        || currentAnswer!.answerData.minPointId !== this.answer12.minPointId
                        || currentAnswer!.answerData.comment !== this.answer12.comment)) {
                    shouldSave = true;

                    answer.isComplete = !this.validationChecks(8, true)
                    answer.answerData = this.answer12
                }
                break;
        }

        if (shouldSave) {
            this.updateSaveList(questionNumber, answer.isComplete)
            this.saveCommand.answers.push(answer)
        }

        return shouldSave
    }

    private mapDropDownAnswers(answerData: { value: string, label: string }[]) {

        let finalAnswer = new CreatableDropDownAnswerType()

        if (answerData && answerData.length > 0) {
            let answers = new List(KeyValuePair)

            answerData.forEach((element: any) => {
                let answer = new KeyValuePair()

                answer.itemId = Number(element.value)
                answer.value = element.label

                answers.push(answer)
            });

            finalAnswer.dropDownItems = answers
        }

        return finalAnswer
    }

    private mapCheckBoxAnswersForQuestion(questionNumber: number) {

        switch (questionNumber) {
            case 8:
                this.answer8.selectedValues = []

                if (this.answer8Box1) { this.answer8.selectedValues.push("No budget") }
                if (this.answer8Box2) { this.answer8.selectedValues.push("no contact to direct decision maker") }
                if (this.answer8Box3) { this.answer8.selectedValues.push("Wrong industry") }
                if (this.answer8Box4) { this.answer8.selectedValues.push("Wrong company size") }
                if (this.answer8Box5) { this.answer8.selectedValues.push("Other") }

                this.answer8.otherSelected = this.answer8Box5
                this.answer8.otherString = this.answer8Box5 ? this.answer8.otherString : ""
                break;

            case 11:
                this.answer11.selectedValues = []

                if (this.answer11Box1) { this.answer11.selectedValues.push("No budget") }
                if (this.answer11Box2) { this.answer11.selectedValues.push("no contact to direct decision maker") }
                if (this.answer11Box3) { this.answer11.selectedValues.push("Wrong industry") }
                if (this.answer11Box4) { this.answer11.selectedValues.push("Wrong company size") }
                if (this.answer11Box5) { this.answer11.selectedValues.push("Other") }

                this.answer11.otherSelected = this.answer11Box5
                this.answer11.otherString = this.answer11Box5 ? this.answer11.otherString : ""
                break;
        }
    }


    private shouldSaveDropDownAnswers(finalAnswer: CreatableDropDownAnswerType, currentAnswer: any) {

        let items = currentAnswer.answerData.dropDownItems.map((m: any) => m.value);

        let matched = finalAnswer.dropDownItems.filter(f => items.includes(f.value))

        // If there has been a change in the items then we should update 
        if ((items.length !== finalAnswer.dropDownItems.length) || matched.length !== finalAnswer.dropDownItems.length) {
            return true;
        }

        return false;
    }

    private shouldSaveCheckBoxAnswers(finalAnswer: MultiCheckBoxWithOtherStringAnswerType, currentAnswer: any) {

        let items = currentAnswer.answerData.selectedValues.map((m: any) => m);

        let matched = finalAnswer.selectedValues.filter(f => items.includes(f))

        // If there has been a change in the items then we should update 
        if (items.length !== finalAnswer.selectedValues.length || matched.length !== finalAnswer.selectedValues.length) {
            return true;
        }

        if (finalAnswer.otherSelected !== currentAnswer.answerData.otherSelected) {
            return true;
        }

        if (finalAnswer.otherString !== currentAnswer.answerData.otherString) {
            return true;
        }

        return false;
    }

    public unpackBuyingCenters(centers: List<{ buyingCenterId: number; buyingCenterValue: string }>) {
        centers.forEach(center => {
            const centerToAdd = {
                value: center.buyingCenterId,
                label: center.buyingCenterValue,
            }
            this.buyingCenterOptions.push(centerToAdd);
        });
    }

    public handleFunctionChange(answer: string, selectedItems: any, isMultipleItems: boolean) {

        var canAddItem = this.validateCreatableSelectAddedItem(selectedItems, isMultipleItems);

        if (canAddItem) {
            if (!this[answer].find((item: any) => item.value == selectedItems.value))
                this[answer] = [...this[answer], selectedItems];
        }
    }

    public handleBuyingCenterChange(answer: string, selectedItems: any, isMultipleItems: boolean) {

        var canAddItem = this.validateCreatableSelectAddedItem(selectedItems, isMultipleItems);

        if (canAddItem) {
            this[answer] = selectedItems
        }
    }

    private validateCreatableSelectAddedItem(selectedItems: any, isMultipleItems: boolean) {
        let allowed = false;

        if (selectedItems && (isMultipleItems && selectedItems.length > 0))   // Check if there are items
        {
            var addedItem = selectedItems[selectedItems.length - 1] //Get last item (item added)

            if (addedItem.label.length < 450)    //450 is the table character limit for the column (Cannot exceed this)
            {
                allowed = true
            }
        }
        else {
            allowed = true
        }

        return allowed;
    }

    public uploadCustomerProfileFile(fileList: File[] | FileList) {
        this.taskRunner.run(async () => {
            const saveResponse = await this.customerProfilesApiClient.saveCustomerProfileFile(fileList, this.clientId, this.customerProfileId)

            if (saveResponse.data.success) {
                this.notifications.addSuccess(
                    textConstants.messageText.saveMessage.UploadSuccess,
                    saveResponse.data.message
                );
                this.customerProfileId = saveResponse.data.data

                this.saveFileSuccess = true
            }
            else {
                this.notifications.addDanger(
                    textConstants.titleText.Error,
                    saveResponse.data.message
                );
                this.saveFileSuccess = false
            }
        });
    }

    public downloadFile() {
        this.taskRunner.run(async () => {
            const saveResponse = await this.customerProfilesApiClient.downloadCustomerProfileFile(this.clientId, this.customerProfileId)

            if (saveResponse.data.success) {
                this.notifications.addSuccess(
                    textConstants.messageText.saveMessage.DownloadSuccess,
                    saveResponse.data.message
                );

                if (this.fileName.endsWith(".docx")) {
                    const url = window.URL.createObjectURL(base64toBlob(saveResponse.data.data, "application/vnd.openxmlformats-officedocument.wordprocessingml.document"));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
                else {
                    const url = window.URL.createObjectURL(base64toBlob(saveResponse.data.data, "application/pdf"));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            }
            else {
                this.notifications.addDanger(
                    textConstants.titleText.Error,
                    saveResponse.data.message
                );
                this.saveFileSuccess = false
            }
        });
    }

    public deleteFile() {
        this.taskRunner.run(async () => {
            const saveResponse = await this.customerProfilesApiClient.deleteCustomerProfileFile(this.clientId, this.customerProfileId);

            if (saveResponse) {
                if (saveResponse.data.data) {
                    this.saveFileSuccess = false;
                }
            }
        });
    }

    public couldNotSaveFile() {
        this.notifications.addDanger(
            textConstants.titleText.Error,
            textConstants.messageText.validationMessage.CustomerProfileFileFailed
        );
    }

    public downloadTemplateFile() {
        this.taskRunner.run(async () => {
            const saveResponse = await this.customerProfilesApiClient.download30OrLessTemplateFile(this.clientId);

            if (saveResponse) {
                if (saveResponse.data.data) {
                    this.saveFileSuccess = false;

                    const url = window.URL.createObjectURL(base64toBlob(saveResponse.data.data, "application/vnd.openxmlformats-officedocument.wordprocessingml.document"));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '30_Or_Less_Exercise.docx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            }
        });
    }

    public async updateNavigator() {
        let ICPCompletionListResponse = await this.customerProfilesApiClient.getICPCompletionInformation(this.customerProfileId);
        if (ICPCompletionListResponse.data.success) {
            this.ICPCompletionList.set(ICPCompletionListResponse.data.data)
            this.populateNavigatorSteps()
        }
    }

    public async UpdateNavigatorAfterSave(recursionCount: number = 0) {
        if (this.isSaveComplete) {
            await this.updateNavigator();
            return
        }

        // Prevent the continouos requests to this method
        if (recursionCount <= 100) {
            recursionCount++;
            setTimeout(async () => { await this.UpdateNavigatorAfterSave(recursionCount); }, 500);
        }
        else {
            // Reset the circuit so that new saves can still occur (no infinite recursion)
            this.isSaveComplete = true;
        }
    }

    private setStepIsComplete(stepNumber: number) {
        return this.ICPCompletionList.find(q => q.questionNumber === stepNumber)?.isComplete || false
    }

    private navigationContent(newArray: NavigatorStep[]) {

        const navigationContent = this.questions.map((question) => {
            const y = this.navigationQuestions.find(
                (answer) => answer.questionNumber === question.questionNumber
            );

            const navigationObject: NavigatorStep = {
                id: question.maintenanceQuestionNumber + 1,
                stepNumber: question.maintenanceQuestionNumber + 1,
                stepText: question.questionText,
                isComplete: y ? y.completed : false,
            };

            if (!Number.isInteger(navigationObject.id) && navigationObject.isComplete) {
                newArray.push(navigationObject);
            }

            return navigationObject;
        });

        return navigationContent;
    }

    public populateNavigatorSteps() {
        let newArray: NavigatorStep[] = []

        let navigationContent = this.navigationContent(newArray)

        if (this.isComplete) {
            let newStep: NavigatorStep = {
                id: 0,
                stepNumber: 0,
                stepText: `<h5>${textConstants.titleText.CustomerProfileOverview}</h5>`,
                isComplete: true,
            };

            navigationContent.push(newStep);
        }

        const question3Index = navigationContent.findIndex((x) => x.id === 3);

        if (question3Index !== -1) {
            const question3 = navigationContent[question3Index];
            navigationContent.splice(question3Index, 1);
            question3.isComplete = newArray.length === 5;
            navigationContent.push(question3);
        }

        const filteredQuestions = navigationContent.filter((question) =>
            Number.isInteger(question.id) || question.id === 0
        );

        filteredQuestions.unshift({
            id: 1,
            stepNumber: 1,
            stepText: "<h5>Ideal Customer Profile Name</h5>",
            isComplete: !!this.profileName,
        });

        filteredQuestions.sort((a, b) => a.id - b.id);

        filteredQuestions.forEach(question => {
            question.isComplete = this.setStepIsComplete(question.id) || question.id === 0
        });

        this.navigatorSteps = filteredQuestions;
    }
}