import { NeoModel, ModelBase, List } from "@singularsystems/neo-core";
import BlacklistedDomain from './BlacklistedDomain';

@NeoModel
export default class QuickAddItem extends ModelBase {

    // Properties
    public item: string = "";
}

export class DomainVerificationResult{
    isSuccess: boolean = true
    failMessage: string[] = []
    saveList: List<BlacklistedDomain> = new List(BlacklistedDomain)
}