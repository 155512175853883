import { ModelBase, NeoModel } from '@singularsystems/neo-core';
import { CustomerQuestionType } from './CustomerQuestionType';

@NeoModel
export default class QuestionnaireAnswerLookup extends ModelBase {

    // Properties    
    public questionNumber: number = 0;
    public questionType: CustomerQuestionType = 0;
    public answerData: any;
    public isComplete: boolean = false;
}