// tslint:disable: max-classes-per-file
import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Routing, Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../Services/AppService';

interface IBreadCrumbProps {
    rootItem: Routing.BreadCrumbItem;
}

class BreadCrumbUIItem {

    public label: string;

    constructor(public breadCrumb: Routing.BreadCrumbItem, public isLast?: boolean) {
        this.label = breadCrumb.label instanceof Function ? breadCrumb.label() : breadCrumb.label;
    }
}

@observer
export default class BreadCrumb extends React.Component<IBreadCrumbProps> {

    public render() {

        let items: BreadCrumbUIItem[] = [];
        if (Views.ViewBase.currentView) {

            items = Views.ViewBase.currentView.getBreadCrumbList().map(b => new BreadCrumbUIItem(b));

            if (items.length) {
                items[items.length - 1].isLast = true;
            }
        }

        return (
            <div className="bread-crumb">

                {items.filter(b => b.label).map((item, index) => (

                    <div key={index} className="bread-crumb-level">
                        {index !== 0 && <span className="bread-crumb-seperator">
                            {/* Make ">" bold for the last item only */}
                            {index === items.length - 1 && <b>&gt;</b>}
                            {index !== items.length - 1 && <>&gt;</>}
                        </span>}

                        {/* Hide breadcrumb when user is on root view */}
                        {items.length > 1 && <b><BreadCrumbItem item={item} /></b>}
                    </div>
                ))}

            </div>
        )
    }
}

class BreadCrumbItem extends React.Component<{ item: BreadCrumbUIItem }>{
    protected customAuthService = AppService.get(Types.Security.CustomAuthenticationService);

    public render() {
        const item = this.props.item;
        const breadCrumb = this.props.item.breadCrumb;

        if (item.label === "Industry Sub Industry") {
            item.label = "Industry Sub-Industry"
        }
        else if (item.label === "Invited Users") {
            item.label = "Users"
        }

        return (
            !item.isLast && breadCrumb.link ? <Link to={breadCrumb.link}>{item.label}</Link> :
                !item.isLast && breadCrumb.onClick ? <a href="/" onClick={breadCrumb.onClick}>{item.label}</a> :
                    <span>{item.label}</span>
        )
    }
}