import {ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class OutboundEmailLookup extends ModelBase {

    outboundEmailId : number = 0

    clientId : number = 0;

    emailProviderTypeId : number = 0;

    emailAccountURL : string = "";

    emailAddress : string = "";

    username : string = "";

    password : string = "";

    imapServer : string = "";

    imapPort : number = 0

    smtpServer : string = "";

    smtpPort : number = 0
}