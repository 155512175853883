import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import DomainVariationVM from './DomainVariationVM';
import { observer } from 'mobx-react';
import GoBack from '../../Components/GoBack';
import { textConstants } from '../../common/textConstants';
import * as Icon from 'react-feather';

@observer
export default class DomainVariationView extends Views.ViewBase<DomainVariationVM> {

  constructor(props: unknown) {
    super("Domain Variation", DomainVariationVM, props);
  }

  public render() {
    return (
      <>
        <GoBack />
        <Neo.Card>
          <h2 className='p-24 mt-4'>{textConstants.titleText.DomainVariation}</h2>
          <Neo.Form model={this.viewModel.countryDomainsList} onSubmit={() => this.viewModel.saveDomainVariations()} showSummaryModal>
            <NeoGrid.Grid className="collapsedButton" items={this.viewModel.countryDomainsList}>
              {(country, meta) => (
                <NeoGrid.RowGroup expandProperty={meta.isExpanded}>

                  {/* Country */}
                  <NeoGrid.Row>
                    <NeoGrid.Column
                      display={meta.countryName}
                      label={textConstants.titleText.Country}
                    />
                  </NeoGrid.Row>

                  {/* Domain Variation */}
                  <NeoGrid.ChildRow>
                    <NeoGrid.Grid items={country.domainVariations} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
                      {(variation, variationMeta) => (
                        <NeoGrid.RowGroup>
                          <NeoGrid.Row>
                            <NeoGrid.Column
                              bind={variationMeta.variation}
                              label={textConstants.titleText.DomainVariation}
                            />
                            <NeoGrid.ButtonColumn showDelete
                              deleteButton={{
                                icon: <Icon.X size={24} />,
                                variant: "light",
                                className: "circled"
                              }
                              } />
                          </NeoGrid.Row>
                        </NeoGrid.RowGroup>

                      )}
                    </NeoGrid.Grid>
                  </NeoGrid.ChildRow>
                </NeoGrid.RowGroup>
              )}
            </NeoGrid.Grid>
            <div className="my-4 text-right p-24">
              <Neo.Button className="btn-width-100" isSubmit variant="success">{textConstants.buttonText.Save}</Neo.Button>
            </div>
          </Neo.Form>
        </Neo.Card>
      </>
    );
  }
}