import React from "react";

interface IDashboardPositive {
    positiveCount: number,
    positivePercentage?: number,
}

class DashboardPositive extends React.Component<IDashboardPositive>
{
    render() {
        return (
            <>
                <div className="row positiveGreen">
                    {/* NON-ZERO POSITIVE COUNT */}
                    {this.props.positiveCount > 0 &&
                        <div className="nbr">
                            {this.props.positiveCount}
                        </div>
                    }
                </div>
                {/* ZERO POSITIVE COUNT */}
                {this.props.positiveCount === 0 &&
                    <>
                        {this.props.positiveCount}
                    </>
                }

                {/* (EXPAND) PERCENTAGE */}
                <div className="row">
                    <span className="generalPercentage">
                        {this.props?.positivePercentage}%
                    </span>
                </div>
            </>
        )
    }
}

export default DashboardPositive;