import { NeoModel, Attributes, Validation, List, Data } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import ProspectingLookup from '../../Models/Prospecting/ProspectingLookup';
import { textConstants } from '../../common/textConstants';
import { base64toBlob, getCurrentDateTime } from '../../common/utils';
import LPCLookup from '../../Models/Prospecting/LPCLookup';
import EmployeeSizeRange from '../../Models/Prospecting/EmployeeSizeRange';
import IROLookup from '../../Models/Prospecting/IROLookup';
import IROEditStatus from './../../Models/Maintenance/IROEditStatus';
import CommandResult from '../../Models/InvitedUsers/Commands/CommandResult';
import SubIndustryRangeCriteria from '../../Models/Prospecting/SubIndustryRangeCriteria';
import SubIndustryRange from '../../Models/Prospecting/SubIndustryRange';
import BatchInProgressLookup from '../../Models/Prospecting/BatchInProgressLookup';
import PlatformSearchCriteria from '../../Models/Scraping/PlatformSearchCriteria';
import PlatformSearchLookup from '../../Models/Scraping/PlatformSearchLookup';
import DeletePlatformSearch from '../../Models/Scraping/Commands/DeletePlatformSearch';
import ComXConfiguration from '../../Models/Maintenance/ComXConfiguration';

@NeoModel
export default class ProspectingVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private prospectingApiClient = AppService.get(Types.ApiClients.ProspectingApiClient),
    private comXConfigurationsApiClient = AppService.get(Types.ApiClients.ComXConfigurationsApiClient),
    private targetMarketAccountsApiClient = AppService.get(Types.ApiClients.TargetMarketAccountsApiClient),
    private targetMarketsApiClient = AppService.get(Types.ApiClients.TargetMarketsApiClient),
    private iroEditStatusApiClient = AppService.get(Types.ApiClients.IROEditStatusApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private scrapingApiClient = AppService.get(Types.ApiClients.ScrapingApiClient),
    private config = AppService.get(Types.Config),
    private comXHub = AppService.get(Types.ApiClients.ComXHub),
    public appDataCache = AppService.get(Types.Services.AppDataCache),
    public customAuthService = AppService.get(Types.Security.CustomAuthenticationService)
  ) {
    super(taskRunner);
    this.connectToComXHub()
  }

  public showSpinner: boolean | undefined = true;

  public async initialise() {
    this.taskRunner.run(async () => {
      await this.getConfigDownloadLimit();
    });
  }

  @Attributes.Display(textConstants.titleText.TotalAccountsToProspect)
  public accountsToProspect: number | string = ""
  public prospectingLookup = new ProspectingLookup
  public targetMarketId: number = 0
  @Attributes.Display(textConstants.titleText.LessThan50Batch)
  public batch: string = ""

  public isAB: boolean = true
  public isIRO: boolean = false

  @Attributes.Display(textConstants.titleText.IROAccounts)
  public isABOrIRO: boolean = false

  public fileList: any;
  public fileName: string = "none";

  public prospectedDomainsURL: string = "";
  public prospectedAndRespondedDomainsURL: string = "";
  public clientName: string = "";
  public targetMarketName: string = "";
  @Attributes.Display(textConstants.titleText.TotalAccountsAvailable)
  public numberOfAccounts: number = 0

  public LPCTemplateList = new List(LPCLookup)
  public employeeSizeRangeList = new List(EmployeeSizeRange)
  public IROSearch = new IROLookup()
  public regionWarningMessage: string = ""

  public batchesInProgressLookup = new List(BatchInProgressLookup)

  public IsIROStage: boolean = false;

  @Attributes.Display(textConstants.titleText.IROEditStatus)
  public IROEditStatusId: number | null = 1

  public IROEditStatuses = new List(IROEditStatus)

  public searchCriteria: SubIndustryRangeCriteria = new SubIndustryRangeCriteria();

  public platformSearchCriteria: PlatformSearchCriteria = new PlatformSearchCriteria();

  public showXingUploadModal: boolean = false

  public platformId: number = 0

  public platforms = this.appDataCache.platforms.get().data.sortBy("platformName");

  public showXingSearchDeleteModal: boolean = false

  public selectedSearchName: string = ""

  public deletePlatformSearch = new DeletePlatformSearch();

  public columnHeadings: string[] = [
    ' -Company',
    ' -Country',
    ' -Tags',
    ' -Industry',
    ' -First name',
    ' -Last Names',
    ' -Title',
    ' -Email',
    ' -Snippet 1',
    ' -Snippet 2',
    ' -Snippet 4',
    ' -LinkedIn profile link',
    ' -Office Use - Send Order',
    ' -TM ID',
    ' -Account ID',
    ' -BatchId'
  ];

  public xingColumnHeadings: string[] = [
    ' -ID',
    ' -Cleaned name',
    ' -Name',
    ' -Max contacts to get',
  ];

  public subIndustryPageManager = new Data.PageManager(this.searchCriteria, SubIndustryRange, this.prospectingApiClient.getSubIndustryRanges,
    {
      pageSize: 9999,
      fetchInitial: false
    }
  )

  public xingPageManager = new Data.PageManager(this.platformSearchCriteria, PlatformSearchLookup, this.scrapingApiClient.getXingSearches,
    {
      pageSize: 20,
      fetchInitial: false
    }
  )

  public comXConfigurationList = new List(ComXConfiguration)

  public downloadLimit: number = 1000;

  public updateProspectSearch = (platformSearchLookup: PlatformSearchLookup) => {

    const data = this.xingPageManager.data
    data.forEach(x => {
      if (x.platformSearchId === platformSearchLookup.platformSearchId) {
        x.searchFilename = platformSearchLookup.searchFilename
        x.createdDate = platformSearchLookup.createdDate
        x.createdBy = platformSearchLookup.createdBy
        x.completedAccountsPercentage = platformSearchLookup.completedAccountsPercentage
        x.totalResults = platformSearchLookup.totalResults
        x.totalRequests = platformSearchLookup.totalRequests
        x.requestsPerMinute = platformSearchLookup.requestsPerMinute
        x.requestsPerResult = platformSearchLookup.requestsPerResult
      }
    })
    this.xingPageManager.setItems(data)
  }

  public connectToComXHub() {
    this.comXHub.UpdateSearchStatus.subscribe(this.updateProspectSearch);
  }

  public async fetchTargetMarketSummary() {
    await this.populateTMSummary();

    const iroEditStatusFetch = await this.iroEditStatusApiClient.get();
    this.IROEditStatuses.set(iroEditStatusFetch.data.sortBy("iroEditStatusName"));
  }

  private async populateTMSummary() {
    if (this.targetMarketId > 0) {
      const tMSummary = await this.taskRunner.waitFor(this.targetMarketAccountsApiClient.getTargetMarketSummary(this.targetMarketId));
      this.clientName = tMSummary.data.clientName;
      this.targetMarketName = tMSummary.data.targetMarketName;
      this.IsIROStage = tMSummary.data.isIROStage;
      this.IROEditStatusId = tMSummary.data.iroEditStatusId;
    }
    else {
      this.notifications.addWarning("Warning", "TargetMarketId is not set. Please refresh page.");
    }
  }

  public async fetchBatchesInProgress() {
    const batchesInProgress = await this.taskRunner.waitFor(this.prospectingApiClient.getBatchesInProgress(this.targetMarketId));
    this.batchesInProgressLookup.set(batchesInProgress.data)
  }

  public populateUrls(id: number) {
    this.prospectedDomainsURL = this.config.apiPath + `/Prospecting/GetProspectedDomains/${id}`;
    this.prospectedAndRespondedDomainsURL = this.config.apiPath + `/Prospecting/GetProspectedAndRespondedDomains/${id}`;
  }

  // Methods
  @Attributes.OnChanged<ProspectingVM>((a) => a.toggleTab)
  public selectedTab: string = textConstants.titleText.DownloadProspectList

  private async toggleTab() {
    const selectedValue = String(this.selectedTab);

    // Download Prospect List tab
    if (selectedValue.toLocaleLowerCase() === textConstants.titleText.DownloadProspectList.toLocaleLowerCase()) {
      this.fetchBatchesInProgress()
    }

    // Searches & LPC Template tab
    if (selectedValue.toLocaleLowerCase() === textConstants.titleText.SearchesAndLPC.toLocaleLowerCase()) {
      let response = await this.taskRunner.waitFor(this.prospectingApiClient.getLPCSearch(this.targetMarketId));
      this.LPCTemplateList.set(response.data)
    }

    // IRO Search tab
    if (selectedValue.toLocaleLowerCase() === textConstants.titleText.IROSearch.toLocaleLowerCase()) {
      this.taskRunner.run(async () => {
        let iroSearchResponse = await (this.prospectingApiClient.getIROSearch(this.targetMarketId));
        let employeeSizeResponse = await (this.prospectingApiClient.getEmployeeSizeRanges(this.targetMarketId));
        let regionWarningResponse = await (this.prospectingApiClient.DisplayRegionWarning(this.targetMarketId));

        this.IROSearch.set(iroSearchResponse.data);

        this.employeeSizeRangeList.set(employeeSizeResponse.data)
        this.searchCriteria.targetMarketId = this.targetMarketId
        this.subIndustryPageManager.refreshData();

        if (regionWarningResponse.data === true) {
          this.regionWarningMessage = textConstants.generalText.RegionWarningMessage
        }
      })
    }

    // XING tab
    if (selectedValue.toLocaleLowerCase() === textConstants.titleText.Xing.toLocaleLowerCase()) {
      const platform = this.platforms.find(p => p.platformName.toLocaleLowerCase() === textConstants.titleText.Xing.toLocaleLowerCase())

      if (platform) {
        this.platformId = platform.platformId
      }
      this.platformSearchCriteria.targetMarketId = this.targetMarketId
      this.platformSearchCriteria.platformId = this.platformId
      this.xingPageManager.refreshData();
    }
  }

  public addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toggleIsABOrIRO() {
    if (this.isABOrIRO === false) {
      this.isAB = true;
      this.isIRO = !this.isAB;
    }
    else {
      this.isIRO = true;
      this.isAB = !this.isIRO;
    }
  }

  public setUploadHeader() {
    if (this.isAB) {
      return textConstants.titleText.ABProspectedAccounts
    } else {
      return textConstants.titleText.IROAccounts
    }
  }

  public downloadTemplate() {
    this.taskRunner.run(async () => {
      const response = await this.prospectingApiClient.getTemplate(this.isAB, this.isIRO);
      const url = window.URL.createObjectURL(base64toBlob(response.data.fileName, response.data.contentType));
      const link = document.createElement('a');
      link.href = url;

      let filename = "";
      if (this.isAB) {
        filename = "AB_Upload_Template_" + getCurrentDateTime() + ".xlsx";
      }
      else {
        filename = "IRO_Upload_Template_" + getCurrentDateTime() + ".xlsx";
      }

      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
  }

  public downloadXingTemplate() {
    this.taskRunner.run(async () => {
      const response = await this.scrapingApiClient.getXingTemplate();

      const url = window.URL.createObjectURL(base64toBlob(response.data, "xlsx"));
      const link = document.createElement('a');
      link.href = url;

      const datetime = getCurrentDateTime();

      link.setAttribute('download', 'Xing_Template_' + datetime + '.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
  }


  public documentUpload(uploadType: string) {
    switch (uploadType) {

      case "ABIRO":
        this.taskRunner.run(async () => {
          const response = await this.prospectingApiClient.uploadDocument(this.fileList, this.isAB, this.isIRO, this.targetMarketId);

          if (response.data.success) {
            this.notifications.addSuccess(textConstants.titleText.Prospecting, textConstants.messageText.saveMessage.UploadSuccess);
          }
          else {
            this.notifications.addDanger(textConstants.titleText.Error, response.data.message);

            // Return Invalid Industries file if need be
            if (response.data.data !== null) {
              const url = window.URL.createObjectURL(
                base64toBlob(response.data.data, "text/csv")
              );

              const link = document.createElement("a");

              link.href = url;

              link.setAttribute(
                "download",
                "IRO_Invalid_Industries_" + this.clientName + '_' + this.targetMarketName + "_" + getCurrentDateTime() + ".csv"
              );

              document.body.appendChild(link);

              link.click();
              link.remove();
            }
          }
        });
        break;
      case "Xing":
        this.taskRunner.run(async () => {
          var response = await this.scrapingApiClient.uploadXingDocument(this.fileList, this.targetMarketId, this.platformId);

          const cr: CommandResult = response.data as CommandResult;
          if (!cr.success) {
            this.notifications.addDanger(
              textConstants.titleText.Error,
              cr.error
            )
          } else {
            this.xingPageManager.refreshData();
          }
          this.showXingUploadModal = false
        });
        break;
    }
  }

  public downloadAccountsToProspect() {
    this.taskRunner.run(async () => {
      const accountsToProspect = await this.prospectingApiClient.downloadAccounts(this.downloadLimit, this.targetMarketId);

      this.setBatch(accountsToProspect.data.item1);

      if (accountsToProspect.data.item3 !== "") {
        this.notifications.addSuccess(textConstants.titleText.Prospecting, accountsToProspect.data.item3);
      }

      const url = window.URL.createObjectURL(base64toBlob(accountsToProspect.data.item2, "text/csv"));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Prospecting_' + this.clientName + '_' + this.targetMarketName + '_' + getCurrentDateTime() + '.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Update Batches in Progress
      this.fetchBatchesInProgress()
    });
  }

  public reDownloadAccountsToProspect(userId: number) {
    this.taskRunner.run(async () => {
      // Update Batches in Progress
      this.fetchBatchesInProgress()

      const accountsToProspect = await this.prospectingApiClient.reDownloadAccounts(userId, this.targetMarketId);

      if (accountsToProspect.data.item3 !== "") {
        this.notifications.addSuccess(textConstants.titleText.Prospecting, textConstants.messageText.saveMessage.DownloadSuccess);
      }

      const url = window.URL.createObjectURL(base64toBlob(accountsToProspect.data.item2, "text/csv"));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Prospecting_' + this.clientName + '_' + this.targetMarketName + '_' + getCurrentDateTime() + '.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  public setBatch(value: string) {
    this.batch = value;
  }

  public async saveIROEditStatus() {
    if (this.targetMarketId > 0) {
      const editToDo = this.IROEditStatuses.find((c) => c.iroEditStatusName === "Edit to-do");

      if (this.IROEditStatusId === editToDo!.iroEditStatusId) {
        // Cannot set the IROEditStatusId to busy editing, if this is done set it to the value in the database
        const tMSummary = await this.taskRunner.waitFor(this.targetMarketAccountsApiClient.getTargetMarketSummary(this.targetMarketId));
        this.IROEditStatusId = tMSummary.data.iroEditStatusId;
      }
      else {
        this.taskRunner.run(async () => {
          const response = await this.prospectingApiClient.saveIROEditStatus(this.targetMarketId, this.IROEditStatusId, this.IsIROStage);
          if (response.data.success) {
            this.notifications.addSuccess(textConstants.titleText.Prospecting, textConstants.messageText.saveMessage.IROEditStatusSuccess);
          }
          else {
            this.notifications.addDanger(textConstants.titleText.Error, response.data.message);
          }
          this.doTargetMarketSnapshot();
        });
      }
    }
    else {
      this.notifications.addWarning("Warning", "TargetMarketId is not set. Please refresh page.");
    }
  }

  public saveIsIROStage() {

    this.taskRunner.run(async () => {

      const response = await this.prospectingApiClient.saveIsIROStage(this.targetMarketId, this.IROEditStatusId, this.IsIROStage);

      if (response.data.success) {
        this.notifications.addSuccess(textConstants.titleText.Prospecting, textConstants.messageText.saveMessage.IROStageSuccess);
      }
      else {
        this.notifications.addDanger(textConstants.titleText.Error, response.data.message);
      }
    })
  }

  public async doTargetMarketSnapshot() {
    if (this.IROEditStatusId === this.IROEditStatuses.find((c) => c.iroEditStatusName === "No edits")!.iroEditStatusId) {
      const snpashotResponse = await this.targetMarketsApiClient.createTargetMarketSnapshot(this.targetMarketId)
      const cr: CommandResult = snpashotResponse.data as CommandResult;
      if (cr.success) {
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.SnapshotSaved);
        this.prospectingApiClient.getEmployeeSizeRanges(this.targetMarketId).then(response => this.employeeSizeRangeList.set(response.data))
        this.subIndustryPageManager.refreshData();
      } else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
      }
    }
  }

  public downloadLPCSearch() {

    this.taskRunner.run(async () => {
      const response = await this.prospectingApiClient.downloadLPCSearch(this.targetMarketId);

      const url = window.URL.createObjectURL(base64toBlob(response.data, "text/csv"));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'LPCDownload_' + this.clientName + '_' + this.targetMarketName + '_' + getCurrentDateTime() + '.csv');
      document.body.appendChild(link);
      link.click();
    })

  }

  public downloadUploadProspectTemplate() {
    this.taskRunner.run(async () => {
      const response = await this.prospectingApiClient.DownloadUploadProspectTemplate();

      const url = window.URL.createObjectURL(base64toBlob(response.data, "text/csv"));
      const link = document.createElement("a");
      link.href = url;
      const datetime = getCurrentDateTime();
      link.setAttribute("download", "Upload Prospect Template_" + datetime + ".csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  public uploadProspectFile(fileList: File[] | FileList) {
    this.taskRunner.run(async () => {
      const saveResponse = await this.prospectingApiClient.UploadProspectFile(
        fileList,
        this.targetMarketId
      );

      if (saveResponse.data.success) {
        this.notifications.addSuccess(
          textConstants.titleText.UploadProspects,
          saveResponse.data.message
        );
      }
      else if (!saveResponse.data.success && saveResponse.data.data !== null) {
        this.notifications.addDanger(
          textConstants.titleText.Error,
          saveResponse.data.message
        );

        const url = window.URL.createObjectURL(
          base64toBlob(saveResponse.data.data, "text/csv")
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Prospects_Invalid_Upload_" +
          this.clientName +
          "_" +
          getCurrentDateTime() +
          ".csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      else {
        this.notifications.addDanger(
          textConstants.titleText.Error,
          saveResponse.data.message
        );
      }
    });
  }

  public openUploadModal() {
    this.showXingUploadModal = true
  }

  public openPlatformDeleteModal(platformSearch: PlatformSearchLookup) {
    this.deletePlatformSearch = new DeletePlatformSearch();
    this.deletePlatformSearch.platformSearchId = platformSearch.platformSearchId

    this.selectedSearchName = platformSearch.searchFilename
    // Show the modal
    this.showXingSearchDeleteModal = true;
  }

  public async getConfigDownloadLimit() {
    var downloadLimitCache = this.appDataCache.comXConfigurations.get().data;
    let limitBody;
    let configurationCode = textConstants.generalText.ProspectingAccountDownloadLimit;
    if (downloadLimitCache[0]) {
      limitBody = downloadLimitCache.find((limit: any) => limit.configCode === configurationCode)
    } else {
      const comXConfigurationsResponse = await this.comXConfigurationsApiClient.get();
      this.comXConfigurationList.set(comXConfigurationsResponse.data);
      if (this.comXConfigurationList !== null) {
        limitBody = this.comXConfigurationList.find((config: any) => config.configCode === configurationCode)
      }
    }
    this.downloadLimit = Number(limitBody?.value);
  }

  public deleteSearch() {
    this.showXingSearchDeleteModal = false;
    this.taskRunner.run(async () => {
      const response = await this.scrapingApiClient.deletePlatformSearch(this.deletePlatformSearch);
      const cr: CommandResult = response.data as CommandResult;
      if (cr.success) {
        this.notifications.addSuccess(
          textConstants.titleText.Saved,
          textConstants.messageText.saveMessage.XingSaved
        );
        this.xingPageManager.refreshData();
      }
    });
  }

  public downloadPlatformSearchResults(platformSearch: PlatformSearchLookup) {
    this.taskRunner.run(async () => {
      const response = await this.scrapingApiClient.downloadResults(platformSearch.platformSearchId);

      const url = window.URL.createObjectURL(base64toBlob(response.data, "text/csv"));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Xing Search Results_' + getCurrentDateTime() + '.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    })

  }

  public stopSearch(platformSearchId: number) {
    this.taskRunner.run(async () => {
      const response = await this.scrapingApiClient.stopSearch(platformSearchId);
      const cr: CommandResult = response.data as CommandResult;
      if (cr.success) {
        this.notifications.addSuccess(
          textConstants.titleText.StopSearch,
          textConstants.messageText.saveMessage.StoppingXingSearch,
          10
        );
      }
    })

  }
}