import styled from 'styled-components';



export const StyledContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
`;

export const StyledEmailCard = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background: #FFF;
`; 

export const StyledEmailCardHeading = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #0B0B0C;
`;

export const StyledEmailCardText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #0B0B0C;
  width: 80%;
  line-height: normal;
`;

export const StyledEmailContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 8px;
`;

export const StyledEmailContentButton = styled.div`
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #002648;
  color: #ffffff;
  font-size: 14px;
  align-self: stretch;
  cursor: pointer;
`;

export const StyledEmailList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 8px;
`;

export const StyledEmailListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;


export const StyledLabel = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #818888;
  margin-bottom: -8px;
  text-transform: uppercase;
`;

export const StyledInputRead = styled.div`
  display: flex;
  height: 48px;
  padding: 8px 16px;
  align-items: center;
  border-radius: 8px;
  background: #F8F8F8;
  font-size: 14px;
`;

export const StyledProvderActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
`;

export const StyledIconHolder = styled.div`
  text-align: center;
  width: 48px;
  height: 48px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #F8F8F8;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  scale: (1);

  &:hover {
    transform: scale(1.10);
  }

  svg {
    height: 24px;
    width: 24px;
    margin-bottom:0px;
    stroke: #002648;
  }
`;

export const StyledModalFormWrapper = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-end;
  gap: 64px;
  border-radius: 16px;
  background:#FFF;
`;

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`;

export const StyledFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  color: #0B0B0C;
`

export const StyledFormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
  position: relative;

  label {
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    color: #0B0B0C;
  }

  input {
    border-radius: 8px;
    padding: 16px;
    background: #F8F8F8;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 0;
    width: 100%;
    
    placeholder {
      color: #818888;
    }
  }
`;

export const InputIcon = styled.div`
  position: absolute;
  top: 43px;
  z-index: 99;
  right: 20px;
`;