import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NotifyUtils } from '@singularsystems/neo-core';
import { Neo, Views } from '@singularsystems/neo-react';
import { Observer, observer } from 'mobx-react';
import './Styles/App.scss';
import Sidebar from './Components/Sidebar';
import AppLayout, { MenuState } from './Components/AppLayout';
import HeaderPanel from './Components/HeaderPanel';
import RouteView from './Components/RouteView';
import { AppService, Types } from './Services/AppService';
import OnboardingSideMenu from './Components/OnboardingSideMenu';
import Hotjar from './Components/Hotjar';
import OnboardingHelpCentre from 'Components/OnboardingHelpCentre';
import ZendeskLoader from 'Components/ZendeskLoader';
import ZendeskLauncher from 'Components/ZendeskLauncher/ZendeskLauncher';
@observer
export default class App extends React.Component {
 
  constructor(props: any) {
    super(props)
  }
  private config = AppService.get(Types.Config)

  protected customAuthService = AppService.get(Types.Security.CustomAuthenticationService);

  public showRightPanel() {
    if (this.customAuthService.globalProps.isOnboarding
      && !this.customAuthService.globalProps.showOnboardingSideMenu) {
      return `app-right-panel pl-0`
    }
    else if (this.customAuthService.globalProps.showOnboardingSideMenu) {
      return `onboarding app-right-panel onboarding-content-shift`
    }
    else if (this.customAuthService.globalProps.showActionListLeadMessageSideMenu) {
      return "app-right-panel pl-0 pt-0"
    }
    else {
      return "app-right-panel"
    }
  }

  public render() {
    const layout = AppLayout.current;

    return (

      <BrowserRouter>
      <main>
        <div className={"app-container" + (layout.menuState === MenuState.Collapsed ? " mini-nav " : "")}>

          {/* Left menu panel */}
          {!this.customAuthService.globalProps.isOnboarding && !this.customAuthService.globalProps.showActionListLeadMessageSideMenu && this.customAuthService.globalProps.showSidemenu &&
            <Sidebar />
          }

          {/* Left menu panel Onboarding*/}
          {this.customAuthService.globalProps.showOnboardingSideMenu && !this.customAuthService.globalProps.showActionListLeadMessageSideMenu &&
            <OnboardingSideMenu />
          }

          {/* Top header panel */}
          <HeaderPanel />
          <div className={"responsiveOverlay " + (layout.menuVisible ? "show" : "")} onClick={(e) => layout.showMenu(e)}></div>

          {this.customAuthService.isAuthenticated &&
            <Hotjar />
          }

          {/* Right content panel */}
          <div className={this.showRightPanel()}>
            {this.customAuthService.onboardingFunctions.isOnboardingHelpCenterVisible() &&
              <OnboardingHelpCentre />
            }
            <Observer>
              {() => (
                <Neo.Loader task={Views.ViewBase.currentView ? Views.ViewBase.currentView.taskRunner : undefined} className="page-loader" showSpinner={false} />
              )}
            </Observer>

            <div className={"app-content-area container-fluid" + (layout.pinHeader ? " pinned" : "")}
              onClick={(event) => {
                this.customAuthService.globalProps.showOnboardingStepsMenu = false
              }}>
              <React.Suspense fallback={<div>Loading...</div>}>
                <RouteView />
              </React.Suspense>
            </div>
          </div>

          <Neo.ModalContainer />
          <Neo.ToastContainer notificationStore={NotifyUtils.store} />
          <Neo.TooltipProvider />

          {/* Global Zendesk Component Not Onboarding */}
          {!this.customAuthService.globalProps.isOnboarding &&
            <ZendeskLoader zendeskIsVisible={this.customAuthService.globalProps.isZendeskVisible} />
          }

          {/* Going to need both since the above may be shifted to the left. */}
          {/* Would prefer to do it in one overall since they are basically the same */}
          {/* This can be altered later */}
          {this.customAuthService.globalProps.isOnboarding &&
            <ZendeskLauncher zendeskIsVisible={this.customAuthService.globalProps.isZendeskVisible} />
          }
        </div>
      </main>
    </BrowserRouter>

    );
  }
}