import React, { useState, useEffect, useRef } from 'react';

export interface IEmailContent {
  emailContent: (emailContent: string) => void;
  setUpdatedContent?: () => void
  value?: string;
  placeholder?: string;
  updatedContent?: string;
}

// The email body of the EmailComponent
const EmailContent = ({ emailContent, setUpdatedContent, value, placeholder, updatedContent }: IEmailContent) => {
  const [textarea, setTextarea] = useState(value ?? "");
  const prevValueRef = useRef<string | undefined>();

  useEffect(() => {
    if (value !== undefined && value !== prevValueRef.current) {
      setTextarea(value);
      prevValueRef.current = value;
    }
  }, [value]);

  useEffect(() => {
    if (updatedContent !== undefined) {
      setTextarea(updatedContent!);
      emailContent(updatedContent!);

      // Checks if there is a function for setUpdatedContent if there is, call it.
      setUpdatedContent && setUpdatedContent();
    }
  }, [updatedContent, emailContent]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textareaContent = event.target.value;
    setTextarea(textareaContent);
    emailContent(textareaContent);
  };

  return (
    <textarea
      id="emailContent"
      onChange={handleChange}
      value={textarea}
      placeholder={placeholder}
    />
  );
};

export default EmailContent;