import React from 'react';
import ChangePasswordVM from './ChangePasswordVM';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import { ValidationDisplayMode } from '@singularsystems/neo-core/dist/Validation/Misc';

interface IChangePasswordProps {
    viewModel: ChangePasswordVM;
}

@observer
export default class ChangePassword extends React.Component<IChangePasswordProps> {

    constructor(props: IChangePasswordProps) {
        super(props);
    }

    componentDidMount() {
        this.clearFields();
    }

    clearFields = () => {
        this.props.viewModel.passwords.newPassword = '';
        this.props.viewModel.passwords.oldPassword = '';
    }

    setPassword = async () => {
        await this.props.viewModel.updatePassword();
        this.close();
    }

    close = () => {
        this.clearFields();
        this.props.viewModel.showChangePassword = false;
    }

    public render() {
        const viewModel = this.props.viewModel;

        return (
            <Neo.Modal 
                title="Change Password" 
                show={viewModel.showChangePassword}
                onClose={() => {this.close()}}
                closeButton={{ isOutline: false}}
                className="hideModalFooter"
                suppressForm={true}>
                    <Neo.Form 
                        model={viewModel.passwords}
                        onSubmit={() => this.setPassword()}
                        validationDisplayMode={ValidationDisplayMode.AfterBlur}
                        showSummaryModal={false}>

                        <Neo.FormGroup
                            input={{ type: "password" }}
                            bind={viewModel.passwords.meta.oldPassword}
                        />
                        <Neo.FormGroup
                            input={{ type: "password" }}
                            bind={viewModel.passwords.meta.newPassword}
                        />

                        <div className="custom-footer p-0">
                            <div className=" row m-0 float-right pt-2 ">
                                <div className="col-md-6 col6ResponsivePosition p-0">
                                    <Neo.Button className="mr-2" text="Submit" variant="primary" isSubmit />
                                </div>
                            </div>
                        </div>
                    </Neo.Form>
            </Neo.Modal>
        );
    }
}