import { NeoModel, ModelBase, List } from '@singularsystems/neo-core';
import OnboardingStepLookup from './OnboardingStepLookup';

@NeoModel
export default class GlobalProps extends ModelBase {

  public isOnboarding: boolean = true;

  public currentStep: number = 0;

  public sectionCode: string = "";

  public canGoNext: boolean = false;

  public clientId: number = 0;

  public isClientUser: boolean = false;

  public isFirstOnboardingLogin: boolean = false;

  public hasSeenTutorial: boolean = false;

  public clientHasSeenTutorial: boolean = false;

  public hasSeenTechnicalIntegration: boolean = false;

  public hasSeenCompleteYourSetup: boolean = false;

  public isOnboardingComplete: boolean = false;

  public showBurgerIcon: boolean = false;

  public showBreadCrumb: boolean = false;

  public onboardingSteps: List<OnboardingStepLookup> = new List(OnboardingStepLookup);

  public showOnboardingSideMenu: boolean = false

  public showOnboardingStepsMenu: boolean = false

  public clientCampaignMessageId: number = 0;

  public clientFollowUpTemplateId: number = 0;

  public isCampaignMessageReady: boolean = false

  public joinSupportCallURL: string = ""

  public joinSupportCallURLOnboarding: string = ""

  public showActionListLeadMessageSideMenu: boolean = false

  public isZendeskVisible: boolean = false;

  public showOnboardingHelpCenter: boolean = false;

  public isMeetingBooked: boolean = false;

  public showSidemenu: boolean = true;
}