import React from 'react';
import { Views, NeoGrid, Neo } from '@singularsystems/neo-react';
import ComXConfigurationVM from './ComXConfigurationVM';
import { observer } from 'mobx-react';
import { textConstants } from './../../common/textConstants';
import GoBack from '../../Components/GoBack';

@observer
export default class ComXConfigurationView extends Views.ViewBase<ComXConfigurationVM> {

	constructor(props: unknown) {
		super("Configuration", ComXConfigurationVM, props);
	}

	onLeave() {
		this.viewModel.removeNotifications();
		return undefined;
	}

	private getItemsByType(type: string) {
		return this.viewModel.comXConfigurationList
			.filter(c => c.valueTypeDescription === type)
			.sortBy(c => c.friendlyDescription)
	}

	public render() {
		let { ConfigurationHeadings, ConfigurationTypes } = textConstants.ComXConfigurationText

		return (
			<>
				<GoBack />
				<Neo.Card>
					<h2 className='p-24 mt-4'>{textConstants.titleText.Configuration}</h2>
					<Neo.Form model={this.viewModel.comXConfigurationList} onSubmit={() => this.viewModel.saveComXConfigurationList()} showSummaryModal>

						{/* STRINGS */}
						<h4 className='p-24 mt-4'>{ConfigurationHeadings.Strings}</h4>
						<NeoGrid.Grid className="mb-5" items={this.getItemsByType(ConfigurationTypes.string)}>
							{(comXConfiguration, comXConfigurationMeta) => (
								<NeoGrid.Row >
									<NeoGrid.Column bind={comXConfigurationMeta.friendlyDescription} />
									<NeoGrid.Column bind={comXConfigurationMeta.value} disabled={!comXConfiguration.isEditable} />
								</NeoGrid.Row>
							)}
						</NeoGrid.Grid>

						{/* NUMBERS */}
						<h4 className='p-24 mt-4'>{ConfigurationHeadings.numbers}</h4>
						<NeoGrid.Grid className="mb-5" items={this.getItemsByType(ConfigurationTypes.number)}>
							{(comXConfiguration, comXConfigurationMeta) => (
								<NeoGrid.Row >
									<NeoGrid.Column bind={comXConfigurationMeta.friendlyDescription} />
									<NeoGrid.Column bind={comXConfigurationMeta.value} disabled={!comXConfiguration.isEditable} />
								</NeoGrid.Row>
							)}
						</NeoGrid.Grid>

						{/* BOOLEANS */}
						<h4 className='p-24 mt-4'>{ConfigurationHeadings.Booleans}</h4>
						<NeoGrid.Grid items={this.getItemsByType(ConfigurationTypes.boolean)}>
							{(comXConfiguration, comXConfigurationMeta) => (
								<NeoGrid.Row >
									<NeoGrid.Column bind={comXConfigurationMeta.friendlyDescription} />
									<NeoGrid.Column bind={comXConfigurationMeta.value} disabled={!comXConfiguration.isEditable} />
								</NeoGrid.Row>
							)}
						</NeoGrid.Grid>

						{/* JSON OBJECTS */}
						<h4 className='p-24 mt-4'>{ConfigurationHeadings.JsonObjects}</h4>
						<h6 className='p-24 mt-4'>{ConfigurationHeadings.JsonObjectsSubHeader}</h6>
						<NeoGrid.Grid items={this.getItemsByType(ConfigurationTypes.jsonObject)}>
							{(comXConfiguration, comXConfigurationMeta) => (
								<NeoGrid.Row >
									<NeoGrid.Column bind={comXConfigurationMeta.friendlyDescription} />
									<NeoGrid.Column bind={comXConfigurationMeta.value} disabled={!comXConfiguration.isEditable} />
								</NeoGrid.Row>
							)}
						</NeoGrid.Grid>

						<div className="my-4 text-right p-24">
							<Neo.Button className="btn-width-100" isSubmit variant="success">{textConstants.buttonText.Save}</Neo.Button>
						</div>
					</Neo.Form>
				</Neo.Card>
			</>
		);
	}
}