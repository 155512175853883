import { observer } from "mobx-react";
import React from "react";
import HtmlParser from "react-html-parser";
import { textConstants } from "../../common/textConstants";
import TermsOfServiceLookup from "Models/Client/Query/TermsOfServiceLookup";

interface ITermsOfServiceProps {
    termsOfServiceLookup: TermsOfServiceLookup
}

@observer
class TermsOfService extends React.Component<ITermsOfServiceProps>{

    constructor(props: ITermsOfServiceProps) {
        super(props);
    }

    public render() {
        return (
            <>
                 <div className='actionPlanChecklist row'>
                    <div className='col-12 p-24'>
                        <h2 className='p-0'>{textConstants.TermsOfService}</h2>
                        <div>
                            <div>
                               {/* Text */}
                                {
                                    HtmlParser(this.props.termsOfServiceLookup.termsOfServiceHtml)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default TermsOfService