import {Attributes, ModelBase, NeoModel, Validation} from '@singularsystems/neo-core';
import { textConstants } from '../../../common/textConstants';

@NeoModel
export default class TextOnlyAnswerType extends ModelBase {
    // Properties
    @Attributes.Display("")
    public answer: string = ""

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.answer === "", textConstants.messageText.validationMessage.thisFieldIsRequired);
    }
}