import React from "react";
import {
    StyledDraggable
} from'./Draggable.styles';

type Props = {
    /**
     * Drag ID.
     */
    id?: number;
  
    /**
     * Dragged Children.
     */
    children: React.ReactNode;
  
    /**
     * Dragging item.
     */
    item?: object;
  
    /**
     * On drag start for the dragging element.
     */
    onDragStart?: (event: any, item: any) => void;
  
    /**
     * On drop dragging element.
     */
    onDrop?: (event: any, item: any) => void;

    onDragEnd?: () => void;

    /**
     * Is dragging.
     * @default false
     * @type {boolean}
     * @memberof Draggable
     **/
    isDragging: boolean;
    isDisabled?: boolean;
  };
  
  const  Draggable = ({
    children,
    id,
    item = {},
    onDragStart = () => {},
    onDrop = () => {},
    onDragEnd = () => {},
    isDragging,
    isDisabled
  }: Props) => {
    const handleDrag = (ev: any) => {
      onDragStart(ev, item);
    };
  
    const handleDrop = (ev: any) => {
      onDrop(ev, item);
    };

    const handleDragEnd = (ev: any) => {
      onDragEnd();
    };
  
    return (
      <StyledDraggable
        isDisabled={isDisabled}
        draggable={true}
        onDragOver={(ev) => ev.preventDefault()}
        onDragStart={handleDrag}
        onDrop={handleDrop}
        onDragEnd={handleDragEnd}
        isDragging={isDragging}
        id={`${id}`}
      >
        {children}
      </StyledDraggable>
    );
  }
  

export default Draggable;