import ApiResult from "Models/ApiResult";
import DashboardCard from "Models/Maintenance/DashboardCard";
import { injectable } from "inversify";
import Axios, { AxiosPromise } from "axios";
import { AppService, Types } from "Services/AppService";
import { Data } from '@singularsystems/neo-core';
import { AppConfig } from "AppConfig";

export interface IDashboardCardsApiClient extends Data.IUpdateableApiClient<DashboardCard, number> {
    uploadImage(file: File[] | FileList, dashboardCardUniqueIdentifier: string): AxiosPromise<ApiResult>
    getImage(dashboardCardUniqueIdentifier: string): AxiosPromise<ApiResult>
    showDashboardNewsToAllUsers(): AxiosPromise<ApiResult>
}

@injectable()
export default class DashboardCardsApiClient extends Data.UpdateableApiClient<DashboardCard, number> implements DashboardCardsApiClient {

    public config: AppConfig | null = null

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/DashboardCards`);
        this.config = config
    }

    public uploadImage(file: File[] | FileList, dashboardCardUniqueIdentifier: string): AxiosPromise<ApiResult> {

        const bodyFormData = new FormData();
        bodyFormData.set('files', file[0]);
        bodyFormData.set('dashboardCardUniqueIdentifier', dashboardCardUniqueIdentifier)

        return (Axios.post(`${this.config?.apiPath}/DashboardCard/UploadImage`, bodyFormData));
    }

    public getImage(dashboardCardUniqueIdentifier: string): AxiosPromise<ApiResult> {
        return Axios.get(`${this.config?.apiPath}/DashboardCard/GetImage/${dashboardCardUniqueIdentifier}`);
    }

    public showDashboardNewsToAllUsers(): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config?.apiPath}/DashboardCard/ShowDashboardNewsToAllUsers`)
    }
}