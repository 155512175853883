import React from 'react';
import { Views, Neo, NeoGrid } from '@singularsystems/neo-react';
import ManagementRoleVM from './ManagementRoleVM';
import { observer } from 'mobx-react';
import GoBack from '../../Components/GoBack';
import { textConstants } from '../../common/textConstants';
import * as Icon from 'react-feather';

@observer
export default class ManagementRoleView extends Views.ViewBase<ManagementRoleVM> {

  constructor(props: unknown) {
    super("Management Role", ManagementRoleVM, props);
  }

  onLeave() {
    this.viewModel.removeNotifications();
    return undefined;
  }

  public render() {
    return (
      <>
        <GoBack />
        <Neo.Card>
          <h2 className='p-24 mt-4'>{textConstants.titleText.ManagementRole}</h2>
          <Neo.Form model={this.viewModel.managementRoleList} onSubmit={() => this.viewModel.saveManagementRoleList()} showSummaryModal>
            <NeoGrid.Grid className="collapsedButton" items={this.viewModel.managementRoleList} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
              {(manRole, meta) => (
                <NeoGrid.RowGroup expandProperty={meta.isExpanded}>

                  {/* Role */}
                  <NeoGrid.Row>
                    {/* Role Name */}
                    <NeoGrid.Column bind={meta.roleName}
                      label={textConstants.titleText.Role}
                    />
                    <NeoGrid.ButtonColumn showDelete
                      deleteButton={{
                        icon: <Icon.X size={24} />,
                        variant: "light",
                        className: "circled"
                      }
                      } />
                  </NeoGrid.Row>

                  {/* SubRole */}
                  <NeoGrid.ChildRow>
                    <NeoGrid.Grid items={manRole.managementSubRoles} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
                      {(subRole, subRoleMeta) => (
                        <NeoGrid.RowGroup expandProperty={subRoleMeta.isExpanded}>
                          <NeoGrid.Row>
                            {/* SubRole */}
                            <NeoGrid.Column bind={subRoleMeta.subRoleName}
                              label={textConstants.titleText.SubRole}
                            />
                            {/* Variation */}
                            <NeoGrid.Column bind={subRoleMeta.variation}
                              label={textConstants.titleText.Variation}
                            />
                            <NeoGrid.ButtonColumn showDelete
                              deleteButton={{
                                icon: <Icon.X size={24} />,
                                variant: "light",
                                className: "circled"
                              }
                              } />
                          </NeoGrid.Row>

                          {/* Localisation */}
                          <NeoGrid.ChildRow>
                            <NeoGrid.Grid items={subRole.managementSubRoleLocalisations} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
                              {(localisation, localisationMeta) => (
                                <NeoGrid.Row>
                                  {/* Localisation */}
                                  <NeoGrid.Column bind={localisationMeta.localisation}
                                    label={textConstants.titleText.Localisation}
                                  />
                                  {/* Language */}
                                  <NeoGrid.Column bind={localisationMeta.languageId}
                                    select={{ displayMember: "languageName", items: this.viewModel.languages }}
                                  />
                                  <NeoGrid.ButtonColumn showDelete
                                    deleteButton={{
                                      icon: <Icon.X size={24} />,
                                      variant: "light",
                                      className: "circled"
                                    }
                                    } />
                                </NeoGrid.Row>
                              )}
                            </NeoGrid.Grid>
                          </NeoGrid.ChildRow>
                        </NeoGrid.RowGroup>

                      )}
                    </NeoGrid.Grid>
                  </NeoGrid.ChildRow>
                </NeoGrid.RowGroup>
              )}
            </NeoGrid.Grid>
            <div className="my-4 text-right p-24">
              <Neo.Button className="btn-width-100" isSubmit variant="success">{textConstants.buttonText.Save}</Neo.Button>
            </div>
          </Neo.Form>
        </Neo.Card>
      </>
    );
  }
}