import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class TargetMarketType extends ModelBase {

    public targetMarketTypeId: number = 0;
    
    @Rules.StringLength(500)
    @Attributes.Display("Target Market Type")
    public targetMarketTypeName: string = "";

    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.targetMarketTypeName === null || c.targetMarketTypeName === '', textConstants.messageText.validationMessage.targetMarketTypeName);
    }

    public toString(): string {
        if (this.isNew || !this.targetMarketTypeName) {
            return "New target market type";
        } else {
            return this.targetMarketTypeName;
        }
    }
}