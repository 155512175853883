import {
  Attributes,
  NeoModel,
} from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';

import { textConstants } from '../../common/textConstants';
import { base64toBlob, getCurrentDateTime } from '../../common/utils';
import ClientLookup from '../../Models/Client/ClientLookup';
import MasterAccountOption
  from '../../Models/MasterAccounts/MasterAccountOption';
import {
  AppService,
  Types,
} from '../../Services/AppService';

@NeoModel
export default class UpdateTargetMarketsVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private masterAccountsApiClient = AppService.get(Types.ApiClients.MasterAccountsApiClient),
    private targetMarketAccountsApiClient = AppService.get(Types.ApiClients.TargetMarketAccountsApiClient),
    private customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
    private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient)) {

    super(taskRunner);
  }

  // Properties
  public columnHeadings: string[] = [textConstants.titleText.Domain];
  public isAccountsResponded: boolean = true;
  public isAccountsToReProspect: boolean = true;
  public isResponded: boolean = true;
  public isReProspected: boolean = false;
  public clientId: number = 0;
  public client: ClientLookup = new ClientLookup();
  public updateType: string = "responded";
  public options: MasterAccountOption = new MasterAccountOption();
  public displayName: string = "";

  @Attributes.OnChanged<UpdateTargetMarketsVM>((a) => a.toggleTab)
  public selectedTab: string = textConstants.titleText.AccountsResponded

  // Methods

  private toggleTab() {
    const selectedValue = String(this.selectedTab);

    if (selectedValue.toLocaleLowerCase() === textConstants.titleText.AccountsResponded.toLocaleLowerCase()) {
      this.updateType = "responded"
      this.options.inValidDomains = false
    } else if (selectedValue.toLocaleLowerCase() === textConstants.titleText.AccountsToReProspect.toLocaleLowerCase()) {
      this.updateType = "reprospected"
      this.options.inValidDomains = false
    } else {
      this.options.inValidDomains = true
      this.options.ClientId = this.client.clientId
    }
  }

  public async fetchClient() {
    const response = await this.clientsApiClient.getClient(this.clientId);
    this.client.set(response.data);
  }

  public downloadTemplate(downloadTemplateColName: string) {
    this.taskRunner.run(async () => {
      const response = await this.targetMarketAccountsApiClient.getUpdateTemplate(downloadTemplateColName);
      const url = window.URL.createObjectURL(base64toBlob(response.data.fileContents, response.data.contentType));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Template_' + getCurrentDateTime() + '.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
  }

  public downloadInvalidDomainsTemplate() {
    this.taskRunner.run(async () => {
      const response = await this.masterAccountsApiClient.getTemplate(this.options);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Template_' + getCurrentDateTime() + '.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
  }

  public async documentUpload(fileList: File[] | FileList, downloadTemplateName: string = "") {
    if (this.options.inValidDomains) {
      this.taskRunner.run(async () => {
        const response = await this.masterAccountsApiClient.uploadDocument(fileList, this.options);
        if (response.data.success) {
          this.notifications.addSuccess(textConstants.titleText.MasterAccount, textConstants.messageText.saveMessage.UploadSuccess);
        }
        else {
          this.notifications.addDanger(textConstants.titleText.Error, response.data.message);
        }
      });
    } else {
      this.taskRunner.run(async () => {
        const response = await this.targetMarketAccountsApiClient.uploadUpdateDocument(fileList, this.clientId, this.updateType, downloadTemplateName);
        if (response.data.success) {
          this.notifications.addSuccess(textConstants.titleText.AddClientList, textConstants.messageText.saveMessage.UploadSuccess);
        }
        else {
          this.notifications.addDanger(textConstants.titleText.Error, response.data.message);
        }
      });
    }
  }
}