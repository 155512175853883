import { ModelBase, NeoModel, Attributes } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class ProspectingLookup extends ModelBase {

  @Attributes.Display(textConstants.titleText.TotalAccountsAvailable)
  public accountsAvailable: number = 123

  @Attributes.Display(textConstants.titleText.AllProspectedDomains)
  public prospectedDomains: string = "https://www.areallycoolwebsite.com";

  @Attributes.Display(textConstants.titleText.IROAndResponded)
  public iROAndRespondedDomains: string = "https://www.thisurlrighthere.com";
}