import { injectable } from 'inversify';
import Axios, { AxiosPromise } from 'axios';
import Types from '../../AuthorisationTypes';
import UserGroup from '../../Models/UserGroup';
import { Misc } from '@singularsystems/neo-core';

export interface IUserGroupsApiClient {
    /**
     * Will get a list of user groups
     */
    getLookupAsync(): AxiosPromise<any>;

    /**
     * Will get the UserGroup together with its Group Memberships and Assigned Roles
     */
    get(userGroupId: number): AxiosPromise<any>;

    /**
     * Will save the UserGroup
     */
    save(userGroup: UserGroup): AxiosPromise<any>;
    
    /**
     * Will delete the UserGroup
     */
    delete(userGroupId: number): AxiosPromise<any>;
}

@injectable()
export default class UserGroupsApiClient implements IUserGroupsApiClient {

    private apiPath: string;

    constructor(config = Misc.Globals.appService.get(Types.Config.ConfigModel)) {
        this.apiPath = `${config.authorisationServerApiPath}/UserGroups`;
    }

    public getLookupAsync() {
        return Axios.get(`${this.apiPath}/Lookup/`);
    }

    public get(userGroupId: number): AxiosPromise<any> {
        return Axios.get(`${this.apiPath}/${userGroupId}`);
    }

    public save(userGroup: UserGroup): AxiosPromise<any> {
        return Axios.post(`${this.apiPath}/Save/`, userGroup.toJSObject());
    }

    public delete(userGroupId: number): AxiosPromise<any> {
        return Axios.delete(`${this.apiPath}/${userGroupId}`);
    }
}
