import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import AccountStatus from '../Models/Maintenance/AccountStatus';
import { AppService, Types } from '../Services/AppService';

export interface IAccountStatusesApiClient extends Data.IUpdateableApiClient<AccountStatus, number> {

}

@injectable()
export default class AccountStatusesApiClient extends Data.UpdateableApiClient<AccountStatus, number> implements IAccountStatusesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/accountStatuses`);
    }
}