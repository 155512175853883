import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class ArchiveBatchReviewCommand extends ModelBase {

    // Properties
    public batchReviewUploadId: number = 0;

    public clientId: number = 0;

    public isDelete: boolean = true;
}