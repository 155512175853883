import { NeoModel, ModelBase} from  '@singularsystems/neo-core';

@NeoModel
export default class OnboardingStepLookup extends ModelBase {

    public stepNumber: number = 0;

    public stepName: string = '';

    public hasSeenStepCompletedPopup: boolean = false;

    public isRequired: boolean = false;

    public isComplete: boolean = false;

    public isUnlocked: boolean = false;
}