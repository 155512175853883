import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter'
import { TypeSortInfo, TypeFilterValue } from '@inovua/reactdatagrid-enterprise/types'

export const createFilterDataColumn = (filterOptionsTest: any, column: IGridFilterColumn) => {
    const columns: any = {
        name: column.name,
        header: column.header,
        defaultFlex: 1,
        defaultLocked: column.defaultLocked,
        filterEditor: SelectFilter,
        minWidth: column.minWidth,
        filterEditorProps: {
            multiple: column.filterKey.isMultiple,
            wrapMultiple: false,
            dataSource: filterOptionsTest && filterOptionsTest[column.filterKey.key].map((c: any) => {
                return { id: c[column.filterKey.id], label: c[column.filterKey.label] }
            })
        },
    }
    return columns
}

export const externalFilterDataColumn = (filterOptionsTest: any, column: IGridFilterColumn) => {
    const columns: any = {
        name: column.name,
        header: column.header,
        defaultFlex: 1,
        filterEditor: SelectFilter,
        filterEditorProps: {
            multiple: column.filterKey.isMultiple,
            wrapMultiple: false,
            dataSource: filterOptionsTest
        },
        minWidth: column.minWidth,
        render: column.render
    }
    return columns
}

export const numberFilterDataColumn = (filterOptionsTest: any, column: IGridFilterColumn) => {
    const columns: any = {
        name: column.name,
        header: column.header,
        defaultFlex: 1,
        filterEditor: NumberFilter,
        filterEditorProps: {
            dataSource: filterOptionsTest
        },
        minWidth: column.minWidth,
        render: column.render
    }
    return columns
}

export interface IGridFilterColumn {
    name: string,
    header: string,
    isFilter: boolean,
    filterKey: IGridFilterDataSorce
    defaultLocked?: boolean | "start" | "end"
    minWidth: any
    render?: ({ value }: any) => JSX.Element
}

export interface IGridFilterDataSorce {
    key: string
    label: string
    id: string
    isMultiple: boolean
    filterData?: [{ id: string, count: any }]
}

export interface GridProps {
    skip: number,
    limit: number,
    sortInfo?: TypeSortInfo,
    currentData: any[],
    filterValue: TypeFilterValue,
    totalRecords?: number

}

export interface GridFilterValue {
    name: string,
    operator: string,
    type: string,
    value: any
}

export interface IGridSortInfo {
    dir: number, id: string, name: string, type: any
}

export interface IsGridCriteria {
    isCoumnFilter: boolean
    notFilter: string[]
}

export const GridManagerConst = {
    filterColumnInd: "isCoumnFilter",
    notinlist: "notinlist",
    notFilter: "notFilter",
    notEqual: "Does not contain",
    columnId: "$id"
}