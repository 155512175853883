import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { textConstants } from '../../../common/textConstants';

@NeoModel
export default class AdvancedEmailSettingsLookup extends ModelBase {
  
    public isFirewall : boolean = false;
  
    public isDKIM : boolean = false;
  
    public isSPF : boolean = false;
  
    public isCNAME : boolean = false;

    public testEmailAddress : string = "";

    public internalEmailTextHeader : string = "";

    public internalEmailText : string = "";

    public firewallTooltip : string = "";

    public woodpeckerIP1 : string = "";

    public woodpeckerIP2 : string = "";

    public dkimTooltip : string = "";

    public spfTooltip : string = "";

    public cnameTooltip : string = "";


    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.isSPF === false, textConstants.messageText.validationMessage.thisFieldIsRequired);
        rules.failWhen(c => c.isCNAME === false, textConstants.messageText.validationMessage.thisFieldIsRequired);
    }
}