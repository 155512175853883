import MasterAccountsVM from '../MasterAccountsVM';
import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
// Grid
import { AppService, Types } from "../../../Services/AppService";
import MasterAccountFilterList from '../../../Models/MasterAccounts/Query/MasterAccountFilterList';
import ComXReactDataGrid from '../../../Components/ReactDataGrid/ComXReactDataGrid';
import { CreateColumns, MasterAccountFilterColumns, helperConst, MasterAccountFilterTypes } from './MasterAccountGridHelper';
import MasterAccountLookup from '../../../Models/MasterAccounts/MasterAccountLookup';
import * as Icon from "react-feather";

interface IMasterAccountDataGridProps {
    viewModel: MasterAccountsVM,
}

interface IMasterAccountDataGridState {
    filterList: MasterAccountFilterList
}
const gridStyle = { minHeight: 550 }



@observer
export default class MasterAccountDataGrid extends React.Component<IMasterAccountDataGridProps, IMasterAccountDataGridState> {
    constructor(props: IMasterAccountDataGridProps) {
        super(props)
        this.state = {
            filterList: new MasterAccountFilterList()
        }
    }
    private config = AppService.get(Types.Config)
    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

    public securityCheck(role: any, openMethod: () => void) {
        if (this.authorisationService.hasRole(role)) {
            openMethod();
        }
        else {
            this.props.viewModel.showForbiddenModal = true;
        }
    }
    public refreshMethod: any
    public GetRefreshMethod = (data: (props: any) => Promise<void>) => {
        this.refreshMethod = data
    }

    public styleFilters(filterData: any) {
        this.styleComboBoxFilters(filterData);

        this.styleTextFilters(filterData);
    }

    private styleComboBoxFilters(filterData: any) {
        if (filterData.filterValue.value !== null && filterData.filterValue.type === "inlistFilerType") {
            let filters = document.getElementsByClassName("inovua-react-toolkit-combo-box__clear-icon");

            for (let x = 0; filters.length > x; x++) {
                document.getElementsByClassName("inovua-react-toolkit-combo-box__clear-icon")[x].parentNode!.parentElement!.style.borderColor = "#FBA174";
            }
        }
        else {
            let filters = document.getElementsByClassName("inovua-react-toolkit-combo-box__tools")

            if (filters !== undefined) {
                for (let x = 0; filters.length > x; x++) {
                    let itemValue = document.getElementsByClassName("inovua-react-toolkit-combo-box__tools")[x]

                    if (itemValue.firstElementChild!.className !== "inovua-react-toolkit-combo-box__clear-icon") {
                        document.getElementsByClassName("inovua-react-toolkit-combo-box__tools")[x].parentElement!.style.borderColor = "#c5c5c5";
                    }
                }
            }
        }
    }

    private styleTextFilters(filterData: any) {
        if (filterData.filterValue.value !== null && filterData.filterValue.value !== '' && filterData.filterValue.type === "textFreeFilter") {
            let filters = document.getElementsByClassName("inovua-react-toolkit-text-input__clear-button")

            for (let x = 0; filters.length > x; x++) {
                let itemValue = document.getElementsByClassName("inovua-react-toolkit-text-input__clear-button")[x]

                if (!itemValue.parentElement!.className.includes("--hidden")) {
                    document.getElementsByClassName("inovua-react-toolkit-text-input__clear-button-wrapper")[x].parentElement!.style.borderColor = "#FBA174";
                }
            }
        }
        else {
            let filters = document.getElementsByClassName("inovua-react-toolkit-text-input__clear-button-wrapper--hidden")

            if (filters !== undefined) {
                for (let x = 0; filters.length > x; x++) {
                    document.getElementsByClassName("inovua-react-toolkit-text-input__clear-button-wrapper--hidden")[x].parentElement!.style.borderColor = "#c5c5c5";
                }
            }
        }
    }

    public render() {
        const rowClassName = ({ data }: any) => {
            return {
                color: '#FFFFFF'
            }
        }

        var columns: any = CreateColumns(this.props.viewModel)
        const filterValue = MasterAccountFilterColumns()
        return (
            <div id={"masterAccountGrid"}>
                <div className="col-sm-12 pt-3 pb-3 pl-0 pr-0">
                    <div className="row m-0">
                        {/* Bulk Delete button */}
                        <div className="pl-2">
                            <Neo.Button variant="light" className="text-center circled bulkAction buttonHover" icon={<Icon.Archive />}
                                onClick={() => this.props.viewModel.OpenBulkActionModal("BulkDelete")}
                                disabled={this.props.viewModel.selectedItemsCount < 1}>
                            </Neo.Button>
                        </div>

                        {/* Bulk Undelete button */}
                        <div className="pl-2">
                            <Neo.Button variant="light" className="text-center circled bulkAction buttonHover"
                                onClick={() => this.props.viewModel.OpenBulkActionModal("BulkUndo")}
                                icon={<Icon.RotateCw />}
                                disabled={this.props.viewModel.selectedItemsCount < 1}>
                            </Neo.Button>
                        </div>
                        {/* Records Selected */}
                        <div className="pl-2 verticalAlignCenter accountSelectTax">
                            {this.props.viewModel.selectedItemsCount} records selected
                        </div>
                    </div>
                </div>
                <ComXReactDataGrid
                    licence={this.config.gridLicense}
                    columns={columns}
                    defaultFilterValue={filterValue}
                    criteria={this.props.viewModel.masterAccountCriteria}

                    idProperty={helperConst.idProperty}
                    style={helperConst.gridStyle}
                    emptyText={helperConst.emptyText}
                    externalSearchStringName={helperConst.searchString}
                    initialPageSize={helperConst.initialPageSize}
                    filterTypes={MasterAccountFilterTypes}
                    pagination
                    checkboxColumn
                    enableFiltering
                    className="ComxDataGrid"
                    cellSelection={{ "2,fixedName": true, "2,dateAdded": true }}
                    selected={this.props.viewModel.selected}
                    modelType={MasterAccountLookup}
                    TFilter={MasterAccountFilterList}
                    onFilterChange={(x) => this.styleFilters(x)}
                    setReactPageGridAndList={async (x) => await this.props.viewModel.setReactPageGridAndList(x)}
                    refreshDataGrid={(data: (props: any) => Promise<void>) => this.props.viewModel.GetRefreshMethod(data)}
                    onSelectionChange={(selectionData, dataSource) => this.props.viewModel.onSelectionChange(selectionData, dataSource)}
                    rowClassName={rowClassName}
                />
            </div>
        );
    }
}

