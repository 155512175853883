import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class SupportLink extends ModelBase {

    public supportLinkId: number = 0;

    public onboardingStepId: number = 0;
    
    public description: string = "";

    public url: string = "";

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.onboardingStepId === null, textConstants.messageText.validationMessage.description);
    }

    public toString(): string {
        if (this.isNew || !this.description) {
            return "Support link";
        } else {
            return this.description;
        }
    }
}