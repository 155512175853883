import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import * as Icon from 'react-feather';

export interface IGridChartSwitch {
    isButton1Selected: boolean,
    toggle: () => void
    button1Icon?: any
    button2Icon?: any
    className?: string
    disabled?: boolean
    children?: React.ReactNode
}

export default class GridChartSwitch extends React.Component<IGridChartSwitch> {

    public render() {

        return (
            <>
                <div className={`gridChartSwitch pull-right ${this.props.className}`}>
                    {/* Button 1 */}
                    <Neo.Button
                        className={`button1Shape ${this.props.isButton1Selected ? "selectedButton" : "unselectedButton"}`}
                        onClick={() => !this.props.isButton1Selected ? this.props.toggle() : ""}
                        disabled = {this.props.disabled}
                        icon=
                        {
                            <div
                                className={`${this.props.isButton1Selected ? "selectedIcon" : "unselectedIcon"}`}>
                                {this.props.button1Icon ? this.props.button1Icon : <Icon.Menu />}
                            </div>
                        }>
                    </Neo.Button>

                    {/* Button 2 */}
                    <Neo.Button
                        className={`button2Shape ${!this.props.isButton1Selected ? "selectedButton" : "unselectedButton"}`}
                        disabled = {this.props.disabled}
                        onClick={() => this.props.isButton1Selected ? this.props.toggle() : ""}
                        icon=
                        {
                            <div
                                className={`${!this.props.isButton1Selected ? "selectedIcon" : "unselectedIcon"}`}>
                                {this.props.button1Icon ? this.props.button2Icon : <Icon.BarChart />}
                            </div>
                        }>
                    </Neo.Button>
                </div>
            </>
        );
    }
}