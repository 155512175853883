import {NeoModel, List} from '@singularsystems/neo-core';
import TargetMarketAccountStatus from './../../Models/Maintenance/TargetMarketAccountStatus';
import { AppService, Types } from '../../Services/AppService';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';

@NeoModel
export default class TargetMarketAccountStatusVM extends Views.ViewModelBase {
    
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private targetMarketAccountStatusesApiClient =  AppService.get(Types.ApiClients.TargetMarketAccountStatusesApiClient)) {

        super(taskRunner);
    }

    // Properties
    public targetMarketAccountStatusList = new List(TargetMarketAccountStatus);

    public async initialise() {
        const response = await this.taskRunner.waitFor(this.targetMarketAccountStatusesApiClient.get());
        this.targetMarketAccountStatusList.set(response.data);
    }

    public saveTargetMarketAccountStatusList(){
        this.taskRunner.run(async () => {
            const response = await this.targetMarketAccountStatusesApiClient.saveList(this.targetMarketAccountStatusList.toJSArray());
            this.targetMarketAccountStatusList.update(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved,textConstants.messageText.saveMessage.TargetMarketAccountStatusesSaved);
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}