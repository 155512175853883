import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class OnboardingMaintenance extends ModelBase {

    public onboardingMaintenanceId: number = 0;
    
    @Rules.StringLength(500)
    public pageCode: string = "";
    
    @Attributes.Display("Page")
    public friendlyDescription: string = "";

    public headerText: string = "";

    public bodyText: string = "";

    public preambleText: string = "";

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.friendlyDescription === null || c.friendlyDescription === '', textConstants.messageText.validationMessage.description);
    }

    public toString(): string {
        if (this.isNew || !this.friendlyDescription) {
            return "New onboarding maintenance";
        } else {
            return this.friendlyDescription;
        }
    }
}