import React from 'react';
import { Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import TargetMarketCampaignsVM from './TargetMarketExternalVM';
import TargetMarketVM from './TargetMarketVM';
import TargetMarketView from './TargetMarketView';
import AddEditTargetMarketVM from './AddEditTargetMarketVM';
import AddEditTargetMarketView from './AddEditTargetMarketView';
import TargetMarketAccountsVM from '../TargetMarketAccounts/TargetMarketAccountsVM';
import TargetMarketAccountsView from '../TargetMarketAccounts/TargetMarketAccountsView';
import DashboardView from '../Home/DashboardView';

class TargetMarketCampaignsViewParams {
  public clientId = {}
  public targetMarketsMode = {} // TargetMarkets AddTargetMarket
  public targetMarketId = {}
  public targetMarketMode = {} // Target Market Accounts  EditTargetMarket
}

@observer
export default class TargetMarketCampaignsView extends Views.ViewBase<TargetMarketCampaignsVM, TargetMarketCampaignsViewParams> {

  constructor(props: unknown) {
    super(textConstants.generalText.TargetMarketsSpaced, TargetMarketCampaignsVM, props);
  }

  onLeave() {
    this.viewModel.removeNotifications();
    return undefined;
  }

  static params = new TargetMarketCampaignsViewParams();

  public async componentDidMount() {
    await this.viewModel.getClientId()
    this.viewParams.clientId.value = this.viewModel.clientId
    this.viewParams.targetMarketsMode.value = textConstants.generalText.TargetMarkets
    this.viewModel.customAuthService.globalProps.isZendeskVisible = true;
  }

  public updateBreadcrumb(currentScreenName: string) {
    this.viewParams.targetMarketsMode.description = currentScreenName;
  }

  public clearTargetMarketInfo() {
    this.viewModel.targetMarketId = null;
    this.viewModel.targetMarketName = "";
    this.viewParams.targetMarketId.value = null;
    this.viewParams.targetMarketMode.value = null;
  }

  public updateTargetMarketParams(description: string, description1: string) {
    this.viewParams.targetMarketId.description = description;
    this.viewParams.targetMarketMode.description = description1;
  }

  public async viewParamsUpdated() {
    const targetMarketId = this.viewParams.targetMarketId.asNullableInt();
    let clientId = this.viewParams.clientId.asNullableInt();

    // Fix naviagtion to Target Markets via breadcrumb. This also fixes navigation to Target Markets via side menu when already in Target Markets
    if (clientId === null) {
      await this.viewModel.getClientId()
      this.viewParams.clientId.value = this.viewModel.clientId
      this.viewParams.targetMarketsMode.value = textConstants.generalText.TargetMarkets
      clientId = this.viewModel.clientId
    }

    this.viewModel.showAddEditTargetMarket = false;

    if (clientId) {

      // Target Markets screen
      if (this.viewParams.targetMarketsMode.value === textConstants.generalText.TargetMarkets || this.viewParams.targetMarketsMode.value === null) {
        // Initialize ViewModel
        this.viewModel.TargetMarketViewModel = new TargetMarketVM();
        this.viewModel.TargetMarketViewModel.taskRunner = this.viewModel.taskRunner;

        // Set values & fetch data
        await this.viewModel.TargetMarketViewModel.fetchData(clientId);
        const clientName = await this.viewModel.getClientName(clientId);
        this.viewModel.showDashboard = false
        this.viewModel.showTargetMarkets = true

        //Check if search criteria has been saved
        if (this.viewModel.savedSearchCriteria) {
          this.viewModel.TargetMarketViewModel.hideGrid = true;
          this.viewModel.TargetMarketViewModel.searchCriteria.searchString = this.viewModel.savedSearchCriteria.searchString;

          this.viewModel.TargetMarketViewModel.pageManager.refreshData();
          this.viewModel.TargetMarketViewModel.hideGrid = false;
        }

        // Clear the breadcrumb
        this.viewParams.targetMarketsMode.description = ""

        // Add Target Market screen
      } else if (this.viewParams.targetMarketsMode.value === textConstants.generalText.AddTargetMarket) {

        // Initialize ViewModel
        this.viewModel.AddEditTargetMarketViewModel = new AddEditTargetMarketVM();
        this.viewModel.AddEditTargetMarketViewModel.taskRunner = this.viewModel.taskRunner;

        // Set values & fetch data
        await this.viewModel.AddEditTargetMarketViewModel.initialise();
        this.viewModel.AddEditTargetMarketViewModel.pageTitle = textConstants.titleText.SpecifyTargetMarket;
        this.viewModel.AddEditTargetMarketViewModel.clientId = clientId;
        this.viewModel.targetMarketId = null;

        const clientName = await this.viewModel.getClientName(clientId);
        this.viewModel.setClientDisplayName(clientName);
        this.updateBreadcrumb(textConstants.generalText.AddTargetMarketSpaced);
        this.viewModel.showTargetMarkets = false
        this.viewModel.showAddEditTargetMarket = true

        //Save target market search													
        this.viewModel.savedSearchCriteria = this.viewModel.TargetMarketViewModel.searchCriteria;
      }

      if (targetMarketId) {

        this.viewModel.targetMarketId = targetMarketId;

        // Target Market Accounts screen
        if (this.viewParams.targetMarketMode.value === textConstants.generalText.TargetMarketAccountsSpaced) {

          this.viewModel.showDashboard = false
          this.viewModel.showTargetMarkets = false

          // Initialize ViewModel
          this.viewModel.TargetMarketAccountsViewModel = new TargetMarketAccountsVM();
          this.viewModel.TargetMarketAccountsViewModel.taskRunner = this.viewModel.taskRunner;
          this.viewModel.TargetMarketAccountsViewModel.clientId = clientId

          // Set values & fetch data;
          await this.viewModel.TargetMarketAccountsViewModel.fetchData(targetMarketId);

          this.viewModel.TargetMarketAccountsViewModel.navigateToAddClientList = () => {
            this.viewParams.targetMarketMode.value = textConstants.generalText.AddClientList;
          };
          this.viewModel.showTargetMarketAccounts = true
          this.updateBreadcrumb(`${this.viewModel.TargetMarketAccountsViewModel.targetMarketSummary.targetMarketName}`);
        }

        if (this.viewParams.targetMarketMode.value === textConstants.generalText.EditTargetMarket) {

          this.viewModel.showTargetMarkets = false
          this.viewModel.showDashboard = false

          // Initialize ViewModel
          this.viewModel.AddEditTargetMarketViewModel = new AddEditTargetMarketVM();
          this.viewModel.AddEditTargetMarketViewModel.taskRunner = this.viewModel.taskRunner;

          // Set values & fetch data
          this.viewModel.AddEditTargetMarketViewModel.isEdit = true;
          this.viewModel.AddEditTargetMarketViewModel.clientId = clientId;
          this.viewModel.AddEditTargetMarketViewModel.targetMarketId = targetMarketId;
          await this.viewModel.AddEditTargetMarketViewModel.initialise();
          this.viewModel.AddEditTargetMarketViewModel.pageTitle = textConstants.titleText.SpecifyTargetMarket;
          const clientName = await this.viewModel.getClientName(clientId);
          this.viewModel.setClientDisplayName(clientName);

          this.updateBreadcrumb(textConstants.generalText.EditTargetMarketSpaced);
        }

        if (this.viewParams.targetMarketMode.value === "" || this.viewParams.targetMarketMode.value === null) {
          this.viewParams.targetMarketId.value = null;
        }
      }
    }
    else {
      this.viewModel.initialCampaignLoad = true;
      this.viewModel.showTargetMarkets = false
      this.viewModel.showDashboard = true
      this.viewModel.showAddEditTargetMarket = false
      this.navigation.navigateToView(DashboardView, { clientId: null });
    }

    // This has been done to avoid clearing the Notification from the AddEditTargetMarket
    if (
      this.viewParams.targetMarketsMode.value === textConstants.generalText.TargetMarkets &&
      this.viewModel.clearAddEditNotifications === false
    ) {
      this.viewModel.clearAddEditNotifications = true;
    }
    else {
      this.viewModel.removeNotifications();
    }
  }

  public openUrl(url: string) {
    if (url === "") {
      this.viewModel.notifications.addDanger("Error", `No url has been specified.`, 5);
    } else {
      window.open(url, '_blank');
    }
  }

  public checkUrlValid(url: string) {
    if (url) {
      if (url.length > 0) {
        return true;
      }
    }
    return false;
  }

  public render() {
    return (
      <React.Fragment>
        {this.viewModel.showAddEditTargetMarket && this.viewModel.showTargetMarkets === false &&
          <AddEditTargetMarketView
            viewModel={this.viewModel.AddEditTargetMarketViewModel}
            back={() => {
              this.viewModel.clearAddEditNotifications = false;
              this.viewParams.targetMarketsMode.value = textConstants.generalText.TargetMarkets;
              this.viewModel.showAddEditTargetMarket = false;
            }}
            clientName={this.viewModel.clientDisplayName}
          />
        }

        {(this.viewModel.targetMarketId !== null && this.viewParams.targetMarketMode.value === textConstants.generalText.EditTargetMarket) &&
          <AddEditTargetMarketView viewModel={this.viewModel.AddEditTargetMarketViewModel}
            back={() => {
              this.viewModel.clearAddEditNotifications = false;
              this.clearTargetMarketInfo();
              this.viewParams.targetMarketsMode.value = textConstants.generalText.TargetMarkets
            }
            }
            clientName={this.viewModel.clientDisplayName}
          />
        }

        {this.viewModel.showTargetMarkets === true &&
          <TargetMarketView viewModel={this.viewModel.TargetMarketViewModel}
            back={() => { }}

            openAddTargetMarket={() => {
              // this.viewParams.clientId.value = this.viewModel.clientId;
              this.viewModel.showTargetMarkets = false
              this.viewParams.targetMarketsMode.value = textConstants.generalText.AddTargetMarket
            }}

            openTargetMarketAccounts={(targetMarketId: number) => {
              this.viewParams.targetMarketId.value = targetMarketId;
              this.viewParams.targetMarketMode.value = textConstants.generalText.TargetMarketAccountsSpaced;
            }}

            openEditTargetMarket={(targetMarketId: number, targetMarketName: string) => {
              this.viewModel.targetMarketName = targetMarketName;
              this.viewParams.targetMarketId.value = targetMarketId;
              //Save target market search													
              this.viewModel.savedSearchCriteria = this.viewModel.TargetMarketViewModel.searchCriteria;
              this.viewParams.targetMarketMode.value = textConstants.generalText.EditTargetMarket;
            }}

            openProspectTargetMarket={(targetMarketId: number, prospectingCount: number) => {
            }}

            openUpdateTargetMarket={() => {
            }}
          />
        }
        {(this.viewModel.targetMarketId !== null && this.viewParams.targetMarketMode.value === textConstants.generalText.TargetMarketAccountsSpaced) &&
          <TargetMarketAccountsView viewModel={this.viewModel.TargetMarketAccountsViewModel}
            back={() => {
              this.clearTargetMarketInfo();
              this.viewParams.targetMarketsMode.value = textConstants.generalText.TargetMarkets
            }
            } />
        }
      </React.Fragment>
    );
  }
}