import { Attributes, ModelBase, NeoModel, Validation } from "@singularsystems/neo-core";

@NeoModel
export default class ActionListConfiguration extends ModelBase {
  public actionListConfigurationId: number = 0;

  @Attributes.Display("Configuration Name")
  public configurationFriendlyName: string = ""

  public uniqueTableKey: string = ""

  public description: string = ""
}