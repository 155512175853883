import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { textConstants } from './../../common/textConstants';
import { AppService, Types } from "../../Services/AppService";
import React from 'react';
import SettingsVM from './SettingsVM';
import TechnicalSettingsContainer from '../Client/TechnicalSettingsContainer';
import TechnicalSettingsVM from '../Client/TechnicalSettingsVM';
import DashboardView from './DashboardView';

class SettingsViewParams {
    public clientId = {};
    public settingsMode = {}; // Settings
}

@observer
export default class SettingsView extends Views.ViewBase<SettingsVM, SettingsViewParams> {

    static params = new SettingsViewParams();

    private showPageChangeWarning: boolean = false

    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

    private customAuthService = AppService.get(Types.Security.CustomAuthenticationService)

    constructor(props: unknown) {
        super("Settings", SettingsVM, props);
        document.addEventListener('clearSearchCriteria', () => {
            this.viewModel.clientCriteria.clientName = "";
            this.viewModel.savedSearchCriteria = undefined;
        });

        this.customAuthService.globalProps.isOnboarding = false
    }

    onLeave() {
        this.viewModel.removeNotifications();
        return undefined;
    }

    componentDidUpdate() {
    }

    public async componentDidMount() {
        this.customAuthService.globalProps.isZendeskVisible = true
        await this.viewModel.getClientId()
        this.viewParams.clientId.value = this.viewModel.clientId
        this.viewParams.settingsMode.value = textConstants.titleText.Settings
    }

    public clearClientInformation() {
        this.viewModel.clientId = null;
        this.viewParams.clientId.value = null;
    }

    public updateClientParams(description: string, description1: string) {
        this.viewParams.clientId.description = description;
        this.viewParams.settingsMode.description = description1;
    }

    public securityCheck(role: any, openMethod: () => void) {
        if (this.authorisationService.hasRole(role)) {
            openMethod();
        }
        else {
            this.viewModel.showForbiddenModal = true;
        }
    }

    public async viewParamsUpdated() {
        const clientId = this.viewParams.clientId.asNullableInt();

        //Check for upload notifications
        this.viewModel.checkUserNotifications();

        if (clientId) {

            this.viewModel.clientId = clientId;

            // Settings
            if (this.viewParams.settingsMode.value === "Settings") {
                this.viewModel.technicalSettingsVM = new TechnicalSettingsVM();
                this.viewModel.technicalSettingsVM.taskRunner = this.viewModel.taskRunner
                this.viewModel.technicalSettingsVM.clientId = clientId
                const clientName = await this.viewModel.getClientName(clientId);
                // this.updateClientParams(clientName, textConstants.titleText.Settings);
            }

            this.forceUpdate();
        } else {
            this.navigation.navigateToView(DashboardView, { clientId: null });
        }
    }

    public render() {
        return (
            <Neo.Card>
                <div className='row m-0'>
                    <h2 className='col-12 p-24 mt-4'>Settings</h2>
                    <div className='col-12 p-0'>
                        {this.viewModel.clientId !== null && this.viewParams.settingsMode.value === "Settings" &&
                            <TechnicalSettingsContainer viewModel={this.viewModel.technicalSettingsVM} clientID={this.viewModel.clientId} />
                        }
                    </div>
                </div >
            </Neo.Card>
        );
    }
}
