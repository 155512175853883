import React from "react";

import Calender from "assets/svgs/Calender";
import YouTube from "assets/svgs/YouTube";
import ExternalLink from "assets/svgs/ExternalLink";
import CopyIcon from "assets/svgs/CopyIcon";
import { List, NotifyUtils } from '@singularsystems/neo-core';

import {
  StyledCard,
  StyledCardSubHeader,
  StyledMeetingCard,
  StyledMeetingCardDetails,
  StyledMeetingCardLink,
  StyledLine,
  StyledVideoLinkWrapper,
  StyledVideoLinksWrapper,
  StyledVideoLink,
  StyledVideoLinkNumber,
  StyledHubCard,
  StyledVideoLinkText,
  StyledVideoExternalLink,
  StyledHubPasswordLabel,
  StyledHubPassword,
  StyledPasswordWrapper,
  StyledCopyToClipboard,
  StyledExternalLink,
} from "../../styles";
import CopyToClipboard from 'react-copy-to-clipboard';
import { setTextForClipboard } from 'common/utils';
import { textConstants } from 'common/textConstants';
import OnboardingMeetingLookup from "Models/Onboarding/OnboardingMeetingLookup";
import moment from "moment";

export interface IMeetingDetailsProps {
  linkList: ICampaignMessageLink[]
  comxHub: IComXHubInfo
  cancelMeeting: () => void
  onboardingMeeting: OnboardingMeetingLookup
}

export interface ICampaignMessageLink {
  id: number
  link: string
  title: string
}

export interface IComXHubInfo {
  url: string
  password: string
}

const showCopySuccess = () => {
  const variant = "success"
  NotifyUtils.add(variant, textConstants.messageText.saveMessage.TextToClipboard,
    variant as any, 5);
}

const MeetingDetailsContent = (
  { linkList, comxHub, onboardingMeeting, cancelMeeting }: IMeetingDetailsProps) => {

  return (
    <StyledCard>
      {onboardingMeeting.isMeetingScheduled &&
        <>
          <StyledCardSubHeader>
            <Calender /> <h6>{textConstants.Onboarding.Meeting.MeetingWithCSM}</h6>
          </StyledCardSubHeader>

          <StyledMeetingCard>
            <StyledMeetingCardDetails>
              <h6>{textConstants.Onboarding.Meeting.CallWith}{onboardingMeeting.csmFullName}</h6>
              <p>{(moment(`${onboardingMeeting.startTime}`)).format(textConstants.dateformat.format4)} - {moment(onboardingMeeting.endTime).format(textConstants.dateformat.hourMinuteFormat)} {onboardingMeeting.timezone}</p>
            </StyledMeetingCardDetails>

            <StyledMeetingCardLink onClick={() => cancelMeeting()}>{textConstants.Onboarding.Meeting.ReCancel}</StyledMeetingCardLink>

          </StyledMeetingCard>
          <StyledLine />
        </>
      }

      <StyledCardSubHeader>
        <YouTube />{" "}
        <h6>{textConstants.Onboarding.Meeting.WatchAndRead}</h6>
      </StyledCardSubHeader>

      <StyledVideoLinkWrapper>
        <StyledVideoLinksWrapper>
          {linkList.map((links: any, key) => {
            return <StyledVideoLink href={`${links.link}`} target="_blank" key={key}>
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                }}
              >
                <StyledVideoLinkNumber>{links.id}</StyledVideoLinkNumber>
                <StyledVideoLinkText>
                  {links.title}
                </StyledVideoLinkText>
              </div>
              <StyledExternalLink>
                <ExternalLink />
              </StyledExternalLink>
            </StyledVideoLink>

          })}
        </StyledVideoLinksWrapper>

        <StyledHubCard>
          <h5>ComX Hub</h5>
          <p>
            {textConstants.Onboarding.Meeting.ComXHub}
          </p>

          <StyledHubPasswordLabel>{textConstants.Onboarding.Meeting.PasswordUpper}</StyledHubPasswordLabel>

          <StyledPasswordWrapper>
            <StyledHubPassword>
              <p>{comxHub.password}</p>
              <StyledCopyToClipboard>
                <CopyToClipboard text={setTextForClipboard(comxHub.password)} onCopy={() => showCopySuccess()}>
                  <i className="appendedIcon">{<CopyIcon />}</i>
                </CopyToClipboard>
              </StyledCopyToClipboard>
            </StyledHubPassword>

            <StyledVideoExternalLink href={`${comxHub.url}`} target="_blank">
              <ExternalLink />
            </StyledVideoExternalLink>
          </StyledPasswordWrapper>
        </StyledHubCard>
      </StyledVideoLinkWrapper>
    </StyledCard>
  );
};

export default MeetingDetailsContent;
