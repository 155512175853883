import {ModelBase, NeoModel} from '@singularsystems/neo-core';
  
@NeoModel
export default class BatchReviewAccountContactLookup extends ModelBase {      
  public batchReviewAccountId : number = 0;

  public batchReviewAccountContactId : number = 0;

  public firstName : string = "";

  public lastName : string = "";

  public title : string = "";

  public deleted : boolean = false;
}