import { observer } from "mobx-react";
import React from "react";
import { textConstants } from "../../common/textConstants";
import { Neo } from "@singularsystems/neo-react";
import * as Icon from "react-feather";
import ActionListVM from "../../Views/ActionList/ActionListVM";

interface IActionListTableHeaderRow {
  viewModel: ActionListVM;
  archiveOrUnarchiveSelectedLeads: (isArchive: boolean) => void;
}

@observer
class ActionListTableHeader extends React.Component<IActionListTableHeaderRow> {
  constructor(props: any) {
    super(props);
  }

  private setCheckboxStyle() {
    if (this.props.viewModel.isSelectAll) {
      return "selectedCheckbox";
    } else if (this.props.viewModel.isSelectAll === null) {
      return "notAllSelected";
    }

    return "";
  }

  render() {
    return (
      <div className="actionListRowContainer">
        {/* SELECTION COUNTER AND ARCHIVE BUTTONS */}
        <div className="borderBottom">
          {this.props.viewModel.selectedReplies.length > 0 && (
            <>
              <div className="row m-0 pl-10 text-left my-auto ">
                {/* ARCHIVE BUTTON */}
                <span className="col-12 p-0 mx-2 text-left pull-left">
                  {this.props.viewModel.selectedTab !==
                    textConstants.buttonText.Archive && (
                      <Neo.Button
                        icon={<Icon.Archive className="mr-2" />}
                        className="my-auto p-6 mr-3 buttonHover archiveButton btn btn-light"
                        onClick={() => {
                          this.props.archiveOrUnarchiveSelectedLeads(true);
                        }}
                      >
                        {textConstants.ActionListText.ArchiveButtonText.Archive}
                      </Neo.Button>
                    )}

                  {/* UNARCHIVE BUTTON */}
                  {this.props.viewModel.selectedTab ===
                    textConstants.buttonText.Archive && (
                      <Neo.Button
                        icon={<Icon.RotateCw className="mr-2" />}
                        className="my-auto p-6 mr-3 buttonHover archiveButton btn btn-light"
                        onClick={() => {
                          this.props.archiveOrUnarchiveSelectedLeads(false);
                        }}
                      >
                        {textConstants.ActionListText.ArchiveButtonText.Restore}
                      </Neo.Button>
                    )}
                  {/* COUNTER */}
                  {this.props.viewModel.selectedReplies.length}
                  {this.props.viewModel.selectedReplies.length === 1 &&
                    textConstants.ActionListText.RecordSelected.single}
                  {this.props.viewModel.selectedReplies.length > 1 &&
                    textConstants.ActionListText.RecordSelected.multiple}
                  {/* CLEAR */}
                  <a
                    className="mx-1 selectAllLink"
                    onClick={() =>
                      this.props.viewModel.resetBulkControlProperties()
                    }
                  >
                    {textConstants.ActionListText.ArchiveButtonText.ClearAll}
                  </a>
                </span>

                {/* This may come back in the future */}
                {/* SELECT ALL */}
                {/* <a className="mx-1 selectAllLink"
                  onClick={() => this.props.viewModel.hasClickedSelectAll()}>
                  {textConstants.ActionListText.ArchiveButtonText.SelectAllPage + " (" + this.props.viewModel.pagedLeadList.length + ")"}
                </a>
                or */}
              </div>
            </>
          )}
        </div>

        <div className="actionListTableHeader mt-3 mb-3 actionListGrid">
          {/* Select All Checkbox Column */}
          <div className="checkboxCol my-auto text-center">
            {
              <Neo.FormGroup
                className={this.setCheckboxStyle()}
                input={{ type: "checkbox" }}
                bind={this.props.viewModel.meta.isSelectAll}
                suppressLabel={true}
                onChange={() => {
                  this.props.viewModel.hasClickedSelectAllPage();
                }}
              />
            }
          </div>
          {/*Name Column*/}
          <div className="px-0 ml-0 my-auto ">
            {textConstants.ActionListText.ColumnHeaders.Name}
          </div>
          {/* Campaign Column */}
          <div className="px-0 ml-0 my-auto">
            {textConstants.ActionListText.ColumnHeaders.Campaign}
          </div>
          {/* Activity Column */} {/* Future Work */}
          <div className="px-0 ml-0 my-auto">
            {textConstants.ActionListText.ColumnHeaders.Activity}
          </div>
          {/* Status Column*/}
          <div id="replyHeader" className="px-0 ml-0 my-auto">
            {textConstants.ActionListText.ColumnHeaders.Status}
          </div>
          {/* Navigation Buttom Column */}
          <div className="my-auto p-24"></div>
        </div>
      </div>
    );
  }
}

export default ActionListTableHeader;
