import 'rc-slider/assets/index.css';

import React from 'react';
import { observer } from 'mobx-react';


interface OverlayProps {
    isVisible: boolean
    className: string
    overlayText: string

}

@observer
export default class Overlay extends React.Component<OverlayProps> {
    public render() {
        return (
       
            <div  className={` overlay ${this.props.isVisible ? `${this.props.className}` : "displayNone"}`}><span className="disabledText">{this.props.overlayText}</span></div>
           
        );
    }
}
