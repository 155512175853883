import {NeoModel, ValueObject } from '@singularsystems/neo-core';
import { targetMarketAccountTabs } from '../../../Views/TargetMarketAccounts/TargetMarketAccountsVM';
import { IsGridCriteria } from '../../../Components/ReactDataGrid/DataGridHelper';

@NeoModel
export default class TargetMarketAccountCriteria extends ValueObject implements IsGridCriteria {

    public targetMarketId: number = 0;
    
    public searchString: string="";

    public targetMarketAccountStatus: string[] = []

    public subIndustry: string[] = []

    public companySize: string[] = []

    public dateLastProspected: string[] = []

    public whitelistImport: string[] = []

    public priority: number = 1

    public removed: number = 1

    public tabName: targetMarketAccountTabs = "To-do"

    public originalName: string="";
    
    public fixedName: string="";

    public location: string="";

    public domain: string="";

    public isCoumnFilter : boolean = false;

    public notFilter: string[] = [] ;

    public isBulkAction : boolean = false;

    public timesProspected : number[] = []
    
    public ResetLocalFilters() 
    {  
        this.targetMarketAccountStatus = []
        this.subIndustry = []
        this.companySize = []
        this.dateLastProspected = []
        this.priority = 1
        this.removed = 1
        this.originalName = "";
        this.fixedName = "";
        this.location = "";
        this.domain = "";
        this.isCoumnFilter = false;
        this.notFilter = []
        this.timesProspected = []
    }
} 