import "rc-slider/assets/index.css";
import "react-input-range/lib/css/index.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import AddEditTargetMarketVM from "../AddEditTargetMarketVM";
import { textConstants } from "common/textConstants";
import InfoLabel from "../Functions/InfoLabel";
import Overlay from "../Functions/Overlay";
import * as Icon from "react-feather";
import CreatableSelect from "react-select/creatable";
import DeleteIcon from "assets/svgs/DeleteIcon";
import {
    StyledOrganizationItem,
    StyledOrganizationList,
} from "Views/IdealCustomerProfiles/Styles/CustomerProfileView.styles";
import DragIcon from "assets/svgs/DragIcon";
import SortableList from "Components/Draggable/SortedList";
import { SingleValue } from "react-select";

interface TMScreen5Props {
    viewModel: AddEditTargetMarketVM;
    isHeaderManagerSelected: () => boolean;
    isNotEditable?: boolean;
}

@observer
export default class TMScreen5 extends React.Component<TMScreen5Props> {
    public selectorOpen: boolean = false;

    public OtherRolesSelectorDropDown() {
        const list =
            this.props.viewModel.savedListOptions !== null &&
            (this.props.viewModel.savedListOptions.map((v: any) => {
                if (v !== undefined) {
                    return {
                        value: v.value,
                        label: v.label,
                    };
                } else {
                    return null;
                }
            }) as any);

        const deleteItem = (index: number) => {
            const newList = list.filter(
                (item: { value: any }) => item.value !== index
            );
            this.props.viewModel.setRoles(newList);
        };

        const handleChange = async (e: SingleValue<{ value: any; label: any }>) => {
            const newItem = {
                value: e!.value,
                label: e!.label,
            };
            const newList = [...list, newItem];
            this.props.viewModel.setRoles(newList);
        };

        const DragComponent = ({ item, index }: any) => {
            return (
                <StyledOrganizationItem>
                    <div>
                        <DragIcon />
                        <span>{item.label}</span>
                    </div>
                    {!this.props.isNotEditable && (
                        <div onClick={() => deleteItem(item.value)}>
                            <DeleteIcon />
                        </div>
                    )}
                </StyledOrganizationItem>
            );
        };

        const setSortableList = async (items: any) => {
            this.props.viewModel.savedListOptions = items;
        };

        return (
            <div>
                {!this.props.isNotEditable &&
                    !this.props.isHeaderManagerSelected() && (
                        <CreatableSelect
                            isDisabled={this.props.isNotEditable}
                            className={`p-0 mt-2`}
                            isClearable={false}
                            value={
                                this.props.viewModel.savedListOptions !==
                                    null ?
                                this.props.viewModel.savedListOptions.map(
                                    (v) => {
                                        if (v !== undefined) {
                                            return {
                                                value: v.value,
                                                label: v.label,
                                            };
                                        } else {
                                            return null;
                                        }
                                    }
                                ) : null
                            }
                            onChange={(e) => handleChange(e)}
                            options={this.props.viewModel.roleListOptions}
                            noOptionsMessage={() =>
                                textConstants.Onboarding.NoItemsInList
                            }
                            placeholder={
                                textConstants.messageText.validationMessage
                                    .startTyping
                            }
                        />
                    )}
                {!this.props.isHeaderManagerSelected() && (
                    <StyledOrganizationList>
                        <SortableList
                            items={list}
                            setItems={async (items: any) => {
                                setSortableList(items);
                            }}
                            component={DragComponent}
                            isDisabled={this.props.isNotEditable}
                        />
                    </StyledOrganizationList>
                )}
            </div>
        );
    }

    public handleChange(selectedItems: any) {
        this.props.viewModel.setRoles(selectedItems);
    }

    public render() {
        return (
            <div className="col-sm p-0 tMScreen5">
                {/* OTHER FUNCTION KEYWORDS */}
                <div className="row m-0 p-0">
                    <div className="col-8 p-0 m-0">
                        <h4 className="mt-2">
                            {textConstants.titleText.OtherKeyFunctionWords}
                        </h4>
                    </div>

                    <div className="col-4 p-0 m-0">
                        {!this.props.isHeaderManagerSelected() &&
                            !this.props.isNotEditable && (
                                <button
                                    className="otherFunctionsAddButton pull-right"
                                    onClick={() => {
                                        this.selectorOpen = true;
                                        this.forceUpdate();
                                    }}
                                >
                                    <Icon.Plus className="mr-2"></Icon.Plus>
                                    {textConstants.Onboarding.AddFunction}
                                </button>
                            )}
                    </div>
                </div>

                <InfoLabel
                    labelType="info"
                    labelText={
                        textConstants.messageText.validationMessage
                            .headerManageLevelSelected
                    }
                    isVisible={this.props.isHeaderManagerSelected()}
                    font=""
                />

                {this.props.viewModel.isFunctionsBlank &&
                    this.props.viewModel.isOtherFunctionsBlank && (
                        <div className="mandatoryText">
                            Please enter a new function
                        </div>
                    )}
                <Overlay
                    isVisible={this.props.isHeaderManagerSelected()}
                    className="overlayRoles"
                    overlayText="Disabled"
                />

                {(this.selectorOpen ||
                    this.props.viewModel.savedListOptions.length > 0) &&
                    this.OtherRolesSelectorDropDown()}  
            </div>
        );
    }
}