import {ModelBase, NeoModel} from '@singularsystems/neo-core';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';


@NeoModel
export default class TargetMarketManagementRolesLookup extends ModelBase {
    // Properties
    public targetMarketManagementRoleId: number = 0
    public managementRoleId: number = 0
    public managementSubRoleId: number = 0
    @Display('Role')
    public roleName: string | null = null;
    public roleKey: string = "";
    public isSelected: boolean = false;
}