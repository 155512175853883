import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import LeadType from 'Models/ActionList/LeadType';

export interface ILeadTypesApiClient extends Data.IUpdateableApiClient<LeadType, number> {

}

@injectable()
export default class LeadTypesApiClient extends Data.UpdateableApiClient<LeadType, number> implements ILeadTypesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/leadTypes`);
    }
}