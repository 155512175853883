import { NeoModel, List,} from '@singularsystems/neo-core';
import TargetMarketType from './../../Models/Maintenance/TargetMarketType';
import { AppService, Types } from '../../Services/AppService';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';

@NeoModel
export default class TargetMarketTypeVM extends Views.ViewModelBase {
    
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private targetMarketTypesApiClient =  AppService.get(Types.ApiClients.TargetMarketTypesApiClient)) {

        super(taskRunner);
    }

    // Properties
    public targetMarketTypeList = new List(TargetMarketType);

    public async initialise() {
        const response = await this.taskRunner.waitFor(this.targetMarketTypesApiClient.get());
        this.targetMarketTypeList.set(response.data);
    }

    public saveTargetMarketTypeList(){
        this.taskRunner.run(async () => {
            const response = await this.targetMarketTypesApiClient.saveList(this.targetMarketTypeList.toJSArray());
            this.targetMarketTypeList.update(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved,textConstants.messageText.saveMessage.TargetMarketTypesSaved);
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}