import { NeoModel} from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import InvitedUsersViewModel from './Component/InvitedUserViewModel';

@NeoModel
export default class InvitedUsersVM extends Views.ViewModelBase {

  constructor(taskRunner = AppService.get(Types.Neo.TaskRunner),
  private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {
    super(taskRunner);
  }

  // ClientId is set to 0 as this functionality fetches all invited users
  public InvitedUsersViewModel = new InvitedUsersViewModel();

  public initialise() {
    this.InvitedUsersViewModel.taskRunner = this.taskRunner;
    this.InvitedUsersViewModel.clientId = 0;
    this.InvitedUsersViewModel.initialise();
    this.InvitedUsersViewModel.setupComponent();
    this.InvitedUsersViewModel.pageManagerInvited.refreshData();
  }

  public removeNotifications() {
    this.notifications.store.notifications = [];
  }
}