import { List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import Role from './Role';
import IRoleItem from './IRoleItem';

@NeoModel
export default class RoleCategory extends ModelBase implements IRoleItem {

    public roleCategoryId : number = 0;

    public category : string = "";

    public roles = new List(Role);

    // Client only properties / methods

    public get itemName() {
        return this.category;
    }

    public get children() {
        // This suddenly would not compile without this "as unknown as List<IRoleItem>" :(
        return this.roles as unknown as List<IRoleItem>;
    }

    public get level() { return 2; };

    public get fullChildrenCount() {
        return this.children.length;
    }

    public get selectedChildrenCount() {
        return this.children.filter(c => c.selected).length;
    }
    
    public get selected() {
        let allSelected = true;
        let allUnselected = true;
        for (const role of this.roles)
        {
            if (role.selected === true)
            {
                allUnselected = false;
            }
            else if (role.selected === false)
            {
                allSelected = false;
            }
            if (!allSelected && !allUnselected) { break; }
        }
        if (allSelected && allUnselected) { return undefined; }
        else if (allSelected) { return true; }
        else if (allUnselected) { return false; }
        else { return undefined; }
    }

    public set selected(value: boolean | undefined) {
        this.roles.forEach(role => role.selected = value as boolean);
    }

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString() {
        if (this.isNew) {
            return "New role category"
        } else {
            return "Role Category";
        }
    }
}