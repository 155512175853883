import "rc-slider/assets/index.css";
import "react-input-range/lib/css/index.css";
import React from "react";
import { observer } from "mobx-react";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import AddEditTargetMarketVM from "../AddEditTargetMarketVM";
import { IPropertyInstance } from "@singularsystems/neo-core/dist/Model";
import ListSelectorModal from "Components/Modals/ListSelectorModal";
import ListSelectorSelectedItems from "Components/OnboardingComponents/ListSelectorSelectedItems";
import { textConstants } from "common/textConstants";

interface TMScreen3Props {
    viewModel: AddEditTargetMarketVM;
    formatFunctionRole: (roleName: IPropertyInstance<string | null>) => {};
}

@observer
export default class TMScreen3 extends React.Component<TMScreen3Props> {
    state = {
        openTMListModal: false,
    };

    public toggleModal() {
        this.setState({
            openTMListModal: !this.state.openTMListModal,
        });
    }

    public render() {
        return (
            <div className="col-sm">
                <ListSelectorModal
                    isOpen={this.state.openTMListModal}
                    closeModal={async () => {
                        this.toggleModal();
                    }}
                    onListReturn={(selectedList, clonedList) =>
                        this.props.viewModel.setSelectedList(selectedList, clonedList)
                    }
                    modalList={this.props.viewModel.listSelectorClonedList}
                    modalTitle={
                        textConstants.Onboarding.ListSelectorTypeRolesLowercase
                    }
                />

                <ListSelectorSelectedItems
                    title={textConstants.Onboarding.ListSelectorTitleRoles}
                    btnText={textConstants.Onboarding.ListSelectorBtnTextRoles}
                    itemType={textConstants.Onboarding.ListSelectorTypeRoles}
                    list={this.props.viewModel.selectedModalList}
                    isHeadOfSeniority={
                        this.props.viewModel.targetMarket.meta
                            .isSeniorityLevelHead
                    }
                    isManagerOfSeniority={
                        this.props.viewModel.targetMarket.meta
                            .isSeniorityLevelManager
                    }
                    onChange={() => {
                        this.props.viewModel.isScreenDirty = true;
                    }}
                    openModal={() => {
                        this.props.viewModel.listSelectorClonedList =
                            this.props.viewModel.cloneSelectorModalList(this.props.viewModel.modalList)
                        this.props.viewModel.setupListModalData();
                        this.toggleModal();
                    }}
                ></ListSelectorSelectedItems>
            </div>
        );
    }
}
