import { observer } from "mobx-react";
import React from "react";
import * as Icon from 'react-feather';
import LeadMessageSideMenuButton from "./LeadMessageSideMenuButton";
import LeadMessageVM from "../Views/ActionList/LeadMessageVM";
import { textConstants } from "../common/textConstants";

interface ILeadMessageSideMenuProps {
    newHotTotal?: number,
    newWarmTotal?: number,
    newColdTotal?: number,
    viewModel: LeadMessageVM,
    back: () => void,
    showPreviousMessage: () => void,
    showNextMessage: () => void,
    changeLeadType: (leadType: string) => void
    isFirstRecord : boolean,
    isLastRecord : boolean,
    hotTotal: number,
    warmTotal: number,
    coldTotal: number,
    isLeadLoading: boolean,
}

@observer
class LeadMessageSideMenu extends React.Component<ILeadMessageSideMenuProps> {

    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <div className={`LeadMessageSideMenu mt-4 mr-4`}>
                {/* Back Button */}
                <button className="button" onClick={() => this.props.back()} disabled={this.props.isLeadLoading}><Icon.ArrowLeft /></button>

                {/* Separator */}
                <div className="line"></div>

                {/* Hot Leads button */}
                <LeadMessageSideMenuButton
                    unseenCount={this.props.newHotTotal}
                    icon={<Icon.Zap />}
                    isSelected={this.props.viewModel.selectedLeadType === textConstants.ActionListText.LeadTypes.hot}
                    onClick={() => this.props.changeLeadType(textConstants.ActionListText.LeadTypes.hot)}
                    isDisabled={this.props.hotTotal < 1 || this.props.isLeadLoading}
                />

                {/* Warm Leads Button */}
                <LeadMessageSideMenuButton
                    unseenCount={this.props.newWarmTotal}
                    icon={<Icon.Thermometer />}
                    isSelected={this.props.viewModel.selectedLeadType === textConstants.ActionListText.LeadTypes.warm}
                    onClick={() => this.props.changeLeadType(textConstants.ActionListText.LeadTypes.warm)}
                    isDisabled={this.props.warmTotal < 1 || this.props.isLeadLoading}
                />

                {/* Cold Leads Button */}
                <LeadMessageSideMenuButton
                    unseenCount={this.props.newColdTotal}
                    icon={<Icon.Wind />}
                    isSelected={this.props.viewModel.selectedLeadType === textConstants.ActionListText.LeadTypes.cold}
                    onClick={() => this.props.changeLeadType(textConstants.ActionListText.LeadTypes.cold)}
                    isDisabled={this.props.coldTotal < 1 || this.props.isLeadLoading}
                />

                {/* Separator */}
                <div className="line"></div>

                {/* Up (Previous) and Down (Next) buttons */}
                <button
                    className="button"
                    disabled={this.props.isFirstRecord || this.props.isLeadLoading}
                    onClick={() => this.props.showPreviousMessage()}>
                    <Icon.ChevronUp />
                </button>

                <button
                    className="button"
                    disabled={this.props.isLastRecord || this.props.isLeadLoading}
                    onClick={() => this.props.showNextMessage()}>
                    <Icon.ChevronDown />
                </button>
            </div>
        );
    }
}

export default LeadMessageSideMenu;