import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { HTTPResponseCodes } from 'Models/Enums/ApplicationEnums';
import ReminderCriteria from 'Models/Reminder/Query/ReminderCriteria';
import ReminderSaveCommand from 'Models/Reminder/Query/ReminderSaveCommand';
import ReminderData from 'Models/Reminder/ReminderData';
import { AppService, Types } from 'Services/AppService';
import { textConstants } from 'common/textConstants';

@NeoModel
export default class ReminderVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private actionListApiClient = AppService.get(Types.ApiClients.ActionListApiClient),
    private demoClientApiClient = AppService.get(Types.ApiClients.DemoClientsApiClient),
  ) {

    super(taskRunner);
  }

  public listReminderLookups: List<ReminderData> = new List(ReminderData);

  public reminderForm: ReminderData = new ReminderData()
  public remindersCriteria: ReminderCriteria = new ReminderCriteria();

  public prospectId = 0;
  public clientId = 0;
  public titleMaxLength = 50;

  public isFormEdit: boolean = false;
  public isLoading: boolean = true;
  public isValidInput: boolean = true;
  public isDemoClient: boolean  = false;

  public async initialise() {

    // Sets the isDemoClient Property
    await this.setIsDemoClient()

    await this.getReminders()
  }

  private async setIsDemoClient() {
    let demoClient = await this.demoClientApiClient.isDemoClient(this.clientId);

    if (demoClient && demoClient.status === HTTPResponseCodes.Ok) {

      this.isDemoClient = demoClient.data;
    }
  }

  // Gets the reminders for this specific client
  public async getReminders() {

    // Assigns the reminderCriteria with the information needed
    this.remindersCriteria.clientId = this.clientId;

    // This is specifically to allow for alternatives when the reminder is used in more places
    this.remindersCriteria.extra = textConstants.Reminders.prospectId;
    this.remindersCriteria.extraId = this.prospectId;

    // If it is a demo client don't fetch from backend.
    if (this.isDemoClient) {

      let data = this.listReminderLookups.sortBy("time", "asc") as any;

      this.listReminderLookups.set(data)

      this.isLoading = false;
      return;
    }

    // Gets the Reminders for that prospect Id.
    let result = (await this.actionListApiClient.getReminders(this.remindersCriteria)).data.data

    // Sorts it by time
    if (result) {
      this.listReminderLookups.set(result.data.sortBy("time", "asc"))
    }

    // Sets loading to false
    this.isLoading = false;
  }

  // Adding a new reminder
  public async addReminder() {
    this.reminderForm = new ReminderData();
    this.isFormEdit = true;
  }

  private populateSaveCommand(is_active: boolean) {
    let saveCommand = new ReminderSaveCommand()

    saveCommand.prospect_id = this.remindersCriteria.extraId
    saveCommand.title = this.reminderForm.title;
    saveCommand.description = this.reminderForm.description;
    saveCommand.time = this.reminderForm.time;
    saveCommand.is_active = is_active;

    return saveCommand
  }

  // Validates the Input length
  private validateInput() {
    let isValidInput = true;

    if (this.reminderForm.title.length > this.titleMaxLength || this.reminderForm.title.length === 0) {
      isValidInput = false
    }

    return isValidInput
  }

  // Validates the Title text to make sure its less than the max length. 
  public validationText() {
    if (this.reminderForm.title.length > this.titleMaxLength) {
      return textConstants.Reminders.titleMaxLength;
    }
    else if (this.reminderForm.title.length === 0) {
      return textConstants.Reminders.titleRequired
    }
    else {
      return ""
    }
  }

  // Saves the Reminder
  public async saveReminder(is_active: boolean = true) {

    // Checks if the input is valid
    this.isValidInput = this.validateInput()

    if (this.isValidInput) {

      // Adds a loading spinner
      this.isLoading = true;

      // Populates the save command with all the information from the reminder Form
      let saveCommand = this.populateSaveCommand(is_active)

      // Checks if there is an id for the reminder form
      if (this.reminderForm.id !== "" && this.reminderForm.id) {

        // If there is an Id we know that its updating rather than saving
        saveCommand.id = this.reminderForm.id

        // Updates reminders on front end if it is a demo client
        if (this.isDemoClient) {
          this.updateMockDataReminder(saveCommand)

          await this.reloadReminders()
          return
        }

        // Updates the reminder
        let result = (await this.actionListApiClient.updateReminder(saveCommand))

        // if the form is successful we want to get the reminder list again.
        if (result.status == HTTPResponseCodes.Ok) {
          await this.reloadReminders()
        }
      }
      else {

        // Saves on frontend if it is a demo client
        if (this.isDemoClient) {
          this.saveMockDataReminder(saveCommand)

          await this.reloadReminders()
          return
        }

        // if there is no reminderForm id then we know its a save Reminder
        let result = (await this.actionListApiClient.saveReminder(this.clientId, saveCommand))

        if (result.status == HTTPResponseCodes.Ok) {

          // Reloads Reminders
          await this.reloadReminders()
        }
      }
    }
  }

  // Reload reminders after save or update.
  private async reloadReminders() {
    await this.getReminders().finally(() => {
      this.isLoading = false;
    });

    this.isFormEdit = false;
  }

  // Sets the reminder data to be equal to the selected item.
  public editReminder(reminderId: string) {
    this.reminderForm = new ReminderData();

    let selectedReminder = this.listReminderLookups.find(reminder => reminderId === reminder.id)

    // Assigns the values if there is a selected Reminder
    if (selectedReminder) {
      this.reminderForm.title = selectedReminder.title;
      this.reminderForm.description = selectedReminder.description;
      this.reminderForm.id = selectedReminder.id;
      this.reminderForm.is_active = selectedReminder.is_active;
      this.reminderForm.time = selectedReminder.time;

      this.isFormEdit = true
    }
  }

  // Updates the Reminder when you have completed your reminder
  public async updateReminderCompletion(reminderId: string) {
    let selectedReminder = this.listReminderLookups.find(reminder => reminderId === reminder.id)

    // If there is a selected reminder, assign this to the reminder form
    if (selectedReminder) {
      selectedReminder.is_active = !selectedReminder.is_active

      this.reminderForm.id = reminderId;
      this.reminderForm.title = selectedReminder.title;

      // Save the reminder with the is_active property of the item.
      await this.saveReminder(selectedReminder.is_active)
    }
  }

  // Limits the description length and adds the ... after the length has been reached
  public limitDescriptionLength(description: string) {
    let descriptionLimit = 200;

    let limitedDescription = description.length > descriptionLimit ? `${description.slice(0, descriptionLimit)}...` : description;

    return limitedDescription;
  }

  // Updating Stored Reminders
  public updateMockDataReminder(saveCommand: ReminderSaveCommand) {

    let reminder = this.listReminderLookups.find(reminder => reminder.id === saveCommand.id)

    if (reminder) {
      this.populateReminderLookupForMockData(reminder, saveCommand)

      if (reminder.is_active === false) {
        this.listReminderLookups.remove(reminder)
      }
    }
  }

  // Storing Reminders on the frontend
  public saveMockDataReminder(saveCommand: ReminderSaveCommand) {
    let reminderData = new ReminderData();

    this.populateReminderLookupForMockData(reminderData, saveCommand)

    reminderData.id = reminderData.time.toUTCString()

    this.listReminderLookups.push(reminderData)
  }

  // Populates the ReminderData with the SaveCommand
  private populateReminderLookupForMockData(reminderData: ReminderData, saveCommand: ReminderSaveCommand) {

    reminderData.description = saveCommand.description
    reminderData.title = saveCommand.title
    reminderData.time = saveCommand.time
    reminderData.is_active = saveCommand.is_active

    return reminderData;
  }
}