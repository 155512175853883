import { NeoModel, List} from '@singularsystems/neo-core';
import TargetMarketAccountTab from './../../Models/Maintenance/TargetMarketAccountTab';
import { AppService, Types } from '../../Services/AppService';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';

@NeoModel
export default class TargetMarketAccountTabVM extends Views.ViewModelBase {
    
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private targetMarketAccountTabsApiClient =  AppService.get(Types.ApiClients.TargetMarketAccountTabsApiClient)) {

        super(taskRunner);
    }

    // Properties
    public targetMarketAccountTabList = new List(TargetMarketAccountTab);

    public async initialise() {  
        this.getTargetMarketAccountTabs()
    }

    public saveTargetMarketAccountTabList() {
    
        this.taskRunner.run(async () => {
            const response = await this.targetMarketAccountTabsApiClient.saveList(this.targetMarketAccountTabList.toJSArray());
            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.TMATabSaved);
            this.targetMarketAccountTabList.update(response.data)
        });
    }

    public async getTargetMarketAccountTabs(){
        const response = await this.taskRunner.waitFor(this.targetMarketAccountTabsApiClient.get());
        this.targetMarketAccountTabList.set(response.data);
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}