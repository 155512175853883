import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import TargetMarketFunction from '../Models/Maintenance/TargetMarketFunction';
import { AppService, Types } from '../Services/AppService';

export interface ITargetMarketFunctionsApiClient extends Data.IUpdateableApiClient<TargetMarketFunction, number> {

}

@injectable()
export default class TargetMarketFunctionsApiClient extends Data.UpdateableApiClient<TargetMarketFunction, number> implements ITargetMarketFunctionsApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/targetMarketFunctions`);
    }
}