import React from 'react';
import { Views, NeoGrid, Neo } from '@singularsystems/neo-react';
import EmployeeSizeVM from './EmployeeSizeVM';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import GoBack from '../../Components/GoBack';
import * as Icon from 'react-feather';

@observer
export default class CompanySizeView extends Views.ViewBase<EmployeeSizeVM> {

	constructor(props: unknown) {
		super("Company Size", EmployeeSizeVM, props);
	}

	onLeave() {
		this.viewModel.removeNotifications();
		return undefined;
	}

	public render() {
		return (
			<>
				<GoBack />
				<Neo.Card>
					<h2 className='p-24 mt-4'>{textConstants.titleText.NumberOfEmployees}</h2>
					<Neo.Form model={this.viewModel.employeeSizeList} onSubmit={() => this.viewModel.saveEmployeeSizeList()} showSummaryModal>
						<NeoGrid.Grid items={this.viewModel.employeeSizeList} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
							{(employeeSize, employeeSizeMeta) => (
								<NeoGrid.Row >
									<NeoGrid.Column bind={employeeSizeMeta.employeeSizeName} />
									<NeoGrid.Column bind={employeeSizeMeta.employeeMinSize} />
									<NeoGrid.Column bind={employeeSizeMeta.employeeMaxSize} />
									<NeoGrid.Column bind={employeeSizeMeta.employeeSizeFactor} />
									<NeoGrid.ButtonColumn showDelete
										deleteButton={{
											icon: <Icon.X size={24} />,
											variant: "light",
											className: "circled"
										}
										} />
								</NeoGrid.Row>
							)}
						</NeoGrid.Grid>
						<div className="my-4 text-right p-24">
							<Neo.Button className="btn-width-100" isSubmit variant="success">{textConstants.buttonText.Save}</Neo.Button>
						</div>
					</Neo.Form>
				</Neo.Card>
			</>
		);
	}
}