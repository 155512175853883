import { textConstants } from "common/textConstants";
import { observer } from "mobx-react";
import React from "react";

interface IPageSizeSelector {
    pageSize: number,
    pageSizeOptions?: [],
    isDashboard?: boolean,
    setPageSize: (pageSize: string) => void
}

@observer
class PageSizeSelector extends React.Component<IPageSizeSelector> {

    private setMargin() {
        if (this.props.isDashboard) {
            return "marginTop12"
        }
        return "marginTop8"
    }
    
    render() {

        let pageSizeOptions: string[] = []
        let defaultPageSizeOptions = ["5", "8", "10", "20", "50", "100"]
        pageSizeOptions = this.props.pageSizeOptions ?? defaultPageSizeOptions

        return (
            <>
                <span className={`ml-1 ${this.setMargin()} mr-2`}>{textConstants.ActionListText.ResultsPerPage}</span>

                <select
                    defaultValue={this.props.pageSize}
                    onChange={(event) => this.props.setPageSize(event.target.value)}
                >
                    {pageSizeOptions.map((option) => (
                        <option
                            key={option}
                            value={option}
                        >
                            {option}
                        </option>
                    ))}
                </select>
            </>
        )
    }
}

export default PageSizeSelector