import React from 'react';
import { observer } from 'mobx-react';
import { Neo, NeoGrid, } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import * as Roles from '../../Models/Security/Roles';
import { AppService, Types, } from '../../Services/AppService';
import ClientCampaigMessageVM from './ClientCampaignMessageVM';
import CustomPagingControl from '../../Components/CustomPagingControl';
import SearchField from '../../Components/SearchField';
import ClientCampaignMessageLookup from '../../Models/CampaignMessage/ClientCampaignMessageLookup';
import Select from 'react-select';
import { List } from '@singularsystems/neo-core';
import TMLookup from '../../Models/CampaignMessage/TMLookup';
import * as Icon from 'react-feather';
import FollowUpTemplatesView from './FollowUpTemplatesView';
import ClientNavigationButtons from '../../Components/ClientNavigationButtons';
import InfoBox from '../../Components/InfoBox';
import { StyledButtonWrapper, StyledButton, StyledButtonGroupWrapper } from 'Components/Button/styles';
import DataTooltip from 'Components/DataTooltip';
import Button from 'Components/Button/Button';

interface IClientCampaignMessageViewProps {
  viewModel: ClientCampaigMessageVM,
  openAddEmailSignature: () => void,
  openEditEmailSignature: (clientCampaignMessageId: number, clientCampaignMessageStatusId: number) => void,
  openAddCampaignMessage: () => void,
  openAddFollowUpTemplate: () => void,
  openEditCampaignMessage: (clientCampaignMessageId: number, clientCampaignMessageStatusId: number) => void,
  openEditFollowUpTemplate: (clientFollowUpTemplateId: number, clientCampaignMessageStatusId: number) => void,
  back: () => void
}

@observer
export default class ClientCampaignMessageView extends React.Component<IClientCampaignMessageViewProps> {

  private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);
  protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService)

  public securityCheck(role: any, openMethod: () => void) {
    if (this.authorisationService.hasRole(role)) {
      openMethod();
    }
    else {
      this.props.viewModel.showForbiddenModal = true;
    }
  }

  public async addCampaignMessage() {
    this.props.openAddCampaignMessage()
  }

  public async addCampaignSignature() {
    this.props.openAddEmailSignature()
  }

  public async editCampaignMessage(clientCampaignMessageId: number, messageType: string, clientCampaignMessageStatusId: number) {
    if (messageType.toLocaleLowerCase().includes("signature")) {
      this.props.openEditEmailSignature(clientCampaignMessageId, clientCampaignMessageStatusId)
    }
    else {
      this.props.openEditCampaignMessage(clientCampaignMessageId, clientCampaignMessageStatusId)
    }
  }

  public async cloneCampaignMessage(clientCampaignMessageId: number, campaignMessageType: string) {
    this.securityCheck(Roles.CampaignMessages.Clone, () => this.props.viewModel.openCloneModal(clientCampaignMessageId, campaignMessageType))
  }

  public async undoCampaignMessageDelete(campaignMessageLookup: ClientCampaignMessageLookup) {
    this.securityCheck(Roles.CampaignMessages.Manage, () => this.props.viewModel.openUndoCampaignMessageDeleteModal(campaignMessageLookup));
  }

  public displaySearch() {
    const condition1 = this.props.viewModel.isArchived && this.props.viewModel.pageManager.totalRecords === 0;
    const condition2 = this.props.viewModel.pageManager.totalRecords > 0;
    const condition3 = this.props.viewModel.pageManager.hasFetched;
    return (condition1 || condition2 || !this.props.viewModel.displayOverlay) && condition3;
  }

  handleChange = (selectChange: any) => {
    this.props.viewModel.selectedTargetMarkets = new List(TMLookup);

    if (selectChange !== null && selectChange.length > 0) {

      selectChange.forEach((tm: any) => {
        let tmLookup = new TMLookup();
        tmLookup.targetMarketId = tm.value;
        tmLookup.targetMarketName = tm.label;

        this.props.viewModel.selectedTargetMarkets!.push(tmLookup)
      })
    }
    else {
      this.props.viewModel.showCloneTargetMarketError = true;
    }

    this.props.viewModel.setTargetMarketList();
  }

  public render() {
    const { isComXUser, searchFieldVM } = this.props.viewModel

    const dropdownStyle = {
      clearIndicator: (styles: any) => {
        return {
          ...styles,
          color: 'red',
          ":hover": {
            color: 'red'
          }
        }
      },
      multiValue: (styles: any) => {
        return {
          ...styles,
          backgroundColor: '#7CBFC5',
          borderRadius: '15px'
        };
      },
      multiValueLabel: (styles: any) => ({
        ...styles,
        color: 'white',
      }),
      multiValueRemove: (styles: any) => ({
        ...styles,
        color: 'red',
        ':hover': {
          backgroundColor: '#7CBFC5',
          color: 'red',
          borderRadius: '15px'
        },
      }),
    }

    const campaignMessages =
      <Neo.Tab
        onDisplayed={() => {
          this.props.viewModel.toggleTab("", this.props.viewModel.isComingFromFollowUpTemplates ? textConstants.titleText.FollowUpTemplates : textConstants.titleText.CampaignMessage, this.props.viewModel.clientId)
        }}
        header={textConstants.titleText.CampaignMessage}>
        <div className={"ClientCampaignsView negSideMargins24"}>
          <div className="row mb-0 ml-0 mr-0">
            <div className="col-12 p-24 pull-right">
              {/* Add Campaign Message */}
            </div>
          </div>
          <div className="row m-0">
            {
              <div className="col-6 p-24 justify-content-left" >
                {isComXUser &&
                  <ClientNavigationButtons viewModel={this.props.viewModel.clientNavigationButtons} back={this.props.back} />}
              </div>
            }
          </div>
          {
            <div>

              <div className="col-lg-4 col-md-5 col-sm-12">

              </div>
              <div className="row m-0 ">
                <div className="archiveTabHeader col-3 pull-right p-24">
                  <Neo.TabContainer className="mt-2 mb-2 m-0" variant="flat">
                    <Neo.Tab header="Current" onDisplayed={() => {
                      this.props.viewModel.hideGrid = true;
                      this.props.viewModel.searchCriteria.isDeleted = false;
                      this.props.viewModel.isArchived = false
                      this.props.viewModel.isActiveNameEditCampaignId = 0;
                    }}>
                    </Neo.Tab>
                    <Neo.Tab header="Archived" onDisplayed={() => {
                      this.props.viewModel.hideGrid = false;
                      this.props.viewModel.searchCriteria.isDeleted = true;
                      this.props.viewModel.isArchived = true
                      this.props.viewModel.isActiveNameEditCampaignId = 0;
                    }}>
                    </Neo.Tab>
                  </Neo.TabContainer>
                </div>

                <div className='col-9 p-24'>
                  {/* Add AI Message */}
                  {isComXUser &&
                    <Button
                      buttonType='primary'
                      className="pull-right ml-4 mt-0 mb-1"
                      variant="hasIcon"
                      size="xSmall"
                      onClick={() => this.props.viewModel.openAIModal()}
                    >
                      <Icon.Plus />
                      {textConstants.buttonText.AddAIMessage}
                    </Button>
                  }

                  {/* Add Campaign Message */}
                  {isComXUser && this.authorisationService.hasRole(Roles.CampaignMessages.AddEditCampaignMessages) &&
                    <Button
                      buttonType='primary'
                      className="pull-right ml-4 mt-0 mb-1"
                      variant="hasIcon"
                      size="xSmall"
                      onClick={() => this.addCampaignMessage()}
                    >
                      <Icon.Plus />
                      {textConstants.buttonText.AddCampaignMessage}
                    </Button>
                  }

                  {/* Add Email Signature */}
                  {isComXUser && this.authorisationService.hasRole(Roles.CampaignMessages.AddEditCampaignMessages) &&
                    <Button
                      buttonType='primary'
                      className="pull-right ml-4 mt-0 mb-1"
                      variant="hasIcon"
                      size="xSmall"
                      onClick={() => this.addCampaignSignature()}
                    >
                      <Icon.Plus />
                      {textConstants.buttonText.AddEmailSignature}
                    </Button>
                  }

                  {/* Search field */}
                  {this.displaySearch() && // this only hides the search if its not archived
                    <SearchField
                      onClickFunction={(e) => {
                        this.props.viewModel.pageManager.refreshData();
                        searchFieldVM.hideNoResultDialog = true;
                      }}
                      searchBindableProp={this.props.viewModel.searchCriteria.meta.searchString}
                      disabled={this.props.viewModel.pageManager.totalRecords === 0 && this.props.viewModel.isArchived}
                      searchString={this.props.viewModel.searchCriteria.searchString}
                      searchFieldVM={searchFieldVM}
                    />}
                </div>
              </div>
            </div>
          }

          {this.props.viewModel.pageManager.totalRecords === 0 && this.props.viewModel.hideGrid === false &&
            searchFieldVM.isSearching && !this.authenticationService.globalProps.isOnboarding &&
            <InfoBox header={textConstants.titleText.NoSearchMatches}
              text={textConstants.generalText.NoSearchMatches} />
          }

          {/* Client Campaign Messages grid */}
          {this.props.viewModel.pageManager.totalRecords === 0 && this.props.viewModel.isArchived &&
            !searchFieldVM.isSearching && !searchFieldVM.hideNoResultDialog &&
            <div className="ClientCampaignsView" >
              <InfoBox header={textConstants.titleText.NoArchivedCampaignMessages}
                text={textConstants.generalText.NoArchivedCampaignMessagesText} />
            </div>
          }

          {this.props.viewModel.pageManager.totalRecords === 0 && !this.props.viewModel.isArchived &&
            this.props.viewModel.hideGrid === false && !searchFieldVM.isSearching && !searchFieldVM.hideNoResultDialog &&
            !this.authenticationService.globalProps.isOnboarding &&
            <div className="ClientCampaignsView" >
              <InfoBox text={textConstants.generalText.NoCampaignMessagesText}
                header={textConstants.titleText.NoCampaignMessages} />
            </div>
          }

          {this.props.viewModel.pageManager.totalRecords === 0 && !this.props.viewModel.isArchived &&
            this.props.viewModel.hideGrid === false && !searchFieldVM.isSearching && !searchFieldVM.hideNoResultDialog &&
            this.authenticationService.globalProps.isOnboarding &&
            <InfoBox text={textConstants.generalText.NoCampaignMessagesText}
              header={textConstants.titleText.NoCampaignMessages} />
          }

          {this.props.viewModel.pageManager.totalRecords > 0 &&
            this.props.viewModel.hideGrid === false &&
            <div className="row m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0 campaignGridMargin">
                <React.Fragment>
                  <Neo.Pager pageManager={this.props.viewModel.pageManager} pageControls="none">
                    {(data) => (
                      <NeoGrid.Grid<ClientCampaignMessageLookup> isBordered={false} items={this.props.viewModel.pageManager}>
                        {(ClientCampaignMessageLookup, meta) => (
                          <NeoGrid.Row  >

                            {/* Name */}
                            <NeoGrid.Column label={textConstants.titleText.Name} sort={meta.campaignName} alignment={'left'} >
                              <div className={this.props.viewModel.isArchived ? "" : "clickableCursor"} onClick={() => {
                                if (!this.props.viewModel.isArchived && !this.props.viewModel.isNameEditing(ClientCampaignMessageLookup.clientCampaignMessageId)) {
                                  this.props.viewModel.previousGridPageNumber = this.props.viewModel.pageManager.pageNo
                                  this.editCampaignMessage(ClientCampaignMessageLookup.clientCampaignMessageId,
                                    ClientCampaignMessageLookup.campaignMessageType, ClientCampaignMessageLookup.campaignMessageStatusId)
                                }
                              }}>
                                {ClientCampaignMessageLookup.campaignName}
                              </div>
                            </NeoGrid.Column>

                            {/* Status */}
                            {
                              (!this.props.viewModel.isArchived) &&
                              <NeoGrid.Column
                                className={"TMVColumnWidthName"}
                                label={textConstants.titleText.Status}
                                sort={ClientCampaignMessageLookup.meta.campaignMessageStatus}
                              >
                                <div>
                                  <div className="pl-0 pr-0" >
                                    <span>{ClientCampaignMessageLookup.campaignMessageStatus}</span>
                                  </div>
                                </div>

                              </NeoGrid.Column>
                            }

                            {/* TargetMarkets */}
                            <NeoGrid.Column
                              className="TMVColumnWidthName"
                              label={textConstants.titleText.TargetMarkets}
                              data-tip={ClientCampaignMessageLookup.targetMarkets.includes('...') ? ClientCampaignMessageLookup.targetMarketsFull : null}
                            >
                              <div>
                                <div className="pl-0 pr-0"><span>{ClientCampaignMessageLookup.targetMarkets}</span></div>
                              </div>
                            </NeoGrid.Column>

                            {/* Message Type */}
                            <NeoGrid.Column
                              label={textConstants.titleText.MessageType}
                              sort={meta.campaignMessageType}

                            >
                              <div>
                                <div className="pl-0 pr-0">
                                  <span>{ClientCampaignMessageLookup.campaignMessageType}</span>
                                </div>
                              </div>
                            </NeoGrid.Column>

                            {/* Actions */}
                            <NeoGrid.ButtonColumn className="text-center pl-0 pr-0 pb-0 pt-0" >
                              <StyledButtonWrapper>
                                {!this.props.viewModel.isArchived &&
                                  <StyledButtonGroupWrapper>
                                    {this.authorisationService.hasRole(Roles.CampaignMessages.AddEditCampaignMessages) &&
                                      <DataTooltip
                                        parent={
                                          <StyledButton
                                            isIconButton={true}
                                            onClick={() => {
                                              this.props.viewModel.previousGridPageNumber = this.props.viewModel.pageManager.pageNo
                                              this.editCampaignMessage(ClientCampaignMessageLookup.clientCampaignMessageId,
                                                ClientCampaignMessageLookup.campaignMessageType, ClientCampaignMessageLookup.campaignMessageStatusId)
                                            }}>
                                            <Icon.Edit size={16} />
                                          </StyledButton>}>
                                        {textConstants.buttonText.Edit}
                                      </DataTooltip>
                                    }

                                    {/* Clone */}
                                    {this.authorisationService.hasRole(Roles.CampaignMessages.Clone) &&
                                      <DataTooltip
                                        parent={
                                          <StyledButton
                                            isIconButton={true}
                                            onClick={() =>
                                              this.cloneCampaignMessage(ClientCampaignMessageLookup.clientCampaignMessageId, ClientCampaignMessageLookup.campaignMessageType)
                                            }>
                                            <Icon.Copy size={16} />
                                          </StyledButton>}>
                                        {textConstants.buttonText.Clone}
                                      </DataTooltip>
                                    }
                                  </StyledButtonGroupWrapper>
                                }

                                {/* Archive */}
                                {!this.props.viewModel.isArchived &&
                                  <DataTooltip
                                    parent={
                                      <StyledButton
                                        isIconButton={true}
                                        onClick={() => {
                                          this.securityCheck(Roles.CampaignMessages.Manage, () => this.props.viewModel.openDeleteModal(ClientCampaignMessageLookup));
                                        }}>
                                        <Icon.Archive size={16} />
                                      </StyledButton>}>
                                    {textConstants.buttonText.Archive}
                                  </DataTooltip>
                                }

                                {/* Un-Archive */}
                                {this.props.viewModel.isArchived &&
                                  <DataTooltip
                                    parent={
                                      <StyledButton
                                        isIconButton={true}
                                        onClick={() => {
                                          this.undoCampaignMessageDelete(ClientCampaignMessageLookup);
                                        }}>
                                        <Icon.RotateCw size={16} />
                                      </StyledButton>}>
                                    {textConstants.buttonText.Unarchive}
                                  </DataTooltip>
                                }
                              </StyledButtonWrapper>
                            </NeoGrid.ButtonColumn>
                          </NeoGrid.Row>
                        )}
                      </NeoGrid.Grid>
                    )}
                  </Neo.Pager>

                  <div className="col-12 p-24">
                    <CustomPagingControl
                      displayLabel={true}
                      pageManager={this.props.viewModel.pageManager} />
                  </div>
                </React.Fragment>
              </div>
            </div>
          }

          {/* Clone Campaign Message Modal */}
          <Neo.Modal
            title="Clone Campaign Message"
            show={this.props.viewModel.showCampaignMessagesCloneModal}
            formProps={{ model: this.props.viewModel.cloneCampaignMessageCommand }}
            onClose={() => {
              this.props.viewModel.showCampaignMessagesCloneModal = false;
              this.props.viewModel.showCloneTargetMarketError = false;
            }}
            closeButton={{
              text: isComXUser ? textConstants.buttonText.SaveAsDraft : textConstants.buttonText.SaveAsActive,
              variant: "secondary",
              isSubmit: true,
              onClick: () => this.props.viewModel.cloneCampaignMessageOrEmailSignature()
            }}
          >
            <Neo.GridLayout md={1}>
              <Neo.FormGroup
                bind={this.props.viewModel.cloneCampaignMessageCommand.meta.campaignName}
              />
              <br />
              <label>Select all affected Target Markets</label>
              <Select
                styles={dropdownStyle}
                value={
                  this.props.viewModel.selectedTargetMarkets !== null &&
                  this.props.viewModel.selectedTargetMarkets.map(t => {
                    if (t !== undefined) {
                      return {
                        value: t.targetMarketId,
                        label: t.targetMarketName,
                      };
                    } else {
                      return null;
                    }
                  }) || []
                }
                isMulti
                closeMenuOnSelect={false}
                onChange={this.handleChange}
                options={this.props.viewModel.targetMarketOptions}
              />
              {
                <p
                  className="invalid-feedback error"
                  style={this.props.viewModel.showCloneTargetMarketError ? { display: "block" } : { display: "none" }}
                >
                  You must select at least one Target Market.
                </p>}
            </Neo.GridLayout>
          </Neo.Modal>

          {/* Clone Email Signature Modal */}
          <Neo.Modal
            title="Clone Email Signature"
            show={this.props.viewModel.showSignatureCloneModal}
            formProps={{ model: this.props.viewModel.cloneCampaignMessageCommand }}
            onClose={() => this.props.viewModel.showSignatureCloneModal = false}
            closeButton={{
              text: isComXUser ? textConstants.buttonText.SaveAsDraft : textConstants.buttonText.SaveAsActive,
              variant: "secondary",
              isSubmit: true,
              onClick: () => this.props.viewModel.cloneCampaignMessageOrEmailSignature(true)
            }}
          >
            <Neo.GridLayout md={1}>
              <Neo.FormGroup
                className='validationText'
                placeholder={textConstants.generalText.placeHolderTypeHereText}
                bind={this.props.viewModel.cloneCampaignMessageCommand.meta.campaignName} />
            </Neo.GridLayout>
          </Neo.Modal>

          {/* Forbidden Modal */}
          <Neo.Modal
            title="Forbidden"
            show={this.props.viewModel.showForbiddenModal}
            onClose={() => this.props.viewModel.showForbiddenModal = false}
            closeButton={{ text: "Cancel", variant: "light" }}>
            <p>You have insufficient privileges to perform this operation.</p>
          </Neo.Modal>

          {/* Delete Campaign Message Modal */}
          <Neo.Modal title={`Delete confirmation`} show={this.props.viewModel.showDeleteModal}
            onClose={() => this.props.viewModel.showDeleteModal = false}
            closeButton={{ text: "No", variant: "light" }}
            acceptButton={{
              text: "Yes", variant: "secondary",
              onClick: () => this.props.viewModel.deleteClientCampaignMessage()
            }}>
            Campaign Message &#34;{this.props.viewModel.selectedCampaignMessageName}&#34; is about to be archived. Do you wish to continue?
          </Neo.Modal>

          {/* Undo Campaign Message Delete Modal */}
          <Neo.Modal
            title={`Undo Archive Confirmation`}
            show={this.props.viewModel.showUndoDeleteModal}
            onClose={() => this.props.viewModel.showUndoDeleteModal = false}
            closeButton={{ text: "No", variant: "light" }}
            acceptButton={{ text: "Yes", variant: "secondary", onClick: () => this.securityCheck(Roles.CampaignMessages.Manage, () => this.props.viewModel.undoCampaignMessageDelete()) }}>
            Campaign Message &#34;{this.props.viewModel.selectedCampaignMessageName}&#34; is about to be unarchived. Do you wish to continue?
          </Neo.Modal>

          {/* AI Copy Creation modal */}
          <Neo.Modal
            title={textConstants.ModalText.AICopyCreation}
            show={this.props.viewModel.showAIModal}
            formProps={{ model: this.props.viewModel.createAICampaignMessageCommand }}
            onClose={() => this.props.viewModel.showAIModal = false}
            closeButton={{
              text: textConstants.buttonText.createAI,
              variant: "secondary",
              onClick: () => this.props.viewModel.generateCampaignMessageViaAIAsync(),
              disabled: this.props.viewModel.createAICampaignMessageCommand.customerProfileId === 0 || this.props.viewModel.createAICampaignMessageCommand.templateNameId === 0
            }}
          >
            <Neo.GridLayout md={1}>
              {/* ICP dropdown */}
              <Neo.FormGroup
                className='validationText'
                bind={this.props.viewModel.createAICampaignMessageCommand.meta.customerProfileId}
                select={{ items: this.props.viewModel.clientICPList }}
                label={textConstants.generalText.selectICP}
              />
              <br></br>
              {/* Template Version dropdown */}
              <Neo.FormGroup
                className='validationText'
                bind={this.props.viewModel.createAICampaignMessageCommand.meta.templateNameId}
                select={{ items: this.props.viewModel.templateList }}
                label={textConstants.generalText.selectTemplate}
              />
            </Neo.GridLayout>
          </Neo.Modal>
        </div >
      </Neo.Tab>


    {/* Follow Up Templates */ }
    const followUpTemplates =
      <Neo.Tab
        header={textConstants.titleText.FollowUpTemplates}
        onDisplayed={() => {
          this.props.viewModel.toggleTab("", textConstants.titleText.FollowUpTemplates, this.props.viewModel.clientId)
        }}>
        <FollowUpTemplatesView
          viewModel={this.props.viewModel.followUpTemplatesVM}
          navigation={this.props.viewModel.clientNavigationButtons}
          back={this.props.back}
          openFollowUpTemplate={() => this.props.openAddFollowUpTemplate()}
          openEditFollowUpTemplate={(clientFollowUpTemplateId: number, clientCampaignMessageStatusId: number) =>
            this.props.openEditFollowUpTemplate(clientFollowUpTemplateId, clientCampaignMessageStatusId)}
        />
      </Neo.Tab>

    return (
      <div>
        {/* Back button */}
        {!this.authenticationService.globalProps.isOnboarding && isComXUser &&
          <Neo.Button
            className="btn-width-100 mt-3 marginLeft24"
            variant="light"
            icon={<Icon.ArrowLeft />}
            onClick={async (e: any) => {
              await this.props.back()
            }}
          >
            {textConstants.buttonText.Back}
          </Neo.Button>
        }
        <Neo.Card>
          <div className='campaignsTabHeader mt-3'>
            <Neo.TabContainer
              tabManager={this.props.viewModel.tabManager}
              className='mt-2 mb-2 m-0 p-0'
              onClick={() => this.props.viewModel.previousGridPageNumber = 1}>
              {campaignMessages}
              {followUpTemplates}
            </Neo.TabContainer>
          </div>
        </Neo.Card>
      </div>
    );
  }
}