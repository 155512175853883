import React, { useState, useRef, useEffect } from "react";
import {
  StyledNavigationWrapper,
  StyledNavigationContent,
  StyledNavigationItem,
  StyledNavigationItemHeading,
  StyledNavigationItemContent,
  ScreenNavigatorBar,
} from "./ScreenNavigator.styles";
import ChevronDown from "assets/svgs/ChevronDown";
import ChevronUp from "assets/svgs/ChevronUp";
import StepInactive from "assets/svgs/StepInactive";
import StepActive from "assets/svgs/StepActive";
import { AppService, Types } from "Services/AppService";
import NavigatorStep from "Models/Navigation/NavigatorStep";

import useClickOutside from "hooks/useClickOutSide";
import OnboardingFunctions from "Models/Onboarding/OnboardingFunctions";

interface ScreenNavigatorProps {
  goToStep: (id: number) => void;
  steps: NavigatorStep[];
  currentStepNumber: number;
  isDisabled?: boolean;
}

const ScreenNavigator = ({
  goToStep: goToStep,
  steps: data,
  currentStepNumber,
  isDisabled,
}: ScreenNavigatorProps) => {
  const customAuthService = AppService.get(Types.Security.CustomAuthenticationService);
  const [isOpen, setOpen] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(400);
  const [steps, setSteps] = useState<NavigatorStep[]>([]);
  const NavWidth = useRef<HTMLDivElement>(null);
  const NavHeight = useRef<HTMLDivElement>(null);
  const [overviewStepOffset, setOverviewStepOffset] = useState(0)
  const [calculatedPercentage, setCalculatedPercentage] = useState<number>(0);

  const calculatePercentage = (array: NavigatorStep[]) => {
    const totalItems = array.length;
    const doneItems = array.filter(item => item.isComplete === true).length;
    setCalculatedPercentage(((doneItems >= totalItems ? totalItems : doneItems) / totalItems) * 100);

    return calculatedPercentage;
  }

  const resize = () => {
    if (NavWidth.current) {
      setWidth(NavWidth.current.offsetWidth);
    }
    if (NavHeight.current) {
      window.innerHeight > 800 ? setHeight(800) : setHeight(window.innerHeight);
    }
  };

  const prevData = useRef<NavigatorStep[]>([]);
  useEffect(() => {
    prevData.current = data;
  }, [data]);

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();
  
    if (data) {
      let hasOverviewStep = data.some(x => x.stepNumber === 0);
      if (hasOverviewStep) {
        setOverviewStepOffset(1);
      }
      if (JSON.stringify(data) !== JSON.stringify(prevData)) {
      const totalItems = data.length;
      const doneItems = data.filter(item => item.isComplete === true).length;
      const newPercentage = ((doneItems >= totalItems ? totalItems : doneItems) / totalItems) * 100;
      setCalculatedPercentage(newPercentage);
      }
      setSteps(data);
    }
  
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [data, overviewStepOffset, prevData]);

  useClickOutside(NavWidth, () => {
    setOpen(false);
  });

  return (
    <>
      <StyledNavigationWrapper ref={NavWidth} isDisabled={isDisabled}
        onClick={() => setOpen(!isOpen)}>

        {/* Screen Navigator */}
        <div className={`screenNavigator row m-0 ${calculatedPercentage === 100 ? 'allItemsComplete' : ''}`}>

          {/* Navigation steps completed bar */}
          <div className="col navigationBar">
            <ScreenNavigatorBar progress={calculatedPercentage} />
          </div>

          {/* Navigator button dropdown */}
          {steps.length - overviewStepOffset !== -1 && <div className="navigatorButton">
            <div className="row m-0">

              {/* completed steps */}
              <div className="stepsCompleted">
                {currentStepNumber} / {steps.length - overviewStepOffset}
              </div>
              <div className="navigatorDropdownButton">
                {isOpen ? <ChevronUp /> : <ChevronDown />}
              </div>
            </div>
          </div>}
        </div>
      </StyledNavigationWrapper>


      {/* Dropdown list */}
      <StyledNavigationContent isOpen={isOpen} key={data.length} width={width} height={height} ref={NavHeight}>
        {steps.length > 0 && (
          <>
            {steps.map((step: NavigatorStep) => (
              <StyledNavigationItem
                key={step.id}
                onClick={() => {
                  customAuthService.onboardingFunctions.getRelevantSectionCode(step.id)
                  goToStep(step.id);
                }}
              >
                <StyledNavigationItemHeading>
                  {step.isComplete ? <StepActive /> : <StepInactive />}
                  {step.stepNumber != 0 ? `Step ${step.stepNumber}` : 'Overview'}
                </StyledNavigationItemHeading>
                <StyledNavigationItemContent
                  dangerouslySetInnerHTML={{
                    __html: `${step.stepText}`,
                  }}
                />
              </StyledNavigationItem>
            ))}
          </>
        )}
      </StyledNavigationContent>
    </>
  );
};

export default ScreenNavigator;