import Axios, { AxiosPromise } from "axios";
import { injectable } from "inversify";
import { AppService, Types } from "../Services/AppService";
import ActionListCriteria from "Models/ActionList/Query/ActionListCriteria";
import ApiResult from "Models/ApiResult";
import EmailCommand from "Models/ComXMailer/Email";
import ReminderCriteria from "Models/Reminder/Query/ReminderCriteria";
import ReminderSaveCommand from "Models/Reminder/Query/ReminderSaveCommand";

export interface IActionListApiClient {
    getActionList(criteria: ActionListCriteria): AxiosPromise<ApiResult>;
    getReply(clientId: number, prospectId: number, campaignId: number, replyId: string): AxiosPromise<ApiResult>;
    getActionListSummary(clientId: number): AxiosPromise<ApiResult>;
    getActionListArchivedStatuses(clientId: number): AxiosPromise<ApiResult>;
    setReplyAsSeen(clientId: number, replytId: string): AxiosPromise<ApiResult>;
    archiveOrUnarchiveSelectedLeads(clientId: number, isArchive: boolean, replyIds: string[]): AxiosPromise<ApiResult>;
    sendEmail(body: EmailCommand, replyId: string): AxiosPromise<ApiResult>;
    getReminders(criteria: ReminderCriteria): AxiosPromise<ApiResult>;
    saveReminder(clientId: number, saveCommand: ReminderSaveCommand): AxiosPromise<ApiResult>;
    updateReminder(saveCommand: ReminderSaveCommand): AxiosPromise<ApiResult>;
}

@injectable()
export default class ActionListApiClient implements IActionListApiClient {

    constructor(public config = AppService.get(Types.Config)) {
    }

    public getActionList(criteria: ActionListCriteria): AxiosPromise<ApiResult> {
        return (Axios.get(`${this.config.apiPath}/ActionList/GetActionListPagedAsync`, { params: criteria.toJSObject() }));
    }

    public getActionListSummary(clientId: number): AxiosPromise<ApiResult> {
        return (Axios.get(`${this.config.apiPath}/ActionList/GetActionListSummaryAsync?clientId=${clientId}`));
    }

    public getReply(clientId: number, prospectId: number, campaignId: number, replyId: string): AxiosPromise<ApiResult> {
        return (Axios.get(`${this.config.apiPath}/ActionList/GetReplyAsync?clientId=${clientId}&campaignId=${campaignId}&prospectId=${prospectId}&replyId=${replyId}`));
    }

    public setReplyAsSeen(clientId: number, replyId: string): AxiosPromise<ApiResult> {
        return (Axios.post(`${this.config.apiPath}/ActionList/SetReplyAsSeenAsync?clientId=${clientId}&replyId=${replyId}`));
    }

    public archiveOrUnarchiveSelectedLeads(clientId: number, isArchive: boolean, replyIds: string[]): AxiosPromise<ApiResult> {
        return (Axios.post(`${this.config.apiPath}/ActionList/ArchiveOrUnarchiveSelectedLeadsAsync?clientId=${clientId}&isArchive=${isArchive}`, replyIds))
    }

    public getActionListArchivedStatuses(clientId: number): AxiosPromise<ApiResult> {
        return (Axios.get(`${this.config.apiPath}/ActionList/GetActionListArchivedStatuses?clientId=${clientId}`));
    }

    public sendEmail(body: EmailCommand, replyId: string): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/ActionList/SendEmailAsync?replyId=${replyId}`, body);
    }
    public getReminders(criteria: ReminderCriteria): AxiosPromise<ApiResult> {
        return (Axios.get(`${this.config.apiPath}/ActionList/GetReminders`, { params: criteria.toJSObject() }));
    }

    public saveReminder(clientId: number, saveCommand: ReminderSaveCommand): AxiosPromise<ApiResult> {
        return (Axios.post(`${this.config.apiPath}/ActionList/SaveReminder?clientId=${clientId}`, saveCommand.toJSObject()));
    }

    public updateReminder(saveCommand: ReminderSaveCommand): AxiosPromise<ApiResult> {
        return (Axios.post(`${this.config.apiPath}/ActionList/UpdateReminder`, saveCommand.toJSObject()));
    }
}