import { NeoModel } from '@singularsystems/neo-core';
import { AppService, Types } from '../../Services/AppService';
import { Managers, Views } from '@singularsystems/neo-react';
import ClientCriteria from '../../Models/Client/Query/ClientCriteria';
import TechnicalSettingsVM from '../Client/TechnicalSettingsVM';

@NeoModel
export default class SettingsVM extends Views.ViewModelBase {
    [x: string]: any;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
        private authenticationService = AppService.get(Types.Neo.Security.AuthenticationService),
        private comXHub = AppService.get(Types.ApiClients.ComXHub),
        private invitedUserApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient)) {

        super(taskRunner);
        this.connectToComXHub();
    }

    public clientCriteria = new ClientCriteria();

    // ViewParams
    public clientId: number | null = null;

    public tabManager = new Managers.TabManager();

    // Properties
    public clearAddEditNotifications: boolean = true;
    // public showNewClientModal: boolean = false;
    public clientDisplayName: string = "";
    // Navigation path list
    public navigationList = ["SettingsView"];

    // Model property
    public showForbiddenModal: boolean = false;

    public technicalSettingsVM = new TechnicalSettingsVM()

    public showWarningMessage: boolean = false

    public async checkUserNotifications(){
        await this.taskRunner.waitFor(this.invitedUserApiClient.checkNotifications());
    }

    public async getClientName(clientId: number) {
        const response = await this.taskRunner.waitFor(this.clientsApiClient.getClient(clientId));
        return response.data.clientName;
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }

    public async getClientId() {
        const { user, isAuthenticated } = this.authenticationService;

        // Fetch the platformSetup and successTracker urls
        if (isAuthenticated) {
            const response = await this.clientsApiClient.getDashboardInfo(user!.userName);
            this.clientId = response.data.clientId;
        }
    }

    public connectToComXHub() {
        this.comXHub.NotifyUploadUser.subscribe(this.NotifyUploadUser);
    }
}