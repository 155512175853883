import React from "react";
import { textConstants } from "../../common/textConstants";
import * as Icon from 'react-feather';

interface IDashboardCampaignName {
    name: string,
    status: string,
    sendOutEmail?: string
}

class DashboardCampaignName extends React.Component<IDashboardCampaignName>
{
    private toggleCampaignStatus(status: string) {
        switch (status.toLowerCase()) {
            case textConstants.titleText.Running.toLowerCase(): return "runStatus";
            case textConstants.titleText.Paused.toLowerCase(): return "pausedStatus";
            default: return "draftStatus";
        }
    }

    private toggleCampaignIcon(status: string) {
        switch (status.toLowerCase()) {
            case textConstants.titleText.Draft.toLowerCase(): return <Icon.Clock size={16} />;
            default: return "";
        }
    }

    private campaignIcon(status: string) {
        switch (status.toLowerCase()) {
            case textConstants.titleText.Draft.toLowerCase(): return "mr-2";
            default: return "";
        }
    }

    private getCampaignStatus(status: string) {
        switch (status.toLowerCase()) {
            case textConstants.titleText.Running.toLowerCase(): return textConstants.titleText.Running;
            case textConstants.titleText.Paused.toLowerCase(): return textConstants.titleText.Paused;
            case textConstants.titleText.Draft.toLowerCase(): return textConstants.titleText.Draft;
            case textConstants.titleText.Edited.toLowerCase(): return textConstants.titleText.Edited;
            case textConstants.titleText.Completed.toLowerCase(): return textConstants.titleText.Completed;
            case textConstants.titleText.Deleted.toLowerCase(): return textConstants.titleText.Deleted;
            case textConstants.titleText.Stopped.toLowerCase(): return textConstants.titleText.Stopped;
            default: return "";
        }
    }

    render() {
        return (
            <>
                {this.props.name &&
                    <>
                        <div className="campaign-item">
                            <span>
                                {
                                    this.props.name
                                }
                            </span>
                        </div>
                        <div className={`mt-2 mb-2 statusFontSize ${this.toggleCampaignStatus(this.props.status)}`}>
                            <i className={`${this.campaignIcon(this.props.status)}`}>{this.toggleCampaignIcon(this.props.status)}</i>
                            <span>
                                {
                                    this.getCampaignStatus(this.props.status).toUpperCase()
                                }
                            </span>
                        </div>
                        {this.props.sendOutEmail && // This is temp, will be removed once the email is brought from woodpecker
                            <div className="row m-0 statusFontSize draftStatus">
                                <div className="mr-2"><Icon.Mail size={16} /></div>
                                <div>{this.props.sendOutEmail.toUpperCase()}
                                </div>
                            </div>}
                    </>
                }
            </>
        )
    }
}

export default DashboardCampaignName;