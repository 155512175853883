import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class BuyingCenter extends ModelBase{

    public buyingCenterId: number = 0;

    @Rules.StringLength(500)
    @Attributes.Display("Buying Center")
    public buyingCenterName: string = "";

    public uniqueTableKey: string = ""

    public deleted: any = null

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.buyingCenterName === null || c.buyingCenterName === '', textConstants.messageText.validationMessage.buyingCenterName);
    }

    public toString(): string {
        if (this.isNew || !this.buyingCenterName) {
            return "New Buying Center";
        } else {
            return this.buyingCenterName;
        }
    }
}