import { injectable } from 'inversify';
import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import Axios, { AxiosPromise } from 'axios';
import EmployeeSizeRange from '../Models/Prospecting/EmployeeSizeRange';
import SubIndustryRangeCriteria from '../Models/Prospecting/SubIndustryRangeCriteria';
import BatchInProgressLookup from '../Models/Prospecting/BatchInProgressLookup';
import { ResponseResolver } from '../common/utils';

export interface IProspecting {
    getTemplate(isAB: boolean, isIRO: boolean): AxiosPromise<any>,
    uploadDocument(filelist: File[] | FileList, isAB: boolean, isIRO: boolean, targetMarketId: number): AxiosPromise<any>,
    downloadAccounts(numberToDownload: number | string, targetMarketId: number): AxiosPromise<any>,
    reDownloadAccounts(userid: number | string, targetMarketId: number): AxiosPromise<any>,
    getEmployeeSizeRanges(targetMarketId: number): AxiosPromise<Array<Model.PlainObject<EmployeeSizeRange>>>;
    getLPCSearch(targetMarketId: number): AxiosPromise<any>,
    getSubIndustryRanges(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SubIndustryRangeCriteria>>): AxiosPromise<any>,
    getIROSearch(targetMarketId: number): AxiosPromise<any>,
    saveIROEditStatus(targetMarketId: number, IROEditStatusId: number | null, IsIROStage: boolean): AxiosPromise<any>,
    saveIsIROStage(targetMarketId: number, IROEditStatusId: number | null, IsIROStage: boolean): AxiosPromise<any>,
    downloadLPCSearch(targetMarketId: number): AxiosPromise<any>,
    getBatchesInProgress(targetMarketId: number): AxiosPromise<Array<Model.PlainObject<BatchInProgressLookup>>>,
    DownloadUploadProspectTemplate(): AxiosPromise<any>,
    UploadProspectFile(filelist: File[] | FileList, targetMarketId: number): AxiosPromise<any>,
    DisplayRegionWarning(targetMarketId: number): AxiosPromise<boolean>;
}

@injectable()
export default class ProspectingApiClient implements IProspecting {

    constructor(private config = AppService.get(Types.Config)) {
    }

    public getTemplate(isAB: boolean, isIRO: boolean): AxiosPromise<any> {
        const option = {
            isAB,
            isIRO,
        };
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.post(`${apiPath}/Prospecting/DownloadTemplate`, option));
    }

    public uploadDocument(filelist: File[] | FileList, isAB: boolean, isIRO: boolean, targetMarketId: number) {
        const apiPath = AppService.get(Types.Config).apiPath;

        const option = {
            isAB,
            isIRO,
        };

        const bodyFormData = new FormData();
        bodyFormData.set('options', JSON.stringify(option));
        bodyFormData.set('files', filelist[0]);
        bodyFormData.set('targetMarketId', targetMarketId.toString());

        return Axios.post(`${apiPath}/Prospecting/UploadDocument`, bodyFormData);
    }

    public downloadAccounts(numberToDownload: number, targetMarketId: number): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get(`${apiPath}/Prospecting/DownloadAccountsToProspect?numberToDownload=${numberToDownload}&targetMarketId=${targetMarketId}`));
    }

    public reDownloadAccounts(userid: string | number, targetMarketId: number): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get(`${apiPath}/Prospecting/ReDownloadAccountsToProspect?userId=${userid}&targetMarketId=${targetMarketId}`));
    }

    public getEmployeeSizeRanges(targetMarketId: number): AxiosPromise<Array<Model.PlainObject<EmployeeSizeRange>>> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get(`${apiPath}/Prospecting/GetEmployeeSizeRanges?targetMarketId=${targetMarketId}`));
    }

    public getSubIndustryRanges(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<SubIndustryRangeCriteria>>): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.get(`${apiPath}/Prospecting/GetSubIndustryRanges?${Utils.getQueryString(request)}`)
    }

    public getLPCSearch(targetMarketId: number): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;

        return ResponseResolver<any>(Axios.get(`${apiPath}/Prospecting/GetLPCSearch?targetMarketId=${targetMarketId}`));
    }
    public getIROSearch(targetMarketId: number): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get(`${apiPath}/Prospecting/GenerateIROSearch?targetMarketId=${targetMarketId}`));
    }
    public saveIROEditStatus(targetMarketId: number, IROEditStatusId: number, IsIROStage: boolean): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;

        const status = {
            targetMarketId,
            IROEditStatusId,
            IsIROStage,
        };

        return Axios.post(`${apiPath}/Prospecting/SaveIROEditStatus`, status);
    }
    public saveIsIROStage(targetMarketId: number, IROEditStatusId: number, IsIROStage: boolean): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;

        const status = {
            targetMarketId,
            IROEditStatusId,
            IsIROStage,
        };

        return Axios.post(`${apiPath}/Prospecting/SaveIsIROStage`, status);
    }

    public downloadLPCSearch(targetMarketId: number): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get(`${apiPath}/Prospecting/DowloadLPCSearch?targetMarketId=${targetMarketId}`));
    }

    public getBatchesInProgress(targetMarketId: number): AxiosPromise<Array<Model.PlainObject<BatchInProgressLookup>>> {
        const apiPath = AppService.get(Types.Config).apiPath;

        return ResponseResolver<Array<Model.PlainObject<BatchInProgressLookup>>>(Axios.get(`${apiPath}/Prospecting/GetBatchesInProgress?targetMarketId=${targetMarketId}`));
    }

    public DownloadUploadProspectTemplate(): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get(`${apiPath}/Prospecting/DownloadUploadProspectTemplate`));
    }

    public UploadProspectFile(filelist: File[] | FileList, targetMarketId: number) {
        const apiPath = AppService.get(Types.Config).apiPath;

        const bodyFormData = new FormData();
        bodyFormData.set('files', filelist[0]);
        bodyFormData.set('targetMarketId', targetMarketId.toString());

        return Axios.post(`${apiPath}/Prospecting/UploadProspectFile`, bodyFormData);
    }

    public DisplayRegionWarning(targetMarketId: number): AxiosPromise<boolean> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<boolean>(Axios.get(`${apiPath}/Prospecting/DisplayRegionWarning?targetMarketId=${targetMarketId}`));
    }
}