import { ModelBase, NeoModel, List } from '@singularsystems/neo-core';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';
import { any } from 'prop-types';

@NeoModel
export default class ListSelectorModalSubListLookup extends ModelBase {

    // Properties
    public subItemId: number = 0;

    public subItemName: string = "";

    public subItemDetails: string = "";

    public isSelected: Nullable<boolean> = false;

    public isPriority: Nullable<boolean> = false;
}