import React from 'react';
import { Views } from '@singularsystems/neo-react';
import KnowledgeCenterVM from './KnowledgeCenterVM';
import { observer } from 'mobx-react';

@observer
export default class KnowledgeCenterView extends Views.ViewBase<KnowledgeCenterVM> {

    constructor(props: unknown) {
        super("Knowledge Center", KnowledgeCenterVM, props);
    }

    public render() {
        return (
            <div>
			    <h2>Knowledge Center</h2>
				
            </div>
        );
    }
}