import { NeoModel, Rules, ModelBase } from '@singularsystems/neo-core';

@NeoModel
export default class ReminderCriteria extends ModelBase {

  @Rules.Required()
  public clientId: number = 0;

  // The property name for the backend that will get the specific reminder details
  public extra: string = "";

  // The Id assigned to the extras for the types of reminders
  public extraId: number = 0;
}