import { ModelBase, NeoModel, Validation } from "@singularsystems/neo-core";
import { textConstants } from "../../common/textConstants";
import { isURL } from "../../common/utils";

@NeoModel
export default class TechnicalSettingsConfig extends ModelBase{

    public technicalSettingsConfigId : number = 0; 

    public firewallTooltip : string = "";

    public woodpeckerIP1 : string = "";

    public woodpeckerIP2 : string = "";

    public dkimTooltip : string = "";

    public spfTooltip : string = "";

    public cnameTooltip : string = "";

    public internalEmailText : string = "";

    public internalEmailTextHeader : string = "";

    public crmIntegrationText : string = "";

    public crmIntegrationVideoText : string = "";

    public crmIntegrationVideoURL : string = "";

    public actionPlanText : string = "";

    public mailToText : string = "";

    public uniqueTableKey : string = "";

    public termsOfServiceText : string = "";

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c =>c.crmIntegrationVideoURL === null || c.crmIntegrationVideoURL === "" || !isURL(c.crmIntegrationVideoURL), "Please provide a valid URL");
        rules.failWhen(c => c.mailToText.replace(new RegExp("\n","g"), textConstants.generalText.MailToNewLine).length > 1500, "Must not exceed character limit of 1500");
    }

    public toString(): string {
        return "Advanced Email Settings";
    }
}