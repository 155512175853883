import { ModelBase, NeoModel} from '@singularsystems/neo-core';

@NeoModel
export default class ReportingCampaignFilter extends ModelBase {
  public reportingData: any[] = new Array;

  public month: number = 0;

  public year: number = 0;

  public day: number = 0;
}