import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class CRMIntegrationLookup extends ModelBase 
{
    public woodpeckerApiKey: string = "";
    
    public videoUrl: string = "";

    public instructionsHtml: string = "";

    public instructionsVideoHtml: string = "";
}