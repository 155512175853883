import styled from 'styled-components';

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledModalContent = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
    @media (min-width: 768px) {
        width: 50%;
    }
`;

export const StyledModalClose = styled.div`
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  &:hover {
    color: #000;
  }
`;

export const StyledModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  color: #0b0b0c;
  margin-bottom: 20px;
`;
