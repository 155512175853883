import { tabNameLabels } from '../Views/TargetMarketAccounts/TargetMarketAccountsVM'
import React from 'react'

interface ITabHeaderBadge {
    tabs: tabNameLabels
}

export class TabHeaderBadge extends React.Component<ITabHeaderBadge>{
    render() {
        return (

            <div className="TabHeaderBadges pl-3 pr-3" >
                <span className="px-2">{this.props.tabs.tabName}</span>
                <span className='tabNumbers'>
                    {this.props.tabs.tabCount >= 0 && "(" + this.props.tabs.tabCount + ")"}
                </span>
            </div>

        )
    }
}