import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class UnlockCommand extends ModelBase {

    public id : string = "";

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}