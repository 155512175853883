import styled, {keyframes, css} from 'styled-components';
import { theme } from 'Globals/Theme/theme';


const slideOut = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
`;

const slideIn = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
`;

const slideAnimation = css<IZendeskLoaderContainerProps>`
    animation: ${props => props.isOpen ? slideOut : slideIn} 0.5s ease-in-out;
`;

interface IZendeskLoaderContainerProps {
    isOpen: boolean;
}
export const ZendeskLoaderButton = styled.div<IZendeskLoaderContainerProps>`

    background: ${theme.palette.primary.main};
    border-radius: 100px;
    position: fixed;
    z-index: 10;
    bottom: 20px;
    right: 20px;
    height: 48px;
    color: ${theme.palette.primary.dark};
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    svg {
      margin: -4px 8px 0px 0px;
    }

    ${props => props.isOpen && slideAnimation}

    p {
      margin: 0;
      line-height: normal;
      font-size: 16px;
      font-weight: bold;
      color: ${theme.palette.primary.dark};
      font-family: "Lato-Regular";
      position: relative;
      bottom: 2px;
    }
`;