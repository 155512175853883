import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import CampaignMessagesMaintenanceVM from './CampaignMessagesMaintenanceVM';
import { observer } from 'mobx-react';
import GoBack from '../../Components/GoBack';
import { textConstants } from '../../common/textConstants';
import * as Icon from 'react-feather';
import RichTextEditor from 'Components/RichTextEditor';

@observer
export default class CampaignMessagesMaintenanceView extends Views.ViewBase<CampaignMessagesMaintenanceVM> {

    constructor(props: unknown) {
        super("Campaign Messages Maintenance", CampaignMessagesMaintenanceVM, props);
    }

    private tempText: string = ""

    public render() {
        return (
            <>
                <GoBack />

                <Neo.Card>
                    <h2 className='p-24 mt-4'>{textConstants.titleText.CampaignMessageMaintenance}</h2>

                    <Neo.TabContainer
                        tabManager={this.viewModel.tabManager} className='m-0'>

                        <Neo.Tab
                            onDisplayed={() => {
                                this.viewModel.toggleTab("", textConstants.titleText.CampaignMessageStatuses)
                            }}
                            header={textConstants.titleText.CampaignMessageStatuses}
                        >
                            <Neo.Form className="mt-2" model={this.viewModel.campaignMessageStatusList}>
                                <NeoGrid.Grid items={this.viewModel.campaignMessageStatusList} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
                                    {(campaignMessageStatus, meta) => (
                                        <NeoGrid.Row>
                                            <NeoGrid.Column bind={meta.messageStatus}
                                                label={textConstants.titleText.CampaignMessagesStatus} />
                                            <NeoGrid.ButtonColumn showDelete
                                                deleteButton={{
                                                    icon: <Icon.X size={24} />,
                                                    variant: "light",
                                                    className: "circled"
                                                }} />
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            </Neo.Form>
                        </Neo.Tab>

                        <Neo.Tab onDisplayed={() => this.viewModel.toggleTab("", textConstants.Onboarding.HelpCenter.Text)}
                            header={textConstants.titleText.EmailSignatureTemplates}>

                            <Neo.Form className="mt-2" model={this.viewModel.emailSignatureTemplates}>
                                <NeoGrid.Grid items={this.viewModel.emailSignatureTemplates}>
                                    {(campaignMessageMaintenance, meta) => (
                                        <NeoGrid.Row>
                                            <NeoGrid.Column bind={meta.emailSignatureFriendlyDescription}
                                                label={textConstants.titleText.FriendlyDescription} >
                                            </NeoGrid.Column>
                                            <NeoGrid.Column></NeoGrid.Column>
                                            <NeoGrid.ButtonColumn>
                                                <Neo.Button variant="light"
                                                    onClick={() => this.viewModel.openModal(campaignMessageMaintenance)}>
                                                    {textConstants.buttonText.Edit}
                                                </Neo.Button>
                                            </NeoGrid.ButtonColumn>
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            </Neo.Form>

                        </Neo.Tab>
                    </Neo.TabContainer>
                    <div className="my-4 text-right p-24">
                        <Neo.Button className="btn-width-100" variant='success' onClick={() => this.viewModel.updateCampaignMessagesMaintenance()}>{textConstants.buttonText.Save}</Neo.Button>
                    </div>

                    <Neo.Modal
                        title={`${textConstants.titleText.Update} ${this.viewModel.emailSignatureTemplate.emailSignatureFriendlyDescription}`}
                        className={"modal-style-test "}
                        show={this.viewModel.showDefaultSignatureModal}
                        onClose={() => this.viewModel.showDefaultSignatureModal = false}
                        closeButton={{ text: textConstants.buttonText.Cancel, variant: "light" }}
                        acceptButton={{
                            text: "Done", variant: "secondary",
                            onClick: () => {
                                this.viewModel.emailSignatureTemplate.emailSignatureBody = this.tempText
                                this.viewModel.showDefaultSignatureModal = false
                            }
                        }}
                        formProps={{ model: this.viewModel.emailSignatureTemplate }}>
                        <div className='p-24 campaignMessageMaintenanceRichTextEditor'>
                            <RichTextEditor initialString={this.viewModel.emailSignatureTemplate.emailSignatureBody}
                                getHtmlText={(htmlText: string) => {
                                    this.tempText = htmlText;
                                }} />
                        </div>
                    </Neo.Modal>
                </Neo.Card>
            </>
        );
    }
}