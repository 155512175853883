import React from 'react';

import { observer } from 'mobx-react';

import { Neo } from '@singularsystems/neo-react';

import { textConstants } from '../../common/textConstants';
import DowloadTemplate from './DowloadTemplateSubView';
import UpdateTargetMarketsVM from './UpdateTargetMarketsVM';
import * as Icon from 'react-feather';

interface IUpdateTargetMarketsProps {
  viewModel: UpdateTargetMarketsVM;
  back: () => void;
}

@observer
export default class UpdateTargetMarketsView extends React.Component<IUpdateTargetMarketsProps> {
  public async onDocumentSelected(fileList: File[] | FileList, downloadTemplateName: string) {
    this.props.viewModel.documentUpload(fileList, downloadTemplateName);
  }

  public render() {
    const isRespondedTab = (
      <Neo.Tab header={textConstants.titleText.AccountsResponded}>
        <DowloadTemplate
          columnHeadings={this.props.viewModel.columnHeadings}
          downloadTemplate={() => this.props.viewModel.downloadTemplate(textConstants.UpdateTargetMarketsTemplateKey.AccountsResponded)}
          onDocumentSelected={(e) => this.onDocumentSelected(e, textConstants.UpdateTargetMarketsTemplateKey.AccountsResponded)}
        />
      </Neo.Tab>
    );

    const isReProspected = (
      <Neo.Tab header={textConstants.titleText.AccountsToReProspect}>
        <DowloadTemplate
          columnHeadings={this.props.viewModel.columnHeadings}
          downloadTemplate={() => this.props.viewModel.downloadTemplate(textConstants.UpdateTargetMarketsTemplateKey.AccountsToReProspect)}
          onDocumentSelected={(e) => this.onDocumentSelected(e, textConstants.UpdateTargetMarketsTemplateKey.AccountsToReProspect)}
        />
      </Neo.Tab>
    );

    const inValidDomains = (
      <Neo.Tab header={textConstants.generalText.InvalidDomains}>
        <DowloadTemplate
          columnHeadings={this.props.viewModel.columnHeadings}
          downloadTemplate={() => this.props.viewModel.downloadTemplate(textConstants.UpdateTargetMarketsTemplateKey.InvalidDomains)}
          onDocumentSelected={(e) => this.onDocumentSelected(e, textConstants.UpdateTargetMarketsTemplateKey.InvalidDomains)}
        />
      </Neo.Tab>
    );

    return (
      <div className='row m-0 UpdateTargetMarketsView'>
        <div className='col-12 p-24'>
          <Neo.Button
            className="btn-width-100 mt-3"
            variant="light"
            icon={<Icon.ArrowLeft />}
            onClick={async (e: any) => {
              this.props.back()
            }}
          >
            {textConstants.buttonText.Back}
          </Neo.Button>
        </div>
        <Neo.Card className='cardStyling paddingBottom24'>
          <div className="col-4 p-24 mt-4">
            <h2 className='pt-0'>{textConstants.titleText.UpdateTargetMarkets}</h2>
          </div>
          <div className='col-12 p-24'>
            <label className="clientText mt-2">
              {textConstants.titleText.Client}:
              <span className="ml-4">
                {this.props.viewModel.client.clientName}
              </span>
            </label>
            <Neo.TabContainer
              className='m-0'
              selectedTab={this.props.viewModel.meta.selectedTab}>
              {isRespondedTab} {isReProspected} {inValidDomains}
            </Neo.TabContainer>
          </div>
        </Neo.Card>
      </div>
    );
  }
}