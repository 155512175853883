import { Attributes, ModelBase, NeoModel,} from '@singularsystems/neo-core';

@NeoModel
export default class ClientLookup extends ModelBase {

    public clientId: number = 0;

    @Attributes.Display("Name")
    public clientName: string = "";

    @Attributes.Display("Target Markets")
    @Attributes.Integer()
    public targetMarketCount: number = 0;

    @Attributes.Display("Campaign Messages")
    @Attributes.Integer()
    public campaignMessagesCount: number = 0;

    public idealCustomerProfileCount: number = 0;

    public isDeleted : boolean = false
}