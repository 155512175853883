import { NeoModel, Data, List } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import TargetMarketCriteria from '../../Models/TargetMarket/Query/TargetMarketCriteria';
import TargetMarketLookup from '../../Models/TargetMarket/TargetMarketLookup';
import CloneTargetMarketCommand from '../../Models/TargetMarket/Commands/CloneTargetMarket';
import CommandResult from '../../Models/InvitedUsers/Commands/CommandResult';
import { textConstants } from '../../common/textConstants';
import DeleteTargetMarketCommand from '../../Models/TargetMarket/Commands/DeleteTargetMarket';
import UndoTargetMarketDeleteCommand from '../../Models/TargetMarket/Commands/UndoTargetMarketDelete';
import UpdateTargetMarketStatusCommand from '../../Models/TargetMarket/Commands/UpdateTargetMarketStatus';
import ClientLookup from '../../Models/Client/ClientLookup';
import { base64toBlob, getCurrentDateTime } from '../../common/utils';
import { reaction } from 'mobx';
import TargetMarketStatus from '../../Models/Maintenance/TargetMarketStatus';
import TargetMarketInformation from '../../Models/TargetMarket/TargetMarketInformation';
import { TargetMarketUpdateNameCommand } from '../../Models/TargetMarket/Commands/TargetMarketUpdateNameCommand';
import InfoVideoVM from '../../Components/InfoVideoVM';
import ClientNavigationButtonsVM from '../../Components/ClientNavigationButtonsVM';
import SearchFieldVM from '../../Components/SearchFieldVM';
import OnboardingFunctions from 'Models/Onboarding/OnboardingFunctions';

@NeoModel
export default class TargetMarketVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    public appDataCache = AppService.get(Types.Services.AppDataCache),
    private targetMarketsApiClient = AppService.get(Types.ApiClients.TargetMarketsApiClient),
    private targetMarketAccountsApiClient = AppService.get(Types.ApiClients.TargetMarketAccountsApiClient),
    private clientApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
    private targetMarketStatusesApiClient = AppService.get(Types.ApiClients.TargetMarketStatusesApiClient),
    private authService = AppService.get(Types.Neo.Security.AuthenticationService),
    private invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient),
    private comXConfigApiClient = AppService.get(Types.ApiClients.ComXConfigurationsApiClient),
    public customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
    private comxHub = AppService.get(Types.ApiClients.ComXHub)) {
    super(taskRunner);
    this.IsTargetMarketCalculating = []
    this.connectToComXHub()
  }

  // Properties
  public searchCriteria: TargetMarketCriteria = new TargetMarketCriteria();
  public client = new ClientLookup();
  public clientName: string = "";
  public showCloneModal: boolean = false;
  public clientId: number = 0;
  public showDeleteModal: boolean = false;
  public showUndoDeleteModal: boolean = false;
  public showWhitelistModal: boolean = false;
  public selectedTMName: string = "";
  public columnHeadings: string[] = ["- Fixed Name", "- Original Name - ", "Location - ", "Domain"];
  public countryId: number = 0;
  public targetMarketId: number = 0;
  public showForbiddenModal: boolean = false;
  public countries = this.appDataCache.countries.get().data.slice().sortBy("countryName")
  public industriesVersions = this.appDataCache.industriesVersions.get().data
  public showMaxTargetMarketsModal: boolean = false
  public isTMNameEditable: boolean = true;
  public isActiveTargetMarketId: number = 0;

  public navigateTo: string = "";

  public isComXUser: boolean = false

  public showSpinner: boolean | undefined = true;

  public isArchived: boolean = false;

  public targetMarketStatusList = new List(TargetMarketStatus);

  public displayOverlay: boolean = false;

  public hideGrid: boolean = false;

  public previousName: string = "";

  public showBackButton: boolean = true;

  public videoUrl: string = "";

  public hasPageManagerRefreshed: boolean = true;

  // Commands
  public cloneTargetMarketCommand = new CloneTargetMarketCommand();
  public deleteTargetMarketCommand = new DeleteTargetMarketCommand();
  public updateTargetMarketStatusCommand = new UpdateTargetMarketStatusCommand();
  public undoTargetMarketDeleteCommand = new UndoTargetMarketDeleteCommand();
  public infoVideoVM = new InfoVideoVM();


  public IsTargetMarketCalculating: TargetMarketLookup[]
  public isTargetMarketId = 0

  // Edit Target Market Name
  public showTMANameUpdate: boolean = false
  public targetMarketUpdateNameCommand = new TargetMarketUpdateNameCommand();

  public clientNavigationButtons: ClientNavigationButtonsVM = new ClientNavigationButtonsVM;

  public searchFieldVM = new SearchFieldVM;

  public hasCompletedLoad: boolean = false

  public firstRowSequenceNumber: number = 0

  // Pagination
  public pageManager = new Data.PageManager(this.searchCriteria, TargetMarketLookup, this.targetMarketsApiClient.getTargetMarkets,
    {
      pageSize: 20,
      sortBy: "sequenceNumber",
      fetchInitial: false
    }
  )

  public async initialise() {


  }

  // Methods
  public calculations = (targetMarketLookups: any) => {
    this.setTargetMarket(targetMarketLookups)
  }

  public connectToComXHub() {
    this.comxHub.CalculationsChanged.subscribe(this.calculations);
  }

  public setTargetMarket(targetMarketLookups: TargetMarketLookup[]) {
    const data = this.pageManager.data

    //Empty array if not done
    this.IsTargetMarketCalculating = []
    this.IsTargetMarketCalculating.push(...targetMarketLookups)

    if (!targetMarketLookups[0].isCalculating) {
      data.forEach(tm => {
        let targetMarketLookup = this.IsTargetMarketCalculating.find(f => f.targetMarketId === tm.targetMarketId);

        if (targetMarketLookup) {
          if (tm.targetMarketId === targetMarketLookup.targetMarketId) {
            tm.targetMarketAccountCount = targetMarketLookup.targetMarketAccountCount
            tm.progressDone = targetMarketLookup.progressDone
            tm.progressToDo = targetMarketLookup.progressToDo
            tm.progressResponded = targetMarketLookup.progressResponded
            tm.prospectingCount = targetMarketLookup.prospectingCount
            tm.isCalculating = targetMarketLookup.isCalculating

            this.IsTargetMarketCalculating.remove(targetMarketLookup);
          }
        }
      })
    } else {
      data.forEach(x => {
        let targetMarketLookup = this.IsTargetMarketCalculating.find(f => f.targetMarketId === x.targetMarketId);

        if (targetMarketLookup) {
          x.isCalculating = targetMarketLookup.isCalculating

          this.IsTargetMarketCalculating.remove(targetMarketLookup);
        }
      })
    }

    this.pageManager.setItems(data)
  }

  public openDeleteModal(targetMarket: TargetMarketLookup) {
    this.deleteTargetMarketCommand = new DeleteTargetMarketCommand();
    this.deleteTargetMarketCommand.targetMarketId = targetMarket.targetMarketId;
    // Show the modal
    this.showDeleteModal = true;
    this.selectedTMName = targetMarket.targetMarketName;
  }


  public deleteTargetMarket() {
    this.showDeleteModal = false;
    this.taskRunner.run(async () => {
      const response = await this.targetMarketsApiClient.deleteTargetMarket(this.deleteTargetMarketCommand);
      const cr: CommandResult = response.data as CommandResult;
      if (cr.success) {
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.TargetMarketSaved);
        this.pageManagerRefresh();
      } else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
      }
    });
  }

  public openCloneModal(targetMarketId: number) {
    this.cloneTargetMarketCommand = new CloneTargetMarketCommand();
    this.cloneTargetMarketCommand.targetMarketId = targetMarketId;
    this.showCloneModal = true;
    this.countries = this.appDataCache.countries.get().data.slice().sortBy("countryName")
  }

  public openWhitelistModal(countryId: number, targetMarketId: number) {
    this.showWhitelistModal = true;
    this.countryId = countryId;
    this.targetMarketId = targetMarketId;
  }

  public cloneTargetMarket() {
    this.showCloneModal = false;
    this.taskRunner.run(async () => {
      const response = await this.targetMarketsApiClient.cloneTargetMarket(this.cloneTargetMarketCommand);
      const cr: CommandResult = response.data.data as CommandResult;

      if (cr.success) {
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.TMCloneSuccessful);
        this.pageManagerRefresh();
      } else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
      }
    })
  }

  public async fetchData(clientId: number) {
    if (clientId > 0) {
      this.searchCriteria.clientId = clientId;
      this.searchCriteria.isDeleted = this.isArchived;

      const isClientResponse = await this.invitedUsersApiClient.isClientUser(this.authService!.user!.userName);
      this.isComXUser = !isClientResponse.data

      const dispose2 = reaction(() => this.searchCriteria.isDeleted, () => this.pageManagerRefresh(), { fireImmediately: false })

      await this.getVideoUrls();
      this.infoVideoVM.url = this.videoUrl;
      this.infoVideoVM.content = textConstants.generalText.CreateTargetMarketText;
      this.infoVideoVM.header = textConstants.generalText.CreateTargetMarket;

      // Load client details 
      const response = await this.clientApiClient.getClient(clientId);
      this.getTMStatuses();
      this.client.set(response.data);

      this.clientNavigationButtons.clientName = this.client.clientName
      this.clientNavigationButtons.navigateTo = this.navigateTo
      this.clientNavigationButtons.isTargetMarkets = true

      this.pageManager.reset();
      await this.pageManagerRefresh();

      if (this.customAuthService.globalProps.isOnboarding) {
        this.onboardingModalSetup();
      }

      this.hasCompletedLoad = true
    }
  }

  public showOnboardingModal: boolean = false;

  public onboardingStepId?: number = 0

  public onboardingPopupModalContent: string = "";

  public async onboardingModalSetup() {
    const {
      onboardingStepId,
      showOnboardingModal,
      onboardingPopupModalContent,
    } = await this.customAuthService.onboardingFunctions.getOnboardingData();

    this.onboardingStepId = onboardingStepId;
    this.showOnboardingModal = showOnboardingModal;
    this.onboardingPopupModalContent = onboardingPopupModalContent;
  }

  public async setStepCompleteModalAsSeen() {
    await this.customAuthService.onboardingFunctions.setStepCompleteModalAsSeen(this.onboardingStepId);
  }

  public async getTMStatuses() {
    const response = await this.targetMarketStatusesApiClient.get();
    this.targetMarketStatusList.set(response.data);
  }

  public downloadTemplate() {
    this.taskRunner.run(async () => {
      const response = await this.targetMarketAccountsApiClient.getTemplate();

      const url = window.URL.createObjectURL(base64toBlob(response.data.fileContents, response.data.contentType));
      const link = document.createElement('a');
      link.href = url;
      const datetime = getCurrentDateTime();
      link.setAttribute('download', 'Template_' + datetime + '.csv');
      document.body.appendChild(link);
      link.click();
    })
  }

  public documentUpload(fileList: File[] | FileList) {


    if (this.countryId !== undefined || this.countryId !== null || this.countryId !== 0) {
      this.taskRunner.run(async () => {
        const response = await this.targetMarketAccountsApiClient.uploadDocument(fileList, this.countryId, this.targetMarketId);
        if (response.data.success) {
          this.notifications.addSuccess(textConstants.titleText.AddClientList, textConstants.messageText.saveMessage.WhitelistUpload);
          this.pageManagerRefresh();
        }
        else {
          this.notifications.addDanger(textConstants.titleText.Error, response.data.message);
        }
      });
    }
    else {
      this.notifications.addDanger(textConstants.titleText.Error, "Please select a country.");
    }

    this.showWhitelistModal = false;
  }

  public updateTargetMarketStatus(targetMarketId: number, targetMarketStatusId: number) {

    this.updateTargetMarketStatusCommand.targetMarketId = targetMarketId
    this.updateTargetMarketStatusCommand.targetMarketStatusId = targetMarketStatusId

    this.taskRunner.run(async () => {
      const response = await this.targetMarketsApiClient.updateTargetMarketStatus(this.updateTargetMarketStatusCommand);
      const cr: CommandResult = response.data as CommandResult;
      if (cr.success) {
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.TargetMarketSaved);
        // this.pageManager.refreshData();
      } else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
      }
    });

  }

  public getStatusColor(status: number) {
    switch (status) {
      case 1: {
        return "#cb5151"
      }
      case 2: {
        return "#FBA174"
      }
      case 3: {
        return "#eaca6a"
      }
      case 4: {
        return "#8cb08c"
      }
      case 5: {
        return "#597D9B"
      }
      case 6: {
        return "#808080"
      }
      default: {
        return ""
      }
    }
  }

  public async allowAddTM() {
    const response = await this.targetMarketsApiClient.allowAddTM(this.client.clientId)
    const result = response.data as boolean
    return result
  }

  public async openUndoTargetMarketDeleteModal(targetMarket: TargetMarketLookup) {
    this.undoTargetMarketDeleteCommand = new UndoTargetMarketDeleteCommand();
    this.undoTargetMarketDeleteCommand.targetMarketId = targetMarket.targetMarketId;
    this.showUndoDeleteModal = true;
    this.selectedTMName = targetMarket.targetMarketName;
  }

  public navigateToEditClient: () => void = () => { };

  public async undoTargetMarketDelete() {
    this.taskRunner.run(async () => {
      this.showUndoDeleteModal = false;

      const response = await this.targetMarketsApiClient.undoTargetMarketDelete(this.undoTargetMarketDeleteCommand.targetMarketId);
      const result = response.data as boolean

      if (result) {
        //  Refresh
        this.searchCriteria.isDeleted = true;
        this.pageManagerRefresh();
      }
      else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, 'Failed to undo Target Market delete.');
      }
    })
  }

  public async pageManagerRefresh() {
    this.hideGrid = true;
    // Hide the overlay as it can only be displayed once we have determined 
    // that the are no current or archived target markets.
    this.displayOverlay = false;

    const result = await this.targetMarketsApiClient.targetMarketInformation(this.client.clientId);

    const targetMarketInfo = result.data as TargetMarketInformation;

    if (this.customAuthService.globalProps.isOnboarding) {
      this.onboardingModalSetup();
    }

    await this.pageManager.refreshData(undefined, this.taskRunner).then(() => {
      this.searchFieldVM.hideNoResultDialog = false;
      this.hideGrid = false;

      this.displayOverlay = this.pageManager.totalRecords === 0 &&
        this.isArchived === false &&
        targetMarketInfo.nonDeletedTargetMarkets === 0 &&
        targetMarketInfo.deletedTargetMarkets === 0;

    });

    this.hasPageManagerRefreshed = true;
  }

  public async updateTargetMarketName(targetMarketLookup: TargetMarketLookup) {

    this.targetMarketUpdateNameCommand.targetMarketId = targetMarketLookup.targetMarketId
    this.targetMarketUpdateNameCommand.targetMarketName = targetMarketLookup.targetMarketName

    this.taskRunner.run(async () => {
      const response = await this.targetMarketsApiClient.updateTargetMarketName(this.targetMarketUpdateNameCommand);

      const cr: CommandResult = response.data as CommandResult;
      if (cr.success) {
        this.targetMarketUpdateNameCommand = new TargetMarketUpdateNameCommand()
        this.pageManager.refreshData()
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.TargetMarketNameUpdated);
      } else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
      }
    })
  }

  private async getVideoUrls() {
    var comXConfig = this.appDataCache.comXConfigurations.get().data

    if (comXConfig[0] !== undefined) {
      this.videoUrl = comXConfig.find((config: any) => config.configCode === textConstants.generalText.HowToCreateTMVideo)?.value as string
    }
    else {
      comXConfig = (await this.comXConfigApiClient.get()).data
      this.videoUrl = comXConfig.find((config: any) => config.configCode === textConstants.generalText.HowToCreateTMVideo)?.value as string
    }
  }

  public disableEditTargetMarket(targetMarketLookup: TargetMarketLookup): boolean {
    if (this.targetMarketStatusList.length === 0) {
      this.getTMStatuses()
    }

    var activeTMStatus = this.targetMarketStatusList.find(tms => tms.uniqueTableKey === textConstants.generalText.TargetMarketStatusActive)

    // check if we cannot find the active status even after retrieving the entire list, we return false as a safety measure
    // Check that the TM lookup is set (Should be but just for safety)
    if (activeTMStatus === undefined || activeTMStatus === null || targetMarketLookup === undefined || targetMarketLookup === null) {
      return false;
    }

    return activeTMStatus.targetMarketStatusId === targetMarketLookup.targetMarketStatusId
  }

  public isEditable(targetMarketId: number): boolean {
    return (this.isActiveTargetMarketId === targetMarketId);
  }

  public async toggleEditable(targetMarketId: number) {
    this.isActiveTargetMarketId = targetMarketId;
  }

  public canClone(tmIndustriesVersionId: number) {
    let activeIndustriesVersion = this.industriesVersions.find(f => f.industriesVersionId === tmIndustriesVersionId && f.isActive)

    return activeIndustriesVersion?.industriesVersionId != null;
  }

  public isFirstElementInList(sequenceNumber: number) {

    if (this.firstRowSequenceNumber === 0 || this.firstRowSequenceNumber > sequenceNumber) {
      this.firstRowSequenceNumber = sequenceNumber;
      return true;
    }

    return this.firstRowSequenceNumber === sequenceNumber
  }
}

