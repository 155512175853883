import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import ClientSettingsVM from '../ClientSettingsVM';
import Types from '../../../Modules/AppTypes';
import { AppService } from '../../../Services/AppService';
import { textConstants } from '../../../common/textConstants';
import * as Roles from '../../../Models/Security/Roles';
import SearchField from '../../../Components/SearchField';
import TextAsParagraphs from '../../../Components/TextAsParagraphs';
import QuickAddModal from '../../../Components/QuickAddModal';
import BlacklistedDomainDataGrid from './BlacklistedDomainGrid/BlacklistedDomainGrid';
import * as Icon from 'react-feather';
import InfoCard from '../../../Components/InfoCard';
import InfoBox from '../../../Components/InfoBox';
import SearchFieldVM from '../../../Components/SearchFieldVM';
import Button from 'Components/Button';
import { StyledButton } from 'Components/Button/styles';
import OnboardingModal from 'Components/Modals/OnboardingModal';
import TrophyWoman from "assets/img/TrophyWoman.png";
import HtmlParser from 'react-html-parser';
import { OnboardingSteps } from 'Models/Enums/ApplicationEnums';
import { OnboardingNextStepFooter } from 'Components/FooterComponent';
import BlacklistAndGreyListUploadModal from 'Components/Modals/BlacklistAndGreylistUploadModal';
import { List } from '@singularsystems/neo-core';
import QuickAddItem from 'Models/BlacklistedDomains/QuickAddDomain';
import OnboardingFunctions from 'Models/Onboarding/OnboardingFunctions';

interface IBlacklistedDomain {
  viewModel: ClientSettingsVM
}

@observer
export default class BlacklistedDomain extends React.Component<IBlacklistedDomain> {
  private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

  public securityCheck(role: any, openMethod: () => void) {
    if (this.authorisationService.hasRole(role)) {
      openMethod();
    }
    else {
      this.props.viewModel.showForbiddenModal = true;
    }
  }

  componentDidMount(): void {
    const { globalProps } = this.props.viewModel.customAuthService

    if (globalProps.isOnboarding) {
      if (!globalProps.onboardingSteps.find(f => f.stepNumber === OnboardingSteps.BlackList)?.isComplete) {
        globalProps.showOnboardingHelpCenter = true;
      }
      this.props.viewModel.customAuthService.onboardingFunctions.getRelevantSectionCode()
    }
  }

  public closeModal() {
    this.props.viewModel.showOnboardingModal = false
    this.props.viewModel.setStepCompleteModalAsSeen()
  }

  public onDocumentSelected(fileList: File[] | FileList) {

    this.props.viewModel.blacklistedDomainHelper.fileName = fileList[0].name;

    this.props.viewModel.blacklistedDomainHelper.fileList = [fileList[0]];
  }

  private setInternalViewStyle() {
    if (!this.props.viewModel.customAuthService.globalProps.isOnboarding && this.props.viewModel.isComXUser) {
      return "internalViewStyle"
    } else {
      return ""
    }
  }

  componentWillUnmount(): void {
    const { globalProps } = this.props.viewModel.customAuthService

    if (globalProps.isOnboarding) {
      globalProps.showOnboardingHelpCenter = false;
    }
  }

  public searchFieldVM = new SearchFieldVM();

  public render() {
    const { globalProps } = this.props.viewModel.customAuthService

    const searchFieldVM = this.searchFieldVM

    return (
      <div className={`${this.setInternalViewStyle()} BlacklistedDomain`}>

        {/* What is the Blacklist? */}
        {this.props.viewModel.customAuthService.globalProps.isOnboarding &&
          <Neo.Card>
            <div className="row col-12 m-0 p-0">
              {<InfoCard
                viewModel={this.props.viewModel.infoCardVM}
                useTextArray={true}
                isNewDesign={true}
                isExpanded={this.props.viewModel.isHeaderCardExpanded}
                openOrClose={() => { this.props.viewModel.isHeaderCardExpanded = !this.props.viewModel.isHeaderCardExpanded }} />}
            </div>
          </Neo.Card>
        }

        <Neo.Card className='cardPadding'>
          <div className="row m-0">
            {/* Blacklist header */}
            <div className="p-0 col-2">
              <h2>{textConstants.titleText.Blacklist}</h2>
            </div>

            {/* Search and buttons */}
            <div className="p-0 col-10">

              {/* Add Domain button */}
              <div className="text-center p-0 pull-right ml-4 negativeMargins mb-1">
                <StyledButton variant="hasIcon" size="xSmall"
                  onClick={() => this.props.viewModel.blacklistedDomainHelper.showQuickAddModal = true}>
                  {<Icon.Plus />}
                  {textConstants.titleText.Domain}
                </StyledButton>
              </div>

              {/* Download button */}
              <div className="text-center pull-right ml-4 extraLightButton">
                <Neo.Button
                  variant='light'
                  icon={<Icon.Download size={24} />}
                  onClick={() => this.props.viewModel.blacklistedDomainHelper.downloadBlacklistedDomains()}
                  className="buttonWidthBlacklist buttonWidthResponsive mr-0 text-center mb-1"
                  disabled={this.props.viewModel.blacklistedDomainHelper.blacklistCount === 0}
                >
                  {textConstants.buttonText.Download}
                </Neo.Button>
              </div>

              {/* Upload button */}
              <div className="text-center pull-right ml-4 extraLightButton mb-1">
                <Neo.Button
                  variant='light'
                  icon={<Icon.Upload size={24} />}
                  className="buttonWidthBlacklist buttonWidthResponsive mr-0"
                  onClick={() => this.securityCheck(Roles.Blacklist.Manage,
                    () => this.props.viewModel.blacklistedDomainHelper.openBlacklistUploadModal())}>
                  {textConstants.titleText.Upload}
                </Neo.Button>
              </div>

              {/* Search field */}
              <div className="p-0 pull-right mb-1">
                <SearchField
                  searchBindableProp={this.props.viewModel.blacklistedDomainHelper.blacklistSearchCriteria.meta.searchString}
                  onClickFunction={(e) => {
                    this.props.viewModel.blacklistedDomainHelper.refreshMethod()
                    this.props.viewModel.blacklistedDomainHelper.isRefreshCount = true
                    searchFieldVM.hideNoResultDialog = true
                  }}
                  searchString={this.props.viewModel.blacklistedDomainHelper.blacklistSearchCriteria.searchString}
                  searchFieldVM={searchFieldVM}
                />
              </div>
            </div>
          </div>

          {this.props.viewModel.blacklistedDomainHelper.blacklistCount > 0 &&
            <div>
              <BlacklistedDomainDataGrid viewModel={this.props.viewModel.blacklistedDomainHelper} />
            </div>
          }

          {/* You have no domains yet */}
          {this.props.viewModel.blacklistedDomainHelper.blacklistCount === 0 &&
            !searchFieldVM.isSearching && !searchFieldVM.hideNoResultDialog &&
            <InfoBox header={textConstants.titleText.NoBlacklistedDomains}
              text={textConstants.generalText.NoBlacklistedDomainsText} />
          }

          {this.props.viewModel.blacklistedDomainHelper.blacklistCount === 0 &&
            searchFieldVM.isSearching &&
            <InfoBox header={textConstants.titleText.NoSearchMatches}
              text={textConstants.generalText.NoSearchMatches} />
          }
        </Neo.Card>

        {globalProps.isOnboarding && globalProps.currentStep === OnboardingSteps.BlackList &&
          !globalProps.onboardingSteps.find(x => x.stepNumber === OnboardingSteps.BlackList)?.isComplete
          &&
          <div className={`row appFooter ${this.props.viewModel.customAuthService.onboardingFunctions.isOnboardingHelpCenterVisible() ? "helpCenterAllowance" : ""}`} >
            <OnboardingNextStepFooter title={textConstants.buttonText.CompleteBlacklist} onClick={() => this.props.viewModel.completeBlacklistStep()} />
          </div>
        }

        <OnboardingModal
          isOpen={this.props.viewModel.showOnboardingModal && !this.props.viewModel.showQuickAddModal}
          imageSrc={TrophyWoman}
          content={<>{HtmlParser(this.props.viewModel.onboardingPopupModalContent)}</>}
          closeModal={() => {
            this.closeModal();
          }}
          acceptAndCloseButton={{
            text: textConstants.generalText.Close,
            onClose: () => {
              this.closeModal();
            }
          }}
        />

        {/* Delete Blacklisted Domain Modal */}
        <Neo.Modal title={textConstants.generalText.DeleteConfirmation} show={this.props.viewModel.blacklistedDomainHelper.showBlacklistDeleteModal}
          onClose={() => this.props.viewModel.blacklistedDomainHelper.showBlacklistDeleteModal = false}
          closeButton={{ text: "No", variant: "light" }}
          acceptButton={{
            text: "Yes", variant: "secondary", onClick: () => {
              this.props.viewModel.blacklistedDomainHelper.deleteBlacklistedDomain();
              searchFieldVM.hideNoResultDialog = false
            }
          }}>
          Blacklisted Domain {`"${this.props.viewModel.blacklistedDomainHelper.selectedDomainName}"`} is about to be deleted. Do you wish to continue?
        </Neo.Modal>

        <BlacklistAndGreyListUploadModal
          title={textConstants.generalText.UploadBlacklist}
          show={this.props.viewModel.blacklistedDomainHelper.showBlacklistUploadModal}
          onClose={() => this.props.viewModel.blacklistedDomainHelper.showBlacklistUploadModal = false}
          onDocumentSelected={(e) => this.onDocumentSelected(e)}
          downloadTemplate={() => this.props.viewModel.blacklistedDomainHelper.downloadTemplateForBlacklistUpload()}
          fileName={this.props.viewModel.blacklistedDomainHelper.fileName}
          clearFileList={() => { this.props.viewModel.blacklistedDomainHelper.fileList = []; this.props.viewModel.blacklistedDomainHelper.fileName = "" }}
          disableAdd={this.props.viewModel.blacklistedDomainHelper.fileList.length === 0}
          addFile={async () => {
            this.props.viewModel.blacklistedDomainHelper.uploadBlacklist(this.props.viewModel.blacklistedDomainHelper.fileList);
            this.props.viewModel.blacklistedDomainHelper.fileList = [];
            this.props.viewModel.blacklistedDomainHelper.fileName = "";
          }}
          uploadTitle={textConstants.buttonText.AddCSVFile}
          description={textConstants.generalText.BlacklistUploadText}
        />

        <QuickAddModal
          title={textConstants.titleText.QuickAddDomain}
          show={this.props.viewModel.blacklistedDomainHelper.showQuickAddModal}
          onClose={() => {
            this.props.viewModel.blacklistedDomainHelper.cleanUpProcess(true)
            this.props.viewModel.blacklistedDomainHelper.showQuickAddModal = false
            this.props.viewModel.blacklistedDomainHelper.QuickAddStringList = new List(QuickAddItem)
          }}
          onSubmit={async () => {
            await this.props.viewModel.blacklistedDomainHelper.saveQuickAdd(false)
          }}
          quickAddItem={this.props.viewModel.blacklistedDomainHelper.QuickAddString}
          addPlaceholder={textConstants.titleText.AddBlacklistDomain}
          addOnClick={(e: any) => {
            this.props.viewModel.blacklistedDomainHelper.validateNewDomainEntry(this.props.viewModel.blacklistedDomainHelper.QuickAddString, true)
          }}
          validateQuickAddItem={this.props.viewModel.blacklistedDomainHelper.QuickAddErrorDomains}
          validationUnsuccessful={this.props.viewModel.blacklistedDomainHelper.hasValidationFailed}
          addedItemsList={this.props.viewModel.blacklistedDomainHelper.QuickAddStringList}
          removeItem={(e: any, domain: any) => { this.props.viewModel.blacklistedDomainHelper.QuickAddStringList.remove(domain); }}
          validationMessage={textConstants.messageText.validationMessage.quickAddErrorMessage}
          description={textConstants.generalText.BlacklistQuickAddText}
        />

        {/* Bulk Delete Modal */}
        <Neo.Modal title={textConstants.generalText.BulkActionConfirmation} show={this.props.viewModel.blacklistedDomainHelper.showBulkActionModal}
          onClose={() => this.props.viewModel.blacklistedDomainHelper.showBulkActionModal = false}
          closeButton={{ text: "No", variant: "light" }}
          acceptButton={{
            text: "Yes", variant: "secondary",
            onClick:
              () => {
                this.props.viewModel.blacklistedDomainHelper.PerformBulkAction();
                searchFieldVM.hideNoResultDialog = false
              }
          }}>
          {this.props.viewModel.blacklistedDomainHelper.bulkActionMessage}
        </Neo.Modal>
      </div>
    );
  }
}