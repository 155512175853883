import { ModelBase, NeoModel, Attributes } from '@singularsystems/neo-core';

@NeoModel
export default class IROEditStatus extends ModelBase {

    public iroEditStatusId : number = 0

    @Attributes.Display("IRO Edit Status")
    public iroEditStatusName: string = ""

    public toString(): string {
        if (this.isNew || !this.iroEditStatusName) {
            return 'New IROEditStatus';
        } else {
            return this.iroEditStatusName;
        }
    }
}