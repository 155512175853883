import React from "react";
import { observer } from "mobx-react";
import LeadMessageVM from "./LeadMessageVM";
import * as Icon from 'react-feather';
import ActionListLeadStatus from "../../Models/ActionList/ActionListLeadStatus";
import { List, NotifyUtils } from "@singularsystems/neo-core";
import HtmlParser from "react-html-parser";
import LeadMessageSideMenu from "../../Components/LeadMessageSideMenu";
import EmailComponent from "./EmailComponent";
import ActionListDefault, { ActionListNewMessage, ActionListSearchTemplates } from "./ActionListLeadFooter";
import CustomDropDown, { IOptions } from "Components/CustomDropDown/CustomDropDown";
import moment from "moment";
import { convertActionListStatusesToCustomDropdown } from '../../common/utils';
import { Neo } from "@singularsystems/neo-react";
import { textConstants } from "common/textConstants";
import ReminderSideMenu from "Components/Reminder/ReminderSideMenu";
import ActionListCriteria from "Models/ActionList/Query/ActionListCriteria";
import DataTooltip from "Components/DataTooltip/DataTooltip";
import CopyToClipboard from "react-copy-to-clipboard";

interface ILeadMessage {
    viewModel: LeadMessageVM,
    back: () => void,
    newHotTotal: number,
    newWarmTotal: number,
    newColdTotal: number,
    leadStatuses?: List<ActionListLeadStatus>
    showPreviousMessage: () => void,
    showNextMessage: () => void,
    changeLeadType: (leadType: string) => void,
    isFirstRecord: boolean,
    isLastRecord: boolean,
    archiveCurrentLead: () => void,
    hotTotal: number,
    warmTotal: number,
    coldTotal: number,
    isLeadLoading: boolean,
    actionListCriteria: ActionListCriteria
}

@observer
export default class LeadMessageView extends React.Component<ILeadMessage> {

    public statuses: IOptions[] = []

    private getDropDownOptions() {
        const { viewModel } = this.props

        var options = convertActionListStatusesToCustomDropdown(viewModel.actionListStatuses)
        viewModel.statuses = options
    }

    componentWillUnmount() {
        const { viewModel } = this.props

        viewModel.customAuthService.globalProps.isZendeskVisible = true;
        viewModel.customAuthService.globalProps.showActionListLeadMessageSideMenu = false;

        sessionStorage.removeItem(textConstants.LocalStorage.ActionListCriteria)
    }

    componentDidMount(): void {
        this.getDropDownOptions()
    }

    private showCopyEmailSuccess() {
        const variant = "success"
        NotifyUtils.add(variant,
            textConstants.messageText.saveMessage.EmailToClipboard,
            variant as any, 5);
    }

    private copyEmailToClipboard(text: string, emailToCopy: string, buttonClassName?: string) {
        return <CopyToClipboard
            text={emailToCopy}
            onCopy={this.showCopyEmailSuccess}>
            {/* Copies the email */}
            <div className="col p-0">
                <DataTooltip
                    parent={
                        <button className={`emailNamePill ${buttonClassName}`}>
                            {text}
                        </button>}
                    type={"tooltip"}
                    variant="light"
                    direction="up"
                    className="leadmessage-tooltip">
                    {textConstants.generalText.ClickToCopy}
                </DataTooltip>
            </div>
        </CopyToClipboard>
    }

    public render() {
        const { viewModel } = this.props

        return (
            <>
                <LeadMessageSideMenu
                    newHotTotal={this.props.newHotTotal}
                    newWarmTotal={this.props.newWarmTotal}
                    newColdTotal={this.props.newColdTotal}
                    viewModel={viewModel}
                    back={() => { this.props.back() }}
                    showPreviousMessage={() => { this.props.showPreviousMessage() }}
                    showNextMessage={() => { this.props.showNextMessage() }}
                    changeLeadType={(leadType: string) => this.props.changeLeadType(leadType)}
                    isFirstRecord={this.props.isFirstRecord}
                    isLastRecord={this.props.isLastRecord}
                    hotTotal={this.props.hotTotal}
                    warmTotal={this.props.warmTotal}
                    coldTotal={this.props.coldTotal}
                    isLeadLoading={this.props.isLeadLoading}
                />

                <ReminderSideMenu viewModel={this.props.viewModel.reminderVM} />

                <div className="leadMessageView m-1">
                    {viewModel.leadLookup !== undefined &&
                        <>
                            {/* Prospect Name and Status Row */}
                            <div className='mt-2 mb-1 row'>

                                {/* Prospect name */}
                                <div className='prospectNameHeader'>
                                    {!viewModel.leadLookup.secondary_prospect ?
                                        `${viewModel.leadLookup.prospect_name.first_name} ${viewModel.leadLookup.prospect_name.last_name}` :
                                        viewModel.leadLookup.secondary_prospect.email}
                                </div>

                                {/*Status Column*/}
                                <div className={`px-0 ml-0 mt-1 col`}>

                                    {viewModel.statuses.length > 0 &&
                                        <CustomDropDown
                                            className="statusDropdown"
                                            options={viewModel.statuses}
                                            placeHolder={viewModel.statuses.find(s => s.value === this.props.viewModel.leadLookup?.cluster_info.category)?.label || ""}
                                            isDisabled={true} />
                                    }
                                </div>

                                {/* Archive button */}
                                <div className={`px-0 ml-0 mt-1`}>
                                    <Neo.Button
                                        icon={<Icon.Archive />}
                                        className="my-auto p-0 buttonHover archiveButton btn btn-light"
                                        onClick={() => { this.props.archiveCurrentLead() }}
                                    >
                                        {textConstants.ActionListText.ArchiveButtonText.Archive}
                                    </Neo.Button>
                                </div>
                            </div>

                            <div className="headerRow"></div>

                            {/* Status Change Section */}
                            <div className='row greyPanel mt-3'>

                                {/* Status change name and details */}
                                <div className='col-11 p-0'>
                                    <b>{viewModel.leadLookup?.sentToName}</b> Changed status to: <b>
                                        {HtmlParser(viewModel.statuses.find(s => s.value === viewModel.leadLookup?.cluster_info.category)?.label || "")}
                                    </b>
                                </div>

                                {/* Date of status change */}
                                <div className='col-1 p-0 modifiedDate'>
                                    {viewModel.leadLookup?.statusChangeDate}
                                </div>

                            </div>

                            {/* Replied date and details */}
                            {viewModel.replyLookup.responded_back_at &&
                                <div className='row greyPanel mt-3'>
                                    <div className='col-11 p-0 mb-1'>
                                        {textConstants.ActionListText.YouRepliedOn}: <b> {moment(viewModel.replyLookup.responded_back_at).format(textConstants.dateformat.DDMMYYYY)} </b>
                                    </div>
                                </div>
                            }

                            {/* Subject Row */}
                            <div className='row greyPanel messagePanel mt-4'>

                                {/* Subject details */}
                                <div className='col-10 p-0'>
                                    <button className='initialsButton' disabled={true} > {
                                        (viewModel.leadLookup?.prospect_name.first_name + " " + viewModel.leadLookup?.prospect_name.last_name).split(' ').map((x) => x.charAt(0)).join('').toUpperCase()}
                                    </button> <b className='pl-3'>{textConstants.ActionListText.Reply}</b> - {viewModel.replyLookup.subject}
                                </div>

                                {/* Date of message */}
                                <div className='col-2 p-0 modifiedDate'>
                                    {moment(viewModel.leadLookup?.delivered_at).format(textConstants.dateformat.DDMMYYYY)}
                                </div>
                            </div>

                            {/* Reply Message */}
                            <div className='row messageBodyRow'>

                                {/* From */}
                                <div className='col-12 p-0 row'>
                                    <div className="col-5 pr-0 alignCenter">
                                        <div className="row m-0">
                                            <b className='pr-2 m-auto'>From: </b>

                                            {this.copyEmailToClipboard(
                                                viewModel.leadLookup.secondary_prospect
                                                    ? viewModel.leadLookup.secondary_prospect.email
                                                    : `${viewModel.leadLookup?.prospect_name.first_name} ${viewModel.leadLookup?.prospect_name.last_name} (${viewModel.leadLookup?.prospect_email})`
                                                , viewModel.leadLookup.secondary_prospect
                                                    ? viewModel.leadLookup.secondary_prospect.email
                                                    : viewModel.leadLookup.prospect_email)
                                            }
                                        </div>
                                    </div>

                                    {/* Original */}
                                    {viewModel.leadLookup.secondary_prospect &&
                                        <div className="col pr-0 alignCenter">
                                            <div className="row m-0 ">
                                                <b className='m-auto pr-2'>{textConstants.generalText.PrimaryProspect}: </b>

                                                {this.copyEmailToClipboard(
                                                    `${viewModel.leadLookup?.prospect_name.first_name} ${viewModel.leadLookup?.prospect_name.last_name} (${viewModel.leadLookup?.prospect_email})`
                                                    , viewModel.leadLookup.prospect_email
                                                    , 'recipient')
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/* To */}
                                <div className='row col-12 mt-2 alignCenter'>
                                    to:
                                    {this.copyEmailToClipboard(
                                        viewModel.leadLookup?.campaign_email
                                        , viewModel.leadLookup.campaign_email
                                        , 'recipient ml-2')
                                    }
                                </div>

                                {/* Message Body */}
                                <div className='row col-12 mt-4'>
                                    {HtmlParser(viewModel.replyLookup.message)}
                                </div>

                            </div>

                            {/* Information Panel Row */}
                            {viewModel.isInformationBarVisible &&
                                <div className='row infoYellowPanel mt-4'>

                                    {/* Info Text */}
                                    <div className='col-11 p-0'>
                                        {HtmlParser(viewModel.infoBarContent)}
                                        <button className='learnMoreButton ml-4 pl-4 pr-4'
                                            onClick={() => { window.open(viewModel.infoBarRedirectUrl, "_blank") }}>
                                            {textConstants.buttonText.learnMore}
                                        </button>
                                    </div>

                                    {/* X (Close button) */}
                                    <div className='col-1 p-0 textAlignRight'>
                                        <Icon.X className="closeButton" onClick={() => {
                                            viewModel.isInformationBarVisible = false
                                            viewModel.UpdateLeadInfoBar()
                                        }}></Icon.X>
                                    </div>
                                </div>
                            }
                        </>
                    }

                    {/* New Message */}
                    {viewModel.isMessageCreatorOpen &&
                        <div className="row ">
                            <EmailComponent
                                viewModel={this.props.viewModel}
                                leadLookup={viewModel.leadLookup}
                                subject={viewModel.replyLookup.subject}
                                content={viewModel.messageContent} // The actual email text
                            /></div>
                    }

                    {/* Lead Footer */}
                    <div className="row emailFooter">
                        {(!viewModel.isMessageCreatorOpen || viewModel.isMaximized === false) &&
                            <ActionListDefault viewModel={this.props.viewModel} />
                        }
                        {!viewModel.isSearchingTemplates && viewModel.isMessageCreatorOpen && viewModel.isMaximized !== false &&
                            <ActionListNewMessage viewModel={viewModel} />
                        }
                        {viewModel.isSearchingTemplates && viewModel.isMessageCreatorOpen && viewModel.isMaximized !== false &&
                            <ActionListSearchTemplates viewModel={viewModel} />
                        }
                    </div>
                </div>

                {/* Validation Message */}
                <Neo.Modal
                    title={textConstants.titleText.InvalidData}
                    show={viewModel.showInvalidDataModal}
                    onClose={() => {
                        viewModel.showInvalidDataModal = false;
                    }}
                    closeButton={{ text: "Close", variant: "light" }}
                >
                    {viewModel.invalidDataMessage}
                </Neo.Modal>
            </>
        )
    }
}