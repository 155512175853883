import {ModelBase, NeoModel} from '@singularsystems/neo-core';
  
@NeoModel
export default class UploadNotificationLookup extends ModelBase {      
    public username: string = "";
    
    public isSuccess: boolean = false;

    public hasUnsuccessfulDomains: boolean = false;

    public message: string = "Failed to process uploaded file.";

    public messageHeader: string = "File Upload Processing Failure";

    public errorFile: any = null;

    public errorFileName: string = "";
}