export interface Dictionary {
  [key: string]: string;
}

export default class DownloadICPCommand {

  public employeeSizes: Dictionary = {};

  public productWorth: Dictionary = {};

}