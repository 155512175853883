import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class CampaignMessageStatus extends ModelBase {

    public campaignMessageStatusId: number = 0;
    
    @Rules.StringLength(500)
    @Attributes.Display("Message Status")
    public messageStatus: string = "";

    public uniqueTableKey: string = "";

    // Client only properties / methods
    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.messageStatus === null || c.messageStatus === '', textConstants.messageText.validationMessage.messageStatus);
    }

    public toString(): string {
        if (this.isNew || !this.messageStatus) {
            return "New campaign message status";
        } else {
            return this.messageStatus;
        }
    }
}