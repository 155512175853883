import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class TargetMarketSpecificRole extends ModelBase {

    public targetMarketSpecificRoleId: number = 0;   

    public targetMarketId: number = 0;

    public targetMarketSpecificRoleName: string = "";
}