import { Neo, NeoGrid } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { textConstants } from "../../common/textConstants"
import AdvancedEmailSettingsVM from "./AdvancedEmailSettingsVM";
import * as Icon from "react-feather";
import { StyledEmailCardHeading } from "Views/EmailSetup/styles";

export interface IAdvancedEmailSettingsSection {
  viewModel: AdvancedEmailSettingsVM;
  hasEditPermissions: boolean;
  showSegregator?: boolean;
  isOnboarding?: boolean
  addPadding?: boolean
}

@observer
export default class AdvancedEmailSettingsSection extends React.Component<IAdvancedEmailSettingsSection> {

  public render() {
    return (
      <div className={this.props.addPadding ? "p-24": "p-0"}>
        
        {this.props.showSegregator && 
          <div className="sectionSegregator"/>
        }

        <div className={"row"}>
          <div className="col-12">
            {!this.props.isOnboarding &&
              <h3 className="pt-0 mt-4 mb-3">
                {textConstants.AdvancedEmailSettings}
              </h3>
            }
            {this.props.isOnboarding &&
              <StyledEmailCardHeading className="mt-4 mb-3">
                {textConstants.AdvancedEmailSettings}
              </StyledEmailCardHeading>
            }
          </div>
        </div>

        <div className={"row"}>
          <div className="col-12 mb-3">
            <div className="item">
              {/* FIREWALL */}
              <Neo.FormGroup
                bind={this.props.viewModel.advancedEmailSettingsLookup.meta.isFirewall}
                disabled={!this.props.hasEditPermissions}
                input={{ type: "checkbox" }}
                label={
                  <span>
                    {textConstants.EmailSettings.AdvancedEmailSettings.FirewallLabel}
                    <i
                      className="IconQuestions"
                      data-tip={this.props.viewModel.advancedEmailSettingsLookup.firewallTooltip}
                    >
                      <Icon.HelpCircle color="#b3b7b7" size={16} />
                    </i>
                  </span>
                }
              />
            </div>

            {/* Firewall extra: IP Address Boxes */}
            {this.props.viewModel.advancedEmailSettingsLookup.isFirewall 
              && 
              <div className="itemInfoFirewall">
                {textConstants.EmailSettings.AdvancedEmailSettings.FirewallInfo}
              </div>
            }

            <div>
              {/* DKIM */}
              <Neo.FormGroup
                bind={this.props.viewModel.advancedEmailSettingsLookup.meta.isDKIM}
                className="borderRadius"
                input={{ type: "checkbox" }}
                disabled={!this.props.hasEditPermissions}
                label={
                  <span>
                    {textConstants.EmailSettings.AdvancedEmailSettings.DKIMLabel}
                    <i
                      className="IconQuestions"
                      data-tip={this.props.viewModel.advancedEmailSettingsLookup.dkimTooltip}
                    >
                      <Icon.HelpCircle color="#b3b7b7" size={16} />
                    </i>
                  </span>
                }
              />
            </div>

            <div className="item">
              <div className="itemInfo">
                {textConstants.EmailSettings.AdvancedEmailSettings.RequiredSectionInfo}
              </div>

              {/* SPF */}
              <Neo.FormGroup
                bind={this.props.viewModel.advancedEmailSettingsLookup.meta.isSPF}
                disabled={!this.props.hasEditPermissions}
                input={{ type: "checkbox" }}
                label={
                  <span>
                    {textConstants.EmailSettings.AdvancedEmailSettings.SPFLabel}
                    <i
                      className="IconQuestions"
                      data-tip={this.props.viewModel.advancedEmailSettingsLookup.spfTooltip}
                    >
                      <Icon.HelpCircle color="#b3b7b7" size={16} />
                    </i>
                  </span>
                }
              />

              {/* CNAME */}
              <Neo.FormGroup
                bind={this.props.viewModel.advancedEmailSettingsLookup.meta.isCNAME}
                input={{ type: "checkbox" }}
                disabled={!this.props.hasEditPermissions}
                label={
                  <span>
                    {textConstants.EmailSettings.AdvancedEmailSettings.CNameLabel}
                    <i
                      className="IconQuestions"
                      data-tip={this.props.viewModel.advancedEmailSettingsLookup.cnameTooltip}
                    >
                      <Icon.HelpCircle color="#b3b7b7" size={16} />
                    </i>
                  </span>
                }
              />
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}
