import { List, Misc, NeoModel } from "@singularsystems/neo-core";
import { Managers, Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../Services/AppService";
import CommandResult from "../../Models/InvitedUsers/Commands/CommandResult";
import { textConstants } from "../../common/textConstants";
import EditClient from "../../Models/Client/Commands/EditClient";
import InvitedUsersViewModel from "../InvitedUsers/Component/InvitedUserViewModel";
import DeleteBlacklistedDomain from "../../Models/BlacklistedDomains/Commands/DeleteBlacklistedDomain";
import QuickAddItem, {
} from "../../Models/BlacklistedDomains/QuickAddDomain";
import BlacklistedDomainCriteria from "../../Models/BlacklistedDomains/Query/BlacklistedDomainCriteria";
import GreyListedProspectHelper from './GreylistedProspect/GreyListedProspectHelper';

import BlacklistedDomainHelper from "./BlacklistedDomain/BlacklistedDomainHelper";
import BatchReviewUploadVM from "../BatchReview/BatchReviewUploadVM";
import AuthorisationTypes from "../../Authorisation/AuthorisationTypes";
import UserGroupLookup from "../../Authorisation/Models/UserGroupLookup";
import UserGroupsViewModel from "../../Authorisation/Views/UserGroupsViewModel";
import GroupMembershipLookup from "../../Authorisation/Models/GroupMembershipLookup";
import AdvancedEmailSettingsVM from "../../Components/TechnicalIntegrationSettings/AdvancedEmailSettingsVM";
import InfoCardVM from '../../Components/InfoCardVM';
import OnboardingStepLookup from 'Models/Onboarding/OnboardingStepLookup';
import { OnboardingSteps, OnboardingPopups } from 'Models/Enums/ApplicationEnums';
import UpdateHasSeenOnboardingStepPopupCommand from 'Models/Onboarding/Commands/UpdateHasSeenOnboardingStepPopupCommand';
import OnboardingPopupLookup from 'Models/Onboarding/Queries/OnboardingPopupLookup';
import ClientNavigationButtonsVM from "Components/ClientNavigationButtonsVM";
import OnboardingFunctions from "Models/Onboarding/OnboardingFunctions";

type bulkActions = "BulkDelete" | ""

@NeoModel
export default class ClientSettingsVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient),
    private authService = AppService.get(Types.Neo.Security.AuthenticationService),
    private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
    private blacklistedDomainsApiClient = AppService.get(Types.ApiClients.BlacklistedDomainsApiClient),
    private greyListedProspectApiClient = AppService.get(Types.ApiClients.GreyListedProspectApiClient),
    public authApiClient = Misc.Globals.appService.get(AuthorisationTypes.ApiClients.AuthorisationApiClient),
    private calendlyLinksApiClient = AppService.get(Types.ApiClients.CalendlyLinksApiClient),
    public customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
    private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient),
    public appDataCache = AppService.get(Types.Services.AppDataCache)) {
    super(taskRunner);
  }
  public selectedTab: string = textConstants.titleText.Client;

  public isComXUser: boolean | null = null
  public showBlacklist: boolean = false
  public showBatchReview: boolean = false

  public clientDetails = new EditClient();
  public clientId: number = 0;
  public InvitedUsersViewModel = new InvitedUsersViewModel();
  public showInvalidDataModal: boolean = false;
  public invalidDataMessage: string = "";
  public showForbiddenModal: boolean = false;
  public deleteBlacklistedDomainCommand = new DeleteBlacklistedDomain();
  public showBlacklistDeleteModal: boolean = false;
  public showBlacklistUploadModal: boolean = false;
  public selectedDomainName: string = "";
  public columnHeadings: string[] = textConstants.generalText.DomainWebsiteEmail;
  public clientName: string = "";
  // public domainRegex: string = "";
  public QuickAddString: QuickAddItem = new QuickAddItem();
  public QuickAddErrorDomains: string[] = [];
  public showQuickAddModal: boolean = false;
  public userGroups = new List(UserGroupLookup);
  public userGroupsViewModel = new UserGroupsViewModel();
  public groupMemberships = new List(GroupMembershipLookup);
  public calendlyLinks = this.appDataCache.calendlyLinks.get().data;
  public isCalendlyLinkSelected: boolean = false;
  public doNotBlacklistTooltip: string = "Do Not Blacklist Other Prospects After Reply";
  public isInitialLoad: boolean = true;

  public greyListProspectHelper: GreyListedProspectHelper = new GreyListedProspectHelper(
    this.taskRunner,
    this.greyListedProspectApiClient,
    this.notifications,
    this.clientId,
    this.clientDetails,
    this.isComXUser ? this.isComXUser : false
  )

  public clientNavigationButtonVM = new ClientNavigationButtonsVM();

  public batchReviewUploadVM: BatchReviewUploadVM = new BatchReviewUploadVM();

  public advancedEmailSettingsVM = new AdvancedEmailSettingsVM();
  public infoCardVM = new InfoCardVM();
  public isHeaderCardExpanded: boolean = true;
  public isEmptyCardExpanded: boolean = false;

  public bulkActionMessage: string = ""
  public bulkActions: bulkActions | "" = ""
  public blacklistSearchCriteria: BlacklistedDomainCriteria = new BlacklistedDomainCriteria();
  public showBulkActionModal: boolean = false

  public tabManager = new Managers.TabManager();

  public blacklistedDomainHelper: BlacklistedDomainHelper = new BlacklistedDomainHelper(
    this.taskRunner,
    this.blacklistedDomainsApiClient,
    this.notifications,
    this.clientId,
    this.clientDetails
  )

  public navigateTo: string = "";

  public TargetMarketWasPrevious: boolean = true
  public CampaignMessagesWasPrevious: boolean = true

  public showOnboardingModal: boolean = false;

  public onboardingStepId?: number = 0

  public onboardingPopupModalContent: string = "";

  public async toggleTab(
    old_selectedTab: string,
    new_selectedTab: any,
    clientID: number
  ) {
    const selectedValue = String(new_selectedTab).toLocaleLowerCase();
    if (clientID > 0) {
      // Client Tab
      if (selectedValue === textConstants.titleText.Client.toLocaleLowerCase()) {
        const response = await this.taskRunner.waitFor(
          this.authApiClient.userGroups.getLookupAsync()
        );
        this.userGroups.set(response.data);

        if (this.calendlyLinks.length === 0) {
          let calendlyLinksResponse = await this.calendlyLinksApiClient.get();

          if (calendlyLinksResponse.data) {
            this.calendlyLinks = calendlyLinksResponse.data
          }
        }
      }

      // TechnicalIntegrationSettings Tab
      if (selectedValue === textConstants.TechnicalIntegrationSettings.toLocaleLowerCase()) {
        this.advancedEmailSettingsVM = new AdvancedEmailSettingsVM();
        this.advancedEmailSettingsVM.taskRunner = this.taskRunner
        await this.advancedEmailSettingsVM.setAdvancedEmailSettings(this.clientId);
      }

      // Users Tab
      if (selectedValue === textConstants.titleText.Users.toLocaleLowerCase()) {
        this.initialiseInviteUsers(clientID);
      }

      // Blacklist Tab
      if (
        selectedValue === textConstants.titleText.Blacklist.toLocaleLowerCase()
      ) {

        this.blacklistedDomainHelper = new BlacklistedDomainHelper(
          this.taskRunner,
          this.blacklistedDomainsApiClient,
          this.notifications,
          this.clientId,
          this.clientDetails
        )

        this.blacklistedDomainHelper.blacklistSearchCriteria.clientId = clientID;
        const regexResponse = await this.blacklistedDomainsApiClient.getDomainRegex();
        this.blacklistedDomainHelper.domainRegex = regexResponse.data;

        this.infoCardVM.title = textConstants.generalText.BlacklistTitleText
        this.infoCardVM.shortDescription = textConstants.generalText.BlacklistIntroText
        this.infoCardVM.fullDescription = textConstants.generalText.BlacklistText

      }

      // Greylist Tab
      if (selectedValue === textConstants.titleText.GreyList.toLocaleLowerCase()) {
        this.greyListProspectHelper = new GreyListedProspectHelper(
          this.taskRunner,
          this.greyListedProspectApiClient,
          this.notifications,
          this.clientId,
          this.clientDetails,
          this.isComXUser ? this.isComXUser : false
        )

        this.greyListProspectHelper.clientDetails = this.clientDetails
        this.greyListProspectHelper.clientId = this.clientId
        this.greyListProspectHelper.greyListProspectSearchCriteria.clientId = clientID;

        this.taskRunner.run(async () => {
          const regexResponse = await this.greyListedProspectApiClient.getEmailRegex();
          this.greyListProspectHelper.emailRegex = regexResponse.data;
        });

        this.infoCardVM.title = textConstants.generalText.GreylistTitleText
        this.infoCardVM.shortDescription = textConstants.generalText.GreylistIntroText
        this.infoCardVM.fullDescription = textConstants.generalText.GreylistText
      }

      // Batch Review Tab
      if (selectedValue === textConstants.titleText.BatchReviewSpaced.toLowerCase()) {
        this.batchReviewUploadVM = new BatchReviewUploadVM()
        this.batchReviewUploadVM.taskRunner = this.taskRunner
        this.batchReviewUploadVM.BatchReviewUploadCriteria.isComXUser = true;
        this.batchReviewUploadVM.BatchReviewUploadCriteria.clientId = clientID;
        this.batchReviewUploadVM.batchReviewUploadPageManager.refreshData();
      }
    }
  }

  public async initialise() {
  }

  public async onboardingModalSetup() {
    const {
      onboardingStepId,
      showOnboardingModal,
      onboardingPopupModalContent,
    } = await this.customAuthService.onboardingFunctions.getOnboardingData();

    this.onboardingStepId = onboardingStepId;
    this.showOnboardingModal = showOnboardingModal;
    this.onboardingPopupModalContent = onboardingPopupModalContent;
  }

  public async setStepCompleteModalAsSeen() {
    await this.customAuthService.onboardingFunctions.setStepCompleteModalAsSeen(this.onboardingStepId);
  }

  public async completeBlacklistStep() {
    await this.StepIsCompleteOnboarding();
    this.customAuthService.globalProps.showOnboardingHelpCenter = false;
    this.onboardingModalSetup()
  }

  private async StepIsCompleteOnboarding() {
    if (this.customAuthService.globalProps.isOnboarding) {
      await this.customAuthService.onboardingFunctions.updateOnboardingStep();
    }
  }

  public async fetchClient(clientId: number) {
    await this.taskRunner.run(async () => {
      const clientDetailsResponse = await this.clientsApiClient.getClientForEdit(clientId);
      const isClientResponse = await this.invitedUsersApiClient.isClientUser(this.authService!.user!.userName);
      this.isComXUser = !isClientResponse.data
      this.clientDetails.set(clientDetailsResponse.data);
      this.clientDetails.isSelfDirty = false;
    });

    this.clientName = this.clientDetails.clientName

    this.clientNavigationButtonVM.navigateTo = this.navigateTo;
    this.clientNavigationButtonVM.clientName = this.clientName;
    this.clientNavigationButtonVM.isClientSettings = true;

    // External (Client)
    if (!this.isComXUser) {
      // Show Blacklist tab      
      await this.toggleTab("", textConstants.titleText.Blacklist, this.clientId)
    } else {
      // Internal (ComXUser)

      // Show Client tab    
      await this.toggleTab("", textConstants.titleText.Client, this.clientId)
      this.tabManager.selectedTab = textConstants.titleText.Client
    }
  }

  public initialiseInviteUsers(clientId: number) {
    this.InvitedUsersViewModel.taskRunner = this.taskRunner;
    this.InvitedUsersViewModel.invitedUserPage = true;
    this.InvitedUsersViewModel.clientId = clientId;
    this.InvitedUsersViewModel.initialise();
    this.InvitedUsersViewModel.setupComponent();
    this.InvitedUsersViewModel.pageManagerInvited.refreshData();
  }

  public save() {
    this.runValidationChecks();
    if (this.showInvalidDataModal) {
      return;
    }

    this.taskRunner.run(async () => {
      const response = await this.clientsApiClient.editClient(this.clientDetails);
      const cr: CommandResult = response.data as CommandResult;

      if (cr.success) {
        this.notifications.addSuccess(
          textConstants.titleText.Saved,
          textConstants.messageText.saveMessage.ClientSaved
        );
      }
    }).then(async () => {
      const clientDetailsResponse = await this.clientsApiClient.getClientForEdit(this.clientDetails.clientId);
      this.clientDetails.set(clientDetailsResponse.data);
      this.clientName = this.clientDetails.clientName
    });

  }

  public async assignAppropriateUserRoles(allowCampaignMessageRevoke: boolean = true) {

    const clientUsersResponse = await this.clientsApiClient.getLinkedUsers(this.clientId);
    const clientUsers = clientUsersResponse.data
    var clientUserGroupId = this.userGroups.find(ug => ug.userGroupName === "Client")!.userGroupId
    var batchReviewUserGroupId = this.userGroups.find(ug => ug.userGroupName === "Client Batch Review")!.userGroupId
    var campaignReviewUserGroupId = this.userGroups.find(ug => ug.userGroupName === "Client Campaign Review")!.userGroupId
    var userGroupIDs: number[] = []
    var userIds: number[] = []

    // Add relevant user groups
    if (this.clientDetails.isClientReview) { userGroupIDs.push(batchReviewUserGroupId) }
    if (this.clientDetails.isCampaignMessagesReview) { userGroupIDs.push(campaignReviewUserGroupId) }

    if (clientUserGroupId) {
      // Get Client Group membership info
      const response = await this.authApiClient.userGroups.get(clientUserGroupId);
      this.groupMemberships.set(response.data.groupMemberships);

      // Get user IDs of all linked users
      clientUsers.forEach(userName => {
        var user = this.groupMemberships.find(gm => gm.userName === userName)
        if (user) {
          userIds.push(user.userId)
        }
      });

      // Revoke Batch Review access
      if (!this.clientDetails.isClientReview) {
        await this.revokeBatchReviewAccess(batchReviewUserGroupId, userIds);
      }

      // Revoke Campaign Messages access
      if (allowCampaignMessageRevoke) {
        if (!this.clientDetails.isCampaignMessagesReview) {
          await this.revokeCampaignMessagesAccess(campaignReviewUserGroupId, userIds);
        }
      }

      // Add all linked users to applicable groups
      userGroupIDs.forEach(userGroupId => {
        this.userGroupsViewModel.userGroup.userGroupId = userGroupId

        userIds.forEach(userId => {
          this.userGroupsViewModel.memberFindCriteria.addUserId = userId
          this.userGroupsViewModel.addMember(true)
        });
      });


    }
  }

  private async revokeBatchReviewAccess(batchReviewUserGroupId: number, userIds: any[]) {
    const response = await this.authApiClient.userGroups.get(batchReviewUserGroupId);
    var batchReviewMemberships: List<GroupMembershipLookup> = response.data.groupMemberships;

    batchReviewMemberships.forEach(membership => {
      let match = userIds.filter(f => f === membership.userId)
      if (match.length > 0) {
        this.userGroupsViewModel.removeMember(membership, true);
      }

    });
  }

  private async revokeCampaignMessagesAccess(campaignReviewUserGroupId: number, userIds: any[]) {
    const response = await this.authApiClient.userGroups.get(campaignReviewUserGroupId);
    var campaignReviewMemberships: List<GroupMembershipLookup> = response.data.groupMemberships;

    campaignReviewMemberships.forEach(membership => {
      let match = userIds.filter(f => f === membership.userId)
      if (match.length > 0) {
        this.userGroupsViewModel.removeMember(membership, true);
      }
    });
  }

  // Method to validate complete URLs
  public isURL(str: string) {
    let regex: RegExp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (
      regex.test(str) &&
      (str.indexOf("https://") !== -1 || str.indexOf("http://") !== -1)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public runValidationChecks() {
    this.showInvalidDataModal = false;
    this.invalidDataMessage = "";

    // Platform Setup
    if (
      this.clientDetails.platformSetup !== "" &&
      this.clientDetails.platformSetup !== null &&
      !this.isURL(this.clientDetails.platformSetup)
    ) {
      this.invalidDataMessage =
        textConstants.messageText.validationMessage.invalidPlatformSetup + "\n";
      this.showInvalidDataModal = true;
    }
    // don't allow save if max target markets > 0
    if (this.clientDetails.maxTargetMarkets === 0) {
      this.invalidDataMessage += textConstants.messageText.validationMessage.invalidMaxTargetMarkets + "\n";
      this.showInvalidDataModal = true;
    }
    // Success Tracking
    if (
      this.clientDetails.successTracking !== "" &&
      this.clientDetails.successTracking !== null &&
      !this.isURL(this.clientDetails.successTracking)
    ) {
      this.invalidDataMessage +=
        textConstants.messageText.validationMessage.invalidSuccessTracking +
        "\n";
      this.showInvalidDataModal = true;
    }
    // Action Plan Checklist
    if (
      this.clientDetails.actionPlanChecklist &&
      !this.isURL(this.clientDetails.actionPlanChecklist)
    ) {
      this.invalidDataMessage +=
        textConstants.messageText.validationMessage.invalidActionPlanChecklist +
        "\n";
      this.showInvalidDataModal = true;
    }
  }

  public removeNonNumerics() {
    if (isNaN(this.clientDetails.maxTargetMarkets)) {
      this.clientDetails.maxTargetMarkets = 0;
    }
  }

  public preventSpaceBarUpdate(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === " ") {
      event.preventDefault();
    }
  }
}
