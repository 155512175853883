import React from "react";
import { Neo, Views } from "@singularsystems/neo-react";
import OnboardingVM from "./OnboardingVM";
import { observer } from "mobx-react";
import WelcomeView from "./WelcomeView";
import TargetMarketView from "../TargetMarket/TargetMarketView";
import CustomerProfileQuestionnaireVM from "../IdealCustomerProfiles/CustomerProfileQuestionnaireVM";
import EmailSetupView from "./EmailSetupView";
import EmailSetupVM from "./EmailSetupVM";
import { textConstants } from "../../common/textConstants";
import CompleteYourSetupView from "./CompleteYourSetup";
import AddEditTargetMarketView from "../TargetMarket/AddEditTargetMarketView";
import AddEditTargetMarketVM from "../TargetMarket/AddEditTargetMarketVM";
import TargetMarketVM from "../TargetMarket/TargetMarketVM";
import TargetMarketAccountsView from "../TargetMarketAccounts/TargetMarketAccountsView";
import TargetMarketAccountsVM from "../TargetMarketAccounts/TargetMarketAccountsVM";
import CustomerProfilesView from "../IdealCustomerProfiles/CustomerProfilesView";
import { OnboardingSteps } from "../../Models/Enums/ApplicationEnums";
import BlacklistedDomain from "../Client/BlacklistedDomain/BlacklistedDomain";
import OnboardingIntroduction from "./OnboardingIntroduction";
import CustomerProfileQuestionnaireView from "Views/IdealCustomerProfiles/CustomerProfileQuestionnaireView";
import { OnboardingFooter, OnboardingNextStepFooter } from "Components/FooterComponent";
import OnboardingCampaignMessageBaseViewVM from './OnboardingCampaignMessageBaseViewVM';
import OnboardingCampaignMessageBaseView from './OnboardingCampaignMessageBaseView';
import OnboardingTechnicalSettings from "./OnboardingTechnicalSettings";
import OnboardingTechnicalSettingsVM from "./OnboardingTechnicalSettingsVM";
import { targetMarketAccountRoute } from "RouteConfiguration";
class OnboardingViewParams {
  public clientId = {};
  public onboardingMode = {}; // Technical Integration
  public targetMarketId = {};
  public targetMarketMode = {}; // Target Market Accounts , EditTargetMarket , ProspectTargetMarket, AddClientList
  public clientCampaignMessagesId = {};
  public campaignMessagesMode = {};
  public isFirstOnboardingLogin = {};
}

@observer
export default class OnboardingView extends Views.ViewBase<OnboardingVM, OnboardingViewParams> {

  constructor(props: any) {
    super("Onboarding", OnboardingVM, props);

    document.addEventListener("stepChange", () => {
      this.viewParams.onboardingMode.value = this.getViewNameByStep();
      this.canSeeListView()
    });

    this.setUpTechnicalDetails();
  }

  private canSeeListView() {
    switch (this.viewModel.customAuthService.globalProps.currentStep) {
      case OnboardingSteps.TargetMarkets:
        this.viewModel.canSeeTMListView = true;
        break;
      case OnboardingSteps.IdealCustomerProfiles:
        this.viewModel.canSeeICPListView = true;
        break;
    }
  }

  static params = new OnboardingViewParams();

  private isTargetMarketVisible: boolean = false;

  private isEmailSetup: boolean = false;

  public async componentDidMount() {
    const { globalProps } = this.viewModel.customAuthService;

    // if the user refreshes, we will need to reload the data from the db
    this.viewParams.clientId.value = globalProps.clientId;

    await this.viewModel.setOnboardingData();
    this.viewModel.canSeeTMListView = true;
    this.viewModel.canSeeICPListView = true;
  }

  private isCampaignMessage() {
    const { globalProps } = this.viewModel.customAuthService;

    return (globalProps.currentStep === OnboardingSteps.BookAMeeting)
      || (globalProps.currentStep === OnboardingSteps.CampaignMessages)
  }

  public async viewParamsUpdated() {
    const { globalProps } = this.viewModel.customAuthService;
    this.viewModel.saveButtonDisabled = true;
    this.viewModel.isNotFinalStep(true)

    if (this.isEmailSetup) {
      this.viewParams.onboardingMode.value = `EmailSetup`;
      this.viewModel.emailSetupViewModel = new EmailSetupVM();
      this.viewModel.emailSetupViewModel.clientId = globalProps.clientId;
      this.viewModel.emailSetupViewModel.taskRunner = this.viewModel.taskRunner;

      await this.viewModel.emailSetupViewModel.initialise();
    }

    await this.setViewModelByStep();

    this.viewModel.setLastActiveStepIntroduction();
    this.viewModel.setStepIntroductionButtonText();
    this.viewModel.setSaveButtonDisabled();
    this.forceUpdate();

    await this.setUpTechnicalDetails();
  }

  private getText() {
    let currentStep = this.viewModel.customAuthService.globalProps.currentStep;

    switch (currentStep) {
      case OnboardingSteps.IdealCustomerProfiles:
        return `${textConstants.buttonText.GoTo} ${textConstants.titleText.TargetMarkets}`;
      case OnboardingSteps.TargetMarkets:
        return `${textConstants.buttonText.GoTo} ${textConstants.titleText.Blacklist}`;
      case OnboardingSteps.BlackList:
        return `${textConstants.buttonText.GoTo} ${textConstants.titleText.CampaignMessage}`;
      case OnboardingSteps.CampaignMessages:
        return `${textConstants.buttonText.FinishOnboarding}`;
      case OnboardingSteps.TechnicalIntegration:
        return this.viewModel.isNotFinalStep() ? `${textConstants.buttonText.GoTo} ${this.viewModel.nextStep}` : `${textConstants.buttonText.FinishOnboarding}`;
      default:
        return "";
    }
  }

  private canGoToNextStep() {
    const { globalProps } = this.viewModel.customAuthService

    return globalProps.onboardingSteps.find(x => x.stepNumber === globalProps.currentStep)?.isComplete
      && !this.viewModel.customAuthService.onboardingFunctions.isOnboardingHelpCenterVisible() && this.viewModel.isIntroductionComplete &&
      this.viewParams.onboardingMode.value !== targetMarketAccountRoute.path &&
      this.viewParams.onboardingMode.value !== textConstants.Routes.CustomerProfileQuestionnaire &&
      this.viewParams.onboardingMode.value !== textConstants.Routes.CampaignMessage
  }

  private canGoToNextStepByCurrentStep() {
    const { globalProps } = this.viewModel.customAuthService

    switch (globalProps.currentStep) {
      case (OnboardingSteps.TargetMarkets): return !this.viewModel.targetMarketViewModel.isArchived
        && this.viewModel.targetMarketViewModel.pageManager.totalRecords > 0
        && this.viewModel.targetMarketViewModel.pageManager.totalRecords !== -1
        && this.viewModel.targetMarketViewModel.hasPageManagerRefreshed
      default: return true;
    }

  }

  private getViewNameByStep(): string {
    const { globalProps } = this.viewModel.customAuthService;

    let stepNumber = globalProps.currentStep;

    if (stepNumber === OnboardingSteps.IdealCustomerProfiles && this.viewModel.showICPQuestionnaireView) {
      if (this.viewModel.customerProfileId !== 0) {
        return `CustomerProfileQuestionnaire/${this.viewModel.customerProfileId}}`;
      }

      return `CustomerProfileQuestionnaire`;
    }

    if (stepNumber === OnboardingSteps.IdealCustomerProfiles)
      return this.viewParams.onboardingMode.value === "CustomerProfile"
        ? "AddCustomerProfile"
        : "CustomerProfile"; // Need this, a refresh View params to remain the same. hence not updating vm

    if (stepNumber === OnboardingSteps.TargetMarkets)
      return this.viewParams.onboardingMode.value === "TargetMarkets"
        ? "AddTargetMarkets"
        : "TargetMarkets";

    if (stepNumber === OnboardingSteps.BlackList)
      return this.viewParams.onboardingMode.value === "BlackList"
        ? "AddBlackList"
        : "BlackList";

    if (stepNumber === OnboardingSteps.BookAMeeting)
      return "Meeting";

    if (stepNumber === OnboardingSteps.CampaignMessages) {
      if (this.viewParams.onboardingMode.value === "CampaignMessages" || "AddCampaignMessage") {
        return this.viewParams.onboardingMode.value === "CampaignMessage"
          ? "AddCampaignMessage"
          : "CampaignMessage";
      }

      if (this.viewParams.onboardingMode.value === "EditFollowUpTemplate" || "AddFollowUpTemplate") {
        return this.viewParams.onboardingMode.value === "EditFollowUpTemplate"
          ? "AddFollowUpTemplate"
          : "EditFollowUpTemplate";
      }
    }

    if (stepNumber === OnboardingSteps.TechnicalIntegration)
      return "TechnicalIntegration";

    return "";
  }

  private async setViewModelByStep() {
    const { globalProps } = this.viewModel.customAuthService;

    const clientId = globalProps.clientId;
    let stepNumber = globalProps.currentStep;

    let incompleteItems = this.viewModel.clientOnboardingStepIntroductionLookup
      .filter((stepIntroduction) => stepIntroduction.onboardingStepIntroduction.onboardingStepId === stepNumber
        && !stepIntroduction.isComplete)
      .length

    this.viewModel.isIntroductionComplete = incompleteItems === 0;

    if (this.viewModel.isIntroductionComplete) {
      this.viewModel.isLetsStart = false;
    }

    switch (stepNumber) {
      case OnboardingSteps.IdealCustomerProfiles:
        this.viewModel.campaignMessageBaseViewVM.hasBeenSet = false;
        await this.viewModel.getCustomerProfilesCount();

        if (this.viewModel.customerProfilesCount > 0 && this.viewModel.canSeeICPListView) {
          this.viewModel.canSeeICPListView = false;
          this.viewModel.showICPListView = true;
        }
        else {
          this.viewModel.showICPQuestionnaireView = true;
        }

        if (this.viewModel.showICPListView) {
          this.viewModel.showICPQuestionnaireView = false;
        }
        else if (this.viewModel.showICPQuestionnaireView) {
          if (this.viewModel.showQuestionnaireViewAdd || this.viewModel.showQuestionnaireViewEdit) {
          }
          // Have to add it with these conditions, because it creates 2 instances and causes duplicate saves
          this.viewModel.customerProfileQuestionnaireViewModel = new CustomerProfileQuestionnaireVM();

          this.viewModel.customerProfileQuestionnaireViewModel.numberOfEmployees = this.viewModel.CustomerProfilesViewModel.numberOfEmployees
          this.viewModel.customerProfileQuestionnaireViewModel.productWorth = this.viewModel.CustomerProfilesViewModel.productWorth

          this.viewModel.customerProfileQuestionnaireViewModel.clientId = clientId;
          this.viewModel.customerProfileQuestionnaireViewModel.customerProfileId = this.viewModel.customerProfileId;
          this.viewModel.customerProfileQuestionnaireViewModel.isComXUser = false;
          this.viewModel.customerProfileQuestionnaireViewModel.taskRunner = this.viewModel.taskRunner;

          if (this.viewModel.customerProfileQuestionnaireViewModel.customerProfileId > 0) {
            this.viewModel.customerProfileQuestionnaireViewModel.isEdit = true;
          }
          else {
            this.viewModel.customerProfileQuestionnaireViewModel.isEdit = false;
          }

          await this.viewModel.customerProfileQuestionnaireViewModel.initialise();
          await this.viewModel.customerProfileQuestionnaireViewModel.fetchData();
        }

        if (!this.viewModel.showICPQuestionnaireView) {
          this.viewModel.CustomerProfilesViewModel.searchCriteria.clientId = globalProps.clientId;
          this.viewModel.CustomerProfilesViewModel.taskRunner = this.viewModel.taskRunner;
          this.viewModel.CustomerProfilesViewModel.isComXUser = false;
          globalProps.canGoNext = true; //If the user sees this view they can technically go next

          if (this.viewModel.CustomerProfilesViewModel.searchCriteria.clientId) {
            await this.viewModel.CustomerProfilesViewModel.pageManager.refreshData();
          }

          await this.viewModel.CustomerProfilesViewModel.initialise()
        }

        break;

      case OnboardingSteps.TargetMarkets:
        this.viewModel.campaignMessageBaseViewVM.hasBeenSet = false;
        this.viewModel.targetMarketViewModel = new TargetMarketVM();
        this.viewModel.targetMarketViewModel.clientId = globalProps.clientId;
        this.viewModel.targetMarketViewModel.taskRunner = this.viewModel.taskRunner;
        this.viewModel.targetMarketViewModel.showBackButton = false;

        await this.viewModel.targetMarketViewModel.fetchData(globalProps.clientId);

        let totalRecords = this.viewModel.targetMarketViewModel.pageManager.totalRecords > 0;

        // Required to set the view to be visible if there is an item in draft but not completed
        if (totalRecords && this.viewModel.canSeeTMListView) {
          this.viewModel.showTMListView = true;
          this.viewModel.canSeeTMListView = false;
        }
        else if (this.viewParams.onboardingMode.value === "TargetMarketAccounts") {
          this.viewModel.showTMAView = true;
        }
        else {
          this.viewModel.showAddEditTMView = true;
        }

        if (this.viewModel.showTMListView) {
          this.viewModel.showTMAView = false;
          this.viewModel.showAddEditTMView = false;
        }
        else if (this.viewModel.showAddEditTMView) {
          if (this.viewModel.targetMarketId <= 0) {
            this.viewModel.showTMAView = false;
            this.viewModel.addEditTargetMarketViewModel = new AddEditTargetMarketVM();
            this.viewModel.addEditTargetMarketViewModel.taskRunner = this.viewModel.taskRunner;
            this.viewModel.addEditTargetMarketViewModel.isComXUser = false;
            this.viewModel.addEditTargetMarketViewModel.targetMarketId = 0;
            this.viewModel.addEditTargetMarketViewModel.pageTitle = textConstants.titleText.SpecifyTargetMarket;
            this.viewModel.addEditTargetMarketViewModel.clientId = globalProps.clientId;
            await this.viewModel.addEditTargetMarketViewModel.initialise();
          }
          else {
            this.viewModel.addEditTargetMarketViewModel = new AddEditTargetMarketVM();
            this.viewModel.addEditTargetMarketViewModel.taskRunner = this.viewModel.taskRunner;
            this.viewModel.addEditTargetMarketViewModel.isComXUser = false;
            this.viewModel.addEditTargetMarketViewModel.targetMarketId = this.viewModel.targetMarketId;
            this.viewModel.addEditTargetMarketViewModel.isEdit = true;
            this.viewModel.addEditTargetMarketViewModel.pageTitle = textConstants.titleText.SpecifyTargetMarket;
            await this.viewModel.addEditTargetMarketViewModel.initialise();
          }
        }
        else if (this.viewModel.showTMAView) {
          this.viewModel.showAddEditTMView = false;

          this.viewModel.targetMarketAccountsViewModel = new TargetMarketAccountsVM();
          this.viewModel.targetMarketAccountsViewModel.taskRunner = this.viewModel.taskRunner;
          this.viewModel.targetMarketAccountsViewModel.clientId = globalProps.clientId;

          // Set values & fetch data;
          await this.viewModel.targetMarketAccountsViewModel.fetchData(this.viewModel.targetMarketId);
        }
        this.isTargetMarketVisible = true;
        break;

      case OnboardingSteps.BlackList:
        // Blacklist
        this.viewModel.clientSettingsViewModel.taskRunner = this.viewModel.taskRunner;
        this.viewModel.clientSettingsViewModel.clientId = clientId;

        // Set values & fetch data
        var clientName = await this.viewModel.dashboardViewModel.getClientName(clientId);
        this.viewModel.clientSettingsViewModel.clientName = clientName;
        await this.viewModel.clientSettingsViewModel.fetchClient(clientId);
        this.viewModel.campaignMessageBaseViewVM.hasBeenSet = false;
        this.viewModel.showBlacklistView = true;

        break;

      case OnboardingSteps.BookAMeeting:
        if (!this.viewModel.campaignMessageBaseViewVM.hasBeenSet) {
          this.viewModel.campaignMessageBaseViewVM = new OnboardingCampaignMessageBaseViewVM()
          this.viewModel.campaignMessageBaseViewVM.taskRunner = this.viewModel.taskRunner
          await this.viewModel.campaignMessageBaseViewVM.initialise()
          this.viewModel.campaignMessageBaseViewVM.hasBeenSet = true;
        }
        break;

      case OnboardingSteps.CampaignMessages:
        if (!this.viewModel.campaignMessageBaseViewVM.hasBeenSet) {
          this.viewModel.campaignMessageBaseViewVM = new OnboardingCampaignMessageBaseViewVM()
          this.viewModel.campaignMessageBaseViewVM.initialise()
          this.viewModel.campaignMessageBaseViewVM.hasBeenSet = true;
        }
        break;

      case OnboardingSteps.TechnicalIntegration:
        this.viewModel.onboardingTechnicalSettingsVM = new OnboardingTechnicalSettingsVM()
        this.viewModel.campaignMessageBaseViewVM.hasBeenSet = false;
        this.viewModel.onboardingTechnicalSettingsVM.taskRunner = this.taskRunner;
        this.viewModel.onboardingTechnicalSettingsVM.authenticationService = this.viewModel.customAuthService
        await this.viewModel.onboardingTechnicalSettingsVM.initialise()

        break;

      default:
        break;
    }

    return true;
  }

  private async setUpTechnicalDetails() {
    const { globalProps } = this.viewModel.customAuthService;

    if (!globalProps.hasSeenTechnicalIntegration) {
      if (!globalProps.isFirstOnboardingLogin && globalProps.clientId > 0) {
        this.isEmailSetup = true;
        await this.getViewModelEmailSetup();
      }
    }
  }

  private isIntroductionCompleted() {
    const { globalProps } = this.viewModel.customAuthService;

    let currentStepItems = this.viewModel.clientOnboardingStepIntroductionLookup
      .filter(stepIntroduction => stepIntroduction.onboardingStepIntroduction.onboardingStepId === globalProps.currentStep);

    let completedSteps = currentStepItems
      .filter(x => x.isComplete === true)

    return completedSteps.length === currentStepItems.length
  }

  private async getViewModelEmailSetup() {
    const { globalProps } = this.viewModel.customAuthService;

    this.viewModel.emailSetupViewModel = new EmailSetupVM();
    this.viewModel.emailSetupViewModel.clientId = globalProps.clientId;
    this.viewModel.emailSetupViewModel.taskRunner = this.viewModel.taskRunner;
    await this.viewModel.emailSetupViewModel.initialise();

    return true;
  }

  private emailSetupNavigation(navigationPath: string) {
    const { globalProps } = this.viewModel.customAuthService;

    if (navigationPath === textConstants.Onboarding.Navigation.ICP) {
      this.viewParams.onboardingMode.value = textConstants.Onboarding.Navigation.ICP;
      globalProps.currentStep = OnboardingSteps.IdealCustomerProfiles;
    }
    else if (navigationPath === textConstants.Onboarding.Navigation.TechnicalIntegration) {
      //Navigate appropriately
      globalProps.currentStep = OnboardingSteps.TechnicalIntegration;
      this.viewParams.onboardingMode.value = this.getViewNameByStep();
      let event2 = new Event("stepChangeDropDown", { bubbles: true, cancelable: false, });
      document.dispatchEvent(event2);
    }

    this.isEmailSetup = false;
    globalProps.hasSeenTechnicalIntegration = true;
    globalProps.isFirstOnboardingLogin = false;
    globalProps.showOnboardingSideMenu = true;
  }

  private goToTechnicalIntegration() {
    const { globalProps } = this.viewModel.customAuthService
    globalProps.showOnboardingSideMenu = true;
    globalProps.hasSeenCompleteYourSetup = true;
    globalProps.hasSeenTechnicalIntegration = true;
    globalProps.currentStep = OnboardingSteps.TechnicalIntegration
    this.viewModel.customAuthService.onboardingFunctions.getRelevantSectionCode();
    this.viewModel.customAuthService.onboardingFunctions.setHelpCenterSectionCode();
    this.isEmailSetup = false;
    this.viewParams.onboardingMode.value = "TechnicalIntegration";
  }

  public render() {
    const { globalProps } = this.viewModel.customAuthService;

    return (
      <div className="OnboardingView">

        {/* Welcome */}
        {globalProps.isFirstOnboardingLogin
          &&
          <WelcomeView
            openTechnicalIntegration={() => {
              globalProps.isFirstOnboardingLogin =
                false;

              if (globalProps.hasSeenTechnicalIntegration) {
                globalProps.hasSeenCompleteYourSetup = false;
                this.viewParams.onboardingMode.value = "";
              }
              else {
                this.isEmailSetup = true;
                this.viewParams.onboardingMode.value = `EmailSetup`;
                globalProps.hasSeenCompleteYourSetup = true;
              }
            }}
          />
        }

        {/* COMPLETE SETUP VIEW */}
        {!globalProps.isFirstOnboardingLogin
          && globalProps.hasSeenTechnicalIntegration
          && !globalProps.hasSeenCompleteYourSetup
          &&
          <CompleteYourSetupView
            viewModel={this.viewModel}
            globalProps={globalProps}
            goToCurrentStep={() => {
              // Necessary to re-render components
              globalProps.currentStep = this.viewModel.getCurrentStep();
              this.viewModel.customAuthService.onboardingFunctions.getRelevantSectionCode();
              this.viewModel.customAuthService.onboardingFunctions.setHelpCenterSectionCode();
              globalProps.hasSeenCompleteYourSetup = true;
              globalProps.showOnboardingSideMenu = true;
              this.viewParams.onboardingMode.value = this.getViewNameByStep();
            }}
          />
        }

        {/* EMAIL SETUP VIEW */}
        {!globalProps.hasSeenTechnicalIntegration
          && this.isEmailSetup
          &&
          <EmailSetupView
            viewModel={this.viewModel.emailSetupViewModel}
            navigate={(navigationPath) => {
              globalProps.hasSeenCompleteYourSetup = true;
              this.emailSetupNavigation(navigationPath);
            }}
            navigateToTechnicalIntegration={() => {
              this.goToTechnicalIntegration()
            }}
          />
        }

        {/* ALL STEPS AFTER "COMPLETE SETUP" VIEW */}
        {globalProps.hasSeenCompleteYourSetup
          &&
          // Container Div
          <>
            {/* ONBOARDING INTRODUCTION */}
            {((!this.isIntroductionCompleted() || this.viewModel.isLetsStart))
              && this.viewModel.clientOnboardingStepIntroductionLookup !== null
              && !this.isCampaignMessage() &&
              <OnboardingIntroduction
                viewModel={this.viewModel}
                setCompleted={stepIntroductionId => {
                  this.viewModel.isLetsStart = true;
                  this.viewModel.updateClientOnboardingStepIntroduction(stepIntroductionId).then(() => this.forceUpdate())
                }}
                isEmailSetup={globalProps.currentStep === OnboardingSteps.TechnicalIntegration}
              />
            }
            {this.isIntroductionCompleted() && (!this.viewModel.isLetsStart ||
              this.isCampaignMessage()) &&
              <>
                {/* Ideal Customer Profile (ICP) - LIST VIEW*/}
                {globalProps.currentStep === OnboardingSteps.IdealCustomerProfiles
                  && this.viewModel.showICPListView
                  && !this.viewModel.showICPQuestionnaireView
                  &&
                  <div className="appFooterRestrictions">
                    <CustomerProfilesView
                      viewModel={this.viewModel.CustomerProfilesViewModel}
                      openCustomerProfileQuestionnaire={(customerProfileId?: number) => {
                        this.viewModel.showICPListView = false;

                        if (customerProfileId) {
                          this.viewModel.customerProfileId = customerProfileId;
                          this.viewModel.showQuestionnaireViewEdit = true;
                        }
                        else {
                          this.viewModel.customerProfileId = 0;
                          this.viewModel.showICPQuestionnaireView = true;
                          this.viewModel.showQuestionnaireViewAdd = true;
                        }

                        this.viewModel.customAuthService.onboardingFunctions.getRelevantSectionCode();
                        this.viewParams.onboardingMode.value = this.getViewNameByStep();
                      }}
                      back={() => {
                        this.viewModel.customerProfileId = 0;
                        this.viewModel.showQuestionnaireViewEdit = false;
                        this.viewModel.customAuthService.globalProps.isZendeskVisible = false;
                      }}
                      isOnboarding={true}
                    />
                  </div>
                }

                {/* ICP QUESTIONNAIRE VIEW */}
                {globalProps.currentStep === OnboardingSteps.IdealCustomerProfiles
                  && this.viewModel.showICPQuestionnaireView
                  && this.viewModel.isIntroductionComplete
                  &&
                  <CustomerProfileQuestionnaireView
                    viewModel={this.viewModel.customerProfileQuestionnaireViewModel}
                    disableBack={(this.viewModel.customerProfilesCount === 0 && this.viewModel.customerProfileQuestionnaireViewModel.customerProfileId === 0)}
                    back={(isPublish) => {
                      this.viewModel.customerProfileId = 0
                      this.viewModel.showICPQuestionnaireView = false
                      this.viewModel.showQuestionnaireViewEdit = false
                      this.viewModel.showICPListView = true;
                      this.viewParams.onboardingMode.value = this.getViewNameByStep()
                      globalProps.canGoNext = true
                    }}
                    downloadProfile={() => this.viewModel.CustomerProfilesViewModel.downloadICP(
                      this.viewModel.customerProfileQuestionnaireViewModel.customerProfileId,
                      this.viewModel.customerProfileQuestionnaireViewModel.profileName
                    )}
                  />
                }

                {/* TARGET MARKETS - LIST VIEW*/}
                {globalProps.currentStep === OnboardingSteps.TargetMarkets
                  && this.viewModel.showTMListView
                  && this.viewModel.isIntroductionComplete
                  &&
                  <TargetMarketView
                    viewModel={this.viewModel.targetMarketViewModel}
                    isOnboarding={true}
                    back={() => { }}
                    openAddTargetMarket={() => {
                      this.isTargetMarketVisible = false;
                      this.viewModel.targetMarketId = 0;
                      this.viewModel.showTMListView = false;
                      this.viewModel.showTMAView = false;
                      this.viewModel.showAddEditTMView = true;
                      this.viewParams.onboardingMode.value = "AddTargetMarket";
                    }}
                    openEditTargetMarket={(targetMarketId: number, targetMarketName: string) => {
                      this.isTargetMarketVisible = false;
                      this.viewModel.targetMarketId = targetMarketId;
                      this.viewModel.showTMListView = false;
                      this.viewModel.showAddEditTMView = true;
                      this.viewModel.showTMAView = false;
                      this.viewParams.onboardingMode.value = "EditTargetMarket";
                    }}
                    openProspectTargetMarket={() => { }}
                    openTargetMarketAccounts={(targetMarketId: number) => {
                      this.isTargetMarketVisible = false;
                      this.viewModel.showTMAView = true;
                      this.viewModel.showAddEditTMView = false;
                      this.viewModel.showTMListView = false;
                      this.viewModel.targetMarketId = targetMarketId;
                      this.viewParams.onboardingMode.value = "TargetMarketAccounts";
                    }}
                    openUpdateTargetMarket={() => { }}
                  />
                }

                {/* TARGET MARKETS - ADD/EDIT VIEW*/}
                {globalProps.currentStep === OnboardingSteps.TargetMarkets
                  && this.viewModel.showAddEditTMView
                  && this.viewModel.isIntroductionComplete
                  && this.isTargetMarketVisible &&
                  <>
                    <AddEditTargetMarketView
                      viewModel={this.viewModel.addEditTargetMarketViewModel}
                      clientName=""
                      back={() => {
                        this.viewModel.canSeeTMListView = true
                        this.viewModel.showTMListView = true;
                        this.viewModel.showTMAView = false;
                        this.viewModel.showAddEditTMView = false;
                        this.viewModel.targetMarketId = 0;
                        this.viewModel.targetMarketViewModel.pageManagerRefresh();
                        this.viewParams.onboardingMode.value = "TargetMarkets";
                      }}
                    />
                  </>
                }

                {/* TARGET MARKETS - ACCOUNTS VIEW */}
                {globalProps.currentStep === OnboardingSteps.TargetMarkets
                  && this.viewModel.showTMAView
                  &&
                  <TargetMarketAccountsView
                    viewModel={this.viewModel.targetMarketAccountsViewModel}
                    back={() => {
                      this.viewModel.targetMarketId = -1;
                      this.viewModel.showTMListView = true;
                      this.viewModel.showTMAView = false;
                      this.viewModel.showAddEditTMView = false;
                      this.viewParams.onboardingMode.value = "TargetMarkets";
                    }}
                  />
                }

                {/* Blacklist */}
                {globalProps.currentStep === OnboardingSteps.BlackList
                  && this.viewModel.showBlacklistView
                  &&
                  <div className="marginTop24 appFooterRestrictions">
                    <BlacklistedDomain viewModel={this.viewModel.clientSettingsViewModel} />
                  </div>
                }

                {/* CAMPAIGN MESSAGES && BOOK A MEETING */}
                {this.isCampaignMessage()
                  &&
                  <>
                    <OnboardingCampaignMessageBaseView viewModel={this.viewModel.campaignMessageBaseViewVM} />
                  </>
                }

                {/* Technical Integration Settings */}
                {globalProps.currentStep === OnboardingSteps.TechnicalIntegration
                  &&
                  <OnboardingTechnicalSettings viewModel={this.viewModel.onboardingTechnicalSettingsVM} />
                }

                {/* Invalid Data Modal */}
                <Neo.Modal
                  title={textConstants.titleText.InvalidData}
                  show={this.viewModel.showInvalidDataModal}
                  onClose={() => (this.viewModel.showInvalidDataModal = false)}
                  closeButton={{ text: "Close", variant: "light" }}
                >
                  {this.viewModel.invalidDataMessage.split("\n")
                    .map((item, i) => {
                      return <p key={i}>{item}</p>;
                    })
                  }
                </Neo.Modal>
              </>
            }
          </>
        }

        {/* INTRODUCTION STEPS FOOTER (FROM INTRODUCTION FOR STEP TO ACTUAL STEP) */}
        {this.viewModel.canGoToQuestionnaire
          && !this.viewModel.isIntroductionComplete
          && this.viewParams.onboardingMode.value !== targetMarketAccountRoute.path &&
          <div className={`row appFooter`}>
            <OnboardingFooter
              letsStartDisable={this.viewModel.saveButtonDisabled}
              letsStartButton={() => this.viewModel.closeOnboardingIntroduction()}
              currentStep={this.viewModel.customAuthService.globalProps.currentStep} />
          </div>
        }

        {/* ONBOARDING FOOTER  (TAKES USER TO NEXT STEP IN ONBOARDING)*/}
        {this.canGoToNextStep() && this.canGoToNextStepByCurrentStep() &&
          <div className={`row appFooter`}>
            <OnboardingNextStepFooter
              title={this.getText()}
              onClick={() => {
                this.viewModel.navigateToNextStep();
              }} />
          </div>
        }
      </div>
    );
  }
}
