import { Attributes, ModelBase, NeoModel, Rules, } from '@singularsystems/neo-core';

@NeoModel
export default class GreyListedProspectModel extends ModelBase {

    // Properties
    public greylistedProspectId: number = 0;

    public clientId: number = 0    

    @Rules.StringLength(500)
    @Attributes.Display("Email")
    public emailAddress: string = "";

    public toString(): string {
        if (this.isNew || !this.emailAddress) {
            return "New email";
        } else {
            return this.emailAddress;
        }
    }
}