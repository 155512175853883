import { ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { textConstants } from '../../../common/textConstants';

@NeoModel
export default class CreateClient extends ModelBase {
   
   @Rules.StringLength(500)
   public clientName: string = "";

   public isClientReview: boolean = false;

   public platformSetup: string = ""

   public successTracking: string = ""

   // Client Rules
   public addBusinessRules(rules: Validation.Rules<this>) {
      super.addBusinessRules(rules);
      rules.failWhen(c => c.clientName === null || c.clientName.trim() === '', textConstants.messageText.validationMessage.clientName);
   }
}