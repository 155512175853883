import React from 'react';
import { observer } from 'mobx-react';

interface IOverlayProps {
    clasName?: string
    text?: string
}

@observer
class Loading extends React.Component<IOverlayProps> {
    constructor(props: IOverlayProps) {
        super(props);
    }
    render() {
        const { text } = this.props
        return (
        <div className="content"><div className="spin"></div><span>{text}</span></div>
        );
    }
}

export default Loading;
