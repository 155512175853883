import { ModelBase, NeoModel } from "@singularsystems/neo-core";

@NeoModel
export default class OnboardingSideMenuLookup extends ModelBase {

    public onboardingSideMenuId : Number = 0;

    public screenName: string = "";

    public headerText: string = "";

    public lessonText: string = "";

    public transcriptText: string = "";

    public videoURL: URL | string = "";

}