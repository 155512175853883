import Axios, { AxiosPromise } from "axios";
import { injectable } from 'inversify';
import { ResponseResolver } from "../common/utils";
import { AppService, Types } from "../Services/AppService";

export interface IHealthCheckApiClient {
    SendTimeoutNotification(requestObject: any): AxiosPromise<any>,
}

@injectable()
export default class HealthCheckApiClient implements IHealthCheckApiClient {

    constructor(public cnfig = AppService.get(Types.Config)) {
    }

    public SendTimeoutNotification(requestObject: any): AxiosPromise<any> 
    {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.post(`${apiPath}/HealthCheck/TimeoutNotification`, requestObject));
    }
}