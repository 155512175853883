import { PageManager } from '@singularsystems/neo-core/dist/Data';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import React from 'react';
import PageSizeSelector from './Pager/PageSizeSelector';

interface ICustomPagingControlProps {
    pageManager: PageManager<any, any>,
    displayLabel: boolean
    showPageSizePicker?: boolean
    isDashboard?: boolean
}

@observer
class CustomPagingControl extends React.Component<ICustomPagingControlProps>
{
    constructor(props: ICustomPagingControlProps) {
        super(props);
        this.calculateToValue = this.calculateToValue.bind(this);
    }

    calculateToValue() {
        let currentToValue = this.props.pageManager.pageNo * this.props.pageManager.pageSize;
        return currentToValue > this.props.pageManager.totalRecords ? this.props.pageManager.totalRecords : currentToValue;
    }

    setStylingWhenAllItemsAreOnPage() {
        if (this.props.showPageSizePicker && this.props.pageManager.pageSize >= this.props.pageManager.totalRecords) {
            return "customPager2"
        } else {
            return ""
        }
    }

    public render() {
        return (
            <div className={`row ml-0 mt-1 w-100 justify-content-end customPager ${this.setStylingWhenAllItemsAreOnPage()}`}>
                {this.props.showPageSizePicker &&
                    <>
                        <PageSizeSelector
                            pageSize={this.props.pageManager.pageSize}
                            setPageSize={
                                (pageSize) => this.props.pageManager.pageSize = Number(pageSize)}
                            isDashboard={this.props.isDashboard}
                        />

                        <div className="ml-3 mt-1 mb-2 mr-1 separator"></div>
                    </>
                }

                <div className="w-20 mt-1 px-2 pt-2 pb-2">
                    {this.props.displayLabel &&
                        this.props.pageManager.totalRecords > 0 &&
                        <div className="text-left">
                            {(this.props.pageManager.pageNo - 1) * this.props.pageManager.pageSize + 1} - {this.calculateToValue()} of {this.props.pageManager.totalRecords}
                        </div>
                    }
                </div>

                <div className="w-20 mt-1">
                    <Neo.PagerControlsBasic pageManager={this.props.pageManager} noOfButtons={4} alignment='end' />
                </div>
            </div>
        )
    }
}

export default CustomPagingControl;