import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import TargetMarketSummary from '../../Models/TargetMarketAccounts/TargetMarketSummary';
import Country from './../../Models/Maintenance/Country';
import { textConstants } from '../../common/textConstants';
import { base64toBlob, getCurrentDateTime } from '../../common/utils';

@NeoModel
export default class AddClientListVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        private targetMarketAccountsApiClient = AppService.get(Types.ApiClients.TargetMarketAccountsApiClient)) {

        super(taskRunner);
    }

    public targetMarketSummary: TargetMarketSummary = new TargetMarketSummary();

    public selectedCountry: Country = new Country();

    public columnHeadings: string[] = ["- Fixed Name", "- Original Name - ", "Location - ", "Domain"];

    public targetMarketId: number = 0;

    public async initialise() {
    }
    public countries = this.appDataCache.countries.get().data.sortBy("countryName")
    
    public async fetchData(targetMarketId: number){
        if(targetMarketId > 0){
            const response = await this.taskRunner.waitFor(this.targetMarketAccountsApiClient.getTargetMarketSummary(targetMarketId));
            this.targetMarketSummary.set(response.data);
            this.targetMarketId = targetMarketId;
            this.selectedCountry.countryId = this.targetMarketSummary.countryId
        }
        else{
            this.notifications.addWarning("Warning", "TargetMarketId is not set. Please refresh page.");
        }
    }

    public downloadTemplate() {
        this.taskRunner.run(async () => {
            const response = await this.targetMarketAccountsApiClient.getTemplate();

            const url = window.URL.createObjectURL(base64toBlob(response.data.fileContents, response.data.contentType));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Template_' + getCurrentDateTime() + '.csv');
            document.body.appendChild(link);
            link.click();
        })
    }

    public documentUpload(fileList: File[] | FileList, targetMarketId: number) {

        if (this.selectedCountry.countryId !== undefined && this.selectedCountry.countryId !== null && this.selectedCountry.countryId !== 0){
            this.taskRunner.run(async ()=>{
                const response = await  this.targetMarketAccountsApiClient.uploadDocument(fileList,this.selectedCountry.countryId, targetMarketId);
                if (response.data.success)
                {
                    this.notifications.addSuccess(textConstants.titleText.AddClientList,textConstants.messageText.saveMessage.WhitelistUpload);
                }
                else {
                    this.notifications.addDanger(textConstants.titleText.Error, response.data.message);
                }
           });
        }
        else {
            this.notifications.addDanger(textConstants.titleText.Error, "Please select a country.");
        }
     }
}