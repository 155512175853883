import { NeoModel, Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../../Services/AppService';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';
import { reaction } from 'mobx';
import ClientCriteria from '../../Models/Client/Query/ClientCriteria';
import ClientLookup from './../../Models/Client/ClientLookup';
import CreateClient from './../../Models/Client/Commands/CreateClient';
import CommandResult from './../../Models/InvitedUsers/Commands/CommandResult';
import EditClient from './../../Models/Client/Commands/EditClient';
import DeleteClient from './../../Models/Client/Commands/DeleteClient';
import TargetMarketVM from './../TargetMarket/TargetMarketVM';
import UpdateTargetMarketsVM from '../TargetMarket/UpdateTargetMarketsVM';
import AddEditTargetMarketVM from '../TargetMarket/AddEditTargetMarketVM';
import TargetMarketAccountsVM from '../TargetMarketAccounts/TargetMarketAccountsVM';
import ProspectingVM from '../Prospecting/ProspectingVM';
import AddClientListVM from '../TargetMarketAccounts/AddClientListVM';
import ClientSettingsVM from './ClientSettingsVM';
import DashboardVM from '../Home/DashboardVM';
import UndoDeleteClientCommand from '../../Models/Client/Commands/UndoDeleteClient';
import AddClientVM from './AddClientVM';
import ClientCampaignMessageVM from '../CampaignMessages/ClientCampaignMessageVM';
import CampaignEmailSignatureVM from '../CampaignMessages/CampaignEmailSignatureVM';
import CampaignMessageVM from '../CampaignMessages/CampaignMessageVM';
import BatchReviewProspectsVM from '../BatchReview/BatchReviewProspectsVM';
import CustomerProfilesVM from '../IdealCustomerProfiles/CustomerProfilesVM';
import CustomerProfileQuestionnaireVM from '../IdealCustomerProfiles/CustomerProfileQuestionnaireVM';
import { Navigation } from '../../Models/Enums/ApplicationEnums';
import UploadNotificationLookup from '../../Models/UploadNotificationLookup';
import { base64toBlob } from '../../common/utils';
import AddEditFollowUpTemplateVM from '../CampaignMessages/AddEditFollowUpTemplateVM';
import SearchFieldVM from '../../Components/SearchFieldVM';
import ActionListVM from 'Views/ActionList/ActionListVM';

@NeoModel
export default class ClientVM extends Views.ViewModelBase {
    [x: string]: any;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
        private authenticationService = AppService.get(Types.Neo.Security.AuthenticationService),
        private comxHub = AppService.get(Types.ApiClients.ComXHub),
        private customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
        private invitedUserApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient)) {

        super(taskRunner);
        this.connectToComXHub();
    }

    public clientCriteria = new ClientCriteria();

    // ViewParams
    public clientId: number | null = null;
    public targetMarketId: number | null = null;
    public targetMarketName: string = "";
    public campaignMessageName: string = ""
    public followUpTemplateName: string = ""
    public campaignMessageType: string = ""
    public clientCampaignMessageId: number = 0
    public clientFollowUpTemplateId: number = 0
    public clientCampaignMessageStatusId: number = 0
    public clientCampaignId: number = 0
    public currentICPMode: string = ""

    // ViewModels
    public TargetMarketViewViewModel = new TargetMarketVM();
    public UpdateTargetMarketsViewModel = new UpdateTargetMarketsVM();
    public AddEditTargetMarketViewModel = new AddEditTargetMarketVM();
    public TargetMarketAccountsViewModel = new TargetMarketAccountsVM();
    public ProspectingViewModel = new ProspectingVM();
    public AddClientListViewModel = new AddClientListVM();
    public ClientSettingsViewModel = new ClientSettingsVM();
    public AddClientViewModel = new AddClientVM();
    public DashboardViewModel = new DashboardVM();
    public ClientCampaignMessageViewModel = new ClientCampaignMessageVM();
    public CampaignEmailSignatureViewModel = new CampaignEmailSignatureVM();
    public CampaignMessageViewModel = new CampaignMessageVM();
    public BatchReviewProspectsViewModel = new BatchReviewProspectsVM();
    public CustomerProfilesViewModel = new CustomerProfilesVM();
    public CustomerProfileQuestionnaireViewModel = new CustomerProfileQuestionnaireVM();
    public AddFollowUpTemplateViewModel = new AddEditFollowUpTemplateVM();
    public searchFieldVM = new SearchFieldVM();
    public ActionListViewModel = new ActionListVM();
    public ActionListSelectedTab: string = textConstants.buttonText.HotLeads
    public ActionListCurrentSelectedTab: string = ""

    // Properties
    public clearAddEditNotifications: boolean = true;
    public previousClientCampaignMessageGridPage: number = 1;

    // public showNewClientModal: boolean = false;
    public showEditClientModal: boolean = false;
    public showDeleteModal: boolean = false;
    public selectedClientName: string = "";
    public clientDisplayName: string = "";
    public isArchived: boolean = false;
    public showUndoDeleteModal: boolean = false;
    public hideGrid = false;
    public batchReviewUploadId: number = 0;
    public showBatchReviewProspects = false;
    public showBatchReviewTab = false;
    public shouldInitializeActionList = true;

    // Navigation path list
    public navigationList = ["ClientView"];
    public showICPQuestionnaire = false;
    public ICPMode: string = "";
    public isComingFromFollowUpTemplates: boolean = false

    // Commands
    public createClientCommand = new CreateClient();
    public editClientCommand = new EditClient();
    public deleteClientCommand = new DeleteClient();
    public undoDeleteClientCommand = new UndoDeleteClientCommand();

    // Model property
    public showForbiddenModal: boolean = false;

    public showWarningMessage: boolean = false
    // Pagination
    public pageManager = new Data.PageManager(this.clientCriteria, ClientLookup,
        this.clientsApiClient.getClientsPaged,
        { pageSize: 10, sortBy: "clientName", fetchInitial: true });

    public openEditClientModal(client: ClientLookup) {
        this.editClientCommand = new EditClient();
        this.editClientCommand.clientId = client.clientId;
        this.editClientCommand.clientName = client.clientName;
        this.showEditClientModal = true;
    }

    public async initialise() {
        reaction(() => this.clientCriteria.isDeleted, () => this.refreshPageManager(), { fireImmediately: false, delay: 650 })
    }

    public openDeleteModal(client: ClientLookup) {
        this.deleteClientCommand = new DeleteClient();
        this.deleteClientCommand.ClientId = client.clientId;
        // Show the modal
        this.showDeleteModal = true;
        this.selectedClientName = client.clientName;
    }

    public async checkUserNotifications() {
        await this.taskRunner.waitFor(this.invitedUserApiClient.checkNotifications());
    }

    public deleteClient() {
        this.showDeleteModal = false;
        this.taskRunner.run(async () => {
            const response = await this.clientsApiClient.deleteClient(this.deleteClientCommand);
            const cr: CommandResult = response.data as CommandResult;
            if (cr.success) {
                this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.ClientSaved);
                this.pageManager.refreshData();
            }
        });
    }

    public editClient() {
        this.showEditClientModal = false;
        this.taskRunner.run(async () => {
            const response = await this.clientsApiClient.editClient(this.editClientCommand);
            const cr: CommandResult = response.data as CommandResult;
            if (cr.success) {
                this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.ClientSaved);
                this.pageManager.refreshData();
            }
        })
    }

    public async getClientName(clientId: number) {
        const response = await this.taskRunner.waitFor(this.clientsApiClient.getClient(clientId));
        return response.data.clientName;
    }

    public async setClientDisplayName(clientName: string) {
        this.clientDisplayName = clientName;
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }

    public async openUndoDeleteClientModal(client: ClientLookup) {
        this.undoDeleteClientCommand = new UndoDeleteClientCommand()
        this.undoDeleteClientCommand.clientId = client.clientId
        this.showUndoDeleteModal = true
        this.selectedClientName = client.clientName
    }

    public async undoDeleteClient() {

        this.showUndoDeleteModal = false;

        const response = await this.clientsApiClient.undoDeleteClient(this.undoDeleteClientCommand.clientId);
        const result = response.data

        if (result) {
            //  Refresh
            this.clientCriteria.isDeleted = true;
            this.refreshPageManager()
        }
        else {
            this.notifications.addDanger(textConstants.titleText.SaveFailed, 'Failed to undo Client delete.');
        }
    }

    public refreshPageManager() {
        this.hideGrid = true;
        this.pageManager.refreshData(undefined, this.taskRunner).then(() => {
            this.hideGrid = false
        })
    }

    //Returns the string value viewParams.clientMode needs to setup and display the view
    public navigate(goToThisView: string): string | null {

        if (goToThisView === Navigation.goToCampaignMessages) {
            this.navigationList.push(textConstants.titleText.ClientCampaignMessagesValue);
            return textConstants.titleText.ClientCampaignMessagesValue;

        } else if (goToThisView === Navigation.goToTargetMarkets) {
            this.navigationList.push("TargetMarkets");
            return "TargetMarkets";

        } else if (goToThisView === Navigation.goToClientSettings) {
            this.navigationList.push("EditClient");
            return "EditClient";

        } else if (goToThisView === Navigation.goToCustomerProfiles) {
            this.navigationList.push(textConstants.titleText.IdealCustomerProfile);
            return textConstants.titleText.IdealCustomerProfile;
        }
        else if (goToThisView === Navigation.goToClientReports) {
            this.navigationList.push(textConstants.titleText.ClientReportView);
            return textConstants.titleText.ClientReportView;
        }

        else {
            // If we are not moving forward go back to previous component
            this.navigationList.pop();
            // Set to the most recent view in the list
            let value = this.navigationList[this.navigationList.length - 1];

            if (value === "ClientView") {
                return null;
            } else {
                return value;
            }
        }
    }

    public connectToComXHub() {
        this.comxHub.NotifyUploadUser.subscribe(this.NotifyUploadUser);
    }

    public NotifyUploadUser = (uploadNoticationLookup: UploadNotificationLookup) => {
        let user = this.authenticationService.user;

        if (user!.userName === uploadNoticationLookup.username) {
            if (uploadNoticationLookup.isSuccess && !uploadNoticationLookup.hasUnsuccessfulDomains) {
                setTimeout(() => { this.notifications.addSuccess(uploadNoticationLookup.messageHeader, uploadNoticationLookup.message) }, 2000)
            }
            else {
                setTimeout(() => {
                    if (uploadNoticationLookup.errorFile !== null) {
                        const url = window.URL.createObjectURL(base64toBlob(uploadNoticationLookup.errorFile, "text/csv"));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', uploadNoticationLookup.errorFileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                    if (uploadNoticationLookup.hasUnsuccessfulDomains) {
                        this.notifications.addSuccess(uploadNoticationLookup.messageHeader, uploadNoticationLookup.message);
                    } else {
                        this.notifications.addDanger(uploadNoticationLookup.messageHeader, uploadNoticationLookup.message);
                    }
                }, 2000)
            }
        }
    }
}