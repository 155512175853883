import {ModelBase, NeoModel  } from "@singularsystems/neo-core";

@NeoModel
export default class NavigationStep extends ModelBase
{
    public navigationStepId: number = 0;
    public systemArea: string = "";
    public stepNumber: number = 0;
    public currentStep: string = "";
    public nextStep: string = "";
    public previousStep: string = "";
    public navigationHeaderText: string = "";
    public isInternalOnly: boolean = false
    public isRequired: boolean = false
}