import { injectable } from 'inversify';
import { Data, Model, Utils, List } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import Axios, { AxiosPromise } from 'axios';
import CommandResult from '../Models/InvitedUsers/Commands/CommandResult';
import DeleteBlacklistedDomain from '../Models/BlacklistedDomains/Commands/DeleteBlacklistedDomain';
import BlacklistedDomainCriteria from '../Models/BlacklistedDomains/Query/BlacklistedDomainCriteria';
import BlacklistedDomain from '../Models/BlacklistedDomains/BlacklistedDomain';
import { ResponseResolver } from '../common/utils';

export interface IBlacklistedDomainsApiClient {
   getBlacklistedDomainsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BlacklistedDomainCriteria>>) : AxiosPromise<any>,
   getTemplate(): AxiosPromise<any>,
   getDomainRegex(): AxiosPromise<string>,
   uploadDocument(filelist: File[] | FileList, clientId: number): AxiosPromise<any>,
   deleteBlacklistedDomain(blacklistedDomain: DeleteBlacklistedDomain) : AxiosPromise<Model.PlainObject<CommandResult>>
   downloadBlacklistedDomains(clientId: number) : AxiosPromise<any>;   
   saveBlackListDomains(blacklistedDomain: BlacklistedDomain[], clientId: number) : AxiosPromise<any>;
   bulkDelete(blacklistedDomain: DeleteBlacklistedDomain): AxiosPromise<{ success: boolean; error: string; id: number }>;
}

@injectable()
export default class BlacklistedDomainsApiClient implements IBlacklistedDomainsApiClient {

    constructor(public cnfig = AppService.get(Types.Config)) {
    }

    public getBlacklistedDomainsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BlacklistedDomainCriteria>>) : AxiosPromise<any>{
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/BlacklistedDomain/GetBlacklistedDomains?${Utils.getQueryString(request)}`);
    }

    public deleteBlacklistedDomain(blacklistedDomain: DeleteBlacklistedDomain) : AxiosPromise<{ success: boolean; error: string; id: number; }> {
        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.cnfig.apiPath}/BlacklistedDomain/DeleteBlacklistedDomain`, blacklistedDomain.toJSObject()));
    }

    public getTemplate(): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get(`${apiPath}/BlacklistedDomain/DownloadTemplate`));
    }

    public uploadDocument(filelist: File[] | FileList, clientId: number) {
        const apiPath = AppService.get(Types.Config).apiPath;
        
        const bodyFormData = new FormData();
        bodyFormData.set('files', filelist[0]);
        bodyFormData.set('clientId', clientId.toString());

        return Axios.post(`${apiPath}/BlacklistedDomain/UploadDocument`, bodyFormData);
    }

    public downloadBlacklistedDomains(clientId: number): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get(`${apiPath}/BlacklistedDomain/DownloadBlacklistedDomains?clientId=${clientId}`));
    }

    public saveBlackListDomains(blacklistedDomain: List<BlacklistedDomain>, clientId: number){
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.post(`${apiPath}/BlacklistedDomain/SaveBlackListDomains?clientId=${clientId}`,blacklistedDomain.toJSArray());
    }

    public getDomainRegex(): AxiosPromise<string> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<string>(Axios.get(`${apiPath}/BlacklistedDomain/GetDomainRegex`));
    }

    public bulkDelete(blacklistedDomain: DeleteBlacklistedDomain): AxiosPromise<{ success: boolean; error: string; id: number }> {
        return Axios.post(`${this.cnfig.apiPath}/BlacklistedDomain/BulkDelete`, blacklistedDomain.toJSObject());
    }
}