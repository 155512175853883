import {
  Attributes,
  ModelBase,
  NeoModel,
} from '@singularsystems/neo-core';

@NeoModel
export default class PlatformSearchLookup extends ModelBase {
  public platformSearchId: number = 0;

  @Attributes.Display("Search")
  public searchFilename: string = "";

  @Attributes.Display("Date Created")
  public createdDate: string = "";

  @Attributes.Display("Created By")
  public createdBy: string = "";

  public totalAccounts: number = 0;

  public totalResults: number = 0;

  public completedAccountsPercentage: number = 0;

  public totalRequests: number = 0;

  public requestsPerResult: number | string = 0;

  public requestsPerMinute: number | string = 0;
}