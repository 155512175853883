import React from "react";
import ActionListVM from "./ActionListVM";
import * as Icon from 'react-feather'
import ActionListTab from "Components/ActionListTab";
import { textConstants } from "common/textConstants";
import { Neo } from "@singularsystems/neo-react";
import ActionListTable from "Components/ActionListComponents/ActionListTable";
import LeadMessageView from "./LeadMessageView";
import { observer } from "mobx-react";
import SearchField from "Components/SearchField";
import InfoBox from "Components/InfoBox";
import DataTooltip from "Components/DataTooltip";
import ActionList_NoLeads from "assets/svgs/ActionList_NoLeads";
import { isMobile } from "common/utils";

interface IActionListReports {
  viewModel: ActionListVM,
  backToActionList: () => void,
  openLeadsView: (prospectId: number, campaignId: number, replyId: string) => void,
  showPreviousMessage: () => void,
  showNextMessage: () => void,
  changeLeadType: (leadType: string) => void,
  archiveCurrentLead: () => void,
  back?: () => void,
}

@observer
class ActionListScreen extends React.Component<IActionListReports> {

  componentWillUnmount(): void {
    this.props.viewModel.resetFilters();
  }

  render() {
    const { viewModel } = this.props

    return (
      <>
        {isMobile() &&
          <Neo.Card>
            <InfoBox header={textConstants.titleText.FeatureNotOptimized}
              text={textConstants.generalText.FeatureNotOptimized}
            />
          </Neo.Card>
        }

        {/* TAB BUTTONS */}
        {viewModel.showActionListView && !isMobile() &&
          <>
            {!viewModel.customAuthService.globalProps.isClientUser &&
              <Neo.Button
                className="btn-width-100 mt-3 mb-0 ml-4"
                variant="light"
                icon={<Icon.ArrowLeft />}
                onClick={() => {
                  this.props.back && this.props.back()
                }}
              >
                {textConstants.buttonText.Back}
              </Neo.Button>
            }
            <div className="actionListView m-4">
              <div className="row actionListTabs">
                <ActionListTab
                  icon={<Icon.Zap />}
                  tabName={textConstants.buttonText.HotLeads}
                  unseenCount={viewModel.actionListSummaryLookup.newHotTotal}
                  total={viewModel.actionListSummaryLookup.hotTotal}
                  isSelected={
                    viewModel.selectedTab === textConstants.buttonText.HotLeads
                  }
                  onClick={() => {
                    viewModel.toggleTab(textConstants.buttonText.HotLeads)
                    viewModel.resetBulkControlProperties()
                  }}
                />

                <ActionListTab
                  icon={<Icon.Thermometer />}
                  tabName={textConstants.buttonText.WarmLeads}
                  unseenCount={viewModel.actionListSummaryLookup.newWarmTotal}
                  total={viewModel.actionListSummaryLookup.warmTotal}
                  isSelected={
                    viewModel.selectedTab === textConstants.buttonText.WarmLeads
                  }
                  onClick={() => {
                    viewModel.toggleTab(textConstants.buttonText.WarmLeads)
                    viewModel.resetBulkControlProperties()
                  }}
                />

                <ActionListTab
                  icon={<Icon.Wind />}
                  tabName={textConstants.buttonText.ColdLeads}
                  unseenCount={viewModel.actionListSummaryLookup.newColdTotal}
                  total={viewModel.actionListSummaryLookup.coldTotal}
                  isSelected={
                    viewModel.selectedTab === textConstants.buttonText.ColdLeads
                  }
                  onClick={() => {
                    viewModel.toggleTab(textConstants.buttonText.ColdLeads)
                    viewModel.resetBulkControlProperties()
                  }}
                />

                <ActionListTab
                  icon={<Icon.Archive />}
                  isSelected={
                    viewModel.selectedTab === textConstants.buttonText.Archive
                  }
                  onClick={() => {
                    viewModel.toggleTab(textConstants.buttonText.Archive)
                    viewModel.resetBulkControlProperties()
                  }}
                  tabName=""
                  unseenCount={0}
                  total={0}
                />
              </div>

              {/* CARD CONTAINING LEAD LIST */}
              <div className="row actionListCardContainer">
                <Neo.Card className="col-12">
                  <div className="row p-24">
                    <h4 className="pt-0 mr-1">
                      {viewModel.selectedTab}
                    </h4>
                    {viewModel.selectedTab !== textConstants.buttonText.Archive &&
                      <div className="mb-2">
                        <DataTooltip parent={<Icon.AlertCircle size={20} />}
                          direction='down'
                          type='description'
                          variant='dark'
                          className="actionlist-tooltip">
                          {viewModel.actionListConfigurationsList.find(config => config.uniqueTableKey === viewModel.actionListLeadType)?.description ?? ""}
                        </DataTooltip>
                      </div>
                    }
                    <div className="col text-right p-0">
                      <SearchField
                        onClickFunction={(e) => {
                          viewModel.actionListCriteria.pageNumber = 1
                          viewModel.getActionList()
                          viewModel.searchFieldVM.hideNoResultDialog = true
                        }}
                        searchBindableProp={viewModel.actionListCriteria.meta.searchString}
                        searchString={viewModel.actionListCriteria.searchString}
                        searchFieldVM={viewModel.searchFieldVM}
                      />
                    </div>

                    <div className="dropdownPositionRelative p-0 ml-4">
                      <>
                        <Neo.FormGroup bind={viewModel.meta.selectedActionListStatusId}
                          placeholder={""}
                          suppressLabel={true}
                          select={{ items: viewModel.actionListFilterStatuses, allowNulls: true, deSelectText: textConstants.Dashboard.allStatusesFilter }}
                          onChange={async () => {
                            await viewModel.getFilteredActionListLeads()
                          }}
                          className={"filterDropDown"}
                        />
                      </>

                    </div>
                  </div>

                  {/* No search results */}
                  {viewModel.pagedLeadList.length === 0 &&
                    viewModel.searchFieldVM.isSearching && !viewModel.isLoading &&
                    <InfoBox header={textConstants.titleText.NoSearchMatches}
                      text={textConstants.generalText.NoSearchMatches}
                    />
                  }

                  {this.props.viewModel.pagedLeadList.length === 0 &&
                    !viewModel.searchFieldVM.isSearching && !viewModel.isLoading &&
                    <>
                      <div className="d-flex">
                        {<ActionList_NoLeads className="ml-auto mr-auto noleads" />}
                      </div>
                      <h2 className="text-center ml-3 mr-3 ">
                        No {this.props.viewModel.selectedTab} Available
                      </h2>
                    </>
                  }

                  {viewModel.pagedLeadList.length > 0 && !viewModel.isLoading &&
                    <div className="row">
                      <div className="col-12 px-0">
                        <ActionListTable
                          viewModel={viewModel}
                          archiveOrUnarchiveSelectedLeads={(isArchive) => { viewModel.archiveOrUnarchiveSelectedLeads(isArchive) }}
                          openLeadView={this.props.openLeadsView}
                        />
                      </div>
                    </div>
                  }
                </Neo.Card>
              </div>
            </div>
          </>
        }

        {!viewModel.showActionListView && !isMobile() &&
          <LeadMessageView
            viewModel={viewModel.LeadMessageViewModel}
            newHotTotal={viewModel.actionListSummaryLookup.newHotTotal}
            newWarmTotal={viewModel.actionListSummaryLookup.newWarmTotal}
            newColdTotal={viewModel.actionListSummaryLookup.newColdTotal}
            hotTotal={viewModel.actionListSummaryLookup.hotTotal}
            warmTotal={viewModel.actionListSummaryLookup.warmTotal}
            coldTotal={viewModel.actionListSummaryLookup.coldTotal}
            leadStatuses={viewModel.leadStatuses}
            showPreviousMessage={this.props.showPreviousMessage}
            showNextMessage={this.props.showNextMessage}
            back={this.props.backToActionList}
            changeLeadType={this.props.changeLeadType}
            isFirstRecord={viewModel.isFirstRecord()}
            isLastRecord={viewModel.isLastRecord()}
            archiveCurrentLead={this.props.archiveCurrentLead}
            isLeadLoading={viewModel.isLeadLoading}
            actionListCriteria={viewModel.actionListCriteria}
          />
        }
      </>
    );
  }
}
export default ActionListScreen