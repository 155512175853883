import { List, ModelBase, NeoModel } from "@singularsystems/neo-core";
import CustomerProfileStatusLookup from "./CustomerProfileStatusLookup";
import QuestionnaireAnswerLookup from "./QuestionnaireAnswerLookup";

@NeoModel
export default class CustomerProfileAnswersLookup extends ModelBase {
    customerProfileId : number = 0;

    profileName : string = "";

    fileName : string = "";

    fileUrl : string = "";

    customerProfileStatus : CustomerProfileStatusLookup = new CustomerProfileStatusLookup();

    profileAnswers : List<QuestionnaireAnswerLookup> = new List(QuestionnaireAnswerLookup);
}