import React, { useRef, useEffect, useState } from "react";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { ZendeskLoaderButton } from "./ZendeskLoader.styles";
import { textConstants } from 'common/textConstants';
import * as Icon from 'react-feather';
import { AppService, Types } from 'Services/AppService';

interface Props {
  label?: string;
  zendeskIsVisible: boolean;
}

const setting = {
  color: {
    theme: "#FF6731",
  }
};

const ZendeskLoader = ({ label, zendeskIsVisible }: Props) => {
  const zendeskRef = useRef(null);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  // Sets the state of the isZendeskVisible to the property coming from the App.tsx
  const [isZendeskVisible, setIsZendeskVisible] = useState(zendeskIsVisible);

  // Gets the global props
  const customAuthService = AppService.get(Types.Security.CustomAuthenticationService);
  const isClientUser = customAuthService.globalProps.isClientUser;

  const config = AppService.get(Types.Config);

  // Rerenders the zendesk loader
  useEffect(() => {
    setIsZendeskVisible(zendeskIsVisible);
  }, [zendeskIsVisible]);


  const handleLoaded = () => {
    ZendeskAPI("webWidget", "hide");
  };

  const handleOpen = () => {
    ZendeskAPI("webWidget", "show");
    ZendeskAPI("webWidget", "open");
    setIsWidgetOpen(true);
  };

  ZendeskAPI("webWidget:on", "close", () => {
    ZendeskAPI("webWidget", "hide");
    setIsWidgetOpen(false);
  });

  return (
    <>
      {/* Makes sure its a client user and not in onboarding */}
      {isClientUser && (
        <>
          {/* Will make sure the zendesk is hidden if not visible. */}
          {!isZendeskVisible && handleLoaded()}

          {/* Custom Styling for the Zendesk help button. */}
          {isZendeskVisible && (
            <ZendeskLoaderButton isOpen={isWidgetOpen} onClick={handleOpen}>
              <Icon.HelpCircle />
              <p>{label ? label : textConstants.buttonText.Help}</p>
            </ZendeskLoaderButton>
          )}

          {/* Global Zendesk component that will always be accessible */}
          <Zendesk
            ref={zendeskRef}
            zendeskKey={config.zendeskKey}
            {...setting}
            onLoaded={handleLoaded}
          />
        </>
      )}
    </>
  );
};

export default ZendeskLoader;
