import { NeoModel, Data, List, Model } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import { reaction } from 'mobx';
import MasterAccountLookup from '../../Models/MasterAccounts/MasterAccountLookup';
import MasterAccountCriteria from './../../Models/MasterAccounts/Query/MasterAccountCriteria';
import EditMasterAccountCommand from './Commands/EditMasterAccount';
import DeleteMasterAccountCommand from './Commands/DeleteMasterAccount';
import CommandResult from '../../Models/InvitedUsers/Commands/CommandResult';
import { textConstants } from '../../common/textConstants';
import UndeleteMasterAccountCommand from './Commands/UndeleteMasterAccount';
import EmployeeSize from '../../Models/Maintenance/EmployeeSize';
import AccountStatus from '../../Models/Maintenance/AccountStatus';
import SubIndustry from '../../Models/Maintenance/SubIndustry';
import SubRegion from '../../Models/Maintenance/SubRegion';
import { base64toBlob, getCurrentDateTime } from '../../common/utils';
import { TypeRowSelection } from '@inovua/reactdatagrid-community/types';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import InvalidReason from '../../Models/Maintenance/InvalidReason';
import SearchFieldVM from 'Components/SearchFieldVM';

type bulkActions = "BulkDelete" | "BulkUndo" | ""

@NeoModel
export default class MasterAccountsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private masterAccountsApiClient = AppService.get(Types.ApiClients.MasterAccountsApiClient),
        private customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
        private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService),) {

        super(taskRunner);
        this.customAuthService.globalProps.isOnboarding = false;
    }

    // Properties
    public masterAccountCriteria: MasterAccountCriteria = new MasterAccountCriteria();
    public showEditModal: boolean = false
    public employeeSizes = new List(EmployeeSize)
    public subIndustries = new List(SubIndustry)
    public accountStatuses = new List(AccountStatus)
    public invalidReasons = new List(InvalidReason)
    public subRegions = new List(SubRegion)
    public countries = this.appDataCache.countries.get().data.sortBy("countryName")
    public editMasterAccountCommand = new EditMasterAccountCommand()
    public deleteMasterAccountCommand = new DeleteMasterAccountCommand()
    public undeleteMasterAccountCommand = new UndeleteMasterAccountCommand()
    public showDeleteModal: boolean = false
    public selectedAccountName: string = "";
    public showForbiddenModal: boolean = false;
    public regionDisable: boolean = false
    public refreshMethod: any
    public selected?: TypeRowSelection
    public selectedItems?: TypeOnSelectionChangeArg
    public isRefreshCount: boolean = false;

    // Master Account Bulk Action 
    public showBulkActionModal: boolean = false
    public bulkActionMessage: string = ""
    public bulkActions: bulkActions | "" = ""

    public deletePerformed: boolean = false
    public editButtonClicked: boolean = false
    public deleteButtonClicked: boolean = false
    public unDeleteButtonClicked: boolean = false
    public unSelectedAccounts = new List(MasterAccountLookup)
    public selectedItemsCount: number = 0
    public searchFieldVM = new SearchFieldVM();

    public async initialise() {
        const countryIdReaction = reaction(() => this.masterAccountCriteria.countryId, () => this.FetchData())

        const sizes = (await this.taskRunner.waitFor(this.appDataCache.employeeSizes.getDataAsync())).sortBy("employeeMinSize");
        this.employeeSizes.set(sizes);

        const statuses = (await this.taskRunner.waitFor(this.appDataCache.accountStatuses.getDataAsync())).sortBy("accountStatusName");
        this.accountStatuses.set(statuses);

        const reasons = (await this.taskRunner.waitFor(this.appDataCache.invalidReasons.getDataAsync())).sortBy("invalidReasonMessage");
        this.invalidReasons.set(reasons);

        const industries = (await this.taskRunner.waitFor(this.appDataCache.subIndustries.getDataAsync())).sortBy("subIndustryName");
        this.subIndustries.set(industries);

        const subregions = (await this.taskRunner.waitFor(this.appDataCache.subRegions.getDataAsync())).sortBy("subRegionName");
        this.subRegions.set(subregions);

        // Fetch the countries list, in case it hasn't been fetched yet because that happens sometimes
        if (this.countries.length < 1) {
            this.countries = this.appDataCache.countries.get().data.sortBy("countryName")
        }

    }

    public async FetchData() {
        const regions = (await this.taskRunner.waitFor(this.appDataCache.regions.getDataAsync())).sortBy("countryId");
        this.regionDisable = regions.filter(x => x.countryId === this.masterAccountCriteria.countryId).length === 0
        this.refreshMethod()
    }

    public async setReactPageGridAndList(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<MasterAccountCriteria>>) {
        let responseModel = await this.masterAccountsApiClient.getMasterAccounts(request)

        if (this.isRefreshCount) {
            this.CalculateSelectedItems(responseModel.data.pageResult.total);
        }

        this.isRefreshCount = false;
        return responseModel
    }

    public GetRefreshMethod = (dataSource: (props: any) => Promise<void>) => {
        this.refreshMethod = dataSource
    }

    public onSelectionChange(data: any, totalRecords?: number) {

        this.selectedItems = data;

        this.handleSelectAll(data);

        this.CalculateSelectedItems(totalRecords);
    }

    private handleSelectAll(data: any) {
        if (this.selectedItems !== undefined) {
            if (this.selectedItems.selected === true) {

                // making a list of unselected items
                let gridUnSelectedItems = this.selectedItems.unselected;

                if (gridUnSelectedItems) {
                    let gridUnselectedAccounts = Object.keys(gridUnSelectedItems);

                    gridUnselectedAccounts.forEach(unselectedAccountId => {

                        let existingUnselectedAccount = this.unSelectedAccounts.find(account => account.masterAccountId.toString() === unselectedAccountId);

                        if (existingUnselectedAccount === undefined) {
                            let accountToAdd = new MasterAccountLookup();

                            if (unselectedAccountId === data.data.masterAccountId.toString()) {
                                this.CreateLookup(accountToAdd, data);
                            }

                            this.unSelectedAccounts.push(accountToAdd);
                        }
                    });

                    // Remove any reselected accounts
                    this.unSelectedAccounts.forEach(deselectedAccount => {
                        const accountStillUnselected = (gridUnselectedAccounts.indexOf(deselectedAccount.masterAccountId.toString()) > -1);
                        if (!accountStillUnselected) {
                            this.unSelectedAccounts.remove(deselectedAccount);
                        }
                    });
                }

                // Clear unselected accounts if user has reselected everything
                if (gridUnSelectedItems === null && this.unSelectedAccounts.length > 0) {
                    this.unSelectedAccounts.length = 0;
                }
            }
        }
    }

    private CreateLookup(accountToAdd: MasterAccountLookup, data: any) {
        accountToAdd.originalName = data.data.originalName;
        accountToAdd.fixedName = data.data.fixedName;
        accountToAdd.description = data.data.description;
        accountToAdd.logoUrl = data.data.logoUrl;
        accountToAdd.industrySubIndustry = data.data.industrySubIndustry;
        accountToAdd.employeeSize = data.data.employeeSize;
        accountToAdd.isDeleted = data.data.isDeleted;
        accountToAdd.location = data.data.location;
        accountToAdd.domain = data.data.domain;
        accountToAdd.masterAccountId = data.data.masterAccountId;
        accountToAdd.status = data.data.status;
        accountToAdd.region = data.data.invalidReason;
        accountToAdd.reOpenedCount = data.data.reOpenedCount;
    }

    private CalculateSelectedItems(totalRecords: number | undefined) {

        if (totalRecords !== 0) {
            if (this.selectedItems && this.selectedItems.selected && totalRecords) {
                var selected = this.selectedItems.selected;
                this.selected = this.selectedItems.selected

                if (selected === true) {
                    let recordCount = totalRecords - (this.unSelectedAccounts ? this.unSelectedAccounts.length : 0);
                    this.selectedItemsCount = recordCount
                } else if (selected) {
                    var manuallySelectedItems = Object.keys(this.selectedItems.selected);
                    this.selectedItemsCount = manuallySelectedItems.length;
                }
            }
        } else {
            this.selectedItemsCount = 0;
        }

    }

    public openEditModal(masterAccount: MasterAccountLookup) {

        // Remove "To-do" status from list
        var statusToRemove = this.accountStatuses.find(status => status.accountStatusName.toLowerCase() === "to-do")
        if (statusToRemove !== undefined) {
            this.accountStatuses.remove(statusToRemove)
        }

        this.editMasterAccountCommand.masterAccountId = masterAccount.masterAccountId
        this.editMasterAccountCommand.fixedName = masterAccount.fixedName.trim()
        this.editMasterAccountCommand.originalName = masterAccount.originalName
        this.editMasterAccountCommand.location = masterAccount.location
        this.editMasterAccountCommand.domain = masterAccount.domain
        this.editMasterAccountCommand.isDeleted = masterAccount.isDeleted

        let employeeSize = this.employeeSizes.find(es => es.employeeSizeName === masterAccount.employeeSize)
        if (employeeSize !== undefined) {
            this.editMasterAccountCommand.employeeSizeId = employeeSize.employeeSizeId
        }

        let subIndustry = this.subIndustries.find(sub => sub.subIndustryName === masterAccount.industrySubIndustry)
        if (subIndustry !== undefined) {
            this.editMasterAccountCommand.subIndustryId = subIndustry.subIndustryId
        }

        let accountStatus = this.accountStatuses.find(accs => accs.accountStatusName === masterAccount.status)
        if (accountStatus !== undefined) {
            this.editMasterAccountCommand.accountStatusId = accountStatus.accountStatusId
        }

        if (accountStatus?.accountStatusName !== "Valid") {
            let invalidReason = this.invalidReasons.find(inv => inv.invalidReasonMessage === masterAccount.invalidReason)
            if (invalidReason !== undefined) {
                this.editMasterAccountCommand.invalidReasonId = invalidReason.invalidReasonId
            }
        }
        else {
            this.editMasterAccountCommand.invalidReasonId = 0;
        }


        let region = this.subRegions.find(subRegion => subRegion.subRegionName === masterAccount.region)
        if (region !== undefined) {
            this.editMasterAccountCommand.subRegionId = region.subRegionId
        }

        this.showEditModal = true;
    }

    public closeEditModal() {
        this.showEditModal = false;
        this.editMasterAccountCommand.subRegionId = 0
    }

    public editMasterAccount() {
        this.taskRunner.run(async () => {
            const response = await this.masterAccountsApiClient.editMasterAccount(this.editMasterAccountCommand);
            const cr: CommandResult = response.data as CommandResult;
            if (cr.success) {
                this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.MasterAccountSaved);
                this.FetchData()
                this.closeEditModal()
            } else {
                this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
            }
        });
    }

    public checkInvalidReason(id: number) {
        let invalidRecord = this.accountStatuses.find(as => as.accountStatusId === id)

        if (invalidRecord?.accountStatusName.toLowerCase() === textConstants.generalText.invalidText) {
            return true
        } else return false;
    }

    private ParseReturnResult(responseDate: any, saveSuccessMessage: string) {
        const cr: CommandResult = responseDate as CommandResult;
        if (cr.success) {
            this.notifications.addSuccess(textConstants.titleText.Saved, saveSuccessMessage);
        } else {
            this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
        }
    }

    public openDeleteModal(masterAccount: MasterAccountLookup) {
        this.deleteMasterAccountCommand = new DeleteMasterAccountCommand();
        this.deleteMasterAccountCommand.masterAccountId = masterAccount.masterAccountId
        // Show the modal
        this.showDeleteModal = true;
        this.selectedAccountName = masterAccount.originalName
    }

    public deleteMasterAccount() {
        this.showDeleteModal = false;
        this.taskRunner.run(async () => {
            const response = await this.masterAccountsApiClient.deleteMasterAccount(this.deleteMasterAccountCommand);
            const cr: CommandResult = response.data as CommandResult;
            if (cr.success) {
                this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.MasterAccountDeleted);
                this.FetchData()
            } else {
                this.notifications.addDanger(textConstants.titleText.DeleteFailed, cr.error);
            }
        });
    }

    public undeleteMasterAccount(masterAccountId: number) {
        this.undeleteMasterAccountCommand.masterAccountId = masterAccountId
        this.taskRunner.run(async () => {
            const response = await this.masterAccountsApiClient.undeleteMasterAccount(this.undeleteMasterAccountCommand);
            const cr: CommandResult = response.data as CommandResult;
            if (cr.success) {
                this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.MasterAccountSaved);
                this.FetchData()
            } else {
                this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
            }
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }

    public downloadMasterAccounts() {
        this.taskRunner.run(async () => {
            const response = await this.masterAccountsApiClient.downloadMasterAccounts(this.masterAccountCriteria.countryId);

            const url = window.URL.createObjectURL(base64toBlob(response.data, "xlsx"));
            const link = document.createElement('a');
            link.href = url;
            const datetime = getCurrentDateTime();
            const countryName = this.countries.filter(c => c.countryId === this.masterAccountCriteria.countryId)[0]
            link.setAttribute('download', countryName.countryName + 'MasterAccounts_' + datetime + '.xlsx');
            document.body.appendChild(link);
            link.click();
        })
    }

    public securityCheck(role: any, openMethod: () => void) {
        if (this.authorisationService.hasRole(role)) {
            openMethod();
        }
        else {
            this.showForbiddenModal = true;
        }
    }

    // Bulk Operations
    public OpenBulkActionModal(action: bulkActions) {
        this.bulkActions = action
        this.bulkActionMessage = ""
        if (this.bulkActions === textConstants.generalText.BulkDelete) {
            this.bulkActionMessage = textConstants.messageText.validationMessage.bulkDeleteConfirmation
            this.showBulkActionModal = true
            this.deleteMasterAccountCommand.invalidReasonId = 0;
        }
        if (this.bulkActions === textConstants.generalText.BulkUndo) {
            this.bulkActionMessage = textConstants.messageText.validationMessage.bulkUndoConfirmation
            this.showBulkActionModal = true
        }
    }

    public PerformBulkAction() {
        this.showBulkActionModal = false;
        if (this.bulkActions === textConstants.generalText.BulkDelete) {
            this.bulkDelete()
        }

        if (this.bulkActions === textConstants.generalText.BulkUndo) {
            this.bulkUnDelete()
        }
        this.selectedItemsCount = 0

        if (this.selectedItems) {
            this.selectedItems.selected = null;
        }

        this.selected = false
    }

    private async bulkDelete() {
        this.deleteMasterAccountCommand.isSelectAll = false
        this.deleteMasterAccountCommand.masterAccountCriteria = this.masterAccountCriteria

        this.taskRunner.run(async () => {

            var masterAccountLookups = new List(MasterAccountLookup)
            if (this.selectedItems && this.selectedItems.selected) {

                var selected = this.selectedItems.selected

                // Items have been selected using Select All checkbox
                if (selected === true) {
                    this.deleteMasterAccountCommand.unselectedAccounts = this.unSelectedAccounts
                    this.deleteMasterAccountCommand.isSelectAll = true
                }

                // Items have been selected individually
                else if (selected) {
                    var keysMap = Object.keys(selected).map(originalName => selected[originalName])

                    this.PopulateMasterAccountLookups(keysMap, masterAccountLookups);

                    this.deleteMasterAccountCommand.masterAccountLookups = masterAccountLookups
                }

                this.showDeleteModal = false;
                const response = await this.masterAccountsApiClient.bulkDeleteMasterAccounts(this.deleteMasterAccountCommand)
                this.ParseReturnResult(response.data, textConstants.messageText.saveMessage.BulkDelete)
                this.refreshMethod()
            }
        });
    }
    private PopulateMasterAccountLookups(keysMap: any[], masterAccountLookups: List<MasterAccountLookup>) {
        keysMap.forEach(row => {
            var newLookup = new (MasterAccountLookup);
            newLookup.fixedName = row.fixedName;
            newLookup.originalName = row.originalName;
            newLookup.description = row.description;
            newLookup.logoUrl = row.logoUrl;
            newLookup.status = row.status;
            newLookup.employeeSize = row.employeeSize;
            newLookup.isDeleted = row.isDeleted;
            newLookup.location = row.location;
            newLookup.domain = row.domain;
            newLookup.masterAccountId = row.masterAccountId;
            newLookup.industrySubIndustry = row.industrySubIndustry;
            newLookup.IndustriesVersion = row.industriesVersion
            newLookup.region = row.region;
            newLookup.reOpenedCount = row.reOpenedCount;
            newLookup.invalidReason = row.invalidReason;
            masterAccountLookups.push(newLookup);
        });
    }

    private async bulkUnDelete() {
        this.undeleteMasterAccountCommand.isSelectAll = false
        this.undeleteMasterAccountCommand.masterAccountCriteria = this.masterAccountCriteria

        this.taskRunner.run(async () => {

            var masterAccountLookups = new List(MasterAccountLookup)
            if (this.selectedItems && this.selectedItems.selected) {

                var selected = this.selectedItems.selected

                // Items have been selected using Select All checkbox
                if (selected === true) {
                    this.undeleteMasterAccountCommand.isSelectAll = true
                    this.undeleteMasterAccountCommand.unselectedAccounts = this.unSelectedAccounts
                }

                // Items have been selected individually
                else if (selected) {
                    var keysMap = Object.keys(this.selectedItems.selected).map(originalName => selected[originalName])
                    this.PopulateMasterAccountLookups(keysMap, masterAccountLookups);
                    this.undeleteMasterAccountCommand.masterAccountLookups = masterAccountLookups
                }
            }
            const response = await this.masterAccountsApiClient.bulkUndeleteMasterAccounts(this.undeleteMasterAccountCommand)
            this.ParseReturnResult(response.data, textConstants.messageText.saveMessage.BulkUndoMasterAccounts)
            this.refreshMethod()
        });
    }

}