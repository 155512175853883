import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { textConstants } from '../../../common/textConstants';
import RichTextEditor from '../../../Components/RichTextEditor';
import OnboardingPopupMaintenanceVM from './OnboardingPopupMaintenanceVM';
import DashboardTooltipModel from '../../../Models/Maintenance/DashboardTooltipModel';

export interface ITooltipIndividualScreenViewProps {
    viewModel: OnboardingPopupMaintenanceVM,
    dashboardTooltip: DashboardTooltipModel,
    save(): void,
}

@observer
export default class TooltipIndividualScreenView extends React.Component<ITooltipIndividualScreenViewProps> {
    constructor(props: any) {
        super(props);
    }

    private tempUpdatedHtml: string = "";
    private initialDisplayText: string = "";
    private editedModal: string = "";

    public selectModalText() {
        if (!this.tempUpdatedHtml) {
            return this.initialDisplayText;
        }

        switch (this.editedModal) {
            case textConstants.generalText.TooltipHeader: return this.props.dashboardTooltip.header = this.tempUpdatedHtml;
            case textConstants.generalText.TooltipSubheader: return this.props.dashboardTooltip.subheader = this.tempUpdatedHtml;
            case textConstants.generalText.TooltipBody: return this.props.dashboardTooltip.body = this.tempUpdatedHtml;
            case textConstants.generalText.TooltipSubbody: return this.props.dashboardTooltip.subBody = this.tempUpdatedHtml;
            case textConstants.generalText.TooltipArticleLink: return this.props.dashboardTooltip.articleLinkText = this.tempUpdatedHtml;
            default: return this.tempUpdatedHtml;
        }
    }

    public render() {
        return (
            <div className='onboarding-step-menu-data'>
                <Neo.Form onSubmit={() => this.props.save()}>
                    <div className='row m-0'>
                        <h3 className='col-12 mt-3 mb-3 pt-0 p-24'>{this.props.viewModel.dashboardTooltip.popupName}</h3>
                        {/* header */}
                        <div className='col-12 p-24'>
                            <div className='row'>
                                <div className="col-sm-1 pt-2 pb-2">Main Header</div>
                                <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.dashboardTooltip.meta.header} disabled={true} suppressLabel />
                                <div className="col-sm-1 text-center">
                                    <Neo.Button
                                        className="btn-width-100"
                                        variant="light"
                                        onClick={() => {
                                            this.initialDisplayText = this.props.dashboardTooltip.header;
                                            this.editedModal = textConstants.generalText.TooltipHeader;
                                            this.props.viewModel.showTooltipUpdateTextModal = true;
                                        }}>
                                        {textConstants.buttonText.Edit}
                                    </Neo.Button>
                                </div>
                            </div>

                            {
                                <div className='row'>
                                    {/* body */}
                                    <div className="col-sm-1 pt-2 pb-2">Main Body</div>
                                    <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.dashboardTooltip.meta.body} disabled={true} suppressLabel />
                                    <div className="col-sm-1 text-center">
                                        <Neo.Button
                                            className="btn-width-100"
                                            variant="light"
                                            onClick={() => {
                                                this.initialDisplayText = this.props.dashboardTooltip.body;
                                                this.editedModal = textConstants.generalText.TooltipBody;
                                                this.props.viewModel.showTooltipUpdateTextModal = true;
                                            }}>
                                            {textConstants.buttonText.Edit}
                                        </Neo.Button>
                                    </div>
                                </div>
                            }

                            {
                                <div className='row'>
                                    {/* sub header */}
                                    <div className="col-sm-1 pt-2 pb-2">Sub-Section Header</div>
                                    <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.dashboardTooltip.meta.subheader} disabled={true} suppressLabel />
                                    <div className="col-sm-1 text-center">
                                        <Neo.Button
                                            className="btn-width-100"
                                            variant="light"
                                            onClick={() => {
                                                this.initialDisplayText = this.props.dashboardTooltip.subheader;
                                                this.editedModal = textConstants.generalText.TooltipSubheader;
                                                this.props.viewModel.showTooltipUpdateTextModal = true;
                                            }}>
                                            {textConstants.buttonText.Edit}
                                        </Neo.Button>
                                    </div>
                                </div>
                            }

                            {
                                <div className='row'>
                                    {/* sub body */}
                                    <div className="col-sm-1 pt-2 pb-2">Sub-Section Body</div>
                                    <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.dashboardTooltip.meta.subBody} disabled={true} suppressLabel />
                                    <div className="col-sm-1 text-center">
                                        <Neo.Button
                                            className="btn-width-100"
                                            variant="light"
                                            onClick={() => {
                                                this.initialDisplayText = this.props.dashboardTooltip.subBody;
                                                this.editedModal = textConstants.generalText.TooltipSubbody;
                                                this.props.viewModel.showTooltipUpdateTextModal = true;
                                            }}>
                                            {textConstants.buttonText.Edit}
                                        </Neo.Button>
                                    </div>
                                </div>
                            }

                            {
                                <div className='row'>
                                    {/* sub body */}
                                    <div className="col-sm-1 pt-2 pb-2">Article Link Text</div>
                                    <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.dashboardTooltip.meta.articleLinkText} disabled={true} suppressLabel />
                                    <div className="col-sm-1 text-center">
                                        <Neo.Button
                                            className="btn-width-100"
                                            variant="light"
                                            onClick={() => {
                                                this.initialDisplayText = this.props.dashboardTooltip.articleLinkText;
                                                this.editedModal = textConstants.generalText.TooltipArticleLink;
                                                this.props.viewModel.showTooltipUpdateTextModal = true;
                                            }}>
                                            {textConstants.buttonText.Edit}
                                        </Neo.Button>
                                    </div>
                                </div>
                            }

                            {
                                <div className='row'>
                                    {/* redirect link */}

                                    <div className="col-sm-1 pt-2 pb-2">Video</div>
                                    <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.dashboardTooltip.meta.link} suppressLabel />
                                    <div className='col-sm-1'></div>
                                </div>
                            }

                            <div className="my-4 text-right">
                                <Neo.Button className="btn-width-100" isSubmit variant="success"
                                >{textConstants.buttonText.Save}
                                </Neo.Button>
                            </div>
                        </div>
                    </div>
                </Neo.Form>

                {/*Edit modals*/}
                <Neo.Modal
                    title={`Update Display Text`}
                    className={"modal-style-test"}
                    show={this.props.viewModel.showTooltipUpdateTextModal}
                    onClose={() => this.props.viewModel.showTooltipUpdateTextModal = false}
                    closeButton={{ text: "Cancel", variant: "light" }}
                    acceptButton={{
                        text: "Done", variant: "secondary",
                        onClick: () => {
                            this.selectModalText();
                            this.props.viewModel.showTooltipUpdateTextModal = false
                        }
                    }}
                    formProps={{ model: this.props.dashboardTooltip }}>
                    <div>
                        <RichTextEditor
                            initialString={this.initialDisplayText}
                            getHtmlText={(htmlText: string) => {
                                this.tempUpdatedHtml = htmlText;
                            }} />
                    </div>
                </Neo.Modal>
            </div >
        );
    }
}