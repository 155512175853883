import React from 'react';
import { observer } from 'mobx-react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { List } from '@singularsystems/neo-core';

interface IPieChart {
    title?: string;
    SeriesData: List<any>;
}

@observer
export default class PieChart extends React.Component<IPieChart> {

    constructor(props: IPieChart) {
        super(props);
    }

    public highChartOptions: any;

    // Populates the data into the PieGraphData

    public highChartsOptions() {

        this.highChartOptions = {
            credits: {
              enabled: false
            },
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                margin: [50, 0, 50, 300]
            },

            title: {
                text: '',
            },

            legend: {
                align: 'left',
                verticalAlign: 'middle',
                layout: 'vertical',
                itemMarginTop: 10,
                itemMarginBottom: 10,
                itemStyle: {
                    fontWeight: 'lighter'
                }
            },
            tooltip: {
                shared: true,
                useHTML: true,
                headerFormat: ' ',
                pointFormat: '{point.custom}: ' + '<b>{point.y:.0f}%</b>',
                valueDecimals: 2
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.y}%</b>'
                    },
                    showInLegend: true,
                },
            },

            series: [{
                name: "Target Market Completion Rate",
                colorByPoint: true,
                data: this.props.SeriesData
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
        }
    }

    public render() {
        this.highChartsOptions();
        return (
            <div className="pieChartComponent p-24">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.highChartOptions}
                />
            </div >
        );
    }
}