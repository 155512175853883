import { Data, NeoModel } from "@singularsystems/neo-core";
import BatchReviewUploadCriteria from '../../Models/BatchReview/Query/BatchReviewUploadCriteria';
import BatchReviewUploadLookup from '../../Models/BatchReview/Query/BatchReviewUploadLookup';
import { Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../Services/AppService";
import ArchiveBatchReviewCommand from "../../Models/BatchReview/Command/ArchiveBatchReviewCommand";
import { textConstants } from "../../common/textConstants";
import { base64toBlob, getCurrentDateTime } from "../../common/utils";
import SearchFieldVM from "../../Components/SearchFieldVM";

@NeoModel
export default class BatchReviewUploadVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private batchReviewApiClient = AppService.get(Types.ApiClients.BatchReviewApiClient),
    
  ) 
  {
    super(taskRunner);
  }

  public isArchive : boolean = false
  public hideGrid : boolean = false

  public BatchReviewUploadCriteria: BatchReviewUploadCriteria = new BatchReviewUploadCriteria();

  public searchFieldVM = new SearchFieldVM();

  public batchReviewUploadPageManager = new Data.PageManager(this.BatchReviewUploadCriteria, BatchReviewUploadLookup,
    this.batchReviewApiClient.getBatchReviewUploadLookup,
    {
      pageSize: 20,
      fetchInitial: false,
    }
  )

  public async pageManagerRefresh() {

    this.hideGrid = true;

    this.BatchReviewUploadCriteria.isDeleted = this.isArchive;

    await this.batchReviewUploadPageManager.refreshData(undefined, this.taskRunner).then(() => {
      this.hideGrid = false;
    });
  }

  public async archiveItem(batchReviewUploadId: number){

    this.taskRunner.run(async () => 
    {
      let command = new ArchiveBatchReviewCommand()
      command.clientId = this.BatchReviewUploadCriteria.clientId;
      command.isDelete = true;
      command.batchReviewUploadId = batchReviewUploadId;

      let result = await this.batchReviewApiClient.archiveOrUnarchiveBatchReview(command)
      if (result.data) {
        this.notifications.addSuccess(
            textConstants.titleText.BatchReviewSpaced,
            textConstants.messageText.saveMessage.BatchReviewArchived
        )

        this.pageManagerRefresh() 
      }
    })
  }

  public async unarchiveItem(batchReviewUploadId: number){

    this.taskRunner.run(async () => 
    {
      let command = new ArchiveBatchReviewCommand()
      command.clientId = this.BatchReviewUploadCriteria.clientId;
      command.isDelete = false;
      command.batchReviewUploadId = batchReviewUploadId;

      let result = await this.batchReviewApiClient.archiveOrUnarchiveBatchReview(command)

      if (result.data) {
        this.notifications.addSuccess(
            textConstants.titleText.BatchReviewSpaced,
            textConstants.messageText.saveMessage.BatchReviewUnarchived
        )

        this.pageManagerRefresh() 
      }
    })
  }
  
  public async downloadItems(batchReviewUploadId: number){
    var batchReview = this.batchReviewUploadPageManager.data.find(f => f.batchReviewUploadId === batchReviewUploadId)

    this.taskRunner.run(async () => {
      let response = await this.batchReviewApiClient.downloadBatchReview(batchReviewUploadId, this.BatchReviewUploadCriteria.clientId);

      if(response.data){
        const url = window.URL.createObjectURL(base64toBlob(response.data.fileContents, response.data.contentType));

        const link = document.createElement("a");
        link.href = url;
        
        link.setAttribute(
          "download",
          "BatchReview_" +
          batchReview?.batchFileName +
          "_" +
          getCurrentDateTime() +
          ".csv"
        );

        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    })
  }
}
