import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import Axios, { AxiosPromise } from 'axios';
import CustomerProfileCriteria from '../Models/IdealCustomerProfiles/CustomerProfileCriteria';
import { Data, Model, Utils } from '@singularsystems/neo-core';
import ToggleCustomerProfileArchive from '../Models/IdealCustomerProfiles/Commands/ToggleCustomerProfileArchive';
import ApiResult from '../Models/ApiResult';
import { ResponseResolver } from '../common/utils';
import SaveQuestionnaireAnswersCommand from '../Models/IdealCustomerProfiles/Commands/SaveQuestionnaireAnswersCommand';
import CustomerProfileAnswersLookup from '../Models/IdealCustomerProfiles/CustomerProfileAnswersLookup';
import BuyingCenterQuery from '../Models/IdealCustomerProfiles/Queries/BuyingCenterQuery';
import CloneICPCommand from '../Models/IdealCustomerProfiles/Commands/CloneICPCommand';
import ClientICPLookup from 'Models/CampaignMessage/ClientICPLookup';
import DownloadICPCommand from 'Models/IdealCustomerProfiles/Commands/DownloadICPCommand';

export interface ICustomerProfilesApiClient {
    toggleArchiveCustomerprofile(toggleArchive: ToggleCustomerProfileArchive): AxiosPromise<any>,
    getCustomerProfiles(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<CustomerProfileCriteria>>): AxiosPromise<any>,
    getCustomerProfilesCount(clientId: number): AxiosPromise<any>,
    saveCustomerProfile(saveCommand: SaveQuestionnaireAnswersCommand): AxiosPromise<ApiResult>,
    getCustomerSpecificProfile(clientId: number, customerProfileId: number): AxiosPromise<CustomerProfileAnswersLookup>
    getBuyingCenters(query: BuyingCenterQuery): AxiosPromise<any>
    saveCustomerProfileFile(fileList: File[] | FileList, clientId: number, customerProfileId: number | null): AxiosPromise<ApiResult>,
    downloadCustomerProfileFile(clientId: number, customerProfileId: number): AxiosPromise<ApiResult>,
    deleteCustomerProfileFile(clientId: number, customerProfileId: number): AxiosPromise<ApiResult>,
    download30OrLessTemplateFile(clientId: number): AxiosPromise<ApiResult>,
    getICPCompletionInformation(customerProfileId: number): AxiosPromise<ApiResult>
    cloneICP(idealCustomer: CloneICPCommand): AxiosPromise<Model.PlainObject<ApiResult>>
    getClientICPList(clientId: number): AxiosPromise<Array<Model.PlainObject<ClientICPLookup>>>
    generateCampaignMessageViaAIAsync(command: SaveQuestionnaireAnswersCommand): AxiosPromise<ApiResult>
    downloadICP(customerProfileId: number, requestBody: DownloadICPCommand): AxiosPromise<ApiResult>
}

@injectable()
export default class CustomerProfilesApiClient implements ICustomerProfilesApiClient {

    constructor(public cnfig = AppService.get(Types.Config)) {
    }

    getCustomerProfiles(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<CustomerProfileCriteria>>): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/CustomerProfile/GetCustomerProfiles?${Utils.getQueryString(request)}`);
    }

    getCustomerProfilesCount(clientId: number): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/CustomerProfile/GetCustomerProfilesCount/${clientId}`);
    }

    toggleArchiveCustomerprofile(toggleArchive: ToggleCustomerProfileArchive): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.post(`${config.apiPath}/CustomerProfile/toggleArchiveCustomerProfile`, toggleArchive.toJSObject());
    }

    public saveCustomerProfile(saveCommand: SaveQuestionnaireAnswersCommand): AxiosPromise<ApiResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<ApiResult>(Axios.post<ApiResult>(`${apiPath}/CustomerProfile/SaveCustomerProfile`, saveCommand.toJSObject()));
    }

    public getCustomerSpecificProfile(clientId: number, customerProfileId: number): AxiosPromise<CustomerProfileAnswersLookup> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<CustomerProfileAnswersLookup>(Axios.get<ApiResult>(`${apiPath}/CustomerProfile/GetCustomerProfile?clientId=${clientId}&customerProfileId=${customerProfileId}`));
    }

    public getBuyingCenters(query: BuyingCenterQuery): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;

        if (query.customerProfileId) {
            return ResponseResolver<any>(Axios.post<ApiResult>(`${apiPath}/CustomerProfile/GetBuyingCenters?clientId=${query.clientId}&customerProfileId=${query.customerProfileId}`))
        } else {
            return ResponseResolver<any>(Axios.post<ApiResult>(`${apiPath}/CustomerProfile/GetBuyingCenters?clientId=${query.clientId}`));
        }
    }

    //Returns the infomation from front end to backend
    public cloneICP(idealCustomer: CloneICPCommand): AxiosPromise<ApiResult> {
        return Axios.post(`${this.cnfig.apiPath}/CustomerProfile/CloneIdealCustomerProfile`, idealCustomer.toJSObject());
    }

    public saveCustomerProfileFile(fileList: File[] | FileList, clientId: number, customerProfileId: number | null): AxiosPromise<ApiResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        const bodyFormData = new FormData();
        bodyFormData.set('files', fileList[0]);
        bodyFormData.set('clientId', clientId.toString());

        if (customerProfileId) {
            bodyFormData.set('customerProfileId', customerProfileId.toString());
        }

        return Axios.post<ApiResult>(`${apiPath}/CustomerProfile/SaveCustomerProfileFile`, bodyFormData);
    }

    public downloadCustomerProfileFile(clientId: number, customerProfileId: number): AxiosPromise<ApiResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.get<ApiResult>(`${apiPath}/CustomerProfile/DownloadCustomerProfileFile?clientId=${clientId}&customerProfileId=${customerProfileId}`);
    }

    public download30OrLessTemplateFile(clientId: number): AxiosPromise<ApiResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.get<ApiResult>(`${apiPath}/CustomerProfile/Download30OrLessTemplateAsync?clientId=${clientId}`);
    }

    public deleteCustomerProfileFile(clientId: number, customerProfileId: number): AxiosPromise<ApiResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.get<ApiResult>(`${apiPath}/CustomerProfile/RemoveCustomerProfileFile?clientId=${clientId}&customerProfileId=${customerProfileId}`);
    }

    public getICPCompletionInformation(customerProfileId: number): AxiosPromise<ApiResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.get<ApiResult>(`${apiPath}/CustomerProfile/GetCompletionInformation?customerProfileId=${customerProfileId}`);
    }

    public getClientICPList(clientId: number): AxiosPromise<Array<Model.PlainObject<ClientICPLookup>>> {
        const apiPath = AppService.get(Types.Config).apiPath
        return ResponseResolver<Array<ClientICPLookup>>(Axios.get(`${apiPath}/CustomerProfile/GetClientICPListAsync/${clientId}`));
    }

    public generateCampaignMessageViaAIAsync(command: SaveQuestionnaireAnswersCommand): AxiosPromise<ApiResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.post(`${apiPath}/CustomerProfile/generateCampaignMessageViaAIAsync`, command.toJSObject());
    }

    public downloadICP(customerProfileId: number, requestBody: DownloadICPCommand): AxiosPromise<ApiResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.post<ApiResult>(`${apiPath}/CustomerProfile/DownloadCustomerProfile?customerProfileId=${customerProfileId}`, requestBody);
    }
}