import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import Language from '../Models/Maintenance/Language';
import { AppService, Types } from '../Services/AppService';

export interface ILanguagesApiClient extends Data.IUpdateableApiClient<Language, number> {

}

@injectable()
export default class LanguagesApiClient extends Data.UpdateableApiClient<Language, number> implements ILanguagesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/languages`);
    }
}