import { ModelBase, NeoModel} from '@singularsystems/neo-core'; 

@NeoModel 
export default class TargetMarketAccountDeletedStateCommand extends ModelBase { 
   public targetMarketId: number = 0;

   public isDeletedState: boolean = false;

   public originalName: string = ""

   public targetMarketAccountId: number = 0

   public masterAccountId: number = 0

   public targetMarketAccountStatus: string = ""

   public clientAccountId: number = 0

   public dateLastProspected: string = "";
}