import React from 'react';
import { Views, Neo, NeoGrid } from '@singularsystems/neo-react';
import IndustrySubIndustryVM from './IndustrySubIndustryVM';
import { observer } from 'mobx-react';
import { textConstants } from './../../common/textConstants';
import GoBack from '../../Components/GoBack';
import * as Icon from 'react-feather';
import { IPropertyInstance } from '@singularsystems/neo-core/dist/Model';
import IndustriesVersion from 'Models/Maintenance/IndustriesVersion';

@observer
export default class IndustrySubIndustryView extends Views.ViewBase<IndustrySubIndustryVM> {

	constructor(props: unknown) {
		super("Industry Sub-Industry", IndustrySubIndustryVM, props);
	}

	onLeave() {
		this.viewModel.removeNotifications();
		return undefined;
	}

	private getIndustryVersion(industryId: number) {

		let industryVersionName: IPropertyInstance<string>

		if (!industryId) {
			let tempVersion = new IndustriesVersion()
			tempVersion.industryVersionName = "Industry Version"
			return tempVersion.meta.industryVersionName
		}

		let industry = this.viewModel.IndustryList.find(f => f.industryId === industryId)

		let industryVersion = this.viewModel.IndustriesVersionList.find(f => f.industriesVersionId === industry!.industriesVersionId)

		industryVersionName = industryVersion!.meta.industryVersionName;

		return industryVersionName
	}

	public render() {
		return (
			<>
				<GoBack />
				<Neo.Card>
					<h2 className='p-24 mt-4'>{textConstants.titleText.IndustrySubIndustries}</h2>
					<Neo.Form model={this.viewModel.IndustrySubIndustryList} onSubmit={() => { this.viewModel.saveList() }} showSummaryModal>
						<NeoGrid.Grid items={this.viewModel.IndustrySubIndustryList} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
							{(i, iMeta) => (
								<NeoGrid.Row >
									<NeoGrid.Column display={this.getIndustryVersion(i.industryId!)} />
									<NeoGrid.Column bind={iMeta.industryId} select={{ displayMember: "industryName", items: this.viewModel.IndustryList }} />
									<NeoGrid.Column bind={iMeta.subIndustryId} select={{ displayMember: "subIndustryName", items: this.viewModel.SubIndustryList }} />
									<NeoGrid.ButtonColumn showDelete
										deleteButton={{
											icon: <Icon.X size={24} />,
											variant: "light",
											className: "circled"
										}} />
								</NeoGrid.Row>
							)}
						</NeoGrid.Grid>
						<div className="my-4 text-right p-24">
							<Neo.Button className="btn-width-100" isSubmit variant="success">{textConstants.buttonText.Save}</Neo.Button>
						</div>
					</Neo.Form>
				</Neo.Card>
			</>
		);
	}
}