import React, { useState, useEffect } from "react";
import CloseIcon from "assets/svgs/Close";
import {
  StyledDrawerContainer,
  StyledDrawerContentWrapper,
  StyledDrawerContent,
  StyledDrawerCloseButton,
} from "./styles";
import { AppService, Types } from "Services/AppService";

interface IDrawerProps {
  setClose: () => void;
  position?: "left" | "right";
  height?: number;
  children: React.ReactNode;
}

const Drawer = ({
  setClose,
  position = "right",
  children,
}: IDrawerProps) => {
  const width = window.innerWidth / 2;

  useEffect(() => {
    const customAuthenticationService = AppService.get(Types.Security.CustomAuthenticationService);

    customAuthenticationService.globalProps.isZendeskVisible = false;

    return () => {
      customAuthenticationService.globalProps.isZendeskVisible = true;
    };
  }, [])

  return (
    <>
      <StyledDrawerContainer>
        <StyledDrawerContentWrapper width={"50%"}>
          <StyledDrawerCloseButton onClick={setClose}>
            <CloseIcon />
          </StyledDrawerCloseButton>
          <StyledDrawerContent>{children}</StyledDrawerContent>
        </StyledDrawerContentWrapper>
      </StyledDrawerContainer>
    </>
  );
};

export default Drawer;
