import BlacklistedDomainHelper from '../BlacklistedDomainHelper';
import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import { AppService, Types } from "../../../../Services/AppService";
import ComXReactDataGrid from '../../../../Components/ReactDataGrid/ComXReactDataGrid';
import { CreateColumns, helperConst } from './BlacklistedDomainGridHelper';
import BlacklistedDomainLookup from '../../../../Models/BlacklistedDomains/BlacklistedDomainLookup';
import BlacklistedDomainFilterList from '../../../../Models/BlacklistedDomains/Query/BlacklistedDomainFilterList';
import * as Icon from 'react-feather';
import { StyledButton } from 'Components/Button/styles';
import DataTooltip from 'Components/DataTooltip';
import { textConstants } from 'common/textConstants';

interface IBlacklistedDomainDataGridProps {
    viewModel: BlacklistedDomainHelper,
}

@observer
export default class BlacklistedDomainDataGrid extends React.Component<IBlacklistedDomainDataGridProps>{
    constructor(props: IBlacklistedDomainDataGridProps) {
        super(props)
    }

    private config = AppService.get(Types.Config)
    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

    public securityCheck(role: any, openMethod: () => void) {
        if (this.authorisationService.hasRole(role)) {
            openMethod();
        }
        else {
            this.props.viewModel.showForbiddenModal = true;
        }
    }

    public render() {
        const rowClassName = ({ data }: any) => {
            return {
                color: '#FFFFFF'
            }
        }

        var columns: any = CreateColumns(this.props.viewModel)

        return (
            <div id={"blacklistedDomainGrid"}>
                <div className="col-sm-12 pb-3 pl-0 pr-0 BlacklistedDomain">
                    <div className="row pl-2">
                        {/* Bulk Delete button */}
                        <div className="pl-2 bulkDelete">
                            <DataTooltip
                                parent={
                                    <StyledButton
                                        isIconButton={true}
                                        disabled={this.props.viewModel.selectedItemsCount < 1}
                                        onClick={() => this.props.viewModel.OpenBulkActionModal("BulkDelete")}>
                                        <Icon.Trash2 size={16} />
                                    </StyledButton>
                                }>
                                {textConstants.buttonText.BulkDelete}
                            </DataTooltip>
                        </div>

                        {/* Records Selected */}
                        <div className="pl-2 verticalAlignCenter accountSelectTax">
                            {this.props.viewModel.selectedItemsCount} records selected
                        </div>
                    </div>
                </div>
                {
                    this.props.viewModel.blacklistSearchCriteria.clientId &&
                    <ComXReactDataGrid
                        licence={this.config.gridLicense}
                        columns={columns}
                        // defaultFilterValue={filterValue}
                        criteria={this.props.viewModel.blacklistSearchCriteria}
                        idProperty={helperConst.idProperty}
                        style={helperConst.gridStyle}
                        emptyText={helperConst.emptyText}
                        externalSearchStringName={helperConst.searchString}
                        initialPageSize={helperConst.initialPageSize}
                        // filterTypes={MasterAccountFilterTypes}
                        pagination
                        checkboxColumn
                        // enableFiltering
                        className="ComxDataGrid"
                        cellSelection={{ "2,domain": true, "2,dateAdded": true }}
                        selected={this.props.viewModel.selected}
                        modelType={BlacklistedDomainLookup}
                        TFilter={BlacklistedDomainFilterList}
                        // onFilterChange={(x) => this.styleFilters(x)}
                        setReactPageGridAndList={async (x) => await this.props.viewModel.setReactPageGridAndList(x)}
                        refreshDataGrid={(data: (props: any) => Promise<void>) => this.props.viewModel.getRefreshMethod(data)}
                        onSelectionChange={(selectionData, dataSource) => this.props.viewModel.onSelectionChange(selectionData, dataSource)}
                        rowClassName={rowClassName}
                    />
                }

            </div>
        );
    }

}