import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import IndustriesVersion from '../Models/Maintenance/IndustriesVersion';

export interface IIndustriesVersionApiClient extends Data.IUpdateableApiClient<IndustriesVersion, number> {

}

@injectable()
export default class IndustriesVersionApiClient extends Data.UpdateableApiClient<IndustriesVersion, number> 
    implements IIndustriesVersionApiClient 
{
    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/IndustriesVersions`);
    }
}