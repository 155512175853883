import { List, ModelBase, NeoModel} from '@singularsystems/neo-core'; 
import TargetMarketAccountCriteria from '../../../Models/TargetMarketAccounts/Query/TargetMarketAccountCriteria';
import TargetMarketAccountLookup from '../../../Models/TargetMarketAccounts/TargetMarketAccountLookup';

@NeoModel 
export default class TMABulkReprospectCommand extends ModelBase {
   public targetMarketAccountLookups? = new List(TargetMarketAccountLookup)
   public targetMarketAccountCriteria?: TargetMarketAccountCriteria = new(TargetMarketAccountCriteria)
   public unselectedAccounts? = new List(TargetMarketAccountLookup)
   public isSelectAll:boolean = false;
}