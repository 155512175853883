import { ModelBase, NeoModel } from "@singularsystems/neo-core";

@NeoModel
export default class OnboardingMeetingLookup extends ModelBase
{
    public clientId : number = 0

    public csmFullName : string = "";

    public startTime : string = "";

    public endTime : string = "";

    public timezone : string = "";

    public isMeetingScheduled: boolean = false
}