import { injectable } from 'inversify';
import Axios, { AxiosPromise } from 'axios';
import Types from '../../AuthorisationTypes';
import NewMembership from '../../Models/NewMembership';
import { Misc } from '@singularsystems/neo-core';

export interface IMembershipsApiClient {
    /**
     * Will add a Membership
     */
    add(membership: NewMembership): AxiosPromise<any>
    
    /**
     * Will remove a Membership
     */
    remove(membershipId: number): AxiosPromise<any>
}

@injectable()
export default class MembershipsApiClient implements IMembershipsApiClient {

    private apiPath: string;

    constructor(config = Misc.Globals.appService.get(Types.Config.ConfigModel)) {
        this.apiPath = `${config.authorisationServerApiPath}/Memberships`;
    }

    public add(membership: NewMembership): AxiosPromise<any> {
        return Axios.post(`${this.apiPath}/AddMembership/`, membership.toJSObject());
    }

    public remove(membershipId: number): AxiosPromise<any> {
        return Axios.delete(`${this.apiPath}/RemoveMembership/${membershipId}`);
    }
}
