import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import * as Icon from 'react-feather';
import EmailChip from "./EmailPill";
import EmailContent from 'Components/EmailContent';
import { textConstants } from 'common/textConstants';
import ActionListLookupData from "Models/ActionList/ActionListLookupData";
import LeadMessageVM from "./LeadMessageVM";
import Select from 'react-select';

interface IEmailComponent {
  viewModel: LeadMessageVM
  leadLookup?: ActionListLookupData
  subject?: string
  content?: string
}

enum emailType {
  EmailTo = "Email",
  EmailCc = "EmailCc",
  EmailBcc = "EmailBcc"
}

@observer
export default class EmailComponent extends React.Component<IEmailComponent> {

  state = {
    isCcVisible: false,
    isBccVisible: false,
    openTemplates: false,
    totalCharacterCount: 0,
    subject: "",
    initialEmail: ""
  }

  public emailList: string[] = [];
  public emailListCc: string[] = [];
  public emailListBcc: string[] = [];

  public mailToLink: string = "mailto:";
  public emailTo: string = "";
  public emailCC: string = "";
  public emailBcc: string = "";
  public emailContent: string = "";
  public emailSubject: string = "";
  public storeEmailContent: string = this.props.content || "";
  public savedEmail: any

  handleEmailContent = (emailContent: string) => {
    this.storeEmailContent = emailContent;
    this.emailContent = encodeURIComponent(emailContent);
    this.populateEmailToSend(this.props.viewModel);
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      subject: event.target.value
    })

    this.handleEmailSubject(this.state.subject);
  };

  handleEmailSubject = (subject: string) => {
    this.emailSubject = encodeURIComponent(subject);
    this.populateEmailToSend(this.props.viewModel);
  }

  mailToEmails(emailList: string[]) {
    let emailString: string = "";
    let counter: number = 1;

    emailList.forEach((email) => {
      let emailValue: string = "";
      // Deals with the displayName (email) format
      if (email.includes("(") && email.includes(")")) {
        emailValue = email.substring(email.indexOf("(") + 1, email.indexOf(")"))
      } else {
        emailValue = email;
      }

      emailString = `${emailString}${emailValue}${counter !== emailList.length ? ',' : ""}`
      counter++;
    })

    return emailString
  }

  handleEmails = (email: string, emailType: emailType, isPushing: boolean) => {
    switch (emailType) {
      case "Email":
        let existingTo = this.emailList.find(emailInList => emailInList === email)

        if (existingTo && isPushing) {
          return
        }

        isPushing ? this.emailList.push(email) : this.emailList.remove(email);

        this.emailTo = this.mailToEmails(this.emailList)
        break;
      case "EmailCc":
        let existingCc = this.emailList.find(emailInList => emailInList === email)

        if (existingCc && isPushing) {
          return
        }

        isPushing ? this.emailListCc.push(email) : this.emailListCc.remove(email)
        this.emailCC = this.mailToEmails(this.emailListCc)
        break;
      case "EmailBcc":
        let existingBcc = this.emailList.find(emailInList => emailInList === email)

        if (existingBcc && isPushing) {
          return
        }
        isPushing ? this.emailListBcc.push(email) : this.emailListBcc.remove(email)
        this.emailBcc = this.mailToEmails(this.emailListBcc)
        break;
    }

    this.populateEmailToSend(this.props.viewModel);
  }

  private convertEmailListToArray(emailString: string) {
    if (emailString === "") {
      return [];
    }
    let emailArray = emailString.split(",").map(email => email.trim());
    return emailArray;
  }

  private populateEmailToSend(viewModel: LeadMessageVM) {
    viewModel.savedEmail.message = this.emailContent
    viewModel.savedEmail.subject = this.emailSubject
    viewModel.savedEmail.cc = this.convertEmailListToArray(this.emailCC)
    viewModel.savedEmail.bcc = this.convertEmailListToArray(this.emailBcc)
    viewModel.savedEmail.to = this.convertEmailListToArray(this.emailTo)
  }

  componentDidMount(): void {
    const { savedEmail } = textConstants.LocalStorage
    if (localStorage.getItem(savedEmail) !== null) {
      this.populateEmailFromLocalStorage()
    } else {
      this.populateDefaultEmail();
    }

    this.populateEmailToSend(this.props.viewModel);
  }

  componentDidUpdate(prevProps: Readonly<IEmailComponent>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.subject !== prevProps.subject || this.props.leadLookup?.prospect_email !== prevProps.leadLookup?.prospect_email || this.props.content !== prevProps.content) {
      let subject = this.props.subject === undefined ? "" : this.props.subject;

      let initialEmail = this.props.leadLookup ? `${this.props.leadLookup?.prospect_name.first_name} ${this.props.leadLookup?.prospect_name.last_name} (${this.props.leadLookup?.prospect_email})` : "";
      this.storeEmailContent = this.props.content || ""

      this.setState({
        subject: subject,
        initialEmail: initialEmail
      })
    }
  }

  private populateEmailFromLocalStorage() {
    let { savedEmail: savedEmail } = this.props.viewModel
    savedEmail = JSON.parse(localStorage.getItem(textConstants.LocalStorage.savedEmail)!)
    localStorage.removeItem(textConstants.LocalStorage.savedEmail)

    this.setState({
      initialEmail: undefined
    })

    // 'From' has already been set in the VM -> setPageState()
    savedEmail.to.forEach(emailAddress => { this.handleEmails(emailAddress, emailType.EmailTo, true) })
    savedEmail.cc.forEach(cc => { this.handleEmails(cc, emailType.EmailCc, true) })
    savedEmail.bcc.forEach(bcc => { this.handleEmails(bcc, emailType.EmailBcc, true) })

    let isCcVisible = this.emailCC.length > 0
    let isBccVisible = this.emailBcc.length > 0

    this.handleEmailSubject(decodeURIComponent(savedEmail.subject))
    let subject = decodeURIComponent(savedEmail.subject)

    this.setState({
      subject: subject,
      isCcVisible: isCcVisible,
      isBccVisible: isBccVisible,
    })
    this.handleEmailContent(decodeURIComponent(savedEmail.message))
    this.forceUpdate()
  }

  private populateDefaultEmail() {
    let initialEmail = this.props.leadLookup?.secondary_prospect
      ? `${this.props.leadLookup?.secondary_prospect.email}`
      : `${this.props.leadLookup?.prospect_name.first_name} ${this.props.leadLookup?.prospect_name.last_name} (${this.props.leadLookup?.prospect_email})`

    let subject = this.props.subject === undefined ? "" : `${this.props.subject}`;

    this.setState({
      initialEmail: initialEmail,
      subject: subject,
    }, () => {
      this.handleEmailSubject(this.state.subject);
      this.handleEmailContent(this.props.content!);
      this.handleEmails(this.state.initialEmail, emailType.EmailTo, true);
      this.forceUpdate()
    })
  }

  public render() {
    const { viewModel } = this.props

    const customDropdownStyles = {
      control: (provided: any) => ({
        ...provided,
        minHeight: '35px',
        maxHeight: '35px',
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        marginTop: '-8px',
      }),
      indicatorSeparator: (provided: any) => ({
        ...provided,
        maxHeight: '20px'
      })
    };

    return (
      <div className={`newEmail ${viewModel.isMaximized ? "maximize" : viewModel.isMaximized === false ? "minimized" : ""} ${this.state.isCcVisible || this.state.isBccVisible ? "showAdditionalEmails" : ""}`}>
        {/* Header */}
        <div className="row emailHeader">
          <div className="mt-1">{textConstants.LeadEmailCreator.NewMessage}</div>
          <div className="col"></div>

          {viewModel.isMaximized !== false &&
            <div className="minimize clickableCursor" onClick={() => { viewModel.isMaximized = false; }}><Icon.Minus /></div>
          }
          {viewModel.isMaximized === false &&
            <div className="ml-2 mt-1 clickableCursor" onClick={() => { viewModel.isMaximized = null; }}><Icon.Plus /></div>
          }
          {viewModel.isMaximized &&
            <div className="ml-2 mt-1 clickableCursor" onClick={() => { viewModel.isMaximized = null; }}><Icon.Minimize2 size={20} /></div>
          }
          {!viewModel.isMaximized &&
            <div className="ml-2 mt-1 clickableCursor" onClick={() => { viewModel.isMaximized = true }}><Icon.Maximize2 size={20} /></div>
          }
          <div className="ml-2 mt-1 clickableCursor" onClick={() => {
            viewModel.isMessageCreatorOpen = false;
            viewModel.isMaximized = false
          }}><Icon.X /></div>
        </div>

        {viewModel.isMaximized !== false &&
          <div className={`row emailBody`}>

            {/* From */}
            <div className="row emailFrom">
              <div className="col-11 p-0">
                <div className="row align-items-center">
                  <span className="emailFromText"><strong>From:</strong></span>
                  <div className="mr-3 mt-1 mb-1 emailFromDropdown">
                    <Select
                      name="emailAddresses"
                      id="emailAddresses"
                      options={viewModel.emailFromOptions}
                      onChange={selectedItem => viewModel.handleEmailSelection(selectedItem)}
                      value={viewModel.selectedEmailFrom}
                      styles={customDropdownStyles}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Who is the email to */}
            <div className="row emailMessage">
              <div className="col-11 p-0">
                <div className="row align-items-center d-flex flex-nowrap">
                  <div className="mr-3 mt-1 mb-1 d-flex">To:
                    <EmailChip
                      checked={false}
                      initialEmails={this.emailList}
                      color={"primary"}
                      label={""}
                      onAddEmail={(email) => {
                        this.handleEmails(email, emailType.EmailTo, true);
                      }}
                      onRemoveEmail={(email) => {
                        this.handleEmails(email, emailType.EmailTo, false);
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* These cause the Bcc and Cc to become visible */}
              <div className="col-1 p-0 pull-right d-flex justify-content-end">
                {!this.state.isCcVisible && (
                  <a onClick={async () => {
                    this.setState({
                      isCcVisible: true,
                    })
                  }}>Cc</a>
                )}

                {!this.state.isBccVisible && (
                  <a onClick={async () => {
                    this.setState({
                      isBccVisible: true,
                    })
                  }}>Bcc</a>
                )}
              </div>
            </div>

            {/* Cc and Bcc input for the emails */}
            <div className="additionRecipients">
              {this.state.isCcVisible &&
                <div className="emailMessage">
                  <div className="row align-items-center d-flex flex-nowrap">
                    <div className="mr-3 mt-1 mb-1 d-flex">Cc:
                      <EmailChip
                        initialEmails={this.emailListCc}
                        checked={false}
                        color={"primary"}
                        label={""}
                        onAddEmail={(email) => {
                          this.handleEmails(email, emailType.EmailCc, true);
                        }}
                        onRemoveEmail={(email) => {
                          this.handleEmails(email, emailType.EmailCc, false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              }
              {this.state.isBccVisible &&
                <div className="emailMessage">
                  <div className="row align-items-center d-flex flex-nowrap">
                    <div className="mr-3 mt-1 mb-1 d-flex">Bcc:
                      <EmailChip
                        initialEmails={this.emailListBcc}
                        checked={false}
                        color={"primary"}
                        label={""}
                        onAddEmail={(email) => {
                          this.handleEmails(email, emailType.EmailBcc, true);
                        }}
                        onRemoveEmail={(email) => {
                          this.handleEmails(email, emailType.EmailBcc, false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              }
            </div>

            {/* Subject of the email unsaved currently */}
            <div className="emailMessage emailSubject">
              <div className="mr-3">Subject: </div>
              <input id="subject" contentEditable
                className="emailAddress"
                placeholder={textConstants.video.emailSubjectPlaceholder}
                value={this.state.subject}
                onChange={this.handleInputChange}
              />
            </div>

            <EmailContent
              emailContent={this.handleEmailContent}
              value={this.storeEmailContent}
              updatedContent={viewModel.templateText}
              placeholder={viewModel.placeholderText}
              setUpdatedContent={() => viewModel.templateText = undefined} />
          </div>
        }
      </div>
    );
  }
}