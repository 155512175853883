import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class AssignedRole extends ModelBase {

    public assignedRoleId : number = 0;

    public userGroupId : number = 0;

    public resourceId : number = 0;

    public roleCategoryId : number = 0;

    public roleId : number = 0;

    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString() {
        if (this.isNew) {
            return "New assigned role"
        } else {
            return "Assigned Role";
        }
    }
}