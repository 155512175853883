import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class BatchReviewDeleteContactCommand extends ModelBase {

    // Properties
    public batchReviewAccountId: number = 0;

    public batchReviewUploadId: number = 0;

    public BatchReviewAccountContactId: number = 0;

    public clientId: number = 0;

    public isDelete: boolean = true;
}