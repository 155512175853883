import { Neo } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import HtmlParser from "react-html-parser";
import { NotifyUtils } from '@singularsystems/neo-core';
import CRMIntegrationLookup from "../../Models/Client/Query/CRMIntegrationLookup";
import { textConstants } from "../../common/textConstants";
import * as Icon from 'react-feather';
import InfoVideo from "../../Components/InfoVideo/InfoVideo";
import InfoVideoVM from "../../Components/InfoVideoVM";

interface ICRMIntegrationProps {
    crmIntegrationLookup: CRMIntegrationLookup
    viewModel: InfoVideoVM;
}

@observer
class CRMIntegration extends React.Component<ICRMIntegrationProps>{

    constructor(props: ICRMIntegrationProps) {
        super(props);
    }

    private showCopySuccess() {
        const variant = "success"
        NotifyUtils.add(variant,
            textConstants.messageText.saveMessage.TextToClipboard,
            variant as any, 5);
    }

    public render() {
        return (
            <>
                <div> <h2 className='p-0 marginLeft9'>{textConstants.CRMIntegrationSettings}</h2> </div>

                <div className="row">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 p-24">
                        <div>{<InfoVideo viewModel={this.props.viewModel} />}</div>
                    </div>
                    <div className="ClientSettingsView col-12 p-24">
                        {/* Text */}
                        {
                            HtmlParser(this.props.crmIntegrationLookup.instructionsHtml)
                        }
                        <p className="woodPeckerAPIKey">Woodpecker API key provided by ComX:</p>

                        <Neo.FormGroup
                            className="p-0 col-3"
                            display={this.props.crmIntegrationLookup.meta.woodpeckerApiKey}
                            suppressLabel={true}
                            append={
                                <Neo.Button variant="light">
                                    <CopyToClipboard text={this.props.crmIntegrationLookup.woodpeckerApiKey} onCopy={() => { this.showCopySuccess() }}>
                                        <i>
                                            <Icon.Copy size={16} color={'#b3b7b7'} />
                                        </i>
                                    </CopyToClipboard>
                                </Neo.Button>
                            }
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default CRMIntegration