import React from "react";
import { Neo } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import Types from "../../Modules/AppTypes";
import { textConstants } from "../../common/textConstants";
import CampaignEmailSignatureVM from "./CampaignEmailSignatureVM";
import { AppService } from "../../Services/AppService";
import CampaignMessageCommentLookup from "../../Models/CampaignMessage/CampaignMessageCommentLookup";
import CopyToClipboard from "react-copy-to-clipboard";
import { NotifyUtils } from "@singularsystems/neo-core";
import { Tooltip } from "@singularsystems/neo-react/dist/ReactComponents/_Exports";
import * as Icon from "react-feather";
import { setUpdateText } from "../../common/utils";
import RichTextEditor from "Components/RichTextEditor";
import { StyledButton } from "Components/Button/styles";

interface ICampaignEmailSignature {
  viewModel: CampaignEmailSignatureVM;
  back: () => void;
}

@observer
export default class CampaignEmailSignature extends React.Component<ICampaignEmailSignature> {
  private authorisationService = AppService.get(
    Types.Neo.Security.AuthorisationService
  );

  public async allowDeleteCheck(clientCampaignId: number) {
    this.props.viewModel.taskRunner.run(async () => {
      // Checks if a new Target Market may be added
      let isAllowed =
        await this.props.viewModel.campaignEmailSignatureHelper.allowCommentDeletion(
          clientCampaignId
        );

      if (isAllowed) {
        this.props.viewModel.campaignEmailSignatureHelper.openDeleteModal(
          clientCampaignId
        );
      } else {
        // Deny Add
        this.props.viewModel.campaignEmailSignatureHelper.showDeleteDeniedModal =
          true;
      }
    });
  }

  private showCopySuccess() {
    const variant = "success";
    NotifyUtils.add(
      variant,
      textConstants.messageText.saveMessage.TextToClipboard,
      variant as any,
      5
    );
  }

  public render() {
    return (
      <div className="CampaignEmailSignature">
        <React.Fragment>
          {/* Campaign Message card*/}
          {
            <div>
              <div className="row mt-4 p-24">
                <div className="col-12">
                  <h2>{textConstants.generalText.EditEmailSignatureSpaced}</h2>
                </div>
              </div>
            </div>
          }
          <Neo.GridLayout md={2}>
            {/* Campaign Name */}
            <div className="row mt-4 p-24">
              <div className="col-6">
                <Neo.FormGroup
                  disabled={!this.props.viewModel.isComXUser}
                  label={
                    <>
                      Name<span className="validationRed">*</span>
                    </>
                  }
                  noValidate={true}
                  className={
                    (this.props.viewModel.campaignEmailSignatureHelper
                      .validationFirstCheckDone ||
                      this.props.viewModel.campaignEmailSignatureHelper
                        .draftValidationFirstCheckDone) &&
                      this.props.viewModel.campaignEmailSignatureHelper
                        .campaignMessageLookup.campaignName.length === 0
                      ? "validation-border-red mb-0"
                      : "mb-0 CampaignMessageText"
                  }
                  bind={
                    this.props.viewModel.campaignEmailSignatureHelper
                      .campaignMessageLookup.meta.campaignName
                  }
                  onChange={() =>
                  (this.props.viewModel.campaignEmailSignatureHelper.isScreenDirty =
                    true)
                  }
                />
              </div>
            </div>
          </Neo.GridLayout>

          {/*Validation Message*/}
          {(this.props.viewModel.campaignEmailSignatureHelper
            .validationFirstCheckDone ||
            this.props.viewModel.campaignEmailSignatureHelper
              .draftValidationFirstCheckDone) &&
            this.props.viewModel.campaignEmailSignatureHelper
              .campaignMessageLookup.campaignName.length === 0 && (
              <p className={"p-24 validationRed validationFontSize"}>
                Campaign Name must be specified
              </p>
            )}

          {
            <div>
              <Tooltip content="This section has mandatory fields that are missing">
                <i
                  className={
                    this.props.viewModel.campaignEmailSignatureHelper
                      .validationFirstCheckDone &&
                      this.props.viewModel.campaignEmailSignatureHelper
                        .campaignMessageLookup.draftText.length === 0
                      ? "fa fa-exclamation-circle ml-1 validationRed"
                      : ""
                  }
                />
              </Tooltip>
            </div>
          }
          <div>
            <div className="row mt-4 p-24">
              <div className="col-12">
                <div className={"bigTextPadding"}>
                  <strong>
                    {textConstants.generalText.SignatureText1}
                    <span className="validationRed">*</span>
                    <p>{textConstants.generalText.SignatureText2}</p>
                  </strong>
                  <div>
                    <ul>
                      <li>{textConstants.generalText.SignatureText3}</li>
                      <li>{textConstants.generalText.SignatureText4}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="row p-24">
              <div className="col-12">
                <Neo.GridLayout md={2}>
                  <div
                    className={
                      this.props.viewModel.campaignEmailSignatureHelper
                        .validationFirstCheckDone &&
                        this.props.viewModel.campaignEmailSignatureHelper
                          .campaignMessageLookup.draftText.length === 0
                        ? ""
                        : "CustomInputLabel"
                    }
                  >
                    {
                      <span>
                        Example of best practice
                        <Tooltip
                          content="This section has mandatory fields that are missing"
                          inline={true}
                        >
                          <i
                            className={
                              (this.props.viewModel.campaignEmailSignatureHelper
                                .validationFirstCheckDone ||
                                this.props.viewModel
                                  .campaignEmailSignatureHelper
                                  .draftValidationFirstCheckDone) &&
                                this.props.viewModel.campaignEmailSignatureHelper
                                  .campaignMessageLookup.campaignName.length === 0
                                ? "fa fa-exclamation-circle ml-1 validationRed"
                                : ""
                            }
                          />
                        </Tooltip>
                      </span>
                    }
                  </div>

                  <div
                    className={
                      this.props.viewModel.campaignEmailSignatureHelper
                        .validationFirstCheckDone &&
                        this.props.viewModel.campaignEmailSignatureHelper
                          .campaignMessageLookup.campaignMessageId > 0 &&
                        !this.props.viewModel.isComXUser &&
                        this.props.viewModel.campaignEmailSignatureHelper
                          .campaignMessageLookup.messageText.length === 0
                        ? ""
                        : "" + "CustomInputLabel"
                    }
                  >
                    Customize your version
                    {/* Copy to Clipboard button */}
                    <span
                      className={
                        this.props.viewModel.isComXUser
                          ? "formGroupPosition"
                          : "formGroupPosition2"
                      }
                    >
                      {/*dead clipboard displayed*/}
                      {this.props.viewModel.campaignEmailSignatureHelper
                        .isComXUser &&
                        this.props.viewModel.campaignEmailSignatureHelper
                          .campaignMessageLookup.campaignMessageId <= 0 && (
                          <i className="fa fa-clipboard noClick-placeClipboard"></i>
                        )}
                      {/* clickable clipboard displayed */}
                      {this.props.viewModel.campaignEmailSignatureHelper
                        .isComXUser &&
                        this.props.viewModel.campaignEmailSignatureHelper
                          .campaignMessageLookup.campaignMessageId > 0 && (
                          <CopyToClipboard
                            text={
                              this.props.viewModel.campaignEmailSignatureHelper
                                .campaignMessageLookup.messageText
                            }
                            onCopy={() => this.showCopySuccess()}
                          >
                            <i className="fa fa-clipboard placeClipboard"></i>
                          </CopyToClipboard>
                        )}
                    </span>
                  </div>
                </Neo.GridLayout>
              </div>
            </div>
          </div>
          <div className="row p-24">
            <div className="col-12">
              <Neo.GridLayout md={2}>
                {/* Example of best practice */}
                <div>
                  <RichTextEditor
                    initialString={
                      this.props.viewModel.campaignEmailSignatureHelper
                        .campaignMessageLookup.draftText
                    }
                    getHtmlText={(htmlText: string) => {
                      this.props.viewModel.campaignEmailSignatureHelper
                        .campaignMessageLookup.draftText = htmlText;
                    }}
                    disabled={
                      !this.props.viewModel.campaignEmailSignatureHelper
                        .isComXUser
                    }
                    override={
                      ["bold", "italic", "underline", "strikethrough",
                        "monospace"]
                    }
                  />

                  {/* Updated By... */}
                  {this.props.viewModel.campaignEmailSignatureHelper
                    .campaignMessageLookup.draftModifiedOn !== " @ " &&
                    this.props.viewModel.campaignEmailSignatureHelper
                      .campaignMessageLookup.draftModifiedOn !== "" && (
                      <p className={"CustomUpdatedLabel mb-0"}>
                        {setUpdateText(
                          this.props.viewModel.campaignEmailSignatureHelper
                            .campaignMessageLookup.draftModifiedBy
                        )}
                        {
                          this.props.viewModel.campaignEmailSignatureHelper
                            .campaignMessageLookup.draftModifiedBy
                        }{" "}
                        on{" "}
                        {
                          this.props.viewModel.campaignEmailSignatureHelper
                            .campaignMessageLookup.draftModifiedOn
                        }
                      </p>
                    )}
                </div>

                <div>
                  {/* Customize your version */}
                  <RichTextEditor
                    initialString={
                      this.props.viewModel.campaignEmailSignatureHelper
                        .campaignMessageLookup.messageText
                    }
                    getHtmlText={(htmlText: string) => {
                      this.props.viewModel.campaignEmailSignatureHelper
                        .campaignMessageLookup.messageText = htmlText;
                    }}
                    disabled={
                      this.props.viewModel.campaignEmailSignatureHelper
                        .campaignMessageLookup.campaignMessageId === 0
                    }
                    override={
                      ["bold", "italic", "underline", "strikethrough",
                        "monospace"]
                    }
                  />

                  {/* Updated By... */}
                  {this.props.viewModel.campaignEmailSignatureHelper
                    .campaignMessageLookup.messageModifiedOn !== " @ " &&
                    this.props.viewModel.campaignEmailSignatureHelper
                      .campaignMessageLookup.messageModifiedOn !== "" && (
                      <p className={"CustomUpdatedLabel mb-0"}>
                        {setUpdateText(
                          this.props.viewModel.campaignEmailSignatureHelper
                            .campaignMessageLookup.messageModifiedBy
                        )}
                        {
                          this.props.viewModel.campaignEmailSignatureHelper
                            .campaignMessageLookup.messageModifiedBy
                        }{" "}
                        on{" "}
                        {
                          this.props.viewModel.campaignEmailSignatureHelper
                            .campaignMessageLookup.messageModifiedOn
                        }
                      </p>
                    )}
                </div>

                {/*Validation Message COMX user*/}
                <div>
                  {this.props.viewModel.campaignEmailSignatureHelper
                    .validationFirstCheckDone &&
                    this.props.viewModel.campaignEmailSignatureHelper
                      .campaignMessageLookup.draftText.length === 0 && (
                      <p className={"validationRed validationFontSize mb-0"}>
                        ComX Suggestion must be specified{" "}
                      </p>
                    )}
                </div>

                {/*Validation Message External User*/}
                <div>
                  {this.props.viewModel.campaignEmailSignatureHelper
                    .validationFirstCheckDone &&
                    this.props.viewModel.campaignEmailSignatureHelper
                      .campaignMessageLookup.campaignMessageId > 0 &&
                    !this.props.viewModel.isComXUser &&
                    this.props.viewModel.campaignEmailSignatureHelper
                      .campaignMessageLookup.messageText.length === 0 && (
                      <p className={"validationRed validationFontSize mb-0"}>
                        Signature must be specified{" "}
                      </p>
                    )}
                </div>
              </Neo.GridLayout>
            </div>
          </div>
          <div className="row p-24">
            <div className="col-12">
              <div
                className={"CustomInputLabel"}
                hidden={
                  this.props.viewModel.campaignEmailSignatureHelper
                    .campaignMessageLookup.campaignMessageId === 0
                }
              >
                Write a comment
              </div>
              <Neo.GridLayout md={2}>
                {/* Comment Textbox */}
                <Neo.FormGroup
                  className={"custom-scrollbar-expand"}
                  suppressLabel={true}
                  bind={
                    this.props.viewModel.campaignEmailSignatureHelper
                      .campaignMessageLookup.meta.currentComment
                  }
                  input={{ rows: 2 }}
                  placeholder={"Type here"}
                  hidden={
                    this.props.viewModel.campaignEmailSignatureHelper
                      .campaignMessageLookup.campaignMessageId === 0
                  }
                  onChange={() =>
                  (this.props.viewModel.campaignEmailSignatureHelper.isScreenDirty =
                    true)
                  }
                />

                {/* Add Comment */}
              </Neo.GridLayout>
              {this.props.viewModel.campaignEmailSignatureHelper
                .campaignMessageLookup.campaignMessageId > 0 && (
                  <Neo.Button
                    className="btn btn-light"
                    onClick={() =>
                      this.props.viewModel.campaignEmailSignatureHelper.SaveComment()
                    }
                  >
                    {textConstants.buttonText.Comment}
                  </Neo.Button>
                )}

              {/* Comments */}
              <Neo.GridLayout md={2}>
                {this.props.viewModel.campaignEmailSignatureHelper
                  .campaignMessageLookup.campaignMessageId > 0 &&
                  this.props.viewModel.campaignEmailSignatureHelper
                    .commentsLookup !== null &&
                  this.props.viewModel.campaignEmailSignatureHelper
                    .commentsLookup.length > 0 && (
                    <div className="commentContainerStyle">
                      {this.props.viewModel.campaignEmailSignatureHelper.commentsLookup.map(
                        (comment: CampaignMessageCommentLookup, i) => (
                          <div className="commentValue" key={i}>
                            <p className="commentorTimeValue">
                              <span
                                className={
                                  comment.isInternalUser
                                    ? "commentorInternalNameValue"
                                    : "commentorExternalNameValue"
                                }
                              >
                                {comment.commenterName}

                                {/* Delete comment */}
                                {comment.isCurrentUser && (
                                  <button
                                    className="commentDeleteBtn"
                                    onClick={() =>
                                      this.allowDeleteCheck(
                                        comment.campaignMessageCommentId
                                      )
                                    }
                                  >
                                    <i>
                                      <Icon.Trash2 size={16} />
                                    </i>
                                  </button>
                                )}
                              </span>
                              <span>{comment.createdOn}</span>
                            </p>
                            <span className="commentText">
                              {comment.comment}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  )}
              </Neo.GridLayout>
            </div>
          </div>
          <div className="row mt-4 p-0 ml-0">
            <div className="col-12">
              <Neo.GridLayout md={2}>
                {
                  <div className={"row mb-4"}>
                    {/* Save as Draft */}
                    {((this.props.viewModel.campaignEmailSignatureHelper
                      .isComXUser &&
                      (this.props.viewModel.campaignEmailSignatureHelper
                        .campaignMessageLookup.clientCampaignMessageStatusId ===
                        this.props.viewModel.drafCampaignMessageStatusId ||
                        this.props.viewModel.campaignEmailSignatureHelper
                          .campaignMessageLookup
                          .clientCampaignMessageStatusId === 0)) ||
                      !this.props.viewModel.campaignEmailSignatureHelper
                        .isComXUser) && (
                        <Neo.Button
                          variant="light"
                          className="btn btn-light ml-4 mt-auto mb-auto"
                          isOutline
                          onClick={(e: any) => {
                            if (
                              this.props.viewModel.campaignEmailSignatureHelper.validationBeforeSave(
                                false,
                                false
                              )
                            ) {
                              e.preventDefault();
                              this.props.viewModel.campaignEmailSignatureHelper.SaveEmailSignature(
                                () => {
                                  this.props.back();
                                },
                                false,
                                false
                              );
                            }
                          }}
                        >
                          {textConstants.buttonText.SaveAsDraft}
                        </Neo.Button>
                      )}

                    {/* Ready for Client Review */}
                    {this.props.viewModel.campaignEmailSignatureHelper
                      .isComXUser && (
                        <StyledButton
                          className="pull-right ml-4"
                          variant="hasIcon"
                          size="xSmall"
                          onClick={() => {
                            if (
                              this.props.viewModel.campaignEmailSignatureHelper.validationBeforeSave(
                                false,
                                true
                              )
                            ) {
                              this.props.viewModel.campaignEmailSignatureHelper.showClientReviewModal =
                                true;
                            }
                          }}
                        >
                          {textConstants.buttonText.ReadyForClientReview}
                        </StyledButton>
                      )}

                    {/* Publish */}
                    <StyledButton
                      className="pull-right ml-4"
                      variant="hasIcon"
                      size="xSmall"
                      onClick={() => {
                        if (
                          this.props.viewModel.campaignEmailSignatureHelper.validationBeforeSave(
                            true,
                            false
                          )
                        ) {
                          this.props.viewModel.campaignEmailSignatureHelper.showPublishModal =
                            true;
                        }
                      }}
                    >
                      {textConstants.buttonText.Publish}
                    </StyledButton>
                  </div>
                }
              </Neo.GridLayout>
            </div>
          </div>
        </React.Fragment>

        {/* Delete Comment Modal */}
        <Neo.Modal
          title={`Delete confirmation`}
          show={
            this.props.viewModel.campaignEmailSignatureHelper.showDeleteModal
          }
          onClose={() =>
          (this.props.viewModel.campaignEmailSignatureHelper.showDeleteModal =
            false)
          }
          closeButton={{ text: "No", variant: "light" }}
          acceptButton={{
            text: "Yes",
            variant: "secondary",
            onClick: () =>
              this.props.viewModel.campaignEmailSignatureHelper.DeleteComment(),
          }}
        >
          <p>
            Are you sure you want to delete this comment?
            <br />
            You will not be able to undo this action.
          </p>
        </Neo.Modal>

        {/* Delete Denied Modal */}
        <Neo.Modal
          title="Delete Denied"
          show={
            this.props.viewModel.campaignEmailSignatureHelper
              .showDeleteDeniedModal
          }
          onClose={() =>
          (this.props.viewModel.campaignEmailSignatureHelper.showDeleteDeniedModal =
            false)
          }
          closeButton={{ text: "Close", variant: "light" }}
        >
          <p>
            {textConstants.messageText.validationMessage.commentDeleteDenied}
          </p>
        </Neo.Modal>

        {/* Invalid Data Modal */}
        <Neo.Modal
          title={textConstants.titleText.InvalidData}
          show={
            this.props.viewModel.campaignEmailSignatureHelper
              .showInvalidDataModal
          }
          onClose={() =>
          (this.props.viewModel.campaignEmailSignatureHelper.showInvalidDataModal =
            false)
          }
          closeButton={{ text: "Close", variant: "light" }}
        >
          {this.props.viewModel.campaignEmailSignatureHelper.invalidDataMessage
            .split("\n")
            .map((item, i) => {
              return <p key={i}>{item}</p>;
            })}
        </Neo.Modal>

        {/* Client Review Confirmation Modal */}
        <Neo.Modal
          title={
            this.props.viewModel.campaignEmailSignatureHelper
              .showClientReviewModal
              ? `Client review confirmation`
              : `Publish confirmation`
          }
          show={
            this.props.viewModel.campaignEmailSignatureHelper
              .showClientReviewModal ||
            this.props.viewModel.campaignEmailSignatureHelper.showPublishModal
          }
          onClose={() => {
            this.props.viewModel.campaignEmailSignatureHelper.showClientReviewModal =
              false;
            this.props.viewModel.campaignEmailSignatureHelper.showPublishModal =
              false;
          }}
          closeButton={{ text: "No", variant: "light" }}
          acceptButton={{
            text: "Yes",
            variant: "secondary",
            onClick: () => {
              this.props.viewModel.campaignEmailSignatureHelper
                .showClientReviewModal
                ? this.props.viewModel.campaignEmailSignatureHelper.SaveEmailSignature(
                  () => {
                    this.props.back();
                  },
                  false,
                  true
                )
                : this.props.viewModel.campaignEmailSignatureHelper.SaveEmailSignature(
                  () => {
                    this.props.back();
                  },
                  true,
                  false
                );
            },
          }}
        >
          {this.props.viewModel.campaignEmailSignatureHelper
            .showClientReviewModal && (
              <p>
                Are you sure you want to send this for Client Review?
                <br />
              </p>
            )}
          {this.props.viewModel.campaignEmailSignatureHelper
            .showPublishModal && (
              <p>
                Are you sure you want to publish this?
                <br />
              </p>
            )}
        </Neo.Modal>
      </div>
    );
  }
}
