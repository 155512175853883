import { Attributes, ModelBase, NeoModel, Rules, Validation, List } from '@singularsystems/neo-core';
import TargetMarketSubIndustry from '../TargetMarketSubIndustries/TargetMarketSubIndustry';
import TargetMarketManagementFunction from '../Maintenance/TargetMarketManagementFunction';
import TargetMarketManagementRole from '../Maintenance/TargetMarketManagementRole';
import TargetMarketRole from './TargetMarketRole';
import { textConstants } from '../../common/textConstants';
import TargetMarketSubRegion from './TargetMarketSubRegion';
import TargetMarketSpecificRole from './TargetMarketSpecificRole';

@NeoModel
export default class TargetMarket extends ModelBase {

  public targetMarketId: number = 0;

  public clientId: number = 0;

  @Rules.StringLength(500)
  public targetMarketName: string = "";

  public targetMarketTypeId: number = 0;

  @Attributes.Integer()
  public sequenceNumber: number = 0;

  public targetMarketStatusId: number = 0;

  public countryId: number = 0;

  @Attributes.Display("Role")
  public isSeniorityLevelC: boolean = true;

  public isSeniorityLevelHead: boolean = true;

  public isSeniorityLevelManager: boolean = true;

  public employeeMinSizeId: number = 0;

  public employeeMaxSizeId: number = 0;

  @Attributes.Display(textConstants.titleText.ClientReview)
  public isClientReview: boolean = false

  @Attributes.Display("Include Legal Roles")
  public isLegalRoleInclusive: boolean = false

  @Attributes.Display("Do Filler Stage")
  public isFillerStage: boolean = false

  @Attributes.Display("Role Based IRO")
  public isRoleBasedIRO: boolean = false

  public clientSpecificMax: number | null = null

  @Attributes.Display("Campaign Snippet Language")
  public languageCountryId: number | null = null

  public batchUploadNotes: string = ""

  public previousEmployeeMinSizeId: number | null = null
  public previousEmployeeMaxSizeId: number | null = null
  public iroEditStatusId: number = 0;

  public isIROStage: boolean = false;

  @Attributes.Display("Show German Regions")
  public showGermanRegions: boolean = false

  // Child Lists
  public targetMarketSubIndustries = new List(TargetMarketSubIndustry)
  public targetMarketManagementFunctions = new List(TargetMarketManagementFunction)
  public targetMarketManagementRoles = new List(TargetMarketManagementRole)
  public targetMarketRoles = new List(TargetMarketRole) // Other function keywords
  public targetMarketSubRegions = new List(TargetMarketSubRegion)
  public targetMarketSpecificRoles = new List(TargetMarketSpecificRole)

  public isComplete: boolean = false;
  public daysBeforeAutoCompletion: number | null = null;

  public auditModifiedByUsername: string = ""
  public auditModifiedOn: Date = new Date()

  public addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
    rules.failWhen(c => c.targetMarketName === null || c.targetMarketName.trim() === '', 'Please enter a target market name');
    rules.failWhen(c => c.countryId === 0, "Please select a country");
    rules.failWhen(c => c.employeeMinSizeId === 0, "Please select a range");
  }

  public toString(): string {
    if (this.isNew || !this.targetMarketName) {
      return "New Target Market";
    } else {
      return this.targetMarketName;
    }
  }
}