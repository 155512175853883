import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import { NotifyUtils } from '@singularsystems/neo-core';
import CopyToClipboard from 'react-copy-to-clipboard';
import * as Icon from 'react-feather';
import FollowUpTemplateLookup from '../../Models/CampaignMessage/FollowUpTemplateLookup';
import AddEditFollowUpTemplateVM from './AddEditFollowUpTemplateVM';
import FollowUpTemplateCommentLookup from '../../Models/CampaignMessage/FollowUpTemplateCommentLookup';
import { setUpdateText } from '../../common/utils';

interface IFollowUpTemplateCard {
    followUpTemplateLookup: FollowUpTemplateLookup,
    isComXUser: boolean,
    parentViewModel: AddEditFollowUpTemplateVM,
}


@observer
export default class FollowUpTemplateCard extends React.Component<IFollowUpTemplateCard> {

    private showCopySuccess() {
        const variant = "success"
        NotifyUtils.add(variant, textConstants.messageText.saveMessage.TextToClipboard,
            variant as any, 5);
    }


    public render() {

        var viewModel = this.props.parentViewModel;

        return (
            <React.Fragment>
                <div>
                    <div className="row mt-4">
                        <div className="col-2">
                            <h4 className="ExpandHeaderText">Email {this.props.followUpTemplateLookup.ordinal}</h4>
                            {this.props.followUpTemplateLookup.ordinal >= 2 &&
                                <>
                                    <button className="ExpandCollapseBtn"
                                        onClick={() => {

                                            if (viewModel.isExpandedId === this.props.followUpTemplateLookup.ordinal) {
                                                viewModel.isExpandedId = -1
                                            }
                                            else {
                                                viewModel.isExpandedId = this.props.followUpTemplateLookup.ordinal;
                                            }
                                        }}>

                                        {(viewModel.isExpandedId === this.props.followUpTemplateLookup.ordinal) &&
                                            <i>
                                                <Icon.ChevronLeft size={24} />
                                            </i>}

                                        {(viewModel.isExpandedId  !== this.props.followUpTemplateLookup.ordinal) &&
                                            <i>
                                                <Icon.ChevronDown size={24} />
                                            </i>}

                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </div>

                {(this.props.followUpTemplateLookup.ordinal === 1 || (viewModel.isExpandedId === this.props.followUpTemplateLookup.ordinal)) &&
                    <>
                        <Neo.GridLayout md={2}>

                            <div className={(((this.props.parentViewModel.validationFirstCheckDone)
                                && ((this.props.parentViewModel.firstMessageEmpty && this.props.followUpTemplateLookup.ordinal === 1))
                                && this.props.followUpTemplateLookup.draftText.length === 0) ? "" : "") + "CustomInputLabel"}>
                                Example of best practice
                            </div>

                            <div className={((this.props.parentViewModel.validationFirstCheckDone
                                && ((this.props.parentViewModel.firstMessageEmpty && this.props.followUpTemplateLookup.ordinal === 1))
                                && (!this.props.isComXUser && this.props.followUpTemplateLookup.messageText.length === 0)
                                && this.props.parentViewModel.clientFollowUpTemplateId > 0) ? "" : "") + "CustomInputLabel"}>
                                Customize your version
                                {/*dead clipboard displayed*/}
                                {(this.props.isComXUser && this.props.followUpTemplateLookup.followUpTemplateId === 0) &&
                                    <i className="fa fa-clipboard noClick-placeClipboard"></i>
                                }
                                {/* clickable clipboard displayed */}
                                {(this.props.isComXUser && this.props.followUpTemplateLookup.followUpTemplateId > 0) &&
                                    <CopyToClipboard text={this.props.followUpTemplateLookup.messageText} onCopy={() => this.showCopySuccess()}>
                                        <i className="fa fa-clipboard placeClipboard"></i>
                                    </CopyToClipboard>
                                }
                            </div>
                        </Neo.GridLayout>

                        <Neo.GridLayout md={2}>
                            <div>
                                {/* Example of best practice */}
                                <Neo.Textbox
                                    className={(((this.props.parentViewModel.validationFirstCheckDone)
                                        && ((this.props.parentViewModel.firstMessageEmpty && this.props.followUpTemplateLookup.ordinal === 1))
                                        && this.props.followUpTemplateLookup.draftText.length === 0) ?
                                        "validation-border-red " : "") + "EmailTextArea " + (!this.props.isComXUser ? "yourEditsColumn": "comXEditsColumn")}
                                    bind={this.props.followUpTemplateLookup.meta.draftText}
                                    input={{ rows: 20 }}
                                    disabled={!this.props.isComXUser}
                                    onInput={() => { this.props.parentViewModel.isScreenDirty = true }}
                                    placeholder={"Not Used"}
                                />

                                {/* Updated By... */}
                                {this.props.followUpTemplateLookup.draftModifiedOn !== " @ " &&
                                    this.props.followUpTemplateLookup.draftModifiedOn !== "" &&
                                    <p className={"smallText mb-0 CustomUpdatedLabel"}>{setUpdateText(this.props.followUpTemplateLookup.draftModifiedBy)} {this.props.followUpTemplateLookup.draftModifiedBy} on {this.props.followUpTemplateLookup.draftModifiedOn}</p>
                                }
                            </div>

                            <div>
                                {/* Customize your version */}
                                <Neo.Textbox
                                    className={((this.props.parentViewModel.validationFirstCheckDone
                                        && ((this.props.parentViewModel.firstMessageEmpty && this.props.followUpTemplateLookup.ordinal === 1))
                                        && (!this.props.parentViewModel.isComXUser && this.props.followUpTemplateLookup.messageText.length === 0)
                                        && this.props.parentViewModel.clientFollowUpTemplateId > 0) ?
                                        "validation-border-red " : " ") + "EmailTextArea comXEditsColumn"}
                                    bind={this.props.followUpTemplateLookup.meta.messageText}
                                    input={{ rows: 20 }}
                                    disabled={this.props.followUpTemplateLookup.followUpTemplateId === 0 || this.props.followUpTemplateLookup.draftText === ""}
                                    onInput={() => { this.props.parentViewModel.isScreenDirty = true }}
                                    placeholder={"Not Used"}
                                />
                                {/* Updated By... */}
                                {this.props.followUpTemplateLookup.messageModifiedOn !== " @ " &&
                                    this.props.followUpTemplateLookup.messageModifiedOn !== "" &&
                                    <p className={"smallText mb-0 CustomUpdatedLabel"}>{setUpdateText(this.props.followUpTemplateLookup.messageModifiedBy)} {this.props.followUpTemplateLookup.messageModifiedBy} on {this.props.followUpTemplateLookup.messageModifiedOn}</p>
                                }
                            </div>

                            {/*Validation COMX user*/}
                            <div>
                                {this.props.parentViewModel.validationFirstCheckDone &&
                                    this.props.followUpTemplateLookup.ordinal === 1 &&
                                    this.props.parentViewModel.firstMessageEmpty &&
                                    this.props.followUpTemplateLookup.draftText.length === 0 &&
                                    <p className={"validationRed validationFontSize mb-0"}>Must have at least one message specified. </p>
                                }
                            </div>

                            {/*Validation External user*/}
                            <div>
                                {this.props.parentViewModel.validationFirstCheckDone &&
                                    this.props.followUpTemplateLookup.ordinal === 1 &&
                                    this.props.parentViewModel.firstMessageEmpty &&
                                    (!this.props.isComXUser && this.props.followUpTemplateLookup.messageText.length === 0) &&
                                    this.props.parentViewModel.clientFollowUpTemplateId > 0 &&
                                    <p className={"validationRed validationFontSize mb-0"}>Must have at least one message specified. </p>
                                }
                            </div>


                        </Neo.GridLayout>
                        <Neo.GridLayout md={2}>
                            {/*New Comment*/}

                            {/*div used to scroll to once a new comment is made*/}
                            {(this.props.followUpTemplateLookup.followUpTemplateId > 0 && this.props.followUpTemplateLookup.draftText  !== ""
                                || (this.props.followUpTemplateLookup.comments !== null && this.props.followUpTemplateLookup.comments.length > 0)) &&
                                <div>
                                    <div className={"CustomInputLabel"}>
                                        Write a comment
                                    </div>
                                    <Neo.FormGroup
                                        className="newCommentStyling"
                                        suppressLabel={true}
                                        bind={this.props.followUpTemplateLookup.meta.currentComment}
                                        onKeyPress={() => this.props.parentViewModel.isCommentDirty = true}
                                        input={{ rows: 2 }}
                                        placeholder={"Type here"}
                                        hidden={this.props.followUpTemplateLookup.followUpTemplateId === 0
                                            || (this.props.followUpTemplateLookup.draftText === ""
                                                && (this.props.followUpTemplateLookup.comments === null || this.props.followUpTemplateLookup.comments.length === 0))}
                                    />
                                </div>
                            }
                        </Neo.GridLayout>

                        {(this.props.followUpTemplateLookup.followUpTemplateId > 0 && this.props.followUpTemplateLookup.draftText  !== ""
                            || (this.props.followUpTemplateLookup.comments !== null && this.props.followUpTemplateLookup.comments.length > 0)) &&
                            <Neo.Button
                                className="btn btn-light mt-1" onClick={() => this.props.parentViewModel.SaveComment(this.props.followUpTemplateLookup.followUpTemplateId, this.props.followUpTemplateLookup.currentComment)}
                            >
                                Comment
                            </Neo.Button>
                        }

                        {/* Comments */}
                        {this.props.followUpTemplateLookup.followUpTemplateId > 0 && this.props.followUpTemplateLookup.comments !== null && this.props.followUpTemplateLookup.comments.length  !== 0 &&
                            <Neo.GridLayout md={2}>
                                <div className="commentContainerStyle">
                                    {
                                        this.props.followUpTemplateLookup.comments.map((comment: FollowUpTemplateCommentLookup, i) =>
                                            <div className="commentValue" key={i}>
                                                <p className="commentorTimeValue">
                                                    <span className={comment.isInternalUser ? "commentorInternalNameValue" : "commentorExternalNameValue"}>
                                                        {comment.commenterName}
                                                    </span> {comment.createdOn}

                                                    {/* Delete comment */}
                                                    {comment.isCurrentUser && <button className="commentDeleteBtn"
                                                        onClick={() => this.props.parentViewModel.AllowDeleteCheck(comment.followUpTemplateCommentId, this.props.followUpTemplateLookup.followUpTemplateId)}>
                                                        <i><Icon.Trash2 size={16} /></i>
                                                    </button>}
                                                </p>
                                                <span className="commentText">
                                                    {comment.comment}
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                            </Neo.GridLayout>

                        }

                    </>}
                {/*   </CollapsibleCard> */}
            </React.Fragment>
        );
    }
}