import {NeoModel, ValueObject, Rules} from '@singularsystems/neo-core';

@NeoModel
export default class BatchReviewUploadCriteria extends ValueObject{

  @Rules.Required()
  public clientId: number = 0;

  public searchString: string = "";

  public isComXUser: boolean = false;

  public isDeleted : boolean = false;
}