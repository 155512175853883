import React from "react";
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import FollowUpTemplatesVM from "./FollowUpTemplatesVM";
import { observer } from "mobx-react";
import { textConstants } from "../../common/textConstants";
import * as Roles from "../../Models/Security/Roles";
import ClientFollowUpTemplateLookup from "../../Models/CampaignMessage/ClientFollowUpTemplateLookup";
import { AppService, Types } from "../../Services/AppService";
import * as Icon from "react-feather";
import CustomPagingControl from "../../Components/CustomPagingControl";
import CopyToClipboard from "react-copy-to-clipboard";
import { NotifyUtils } from "@singularsystems/neo-core";
import SearchField from "../../Components/SearchField";
import ClientNavigationButtonsVM from "../../Components/ClientNavigationButtonsVM";
import ClientNavigationButtons from "../../Components/ClientNavigationButtons";
import InfoBox from "../../Components/InfoBox";
import OpenFollowUpTemplate from "../../Components/OpenFollowUpTemplate";
import {
  StyledButton,
  StyledButtonGroupWrapper,
  StyledButtonWrapper,
} from "Components/Button/styles";
import DataTooltip from "Components/DataTooltip";
import Button from "Components/Button/Button";

interface IFollowUpTemplateProps {
  viewModel: FollowUpTemplatesVM;
  navigation: ClientNavigationButtonsVM;
  back: () => void;
  openFollowUpTemplate: () => void;
  openEditFollowUpTemplate: (
    clientFollowUpTemplateId: number,
    clientCampaignMessageStatusId: number
  ) => void;
}

@observer
export default class FollowUpTemplatesView extends React.Component<IFollowUpTemplateProps> {
  private authorisationService = AppService.get(
    Types.Neo.Security.AuthorisationService
  );
  protected authenticationService = AppService.get(
    Types.Security.CustomAuthenticationService
  );

  public displaySearch() {
    const { viewModel } = this.props;

    const condition1 =
      viewModel.isArchived && viewModel.pageManager.totalRecords === 0;
    const condition2 = viewModel.pageManager.totalRecords > 0;
    const condition3 = viewModel.pageManager.hasFetched;
    return (
      (condition1 || condition2 || !viewModel.displayOverlay) && condition3
    );
  }

  private showCopySuccess() {
    let variant = "success";
    NotifyUtils.add(
      variant,
      textConstants.messageText.saveMessage.TextToClipboard,
      variant as any,
      5
    );
  }

  public securityCheck(role: any, openMethod: () => void) {
    if (this.authorisationService.hasRole(role)) {
      openMethod();
    } else {
      this.props.viewModel.showForbiddenModal = true;
    }
  }

  public async undoFollowUpTemplateDeletion(
    campaignMessageLookup: ClientFollowUpTemplateLookup
  ) {
    this.securityCheck(Roles.TargetMarkets.Manage, () =>
      this.props.viewModel.openUndoDeleteModal(campaignMessageLookup)
    );
  }

  public addFollowUpTemplate() {
    this.props.openFollowUpTemplate();
  }

  public async editFollowUpTemplate(
    clientFollowUpTemplateId: number,
    clientCampaignMessageStatusId: number
  ) {
    this.props.openEditFollowUpTemplate(
      clientFollowUpTemplateId,
      clientCampaignMessageStatusId
    );
  }

  public render() {
    const { viewModel } = this.props;
    const { isComXUser, isArchived, searchFieldVM } = viewModel;

    return (
      <>
        <div className="ClientCampaignsView negSideMargins24">
          <div className="row m-0">
            {
              <div className="col-6 p-24 justify-content-left">
                {isComXUser && (
                  <ClientNavigationButtons
                    viewModel={this.props.navigation}
                    back={this.props.back}
                  />
                )}
              </div>
            }
          </div>
          <div className="row m-0">
            <div className="archiveTabHeader col-3 pull-right p-24">
              <Neo.TabContainer className="mt-2 mb-2 m-0" variant="flat">
                <Neo.Tab
                  header="Current"
                  onDisplayed={() => {
                    viewModel.searchCriteria.isDeleted = false;
                    viewModel.isArchived = false;
                    viewModel.isActiveNameEditFollowUpTemplateId = 0;
                  }}
                ><></></Neo.Tab>
                <Neo.Tab
                  header="Archived"
                  onDisplayed={() => {
                    viewModel.searchCriteria.isDeleted = true;
                    viewModel.isArchived = true;
                    viewModel.isActiveNameEditFollowUpTemplateId = 0;
                  }}
                ><></></Neo.Tab>
              </Neo.TabContainer>
            </div>
            <div className="col-9 p-24">
              {/* Add Template */}
              {isComXUser && this.authorisationService.hasRole(Roles.CampaignMessages.AddEditCampaignMessages) && (
                <Button
                  buttonType="primary"
                  className="pull-right ml-4 mt-0 mb-1"
                  variant="hasIcon"
                  size="xSmall"
                  onClick={() => this.addFollowUpTemplate()}
                >
                  <Icon.Plus />
                  {textConstants.buttonText.AddTemplate}
                </Button>
              )}
              {this.displaySearch() && ( // this only hides the search if its not archived
                <SearchField
                  onClickFunction={(e) => {
                    viewModel.pageManager.refreshData();
                    searchFieldVM.hideNoResultDialog = true;
                  }}
                  searchBindableProp={
                    viewModel.searchCriteria.meta.searchString
                  }
                  disabled={
                    viewModel.pageManager.totalRecords === 0 &&
                    viewModel.isArchived
                  }
                  searchString={viewModel.searchCriteria.searchString}
                  searchFieldVM={searchFieldVM}
                />
              )}
            </div>
          </div>

          <div className="row m-0">
            <div className="col-12 p-0">
              {viewModel.pageManager.totalRecords === 0 &&
                viewModel.hideGrid === false && (
                  <>
                    {searchFieldVM.isSearching && (
                      <InfoBox
                        header={textConstants.titleText.NoSearchMatches}
                        text={textConstants.generalText.NoSearchMatches}
                      />
                    )}

                    {isComXUser &&
                      !isArchived &&
                      !searchFieldVM.isSearching &&
                      !searchFieldVM.hideNoResultDialog && (
                        <div className="ClientCampaignsView">
                          <InfoBox
                            header={textConstants.titleText.NoFollowUpTemplates}
                            text={
                              textConstants.generalText.NoFollowUpTemplateText
                            }
                          />
                        </div>
                      )}

                    {!isComXUser &&
                      !isArchived &&
                      !searchFieldVM.isSearching &&
                      !searchFieldVM.hideNoResultDialog && (
                        <div className="ClientCampaignsView">
                          <InfoBox
                            header={
                              textConstants.titleText
                                .NoFollowUpTemplatesExternal
                            }
                            text={
                              textConstants.generalText
                                .NoFollowUpTemplateTextExternal
                            }
                          />
                        </div>
                      )}

                    {isArchived &&
                      !searchFieldVM.isSearching &&
                      !searchFieldVM.hideNoResultDialog && (
                        <div className="ClientCampaignsView">
                          <InfoBox
                            header={
                              textConstants.titleText
                                .NoArchivedFollowUpTemplates
                            }
                            text={
                              textConstants.generalText
                                .NoArchiveFollowUpTemplateText
                            }
                          />
                        </div>
                      )}
                  </>
                )}

              {viewModel.pageManager.totalRecords > 0 &&
                viewModel.hideGrid === false && (
                  <div className="row m-0">
                    <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                      <React.Fragment>
                        <Neo.Pager
                          pageManager={viewModel.pageManager}
                          pageControls="none"
                        >
                          {(data) => (
                            <NeoGrid.Grid<ClientFollowUpTemplateLookup>
                              isBordered={false}
                              items={viewModel.pageManager}
                            >
                              {(ClientFollowUpTemplateLookup, meta) => (
                                <NeoGrid.Row>
                                  {/* Name */}
                                  <NeoGrid.Column
                                    label={textConstants.titleText.Name}
                                    sort={meta.followUpTemplateName}
                                  >
                                    <div
                                      className={viewModel.isArchived ? "" : "clickableCursor"}
                                      onClick={async () => {
                                        if (
                                          !viewModel.isArchived &&
                                          !viewModel.isNameEditing(
                                            ClientFollowUpTemplateLookup.clientFollowUpTemplateId
                                          ) &&
                                          this.authorisationService.hasRole(
                                            Roles.CampaignMessages
                                              .AddEditCampaignMessages
                                          )
                                        ) {
                                          await viewModel.openOpenTemplateModal(
                                            ClientFollowUpTemplateLookup.clientFollowUpTemplateId,
                                            ClientFollowUpTemplateLookup.campaignMessageStatusId
                                          );
                                        }
                                      }}
                                    >
                                      {
                                        ClientFollowUpTemplateLookup.followUpTemplateName
                                      }
                                    </div>
                                  </NeoGrid.Column>
                                  {/* Status */}
                                  {!viewModel.isArchived && (
                                    <NeoGrid.Column
                                      className={"TMVColumnWidthName"}
                                      label={textConstants.titleText.Status}
                                      sort={
                                        ClientFollowUpTemplateLookup.meta
                                          .campaignMessageStatus
                                      }
                                    >
                                      <>
                                        <div className="pl-0 pr-0">
                                          <span>
                                            {
                                              ClientFollowUpTemplateLookup.campaignMessageStatus
                                            }
                                          </span>
                                        </div>
                                      </>
                                    </NeoGrid.Column>
                                  )}

                                  {/* Summary  */}
                                  <NeoGrid.Column
                                    className="TMVColumnWidthName"
                                    label={textConstants.titleText.Summary}
                                  >
                                    <>
                                      <div className="pl-0 pr-0">
                                        <span>
                                          {ClientFollowUpTemplateLookup.summary}
                                        </span>
                                      </div>
                                    </>
                                  </NeoGrid.Column>

                                  {/* Category  width={230} */}
                                  <NeoGrid.Column
                                    label={
                                      textConstants.titleText.ActionListStatus
                                    }
                                  >
                                    <>
                                      <div className="pl-0 pr-0">
                                        <span>
                                          {
                                            viewModel.filterList.find(
                                              (x) =>
                                                x.leadTypeStatusId ===
                                                ClientFollowUpTemplateLookup.leadTypeStatusId
                                            )?.leadTypeStatusName
                                          }
                                        </span>
                                      </div>
                                    </>
                                  </NeoGrid.Column>

                                  {/* Actions */}
                                  <NeoGrid.ButtonColumn className="text-center pl-0 pr-0 pb-0 pt-0">
                                    <StyledButtonWrapper>
                                      {!viewModel.isArchived && (
                                        <StyledButtonGroupWrapper>
                                          {/* Edit */}
                                          {this.authorisationService.hasRole(
                                            Roles.CampaignMessages
                                              .AddEditCampaignMessages
                                          ) && (
                                              <DataTooltip
                                                parent={
                                                  <StyledButton
                                                    isIconButton={true}
                                                    onClick={() => {
                                                      this.editFollowUpTemplate(
                                                        ClientFollowUpTemplateLookup.clientFollowUpTemplateId,
                                                        ClientFollowUpTemplateLookup.campaignMessageStatusId
                                                      );
                                                    }}
                                                  >
                                                    <Icon.Edit size={16} />
                                                  </StyledButton>
                                                }>
                                                {textConstants.buttonText.Edit}
                                              </DataTooltip>
                                            )}

                                          {/* Open */}
                                          {this.authorisationService.hasRole(
                                            Roles.CampaignMessages
                                              .AddEditCampaignMessages
                                          ) && (
                                              <DataTooltip
                                                parent={
                                                  <StyledButton
                                                    isIconButton={true}
                                                    onClick={async () => {
                                                      await viewModel.openOpenTemplateModal(
                                                        ClientFollowUpTemplateLookup.clientFollowUpTemplateId,
                                                        ClientFollowUpTemplateLookup.campaignMessageStatusId
                                                      );
                                                    }}
                                                  >
                                                    <Icon.Folder size={16} />
                                                  </StyledButton>
                                                }>
                                                {textConstants.buttonText.Open}
                                              </DataTooltip>
                                            )}

                                          {/* Clone */}
                                          {this.authorisationService.hasRole(
                                            Roles.CampaignMessages.Clone
                                          ) && (
                                              <DataTooltip
                                                parent={
                                                  <StyledButton
                                                    isIconButton={true}
                                                    onClick={async () => {
                                                      await viewModel.openCloneModal(
                                                        ClientFollowUpTemplateLookup.clientFollowUpTemplateId
                                                      );
                                                    }}
                                                  >
                                                    <Icon.Copy size={16} />
                                                  </StyledButton>
                                                }>
                                                {textConstants.buttonText.Clone}
                                              </DataTooltip>
                                            )}
                                        </StyledButtonGroupWrapper>
                                      )}

                                      {/* Delete */}
                                      {!viewModel.isArchived && (
                                        <DataTooltip
                                          parent={
                                            <StyledButton
                                              isIconButton={true}
                                              onClick={() => {
                                                viewModel.openDeleteModal(
                                                  ClientFollowUpTemplateLookup
                                                );
                                              }}
                                            >
                                              <Icon.Archive size={16} />
                                            </StyledButton>
                                          }>
                                          {textConstants.buttonText.Archive}
                                        </DataTooltip>
                                      )}

                                      {/* Unarchive */}
                                      {viewModel.isArchived && (
                                        <DataTooltip
                                          parent={
                                            <StyledButton
                                              isIconButton={true}
                                              onClick={() => {
                                                this.undoFollowUpTemplateDeletion(
                                                  ClientFollowUpTemplateLookup
                                                );
                                              }}
                                            >
                                              <Icon.RotateCw size={16} />
                                            </StyledButton>
                                          }>
                                          {textConstants.buttonText.Unarchive}
                                        </DataTooltip>
                                      )}
                                    </StyledButtonWrapper>
                                  </NeoGrid.ButtonColumn>
                                </NeoGrid.Row>
                              )}
                            </NeoGrid.Grid>
                          )}
                        </Neo.Pager>

                        <div className="col-12 p-24">
                          <CustomPagingControl
                            displayLabel={true}
                            pageManager={viewModel.pageManager}
                          />
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>

        {/* Open Follow Up Template Modal */}
        <Neo.Modal
          title=""
          show={viewModel.showOpenTemplateModal}
          className="mt-4 openUseTemplateModal"
          footer={
            <div className="row p-0 m-0 col-12">
              <div className="col-4 openTemplateVersionDiv"></div>
              <div className="col-4">
                <Neo.Button
                  variant="light"
                  onClick={() => {
                    viewModel.addEditFollowUpTemplateVM.selectedModalOpenTemplateDraftText =
                      "";
                    viewModel.showOpenTemplateModal = false;
                    this.editFollowUpTemplate(
                      viewModel.addEditFollowUpTemplateVM
                        .clientFollowUpTemplateId,
                      viewModel.addEditFollowUpTemplateVM.campaignStatusId
                    );
                  }}
                  className="btn form-btn ml-0"
                >
                  Edit
                </Neo.Button>
              </div>

              <div className="col-4">
                <CopyToClipboard
                  text={
                    viewModel.addEditFollowUpTemplateVM
                      .selectedModalOpenTemplateDraftText
                  }
                  onCopy={() => this.showCopySuccess()}
                >
                  <Neo.Button variant="secondary" className="btn form-btn ml-0">
                    Copy
                  </Neo.Button>
                </CopyToClipboard>
              </div>
            </div>
          }
          onClose={() => {
            viewModel.addEditFollowUpTemplateVM.selectedModalOpenTemplateDraftText =
              "";
            viewModel.showOpenTemplateModal = false;
          }}
        >
          <div className="row">
            {/* Side menu containter */}
            <div className="col-4 pt-3 pl-0 pr-0 openTemplateVersionDiv">
              <div className="row pl-3 pb-3 m-0">
                <h3 className="pt-0">
                  {viewModel.addEditFollowUpTemplateVM.followUpTemplateName}
                </h3>
              </div>
              <div className="row m-0 pl-3 modalBorderBottom">
                <p>
                  <strong>Summary: </strong>
                  {viewModel.addEditFollowUpTemplateVM.summary}
                </p>
              </div>

              {viewModel.addEditFollowUpTemplateVM.followUpTemplates!.map(
                (fuTemplate) => (
                  <OpenFollowUpTemplate
                    key={fuTemplate.ordinal}
                    viewModel={viewModel.addEditFollowUpTemplateVM}
                    followUpTemplateLookup={fuTemplate}
                  />
                )
              )}
            </div>

            {/* Main email containter */}
            <div className="col-8 pt-3">
              <div className="row m-0 p-0 col-12">
                <div className="col-2 p-0">
                  <h3 className="pt-0">
                    Email{" "}
                    {
                      viewModel.addEditFollowUpTemplateVM
                        .selectedModalOpenTemplate
                    }
                  </h3>
                </div>
                <div className="col-10 p-0">
                  <div
                    onClick={() => {
                      viewModel.addEditFollowUpTemplateVM.selectedModalOpenTemplateDraftText =
                        "";
                      viewModel.showOpenTemplateModal = false;
                    }}
                    className="openTemplateModalX pull-right"
                  >
                    x
                  </div>
                </div>
              </div>

              <div>
                Created by{" "}
                <strong>{viewModel.addEditFollowUpTemplateVM.createdBy}</strong>
              </div>
              {/* div for the template email contents*/}
              <div className="mt-4">
                {viewModel.addEditFollowUpTemplateVM
                  .selectedModalOpenTemplateDraftText !== "" && (
                    <Neo.Textbox
                      bind={
                        viewModel.addEditFollowUpTemplateVM.meta
                          .selectedModalOpenTemplateDraftText
                      }
                      disabled={true}
                      className={"EmailTextArea p-0"}
                      input={{ rows: 80 }}
                    />
                  )}

                {viewModel.addEditFollowUpTemplateVM
                  .selectedModalOpenTemplateDraftText === "" && (
                    <div className="noTemplateMessage">
                      <Icon.AlertCircle />{" "}
                      <i>{textConstants.generalText.NoFollowUpTemplateMessage}</i>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </Neo.Modal>

        {/* Clone Follow Up Template Modal*/}
        <Neo.Modal
          title="Clone Follow Up Template"
          show={viewModel.showCloneModal}
          formProps={{ model: viewModel.cloneClientFollowUpTemplateCommand }}
          onClose={() => (viewModel.showCloneModal = false)}
          closeButton={{ text: "Cancel", variant: "light" }}
          acceptButton={{
            text: isComXUser ? textConstants.buttonText.Submit : textConstants.buttonText.SaveAsActive,
            variant: "secondary",
            onClick: () => viewModel.cloneFollowUpTemplate(),
          }}
        >
          <Neo.GridLayout md={1}>
            <Neo.FormGroup
              bind={
                viewModel.cloneClientFollowUpTemplateCommand.meta
                  .clientFollowUpTemplateName
              }
              label="Clone Name"
              placeholder={textConstants.generalText.placeHolderTypeHereText}
            />
          </Neo.GridLayout>
        </Neo.Modal>

        {/* Delete Follow Up Template Modal */}
        <Neo.Modal
          title={`Delete confirmation`}
          show={viewModel.showDeleteModal}
          onClose={() => (viewModel.showDeleteModal = false)}
          closeButton={{ text: "No", variant: "light" }}
          acceptButton={{
            text: "Yes",
            variant: "secondary",
            onClick: () => viewModel.deleteFollowUpTemplates(),
          }}
        >
          Follow Up Template &#34;{viewModel.selectedFollowUpTemplateName}&#34; is about
          to be archived. Do you wish to continue?
        </Neo.Modal>

        {/* Undo Follow Up Template Delete Modal */}
        <Neo.Modal
          title={`Undo Archive Confirmation`}
          show={viewModel.showUndoDeleteModal}
          onClose={() => (viewModel.showUndoDeleteModal = false)}
          closeButton={{ text: "No", variant: "light" }}
          acceptButton={{
            text: "Yes",
            variant: "secondary",
            onClick: () => viewModel.undoDeleteFollowUpTemplate(),
          }}
        >
          {textConstants.titleText.FollowUpTemplates} &#34;
          {viewModel.selectedFollowUpTemplateName}&#34;{" "}
          {textConstants.generalText.toUnarchiveModal}
        </Neo.Modal>

        {/* Forbidden */}
        <Neo.Modal
          title="Forbidden"
          show={viewModel.showForbiddenModal}
          onClose={() => (viewModel.showForbiddenModal = false)}
          closeButton={{ text: "Cancel", variant: "light" }}
        >
          <p>{textConstants.generalText.insufficientPrivileges}</p>
        </Neo.Modal>
      </>
    );
  }
}
