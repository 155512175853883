import { ModelBase, NeoModel } from '@singularsystems/neo-core';
import ProspectName from './ProspectName';
import ClusterInfo from './ClusterInfo';
import NextReminder from 'Models/Reminder/NextReminder';
import SecondaryProspect from './SecondaryProspect';

@NeoModel
export default class ActionListLookupData extends ModelBase {
    public reply_id: string = ""

    public prospect_id: number = 0

    public campaign_id: number = 0

    public prospect_name: ProspectName = new ProspectName()

    public prospect_email: string = "";

    public campaign_email: string = "";

    public campaign_name: string = "";

    public cluster_info: ClusterInfo = new ClusterInfo()

    public delivered_at: string = ""

    public clustered_at: string = ""

    public read: boolean = true

    public statusChangeDate: string = ""

    public sentToName: string = "";

    public isSelected: boolean = false;

    public responded_at: string = "";
    
    public next_reminder: NextReminder = new NextReminder();

    public secondary_prospect: SecondaryProspect = new SecondaryProspect();
}