import { observer } from 'mobx-react';
import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import { ValidationDisplayMode } from '@singularsystems/neo-core/dist/Validation/Misc';
import AddClientVM from './AddClientVM';
import { AppService, Types } from '../../Services/AppService';
import * as Icon from 'react-feather';
import InfoLabelHover from '../TargetMarket/elements/Label.Hover';

interface IAddClientProps {
    viewModel: AddClientVM,
    back: () => void
}

@observer
export default class AddClientView extends React.Component<IAddClientProps> {

    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

    public securityCheck(role: any, openMethod: () => void) {
        if (this.authorisationService.hasRole(role)) {
            openMethod();
        }
        else {
            this.props.viewModel.showForbiddenModal = true;
        }
    }

    public render() {
        const tooltipForBlacklist = <div>{this.props.viewModel.doNotBlacklistTooltip}</div>;

        return (
            <React.Fragment> {/* Back */}

                <div className='ClientSettingsView'>
                    <div className="row m-0">
                        <div className='col-12 p-24'>
                            <Neo.Button variant="light" className="mt-3 backButton"
                                icon={<Icon.ArrowLeft />}
                                onClick={() => { this.props.back() }}>
                                <div>{textConstants.buttonText.Back}</div>
                            </Neo.Button>
                        </div>
                    </div>

                    <Neo.Card>
                        <div className='AddClient'>
                            <div className='row m-0'>
                                <div className='col-4 p-24'>
                                    <h2 className='pt-0 mt-4'>Add Client</h2>
                                </div>
                            </div>

                            < Neo.Form
                                className='p-24 pr-0'
                                validationDisplayMode={ValidationDisplayMode.Always}
                                onSubmit={() => { this.props.viewModel.save(() => { this.props.back() }) }}
                                model={this.props.viewModel.clientDetails}
                            >
                                {/* Client Settings card */}
                                <div>
                                    {/* Client Settings card */}
                                    <Neo.GridLayout md={2} className={"validationText mb-2 mr-0 ml-0 mt-0"}>
                                        {/* Client Name */}
                                        <Neo.FormGroup
                                            placeholder={textConstants.generalText.placeHolderTypeHereText}
                                            bind={this.props.viewModel.clientDetails.meta.clientName} className="mb-2 p-0" />

                                        {/* Client Review */}
                                        <div className='row m-0 p-0 blacklistSVG'>
                                            <Neo.FormGroup
                                                placeholder={textConstants.generalText.placeHolderTypeHereText}
                                                bind={this.props.viewModel.clientDetails.meta.isClientReview} className="mt-4 p-0"
                                                input={{ type: "checkbox" }}
                                            />
                                            {/* Do not blacklist other prospects after reply */}
                                            <Neo.FormGroup
                                                placeholder={textConstants.generalText.placeHolderTypeHereText}
                                                bind={this.props.viewModel.clientDetails.meta.doNotBlacklistWoodpecker}
                                                className="mt-4 ml-5 p-0"
                                                input={{ type: "checkbox" }}
                                            />
                                            <InfoLabelHover title={""}>{tooltipForBlacklist}</InfoLabelHover>
                                        </div>

                                        {/* Platform Setup */}
                                        <Neo.FormGroup
                                            placeholder={textConstants.generalText.placeHolderTypeHereText}
                                            bind={this.props.viewModel.clientDetails.meta.platformSetup} className="mb-3 p-0" />

                                        {/* Success Tracking */}
                                        <Neo.FormGroup
                                            placeholder={textConstants.generalText.placeHolderTypeHereText}
                                            bind={this.props.viewModel.clientDetails.meta.successTracking} className="mb-3 p-0" />

                                        {/* Max Target Markets*/}
                                        <Neo.FormGroup bind={this.props.viewModel.clientDetails.meta.maxTargetMarkets} className="mb-3 p-0" />

                                        {/* Woodpecker API*/}
                                        <Neo.FormGroup
                                            placeholder={textConstants.generalText.placeHolderTypeHereText}
                                            bind={this.props.viewModel.clientDetails.meta.woodpeckerApiKey} className="mb-3 p-0" />

                                        {/*Action Plan Checklist*/}
                                        <Neo.FormGroup
                                            placeholder={textConstants.generalText.placeHolderTypeHereText}
                                            bind={this.props.viewModel.clientDetails.meta.actionPlanChecklist} className="mb-3 p-0" />

                                        {/*Customer Success Manager*/}
                                        <Neo.FormGroup bind={this.props.viewModel.clientDetails.meta.calendlyLinkId}
                                            placeholder={textConstants.generalText.placeHolderTypeHereText}
                                            select={{ items: this.props.viewModel.calendlyLinks }}
                                            onChange={() => {
                                                this.props.viewModel.clientDetails.isSelfDirty = true;
                                            }}
                                        />
                                    </Neo.GridLayout>
                                </div>

                                {/* Save */}
                                <div className="row m-0">
                                    <div className="col-lg-12">
                                        <Neo.Button
                                            className="mt-2 mb-2 pull-right"
                                            variant='secondary'
                                            isSubmit
                                        >
                                            {textConstants.generalText.Save}
                                        </Neo.Button>
                                    </div>
                                </div>

                            </Neo.Form>

                            <Neo.Modal title={textConstants.titleText.InvalidData} show={this.props.viewModel.showInvalidDataModal}
                                onClose={() => this.props.viewModel.showInvalidDataModal = false}
                                closeButton={{ text: textConstants.generalText.Close, variant: "light" }}
                            >
                                {this.props.viewModel.invalidDataMessage.split('\n').map((item, i) => {
                                    return <p key={i}>{item}</p>
                                })}
                            </Neo.Modal>
                        </div>
                    </Neo.Card>
                </div>
            </React.Fragment>
        )
    }
}