import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import NavigatorStep from 'Models/Navigation/NavigatorStep';
import OnboardingMaintenance from 'Models/Maintenance/OnboardingMaintenance';
import { textConstants } from 'common/textConstants';
import OnboardingMeetingLookup from 'Models/Onboarding/OnboardingMeetingLookup';
import { OnboardingSteps } from 'Models/Enums/ApplicationEnums';

@NeoModel
export default class MeetingsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient),
        private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
        private customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
        private onboardingMaintenance = AppService.get(Types.ApiClients.OnboardingMaintenanceApiClient),
        private comXHub = AppService.get(Types.ApiClients.ComXHub)) {

        super(taskRunner);
        this.connectToComXHub();
    }

    public clientId: number = 0
    public clientCalendlyUrl: string = ""
    public navigatorSteps: NavigatorStep[] = [];
    public headerText = ""
    public bodyText = ""
    public onboardingMaintenanceList = new List(OnboardingMaintenance);
    public maintenanceData: OnboardingMaintenance = new OnboardingMaintenance()
    public meetingLookup: OnboardingMeetingLookup = new OnboardingMeetingLookup()
    public showCalendly: boolean = true
    public showSpinner: boolean = false

    public async initialise() {
        this.clientId = this.customAuthService.globalProps.clientId
        await this.taskRunner.run(async () => {
            let calendlyResponse = await this.clientsApiClient.getClientCalendlyLink(this.clientId)

            if (calendlyResponse.data) {
                this.clientCalendlyUrl = calendlyResponse.data
            }

            await this.populateNavigatorStep()
            await this.populateMaintenanceData()
            await this.populateMeetingLookup()
        })
    }

    public connectToComXHub() {
        this.comXHub.UpdateCalendlyMeeting.subscribe(this.updateMeeting);
    }

    public updateMeeting = (meeting: OnboardingMeetingLookup) => {
        const { globalProps } = this.customAuthService

        if (meeting.clientId === this.clientId) {
            globalProps.isMeetingBooked = true

            let bookAMeetingStep = globalProps.onboardingSteps.find(x => x.stepNumber === OnboardingSteps.BookAMeeting)!
            bookAMeetingStep.isComplete = true;

            this.meetingLookup = meeting
        }
    }

    public async populateNavigatorStep() {
        let newStep: NavigatorStep = {
            id: 1,
            stepNumber: 1,
            stepText: "Schedule a meeting",
            isComplete: false,
        }

        this.navigatorSteps.push(newStep);
    }

    public async populateMaintenanceData() {
        this.onboardingMaintenanceList.set((await this.onboardingMaintenance.get()).data)
        this.maintenanceData = this.onboardingMaintenanceList.find(item => item.pageCode === textConstants.Onboarding.ScheduleMeeting)!
    }

    public async populateMeetingLookup() {

        let response = await this.onboardingApiClient.getOnboardingMeeting(this.clientId)
        if (response.data.success) {
            this.meetingLookup = response.data.data
        }
    }

    public async updateMeetingDetails(data: any, navigate: () => void, recursionCounter: number = 0) {

        if (data.event === 'calendly.event_scheduled') {

            this.showCalendly = false
            this.showSpinner = true

            setTimeout(async () => {
                if (recursionCounter === 60) {
                    this.notifications.addDanger("Error", textConstants.Onboarding.MeetingStatusUnknown)
                    return
                }

                if (this.meetingLookup.isMeetingScheduled) {
                    recursionCounter = 60
                    this.showSpinner = false
                    navigate()
                } else {
                    recursionCounter++
                    this.updateMeetingDetails(data, navigate, recursionCounter)
                }
            }, 500);
        }
    }
}