import React from 'react';
import { StyledCard } from './styles';

interface ICardProps {
    children: React.ReactNode;
}

const Card = ({ children }: ICardProps) => {
  return <StyledCard>{children}</StyledCard>;
};

export default Card;
