import { ModelBase, NeoModel, Validation, Attributes } from '@singularsystems/neo-core';

@NeoModel
export default class ReInviteCommand extends ModelBase {

    @Attributes.Integer()
    public InvitedUserId: number = 0;

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}