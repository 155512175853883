import { NeoModel, ModelBase } from "@singularsystems/neo-core";

@NeoModel
export default class ClientTestEmailLookup extends ModelBase {

    public clientTestEmailId: number = 0

    public clientId: number = 0

    public emailAddress: string = "";
}