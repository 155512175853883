export const textConstants = {
    LocalStorage: {
        isNewToken: "comx-isNewToken",
        savedEmail: "comx-savedEmail",
        comx: "comx-",
        AccessToken: "-AccessToken",
        RefreshToken: "-RefreshToken",
        true: "true",
        false: "false",
        ActionListCriteria: "comx-ActionListCriteria"
    },
    ActionListText: {
        ColumnHeaders: {
            Name: "NAME",
            Campaign: "CAMPAIGN",
            Activity: "ACTIVITY",
            Status: "STATUS",
        },
        ArchiveButtonText: {
            Archive: "Archive",
            Restore: "Restore",
            SelectAll: "Select all leads",
            SelectAllPage: "Select all on page",
            ClearAll: "Clear selections.",
        },
        RecordSelected: {
            single: " record selected.",
            multiple: " records selected.",
        },
        LeadTypes: {
            hot: "LeadType1",
            warm: "LeadType2",
            cold: "LeadType3",
        },
        NoActivity: "No Activity",
        ResultsPerPage: "Results per Page",
        Showing: "Showing",
        Page: "Page",
        AddOtherEmail: "Add other E-Mail",
        PleaseSelectEmail: "Please select an email address to send from.",
        EmailFailed: "Failed to send email. Please try again.",
        EmailSuccessful: "Email sent successfully.",
        Tab: "tab",
        YouRepliedOn: "You replied to this message on",
        Reply: "Reply"
    },

    // Button Text
    BreadCrumbText: {
        Dashboard: "Dashboard",
        ClientSettings: "Settings",
        AddClient: "Add Client",
        AddClientList: "Add Client List",
        TargetMarkets: "Target Markets",
        UpdateTargetMarkets: "Update Target Markets",
        AddTargetMarket: "Add Target Market",
        EditTargetMarket: "Edit Target Market",
        ProspectTargetMarket: "Prospect Target Market",
        CampaignMessages: "Campaign Messages",
        AddCampaignMessage: "Add Campaign Message",
        EditCampaignMessage: "Edit Campaign Message",
        AddEmailSignature: "Add Email Signature",
        EditEmailSignature: "Edit Email Signature",
        AddFollowUpTemplate: "Add Follow Up Template",
        EditFollowUpTemplate: "Edit Follow Up Template",
        ICPQuestionnaire: "ICP Questionnaire",
    },

    buttonText: {
        ClearAll: "CLEAR ALL",
        Cancel: "Cancel",
        Save: "Save",
        UpdateMasterAccounts: "Update Master Accounts",
        AddTargetMarket: "Add Target Market",
        AddCampaignMessage: "Campaign Message",
        AddAIMessage: "AI Message",
        AddEmail: "Add Email",
        AddEmailSignature: "Email Signature",
        Edit: "Edit",
        Open: "Open",
        Clone: "Clone",
        TMCloneDisabled:
            "You are not able to clone this TM\nbecause it includes legacy industries.",
        Prospect: "Prospect",
        Whitelist: "Whitelist",
        UpdateTargetMarkets: "Update Target Markets",
        Percentage: "%",
        DeleteTargetMarket: "Delete Target Market",
        Back: "Back",
        Block: "Block",
        Unblock: "Unblock",
        Invite: "Invite",
        Download: "Download",
        Upload: "Upload",
        ReDownload: "Re-Download",
        ResetPassword: "Reset Password",
        Copy: "Copy",
        DownloadLPC: "Download LPC",
        AddClient: "Add Client",
        StartNewSearch: "Start new search",
        DownloadResults: "Download results",
        Unarchive: "Unarchive",
        Archive: "Archive",
        Delete: "Delete",
        BulkDelete: "Bulk Delete",
        AddCustomerProfile: "Add Profile",
        AddTemplate: "Add Template",
        Manage: "Manage",
        Refresh: "Refresh",
        Refreshing: "Refreshing",
        HotLeads: "Hot Leads",
        WarmLeads: "Warm Leads",
        ColdLeads: "Cold Leads",
        OpenTemplate: "Open Templates",
        SaveAsDraft: "Save as Draft",
        SaveAsActive: "Save as Active",
        Submit: "Submit",
        ReadyForClientReview: "Ready for Client Review",
        Publish: "Publish",
        CreateMessage: "Create Message",
        SendMessage: "Send Message",
        Clear: "Clear",
        SaveAndDoLater: "Save and do it later",
        DoneGoToNextSTep: "Done, Go to Next Step",
        Done: "Done",
        SaveAndFinish: "Save and Finish",
        GoTo: "Go to",
        LetsStart: "Let's Start",
        Help: "Help",
        AddCSVFile: "Add CSV File",
        FinishOnboarding: "Finish Onboarding",
        CompleteBlacklist: "Complete Blacklist",
        goToActionList: "Go to Action List",
        Ok: "Ok",
        createAI: "Create with AI",
        learnMore: "Learn More",
        Comment: "Comment",
        SaveReminder: "Save Reminder",
        Add: "Add",
        InviteUser: "Invite User",
        CompleteReview: "Complete Review",
        ClientSettings: "Client Settings"
    },

    ComXConfigurationText: {
        ConfigurationTypes: {
            number: "number",
            string: "string",
            boolean: "boolean",
            jsonObject: "json_object",
        },
        ConfigurationHeadings: {
            numbers: "Numbers",
            Strings: "Strings/Links",
            Booleans: "Booleans",
            JsonObjects: "JSON Objects",
            JsonObjectsSubHeader:
                "Please do not change the properties of the objects, only change the values of the object present",
        },
    },

    // Headings
    titleText: {
        RunningClientCampaignStatus: "RUNNING",
        AddICP: "Add Profile",
        EditICP: "Edit Profile",
        NewReplies: "New Replies ",
        Replies: "Replies ",
        AreWaiting: "are waiting",
        SpecificRoles: "Specific Keyword Roles",
        CustomerProfileStatus: "Ideal Customer Profile Status",
        IdealCustomerProfile: "Ideal Customer Profile",
        ClientReportView: "ClientReportView",
        ClientReports: "Client Reports",
        AccountStatus: "Account Status",
        IntroPages: "Introductory Pages",
        HelpCenterTexts: "Help Center Texts",
        OnboardingIntroSteps: "Onboarding Step Introductions",
        OnboardingMaintenance: "Onboarding Maintenance",
        AccountUpload: "Account Upload",
        VisitComXDbTitle: "Visit ComX Hub",
        ComXHubHeader: "ComX Hub",
        BookSupportDbTitle: "Book Support Meeting",
        KnowledgeHubPasswordDbTitle: "KnowledgeHubPassword",
        Country: "Country",
        Clients: "Clients",
        LeadMessage: "Lead Message",
        LeadMessageView: "LeadMessage",
        AddCampaignMessage: "Add Campaign Message",
        AddCampaignSignature: "Add Campaign Signature",
        FunctionType: "Function Type",
        AddCountry: "Add Country",
        Saved: "Saved",
        Success: "Success",
        StopSearch: "Stop Search",
        BackgroundJobAdded: "Background Job Recieved",
        IndustrySubIndustries: "Industry Sub-Industry",
        CLevel: "C-Level Roles",
        Industries: "Industry",
        HeadofRolesTM: "Head and Manager Roles and Functions",
        OtherKeyFunctionWords:
            "Add other function keywords that you have not found in previous steps",
        AdvancedSettingsHeader: "Advanced Settings",
        NeedHelpICP: "Need Help?",
        Industry: "Industry",
        MasterAccountStatus: "Master Account Status",
        Onboarding: "Onboarding",
        ReProspectingStatus: "Re-prospecting Status",
        Role: "Role",
        SeniorityLevels: "Seniority Levels",
        SourceTypes: "Source Type",
        SubIndustries: "Sub-Industry",
        SubIndustry: "Sub-Industry",
        IndustriesVersions: "Industries Versions",
        TargetMarkets: "Target Markets",
        NoArchivedICPTitle: "You have no archived profiles yet.",
        NoTargetMarkets: "You have no target markets yet.",
        NoCustomerProfiles: "You have no profiles yet.",
        NoCampaignMessages: "You have no campaign messages yet.",
        NoBlacklistedDomains: "You have no blacklisted domains yet.",
        AddBlacklistDomain: "Add domains here",
        AddGreylistEmail: "Add email here",
        NoArchivedTargetMarkets: "You have no archived target markets yet.",
        NoArchivedCampaignMessages: "There are no archived campaign messages",
        NoFollowUpTemplates: "There are no follow up templates",
        NoFollowUpTemplatesExternal: "ComX Templates will be available soon",
        NoArchivedFollowUpTemplates:
            "There are no archived follow up templates",
        NoSearchMatches:
            "Sorry, there are no exact matches for your search query.",
        NoLockedUsers: "There are currently no locked users.",
        TargetMarketAccountStatus: "Target Market Account Status",
        CampaignMessageInformation: "Campaign Message Information",
        TargetMarketFunction: "Target Market Functions",
        TargetMarketStatus: "Target Market Status",
        TargetMarketType: "Target Market Type",
        Maintenance: "Maintenance",
        CampaignMessagesSpaced: "Campaign Messages",
        CampaignMessage: "Campaign Message",
        FollowUpTemplates: "Follow Up Templates",
        Summary: "Summary",
        Category: "Category",
        CampaignMessageName: "Campaign Message Name",
        FollowUpTemplateName: "Template Name",
        EmailSignatureName: "Email Signature Name",
        Signature: "Signature",
        MessageType: "Message Type",
        Action: "Actions",
        Client: "Client",
        InvitedUsers: "Invited Users",
        ActiveUsers: "Active Users",
        LockedUsers: "Locked Users",
        BlockedUsers: "Blocked Users",
        UserBlocked: "User Blocked",
        UserBlockedMessage: `Selected user has been blocked`,
        UserUnlocked: "User Unlocked",
        UserUnlockedMessage: `Selected user has been unlocked`,
        BlockUsers: "Block Users",
        EditUser: "Edit User",
        UserUpdatedMessage: `Selected user's details have been updated`,
        UnblockUsers: "Unblock Users",
        ManageInvites: "Manage Users",
        ViewTM: "View Target Markets",
        ViewCampaignMessages: "View Campaign Messages",
        Error: "Error",
        MasterAccount: "Master Accounts",
        AddClientList: "Add Client List",
        TargetMarketAccounts: "Target Market Accounts",
        TotalRelevantMasterDataAccounts: "Total Relevant Master Data Accounts",
        SaveFailed: "Save failed",
        Failed: "Failed",
        FetchFailed: "Fetch failed",
        SaveLinkFailed: "Please provide a valid URL",
        Name: "Name",
        Status: "Status",
        Requests: "Requests",
        RequestsPerResult: "Requests per result",
        RequestsPerMinute: "Requests per minute",
        Results: "Results",
        InvalidReason: "Invalid Reason",
        Accounts: "Account",
        Progress: "Progress",
        Upload: "Upload",
        Uploaded: "Uploaded",
        UploadCSV: "Upload .CSV",
        AddTargetMarket: "Add Target Market",
        SpecifyTargetMarket: "Specify your Target Market",
        NewMarketType: "Create New Market Using",
        NumberOfEmployees: "Number of Employees",
        Functions: "Functions",
        Function: "Function",
        SubFunction: "Sub Function",
        Variation: "Variation",
        Advanced: "Advanced",
        Select: "Select",
        PriorityIndustry: "Priority Industry",
        Priority: "Priority",
        MarketCriteria: "Market Criteria",
        OnlyDoWhitelist: "Only Do Whitelist",
        DraftPreLaunch: "Draft - Pre-launch Setup",
        NewMarket: "Create New Market Using:  ",
        EditTargetMarket: "Edit Target Market",
        UpdateTargetMarkets: "Update Target Markets:",
        Domain: "Domain",
        AccountsResponded: "Accounts Responded",
        AccountsToReProspect: "Accounts to Re-prospect",
        PleaseEnsureHeaders:
            " Please ensure your file contains the following headings:",
        PleaseEnsureHeader:
            " Please ensure your file contains the following heading:",
        ToUpload: "To upload file, drag file into this block or ",
        TargetMarketName: "Target Market",
        TargetMarketNameSpaced: "Target Market Name",
        ReasonAndBlock:
            "Enter the reason for blocking the user and then click Block:",
        ReasonAndUnblock:
            "Enter the reason for unblocking the users below and then click Unblock:",
        EmailAndInvite:
            "Enter the user's email address below and then click Invite:",
        DownloadProspectList: "Download Prospect List",
        UploadProspectList: "Upload Prospect List",
        Prospecting: "Prospecting",
        OtherDownloads: "Other Downloads",
        TotalAccountsToProspect: "Total Accounts to be Prospected",
        LessThan50Batch: "< 50 Batch",
        AllProspectedDomains: "All Prospected Domains:   ",
        TotalAccountsAvailable: "Number of Accounts Available",
        UploadListOfAccounts: "Upload List of Accounts",
        IROAndResponded: "All Prospected & Responded Domains:   ",
        UploadProspectedAccounts: "Upload Prospected List of Accounts",
        ABProspectedAccounts: "AB: Prospected Accounts",
        IROAccounts: "IRO: Prospected and Responded Accounts",
        GatsbyTargetMarketSystemID: "Gatsby Target Market System ID:",
        InvalidData: "Invalid Data",
        InvalidSelection: "Invalid Selection",
        TargetMarketsValue: "TargetMarkets",
        ClientCampaignMessagesValue: "ClientCampaignMessages",
        ClientFollowUpTemplatesValue: "ClientFollowUpTemplates",
        ManagementFunction: "Management Function",
        Localisation: "Localisation",
        ManagementRole: "Management Role",
        SubRole: "Sub Role",
        Language: "Language",
        SearchesAndLPC: "Searches & LPC Template",
        Order: "Order",
        SearchText: "Search Text",
        IROSearch: "IRO Search",
        AddRange: "Add Range",
        KeepRange: "Keep Range",
        RemoveRange: "Remove Range",
        JobTitle: "Job Title",
        JobLevels: "Job Levels",
        JobFunctions: "Job Functions",
        Countries: "Countries",
        Configuration: "Configuration",
        DashboardReports: "Dashboard Reports Configuration",
        BackgroundJobConfig: "Background Job Configuration",
        OnIROStage: "On IRO Stage",
        IROEditStatus: "IRO Edit Status",
        IROCLevel: "C-Level",
        IROVP: "VP",
        IROHeadLevel: "Head-Level",
        IRODirector: "Director",
        IROManagerLevel: "Manager-Level",
        Employees: "Employees",
        SubMarkets: "Sub Markets",
        InviteEmail: "Invited User Email",
        Email: "Email",
        BlockedUserEmail: "Blocked User Email",
        ActiveUserEmail: "Active User Email",
        LockedUserEmail: "Locked User Email",
        DashBoard: "Dashboard",
        PlatformSetup: "Platform Setup",
        SuccessTracker: "Market Feedback Tracking",
        ComXHub: "Visit ComX Hub",
        BookSupport: "Book Support meeting",
        ClientPortal: "Client Portal",
        User: "User",
        ClientReview: "Client Review",
        DoNotBlacklistWoodpecker: "Do not blacklist in Woodpecker",
        CampaignMessagesReview: "Campaign Messages Review",
        DeleteFailed: "Delete failed",
        TMADeleteFailed: "Failed to delete Target Market Account",
        OriginalName: "Original Name",
        Blacklist: "Blacklist",
        ActionList: "Action List",
        DashboardBlacklist: "Add to Blacklist",
        GreyList: "Greylist",
        Users: "Users",
        SettingsAndPlatformSetup: "Settings & Platform Setup",
        BlacklistedDomains: "Blacklisted Domains",
        GreylistedEmail: "Greylisted Emails",
        DateAdded: "Date Added",
        AddedBy: "Added By",
        TargetMarketsSetup: "Target Market Engine",
        AddBlacklist: "Add Blacklist",
        AddGreylist: "Add Greylist",
        QuickAddDomain: "Add domain",
        QuickAddEmail: "Add email",
        BlacklistAccount: "Blacklist Account",
        WoodpeckerAPI: "Woodpecker API",
        BatchesInProgress: "Batches in Progress",
        UploadProspects: "Upload Prospects",
        Xing: "XING",
        Search: "Search",
        DateCreated: "Date Created",
        CreatedBy: "Created By",
        Region: "Region",
        SubRegion: "Sub Region",
        Regions: "Regions",
        XINGSearches: "XING Searches",
        Download: "Download",
        DomainVariation: "Domain Variation",
        TargetMarketAccountTab: "Target Market Account Tab",
        TargetMarketAccountTabStatuses: "Target Market Account Tab Statuses",
        Display: "Display",
        InformationManagement: "Information Management",
        OnboardingSideMenuMaintenance: "Onboarding Side Menu Maintenance",
        DashboardMaintenance: "Dashboard Maintenance",
        DashboardCardMaintenance: "Dashboard Card Maintenance",
        Popups: "Popups",
        IROIndustriesMapping: "IRO Cross Industries Version Mapping",
        SupportLinks: "Support Links",
        IntroductionPages: "Introduction Pages",
        DashboardCard: "Dashboard Card",
        OnboardingPopupMaintenance: "Onboarding Popups Maintenance",
        CampaignMessagesStatus: "Campaign Messages Status",
        CampaignMessageStatuses: "Campaign Message Statuses",
        InvalidReasonsHeading: "Invalid Reasons",
        LeadTypeHeading: "Lead Types",
        ActionListStatusHeading: "Action List Configurations",
        ActionListStatus: "Action List Status",
        BuyingCenter: "Buying Center",
        BatchReviewSpaced: "Batch Review",
        BatchReview: "BatchReview",
        BatchReviewProspectsSpaced: "Batch Review Prospects",
        BatchReviewProspects: "BatchReviewProspects",
        TargetMarketNumber: "Target Market Number",
        DateUploaded: "Date Uploaded",
        DateReviewed: "Date Reviewed",
        Batch: "Batch",
        Date: "Date",
        ClientSettings: "Client Settings",
        CustomerProfileQuestions: "Ideal Customer Profile Questions",
        HighlyRecommended: "Highly Recommended",
        Welcome: "Welcome",
        WelcomeExclamation: "Welcome!",
        Settings: "Settings",
        Running: "Running",
        Paused: "Paused",
        Draft: "Draft",
        Edited: "Edited",
        Completed: "Completed",
        Deleted: "Deleted",
        Stopped: "Stopped",
        PositiveTooltipHeader: "Positive Header",
        NeutralTooltipHeader: "Neutral Header",
        ScoreTooltipHeader: "Score Header",
        ReplyTooltipHeader: "Reply Header",
        PositiveTooltipPointer: "positiveHeader",
        NeutralTooltipPointer: "neutralHeader",
        ScoreTooltipPointer: "scoreHeader",
        ReplyTooltipPointer: "replyHeader",
        BlacklistTooltipName: "Blacklist",
        ZapierIntegration: "Zapier integration",
        UpdateMasterData: "Update Master Data",
        DemoClientData: "Demo Client Mock Data",
        UploadDemoClient: "Upload Demo Client",
        ActionListView: "ActionList",
        HelpCenter: "Help Center",
        TargetMarketOverview: "Target Market Overview",
        CustomerProfileOverview: "Customer Profile Overview",
        CustomerProfileName: "Ideal customer profile name",
        FriendlyDescription: "Friendly Description",
        Update: "Update",
        EmailSignatureTemplates: "Email Signature Templates",
        CampaignMessageMaintenance: "Campaign Message Maintenance",
        FeatureNotOptimized: "Feature Not Mobile-Friendly",
        Reporting: "Reporting",
    },

    // General Text
    generalText: {
        URL: "URL",
        fillInSummary: "Please fill in a summary",
        templateNameMustBeSpecified: "Template Name must be specified",
        selectAStatus: "Please select a status",
        selectICP: "Select the related Ideal Customer Profile (ICP)",
        selectTemplate: "Select your message template version",
        characterCount: "Character Count",
        emailCharacterCountValidation: "The maximum character limit is 2000.",
        runningClientCampaignReportStatus: "RUNNING",
        AllUserEmail: "All User (email)",
        Neutral: "neutral",
        LeadMessageInfoBar: "Lead Message Info Bar",
        PleaseAddPriority:
            "Please ensure you have set at least 1 item selected with a priority set before continuing.",
        PleaseAddItem:
            "Please ensure you have selected at least 1 item before continuing.",
        SearchBar: " Search...",
        NoHelpCenterText: "No Help Center assistance required.",
        ClientCampaignPageSizeCode: "ClientCampaignPageSize",
        TooltipHeader: "header",
        TooltipSubheader: "subheader",
        invalidText: "invalid",
        TooltipBody: "body",
        NoLeadName: "No Lead Name",
        TooltipSubbody: "subbody",
        TooltipArticleLink: "articleLink",
        ReplyRateLevelLow: "Reply Rate (< 8%)",
        ReplyRateLevelMedium: "Reply Rate (8% - 15%)",
        ReplyRateLevelLHigh: "Reply Rate (> 15%)",
        placeHolderTypeHereText: "Type here... ",
        placeHolderStartTyping: "Start typing to add...",
        ReplyRate: "reply",
        OpenRate: "open",
        BounceRate: "bounce",
        Score: "score",
        PositiveReplyRate: "positive",
        RateLevelHigh: "high",
        RateLevelMedium: "medium",
        RateLevelLow: "low",
        DisplayPaddingRight: "paddingRight9",
        BatchReviewProspects: "BatchReviewProspects",
        MarketFeedbackTracking: "Market Feedback Tracking",
        RequestFullAnalysis: "Request Full Analysis",
        NewLine: "\n",
        MailToNewLine: "%0d%0a",
        IdealCustomerProfileSpaced: "Ideal Customer Profile",
        DescribeIdealCustomerProfile: "Describe your ideal customer profile",
        NoICPLearnAbout:
            "Learn about best practices for creating Ideal Customer Profiles with our Customer Success Team:",
        NoICPArchives: "Archived customer profiles will appear here.",
        DownloadTemplate: "Download Template",
        InvalidDomains: "Invalid Domains",
        BackToGroups: "Back to Groups",
        AddNewGroup: "Add New Group",
        Manage: "Manage",
        Save: "Save",
        Add: "Add",
        Remove: "Remove",
        ClickHere: "Click here",
        MaintenanceView: "Maintenance View",
        ToDo: "To do",
        InProgress: "In progress",
        Complete: "Complete",
        ProgressKey: "Progress key",
        View: "View",
        VisitHub: "Visit Hub",
        NoFollowUpTemplateMessage: "No follow up template message yet.",
        PublishAreYouSure: "Are you sure you want to publish this?",
        FailedToUndoComment: "You will not be able to undo this action.",
        ClientReviewAreYouSure:
            "Are you sure you want to send this for Client Review?",
        DeleteCommentAreYouSure:
            "Are you sure you want to delete this comment?",
        RegisterBookMeeting: "Register for Workshop",
        Delete: "Delete",
        QuickAdd: "Quick Add",
        AddDomain: "Add Domain",
        AccessTMHere: "Add, view, and edit your target markets",
        ViewCampaignMessages: "View and edit your Campaign Messages",
        ViewBatchesInReview: "View all Batches in Review",
        RemoveFromTM: "Removed From TM",
        BulkDelete: "BulkDelete",
        BulkUndo: "BulkUndo",
        BulkReprospect: "BulkReprospect",
        BulkUnblacklist: "BulkUnblacklist",
        ProspectingAccountDownloadLimit: "ProspectingAccountsToDownload",
        insufficientPrivileges:
            "You have insufficient privileges to perform this operation.",
        toUnarchiveModal: "is about to be unarchived. Do you wish to continue?",
        Todo: "To-do",
        DashboardPasswordLabel: "Password",
        HotLeads: "HOT LEADS",
        WarmLeads: "WARM LEADS",
        ColdLeads: "COLD LEADS",
        TargetMarketSettings: "Target Market Settings",
        EmailServiceProviders: "Email Service Providers",
        AdvancedEmailSettings: "Advanced Email Settings",
        InternalTestEmail: "Internal Test Email Text",
        InternalTestEmailHeader: "Internal Test Email Header",
        UpdateInternalTestEmailText: "Update Internal Test Email Text",
        UpdateCRMIntegrationVideoText: "Update CRM Integration Video Text",
        CRMIntegration: "CRM Integration",
        ActionPlanChecklist: "Action Plan Checklist",
        TechnicalIntegrationEmailBody: "Technical Integration Email Body",
        LeadMessageInfoManagementBar: "Lead Message Info Bar",
        CustomerMSuccessManagerCalendlyLinks:
            "Customer Success Manager Calendly Links",
        UpdateDisplayText: "Update Display Text",
        UpdateInternalTestEmailTextHeader:
            "Update Internal Test Email Text Header",
        UpdateCRMIntegrationText: "Update CRM Integration Text",
        ActionPlanChecklistText: "Action Plan Checklist Text",
        TermsOfServiceText: "Terms Of Service Text",
        MailToBodyText: "MailTo Body Text",
        ViewSettings: "View and edit your Settings",
        CreateTargetMarket: "How To Create Target Markets",
        CreateTargetMarketText:
            "You can add a Target Market by clicking the Add Target Market button.",
        NoBlacklistedDomainsText:
            "Your blacklisted domains will appear here. You can also upload your current blacklist.",
        NoTargetMarketText:
            "Learn how to create optimal target markets with the related<br>Help Center articles.",
        NoCampaignMessagesText: "You currently have no campaign messages.",
        CreateCampaignMessage: "How To Create Campaign Message",
        CreateCampaignMessageText:
            "You can add a Campaign Message by cicking the Add Campaign Message button",
        NoArchivedTargetMarketText: "Archived target markets will appear here.",
        NoArchivedCampaignMessagesText:
            "Archived campaign messages will appear here.",
        NoFollowUpTemplateText: "Follow up templates will appear here.",
        NoFollowUpTemplateTextExternal:
            "We're working out your follow up templates. Our Customer Success Team will inform you as soon as it's ready.",
        NoArchiveFollowUpTemplateText:
            "Archived follow up templates will appear here.",
        NoSearchMatches:
            "Please check for the correct spelling before you continue searching.",
        NoLockedUsers: "When there are users locked, they will appear here.",
        BlacklistTitleText: "What is the Blacklist?",
        BlacklistIntroText:
            "A blacklist is the list of companies you want to avoid contacting. In order to achieve this, we require the domain of the company (E.g.: comx.io).",
        BlacklistText: [
            "Adding a company's domain (E.g.: comx.io) to the Blacklist will block the account in running campaigns and ComX will no longer contact them.",
            "You can add domains one-by-one via Add Domain, or in bulk via Upload .CSV, which accepts a list of domains/ email addresses/ websites and automatically cleans and extracts them for blacklisting.",
        ],
        GreylistTitleText: "What is a greylist?",
        GreylistIntroText:
            "A greylist is the list of individuals you want to avoid contacting. A greylist is used when you don’t want to blacklist the entire company, only certain individuals within companies. In order to achieve this, we require the email address of the individual (e.g: john.doe@comx.io).",
        GreylistText: [
            "A greylist is the list of individuals you want to avoid contacting. A greylist is used when you don’t want to blacklist the entire company, only certain individuals within companies. In order to achieve this, we require the email address of the individual (e.g: john.doe@comx.io).",
            "The greylist also contains all the previously prospected contacts from your ComX campaigns, in order to prevent double contacting.",
            "You can add emails one-by-one via the Quick Add. Or upload a bulk list of email addresses.",
        ],
        BlacklistQuickAddText: [
            "When adding domains to the blacklist, the related accounts are blocked immediately in running campaigns and ComX will no longer contact them.",
            "Domains will be automatically expanded for your target market regions (e.g: if you target DACH and add comx.io, we will also blacklist comx.com/ comx.de/ comx.ch/ comx.at).",
        ],
        GreylistQuickAddText: [
            "Enter the emails of the individuals you don't want to prospect (one entry per line).",
        ],
        BlacklistUploadText: [
            "Upload a .CSV file that contains: <b>Domains/ Email Addresses/ Websites (All In A Single Column) of the Companies you don't want to contact.</b>",
            "NOTE: For Accuracy we <b>recommend uploading a clean list of domains.</b> The system will attempt to clean emails and websites.",
            "Please ensure your file contains the following heading: <b>Domain OR Website OR Email.</b>",
        ],
        GreylistUploadText: [
            "Upload a .CSV file that contains: <b>Email Addresses</b>",
            "Please ensure your file contains the following heading: <b>Email Address</b>",
        ],
        AddViewBlacklist:
            "Add, view and edit your blacklist companies. Adding a domain will blacklist the account immediately in running campaigns",
        BlacklistQuickAdd: "Blacklist Quick Add",
        AddTargetMarket: "AddTargetMarket",
        TargetMarkets: "TargetMarkets",
        TargetMarketStatusActive: "TargetMarketStatus4",
        ClientCampaignMessages: "ClientCampaignMessages",
        BatchReview: "Batch Review",
        ReviewNowSpaced: "Review Now",
        TargetMarketsSpaced: "Target Markets",
        TechnicalSettingsSpaced: "Technical Settings",
        CampaignMessagesSpaced: "Campaign Messages",
        EditCampaignMessagesSpaced: "Edit Campaign Messages",
        EditCampaignMessageSpaced: "Edit Campaign Message",
        AddFollowUpTemplate: "Add Follow Up Template",
        EditFollowUpTemplateSpaced: "Edit Follow Up Template",
        TargetMarketAccountsSpaced: "Target Market Accounts",
        AddClientList: "AddClientList",
        EditTargetMarket: "EditTargetMarket",
        EditEmailSignature: "EditEmailSignature",
        EditEmailSignatureSpaced: "Edit Email Signature",
        EditCampaignMessage: "EditCampaignMessage",
        EditFollowUpTemplate: "EditFollowUpTemplate",
        EditProfileSpaced: "Edit Profile",
        AddProfileSpaced: "Add Profile",
        EditTargetMarketSpaced: "Edit Target Market",
        AddTargetMarketSpaced: "Add Target Market",
        DomainWebsiteEmail: ["Domain OR Website OR Email"],
        DeleteConfirmation: "Delete confirmation",
        UploadBlacklist: "Upload Blacklist",
        UploadGreylist: "Upload Greylist",
        UploadMockData: "Upload Mock Data",
        BulkActionConfirmation: "Bulk Action Confirmation",
        Close: "Close",
        SignatureText1:
            "Please add the signature associated to the email address used for ComX below.",
        SignatureText2: "Please keep in mind that your signature:",
        SignatureText3: "should be short and concise",
        SignatureText4:
            "should NOT contain logos, too many links and/or too much formatting (these features increase the risk of your email landing in spam folders)",
        RegionWarningMessage:
            "Sub-regions have been de-selected. Please skip IRO Stage",
        TargetMarketSettingsMaintenance: "# Bold -- * Italics -- _ Underline",
        CRMIntegrationFail: "Could not retrieve the CRM Integration Data",
        Settings: "Settings",
        FoundersVideo: "FoundersVideo",
        FoundersVideoURL: "Founder's Video URL",
        EmailSetupVideo: "EmailSetupVideo",
        EmailSetupVideoURL: "Email Setup Video URL",
        ICPVideo: "ICPVideo",
        ComXAuthServiceURL: "ComXAuthServiceURL",
        SenderNotFound: "Could not find sender address",
        ComXAuthServiceScopes: "ComXAuthServiceScopes",
        TMFunctionsVideo: "AddTMFunctionVideo",
        ICPVideoCardTitle: "How To Create Ideal Customer Profile",
        ICPVideoCardBody:
            "Attention: Some questions might feel repetitive - this is intentional. If you feel more comfortable in completing the questions in German, please feel free to do so.",
        TMFunctionVideoTitle: "Struggling with Funtions?",
        TMFunctionVideoBody:
            "Watch this tutorial video before selecting your functions for the first time.",
        knowledgeHubVideo: "KnowledgeHubVideo",
        PleaseChooseTM:
            "Please choose one of the following categories in order to define further roles underneath.",
        PleaseChooseCountry:
            "Please choose country in order to continue adding your target market.",
        highlyRecommendedSubtitle1: " Video tutorial with ",
        highlyRecommendedSubtitle2: " Customer Success Team",
        statTooltipPadding: "generalStatWithTooltip ",
        newTab: "Open in New Tab",
        HowToCreateTMVideo: "HowToCreateTMVideo",
        UserGroups: "User Groups",
        ComXHubGeneralText:
            "The main goal of the Hub is to hand over all the knowledge in the sales, marketing and B2B universe to you and your organization.",
        noArchivedTargetMarkets: "There are no archived target markets",
        campaignCard: {
            campaignsBeingPrepared: "Campaigns are being prepared!",
            noCampaignsYet: "You have no campaigns yet.",
            loadingCampaignData: "LOADING CAMPAIGN DATA ...",
            teamInTouch:
                "<p>Our marketing team is <strong>preparing the campaigns.</strong> You will be <strong>notified when the <strong>campaigns are running.</strong></p>",
            showCompleted: "Show completed campaigns",
            NoClosedCampaigns: "No closed campaigns.",
            failedToLoadCampaignData: "FAILED TO RETRIEVE CAMPAIGN DATA",
        },
        followUpTemplateUndoFailed: "Failed to undo Follow Up Template delete.",
        newUserGroupPlaceholder: "New user group",
        enoughChartData:
            "There aren't enough data points yet to render the chart",
        noActiveTargetMarkets:
            "There are no active Target Markets currently available",
        reportingDataMininumLength: "ReportingMininumLength",
        actionListLeadsEnterEmail: "Enter email address",
        dropDownPlaceholderICP:
            "Which Departments are involved in the final decision...",
        completeExercise: "COMPLETE THE FOLLOWING EXERCISE",
        uploadOrDragFile: "Upload or drag file into this block",
        selectYourRange: "SELECT YOUR RANGE:",
        addComment: "ADD COMMENT",
        completionTime: "Avg. completion time 30min",
        newActionListStatus: "New Action List Status",
        zendeskText: "How can we help you?",
        IdealCustomerProfiles: "Ideal Customer Profiles",
        incomplete: "Incomplete",
        congratulations: "Congratulations!",
        needToAddEmail: "Need to add an email",
        specifyYourTM: "Specify your Target Market",
        none: "none",
        cannotEditTargetMarket:
            "Cannot edit the target market in the overview screen. Please go to the individual step for changes.",
        cannotEditICP:
            "Cannot edit the customer profile in the overview screen. Please go to the individual step for changes.",
        potentialAccounts: "Number of potential accounts: ",
        priorityHint: "Priority: We will always start contacting these companies first.",
        activeReminders: "Active Reminders",
        successfullyDownloadICP: "Successfully downloaded the customer profile.",
        failedToDownloadICP: "Failed to download the customer profile.",
        enterValidURL: "Please enter a valid Video URL",
        FeatureNotOptimized: "This feature is not optimized for mobile devices. Please use a desktop for the best experience.",
        ClickToCopy: "Click to copy",
        SecondaryProspect: "Secondary Prospect",
        PrimaryProspect: "Primary Prospect"
    },

    video: {
        About: "About",
        Transcript: "Transcript",
        AboutDummyText:
            "Some questions might feel repetitive - this is intentional. If you feel more comfortable in completing the questions in German, please feel free to do so.",
        TranscriptDummyText:
            "Transcripts are generated using a combination of speech recognition software and human transcribers, and may contain errors. Please check the corresponding audio before quoting in print.",
        VideoTitleFallback: "Video Title",
        emailSubjectPlaceholder: "Enter a subject",
        runningClientCampaignReportStatus: "Running",
        selectYourICP: "Select your ICP",
        selectFrameworkVersion: "Select framework version",
        campaignNameMustBeSpecified: "Campaign Name must be specified",
    },

    popUps: {
        foundersPopUpPrimary: "Founders Video",
        foundersPopUpSecondary: "Founders Video - New User",
        knowledgeHubPopup: "Knowledge Center",
    },

    links: {
        MarketFeedbackTrackingRedirectConfigCode: "MarketFeedbackTrackingURL",
        ComXHub: "https://hub.comx.io/",
    },

    notFoundPage: {
        title: "Oops",
        text: "Page not found",
        buttonText: "Take me home",
    },

    // Messages
    messageText: {
        saveMessage: {
            AccountStatusesSaved: "Account status saved successfully.",
            OnboardingMaintenanceSaved:
                "Onboarding maintenance saved successfully.",
            ClientSaved: "Client saved successfully.",
            SettingsSaved: "Settings saved successfully",
            CountriesSaved: "Country saved successfully.",
            IndustiesVersionsSaved: "Industries versions saved successfully.",
            CompanySizesSaved: "Company sizes saved successfully.",
            FunctionTypesSaved: "Function type saved successfully.",
            GenericSaved: "Data saved successfully",
            IndustrySubIndustriesSaved:
                "Industry sub-industry saved successfully.",
            IndustrySaved: "Industry saved successfully.",
            MasterAccountStatusesSaved:
                "Master account status saved successfully",
            ReProspectingStatusesSaved:
                "Re-prospecting status saved successfully",
            RolesSaved: "Role saved successfully",
            SeniorityLevelsSaved: "Seniority levels saved successfully",
            SourceTypesSaved: "Source type saved successfully.",
            SubIndustrySaved: "Sub-industry saved successfully",
            TargetMarketSaved: "Target market saved successfully",
            CampaignMessageArchived: "Campaign Message archived successfully",
            FollowUpTemplateArchived:
                "Follow up template archived successfully",
            CampaignMessageStatus:
                "Campaign Message status changed successfully",
            CampaignMessageUnarchived:
                "Campaign Message unarchived successfully",
            FollowUpTemplateUnarchive:
                "Follow up template unarchived successfully",
            TargetMarketAccountStatusesSaved:
                "Target market account status saved successfully",
            TargetMarketFunctionsSaved:
                "Target market functions saved successfully",
            TargetMarketStatusesSaved:
                "Target market status saved successfully",
            TargetMarketTypesSaved: "Target market types saved successfully",
            UploadSuccess: "Upload was successful",
            BlacklistedDomainsUploadSuccess:
                "Success. Domains will be processed in the background.",
            WhitelistUpload:
                "The Whitelist has been successfully uploaded. You will be notified once the file has been processed.",
            WhitelistUploaded:
                "The Whitelist has been successfully uploaded and will be processed when saving the Target Market.",
            DownloadSuccess: "Download was successful",
            TMCloneSuccessful: "Target market cloned successfully",
            CampaignMessageCloneSuccessful: "Campaign message cloned successfully",
            EmailSignatureCloneSuccessful: "Email signature cloned successfully",
            IdealCustomerClone: "Ideal customer profile cloned successfully",
            FollowUpTemplateClone: "Follow up template cloned successfully",
            SaveFailed: "Save failed",
            SaveFailedMessage: "Failed to retrieve data",
            ManagementFunctionSaved: "Management Function saved successfully",
            ManagementRoleSaved: "Management Role saved successfully",
            LanguagesSaved: "Languages saved successfully.",
            ConfigSaved: "Configuration saved successfully.",
            ConfigFailed: "Configuration failed to save.",
            InvalidReasonSaved: "Invalid Reason saved successfully",
            StatusSaved: "Status saved successfully",
            InvalidReasonSavedDuplicates:
                "Invalid Reason saved successfully. Duplicates have been removed.",
            BuyingCentersSaved: "Buying Centers saved successfully.",
            TextToClipboard: "Text copied to clipboard",
            EmailToClipboard: "Email copied to clipboard",
            IROEditStatusSuccess: "IRO Edit Status saved successfully",
            IROStageSuccess: "IRO Stage saved successfully",
            SnapshotSaved: "Target Market snapshot saved successfully",
            OnboardingText:
                "Access the onboarding checklist to setup your ComX Platform ",
            CheckDailyText:
                "Monitor your ComX Platform performance and call to actions",
            BookMeetingText:
                "Join the ComX team in our bi-weekly support and learning workshops. Tuesday and Thursday at 4pm CET.",
            MasterAccountSaved: "Master Account saved successfully",
            MasterAccountDeleted: "Master Account deleted successfully",
            TargetMarketAccountDeleted:
                "Target Market Account deleted successfully",
            TargetMarketAccountUnDeleted:
                "Target Market Account undeleted successfully",
            TargetMarketAccountSaved:
                "Target Market Account saved successfully",
            ReprospectingInProgress:
                "Your Request to Reprocess will be completed in the background. You will be notified once complete",
            EmailSignatureSaved: "Email Signature saved successfully",
            EmailSignatureSavedAndPublished:
                "Email Signature saved and published successfully",
            EmailSignatureSavedAndClientReview:
                "Email Signature saved and ready for client review",
            CampaignMessageSaved: "Campaign Messages saved successfully",
            FollowUpTemplateSaved: "Follow Up Template saved successfully",
            PublishedMessageSaved:
                "Campaign Messages saved and published successfully",
            PublishedFollowUpTemplateSaved:
                "Follow Up Template saved and publish successfully",
            ClientReviewMessageSaved:
                "Campaign Messages saved and ready for client review",
            ClientReviewFollowUpTemplateSaved:
                "Follow Up Template saved and ready for client review",
            ICPSaved: "Ideal Customer Profile saved successfully",
            ICPAutoSave: "Autosave complete",
            CommentSaved: "Comment saved successfully",
            BlacklistSaved: "Blacklist saved successfully.",
            BlacklistDomainDeleted:
                "Blacklist domain is in the process of being deleted.",
            GreylistlistSaved: "Greylist saved successfully.",
            QuickAddSuccess: "Quick Add was successful",
            BlacklistAccountSuccess: "Account blacklisted successfully",
            BlacklistAccountFail: "Failed to blacklist account",
            BulkDelete: "Bulk delete of accounts was successful",
            BulkDeleteDomains: "Bulk Blacklist Domain deletion is in process.",
            BulkDeleteProspects: "Bulk delete of prospects was successful",
            BulkDomainDelete: "Bulk Blacklist Domain deletion is in process.",
            BulkGreylistDelete:
                "Bulk delete of greylisted emails was successful",
            BulkEmailDelete: "Bulk delete of greylisted emails was successful",
            BulkUndo: "Bulk un-remove of target market accounts was successful",
            BulkUndoMasterAccounts:
                "Bulk un-remove of master accounts was successful",
            BulkReprospect:
                "Bulk reprospect of target market accounts was successful",
            BulkUnblacklist:
                "Bulk un-blacklisting of target market accounts is in progress",
            Unblacklist:
                "Un-blacklisting of target market account is in process",
            TargetMarketNameUpdated: "Target market name update successful",
            CampaignMessageNameUpdated:
                "Campaign Message name update successful",
            FollowUpTemplateNameUpdated:
                "Follow Up Template name update successful",
            XingSaved: "Xing search list save successful",
            StoppingXingSearch: "Stopping Xing search",
            DomainVariationsSaved: "Domain Variation saved successfully",
            TMATabSaved: "Target Market Account tab saved successfully.",
            TMATabStatusSaved:
                "Target Market Account Tab Status saved successfully.",
            CommentDelete: "Comment deleted successfully",
            BatchReviewComplete: "Batch Review successfully completed",
            BatchReviewArchived: "Batch Review successfully archived",
            BatchReviewUnarchived: "Batch Review successfully restored",
            SideMenuDataSaved: "Side Menu Data saved successfully",
            PopupDataSaved: "Popups Data saved successfully",
            OutboundEmailSaved: "Outbound Email saved successfully.",
            DashboardCardSaved: "Dashboard card saved successfully",
            TooltipSaved: "Tooltip saved successfully",
            DomainAdded: "Domain added successfully.",
            RequestFullAnalysisSuccess: "Request Successful",
            RequestFullAnalysisSuccessMessage:
                "Analysis request has been sent successfully",
            RequestFullAnalysisFail: "Request Failure",
            RequestFullAnalysisFailMessage:
                "Analysis request failed to send, please try again",
            UnableToMarkAsSeen: "Unable to mark reply as seen",
            DemoClientMockDataSave: "Demo Client Mock Data saved successfully",
            DemoClientMockDataUpload: "Upload successful.",
            DashboardNewsCardVisibleToAllUsers: "Dashboard news card visible to all users.",
            FailedToShowDashboardNewsCardToAllUsers: "Failed to show dashboard news card to all users.",
        },
        validationMessage: {
            CustomerProfileFileFailed:
                "Could not upload file. File format must must be either: .docx or .pdf",
            UnSaveChanges:
                "You have unsaved changes. Are you sure you want to leave?",
            accountStatusName: "Account status must be specified",
            messageStatus: "Message Status must be specified",
            invalidReasonPrompt: "Invalid reason must be specified",
            statusMustBeSpecified: "Status name must be specified",
            messageType: "Message Type must be specified",
            countryName: "Country name must be specified",
            campaignName: "Campaign name must be specified",
            comxSuggestion: "ComX Suggestion must be specified",
            clientName: "Client name must be specified",
            questionRequired: "This question is required",
            companySizeName: "Company size must be specified",
            functionTypeName: "Function type name must be specified",
            industryName: "Industry name must be specified.",
            buyingCenterName: "Buying Center name must be specified.",
            industry: "Industry must be specified",
            masterAccountStatusName: "Master account status must be specified",
            reProspectingStatusName: "Re-prospecting status must be specified",
            roleName: "Role must be specified",
            seniorityLevelName: "Seniority level must be specified",
            sourceTypeName: "Source type must be specified",
            subIndustry: "Sub-Industry must be specified",
            subIndustryName: "Sub-Industry name must be specified",
            targetMarketAccountStatusName:
                "Target market account status must be specified",
            targetMarketFunctionName:
                "Target Market Function must be specified",
            targetMarketStatusName: "Target Market Status must be specified",
            targetMarketTypeName: "Target Market Type must be specified",
            emailAddress: "Email address must be specified",
            targetMarketName: "Target Market Name must be specified",
            clientCampaignMessageName:
                "Campaign Message Name must be specified",
            clientFollowUpTemplateName:
                "Follow Up Template Name must be specified",
            country: "Country must be specified",
            onlyNumbersAllowed: "Only numbers allowed",
            notEnoughAccounts: "There are not that many accounts available",
            mustNotIndustry: "Industry must not be specified",
            mustNotCompanySize: "Company Size must not be specified",
            mustWhitelist: "Whitelist file must be specified",
            mustPriorityIndustry: "A Priority Industry must be specified",
            mustIndustry: "An Industry must be specified",
            mustCompanySize: "Company Size must be specified",
            mustSeniorityLevel: "A Seniority Level must be specified",
            mustPriorityFunction: "A Priority Function must be specified",
            mustFunction: "A Function must be specified",
            mustCountry: "Country must be specified",
            csv: "Please upload a .csv file",
            language: "Language must be specified",
            value: "Value must be specified",
            valueMustBeNumber: "Value must be a number",
            valueMustBeJSON: "Value must be a valid JSON object",
            valueMustBeBool: "Value must be true or false",
            description: "Description must be specified",
            body: "Body must be specified",
            localisation: "Localisation must be specified",
            subRole: "Sub Role must be specified",
            managementRole: "Role must be specified",
            managementFunction: "Function must be specified",
            subFunction: "Sub Function must be specified",
            headerManageLevelSelected: `In order to target Functions, you need to select Head or Manager Role levels`,
            mandatoryTohave1selected: `Please select at least one Sub Function`,
            countryMustBeSelected: `Please select a Country in order to add Functions.`,
            cannotEditTargetMarket:
                "A previous edit is busy being implemented. Please try again later",
            manadoryFunctionRequired:
                "You have not selected any functions to target. Please select functions or enter other functions. Alternatively remove the head and manager seniority from your target",
            otherKeywordsMessage: `Please add other keywords not found above. Please enter the keyword, not a jobtitle - e.g.: Enter "marketing", not "Head of marketing"`,
            otherKeywordsValidation: `Please enter the keyword, not a jobtitle - e.g.: Enter "marketing", not "Head of marketing"`,
            invalidPlatformSetup:
                "Please provide a valid URL for Platform Setup",
            invalidMaxTargetMarkets:
                "Max Target Markets must be greater than 0",
            invalidSuccessTracking:
                "Please provide a valid URL for Success Tracking",
            invalidActionPlanChecklist:
                "Please provide a valid URL for Action Plan Checklist",
            atleastMandatoryFunction: `Please select at least one Priority Function`,
            startTyping: "Start typing to add...",
            targetMarketTypeChange:
                "You can only change to Only Do Whitelist when a TM is still in draft status. Clone and create a new TM to do a whitelist only TM",
            fixedNameErrorMessage: "Fixed Name may not contain whitespaces",
            invalidReasonErrorMessage:
                "Failed to save. Please check for duplicates or other input errors.",
            domainErrorMessage: "Domain may not contain whitespaces",
            originalNameErrorMessage:
                "Original Name may not contain whitespaces",
            locationErrorMessage: "Location may not contain whitespaces",
            invalidreasonErrorMessage:
                "Failed to save. Please select an invalid reason",
            domain: "Domain must be specified",
            maxTMReached:
                "You have reached your maximum number of Target Markets. Please speak to your customer success manager if you need to add more Target Markets.",
            commentDeleteDenied: "You may only delete your own comments",
            usergroupErrorMessage: "Please select at least one User Group.",
            quickAddErrorMessage:
                "This is not a valid domain. Please enter a valid domain format",
            quickAddEmailMessage:
                "The following email addresses are invalid. Please enter a valid email address format",
            testEmailErrorMessage:
                "This is not a valid email. Please enter a valid email.",
            otherKeywordsRegexValidation:
                "You have entered an invalid keyword. Please check for special characters and try again",
            regionName: "Region name must be specified.",
            subRegion: "Sub Region must be specified",
            countryMustBeSelectedForRegions: `Please select a Country in order to add Regions.`,
            bulkUnblacklistConfirmation:
                "Are you sure you want to remove the selected accounts from the blacklist? Please note this may take time, please do not navigate away from the screen.",
            bulkDeleteConfirmation:
                "Are you sure you want to remove selected accounts? Please note this may take time, please do not navigate away from the screen.",
            bulkUndoConfirmation:
                "Are you sure you want to undo removal of selected accounts? Please note this may take time, please do not navigate away from the screen.",
            bulkReprospectConfirmation:
                "Are you sure you want to re-prospect selected accounts?",
            bulkBlacklistDeleteConfirmation:
                "Are you sure you want to remove selected blacklist domains?",
            bulkGreylistDeleteConfirmation:
                "Are you sure you want to remove selected greylist emails?",
            variationName: "Domain variation must be specified.",
            maxTargetMarkets:
                "Max target market number must be greater than 0.",
            actionPlanChecklistMandatory:
                "Action Plan Checklist must be specified and must be a valid URL",
            tabName: "Tab name must be specified",
            outboundEmailDeleteSuccess: "Outbound email deleted successfully",
            thisFieldIsRequired: "This field is required",
            thisFieldIsRequiredOrHasInvalidEmail:
                "This field is required and must contain a valid email address",
            thisFieldIsRequiredOrHasInvalidUrl:
                "This field is required and must contain a valid URL",
            invalidDomain: "Invalid Domain: ",
            domainExists: "Unable to add a duplicate domain",
            invalidDomainEmptySpace: "Please input a domain.",
            invalidIndustriesAndPriority:
                "Please select at least one Industry, and one Priority",
        },
        fetchMessage: {
            fetchDemoClients: "Successfully fetched the demo clients.",
            fetchClientList: "Successfully fetched the client list.",
        },
    },

    // Date format
    dateformat: {
        format1: "DD_MM_YYYY, hh_mm_ss A",
        format2: "ddd MMM DD, hh:mm A",
        format3: "dd/MMM/yyyy  -  hh:mm TT",
        format4: "dddd, MMMM D, YYYY [at] HH:mm",
        hourMinuteFormat: "HH:mm",
        dayMonthYear: "DD MMM yyyy - HH:mm",
        dayMonthYearNoTime: "dd MMM yyyy",
        DDMMYYYY: "DD/MM/YYYY",
        yearMonthDay: "yyyy-MM-dd"
    },

    // Download Update Target Markets Template Key
    UpdateTargetMarketsTemplateKey: {
        AccountsResponded: "AccountsResponded",
        AccountsToReProspect: "AccountsToReProspect",
        InvalidDomains: "InvalidDomains",
    },

    CampaignMessageStatusKeys: {
        DraftStatus: "CampaignMessageStatus1",
        ActiveStatus: "CampaignMessageStatus2",
        ClientReviewStatus: "CampaignMessageStatus3",
    },

    funtionsFilterKeys: {
        clevel: "C-Level",
        HeadLevel: "Heads",
        ManagerLevel: "Managers",
        NotStandard: "Standard Not",
    },

    Onboarding: {
        ScheduleAMeeting: "Schedule a Meeting",
        Welcome: "Welcome.",
        GetStarted: "Let's get you started.",
        GreatToHaveYou:
            "It's great to have you with us. Let's create your first campaign in 5 easy steps.",
        StartNow: "Start now",
        Continue: "Continue",
        LetsContinue: "Let's Continue",
        NoItemsInList: "No items in list",
        AddFunction: "Add Function",
        IntroductoryPages: "Introductory Pages",
        Popups: "Popups",
        SupportLinks: "Support Links",
        OnboardingVideos: "Onboarding Videos",
        IntroductionPages: "Introduction Pages",
        StepsLeft: "Steps Left",
        StepLeft: "Step Left",
        HelpCenter: {
            Text: "Help Center Texts",
            VideoTutorials: "Video Tutorial",
        },
        EmailSetup: {
            EmailSubHeading: "First thing first",
            EmailSetupHeading: "Get your email accounts ready",
            EmailSetupParagraph1:
                "In order to get the lead campaigns sent out, an email setup needs to be done. \nTo accomplish that, somebody running IT from your side will be necessary.",
            EmailSetupParagraph2:
                "Have a look at the technical requirements, and share the useful to-do list with your IT team if you wont be doing the setup yourself. We recommend you start this this sooner rather than later.",
            EmailBody:
                "*Outbound sales email address setup*%0d%0aEmail address ComX will use to send out the campaigns. We will need:%0d%0a— User Name%0d%0a— IMAP server and port%0d%0a— SMTP server and port%0d%0a%0d%0a*Domain setup*%0d%0aIn order for ComX to ensure the full domain health of your company domain when sending outbound campaigns, we ask you to set up the following in your domain(s):%0d%0a— SPF%0d%0a— DKIM%0d%0a— DMARC%0d%0a— CNAME%0d%0a%0d%0a*Outbound Profile*%0d%0aThat is the email signature - it needs to be representative and trustworthy.",
            EmailSpecs: "View email setup specifications",
            EmailSetupCTA: "Understand, Let's Start",
        },
        EmailModal: {
            EmailSetupHeading: "Email setup specification",
            EmailStep1: "Step 1",
            EmailStep1Paragraph1: "Choose your Outbound Profile",
            EmailStep1Paragraph2: "For the set-up please provide either:",
            EmailStep2: "Step 2",
            EmailStep2Paragraph1: "Create a new email address",
            EmailStep1List1:
                "— a newly created email address using your company domain",
            EmailStep1List2:
                "— a newly created email address using another domain previously used for outbound sales activities",
            EmailStep1List3:
                "— The newly created email address needs to respect the following requirements:",
            EmailStep1List4:
                "— it needs to be a stand-alone email address and cannot be an alias of an existing email address",
            EmailStep1List5:
                "— it needs to be personal, e.g. it contains either the first name, the last name or both of the sender of the emails",
            EmailSpecsToIT: "Send Specification to IT",
        },
        OnboardingSideMenu: {
            SalesTeam: "Sales Team",
            ITTeam: "IT Team",
            EmailTeam: "Email Setup",
            Progress: "Your Onboarding Progress",
        },
        ScheduleMeeting: "ScheduleMeeting",
        SkipScheduleMeeting: "Are you sure you want to skip the scheduling?",
        AlreadyScheduledMeeting: "Already scheduled a meeting",
        AlreadyScheduledOnboardingMeeting: "I have already scheduled an Onboarding Meeting",
        Meeting: {
            HeaderText: "We create your campaign message with you.",
            SubText1:
                "We will help you create a great message that will attract your leads.",
            SubText2:
                "Please book a meeting with your Customer Success Manager to complete this step.",
            MeetingWithCSM: "Meeting with our Success Manager",
            CallWith: "Onboarding Call with ",
            ReCancel: "Re-Schedule / Cancel",
            WatchAndRead:
                "Please watch and read the following links before the meeting!",
            ComXHub:
                "The main goal of the Hub is to hand over all the knowledge in the sales, marketing and B2B universe to you and your organization.",
            PasswordUpper: "PASSWORD",
        },
        Navigation: {
            ICP: "ICP",
            TechnicalIntegration: "Technical Integration",
        },
        CampaignMessageBaseView: {
            CMComing: "CampaignMessageComing",
            BookAMeeting: "BookAMeeting",
            CMOverview: "CampaignMessageOverview",
            EditSignature: "EditSignature",
            EditCampaignMessage: "EditCampaignMessage",
            EditFollowUpTemplate: "EditFollowUpTemplate",
        },
        ListSelectorTitleIndustries: "Add industries that you want to target",
        SelectedIndustries: "Selected Industries",
        ListSelectorTypeIndustries: "INDUSTRIES",
        ListSelectorBtnTextIndustries: "Add Industry",
        ListSelectorSubTitleIndustries: "INDUSTRIES SELECTED",

        ListSelectorTitleRoles: "Add roles that you want to target",
        SelectedRoles: "Selected Roles",
        ListSelectorTypeRoles: "ROLES",
        ListSelectorTypeRolesLowercase: "Roles",
        ListSelectorBtnTextRoles: "Add Role",
        ListSelectorSubTitleRoles: "ROLES SELECTED",

        ListSelectorTitleRolesAndFunctions:
            "Add head and manager roles and functions",
        SelectedManagementRoles: "Selected Management Roles and Functions",
        ListSelectorTypeRolesAndFunctions: "ROLES AND FUNCTIONS",
        ListSelectorBtnTextRolesAndFunctions: "Add Role and Function",
        ListSelectorSubTitleRolesAndFunctions: "Roles and Functions",
        ListSelectorTMSubTitleRolesAndFunctions:
            "Please select options to specify the roles and functions you have added below",
        ListSelectorHeadOfSeniorityLevelRolesAndFunctions:
            "Head of Seniority Level",
        ListSelectorManagerOfSeniorityLevelRolesAndFunctions:
            "Manager of Seniority Level",
        Selected: "Selected",

        IntroductionTitle: "Complete the steps below to continue onboarding",
        NoResultsForSearch: "There are no results for your current search.",
        CampaignMessageComing: "Your Campaign Message is coming.",
        Meantime: "In the meantime:",
        OurWizards:
            "Our marketing wizards are preparing your Campaign Message as we speak. It usually takes up to 3 working days for the message to be crafted. You will be notified when the message is ready.",
        TechnicalTime:
            "You could use the time to do the technical integration.",
        ExploreSalesHub: "Start exploring our sales ComX Hub.",
        SetupEmail: "Setup email integration",
        ExploreHub: "Explore ComX Hub",
        CompleteYourSetup: {
            Title: "Complete your setup.",
            SubTitle: "6 Steps to create your first lead campaign.",
            Step1Title: "Define your ideal customer profile",
            Step2Title: "Set target markets",
            Step3Title: "Add optional blacklist",
            Step4Title: "Book a meeting with a consultant",
            Step5Title: "Create a campaign message",
            Step6Title: "Setup your email",
        },
        FinalOnboardingStep: "The last step of the onboarding process!",
        GetEmailsReady:
            "Get your email accounts ready to send out your first campaign message!",
        GoToEmailSetup: "Go to Email Setup",
        MeetingStatusUnknown:
            "Meeting status unknown. Please reload the page to determine if booking was successful.",
        CancellationFailed: "Unable to cancel meeting. Please try again.",
        requirementsSent: "Requirements sent!",
        letsContinueWithOnboarding: "Let's continue with onboarding.",
    },
    TechnicalIntegrationSettings: "Technical Integration Settings",
    AdvancedEmailSettings: "Advanced Email Settings",
    CRMIntegrationSettings: "CRM Integration Settings",
    InternalTest: "Internal Test",
    ActionPlanChecklist: "Action Plan Checklist",
    TermsOfService: "Terms Of Service",
    ModalText: {
        ReferUsText: "Refer Us",
        PublishICP: "Publish ICP",
        AICopyCreation: "AI Copy Creation",
    },

    IndustriesVersionsValidations: {
        CannotSetNewActive:
            "Cannot set new version as active.\nMust link industries and sub-industries first.",
        NoIndustryOrSubIndustryLinked:
            "No industries or sub-industries linked to version yet.",
        NoSubIndustryLinked: "No sub-industries linked to version yet.",
    },

    Dashboard: {
        mailsDelivered: " mail(s) delivered",
        accurateDataMessage:
            " - To show accurate data, a minimum of 300 is needed.",
        opened: " opened  ",
        replied: " replied",
        bouncedRate: " bounced rate",
        optOutRate: " opt-out rate",
        delivered: "Delivered",
        allEmailFilter: "All (Email)",
        allStatusFilter: "All (Status)",
        allStatusesFilter: "All Statuses",

        mailDelivered: "MAIL DELIVERED",
        open: "OPENED",
        replies: "REPLIES",
        positive: "POSITIVE",
        neutral: "NEUTRAL",
        sentDelivered: "MAIL SENT / DELIVERED",
        bounced: "BOUNCED",
    },

    GraphData: {
        allCampaigns: "All Campaigns",
        allTargetMarkets: "All Target Markets (Active)",
        campaignMessageLineChartPropertyName: "campaignMessageDataLookups",
        repliesChartPropertyName: "archivedClientCampaigns",
        graphSeriesNames: [
            "Delivered",
            "Bounced",
            "Opened",
            "Replied",
            "Positive",
            "Neutral",
        ],
        chartTypes: [
            "Sendout Over Time",
            "Open Rate Over Time",
            "Bounce Rate Over Time",
            "Replies Over Time",
            "Reply Rate Over Time",
            "Target Market Completion Rate",
        ],

        // Colours
        chartBaseColour: [
            "#91AADB",
            "#97CBE6",
            "#D2E7F1",
            "#FFA685",
            "#3FB560",
            "#F6BD3A",
        ],
        repliesOverTimeGreyedColours: ["#818888", "#818888", "#818888"],
        repliesOverTimeColours: ["#3FB560", "#F6BD3A", "#6B8195"],
        replyRateOverTimeColours: ["#3FB560"],
        replyRateOverTimeGreyedColours: ["#818888"],
        emailSeriesGreyedColours: [
            "#818888",
            "#818888",
            "#818888",
            "#818888",
            "#818888",
        ],
        pieChartColours: {
            ReadyToRetarget: "#EDEEEE",
            NotYetContacted: "#7E93A3",
            ReadyForSendOut: "#405D75",
        },

        repliesOverTimeNumber: [
            "positiveCount",
            "neutralCount",
            "negativeCount",
        ],
        replyRateOverTime: ["replyRate"],
        repliesOverTimePercent: ["positivity", "neutrality", "negativity"],

        pieChartNumbers: [
            "aggregateToDo",
            "aggregateInProgress",
            "aggregateComplete",
        ],
        pieChartSeriesNames: {
            ReadyToRetarget: "Ready to Retarget",
            NotYetContacted: "Not Yet Contacted",
            ReadyForSendOut: "Ready for Send Out",
        },
        pieChartLegendSubtext: {
            ReadyToRetarget: "ReadyToRetarget",
            NotYetContacted: "NotYetContacted",
            ReadyForSendOut: "ReadyForSendOut",
        },

        totalRepliesOverTimeNumber: [
            "allPositiveReplies",
            "allNeutralReplies",
            "allNegativeReplies",
        ],
        totalRepliesOverTimePercent: [
            "allPositiveRates",
            "allNeutralRates",
            "allNegativeRates",
        ],
        totalReplyRateOverTimeNumber: ["totalReplyRate"],

        allCampaignsNumber: [
            "deliveryCount",
            "bouncedCount",
            "openedCount",
            "repliedCount",
            "positiveCount",
            "neutralCount",
        ],
        allCampaignsPercent: [
            "deliveryRate",
            "bouncedRate",
            "openedRate",
            "repliedRate",
            "positivity",
            "neutrality",
        ],

        emailCampaignsChartNumber: [
            "deliveryCount",
            "bouncedCount",
            "openedCount",
        ],
        emailCampaignsChartPercent: [
            "deliveryRate",
            "bouncedRate",
            "openedRate",
        ],

        lineChartSeriesNames: {
            repliesOverTime: [
                "Positive Replies",
                "Neutral Replies",
                "Negative Replies",
            ],
            replyRateOverTime: ["Reply Rate"],
            emailSeriesNames: ["Email 1", "Email 2", "Email 3", "Email 4"],
            totalSentOutSeriesName: "Total Emails Sent",
            overallBounceRateSeriesName: "Overall Bounce Rate",
            overallOpenRateSeriesName: "Overall Open Rate",
        },

        datePickerOptions: {
            monthPicker: "MMMM yyyy",
            dayPicker: "dd MMMM yyyy",
        },
    },

    DemoClients: {
        replyExperience: "Reply Experience",
        campaignMessageStatistics: "Campaign Message Statistics",
        campaignReportsDaily: "Campaign Reports Daily",
        campaignReportsMonthly: "Campaign Reports Monthly",
    },
    SVG: {
        Download:
            '<svg width="24" height="24" fill="none" ><path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#121212" stroke-width="2" stroke-linecap="round"/><path d="M7 10L12 15L17 10" stroke="#121212" stroke-width="2" stroke-linecap="round"/><path d="M12 15V3" stroke="#121212" stroke-width="2" stroke-linecap="round" /></svg>',
    },

    Events: {
        clientBatchReviewDraft: "Client_Batch_Review_Draft",
        clientBatchReviewComplete: "Client_Batch_Review_Complete",
        clientTemplatePublish: "Client_Template_Publish",
        clientTemplateDraft: "Client_Template_Draft",
        clientCampaignMessagePublish: "Client_Campaign_Message_Publish",
        clientCampaignMessageDraft: "Client_Campaign_Message_Draft",
        clientBlackListDownload: "Client_Blacklist_Download",
        clientBlackListQuickAdd: "Client_Blacklist_QuickAdd",
        clientBlackListUpload: "Client_Blacklist_Upload",
        clientOpenHub: "Client_Open_Hub",
        clientICPDraft: "Client_ICP_Draft",
        clientICPPublish: "Client_ICP_Publish",
        clientSupportCall: "Client_Support_Call",
        clientTMSave: "Client_TM_Save",
        clientFirstCampaign: "Client_First_Campaign",
    },

    Routes: {
        CampaignMessage: "CampaignMessage",
        AddCampaignMessage: "AddCampaignMessage",
        Dashboard: "Dashboard",
        KnowledgeCenter: "Hub",
        Campaigns: "Campaigns",
        Clients: "Clients",
        IdealCustomerProfileSpaced: "Ideal Customer Profile",
        TargetMarketsSpaced: "Target Markets",
        Blacklist: "Blacklist",
        BatchReviewSpaced: "Batch Review",
        CampaignMessagesSpaced: "Campaign Messages",
        ClientCampaignMessagesSpaced: "Client Campaign Messages",
        TargetMarketAccountsSpaced: "Target Market Accounts",
        AddClientListSpaced: "Add Client List",
        AddTargetMarketSpaced: "Add Target Market",
        UpdateTargetMarketsSpaced: "Update Target Markets",
        Prospecting: "Prospecting",
        AddEmailSignatureSpaced: "Add Email Signature",
        Administration: "Administration",
        Maintenance: "Maintenance",
        MasterAccountsSpaced: "Master Accounts",
        Security: "Security",
        Users: "Users",
        Onboarding: "Onboarding",
        Settings: "Settings",
        CampaignEmailOverview: "Campaign Email Overview",
        ActionList: "Action List",
        LeadMessage: "Lead Message",
        CustomerProfileQuestionnaire: "CustomerProfileQuestionnaire",
        Reporting: "Reporting",
    },

    RouteNames: {
        dashboard: "/Dashboard",
        actionList: "/ActionList",
    },

    LeadEmailCreator: {
        NewMessage: "New Message",
        TemplateNotSet: "Template not set.",
        SearchTemplates: "Search Templates",
    },

    EmailSettings: {
        AddNewEmail: "Add new email",
        EmailSubText:
            "Add all credentials of your new email of your outbound profile. Watch the tutorial in the ComX lesson if you have any questions.",
        AddEmailButton: "Add Email",
        EmailAddressPlaceholder: "Email Address",
        ProviderNameLabel: "Provider Name",
        AddEmailModal: "Add email credential",
        SelectProviderEmailLabel: "Select your company email provider",
        WebLinkLabel: "Weblink to Email Account",
        OutboundEmailLabel: "Outbound Sales Email",
        UsernameLabel: "Username",
        PasswordLabel: "Password",
        imapServerLabel: "IMAP Server",
        imapPortLabel: "IMAP Port",
        smtpServerLabel: "SMTP Server",
        smtpPortLabel: "SMTP Port",
        AdvancedEmailSettings: {
            FirewallLabel: "Do you have a firewall in place?",
            FirewallInfo:
                "You will need to whitelist certain IPs in your firewall/email settings. Please contact help at mailbot@comx.io for more information",
            DKIMLabel: "I have setup DKIM on my domain",
            RequiredSectionInfo:
                "Below configurations are critical and required:",
            SPFLabel: "I have setup SPF on my domain",
            CNameLabel: "I have setup CNAME on my domain",
        },
        InternalTest: {
            EmailTestPlaceholder: "Test email address",
        },
    },
    Reminders: {
        Description: "DESCRIPTION",
        Title: "TITLE",
        dueDate: "DUE DATE",
        reminderDue: "Reminder due:",
        reminderSet: "Reminder set:",
        newReplyRecieved: "New reply recieved",
        timeToReply: "Time to Reply",
        titleMaxLength: "Title cannot be longer than",
        titleRequired: "Title required",
        prospectId: "prospect_id"
    },
    Reporting: {
        chartBaseColour: [
            "#91AADB",
            "#97CBE6",
            "#FFA685",
            "#F6BD3A",
            "#3FB560",
            "#D2E7F1",
            "#97CBE6",
            "#91AADB",
            "#97CBE6",
        ],
        allClients: "All Clients",
        allCampaigns: "All Campaigns",
        clientPerformaceOverview: "client_performance_overview",
        engagementReportNames: [
            "engagement_by_company_size",
            "engagement_by_industry",
            "engagement_by_seniority",
            "engagement_by_position",
            "engagement_by_country",
        ],
        disabledLegends: ["Prospect To Be Contacted", "Total Prospect Count"],
        convertNames: {
            Opened: "Opened",
            CampaignMessageOpened: "Campaign Message Opened",
            TotalEmailOpened: "Total Email Opened",
            CampaignSent: "Campaign Sent",
            TotalCampaignMessageSent: "Total Campaign Message Sent",
            Sent: "Sent",
            ProspectBounced: "Prospect Bounced",
            TotalProspectBounced: "Total Prospect Bounced",
            Bounced: "Bounced",
            ProspectReplied: "Prospect Replied",
            TotalProspectReplied: "Total Prospect Replied",
            Replies: "Replies",
            TotalNegativeReplies: "Total Negative Replies",
            TotalNeutralReplies: "Total Neutral Replies",
            TotalPositiveReplies: "Total Positive Replies",
            NegativeReplies: "Negative Replies",
            NeutralReplies: "Neutral Replies",
            PositiveReplies: "Positive Replies",
        }
    }
};
