import Axios, { AxiosPromise } from 'axios';
import { injectable } from 'inversify';

import {
  Data,
  Model,
  Utils,
  List,
} from '@singularsystems/neo-core';

import TargetMarketAccountCriteria
  from '../Models/TargetMarketAccounts/Query/TargetMarketAccountCriteria';
import TargetMarketSummary
  from '../Models/TargetMarketAccounts/TargetMarketSummary';
import {
  AppService,
  Types,
} from '../Services/AppService';
import EditTargetMarketAccountCommand from '../Views/TargetMarketAccounts/Commands/EditTargetMarketAccount';
import TargetMarketAccountLookup from '../Models/TargetMarketAccounts/TargetMarketAccountLookup';
import TMADeleteCommand from '../Views/TargetMarketAccounts/Commands/TMADeleteCommand';
import TMABulkReprospectCommand from '../Views/TargetMarketAccounts/Commands/TMABulkReprospectCommand';
import TMABulkUndeleteCommand from '../Views/TargetMarketAccounts/Commands/TMABulkUndeleteCommand';
import TMAUnblacklistCommand from '../Views/TargetMarketAccounts/Commands/TMAUnblacklistCommand';
import { ResponseResolver } from '../common/utils';
import ApiResult from '../Models/ApiResult';
import CommandResult from '../Models/InvitedUsers/Commands/CommandResult';

export interface ITargetMarketAccounts {
    getTargetMarketAccounts(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TargetMarketAccountCriteria>>): AxiosPromise<any>,
    getTargetMarketSummary(id: number): AxiosPromise<Model.PlainObject<TargetMarketSummary>>,
    downloadTargetMarketAccounts(request: TargetMarketAccountCriteria): AxiosPromise<any>,
    uploadDocument(filelist: File[] | FileList, countryId: number, targetMarketId: number): AxiosPromise<any>,
    getTemplate(): AxiosPromise<any>,
    getUpdateTemplate(downloadTemplateName: string): AxiosPromise<any>,
    uploadUpdateDocument(filelist: File[] | FileList, clientId: number, updateType: string, downloadTemplateName: string): AxiosPromise<any>,
    EditTargetMarketAccount(editTargetMarketAccountCommand: EditTargetMarketAccountCommand): AxiosPromise<CommandResult>,
    DeleteTargetMarketAccount(targetMarketAccountDeletedStateCommand : TMADeleteCommand ): AxiosPromise<any>,
    DeleteTargetMarketAccount2(TargetMarketAccountLookup: List<TargetMarketAccountLookup>, targetMarketId: number): AxiosPromise<any>
    UndoDeleteTargetMarketAccount(BulkUndeleteCommand: TMABulkUndeleteCommand): AxiosPromise<CommandResult>
    ReprospectingTargetMarketAccounts(BulkReprospectCommand: TMABulkReprospectCommand): AxiosPromise<CommandResult>
    GetTargetMarketAccountTabs(targetmarkertId: number): AxiosPromise<any>
    BulkOrSingleUnblacklist(UnblacklistCommand: TMAUnblacklistCommand): AxiosPromise<any>
}

@injectable()
export default class TargetMarketAccountsApiClient implements ITargetMarketAccounts {
    
    public uploadDocument(filelist: File[] | FileList, countryId: number, targetMarketId: number) {
        const apiPath = AppService.get(Types.Config).apiPath;

        const bodyFormData = new FormData();
        bodyFormData.set('files', filelist[0]);
        bodyFormData.set('countryId', countryId.toString());
        bodyFormData.set('targetMarketId', targetMarketId.toString());

        return Axios.post(`${apiPath}/TargetMarketAccount/UploadDocument`, bodyFormData, {timeout:600000});
    }

    public uploadUpdateDocument(filelist: File[] | FileList, clientId: number, updateType: string, downloadTemplateName: string) {
        const apiPath = AppService.get(Types.Config).apiPath;

        const bodyFormData = new FormData();
        bodyFormData.set('files', filelist[0]);
        bodyFormData.set('clientId', clientId.toString());
        bodyFormData.set('updateType', updateType)
        bodyFormData.set("downloadTemplateName", downloadTemplateName)

        return Axios.post(`${apiPath}/TargetMarketAccount/UploadUpdateDocument`, bodyFormData);
    }

    public getTemplate(): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get<ApiResult>(`${apiPath}/TargetMarketAccount/DownloadTemplate`));
    }

    public getUpdateTemplate(downloadTemplateName: string): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get<ApiResult>(`${apiPath}/TargetMarketAccount/DownloadUpdateTemplate?downloadTemplateName=${downloadTemplateName}`));
    }

    public downloadTargetMarketAccounts(request: TargetMarketAccountCriteria): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get<ApiResult>(`${apiPath}/TargetMarketAccount/DownloadTargetMarketAccounts?TargetMarketId=${request.targetMarketId}&SearchString=${request.searchString}`));
    }

    public getTargetMarketSummary(id: number): AxiosPromise<Model.PlainObject<TargetMarketSummary>> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<TargetMarketSummary>(Axios.get<ApiResult>(`${apiPath}/TargetMarketAccount/GetTargetMarketSummary/${id}`));
    }

    public getTargetMarketAccounts(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TargetMarketAccountCriteria>>): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.get(`${apiPath}/TargetMarketAccount/GetTargetMarketAccounts?${Utils.getQueryString(request)}`);
    }

    EditTargetMarketAccount(editTargetMarketAccountCommand: EditTargetMarketAccountCommand): AxiosPromise<CommandResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${apiPath}/TargetMarketAccount/EditTargetMarketAccount`, editTargetMarketAccountCommand.toJSObject()));
    }
    DeleteTargetMarketAccount(targetMarketAccountDeletedStateCommand: TMADeleteCommand): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.post<ApiResult>(`${apiPath}/TargetMarketAccount/DeleteTargetMarketAccount`, targetMarketAccountDeletedStateCommand.toJSObject()));
    }
    DeleteTargetMarketAccount2(TargetMarketAccountLookup: List<TargetMarketAccountLookup>, targetMarketId: number): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.post(`${apiPath}/TargetMarketAccount/DeleteTargetMarketAccount2?TargetMarketId=${targetMarketId}`, TargetMarketAccountLookup.toJSArray());
    }
    UndoDeleteTargetMarketAccount(BulkUndeleteCommand: TMABulkUndeleteCommand): AxiosPromise<CommandResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${apiPath}/TargetMarketAccount/UndoDeleteTargetMarketAccount`, BulkUndeleteCommand.toJSObject()));
    }
    ReprospectingTargetMarketAccounts(BulkReprospectCommand: TMABulkReprospectCommand): AxiosPromise<CommandResult> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${apiPath}/TargetMarketAccount/ReprospectingTargetMarketAccounts`, BulkReprospectCommand.toJSObject()));
    }

    GetTargetMarketAccountTabs(targetMarkertId: number): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.get(`${apiPath}/TargetMarketAccount/GetTargetMarketAccountTabs?TargetMarketId=${targetMarkertId}`);
    }
    
    BulkOrSingleUnblacklist(UnblacklistCommand: TMAUnblacklistCommand): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.post<ApiResult>(`${apiPath}/TargetMarketAccount/UnblacklistAccount`, UnblacklistCommand.toJSObject()));
    }
}