import { List, ModelBase, NeoModel, Validation} from '@singularsystems/neo-core';
import ManagementSubRole from './ManagementSubRole';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class ManagementRole extends ModelBase {

    managementRoleId: number = 0;

    roleName: string = "";

    isExpanded: boolean = false

    public managementSubRoles = new List(ManagementSubRole)

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.roleName === "", textConstants.messageText.validationMessage.managementRole);
    }

    public toString(): string {
        if (this.isNew || !this.roleName) {
            return "New Sub Role";
        } else {
            return this.roleName;
        }
    }
}