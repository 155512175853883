import { NeoModel, List, Data, Model, Misc } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../../Services/AppService';
import InviteCommand from '.././../../Models/InvitedUsers/Commands/InviteCommand';
import CommandResult from '../../../Models/InvitedUsers/Commands/CommandResult';
import UnblockCommand from './../../../Models/InvitedUsers/Commands/BlockCommand';
import BlockCommand from './../../../Models/InvitedUsers/Commands/BlockCommand';
import RoInvitedUserCriteria from './../LookUps/RoInvitedUserLookupCriteria';
import RoInvitedUserLookup from './../LookUps/RoInvitedUserLookup';
import { InvitedUserStatus } from '../../../Models/Enums/ApplicationEnums';
import { textConstants } from '../../../common/textConstants';
import AuthorisationTypes from '../../../Authorisation/AuthorisationTypes';
import UserGroupLookup from '../../../Authorisation/Models/UserGroupLookup';
import DeleteCommand from '../../../Models/InvitedUsers/Commands/DeleteCommand';
import ReInviteCommand from '../../../Models/InvitedUsers/Commands/ReInviteCommand';
import { reaction } from 'mobx';
import UnlockCommand from '../../../Models/InvitedUsers/Commands/UnlockCommand';
import EditUserCommand from '../../../Models/InvitedUsers/Commands/EditUserCommand';
import ClientListLookup from '../../../Models/Client/ClientListLookup';
import SearchFieldVM from '../../../Components/SearchFieldVM';

@NeoModel
export default class InvitedUsersViewModel extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private config = AppService.get(Types.Config),
    private invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient),
    private AuthorisationApiClient = Misc.Globals.appService.get(AuthorisationTypes.ApiClients.AuthorisationApiClient),
    private customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
    private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient)
  ) {

    super(taskRunner);
    if (this.customAuthService.globalProps.clientId === 0) {
      this.customAuthService.globalProps.isOnboarding = false;
    }
  }

  public displayUserGroupValidationMessage: boolean = false;
  public showDeleteModal: boolean = false;
  public showInviteModal: boolean = false;
  public showUnblockModal: boolean = false;
  public showBlockModal: boolean = false;
  public showEditModal: boolean = false;
  public inviteCommand: InviteCommand = new InviteCommand();
  public deleteCommand: DeleteCommand = new DeleteCommand();
  public commandResult: CommandResult | null = null;
  public unblockCommand: UnblockCommand = new UnblockCommand();
  public unlockUserCommand: UnlockCommand = new UnlockCommand();
  public blockCommand: BlockCommand = new BlockCommand();
  public editUserCommand: EditUserCommand = new EditUserCommand();
  public userGroups = new List(UserGroupLookup);
  public clientList = new List(ClientListLookup);
  public userGroupsToAdd = new List(UserGroupLookup);
  public InvitedUserListPage = {}
  public invitedUserPage: boolean = false;

  public searchFieldVM = new SearchFieldVM();

  public BlockedUsersTab = new Model.ObservableValue(textConstants.titleText.BlockedUsers)
  public ActiveUsersTab = new Model.ObservableValue(textConstants.titleText.ActiveUsers)
  public InviteUsersTab = new Model.ObservableValue(textConstants.titleText.InvitedUsers)
  public LockedUsersTab = new Model.ObservableValue(textConstants.titleText.LockedUsers)

  public clientId: number = 0

  private roInvitedUserCriteria: RoInvitedUserCriteria = new RoInvitedUserCriteria()
  public pageManagerInvited = new Data.PageManager(
    this.roInvitedUserCriteria, RoInvitedUserLookup,
    this.invitedUsersApiClient.getInvitedUsersPaged,
    { pageSize: 10, sortBy: "inviteEmail", fetchInitial: false });

  private roIBlockedUserCriteria: RoInvitedUserCriteria = new RoInvitedUserCriteria()
  public pageManagerBlocked = new Data.PageManager(
    this.roIBlockedUserCriteria, RoInvitedUserLookup,
    this.invitedUsersApiClient.getBlockedUsersPaged,
    { pageSize: 10, sortBy: "inviteEmail", fetchInitial: false });

  private roIActiveCriteria: RoInvitedUserCriteria = new RoInvitedUserCriteria()
  public pageManagerActive = new Data.PageManager(
    this.roIActiveCriteria, RoInvitedUserLookup,
    this.invitedUsersApiClient.getActiveUsersPaged,
    { pageSize: 10, sortBy: "inviteEmail", fetchInitial: false });

  private roILockedUserCriteria: RoInvitedUserCriteria = new RoInvitedUserCriteria()

  public pageManagerLocked = new Data.PageManager(
    this.roILockedUserCriteria, RoInvitedUserLookup,
    this.invitedUsersApiClient.getLockedUsersPaged,
    { pageSize: 10, sortBy: "inviteEmail", fetchInitial: false });

  public selectedTab: string = textConstants.titleText.InvitedUsers

  public async initialise() {
    // load the user groups, await these
    const response = await this.taskRunner.waitFor(this.AuthorisationApiClient.userGroups.getLookupAsync());
    this.userGroups.set(response.data);

    await this.getClientList();

    const disposer = reaction(() => this.clientId, () => { }, { fireImmediately: true, delay: 0 })
  }

  private async getClientList() {
    this.taskRunner.run(async () => {
      let results = await this.clientsApiClient.getClientsList()

      if (results) {
        this.clientList.set(results.data)
      }
    })
  }

  public async setupComponent() {
    // Setup filters
    this.roInvitedUserCriteria.id = 0;
    this.roInvitedUserCriteria.inviteEmail = "";
    this.roInvitedUserCriteria.invitedUserStatus = InvitedUserStatus.InvitedUser;
    this.roInvitedUserCriteria.clientId = this.clientId;

    this.roIBlockedUserCriteria.id = 0;
    this.roIBlockedUserCriteria.inviteEmail = "";
    this.roIBlockedUserCriteria.invitedUserStatus = InvitedUserStatus.BlockedUser;
    this.roIBlockedUserCriteria.clientId = this.clientId;

    this.roIActiveCriteria.id = 0;
    this.roIActiveCriteria.inviteEmail = "";
    this.roIActiveCriteria.invitedUserStatus = InvitedUserStatus.Active;
    this.roIActiveCriteria.clientId = this.clientId;
  }

  public toggleTab(clientId: number, st: string) {

    if (st === this.BlockedUsersTab.value) {
      this.roIBlockedUserCriteria.clientId = clientId;
      this.pageManagerBlocked.refreshData();
    } else if (this.ActiveUsersTab.value === st) {
      this.roIActiveCriteria.clientId = clientId;
      this.pageManagerActive.refreshData();
    } else if (this.LockedUsersTab.value === st) {
      this.roILockedUserCriteria.clientId = clientId;
      this.pageManagerLocked.refreshData();
    } else {
      this.roInvitedUserCriteria.clientId = clientId;
      this.pageManagerInvited.refreshData();
    }
  }

  public displayInviteModal(value: boolean) {
    this.showInviteModal = value;
    if (this.showInviteModal) {

      this.clearInvite();

      // Assign User Groups
      if (this.clientId !== 0) {
        const clientUserGroup = this.userGroups.find((e) => {
          return e.userGroupName === "Client";
        })

        if (clientUserGroup != null) {
          this.userGroupsToAdd.push(clientUserGroup);
        }
      }
    }
  }

  public displayUnblockModal(value: boolean, userid: number) {
    this.showUnblockModal = value;
    if (this.showUnblockModal) {
      this.unblockCommand = new UnblockCommand();
      this.unblockCommand.id = userid;
    }
  }

  public displayBlockModal(value: boolean, userid: number) {
    this.showBlockModal = value;
    if (this.showBlockModal) {
      this.blockCommand = new BlockCommand();
      this.blockCommand.id = userid;
    }
  }

  public displayEditUserModal(value: boolean, userData?: RoInvitedUserLookup) {
    this.showEditModal = value;

    if (this.showEditModal) {
      this.editUserCommand = new EditUserCommand();
      this.editUserCommand.id = userData!.id;
      this.editUserCommand.identityGuid = userData!.registeredUserId;
      this.editUserCommand.firstName = userData!.firstName;
      this.editUserCommand.lastName = userData!.lastName;
      this.editUserCommand.email = userData!.inviteEmail;
      this.editUserCommand.clientid = userData!.clientId
    }
  }

  public displayDeleteModal(value: boolean, id: number) {
    this.showDeleteModal = value;
    if (this.showDeleteModal) {
      this.deleteCommand = new DeleteCommand();
      this.deleteCommand.id = id;
    }
  }

  public InvitedUserToDelete() {
    const id = this.deleteCommand.id;
    let email = "";

    this.pageManagerInvited.data.forEach(element => {
      if (element.id === id) {
        email = element.inviteEmail;
      }
    });

    return email;
  }

  public async FetchData() {
    this.pageManagerActive.refreshData();
    this.pageManagerBlocked.refreshData();
    this.pageManagerInvited.refreshData();
  }

  public clearInvite() {
    this.inviteCommand = new InviteCommand();

    while (this.userGroupsToAdd.length > 0) {
      this.userGroupsToAdd.pop();
    }
  }

  public async inviteUser() {

    // Check user group validation
    if (this.userGroupsToAdd.length === 0) {
      this.displayUserGroupValidationMessage = true;
    }
    else {

      let inValid = false;

      this.userGroupsToAdd.forEach(element => {
        if (element.userGroupId === 0 || element.userGroupId === null) {
          inValid = true;
        }
      });

      this.displayUserGroupValidationMessage = inValid;
    }

    if (this.displayUserGroupValidationMessage === false) {
      this.displayInviteModal(false);

      this.taskRunner.run(async () => {

        this.userGroupsToAdd.map((e: UserGroupLookup) => {
          this.inviteCommand.userGroups.push(e.userGroupId);
        });

        // Invite User
        const response = await this.invitedUsersApiClient.invite(this.inviteCommand);
        this.commandResult = CommandResult.fromJSObject<CommandResult>(response.data);

        if (this.commandResult.success) {

          this.notifications.addSuccess("User invited", `${this.inviteCommand.inviteEmail} has been invited`);

          // Create a new ClientUser
          if (this.clientId  !== 0) {
            const resp = await this.invitedUsersApiClient.inviteClientUser(this.clientId, this.commandResult.id, this.inviteCommand.inviteEmail);
            const cr = CommandResult.fromJSObject<CommandResult>(resp.data);

            if (!cr.success) {
              this.notifications.addDanger("Error", `${this.inviteCommand.inviteEmail} has been invited, however the associated ClientUser was not created.`);
            }
          }

          this.toggleTab(this.clientId, this.InviteUsersTab.value);

          // Clear Invite
          this.clearInvite();
        }
        else {
          this.notifications.addDanger("Error", this.commandResult.error);

          // Clear Invite
          this.clearInvite();
        }
      });
    }
  }

  public unblockUser() {
    this.taskRunner.run(async () => {

      const response = await this.invitedUsersApiClient.unblock(this.unblockCommand);

      this.commandResult = CommandResult.fromJSObject<CommandResult>(response.data);

      if (this.commandResult.success) {
        this.displayUnblockModal(false, 0);
        this.notifications.addSuccess("User unblocked", `Selected user has been unblocked`);
        this.unblockCommand = new UnblockCommand();
        this.toggleTab(this.clientId, this.BlockedUsersTab.value);
      }
    });
  }

  public blockUser() {
    this.taskRunner.run(async () => {

      const response = await this.invitedUsersApiClient.block(this.blockCommand);

      this.commandResult = CommandResult.fromJSObject<CommandResult>(response.data);

      if (this.commandResult.success) {
        this.displayBlockModal(false, 0);
        this.notifications.addSuccess(textConstants.titleText.UserBlocked, textConstants.titleText.UserBlockedMessage);
        this.blockCommand = new BlockCommand();
        this.toggleTab(this.clientId, this.ActiveUsersTab.value);
      }
    });
  }

  public async updateUserDetails() {
    this.taskRunner.run(async () => {
      let result = await this.invitedUsersApiClient.updateUserDetails(this.editUserCommand);

      if (result) {
        this.showEditModal = false
        this.notifications.addSuccess(textConstants.titleText.EditUser, textConstants.titleText.UserUpdatedMessage);
        this.editUserCommand = new EditUserCommand();
        this.toggleTab(this.clientId, this.ActiveUsersTab.value);
      }
    });
  }

  public async unlockLockedUser(userid: string) {
    this.unlockUserCommand.id = userid;
    const response = await this.invitedUsersApiClient.unlockUser(this.unlockUserCommand);
    this.commandResult = CommandResult.fromJSObject<CommandResult>(response.data);

    if (this.commandResult.success) {
      this.notifications.addSuccess(textConstants.titleText.UserUnlocked, textConstants.titleText.UserUnlockedMessage);
      this.unlockUserCommand = new UnlockCommand();
      this.toggleTab(this.clientId, this.LockedUsersTab.value);
    }
  }

  public deleteUser() {

    this.displayDeleteModal(false, 0);

    this.taskRunner.run(async () => {

      const response = await this.invitedUsersApiClient.deleteInvitedUser(this.deleteCommand);

      this.commandResult = CommandResult.fromJSObject<CommandResult>(response.data);

      if (this.commandResult.success) {
        this.notifications.addSuccess("User deleted", `Selected user has been deleted`);
        this.toggleTab(this.clientId, this.InviteUsersTab.value);
      }
      else {
        this.notifications.addDanger("Error saving", this.commandResult.error);
      }
    });
  }

  public reinviteUser(id: number) {

    this.taskRunner.run(async () => {

      const reInviteCommand = new ReInviteCommand();
      reInviteCommand.InvitedUserId = id;

      const response = await this.invitedUsersApiClient.reInviteUser(reInviteCommand);

      this.commandResult = CommandResult.fromJSObject<CommandResult>(response.data);

      if (this.commandResult.success) {
        this.notifications.addSuccess("User Invite", `Selected user has been invited.`);
        this.toggleTab(this.clientId, this.InviteUsersTab.value);
      }
      else {
        this.notifications.addDanger("Error", this.commandResult.error);
      }
    });
  }
}