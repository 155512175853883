import styled from 'styled-components';

interface IStyledDropDownContainerProps {
    isDisabled?: boolean;
    isReadOnly?: boolean;
    variant?: "contained";
}

const currentVariant = (value: string | undefined, disabled?: boolean) => {

    let newStyles = "";

    switch (value) {
        case "contained":
            newStyles =
            `padding: 6px 12px;
            border-radius: 8px;
            background-color: #f8f8f8;
            height: 48px;
            
            ${disabled ? `opacity: 0.4;` : ""} `;
            break;

        default:
            break;
    }
    return newStyles
}

export const StyledDropDownContainer = styled.div<IStyledDropDownContainerProps>`
    text-align: left;
    position: relative;
    border-radius: 5px;
    background: #edeeee;
    border-radius: 30px;
    padding: 8px 16px;
    
    font-family: 'Roboto', sans-serif;
    cursor: pointer;

    
    ${({ isDisabled }) =>
        isDisabled &&
        `cursor: not-allowed;
    pointer-events: none;

  `};

  ${({ variant, isDisabled }) => currentVariant(variant, isDisabled)};

  ${({isReadOnly}) => isReadOnly && `opacity: 1`};
`;

export const StyledDropDownInput = styled.div`
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
`;

interface IStyledDropDownMenuProps {
    offset?: number;
    topPositioning: boolean;
}
export const StyledDropDownMenu = styled.div<IStyledDropDownMenuProps>`
    position: absolute;

    ${({topPositioning, offset}) => (topPositioning ? `top: ${offset}px` : `bottom: ${offset}px`)};

    width: 100%;
    overflow: auto;
    background: #F8F8F8;
    border-radius: 8px;
    z-index: 99;
    left: 0px;
    max-height: 250px;
`;

interface IStyledDropDownMenuItemProps {
    selected: boolean;
    value?: number;
}

export const StyledDropDownMenuItem = styled.div<IStyledDropDownMenuItemProps>`
    cursor: pointer;
    background: #F8F8F8;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &:hover {
        background-color: #EDEEEE;
    }
    background-color: ${({ selected }) => (selected ? '#EDEEEE' : '#F8F8F8')};
`;

export const StyledDropDownTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`;

export const StyledDropDownTag = styled.div`
    background-color: #ddd;
    padding: 2px 4px;
    border-radius: 2px;
    display: flex;
    align-items: center;
`;

export const StyledDropDownTagClose = styled.div`
    display: flex;
    align-items: center;
`;