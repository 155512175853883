import React from 'react';
import { Views, Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import DashboardVM from './DashboardVM';
import * as Roles from '../../Models/Security/Roles';
import ClientSettingsVM from '../Client/ClientSettingsVM';
import QuickAddModal from '../../Components/QuickAddModal';
import ClientSettingsView from '../Client/ClientSettingsView';
import Types from '../../Modules/AppTypes';
import { AppService } from '../../Services/AppService';
import BatchReviewProspectsView from '../BatchReview/BatchReviewProspectsView';
import BatchReviewUploadVM from '../BatchReview/BatchReviewUploadVM';
import BatchReviewUploadView from '../BatchReview/BatchReviewUploadView';
import BatchReviewProspectsVM from '../BatchReview/BatchReviewProspectsVM';
import ReactPlayer from 'react-player';
import HtmlParser from 'react-html-parser';
import OnboardingModal from '../../Components/Modals/OnboardingModal';
import { isMobile, setTextForClipboard } from '../../common/utils';
import CopyToClipboard from 'react-copy-to-clipboard';
import { List, NotifyUtils } from '@singularsystems/neo-core';
import { RefObject } from 'react';
import AppLayout, { ScreenSize } from '../../Components/AppLayout';
import { NavLink } from 'react-router-dom';
import { blacklistCampaignsRoute } from '../../RouteConfiguration';
import * as Icon from 'react-feather';
import ClientCampaignWoodpeckerEmailTable from '../../Components/DashboardComponents/ClientCampaignWoodpeckerEmailTable';
import DashboardReports from '../../Components/DashboardComponents/DashboardReports';
import { triggerHotjarEvent } from '../../Components/Hotjar';
import Card from 'Components/Card';
import Button from 'Components/Button';
import Input from 'Components/Input';
import CopyIcon from 'assets/svgs/CopyIcon';

import {
  StyledCardWrapper,
  StyledCardContent,
  StyledCardTitle,
  StyledWelcomeModalContent,
  StyledCardTextSpan
} from './Styles/styles';
import DashboardActionListCard from 'Components/DashboardActionListCard';
import QuickAddItem from 'Models/BlacklistedDomains/QuickAddDomain';

class DashboardViewParams {
  public clientId = {}
  public dashboardMode = {} // TargetMarkets AddTargetMarket Blacklist
  public clientCampaignId = {}
  public hasSeenTutorial = {}
}

@observer
export default class DashboardView extends Views.ViewBase<DashboardVM, DashboardViewParams> {

  private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService)
  protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService)

  constructor(props: unknown) {
    super(textConstants.titleText.DashBoard, DashboardVM, props);
    this.tooltipReference = React.createRef();
    if (isMobile()) {
      this.authenticationService.globalProps.showSidemenu = false
    }
  }

  public tooltipReference: RefObject<any>

  static params = new DashboardViewParams();

  private showCopySuccess() {
    const variant = "success"
    NotifyUtils.add(variant, textConstants.messageText.saveMessage.TextToClipboard,
      variant as any, 5);
  }

  public updateClientParams(description: string, description1: string) {
    this.viewParams.dashboardMode.description = description1;
    this.viewParams.clientId.description = description;
  }

  public batchReviewRedirection() {
    if (this.viewModel.batchReviewUploadId === null || this.viewModel.batchReviewUploadId === 0) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const data = urlParams.get('brid')

      if (data !== null) {
        this.viewModel.batchReviewUploadId = parseInt(data)
        this.viewModel.isEntryViaLink = true;
        this.viewModel.showDashboard = false
      }
    }
  }

  componentWillUnmount(): void {
    if (isMobile()) {
      this.authenticationService.globalProps.showSidemenu = false;
    }
  }

  public async viewParamsUpdated() {
    const clientId = this.viewParams.clientId.asNullableInt()

    this.authenticationService.globalProps.showSidemenu = !isMobile()

    if (clientId !== null) {
      // Blacklist
      if (this.viewParams.dashboardMode.value === textConstants.titleText.Blacklist) {
        // Initialize ViewModel
        this.viewModel.ClientSettingsViewModel = new ClientSettingsVM();
        this.viewModel.ClientSettingsViewModel.taskRunner = this.viewModel.taskRunner;
        this.viewModel.ClientSettingsViewModel.clientId = clientId

        // Set values & fetch data
        const clientName = await this.viewModel.getClientName(clientId);
        this.viewModel.ClientSettingsViewModel.clientName = clientName
        this.updateClientParams(clientName, this.viewModel.showBlacklistToClient === true ? textConstants.titleText.Blacklist : textConstants.titleText.BatchReview);
        await this.viewModel.ClientSettingsViewModel.fetchClient(clientId);
        this.viewModel.showDashboard = false
        this.viewModel.showBlacklist = true
      }

      // Batch Review VM initialization
      else if (this.viewParams.dashboardMode.value === textConstants.titleText.BatchReview) {
        this.viewModel.showDashboard = false;
        this.viewModel.BatchReviewUploadViewModel = new BatchReviewUploadVM();
        this.viewModel.BatchReviewUploadViewModel.BatchReviewUploadCriteria.isComXUser = false;
        this.viewModel.BatchReviewUploadViewModel.BatchReviewUploadCriteria.clientId = clientId;
        const clientName = await this.viewModel.getClientName(clientId);
        this.viewModel.setClientDisplayName(clientName);
        this.updateClientParams(clientName, textConstants.generalText.BatchReview);
        this.viewModel.showBatchReview = true;
        this.viewModel.BatchReviewUploadViewModel.batchReviewUploadPageManager.refreshData();
      }

      // Batch Review Prospects
      else if (this.viewParams.dashboardMode.value === textConstants.titleText.BatchReviewProspects) {
        // Will set the correct BatchReviewId based on the query params found in the URL
        // If nothing, normal process will resume
        this.batchReviewRedirection();

        this.viewModel.showBatchReview = false;

        this.viewModel.BatchReviewProspectsViewModel = new BatchReviewProspectsVM();
        this.viewModel.BatchReviewProspectsViewModel.isComXUser = false;
        this.viewModel.BatchReviewProspectsViewModel.taskRunner = this.taskRunner
        this.viewModel.BatchReviewProspectsViewModel.criteria.batchReviewUploadId = this.viewModel.batchReviewUploadId;
        this.viewModel.BatchReviewProspectsViewModel.criteria.clientId = clientId;

        this.viewModel.BatchReviewProspectsViewModel.clientId = clientId;

        let shouldContinueToBatchReview = true;

        if (this.viewModel.isEntryViaLink) {
          let canOpenBatchReview = await this.taskRunner.waitFor(this.viewModel.BatchReviewProspectsViewModel.canOpenBatchReview(clientId, this.viewModel.batchReviewUploadId))

          if (!canOpenBatchReview) {
            shouldContinueToBatchReview = false;
          }
        }

        if (shouldContinueToBatchReview) {
          await this.viewModel.BatchReviewProspectsViewModel.fetchData(clientId)

          const clientName = await this.viewModel.getClientName(clientId);
          this.viewModel.setClientDisplayName(clientName);
          this.updateClientParams(clientName, textConstants.generalText.BatchReview);

          this.viewModel.showBatchReviewProspects = true;
          this.viewModel.showDashboard = false;
        }
        else {
          this.viewModel.showDashboard = true;
          this.viewParams.dashboardMode.value = ""
        }
      }

      else if (this.viewParams.dashboardMode.value === textConstants.Routes.CampaignEmailOverview) {
        this.viewModel.showCampaignOverview = true;
        this.viewParams.dashboardMode.description = textConstants.Routes.CampaignEmailOverview;
        this.viewModel.showDashboard = false
      }

      // Handle selecting the client name breadcrumb element
      if (clientId !== null &&
        (this.viewParams.dashboardMode.value !== null || this.viewParams.dashboardMode.value !== "") &&
        this.viewModel.initialDashboardLoad
      ) {
        // Do nothing
        this.viewModel.initialDashboardLoad = false;
      }
    }

    else {
      this.viewModel.initialDashboardLoad = true;
      this.viewModel.showBatchReview = false;
      this.viewModel.showBatchReviewProspects = false;
      this.viewModel.showEmailSignature = false
      this.viewModel.showDashboard = true
      this.viewModel.showBlacklist = false
      this.viewModel.showBlacklistToClient = false
      this.viewModel.showBatchReviewToClient = false
      this.viewModel.isEntryViaLink = false;
      this.viewModel.showCampaignOverview = false;
    }
  }

  public openUrl(url: string) {
    if (url === "") {
      this.viewModel.notifications.addDanger("Error", `No url has been specified.`, 5);
    } else {
      triggerHotjarEvent(textConstants.Events.clientOpenHub)
      window.open(url, '_blank');
    }
  }

  public checkUrlValid(url: string) {
    if (url) {
      if (url.length > 0) {
        return true;
      }
    }
    return false;
  }

  public getViewStyling() {
    let styling = "DashboardView"
    if (AppLayout.current.currentScreenSize === ScreenSize.Small) {
      styling = "DashboardViewMobileVersion"
    }
    else if (AppLayout.current.currentScreenSize === ScreenSize.ExtraSmall) {
      styling = "DashboardViewMobileVersion"
    }
    return styling
  }

  private setChevronStyle() {
    if (this.viewModel.isCSTeamVideoExpanded) {
      return "csTeamVideoArrowUp"
    } else {
      return "csTeamVideoArrowDown"
    }
  }

  private updateBlacklistQuickAddInputValue(b: any) {
    const domain = b.target.value;

    this.viewModel.blacklistDomain = domain
  }

  // Main Version Start
  public render() {

    return (
      <React.Fragment>
        {this.viewModel.showDashboard &&
          <div className={this.getViewStyling()}>
            {/* CS Team Video SECTION */}

            <div>
              {!this.viewParams.hasSeenTutorial.value &&
                <div className="m-4">
                  {/* Large view */}
                  {this.viewModel.showDashboardNews &&
                    <div className="row csTeamVideoSection">

                      {/* Video */}
                      {this.viewModel.highlyRecommendedVideoLink &&
                        <div className="col-md-4 pl-2 pr-2" >
                          <ReactPlayer
                            width={""}
                            height={"30vh"}
                            controls={true}
                            className="mobileVideoStyle"
                            url={this.viewModel.highlyRecommendedVideoLink}>
                          </ReactPlayer>
                        </div>
                      }
                      {!this.viewModel.highlyRecommendedVideoLink && this.viewModel.imageData &&
                        <div className="pull-right pt-3 pb-3 pl-3 pr-3">
                          <img className="dashboard-card-image" src={`data:image/jpeg;base64,${this.viewModel.imageData}`} />
                        </div>
                      }

                      {/* Text */}
                      <div className="col pl-2 pr-2 hrecommendedBody">
                        <div className="hrecommendedSubtitle">
                          <i>{<Icon.Play />}</i>
                          <span className="pr-1">{textConstants.generalText.highlyRecommendedSubtitle1}</span>
                          <span className="highlyRecommendedCS mr-1">CS</span>
                          <span>{textConstants.generalText.highlyRecommendedSubtitle2}</span>
                        </div>
                        {HtmlParser(this.viewModel.highlyRecommendedHeader)}
                        {HtmlParser(this.viewModel.highlyRecommendedBody)}
                        {this.viewModel.highlyRecommendedRedirect &&
                          <a className="highlyRedirect" onClick={() => { window.open(this.viewModel.highlyRecommendedRedirect) }}>
                            <Icon.ExternalLink style={{ paddingBottom: "3px", color: "#111e1e" }} />
                            Open in new tab
                          </a>}
                      </div>

                      {/* Expand / Collapse button */}

                      <div className="col-1 mt-3 p-0 pr-4">
                        <button
                          className={this.setChevronStyle()}
                          onClick={() => {
                            this.viewModel.markDashboardNewsAsSeen()
                            this.viewModel.showDashboardNews = false
                          }}>
                          <Icon.X />
                        </button>
                      </div>
                    </div>
                  }
                </div>
              }

              {/* ACTION LIST CARD SECTION */}
              {this.authorisationService.hasRole(Roles.ActionList.Access) && this.viewModel.actionListGrandTotal > 0 && !isMobile() &&
                <DashboardActionListCard viewModel={this.viewModel} />
              }

              {/* WELCOME SECTION */}
              <DashboardReports
                viewModel={this.viewModel}
                viewCampaignDetails={(clientCampaignId: number) => {
                  this.viewParams.clientId.value = this.viewModel.clientId
                  this.viewParams.clientCampaignId.value = clientCampaignId
                  this.viewParams.dashboardMode.value = textConstants.Routes.CampaignEmailOverview;
                  this.viewModel.clientCampaignId = clientCampaignId;
                }}
              />

              {/* CARDS SECTION */}
              <StyledCardWrapper isMobile={isMobile()}>

                {/*ComX Hub Card Start */}
                <Card>
                  <StyledCardContent>
                    <StyledCardTitle>{textConstants.titleText.ComXHub}</StyledCardTitle>
                    <StyledCardTextSpan>{HtmlParser(this.viewModel.visitComXBody)}</StyledCardTextSpan>
                    <Input
                      className="mb-0"
                      label={textConstants.generalText.DashboardPasswordLabel}
                      disabled={true}
                      fullWidth
                      key={this.viewModel.meta.knowledgeHubPassword.value}
                      value={this.viewModel.meta.knowledgeHubPassword.value}
                      endIcon={<CopyToClipboard text={setTextForClipboard(this.viewModel.knowledgeHubPassword)} onCopy={() => this.showCopySuccess()}>
                        <i className="appendedIcon">{<CopyIcon />}</i>
                      </CopyToClipboard>}
                    />
                    {/* Visit Hub button */}
                    <Button
                      variant="contained"
                      size='medium'
                      buttonType="primary"
                      onClick={() => { this.openUrl(this.viewModel.visitComXCardRedirect) }}>
                      <span>{textConstants.generalText.VisitHub}</span>
                    </Button>
                  </StyledCardContent>
                </Card>
                {/* ComX Hub Card End */}

                {/* Blacklist Card */}
                {this.authorisationService.hasRole(Roles.Blacklist.Manage) &&
                  <Card>
                    <StyledCardContent>
                      <StyledCardTitle>{textConstants.titleText.DashboardBlacklist}  <NavLink key={blacklistCampaignsRoute.path} to={blacklistCampaignsRoute.path}>
                        <span>View blacklist</span>
                      </NavLink>
                      </StyledCardTitle>
                      <StyledCardTextSpan>{HtmlParser(this.viewModel.blacklistBody)}</StyledCardTextSpan>

                      <div className="quickAddError">
                        <Input
                          className="mb-0"
                          label={textConstants.titleText.Domain}
                          onChange={(b: any) => this.updateBlacklistQuickAddInputValue(b)}
                          placeholder={"domain.tld"}
                          fullWidth
                        />

                        {this.viewModel.blacklistErrorMessage !== "" &&
                          <p className="infoLabel danger customMarginBottom" >
                            {this.viewModel.blacklistErrorMessage}
                          </p>
                        }
                      </div>

                      {/* Add to Blacklist button */}
                      <Button
                        variant="contained"
                        size='medium'
                        buttonType="primary"
                        onClick={async () => {
                          await this.viewModel.addToBlacklist()
                        }}
                      >{textConstants.titleText.DashboardBlacklist}
                      </Button>

                      {/* View blacklist button */}

                    </StyledCardContent>
                  </Card>
                }
                {/* Blacklist Card End */}

                {/*Book Support Meeting Card */}
                <Card>
                  <StyledCardContent>
                    <StyledCardTitle>{textConstants.titleText.BookSupport}</StyledCardTitle>
                    <StyledCardTextSpan>{HtmlParser(this.viewModel.bookSupportBody)}</StyledCardTextSpan>
                    {/* Register for Workshop button */}
                    <div><br /></div>
                    <Button
                      variant="contained"
                      size='medium'
                      buttonType="primary"
                      onClick={() => { this.openUrl(this.viewModel.bookSupportRedirect) }}>
                      <span>{textConstants.generalText.RegisterBookMeeting}</span>
                    </Button>
                  </StyledCardContent>
                </Card>
                {/* Book Support Meeting Card End */}
              </StyledCardWrapper>


              {this.viewModel.showBlacklist &&
                <ClientSettingsView viewModel={this.viewModel.ClientSettingsViewModel}
                  back={() => {
                    this.viewModel.navigateList.pop();
                    this.viewModel.viewName = this.viewModel.navigateList[this.viewModel.navigateList.length - 1];
                    if (this.viewModel.viewName === "DashboardView") {
                      this.viewParams.clientId.value = null
                    } else {
                      this.viewParams.dashboardMode.value = this.viewModel.viewName;
                      this.viewParams.clientId.value = null
                    }
                  }}
                  navigateToTargetMarket={() => {
                    this.viewModel.navigateList.push(textConstants.generalText.TargetMarkets);
                    this.viewParams.clientId.value = this.viewParams.clientId.value;
                    this.viewParams.dashboardMode.value = textConstants.generalText.TargetMarkets;
                    this.viewModel.showBlacklist = false;
                  }}
                  openBatchReviewProspects={(batchReviewUploadId: number) => {
                  }}
                >
                </ClientSettingsView>
              }

              {/* Quick Add Blacklist Modal*/}
              {!this.viewModel.showBlacklist &&
                <QuickAddModal
                  title={textConstants.titleText.QuickAddDomain}
                  show={this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.showQuickAddModal}
                  onClose={() => {
                    this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.cleanUpProcess(true)
                    this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.showQuickAddModal = false
                    this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.QuickAddStringList = new List(QuickAddItem)
                  }}
                  onSubmit={async () => {
                    await this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.saveQuickAdd(true)
                  }}
                  quickAddItem={this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.QuickAddString}
                  addPlaceholder={textConstants.titleText.AddBlacklistDomain}
                  addOnClick={(e: any) => {
                    this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.validateNewDomainEntry(this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.QuickAddString, true)
                  }}
                  validateQuickAddItem={this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.QuickAddErrorDomains}
                  addedItemsList={this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.QuickAddStringList}
                  removeItem={(e: any, domain: any) => { this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.QuickAddStringList.remove(domain); }}
                  validationMessage={textConstants.messageText.validationMessage.quickAddErrorMessage}
                  description={textConstants.generalText.BlacklistQuickAddText}
                  validationUnsuccessful={this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.hasValidationFailed}
                />
              }

              {/*Founders Modal*/}
              <OnboardingModal
                extraClass="foundersModal"
                isOpen={this.viewModel.showFoundersVideo}
                acceptAndCloseButton={{
                  text: textConstants.Onboarding.LetsContinue,
                  onClose: () => {
                    this.viewModel.showFoundersVideo = false
                    this.viewModel.UpdateTutorialSeen();
                  }
                }}
                closeModal={() => {
                  this.viewModel.showFoundersVideo = false
                  this.viewModel.UpdateTutorialSeen();
                }}
                content={
                  <StyledWelcomeModalContent>
                    {/*The Video*/}
                    <ReactPlayer
                      className="videoRadius"
                      width="100%"
                      height={"100%"}
                      controls={true}
                      url={this.viewModel.foundersVideoURL}
                    />
                    <div>
                      {HtmlParser(this.viewModel.onboardingPopupLookup.text)}
                    </div>
                  </StyledWelcomeModalContent>}
              >
              </OnboardingModal>
            </div>

          </div >
        }

        {this.viewParams.dashboardMode.value === textConstants.Routes.CampaignEmailOverview && this.viewModel.showCampaignOverview &&
          <ClientCampaignWoodpeckerEmailTable
            viewModel={this.viewModel}
            campaignData={this.viewModel.campaignData}
            back={() => {
              this.viewModel.isGridSelected = true;
              this.viewParams.clientId.value = null;
              this.viewParams.clientCampaignId.value = null;
              this.viewParams.dashboardMode.value = null;
              this.viewModel.showDashboard = true;
              this.viewModel.firstFetch = true;
              this.viewModel.showCampaignOverview = false;
            }} />
        }

        {this.viewModel.showBatchReview &&
          <BatchReviewUploadView
            viewModel={this.viewModel.BatchReviewUploadViewModel}
            back={() => {
              this.viewParams.clientId.value = null
              this.viewParams.dashboardMode.value = null
            }}
            openBatchReviewProspects={(batchReviewUploadId: number) => {
              this.viewParams.dashboardMode.value = textConstants.titleText.BatchReviewProspects;
              this.viewModel.batchReviewUploadId = batchReviewUploadId;
            }}
            isComXUser={false}
          />
        }

        {this.viewModel.showBatchReviewProspects && this.viewParams.dashboardMode.value === textConstants.titleText.BatchReviewProspects &&
          <BatchReviewProspectsView
            viewModel={this.viewModel.BatchReviewProspectsViewModel}
            back={() => {
              this.viewModel.batchReviewUploadId = 0
              this.viewParams.dashboardMode.value = textConstants.titleText.BatchReview;
              this.viewModel.showBatchReviewProspects = false;
              this.viewModel.showBatchReview = true;
              this.viewModel.BatchReviewUploadViewModel.initialise();
              this.viewModel.isEntryViaLink = false
            }}
          />
        }
      </React.Fragment >
    );
  }
}
