import { Attributes, List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import ActionListFollowUpTemplateLookup from './ActionListFollowUpTemplateLookup';

@NeoModel
export default class ActionListClientFollowUpTemplateLookup extends ModelBase {

    // Properties
    public clientFollowUpTemplateId: number = 0

    public clientId: number = 0;

    @Attributes.Display("Name")
    public followUpTemplateName: string = "";

    public followUpTemplates = new List(ActionListFollowUpTemplateLookup)

    public leadTypeStatusId: number = 0;
}