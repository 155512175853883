import styled from 'styled-components';

export const StyledStepper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &:first-child {
        margin-top: 16px ;
    }
`;

interface IStyledStepProps {
    active?: boolean;
    completed?: boolean;
    last?: boolean;
    unlocked?: boolean;
    variant?: "primary" | "secondary";
}

function backgroundVariant(active?: boolean, completed?: boolean, unlocked?: boolean, variant?: "primary" | "secondary") {
    switch (variant) {
        case "primary":
            return `background: ${(active ? '#E9F2F7' : 'none')};    
                &:hover  {
                    background-color: ${active || completed || unlocked ? ((active) ? '#E9F2F7' : '#889fb2') : "none"};
                }`

        case "secondary":
            return `background: ${active ? "#F5EBD0" : "none"};
                &:hover  {
                    background-color: #F5EBD0;
                    opacity: 0.8;
                }`

        default:
            return "";
    }
}

export const StyledStep =
    styled.div<IStyledStepProps>`
    display: flex;
    flex-direction: row;
    padding: 16px 8px;
    height: 64px;
    width: 100%;
    ${({ active, completed, unlocked, variant }) => backgroundVariant(active, completed, unlocked, variant)}
    border-radius: 16px;
    margin-top: -16px;  
    user-select: none;
    ${({ active, completed, unlocked }) => ((active || completed || unlocked) ? "cursor: pointer" : "")};
`;

export const StyledTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #002648;
    padding-top: 4px;
`;

function iconVariant(active?: boolean, completed?: boolean, variant?: "primary" | "secondary") {
    switch (variant) {
        case "primary":
            return `${(active ? '#D2E7F1' : completed ? '#97CBE6' : '#EDEEEE')}`;
        case "secondary":
            return `${(active ? '#FADE9C' : completed ? '#F6BD3A' : '#FADE9C')}`;
        default:
            return "";
    }
}

export const StyledStepIcon = styled.div<IStyledStepProps>`
    width: 32px;
    height: 32px;
    background: ${({ active, completed, variant }) => iconVariant(active, completed, variant)};
    border-radius: 100px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
`;

export const StyledConnectorLine = styled.div<IStyledStepProps>`
    width: 2px;
    height: 46px;
    background: ${({ active }) => (active ? '#D2E7F1' : '#EDEEEE')};
    position: relative;
    top: -1px;
    left: -96px;
    margin-top: -16px;
    z-index: 1;
    display: ${({ last }) => (last ? 'none' : 'block')};
`;