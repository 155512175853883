import { ModelBase, List, NeoModel, Rules} from '@singularsystems/neo-core'; 
import BlacklistedDomainLookup from '../BlacklistedDomainLookup';
import BlacklistedDomainCriteria from '../Query/BlacklistedDomainCriteria';

@NeoModel 
export default class DeleteBlacklistedDomain extends ModelBase { 
   @Rules.Required()
   public BlacklistedDomainId: number = 0; 

   public blacklistedDomainLookup?= new List(BlacklistedDomainLookup)

   public blacklistedDomainCriteria = new (BlacklistedDomainCriteria)

   public unselectedBlacklistedDomains?= new List(BlacklistedDomainLookup)

   public isSelectAll: boolean = false;
}