import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class EmployeeSize extends ModelBase {

    public employeeSizeId: number = 0;
    
    @Rules.StringLength(500)
    @Attributes.Display("Company Size")
    public employeeSizeName: string = "";

    @Rules.Required()
    @Attributes.Display("Company Min Size")
    public employeeMinSize: number | null = null;

    @Rules.Required()
    @Attributes.Display("Company Max Size")
    public employeeMaxSize: number | null = null;

    // Client only properties / methods
    public employeeSizeFactor : number = 0

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.employeeSizeName === null || c.employeeSizeName === '', textConstants.messageText.validationMessage.companySizeName);
    }

    public toString(): string {
        if (this.isNew || !this.employeeSizeName) {
            return "New employee size";
        } else {
            return this.employeeSizeName;
        }
    }
}