import { Neo, NeoGrid } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { textConstants } from "../../common/textConstants";
import CustomPagingControl from "../../Components/CustomPagingControl";
import SearchField from "../../Components/SearchField";
import BatchReviewUploadLookup from "../../Models/BatchReview/Query/BatchReviewUploadLookup";
import BatchReviewUploadVM from "./BatchReviewUploadVM";
import * as Roles from '../../Models/Security/Roles';
import { AppService, Types } from "../../Services/AppService";
import * as Icon from 'react-feather';
import InfoBox from "../../Components/InfoBox";

interface IBatchReviewUploadView {
    viewModel: BatchReviewUploadVM,
    back: () => void,
    openBatchReviewProspects: (batchReviewUploadId: number) => void,
    isComXUser: boolean
}

@observer
export default class BatchReviewUploadView extends React.Component<IBatchReviewUploadView> {

    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

    private setHeaderPadding() {
        if (this.props.isComXUser) {
            return "pl-24"
        } else {
            return "p-24"
        }
    }

    private setReviewNowPadding() {
        if (this.props.isComXUser) {
            return "p-0"
        } else {
            return ""
        }
    }

    private setNegMargin() {
        if (this.props.isComXUser) {
            return "negSideMargins24"
        }
        else {
            return "m-0"
        }
    }

    public render() {
        const { searchFieldVM } = this.props.viewModel
        const mainComponent =
            <div className="">
                <div className="row mt-3 ml-0 mr-0">
                    {/* Search field */}
                    <div className={`col-12 ${this.setHeaderPadding()}`}>
                        <div className="row ml-0 mr-0">
                            <div className="col-2 p-0">
                                <h2>Batch Review</h2>
                            </div>
                            <div className="col-10 p-0">
                                <div className="pull-right">
                                    <SearchField
                                        onClickFunction={(e) => { this.props.viewModel.batchReviewUploadPageManager.refreshData() }}
                                        searchBindableProp={this.props.viewModel.BatchReviewUploadCriteria.meta.searchString}
                                        searchString={this.props.viewModel.BatchReviewUploadCriteria.searchString}
                                        searchFieldVM={searchFieldVM}
                                    /></div>
                            </div>
                        </div>
                    </div>

                    {
                        <div className="col-12 p-0">
                            <Neo.TabContainer className="mb-0 mr-0 ml-0 mt-2" variant="flat">
                                <Neo.Tab header="Current" onDisplayed={() => {
                                    this.props.viewModel.isArchive = false;
                                    this.props.viewModel.BatchReviewUploadCriteria.isDeleted = false;
                                    this.props.viewModel.pageManagerRefresh();
                                }}>
                                </Neo.Tab>
                                <Neo.Tab header="Archived" onDisplayed={() => {
                                    this.props.viewModel.hideGrid = false;
                                    this.props.viewModel.isArchive = true;
                                    this.props.viewModel.BatchReviewUploadCriteria.isDeleted = true;
                                    this.props.viewModel.pageManagerRefresh();
                                }}>
                                </Neo.Tab>
                            </Neo.TabContainer>
                        </div>
                    }
                </div>

                <div>
                    <div className={`${this.setNegMargin()} row`}>
                        <div className="col-12 p-0">

                            {this.props.viewModel.batchReviewUploadPageManager.totalRecords === 0 && searchFieldVM.isSearching &&
                                <InfoBox header={textConstants.titleText.NoSearchMatches}
                                    text={textConstants.generalText.NoSearchMatches} />
                            }

                            {this.props.viewModel.batchReviewUploadPageManager.totalRecords > 0 &&
                                <React.Fragment>
                                    <Neo.Pager pageManager={this.props.viewModel.batchReviewUploadPageManager} pageControls="none">
                                        <NeoGrid.Grid<BatchReviewUploadLookup> items={this.props.viewModel.batchReviewUploadPageManager}>
                                            {(BatchReviewUploadLookup, meta) => (
                                                <NeoGrid.Row >
                                                    {/* Batch Name */}
                                                    {this.props.isComXUser &&
                                                        <NeoGrid.Column
                                                            className="pb-0 pt-0"
                                                            label={textConstants.titleText.Batch}
                                                        >
                                                            <div className="pb-3 pt-3 pl-3 clickableCursor"
                                                                onClick={() => { this.props.openBatchReviewProspects(BatchReviewUploadLookup.batchReviewUploadId) }}
                                                            >
                                                                {BatchReviewUploadLookup.batchFileName}
                                                            </div>

                                                        </NeoGrid.Column>

                                                    }

                                                    {/* Sequence Number */}
                                                    <NeoGrid.Column
                                                        label={textConstants.titleText.TargetMarketNumber}
                                                        display={meta.sequenceNumber}
                                                    />

                                                    {/* Target Market Name */}
                                                    <NeoGrid.Column
                                                        label={textConstants.titleText.TargetMarketName}
                                                        display={meta.targetMarketName}
                                                    />

                                                    {/* Date Uploaded or Date */}
                                                    <NeoGrid.Column
                                                        label={this.props.isComXUser ? textConstants.titleText.DateUploaded : textConstants.titleText.Date}
                                                        display={meta.dateUploaded}
                                                    />

                                                    {/* Date Reviewed */}
                                                    {this.props.isComXUser &&
                                                        <NeoGrid.Column
                                                            label={textConstants.titleText.DateReviewed}
                                                            display={meta.dateReviewed}
                                                        />}

                                                    {/* View/Review Now */}
                                                    {this.authorisationService.hasRole(Roles.BatchReview.View) &&
                                                        <NeoGrid.ButtonColumn
                                                            width={125}
                                                            className={`text-center ${this.setReviewNowPadding()}`}
                                                            hidden={this.props.viewModel.isArchive}
                                                        >

                                                            <Neo.Button
                                                                className="text-center w-100"
                                                                variant="light"
                                                                onClick={() => { this.props.openBatchReviewProspects(BatchReviewUploadLookup.batchReviewUploadId) }}
                                                            >
                                                                {BatchReviewUploadLookup.dateReviewed !== "-" ? 'View Now' : 'Review Now'}
                                                            </Neo.Button>
                                                        </NeoGrid.ButtonColumn>
                                                    }

                                                    {this.props.viewModel.BatchReviewUploadCriteria.isComXUser &&
                                                        <>
                                                            {!this.props.viewModel.isArchive &&
                                                                <>
                                                                    {/* Download */}
                                                                    <NeoGrid.ButtonColumn
                                                                        width={50}
                                                                        className="text-center"
                                                                        label={"Download"}
                                                                    >
                                                                        <Neo.Button
                                                                            variant="light"
                                                                            className="text-center circled"
                                                                            icon={<Icon.Download />}
                                                                            onClick={async () => {
                                                                                await this.props.viewModel.downloadItems(BatchReviewUploadLookup.batchReviewUploadId)
                                                                            }}
                                                                        />
                                                                    </NeoGrid.ButtonColumn>

                                                                    {/* Archive */}
                                                                    {this.authorisationService.hasRole(Roles.BatchReview.Delete) &&
                                                                        <NeoGrid.ButtonColumn
                                                                            label={textConstants.buttonText.Archive}
                                                                            hidden={this.props.viewModel.isArchive}
                                                                            className="text-center pl-0 pr-24"
                                                                            deleteButton={{
                                                                                className: '',
                                                                                onClick: async () => {
                                                                                    await this.props.viewModel.archiveItem(BatchReviewUploadLookup.batchReviewUploadId)
                                                                                }
                                                                            }}
                                                                        />
                                                                    }
                                                                </>
                                                            }

                                                            {/* Unarchive */}
                                                            {this.props.viewModel.isArchive &&
                                                                this.authorisationService.hasRole(Roles.BatchReview.Delete) &&
                                                                <NeoGrid.ButtonColumn
                                                                    width={50}
                                                                    className="text-center p-24"
                                                                    label={"Unarchive"}
                                                                >
                                                                    <Neo.Button
                                                                        variant="light"
                                                                        className="text-center circled"
                                                                        icon={<Icon.RotateCw />}
                                                                        onClick={async () => {
                                                                            await this.props.viewModel.unarchiveItem(BatchReviewUploadLookup.batchReviewUploadId)
                                                                        }}
                                                                    />
                                                                </NeoGrid.ButtonColumn>
                                                            }
                                                        </>
                                                    }
                                                </NeoGrid.Row>
                                            )}
                                        </NeoGrid.Grid>
                                    </Neo.Pager>
                                    <CustomPagingControl
                                        displayLabel={false}
                                        pageManager={this.props.viewModel.batchReviewUploadPageManager} />
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>

        return (

            <div className="BatchReviewUpload">
                {this.props.isComXUser &&
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            {mainComponent}
                        </div>
                    </div>
                }

                {!this.props.isComXUser &&
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            {mainComponent}
                        </div>

                    </div>}
            </div>
        );
    }
}