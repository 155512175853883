import React from "react";

import MeetingDetailsContent from "./Components/MeetingDetailsContent";
import { StyledContentArea } from "./styles";
import { IMeetingDetailsProps } from './Components/MeetingDetailsContent/MeetingDetailsContent';

const MeetingDetails = (props: IMeetingDetailsProps) => {
  return (
    <StyledContentArea>
      <MeetingDetailsContent
        linkList={props.linkList}
        comxHub={props.comxHub}
        onboardingMeeting={props.onboardingMeeting}
        cancelMeeting={props.cancelMeeting}
      />
    </StyledContentArea>
  );
};

export default MeetingDetails;