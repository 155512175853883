import styled from "styled-components";

interface ISideMenuProps {
  open: boolean;
}

export const StyledSideMenu = styled.div<ISideMenuProps>`
  width: ${({ open }) => (open ? "240px" : "100px")};
  left: 0px;
  background: #f8f8f8;
  border-right: 1px solid #dcdddd;
  padding: 16px 8px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const StyledSideMenuHeader = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: #818888;
  margin-bottom: 16px;
`;

interface IStyledStepProps {
  active: boolean;
}

export const StyledStep = styled.div<IStyledStepProps>`
  display: flex;
  flex-direction: row;
  padding: 16px 8px;
  height: 64px;
  width: 100%;
  border-radius: 16px;
  background: ${({ active }) => (active ? "#F5EBD0" : "none")};
  &:hover  {
    background-color: #F5EBD0;
    opacity: 0.8;
  }
  user-select: none;
  cursor: pointer;
`;

export const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #002648;
  padding-top: 4px;
`;

export const StyledStepIcon = styled.div`
  width: 32px;
  height: 32px;
  background: #fade9c;
  border-radius: 100px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
`;

export const StyledProgressCard = styled.div`
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
  padding: 24px;
  margin-bottom: 64px;
`;

export const StyledProgressCardTitle = styled.div`
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #0b0b0c;
`;
