import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import Region from '../Models/Maintenance/Region';

export interface IRegionApiClient extends Data.IUpdateableApiClient<Region, number> {

}

@injectable()
export default class RegionApiClient extends Data.UpdateableApiClient<Region, number> implements IRegionApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/Regions`);
    }
}