import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import { List } from '@singularsystems/neo-core';
import LeadType from 'Models/ActionList/LeadType';
import { textConstants } from 'common/textConstants';
import LeadTypeStatus from 'Models/ActionList/LeadTypeStatus';
import ActionListStatus from 'Models/ActionList/ActionListStatus';
import ActionListConfiguration from 'Models/ActionList/ActionListConfiguration';

export default class ActionListStatusVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private leadTypesApiClient = AppService.get(Types.ApiClients.LeadTypesApiClient),
        private leadTypeStatusesApiClient = AppService.get(Types.ApiClients.LeadTypeStatusesApiClient),
        private actionListStatusesApiClient = AppService.get(Types.ApiClients.ActionListStatusesApiClient),
        private actionListConfigurationsApiClient = AppService.get(Types.ApiClients.ActionListConfigurationsApiClient),
    ) {

        super(taskRunner);
        this.makeObservable();
    }

    public async initialise() {
        await this.setPageData();
    }

    public leadTypesList = new List(LeadType);
    public leadTypeStatusesList = new List(LeadTypeStatus);
    public actionListStatusesList = new List(ActionListStatus);
    public actionListConfigurationList = new List(ActionListConfiguration);

    public async setPageData() {
        const leadTypeResponse = await this.taskRunner.waitFor(this.leadTypesApiClient.get());
        this.leadTypesList.set(leadTypeResponse.data);

        const leadTypeStatusResponse = await this.taskRunner.waitFor(this.leadTypeStatusesApiClient.get());
        this.leadTypeStatusesList.set(leadTypeStatusResponse.data);

        const actionListStatusResponse = await this.taskRunner.waitFor(this.actionListStatusesApiClient.get());
        this.actionListStatusesList.set(actionListStatusResponse.data);

        const actionListConfigurationResponse = await this.taskRunner.waitFor(this.actionListConfigurationsApiClient.get());
        this.actionListConfigurationList.set(actionListConfigurationResponse.data);
    }

    public async updateLeadTypeStatus() {
        this.taskRunner.run(async () => {
            const response = await this.leadTypesApiClient.saveList(this.leadTypesList.toJSArray());
            this.leadTypesList.update(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.StatusSaved)
        });
    }

    public async updateLeadTypeMappedStatuses() {
        this.taskRunner.run(async () => {
            const leadTypeStatusesResponse = await this.leadTypeStatusesApiClient.saveList(this.leadTypeStatusesList.toJSArray());
            this.leadTypeStatusesList.update(leadTypeStatusesResponse.data);

            const actionListStatusesResponse = await this.actionListStatusesApiClient.saveList(this.actionListStatusesList.toJSArray());
            this.actionListStatusesList.update(actionListStatusesResponse.data);

            const actionListConfigurationsResponse = await this.actionListConfigurationsApiClient.saveList(this.actionListConfigurationList.toJSArray());
            this.actionListConfigurationList.update(actionListConfigurationsResponse.data);

            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.StatusSaved)
        });
    }
}
