import { NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class CustomerProfileQuestionCompleted extends ValueObject {

    public id: number = 0;

    public questionNumber: number = 0; 

    public questionText: string = "";

    public completed: boolean = false;
}