import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import CustomerProfileStatus from '../Models/Maintenance/CustomerProfileStatus';

export interface ICustomerProfileStatusesApiClient extends Data.IUpdateableApiClient<CustomerProfileStatus, number> {

}

@injectable()
export default class CustomerProfileStatusesApiClient extends Data.UpdateableApiClient<CustomerProfileStatus, number> implements ICustomerProfileStatusesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/customerProfileStatuses`);
    }
}