import { ValidationDisplayMode } from '@singularsystems/neo-core/dist/Validation/Misc';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import React from 'react';
import InfoLabel from '../Views/TargetMarket/Functions/InfoLabel';
import TextAsParagraphs from './TextAsParagraphs';
import * as Icon from 'react-feather';
import QuickAddItem from 'Models/BlacklistedDomains/QuickAddDomain';
import { List } from '@singularsystems/neo-core';

interface IQuickAddModal {
  title: string;
  show: boolean;
  onClose: () => void
  onSubmit: () => void
  description?: string[];
  quickAddItem: QuickAddItem;
  addPlaceholder: string;
  addOnClick: (e: any) => void;
  removeItem: (e: any, item: any) => void;
  addedItemsList: List<QuickAddItem>;
  validateQuickAddItem: string[];
  validationMessage: string;
  validationUnsuccessful: boolean;
}

@observer
export default class QuickAddModal extends React.Component<IQuickAddModal> {
  public render() {
    const { title, show, onClose, onSubmit, description, quickAddItem, addPlaceholder, addOnClick, addedItemsList, validateQuickAddItem, validationMessage, removeItem, validationUnsuccessful } = this.props

    return (
      <Neo.Modal title={title} show={show}
        onClose={onClose}
        closeButton={{ text: "Close", variant: "light" }}
        suppressForm={true}
        className="blacklistAndGreylistModal">

        <Neo.Form
          model={quickAddItem}
          onSubmit={onSubmit}
          validationDisplayMode={ValidationDisplayMode.Always}
          showSummaryModal={true}>

          {/* Quick Add Description */}
          <div className='row'>
            <div className='col-12'>
              {description && <TextAsParagraphs paragraphArray={description} />}
            </div>
          </div>

          <div className='row mt-2 mb-4 quickAddParagraphBorder'></div>

          {/* Add item row */}
          <div className='row mb-4'>

            {/* Textbox */}
            <div className='col-11'>
              <Neo.FormGroup
                input={{ type: "text" }}
                bind={quickAddItem.meta.item}
                placeholder={addPlaceholder}
                suppressLabel={true}
              />
            </div>

            {/* Add/Plus button */}
            <div className='col-1 p-0'>
              <Neo.Button
                className='plusButton'
                variant='light'
                disabled={quickAddItem.meta.item.value === ""}
                onClick={addOnClick}
                icon={<Icon.Plus />}>
              </Neo.Button>
            </div>

            <div className="quickAddError col-12">
              {(validateQuickAddItem.find(invalidItemList => invalidItemList === quickAddItem.meta.item.value) !== undefined || validationUnsuccessful) &&
                <div className="errorMessage">{validationMessage}</div>
              }

              {validateQuickAddItem.map((x, i) => (
                <InfoLabel
                  labelType="danger"
                  key={i}
                  labelText={quickAddItem.meta.item.value}
                  isVisible={validateQuickAddItem.length > 0 &&
                    validateQuickAddItem.find(invalidItemList => invalidItemList === quickAddItem.meta.item.value) !== undefined}
                  font="" />
              ))
              }
            </div>
          </div>

          {/* Mapped Items */}
          {/* Edit Item Row */}
          <div className='row'>
            <div className='col-12 pr-0 selectedItemsContainer'>
              {addedItemsList.length > 0 &&
                addedItemsList.map((addedItem, key) => (

                  <React.Fragment key={key}>
                    <div className='row mb-4 pr-0 m-0'>

                      {/* Textbox */}
                      <div className='col-11 pl-0'>
                        <Neo.FormGroup
                          input={{ type: "text" }}
                          bind={addedItem.meta.item}
                          suppressLabel={true} />
                      </div>

                      {/* Delete button */}
                      <div className='col-1 p-0'>
                        <Neo.Button
                          className='plusButton'
                          variant='light'
                          onClick={(e: any) => removeItem(e, addedItem)}
                          icon={<Icon.Trash2 />}>
                        </Neo.Button>
                      </div>

                      <div className="quickAddError col-12 p-0 ">
                        {validateQuickAddItem.find(invalidItemList => invalidItemList === addedItem.item) !== undefined &&
                          <div className="errorMessage">{validationMessage}</div>}

                        <InfoLabel
                          labelType="danger"
                          labelText={addedItem.item}
                          isVisible={validateQuickAddItem.find(invalidItemList => invalidItemList === addedItem.item) !== undefined}
                          font="" />
                      </div>
                    </div>
                  </React.Fragment>
                ))

              }
            </div>
          </div>

          <div className="col-12 p-0 footerContainer">
            <div className=" row m-0 pt-2">
              <div className="mr-3 p-0 col-12">
                <Neo.Button className='quickAddModalButtonWidth' text="Save" isSubmit />
              </div>
            </div>
          </div>
        </Neo.Form>
      </Neo.Modal>
    )
  }
}