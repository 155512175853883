import { List } from "@singularsystems/neo-core";
import { observer } from "mobx-react";
import React from "react";
import ClientCampaignDataLookup from "../../Models/Dashboard/ClientCampaignDataLookup";
import DashboardVM from "../../Views/Home/DashboardVM";
import DashboardTableRow from "./DashboardTableRow";

interface IDashboardTable {
    campaignData: List<ClientCampaignDataLookup>,
    viewModel: DashboardVM,
    openCampaignsDetailOverview: (clientCampaignId: number) => void;
    noCampaignsJSX?: JSX.Element;
}

@observer
class DashboardTable extends React.Component<IDashboardTable> {

    render() {
        return (
            <div className="dashboardTableContainer negSideMargins24">
                {/* Header Row */}
                <DashboardTableRow
                    isHeader={true}
                    viewModel={this.props.viewModel}
                    openCampaignsDetailOverview={this.props.openCampaignsDetailOverview} />

                {/* Data Rows */}
                {!this.props.noCampaignsJSX &&
                    this.props.campaignData.map((campaign: ClientCampaignDataLookup) => {
                        return <DashboardTableRow
                            key={campaign.clientCampaignId}
                            isHeader={false}
                            campaignData={campaign}
                            viewModel={this.props.viewModel}
                            openCampaignsDetailOverview={this.props.openCampaignsDetailOverview} />
                    })
                }

                {/* Adds the no campaigns element to the table */}
                {this.props.noCampaignsJSX && this.props.noCampaignsJSX}
            </div>
        )
    }
}

export default DashboardTable;