import { NeoModel, Data, Model, List } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import TargetMarketAccountCriteria from '../../Models/TargetMarketAccounts/Query/TargetMarketAccountCriteria';
import TargetMarketAccountLookup from '../../Models/TargetMarketAccounts/TargetMarketAccountLookup';
import TargetMarketSummary from '../../Models/TargetMarketAccounts/TargetMarketSummary';
import { base64toBlob, getCurrentDateTime } from '../../common/utils';
import EditTargetMarketAccountCommand from './Commands/EditTargetMarketAccount';
import TargetMarketAccountDeletedStateCommand from './Commands/TargetMarketAccountDeletedStateCommand';
import CommandResult from '../../Models/InvitedUsers/Commands/CommandResult';
import { textConstants } from '../../common/textConstants';
import TargetMarketAccountFilterList from '../../Models/TargetMarketAccounts/Query/TargetMarketAccountFilterList';
import { helperConst } from './TargetMarketAccountGrid/TargetMarketAccountGridHelper';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import TMADeleteCommand from './Commands/TMADeleteCommand';
import TMABulkReprospectCommand from './Commands/TMABulkReprospectCommand';
import TMABulkUndeleteCommand from './Commands/TMABulkUndeleteCommand';
import BlacklistedDomainHelper from '../Client/BlacklistedDomain/BlacklistedDomainHelper';
import EditClient from '../../Models/Client/Commands/EditClient';
import TMAUnblacklistCommand from './Commands/TMAUnblacklistCommand';
import { TypeRowSelection } from '@inovua/reactdatagrid-community/types';
import { indexOf, isNumber } from 'lodash';
import SearchFieldVM from '../../Components/SearchFieldVM';



type bulkActions = "BulkDelete" | "BulkUndo" | "BulkReprospect" | "BulkUnblacklist" | ""

export type targetMarketAccountTabs = "To-do" | "Active" | "Removed" | "Blacklisted" | "Other & System" | "All" | ""

export interface tabNameLabels {
    tabName: targetMarketAccountTabs
    tabCount: number
    tabDescription: string
}

@NeoModel
export default class TargetMarketAccountsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private targetMarketAccountsApiClient = AppService.get(Types.ApiClients.TargetMarketAccountsApiClient),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        private invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient),
        private blacklistedDomainsApiClient = AppService.get(Types.ApiClients.BlacklistedDomainsApiClient),
        private authService = AppService.get(Types.Neo.Security.AuthenticationService),
        public customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
    ) {
        super(taskRunner);
    }

    public showForbiddenModal: boolean = false;

    public criteria: TargetMarketAccountCriteria = new TargetMarketAccountCriteria();

    public targetMarketSummary: TargetMarketSummary = new TargetMarketSummary();

    public navigateToAddClientList: () => void = () => { };

    public showSpinner: boolean | undefined = true;
    public isComXUser: boolean | null = null
    public selectedItems?: TypeOnSelectionChangeArg
    public refreshMethod: any

    // Tabs
    public targetMarketAccountTabList: tabNameLabels[] = [
        { tabName: "To-do", tabCount: 0, tabDescription: helperConst.TodoTab },
        { tabName: "Active", tabCount: 0, tabDescription: helperConst.ActiveTab },
        { tabName: "Blacklisted", tabCount: 0, tabDescription: helperConst.BlacklistedTab },
        { tabName: "Other & System", tabCount: 0, tabDescription: helperConst.OtherSystemTab },
        { tabName: "All", tabCount: 0, tabDescription: helperConst.AllTab },
        { tabName: "Removed", tabCount: 0, tabDescription: helperConst.RemovedTab },
    ]

    // Edit Single Account
    public showEditModal: boolean = false
    public showDeleteModal: boolean = false
    public selectedAccountName: string = "";
    public TMADeleteCommand = new TMADeleteCommand()
    public TMABulkReprospectCommand = new TMABulkReprospectCommand()
    public TMABulkUndeleteCommand = new TMABulkUndeleteCommand()
    public deletedStateCommand = new TargetMarketAccountDeletedStateCommand()
    public editTargetMarketAccountCommand = new EditTargetMarketAccountCommand()
    public targetMarketStatuses = this.appDataCache.targetMarketAccountStatuses.get().data.sortBy("targetMarketAccountStatusName")

    // Target Market Filter
    public targetMarketAccountCriteria: TargetMarketAccountFilterList = new TargetMarketAccountFilterList()
    public prospectedMin: Date = new Date()
    public prospectedMax: Date = new Date()
    public datePickerDisable: boolean = false
    public filterPriority: boolean = true

    // Target Market Bulk Action 
    public showBulkActionModal: boolean = false
    public bulkActionMessage: string = ""
    public bulkActions: bulkActions | "" = ""

    public deletePerformed: boolean = false
    public editButtonClicked: boolean = false
    public deleteButtonClicked: boolean = false
    public unDeleteButtonClicked: boolean = false
    public unSelectedAccounts = new List(TargetMarketAccountLookup)
    public selectedItemsCount: number = 0
    public isRefreshCount: boolean = false;
    public searchFieldVM = new SearchFieldVM();

    // Blacklisting
    public clientId: number = 0
    public clientDetails = new EditClient();
    public unblacklistCommand = new TMAUnblacklistCommand()
    public selected?: TypeRowSelection
    public blacklistedDomainHelper: BlacklistedDomainHelper = new BlacklistedDomainHelper(
        this.taskRunner,
        this.blacklistedDomainsApiClient,
        this.notifications,
        this.clientId,
        this.clientDetails
    )

    tabCreate: ((tabLabels: tabNameLabels, index: number) => void) | undefined

    public async toggleTab(
        old_selectedTab: string,
        new_selectedTab: targetMarketAccountTabs
    ) {
        this.criteria.removed = 1
        this.selected = false
        this.criteria.ResetLocalFilters()
        this.selectedItemsCount = 0

        if (new_selectedTab === "To-do") {
            this.criteria.tabName = "To-do"
        }
        if (new_selectedTab === "Active") {
            this.criteria.tabName = "Active"
        }
        if (new_selectedTab === "Blacklisted") {
            this.criteria.tabName = "Blacklisted"
        }
        if (new_selectedTab === "Other & System") {
            this.criteria.tabName = "Other & System"
        }
        if (new_selectedTab === "All") {
            this.criteria.tabName = "All"
        }
        if (new_selectedTab === "Removed") {
            this.criteria.tabName = "Removed"
            this.criteria.removed = 2
        }
    }

    public GetRefreshMethod = (dataSource: (props: any) => Promise<void>) => {
        this.refreshMethod = dataSource
    }

    public async fetchData(targetMarketId: number) {

        if (targetMarketId > 0) {
            const isClientResponse = await this.invitedUsersApiClient.isClientUser(this.authService!.user!.userName);
            this.isComXUser = !isClientResponse.data
            this.criteria.targetMarketId = targetMarketId;
            const response = await this.taskRunner.waitFor(this.targetMarketAccountsApiClient.getTargetMarketSummary(targetMarketId));
            this.targetMarketSummary.set(response.data);
        }
        else {
            this.notifications.addWarning("Warning", "TargetMarketId is not set. Please refresh page.");
        }
    }

    public setFilter(targetMarketAccountTabList: any) {
        this.targetMarketAccountTabList = targetMarketAccountTabList
    }

    public async setReactPageGridAndList(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<TargetMarketAccountCriteria>>) {
        let responseModel = await this.targetMarketAccountsApiClient.getTargetMarketAccounts(request)
        let newTabs: tabNameLabels[] = []
        if (responseModel) {
            this.targetMarketAccountTabList.forEach(element => {
                var currentTab: tabNameLabels = responseModel.data.targetMarketTabBadges.filter((tab: tabNameLabels) => tab.tabName === element.tabName)[0]
                if (currentTab) {
                    currentTab["tabDescription"] = element.tabDescription
                    newTabs.push(currentTab)
                } else {
                    const tabZero = {
                        tabName: element.tabName, tabCount: 0, tabDescription: element.tabDescription
                    }
                    newTabs.push(tabZero)
                }
            });
            this.setFilter(newTabs)
        }

        if (this.isRefreshCount) {
            this.CalculateSelectedItems(responseModel.data.pageResult.total);
        }

        this.isRefreshCount = false;
        return responseModel
    }

    public onSelectionChange(data: any, totalRecords?: number) {

        this.selectedItems = data;

        this.handleSelectAll(data);

        this.CalculateSelectedItems(totalRecords);
    }

    private CalculateSelectedItems(totalRecords: number | undefined) {
        if (totalRecords  !== 0) {
            if (this.selectedItems && this.selectedItems.selected && totalRecords) {
                var selected = this.selectedItems.selected;
                this.selected = this.selectedItems.selected

                if (selected === true) {
                    let recordCount = totalRecords - (this.unSelectedAccounts ? this.unSelectedAccounts.length : 0);
                    this.selectedItemsCount = recordCount;
                } else if (selected) {
                    var manuallySelectedItems = Object.keys(this.selectedItems.selected);
                    this.selectedItemsCount = manuallySelectedItems.length;
                }
            }
        } else {
            this.selectedItemsCount = 0;
        }

    }

    private getUnselectedTargetMarketAccount(unselectedAccountId: string) {
        if (unselectedAccountId.startsWith('TMA')) {
            return this.unSelectedAccounts.find(account => 'TMA' + account.targetMarketAccountId.toString() === unselectedAccountId);
        }
        else if (unselectedAccountId.startsWith('MASTER')) {
            return this.unSelectedAccounts.find(account => 'MASTER' + account.masterAccountId.toString() === unselectedAccountId);
        }
        else {
            return this.unSelectedAccounts.find(account => 'CLIENT' + account.clientAccountId.toString() === unselectedAccountId);
        }
    }

    private handleSelectAll(data: any) {
        if (this.selectedItems !== undefined) {
            if (this.selectedItems.selected === true) {
                let gridUnSelectedItems = this.selectedItems.unselected;

                if (gridUnSelectedItems) {
                    let gridUnselectedAccounts = Object.keys(gridUnSelectedItems);

                    gridUnselectedAccounts.forEach(unselectedAccountId => {

                        let existingUnselectedAccount = this.getUnselectedTargetMarketAccount(unselectedAccountId);

                        if (existingUnselectedAccount === undefined) {
                            let accountToAdd = new TargetMarketAccountLookup();

                            this.CreateLookup(accountToAdd, data);

                            this.unSelectedAccounts.push(accountToAdd);
                        }
                    });

                    // Remove any reselected accounts
                    this.unSelectedAccounts.forEach(deselectedAccount => {
                        let index = gridUnselectedAccounts.indexOf("TMA" + deselectedAccount.targetMarketAccountId.toString())

                        index = index === -1 ? gridUnselectedAccounts.indexOf("MASTER" + deselectedAccount.masterAccountId.toString()) : index;
                        index = index === -1 ? gridUnselectedAccounts.indexOf("CLIENT" + deselectedAccount.clientAccountId.toString()) : index;

                        const accountStillUnselected = (index > -1);

                        if (!accountStillUnselected) {
                            this.unSelectedAccounts.remove(deselectedAccount);
                        }
                    });
                }

                // Clear unselected accounts if user has reselected everything
                if (gridUnSelectedItems === null && this.unSelectedAccounts.length > 0) {
                    this.unSelectedAccounts.length = 0;
                }
            }
        }
    }

    private CreateLookup(accountToAdd: TargetMarketAccountLookup, data: any) {
        accountToAdd.originalName = data.data.originalName;
        accountToAdd.fixedName = data.data.fixedName;
        accountToAdd.targetMarketAccountStatus = data.data.targetMarketAccountStatus;
        accountToAdd.subIndustry = data.data.subIndustry;
        accountToAdd.companySize = data.data.companySize;
        accountToAdd.deleted = data.data.deleted;
        accountToAdd.location = data.data.location;
        accountToAdd.domain = data.data.domain;
        accountToAdd.dateLastProspected = data.data.dateLastProspected;
        accountToAdd.priority = data.data.priority;
        accountToAdd.reProspectingStatus = data.data.reProspectingStatus;
        accountToAdd.targetMarketAccountId = data.data.targetMarketAccountId;
        accountToAdd.masterAccountId = data.data.masterAccountId;
        accountToAdd.clientAccountId = data.data.clientAccountId;
    }

    public clearFiler() {

    }

    public downloadTargetMarketAccounts() {
        this.taskRunner.run(async () => {
            const response = await this.targetMarketAccountsApiClient.downloadTargetMarketAccounts(this.criteria);
            const url = window.URL.createObjectURL(base64toBlob(response.data.fileContents, response.data.contentType));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'TargetMarketAccounts_' + this.targetMarketSummary.clientName + '_' + this.targetMarketSummary.targetMarketName + '_' + getCurrentDateTime() + '.csv');
            document.body.appendChild(link);
            link.click();
        })
    }

    public setupEditModal(targetMarketAccountLookup: any) {
        var accountStatus = this.targetMarketStatuses.filter(as => this.compareTowStrings(as.targetMarketAccountStatusName, targetMarketAccountLookup.targetMarketAccountStatus))[0]
        this.editTargetMarketAccountCommand.targetMarketId = this.criteria.targetMarketId
        this.editTargetMarketAccountCommand.domain = targetMarketAccountLookup.domain
        this.editTargetMarketAccountCommand.fixedName = targetMarketAccountLookup.fixedName
        this.editTargetMarketAccountCommand.originalName = targetMarketAccountLookup.originalName
        this.editTargetMarketAccountCommand.accountStatusId = accountStatus ? accountStatus.targetMarketAccountStatusId : 0;
        this.editTargetMarketAccountCommand.targetMarketAccountId = targetMarketAccountLookup.targetMarketAccountId
        this.editTargetMarketAccountCommand.masterAccountId = targetMarketAccountLookup.masterAccountId
        this.editTargetMarketAccountCommand.clientAccountId = targetMarketAccountLookup.clientAccountId
        this.editTargetMarketAccountCommand.priority = targetMarketAccountLookup.priority
        this.showEditModal = true
        this.editButtonClicked = false
    }

    public deleteAccount(lookupData: any) {

        var targetMarketAccountLookup = new (TargetMarketAccountLookup)

        targetMarketAccountLookup.originalName = lookupData.originalName
        targetMarketAccountLookup.targetMarketAccountId = lookupData.targetMarketAccountId
        targetMarketAccountLookup.masterAccountId = lookupData.masterAccountId
        targetMarketAccountLookup.clientAccountId = lookupData.clientAccountId
        targetMarketAccountLookup.targetMarketAccountStatus = lookupData.targetMarketAccountStatus
        targetMarketAccountLookup.dateLastProspected = lookupData.dateLastProspected
        this.deleteTargetMarketAccount(true, targetMarketAccountLookup)
    }

    public undeleteAccount(lookupData: any) {

        var targetMarketAccountLookup = new (TargetMarketAccountLookup)
        targetMarketAccountLookup.originalName = lookupData.originalName
        targetMarketAccountLookup.targetMarketAccountId = lookupData.targetMarketAccountId
        targetMarketAccountLookup.masterAccountId = lookupData.masterAccountId
        targetMarketAccountLookup.clientAccountId = lookupData.clientAccountId
        targetMarketAccountLookup.targetMarketAccountStatus = lookupData.targetMarketAccountStatus

        this.setTargetMarketAccountDeletedStateCommand(targetMarketAccountLookup)

        this.deleteTargetMarketAccount(false, targetMarketAccountLookup)
    }

    public async openDeleteModal(targetMarketAccount: TargetMarketAccountLookup) {
        this.showDeleteModal = true;
        this.setTargetMarketAccountDeletedStateCommand(targetMarketAccount);
    }


    private setTargetMarketAccountDeletedStateCommand(targetMarketAccount: TargetMarketAccountLookup) {
        this.deletedStateCommand = new TargetMarketAccountDeletedStateCommand();
        this.selectedAccountName = targetMarketAccount.originalName
        this.deletedStateCommand.targetMarketId = this.criteria.targetMarketId;
        this.deletedStateCommand.targetMarketAccountId = targetMarketAccount.targetMarketAccountId;
        this.deletedStateCommand.originalName = targetMarketAccount.originalName;
        this.deletedStateCommand.masterAccountId = targetMarketAccount.masterAccountId
        this.deletedStateCommand.clientAccountId = targetMarketAccount.clientAccountId
        this.deletedStateCommand.targetMarketAccountStatus = targetMarketAccount.targetMarketAccountStatus
        this.deletedStateCommand.dateLastProspected = targetMarketAccount.dateLastProspected
        this.TMADeleteCommand.targetMarketId = this.criteria.targetMarketId
        this.TMADeleteCommand.isBulkAction = false
        this.TMADeleteCommand.targetMarketAccountDeletedStateCommand = this.deletedStateCommand
        this.deleteButtonClicked = false
    }

    private compareTowStrings(valueOne: string, valueTwo: string) {
        if (valueOne.toLocaleLowerCase() === valueTwo.toLocaleLowerCase()) {
            return true;
        } else {
            return false;
        }
    }

    public async editTargetMarketAccount() {
        this.taskRunner.run(async () => {
            const response = await this.targetMarketAccountsApiClient.EditTargetMarketAccount(this.editTargetMarketAccountCommand);
            this.ParseReturnResult(response.data, textConstants.messageText.saveMessage.TargetMarketAccountSaved);
            this.showEditModal = false
            this.refreshMethod()
        });
    }

    private ParseReturnResult(responseDate: any, saveSuccessMessage: string) {
        const cr: CommandResult = responseDate as CommandResult;
        if (cr.success) {
            this.notifications.addSuccess(textConstants.titleText.Saved, saveSuccessMessage);
        } else {
            this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
        }
    }

    /// Bulk Operations
    public OpenBulkActionModal(action: bulkActions) {
        this.bulkActions = action
        this.bulkActionMessage = ""
        if (this.bulkActions === textConstants.generalText.BulkDelete) {
            this.bulkActionMessage = textConstants.messageText.validationMessage.bulkDeleteConfirmation
            this.showBulkActionModal = true
        }
        if (this.bulkActions === textConstants.generalText.BulkUndo) {
            this.bulkActionMessage = textConstants.messageText.validationMessage.bulkUndoConfirmation
            this.showBulkActionModal = true
        }

        if (this.bulkActions === textConstants.generalText.BulkReprospect) {
            this.bulkActionMessage = textConstants.messageText.validationMessage.bulkReprospectConfirmation
            this.showBulkActionModal = true
        }

        if (this.bulkActions === textConstants.generalText.BulkUnblacklist) {
            this.bulkActionMessage = textConstants.messageText.validationMessage.bulkUnblacklistConfirmation
            this.showBulkActionModal = true
        }
    }

    public PerformBulkAction() {
        this.showBulkActionModal = false;
        if (this.bulkActions === textConstants.generalText.BulkDelete) {
            this.bulkDelete()
        }

        if (this.bulkActions === textConstants.generalText.BulkUndo) {
            this.bulkUnDelete()
        }

        if (this.bulkActions === textConstants.generalText.BulkReprospect) {
            this.bulkReprospect()
        }

        if (this.bulkActions === textConstants.generalText.BulkUnblacklist) {
            this.bulkUnblacklist()
        }
        this.selectedItemsCount = 0

        if (this.selectedItems) {
            this.selectedItems.selected = null;
        }

        this.selected = false
    }

    private async bulkDelete() {
        this.TMADeleteCommand.isSelectAll = false

        this.taskRunner.run(async () => {

            var targetMarketAccountLookups = new List(TargetMarketAccountLookup)
            if (this.selectedItems && this.selectedItems.selected) {

                var selected = this.selectedItems.selected

                // Items have been selected using Select All checkbox
                if (selected === true) {
                    this.TMADeleteCommand.targetMarketAccountCriteria = this.criteria
                    this.TMADeleteCommand.targetMarketAccountCriteria.isBulkAction = true
                    this.TMADeleteCommand.unselectedAccounts = this.unSelectedAccounts
                    this.TMADeleteCommand.isSelectAll = true

                    if (this.TMADeleteCommand.targetMarketAccountCriteria.timesProspected !== null) {
                        var timesProspectedIsValid = isNumber(this.TMADeleteCommand.targetMarketAccountCriteria.timesProspected)

                        if (!timesProspectedIsValid) {
                            this.TMADeleteCommand.targetMarketAccountCriteria.timesProspected = []
                        }
                    }

                    if (!isNumber(this.TMADeleteCommand.targetMarketAccountCriteria.priority)) {
                        this.TMADeleteCommand.targetMarketAccountCriteria.priority = 0
                    }
                }

                // Items have been selected individually
                else if (selected) {
                    var keysMap = Object.keys(selected).map(originalName => selected[originalName])

                    this.PopulateTargetMarketAccountLookups(keysMap, targetMarketAccountLookups);

                    this.TMADeleteCommand.targetMarketAccountLookups = targetMarketAccountLookups
                } else {
                    // To-do: Throw 'no items found' error
                    return
                }

                this.TMADeleteCommand.isBulkAction = true
                this.TMADeleteCommand.targetMarketId = this.criteria.targetMarketId

                this.showDeleteModal = false;
                const response = await this.targetMarketAccountsApiClient.DeleteTargetMarketAccount(this.TMADeleteCommand)
                this.ParseReturnResult(response.data.commandResult, textConstants.messageText.saveMessage.BulkDelete)
                this.refreshMethod()
            }
        });
    }

    private async bulkUnDelete() {
        this.TMADeleteCommand.isSelectAll = false

        this.taskRunner.run(async () => {

            var targetMarketAccountLookups = new List(TargetMarketAccountLookup)
            if (this.selectedItems && this.selectedItems.selected) {

                var selected = this.selectedItems.selected

                // Items have been selected using Select All checkbox
                if (selected === true) {
                    this.TMABulkUndeleteCommand.isSelectAll = true
                    this.TMABulkUndeleteCommand.targetMarketAccountCriteria = this.criteria
                    this.TMABulkUndeleteCommand.targetMarketAccountCriteria.isBulkAction = true
                    this.TMABulkUndeleteCommand.unselectedAccounts = this.unSelectedAccounts

                    if (this.TMABulkUndeleteCommand.targetMarketAccountCriteria.timesProspected !== null) {
                        var timesProspectedIsValid = isNumber(this.TMABulkUndeleteCommand.targetMarketAccountCriteria.timesProspected)

                        if (!timesProspectedIsValid) {
                            this.TMABulkUndeleteCommand.targetMarketAccountCriteria.timesProspected = []
                        }
                    }

                    if (!isNumber(this.TMABulkUndeleteCommand.targetMarketAccountCriteria.priority)) {
                        this.TMABulkUndeleteCommand.targetMarketAccountCriteria.priority = 0
                    }
                }

                // Items have been selected individually
                else if (selected) {
                    var keysMap = Object.keys(this.selectedItems.selected).map(originalName => selected[originalName])
                    this.PopulateTargetMarketAccountLookups(keysMap, targetMarketAccountLookups);
                    this.TMABulkUndeleteCommand.targetMarketAccountLookups = targetMarketAccountLookups
                }
            }


            const response = await this.targetMarketAccountsApiClient.UndoDeleteTargetMarketAccount(this.TMABulkUndeleteCommand)
            this.ParseReturnResult(response.data, textConstants.messageText.saveMessage.BulkUndo)
            this.refreshMethod()
        });
    }

    private async bulkReprospect() {
        this.taskRunner.run(async () => {
            var targetMarketAccountLookups = new List(TargetMarketAccountLookup)
            if (this.selectedItems && this.selectedItems.selected) {
                var selected = this.selectedItems.selected

                // Items have been selected using Select All checkbox
                if (selected === true) {
                    this.TMABulkReprospectCommand.isSelectAll = true
                    this.TMABulkReprospectCommand.targetMarketAccountCriteria = new TargetMarketAccountCriteria()
                    this.TMABulkReprospectCommand.targetMarketAccountCriteria = this.criteria
                    this.TMABulkReprospectCommand.targetMarketAccountCriteria.isBulkAction = true
                    this.TMABulkReprospectCommand.unselectedAccounts = this.unSelectedAccounts
                    this.TMABulkReprospectCommand.targetMarketAccountCriteria.timesProspected = []
                    this.TMABulkReprospectCommand.targetMarketAccountCriteria.priority = 0

                }
                // Items have been selected individually
                else if (selected) {
                    this.TMABulkReprospectCommand.isSelectAll = false
                    this.TMABulkReprospectCommand.targetMarketAccountCriteria = new TargetMarketAccountCriteria()
                    this.TMABulkReprospectCommand.targetMarketAccountCriteria = this.criteria
                    this.TMABulkReprospectCommand.targetMarketAccountCriteria.isBulkAction = true
                    this.TMABulkReprospectCommand.targetMarketAccountCriteria.timesProspected = []
                    this.TMABulkReprospectCommand.targetMarketAccountCriteria.priority = 0
                    var keysMap = Object.keys(this.selectedItems.selected).map(originalName => selected[originalName])
                    this.PopulateTargetMarketAccountLookups(keysMap, targetMarketAccountLookups);
                    this.TMABulkReprospectCommand.targetMarketAccountLookups = targetMarketAccountLookups
                }

                const response = await this.targetMarketAccountsApiClient.ReprospectingTargetMarketAccounts(this.TMABulkReprospectCommand)
                this.ParseReturnResult(response.data, textConstants.messageText.saveMessage.ReprospectingInProgress)
                this.refreshMethod()
            }

        });
    }

    private async bulkUnblacklist() {
        this.unblacklistCommand = new TMAUnblacklistCommand()
        this.taskRunner.run(async () => {

            this.unblacklistCommand.isBulkAction = true
            this.unblacklistCommand.clientId = this.clientId
            this.unblacklistCommand.isSelectAll = false

            var domainsToDelete = new List(EditTargetMarketAccountCommand)
            if (this.selectedItems && this.selectedItems.selected) {

                var selected = this.selectedItems.selected

                // Items have been selected using Select All checkbox
                if (selected === true) {
                    this.unblacklistCommand.isSelectAll = true
                    this.unblacklistCommand.targetMarketAccountCriteria = this.criteria
                    this.unblacklistCommand.targetMarketAccountCriteria.isBulkAction = true
                    this.unblacklistCommand.unselectedAccounts = this.unSelectedAccounts

                    if (this.unblacklistCommand.targetMarketAccountCriteria.timesProspected !== null) {
                        var timesProspectedIsValid = isNumber(this.unblacklistCommand.targetMarketAccountCriteria.timesProspected)

                        if (!timesProspectedIsValid) {
                            this.unblacklistCommand.targetMarketAccountCriteria.timesProspected = []
                        }
                    }

                    if (!isNumber(this.unblacklistCommand.targetMarketAccountCriteria.priority)) {
                        this.unblacklistCommand.targetMarketAccountCriteria.priority = 0
                    }
                }

                // Items have been selected individually
                else if (selected) {
                    var keysMap = Object.keys(this.selectedItems.selected).map(originalName => selected[originalName])
                    this.PopulateDomainsToDelete(keysMap, domainsToDelete);
                    this.unblacklistCommand.domainsToDelete = domainsToDelete
                }
            }

            const response = await this.targetMarketAccountsApiClient.BulkOrSingleUnblacklist(this.unblacklistCommand)
            this.ParseReturnResult(response.data, textConstants.messageText.saveMessage.BulkUnblacklist)
            this.refreshMethod()
        });
    }

    public async reprosepectTargetMarketAccount(targetMarketAccountLookup: TargetMarketAccountLookup) {

        this.TMABulkReprospectCommand.isSelectAll = false
        this.TMABulkReprospectCommand.targetMarketAccountCriteria = this.criteria
        this.TMABulkReprospectCommand.targetMarketAccountCriteria.timesProspected = []
        this.TMABulkReprospectCommand.targetMarketAccountCriteria.priority = targetMarketAccountLookup.priority ? 1 : 0

        var newLookup: TargetMarketAccountLookup = new TargetMarketAccountLookup();
        newLookup.fixedName = targetMarketAccountLookup.fixedName;
        newLookup.originalName = targetMarketAccountLookup.originalName;
        newLookup.targetMarketAccountStatus = targetMarketAccountLookup.targetMarketAccountStatus;
        newLookup.subIndustry = targetMarketAccountLookup.subIndustry;
        newLookup.companySize = targetMarketAccountLookup.companySize;
        newLookup.deleted = targetMarketAccountLookup.deleted;
        newLookup.location = targetMarketAccountLookup.location;
        newLookup.domain = targetMarketAccountLookup.domain;
        newLookup.dateLastProspected = targetMarketAccountLookup.dateLastProspected;
        newLookup.priority = targetMarketAccountLookup.priority;
        newLookup.reProspectingStatus = targetMarketAccountLookup.reProspectingStatus;
        newLookup.targetMarketAccountId = targetMarketAccountLookup.targetMarketAccountId;
        newLookup.timesProspected = targetMarketAccountLookup.timesProspected;
        newLookup.masterAccountId = targetMarketAccountLookup.masterAccountId;
        newLookup.clientAccountId = targetMarketAccountLookup.clientAccountId;
        this.TMABulkReprospectCommand.targetMarketAccountLookups = new List(TargetMarketAccountLookup)
        this.TMABulkReprospectCommand.targetMarketAccountLookups.push(newLookup)

        this.taskRunner.run(async () => {
            const response = await this.targetMarketAccountsApiClient.ReprospectingTargetMarketAccounts(this.TMABulkReprospectCommand) //this.taskRunner.waitFor( )
            this.ParseReturnResult(response.data, textConstants.messageText.saveMessage.ReprospectingInProgress);
        });

        this.refreshMethod()
    }

    private PopulateTargetMarketAccountLookups(keysMap: any[], targetMarketAccountLookups: List<TargetMarketAccountLookup>) {
        keysMap.forEach(row => {
            var newLookup = new (TargetMarketAccountLookup);
            newLookup.fixedName = row.fixedName;
            newLookup.originalName = row.originalName;
            newLookup.targetMarketAccountStatus = row.targetMarketAccountStatus;
            newLookup.subIndustry = row.subIndustry;
            newLookup.companySize = row.companySize;
            newLookup.deleted = row.deleted;
            newLookup.location = row.location;
            newLookup.domain = row.domain;
            newLookup.dateLastProspected = row.dateLastProspected;
            newLookup.priority = row.priority ?? 0;
            newLookup.reProspectingStatus = row.reProspectingStatus;
            newLookup.targetMarketAccountId = row.targetMarketAccountId;
            newLookup.masterAccountId = row.masterAccountId;
            newLookup.clientAccountId = row.clientAccountId;
            newLookup.timesProspected = row.timesProspected ?? 0;
            targetMarketAccountLookups.push(newLookup);
        });
    }

    private PopulateDomainsToDelete(keysMap: any[], editCommandList: List<EditTargetMarketAccountCommand>) {
        keysMap.forEach(row => {
            var newEdit = new (EditTargetMarketAccountCommand);
            newEdit.targetMarketId = this.criteria.targetMarketId
            newEdit.fixedName = row.fixedName.trim()
            newEdit.domain = row.domain.trim()
            newEdit.accountStatusId = row.accountStatusId
            newEdit.originalName = row.originalName.trim()
            newEdit.priority = row.priority ?? 0
            newEdit.targetMarketAccountId = row.targetMarketAccountId
            newEdit.masterAccountId = row.masterAccountId
            newEdit.clientAccountId = row.clientAccountId

            editCommandList.push(newEdit);
        });
    }


    public async deleteTargetMarketAccount(deleteInd: boolean, targetMarketAccount: TargetMarketAccountLookup) {

        if (deleteInd) {
            this.setTargetMarketAccountDeletedStateCommand(targetMarketAccount);
        }

        this.taskRunner.run(async () => {
            if (this.TMADeleteCommand.targetMarketAccountDeletedStateCommand) {
                this.TMADeleteCommand.targetMarketAccountDeletedStateCommand.isDeletedState = deleteInd;

                const response = await this.targetMarketAccountsApiClient.DeleteTargetMarketAccount(this.TMADeleteCommand);
                const resultMessage = deleteInd ? textConstants.messageText.saveMessage.TargetMarketAccountDeleted : textConstants.messageText.saveMessage.TargetMarketAccountUnDeleted;

                if (response.data.apiResult.success) {
                    targetMarketAccount.deleted = !targetMarketAccount.deleted;
                    if (targetMarketAccount.targetMarketAccountId === 0) {
                        targetMarketAccount.targetMarketAccountId = response.data.targetMarketAccountLookup.targetMarketAccountId
                        targetMarketAccount.reProspectingStatus = response.data.targetMarketAccountLookup.reProspectingStatus;
                    }
                    this.notifications.addSuccess(
                        textConstants.titleText.Saved,
                        resultMessage,
                    );
                    this.refreshMethod()
                } else {
                    this.notifications.addDanger(
                        textConstants.titleText.DeleteFailed,
                        textConstants.titleText.TMADeleteFailed,
                    );
                }
            }
        });
    }

    public async BlacklistDomain(rowInfo: any) {
        this.blacklistedDomainHelper.QuickAddString.item = rowInfo.domain.trim()
        this.blacklistedDomainHelper.clientId = this.clientId
        this.blacklistedDomainHelper.taskRunner = this.taskRunner
        await this.blacklistedDomainHelper.saveQuickAdd(true, this, true)
    }

    public UnblacklistDomain(rowInfo: any) {
        this.unblacklistCommand = new TMAUnblacklistCommand()
        this.unblacklistCommand.isBulkAction = false
        this.unblacklistCommand.clientId = this.clientId

        var editCommand = new (EditTargetMarketAccountCommand)
        editCommand.targetMarketId = this.criteria.targetMarketId
        editCommand.fixedName = rowInfo.fixedName.trim()
        editCommand.domain = rowInfo.domain.trim()
        editCommand.accountStatusId = rowInfo.accountStatusId
        editCommand.originalName = rowInfo.originalName.trim()
        editCommand.priority = rowInfo.priority
        editCommand.targetMarketAccountId = rowInfo.targetMarketAccountId
        editCommand.masterAccountId = rowInfo.masterAccountId
        editCommand.clientAccountId = rowInfo.clientAccountId

        this.unblacklistCommand.domainsToDelete.push(editCommand)

        this.taskRunner.run(async () => {

            const response = await this.targetMarketAccountsApiClient.BulkOrSingleUnblacklist(this.unblacklistCommand)
            this.ParseReturnResult(response.data, textConstants.messageText.saveMessage.Unblacklist)
            this.refreshMethod()
        });
    }
}