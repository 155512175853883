import Axios, { AxiosPromise } from "axios";
import { injectable } from "inversify";
import { AppService, Types } from "../Services/AppService";
import ApiResult from "Models/ApiResult";
import ReportingQuery from "Models/Reporting/ReportingQuery";

export interface IReportingApiClient {
    getReports(criteria: ReportingQuery): AxiosPromise<ApiResult>
    getClientsWithWoodpeckerId(): AxiosPromise<ApiResult>
    getClientCampaigns(woodpeckerCompanyId: number): AxiosPromise<ApiResult>
}

@injectable()
export default class ReportingApiClient implements IReportingApiClient {

    constructor(public config = AppService.get(Types.Config)) {
    }

    public getReports(criteria: ReportingQuery): AxiosPromise<ApiResult> {
        return (Axios.get(`${this.config.apiPath}/Reporting/GetReportingChart`, { params: criteria }));
    }

    public getClientsWithWoodpeckerId(): AxiosPromise<ApiResult> {
        return (Axios.get(`${this.config.apiPath}/Reporting/GetWoodpeckerClient`))
    }

    public getClientCampaigns(woodpeckerCompanyId: number): AxiosPromise<ApiResult> {
        return (Axios.get(`${this.config.apiPath}/Reporting/GetClientCampaigns?woodpeckerCompanyId=${woodpeckerCompanyId}`))
    }
}