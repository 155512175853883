import React from 'react';
import { observer } from 'mobx-react';
import DashboardVM from '../../Views/Home/DashboardVM';
import { Neo } from '@singularsystems/neo-react';

interface IReportingDatePickerProps {
  viewModel: DashboardVM,
  dateFormat: string,
}

@observer
class ReportingDatePicker extends React.Component<IReportingDatePickerProps> {
  public toDateElement = React.createRef<HTMLInputElement>();

  public onDateSelected(isClosing: boolean) {
    if (isClosing && this.props.viewModel.minDate && this.toDateElement.current) {
        this.toDateElement.current.focus();
    }

    this.props.viewModel.datePicker(this.props.viewModel.selectedClientCampaignId, this.props.viewModel.filteredByChartList)
}

  public render() {
    return (
      <div className='row m-0'>
        <div className="col-6 pl-4 pr-0 pt-0 pb-0 filterOnChart">
          <Neo.FormGroup
            bind={this.props.viewModel.meta.minDate}
            suppressLabel
            hidden={this.props.viewModel.pieChartActive}
            disabled={this.props.viewModel.pieChartActive}
            dateProps={{
              formatString: `'From: '${this.props.dateFormat}`, maxDate: this.props.viewModel.maxDate, minDate: this.props.viewModel.globalMinimum,
              onDateSelected: () => { this.onDateSelected(true) }
            }}/>
        </div>
        <div className="col-6 pl-4 pr-0 pt-0 pb-0 filterOnChart">
          <Neo.FormGroup
            bind={this.props.viewModel.meta.maxDate}
            suppressLabel
            hidden={this.props.viewModel.pieChartActive}
            disabled={this.props.viewModel.pieChartActive}
            dateProps={{
              formatString: `'To: '${this.props.dateFormat}`, minDate: this.props.viewModel.minDate, maxDate: this.props.viewModel.globalMaximum,
              onDateSelected: () => { this.onDateSelected(false) }
            }}
            inputElement={this.toDateElement} />
        </div>
      </div>
    );
  }
}

export default ReportingDatePicker;