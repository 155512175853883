import { List, NeoModel } from '@singularsystems/neo-core';
import { Managers, Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import CampaignMessageStatus from '../../Models/Maintenance/CampaignMessageStatus';
import { AppService, Types } from '../../Services/AppService';
import EmailSignatureTemplate from 'Models/Maintenance/EmailSignatureTemplate';

@NeoModel
export default class CampaignMessagesMaintenanceVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private campaignMessageStatusesApiClient = AppService.get(Types.ApiClients.CampaignMessageStatusesApiClient),
    private emailSignatureTemplateApiClient = AppService.get(Types.ApiClients.EmailSignatureTemplateApiClient)) {

    super(taskRunner);
  }

  public tabManager = new Managers.TabManager(textConstants.titleText.CampaignMessageStatuses);
  public campaignMessageStatusList = new List(CampaignMessageStatus);
  public emailSignatureTemplates = new List(EmailSignatureTemplate);
  public emailSignatureTemplate = new EmailSignatureTemplate();

  public showDefaultSignatureModal: boolean = false;

  public async initialise() {
    // Initialisation of the Campaign Message Statuses
    const response = await this.taskRunner.waitFor(this.campaignMessageStatusesApiClient.get());
    this.campaignMessageStatusList.set(response.data);

    const maintenanceResponse = await this.taskRunner.waitFor(this.emailSignatureTemplateApiClient.get());
    this.emailSignatureTemplates.set(maintenanceResponse.data);
  }

  public async updateCampaignMessagesMaintenance() {
    this.taskRunner.run(async () => {
      const response = await this.campaignMessageStatusesApiClient.saveList(this.campaignMessageStatusList.toJSArray())
      this.campaignMessageStatusList.update(response.data);

      const maintenanceResponse = await this.emailSignatureTemplateApiClient.saveList(this.emailSignatureTemplates.toJSArray());
      this.emailSignatureTemplates.update(maintenanceResponse.data);

      this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.ConfigSaved);
    });
  }

  public openModal(body: EmailSignatureTemplate) {
    this.emailSignatureTemplate = body
    this.showDefaultSignatureModal = true;
  }

  public async toggleTab(
    old_selectedTab: string,
    new_selectedTab: any
  ) {
    const selectedValue = String(new_selectedTab).toLocaleUpperCase();

    switch (selectedValue) {
      case textConstants.Onboarding.IntroductoryPages.toLocaleUpperCase():
        this.tabManager.selectedTab = textConstants.titleText.CampaignMessageStatuses
        break;

      case textConstants.Onboarding.HelpCenter.Text.toLocaleUpperCase():
        this.tabManager.selectedTab = textConstants.titleText.EmailSignatureTemplates
        break;
    }
  }
}