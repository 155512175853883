import { ModelBase, NeoModel, Rules } from '@singularsystems/neo-core'; 

@NeoModel 
export default class SaveCommentCommand extends ModelBase { 
   @Rules.Required()
   public campaignMessageId: number = 0;

   @Rules.Required()
   public comment: string = "";

   public campaignName: string = "";

   public isEmailSignature : boolean = false
}