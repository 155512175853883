import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import IROIndustryMapping from 'Models/Maintenance/IROIndustryMapping';

export interface IIROIndustryMappingApiClient extends Data.IUpdateableApiClient<IROIndustryMapping, number> {

}

@injectable()
export default class IROIndustryMappingApiClient extends Data.UpdateableApiClient<IROIndustryMapping, number> 
    implements IIROIndustryMappingApiClient 
{
    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/IROIndustryMappings`);
    }
}