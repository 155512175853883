import { injectable } from 'inversify';

import {
  AppServices,
  List,
  Misc,
  NeoModel,
  NotifyUtils,
} from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';

import {
  getObjectKey,
  sortArrayByArray,
} from '../../common/utils';
import AuthorisationTypes from '../AuthorisationTypes';
import AssignedRole from '../Models/AssignedRole';
import { NotificationDuration } from '../Models/Enums/NotificationDuration';
import GroupMembershipLookup from '../Models/GroupMembershipLookup';
import MemberFindCriteria from '../Models/MemberFindCriteria';
import NewMembership from '../Models/NewMembership';
import Resource from '../Models/Resource';
import UserGroup from '../Models/UserGroup';
import UserGroupLookup from '../Models/UserGroupLookup';
import UserLookup from '../Models/UserLookup';
import { AppService, Types } from '../../Services/AppService';

export enum UserGroupsViewState {
  ManageList = 2,
  ManageItem = 3,
}

@injectable()
@NeoModel
export default class UserGroupsVM extends Views.ViewModelBase {
  private userGroupIdToLoad = 0;

  public selectedTab: string = "Members";

  public userGroupsLoaded: boolean = false;

  public userGroups = new List(UserGroupLookup);

  public resourceRoles = new List(Resource);

  public userList = new List(UserLookup);

  public userGroup = new UserGroup();

  public currentUserGroupLookup = new UserGroupLookup();

  public groupMemberships = new List(GroupMembershipLookup);

  public assignedRoles = new List(AssignedRole);

  public viewState: UserGroupsViewState = UserGroupsViewState.ManageList;

  public memberFindCriteria = new MemberFindCriteria();

  public isSaving: boolean = false;

  public userNames: string[] = [];

  public showDeleteModal: boolean = false;
  public showDeleteGroupModal: boolean = false;

  public member: GroupMembershipLookup = new GroupMembershipLookup();
  public group: UserGroupLookup = new UserGroupLookup();

  public selectedUser: string = "";
  public selectedGroup: string = "";

  constructor(
    taskRunner = Misc.Globals.appService.get(AppServices.NeoTypes.TaskRunner),
    public apiClient = Misc.Globals.appService.get(
      AuthorisationTypes.ApiClients.AuthorisationApiClient
    ),
    public rolesTask = Misc.Globals.appService.get(
      AppServices.NeoTypes.TaskRunner
    ),
    private clientApiClient = Misc.Globals.appService.get(Types.ApiClients.ClientsApiClient),
    private customAuthService = AppService.get(Types.Security.CustomAuthenticationService)
  ) {
    super(taskRunner);
    this.customAuthService.globalProps.isOnboarding = false;
  }

  public async initialise() {
    this.refresh();
  }

  public async refresh() {
    // load the roles and users. Don't await these
    this.loadResourceRolesAsync();
    this.loadUsersAsync();

    // load the user groups, await these
    const response = await this.taskRunner.waitFor(
      this.apiClient.userGroups.getLookupAsync()
    );
    this.userGroups.set(response.data);
    this.userGroupsLoaded = true;

    if (this.userGroupIdToLoad !== 0) {
      this.currentUserGroupLookup = this.userGroups.find(
        (ug) => ug.userGroupId === this.userGroupIdToLoad
      )!;
    }
  }

  public async loadUserGroup(userGroupId: number) {
    if (!this.userGroupsLoaded) {
      this.userGroupIdToLoad = userGroupId;

      // still load the full user group while we are waiting for the lookups
      await this.manageUserGroupId(userGroupId);
    } else {
      var userGroup = this.userGroups.find(
        (ug) => ug.userGroupId === userGroupId
      );
      if (userGroup) {
        await this.manageUserGroup(userGroup);
      }
    }
  }

  public async loadResourceRolesAsync() {
    const response = await this.rolesTask.waitFor(
      this.apiClient.resources.getRolesAsync()
    );
    let responseData = response.data;

    responseData = this.reorderRoles(responseData);
    this.resourceRoles.set(responseData);
    if (this.viewState === UserGroupsViewState.ManageItem) {
      this.computeSelectedRoles();
    }
  }

  private reorderRoles(responseData: any) {
    const comxRolseSortOrder = [
      "Clients",
      "Target Markets",
      "Maintenance",
      "Master Data",
      "Dashboard",
      "Greylist",
      "Blacklist",
      "Batch Review",
      "Ideal Customer Profile",
      "Campaign Messages",
      "Settings",
      "Technical Integration_Outbound Emails",
      "Technical Integration_Advanced Email Settings",
      "Technical Integration_Internal Test",
    ];

    const categorySortOrder = ["Security", "Users", "ComX"];

    const comxRoleKey = getObjectKey(responseData, "resourceName", "ComX");
    const comxRoleCategories = responseData[comxRoleKey].categories;
    const UserCategoryKey = getObjectKey(
      comxRoleCategories,
      "category",
      "Users"
    );

    const UserCategory = {
      $id: comxRoleCategories[UserCategoryKey]["$id"],
      resourceName: comxRoleCategories[UserCategoryKey].category,
      categories: [comxRoleCategories[UserCategoryKey]],
    };
    comxRoleCategories.pop(UserCategoryKey);
    responseData.push(UserCategory);

    responseData[comxRoleKey].categories = sortArrayByArray(
      comxRoleCategories,
      comxRolseSortOrder,
      "category"
    );
    responseData = sortArrayByArray(
      responseData,
      categorySortOrder,
      "resourceName"
    );
    return responseData;
  }
  public async loadUsersAsync() {
    const response = await this.rolesTask.waitFor(
      this.apiClient.users.getLookupAsync()
    );

    this.userList.set(
      response.data.sort(
        (a: any, b: any) => a.preferredName.toLowerCase() > b.preferredName.toLowerCase() ? 1 :
          a.preferredName.toLowerCase() < b.preferredName.toLowerCase() ? -1 : 0));
  }

  public async addLinkedClientName() {
    const response = await this.clientApiClient.getLinkedClients(this.groupMemberships.map(a => a.userName));
    let result = response.data;

    this.groupMemberships.forEach(member => {
      let matchingUser = result.find(r => r.userName === member.userName)

      if (matchingUser) {
        member.clientName = matchingUser.clientName
      }
    });

  }

  public addUserGroup() {
    this.currentUserGroupLookup = new UserGroupLookup();
    this.resetUserGroup();
    this.viewState = UserGroupsViewState.ManageItem;
  }

  public resetViewState() {
    this.viewState = UserGroupsViewState.ManageList;
  }

  private resetUserGroup() {
    this.userGroup = new UserGroup();
    this.groupMemberships.set([]);
    this.assignedRoles.set([]);
  }

  public async manageUserGroup(item: UserGroupLookup) {
    await this.manageUserGroupId(item.userGroupId);

    this.currentUserGroupLookup = item;
  }

  public resetAssignedRoles() {
    this.computeSelectedRoles();
  }

  private async manageUserGroupId(userGroupId: number) {
    try {
      const response = await this.apiClient.userGroups.get(userGroupId);

      if (response.data.userGroup) {
        this.userGroup.set(response.data.userGroup);
        this.groupMemberships.set(response.data.groupMemberships);
        this.assignedRoles.set(response.data.assignedRoles);

        this.addLinkedClientName();

        this.computeSelectedRoles();
        this.viewState = UserGroupsViewState.ManageItem;
      } else {
        this.resetUserGroup();
      }
    } catch (e) {
      alert(e);
    }
  }

  private allRoleCount = 0;

  public get assignedRolesLength() {
    if (this.userGroup.isAdministratorGroup) {
      return this.allRoleCount;
    } else {
      return this.assignedRoles.length;
    }
  }

  public computeSelectedRoles() {
    // make a set with the assigned role ids to make the lookup faster
    if (this.userGroup.isAdministratorGroup) {
      this.allRoleCount = 0;

      this.resourceRoles.forEach((resource) => {
        resource.categories.forEach((category) => {
          category.roles.forEach((role) => {
            role.selected = true;
            this.allRoleCount++;
          });
        });

        resource.markOld();
      });
    } else {
      const roleMap: { [id: number]: AssignedRole } = {};
      this.assignedRoles.forEach((item, index) => {
        roleMap[item.roleId] = this.assignedRoles[index];
      });
      // now loop through all resource roles and mark as selected/unselected
      this.resourceRoles.forEach((resource) => {
        resource.categories.forEach((category) => {
          category.roles.forEach((role) => {
            if (roleMap[role.roleId]) {
              role.selected = true;
              role.assignedRoleId = roleMap[role.roleId].assignedRoleId;
            } else {
              role.selected = false;
              role.assignedRoleId = null;
            }
          });
        });
        resource.markOld();
      });
    }
  }

  public async addMember() {
    if (this.canAddMember()) {
      this.taskRunner.run(async () => {
        try {
          if (this.userGroup.isDirty) {
            // Save user groups so that we know we have a user group id
            await this.saveUserGroup();
          }

          const membership = new NewMembership();
          membership.userGroupId = this.userGroup.userGroupId;
          membership.userId = this.memberFindCriteria.addUserId as number;

          this.isSaving = true;
          const response = await this.apiClient.memberships.add(membership);
          // update the list to refresh the UI
          this.currentUserGroupLookup.memberCount++;
          this.groupMemberships.update([response.data]);
          // clear the selected user
          this.memberFindCriteria.addUserId = null;

          NotifyUtils.addSuccess(
            "Member Added",
            `New member added to ${this.userGroup.userGroupName}`,
            NotificationDuration.Standard
          );
        } finally {
          this.isSaving = false;
        }
      });
    }
  }

  public canAddMember() {
    if (this.memberFindCriteria.addUserId) {
      const existingMember = this.groupMemberships.find(
        (m) => m.userId === Number(this.memberFindCriteria.addUserId)
      );
      if (existingMember) {
        NotifyUtils.addWarning(
          "Already a member",
          `${existingMember.memberName} is already a member of the group`,
          NotificationDuration.Standard
        );
        this.memberFindCriteria.addUserId = null;
        return false;
      }
      return true;
    } else {
      NotifyUtils.addWarning(
        "Select user",
        "Select the user first",
        NotificationDuration.Standard
      );
    }
    return false;
  }

  public openDeleteModal(groupMember: GroupMembershipLookup) {
    this.member.membershipId = groupMember.membershipId;
    this.member = groupMember;
    this.showDeleteModal = true;
    this.selectedUser = groupMember.memberName;
  }

  public openDeleteGroupModal(userGroup: UserGroupLookup) {
    this.group.userGroupId = userGroup.userGroupId;
    this.group = userGroup;
    this.showDeleteGroupModal = true;
    this.selectedGroup = userGroup.userGroupName;
  }

  public async removeMember() {
    this.showDeleteModal = false;
    this.taskRunner.run(async () => {
      this.isSaving = true;
      await this.apiClient.memberships.remove(this.member.membershipId);
      this.currentUserGroupLookup.memberCount--;
      this.groupMemberships.remove(this.member);
      this.isSaving = false;
      NotifyUtils.addSuccess(
        "Member Removed",
        `Member removed from ${this.userGroup.userGroupName}`,
        NotificationDuration.Standard
      );
    });
  }

  public canSave(): boolean {
    return (
      this.isSaving || (!this.userGroup.isDirty && !this.resourceRoles.isDirty)
    );
  }

  public async save() {
    this.taskRunner.run(async () => {
      try {
        if (this.userGroup.isDirty) {
          await this.saveUserGroup();
        }

        if (this.resourceRoles.isDirty) {
          await this.saveAssignedRoles();
        }

        NotifyUtils.addSuccess(
          "Saved Successfully",
          `User group ${this.userGroup.userGroupName} saved successfully`,
          NotificationDuration.Standard
        );
      } finally {
        this.isSaving = false;
      }
    });
  }

  public async saveAssignedRoles() {
    this.updateAssignedRoles();

    this.isSaving = true;
    const response = await this.apiClient.assignedRoles.save(
      this.assignedRoles
    );

    this.assignedRoles.update(response.data);
    this.computeSelectedRoles();
    this.isSaving = false;
  }

  private updateAssignedRoles() {
    this.resourceRoles.forEach((resource) => {
      if (resource.isDirty) {
        resource.categories.forEach((category) => {
          if (category.isDirty) {
            category.roles.forEach((role) => {
              if (role.isDirty) {
                if (role.selected && role.assignedRoleId === null) {
                  // this is a new role
                  const newRole = this.assignedRoles.addNew();
                  newRole.userGroupId = this.userGroup.userGroupId;
                  newRole.roleId = role.roleId;
                } else if (
                  role.selected === false &&
                  role.assignedRoleId !== null
                ) {
                  // this is a deleted role
                  const roleToDelete = this.assignedRoles.find(
                    (r) => r.assignedRoleId === role.assignedRoleId
                  );
                  this.assignedRoles.remove(roleToDelete as AssignedRole);
                }
              }
            });
          }
        });
      }
    });
  }

  public async saveUserGroup() {
    this.isSaving = true;
    try {
      const response = await this.apiClient.userGroups.save(this.userGroup);

      this.userGroup.set(response.data);
      // update the base list (to add if new, and change name if existing)
      if (!this.currentUserGroupLookup.isNew) {
        // set the entity identifier so that it does not add a new one to the list
        response.data.entityIdentifier = this.currentUserGroupLookup.entityIdentifier;
      }
      this.userGroups.update([response.data]);

      if (this.currentUserGroupLookup.isNew) {
        // find the one that was just added
        this.currentUserGroupLookup = this.userGroups.find(
          (ug) => ug.userGroupId === this.userGroup.userGroupId
        ) as UserGroupLookup;
      }
    } finally {
      this.isSaving = false;
    }
  }

  public deleteUserGroup() {
    this.showDeleteGroupModal = false;
    this.taskRunner.run(async () => {
      this.isSaving = true;
      await this.apiClient.userGroups.delete(this.group.userGroupId);
      this.userGroups.remove(this.group);
      this.isSaving = false;

      NotifyUtils.addSuccess(
        "User Group Deleted",
        `User group ${this.userGroup.userGroupName} has been deleted`,
        NotificationDuration.Standard
      );
    })
  }

  public removeNotifications() {
    NotifyUtils.store.notifications = [];
  }

  public addClientsToDisplay(): boolean {
    if (this.userGroup.userGroupName.toLowerCase() === "client" || this.userGroup.userGroupName.toLowerCase() === "clients") {
      return true;
    }
    return false;
  }
}


