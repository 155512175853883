import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class ClientCampaignWoodpeckerEmailLookup extends ModelBase {
  public campaignEmailId : number = 0;
  
  public campaignName: string = "";
  public emailNumber: number = 0;

  public mailSentCount: number = 0;
  public deliveryCount: number = 0;
  public deliveryRate: number = 0;

  public bouncedCount: number = 0;
  public bouncedRate: number = 0;

  public openedCount: number = 0;
  public openedRate: number = 0;

  public repliedCount: number = 0;
  public repliedRate: number = 0;
}