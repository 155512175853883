import {ModelBase, NeoModel} from '@singularsystems/neo-core';


@NeoModel
export default class TargetMarketRoleLookup extends ModelBase {

    // Properties
    public roleId: number = 0;
    public targetMarketRoleName: string = "";
    public roleName: string = "";
}