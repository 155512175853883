import React, { useEffect } from "react";
import Button from "Components/Button";
import ArrowRightUp from "assets/svgs/ArrowRightUp";
import CircleIcon from "assets/svgs/Circle";
import CircleChecked from "assets/svgs/CircleChecked";
import {
  StyledLessonCard,
  StyledLessonCardTitle,
  StyledLessonCardDescription,
  StyledLessonLink,
  StyledLessonCardFooter,
  StyledLessonCardHeader,
  StyledLine,
  StyledVideoContent,
  StyledVideoContainer,
} from "../../OnboardingIntroduction.styles";
import { textConstants } from "common/textConstants";
import ClientOnboardingStepIntroduction from 'Models/Onboarding/ClientOnboardingStepIntroduction';
import ReactHtmlParser from 'react-html-parser';
import ReactPlayer from 'react-player';
import Tabs from '../Tabs';
import { StyledCard } from 'Components/Card/styles';
import { AppService, Types } from "Services/AppService";
import { OnboardingSteps } from "Models/Enums/ApplicationEnums";

export interface ILessonProps {
  active?: boolean;
  setCompleted: (stepIntroductionId: number) => void;
  clientOnboardingStepIntroduction: ClientOnboardingStepIntroduction;
  tabsTitle?: string[];
  tabsContent: string[];
  btnText: string;
  isEmailSetup?: boolean;
}

let emailSetupVideoLink = ""

const getEmailURL = async () => {
  const comXConfigApiClient = AppService.get(Types.ApiClients.ComXConfigurationsApiClient)

  let config = await comXConfigApiClient.get()

  if (config) {
    emailSetupVideoLink = config.data.find((config: any) => config.configCode === textConstants.generalText.EmailSetupVideo)?.value as string
  }
}

const introductionPageBodyText = (clientOnboardingStepIntroduction: ClientOnboardingStepIntroduction, isEmailSetup?: boolean) => {

  const { onboardingStepIntroduction } = clientOnboardingStepIntroduction

  let bodyText = onboardingStepIntroduction.bodyText && <StyledLessonCardDescription isEmailSetup={isEmailSetup}>
    {ReactHtmlParser(onboardingStepIntroduction.bodyText)}
  </StyledLessonCardDescription>

  return clientOnboardingStepIntroduction.onboardingStepIntroduction.isMultiStep ? <StyledCard className="introductionPageIsMultipleSteps">{bodyText}</StyledCard> : bodyText
}

let mailtoLink: string = "";
let mailToEmailBody: string = "";
let mailToDisplay: string = "";

const getMailToText = async () => {
  if (mailToDisplay.length === 0) {
    let onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient)

    const response = await onboardingApiClient.getMailToText();
    if (response.data) {
      mailToDisplay = response.data;
      mailToEmailBody = mailToDisplay.split(textConstants.generalText.NewLine).join(textConstants.generalText.MailToNewLine);

      mailtoLink = "mailto:?subject=Technical Integration Information Requirements&body=" + mailToEmailBody
    }
  }
}

const Lesson = ({
  active,
  setCompleted,
  clientOnboardingStepIntroduction,
  tabsTitle = [textConstants.video.About, textConstants.video.Transcript],
  tabsContent,
  btnText,
  isEmailSetup,
}: ILessonProps) => {

  useEffect(() => {
    getMailToText()
    getEmailURL()
  }, []);

  return (
    <StyledLessonCard>
      <StyledLessonCardHeader completed={active}>
        {clientOnboardingStepIntroduction!.isComplete ? <CircleChecked /> : <CircleIcon />}
        <StyledLessonCardTitle completed={clientOnboardingStepIntroduction!.isComplete}>
          {clientOnboardingStepIntroduction.onboardingStepIntroduction.headerText}
        </StyledLessonCardTitle>

        {/* Adding the Send Email Specs to IT */}
        {clientOnboardingStepIntroduction.onboardingStepIntroduction.isMultiStep && active && !clientOnboardingStepIntroduction!.isComplete &&
          <a className="sendEmailSpecsButton ml-auto mt-0 mb-0"
            target={"_blank"}
            rel="noreferrer"
            href={mailtoLink}>
            {textConstants.Onboarding.EmailModal.EmailSpecsToIT}
          </a>
        }
      </StyledLessonCardHeader>

      {active && !clientOnboardingStepIntroduction!.isComplete && (
        <>
          {introductionPageBodyText(clientOnboardingStepIntroduction, isEmailSetup)}
          {(clientOnboardingStepIntroduction.onboardingStepIntroduction.url
            && clientOnboardingStepIntroduction.onboardingStepIntroduction.onboardingStepId !== OnboardingSteps.TechnicalIntegration) && <StyledLessonCardDescription>
              <StyledLessonLink href={clientOnboardingStepIntroduction.onboardingStepIntroduction.url} target="_blank">
                {clientOnboardingStepIntroduction.onboardingStepIntroduction.urlTitle}
              </StyledLessonLink>
              <ArrowRightUp />
            </StyledLessonCardDescription>}
          {clientOnboardingStepIntroduction.onboardingStepIntroduction.videoURL && <StyledVideoContainer>
            <ReactPlayer
              url={clientOnboardingStepIntroduction.onboardingStepIntroduction.videoURL}
              controls={true}
              width="100%"
              height="400px"
            />
            <StyledVideoContent>
              <Tabs tabs={tabsTitle} tabsContent={tabsContent} />
            </StyledVideoContent>
          </StyledVideoContainer>}
          <StyledLine />
          <StyledLessonCardFooter>
            <Button
              className={"mt-4 mb-0"}
              onClick={() => setCompleted(clientOnboardingStepIntroduction!.clientOnboardingStepIntroductionId)}
              variant={"contained"}
              size={"medium"}
              buttonType={"primary"}
              style={{ width: "208px" }}
            >
              {btnText}
            </Button>
          </StyledLessonCardFooter>
        </>
      )}
    </StyledLessonCard>
  );
};

export default Lesson;
