import { List, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';
import TMLookup from '../TMLookup';

@NeoModel
export default class CloneClientCampaignMessageCommand extends ModelBase {
   
  // Properties
  @Rules.Required()
  public clientCampaignMessageId: number = 0;

  @Rules.StringLength(500)
  @Rules.Required()
  @Display("Name:")
  public campaignName: string = "";

  @Rules.Required()
  public targetMarkets : List<TMLookup> | null = null

  public isEmailSignature : boolean = false

  public isComXUser : boolean = false
}