import React from "react";
import { AppService, Types } from "../Services/AppService";
import ChangePassword from "./ChangePassword/ChangePassword";
import ChangePasswordVM from "./ChangePassword/ChangePasswordVM";


export default class UserStatus extends React.Component {

    private authService = AppService.get(Types.Neo.Security.AuthenticationService);
    private config = AppService.get(Types.Config);
    private changePasswordModel = new ChangePasswordVM();

    constructor(props: any) {
        super(props);
        this.logoutClicked = this.logoutClicked.bind(this);
    }

    private logoutClicked(e: React.MouseEvent) {
        e.preventDefault();
        AppService.get(Types.Neo.Routing.NavigationHelper).navigateInternal('/loggedout');
    }

    private changePassword() {
        this.changePasswordModel.showChangePassword = true;
    }

    private getUserInitials(user: any){
        return `${user.givenName[0]}${user.familyName[0]}`
    }

    public render(): JSX.Element | null {
        const user = this.authService.user as any;

        return (
            user &&
            <div className="app-user-icon row m-0 p-24">
                <div className="userDisplayedIcon m-auto p-0"><div className="userDisplayedText">{this.getUserInitials(user)}</div></div>
                <div className="app-user-card" >
                    <div className="card-arrow" />
                    <div className="card ">
                        <div className="card-body border-bottom-light text-dark">
                            <h5 className="mb-0">{user.displayName}</h5>
                            <p className="card-text">{user.userName}</p>
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item" onClick={() => this.changePassword()}>Change Password</li>
                            <li className="list-group-item custom-text-white" onClick={this.logoutClicked} >Logout</li>
                        </ul>
                    </div>
                </div>
                <ChangePassword viewModel={this.changePasswordModel} />
            </div>
        )
    }
}
