import { ModelBase, NeoModel, Validation } from "@singularsystems/neo-core";
import { isURL } from "../../common/utils";

@NeoModel
export default class InformationManagementDetail extends ModelBase{

    public informationManagementDetailId : number = 0; 

    public viewName : string = "";

    public videoUrl : string = "";

    public isDisplayed : boolean = false; 

    public displayText : string = "";

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.videoUrl !== "" && !isURL(c.videoUrl), "Please provide a valid URL");
    }
}