import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import EmailSignatureTemplate from 'Models/Maintenance/EmailSignatureTemplate';

export interface IEmailSignatureTemplateApiClient extends Data.IUpdateableApiClient<EmailSignatureTemplateApiClient, number> {

}

@injectable()
export default class EmailSignatureTemplateApiClient extends Data.UpdateableApiClient<EmailSignatureTemplate, number> implements IEmailSignatureTemplateApiClient {

  constructor(config = AppService.get(Types.Config)) {
    super(`${config.apiPath}/EmailSignatureTemplates`);
  }
}