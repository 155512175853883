import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import DashboardCardVM from './DashboardCardVM';
import { observer } from 'mobx-react';
import { textConstants } from '../../../common/textConstants';
import DashboardCardIndividualScreenView from './DashboardCardIndividualScreenView';
import GoBack from '../../../Components/GoBack';
import * as Icon from 'react-feather';

@observer
export default class DashboardCardView extends Views.ViewBase<DashboardCardVM> {

    constructor(props: unknown) {
        super("Dashboard Card", DashboardCardVM, props);
    }

    public render() {
        return (
            <div className='popupMaintenance'>
                <GoBack />
                <Neo.Card>
                    <h2 className='p-24 mt-4'>{textConstants.titleText.DashboardCardMaintenance}</h2>
                    <div className='pb-3'>
                        {this.viewModel.showInitialTable &&
                            <NeoGrid.Grid items={this.viewModel.dashboardCards}>
                                {(item, meta) => (
                                    <NeoGrid.Row >
                                        <NeoGrid.Column display={meta.cardName} />
                                        <NeoGrid.ButtonColumn>
                                            <Neo.Button variant="light" className="text-center" icon={<Icon.Edit2 />}
                                                onClick={() => {
                                                    this.viewModel.dashboardCard = item;
                                                    this.viewModel.navigationList.push("Individual Screen Maintenance");
                                                    this.viewModel.toggleView();
                                                }}>
                                            </Neo.Button>
                                        </NeoGrid.ButtonColumn>
                                    </NeoGrid.Row>

                                )}
                            </NeoGrid.Grid>
                        }
                    </div>

                    {this.viewModel.showPerScreenView &&
                        (this.viewModel.dashboardCard.cardName !== textConstants.titleText.VisitComXDbTitle) &&
                        <DashboardCardIndividualScreenView
                            viewModel={this.viewModel}
                            dashboardCard={this.viewModel.dashboardCard}
                            save={() => { this.viewModel.saveIndividualScreenData() }}

                        />}
                    {this.viewModel.showPerScreenView &&
                        (this.viewModel.dashboardCard.cardName === textConstants.titleText.VisitComXDbTitle) &&
                        <DashboardCardIndividualScreenView
                            viewModel={this.viewModel}
                            dashboardCard={this.viewModel.dashboardCard}
                            save={() => { this.viewModel.saveIndividualScreenData() }}
                            comxConfig={this.viewModel.comXConfig}
                        />}

                    {/* Invalid Data Modal */}
                    <Neo.Modal title={textConstants.titleText.InvalidData} show={this.viewModel.showInvalidDataModal}
                        onClose={() => this.viewModel.showInvalidDataModal = false}
                        closeButton={{ text: "Close", variant: "light" }}
                    >
                        {textConstants.generalText.enterValidURL}
                    </Neo.Modal>
                </Neo.Card>
            </div>
        );
    }
}