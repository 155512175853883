export enum InvitedUserStatus {
    /// <summary>
    /// Gets Active.
    /// </summary>
    Active,

    /// <summary>
    /// Gets Invited User.
    /// </summary>
    InvitedUser,

    /// <summary>
    /// Gets Blocked User.
    /// </summary>
    BlockedUser,

    /// <summary>
    /// Gets UnblockedUser.
    /// </summary>
    UnblockedUser,

    /// <summary>
    /// Gets RegisteredUser.
    /// </summary>
    RegisteredUser,

    /// <summary>
    /// Gets Invite Pending.
    /// </summary>
    InvitePendingUser,
}

export enum Navigation {
    goToTargetMarkets = "Navigate to Target Markets View",

    goToCampaignMessages = "Navigate to Campaign Messages View",

    goToClientSettings = "Navigate to Client Settings View",

    goToCustomerProfiles = "Navigate to Customer Profiles View",

    goToClientReports = "Navigate to Client Reports",
}

export enum OnboardingSteps {
    /// <summary>
    /// IdealCustomerProfiles
    /// </summary>
    IdealCustomerProfiles = 1,

    /// <summary>
    /// TargetMarkets
    /// </summary>
    TargetMarkets,

    /// <summary>
    /// BlackList
    /// </summary>
    BlackList,

    /// <summary>
    /// BookAMeeting
    /// </summary>
    BookAMeeting,

    /// <summary>
    /// CampaignMessages
    /// </summary>
    CampaignMessages,

    /// <summary>
    /// TechnicalIntegration
    /// </summary>
    TechnicalIntegration,
}

export enum TooltipTypes {

    /// <summary>
    /// OpenRate
    /// </summary>
    OpenRate = "Open Rate",

    /// <summary>
    /// ReplyRate
    /// </summary>
    ReplyRate = "Reply Rate",

    /// <summary>
    /// BounceRate,
    /// </summary>
    BounceRate = "Bounce Rate",

    /// <summary>
    /// PositiveReplies
    /// </summary>
    PositiveReplies = "Positive Replies",

    /// <summary>
    /// Score
    /// </summary>
    Score = "Score",

    /// <summary>
    /// Header
    /// </summary>
    Header = "Header",

    /// <summary>
    /// SmallCard,
    /// </summary>
    SmallCard = "SmallCard",
}

export enum ClientCampaignDataStatus {
    /// <summary>
    /// Has Completed retrieval of data from source
    /// </summary>
    Complete = 0,

    /// <summary>
    /// Data still being retrieved from source
    /// </summary>
    Updating = 1,

    /// <summary>
    /// Failed to update data from source
    /// </summary>
    Failed = 2,
}

export enum MockDataJSONType {
    ReplyExperience = 0,
    CampaignMessageStatistics = 1,
    CampaignGraphMonthly = 2,
    CampaignGraphDaily = 3,
}

export enum CountryCodes {
    none = 1, DZ, AO, BJ, BW, BF, BI, CM, CV, CF, TD, CD, DJ, EG, GQ, ER, ET,
    GA, GM, GH, GN, GW, CI, KE, LS, LR, LY, MG, MW, ML, MR, MU, YT,
    MA, MZ, NA, NE, NG, CG, RE, RW, SH, ST, SN, SC, SL, SO, ZA, SS,
    SD, SR, SZ, TG, TN, UG, TZ, EH, YE, ZM, ZW, AI, AG, AR, AW, BS,
    BB, BQ, BZ, BM, BO, VG, BR, CA, KY, CL, CO, KM, CR, CU, CW, DM,
    DO, EC, SV, FK, GF, GL, GD, GP, GT, GY, HT, HN, JM, MQ, MX, MS,
    NI, PA, PY, PE, PR, BL, KN, LC, PM, VC, SX, TT, TC, US, VI, UY,
    VE, AB, AF, AZ, BD, BT, BN, KH, CN, GE, HK, IN, ID, JP, KZ, LA,
    MO, MY, MV, MN, MM, NP, KP, MP, PW, PG, PH, SG, KR, LK, TW, TJ,
    TH, TL, TM, VN, AX, AL, AD, AM, AT, BY, BE, BA, BG, HR, CY, CZ,
    DK, EE, FO, FI, FR, DE, GI, GR, GG, HU, IS, IE, IM, IT, JE, XK,
    LV, LI, LT, LU, MT, MD, MC, ME, NL, MK, NO, PL, PT, RO, RU, SM,
    RS, SK, SI, ES, SE, CH, TR, UA, GB, VA, BH, IR, IQ, IL, KW, JO,
    KG, LB, OM, PK, PS, QA, SA, SY, AE, UZ, AS, AU, CX, CC, CK, FJ,
    PF, GU, KI, MH, FM, NC, NZ, NR, NU, NF, WS, SB, TK, TO, TV, VU,
    WF, AQ, EU, JR, OLY, UN
}

export enum OnboardingPopups {
    ICPOnboardingFinished = "ICP Onboarding Finished",
    TargetMarketOnboardingFinished = "Target Market Onboarding Finished",
    BlacklistOnboardingFinished = "Blacklist Onboarding Finished",
    CampaignMessageOnboardingFinished = "Campaign Message Onboarding Finished",
    TechnicalIntegrationOnboardingFinished = "Technical Integration Onboarding Finished"
}

export enum SeriesNames {
    Email1Series = 0,
    PositiveSeries = 0,
    NeutralSeries = 1,
    NegativeSeries = 2,
}

export enum HTTPResponseCodes {
    Ok = 200,
    Created = 201,
    Accepted = 202
}

export enum ActionListTypes {
    HotLead = "HotLead",
    WarmLead = "WarmLead",
    ColdLead = "ColdLead"
}

export enum ICPStatus {
    Complete = "CompleteStatus",
    Draft = "DraftStatus"
}

export enum DashboardCards {
    VisitComXHub = "DashboardCard1",
    HighlyRecommended = "DashboardCard2",
    AddBlacklist = "DashboardCard3",
    BookSupport = "DashboardCard4"
}