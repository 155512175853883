import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import ReportingVM from './ReportingVM';
import { observer } from 'mobx-react';
import NotFound from 'Views/NotFound/NotFound';
import { textConstants } from 'common/textConstants';
import ColumnChart from 'Components/ColumnChart';

class ReportingParams {

}

@observer
export default class ReportingView extends Views.ViewBase<ReportingVM, ReportingParams> {
  public static params = new ReportingParams();

  constructor(props: unknown) {
    super("Reporting", ReportingVM, props);
  }

  public async viewParamsUpdated() {

  }

  async componentDidMount() {
    const { viewModel } = this

    if (viewModel.isInitialLoad) {
      await viewModel.initialise()

      viewModel.isInitialLoad = false;
    }
  }

  public render() {
    return (
      <React.Fragment>
        {/* Internal Charts */}
        {!this.viewModel.isClientUser &&
          <Neo.Card className='p-4 ReportingView'>
            <h2>
              {textConstants.titleText.Reporting}
            </h2>

            {this.viewModel.isGetReportsComplete &&
              <>
                <div className='row m-0'>
                  <Neo.AutoCompleteDropDown
                    bind={this.viewModel.meta.selectedClientId}
                    items={this.viewModel.reportingClientFilters}
                    placeholder={textConstants.Reporting.allClients}
                    onItemSelected={async () => {
                      this.viewModel.selectedCampaignId = null
                      await this.viewModel.getReportingInformation()
                      await this.viewModel.generateClientCampaignFilters(this.viewModel.selectedClientId)
                    }}
                  />
                  <div className='col p-0'></div>
                  <div className='row m-0'>

                    <Neo.FormGroup
                      bind={this.viewModel.meta.selectedReportId}
                      placeholder={""}
                      suppressLabel={true}
                      select={{
                        items: this.viewModel.reportingReportFilters, allowNulls: true,
                        nullText: this.viewModel.setUserFriendlyName(textConstants.Reporting.clientPerformaceOverview)
                      }}
                      onChange={async () => {
                        await this.viewModel.getReportingInformation()
                      }}
                      className={"filterDropDown col ml-4 p-0"}
                    />
                    {(this.viewModel.selectedClientId !== null && this.viewModel.selectedClientId !== 0) && this.viewModel.reportingClientCampaignFilters
                      && (this.viewModel.selectedReportId !== null) &&
                      <Neo.FormGroup
                        bind={this.viewModel.meta.selectedCampaignId}
                        placeholder={""}
                        suppressLabel={true}
                        select={{ items: this.viewModel.reportingClientCampaignFilters, allowNulls: true, nullText: textConstants.Reporting.allCampaigns }}
                        onChange={async () => {
                          await this.viewModel.getReportingInformation()
                        }}
                        className={"filterDropDown col ml-4 p-0"}
                      />
                    }
                  </div>
                </div>
                <div className='row m-0'>
                  <div className='col'>
                    <div className='row pull-right'>
                      <Neo.FormGroup
                        onChange={async () => {
                          await this.viewModel.getReportingInformation()
                        }}
                        className='datepicker col ml-4 p-0'
                        suppressLabel
                        bind={this.viewModel.meta.startDate}
                        dateProps={{
                          formatString: `'From: '${textConstants.dateformat.dayMonthYearNoTime}`,
                          maxDate: this.viewModel.endDate,
                          onDateSelected: async () => { await this.viewModel.getReportingInformation() }
                        }}
                      />

                      <Neo.FormGroup
                        onChange={async () => {
                          await this.viewModel.getReportingInformation()
                        }}
                        className='datepicker col ml-4 p-0'
                        suppressLabel
                        bind={this.viewModel.meta.endDate}
                        dateProps={{
                          formatString: `'To: '${textConstants.dateformat.dayMonthYearNoTime}`,
                          minDate: this.viewModel.startDate,
                          maxDate: this.viewModel.maxDate,
                          onDateSelected: async () => { await this.viewModel.getReportingInformation() }
                        }}
                      />
                    </div>
                  </div>
                </div>

                {!this.viewModel.isEnoughData &&
                  <div className="notEnoughChartDataWords">
                    <Neo.FormGroup suppressLabel isReadOnly display={this.viewModel.meta.notEnoughDataOnChart} />
                  </div>
                }
                {this.viewModel.dataHasChanged}
                <ColumnChart
                  SeriesData={this.viewModel.reportData}
                  SeriesNames={this.viewModel.reportProperties}
                  SeriesColours={textConstants.Reporting.chartBaseColour}
                  XAxisCategories={this.viewModel.reportCategories}
                  hasDataChanged={() => {
                    return this.viewModel.hasDataChanged()
                  }}
                  visibleSeries={this.viewModel.visibleSeries}
                  additionalOptions={this.viewModel.additionalOptionsColumnChart()}
                />
              </>
            }
          </Neo.Card>
        }

        {/* Client Related Charts */}
        {this.viewModel.isClientUser &&
          <div className='overflow-hidden'>
            <NotFound />
          </div>
        }
      </React.Fragment>
    );
  }
}