import React from 'react';
import * as Icon from 'react-feather';


interface IActionListTabProps {
    tabName?: string,
    icon: any,
    unseenCount?: number,
    total?: number,
    isSelected: boolean,
    onClick: () => void,
}

class ActionListTab extends React.Component<IActionListTabProps> {

    constructor(props: IActionListTabProps) {
        super(props);
    }

    private setArchivedUnarchivedTabStyle() {
        if (this.props.tabName !== undefined && this.props.tabName !== "") {
            return "activeLeads"
        } else {
            return "archivedLeads"
        }
    }

    private setSelectedTabStyle() {
        if (this.props.isSelected) {
            return "selected"
        } else {
            return "unselected"
        }
    }

    private setTabIconStyle() {
        if (this.props.tabName !== undefined && this.props.tabName !== "") {
            if (this.props.isSelected) {
                return "tabIconSelected"
            } else {
                return "tabIcon"
            }
        } else {
            return ""
        }
    }

    public render() {
        return (
            <div className='ActionListTab'>
                <button
                    className={`${this.setArchivedUnarchivedTabStyle()} ${this.setSelectedTabStyle()}`}
                    onClick={() => this.props.onClick()}>

                    <span
                        // Tab icon
                        className={`${this.setTabIconStyle()}`}>{this.props.icon}

                        {/* Unseen counter */}
                        {this.props.tabName !== undefined && this.props.tabName !== "" &&
                            <>
                                {this.props.unseenCount! > 99 &&
                                    <span className='unseenCount plus'>99+</span>
                                }
                                {this.props.unseenCount! > 0 && this.props.unseenCount! < 100 &&
                                    <span className='unseenCount'>{this.props.unseenCount}</span>
                                }
                                {this.props.unseenCount! < 1 &&
                                    <span className='allSeen'><Icon.Check /></span>
                                }
                            </>
                        }
                    </span>

                    {/* Tab name and total */}
                    <span className='tabName'>{this.props.tabName} {this.props.total! > 0 && `(${this.props.total})`}</span>
                </button>
            </div>
        )
    }
}

export default ActionListTab;