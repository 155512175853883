import React from "react";
import Button from "Components/Button";
import NotFoundImage from "assets/img/404.svg";
import { textConstants } from "common/textConstants";
import { NavLink } from "react-router-dom";

import { StyledBox, StyledPaper, StyledImg } from "./styles";

const NotFound = () => {
  const {
    notFoundPage: { title, buttonText, text },
  } = textConstants;
  return (
    <StyledBox>
      <StyledPaper>
        <React.Fragment>
          <StyledImg src={NotFoundImage} />
          <div className="text-left mt-1">
            <h1>{title}</h1>
            <p>{text}</p>
          </div>
          <NavLink key="/" to={"/"} style={{textDecoration: 'unset'}}>
            <Button variant="contained" buttonType="secondary" size="large">
              {buttonText}
            </Button>
          </NavLink>
        </React.Fragment>
      </StyledPaper>
    </StyledBox>
  );
};

export default NotFound;
