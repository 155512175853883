import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import ActionListStatusVM from './ActionListStatusVM';
import { observer } from 'mobx-react';
import GoBack from 'Components/GoBack';
import { textConstants } from 'common/textConstants';
import * as Icon from 'react-feather';

@observer
export default class ActionListConfigurationsView extends Views.ViewBase<ActionListStatusVM> {

    constructor(props: unknown) {
        super("Action List Status", ActionListStatusVM, props);
    }

    public render() {
        return (
            <>
                <GoBack />
                <Neo.Card>
                    {/* Action List Status */}
                    <h2 className='p-24 mt-4'>{textConstants.titleText.ActionListStatusHeading}</h2>
                    <Neo.Form model={this.viewModel.actionListStatusesList} onSubmit={() => this.viewModel.updateLeadTypeStatus()}>
                        <NeoGrid.Grid items={this.viewModel.actionListStatusesList} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
                            {(status, meta) => (
                                <NeoGrid.Row>
                                    <NeoGrid.Column bind={meta.statusName} />
                                    <NeoGrid.ButtonColumn showDelete
                                        deleteButton={{
                                            icon: <Icon.X size={24} />,
                                            variant: "light",
                                            className: "circled"
                                        }
                                        } />
                                </NeoGrid.Row>
                            )}
                        </NeoGrid.Grid>
                        <div className="my-4 text-right p-24">
                            <Neo.Button className="btn-width-100" variant='success' isSubmit>{textConstants.buttonText.Save}</Neo.Button>
                        </div>
                    </Neo.Form>

                    {/* Mapping Table */}
                    <Neo.Form model={this.viewModel.leadTypeStatusesList} onSubmit={() => this.viewModel.updateLeadTypeMappedStatuses()}>
                        <NeoGrid.Grid className="mt-1" items={this.viewModel.leadTypeStatusesList} showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
                            {(data, meta) => (
                                <NeoGrid.Row >
                                    <NeoGrid.Column bind={meta.leadTypeId} select={{ items: this.viewModel.leadTypesList }} />
                                    <NeoGrid.Column bind={meta.actionListStatusId} select={{ items: this.viewModel.actionListStatusesList }} />
                                    <NeoGrid.ButtonColumn showDelete
                                        deleteButton={{
                                            icon: <Icon.X size={24} />,
                                            variant: "light",
                                            className: "circled"
                                        }
                                        } />
                                </NeoGrid.Row>
                            )}
                        </NeoGrid.Grid>
                        <div className="my-4 text-right p-24">
                            <Neo.Button className="btn-width-100" variant='success' isSubmit>{textConstants.buttonText.Save}</Neo.Button>
                        </div>
                    </Neo.Form>

                    {/* Configurations */}
                    <Neo.Form model={this.viewModel.actionListConfigurationList} onSubmit={() => this.viewModel.updateLeadTypeMappedStatuses()}>
                        <NeoGrid.Grid className="mt-1" items={this.viewModel.actionListConfigurationList} >
                            {(data, meta) => (
                                <NeoGrid.Row >
                                    <NeoGrid.Column bind={meta.configurationFriendlyName} />
                                    <NeoGrid.Column bind={meta.description} />
                                </NeoGrid.Row>
                            )}
                        </NeoGrid.Grid>
                        <div className="my-4 text-right p-24">
                            <Neo.Button className="btn-width-100" variant='success' isSubmit>{textConstants.buttonText.Save}</Neo.Button>
                        </div>
                    </Neo.Form>
                </Neo.Card>
            </>
        );
    }
}