import React, { useEffect } from "react";
import { textConstants } from "common/textConstants";

import {
  StyledTitle,
  StyledQuestionaireContainer
} from "./OnboardingIntroduction.styles";
import OnboardingVM from '../OnboardingVM';
import Lesson from './components/Lesson';
import { Neo } from '@singularsystems/neo-react';

interface IOnboardingIntroduction {
  viewModel: OnboardingVM;
  setCompleted: (stepIntroductionId: number) => void;
  isEmailSetup?: boolean;
}

const OnboardingIntroduction = ({ viewModel, setCompleted, isEmailSetup }: IOnboardingIntroduction) => {

  useEffect(() => {
    viewModel.canGoToQuestionnaire = true;
    viewModel.isFooterShown = false;
  }, [])

  return (
    <div className="pb-96">
      <Neo.Card>
        <StyledQuestionaireContainer>
          <>
            <StyledTitle>
              {textConstants.Onboarding.IntroductionTitle}
            </StyledTitle>
            {viewModel.clientOnboardingStepIntroductionLookup
              .filter((clientOnboardingStepIntroduction) => {
                return clientOnboardingStepIntroduction.onboardingStepIntroduction.onboardingStepId === viewModel.customAuthService.globalProps.currentStep;
              })
              .sortBy(x => x.onboardingStepIntroduction.ordinal)
              .map((clientOnboardingStepIntroduction, index) =>
                <Lesson
                  key={index}
                  active={viewModel.lastActiveStepIntroduction === index}
                  setCompleted={stepIntroductionId => {
                    let stepIntroduction = viewModel.clientOnboardingStepIntroductionLookup.find(x => x.clientOnboardingStepIntroductionId === stepIntroductionId);
                    stepIntroduction!.isComplete = true;
                    setCompleted(stepIntroductionId);
                  }}
                  clientOnboardingStepIntroduction={clientOnboardingStepIntroduction}
                  tabsContent={[clientOnboardingStepIntroduction.onboardingStepIntroduction.aboutText, clientOnboardingStepIntroduction.onboardingStepIntroduction.transcriptText]}
                  btnText={viewModel.btnStepIntroductionText}
                  isEmailSetup={isEmailSetup && index === 0} />
              )}
          </>
        </StyledQuestionaireContainer>
      </Neo.Card>
    </div>
  );
};

export default OnboardingIntroduction;
