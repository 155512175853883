import React from 'react';
import { Views, Neo } from '@singularsystems/neo-react';
import UpdateMasterAccountsVM from './UpdateMasterAccountsVM';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import GoBack from '../../Components/GoBack';
import * as Icon from 'react-feather';

@observer
export default class UpdateMasterAccountsView extends Views.ViewBase<UpdateMasterAccountsVM> {

    constructor(props: unknown) {
        super("Update Master Accounts", UpdateMasterAccountsVM, props);
    }

    public updateNewAndExistingAccounts = () => {
        if (this.viewModel.options.newAndExistingAccounts) {
            this.viewModel.options.inValidDomains = false;
        }
    }

    public updateInValidDomains = () => {
        if (this.viewModel.options.inValidDomains) {
            this.viewModel.options.newAndExistingAccounts = false;
        }
    }

    public async onDocumentSelected(fileList: File[] | FileList) {
        this.viewModel.documentUpload(fileList);
    }

    onLeave() {
        this.viewModel.removeNotifications();
        return undefined;
    }

    public render() {
        return (
            <div className="UpdateMasterAccountsView">
                <GoBack />
                {/* <Neo.Card icon="fa-folder" title="Update Master Data" className="pb-3"> */}
                <Neo.Card>
                    <div className='row m-0 pb-4'>
                        <div className='col-12 p-24'>
                            <h2 className='mt-4'>{textConstants.titleText.UpdateMasterData}</h2>
                            {this.viewModel.options.newAndExistingAccounts &&
                                <Neo.GridLayout md={1}>
                                    <p> {textConstants.titleText.PleaseEnsureHeaders} </p>
                                    <ol className="d-contents">
                                        {this.viewModel.updateAccountsTemplateHeadings.map((i) =>
                                            <li className="dropZoneListDisplay" key={i}> {i} </li>
                                        )}
                                        <a className="text-right customDownloadLink" onClick={() => this.viewModel.downloadTemplate()}>{textConstants.generalText.DownloadTemplate}</a>
                                    </ol>
                                </Neo.GridLayout>
                            }
                            {this.viewModel.options.inValidDomains &&
                                <Neo.GridLayout md={1}>
                                    <p> {textConstants.titleText.PleaseEnsureHeaders} </p>
                                    <ol className="olDisplayContents">
                                        {this.viewModel.domainTemplateHeadings.map((i) =>
                                            <li className="dropZoneListDisplay" key={i}> {i} </li>
                                        )}
                                        <a className="text-right customDownloadLink" onClick={() => this.viewModel.downloadTemplate()}>{textConstants.generalText.DownloadTemplate}</a>
                                    </ol>
                                </Neo.GridLayout>
                            }
                            {/* File Upload area */}
                            {(this.viewModel.options.inValidDomains || this.viewModel.options.newAndExistingAccounts) &&
                                <Neo.FileContext onFilesSelected={(e) => this.onDocumentSelected(e)} allowedExtensions={[".xlsx"]}>
                                    <Neo.FileDropArea multiple={false}>
                                        <p className="text-center dropZoneText">
                                            <Neo.FileUploadButton>
                                                <Icon.Upload />
                                                {textConstants.generalText.uploadOrDragFile}
                                            </Neo.FileUploadButton>
                                        </p>
                                    </Neo.FileDropArea>
                                </Neo.FileContext>
                            }
                        </div>
                    </div>

                    {/* {update notification} */}
                    <Neo.Modal
                        title={'Master Account Upload'}
                        show={this.viewModel.showUploadMasterAccountsModal}
                        onClose={() => this.viewModel.showUploadMasterAccountsModal = false}
                        closeButton={{ text: "Continue", variant: "secondary" }}>
                        <div className="container">
                            <div className="row"> <b>{this.viewModel.insertedCount}</b> New records added </div>
                            <div className="row"> <b>{this.viewModel.updatedCount}</b> Records Updated  </div>
                        </div>
                    </Neo.Modal>
                </Neo.Card>
            </div >
        );
    }
}