import { ModelBase, NeoModel } from '@singularsystems/neo-core'; 

@NeoModel 
export default class SaveFollowUpTemplateCommand extends ModelBase { 

   public followUpTemplateId: number = 0;

   public clientFollowUpTemplateId: number = 0;

   public leadTypeStatusId: number = 0;

   public campaignMessageStatusId: number = 0

   public clientId: number = 0;

   public followUpTemplateName: string=""

   public messageText: string=""

   public draftText: string=""

   public ordinal: number = 0

   public isPublish: boolean = false

   public isClientReview: boolean = false

   public summary: string = ""
}