import { Attributes, ModelBase, NeoModel, Validation } from "@singularsystems/neo-core";
import { textConstants } from "common/textConstants";

@NeoModel
export default class ActionListStatus extends ModelBase
{
    public actionListStatusId: number = 0;
    
    @Attributes.Display("Status Name")
    public statusName: string = ""

    public uniqueTableKey: string = ""

    public dot: string = ""

    public displayMember: string = ""

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.statusName === null || c.statusName === '', textConstants.messageText.validationMessage.statusMustBeSpecified);
    }

    public toString(): string {
        if (this.isNew || !this.statusName) {
            return textConstants.generalText.newActionListStatus;
        } else {
            return this.statusName;
        }
    }
}