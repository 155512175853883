export const breakpoints = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1360,
    xxl: 1920,
  
    down: (key: string) => {
      if (!breakpoints.hasOwnProperty(key)) {
        throw new Error(`Invalid breakpoint key: ${key}`);
      }
  
      const breakpoint = breakpoints[key];
  
      return `@media (max-width: ${breakpoint} - 1px)`;
    },
  
    up: (key: string) => {
      if (!breakpoints.hasOwnProperty(key)) {
        throw new Error(`Invalid breakpoint key: ${key}`);
      }
  
      const breakpoint = breakpoints[key];
  
      return `@media (min-width: ${breakpoint})`;
    },
    not: (key: string) => {
      if (!breakpoints.hasOwnProperty(key)) {
        throw new Error(`Invalid breakpoint key: ${key}`);
      }
  
      const breakpoint = breakpoints[key];
  
      return `@media (min-width: ${breakpoint}) and (max-width: ${breakpoint} - 1px)`;
    },
  
    between: (key1: string, key2: string) => {
      if (!breakpoints.hasOwnProperty(key1)) {
        throw new Error(`Invalid breakpoint key: ${key1}`);
      }
  
      if (!breakpoints.hasOwnProperty(key2)) {
        throw new Error(`Invalid breakpoint key: ${key2}`);
      }
  
      const breakpoint1 = breakpoints[key1];
      const breakpoint2 = breakpoints[key2];
  
      return `@media (min-width: ${breakpoint1}) and (max-width: ${breakpoint2} - 1px)`;
    },
  
    only: (key: string) => {
      if (!breakpoints.hasOwnProperty(key)) {
        throw new Error(`Invalid breakpoint key: ${key}`);
      }
  
      const breakpoint = breakpoints[key];
  
      return `@media (min-width: ${breakpoint}) and (max-width: ${breakpoint})`;
    }
  };
  