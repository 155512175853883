import { Data, DateUtils, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { textConstants } from "../../common/textConstants";
import ToggleCustomerProfileArchive from "../../Models/IdealCustomerProfiles/Commands/ToggleCustomerProfileArchive";
import CustomerProfileCriteria from "../../Models/IdealCustomerProfiles/CustomerProfileCriteria";
import CustomerProfileLookup from "../../Models/IdealCustomerProfiles/CustomerProfileLookup";
import CommandResult from "../../Models/InvitedUsers/Commands/CommandResult";
import CloneICPCommand from "../../Models/IdealCustomerProfiles/Commands/CloneICPCommand";
import { AppService, Types } from "../../Services/AppService";
import InfoVideoVM from "../../Components/InfoVideoVM";
import SearchFieldVM from "../../Components/SearchFieldVM";
import { base64toBlob } from "common/utils";
import DownloadICPCommand, { Dictionary } from "Models/IdealCustomerProfiles/Commands/DownloadICPCommand";
import { HTTPResponseCodes } from "Models/Enums/ApplicationEnums";
import ClientNavigationButtonsVM from "Components/ClientNavigationButtonsVM";
import OnboardingFunctions from "Models/Onboarding/OnboardingFunctions";

@NeoModel
export default class CustomerProfilesVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private customerProfilesApiClient = AppService.get(Types.ApiClients.CustomerProfilesApiClient),
    private comXConfigApiClient = AppService.get(Types.ApiClients.ComXConfigurationsApiClient),
    public customAuthService = AppService.get(Types.Security.CustomAuthenticationService),
  ) {
    super(taskRunner)
  }

  public isArchived: boolean = false;
  public searchCriteria: CustomerProfileCriteria = new CustomerProfileCriteria();
  public toggleArchiveCommand: ToggleCustomerProfileArchive = new ToggleCustomerProfileArchive();
  public isComXUser: boolean = false;
  public navigateTo: string = "";
  public showCloneModal: boolean = false;
  public cloneICPCommand = new CloneICPCommand();
  public infoVideoVM = new InfoVideoVM();
  public videoURL = "";
  public onboardingPopupICPModalContent = "";
  public clientId: number = 0;
  public clientName: string | undefined = undefined;
  public isComplete: boolean = false;

  public clientNavigationButtonVM = new ClientNavigationButtonsVM()
  public searchFieldVM = new SearchFieldVM();

  public numberOfEmployees = {
    1: "11",
    2: "50",
    3: "200",
    4: "500",
    5: "1000",
    6: "5000",
    7: "10000",
    8: "10001+",
  }

  public productWorth = {
    1: "€2K",
    2: "€10K",
    3: "€50K",
    4: "€100K",
    5: "€250K",
    6: "€500K",
    7: "€1M",
    8: "€10M",
    9: "€50M",
    10: "€100M",
    11: "€150M+",
  }

  public async initialise() {
    this.clientId = this.searchCriteria.clientId
    this.customAuthService.globalProps.sectionCode = ""

    this.clientNavigationButtonVM.navigateTo = this.navigateTo
    this.clientNavigationButtonVM.clientName = this.clientName ?? ""
    this.clientNavigationButtonVM.isIdealCustomerProfiles = true

    if (this.customAuthService.globalProps.isOnboarding) {
      this.onboardingModalSetup();
    }
  }

  public showOnboardingModal: boolean = false;

  public onboardingStepId?: number = 0

  public onboardingPopupModalContent: string = "";

  private async onboardingModalSetup() {
    const {
      onboardingStepId,
      showOnboardingModal,
      onboardingPopupModalContent,
    } = await this.customAuthService.onboardingFunctions.getOnboardingData();

    this.onboardingStepId = onboardingStepId;
    this.showOnboardingModal = showOnboardingModal;
    this.onboardingPopupModalContent = onboardingPopupModalContent;
  }

  public async setStepCompleteModalAsSeen() {
    await this.customAuthService.onboardingFunctions.setStepCompleteModalAsSeen(this.onboardingStepId);
  }

  // Pagination
  public pageManager = new Data.PageManager(this.searchCriteria, CustomerProfileLookup, this.customerProfilesApiClient.getCustomerProfiles,
    {
      pageSize: 20,
      fetchInitial: false
    }
  )

  public setNoICPVideo() {
    this.infoVideoVM.content = textConstants.generalText.ICPVideoCardBody;
    this.infoVideoVM.header = textConstants.generalText.ICPVideoCardTitle;
    this.infoVideoVM.url = this.videoURL;
  }

  public async getVideoURLs() {
    let comXConfig = (await this.comXConfigApiClient.get()).data
    this.videoURL = comXConfig.find((config: any) => config.configCode === textConstants.generalText.ICPVideo)?.value as string
  }

  public async pageManagerRefresh() {
    await this.pageManager.refreshData(undefined, this.taskRunner);
    this.searchFieldVM.hideNoResultDialog = false;
  }

  public checkPageManager() {
    if (this.pageManager.totalRecords > 0 && this.pageManager.hasFetched) {
      return true
    } else return false;
  }

  public toggleArchiveCustomerProfile() {
    this.taskRunner.run(async () => {
      await this.customerProfilesApiClient.toggleArchiveCustomerprofile(this.toggleArchiveCommand);
      this.pageManagerRefresh();
    });

  }

  //Used for the cloning function
  public openCloneModal(icpId: number) {
    this.cloneICPCommand = new CloneICPCommand();
    this.cloneICPCommand.customerProfileId = icpId;
    this.cloneICPCommand.clientId = this.searchCriteria.clientId; //Added to keep client path
    this.showCloneModal = true;
  }

  //Clones the Ideal customer profile.
  public cloneICP() {
    this.showCloneModal = false;
    this.taskRunner.run(async () => {
      const response = await this.customerProfilesApiClient.cloneICP(this.cloneICPCommand);
      const cr: CommandResult = response.data.data as CommandResult;
      if (cr.success) {
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.IdealCustomerClone);
        this.pageManagerRefresh();
      } else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
      }
    })
  }

  public getCustomerProfilesCount(): number {
    let len = this.pageManager.data.length;
    return len;
  }

  private convertToDictionary(toDictionary: any): Dictionary {
    const dictionary: Dictionary = { ...toDictionary };
    return dictionary;
  }

  public async downloadICP(customerProfileId: number, profileName: string) {
    this.taskRunner.run(async () => {
      const requestBody = new DownloadICPCommand()

      // Converts the range slider to dictionaryPair
      requestBody.employeeSizes = this.convertToDictionary(this.numberOfEmployees)
      requestBody.productWorth = this.convertToDictionary(this.productWorth)

      var response = await this.customerProfilesApiClient.downloadICP(customerProfileId, requestBody)

      const url = window.URL.createObjectURL(base64toBlob(response.data.data, 'xlsx'));
      const link = document.createElement("a");
      link.setAttribute("download", `${this.clientName !== undefined ? `${this.clientName}_` : ''}ICP_${profileName}_${DateUtils.format(new Date(), textConstants.dateformat.yearMonthDay)}.xlsx`);
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();

      if (response.status === HTTPResponseCodes.Ok) {
        this.notifications.addSuccess(textConstants.titleText.Success, textConstants.generalText.successfullyDownloadICP)
      }
      else {
        this.notifications.addDanger(textConstants.titleText.Failed, textConstants.generalText.failedToDownloadICP)
      }
    })
  }
}