import {ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class TargetMarketFunction extends ModelBase {

    public targetMarketFunctionId: number = 0;   

    public targetMarketId: number = 0;

    public functionTypeId: number = 0;

    public isPriority: boolean = false;
}