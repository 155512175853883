import { NeoModel, Data, List, Misc } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import AuthorisationTypes from '../../Authorisation/AuthorisationTypes';
import UserGroupLookup from '../../Authorisation/Models/UserGroupLookup';
import { textConstants } from '../../common/textConstants';
import { UpdateFollowUpTemplateNameCommand } from '../../Models/CampaignMessage/Commands/UpdateFollowUpTemplateNameCommand';
import FollowUpTemplateCriteria from '../../Models/CampaignMessage/Query/FollowUpTemplateCriteria';
import ClientLookup from '../../Models/Client/ClientLookup';
import { AppService, Types } from '../../Services/AppService';
import CommandResult from '../../Models/InvitedUsers/Commands/CommandResult';
import FollowUpTemplateLookup from '../../Models/CampaignMessage/ClientFollowUpTemplateLookup';
import { reaction } from 'mobx';
import CampaignMessageStatusesApiClient from '../../ApiClients/CampaignMessageStatusesApiClient';
import CampaignMessageStatus from '../../Models/Maintenance/CampaignMessageStatus';
import CloneFollowUpTemplateCommand from '../../Models/CampaignMessage/Commands/CloneFollowUpTemplateCommand';
import DeleteFollowUpTemplateCommand from '../../Models/CampaignMessage/Commands/DeleteFollowUpTemplateCommand';
import ClientFollowUpTemplateLookup from '../../Models/CampaignMessage/ClientFollowUpTemplateLookup';
import AddEditFollowUpTemplateVM from './AddEditFollowUpTemplateVM';
import SearchFieldVM from '../../Components/SearchFieldVM';
import ActionListStatus from 'Models/ActionList/ActionListStatus';
import LeadType from 'Models/ActionList/LeadType';
import LeadTypeStatus from 'Models/ActionList/LeadTypeStatus';
import LeadTypeStatusFilter from 'Models/ActionList/LeadTypeStatusFilter';

@NeoModel
export default class FollowUpTemplatesVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private campaignMessagesApiClient = AppService.get(Types.ApiClients.CampaignMessagesApiClient),
    private clientApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
    private authService = AppService.get(Types.Neo.Security.AuthenticationService),
    private invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient),
    protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService),
    public appDataCache = AppService.get(Types.Services.AppDataCache),
    public authApiClient = Misc.Globals.appService.get(AuthorisationTypes.ApiClients.AuthorisationApiClient),) {
    super(taskRunner);
  }

  public addEditFollowUpTemplateVM = new AddEditFollowUpTemplateVM();
  public searchCriteria: FollowUpTemplateCriteria = new FollowUpTemplateCriteria();
  public isArchived: boolean = false;
  public hideGrid: boolean = true;
  public displayOverlay: boolean = false;
  public client = new ClientLookup();
  public clientId: number = 0;
  public isComXUser: boolean = false;
  public userGroups = new List(UserGroupLookup);
  public navigateTo: string = "";
  public isActiveNameEditFollowUpTemplateId: number = 0;
  public previousName: string = "";
  public UpdateFollowUpTemplateNameCommand = new UpdateFollowUpTemplateNameCommand();
  public showFollowUpTemplateUpdate: boolean = false;
  public getCopy: string = "";
  public campaignMessageStatusesApiClient: CampaignMessageStatusesApiClient = new CampaignMessageStatusesApiClient();

  public draftStatusId: number | undefined = 0;
  public activeStatusId: number | undefined = 0;
  public clientReviewStatusId: number | undefined = 0;

  public cloneClientFollowUpTemplateCommand: CloneFollowUpTemplateCommand = new CloneFollowUpTemplateCommand();
  public showCloneModal: boolean = false;
  public showOpenTemplateModal: boolean = false;
  public deleteClientFollowUpTemplateCommand: DeleteFollowUpTemplateCommand = new DeleteFollowUpTemplateCommand();
  public showDeleteModal: boolean = false;
  public selectedFollowUpTemplateName: string = "";
  public showUndoDeleteModal: boolean = false;
  public showForbiddenModal: boolean = false;

  public campaignMessageStatusList = new List(CampaignMessageStatus);
  public searchFieldVM = new SearchFieldVM();

  public actionStatusesList = this.appDataCache.actionListStatuses.get().data;
  public leadTypesList = this.appDataCache.leadTypes.get().data;
  public leadTypeStatusList = this.appDataCache.leadTypeStatuses.get().data;

  public filterList = new List(LeadTypeStatusFilter)

  public pageManager = new Data.PageManager(this.searchCriteria, FollowUpTemplateLookup, this.campaignMessagesApiClient.getFollowUpTemplatesPaged,
    {
      pageSize: 20,
      sortBy: "followUpTemplateName" || "ordinal",
      fetchInitial: false
    }
  )

  public async initialise() {
  }

  public isNameEditing(followUpTemplatesId: number) {
    return (this.isActiveNameEditFollowUpTemplateId === followUpTemplatesId);
  }

  public isNameEditable(template: FollowUpTemplateLookup) {
    if (this.isComXUser) {
      return true;
    }

    let activeStatus = this.campaignMessageStatusList.find(f => f.uniqueTableKey === textConstants.CampaignMessageStatusKeys.ActiveStatus);

    if (activeStatus) {
      return activeStatus.campaignMessageStatusId !== template.campaignMessageStatusId
    }

    return true;
  }

  public toggleNameEdit(followUpTemplatesId: number) {
    return this.isActiveNameEditFollowUpTemplateId = followUpTemplatesId;
  }

  public async updateFollowUpTemplateName(followUpTemplateLookup: FollowUpTemplateLookup) {
    this.UpdateFollowUpTemplateNameCommand.clientFollowUpTemplateId = followUpTemplateLookup.clientFollowUpTemplateId
    this.UpdateFollowUpTemplateNameCommand.clientFollowUpTemplateName = followUpTemplateLookup.followUpTemplateName

    this.taskRunner.run(async () => {
      const response = await this.campaignMessagesApiClient.updateTemplateName(this.UpdateFollowUpTemplateNameCommand);

      const result: CommandResult = response.data as CommandResult;

      if (result.success) {
        this.showFollowUpTemplateUpdate = false;
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.FollowUpTemplateNameUpdated);
      }
      else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, result.error);
      }
    })
  }

  // Makes sure all the cache items are up to date and then populate hte options
  public populateActionListStatues() {
    if (this.actionStatusesList.length < 1) {
      this.actionStatusesList = this.appDataCache.actionListStatuses.get().data as List<ActionListStatus>;
    }
    
    if (this.leadTypeStatusList.length < 1) {
      this.leadTypeStatusList = this.appDataCache.leadTypeStatuses.get().data as List<LeadTypeStatus>;
    }

    if (this.leadTypesList.length < 1) {
      this.leadTypesList = this.appDataCache.leadTypes.get().data as List<LeadType>;
    }

    this.populateLeadTypeStatusFilter();
  }

  // Creates the select options for the filtered List
  private populateLeadTypeStatusFilter() {
    this.filterList = new List(LeadTypeStatusFilter)
    this.leadTypeStatusList.forEach(x => {
      let leadTypes = this.leadTypesList.find(xs => xs.leadTypeId === x.leadTypeId)
      let actionListStatus = this.actionStatusesList.find(xs => xs.actionListStatusId === x.actionListStatusId)

      let statusFilter = new LeadTypeStatusFilter();
      statusFilter.leadTypeStatusId = x.leadTypeStatusId;
      statusFilter.leadTypeStatusName = `${actionListStatus?.statusName} (${leadTypes?.leadTypeName})`

      this.filterList.push(statusFilter)
    })
  }

  public async fetchData(clientId: number) {
    if (clientId > 0) {

      this.searchCriteria.clientId = clientId;
      this.searchCriteria.isDeleted = this.isArchived;

      const isClientResponse = await this.invitedUsersApiClient.isClientUser(this.authService!.user!.userName);
      this.isComXUser = !isClientResponse.data

      reaction(() => this.searchCriteria.isDeleted, async () => await this.pageManagerRefresh(), { fireImmediately: false })

      // Load client details
      const response = await this.clientApiClient.getClient(clientId);
      const responsed = await this.taskRunner.waitFor(this.campaignMessageStatusesApiClient.get());
      this.campaignMessageStatusList.set(responsed.data);
      this.draftStatusId = this.campaignMessageStatusList.find(cm => cm.uniqueTableKey === "CampaignMessageStatus1")?.campaignMessageStatusId
      this.activeStatusId = this.campaignMessageStatusList.find(cm => cm.uniqueTableKey === "CampaignMessageStatus2")?.campaignMessageStatusId
      this.clientReviewStatusId = this.campaignMessageStatusList.find(cm => cm.uniqueTableKey === "CampaignMessageStatus3")?.campaignMessageStatusId
      this.client.set(response.data);
      this.pageManager.reset();

      this.populateActionListStatues();
    }
  }

  public openCloneModal(clientFollowUpTemplateId: number) {
    this.cloneClientFollowUpTemplateCommand = new CloneFollowUpTemplateCommand();
    this.cloneClientFollowUpTemplateCommand.clientFollowUpTemplateId = clientFollowUpTemplateId;
    this.cloneClientFollowUpTemplateCommand.clientId = this.searchCriteria.clientId; //Added to keep client path
    this.cloneClientFollowUpTemplateCommand.isComXUser = this.isComXUser
    this.showCloneModal = true;
  }

  public async openOpenTemplateModal(clientFollowUpTemplateId: number, campaignMessageStatusId: number) {
    this.addEditFollowUpTemplateVM.clientFollowUpTemplateId = clientFollowUpTemplateId
    this.addEditFollowUpTemplateVM.campaignStatusId = campaignMessageStatusId
    this.addEditFollowUpTemplateVM.selectedModalOpenTemplate = 1
    await this.addEditFollowUpTemplateVM.getCurrentFollowUpTemplates()
    this.showOpenTemplateModal = true;
  }

  public async pageManagerRefresh() {
    this.hideGrid = true;
    await this.pageManager.refreshData(undefined, this.taskRunner).then(() => {
      this.searchFieldVM.hideNoResultDialog = false;
      this.hideGrid = false;
    });
  }

  public async cloneFollowUpTemplate() {
    this.showCloneModal = false;
    this.taskRunner.run(async () => {
      const response = await this.campaignMessagesApiClient.cloneFollowUpTemplate(this.cloneClientFollowUpTemplateCommand);
      const cr: CommandResult = response.data.data as CommandResult;
      if (cr.success) {
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.FollowUpTemplateClone);
        await this.pageManagerRefresh();
      } else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
      }
    })
  }

  public async deleteFollowUpTemplates() {
    this.showDeleteModal = false;
    this.taskRunner.run(async () => {
      const response = await this.campaignMessagesApiClient.deleteClientFollowUpTemplate(this.deleteClientFollowUpTemplateCommand);

      const cr: CommandResult = response.data as CommandResult;
      if (cr.success) {
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.FollowUpTemplateArchived);
        await this.pageManagerRefresh();
      }
      else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
      }
    });
  }

  // Methods
  public openDeleteModal(followUpTemplate: ClientFollowUpTemplateLookup) {
    this.deleteClientFollowUpTemplateCommand.clientFollowUpTemplateId = followUpTemplate.clientFollowUpTemplateId;
    // Show the modal
    this.showDeleteModal = true;
    this.selectedFollowUpTemplateName = followUpTemplate.followUpTemplateName;
  }

  public openUndoDeleteModal(followUpTemplate: ClientFollowUpTemplateLookup) {
    this.deleteClientFollowUpTemplateCommand.clientFollowUpTemplateId = followUpTemplate.clientFollowUpTemplateId;
    // Show the modal
    this.showUndoDeleteModal = true;
    this.selectedFollowUpTemplateName = followUpTemplate.followUpTemplateName;
  }

  public async undoDeleteFollowUpTemplate() {
    this.taskRunner.run(async () => {
      this.showUndoDeleteModal = false;
      const response = await this.campaignMessagesApiClient.undeleteClientFollowUpTemplate(this.deleteClientFollowUpTemplateCommand);
      const result = response.data

      if (result.success) {
        //  Refresh
        this.searchCriteria.isDeleted = true;
        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.FollowUpTemplateUnarchive);
        await this.pageManagerRefresh();
      }
      else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, textConstants.generalText.followUpTemplateUndoFailed);
      }
    })
  }
}