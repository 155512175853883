import React from 'react';
import { observer } from 'mobx-react';
import { textConstants } from 'common/textConstants';
import * as Icon from "react-feather";
import Input from 'Components/Input';
import { List, NotifyUtils } from '@singularsystems/neo-core';
import { AppService, Types } from 'Services/AppService';
import SupportLink from 'Models/Maintenance/SupportLink';
import HelpCenterText from 'Models/Maintenance/HelpCenterText';
import HtmlParser from 'react-html-parser';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyIcon from 'assets/svgs/CopyIcon';
import { setTextForClipboard } from 'common/utils';
import OnboardingStepLookup from 'Models/Onboarding/OnboardingStepLookup';
import Drawer from 'Components/Drawer';
import InfoVideo from 'Components/InfoVideo/InfoVideo';
import InfoVideoVM from './InfoVideoVM';


interface IOnboardingHelpCentreProps {

}

@observer
export default class OnboardingHelpCentre extends React.Component<IOnboardingHelpCentreProps, { showVideoDrawer: boolean }> {

    constructor(props: IOnboardingHelpCentreProps) {
        super(props);
        this.state = {
            showVideoDrawer: false,
        }
    }

    //Properties
    private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient);
    private customAuthenticationService = AppService.get(Types.Security.CustomAuthenticationService);
    private comXConfigApiClient = AppService.get(Types.ApiClients.ComXConfigurationsApiClient);
    private dashboardCardsApiClient = AppService.get(Types.ApiClients.DashboardCardsApiClient);
    public appDataCache = AppService.get(Types.Services.AppDataCache);
    private helpCentertextsList = new List(HelpCenterText);
    private supportLinksList = new List(SupportLink);
    private currentOptions: SupportLink[] = [];
    private headerText: string = "";
    private bodyText: string = "";
    private knowledgeHubPassword: string = "";
    private showSupportLinkItems: boolean = false;
    private visitComXURL: string = "";
    private currentStepText: string = "";
    private onboardingSteps: OnboardingStepLookup[] = [];
    private videoViewModel = new InfoVideoVM;
    private sectionCode = "";

    //Functions
    public async getHelpCenterData() {
        let supportLinks = (await this.onboardingApiClient.getHelpCenterSupportLinks()).data.data;
        this.supportLinksList.set(supportLinks)
        this.currentOptions = this.mapSupportLinks()

        let helpCenterTexts = (await this.onboardingApiClient.getHelpCenterTexts()).data.data;
        this.helpCentertextsList.set(helpCenterTexts)
        this.mapHelpCenterTexts()
    }

    private mapSupportLinks() {
        let currentList = this.supportLinksList.filter(sl => sl.onboardingStepId === this.customAuthenticationService.globalProps.currentStep)
        this.onboardingSteps = this.customAuthenticationService.globalProps.onboardingSteps;
        let currentStepText = this.onboardingSteps.find(sl => sl.stepNumber === this.customAuthenticationService.globalProps.currentStep)?.stepName
        this.currentStepText = currentStepText ? currentStepText : "";

        return currentList
    }

    private async getKnowledgeHubPassword() {
        var comXConfig = this.appDataCache.comXConfigurations.get().data
        let password = "";

        if (comXConfig[0]) {
            password = comXConfig.find((config: any) => config.configCode === textConstants.titleText.KnowledgeHubPasswordDbTitle)?.value as string
        }
        else {
            let config = await this.comXConfigApiClient.get()
            if (config) {
                password = config.data.find((config: any) => config.configCode === textConstants.titleText.KnowledgeHubPasswordDbTitle)?.value as string
            }
        }

        let comxHubRedirectLink: string | undefined = "";
        var dashboardConfigData = this.appDataCache.dashboardCards.get().data
        if (dashboardConfigData.length > 0 && dashboardConfigData[0]) {
            comxHubRedirectLink = dashboardConfigData.find((config: any) => config.cardName === textConstants.titleText.VisitComXDbTitle)?.redirectLink
        } else {
            let config = await this.dashboardCardsApiClient.get()
            if (config) {
                comxHubRedirectLink = config.data.find((config: any) => config.cardName === textConstants.titleText.VisitComXDbTitle)?.redirectLink
            }
        }

        this.visitComXURL = comxHubRedirectLink !== undefined ? comxHubRedirectLink : "";
        this.knowledgeHubPassword = password;
    }

    private toggleSupportLinkDropdown() {
        this.showSupportLinkItems = !this.showSupportLinkItems;
        this.forceUpdate()
    }

    private getKnowledgeHubPasswordSection() {
        return (
            <div className='row m-0'>
                <div className='col-12 p-0'>
                    <div className='row m-0'>
                        <div className='col-10 m-0 p-0'>
                            <Input
                                className="mb-0 dropdownComXHub pull-left"
                                label={"ComX Hub"}
                                disabled={true}
                                fullWidth
                                key={this.knowledgeHubPassword}
                                value={this.knowledgeHubPassword}
                                endIcon={<CopyToClipboard text={setTextForClipboard(this.knowledgeHubPassword)} onCopy={() => this.showCopySuccess()}>
                                    <i className="copySVG">{<CopyIcon />}</i>
                                </CopyToClipboard>}
                            />
                        </div>

                        <div className='col-2 m-0 p-0'>
                            <button className='linkIcon' onClick={() => window.open(this.visitComXURL, '_blank')}>
                                <Icon.ExternalLink />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private showCopySuccess() {
        const variant = "success"
        NotifyUtils.add(variant, textConstants.messageText.saveMessage.TextToClipboard,
            variant as any, 5);
    }

    private mapHelpCenterTexts() {
        let currentHelpCenterItem = this.helpCentertextsList.find(hc => hc.sectionCode === this.customAuthenticationService.globalProps.sectionCode)
        this.headerText = currentHelpCenterItem === undefined ? textConstants.generalText.NoHelpCenterText : currentHelpCenterItem.headerText
        this.bodyText = currentHelpCenterItem === undefined ? "" : currentHelpCenterItem.bodyText
        this.videoViewModel.url = currentHelpCenterItem === undefined ? "" : currentHelpCenterItem.videoURL;
        this.videoViewModel.transcript = currentHelpCenterItem === undefined ? "" : currentHelpCenterItem.transcriptText;
        this.videoViewModel.about = currentHelpCenterItem === undefined ? "" : currentHelpCenterItem.aboutText;
        this.videoViewModel.header = currentHelpCenterItem === undefined ? "" : currentHelpCenterItem.headerText
    }

    public async componentDidMount() {
        if (!this.customAuthenticationService.globalProps.isZendeskVisible) {
            this.customAuthenticationService.globalProps.isZendeskVisible = true
        }

        await this.getKnowledgeHubPassword()
        await this.getHelpCenterData()
        this.forceUpdate()
    }

    public componentWillUnmount() {
        this.customAuthenticationService.globalProps.isZendeskVisible = false;
        this.customAuthenticationService.globalProps.sectionCode = "";
    }

    public render() {
        if (this.sectionCode !== this.customAuthenticationService.globalProps.sectionCode) {
            this.sectionCode = this.customAuthenticationService.globalProps.sectionCode;
            this.mapHelpCenterTexts()
        }

        return (
            <React.Fragment>
                <div className="helpCentreContainer pull-right">
                    <div className='row'>
                        {/* Header */}
                        <div className='headerDiv'>
                            <h2><Icon.LifeBuoy className='mr-2 mb-0' />{textConstants.titleText.HelpCenter}</h2>
                        </div>
                    </div>

                    <div className='row'>
                        {/* Body */}
                        <div className={`messageContainerBlock ${this.showSupportLinkItems ? "smallerHeight" : ""}`}>
                            <div className={`messageContainer`}>
                                <h4 className='pt-1 mb-2'>{this.headerText}</h4>
                                {HtmlParser(this.bodyText)}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='helpCenterFooter mt-1'>
                            {/* Support links options*/}
                            {this.showSupportLinkItems &&
                                <div className='helpCenterDropdownBox'>
                                    {this.currentOptions && this.currentOptions.length > 0 &&
                                        this.currentOptions.map((c, key) => {
                                            return (
                                                <button key={key}
                                                    className="dropdownContainer option"
                                                    disabled={false}
                                                    onClick={() => window.open(c.url, '_blank')}>
                                                    <div className='row m-0 col-12 p-0'>
                                                        <div className='col-10 pl-0 textDiv'>{c.description}</div>
                                                        <div className='col-2 d-flex align-items-center'><Icon.ArrowUpRight /></div>
                                                    </div>
                                                </button>
                                            )
                                        })
                                    }
                                    {this.currentOptions && this.currentOptions.length > 0 &&
                                        <div className='dropdownContainer option comXHub'>
                                            {this.getKnowledgeHubPasswordSection()}
                                        </div>
                                    }
                                </div>
                            }

                            {/* Support links */}
                            {this.currentOptions && this.currentOptions.length > 0 &&
                                <button
                                    className="mb-3 dropdownContainer"
                                    disabled={false}
                                    onClick={() => this.toggleSupportLinkDropdown()}>
                                    <div className='row m-0 col-12 p-0'>
                                        <div className='col-10 pl-0 textDiv'>{textConstants.titleText.SupportLinks}</div>
                                        <div className='col-2 d-flex align-items-center'>
                                            {!this.showSupportLinkItems ? <Icon.ChevronDown /> : <Icon.ChevronUp />}
                                        </div>
                                    </div>
                                </button>
                            }

                            {/* Video Tutorial */}
                            <div className='exerciseContainer'>
                                <button
                                    className="mb-5 dropdownContainer"
                                    disabled={false}
                                    onClick={() => this.setState({ showVideoDrawer: true })}>
                                    <div className='row m-0 col-12 p-0'>
                                        <div className='col-10 pl-0 subTextDiv'>
                                            <Icon.Youtube /> {textConstants.Onboarding.HelpCenter.VideoTutorials}
                                        </div>
                                        <div className='col-2 d-flex align-items-center'>
                                            <Icon.ArrowRight />
                                        </div>
                                    </div>

                                    <div className='row m-0 col-12 pr-0 pl-0'>
                                        <div className='col-12 pl-0 pr-0 text-left'>
                                            {this.currentStepText + " exercise"}
                                        </div>
                                    </div>
                                </button>
                            </div>

                            {this.state.showVideoDrawer &&
                                <Drawer
                                    position="right"
                                    setClose={() => this.setState({
                                        showVideoDrawer: false
                                    })} >
                                    <InfoVideo viewModel={this.videoViewModel} />
                                </Drawer>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}