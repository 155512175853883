import {Attributes, ModelBase, NeoModel, Rules} from '@singularsystems/neo-core';

@NeoModel
export default class ChangePasswordModel extends ModelBase {

    // Properties
    @Rules.StringLength(6, 100, "Password must be at least 6 characters")    
    @Rules.Required("Please enter existing password")
    @Attributes.Display("Old Password")
    public oldPassword: string = "";

    @Rules.Required("Please enter new password")
    @Rules.StringLength(6, 100, "New Password must be at least 6 characters")
    @Attributes.Display("New Password")
    public newPassword: string = "";
}