import { NeoModel, Rules, ModelBase } from '@singularsystems/neo-core';
import { textConstants } from 'common/textConstants';

@NeoModel
export default class ActionListCriteria extends ModelBase {

  @Rules.Required()
  public clientId: number = 0;

  public pageNumber: number = 1;

  public resultsPerPage: number = 10;

  public leadType: string | null = textConstants.ActionListText.LeadTypes.hot;

  public isArchived: boolean = false

  public searchString: string = "";

  public selectedStatus: string | null = null;
}