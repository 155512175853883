import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import TechnicalSettingsConfig from '../Models/Maintenance/TechnicalSettingsConfig';

export interface ITechnicalSettingsConfigApiClient extends Data.IUpdateableApiClient<TechnicalSettingsConfig, number> {

}

@injectable()
export default class TechnicalSettingsConfigApiClient extends Data.UpdateableApiClient<TechnicalSettingsConfig, number> implements ITechnicalSettingsConfigApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/technicalSettingsConfigs`);
    }
}