import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import TargetMarketVM from './TargetMarketVM';
import AddEditTargetMarketVM from './AddEditTargetMarketVM';
import TargetMarketAccountsVM from '../TargetMarketAccounts/TargetMarketAccountsVM';

@NeoModel
export default class TargetMarketCampaignsVM extends Views.ViewModelBase {
    [x: string]: any;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private authenticationService = AppService.get(Types.Neo.Security.AuthenticationService),
        private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public customAuthService = AppService.get(Types.Security.CustomAuthenticationService)) {

        super(taskRunner);
        this.customAuthService.globalProps.isOnboarding = false;
    }

    public platformSetupUrl: string = ""
    public successTrackerUrl: string = ""
    public clientId: number = 0
    public showDashboard: boolean = true
    public showTargetMarkets: boolean = true
    public showTargetMarketAccounts: boolean = false
    public TargetMarketViewModel = new TargetMarketVM();
    public targetMarketName: string = "";
    public clientDisplayName: string = "";
    public showAddEditTargetMarket: boolean = false
    public AddEditTargetMarketVM = new AddEditTargetMarketVM();
    public TargetMarketAccountsViewModel = new TargetMarketAccountsVM();
    public targetMarketId: number | null = null;
    public initialCampaignLoad: boolean = true;
    public clearAddEditNotifications: boolean = true;

    public AddEditTargetMarketViewModel = new AddEditTargetMarketVM();

    public async initialise() {        
    }

    public async getClientName(clientId: number) {
        const response = await this.taskRunner.waitFor(this.clientsApiClient.getClient(clientId));
        return response.data.clientName;
    }

    public async setClientDisplayName(clientName: string) {
        this.clientDisplayName = clientName
    }

    public async getClientId() {
        const { user, isAuthenticated } = this.authenticationService;

        // Fetch the platformSetup and successTracker urls
        if (isAuthenticated) {
            const response = await this.clientsApiClient.getDashboardInfo(user!.userName);
            this.clientId = response.data.clientId;
        }

    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}