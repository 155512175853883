import { ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class InviteCommand extends ModelBase {

    @Rules.EmailAddress()
    @Rules.StringLength(80)
    public inviteEmail: string = "";

    public userGroups: number[] = [];

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.inviteEmail) {
            return 'New InviteCommand';
        } else {
            return this.inviteEmail;
        }
    }
}