import { NeoModel, List } from '@singularsystems/neo-core';
import { AppService, Types } from '../../Services/AppService';
import SubIndustry from '../../Models/Maintenance/SubIndustry';
import { textConstants } from '../../common/textConstants';
import { Views } from '@singularsystems/neo-react';
import IndustriesVersion from 'Models/Maintenance/IndustriesVersion';
import IROIndustryMapping from 'Models/Maintenance/IROIndustryMapping';

@NeoModel
export default class IROIndustryMappingVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private appDataCache = AppService.get(Types.Services.AppDataCache),
        private iroIndustryMappingApiClient = AppService.get(Types.ApiClients.IROIndustryMappingApiClient)
        ) {

        super(taskRunner);
    }

    public subIndustryList = new List(SubIndustry);
    public industriesVersionList = new List(IndustriesVersion);
    public iroIndustryMappingList = new List(IROIndustryMapping);

    public async initialise() {
        await this.setUpViewModel();        
    }

    public async setUpViewModel() {
        const subIndustries = (await this.taskRunner.waitFor(this.appDataCache.subIndustries.getDataAsync())).sortBy("subIndustryName")
        this.subIndustryList.set(subIndustries);

        const industriesVersion = (await this.taskRunner.waitFor(this.appDataCache.industriesVersions.getDataAsync()));
        this.industriesVersionList.set(industriesVersion);

        this.taskRunner.run(async () => {            
            await this.getIROIndustriesMapping()
        });
    }

    public async saveList(){
        this.taskRunner.run(async () => {
            const data = this.iroIndustryMappingList.toJSArray();

            let response = await this.iroIndustryMappingApiClient.saveList(data);

            if (response){
                this.iroIndustryMappingList.update(response.data)

                this.notifications.addSuccess(textConstants.titleText.Saved,
                    textConstants.messageText.saveMessage.IndustrySubIndustriesSaved);
            }
        });
    }

    private async getIROIndustriesMapping(){
        const response = await this.iroIndustryMappingApiClient.get();
           
        if (response){
            this.iroIndustryMappingList.set(response.data)
        }
    }
}