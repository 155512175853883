import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import Role from '../Models/Maintenance/Role';
import { AppService, Types } from '../Services/AppService';

export interface IRolesApiClient extends Data.IUpdateableApiClient<Role, number> {

}

@injectable()
export default class RolesApiClient extends Data.UpdateableApiClient<Role, number> implements IRolesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/roles`);
    }
}