import React from 'react';
import * as Icon from 'react-feather';

export interface ISideBarIcons {
    iconName?: string
}

export default class SideBarIcons extends React.Component<ISideBarIcons> {
    public render() {
        switch (this.props.iconName) {
            case 'home':
                return (<Icon.Home />);
            case 'bullseye':
                return (<Icon.Target />);
            case 'flag':
                return (<Icon.Flag />);
            case 'envelope':
                return (<Icon.Mail />);
            case 'ban':
                return (<Icon.Slash />);
            case 'life-ring':
                return (<Icon.LifeBuoy />);
            case 'cog':
                return (<Icon.Settings />);
            case 'clipboard-check':
                return (<Icon.Clipboard />);
            case 'user':
                return (<Icon.Users />);
            case 'globe':
                return (<Icon.Globe />);
            case 'lock':
                return (<Icon.Lock />);
            case 'clients':
                return (<Icon.User />);
            case 'check-square':
                return (<Icon.CheckSquare />);
            case 'bar-chart':
                return (<Icon.BarChart />);
            default:
                return (<Icon.Folder />);
        }
    }
}