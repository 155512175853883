import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import { textConstants } from "common/textConstants";
import { observer } from "mobx-react";
import OnboardingMaintenanceVM from "./OnboardingMaintenanceVM";
import React from 'react';
import GoBack from "Components/GoBack";
import * as Icon from 'react-feather';
import RichTextEditor from "Components/RichTextEditor";
import { TextType } from "./TextType";

@observer
export default class OnboardingMaintenanceView extends Views.ViewBase<OnboardingMaintenanceVM> {

    constructor(props: unknown) {
        super(textConstants.titleText.Onboarding, OnboardingMaintenanceVM, props);
    }

    private tempUpdatedHtml: string = "";

    public render() {

        return (
            <>
                <GoBack />
                <Neo.Card>
                    <div className="OnboardingMaintenanceView">
                        <h2 className='p-24 mt-4'>{textConstants.titleText.OnboardingMaintenance}</h2>

                        <Neo.TabContainer
                            tabManager={this.viewModel.tabManager}>

                            {/* Introductory Pages */}
                            <Neo.Tab
                                onDisplayed={() => {
                                    this.viewModel.toggleTab("", textConstants.Onboarding.IntroductoryPages)
                                }}
                                header={textConstants.Onboarding.IntroductoryPages}
                            >

                                <NeoGrid.Grid className="mt-2"
                                    items={this.viewModel.onboardingMaintenanceList}
                                >
                                    {(onboaringMaintenance, onboaringMaintenanceMeta) => (
                                        <NeoGrid.Row >
                                            <NeoGrid.Column display={onboaringMaintenanceMeta.friendlyDescription} />
                                            <NeoGrid.Column bind={onboaringMaintenanceMeta.preambleText} />
                                            <NeoGrid.Column bind={onboaringMaintenanceMeta.headerText} />
                                            <NeoGrid.Column display={onboaringMaintenanceMeta.bodyText}
                                                append={<Neo.Button
                                                    variant="light"
                                                    className="text-center"
                                                    icon={<Icon.Edit2 />}
                                                    onClick={() => {
                                                        this.viewModel.setRichTextEditorInfo(
                                                            onboaringMaintenance.onboardingMaintenanceId,
                                                            textConstants.titleText.OnboardingMaintenance,
                                                            TextType.Body,
                                                            onboaringMaintenance.bodyText
                                                        )
                                                    }}
                                                />}
                                            />
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            </Neo.Tab>

                            {/* Help Center texts */}
                            <Neo.Tab
                                onDisplayed={() => {
                                    this.viewModel.toggleTab("", textConstants.Onboarding.HelpCenter.Text)
                                }}
                                header={textConstants.Onboarding.HelpCenter.Text}>

                                <NeoGrid.Grid className="mt-2"
                                    items={this.viewModel.helpCenterTextList}
                                >
                                    {(helpText, helpTextMeta) => (
                                        <NeoGrid.Row >
                                            <NeoGrid.Column display={helpTextMeta.sectionCode} />
                                            <NeoGrid.Column bind={helpTextMeta.headerText} />
                                            <NeoGrid.Column display={helpTextMeta.bodyText}
                                                append={<Neo.Button
                                                    variant="light"
                                                    className="text-center"
                                                    icon={<Icon.Edit2 />}
                                                    onClick={() => {
                                                        this.viewModel.setRichTextEditorInfo(
                                                            helpText.helpCenterTextId,
                                                            textConstants.titleText.HelpCenterTexts,
                                                            TextType.Body,
                                                            helpText.bodyText
                                                        )
                                                    }}
                                                />} />
                                            <NeoGrid.Column bind={helpTextMeta.videoURL} />
                                            <NeoGrid.Column bind={helpTextMeta.uRL} label={textConstants.generalText.URL} />
                                            <NeoGrid.Column display={helpTextMeta.aboutText}
                                                append={<Neo.Button
                                                    variant="light"
                                                    className="text-center"
                                                    icon={<Icon.Edit2 />}
                                                    onClick={() => {
                                                        this.viewModel.setRichTextEditorInfo(
                                                            helpText.helpCenterTextId,
                                                            textConstants.titleText.HelpCenterTexts,
                                                            TextType.About,
                                                            helpText.aboutText
                                                        )
                                                    }}
                                                />}
                                            />
                                            <NeoGrid.Column display={helpTextMeta.transcriptText}
                                                append={<Neo.Button
                                                    variant="light"
                                                    className="text-center"
                                                    icon={<Icon.Edit2 />}
                                                    onClick={() => {
                                                        this.viewModel.setRichTextEditorInfo(
                                                            helpText.helpCenterTextId,
                                                            textConstants.titleText.HelpCenterTexts,
                                                            TextType.Transcript,
                                                            helpText.transcriptText
                                                        )
                                                    }}
                                                />}
                                            />
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            </Neo.Tab>

                            {/* Popups */}
                            <Neo.Tab
                                onDisplayed={() => {
                                    this.viewModel.toggleTab("", textConstants.Onboarding.Popups)
                                }}
                                header={textConstants.Onboarding.Popups}>

                                <NeoGrid.Grid className="mt-2" items={this.viewModel.onboardingPopupList}>
                                    {(popup, popupMeta) => (
                                        <NeoGrid.Row >
                                            <NeoGrid.Column display={popupMeta.popupName} />
                                            <NeoGrid.ButtonColumn width={1}>
                                                <Neo.Button variant="light" className="text-center" icon={<Icon.Edit2 />}
                                                    onClick={() => {
                                                        this.viewModel.setRichTextEditorInfo(
                                                            popup.onboardingPopupId,
                                                            textConstants.titleText.OnboardingPopupMaintenance,
                                                            TextType.Transcript,
                                                            popup.text
                                                        )
                                                    }}>
                                                </Neo.Button>
                                            </NeoGrid.ButtonColumn>
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            </Neo.Tab>

                            {/* Support Links */}
                            <Neo.Tab
                                onDisplayed={() => {
                                    this.viewModel.toggleTab("", textConstants.Onboarding.SupportLinks)
                                }}
                                header={textConstants.Onboarding.SupportLinks}>

                                {/* Support Links */}
                                <NeoGrid.Grid className="mt-1" items={this.viewModel.supportLinkList}>
                                    {(data, meta) => (
                                        <NeoGrid.Row >
                                            <NeoGrid.Column bind={meta.onboardingStepId} select={{ items: this.viewModel.onboardingStepList }} />
                                            <NeoGrid.Column bind={meta.description} />
                                            <NeoGrid.Column bind={meta.url} />
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>

                            </Neo.Tab>

                            {/* Introduction Pages */}
                            <Neo.Tab
                                onDisplayed={() => {
                                    this.viewModel.toggleTab("", textConstants.Onboarding.IntroductionPages)
                                }}
                                header={textConstants.Onboarding.IntroductionPages}>

                                {/* Introduction Pages */}
                                <NeoGrid.Grid className="mt-1" items={this.viewModel.onboardingIntroductionStepList}>
                                    {(data, meta) => (
                                        <NeoGrid.Row >
                                            <NeoGrid.Column bind={meta.onboardingStepId} select={{ items: this.viewModel.onboardingStepList }} disabled={true} />
                                            <NeoGrid.Column bind={meta.headerText} />
                                            <NeoGrid.Column bind={meta.bodyText}
                                                className="gridButtonPadding"
                                                append={<Neo.Button
                                                    variant="light"
                                                    className="text-center"
                                                    icon={<Icon.Edit2 />}
                                                    onClick={() => {
                                                        this.viewModel.setRichTextEditorInfo(
                                                            data.onboardingStepIntroductionId,
                                                            textConstants.titleText.OnboardingIntroSteps,
                                                            TextType.Body,
                                                            data.bodyText
                                                        )
                                                    }}
                                                />} />
                                            <NeoGrid.Column bind={meta.aboutText}
                                                className="gridButtonPadding"
                                                append={<Neo.Button
                                                    variant="light"
                                                    className="text-center"
                                                    icon={<Icon.Edit2 />}
                                                    onClick={() => {
                                                        this.viewModel.setRichTextEditorInfo(
                                                            data.onboardingStepIntroductionId,
                                                            textConstants.titleText.OnboardingIntroSteps,
                                                            TextType.About,
                                                            data.aboutText
                                                        )
                                                    }}
                                                />} />
                                            <NeoGrid.Column bind={meta.transcriptText}
                                                className="gridButtonPadding"
                                                append={<Neo.Button
                                                    variant="light"
                                                    className="text-center"
                                                    icon={<Icon.Edit2 />}
                                                    onClick={() => {
                                                        this.viewModel.setRichTextEditorInfo(
                                                            data.onboardingStepIntroductionId,
                                                            textConstants.titleText.OnboardingIntroSteps,
                                                            TextType.Transcript,
                                                            data.transcriptText
                                                        )
                                                    }}
                                                />} />
                                            <NeoGrid.Column bind={meta.videoURL} />
                                            <NeoGrid.Column bind={meta.url} />
                                            <NeoGrid.Column bind={meta.urlTitle} />
                                            <NeoGrid.Column display={meta.ordinal} />
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            </Neo.Tab>

                            {/* Founders Video URL */}
                            <Neo.Tab
                                onDisplayed={() => {
                                    this.viewModel.toggleTab("", textConstants.Onboarding.OnboardingVideos)
                                }}
                                header={textConstants.Onboarding.OnboardingVideos}>

                                {/* Founder's Video URL */}
                                {this.viewModel.isOnboardingVideoSet &&
                                    <>
                                        <div className="row col-11">
                                            <div className="col-2 foundersURL">
                                                {textConstants.generalText.FoundersVideoURL}
                                            </div>
                                            <div className="ml-2 col-3">
                                                <Neo.FormGroup
                                                    suppressLabel={true}
                                                    bind={(this.viewModel.comXConfigurationList.find(config => config.configCode === textConstants.generalText.FoundersVideo))?.meta.value}>
                                                </Neo.FormGroup>
                                            </div>
                                        </div>
                                        <div className="row col-11">
                                            <div className="col-2 foundersURL">
                                                {textConstants.generalText.EmailSetupVideoURL}
                                            </div>
                                            <div className="ml-2 col-3">
                                                <Neo.FormGroup
                                                    suppressLabel={true}
                                                    bind={(this.viewModel.comXConfigurationList.find(config => config.configCode === textConstants.generalText.EmailSetupVideo))?.meta.value}>
                                                </Neo.FormGroup>
                                            </div>
                                        </div>
                                    </>
                                }
                            </Neo.Tab>

                        </Neo.TabContainer>

                        <Neo.Form
                            model={this.viewModel.onboardingMaintenanceList}
                            onSubmit={() => this.viewModel.saveAll()}
                            showSummaryModal
                        >

                            {/* More sections to come here */}

                            <div className="my-4 text-right p-24">
                                <Neo.Button className="btn-width-100" isSubmit variant="success">{textConstants.buttonText.Save}</Neo.Button>
                            </div>
                        </Neo.Form>

                        {/*Edit modal*/}
                        <Neo.Modal
                            title={`Update Display Text`}
                            className={"modal-style-test"}
                            show={this.viewModel.showEditModal}
                            onClose={() => this.viewModel.showEditModal = false}
                            closeButton={{ text: "Cancel", variant: "light" }}
                            acceptButton={{
                                text: "Done", variant: "secondary",
                                onClick: () => {
                                    this.viewModel.updateSelectedText(this.tempUpdatedHtml);
                                    this.viewModel.showEditModal = false
                                }
                            }}
                        >
                            <div>
                                <RichTextEditor
                                    initialString={this.viewModel.selectedText}
                                    getHtmlText={(htmlText: string) => {
                                        this.tempUpdatedHtml = htmlText;
                                    }} />
                            </div>
                        </Neo.Modal>
                    </div>
                </Neo.Card>
            </>
        );
    }
}