import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import LeadTypeStatus from 'Models/ActionList/LeadTypeStatus';

export interface ILeadTypeStatusesApiClient extends Data.IUpdateableApiClient<LeadTypeStatus, number> {

}

@injectable()
export default class LeadTypeStatusesApiClient extends Data.UpdateableApiClient<LeadTypeStatus, number> implements ILeadTypeStatusesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/leadTypeStatuses`);
    }
}