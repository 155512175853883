export enum SectionCode
  {
    ICP1 = "ICPQuestion1",
    ICP2 = "ICPQuestion2",
    ICP3 = "ICPQuestion3",
    ICP4 = "ICPQuestion4",
    ICP5 = "ICPQuestion5",
    ICP6 = "ICPQuestion6",
    ICP7 = "ICPQuestion7",
    ICP8 = "ICPQuestion8",
    ICP9 = "ICPQuestion9",
    ICP10 = "ICPQuestion10",
    ICP11 = "ICPQuestion11",
    ICP12 = "ICPQuestion12",
    ICP13 = "ICPQuestion13",
    ICP14 = "ICPQuestion14",
    ICP15 = "ICPQuestion15",
    ICP16 = "ICPQuestion16",
    ICP17 = "ICPQuestion17",
    ICP18 = "ICPQuestion18",
    ICP19 = "ICPQuestion19",

    TMOverview = "TMSectionOverview",
    TM1 = "TMSection1",
    TM2 = "TMSection2",
    TM3 = "TMSection3",
    TM4 = "TMSection4",
    TM5 = "TMSection5",
    TM6 = "TMSection6",
    TM2WL = "TMSectionWL2",

    TechSetting1 = "TechSettingSection1",
    TechSetting2 = "TechSettingSection2",
  }