import React from 'react';
import { Routing } from '@singularsystems/neo-react';
import { withRouter, Switch, Route } from 'react-router-dom';
import routes from '../Routes';
import { getEmailAddressFromAccessToken } from 'common/utils';
import { textConstants } from 'common/textConstants';

class RouteView extends Routing.RouteView {
    constructor(props: any) {
        super(props);

        this.routes = routes;
        this.switchComponent = Switch;
        this.routeComponent = Route;

        this.getForbiddenComponent = (route) => <h2>Forbidden</h2>;
        this.getSigningInComponent = (route) => <div>Signing in...</div>;
        this.getComXAuthServiceAccessToken();
        this.goBack = this.goBack.bind(this);
    }

    public goBack = () => {
        this.props.history.goBack();
    }

    // This AuthService refers to the service created by ComX's internal dev team, not the auth server in Singular's stack
    private getComXAuthServiceAccessToken() {
        const {comx, AccessToken, RefreshToken} = textConstants.LocalStorage
        let comXAuthAccessTokenIndex = window.location.href.indexOf("access_token=")

        if (comXAuthAccessTokenIndex > 0) {
            // Remove everything before the access token
            let comxAuthAccessTokenAndRefreshToken = window.location.href.substring(comXAuthAccessTokenIndex + 13)
            
            // Extract the tokens
            let bothTokens = comxAuthAccessTokenAndRefreshToken.split("&refresh_token=")
            let accessToken = bothTokens[0]
            let refreshToken = bothTokens[1]
            
            // Extract the email addresses
            let tokenEmail = getEmailAddressFromAccessToken(accessToken)

            // Save the tokens
            localStorage.setItem(`${comx}${tokenEmail.toLowerCase()}${AccessToken}`, accessToken)
            localStorage.setItem(`${comx}${tokenEmail.toLowerCase()}${RefreshToken}`, refreshToken)
        }
    }
}

export default withRouter(RouteView);