import React from "react";
import { observer } from "mobx-react";

import { Neo, NeoGrid } from "@singularsystems/neo-react";

import { textConstants } from "../../common/textConstants";
import * as Roles from "../../Models/Security/Roles";
import TargetMarketLookup from "../../Models/TargetMarket/TargetMarketLookup";
import { AppService, Types } from "../../Services/AppService";
import TargetMarketVM from "./TargetMarketVM";
import SegmentedProgressBar, {
  IProgressSegment,
} from "../../Components/SegmentedProgressBar";
import Loading from "../../Components/Loading";
import CustomPagingControl from "../../Components/CustomPagingControl";
import SearchField from "../../Components/SearchField";
import {
  CountryCodes,
} from "../../Models/Enums/ApplicationEnums";
import * as Icon from "react-feather";
import ClientNavigationButtons from "../../Components/ClientNavigationButtons";
import InfoBox from "../../Components/InfoBox";
import {
  StyledButton,
  StyledButtonGroupWrapper,
  StyledButtonWrapper,
} from "Components/Button/styles";
import SpriteMap from "Components/MapCountryFlags";
import DataTooltip from "Components/DataTooltip/DataTooltip";
import HtmlParser from "react-html-parser";
import TrophyWoman from "assets/img/TrophyWoman.png";
import OnboardingModal from "Components/Modals/OnboardingModal";

interface ITargetMarketViewProps {
  viewModel: TargetMarketVM;
  back: () => void;
  openUpdateTargetMarket: () => void;
  openAddTargetMarket: () => void;
  openTargetMarketAccounts: (targetMarketId: number) => void;
  openEditTargetMarket: (
    targetMarketId: number,
    targetMarketName: string
  ) => void;
  openProspectTargetMarket: (
    targetMarketId: number,
    prospectingCount: number
  ) => void;
  isOnboarding?: boolean;
}

@observer
export default class TargetMarketView extends React.Component<ITargetMarketViewProps> {
  private authorisationService = AppService.get(
    Types.Neo.Security.AuthorisationService
  );

  public async onDocumentSelected(fileList: File[] | FileList) {
    this.props.viewModel.documentUpload(fileList);
  }

  public securityCheck(role: any, openMethod: () => void) {
    if (this.authorisationService.hasRole(role)) {
      openMethod();
    } else {
      this.props.viewModel.showForbiddenModal = true;
    }
  }

  public checkPageManager() {
    if (
      this.props.viewModel.pageManager.totalRecords === 0 &&
      this.props.viewModel.pageManager.hasFetched
    ) {
      return false;
    } else if (
      this.props.viewModel.pageManager.totalRecords === -1 &&
      this.props.viewModel.pageManager.hasFetched
    ) {
      return false;
    } else if (
      this.props.viewModel.pageManager.totalRecords > 0 &&
      this.props.viewModel.pageManager.hasFetched
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getTargetMarketProgressSegments(
    targetMarketLookup: TargetMarketLookup
  ): IProgressSegment[] {
    return [
      {
        name: "To do",
        value: targetMarketLookup.progressToDo,
        variant: "light",
      },
      {
        name: "In progress",
        value: targetMarketLookup.progressProspected,
        variant: "warning",
      },
      {
        name: "Complete",
        value: targetMarketLookup.progressDone,
        variant: "success",
      },
    ];
  }

  public async addTargetMarket() {
    this.props.viewModel.taskRunner.run(async () => {
      // Checks if a new Target Market may be added
      let isAllowed = await this.props.viewModel.allowAddTM();

      if (isAllowed) {
        // Allow Add
        this.securityCheck(
          Roles.TargetMarkets.AddEditTargetMarkets,
          this.props.openAddTargetMarket
        );
      } else {
        // Deny Add
        this.props.viewModel.showMaxTargetMarketsModal = true;
      }
    });
  }

  public async cloneTargetMarket(targetMarketId: number) {
    // Checks if a new Target Market may be added
    let isAllowed = await this.props.viewModel.allowAddTM();

    if (isAllowed) {
      // Allow Add
      this.securityCheck(Roles.TargetMarkets.Clone, () =>
        this.props.viewModel.openCloneModal(targetMarketId)
      );
    } else {
      // Deny Add
      this.props.viewModel.showMaxTargetMarketsModal = true;
    }
  }

  public async undoTargetMarketDelete(targetMarketLookup: TargetMarketLookup) {
    // Checks if a new Target Market may be added
    let isAllowed = await this.props.viewModel.allowAddTM();

    if (isAllowed) {
      // Allow Add
      this.securityCheck(Roles.TargetMarkets.Manage, () =>
        this.props.viewModel.openUndoTargetMarketDeleteModal(targetMarketLookup)
      );
    } else {
      // Deny Add
      this.props.viewModel.showMaxTargetMarketsModal = true;
    }
  }

  public displaySearch() {
    const condition1 =
      this.props.viewModel.isArchived &&
      this.props.viewModel.pageManager.totalRecords === 0;
    const condition2 = this.props.viewModel.pageManager.totalRecords > 0;
    const condition3 = this.props.viewModel.pageManager.hasFetched;
    return (
      (condition1 || condition2 || !this.props.viewModel.displayOverlay) &&
      condition3
    );
  }

  public targetMarketNameTooltip(tooltip: string) {
    if (this.props.viewModel.isComXUser) {
      return tooltip;
    }
    return null;
  }

  public closeModal() {
    this.props.viewModel.showOnboardingModal = false;
    this.props.viewModel.setStepCompleteModalAsSeen();
  }

  public render() {
    const { isComXUser, searchFieldVM } = this.props.viewModel;

    const stylesClientName: any = {
      xl: isComXUser ? 4 : 2,
      lg: isComXUser ? 4 : 2,
      md: isComXUser ? 6 : 2,
      sm: isComXUser ? 12 : 2,
      xs: isComXUser ? 12 : 2,
    };
    const stylesButtons: any = {
      xl: isComXUser ? 8 : 10,
      lg: isComXUser ? 8 : 10,
      md: isComXUser ? 12 : 10,
      sm: isComXUser ? 12 : 10,
      xs: isComXUser ? 12 : 10,
    };

    const progressLabels = (
      <Neo.GridLayout xs={1} sm={1} md={1} lg={1} xl={1}>
        {/* These div are here for placement of the progress legend - they need to be in the second column - so placing empty divs to allow for the second col*/}
        <div></div>
        <div className="TargetMarketView">
          <div className="text-default badge customBadgeFontSize mr-1 ">%</div>
          <label className="primary-text-color mr-1 customLabelFontSize">
            {textConstants.generalText.ToDo}
          </label>

          <div className="text-yellow badge customBadgeFontSize mr-1">%</div>
          <label className="primary-text-color mr-1 customLabelFontSize">
            {textConstants.generalText.InProgress}
          </label>

          <div className="badge text-success customBadgeFontSize mr-1">%</div>
          <label className="primary-text-color mr-1 customLabelFontSize">
            {textConstants.generalText.Complete}
          </label>
        </div>
        {/* These div are here for placement of the progress legend -
          they need to be in the second column - so placing empty divs to allow for the second col*/}
        <div></div>
      </Neo.GridLayout>
    );
    return (
      <div
        className={`${isComXUser ? "TargetMarketView" : "TargetMarketViewClient"
          }`}
      >
        {/* Back button */}
        {!window.location.href.toLowerCase().includes("campaigns") &&
          this.props.viewModel.showBackButton &&
          isComXUser && (
            <Neo.Button
              className="btn-width-100 mt-3 marginLeft24"
              variant="light"
              icon={<Icon.ArrowLeft />}
              onClick={async (e: any) => {
                this.props.back();
              }}
            >
              {textConstants.buttonText.Back}
            </Neo.Button>
          )}

        <div>
          <Neo.Card>
            {
              <div className="row m-0">
                {/* Search field */}
                <div className="col-12">
                  <div className="row">
                    <div className="col-3 p-24 mt-3">
                      {!this.props.viewModel.isArchived && (
                        <h2>Target Markets</h2>
                      )}
                      {this.props.viewModel.isArchived && (
                        <h2>Archived Target Markets</h2>
                      )}
                    </div>
                    <div className="col-9 p-24 pull-right">
                      <>
                        {/* Update Target Markets */}
                        {this.checkPageManager() && isComXUser && (
                          <StyledButton
                            className="pull-right ml-4"
                            variant="hasIcon"
                            size="xSmall"
                            onClick={() =>
                              this.securityCheck(
                                Roles.TargetMarkets.Manage,
                                () => this.props.openUpdateTargetMarket()
                              )
                            }
                          >
                            {textConstants.buttonText.UpdateTargetMarkets}
                          </StyledButton>
                        )}
                      </>

                      <>
                        {/* Add Target Market */}
                        {this.props.viewModel.client.clientId !== 0 && (
                          <StyledButton
                            className="pull-right ml-4"
                            variant="hasIcon"
                            size="xSmall"
                            onClick={() => this.addTargetMarket()}
                          >
                            {textConstants.buttonText.AddTargetMarket}
                          </StyledButton>
                        )}
                      </>
                      {this.displaySearch() && ( // this only hides the search if its not archived
                        <div className="mt-3">
                          <SearchField
                            onClickFunction={(e) => {
                              this.props.viewModel.pageManager.refreshData();
                              searchFieldVM.hideNoResultDialog = true;
                            }}
                            searchBindableProp={
                              this.props.viewModel.searchCriteria.meta
                                .searchString
                            }
                            disabled={
                              this.props.viewModel.pageManager.totalRecords ===
                              0 && this.props.viewModel.isArchived
                            }
                            searchString={
                              this.props.viewModel.searchCriteria.searchString
                            }
                            searchFieldVM={searchFieldVM}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-12 p-0">
                      {
                        <div className="col-6 p-24">
                          {isComXUser && (
                            <ClientNavigationButtons
                              viewModel={
                                this.props.viewModel.clientNavigationButtons
                              }
                              back={this.props.back}
                            />
                          )}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-12 p-24">
                  <Neo.TabContainer
                    className="mb-0 mr-0 ml-0 mt-2"
                    variant="flat"
                  >
                    <Neo.Tab
                      header="Current"
                      onDisplayed={() => {
                        this.props.viewModel.searchCriteria.isDeleted = false;
                        this.props.viewModel.isArchived = false;
                      }}
                    ><></></Neo.Tab>
                    <Neo.Tab
                      header="Archived"
                      onDisplayed={() => {
                        this.props.viewModel.hideGrid = false;
                        this.props.viewModel.searchCriteria.isDeleted = true;
                        this.props.viewModel.isArchived = true;
                      }}
                      onLeave={() => this.props.viewModel.hasPageManagerRefreshed = false}
                    ><></></Neo.Tab>
                  </Neo.TabContainer>
                </div>
              </div>
            }

            {this.props.viewModel.pageManager.totalRecords === 0 &&
              this.props.viewModel.hideGrid === false &&
              searchFieldVM.isSearching &&
              this.props.viewModel.hasCompletedLoad && (
                <InfoBox
                  header={textConstants.titleText.NoSearchMatches}
                  text={textConstants.generalText.NoSearchMatches}
                />
              )}

            {this.props.viewModel.pageManager.totalRecords === 0 &&
              this.props.viewModel.isArchived &&
              this.props.viewModel.hideGrid === false &&
              !searchFieldVM.isSearching &&
              !searchFieldVM.hideNoResultDialog &&
              this.props.viewModel.hasCompletedLoad && (
                <div className="TargetMarketView">
                  <InfoBox
                    header={textConstants.titleText.NoArchivedTargetMarkets}
                    text={textConstants.generalText.NoArchivedTargetMarketText}
                  />
                </div>
              )}

            {this.props.viewModel.pageManager.totalRecords === 0 &&
              !this.props.viewModel.isArchived &&
              this.props.viewModel.hideGrid === false &&
              !searchFieldVM.isSearching &&
              !searchFieldVM.hideNoResultDialog &&
              this.props.viewModel.hasCompletedLoad && (
                <div className="TargetMarketView">
                  <InfoBox
                    header={textConstants.titleText.NoTargetMarkets}
                    text={textConstants.generalText.NoTargetMarketText}
                  />
                </div>
              )}

            {/* Target Markets grid */}
            {this.props.viewModel.pageManager.totalRecords > 0 &&
              this.props.viewModel.hideGrid === false && (
                <React.Fragment>
                  <Neo.Pager
                    pageManager={this.props.viewModel.pageManager}
                    pageControls="none"
                  >
                    {(data) => (
                      <NeoGrid.Grid<TargetMarketLookup>
                        items={this.props.viewModel.pageManager}
                      >
                        {(targetMarketLookup, meta) => (
                          <NeoGrid.Row className="table-borderless">
                            {/* Sequence Number width={20}*/}
                            <NeoGrid.Column
                              className={"pb-2 pr-0 gridName"}
                              label={isComXUser ? "TM" : "No."}
                              display={meta.sequenceNumber}
                              sort={false}
                              alignment={"left"}
                              width={1}
                            />

                            {/* Name  width={230} */}
                            <NeoGrid.Column
                              className={"TargetMarketDefault pr-0"}
                              label={textConstants.titleText.Name}
                            >
                              <div
                                className={`pt-3 pb-3 gridName ${this.props.viewModel.isArchived ? "" : "clickableCursor"}`}
                                onClick={() => {
                                  if (!this.props.viewModel.isArchived) {
                                    this.securityCheck(
                                      Roles.TargetMarkets.AddEditTargetMarkets,
                                      () =>
                                        this.props.openEditTargetMarket(
                                          targetMarketLookup.targetMarketId,
                                          targetMarketLookup.targetMarketName
                                        )
                                    );
                                  }
                                }}
                              >
                                {targetMarketLookup.targetMarketName}
                              </div>
                            </NeoGrid.Column>

                            {/* Country */}
                            <NeoGrid.Column
                              label={textConstants.titleText.Country}
                              className="pb-2 pr-0"
                              width={150}
                            >
                              <div className="row p-0 m-0">
                                {CountryCodes[targetMarketLookup.countryCode] >
                                  1 && (
                                    <SpriteMap
                                      value={
                                        CountryCodes[
                                        targetMarketLookup.countryCode
                                        ]
                                      }
                                    />
                                  )}
                                <div className="pl-1 pr-2">
                                  <span>{targetMarketLookup.countryName}</span>
                                </div>
                              </div>
                            </NeoGrid.Column>

                            {/* Status */}
                            {(isComXUser && this.props.viewModel.isArchived === false) &&
                              <NeoGrid.Column
                                className='pb-2 pr-0'
                                label={textConstants.titleText.Status}
                                sort={false}
                                width={120}
                                disabled={!targetMarketLookup.isComplete}
                                bind={meta.targetMarketStatusId}
                                defaultValue={targetMarketLookup.targetMarketStatusId}
                                select={{ items: this.props.viewModel.targetMarketStatusList.sortBy("targetMarketOrderNumber") }}
                                onChange={() => {
                                  this.props.viewModel.updateTargetMarketStatus(targetMarketLookup.targetMarketId, targetMarketLookup.targetMarketStatusId);
                                }}
                                editorProps={{ style: { color: this.props.viewModel.getStatusColor(targetMarketLookup.targetMarketStatusId), fontSize: '13px' } }}
                              />
                            }
                            {/* This updates target market list depending on how it is sorted*/}
                            {/* Accounts */}
                            {(targetMarketLookup.isComplete) ?
                              <NeoGrid.ButtonColumn hidden={this.props.viewModel.isArchived} label={textConstants.titleText.Accounts} width={80} className="text-left pl-4 pb-2 pr-4">
                                {isComXUser && !targetMarketLookup.isCalculating &&
                                  <u
                                    className="pb-2 font-weight-bold pe-auto user-select-auto"
                                    onClick={() => {
                                      this.securityCheck(Roles.TargetMarkets.TargetMarketAccounts,
                                        () => this.props.openTargetMarketAccounts(targetMarketLookup.targetMarketId))
                                    }}
                                  >
                                    {targetMarketLookup.targetMarketAccountCount}
                                    <div></div> {/* Done to force rendering when accounts are 0 */}
                                  </u>
                                }

                                {!isComXUser &&
                                  !targetMarketLookup.isCalculating && (
                                    <u
                                      className="pb-2 font-weight-bold pe-auto user-select-auto"
                                      onClick={() => {
                                        this.securityCheck(
                                          Roles.TargetMarkets
                                            .TargetMarketAccounts,
                                          () =>
                                            this.props.openTargetMarketAccounts(
                                              targetMarketLookup.targetMarketId
                                            )
                                        );
                                      }}
                                    >
                                      {
                                        targetMarketLookup.targetMarketAccountCount
                                      }
                                      <div></div>{" "}
                                      {/* Done to force rendering when accounts are 0 */}
                                    </u>
                                  )}

                                {targetMarketLookup.isCalculating &&
                                  <Neo.Button
                                    variant="light"
                                    className="btn-width-100 calculatingAccountsButton"
                                    onClick={() => {
                                      this.securityCheck(Roles.TargetMarkets.TargetMarketAccounts,
                                        () => this.props.openTargetMarketAccounts(targetMarketLookup.targetMarketId))
                                    }}
                                  >
                                    <Loading text="Calculating" />
                                  </Neo.Button>
                                }
                              </NeoGrid.ButtonColumn>
                              : !this.props.viewModel.isArchived ?
                                <NeoGrid.Column width={0} style={{ width: "0px" }} />
                                : <></>
                            }

                            {/* Progress */}
                            <>
                              {
                                <>
                                  {targetMarketLookup.isCalculating && (
                                    <NeoGrid.Column
                                      label={textConstants.titleText.Progress}
                                      className="pb-2 pl-0 pr-0"
                                      width={240}
                                    >
                                      <Loading text="Calculating" />
                                      <div></div> {/* Forcing Render when 0*/}
                                    </NeoGrid.Column>
                                  )}

                                  {!targetMarketLookup.isCalculating && (
                                    <NeoGrid.Column
                                      label={textConstants.titleText.Progress}
                                      className="pb-2 pl-0 pr-0"
                                      width={240}
                                    >
                                      {targetMarketLookup.isComplete ? (
                                        <SegmentedProgressBar
                                          segments={this.getTargetMarketProgressSegments(
                                            targetMarketLookup
                                          )}
                                          total={100}
                                        />
                                      ) : (
                                        <div className="progress incomplete">
                                          {textConstants.generalText.incomplete}
                                        </div>
                                      )}
                                    </NeoGrid.Column>
                                  )}
                                </>
                              }
                            </>

                            {/* Target Markets */}
                            <NeoGrid.ButtonColumn className="pl-3 pr-0 pb-0 pt-0">
                              <StyledButtonWrapper>
                                {!this.props.viewModel.isArchived && (
                                  <StyledButtonGroupWrapper>
                                    {/* Edit */}
                                    <DataTooltip
                                      parent={
                                        <StyledButton
                                          isIconButton={true}
                                          onClick={() => {
                                            this.securityCheck(
                                              Roles.TargetMarkets
                                                .AddEditTargetMarkets,
                                              () =>
                                                this.props.openEditTargetMarket(
                                                  targetMarketLookup.targetMarketId,
                                                  targetMarketLookup.targetMarketName
                                                )
                                            );
                                          }}
                                        >
                                          <Icon.Edit size={16} />
                                        </StyledButton>
                                      }>
                                      {textConstants.buttonText.Edit}
                                    </DataTooltip>

                                    {targetMarketLookup.isComplete &&
                                      <>
                                        {/* Clone */}
                                        {this.props.viewModel.canClone(targetMarketLookup.industriesVersionId) &&
                                          <DataTooltip
                                            parent={
                                              <StyledButton
                                                isIconButton={true}
                                                onClick={() => { this.cloneTargetMarket(targetMarketLookup.targetMarketId) }}>
                                                <Icon.Copy size={16} />
                                              </StyledButton>}>
                                            {textConstants.buttonText.Clone}
                                          </DataTooltip>
                                        }
                                        {!this.props.viewModel.canClone(targetMarketLookup.industriesVersionId) &&
                                          <div className={'disabledCloneTooltipContainer' + (this.props.viewModel.isFirstElementInList(targetMarketLookup.sequenceNumber) ? ' firstRowTooltip' : '')}>
                                            <StyledButton
                                              disabled={true}
                                              isIconButton={true}>
                                              <Icon.Copy size={16} />
                                            </StyledButton>
                                            <div className='disabledCloneTooltip'>{textConstants.buttonText.TMCloneDisabled}</div>
                                          </div>
                                        }

                                        {/* Prospects */}
                                        {isComXUser &&
                                          <DataTooltip
                                            parent={
                                              <StyledButton
                                                isIconButton={true}
                                                onClick={() => {
                                                  this.securityCheck(Roles.TargetMarkets.Prospecting,
                                                    () => this.props.openProspectTargetMarket(targetMarketLookup.targetMarketId, targetMarketLookup.prospectingCount))
                                                }}>
                                                <Icon.Search size={16} />
                                              </StyledButton>}>
                                            {textConstants.buttonText.Prospect}
                                          </DataTooltip>
                                        }

                                        {/* Whitelist */}
                                        {isComXUser && (
                                          <DataTooltip
                                            parent={
                                              <StyledButton
                                                isIconButton={true}
                                                onClick={() =>
                                                  this.securityCheck(
                                                    Roles.TargetMarkets.Manage,
                                                    () =>
                                                      this.props.viewModel.openWhitelistModal(
                                                        targetMarketLookup.countryId,
                                                        targetMarketLookup.targetMarketId
                                                      )
                                                  )
                                                }
                                              >
                                                <Icon.Columns size={16} />
                                              </StyledButton>
                                            }>
                                            {textConstants.buttonText.Whitelist}
                                          </DataTooltip>
                                        )}
                                      </>
                                    }
                                  </StyledButtonGroupWrapper>
                                )}

                                {!this.props.viewModel.isArchived && (
                                  <DataTooltip
                                    parent={
                                      <StyledButton
                                        isIconButton={true}
                                        onClick={() => {
                                          this.securityCheck(
                                            Roles.TargetMarkets.Manage,
                                            () =>
                                              this.props.viewModel.openDeleteModal(
                                                targetMarketLookup
                                              )
                                          );
                                        }}
                                      >
                                        <Icon.Archive size={16} />
                                      </StyledButton>
                                    }>
                                    {textConstants.buttonText.Archive}
                                  </DataTooltip>
                                )}
                                {this.props.viewModel.isArchived && (
                                  <DataTooltip
                                    parent={
                                      <StyledButton
                                        isIconButton={true}
                                        onClick={() => {
                                          this.undoTargetMarketDelete(
                                            targetMarketLookup
                                          );
                                        }}
                                      >
                                        <Icon.RotateCw size={16} />
                                      </StyledButton>
                                    }>
                                    {textConstants.buttonText.Unarchive}
                                  </DataTooltip>
                                )}
                              </StyledButtonWrapper>
                            </NeoGrid.ButtonColumn>
                          </NeoGrid.Row>
                        )}
                      </NeoGrid.Grid>
                    )}
                  </Neo.Pager>

                  <div className="col-12 p-24 zendeskGridMarginBottom">
                    <CustomPagingControl
                      displayLabel={true}
                      pageManager={this.props.viewModel.pageManager}
                    />
                  </div>
                </React.Fragment>
              )}
          </Neo.Card>
        </div>

        <OnboardingModal
          isOpen={this.props.viewModel.showOnboardingModal}
          imageSrc={TrophyWoman}
          content={
            <>{HtmlParser(this.props.viewModel.onboardingPopupModalContent)}</>
          }
          closeModal={() => {
            this.closeModal();
          }}
          acceptAndCloseButton={{
            text: textConstants.generalText.Close,
            onClose: () => {
              this.closeModal();
            },
          }}
        />

        {/* Clone Target Market Modal */}
        <Neo.Modal
          title="Clone Target Market"
          className="validationText"
          show={this.props.viewModel.showCloneModal}
          formProps={{ model: this.props.viewModel.cloneTargetMarketCommand }}
          onClose={() => (this.props.viewModel.showCloneModal = false)}
          closeButton={{ text: "Cancel", variant: "light" }}
          acceptButton={{
            text: "Submit",
            variant: "secondary",
            onClick: () => this.props.viewModel.cloneTargetMarket(),
          }}
        >
          <Neo.GridLayout md={1}>
            <Neo.FormGroup
              className="mt-2"
              bind={
                this.props.viewModel.cloneTargetMarketCommand.meta
                  .targetMarketName
              }
              placeholder={textConstants.generalText.placeHolderTypeHereText}
            />
            <Neo.FormGroup
              bind={
                this.props.viewModel.cloneTargetMarketCommand.meta.countryId
              }
              className="mt-2"
              select={{ items: this.props.viewModel.countries }}
              placeholder={textConstants.generalText.placeHolderTypeHereText}
            />
          </Neo.GridLayout>
        </Neo.Modal>
        {/* Delete Target Market Modal */}
        <Neo.Modal
          title={`Delete confirmation`}
          show={this.props.viewModel.showDeleteModal}
          onClose={() => (this.props.viewModel.showDeleteModal = false)}
          closeButton={{ text: "No", variant: "light" }}
          acceptButton={{
            text: "Yes",
            variant: "secondary",
            onClick: () => this.props.viewModel.deleteTargetMarket(),
          }}
        >
          Target Market &#34;{this.props.viewModel.selectedTMName}&#34; is about to be
          deleted. Do you wish to continue?
        </Neo.Modal>

        {/* Whitelist Modal */}
        <Neo.Modal
          title={`Upload Whitelist`}
          show={this.props.viewModel.showWhitelistModal}
          onClose={() => (this.props.viewModel.showWhitelistModal = false)}
          closeButton={{ text: "Close", variant: "light" }}
        >
          <Neo.GridLayout xl={1} lg={1} md={1} sm={1} xs={1}>
            <p> {textConstants.titleText.PleaseEnsureHeaders} </p>
            <ol>
              {this.props.viewModel.columnHeadings.map((i) => (
                <li className="dropZoneListDisplay" key={i}>
                  {" "}
                  {i}{" "}
                </li>
              ))}
              <a
                className="text-right customDownloadLink"
                onClick={() => this.props.viewModel.downloadTemplate()}
              >
                {textConstants.generalText.DownloadTemplate}
              </a>
            </ol>
            <Neo.FileContext
              onFilesSelected={(e) => this.onDocumentSelected(e)}
              allowedExtensions={[".csv"]}
            >
              <Neo.FileDropArea multiple={false}>
                <p className="text-center dropZoneText">
                  <Neo.FileUploadButton>
                    <Icon.Upload />
                    {textConstants.generalText.uploadOrDragFile}
                  </Neo.FileUploadButton>
                </p>
              </Neo.FileDropArea>
            </Neo.FileContext>
          </Neo.GridLayout>
        </Neo.Modal>
        {/* Forbidden Modal */}
        <Neo.Modal
          title="Forbidden"
          show={this.props.viewModel.showForbiddenModal}
          onClose={() => (this.props.viewModel.showForbiddenModal = false)}
          closeButton={{ text: "Cancel", variant: "light" }}
        >
          <p>You have insufficient privileges to perform this operation.</p>
        </Neo.Modal>
        {/* Max TM Modal */}
        <Neo.Modal
          title="Max Target Markets"
          show={this.props.viewModel.showMaxTargetMarketsModal}
          onClose={() =>
            (this.props.viewModel.showMaxTargetMarketsModal = false)
          }
          closeButton={{ text: "Close", variant: "light" }}
        >
          <p>{textConstants.messageText.validationMessage.maxTMReached}</p>
        </Neo.Modal>
        {/* Undo Target Market Delete Modal */}
        <Neo.Modal
          title={`Undo Archive Confirmation`}
          show={this.props.viewModel.showUndoDeleteModal}
          onClose={() => (this.props.viewModel.showUndoDeleteModal = false)}
          closeButton={{ text: "No", variant: "light" }}
          acceptButton={{
            text: "Yes",
            variant: "secondary",
            onClick: () =>
              this.securityCheck(Roles.TargetMarkets.Manage, () =>
                this.props.viewModel.undoTargetMarketDelete()
              ),
          }}
        >
          Target Market &#34;{this.props.viewModel.selectedTMName}&#34; is about to be
          unarchived. Do you wish to continue?
        </Neo.Modal>
      </div>
    );
  }
}