import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class DashboardReportConfig extends ModelBase {

    public dashboardReportConfigId: number = 0;

    public configCode: string = "";

    @Attributes.Display("Legend Description")
    public configName: string = "";

    public value: string = ""

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.value === "", textConstants.messageText.validationMessage.value);
        rules.failWhen(c => c.configName === "", textConstants.messageText.validationMessage.description);
    }

    public toString(): string {
        if (this.isNew || !this.configName) {
            return "New Configuration";
        } else {
            return this.configName;
        }
    }
}