import React from "react";
import { AppService, Types } from "../Services/AppService";

interface IOnboardingCalendar {
  calendlyUrl?: string
  clientId: string
}

class OnboardingCalendar extends React.Component<IOnboardingCalendar> {

  private config = AppService.get(Types.Config)

  private defaultCalendlyUrl = this.config.calendlyUrl

  state = {
    calendlyUrl: (!this.props.calendlyUrl || this.props.calendlyUrl === "") ? this.defaultCalendlyUrl : this.props.calendlyUrl
  };

  constructor(props: any) {
    super(props)
  }

  componentDidMount() {
    //Set up Calendly Widget
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head!.appendChild(script);
  }

  render() {
    return (
      <div className="MeetingView row m-0">
        <div className="col-12 p-0">
          <div
            className="calendly-inline-widget calendly-custom"
            data-url={`${this.state.calendlyUrl}?utm_source=${this.props.clientId}`}
            style={{ position: "relative", height: "750px" }}
          />
        </div>
      </div>
    );
  }
}

export default OnboardingCalendar;
