import { LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class OutboundEmailTableLookup extends LookupBase {

    outboundEmailId : number = 0;

    emailProviderTypeId : number = 0;

    providerName : string = "";

    emailAddress : string = "";

    username : string = "";
}