import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import BlacklistedDomainHelper from '../BlacklistedDomainHelper';
import * as Roles from '../../../../Models/Security/Roles';
import * as Icon from 'react-feather';
import DataTooltip from 'Components/DataTooltip';
import { textConstants } from 'common/textConstants';
import { StyledButton } from 'Components/Button/styles';


export const helperConst = {
    emptyText: "No blacklisted domains found.",
    searchString: "searchString",
    idProperty: "blacklistedDomainId",
    initialPageSize: 500,
    gridStyle: { minHeight: 600, maxWidth: 2000 },
}

const BlacklistedDomainGridColumns = {
    blacklistedDomainId: {
        name: "blacklistedDomainId",
        display: "blacklistedDomainId",
    },
    domain: {
        name: "domain",
        display: "Domain",
    },
    dateAdded: {
        name: "dateAdded",
        display: "Date Added",
    },
    addedBy: {
        name: "addedBy",
        display: "Added By",
    },
    isDeleted: {
        name: "isDeleted",
        display: "Delete",
    },
}

export const CreateColumns = (viewModel: BlacklistedDomainHelper) => {
    return [
        {
            defaultLocked: true,
            name: BlacklistedDomainGridColumns.blacklistedDomainId.name,
            header: BlacklistedDomainGridColumns.blacklistedDomainId.display,
            defaultVisible: false,
            render: ({ value, data }: any) => (
                <div>
                    {data.blacklistedDomainId}
                </div>
            ),
        },
        {
            name: BlacklistedDomainGridColumns.domain.name,
            header: BlacklistedDomainGridColumns.domain.display,
            sortable: true,
            defaultFlex: 1,
            minWidth: 180,
        },
        {
            name: BlacklistedDomainGridColumns.dateAdded.name,
            header: BlacklistedDomainGridColumns.dateAdded.display,
            defaultFlex: 1,
            minWidth: 250,
            sortable: true
        },
        {
            name: BlacklistedDomainGridColumns.addedBy.name,
            header: BlacklistedDomainGridColumns.addedBy.display,
            minWidth: 180,
            sortable: true
        },
        {
            name: BlacklistedDomainGridColumns.isDeleted.name,
            header: BlacklistedDomainGridColumns.isDeleted.display,
            defaultFlex: 0.1,
            sortable: false,
            defaultLocked: 'end',
            minWidth: 80,
            render: ({ value: isDeleted, data: blacklistDomain }: any) => (

                <div className="text-center">
                    {/* Delete button */}
                    {!isDeleted &&
                        <StyledButton
                            isIconButton={true}
                            onClick={() => viewModel.securityCheck(Roles.Blacklist.Manage,
                                () => viewModel.openDeleteModal(blacklistDomain))}>
                            <Icon.Trash2 size={16} />
                        </StyledButton>
                    }
                </div>
            )
        },
    ];
}