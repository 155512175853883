import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class SaveOutboundEmailCommand extends ModelBase {

    public outboundEmailId : number = 0;
   
    public clientId : number = 0

    public emailProviderTypeId : number = 0
  
    public emailAccountURL : string = ""
  
    public emailAddress : string = ""

    public username : string = ""
  
    public password : string = ""
  
    public imapServer : string = ""

    public imapPort : number = 0

    public smtpServer : string = ""

    public smtpPort : number = 0
}