import React from "react";
import { StyledTabs, StyledTab, StyledTabContent } from "./Tabs.styles";
import ReactHtmlParser from 'react-html-parser';

export interface TabsProps {
  tabs: string[];
  tabsContent: string[];
}

const Tabs = ({ tabs, tabsContent }: TabsProps) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const onClickTabItem = (tab: number) => {
    setActiveTab(tab);
  };

  return (
    <>
      <StyledTabs>
        {tabs.map((tab, index) => {
          return (
            <StyledTab
              key={index}
              active={activeTab === index}
              onClick={() => onClickTabItem(index)}
            >
              {tab}
            </StyledTab>
          );
        })}
      </StyledTabs>
      <StyledTabContent>
        {ReactHtmlParser(tabsContent[activeTab])}
      </StyledTabContent>
    </>
  );
};

export default Tabs;
