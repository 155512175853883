import type { IBlacklistedDomainsApiClient } from './../../../ApiClients/BlacklistedDomainsApiClient';
import { Data, List, Model, ModelBase, NeoModel } from "@singularsystems/neo-core";
import type { ITaskRunner } from "@singularsystems/neo-core";
import type { INotificationHelper } from '@singularsystems/neo-core/dist/Components';
import { base64toBlob, getCurrentDateTime, ValidateUsingRegex } from '../../../common/utils';
import BlacklistedDomainCriteria from '../../../Models/BlacklistedDomains/Query/BlacklistedDomainCriteria';
import BlacklistedDomainLookup from '../../../Models/BlacklistedDomains/BlacklistedDomainLookup';
import { textConstants } from '../../../common/textConstants';
import DeleteBlacklistedDomain from '../../../Models/BlacklistedDomains/Commands/DeleteBlacklistedDomain';
import CommandResult from '../../../Models/InvitedUsers/Commands/CommandResult';
import QuickAddItem, { DomainVerificationResult } from '../../../Models/BlacklistedDomains/QuickAddDomain';
import BlacklistedDomain from '../../../Models/BlacklistedDomains/BlacklistedDomain';
import TargetMarketAccountsVM from '../../TargetMarketAccounts/TargetMarketAccountsVM';
import TypeRowSelection from '@inovua/reactdatagrid-community/types/TypeSelected';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import { AppService, Types } from '../../../Services/AppService';
import { triggerHotjarEvent } from '../../../Components/Hotjar';
import { QuickAddedItem } from 'Models/Interfaces/ApplicationInterfaces';
type bulkActions = "BulkDelete" | ""

@NeoModel
export default class BlacklistedDomainHelper extends ModelBase {
  constructor(
    public taskRunner: ITaskRunner,
    public blacklistedDomainsApiClient: IBlacklistedDomainsApiClient,
    public notifications: INotificationHelper,
    public clientId: number,
    public clientDetails: any,
    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService),
  ) {
    super();
    this.taskRunner = taskRunner
    this.blacklistedDomainsApiClient = blacklistedDomainsApiClient
    this.notifications = notifications
    this.clientId = clientId
    this.clientDetails = clientDetails
  }

  public showQuickAddModal: boolean = false;
  public showBlacklistUploadModal: boolean = false;
  public blacklistSearchCriteria: BlacklistedDomainCriteria = new BlacklistedDomainCriteria();
  public bulkActions: bulkActions | "" = ""
  public bulkActionMessage: string = ""
  public showBulkActionModal: boolean = false
  public deleteBlacklistedDomainCommand = new DeleteBlacklistedDomain();
  public showBlacklistDeleteModal: boolean = false;
  public selectedDomainName: string = "";
  public columnHeadings: string[] = textConstants.generalText.DomainWebsiteEmail
  public domainRegex: string = "";
  public QuickAddString: QuickAddItem = new QuickAddItem();
  public QuickAddStringList = new List(QuickAddItem);
  public domainsToSaveList: { quickAddString: QuickAddItem, key: string }[] = [];

  public QuickAddErrorDomains: string[] = [];
  public clientName: string = "";
  public isSaveSuccess: boolean = false;
  public selected?: TypeRowSelection
  public selectedItems?: TypeOnSelectionChangeArg
  public refreshMethod: any
  public showForbiddenModal: boolean = false;
  public selectedItemsCount: number = 0;
  public unSelectedDomains = new List(BlacklistedDomainLookup);
  public isRefreshCount: boolean = false;
  public blacklistCount: number = 1;

  public fileName: string = ""
  public fileList: File[] = []

  private splitDomains: QuickAddedItem[] = []

  public hasValidationFailed: boolean = false;

  public securityCheck(role: any, openMethod: () => void) {
    if (this.authorisationService.hasRole(role)) {
      openMethod();
    }
    else {
      this.showForbiddenModal = true;
    }
  }

  public async setReactPageGridAndList(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<BlacklistedDomainCriteria>>) {
    let responseModel = await this.blacklistedDomainsApiClient.getBlacklistedDomainsPaged(request)

    if (this.isRefreshCount) {
      this.calculateSelectedItems(responseModel.data.pageResult.total);
    }

    this.blacklistCount = responseModel.data.pageResult.total;

    this.isRefreshCount = false;
    return responseModel
  }

  public getRefreshMethod = (dataSource: (props: any) => Promise<void>) => {
    this.refreshMethod = dataSource
  }

  public onSelectionChange(data: any, totalRecords?: number) {

    this.selectedItems = data;

    this.handleSelectAll(data);

    this.calculateSelectedItems(totalRecords);
  }

  public openBlacklistUploadModal() {
    this.showBlacklistUploadModal = true;
  }

  public downloadBlacklistedDomains() {
    this.taskRunner.run(async () => {
      const response = await this.blacklistedDomainsApiClient.downloadBlacklistedDomains(
        this.clientId
      );

      const url = window.URL.createObjectURL(base64toBlob(response.data.fileContents, response.data.contentType));

      triggerHotjarEvent(textConstants.Events.clientBlackListDownload)

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "BlackListDownload_" +
        this.clientDetails.clientName +
        "_" +
        getCurrentDateTime() +
        ".csv"
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  public OpenBulkActionModal(action: bulkActions) {
    this.bulkActions = action
    this.bulkActionMessage = ""
    if (this.bulkActions === textConstants.generalText.BulkDelete) {
      this.bulkActionMessage = textConstants.messageText.validationMessage.bulkBlacklistDeleteConfirmation
      this.showBulkActionModal = true
    }
  }

  private CreateLookup(domainToAdd: BlacklistedDomainLookup, data: any) {
    domainToAdd.blacklistedDomainId = data.data.blacklistedDomainId;
    domainToAdd.domain = data.data.domain;
    domainToAdd.dateAdded = data.data.dateAdded;
    domainToAdd.addedBy = data.data.addedBy;
  }

  private calculateSelectedItems(totalRecords: number | undefined) {
    if (totalRecords !== 0) {
      if (this.selectedItems && this.selectedItems.selected && totalRecords) {
        var selected = this.selectedItems.selected;
        this.selected = this.selectedItems.selected

        if (selected === true) {
          let recordCount = totalRecords - (this.unSelectedDomains ? this.unSelectedDomains.length : 0);
          this.selectedItemsCount = recordCount
        }
        else if (selected) {
          var manuallySelectedItems = Object.keys(this.selectedItems.selected);
          this.selectedItemsCount = manuallySelectedItems.length;
        }
      }
    } else {
      this.selectedItemsCount = 0;
    }
  }

  private handleSelectAll(data: any) {
    if (this.selectedItems !== undefined) {
      if (this.selectedItems.selected === true) {
        let gridUnSelectedItems = this.selectedItems.unselected;

        if (gridUnSelectedItems) {
          let gridUnselectedDomains = Object.keys(gridUnSelectedItems);

          gridUnselectedDomains.forEach(unselectedDomainId => {

            let existingUnselectedDomain = this.unSelectedDomains.find(domain => domain.blacklistedDomainId.toString() === unselectedDomainId);

            if (existingUnselectedDomain === undefined) {
              let domainToAdd = new BlacklistedDomainLookup();

              if (unselectedDomainId === data.data.blacklistedDomainId.toString()) {
                this.CreateLookup(domainToAdd, data);
              }

              this.unSelectedDomains.push(domainToAdd);
            }
          });

          // Remove any reselected accounts
          this.unSelectedDomains.forEach(deselectedDomain => {
            const domainStillUnselected = (gridUnselectedDomains.indexOf(deselectedDomain.blacklistedDomainId.toString()) > -1);
            if (!domainStillUnselected) {
              this.unSelectedDomains.remove(deselectedDomain);
            }
          });
        }

        // Clear unselected accounts if user has reselected everything
        if (gridUnSelectedItems === null && this.unSelectedDomains.length > 0) {
          this.unSelectedDomains.length = 0;
        }
      }
    }
  }



  public openDeleteModal(blacklistedDomain: BlacklistedDomainLookup) {
    this.deleteBlacklistedDomainCommand = new DeleteBlacklistedDomain();

    this.blacklistSearchCriteria.clientId = this.clientId;
    this.deleteBlacklistedDomainCommand.blacklistedDomainCriteria = this.blacklistSearchCriteria
    this.deleteBlacklistedDomainCommand.BlacklistedDomainId = blacklistedDomain.blacklistedDomainId;

    // Show the modal
    this.showBlacklistDeleteModal = true;
    this.selectedDomainName = blacklistedDomain.domain;
  }

  public deleteBlacklistedDomain() {
    this.showBlacklistDeleteModal = false;
    this.taskRunner.run(async () => {
      const response = await this.blacklistedDomainsApiClient.deleteBlacklistedDomain(
        this.deleteBlacklistedDomainCommand
      );
      const cr: CommandResult = response.data as CommandResult;;
      if (cr.success) {
        this.notifications.addSuccess(
          textConstants.titleText.Saved,
          textConstants.messageText.saveMessage.BlacklistDomainDeleted
        );
        this.refreshMethod();
      }
    });
  }

  public downloadTemplateForBlacklistUpload() {
    this.taskRunner.run(async () => {
      const response = await this.blacklistedDomainsApiClient.getTemplate();

      const url = window.URL.createObjectURL(base64toBlob(response.data.fileContents, response.data.contentType));
      const link = document.createElement("a");
      link.href = url;
      const datetime = getCurrentDateTime();
      link.setAttribute("download", "Blacklist Template_" + datetime + ".csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  private QuickAddDomainValidation = (uploadedDomains: string): DomainVerificationResult => {

    this.splitDomains = []
    const dv = new DomainVerificationResult();

    const domains = uploadedDomains.split(/\n|\s|,|,\s/g).filter(domain => domain !== "");

    domains.forEach((domain) => {
      const quickAddedItem: QuickAddItem = new QuickAddItem
      let isSuccessful = false;
      let failedMessage = ""

      domain = domain.trim()

      var preDomainRegex = new RegExp("https:\/\/|https:\/|https|http:\/\/|http:\/|http|www.?");

      domain = domain.replace(preDomainRegex, "")
      domain = domain.replace("www.", "")

      if (domain.indexOf(".") === 0) {
        domain = domain.substring(1)
      }

      var postDomainRegex = new RegExp("(?:\/(([a-z]|[0-9]){0,}))");
      domain = domain.replace(postDomainRegex, "")
      domain = domain.split("/")[0]

      if (domain === "") {
        dv.isSuccess = false;
        failedMessage = (textConstants.messageText.validationMessage.invalidDomainEmptySpace)

        dv.failMessage.push(textConstants.messageText.validationMessage.invalidDomainEmptySpace);
      }

      else if (!ValidateUsingRegex(domain, this.domainRegex)) {
        dv.isSuccess = false;
        failedMessage = domain

        dv.failMessage.push(domain);
      }
      else if (dv.saveList.find(domainListDomain => domainListDomain.domain === domain) === undefined) {
        const domainSave = new BlacklistedDomain();

        domainSave.clientId = this.clientId;
        domainSave.domain = domain;
        
        isSuccessful = true;

        dv.saveList.push(domainSave);
      }

      quickAddedItem.item = domain

      let individualDomain: QuickAddedItem = {
        quickAddedItem: quickAddedItem,
        isSuccessful: isSuccessful,
        failedMessage: failedMessage
      }
      this.splitDomains.push(individualDomain)

    });
    return dv;
  };

  // Validates the new domain entry
  public validateNewDomainEntry(domain: QuickAddItem, isNewEntry: boolean) {
    this.QuickAddDomainValidation(domain.item);

    this.hasValidationFailed = false;
    this.QuickAddErrorDomains = []

    this.splitDomains.forEach(domainItem => {
      if (!domainItem.isSuccessful) {
        this.QuickAddErrorDomains.push(domainItem.failedMessage);
      }
      else {
        if (isNewEntry && this.QuickAddStringList.find(domainListDomain => domainListDomain.item === domainItem.quickAddedItem.item) === undefined) {
          let newQuickAddDomain = new QuickAddItem
          newQuickAddDomain = domainItem.quickAddedItem

          this.QuickAddStringList.unshift(newQuickAddDomain)
          this.QuickAddString = new QuickAddItem
        }
      }
    })

    if (this.QuickAddErrorDomains && this.QuickAddErrorDomains.length > 0) {
      this.hasValidationFailed = true
      this.QuickAddString.item = this.QuickAddErrorDomains.join(', ')
    }
  }

  public cleanUpProcess(canCleanUp: boolean) {
    if (this.hasValidationFailed && canCleanUp) {
      this.hasValidationFailed = false
      this.QuickAddString = new QuickAddItem();
    }
  }

  public async saveQuickAdd(preventGridRefresh: boolean, viewModel?: TargetMarketAccountsVM, isFromTMA?: boolean) {
    // To cater for blacklists from TM and the Dashboard
    let domainString = this.QuickAddString.item === "" ? "" : this.QuickAddString.item + "\n"

    this.QuickAddStringList.forEach(quickAddDom => {
      domainString += quickAddDom.item + "\n"
    })

    const validationValue = this.QuickAddDomainValidation(
      domainString
    );

    this.cleanUpProcess(validationValue.isSuccess)

    if (validationValue.isSuccess) {
      this.taskRunner.run(async () => {
        const saveResponse = await this.blacklistedDomainsApiClient.saveBlackListDomains(
          validationValue.saveList,
          this.clientId
        );

        if (saveResponse.data.success) {

          triggerHotjarEvent(textConstants.Events.clientBlackListQuickAdd)

          this.showQuickAddModal = false;
          this.QuickAddString = new QuickAddItem();
          this.QuickAddStringList = new List(QuickAddItem);
          this.QuickAddErrorDomains = [];
          this.isSaveSuccess = true;

          if (isFromTMA === true) {
            this.notifications.addSuccess(
              textConstants.titleText.Saved,
              textConstants.messageText.saveMessage.BlacklistAccountSuccess
            );

            if (viewModel) {
              viewModel.refreshMethod()
            }
          }
          else {
            this.notifications.addSuccess(
              textConstants.titleText.AddBlacklist,
              textConstants.messageText.saveMessage.BlacklistedDomainsUploadSuccess
            );
          }

          if (!preventGridRefresh) {
            this.refreshMethod();
          }

        }
        else if (!saveResponse.data.success && saveResponse.data.data !== null) {
          this.showQuickAddModal = false;
          this.QuickAddString = new QuickAddItem();
          this.QuickAddErrorDomains = [];

          if (isFromTMA === true) {

            this.notifications.addDanger(
              textConstants.titleText.Error,
              textConstants.messageText.saveMessage.BlacklistAccountFail
            );
          } else {
            this.notifications.addDanger(
              textConstants.titleText.Error,
              saveResponse.data.message
            );
          }
          const url = window.URL.createObjectURL(
            base64toBlob(saveResponse.data.item2, "text/csv")
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Blacklist_Invalid_Domains_" +
            this.clientName +
            "_" +
            getCurrentDateTime() +
            ".csv"
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        else if (!saveResponse.data.success) {
          this.showQuickAddModal = false;
          this.QuickAddString = new QuickAddItem();
          this.QuickAddErrorDomains = [];

          this.notifications.addDanger(
            textConstants.titleText.Error,
            saveResponse.data.message
          );
        }

      });
    }
    else {
      this.QuickAddErrorDomains = validationValue.failMessage;
    }
    return true
  }

  public uploadBlacklist(fileList: File[] | FileList) {
    this.taskRunner.run(async () => {
      const response = await this.blacklistedDomainsApiClient.uploadDocument(
        fileList,
        this.clientId
      );

      if (response.data.success) {
        triggerHotjarEvent(textConstants.Events.clientBlackListUpload)

        this.notifications.addSuccess(
          textConstants.titleText.AddBlacklist,
          textConstants.messageText.saveMessage.BlacklistedDomainsUploadSuccess
        );

        this.refreshMethod();
      }
      else if (!response.data.success && response.data.data !== null) {

        this.notifications.addDanger(
          textConstants.titleText.Error,
          response.data.message
        );

        const url = window.URL.createObjectURL(
          base64toBlob(response.data.data, "text/csv")
        );

        const link = document.createElement("a");

        link.href = url;

        link.setAttribute(
          "download",
          "Blacklist_Invalid_Domains_" + this.clientName + "_" + getCurrentDateTime() + ".csv"
        );

        document.body.appendChild(link);

        link.click();
        link.remove();
      }
      else {
        this.notifications.addDanger(
          textConstants.titleText.Error,
          response.data.message
        );
      }
    });

    this.showBlacklistUploadModal = false;
  }

  public PerformBulkAction() {
    this.showBulkActionModal = false;
    this.bulkBlacklistDelete()
    this.selectedItemsCount = 0;

    if (this.selectedItems) {
      this.selectedItems.selected = null;
    }

    this.selected = false;
  }

  private async bulkBlacklistDelete() {
    this.deleteBlacklistedDomainCommand.isSelectAll = false

    this.blacklistSearchCriteria.clientId = this.clientId

    this.deleteBlacklistedDomainCommand.blacklistedDomainCriteria = this.blacklistSearchCriteria

    this.taskRunner.run(async () => {

      var blacklistedDomainLookups = new List(BlacklistedDomainLookup)
      if (this.selectedItems && this.selectedItems.selected) {

        var selected = this.selectedItems.selected

        // Items have been selected using Select All checkbox
        if (selected === true) {
          this.deleteBlacklistedDomainCommand.unselectedBlacklistedDomains = this.unSelectedDomains
          this.deleteBlacklistedDomainCommand.isSelectAll = true
        }

        // Items have been selected individually
        else if (selected) {
          var keysMap = Object.keys(selected).map(domain => selected[domain])

          this.populateBlacklistedDomainLookups(keysMap, blacklistedDomainLookups);

          this.deleteBlacklistedDomainCommand.blacklistedDomainLookup = blacklistedDomainLookups
        }

        this.showBlacklistDeleteModal = false;
        const response = await this.blacklistedDomainsApiClient.bulkDelete(this.deleteBlacklistedDomainCommand)
        const cr: CommandResult = response.data as CommandResult;

        if (cr.success) {
          this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.BulkDeleteDomains);
        } else {
          this.notifications.addDanger(textConstants.titleText.SaveFailed, cr.error);
        }

        this.refreshMethod()
      }
    });
  }

  private populateBlacklistedDomainLookups(keysMap: any[], blacklistedDomainLookups: List<BlacklistedDomainLookup>) {
    keysMap.forEach(key => {
      var newLookup = new (BlacklistedDomainLookup);
      newLookup.blacklistedDomainId = key.blacklistedDomainId;
      newLookup.domain = key.domain;
      newLookup.dateAdded = key.dateAdded;
      newLookup.addedBy = key.addedBy;
      blacklistedDomainLookups.push(newLookup);
    });
  }
}
