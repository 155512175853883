import { AppService } from './Services/AppService';
import { appModule } from './Modules/AppModule';
import { AppServices } from '@singularsystems/neo-core';
import { NeoReactModule } from '@singularsystems/neo-react'
import { authorisationAppModule } from './Authorisation/AuthorisationModule';

const appService = AppService as AppServices.AppService;

appService.registerModule(AppServices.NeoModule);
appService.registerModule(NeoReactModule);
appService.registerModule(appModule);
appService.registerModule(authorisationAppModule)