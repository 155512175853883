import { Components } from '@singularsystems/neo-core';
import React from 'react';

type ISegmentedProgressBarProps = {
    segments: IProgressSegment[];
    total: number;
}

export interface IProgressSegment {
    name: string;
    value: number;
    variant: Components.variant;
}

export default class SegmentedProgressBar extends React.Component<ISegmentedProgressBarProps> {

    private segmentPercentage(segment: IProgressSegment) {
        return segment.name, segment.value;
    }

    private getTooltip(segment: IProgressSegment) {
        return `${segment.name} - ${segment.value}%`;
    }

    private setColour(segment: IProgressSegment){
        if (segment.name === "Complete"){
            return "lightGreenBackground";
        } else {
            return `bg-${segment.variant}`
        }
    }

    public render() {
        return (
            <div className="progress">
                {this.props.segments.map(segment =>
                    <div
                        key={segment.name}
                        className={`progress-bar ${this.setColour(segment)}`}
                        style={{ width: `${this.segmentPercentage(segment)}%` }}
                        data-tip={this.getTooltip(segment)}>{this.segmentPercentage(segment)}%</div>
                )}

            </div>
        );
    }
}