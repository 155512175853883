import { ModelBase } from "@singularsystems/neo-core";

export default class ReportingQuery extends ModelBase {

  ReportingQuery() {
    this.startDate = new Date()
    this.endDate = new Date()
  }

  public companyId?: number | null = null;

  public campaignId: number | null = 0;

  public reportType: string = "";

  public startDate: Date = new Date();

  public endDate: Date = new Date();
}