import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';

export interface IOnboardingMaintenanceApiClient extends Data.IUpdateableApiClient<OnboardingMaintenanceApiClient, number> {

}

@injectable()
export default class OnboardingMaintenanceApiClient extends Data.UpdateableApiClient<OnboardingMaintenanceApiClient, number> implements IOnboardingMaintenanceApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/onboardingMaintenances`);
    }
}