import { injectable } from 'inversify';
import { Data, List, Model, Utils } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import Axios, { AxiosPromise } from 'axios';
import ClientCriteria from './../Models/Client/Query/ClientCriteria';
import CommandResult from '../Models/InvitedUsers/Commands/CommandResult';
import EditClient from '../Models/Client/Commands/EditClient';
import DeleteClient from '../Models/Client/Commands/DeleteClient';
import ClientLookup from '../Models/Client/ClientLookup';
import ApiResult from '../Models/ApiResult';
import { ResponseResolver } from '../common/utils';
import LinkedClientLookup from '../Models/InvitedUsers/LinkedClientLookup';
import ClientAdditionalRoleLookup from '../Models/Client/ClientAdditionalRoleLookup';
import AdvancedEmailSettingsLookup from '../Models/Client/Query/AdvancedEmailSettingsLookup';
import AdvancedEmailSettingsSaveCommand from '../Models/Client/Commands/AdvancedEmailSettingsSaveCommand';
import OutboundEmailCriteria from '../Models/Client/Query/OutboundEmailCriteria';
import OutboundEmailLookup from '../Models/Client/OutboundEmailLookup';
import SaveOutboundEmailCommand from '../Models/Client/Commands/SaveOutboundEmailCommand';
import EmailProviderTypeLookup from '../Models/Maintenance/EmailProviderTypeLookup';
import HideHighlyRecommendedCommand from '../Models/Client/Commands/HideHighlyRecommendedCommand';
import SaveRefreshTimeCommand from '../Models/Client/Commands/SaveRefreshTimeCommand';
import MarkTooltipAsSeenCommand from '../Models/Client/Commands/MarkTooltipAsSeenCommand';
import ClientListLookup from '../Models/Client/ClientListLookup';
import ClientCampaignQueryLookup from '../Models/Dashboard/ClientCampaignQueryLookup';
import ArchivedClientCampaignsQueryLookup from '../Models/Dashboard/ArchivedMonthlyCampaignsQueryLookup';
import DailyCampaignMessageReportingDataLookup from '../Models/Dashboard/DailyCampaignMessageReportingDataLookup';
import PieChartReportLookup from '../Models/Dashboard/PieChartReportLookup';
import OutboundEmailTableLookup from 'Models/Client/Query/OutboundEmailTableLookup';
import ClientTestEmailLookup from 'Models/Client/Query/ClientTestEmailLookup';
import TestEmailsSaveCommand from 'Models/Client/Commands/TestEmailsSaveCommand';
import ActionPlanChecklistLookup from 'Models/Client/Query/ActionPlanChecklistLookup';
import TermsOfServiceLookup from 'Models/Client/Query/TermsOfServiceLookup';

export interface IClientsApiClient {
    getClientsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<ClientCriteria>>): AxiosPromise<any>,
    editClient(client: EditClient): AxiosPromise<Model.PlainObject<CommandResult>>;
    deleteClient(client: DeleteClient): AxiosPromise<Model.PlainObject<CommandResult>>;
    checkForDuplicate(clientName: string): AxiosPromise<Model.PlainObject<CommandResult>>;
    getClient(clientId: number): AxiosPromise<Model.PlainObject<ClientLookup>>;
    getArchivedMonthlyClientCampaigns(clientId: number, status: string): AxiosPromise<Model.PlainObject<ArchivedClientCampaignsQueryLookup>>;
    getDashboardInfo(userName: string): AxiosPromise<any>;
    getClientForEdit(clientId: number): AxiosPromise<Model.PlainObject<EditClient>>;
    undoDeleteClient(clientId: number): AxiosPromise<boolean>;
    addClient(client: EditClient): AxiosPromise<Model.PlainObject<CommandResult>>;
    getLinkedClients(userNames: string[]): AxiosPromise<List<LinkedClientLookup>>;
    getLinkedUsers(clientId: number): AxiosPromise<List<string>>;
    getClientAdditionalRoles(clientId: number): AxiosPromise<ClientAdditionalRoleLookup>;
    getCRMIntegrationData(clientId: number): AxiosPromise<any>;
    getAdvancedEmailSettings(clientId: number): AxiosPromise<Model.PlainObject<AdvancedEmailSettingsLookup>>;
    saveAdvancedEmailSettings(saveCommand: AdvancedEmailSettingsSaveCommand): AxiosPromise<ApiResult>;
    getOutboundEmailsTable(searchCriteria: Model.PartialPlainNonTrackedObject<Data.PageRequest<OutboundEmailCriteria>>): AxiosPromise<any>;
    getOutboundEmailsList(clientId: number): AxiosPromise<OutboundEmailTableLookup[]>
    deleteOutboundEmail(outboundEmailId: number): AxiosPromise<ApiResult>;
    unDeleteOutboundEmail(outboundEmailId: number): AxiosPromise<ApiResult>;
    getClientTestEmailsList(clientId: number): AxiosPromise<ClientTestEmailLookup[]>;
    deleteClientTestEmail(clientTestEmailId: number): AxiosPromise<any>
    saveClientTestEmailList(saveCommand: TestEmailsSaveCommand): AxiosPromise<any>
    getActionPlanChecklist(clientId: number): AxiosPromise<ActionPlanChecklistLookup>;
    getOutboundEmail(outboundEmailId: number): AxiosPromise<Model.PlainObject<OutboundEmailLookup>>;
    saveOutboundEmail(saveCommand: SaveOutboundEmailCommand): AxiosPromise<ApiResult>;
    hideHighlyRecommended(saveCommand: HideHighlyRecommendedCommand): AxiosPromise<ApiResult>;
    getActiveEmailProviders(): AxiosPromise<Array<Model.PlainObject<EmailProviderTypeLookup>>>;
    getClientCalendlyLink(clientId: number): AxiosPromise<string>;
    getClientCampaigns(clientId: number, isForced: boolean): AxiosPromise<ClientCampaignQueryLookup>;
    saveRefreshTime(command: SaveRefreshTimeCommand): AxiosPromise<ApiResult>;
    IsRefreshAllowed(clientId: number): AxiosPromise<ApiResult>;
    RequestFullAnalysis(clientId: number): AxiosPromise<any>;
    markTooltipAsSeen(command: MarkTooltipAsSeenCommand): AxiosPromise<ApiResult>;
    getLastRefreshTimestamp(clientId: number): AxiosPromise<ApiResult>;
    getClientsList(): AxiosPromise<Array<Model.PlainObject<ClientListLookup>>>;
    getClientCampaignsStatuses(clientId: number): AxiosPromise<ApiResult>;
    getLineChartReportingData(clientId: number, status: string): AxiosPromise<Model.PlainObject<DailyCampaignMessageReportingDataLookup>>;
    getPieChartReportingData(clientId: number): AxiosPromise<Model.PlainObject<PieChartReportLookup>>;
    setSeenFirstCampaign(clientId: number): AxiosPromise<ApiResult>;
    getClients(): AxiosPromise<Array<Model.PlainObject<ClientListLookup>>>;
    getTermsOfServiceData(clientId: number): AxiosPromise<TermsOfServiceLookup>;
    markDashboardNewsAsSeen(): AxiosPromise<ApiResult>;
}

@injectable()
export default class ClientsApiClient implements IClientsApiClient {

    constructor(public config = AppService.get(Types.Config)) {
    }

    public getClient(clientId: number): AxiosPromise<Model.PlainObject<ClientLookup>> {
        const config = AppService.get(Types.Config)
        return ResponseResolver<ClientLookup>(Axios.get<ApiResult>(`${config.apiPath}/Clients/GetClient?clientId=${clientId}`));
    }

    public getClientsPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<ClientCriteria>>): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/Clients?${Utils.getQueryString(request)}`);
    }

    public getClientsList(): AxiosPromise<Array<Model.PlainObject<ClientListLookup>>> {
        return ResponseResolver<List<ClientListLookup>>(Axios.get(`${this.config.apiPath}/Clients/GetClientList`));
    }

    public editClient(client: EditClient): AxiosPromise<{ success: boolean; error: string; id: number; }> {
        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.config.apiPath}/Clients/EditClient`, client.toJSObject()));
    }

    public deleteClient(client: DeleteClient): AxiosPromise<{ success: boolean; error: string; id: number; }> {
        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.config.apiPath}/Clients/DeleteClient`, client.toJSObject()));
    }

    public checkForDuplicate(clientName: string): AxiosPromise<{ success: boolean; error: string; id: number }> {
        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.config.apiPath}/Clients/CheckForDuplicate?clientName=${clientName}`));
    }

    public getDashboardInfo(userName: string): AxiosPromise<any> {
        return ResponseResolver<any>(Axios.post(`${this.config.apiPath}/Clients/GetDashboardInfo`, { userName }));
    }

    public getClientForEdit(clientId: number): AxiosPromise<Model.PlainObject<EditClient>> {
        const config = AppService.get(Types.Config)
        return ResponseResolver<Model.PlainObject<EditClient>>(Axios.get(`${config.apiPath}/Clients/GetClientForEdit?clientId=${clientId}`));
    }

    public undoDeleteClient(clientId: number): AxiosPromise<boolean> {
        return ResponseResolver<boolean>(Axios.get(`${this.config.apiPath}/Clients/UndoDeleteClient?clientId=${clientId}`));
    }

    public addClient(client: EditClient): AxiosPromise<{ success: boolean; error: string; id: number; }> {
        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.config.apiPath}/Clients/AddClient`, client.toJSObject()));
    }

    public getLinkedClients(userNames: string[]): AxiosPromise<List<LinkedClientLookup>> {
        return ResponseResolver<List<LinkedClientLookup>>(Axios.post(`${this.config.apiPath}/Clients/GetLinkedClients`, userNames));
    }

    public getLinkedUsers(clientId: number): AxiosPromise<List<string>> {
        return ResponseResolver<List<string>>(Axios.get(`${this.config.apiPath}/Clients/GetLinkedUsers?clientId=${clientId}`));
    }

    public getClientAdditionalRoles(clientId: number): AxiosPromise<ClientAdditionalRoleLookup> {
        return ResponseResolver<ClientAdditionalRoleLookup>(Axios.get(`${this.config.apiPath}/Clients/GetClientAdditionalRoles?clientId=${clientId}`));
    }

    public getCRMIntegrationData(clientId: number): AxiosPromise<any> {
        return ResponseResolver<any>(Axios.get(`${this.config.apiPath}/Clients/GetCRMIntegrationData?clientId=${clientId}`));
    }
    public getAdvancedEmailSettings(clientId: number): AxiosPromise<Model.PlainObject<AdvancedEmailSettingsLookup>> {
        return ResponseResolver<Model.PlainObject<AdvancedEmailSettingsLookup>>(Axios.get(`${this.config.apiPath}/Clients/GetAdvancedEmailSettings/${clientId}`));
    }

    public saveAdvancedEmailSettings(saveCommand: AdvancedEmailSettingsSaveCommand): AxiosPromise<ApiResult> {
        return ResponseResolver<ApiResult>(Axios.post(`${this.config.apiPath}/Clients/SaveAdvancedEmailSettings`, saveCommand.toJSObject()));
    }

    public getOutboundEmailsTable(searchCriteria: Model.PartialPlainNonTrackedObject<Data.PageRequest<OutboundEmailCriteria>>): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/Clients/GetOutboundEmailsTable?${Utils.getQueryString(searchCriteria)}`);
    }

    public getOutboundEmailsList(clientId: number): AxiosPromise<OutboundEmailTableLookup[]> {
        const config = AppService.get(Types.Config)
        return ResponseResolver<OutboundEmailTableLookup[]>(Axios.get(`${config.apiPath}/Clients/GetOutboundEmailsList/${clientId}`));
    }

    public deleteOutboundEmail(outboundEmailId: number): AxiosPromise<ApiResult> {
        return ResponseResolver<ApiResult>(Axios.delete(`${this.config.apiPath}/Clients/DeleteOutboundEmail/${outboundEmailId}`))
    }

    public unDeleteOutboundEmail(outboundEmailId: number): AxiosPromise<ApiResult> {
        return ResponseResolver<ApiResult>(Axios.delete(`${this.config.apiPath}/Clients/UnDeleteOutboundEmail/${outboundEmailId}`))
    }

    public getActionPlanChecklist(clientId: number): AxiosPromise<ActionPlanChecklistLookup> {
        return ResponseResolver<ActionPlanChecklistLookup>(Axios.get(`${this.config.apiPath}/Clients/GetActionPlanChecklist/${clientId}`))
    }

    public getOutboundEmail(outboundEmailId: number): AxiosPromise<Model.PlainObject<OutboundEmailLookup>> {
        return ResponseResolver<Model.PlainObject<OutboundEmailLookup>>(Axios.get<ApiResult>(`${this.config.apiPath}/Clients/GetOutboundEmail?outboundEmailId=${outboundEmailId}`));
    }

    public saveOutboundEmail(saveCommand: SaveOutboundEmailCommand): AxiosPromise<ApiResult> {
        return ResponseResolver<ApiResult>(Axios.post(`${this.config.apiPath}/Clients/SaveOutboundEmail`, saveCommand.toJSObject()));
    }

    public getClientTestEmailsList(clientId: number): AxiosPromise<ClientTestEmailLookup[]> {
        const config = AppService.get(Types.Config)
        return ResponseResolver<ClientTestEmailLookup[]>(Axios.get(`${config.apiPath}/Clients/GetClientTestEmails/${clientId}`));
    }

    public deleteClientTestEmail(clientTestEmailId: number): AxiosPromise<any> {
        return ResponseResolver<any>(Axios.delete(`${this.config.apiPath}/Clients/DeleteClientTestEmail/${clientTestEmailId}`))
    }

    public saveClientTestEmailList(saveCommand: TestEmailsSaveCommand): AxiosPromise<any> {
        return ResponseResolver<any>(Axios.post(`${this.config.apiPath}/Clients/SaveClientTestEmailList`, saveCommand.toJSObject()))
    }

    public hideHighlyRecommended(saveCommand: HideHighlyRecommendedCommand): AxiosPromise<ApiResult> {
        return ResponseResolver<ApiResult>(Axios.post(`${this.config.apiPath}/Clients/HideHighlyRecommended`, saveCommand.toJSObject()));
    }

    public getActiveEmailProviders(): AxiosPromise<Array<Model.PlainObject<EmailProviderTypeLookup>>> {
        return ResponseResolver<Array<EmailProviderTypeLookup>>(Axios.get<ApiResult>(`${this.config.apiPath}/Clients/GetActiveEmailProviders`));
    }

    public getClientCalendlyLink(clientId: number): AxiosPromise<string> {
        return ResponseResolver<string>(Axios.get<ApiResult>(`${this.config.apiPath}/Clients/GetClientCalendlyLink/${clientId}`));
    }

    public getClientCampaigns(clientId: number, isForced: boolean): AxiosPromise<ClientCampaignQueryLookup> {
        return ResponseResolver<ClientCampaignQueryLookup>(Axios.get<ApiResult>(`${this.config.apiPath}/Clients/GetClientCampaigns/${clientId}?isForced=${isForced}`));
    }

    public getArchivedMonthlyClientCampaigns(clientId: number, status: string): AxiosPromise<ArchivedClientCampaignsQueryLookup> {
        return ResponseResolver<ArchivedClientCampaignsQueryLookup>(Axios.get<ApiResult>(`${this.config.apiPath}/Clients/getArchivedMonthlyClientCampaigns/${clientId}?status=${status}`))
    }

    public saveRefreshTime(command: SaveRefreshTimeCommand): AxiosPromise<ApiResult> {
        return ResponseResolver<ApiResult>(Axios.post(`${this.config.apiPath}/Clients/SaveRefreshTime`, command.toJSObject()));
    }

    public IsRefreshAllowed(clientId: number): AxiosPromise<ApiResult> {
        return (Axios.get(`${this.config.apiPath}/Clients/IsRefreshAllowed/${clientId}`));
    }

    public RequestFullAnalysis(clientId: number): AxiosPromise<any> {
        return (Axios.get(`${this.config.apiPath}/Clients/RequestFullAnalysis/${clientId}`));
    }

    public markTooltipAsSeen(command: MarkTooltipAsSeenCommand): AxiosPromise<ApiResult> {
        return ResponseResolver<ApiResult>(Axios.post(`${this.config.apiPath}/Clients/MarkTooltipAsSeen`, command.toJSObject()));
    }

    public getLastRefreshTimestamp(clientId: number): AxiosPromise<ApiResult> {
        return (Axios.get(`${this.config.apiPath}/Clients/GetLastRefreshTimestamp/${clientId}`));
    }

    public getClientCampaignsStatuses(clientId: number): AxiosPromise<ApiResult> {
        return (Axios.get(`${this.config.apiPath}/Clients/GetClientCampaignFilterStatuses/${clientId}`));
    }

    public getLineChartReportingData(clientId: number, status: string): AxiosPromise<DailyCampaignMessageReportingDataLookup> {
        return ResponseResolver<DailyCampaignMessageReportingDataLookup>(Axios.get<ApiResult>(`${this.config.apiPath}/Clients/GetLineChartReportingData/${clientId}?status=${status}`))
    }

    public getPieChartReportingData(clientId: number): AxiosPromise<PieChartReportLookup> {
        return ResponseResolver<PieChartReportLookup>(Axios.get<ApiResult>(`${this.config.apiPath}/Clients/GetPieChartReportingData/${clientId}`))
    }

    public setSeenFirstCampaign(clientId: number): AxiosPromise<ApiResult> {
        return (Axios.post(`${this.config.apiPath}/Clients/SetSeenFirstCampaign/${clientId}`))
    }

    public getClients(): AxiosPromise<Array<Model.PlainObject<ClientListLookup>>> {
        return ResponseResolver<Array<Model.PlainObject<ClientListLookup>>>(Axios.get(`${this.config.apiPath}/Clients/GetClients`));
    }

    public getTermsOfServiceData(clientId: number): AxiosPromise<TermsOfServiceLookup> {
        return ResponseResolver<TermsOfServiceLookup>(Axios.get(`${this.config.apiPath}/Clients/GetTermsOfServiceData?clientId=${clientId}`));
    }

    public markDashboardNewsAsSeen(): AxiosPromise<ApiResult> {
        return ResponseResolver<ApiResult>(Axios.post(`${this.config.apiPath}/Clients/MarkDashboardNewsAsSeen`))
    }
}