import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class CommandResult extends ModelBase {

    public success : boolean = false;

    public error : string = "";

    @Attributes.Integer()
    public id : number = 0;

    // Client only properties / methods
}