import { NeoModel, ValueObject } from "@singularsystems/neo-core";

@NeoModel
export default class BatchReviewProspectsGridCriteria extends ValueObject
{
    public batchReviewUploadId: number = 0;
    
    public batchReviewAccountId : number = 0;

    public searchString: string = ""; 

    public isOnEntry: boolean = true; 

    public clientId: number = 0; 
}
