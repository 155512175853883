import React from 'react';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import { YesDropDown, tristateDropDown } from '../../../Models/TargetMarketAccounts/Query/TargetMarketAccountFilterList';
import { Neo } from '@singularsystems/neo-react';
import TargetMarketAccountsVM, { targetMarketAccountTabs } from '../TargetMarketAccountsVM';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import { isNumber } from 'lodash';
import * as Icon from 'react-feather';

export const helperConst = {
    emptyText: "No accounts found. Please try a different section or change search/filter",
    searchString: "searchString",
    idProperty: "uniqueId",
    initialPageSize: 100,
    gridStyle: { minHeight: 600, maxWidth: 2000 },
    TodoTab: "List of all accounts that will be prospected in your target market",
    ActiveTab: "List of accounts that are either still in a campaign or have responded. Please select if you would like to blacklist (never do again) or re-prospect (set to to-do again) accounts.",
    BlacklistedTab: "List of all accounts that are blacklisted and will not be prospected.",
    OtherSystemTab: "List of accounts where data collection is in progress or where data collection was unsuccessful (no valid domain or emails/ no prospects found/ duplicate etc.)",
    AllTab: "List of all accounts in your target market",
    RemovedTab: "Accounts in your target market that you have removed, or were previously prospected and removed because of a target market change.",
}

const inListFilter = {
    filterOperator: "inlist",
    filterType: "inlistFilerType"
}

const textFilter = {
    filterOperator: "Contains",
    filterType: "textFreeFilter"
}

const filterTargetMarketStatuses = {
    key: "filterTargetMarketStatuses",
    label: "targetMarketAccountStatusName",
    id: "targetMarketAccountStatusName",
    isMultiple: true,
}
const whiteListFilterList = {
    key: "filteredWhiteListFilterList",
    label: "filterWhiteListName",
    id: "filterWhiteListName",
    isMultiple: true,
}

const filteredIndustries = {
    key: "filteredIndustries",
    label: "subIndustryName",
    id: "subIndustryName",
    isMultiple: true,
}
const filteredEmployeeSize = {
    key: "filteredEmployeeSize",
    label: "employeeSizeName",
    id: "employeeSizeName",
    isMultiple: true,
}

const filteredProspectedDate = {
    key: "filteredProspectedDate",
    label: "dateLastProspected",
    id: "dateLastProspectedId",
    isMultiple: true,
}

const filteredProspectedTimes = {
    key: "filteredProspectedTimes",
    label: "prospectedTimes",
    id: "prospectedTimesId",
    isMultiple: true,
}

const TargetMarketAccountGridColumn = {
    targetMarketAccountId: {
        name: "targetMarketAccountId",
        display: "targetMarketAccountId",
        filter: inListFilter
    },
    targetMarketAccountStatus: {
        name: "targetMarketAccountStatus",
        display: "STATUS",
        filter: inListFilter
    },
    originalName: {
        name: "originalName",
        display: "COMPANY NAME",
        filter: textFilter
    },
    cleanCompanyName: {
        name: "fixedName",
        display: "CLEAN COMPANY NAME",
        filter: textFilter
    },
    subIndustry: {
        name: "subIndustry",
        display: "INDUSTRY",
        filter: inListFilter
    },
    companySize: {
        name: "companySize",
        display: "COMPANY SIZE",
        filter: inListFilter
    },
    location: {
        name: "location",
        display: "LOCATION",
        filter: textFilter
    },
    domain: {
        name: "domain",
        display: "DOMAIN",
        filter: textFilter
    },
    dateLastProspected: {
        name: "dateLastProspected",
        display: "PROSPECT DATE",
        filter: inListFilter
    },
    priority: {
        name: "priority",
        display: "PRIORITY",
        filter: inListFilter
    },
    edit: {
        name: "edit",
        display: "EDIT"
    },
    blacklist: {
        name: "blacklist",
        display: "BLACKLIST"
    },
    deleted: {
        name: "deleted",
        display: "REMOVE"
    },
    reprospect: {
        name: "reprospect",
        display: "REPROSPECT"
    },
    timesProspected: {
        name: "timesProspected",
        display: "TIMES PROSPECTED",
        filter: inListFilter
    },
    whitelistImport: {
        name: "whitelistImport",
        display: "WHITELIST IMPORT",
        filter: inListFilter
    }
}


export const CreateComxUserColumns = (viewModel: TargetMarketAccountsVM, tabName: targetMarketAccountTabs) => {
    return [
        {
            defaultLocked: true,
            name: TargetMarketAccountGridColumn.targetMarketAccountId.name,
            header: TargetMarketAccountGridColumn.targetMarketAccountId.display,
            defaultVisible: false,
            render: ({ value, data }: any) => (
                <div>
                    {data.targetMarketAccountId !== 0 ? data.targetMarketAccountId : data.masterAccountId !== 0 ? data.masterAccountId : data.clientAccountId}
                </div>
            ),
        },
        {
            defaultLocked: 'start',
            name: TargetMarketAccountGridColumn.targetMarketAccountStatus.name,
            header: TargetMarketAccountGridColumn.targetMarketAccountStatus.display,
            isFilter: true,
            filterKey: filterTargetMarketStatuses,
            sortable: true,
            minWidth: 180,
        },
        {
            defaultLocked: 'start',
            name: TargetMarketAccountGridColumn.originalName.name,
            header: TargetMarketAccountGridColumn.originalName.display,
            defaultFlex: 1,
            minWidth: 250,
            sortable: true
        },
        {
            name: TargetMarketAccountGridColumn.cleanCompanyName.name,
            header: TargetMarketAccountGridColumn.cleanCompanyName.display,
            minWidth: 180,
            defaultFlex: 1,
            sortable: true
        },
        {
            name: TargetMarketAccountGridColumn.subIndustry.name,
            header: TargetMarketAccountGridColumn.subIndustry.display,
            isFilter: true,
            filterKey: filteredIndustries,
            sortable: true,
            minWidth: 180,
        },

        {
            name: TargetMarketAccountGridColumn.companySize.name,
            header: TargetMarketAccountGridColumn.companySize.display,
            isFilter: true,
            filterKey: filteredEmployeeSize,
            sortable: true,
            minWidth: 180,
        },

        {
            name: TargetMarketAccountGridColumn.location.name,
            header: TargetMarketAccountGridColumn.location.display,
            defaultFlex: 1,
            sortable: true,
            minWidth: 180,
        },
        {
            name: TargetMarketAccountGridColumn.domain.name,
            header: TargetMarketAccountGridColumn.domain.display,
            defaultFlex: 1,
            sortable: true,
            minWidth: 180,
        },
        {
            name: TargetMarketAccountGridColumn.dateLastProspected.name,
            header: TargetMarketAccountGridColumn.dateLastProspected.display,
            defaultFlex: 1,
            isFilter: true,
            filterKey: filteredProspectedDate,
            sortable: true,
            minWidth: 180,

        },
        {
            name: TargetMarketAccountGridColumn.timesProspected.name,
            header: TargetMarketAccountGridColumn.timesProspected.display,
            defaultFlex: 1,
            isFilter: true,
            filterKey: filteredProspectedTimes,
            sortable: true,
            minWidth: 180,
        },
        {
            name: TargetMarketAccountGridColumn.priority.name,
            header: TargetMarketAccountGridColumn.priority.display,
            filterEditor: SelectFilter,
            defaultFlex: 0.5,
            minWidth: 100,
            isExternalFilter: true,
            filterKey: { isMultiple: false },
            filterData: YesDropDown,
            sortable: true,
            render: ({ value }: any) => (
                <div className="text-center">
                    {value && <span><Icon.CheckCircle /></span>}
                    {!value && <span className="" />}
                </div>
            )
        },
        {
            name: TargetMarketAccountGridColumn.edit.name,
            header: TargetMarketAccountGridColumn.edit.display,
            defaultFlex: 0.5,
            sortable: false,
            defaultLocked: 'end',
            minWidth: 80,
            render: ({ value, data }: any) => (
                <div className="text-center">
                    <Neo.Button variant="light" className="text-center gridButton customGridButtonPadding" icon={"edit"}
                        onClick={() => viewModel.setupEditModal(data)} />
                </div>
            )
        },
        {
            name: TargetMarketAccountGridColumn.blacklist.name,
            header: tabName === "Blacklisted" ? "Un-Blacklist" : TargetMarketAccountGridColumn.blacklist.display,
            defaultFlex: 0.5,
            sortable: false,
            defaultLocked: 'end',
            minWidth: 80,
            defaultVisible: ["Active", "Blacklisted"].filter(x => x === tabName).length >= 1,
            render: ({ value, data }: any) => (
                <div className="text-center">
                    {viewModel.criteria.tabName === "Active" &&
                        <Neo.Button variant="light" className="text-center gridButton blacklistButton customGridButtonPadding" icon={"ban"}
                            onClick={() => viewModel.BlacklistDomain(data)} />}

                    {viewModel.criteria.tabName === "Blacklisted" &&
                        <Neo.Button variant="light" className="text-center gridButton unblacklistButton customGridButtonPadding" icon={"redo"}
                            onClick={() => viewModel.UnblacklistDomain(data)} />}
                </div>
            )
        },
        {
            name: TargetMarketAccountGridColumn.reprospect.name,
            header: TargetMarketAccountGridColumn.reprospect.display,
            defaultFlex: 0.5,
            sortable: false,
            defaultLocked: 'end',
            minWidth: 80,
            defaultVisible: ["Active", "Other & System"].filter(x => x === tabName).length >= 1,
            render: ({ value, data }: any) => (

                <div className="text-center">
                    {
                        <Neo.Button variant="light" className="text-center  gridButton customGridButtonPadding" icon={"retweet"}
                            onClick={() => viewModel.reprosepectTargetMarketAccount(data)} />
                    }
                </div>
            )
        },
        {
            name: TargetMarketAccountGridColumn.deleted.name,
            header: tabName === "Removed" ? "Un-Remove" : TargetMarketAccountGridColumn.deleted.display,
            defaultFlex: 0.5,
            sortable: false,
            defaultLocked: 'end',
            minWidth: 80,
            defaultVisible: ["To-do", "Other & System", "All", "Removed"].filter(x => x === tabName).length >= 1,
            render: ({ value, data }: any) => (

                <div className="text-center">
                    {/* Un-delete button */}
                    {value &&
                        <Neo.Button variant="light"
                            className="text-center circled"
                            icon={<Icon.RotateCw />}
                            onClick={() => viewModel.undeleteAccount(data)}>

                        </Neo.Button>}

                    {/* Delete button */}
                    {!value &&
                        <Neo.Button variant="danger" className="text-center gridButton" icon={<Icon.Archive />}
                            onClick={() => viewModel.deleteAccount(data)} />
                    }
                </div>
            )
        },
        {
            name: TargetMarketAccountGridColumn.whitelistImport.name,
            header: TargetMarketAccountGridColumn.whitelistImport.display,
            defaultFlex: 1,
            isFilter: true,
            filterKey: whiteListFilterList,
            sortable: true,
            minWidth: 180,
        },
    ]
}
// 
export const CreateClientUserColumns = (viewModel: TargetMarketAccountsVM, tabName: targetMarketAccountTabs) => {
    return [
        {
            defaultLocked: true,
            name: TargetMarketAccountGridColumn.targetMarketAccountId.name,
            header: TargetMarketAccountGridColumn.targetMarketAccountId.display,
            defaultVisible: false,
        },
        {
            defaultLocked: 'start',
            name: TargetMarketAccountGridColumn.targetMarketAccountStatus.name,
            header: TargetMarketAccountGridColumn.targetMarketAccountStatus.display,
            isFilter: true,
            filterKey: filterTargetMarketStatuses,
            sortable: true,
            minWidth: 180,
        },
        {
            defaultLocked: 'start',
            name: TargetMarketAccountGridColumn.originalName.name,
            header: TargetMarketAccountGridColumn.originalName.display,
            defaultFlex: 1,
            sortable: true,
            minWidth: 250,
        },
        {
            name: TargetMarketAccountGridColumn.cleanCompanyName.name,
            header: TargetMarketAccountGridColumn.cleanCompanyName.display,
            minWidth: 180,
            defaultFlex: 2,
            sortable: true
        },
        {
            name: TargetMarketAccountGridColumn.subIndustry.name,
            header: TargetMarketAccountGridColumn.subIndustry.display,
            isFilter: true,
            filterKey: filteredIndustries,
            sortable: true,
            minWidth: 180,
        },

        {
            name: TargetMarketAccountGridColumn.companySize.name,
            header: TargetMarketAccountGridColumn.companySize.display,
            defaultFlex: 1,
            isFilter: true,
            filterKey: filteredEmployeeSize,
            sortable: true,
            minWidth: 180,
        },

        {
            name: TargetMarketAccountGridColumn.location.name,
            header: TargetMarketAccountGridColumn.location.display,
            defaultFlex: 1,
            sortable: true,
            minWidth: 180,
        },
        {
            name: TargetMarketAccountGridColumn.domain.name,
            header: TargetMarketAccountGridColumn.domain.display,
            defaultFlex: 1,
            sortable: true,
            minWidth: 180,
        },
        {
            name: TargetMarketAccountGridColumn.dateLastProspected.name,
            header: TargetMarketAccountGridColumn.dateLastProspected.display,
            defaultFlex: 1,
            isFilter: true,
            filterKey: filteredProspectedDate,
            sortable: true,
            minWidth: 180,
        },

        {
            name: TargetMarketAccountGridColumn.priority.name,
            header: TargetMarketAccountGridColumn.priority.display,
            filterEditor: SelectFilter,
            minWidth: 100,
            isExternalFilter: true,
            filterKey: { isMultiple: false },
            filterData: YesDropDown,
            sortable: true,
            render: ({ value }: any) => (

                <div className="text-center">
                    {value && <span><Icon.CheckCircle /></span>}
                    {!value && <span className="" />}
                </div>
            )
        },
        {
            name: TargetMarketAccountGridColumn.blacklist.name,
            header: tabName === "Blacklisted" ? "Un-Blacklist" : TargetMarketAccountGridColumn.blacklist.display,
            defaultFlex: 0.5,
            defaultLocked: 'end',
            sortable: false,
            defaultVisible: ["Active", "Blacklisted"].filter(x => x === tabName).length >= 1,
            minWidth: 80,
            render: ({ value, data }: any) => (
                <div className="text-center">
                    {viewModel.criteria.tabName === "Active" &&
                        <Neo.Button variant="light" className="text-center gridButton blacklistButton customGridButtonPadding" icon={"ban"}
                            onClick={() => viewModel.BlacklistDomain(data)} />}

                    {viewModel.criteria.tabName === "Blacklisted" &&
                        <Neo.Button variant="light" className="text-center gridButton unblacklistButton customGridButtonPadding" icon={"redo"}
                            onClick={() => viewModel.UnblacklistDomain(data)} />}
                </div>
            )
        },
        {
            name: TargetMarketAccountGridColumn.reprospect.name,
            header: TargetMarketAccountGridColumn.reprospect.display,
            defaultFlex: 0.5,
            sortable: false,
            defaultLocked: 'end',
            defaultVisible: ["Active"].filter(x => x === tabName).length >= 1,
            minWidth: 80,
            render: ({ value, data }: any) => (

                <div className="text-center">
                    {
                        <Neo.Button variant="light" className="text-center gridButton customGridButtonPadding" icon={"retweet"}
                            onClick={() => viewModel.reprosepectTargetMarketAccount(data)} />
                    }
                </div>
            )
        },
        {
            name: TargetMarketAccountGridColumn.deleted.name,
            header: tabName === "Removed" ? "Un-Remove" : TargetMarketAccountGridColumn.deleted.display,
            defaultFlex: 0.5,
            defaultLocked: 'end',
            filterEditor: SelectFilter,
            sortable: false,
            defaultVisible: ["To-do", "Other & System", "All", "Removed"].filter(x => x === tabName).length >= 1,
            minWidth: 80,
            filterEditorProps: {
                multiple: true,
                dataSource: tristateDropDown.map((c: any) => {
                    return { id: c.id, label: c.name }
                }),
            },
            render: ({ value, data }: any) => (

                <div className="text-center">
                    {/* Un-delete button */}
                    {value &&
                        <Neo.Button variant="light" className="text-center buttonHover circled"
                            onClick={() => viewModel.undeleteAccount(data)}
                            icon={<Icon.RotateCw />}>
                        </Neo.Button>}

                    {/* Delete button */}
                    {!value &&
                        <Neo.Button variant="danger" className="text-center gridButton" icon={<Icon.Archive />}
                            onClick={() => viewModel.deleteAccount(data)} />
                    }
                </div>
            )
        },
    ]
}

export const TargetMarketFilterColumns = () => {
    return [
        {
            name: TargetMarketAccountGridColumn.targetMarketAccountStatus.name,
            operator: TargetMarketAccountGridColumn.targetMarketAccountStatus.filter.filterOperator,
            type: TargetMarketAccountGridColumn.targetMarketAccountStatus.filter.filterType,
            value: null,
        },
        {
            name: TargetMarketAccountGridColumn.originalName.name,
            operator: TargetMarketAccountGridColumn.originalName.filter.filterOperator,
            type: TargetMarketAccountGridColumn.originalName.filter.filterType,
            value: ''
        },
        {
            name: TargetMarketAccountGridColumn.cleanCompanyName.name,
            operator: TargetMarketAccountGridColumn.cleanCompanyName.filter.filterOperator,
            type: TargetMarketAccountGridColumn.cleanCompanyName.filter.filterType,
            value: ''
        },

        {
            name: TargetMarketAccountGridColumn.companySize.name,
            operator: TargetMarketAccountGridColumn.companySize.filter.filterOperator,
            type: TargetMarketAccountGridColumn.companySize.filter.filterType,
            value: null,
        },
        {
            name: TargetMarketAccountGridColumn.location.name,
            operator: TargetMarketAccountGridColumn.location.filter.filterOperator,
            type: TargetMarketAccountGridColumn.location.filter.filterType,
            value: ''
        },
        {
            name: TargetMarketAccountGridColumn.domain.name,
            operator: TargetMarketAccountGridColumn.domain.filter.filterOperator,
            type: TargetMarketAccountGridColumn.domain.filter.filterType,
            value: ''
        },
        {
            name: TargetMarketAccountGridColumn.subIndustry.name,
            operator: TargetMarketAccountGridColumn.subIndustry.filter.filterOperator,
            type: TargetMarketAccountGridColumn.subIndustry.filter.filterType,
            value: null,
        },
        {
            name: TargetMarketAccountGridColumn.priority.name,
            operator: TargetMarketAccountGridColumn.priority.filter.filterOperator,
            type: TargetMarketAccountGridColumn.priority.filter.filterType,
            value: null,
        },
        {
            name: TargetMarketAccountGridColumn.dateLastProspected.name,
            operator: TargetMarketAccountGridColumn.dateLastProspected.filter.filterOperator,
            type: TargetMarketAccountGridColumn.dateLastProspected.filter.filterType,
            value: null,
        },
        {
            name: TargetMarketAccountGridColumn.timesProspected.name,
            operator: TargetMarketAccountGridColumn.timesProspected.filter.filterOperator,
            type: TargetMarketAccountGridColumn.timesProspected.filter.filterType,
            value: null
        },
        {
            name: TargetMarketAccountGridColumn.whitelistImport.name,
            operator: TargetMarketAccountGridColumn.whitelistImport.filter.filterOperator,
            type: TargetMarketAccountGridColumn.whitelistImport.filter.filterType,
            value: null,
        },
    ];
}

export const TargetMarketfilterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
    textFreeFilter: {
        type: 'string',
        emptyValue: null,
        operators: [
            {
                name: 'Contains',
                fn: ({ value, filterValue, data }: any) => {
                    return filterValue !== null ? filterValue === value : true;
                }
            },
            {
                name: 'Does not contain',
                fn: ({ value, filterValue, data }: any) => {
                    return filterValue !== null ? filterValue !== value : true;
                }
            }
        ]
    },
    numberFilter: {
        type: 'number',
        emptyValue: null,
        operators: [
            {
                name: 'eq',
                fn: ({ value, filterValue, data }: any) => {
                    if (isNumber(filterValue)) {
                        return filterValue !== null ? filterValue === value : true;
                    }
                    else {
                        return true
                    }
                }
            }
        ]
    },
    inlistFilerType: {
        type: 'inlistFilerType',
        emptyValue: null,
        operators: [
            {
                name: 'inlist',
                fn: ({ value, filterValue, data }: any) => {
                    return filterValue !== null ? filterValue === value : true;
                }
            },
            {
                name: 'notinlist',
                fn: ({ value, filterValue, data }: any) => {
                    return filterValue !== null ? filterValue !== value : true;
                }
            }
        ]
    }
});
