import { AppServices, Numeric, Security } from '@singularsystems/neo-core';
import Types from './AppTypes';

import Axios from 'axios';
import AuthorisationService from '../Authorisation/Services/AuthorisationService';
import { AppConfig } from '../AppConfig';
import { OidcAuthService } from '../Services/AuthenticationService';
import { AppDataService } from '../Services/AppDataService';
import { ComXApiClient } from '../ApiClients/ComXApiClient';
import CountriesApiClient from '../ApiClients/CountriesApiClient';
import IndustriesApiClient from '../ApiClients/IndustriesApiClient';
import SubIndustriesApiClient from '../ApiClients/SubIndustriesApiClient';
import IndustrySubIndustriesApiClient from '../ApiClients/IndustrySubIndustriesApiClient';
import AccountStatusesApiClient from '../ApiClients/AccountStatusesApiClient';
import EmployeeSizesApiClient from '../ApiClients/EmployeeSizesApiClient';
import FunctionTypesApiClient from '../ApiClients/FunctionTypesApiClient';
import MasterAccountStatusesApiClient from '../ApiClients/MasterAccountStatusesApiClient';
import ReProspectingStatusesApiClient from '../ApiClients/ReProspectingStatusesApiClient';
import RolesApiClient from '../ApiClients/RolesApiClient';
import SeniorityLevelsApiClient from '../ApiClients/SeniorityLevelsApiClient';
import SourceTypesApiClient from '../ApiClients/SourceTypesApiClient';
import TargetMarketAccountStatusesApiClient from '../ApiClients/TargetMarketAccountStatusesApiClient';
import TargetMarketFunctionsApiClient from '../ApiClients/TargetMarketFunctionsApiClient';
import TargetMarketStatusesApiClient from '../ApiClients/TargetMarketStatusesApiClient';
import TargetMarketTypesApiClient from '../ApiClients/TargetMarketTypesApiClient';
import ClientsApiClient from '../ApiClients/ClientsApiClient';
import InvitedUsersApiClient from './../ApiClients/InvitedUsersApiClient';
import MasterAccountsApiClient from '../ApiClients/MasterAccountsApiClient';
import AuthorisationTypes from '../Authorisation/AuthorisationTypes';
import TargetMarketAccountsApiClient from '../ApiClients/TargetMarketAccountApiClient';
import TargetMarketsApiClient from '../ApiClients/TargetMarketsApiClient';
import ProspectingApiClient from '../ApiClients/ProspectingApiClient';
import ManagementFunctionsApiClient from '../ApiClients/ManagementFunctionsApiClient';
import LanguagesApiClient from '../ApiClients/LanguagesApiClient';
import ManagementRolesApiClient from '../ApiClients/ManagementRolesApiClient';
import IROEditStatusApiClient from './../ApiClients/IROEditStatusApiClient';
import ExcludedKeywordsApiClient from '../ApiClients/ExcludedKeywordsApiClient';
import BlacklistedDomainsApiClient from '../ApiClients/BlacklistedDomainsApiClient';
import GreyListedProspectApiClient from '../ApiClients/GreyListedProspectApiClient';
import SubRegionApiClient from '../ApiClients/SubRegionApiClient';
import RegionApiClient from '../ApiClients/RegionApiClient';
import ScrapingApiClient from '../ApiClients/ScrapingApiClient';
import PlatformsApiClient from '../ApiClients/PlatformsApiClient';
import TargetMarketAccountTabsApiClient from '../ApiClients/TargetMarketAccountTabsApiClient';
import TargetMarketAccountTabStatusesApiClient from '../ApiClients/TargetMarketAccountTabStatusesApiClient';
import ErrorHandler from '../Components/ErrorHandler';
import ComXConfigurationsApiClient from '../ApiClients/ComXConfigurationsApiClient';
import InformationManagementApiClient from '../ApiClients/InformationManagementApiClient';
import CampaignMessagesApiClient from '../ApiClients/CampaignMessagesApiClient';
import CampaignMessageStatusesApiClient from '../ApiClients/CampaignMessageStatusesApiClient';
import CampaignMessageTypesApiClient from '../ApiClients/CampaignMessageTypesApiClient';
import BatchReviewApiClient from '../ApiClients/BatchReviewApiClient';
import { ComXHub } from '../ApiClients/Hubs/ComXHub';
import CustomerProfilesApiClient from '../ApiClients/CustomerProfilesApiClient';
import CustomerProfileStatusesApiClient from '../ApiClients/CustomerProfileStatusesApiClient';
import BuyingCentersApiClient from '../ApiClients/BuyingCentersApiClient';
import { RouteSecurityService } from '../Services/RouteSecurityService';
import HealthCheckApiClient from '../ApiClients/HealthCheckApiClient';
import OnboardingApiClient from '../ApiClients/OnboardingApiClient';
import TechnicalSettingsConfigApiClient from '../ApiClients/TechnicalSettingsConfigApiClient';
import EmailProviderTypesApiClient from '../ApiClients/EmailProviderTypesApiClient';
import CustomerProfileQuestionsApiClient from '../ApiClients/CustomerProfileQuestionsApiClient';
import CalendlyLinksApiClient from '../ApiClients/CalendlyLinksApiClient';
import DashboardCardsApiClient from '../ApiClients/DashboardCardsApiClient';
import DashboardTooltipApiClient from '../ApiClients/DashboardTooltipsApiClient';
import DashboardTooltipTypeApiClient from '../ApiClients/DashboardTooltipTypeApiClient';
import InvalidReasonsApiClient from '../ApiClients/InvalidReasonsApiClient';
import GlobalSettings from './GlobalSettings';
import FollowUpTemplateCategoriesApiClient from '../ApiClients/FollowUpTemplateCategoriesApiClient';
import { LoginErrorHandler } from '../Views/Security/LoginErrorHandler';
import DashboardReportConfigApiClient from '../ApiClients/DashboardReportConfigApiClient';
import BackgroundJobTypeApiClient from '../ApiClients/BackgroundJobTypeApiClient';
import OnboardingMaintenanceApiClient from '../ApiClients/OnboardingMaintenanceApiClient';
import HelpCenterTextsApiClient from 'ApiClients/HelpCenterTextsApiClient';
import SupportLinksApiClient from 'ApiClients/SupportLinksApiClient';
import OnboardingStepsApiClient from 'ApiClients/OnboardingStepsApiClient';
import OnboardingStepIntroductionApiClient from 'ApiClients/OnboardingStepIntroductionApiClient';
import ActionListApiClient from 'ApiClients/ActionListApiClient';
import LeadTypesApiClient from 'ApiClients/LeadTypesApiClient';
import LeadTypeStatusesApiClient from 'ApiClients/LeadTypeStatusesApiClient';
import ActionListStatusesApiClient from 'ApiClients/ActionListStatusesApiClient';
import NavigationStepsApiClient from 'ApiClients/NavigationStepsApiClient';
import IndustriesVersionApiClient from 'ApiClients/IndustriesVersionApiClient';
import DemoClientsApiClient from 'ApiClients/DemoClientsApiClient';
import IROIndustryMappingApiClient from 'ApiClients/IROIndustryMappingApiClient';
import CampaignMessageMaintenaceApiClient from 'ApiClients/CampaignMessageMaintenanceApiClient';
import ActionListConfigurationsApiClient from 'ApiClients/ActionListConfigurationsApiClient';
import Decimal from "decimal.js-light";
import ReportingApiClient from 'ApiClients/ReportingApiClient';
import AsyncSelect from 'react-select/async'
import Slider, { Range } from 'rc-slider';
import Select from 'react-select';

const appModule = new AppServices.Module("App", container => {

    // Config
    container.bind(Types.Config).to(AppConfig).inSingletonScope();
    container.bind(AuthorisationTypes.Config.ConfigModel).toService(Types.Config.symbol);

    // Security
    container.bind(Types.Neo.Security.AuthenticationService).to(OidcAuthService).inSingletonScope();
    container.bind(Types.Neo.Security.AuthorisationService).to(AuthorisationService).inSingletonScope();
    container.bind(Types.Neo.Security.SecurityService).to(Security.SecurityService).inSingletonScope();

    // Api clients
    container.bind(Types.Neo.Axios).toConstantValue(Axios);
    container.bind(Types.ApiClients.AccountStatusesApiClient).to(AccountStatusesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ComXApiClient).to(ComXApiClient).inSingletonScope();
    container.bind(Types.ApiClients.CountriesApiClient).to(CountriesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.EmployeeSizesApiClient).to(EmployeeSizesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.FunctionTypesApiClient).to(FunctionTypesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.IndustriesApiClient).to(IndustriesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.SubIndustriesApiClient).to(SubIndustriesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.IndustrySubIndustriesApiClient).to(IndustrySubIndustriesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.MasterAccountStatusesApiClient).to(MasterAccountStatusesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ReProspectingStatusesApiClient).to(ReProspectingStatusesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.RolesApiClient).to(RolesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.SeniorityLevelsApiClient).to(SeniorityLevelsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.SourceTypesApiClient).to(SourceTypesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.TargetMarketAccountStatusesApiClient).to(TargetMarketAccountStatusesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.TargetMarketFunctionsApiClient).to(TargetMarketFunctionsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.TargetMarketStatusesApiClient).to(TargetMarketStatusesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.TargetMarketTypesApiClient).to(TargetMarketTypesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ClientsApiClient).to(ClientsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.InvitedUsersApiClient).to(InvitedUsersApiClient).inSingletonScope();
    container.bind(Types.ApiClients.MasterAccountsApiClient).to(MasterAccountsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.TargetMarketsApiClient).to(TargetMarketsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.TargetMarketAccountsApiClient).to(TargetMarketAccountsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ProspectingApiClient).to(ProspectingApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ManagementFunctionsApiClient).to(ManagementFunctionsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ManagementRolesApiClient).to(ManagementRolesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.LanguagesApiClient).to(LanguagesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.IROEditStatusApiClient).to(IROEditStatusApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ExcludedKeywordsApiClient).to(ExcludedKeywordsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.BlacklistedDomainsApiClient).to(BlacklistedDomainsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.GreyListedProspectApiClient).to(GreyListedProspectApiClient).inSingletonScope();
    container.bind(Types.ApiClients.SubRegionApiClient).to(SubRegionApiClient).inSingletonScope();
    container.bind(Types.ApiClients.RegionApiClient).to(RegionApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ScrapingApiClient).to(ScrapingApiClient).inSingletonScope();
    container.bind(Types.ApiClients.PlatformsApiClient).to(PlatformsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.TargetMarketAccountTabsApiClient).to(TargetMarketAccountTabsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.TargetMarketAccountTabStatusesApiClient).to(TargetMarketAccountTabStatusesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ComXConfigurationsApiClient).to(ComXConfigurationsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.DashboardReportConfigApiClient).to(DashboardReportConfigApiClient).inSingletonScope();
    container.bind(Types.ApiClients.BackgroundJobTypeApiClient).to(BackgroundJobTypeApiClient).inSingletonScope();
    container.bind(Types.ApiClients.InformationManagementApiClient).to(InformationManagementApiClient).inSingletonScope();
    container.bind(Types.ApiClients.CampaignMessagesApiClient).to(CampaignMessagesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.CampaignMessageStatusesApiClient).to(CampaignMessageStatusesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.EmailSignatureTemplateApiClient).to(CampaignMessageMaintenaceApiClient).inSingletonScope();
    container.bind(Types.ApiClients.CampaignMessageTypesApiClient).to(CampaignMessageTypesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.BatchReviewApiClient).to(BatchReviewApiClient).inSingletonScope();
    container.bind(Types.ApiClients.CustomerProfilesApiClient).to(CustomerProfilesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.CustomerProfileStatusesApiClient).to(CustomerProfileStatusesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.BuyingCentersApiClient).to(BuyingCentersApiClient).inSingletonScope();
    container.bind(Types.ApiClients.HealthCheckApiClient).to(HealthCheckApiClient).inSingletonScope();
    container.bind(Types.ApiClients.OnboardingApiClient).to(OnboardingApiClient).inSingletonScope();
    container.bind(Types.ApiClients.TechnicalSettingsConfigApiClient).to(TechnicalSettingsConfigApiClient).inSingletonScope();
    container.bind(Types.ApiClients.EmailProviderTypesApiClient).to(EmailProviderTypesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.CustomerProfileQuestionsApiClient).to(CustomerProfileQuestionsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.CalendlyLinksApiClient).to(CalendlyLinksApiClient).inSingletonScope();
    container.bind(Types.ApiClients.DashboardCardsApiClient).to(DashboardCardsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.DashboardTooltipApiClient).to(DashboardTooltipApiClient).inSingletonScope();
    container.bind(Types.ApiClients.DashboardTooltipTypeApiClient).to(DashboardTooltipTypeApiClient).inSingletonScope();
    container.bind(Types.ApiClients.InvalidReasonsApiClient).to(InvalidReasonsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.LeadTypesApiClient).to(LeadTypesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.LeadTypeStatusesApiClient).to(LeadTypeStatusesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ActionListStatusesApiClient).to(ActionListStatusesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.FollowUpTemplateCategoriesApiClient).to(FollowUpTemplateCategoriesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.OnboardingMaintenanceApiClient).to(OnboardingMaintenanceApiClient).inSingletonScope();
    container.bind(Types.ApiClients.HelpCenterTextsApiClient).to(HelpCenterTextsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.SupportLinksApiClient).to(SupportLinksApiClient).inSingletonScope();
    container.bind(Types.ApiClients.OnboardingStepIntroductionApiClient).to(OnboardingStepIntroductionApiClient).inSingletonScope();
    container.bind(Types.ApiClients.OnboardingStepsApiClient).to(OnboardingStepsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.NavigationStepsApiClient).to(NavigationStepsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.IndustriesVersionsApiClient).to(IndustriesVersionApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ActionListApiClient).to(ActionListApiClient).inSingletonScope();
    container.bind(Types.ApiClients.DemoClientsApiClient).to(DemoClientsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.IROIndustryMappingApiClient).to(IROIndustryMappingApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ActionListConfigurationsApiClient).to(ActionListConfigurationsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ReportingApiClient).to(ReportingApiClient).inSingletonScope();

    // Components
    container.bind(Types.Neo.Components.Slider).toConstantValue(Slider);
    container.bind(Types.Neo.Components.Range).toConstantValue(Range);
    container.bind(Types.Neo.Components.AsyncSelect).toConstantValue(AsyncSelect);
    container.bind(Types.Neo.Components.ReactSelect).toConstantValue(Select);

    // Data cache
    container.bind(Types.Services.AppDataCache).to(AppDataService).inSingletonScope();

    // Error Handler
    container.bind(Types.Neo.UI.ErrorHandler).to(ErrorHandler).inRequestScope();

    // Routing
    container.bind(Types.Neo.Routing.RouteSecurityService).to(RouteSecurityService).inSingletonScope();

    // SignalR hubs
    container.bind(Types.ApiClients.ComXHub).to(ComXHub).inSingletonScope();

    // Login Error Handler
    container.bind(Types.Neo.Security.LoginErrorHandler).to(LoginErrorHandler).inSingletonScope();

    container.bind(Types.Neo.Core.DecimalService).toConstantValue(new Numeric.DecimalService(Decimal, Decimal.ROUND_HALF_UP, Decimal.ROUND_DOWN));

    GlobalSettings.setup();
});

const appTestModule = new AppServices.Module("App", container => {

});

export { appModule, appTestModule };