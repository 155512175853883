import React, { Component } from 'react';
import { Neo } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import * as Icon from 'react-feather';

export interface IdownloadTemplateProps {
  columnHeadings: string[];
  downloadTemplate(): void;
  onDocumentSelected(event: any): void;
}

class DowloadTemplate extends Component<IdownloadTemplateProps> {
  public render() {
    return (
      <div className='UpdateTargetMarketsView'>
        <div className='row m-0'>
          <div className='col-12'>
            <Neo.GridLayout xl={1} lg={1} md={1} sm={1} xs={1} className="m-0">
              <p className="mb-2"> {textConstants.titleText.PleaseEnsureHeaders} </p>
              <ol>
                {this.props.columnHeadings.map((i: string) => (
                  <li className="dropZoneListDisplay" key={i}>
                    {" "}
                    {i}{" "}
                  </li>
                ))}
                <a
                  className="text-right customDownloadLink"
                  onClick={this.props.downloadTemplate}
                >
                  {textConstants.generalText.DownloadTemplate}
                </a>
              </ol>
              <Neo.FileContext
                onFilesSelected={this.props.onDocumentSelected}
                allowedExtensions={[".csv"]}
              >
                <Neo.FileDropArea multiple={false}>
                  <p className="text-center dropZoneText">
                    <Neo.FileUploadButton>
                      <Icon.Upload />
                      {textConstants.generalText.uploadOrDragFile}
                    </Neo.FileUploadButton>
                  </p>
                </Neo.FileDropArea>
              </Neo.FileContext>
            </Neo.GridLayout>
          </div>
        </div>
      </div>
    );
  }
}

export default DowloadTemplate