import { textConstants } from 'common/textConstants';
import { isValidEmail } from 'common/utils';
import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';

export interface IEmailChip {
  initialEmails: string[];
  checked: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  color: 'primary' | 'secondary';
  label: string;
  [rest: string]: any;
  onAddEmail: (email: string) => void;
  onRemoveEmail: (email: string) => void;
}

const EmailChip = ({
  initialEmails,
  checked,
  onChange,
  color,
  label,
  onAddEmail,
  onRemoveEmail,
  ...rest
}: IEmailChip) => {
  const [items, setItems] = React.useState(initialEmails);
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState<string | null>('');

  const isInList = (email: string) => {
    // @ts-ignore
    return items.includes(email);
  };

  const isValid = (email: any) => {
    let error = '';

    let valid = isValidEmail(email)

    if (!valid) {
      error = `${email} is not a valid email address.`;
    }

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (error) {
      setError(error);
      return false;
    }
    return true;
  };

  const addEmail = (emailValue: string) => {

    if (emailValue) {
      let isEmailValid = false;

      // Validate whats in the brackets (if there are brackets)
      if (emailValue.includes("(") && emailValue.includes(")")) {
        let emailInBracket = emailValue.substring(emailValue.indexOf("(") + 1, emailValue.indexOf(")"))
        isEmailValid = isValid(emailInBracket)
      } else {
        isEmailValid = isValid(emailValue)
      }

      if (isEmailValid) {
        setItems([...items, emailValue]);
        setValue('');
        onAddEmail(emailValue);
      }
    }
  }

  const handleKeyDown = (evt: any) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();

      const emailValue = value.trim();

      addEmail(emailValue)
    }
  };

  const [prevPropValue] = useState(initialEmails);

  const removeAllEntries = (Function: void) => {
    items.forEach(item => { items.remove(item) })
  }

  useEffect(() => {
    if (items.length === 0 && initialEmails) {
      initialEmails?.forEach(initialEmail => {
        addEmail(initialEmail);
        items.push(initialEmail)

      })
    }
    else if (initialEmails !== prevPropValue && initialEmails) {
      removeAllEntries()

      initialEmails.forEach(initialEmail => {
        addEmail(initialEmail);
        items.push(initialEmail)
      })
    }

  }, [initialEmails, prevPropValue]);

  const handleOnUnfocus = (evt: any) => {
    evt.preventDefault();

    const emailValue = value.trim();
    addEmail(emailValue)
  };

  const handleDelete = (item: any) => {
    setItems(items.filter(i => i !== item));
    onRemoveEmail(item)
  };

  const handleChange = (evt: any) => {
    setValue(evt.target.value);
    setError(null);
  };

  const handlePaste = (evt: any) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData('text');
    paste.split(',').forEach((i: any) => {
      if (isValid(i.trim())) {
        setItems([...items, i.trim()]);
      }
    });
    addEmail(paste)
  };

  return (
    <div className="ml-3">
      <div className='row email-input'>
        {items.map(item => (
          <div key={item} className='email-pill'>
            {item}
            <button onClick={() => handleDelete(item)}>
              <Icon.X size={18} />
            </button>
          </div>
        ))}
        <input
          className='mr-1 email-input-form'
          placeholder={items.length === 0 ? textConstants.generalText.actionListLeadsEnterEmail : ""}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          onBlur={handleOnUnfocus}
        />
      </div>
      {error && <div className='validationError'>{error}</div>}
    </div>
  );
};

export default EmailChip;
