import { NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class FollowUpTemplateCriteria extends ValueObject {
    
  public clientId: number = 0

  public searchString: string = ""

  public isDeleted: boolean = false
}