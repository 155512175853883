import { List, NeoModel } from "@singularsystems/neo-core";
import { Managers, Views } from "@singularsystems/neo-react";
import { AppService, Types } from '../../../Services/AppService';
import OnboardingMaintenance from "Models/Maintenance/OnboardingMaintenance";
import { textConstants } from "common/textConstants";
import HelpCenterText from "Models/Maintenance/HelpCenterText";
import OnboardingPopupLookup from "Models/Onboarding/Queries/OnboardingPopupLookup";
import UpdatePopupDataCommand from "Models/Onboarding/Commands/UpdatePopupDataCommand";
import SupportLink from "Models/Maintenance/SupportLink";
import OnboardingStep from "Models/Maintenance/OnboardingStep";
import ComXConfiguration from "Models/Maintenance/ComXConfiguration";
import { TextType } from "./TextType";
import OnboardingIntroductionStep from "Models/Maintenance/OnboardingStepIntroduction";

@NeoModel
export default class OnboardingMaintenanceVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private onboardingMaintenanceApiClient = AppService.get(Types.ApiClients.OnboardingMaintenanceApiClient),
        private helpCenterTextsApiClient = AppService.get(Types.ApiClients.HelpCenterTextsApiClient),
        private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient),
        private supportLinksApiClient = AppService.get(Types.ApiClients.SupportLinksApiClient),
        private comXConfigurationsApiClient = AppService.get(Types.ApiClients.ComXConfigurationsApiClient),
        private onboardingStepIntroductionApiClient = AppService.get(Types.ApiClients.OnboardingStepIntroductionApiClient),
        public appDataCache = AppService.get(Types.Services.AppDataCache)
    ) {

        super(taskRunner);
    }

    // Properties
    public onboardingMaintenanceList = new List(OnboardingMaintenance);
    public helpCenterTextList = new List(HelpCenterText);
    public showEditModal: boolean = false;
    public selectedItemId: string = "";
    public selectedText: string = "";
    public onboardingPopupList = new List(OnboardingPopupLookup);
    public supportLinkList = new List(SupportLink);
    public onboardingIntroductionStepList = new List(OnboardingIntroductionStep);
    public onboardingSaveCommandList = new List(UpdatePopupDataCommand);
    public onboardingStepList = new List(OnboardingStep);
    public comXConfigurationList = new List(ComXConfiguration);
    public foundersVidConfig: ComXConfiguration | null = null;
    public isOnboardingVideoSet: boolean = false;
    public tableName: string = "";
    public rowId: number = 0;
    public textType: TextType | null = null;
    public tabManager = new Managers.TabManager(textConstants.Onboarding.IntroductoryPages);


    public async initialise() {
        // Get OnboardingMaintenance
        const onboardingMaintenanceResponse = await this.taskRunner.waitFor(this.onboardingMaintenanceApiClient.get());
        this.onboardingMaintenanceList.set(onboardingMaintenanceResponse.data);

        // Get HelpCenterTexts
        const helpCenterTextsResponse = await this.taskRunner.waitFor(this.helpCenterTextsApiClient.get());
        this.helpCenterTextList.set(helpCenterTextsResponse.data);

        /// Get OnboardingPopups
        const popupsResponse = await this.onboardingApiClient.getOnboardingPopupData();
        let data = popupsResponse.data;
        if (data.success) {
            this.onboardingPopupList.set(data.data.sortBy("popupName"));
        }

        // Get SupportLinks
        const supportLinksResponse = await this.taskRunner.waitFor(this.supportLinksApiClient.get());
        this.supportLinkList.set(supportLinksResponse.data);

        // Get onboarding step introduction
        const onboardingStepIntroductionResponse = await this.taskRunner.waitFor(this.onboardingStepIntroductionApiClient.get());
        this.onboardingIntroductionStepList.set(onboardingStepIntroductionResponse.data);

        // Get OnboardingSteps
        const onboardingStepsResponse = await this.taskRunner.waitFor(this.appDataCache.onboardingSteps.getDataAsync());
        this.onboardingStepList.set(onboardingStepsResponse);

        // Get Config (for Founder's Video Link)
        const comXConfigurationsResponse = await this.taskRunner.waitFor(this.comXConfigurationsApiClient.get());
        this.comXConfigurationList.set(comXConfigurationsResponse.data);
        this.isOnboardingVideoSet = true

    }

    public async toggleTab(
        old_selectedTab: string,
        new_selectedTab: any
    ) {
        const selectedValue = String(new_selectedTab).toLocaleUpperCase();

        switch (selectedValue) {
            case textConstants.Onboarding.IntroductoryPages.toLocaleUpperCase():
                this.tabManager.selectedTab = textConstants.Onboarding.IntroductoryPages
                break;

            case textConstants.Onboarding.HelpCenter.Text.toLocaleUpperCase():
                this.tabManager.selectedTab = textConstants.Onboarding.HelpCenter.Text
                break;

            case textConstants.Onboarding.Popups.toLocaleUpperCase():
                this.tabManager.selectedTab = textConstants.Onboarding.Popups
                break;

            case textConstants.Onboarding.SupportLinks.toLocaleUpperCase():
                this.tabManager.selectedTab = textConstants.Onboarding.SupportLinks
                break;

            case textConstants.Onboarding.IntroductionPages.toLocaleUpperCase():
                this.tabManager.selectedTab = textConstants.Onboarding.IntroductionPages
                break;

            case textConstants.Onboarding.OnboardingVideos.toLocaleUpperCase():
                this.tabManager.selectedTab = textConstants.Onboarding.OnboardingVideos
                break;
        }
    }

    public saveAll() {
        this.taskRunner.run(async () => {
            // Save OnboardingMaintenance
            const onboardingMaintenanceResponse = await this.onboardingMaintenanceApiClient.saveList(this.onboardingMaintenanceList.toJSArray());
            this.onboardingMaintenanceList.update(onboardingMaintenanceResponse.data);

            // Save HelpCenterTexts
            const helpCenterTextsResponse = await this.helpCenterTextsApiClient.saveList(this.helpCenterTextList.toJSArray());
            this.helpCenterTextList.update(helpCenterTextsResponse.data);

            // Save Popups
            await this.savePopupData();

            // Save Supportlinks
            const supportLinksResponse = await this.supportLinksApiClient.saveList(this.supportLinkList.toJSArray());
            this.supportLinkList.update(supportLinksResponse.data);

            // Save Founders Video URL
            const response = await this.comXConfigurationsApiClient.saveList(this.comXConfigurationList.toJSArray());
            this.comXConfigurationList.update(response.data);

            // Save onboarding step introduction
            const onboardingStepIntroductionResponse = await this.onboardingStepIntroductionApiClient.saveList(this.onboardingIntroductionStepList.toJSArray());
            this.onboardingIntroductionStepList.update(onboardingStepIntroductionResponse.data);
            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.OnboardingMaintenanceSaved);
        });
    }

    public updateSelectedText(updatedText: string) {
        switch (this.tableName) {

            case textConstants.titleText.OnboardingMaintenance:
                let item = this.onboardingMaintenanceList.find(f => f.onboardingMaintenanceId === this.rowId);
                if (item !== undefined) {
                    item.bodyText = updatedText;
                }
                break;

            case textConstants.titleText.HelpCenterTexts:
                let helpText = this.helpCenterTextList.find(f => f.helpCenterTextId === this.rowId);
                if (helpText !== undefined) {
                    this.setSpecificModalText(helpText, updatedText)
                }
                break;

            case textConstants.titleText.OnboardingPopupMaintenance:
                let popupText = this.onboardingPopupList.find(f => f.onboardingPopupId === this.rowId);
                if (popupText !== undefined) {
                    popupText.text = updatedText
                }
                break;

            case textConstants.titleText.OnboardingIntroSteps:
                let introText = this.onboardingIntroductionStepList.find(f => f.onboardingStepIntroductionId === this.rowId);
                if (introText != undefined) {
                    this.setSpecificModalText(introText, updatedText)
                }
                break;

            default:
                break;
        }
    }

    private async setSpecificModalText(text: any, updatedText: string) {
        switch (this.textType) {
            case TextType.Body:
                text.bodyText = updatedText;
                break;

            case TextType.About:
                text.aboutText = updatedText;
                break;

            case TextType.Transcript:
                text.transcriptText = updatedText;
                break;

            default:
                break;
        }
    }

    public async savePopupData(): Promise<void> {
        this.onboardingSaveCommandList = new List(UpdatePopupDataCommand);
        this.onboardingPopupList.forEach(el => {
            let updateCommand = new UpdatePopupDataCommand();
            updateCommand.popupName = el.popupName;
            updateCommand.text = el.text;
            updateCommand.onboardingPopupId = el.onboardingPopupId;
            this.onboardingSaveCommandList.push(updateCommand);
        })

        const response = await this.onboardingApiClient.updateOnboardingPopupData(this.onboardingSaveCommandList);
    }

    public setRichTextEditorInfo(rowId: number, tableName: string, textType: TextType | null, selectedText: string) {
        this.rowId = rowId;
        this.tableName = tableName
        this.textType = textType
        this.selectedText = selectedText
        this.showEditModal = true;
    }
}