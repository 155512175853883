import { ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';

@NeoModel
export default class ClientTMChanges extends ModelBase {
   
  public hasTMChanged: boolean = false;

  public hasIROStatusChanged: boolean = false;

  public employeeSizeAdded: string = "";

  public employeeSizeRemoved: string = ""

  public industryAdded: string = "";

  public industryRemoved: string = "";

  public functionsAdded: string = "";

  public functionsRemoved: string = "";

  public otherFunctionsChanged: string = "";

  public regionsChanged: string = "";

  public targetMarketName: string = "";
}