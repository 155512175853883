import { List, ModelBase, NeoModel} from '@singularsystems/neo-core'; 
import TargetMarketAccountCriteria from '../../../Models/TargetMarketAccounts/Query/TargetMarketAccountCriteria';
import TargetMarketAccountLookup from '../../../Models/TargetMarketAccounts/TargetMarketAccountLookup';
import EditTargetMarketAccountCommand from './EditTargetMarketAccount';

@NeoModel 
export default class TMAUnblacklistCommand extends ModelBase {
  public isBulkAction: boolean = false;
   
  public clientId : number = 0
   
  public domainsToDelete = new List(EditTargetMarketAccountCommand)

  public isSelectAll : boolean = false

  public unselectedAccounts? = new List(TargetMarketAccountLookup)

  public targetMarketAccountCriteria?: TargetMarketAccountCriteria = new(TargetMarketAccountCriteria)
}