import { ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';

@NeoModel
export default class ClientTMStepsLookup extends ModelBase {
   
  // Properties
  public stepNumber: number = 0;

  public isComplete: boolean = false;

  public isRequired: boolean = false;
}