import { ModelBase, NeoModel, Attributes, List } from '@singularsystems/neo-core';
import IROLookupModel from './IROLookupModel';

@NeoModel
export default class IROLookup extends ModelBase {
    @Attributes.Display("C-Level")
    public clevel: boolean = false

    @Attributes.Display("Head-Level")
    public headLevel: boolean = false

    @Attributes.Display("Director-Level")
    public directorLevel: boolean = false

    @Attributes.Display("Manager Level")
    public managerLevel: boolean = false

    @Attributes.Display("VP-Level")
    public vpLevel: boolean = false
    
    @Attributes.Display("Country")
    public country: string = ""

    @Attributes.Display("Support")
    public support: string = ""

    public lookups = new List(IROLookupModel)

}

