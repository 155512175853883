import {
  ModelBase,
  NeoModel,
} from '@singularsystems/neo-core';

@NeoModel 
export default class MasterAccountOption extends ModelBase { 

   public newAndExistingAccounts: boolean = false;

   public inValidDomains: boolean = false;

   public ClientId?: number  = 0
}