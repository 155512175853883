import { ModelBase, NeoModel, Rules, } from '@singularsystems/neo-core';

@NeoModel
export default class TargetMarketSubIndustry extends ModelBase {

    // Properties
    public targetMarketSubIndustryId: number = 0;   

    // @Rules.Required()
    public targetMarketId: number = 0;

    @Rules.Required()
    public industrySubIndustryId: number = 0;
    
    public isPriority: boolean = false;
}