import { NeoModel, ModelBase } from '@singularsystems/neo-core';

@NeoModel
export default class NextReminder extends ModelBase {

  public id: string = "";

  public title: string = "";

  public description: string = "";

  public time: Date = new Date();
}