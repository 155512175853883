import {ModelBase, NeoModel, List} from '@singularsystems/neo-core';
import TargetMarketManagementSubFunctionsLookup from './TargetMarketManagementSubFunctionsLookup';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';


@NeoModel
export default class TargetMarketManagementFunctionLookup extends ModelBase {
    // Properties
    public managementFunctionId: number | null = null;

    @Display('Function Name')
    public functionName: string = "";
    public isSelected: Nullable<boolean>  = true;
    public isPriority: Nullable<boolean> = true;
    public isExpanded: boolean = false;
    public managementSubFunctionId: number | null = null
    // Child List
    public targetMarketManagementSubFunctionsLookup = new List(TargetMarketManagementSubFunctionsLookup);
} 