import React from 'react';
import { Views, Neo } from '@singularsystems/neo-react';
import MasterAccountsVM from './MasterAccountsVM';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import { NavLink } from 'react-router-dom';
import { updateMasterAccountRoute as route } from '../../RouteConfiguration'
import { AppService, Types } from '../../Services/AppService';
import { ValidationDisplayMode } from '@singularsystems/neo-core/dist/Validation/Misc';
import SearchField from '../../Components/SearchField';
import MasterAccountDataGrid from './MasterAccountGrid/MasterAccountGrid';
import * as Icon from 'react-feather';
import Button from 'Components/Button/Button';

@observer
export default class MasterAccountsView extends Views.ViewBase<MasterAccountsVM> {

    constructor(props: unknown) {
        super("Master Accounts", MasterAccountsVM, props);
    }

    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

    public securityCheck(role: any, openMethod: () => void) {
        if (this.authorisationService.hasRole(role)) {
            openMethod();
        }
        else {
            this.viewModel.showForbiddenModal = true;
        }
    }

    onLeave() {
        this.viewModel.removeNotifications();
        return undefined;
    }

    public render() {
        const searchFieldVM = this.viewModel.searchFieldVM;
        return (
            <Neo.Card>
                <div className="MasterAccountsView p-24 mt-4 mb-4">
                    <div className="row mr-0 ml-0 p-0">
                        <div className="col-2 p-0">
                            <h2 className="pt-0 m-0">{textConstants.titleText.MasterAccount}</h2>
                        </div>

                        <div className="col-10 p-0 marginTop4">
                            {/* Download Master Accounts button*/}
                            <Button
                                buttonType='primary'
                                className="pull-right ml-4 mt-0 mb-1"
                                variant="hasIcon"
                                size="xSmall"
                                onClick={() => this.viewModel.downloadMasterAccounts()}
                                disabled={this.viewModel.masterAccountCriteria.countryId < 1}
                            >
                                <Icon.Download />
                                {textConstants.buttonText.Download}
                            </Button>

                            {/* Update Master Accounts */}
                            <NavLink key={route.path} to={route.path || ''} >
                                <Button
                                    buttonType='primary'
                                    className="pull-right ml-4 mt-0 mb-1"
                                    variant="hasIcon"
                                    size="xSmall"
                                >
                                    <Icon.Plus />
                                    {textConstants.buttonText.Upload}
                                </Button>
                            </NavLink>

                            {/* Search field */}
                            <SearchField
                                searchBindableProp={this.viewModel.masterAccountCriteria.meta.searchString}
                                onClickFunction={(e) => {
                                    this.viewModel.refreshMethod()
                                    this.viewModel.isRefreshCount = true
                                }}
                                searchString={this.viewModel.masterAccountCriteria.searchString}
                                disabled={this.viewModel.masterAccountCriteria.countryId === 0}
                                disableButton={this.viewModel.masterAccountCriteria.countryId === 0}
                                searchFieldVM={searchFieldVM}
                            />

                            {/* Country */}
                            <Neo.FormGroupInline
                                className="pull-right mr-4"
                                bind={this.viewModel.masterAccountCriteria.meta.countryId}
                                select={{ items: this.viewModel.countries }}
                                onChange={() => this.viewModel.FetchData()}
                            />
                        </div>
                    </div>

                    {/* Master Accounts Grid */}
                    <MasterAccountDataGrid viewModel={this.viewModel} />


                    {/* Edit Modal */}
                    <Neo.Modal
                        title="Edit Master Account"
                        show={this.viewModel.showEditModal}
                        onClose={() => this.viewModel.closeEditModal()}
                        suppressForm={true}
                        footer={<div></div>}
                        className="hideModalFooter"
                    >
                        <Neo.Form
                            model={this.viewModel.editMasterAccountCommand}
                            onSubmit={() => { this.viewModel.editMasterAccount() }}
                            validationDisplayMode={ValidationDisplayMode.Always}
                            showSummaryModal={false}
                        >
                            <Neo.GridLayout md={1}>
                                <Neo.FormGroup bind={this.viewModel.editMasterAccountCommand.meta.originalName} />
                                <Neo.FormGroup bind={this.viewModel.editMasterAccountCommand.meta.fixedName} />
                                <Neo.FormGroup
                                    bind={this.viewModel.editMasterAccountCommand.meta.subRegionId}
                                    select={{ items: this.viewModel.subRegions }}
                                    isDisabled={this.viewModel.regionDisable}
                                />
                                <Neo.FormGroup bind={this.viewModel.editMasterAccountCommand.meta.location} />
                                <Neo.FormGroup bind={this.viewModel.editMasterAccountCommand.meta.domain}
                                />
                            </Neo.GridLayout>

                            {/* Industry */}
                            <Neo.GridLayout md={1}>
                                <Neo.FormGroup
                                    bind={this.viewModel.editMasterAccountCommand.meta.subIndustryId}
                                    select={{ items: this.viewModel.subIndustries }} />
                            </Neo.GridLayout>

                            {/* Company Size */}
                            <Neo.GridLayout md={2}>
                                <Neo.FormGroup
                                    bind={this.viewModel.editMasterAccountCommand.meta.employeeSizeId}
                                    select={{ items: this.viewModel.employeeSizes }} />

                                {/* Status */}
                                <Neo.FormGroup
                                    bind={this.viewModel.editMasterAccountCommand.meta.accountStatusId}
                                    select={{ items: this.viewModel.accountStatuses }}
                                    disabled={true} />
                            </Neo.GridLayout>

                            {/* Invalid Reason */}
                            {this.viewModel.checkInvalidReason(this.viewModel.editMasterAccountCommand.accountStatusId) &&
                                <Neo.FormGroup
                                    bind={this.viewModel.editMasterAccountCommand.meta.invalidReasonId}
                                    select={{ items: this.viewModel.invalidReasons }} />
                            }
                            <div className="pt-2 custom-footer submitButtonStyles">
                                <div className="row m-0 float-right pt-2">
                                    <div className="mr-3 col6ResponsivePosition p-0">
                                        <Neo.Button className="modalButtonWidth" text="Save" variant="secondary" isSubmit />
                                    </div>
                                    <div className="col6ResponsivePosition p-0">
                                        <Neo.Button className="modalButtonWidth" text="Cancel" variant="light" onClick={() => this.viewModel.closeEditModal()} />
                                    </div>
                                </div>
                            </div>
                        </Neo.Form>
                    </Neo.Modal>

                    {/* Delete Master Account Modal */}
                    <Neo.Modal title={`Delete confirmation`} show={this.viewModel.showDeleteModal}
                        onClose={() => this.viewModel.showDeleteModal = false}
                        closeButton={{ text: "No", variant: "light" }}
                        acceptButton={{
                            text: "Yes", variant: "secondary", onClick: () => this.viewModel.deleteMasterAccount(),
                            disabled: (this.viewModel.deleteMasterAccountCommand.invalidReasonId === 0)
                        }}>
                        Master Account &#34;{this.viewModel.selectedAccountName}&#34; is about to be deleted. Do you wish to continue?
                        <div>
                            <Neo.FormGroup
                                bind={this.viewModel.deleteMasterAccountCommand.meta.invalidReasonId}
                                select={{ items: this.viewModel.invalidReasons }}
                            />
                        </div>
                    </Neo.Modal>

                    {/* Forbidden Modal */}
                    <Neo.Modal
                        title="Forbidden"
                        show={this.viewModel.showForbiddenModal}
                        onClose={() => this.viewModel.showForbiddenModal = false}
                        closeButton={{ text: "Cancel", variant: "light" }}
                    >
                        <p>You have insufficient privileges to perform this operation.</p>
                    </Neo.Modal>

                    {/* Bulk Action Modal */}
                    <Neo.Modal title={`Bulk Action Confirmation`} show={this.viewModel.showBulkActionModal}
                        onClose={() => this.viewModel.showBulkActionModal = false}
                        closeButton={{ text: "No", variant: "light" }}
                        acceptButton={{
                            text: "Yes", variant: "secondary", onClick: () => this.viewModel.PerformBulkAction(),
                            disabled: (this.viewModel.deleteMasterAccountCommand.invalidReasonId === 0 && this.viewModel.bulkActions === textConstants.generalText.BulkDelete)
                        }}>
                        {this.viewModel.bulkActionMessage}
                        <div>
                            {(this.viewModel.bulkActions === textConstants.generalText.BulkDelete) &&
                                <Neo.FormGroup
                                    bind={this.viewModel.deleteMasterAccountCommand.meta.invalidReasonId}
                                    select={{ items: this.viewModel.invalidReasons }}
                                />
                            }
                        </div>
                    </Neo.Modal>

                </div>
            </Neo.Card>
        );
    }
}