import React from "react"
import ReminderVM from "./ReminderVM"
import moment from "moment"
import { textConstants } from "common/textConstants"
import * as Icon from 'react-feather'
import { observer } from "mobx-react"

interface IReminderList {
  viewModel: ReminderVM;
  editReminder: (reminderId: string) => void;
  updateCompletion: (reminderId: string) => void;
}

// The list of reminders for that specific lead
@observer
export default class ReminderList extends React.Component<IReminderList> {

  public render() {
    const { viewModel, editReminder, updateCompletion } = this.props

    return (
      <div className="ReminderList">
        {viewModel.listReminderLookups &&
          viewModel.listReminderLookups.map((reminder, key) => (
            <div key={key} className="reminderCard">

              {/* Title */}
              <div className="reminder-title">{reminder.title}</div>

              {/* Description */}
              <div className="reminder-description pt-3">{viewModel.limitDescriptionLength(reminder.description)}</div>

              {/* Time and completion */}
              <div className="reminder-date-section d-flex flex-wrap mt-3">

                {/* Formatted Time */}
                <div className="reminder-date-card">
                  <span>{moment(reminder.time).format(textConstants.dateformat.format2).toString()}</span>
                </div>

                {/* Edit Reminder button */}
                <div className="reminder-button" onClick={() => editReminder(reminder.id)}>
                  <Icon.Edit size={16} />
                </div>

                {/* Complete Reminder button */}
                <div className={`reminder-button complete`} onClick={() => updateCompletion(reminder.id)}>
                  <Icon.CheckCircle size={16} />
                </div>
              </div>
            </div>
          ))}
      </div>
    )
  }
}