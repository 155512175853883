import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import AdvancedEmailSettingsVM from 'Components/TechnicalIntegrationSettings/AdvancedEmailSettingsVM';
import NavigatorStep from 'Models/Navigation/NavigatorStep';
import List from '@singularsystems/neo-core/dist/Model/List';
import { textConstants } from 'common/textConstants';
import NavigationStep from 'Models/Navigation/NavigationStep';
import UpdateOnboardingStepCommand from 'Models/Onboarding/UpdateOnboardingStepCommand';
import OnboardingFunctions from 'Models/Onboarding/OnboardingFunctions';

export default class OnboardingTechnicalSettingsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public authenticationService = AppService.get(Types.Security.CustomAuthenticationService),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        public onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient)) {

        super(taskRunner);
        this.makeObservable();
    }

    public showOverview: boolean = false
    public relevantNavigationSteps: NavigatorStep[] = []
    private allNavigationSteps: List<NavigationStep> = new List(NavigationStep);

    public advancedEmailSettingsVM: AdvancedEmailSettingsVM = new AdvancedEmailSettingsVM()
    public clientId = this.authenticationService.globalProps.clientId

    public firstStepNumber: number = 1;
    public currentSection: number = 1;
    public lastSection: number = 2;

    public isValidSave: boolean = false
    public completedLoad = false
    public showCompletedModal = false;
    public nextStep = ""

    public showOnboardingModal: boolean = false;

    public onboardingStepId?: number = 0

    public onboardingPopupModalContent: string = "";

    public async initialise() {
        await this.initializeAdvancedEmailVM()

        await this.setShowOverview()
    }

    private async onboardingModalSetup() {
        const {
            onboardingStepId,
            showOnboardingModal,
            onboardingPopupModalContent,
        } = await this.authenticationService.onboardingFunctions.getOnboardingData();

        this.onboardingStepId = onboardingStepId;
        this.showOnboardingModal = showOnboardingModal;
        this.onboardingPopupModalContent = onboardingPopupModalContent;
    }

    public async setStepCompleteModalAsSeen() {
        await this.authenticationService.onboardingFunctions.setStepCompleteModalAsSeen(this.onboardingStepId);
    }

    private async setNavSteps() {
        let steps = await this.appDataCache.navigationSteps.getDataAsync()

        const navigationSteps = steps.filter(ns => ns.systemArea === textConstants.generalText.TechnicalSettingsSpaced)
        this.allNavigationSteps.set(navigationSteps);

        this.populateNavigator()
    }

    public populateNavigator() {
        this.relevantNavigationSteps = []
        this.allNavigationSteps.forEach(step => {
            let newStep: NavigatorStep = {
                id: step.stepNumber,
                stepNumber: step.stepNumber,
                stepText: step.navigationHeaderText,
                isComplete: this.isNavigatorStepComplete(step.stepNumber)
            };

            this.relevantNavigationSteps.push(newStep);
        });

        return true
    }

    private async initializeAdvancedEmailVM() {
        this.advancedEmailSettingsVM = new AdvancedEmailSettingsVM();
        this.advancedEmailSettingsVM.taskRunner = this.taskRunner;

        let completed = await this.advancedEmailSettingsVM.setAdvancedEmailSettings(this.clientId);

        if (completed) {
            this.completedLoad = true
            await this.setNavSteps()
        }
    }

    public async shouldShowOverview() {
        this.showOverview = false
        let currentOnboardingStep = this.authenticationService.globalProps.currentStep
        let onboardingStep = this.authenticationService.globalProps.onboardingSteps
            .find(f => f.stepNumber === currentOnboardingStep)!

        if (onboardingStep.isComplete) {
            this.showOverview = true
            this.authenticationService.globalProps.isZendeskVisible = false;
            this.authenticationService.globalProps.sectionCode = ""
        }
        if (onboardingStep.isComplete && !onboardingStep.hasSeenStepCompletedPopup) {
            await this.onboardingModalSetup()
        }
    }

    private async setShowOverview() {
        let onboardingStep = this.authenticationService.globalProps.currentStep
        let currentStep = this.authenticationService.globalProps.onboardingSteps.find(f => f.stepNumber === onboardingStep)

        if (this.isNavigatorStepComplete(1) && this.isNavigatorStepComplete(2)) {

            if (!currentStep!.isComplete) {
                let completeStepCommand = new UpdateOnboardingStepCommand()
                completeStepCommand.clientId = this.clientId;
                completeStepCommand.stepNumber = onboardingStep
                completeStepCommand.isComplete = true;

                await this.onboardingApiClient.updateOnboardingClientStep(completeStepCommand)

                currentStep!.isComplete = true
            }

            this.shouldShowOverview()
        }
        else if (currentStep!.isComplete) {
            // Need to reset the completion status to false due the user removing or updating it to a state that is incomplete
            let completeStepCommand = new UpdateOnboardingStepCommand()
            completeStepCommand.clientId = this.clientId;
            completeStepCommand.stepNumber = onboardingStep
            completeStepCommand.isComplete = false;

            await this.onboardingApiClient.updateOnboardingClientStep(completeStepCommand)

            currentStep!.isComplete = false
        }
    }

    private isNavigatorStepComplete(stepNumber: number) {
        let complete = false;

        switch (stepNumber) {
            case 1:
                let hasOutboundEmails = this.advancedEmailSettingsVM.outboundEmailList.length > 0

                let hasRequiredEmailSettings = this.advancedEmailSettingsVM.advancedEmailSettingsLookup.isSPF
                    && this.advancedEmailSettingsVM.advancedEmailSettingsLookup.isCNAME

                if (hasRequiredEmailSettings && hasOutboundEmails) {
                    complete = true
                }
                break

            case 2:
                let hasTest = this.advancedEmailSettingsVM.testEmailList.length > 0

                if (hasTest) {
                    complete = true;
                }
                break
        }

        return complete
    }

    public async goToStep(stepNumber: number) {

        if (stepNumber === this.currentSection) {
            return
        }

        let isValid = await this.saveSettings(this.currentSection)

        if (isValid) {

            this.currentSection = stepNumber
        }
        else {
            this.authenticationService.onboardingFunctions.setHelpCenterSectionCode()
        }
    }

    public async saveSettings(stepNumber: number, validate = true) {
        let isValid = true;

        if (validate) {
            if (stepNumber === 1) {
                isValid = this.advancedEmailSettingsVM.isAdvancedEmailSettingsSectionValid()
            }
            else {
                isValid = this.advancedEmailSettingsVM.validateAlltestEmails()
            }
        }

        if (isValid) {
            if (stepNumber === 1) {
                await this.advancedEmailSettingsVM.saveAdvancedEmailSettings(false, false)

                isValid = this.isNavigatorStepComplete(1)
            }
            else {
                isValid = this.isNavigatorStepComplete(2)

                if (isValid) {
                    await this.advancedEmailSettingsVM.saveTestEmailList()
                }

                this.setShowOverview()
            }

            if (validate) {
                if (this.relevantNavigationSteps.find(f => f.stepNumber === this.currentSection && !f.isComplete)) {

                    this.populateNavigator()
                }
            }
        }

        return isValid
    }
}