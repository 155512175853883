import styled from "styled-components";


export const StyledInfoVideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;
`;

export const StyledInfoVideoTitle = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    color: #0B0B0C;
`;

export const StyledInfoVideoHolder = styled.div`
    width: 100%;
    height: 450px;
    max-height: 100%;
    background: #F8F8F8;
    border-radius: 8px;
    padding: 16px;
    background: #0B0B0C;
`;

export const StyledVideoContent = styled.div`
    width: 100%;
    height: 100%;
    display: block;
    align-items: center;
    justify-content: center;
`;