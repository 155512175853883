import Axios, { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { Model, List } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import ManagementRole from '../Models/Maintenance/ManagementRole';
import CommandResult from '../Models/InvitedUsers/Commands/CommandResult';
import { ResponseResolver } from '../common/utils';

export interface IManagementRolesApiClient {
    getManagementRolesAsync(): AxiosPromise<Array<Model.PlainObject<ManagementRole>>>;
    insertUpdateManagementRoles(managementRoles: List<ManagementRole>): AxiosPromise<Model.PlainObject<CommandResult>>;
}

@injectable()

export default class ManagementRolesApiClient implements IManagementRolesApiClient {

    constructor(public cnfig = AppService.get(Types.Config)) {
    }

    public getManagementRolesAsync(): AxiosPromise<Array<Model.PlainObject<ManagementRole>>> {
        return ResponseResolver<Array<Model.PlainObject<ManagementRole>>>(Axios.get(`${this.cnfig.apiPath}/ManagementRole/getManagementRoles`));
    }

    public insertUpdateManagementRoles(managementRoles: List<ManagementRole>): AxiosPromise<{ success: boolean; error: string; id: number; }> {
        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.cnfig.apiPath}/ManagementRole/SaveManagementRoles`, managementRoles.toJSArray()));
    }
}