import { Routing, AppServices } from '@singularsystems/neo-core';

import Types from './AuthorisationTypes';
import * as Roles from './Models/Security/Roles';

import UserGroupsView from './Views/UserGroupsView';
import AuthorisationDataCache from './Services/AuthorisationDataCache';
import UserGroupsVM from './Views/UserGroupsVM';
import AuthorisationApiClient from './Services/ApiClients/AuthorisationApiClient';
import AssignedRolesApiClient from './Services/ApiClients/AssignedRolesApiClient';
import MembershipsApiClient from './Services/ApiClients/MembershipsApiClient';
import ResourcesApiClient from './Services/ApiClients/ResourcesApiClient';
import UserGroupsApiClient from './Services/ApiClients/UserGroupsApiClient';
import UsersApiClient from './Services/ApiClients/UsersApiClient';
import { ComXHub } from '../ApiClients/Hubs/ComXHub';

// Modules
const authorisationAppModule = new AppServices.Module("Authorisation", container => {
    // Api Clients
    container.bind(Types.ApiClients.AuthorisationApiClient).to(AuthorisationApiClient).inSingletonScope();
    container.bind(Types.ApiClients.AssignedRolesApiClient).to(AssignedRolesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.MembershipsApiClient).to(MembershipsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.ResourcesApiClient).to(ResourcesApiClient).inSingletonScope();
    container.bind(Types.ApiClients.UserGroupsApiClient).to(UserGroupsApiClient).inSingletonScope();
    container.bind(Types.ApiClients.UsersApiClient).to(UsersApiClient).inSingletonScope();
    container.bind(Types.ApiClients.UserRolesHub).to(ComXHub).inSingletonScope();

    // Services
    container.bind(Types.Services.AuthorisationDataCache).to(AuthorisationDataCache).inSingletonScope();

    // View Models
    container.bind(Types.ViewModels.UserGroupsViewModel).to(UserGroupsVM).inTransientScope();
});

const authorisationTestModule = new AppServices.Module("Authorisation", container => {
    // bind test types
});

// Routes
const SecurityRoute : Routing.IMenuRoute = 
    { name: "User Access", path: "/security/useraccess", component: UserGroupsView, role: Roles.UserAccess.Access }

export { authorisationAppModule, 
         authorisationTestModule, 
         SecurityRoute } 