import { injectable } from 'inversify';
import Axios, { AxiosPromise, AxiosResponse } from 'axios';
import { Model, Data, Utils } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import CommandResult from './../Models/InvitedUsers/Commands/CommandResult';
import InviteCommand from './../Models/InvitedUsers/Commands/InviteCommand';
import BlockCommand from '../Models/InvitedUsers/Commands/BlockCommand';
import UnblockCommand from '../Models/InvitedUsers/Commands/UnblockCommand';
import RoInvitedUserCriteria from '../Views/InvitedUsers/LookUps/RoInvitedUserLookupCriteria';
import DeleteCommand from '../Models/InvitedUsers/Commands/DeleteCommand';
import ReInviteCommand from '../Models/InvitedUsers/Commands/ReInviteCommand';
import ApiResult from '../Models/ApiResult';
import { ResponseResolver } from '../common/utils';
import UserMenuAccessLookup from '../Views/InvitedUsers/LookUps/UserMenuAccessLookup';
import UnlockCommand from '../Models/InvitedUsers/Commands/UnlockCommand';
import EditUserCommand from '../Models/InvitedUsers/Commands/EditUserCommand';
import FrontEndKeyLookup from '../Models/InvitedUsers/FrontEndKeyLookup';
import HotjarUserDetailsLookup from '../Models/InvitedUsers/HotjarUserDetailsLookup';
import UpdateShowLeadInfoBarCommand from '../Models/Client/Commands/UpdateShowLeadInfoBarCommand';

export interface IInvitedUsersApiClient {
    /**
     * Will fetch Invited Users
     */
    getInvitedUsers(): AxiosPromise<any>;
    getInvitedUsersPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<RoInvitedUserCriteria>>): AxiosPromise<any>;
    getBlockedUsersPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<RoInvitedUserCriteria>>): AxiosPromise<any>;
    getActiveUsersPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<RoInvitedUserCriteria>>): AxiosPromise<any>;
    getLockedUsersPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<RoInvitedUserCriteria>>): AxiosPromise<any>;
    invite(invite: InviteCommand): AxiosPromise<Model.PlainObject<CommandResult>>;

    block(block: BlockCommand): AxiosPromise<Model.PlainObject<CommandResult>>;

    unblock(unblock: UnblockCommand): AxiosPromise<Model.PlainObject<CommandResult>>;

    updateUserDetails(editUser: EditUserCommand): AxiosPromise<Model.PlainObject<CommandResult>>;

    unlockUser(unlock: UnlockCommand): AxiosPromise<Model.PlainObject<CommandResult>>;

    inviteClientUser(clientId: number, invitedUserId: number, inviteEmail: string): AxiosPromise<{ success: boolean; error: string; id: number; }>;

    registerClientUser(username: string): AxiosPromise<Model.PlainObject<CommandResult>>;

    deleteInvitedUser(deleteCommand: DeleteCommand): AxiosPromise<Model.PlainObject<CommandResult>>;

    reInviteUser(reInviteCommand: ReInviteCommand): AxiosPromise<Model.PlainObject<CommandResult>>;

    isClientUser(username: string): AxiosPromise<boolean>;

    checkNotifications(): AxiosPromise<boolean>;

    getUserMenuAccess(): AxiosPromise<Model.PlainObject<UserMenuAccessLookup>>;

    getFrontEndKeys(): AxiosPromise<FrontEndKeyLookup>;

    hotjarInitialization(): AxiosPromise<HotjarUserDetailsLookup>;

    saveShowLeadInfo(command:UpdateShowLeadInfoBarCommand): AxiosPromise<ApiResult>;

    getLeadInfoBarIndicator(username : string): AxiosPromise<boolean>;
}

@injectable()
export default class InvitedUsersApiClient implements IInvitedUsersApiClient {

    constructor(private config = AppService.get(Types.Config)) {

    }
    
    public getInvitedUsers(): AxiosPromise<any> {
        return Axios.get(`${this.config.identityServer.url}/api/InvitedUsers/GetInvitedUsers`);
    }

    public getInvitedUsersPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<RoInvitedUserCriteria>>): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/InvitedUsers/GetInvitedUsersPaged?${Utils.getQueryString(request)}`);
    }

    public saveShowLeadInfo(command:UpdateShowLeadInfoBarCommand): AxiosPromise<ApiResult>{
        return Axios.post(`${this.config.apiPath}/InvitedUsers/UpdateLeadInfoBarSeenIndicator`, command.toJSObject())
    }

    public getBlockedUsersPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<RoInvitedUserCriteria>>): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/InvitedUsers/GetBlockedUsersPaged?${Utils.getQueryString(request)}`);
    }

    public getActiveUsersPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<RoInvitedUserCriteria>>): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/InvitedUsers/GetActiveUsersPaged?${Utils.getQueryString(request)}`);
    }

    public getLockedUsersPaged(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<RoInvitedUserCriteria>>): AxiosPromise<any> {
        const config = AppService.get(Types.Config)
        return Axios.get(`${config.apiPath}/InvitedUsers/GetLockedUsersPaged?${Utils.getQueryString(request)}`);
    }

    public unlockUser(unlock: UnlockCommand): AxiosPromise<Model.PlainObject<CommandResult>>{
        return Axios.post(`${this.config.apiPath}/InvitedUsers/UnlockUser`, unlock.toJSObject());
    }

    public updateUserDetails(editUser: EditUserCommand): AxiosPromise<Model.PlainObject<CommandResult>> {
        return Axios.post(`${this.config.apiPath}/InvitedUsers/UpdateUserDetails`, editUser.toJSObject());
    }

    public invite(invite: InviteCommand): AxiosPromise<{ success: boolean; error: string; id: number; }> {
        const inviteObject = invite.toJSObject();
        return Axios.post(`${this.config.identityServer.url}/api/InvitedUsers/Invite`, inviteObject)
    }

    public block(block: BlockCommand): AxiosPromise<{ success: boolean; error: string; id: number; }> {
        return Axios.post(`${this.config.identityServer.url}/api/InvitedUsers/block`, block.toJSObject());
    }

    public unblock(unblock: UnblockCommand): AxiosPromise<{ success: boolean; error: string; id: number; }> {
        return Axios.post(`${this.config.identityServer.url}/api/InvitedUsers/unblock`, unblock.toJSObject());
    }

    public inviteClientUser(clientId: number, invitedUserId: number, inviteEmail: string): AxiosPromise<{ success: boolean; error: string; id: number; }> {
        const config = AppService.get(Types.Config)

        const payload = {
            clientId,
            invitedUserId,
            inviteEmail
        }

        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${config.apiPath}/InvitedUsers/InviteClientUser`, payload));
    }

    public registerClientUser(username: string): AxiosPromise<Model.PlainObject<CommandResult>>{
        return ResponseResolver<CommandResult>(Axios.post<ApiResult>(`${this.config.apiPath}/InvitedUsers/RegisterClientUser`, {username: username}));
    }

    public deleteInvitedUser(deleteCommand: DeleteCommand): AxiosPromise<Model.PlainObject<CommandResult>>{
        return ResponseResolver<CommandResult>(Axios.get<ApiResult>(`${this.config.apiPath}/InvitedUsers/DeleteInvitedUser/${deleteCommand.id}`));
    }

    public reInviteUser(reInviteCommand: ReInviteCommand): AxiosPromise<Model.PlainObject<CommandResult>>{
        return Axios.post(`${this.config.identityServer.url}/api/InvitedUsers/reinvite`, reInviteCommand.toJSObject());
    }

    public isClientUser(username: string): AxiosPromise<boolean>{
        return ResponseResolver<boolean>(Axios.post<ApiResult>(`${this.config.apiPath}/InvitedUsers/IsClientUser`, {username: username}));
    }

    public checkNotifications(): AxiosPromise<boolean>{
        return ResponseResolver<boolean>(Axios.get<ApiResult>(`${this.config.apiPath}/InvitedUsers/CheckNotifications`));
    }

    public getLeadInfoBarIndicator(username: string): AxiosPromise<boolean>{
        return (Axios.post(`${this.config.apiPath}/InvitedUsers/GetLeadInfoBarSeenIndicator`, {username: username}));
    }

    public getUserMenuAccess(): AxiosPromise<Model.PlainObject<UserMenuAccessLookup>> {
        return ResponseResolver<UserMenuAccessLookup>(Axios.get<ApiResult>(`${this.config.apiPath}/InvitedUsers/GetUserMenuAccess`));
    }

    public getFrontEndKeys(): AxiosPromise<FrontEndKeyLookup> {
        return ResponseResolver<FrontEndKeyLookup>(Axios.get<ApiResult>(`${this.config.apiPath}/InvitedUsers/GetFrontEndKeys`));
    }

    public hotjarInitialization(): AxiosPromise<HotjarUserDetailsLookup> {
        return ResponseResolver<HotjarUserDetailsLookup>(Axios.get<ApiResult>(`${this.config.apiPath}/InvitedUsers/HotjarInitialization`));
    }
}
