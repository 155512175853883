import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import * as Roles from '../../../../Models/Security/Roles';
import GreyListedProspectHelper from '../GreyListedProspectHelper';
import * as Icon from 'react-feather';
import { StyledButton } from 'Components/Button/styles';

export const helperConst = {
    emptyText: "No greylisted emails found.",
    searchString: "searchString",
    idProperty: "greylistedProspectId",
    initialPageSize: 500,
    gridStyle: { minHeight: 600, maxWidth: 2000 },
}

const GreylistedProspectGridColumns = {
    greylistedProspectId: {
        name: "greylistedProspectId",
        display: "greylistedProspectId",
    },
    emailAddress: {
        name: "emailAddress",
        display: "Email",
    },
    dateAdded: {
        name: "dateAdded",
        display: "Date Added",
    },
    addedBy: {
        name: "addedBy",
        display: "Added By",
    },
    isDeleted: {
        name: "isDeleted",
        display: "Delete",
    },
}

export const CreateColumns = (viewModel: GreyListedProspectHelper) => {
    return [
        {
            defaultLocked: true,
            name: GreylistedProspectGridColumns.greylistedProspectId.name,
            header: GreylistedProspectGridColumns.greylistedProspectId.display,
            defaultVisible: false,
            render: ({ value, data }: any) => (
                <div>
                    {data.greylistedProspectId}
                </div>
            ),
        },
        {
            name: GreylistedProspectGridColumns.emailAddress.name,
            header: GreylistedProspectGridColumns.emailAddress.display,
            sortable: true,
            defaultFlex: 1,
            minWidth: 180,
        },
        {
            name: GreylistedProspectGridColumns.dateAdded.name,
            header: GreylistedProspectGridColumns.dateAdded.display,
            defaultFlex: 1,
            minWidth: 250,
            sortable: true
        },
        {
            name: GreylistedProspectGridColumns.addedBy.name,
            header: GreylistedProspectGridColumns.addedBy.display,
            minWidth: 180,
            sortable: true
        },
        {
            name: GreylistedProspectGridColumns.isDeleted.name,
            header: GreylistedProspectGridColumns.isDeleted.display,
            defaultFlex: 0.1,
            sortable: true,
            defaultLocked: 'end',
            minWidth: 80,
            render: ({ value: isDeleted, data: greylistedProspect }: any) => (

                <div className="text-center">
                    {/* Delete button */}
                    {!isDeleted &&
                        <StyledButton
                            isIconButton={true}
                            onClick={() => viewModel.securityCheck(Roles.Greylist.Manage,
                                () => viewModel.openDeleteModal(greylistedProspect))}>
                            <Icon.Trash2 size={16} />
                        </StyledButton>
                    }
                </div>
            )
        },
    ];
}