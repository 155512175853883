import { NeoModel, List } from '@singularsystems/neo-core';
import { AppService, Types } from '../../Services/AppService';
import Industry from './../../Models/Maintenance/Industry';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';
import IndustriesVersion from 'Models/Maintenance/IndustriesVersion';

@NeoModel
export default class IndustryVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private industriesApiClient = AppService.get(Types.ApiClients.IndustriesApiClient),
        private industriesVersionsApiClient = AppService.get(Types.ApiClients.IndustriesVersionsApiClient),
        private appDataCache = AppService.get(Types.Services.AppDataCache)) {
        super(taskRunner);
    }
    
    public industryList = new List(Industry);
    public industriesVersionsList = this.appDataCache.industriesVersions.get().data.sortBy("industriesVersionId");

    public initialise() {

        this.taskRunner.run(async () => {
            const response = await this.industriesApiClient.get();
            this.industryList.set(response.data);

            const versionResponse = await this.industriesVersionsApiClient.get()
            this.industriesVersionsList = versionResponse.data.sortBy("industriesVersionId")
        });
    }

    public saveIndustryList(){
        this.taskRunner.run(async () => {
            const Data = this.industryList.toJSArray();
            const response = await this.industriesApiClient.saveList(Data);
            this.industryList.update(response.data);
            this.appDataCache.industries.expire(true)
            this.notifications.addSuccess(textConstants.titleText.Saved,textConstants.messageText.saveMessage.IndustrySaved);
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}