import { List, ModelBase, NeoModel, Rules} from '@singularsystems/neo-core'; 
import MasterAccountLookup from '../../../Models/MasterAccounts/MasterAccountLookup';
import MasterAccountCriteria from '../../../Models/MasterAccounts/Query/MasterAccountCriteria';

@NeoModel 
export default class UndeleteMasterAccountCommand extends ModelBase { 
   @Rules.Required()
   public masterAccountId: number = 0;

   public masterAccountLookups?= new List(MasterAccountLookup);

   public masterAccountCriteria?: MasterAccountCriteria = new (MasterAccountCriteria)

   public unselectedAccounts?= new List(MasterAccountLookup)

   public isSelectAll: boolean = false; 
}