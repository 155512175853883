import { Attributes, ModelBase, NeoModel} from '@singularsystems/neo-core';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';
import { IBulkActionGrid, selectedItem } from '../ComxDataGridManager/BulkActionPageManager';


@NeoModel
export default class BlacklistedDomainLookup extends ModelBase implements IBulkActionGrid {

    @selectedItem(["blacklistedDomainId"])
    @Display("Select")
    public IsSelected : boolean = false

    public blacklistedDomainId: number = 0;

    @Attributes.Display("Domain")
    public domain: string = "";

    @Attributes.Display("Date Added")
    public dateAdded: string = "";
    
    @Attributes.Display("Added By")
    public addedBy: string = "";
}