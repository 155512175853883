import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../../common/textConstants';
import { isURL } from '../../../common/utils';
import DashboardCard from '../../../Models/Maintenance/DashboardCard';
import UpdateSideMenuDataCommand from '../../../Models/Onboarding/Commands/UpdateSideMenuDataCommand';
import { AppService, Types } from '../../../Services/AppService';
import ComXConfiguration from '../../../Models/Maintenance/ComXConfiguration';
import { PartialPlainObject } from '@singularsystems/neo-core/dist/Model';
import { DashboardCards } from 'Models/Enums/ApplicationEnums';

@NeoModel
export default class DashboardCardVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private dashboardCardsApiClient = AppService.get(Types.ApiClients.DashboardCardsApiClient),
        private comXConfigApiClient = AppService.get(Types.ApiClients.ComXConfigurationsApiClient),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private comXConfigurationsApiClient = AppService.get(Types.ApiClients.ComXConfigurationsApiClient)) {

        super(taskRunner);
    }


    //properties
    public navigationList: String[] = [];
    public showInitialTable: boolean = true;
    public showPerScreenView: boolean = false;
    public dashboardCards = new List(DashboardCard);
    public dashboardCard: DashboardCard = new DashboardCard;
    public showInvalidDataModal: boolean = false;
    public showUpdateTextModal: boolean = false;
    public updateSideMenuDataCommand: UpdateSideMenuDataCommand = new UpdateSideMenuDataCommand
    public comXConfig: ComXConfiguration = new ComXConfiguration

    public imageData: any = null;
    public updateForAllUsers: boolean = true;

    public async initialise() {
        this.taskRunner.run(async () => {
            const response = await this.dashboardCardsApiClient.get();
            if (response.data) {
                this.dashboardCards.set(response.data);
            } else {
                this.notifications.addDanger(textConstants.titleText.SaveFailed, "Failed to retrieve data");
            }
            await this.getPassword()

            let imageName = this.dashboardCards.find(dashboardCard => dashboardCard.uniqueTableKey === DashboardCards.HighlyRecommended)?.imageName

            if (imageName) {
                await this.getImage(imageName)
            }
        });
    }

    public async getPassword() {
        const info = await this.comXConfigApiClient.get();
        let password = info.data.find((config: any) => config.configCode === textConstants.titleText.KnowledgeHubPasswordDbTitle)
        let model = password as PartialPlainObject<ComXConfiguration>
        this.comXConfig.set(model)
    }

    public goBack(): void {
        if (!this.navigationList[this.navigationList.length - 1]) {
            window.history.back();
        } else {
            this.navigationList.pop();
            this.toggleView();
        }
    }

    public toggleView(item: DashboardCard | null = null) {
        this.showInitialTable = !this.showInitialTable;
        this.showPerScreenView = !this.showPerScreenView;
    }

    public async saveIndividualScreenData() {
        if (this.saveValidations()) {
            this.taskRunner.run(async () => {
                const dashboardResponse = await this.dashboardCardsApiClient.save(this.dashboardCard.toJSObject())
                let passwordSaveResult = false

                if (this.dashboardCard.cardName === textConstants.titleText.HighlyRecommended && this.dashboardCard.showDashboardNews) {
                    var result = await this.dashboardCardsApiClient.showDashboardNewsToAllUsers();
                    if (result.data.success) {
                        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.DashboardNewsCardVisibleToAllUsers);
                    }
                    else {
                        this.notifications.addDanger(textConstants.titleText.SaveFailed, textConstants.messageText.saveMessage.FailedToShowDashboardNewsCardToAllUsers);
                    }
                }

                if (this.dashboardCard.cardName === textConstants.titleText.VisitComXDbTitle) {
                    const passwordResponse = await this.comXConfigurationsApiClient.save(this.comXConfig.toJSObject());
                    passwordSaveResult = (passwordResponse.status === 200)
                } else {
                    passwordSaveResult = true
                }

                if (dashboardResponse.status === 200 && passwordSaveResult) {

                    this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.DashboardCardSaved);
                } else {
                    this.notifications.addDanger(textConstants.titleText.SaveFailed, textConstants.titleText.SaveFailed);
                }
            });
        }


    }

    private saveValidations() {
        let isValid = true;

        if (this.dashboardCard.videoLink) {
            isValid = isURL(this.dashboardCard.videoLink);
        }

        if (this.dashboardCard.redirectLink) {
            isValid = isURL(this.dashboardCard.redirectLink);
        }

        if (!isValid) {
            this.showInvalidDataModal = true;
        }

        return isValid;
    }

    public async uploadImage(fileList: File[] | FileList, dashboardCardUniqueIdentifier: string) {
        this.taskRunner.run(async () => {
            const response = await this.dashboardCardsApiClient.uploadImage(fileList, dashboardCardUniqueIdentifier);

            if (response.data.success) {
                this.dashboardCard.imageName = response.data.data
                await this.getImage(this.dashboardCard.imageName)
                this.notifications.addSuccess(
                    textConstants.titleText.UploadDemoClient,
                    textConstants.messageText.saveMessage.DemoClientMockDataUpload
                );
            }
            else {
                this.notifications.addDanger(
                    textConstants.titleText.Error,
                    response.data.message
                );
            }
        });
    }

    public async getImage(imageName: string) {
        const response = await this.dashboardCardsApiClient.getImage(imageName);

        if (response.data.success) {
            this.imageData = response.data.data
        }
        else {
            this.notifications.addDanger(
                textConstants.titleText.Error,
                response.data.message
            );
        }
    }
}