import React, { ReactNode } from 'react';
import * as Icon from 'react-feather';


interface IInfoLabelHover{
    title: string,
    children: ReactNode
}

export default class InfoLabelHover extends React.Component<IInfoLabelHover> {
    public render() {
        const {children, title} = this.props;
        return (
            <div className="InfoLabelHover">
                {title}<i aria-hidden="true">
                    <Icon.HelpCircle/>
                    <div className="hoverContainer">
                        {children}
                    </div>
                </i>
            </div >
        );
    }
} 