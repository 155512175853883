export enum Clients {
    Manage = "ComX.Clients.Manage",
    Settings = "ComX.Clients.Settings",
}

export enum TargetMarkets {
    AddEditTargetMarkets = "ComX.Target Markets.Add Edit Target Markets",
    Clone = "ComX.Target Markets.Clone",
    Manage = "ComX.Target Markets.Manage",
    Prospecting = "ComX.Target Markets.Prospecting",
    TargetMarketAccounts = "ComX.Target Markets.Target Market Accounts",
    UpdateTargetMarkets = "ComX.Target Markets.Update Target Markets",
}

export enum CampaignMessages {
    AddEditCampaignMessages = "ComX.Campaign Messages.Add Edit Campaign Messages",
    Clone = "ComX.Campaign Messages.Clone",
    Manage = "ComX.Campaign Messages.Manage",
}

export enum IdealCustomerProfile {
    Manage = "ComX.Ideal Customer Profile.Manage",
    Clone = "ComX.Ideal Customer Profile.Clone"
}

export enum BatchReview {
    Manage = "ComX.Batch Review.Manage",
    Delete = "ComX.Batch Review.Delete",
    View = "ComX.Batch Review.View",
}

export enum Maintenance {
    Manage = "ComX.Maintenance.Manage",
}

export enum MasterData {
    Manage = "ComX.Master Data.Manage",
}

export enum Security {
    Access = "Authorisation.User Access.Access",
    Update = "Authorisation.User Access.Update",
}

export enum Users {
    Manage = "ComX.Users.Manage",
}

export enum Dashboard {
    Access = "ComX.Dashboard.Access",
}

export enum ActionList {
    Access = "ComX.Action List.Access",
}

export enum Blacklist {
    Manage = "ComX.Blacklist.Manage",
}

export enum Greylist {
    Manage = "ComX.Greylist.Manage",
}

export enum Settings {
    CRMIntegration = "ComX.Settings.CRM Integration",
    ActionPlanChecklist = "ComX.Settings.Action Plan Checklist",
    TermsOfService = "ComX.Settings.Terms Of Service"
}

export enum TI_OutboundEmails {
    View = "ComX.Technical Integration_Outbound Emails.View",
    Edit = "ComX.Technical Integration_Outbound Emails.Edit",
    Delete = "ComX.Technical Integration_Outbound Emails.Delete",
}

export enum TI_AdvancedEmailSettings {
    View = "ComX.Technical Integration_Advanced Email Settings.View",
    Edit = "ComX.Technical Integration_Advanced Email Settings.Edit",
}

export enum TI_InternalTest {
    View = "ComX.Technical Integration_Internal Test.View",
    Edit = "ComX.Technical Integration_Internal Test.Edit",
}

export enum Reporting {
    View = "ComX.Reporting.View",
}