import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import CampaignMessageVM from './CampaignMessageVM';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import CampaignMessageCard from './CampaignMessageCard';
import Select, { OnChangeValue } from 'react-select';
import MultiValue from 'react-select';
import TMLookup from '../../Models/CampaignMessage/TMLookup';
import { List } from '@singularsystems/neo-core';
import { UnsavedChanges } from '../../Components/UnsavedChanges';
import { Tooltip } from '@singularsystems/neo-react/dist/ReactComponents/_Exports';
import * as Icon from 'react-feather';
import { StyledButton } from 'Components/Button/styles';

interface ICampaignMessageProps {
    viewModel: CampaignMessageVM,
    back: () => void,
    statusId?: number
}

interface IAccountState {
    showValue: boolean | null
    showPrompt: boolean
}

@observer
export default class CampaignMessageView extends React.Component<ICampaignMessageProps, IAccountState> {
    constructor(props: ICampaignMessageProps) {
        super(props)
        this.state = {
            showValue: false,
            showPrompt: false
        }
        this.props.viewModel.messageStatusId = this.props.statusId
    }

    public handleChange = (selectChange: any) => {
        this.props.viewModel.isScreenDirty = true;
      
        this.props.viewModel.selectedTargetMarkets = new List(TMLookup);

        if (selectChange !== null && selectChange.length > 0) {

            this.props.viewModel.isScreenDirty = true

            selectChange.forEach((tm : any) => {
                let tmLookup = new TMLookup();
                tmLookup.targetMarketId = tm.value;
                tmLookup.targetMarketName = tm.label;

                this.props.viewModel.selectedTargetMarkets!.push(tmLookup)
            })
        }
      
        this.props.viewModel.setTargetMarketList();
      }
      

    onEntry() {
        if (this.props.viewModel.isSaving === true) {
            return false;
        }

        const result = this.props.viewModel.isScreenDirty || this.props.viewModel.isCommentDirty

        return result;
    }

    private saveAsDraft(e: any) {
        e.preventDefault();
        if (this.props.viewModel.isComXUser) {
            if (this.props.viewModel.validationBeforeSave(false, false)) {
                // Internal user save should follow normal draft save process
                this.props.viewModel.SaveCampaignMessages(() => {
                    this.props.back()
                }, false)
            }
        } else {
            if (this.props.viewModel.validationBeforeSave(false, true)) {
                // External user save should follow Ready for Client Review process
                this.props.viewModel.SaveCampaignMessages(() => {
                    this.props.back()
                }, false, true)
            }
        }
    }

    public render() {
        const dropdownStyle = {
            container: (styles: any) => {
                return {
                    ...styles,
                    borderRadius: '0px',
                }
            },
            clearIndicator: (styles: any) => {
                return {
                    ...styles,
                    color: '#111e1e',
                    ":hover": {
                        color: '@111e1e'
                    }
                }
            },
            menu: (styles: any) => {
                return {
                    ...styles,
                    zIndex: 1000
                }
            },
            multiValue: (styles: any) => {
                return {
                    ...styles,
                    backgroundColor: '#edeeee',
                    borderRadius: '15px'
                };
            },
            multiValueLabel: (styles: any) => ({
                ...styles,
                color: '#111e1e',
                size: '13px',
            }),
            multiValueRemove: (styles: any) => ({
                ...styles,
                color: '#111e1e',
                ':hover': {
                    backgroundColor: '#edeeee',
                    color: '#111e1e',
                    borderRadius: '15px',
                },
            }),
        }

        const invalidDropdownStyle = {
            container: (styles: any) => {
                return {
                    ...styles,
                    borderRadius: '0px',
                }
            },
            menu: (styles: any) => {
                return {
                    ...styles,
                    zIndex: 1000
                }
            },
            control: (styles: any) => {
                return {
                    ...styles,
                    borderColor: "#cb5151",
                    borderRadius: '0px',
                    ":hover": {
                        borderColor: '#FBA174 !important',
                        boxShadow: "0 0 0 0.2rem rgba(251, 161, 116, 0.25) !important"
                    },
                    ":active": {
                        borderColor: "#FBA174 !important",
                        boxShadow: "0 0 0 0.2rem rgba(251, 161, 116, 0.25) !important"
                    }
                }
            },
            clearIndicator: (styles: any) => {
                return {
                    ...styles,
                    color: 'red',
                    ":hover": {
                        color: 'red'
                    }
                }
            },
            multiValue: (styles: any) => {
                return {
                    ...styles,
                    backgroundColor: '#7CBFC5',
                    borderRadius: '15px'
                };
            },
            multiValueLabel: (styles: any) => ({
                ...styles,
                color: 'white',
            }),
            multiValueRemove: (styles: any) => ({
                ...styles,
                color: 'red',
                ':hover': {
                    backgroundColor: '#7CBFC5',
                    color: 'red',
                    borderRadius: '15px'
                },
            }),
        }

        return (
            <React.Fragment>
                {/* Back */}
                <Neo.Button
                    className="btn-width-100 mt-3 marginLeft24"
                    variant="light"
                    icon={<Icon.ArrowLeft />}
                    onClick={async (e: any) => {
                        this.props.back()
                    }}
                >
                    {textConstants.buttonText.Back}
                </Neo.Button>

                <UnsavedChanges
                    showConfirm={this.props.viewModel.isScreenDirty}
                    showPrompt={this.onEntry()}
                    backButtonPath={"ClientCampaignMessages/"}
                />


                <Neo.Card className='custom-scrollbar-expand'>
                    <div>
                        <div className="row mt-4 p-24">
                            <div className="col-12">
                                <h2>{textConstants.generalText.EditCampaignMessageSpaced}</h2>
                            </div>
                        </div>
                        <div className="row mt-4 p-24">
                            <div className="col-6">
                                <Neo.GridLayout md={2}>
                                    <Neo.FormGroup
                                        disabled={!this.props.viewModel.isComXUser}
                                        label={<>{textConstants.titleText.CampaignMessageName}<span className="validationRed">*</span></>}
                                        className={((this.props.viewModel.validationFirstCheckDone || this.props.viewModel.draftValidationFirstCheckDone) &&
                                            this.props.viewModel.campaignName.length === 0) ? "validation-border-red mb-0 CampaignMessageText" : "mb-0 CampaignMessageText"}
                                        bind={this.props.viewModel.meta.campaignName}
                                        onChange={() => this.props.viewModel.isScreenDirty = true}
                                    />
                                </Neo.GridLayout>
                                {(this.props.viewModel.validationFirstCheckDone || this.props.viewModel.draftValidationFirstCheckDone) &&
                                    this.props.viewModel.campaignName.length === 0 &&
                                    <p className={"validationRed validationFontSize"}>Campaign Name must be specified</p>
                                }
                            </div>
                        </div>
                        <div className="row mt-4 p-24">
                            <div className="col-6">

                                <Neo.GridLayout md={2} className={"mt-3"}>
                                    <Neo.FormGroup
                                        label={"Website/Landing Page"}
                                        className={((this.props.viewModel.validationFirstCheckDone || this.props.viewModel.draftValidationFirstCheckDone) &&
                                            this.props.viewModel.invalidLandingPageUrl) ? "validation-border-red mb-0 CampaignMessageText" : "mb-0 CampaignMessageText"}
                                        bind={this.props.viewModel.meta.landingPageUrl}
                                        onChange={() => this.props.viewModel.isScreenDirty = true}
                                    />

                                </Neo.GridLayout>
                                {(this.props.viewModel.validationFirstCheckDone || this.props.viewModel.draftValidationFirstCheckDone) &&
                                    this.props.viewModel.invalidLandingPageUrl &&
                                    <p className={"validationRed validationFontSize"}>The provided landing page URL is not a valid URL</p>
                                }
                            </div>
                        </div>
                        <div className="row mt-4 p-24">
                            <div className="col-10 CampaignMessageText">
                                <label>Target Markets<span className="validationRed">*</span></label>
                                {
                                    <span className='formatValidationIcon'>
                                        <Tooltip content="This section has mandatory fields that are missing or a field that has invalid data provided">
                                            <i
                                                className={((this.props.viewModel.validationFirstCheckDone || this.props.viewModel.draftValidationFirstCheckDone) &&
                                                    (this.props.viewModel.selectedTargetMarkets === null ||
                                                        this.props.viewModel.selectedTargetMarkets.length === 0)
                                                    || this.props.viewModel.invalidLandingPageUrl)
                                                    ? "fa fa-exclamation-circle ml-1 validationRed" : ""
                                                }
                                            />
                                        </Tooltip>
                                    </span>
                                }
                                <Neo.GridLayout md={2} >
                                    <Select
                                        styles={(this.props.viewModel.validationFirstCheckDone &&
                                            (this.props.viewModel.selectedTargetMarkets === null || this.props.viewModel.selectedTargetMarkets.length === 0))
                                            ? invalidDropdownStyle : dropdownStyle}
                                        value={
                                            this.props.viewModel.selectedTargetMarkets !== null
                                            ? this.props.viewModel.selectedTargetMarkets.map(t => {
                                                  if (t !== undefined) {
                                                      return {
                                                          value: t.targetMarketId,
                                                          label: t.targetMarketName,
                                                      };
                                                  } else {
                                                      return null;
                                                  }
                                              })
                                            : [] // Fallback value when selectedTargetMarkets is null
                                        }
                                        isMulti
                                        closeMenuOnSelect={false}
                                        onChange={(event) => this.handleChange(event)}
                                        options={this.props.viewModel.targetMarketOptions}
                                    />

                                </Neo.GridLayout>
                                {this.props.viewModel.validationFirstCheckDone &&
                                    (this.props.viewModel.selectedTargetMarkets === null ||
                                        this.props.viewModel.selectedTargetMarkets.length === 0) &&
                                    <p className={"validationRed validationFontSize"}>Must select at least one Target Market</p>
                                }
                            </div>
                        </div>
                    </div>

                    {/*Mapping of all the campaign messages (Includes the subject)*/}

                    <div>
                        <div className="row m-0 mb-4">
                            <div className="col-12 p-24">
                                {
                                    this.props.viewModel.campaignMessages!.map(cMessage =>
                                        cMessage.isSubject &&
                                        <CampaignMessageCard
                                            key={cMessage.ordinal}
                                            campaignMessageLookup={cMessage}
                                            isComXUser={this.props.viewModel.isComXUser}
                                            parentViewModel={this.props.viewModel} />)
                                }
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-12 p-24">
                                {this.props.viewModel.campaignMessages!.map(cMessage =>
                                    !cMessage.isSubject &&
                                    <CampaignMessageCard
                                        key={cMessage.ordinal}
                                        campaignMessageLookup={cMessage}
                                        isComXUser={this.props.viewModel.isComXUser}
                                        parentViewModel={this.props.viewModel} />)
                                }
                            </div>
                        </div>
                    </div>

                    {/*Save Buttons*/}
                    <div className="row m-0">
                        <div className="col-lg-6 p-0">
                            <div className={"row p-0 ml-0"}>
                                {/* Save as Draft */}
                                {!((this.props.viewModel.messageStatusId === this.props.viewModel.clientReviewStatusId
                                    || this.props.viewModel.messageStatusId === this.props.viewModel.activeStatusId)
                                    && this.props.viewModel.isComXUser) &&
                                    <Neo.Button
                                        variant="light"
                                        isOutline
                                        className="btn-light ml-4 mt-auto mb-auto"
                                        onClick={(e: any) => {
                                            this.saveAsDraft(e)
                                        }}>
                                        {textConstants.buttonText.SaveAsDraft}
                                    </Neo.Button>
                                }

                                {/* Ready for Client Review */}
                                {this.props.viewModel.isComXUser &&
                                    <StyledButton
                                        className="pull-right ml-4"
                                        variant="hasIcon"
                                        size="xSmall"
                                        onClick={() => {
                                            if (this.props.viewModel.validationBeforeSave(false, true)) {
                                                this.props.viewModel.showClientReviewModal = true
                                            }
                                        }}
                                    >
                                        {textConstants.buttonText.ReadyForClientReview}
                                    </StyledButton>
                                }

                                {/* Publish */}
                                <StyledButton
                                    className="pull-right ml-4"
                                    variant="hasIcon"
                                    size="xSmall"
                                    onClick={() => {
                                        if (this.props.viewModel.validationBeforeSave(true, false)) {
                                            { this.props.viewModel.showPublishModal = true }
                                        }
                                    }}
                                >
                                    {textConstants.buttonText.Publish}
                                </StyledButton>
                            </div>

                        </div>
                        <div className="col-lg-6"></div>
                    </div>
                </Neo.Card>

                {/* Invalid Data Modal */}
                <Neo.Modal title={textConstants.titleText.InvalidData} show={this.props.viewModel.showInvalidDataModal}
                    onClose={() => this.props.viewModel.showInvalidDataModal = false}
                    closeButton={{ text: "Close", variant: "light" }}
                >
                    {this.props.viewModel.invalidDataMessage.split('\n').map((item, i) => {
                        return <p key={i}>{item}</p>
                    })}
                </Neo.Modal>

                {/* Client Review or Publish Confirmation Modal */}

                <Neo.Modal title={this.props.viewModel.showClientReviewModal ? `Client review confirmation` : `Publish confirmation`}
                    show={this.props.viewModel.showClientReviewModal || this.props.viewModel.showPublishModal}
                    onClose={() => {
                        this.props.viewModel.showClientReviewModal = false
                        this.props.viewModel.showPublishModal = false
                    }}
                    closeButton={{ text: "No", variant: "light" }}
                    acceptButton={{
                        text: "Yes", variant: "secondary",
                        onClick: (e) => {
                            e.preventDefault();
                            this.props.viewModel.showClientReviewModal ?
                                this.props.viewModel.SaveCampaignMessages(() => { this.props.back() }, false, true) :
                                this.props.viewModel.SaveCampaignMessages(() => { this.props.back() }, true, false)
                        }
                    }}>
                    {this.props.viewModel.showClientReviewModal &&
                        <p>
                            {textConstants.generalText.ClientReviewAreYouSure}<br />
                        </p>}
                    {this.props.viewModel.showPublishModal &&
                        <p>
                            {textConstants.generalText.PublishAreYouSure}<br />
                        </p>
                    }
                </Neo.Modal>

                {/* Delete Comment Modal */}
                <Neo.Modal title={`Delete confirmation`} show={this.props.viewModel.showDeleteModal}
                    onClose={() => this.props.viewModel.showDeleteModal = false}
                    closeButton={{ text: "No", variant: "light" }}
                    acceptButton={{ text: "Yes", variant: "secondary", onClick: () => this.props.viewModel.DeleteComment() }}>
                    <p>
                        {textConstants.generalText.DeleteCommentAreYouSure}<br />
                        {textConstants.generalText.FailedToUndoComment}
                    </p>
                </Neo.Modal>

                {/* Delete Denied Modal */}
                <Neo.Modal
                    title="Delete Denied"
                    show={this.props.viewModel.showDeleteDeniedModal}
                    onClose={() => this.props.viewModel.showDeleteDeniedModal = false}
                    closeButton={{ text: "Close", variant: "light" }}>
                    <p>{textConstants.messageText.validationMessage.commentDeleteDenied}</p>
                </Neo.Modal>
            </React.Fragment>
        );
    }
}