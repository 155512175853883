import styled from 'styled-components';

export const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0rem;
  padding: 1rem;
  box-sizing: border-box;
`;

export const StyledPaper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  @media (min-width: 1024px) {
    width: 50%;
  }
`;

export const StyledSvgWrapper = styled.div`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  text-align: center;

  img {
    width: 16vw;
  }
`;

export const StyledSubTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #818888;
  margin-top: 24px;
`;

export const StyledTitle = styled.div`
  color: #0b0b0c;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  margin: 24px 0;
`;

export const StyledParagrapgh = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #0b0b0c;
  margin-bottom: 24px;
`;

export const StyledLink = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #0b0b0c;
  margin: 0 0 24px 0;
  cursor: pointer;
  svg {
    margin-left: 4px;
    vertical-align: middle;
  }
`;

interface IStyledButton {
  color?: 'primary' | 'secondary' | 'default';
}

export const StyledButton = styled.a<IStyledButton>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  cursor: pointer;
  width: 100%;
  height: 56px;
  background: ${({ color }) => {
    switch (color) {
      case 'primary':
        return '#FF6731';
      case 'secondary':
        return '#002648';
      default:
        return '#0B0B0C';
    }
  }};
  border-radius: 8px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  color: #ffffff;
  &:hover {
    color: #fff;
    text-decoration: none;
    opacity: 0.8;
  }
`;

export const StyledStepCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: #f8f8f8;
  border-radius: 8px;
  flex: none;
  margin-bottom: 16px;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const StyledStepCardTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: #818888;
`;

export const StyledStepCardHeading = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 16px;
  color: #0b0b0c;
`;

export const StyledStepCardDescription = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  a {
    text-decoration-line: underline;
    color: #7f92a3;
  }
  color: #7f92a3;
`;
