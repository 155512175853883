import React from 'react';
import { StyledInput, StyledInputWrapper, StyledFieldWrapper, EndIcon, StartIcon } from './styles';
import { IInputProps } from './types';

const Input = ({
    error,
    disabled,
    size,
    fullWidth,
    multiline,
    rows,
    rowsMax,
    startIcon,
    endIcon,
    inputProps,
    inputRef,
    type,
    placeholder,
    value,
    label,
    htmlFor,
    ...props
}: IInputProps) => {
    return (
    <StyledInputWrapper
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        startIcon={startIcon}
        endIcon={endIcon}
        inputProps={inputProps}
        inputRef={inputRef}
    >
        {label && <label htmlFor={htmlFor}>{label}</label>}
        <StyledFieldWrapper>
        {startIcon
            ? <StartIcon>
                {startIcon}
            </StartIcon>
            : null
        }
        <StyledInput
            type={type}
            placeholder={placeholder}
            value={value}
            error={error}
            disabled={disabled}
            fullWidth={fullWidth}
            {...props}
        />
        {endIcon
            ? <EndIcon>
                {endIcon}
            </EndIcon>
            : null
        }
        </StyledFieldWrapper>
    </StyledInputWrapper>);
};

export default Input;