import {ModelBase, NeoModel} from '@singularsystems/neo-core';


@NeoModel
export default class SubRegionLookup extends ModelBase {

    // Properties
    public subRegionId: number = 0;
    public regionSubRegionId: number = 0;
    public subRegionName: string = "";
    public isSelected: boolean = false;
}