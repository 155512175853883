import { ModelBase, NeoModel, Attributes } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class LPCLookup extends ModelBase {

  @Attributes.Display(textConstants.titleText.Order)
  public order: number = 0

  @Attributes.Display(textConstants.titleText.SearchText)
  public searchText: string = "";
}