import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import DashboardCardVM from './DashboardCardVM';
import DashboardCard from '../../../Models/Maintenance/DashboardCard';
import { textConstants } from '../../../common/textConstants';
import RichTextEditor from '../../../Components/RichTextEditor';
import ComXConfiguration from '../../../Models/Maintenance/ComXConfiguration';
import * as Icon from 'react-feather';
import { DashboardCards } from 'Models/Enums/ApplicationEnums';

export interface IDashboardCardIndividualScreenViewProps {
    viewModel: DashboardCardVM,
    dashboardCard: DashboardCard,
    comxConfig?: ComXConfiguration,
    save(): void
}

@observer
export default class DashboardCardIndividualScreenView extends React.Component<IDashboardCardIndividualScreenViewProps> {
    constructor(props: any) {
        super(props);
    }

    private tempUpdatedHtml: string = "";
    private textEditorText: string = "";
    private isHeaderText: boolean = false;
    private isCardText: boolean = false;

    public async uploadDashboardNewsCard(fileList: File[] | FileList) {
        await this.props.viewModel.uploadImage(fileList, DashboardCards.HighlyRecommended);
    }

    public async getDashboardNewsCard() {
        await this.props.viewModel.getImage(DashboardCards.HighlyRecommended)
    }

    public render() {
        return (
            <div className='onboarding-step-menu-data'>
                <Neo.Form onSubmit={() => this.props.save()}>
                    <div className='row m-0'>
                        <h3 className='col-12 mt-3 mb-3 p-24 pt-0'>{this.props.viewModel.dashboardCard.cardName}</h3>
                        {/* Header text */}
                        <div className='col-12 p-24'>
                            {this.props.dashboardCard.headerText !== null &&
                                <div className='row'>
                                    <div className="col-sm-1 pt-2 pb-2">Header Text</div>
                                    <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.dashboardCard.meta.headerText} disabled={true} suppressLabel />
                                    <div className="col-sm-1 text-center">
                                        <Neo.Button
                                            className="btn-width-100"
                                            variant="light"
                                            onClick={() => {
                                                this.props.viewModel.showUpdateTextModal = true;
                                                this.textEditorText = this.props.dashboardCard.headerText
                                                this.isHeaderText = true;
                                                this.isCardText = false;
                                            }}>
                                            {textConstants.buttonText.Edit}
                                        </Neo.Button>
                                    </div>
                                </div>
                            }

                            {/* card text */}
                            <div className='row'>
                                <div className="col-sm-1 pt-2 pb-2">Card Text</div>
                                <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.dashboardCard.meta.cardText} disabled={true} suppressLabel />
                                <div className="col-sm-1 text-center">
                                    <Neo.Button
                                        className="btn-width-100"
                                        variant="light"
                                        onClick={() => {
                                            this.props.viewModel.showUpdateTextModal = true;
                                            this.textEditorText = this.props.dashboardCard.cardText
                                            this.isHeaderText = false;
                                            this.isCardText = true;
                                        }}>
                                        {textConstants.buttonText.Edit}
                                    </Neo.Button>
                                </div>
                            </div>

                            {this.props.dashboardCard.videoLink !== null &&
                                <div className='row'>
                                    {/* video link */}

                                    <div className="col-sm-1 pt-2 pb-2">Video Link</div>
                                    <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.dashboardCard.meta.videoLink} suppressLabel />
                                    <div className='col-sm-1'></div>
                                </div>
                            }

                            {this.props.dashboardCard.redirectLink !== null &&
                                <div className='row'>
                                    {/* redirect link */}

                                    <div className="col-sm-1 pt-2 pb-2">Redirect Link</div>
                                    <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.dashboardCard.meta.redirectLink} suppressLabel />
                                    <div className='col-sm-1'></div>
                                </div>
                            }

                            {this.props.comxConfig !== undefined &&
                                <div className='row'>
                                    {/* password link */}

                                    <div className="col-sm-1 pt-2 pb-2">Password</div>
                                    <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.comxConfig.meta.value} suppressLabel />
                                    <div className='col-sm-1'></div>
                                </div>
                            }

                            {this.props.dashboardCard.dashboardCardId === 2 &&
                                <>
                                    <div className='row m-0'>
                                        <div className='pl-0 col-1 pr-0'>Update Visibility</div>
                                        <Neo.FormGroup bind={this.props.viewModel.dashboardCard.meta.showDashboardNews} suppressLabel
                                            input={{ type: "checkbox" }} />
                                    </div>
                                    <div className='row m-0'>
                                        <div className='col p-0'>
                                            <Neo.FileContext onFilesSelected={(e) => this.uploadDashboardNewsCard(e)} allowedExtensions={[".jpg", ".jpeg", ".png"]} accept="image/*">
                                                <Neo.FileDropArea multiple={false}>
                                                    <p className="text-center dropZoneText">
                                                        <Neo.FileUploadButton>
                                                            <Icon.Upload />
                                                            {textConstants.generalText.uploadOrDragFile}
                                                        </Neo.FileUploadButton>
                                                    </p>
                                                </Neo.FileDropArea>
                                            </Neo.FileContext>
                                        </div>
                                    </div>
                                    {this.props.viewModel.imageData &&
                                        <div className='pt-3'>
                                            <img className="dashboard-card-image" src={`data:image/jpeg;base64,${this.props.viewModel.imageData}`} alt="Dashboard News Image" />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className="my-4 text-right p-24">
                        <Neo.Button className="btn-width-100" isSubmit variant="success">
                            {textConstants.buttonText.Save}
                        </Neo.Button>
                    </div>

                </Neo.Form>

                {/*Edit modals*/}
                <Neo.Modal
                    title={`Update Display Text`}
                    className={"modal-style-test"}
                    show={this.props.viewModel.showUpdateTextModal}
                    onClose={() => this.props.viewModel.showUpdateTextModal = false}
                    closeButton={{ text: "Cancel", variant: "light" }}
                    acceptButton={{
                        text: "Done", variant: "secondary",
                        onClick: () => {
                            if (this.isHeaderText) { this.props.dashboardCard.headerText = this.tempUpdatedHtml }
                            if (this.isCardText) { this.props.dashboardCard.cardText = this.tempUpdatedHtml }

                            this.props.viewModel.showUpdateTextModal = false
                        }
                    }}
                    formProps={{ model: this.props.dashboardCard }}>
                    <div>
                        <RichTextEditor
                            initialString={this.textEditorText}
                            getHtmlText={(htmlText: string) => {
                                this.tempUpdatedHtml = htmlText;
                            }} />
                    </div>
                </Neo.Modal>
            </div >
        );
    }
}