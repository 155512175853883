import { NeoModel, ValueObject } from "@singularsystems/neo-core";

@NeoModel
export default class LeadTypeStatus extends ValueObject
{
    public leadTypeStatusId: number = 0;

    public leadTypeId: number = 0;

    public actionListStatusId: number = 0;
}