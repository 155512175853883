import { textConstants } from './../../../common/textConstants';
import { ModelBase, NeoModel, Rules, Attributes, Validation } from '@singularsystems/neo-core';
import { isStringBlank } from '../../../common/utils';

@NeoModel
export default class EditMasterAccountCommand extends ModelBase {

  @Rules.Required()
  public masterAccountId: number = 0;

  public fixedName: string = "";

  @Rules.Required()
  // @Rules.StringLength(500)
  public originalName: string = "";

  @Rules.Required()
  // @Rules.StringLength(500)
  public location: string = "";

  @Rules.Required()
  @Attributes.Display(textConstants.titleText.NumberOfEmployees)
  public employeeSizeId: number = 0;

  @Rules.Required()
  @Attributes.Display(textConstants.titleText.Industry)
  public subIndustryId: number = 0;

  public domain: string = "";

  @Rules.Required()
  @Attributes.Display(textConstants.titleText.Status)
  public accountStatusId: number = 0;
 
  @Attributes.Display(textConstants.titleText.InvalidReason)
  public invalidReasonId: number = 0;

  public isDeleted: boolean = false
  
  @Attributes.Display("Region")
  public subRegionId: number = 0

  public addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
    rules.failWhen(c =>isStringBlank(c.fixedName), textConstants.messageText.validationMessage.fixedNameErrorMessage);
    rules.failWhen(c =>isStringBlank(c.originalName), textConstants.messageText.validationMessage.originalNameErrorMessage);
    rules.failWhen(c =>isStringBlank(c.location), textConstants.messageText.validationMessage.locationErrorMessage);
    rules.failWhen(c => c.isDeleted && c.invalidReasonId === 0, textConstants.messageText.validationMessage.invalidreasonErrorMessage);
  }
}