import { NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../Services/AppService";
import CommandResult from "../../Models/InvitedUsers/Commands/CommandResult";
import { textConstants } from "../../common/textConstants";
import EditClient from "../../Models/Client/Commands/EditClient";


type bulkActions = "BulkDelete" | ""

@NeoModel
export default class AddClientVM extends Views.ViewModelBase {
  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private clientsApiClient = AppService.get(
      Types.ApiClients.ClientsApiClient
    ),
    private calendlyLinksApiClient = AppService.get(Types.ApiClients.CalendlyLinksApiClient),
    public appDataCache = AppService.get(Types.Services.AppDataCache)
  ) {
    super(taskRunner);
  }
  public selectedTab: string = textConstants.titleText.Client;

  
  public clientDetails = new EditClient();
  public clientId: number = 0;
  

  public showInvalidDataModal: boolean = false;
  public invalidDataMessage: string = "";
  public showForbiddenModal: boolean = false;
  public columnHeadings: string[] = [textConstants.titleText.Domain];
  public clientName: string = "";
  public calendlyLinks = this.appDataCache.calendlyLinks.get().data;
  public doNotBlacklistTooltip: string = "Do Not Blacklist Other Prospects After Reply";

  public async initialise() {

    if(this.calendlyLinks.length === 0){
      let calendlyLinksResponse = await this.calendlyLinksApiClient.get();

      if(calendlyLinksResponse.data){
        this.calendlyLinks = calendlyLinksResponse.data
      }
    }
  }

  public fetchClient(clientId: number) {

    this.taskRunner.run(async () => {
      const clientDetailsResponse = await this.taskRunner.waitFor(
        this.clientsApiClient.getClientForEdit(clientId)
      );
      this.clientDetails.set(clientDetailsResponse.data);
    });
  }

  public save(navigate: () => void) {
    this.runValidationChecks();
    if (this.showInvalidDataModal) {
      return;
    }

    this.taskRunner.run(async () => {
      const response = await this.clientsApiClient.addClient(
        this.clientDetails
      );

      const cr: CommandResult = response.data as CommandResult;
      
      if (cr.success) {
        navigate();
        this.notifications.addSuccess(
          textConstants.titleText.Saved,
          textConstants.messageText.saveMessage.ClientSaved
        );
      
      }
    });
  }

  // Method to validate complete URLs
  public isURL(str: string) {
    let regex: RegExp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (
      regex.test(str) &&
      (str.indexOf("https://") !== -1 || str.indexOf("http://") !== -1)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public runValidationChecks() {
    this.showInvalidDataModal = false;
    this.invalidDataMessage = "";
    
    // Platform Setup
    if (
      this.clientDetails.platformSetup !== "" &&
      this.clientDetails.platformSetup !== null &&
      !this.isURL(this.clientDetails.platformSetup)
    ) {
      this.invalidDataMessage =
        textConstants.messageText.validationMessage.invalidPlatformSetup + "\n";
      this.showInvalidDataModal = true;
  
    }

    if (
      this.clientDetails.actionPlanChecklist &&
      !this.isURL(this.clientDetails.actionPlanChecklist)
    ) {
      this.invalidDataMessage +=
        textConstants.messageText.validationMessage.invalidActionPlanChecklist +
        "\n";
      this.showInvalidDataModal = true;
    }

    // Success Tracking
    if (
      this.clientDetails.successTracking !== "" &&
      this.clientDetails.successTracking !== null &&
      !this.isURL(this.clientDetails.successTracking)
    ) {
      this.invalidDataMessage +=
        textConstants.messageText.validationMessage.invalidSuccessTracking +
        "\n";
      this.showInvalidDataModal = true;
    }
  }


  public removeNonNumerics() {
    if (isNaN(this.clientDetails.maxTargetMarkets)) {
      this.clientDetails.maxTargetMarkets = 0;
    }
  }


  public preventSpaceBarUpdate(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === " ") {
      event.preventDefault();
    }
  }
}
