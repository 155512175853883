import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import InformationManagement from '../../Models/Maintenance/InformationManagement';
import InformationManagementCommand from '../../Models/Maintenance/InformationManagementCommand';
import InformationManagementDetail from '../../Models/Maintenance/InformationManagementDetail';
import { AppService, Types } from '../../Services/AppService';

@NeoModel
export default class InformationManagementVM extends Views.ViewModelBase {
    
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private informationManagementApiClient = AppService.get(Types.ApiClients.InformationManagementApiClient),
        private technicalSettingsConfigApiClient = AppService.get(Types.ApiClients.TechnicalSettingsConfigApiClient),
        private emailProverTypesApiClient = AppService.get(Types.ApiClients.EmailProviderTypesApiClient),
        private calendlyLinksApiClient = AppService.get(Types.ApiClients.CalendlyLinksApiClient),

        private appDataCache = AppService.get(Types.Services.AppDataCache)) {

        super(taskRunner);
    }

    //Properties

    public showUpdateTextModal: boolean = false;
    public showTestEmailTextModal: boolean = false;
    public showTestEmailTextHeaderModal: boolean = false;
    public showCRMTextModal: boolean = false;
    public showCRMVideoTextModal: boolean = false;
    public showMailtToTextModal: boolean = false;
    public showActionPlanTextModal : boolean = false
    public showTermsOfServiceTextModal : boolean = false
    public informationManagementCommand = new InformationManagementCommand();

    public informationManagement = new InformationManagement()
    public tempUpdatedHtml : string = ""
    public Google: boolean = false
    public Microsoft: boolean = false
    public IBM: boolean = false
    public Other: boolean = false
    public selectedText : string =""

    public async initialise() {
        const infoManagementResponse = await this.taskRunner.waitFor(this.informationManagementApiClient.get());
        const technicalSettingsResponse = await this.taskRunner.waitFor(this.technicalSettingsConfigApiClient.get());
        const emailProvidersResponse = await this.taskRunner.waitFor(this.emailProverTypesApiClient.get());
        const calendlyLinksResponse = await this.taskRunner.waitFor(this.calendlyLinksApiClient.get())

        this.informationManagement.informationManagementDetails.set(infoManagementResponse.data);
        this.informationManagement.technicalSettings.set(technicalSettingsResponse.data[0])
        this.informationManagement.emailProviderTypes.set(emailProvidersResponse.data)
        this.informationManagement.calendlyLinks.set(calendlyLinksResponse.data)
    }

    public async saveInformationManagement() {
        this.taskRunner.run(async () => {
            const infoManagementResponse = await this.informationManagementApiClient.saveList(this.informationManagement.informationManagementDetails.toJSArray())
            const technicalSettingsResponse = await this.technicalSettingsConfigApiClient.save(this.informationManagement.technicalSettings.toJSObject())
            const emailProvidersResponse = await this.emailProverTypesApiClient.saveList(this.informationManagement.emailProviderTypes.toJSArray())
            const calendlyLinksResponse = await this.calendlyLinksApiClient.saveList(this.informationManagement.calendlyLinks.toJSArray())

            this.informationManagement.informationManagementDetails.update(infoManagementResponse.data)
            this.informationManagement.technicalSettings.update(technicalSettingsResponse.data)
            this.informationManagement.emailProviderTypes.update(emailProvidersResponse.data)
            this.informationManagement.calendlyLinks.update(calendlyLinksResponse.data)

            this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.ConfigSaved)

            // Refresh the comXConfiguration common data list
            this.appDataCache.comXConfigurations.expire();
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }

    public showUpdateModal(rowInfo: InformationManagementDetail) {
        this.showUpdateTextModal = true;
        this.informationManagementCommand.informationManagementDetailId = rowInfo.informationManagementDetailId;
        this.informationManagementCommand.displayText = rowInfo.displayText;
    }

    public saveDisplayText() {
        const rowToUpdate = this.informationManagement.informationManagementDetails.find((id) => id.informationManagementDetailId === this.informationManagementCommand.informationManagementDetailId);
        if (rowToUpdate) {
            rowToUpdate.displayText = this.informationManagementCommand.displayText;
        }
        this.showUpdateTextModal = false;
    }

    public runValidationChecks(){

    }
}