import { List, ModelBase, NeoModel } from "@singularsystems/neo-core";
import type { ITaskRunner } from "@singularsystems/neo-core";
import type { INotificationHelper } from '@singularsystems/neo-core/dist/Components';
import type { ICampaignMessagesApiClient } from '../../ApiClients/CampaignMessagesApiClient';
import CampaignMessageLookup from "../../Models/CampaignMessage/CampaignMessageLookup";
import SaveSignatureCommand from "../../Models/CampaignMessage/Commands/SaveSignatureCommand";
import { textConstants } from "../../common/textConstants";
import SaveCommentCommand from "../../Models/CampaignMessage/Commands/SaveCommentCommand";
import CampaignMessageCommentLookup from "../../Models/CampaignMessage/CampaignMessageCommentLookup";
import DeleteCommentCommand from "../../Models/CampaignMessage/Commands/DeleteCommentCommand";

@NeoModel
export default class CampaignEmailSignatureHelper extends ModelBase {
    constructor(
        public taskRunner: ITaskRunner,
        public campaignMessagesApiClient: ICampaignMessagesApiClient,
        public notifications: INotificationHelper,
        public clientId: number
    ) {
        super();
        this.taskRunner = taskRunner
        this.campaignMessagesApiClient = campaignMessagesApiClient
        this.notifications = notifications
        this.clientId = clientId
    }

    //   public deleteCommentCommand = new DeleteComment();
    public campaignMessageLookup: CampaignMessageLookup = new CampaignMessageLookup()
    public saveSignatureCommand = new SaveSignatureCommand()
    public saveCommentCommand = new SaveCommentCommand()
    public deleteCommentCommand = new DeleteCommentCommand()
    public commentsLookup = new List(CampaignMessageCommentLookup)
    public isComXUser: boolean = false
    public showDeleteModal: boolean = false
    public showDeleteDeniedModal: boolean = false
    public showInvalidDataModal: boolean = false;
    public invalidDataMessage: string = ""
    public showClientReviewModal: boolean = false
    public showPublishModal: boolean = false

    public isScreenDirty: boolean = false;
    public isSaving: boolean = false;
    public isCommentDirty: boolean = false;
    public clientCampaignMessageStatusId: number = 0;

    public validationFirstCheckDone: boolean = false;
    public draftValidationFirstCheckDone: boolean = false;

    public DeleteComment() {
        this.taskRunner.run(async () => {
            const deleteResponse = await this.campaignMessagesApiClient.deleteComment(this.deleteCommentCommand);

            if (deleteResponse.data.success) {
                this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.CommentDelete);
                this.showDeleteModal = false

                const commentsResponse = await this.taskRunner.waitFor(this.campaignMessagesApiClient.getComments(this.campaignMessageLookup.clientCampaignMessageId));

                this.commentsLookup.set(commentsResponse.data)
            }

            this.showDeleteModal = false
        });
    }

    public validationBeforeSave(isPublish: boolean = false, isClientReview: boolean = false): boolean {
        // If it is Save as Draft
        if ((isClientReview === false && isPublish === false && this.isComXUser) || (isClientReview === true && isPublish === false && !this.isComXUser)) {
            // And an Internal user - external user has no validation
            this.runDraftValidationCheck();
        } else {
            this.runAllValidationChecks();
        }
        if (this.showInvalidDataModal) return false;
        else return true;
    }

    public async SaveEmailSignature(navigate: () => void, isPublish: boolean, isClientReview: boolean) {

        if (this.showInvalidDataModal) { return }
        this.populateSaveCommand(isPublish, isClientReview);
        this.showClientReviewModal = false
        this.showPublishModal = false

        this.taskRunner.run(async () => {
            const saveResponse = await this.campaignMessagesApiClient.saveEmailSignature(this.saveSignatureCommand);
            if (saveResponse.data.success) {
                this.isSaving = true;
                if (isPublish) this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.EmailSignatureSavedAndPublished);
                else if (isClientReview) this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.EmailSignatureSavedAndClientReview);
                else this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.EmailSignatureSaved);
                navigate();
            }
        });
    }

    private populateSaveCommand(isPublish: boolean, isClientReview: boolean) {
        this.saveSignatureCommand.campaignMessageId = this.campaignMessageLookup.campaignMessageId;
        this.saveSignatureCommand.clientCampaignMessageId = this.campaignMessageLookup.clientCampaignMessageId;
        this.saveSignatureCommand.clientId = this.clientId;
        this.saveSignatureCommand.campaignName = this.campaignMessageLookup.campaignName;
        this.saveSignatureCommand.messageText = this.campaignMessageLookup.messageText;
        this.saveSignatureCommand.draftText = this.campaignMessageLookup.draftText;
        this.saveSignatureCommand.isPublish = isPublish;
        this.saveSignatureCommand.isClientReview = isClientReview;
        this.saveSignatureCommand.clientCampaignMessageStatusId = this.clientCampaignMessageStatusId
    }

    public setupNewCampaignSignature(isComXUser: boolean, clientId: number) {
        this.isComXUser = isComXUser
        this.clientId = clientId
        this.campaignMessageLookup.campaignMessageType = "Email Signature"
    }

    public async fetchCampaignSignature(isComXUser: boolean, clientCampaignMessageId: number) {
        this.isComXUser = isComXUser
        this.campaignMessageLookup.clientCampaignMessageId = clientCampaignMessageId

        const signatureResponse = await this.taskRunner.waitFor(this.campaignMessagesApiClient.getEmailSignature(clientCampaignMessageId));
        const commentsResponse = await this.taskRunner.waitFor(this.campaignMessagesApiClient.getComments(clientCampaignMessageId));

        this.campaignMessageLookup.set(signatureResponse.data)
        this.commentsLookup.set(commentsResponse.data)
        this.campaignMessageLookup.currentComment = ""
    }


    public async SaveComment() {
        this.runAllValidationChecks(true)
        if (this.showInvalidDataModal) { return }

        this.populateSaveCommentCommand();

        this.taskRunner.run(async () => {
            const saveResponse = await this.campaignMessagesApiClient.saveComment(this.saveCommentCommand);

            if (saveResponse.data.success) {
                this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.CommentSaved);

                const commentsResponse = await this.taskRunner.waitFor(this.campaignMessagesApiClient.getComments(this.campaignMessageLookup.clientCampaignMessageId));

                this.commentsLookup.set(commentsResponse.data)

                this.campaignMessageLookup.currentComment = ""

                this.isCommentDirty = false
            }
        });
    }

    private populateSaveCommentCommand() {
        this.saveCommentCommand.campaignMessageId = this.campaignMessageLookup.campaignMessageId;
        this.saveCommentCommand.comment = this.campaignMessageLookup.currentComment;
        this.saveCommentCommand.campaignName = this.campaignMessageLookup.campaignName
        this.saveCommentCommand.isEmailSignature = true
    }

    public openDeleteModal(commentId: number) {
        this.deleteCommentCommand.campaignMessageCommentId = commentId;
        this.showDeleteModal = true
    }

    public async allowCommentDeletion(commentId: number) {
        const response = await this.campaignMessagesApiClient.allowCommentDeletion(commentId)
        const result = response.data as boolean
        return result
    }

    public runDraftValidationCheck() {
        this.draftValidationFirstCheckDone = true
        this.showInvalidDataModal = false
        this.invalidDataMessage = ""

        if (this.campaignMessageLookup.campaignName === "" || this.campaignMessageLookup.campaignName === null) {
            this.showInvalidDataModal = true
            this.invalidDataMessage = "Campaign Name must be specified" + "\n"
        }
    }

    public runAllValidationChecks(isComment?: boolean) {
        this.validationFirstCheckDone = true
        this.showInvalidDataModal = false
        this.invalidDataMessage = ""

        if (isComment) {
            if (this.campaignMessageLookup.currentComment === "" || this.campaignMessageLookup.currentComment === null) {
                this.showInvalidDataModal = true
                this.invalidDataMessage = "Comment must be specified"
            }
        } else {

            if (this.campaignMessageLookup.campaignName === "" || this.campaignMessageLookup.campaignName === null) {
                this.showInvalidDataModal = true
                this.invalidDataMessage = "Campaign Name must be specified" + "\n"
            }

            if (this.campaignMessageLookup.draftText === "" || this.campaignMessageLookup.draftText === null || this.campaignMessageLookup.draftText === "<br>") {
                this.showInvalidDataModal = true
                this.invalidDataMessage += "ComX Suggestion must be specified"
            }
        }
    }
}
