import React from "react";

const Tool = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.80088 4.19722C9.67873 4.32184 9.61031 4.48938 9.61031 4.66389C9.61031 4.83839 9.67873 5.00593 9.80088 5.13055L10.8675 6.19722C10.9922 6.31937 11.1597 6.38779 11.3342 6.38779C11.5087 6.38779 11.6763 6.31937 11.8009 6.19722L14.3142 3.68389C14.6494 4.42468 14.7509 5.25004 14.6052 6.04998C14.4594 6.84992 14.0734 7.58645 13.4984 8.16141C12.9234 8.73636 12.1869 9.12244 11.387 9.26819C10.587 9.41394 9.76167 9.31244 9.02088 8.97722L4.41421 13.5839C4.149 13.8491 3.78929 13.9981 3.41421 13.9981C3.03914 13.9981 2.67943 13.8491 2.41421 13.5839C2.149 13.3187 2 12.959 2 12.5839C2 12.2088 2.149 11.8491 2.41421 11.5839L7.02088 6.97722C6.68566 6.23643 6.58416 5.41106 6.72991 4.61112C6.87566 3.81118 7.26174 3.07466 7.83669 2.4997C8.41165 1.92474 9.14818 1.53867 9.94812 1.39291C10.7481 1.24716 11.5734 1.34866 12.3142 1.68389L9.80755 4.19055L9.80088 4.19722Z"
      stroke="#002648"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Tool;
