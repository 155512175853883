import React from 'react';

import { observer } from 'mobx-react';

interface IOverlayProps {
    children: React.ReactNode
    isHidden: boolean
    className?: string
}

@observer
class ModalOverlay extends React.Component<IOverlayProps> {
    constructor(props: IOverlayProps) {
        super(props);
    }
    render() {
        const {children, isHidden, className} = this.props
        return (
             
            !isHidden && 
            null || isHidden && 
            <div className={`${className} ModalOverlay`}>           
                <div className="overlay-content">
                    {children}
                </div>
            </div> 
        );
    }
}

export default ModalOverlay;
