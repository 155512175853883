import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'reactstrap';
import * as Icon from 'react-feather';
import { textConstants } from 'common/textConstants';
import ListSelectorModalLookup from './ListSelectorModalLookup';
import { List } from '@singularsystems/neo-core';
import ListSelectorModalSubListLookup from './ListSelectorModalSubListLookup';
import { Neo } from '@singularsystems/neo-react';
import { deselectItem, getSelectedNumberOfItems, togglePriority, validateNotPriority, validatePriority } from 'Components/OnboardingComponents/ListSelectorHelper';
import DataTooltip from 'Components/DataTooltip/DataTooltip';

interface IListSelectorModalProps {
    isOpen: boolean,
    closeModal?: () => void,
    onListReturn: (selectList: List<ListSelectorModalLookup>, clonedList: List<ListSelectorModalLookup>) => void,
    modalList: List<ListSelectorModalLookup>,
    modalTitle: string,
    priorityChanged?: (prioritySelected: boolean) => void,
    potentialAccounts?: number,
    calculatePotentialAccounts?: (selectedList: List<ListSelectorModalLookup>) => void
}

@observer
export default class ListSelectorModal extends React.Component<IListSelectorModalProps> {

    state = {
        isOpen: this.props.isOpen,
        searchString: ""
    };

    constructor(props: IListSelectorModalProps) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
    }

    // Method called to return selected items back to the parent component
    public returnSelectedList() {
        this.props.onListReturn(this.selectedModalList, this.props.modalList)
        this.toggleModal()
    }

    toggleModal() {
        this.setState({
            isOpen: !this.state.isOpen,
        });

        if (this.props.closeModal) {
            this.clearSearch();
            this.props.closeModal();
        }
    }

    handleChange = (event: any) => {
        const newSearchString = event.target.value;
        this.setState({ searchString: newSearchString });
        this.searchString = newSearchString.toLocaleUpperCase();
        this.mapSelectItems()
    }

    clearSearch = () => {
        this.setState({ searchString: "" });
        this.searchString = "";
        this.mapSelectItems();
        const searchTextElement = document.getElementById("searchText") as HTMLInputElement;
        if (searchTextElement) {
            searchTextElement.value = "";
        }
    };

    public searchString: string = "";

    // when items in the modal have a priority, we use this for validation
    public displayPriorityError: boolean = false;

    // when items in the modal DO NOT have a priority, we use this for validation
    public displayNonPriorityError: boolean = false;

    public selectedModalList = new List(ListSelectorModalLookup)

    // Method for when the user adds a main item (meaning all sub-items are also to be selected)
    public selectItem(item: ListSelectorModalLookup, subItem: ListSelectorModalSubListLookup | null) {

        // Select All 
        if (subItem === null) {
            // we set all the sub items to have been selected
            item.subList.forEach(subItem => {
                subItem.isSelected = true
                item.isPriority = item.isPriority === null ? null : false
            })
        } else {
            // since we know we are selecting a subItem (not a select all) we set that subItem to be selected
            subItem.isSelected = true;
        }

        // if the main item if not already selected then we add it to the selected column
        if (!this.selectedModalList.find(selectedItem => selectedItem.itemId === item.itemId)) {
            item.isSelected = true
            item.isSelectedExpanded = item.isExpanded
            this.selectedModalList.push(item)
        }

        this.forceUpdate()
    }

    // When we first open the modal, we need to have existing selected items in the selected column, this function does this for us
    public setInitialSelectedList(item: ListSelectorModalLookup) {

        if (!this.selectedModalList.find(selectedItem => selectedItem.itemId === item.itemId)) {
            this.selectedModalList.push(item)
        }

    }

    // (Right column) Main items that you have selected and can deselect from
    public setSelectedList() {
        let listItems =
            (
                <div className='mt-3'>
                    {this.selectedModalList.sortBy(i => i.itemName).map((item) => (
                        <React.Fragment key={item.itemId}>
                            <div className='mb-4' >

                                {/* List Header */}
                                <div className='row p-0'>
                                    <div className='col-12 pr-0'>

                                        <Icon.MinusCircle
                                            className='mr-2'
                                            onClick={() => {
                                                deselectItem(this.selectedModalList, item, null)
                                                this.selectedModalList.remove(item)
                                                this.props.calculatePotentialAccounts && this.props.calculatePotentialAccounts(this.selectedModalList)
                                                this.forceUpdate()
                                            }} />

                                        {/* Selected Item Name  */}
                                        <span
                                            className='itemCollapsible'
                                            onClick={() => {
                                                item.isSelectedExpanded = !item.isSelectedExpanded
                                            }}>
                                            {item.itemName.toUpperCase()}
                                        </span>

                                        {/* Priority Switch Section */}
                                        {item.isPriority !== null &&
                                            <div className='pull-right mr-4'>
                                                <div className='row p-0'>
                                                    <DataTooltip
                                                        parent={
                                                            <Icon.AlertCircle className='p-1' />
                                                        }
                                                        type='hint'
                                                        variant='dark'
                                                        direction='down'>
                                                        {textConstants.generalText.priorityHint}
                                                    </DataTooltip>

                                                    <span className='mr-2 priorityText'>Priority all</span>
                                                    <Neo.FormGroup
                                                        bind={item.meta.isPriority}
                                                        suppressLabel={true}
                                                        input={{ type: "switch" }}
                                                        onChange={() => {
                                                            togglePriority(item, null)
                                                            this.displayPriorityError = !validatePriority(this.selectedModalList)
                                                            this.forceUpdate()
                                                            this.props.priorityChanged && this.props.priorityChanged(!this.displayPriorityError)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>

                                {/* Line between items */}
                                <div className='itemHeaderContainer mt-3 mb-2'></div>

                                {/* Selected sub items */}
                                {item.isSelectedExpanded &&
                                    this.setSelectedSubList(item)
                                }

                            </div>
                        </React.Fragment>
                    ))}
                </div>
            );

        return listItems
    }

    // (Right column) Sub items (children of selected Items) that you have selected and can deselect
    public setSelectedSubList(item: ListSelectorModalLookup) {
        return <div className='mb-3 col-12 p-0'>
            {/* ListSubItems */}
            {item.subList.map((subItem) =>
                <div key={subItem.subItemId} className='mt-3'>
                    {subItem.isSelected &&
                        <>
                            <div className='row ml-0 mr-2'>

                                <div className='col-1 p-0'>
                                    <span className='selectedItemFont'><Icon.MinusCircle
                                        className='mr-2 p-1'
                                        onClick={() => {
                                            deselectItem(this.selectedModalList, item, subItem)
                                            this.props.calculatePotentialAccounts && this.props.calculatePotentialAccounts(this.selectedModalList)
                                            subItem.isPriority = subItem.isPriority === null ? null : false;
                                            this.forceUpdate()
                                        }} />
                                    </span>
                                </div>

                                <div className='col-11 p-0'>
                                    <span className='selectedItemFont'>
                                        {subItem.subItemName}
                                    </span>

                                    {item.isPriority !== null &&
                                        <div className='pull-right'>
                                            <div className='row p-0'>
                                                <span className='mr-2'>Priority</span>
                                                <Neo.FormGroup
                                                    bind={subItem.meta.isPriority}
                                                    suppressLabel={true}
                                                    input={{ type: "switch" }}
                                                    onChange={() => {
                                                        togglePriority(item, subItem)
                                                        this.displayPriorityError = !validatePriority(this.selectedModalList)
                                                        this.forceUpdate()
                                                        this.props.priorityChanged && this.props.priorityChanged(!this.displayPriorityError)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            )}
        </div>
    }

    // (Left column) Sub items (children of the main items) that you can select
    public mapSelectSubList(item: ListSelectorModalLookup, isFinalParent: boolean) {
        let filteredModalSubList = item.subList.filter(item => item.subItemName.toLocaleUpperCase().includes(this.searchString))
        return <div className='mb-2'>

            {/* ListSubItems */}
            {filteredModalSubList.map((subItem, key) =>
                <div key={subItem.subItemId} className='mt-3'>

                    <div className='row m-0 p-0'>
                        {/* Select relevant icon based on selection */}
                        <div className='col-1 p-0'>
                            <span>
                                {subItem.isSelected &&
                                    <Icon.CheckCircle className={`mr-2 p-1 ${subItem.isSelected ? 'selectedItemFont' : ''}`} />
                                }

                                {!subItem.isSelected &&
                                    <Icon.PlusCircle
                                        className='mr-2 p-1 '
                                        onClick={() => {
                                            this.selectItem(item, subItem)
                                            this.props.calculatePotentialAccounts && this.props.calculatePotentialAccounts(this.selectedModalList)
                                            togglePriority(item, subItem)
                                        }} />
                                }
                            </span>
                        </div>


                        <div className='col-11 p-0'>
                            {/* Sub item name */}
                            <span className={`${subItem.isSelected ? 'selectedItemFont' : ''}`}>{subItem.subItemName}</span>

                            {/* ToolTip location */}
                            {/* Tooltip icon */}
                            {subItem.subItemDetails && <span>
                                <DataTooltip
                                    parentLength={subItem.subItemName.length}
                                    isFinalItem={key === filteredModalSubList.length - 1 && isFinalParent && !this.searchString}
                                    type={"description"}
                                    parent={<Icon.AlertCircle className='ml-2 mr-2 p-1' />}
                                    variant='dark'
                                    direction='down'>
                                    {subItem.subItemDetails}
                                </DataTooltip>
                            </span>}
                        </div>
                    </div>

                </div>
            )}
        </div>
    }

    public selectedSubItem(item: ListSelectorModalLookup) {
        return item.subList.find(subItem => subItem.isSelected === false || subItem.isSelected === null)
    }

    // (Left column) Main items that you can select 
    public mapSelectItems() {
        let filteredModalList = this.props.modalList.filter(item => item.itemName.toLocaleUpperCase().includes(this.searchString) || item.subList.some(subItem => subItem.subItemName.toLocaleUpperCase().includes(this.searchString)))

        let listItems =
            (
                <div className='mt-3'>
                    {filteredModalList.sortBy(i => i.itemName).map((item, key) => (
                        <React.Fragment key={item.itemId}>
                            <div className='mb-4' key={item.itemId}>

                                {/* List Header */}
                                <div className='row p-0'>
                                    <div className='col-12 pr-2 itemCollapsible'>
                                        <div className='row m-0'>
                                            {/* Choosing the relevant icon based on item selection */}
                                            {this.selectedSubItem(item) === undefined &&
                                                <Icon.CheckCircle
                                                    className='mr-2'
                                                />
                                            }
                                            {this.selectedSubItem(item) !== undefined &&
                                                <Icon.PlusCircle
                                                    className='mr-2'
                                                    onClick={(e) => {
                                                        this.selectItem(item, null)
                                                        this.props.calculatePotentialAccounts && this.props.calculatePotentialAccounts(this.selectedModalList)
                                                    }} />
                                            }

                                            <div className='col pl-0 d-flex justify-content-between align-items-center'
                                                onClick={() => {
                                                    item.isExpanded = !item.isExpanded
                                                }}>
                                                {/* Collapsable Item Name */}
                                                <span className={`${this.selectedSubItem(item) === undefined ? 'selectedItemFont' : ''}`}>
                                                    {item.itemName.toUpperCase()}
                                                </span>
                                                <span className='pull-right ml-auto'>
                                                    {
                                                        item.isExpanded ? <Icon.ChevronDown />
                                                            : <Icon.ChevronUp />
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Line between items */}
                                <div className='itemHeaderContainer mt-3 mb-2'></div>

                                {/* Sub items */}
                                {item.isExpanded &&
                                    <span>{this.mapSelectSubList(item, key === filteredModalList.length - 1)}</span>
                                }
                            </div>
                        </React.Fragment>
                    ))}

                    {filteredModalList.length === 0 &&
                        <div className='noSelectedItemsTextArea'><i>{textConstants.Onboarding.NoResultsForSearch}</i></div>
                    }
                </div>
            );

        return listItems
    }

    public componentDidUpdate(prevProps: any) {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState({
                isOpen: this.props.isOpen
            })
        }

        //To initialize the selected list for editing already exisiting items
        if (this.props.isOpen) {
            this.selectedModalList = new List(ListSelectorModalLookup)
            this.displayPriorityError = false
            this.displayNonPriorityError = false

            this.props.modalList.forEach(item => {

                if (item.isSelected) {
                    this.setInitialSelectedList(item)
                }
                else {
                    item.subList.forEach(subItem => {

                        if (subItem.isSelected) {
                            this.setInitialSelectedList(item)
                        }

                    })
                }
            })

            this.props.calculatePotentialAccounts && this.props.calculatePotentialAccounts(this.selectedModalList)
        }
    }

    public render() {
        return (
            <div>
                <Modal isOpen={this.state.isOpen} className={`listSelectorModal`}>
                    {/* Main container */}
                    <div className='row m-0'>
                        <div className='col-12 p-0'>

                            {/* Header row */}
                            <div className='row m-0'>
                                <div className='col-12 p-0'>
                                    <div className='col-12 p-0 mb-4'><h5>Select {this.props.modalTitle}</h5></div>
                                </div>
                            </div>

                            {/* Sub-header row */}
                            <div className='row m-0'>
                                <div className='col-12 p-0'>

                                    <div className='row m-0'>
                                        {/* Selection Column */}
                                        <div className='col-6 p-0'><h6>{this.props.modalTitle} List</h6></div>

                                        {/* Selected Column */}
                                        <div className='col-4 p-0'><h6>{textConstants.Onboarding.Selected} {this.props.modalTitle} ({getSelectedNumberOfItems(this.selectedModalList)})</h6></div>
                                        <div className='col-2 p-0 clearAll'>
                                            <div
                                                className='pull-right'
                                                onClick={() => {
                                                    this.selectedModalList.forEach(item => {
                                                        deselectItem(this.selectedModalList, item, null, true)
                                                    })
                                                    this.selectedModalList = new List(ListSelectorModalLookup)
                                                    this.forceUpdate()
                                                }}>
                                                {textConstants.buttonText.ClearAll}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* Lists row */}
                            <div className='row m-0'>
                                <div className='col-12 p-0'>

                                    <div className='row m-0'>
                                        {/* Selection Column */}
                                        <div className='col-6 pl-0'>
                                            <div className='selectColumn pl-0 pr-0'>

                                                {/* Search bar */}
                                                <div className='row m-0'>
                                                    <div className='col-12'>
                                                        <input
                                                            id="searchText"
                                                            className={`searchBar`}
                                                            disabled={false}
                                                            placeholder={textConstants.generalText.SearchBar}
                                                            onChange={this.handleChange}>
                                                        </input>
                                                    </div>
                                                </div>

                                                <div className='col-12 p-0 selectColumnInsideContainer selectColumn mt-3' id="outerDiv" >
                                                    {/* List Elements */}
                                                    <div className='row m-0'>
                                                        <div className='col-12 itemsContainer'>
                                                            {this.mapSelectItems()}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        {/* Selected Column */}
                                        <div className='col-6 p-0'>
                                            <div className='selectColumn'>
                                                {this.props.potentialAccounts !== undefined &&
                                                    <div className='row potentialAccounts'>
                                                        <div className='col-12 m-auto pl-3 pr-3'>
                                                            {textConstants.generalText.potentialAccounts}
                                                            <span className='pull-right font-weight-bold'>
                                                                {this.props.potentialAccounts}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                {this.selectedModalList.length === 0 &&
                                                    <div className='col-12 pt-2 noSelectedItemsTextArea'>
                                                        <div>Your selected {this.props.modalTitle.toLocaleLowerCase()} will appear here</div>
                                                    </div>
                                                }

                                                {this.selectedModalList.length > 0 &&
                                                    <div className={`col-12 p-0 selectColumnInsideContainer selectedColumn ${this.props.potentialAccounts !== undefined ? 'potentialAccountsOffset' : ''}`}>
                                                        {/* List Elements */}
                                                        <div className='row m-0'>
                                                            <div className='col-12 pt-2 itemsContainer'>
                                                                {this.setSelectedList()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Footer row */}
                            <div className='row m-0'>
                                <div className='col-12 p-0 footerContainer'>

                                    <div className={`${this.displayPriorityError || this.displayNonPriorityError ? '' : 'mt-4'}  row m-0`}>
                                        <div className='col-12 p-0'>

                                            {this.displayPriorityError &&
                                                <div className='error pull-left'>
                                                    <div>{<Icon.AlertCircle />} {textConstants.generalText.PleaseAddPriority}</div>
                                                </div>
                                            }

                                            {this.displayNonPriorityError &&
                                                <div className='error pull-left'>
                                                    <div>{<Icon.AlertCircle />} {textConstants.generalText.PleaseAddItem}</div>
                                                </div>
                                            }

                                            {/* Continue button */}
                                            <button
                                                className='footerButton continue pull-right'
                                                onClick={() => {
                                                    // First case we check is to see if the data we got in the modal has a priority
                                                    if (this.props.modalList.every(item => item.isPriority === null)) {
                                                        this.displayNonPriorityError = !validateNotPriority(this.selectedModalList)

                                                        // Since priority is irrelevant in this dataset, we only check to see if one item has been selected and do NOT validate priority
                                                        if (!this.displayNonPriorityError) {
                                                            this.returnSelectedList();
                                                        }

                                                    } else {
                                                        // Since items in this dataset have a priority, we check to see if at least one item or sub-item has been selected and has a priority set before returning the list
                                                        this.displayPriorityError = !validatePriority(this.selectedModalList)

                                                        if (!this.displayPriorityError) {
                                                            this.returnSelectedList();
                                                        }
                                                    }

                                                    this.forceUpdate()
                                                }}
                                            >
                                                {textConstants.Onboarding.Continue}
                                            </button>

                                            {/* Cancel button */}
                                            <button
                                                className='footerButton cancel pull-right'
                                                onClick={() => {
                                                    this.toggleModal();
                                                }}
                                            >
                                                {textConstants.buttonText.Cancel}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}