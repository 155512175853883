import React from "react";

const HelpIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.08984 8.99666C9.32495 8.32833 9.789 7.76477 10.3998 7.4058C11.0106 7.04682 11.7287 6.9156 12.427 7.03538C13.1253 7.15515 13.7587 7.51819 14.2149 8.06019C14.6712 8.60219 14.9209 9.28819 14.9198 9.99666C14.9198 11.9967 11.9198 12.9967 11.9198 12.9967"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17H12.01"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HelpIcon;
