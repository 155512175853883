import React from "react";
import { Views } from "@singularsystems/neo-react";
import ActionListVM from "./ActionListVM";
import { observer } from "mobx-react";
import { textConstants } from "../../common/textConstants";
import LeadMessageVM from "./LeadMessageVM";
import ActionListScreen from "./ActionListScreen";

class ActionListViewParams {
  public clientId = {}
  public actionListMode = {} // for different leads and display messages
  public prospectId = {}
  public campaignId = {}
  public replyId = {}
  public hasSeenInfoCard = {}
}

@observer
export default class ActionListView extends Views.ViewBase<ActionListVM, ActionListViewParams> {

  constructor(props: unknown) {
    super("Action List", ActionListVM, props);
  }

  static params = new ActionListViewParams();

  public async componentDidMount() {
    const { viewModel, viewParams } = this
    viewModel.showActionListView = true
    await viewModel.getClientId()

    // If no action list mode go directly to specific tab.
    if (!viewParams.actionListMode.value || viewParams.actionListMode.value === "") {
      this.goToSelectedTab(viewModel, viewParams)
      viewParams.clientId.value = viewModel.clientId
      viewParams.actionListMode.value = textConstants.titleText.ActionListView
    }
    else if (viewParams.actionListMode.value === textConstants.titleText.LeadMessageView) {
      viewModel.customAuthService.globalProps.showActionListLeadMessageSideMenu = true
      viewModel.showActionListView = false
    }

    viewModel.customAuthService.globalProps.isZendeskVisible = true;
  }

  private goToSelectedTab(viewModel: any, viewParams: any) {
    let paramQuery = viewParams.view.navigation.currentRouteProps.location.search as string
    const params = new URLSearchParams(paramQuery);

    let tabName = params.get(textConstants.ActionListText.Tab)

    if (tabName && tabName !== "") {
      viewModel.selectedTab = tabName
    }
  }

  public async viewParamsUpdated() {
    const { viewModel, viewParams } = this

    // When viewparams change initialize the action list items only initially
    if (viewModel.isInitialLoad) {
      await viewModel.getClientId()
      await viewModel.initialise()

      viewModel.isInitialLoad = false;
    }

    let clientId = viewParams.clientId.asNullableInt()

    if (clientId === null) {

      if (!viewModel.clientId) {
        await viewModel.getClientId()
      }

      clientId = viewModel.clientId
    }

    if (clientId !== null) {

      // Lead Message (Reply)
      if (viewParams.actionListMode.value === textConstants.titleText.LeadMessageView) {
        let prospectId = viewParams.prospectId.asNullableInt()
        let campaignId = viewParams.campaignId.asNullableInt()
        let replyId = viewParams.replyId.asString()

        if (prospectId && campaignId) {
          viewModel.showActionListView = false;

          viewParams.actionListMode.description = textConstants.Routes.LeadMessage
          viewModel.LeadMessageViewModel = new LeadMessageVM();
          viewModel.LeadMessageViewModel.taskRunner = this.taskRunner
          viewModel.LeadMessageViewModel.clientId = clientId
          viewModel.LeadMessageViewModel.prospectId = prospectId
          viewModel.LeadMessageViewModel.campaignId = campaignId
          viewModel.LeadMessageViewModel.currentReplyId = replyId
          viewModel.LeadMessageViewModel.selectedLeadType = viewModel.actionListCriteria.leadType

          viewModel.LeadMessageViewModel.leadLookup = viewModel.currentMessageIndex >= 0 ? viewModel.pagedLeadList[viewModel.currentMessageIndex] : undefined;

          viewModel.setSessionStorage()

          await viewModel.LeadMessageViewModel.initialise()
          await viewModel.reduceSeenCountIfNecessary()
          await viewModel.notifyClusteringAppOfSeenReply()

          let leadNameObject = viewModel.pagedLeadList.find(l => l.prospect_id === prospectId)?.prospect_name;
          let leadName = leadNameObject?.first_name + " " + leadNameObject?.last_name;
          leadName = leadName !== null ? leadName : textConstants.generalText.NoLeadName;
          this.updateBreadcrumb("", leadName)
        }
      }

      else {
        viewModel.hideLeadMessageSideMenu()
        viewModel.showActionListView = true;
      }
    }
  }

  private updateBreadcrumbFromLeadMessageView() {
    let currentLead = this.viewModel.getCurrentLead()
    let leadNameObject = currentLead.prospect_name;
    let leadName = `${leadNameObject?.first_name} ${leadNameObject?.last_name}`;

    this.setLeadViewParams(currentLead.prospect_id, currentLead.campaign_id, currentLead.reply_id)
    this.updateBreadcrumb("", leadName)
  }

  public updateBreadcrumb(description: string, description1: string) {
    this.viewParams.clientId.description = description;
    this.viewParams.actionListMode.description = description1;
  }

  private async archiveCurrentLead() {
    const { viewModel, viewParams } = this
    await viewModel.archiveOrUnarchiveLeads();

    if (!viewModel.showActionListView) {
      this.updateBreadcrumbFromLeadMessageView()
    }
    else {
      viewParams.setValues({ actionListMode: textConstants.titleText.ActionListView }, true)
    }
  }

  private resetViewParams() {
    const { viewParams } = this

    viewParams.setValues({ actionListMode: textConstants.titleText.ActionListView }, true);
    viewParams.prospectId.reset();
    viewParams.campaignId.reset();
    viewParams.replyId.reset();
  }

  private setLeadViewParams(prospectId: number, campaignId: number, replyId: string) {
    this.viewParams.setValues({
      clientId: this.viewModel.clientId,
      actionListMode: textConstants.titleText.LeadMessageView,
      prospectId: prospectId,
      campaignId: campaignId,
      replyId: replyId
    }, true);
  }

  public render() {
    const { viewModel } = this
    return (
      <ActionListScreen
        viewModel={viewModel}
        showPreviousMessage={async () => {
          await viewModel.showPreviousMessage();
          this.updateBreadcrumbFromLeadMessageView()
        }}
        showNextMessage={async () => {
          await viewModel.showNextMessage();
          this.updateBreadcrumbFromLeadMessageView()
        }}
        changeLeadType={async (leadType) => {
          await viewModel.updateLeadMessageType(leadType);
          this.updateBreadcrumbFromLeadMessageView()
        }}
        archiveCurrentLead={async () => {
          await this.archiveCurrentLead()
        }}
        backToActionList={() => {
          viewModel.showActionListView = true;
          viewModel.hideLeadMessageSideMenu();
          this.resetViewParams();
        }}
        openLeadsView={(prospectId: number, campaignId: number, replyId: string) => {
          viewModel.showLeadMessageSideMenu();
          viewModel.setCurrentMessageIndex(viewModel.pagedLeadList.indexOf(viewModel.pagedLeadList.find(c => c.prospect_id === prospectId)!))
          this.setLeadViewParams(prospectId, campaignId, replyId)
        }}
      />

    );
  }
}