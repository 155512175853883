import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import MasterAccountOption from './../../Models/MasterAccounts/MasterAccountOption';
import { textConstants } from '../../common/textConstants';
import { base64toBlob, getCurrentDateTime } from '../../common/utils';

@NeoModel
export default class UpdateMasterAccountsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private masterAccountsApiClient = AppService.get(Types.ApiClients.MasterAccountsApiClient)) {
        super(taskRunner);
    }

    public options: MasterAccountOption = new MasterAccountOption();
    public updateAccountsTemplateHeadings = 
                ["- Fixed Name ", "- Original Name ","- Description ", "- Logo URL " ,"- Region ","- Location ", "- Company Size ", "- Industry ", "- Domain ",  "- Country "];
    public domainTemplateHeadings = ["Domain "];

    public insertedCount = 0;
    public updatedCount = 0;
    public showUploadMasterAccountsModal: boolean = false;

    public async initialise() {
        this.options.newAndExistingAccounts = true;
    }

    public downloadTemplate() {
        this.taskRunner.run(async () => {
            const response = await this.masterAccountsApiClient.getTemplate(this.options);

            const url = window.URL.createObjectURL(base64toBlob(response.data, "xlsx"));
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const datetime =  getCurrentDateTime();
            link.setAttribute('download', 'MasterAccount_Template_' + datetime + '.xlsx');
            document.body.appendChild(link);
            link.click();
        })
    }

    public documentUpload(fileList: File[] | FileList) {
       this.taskRunner.run(async ()=>{
            const response = await  this.masterAccountsApiClient.uploadDocument(fileList, this.options);
            if (response.data.success)
            {
                this.notifications.addSuccess(textConstants.titleText.MasterAccount,textConstants.messageText.saveMessage.UploadSuccess);
                this.insertedCount = response.data.data.insertedCount
                this.updatedCount = response.data.data.updatedCount
                this.showUploadMasterAccountsModal = true
            }
            else {
                this.notifications.addDanger(textConstants.titleText.Error, response.data.message);
            }
       });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}