import 'rc-slider/assets/index.css';
import 'react-input-range/lib/css/index.css';
import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import { textConstants } from '../../../common/textConstants';
import AddEditTargetMarketVM from '../AddEditTargetMarketVM';

import CreatableSelect from 'react-select/creatable';
import { regOtherKeyWords } from '../../../common/utils'
import * as Icon from "react-feather";

interface IAddEditTargetMarketProps {
  viewModel: AddEditTargetMarketVM,
  isNotEditable?: boolean
}

@observer
export default class TMScreen6 extends React.Component<IAddEditTargetMarketProps> {

  public handleChange(selectedItems: any) {
    let matchCount: number = 0
    let invalidCharMatch: boolean = false;
    let itemsToRemove: any[] = []
    let completeValidationMessage = "";

    // Rule 1 : Remove keywords by excludedKeywords filter
    // Find excluded keywords
    if (selectedItems !== null) {
      for (let i = 0; i < selectedItems.length; i++) {

        let individualWords = selectedItems[i].label.split(" ")

        for (let x = 0; x < individualWords.length; x++) {
          var matchingKeyword = this.props.viewModel.excludedKeywords.find(word => word.keyword === individualWords[x].toString())

          if (matchingKeyword !== undefined) {
            itemsToRemove.push(selectedItems[i])
            matchCount += 1
          }
        }
      }
    }

    // Remove excluded keywords
    itemsToRemove.forEach((item: any) => {
      if (selectedItems.includes(item)) {
        selectedItems.remove(item)
      }
    });

    // Clear array
    itemsToRemove = []

    // Rule 2 : Remove keywords by regex filter
    if (selectedItems !== null && selectedItems.length > 0) {
      for (let j = 0; j < selectedItems.length; j++) {
        let words = selectedItems[j].label.split(" ");
        for (let z = 0; z < words.length; z++) {
          if (regOtherKeyWords.test(words[z].toString()) === false) {
            itemsToRemove.push(selectedItems[j])
            invalidCharMatch = true;
          }
        }
      }
    }

    // Remove excluded keywords
    itemsToRemove.forEach((item: any) => {
      if (selectedItems.includes(item)) {
        selectedItems.remove(item)
      }
    });

    // Display message
    completeValidationMessage += matchCount > 0
      ? textConstants.messageText.validationMessage.otherKeywordsValidation + "\n"
      : "";

    completeValidationMessage += invalidCharMatch
      ? textConstants.messageText.validationMessage.otherKeywordsRegexValidation + "\n"
      : "";

    if (completeValidationMessage.length > 0) {
      this.props.viewModel.invalidDataMessage = completeValidationMessage;
      this.props.viewModel.showInvalidDataModal = true
    }

    this.props.viewModel.setSpecificRoles(selectedItems);
  }

  private mapSpecificKeywordRoles() {
    return this.props.viewModel.specificRoleSavedList.map((role) => {
      if (role !== undefined) {
        return {
          value: role.value,
          label: role.label,
        };
      }

      return null;
    })
  }

  public render() {
    return (
      <div className="AddEditTargetMarketsView">
        <div className='row m-0 p-0'>
          <div className='col-8  m-0 p-0 mb-3'>
            <h4 className='mt-2'>
              {textConstants.titleText.AdvancedSettingsHeader}
            </h4>
          </div>
        </div>
        <Neo.GridLayout md={4}>
          {/* Client Review */}
          <Neo.FormGroup
            bind={this.props.viewModel.targetMarket.meta.isClientReview}
            input={{ type: "switch" }}
            onChange={() => this.props.viewModel.isScreenDirty = true}
            disabled={this.props.isNotEditable} />

          {/* Include Legal Roles */}
          <Neo.FormGroup
            bind={this.props.viewModel.targetMarket.meta.isLegalRoleInclusive}
            input={{ type: "switch" }}
            onChange={() => this.props.viewModel.isScreenDirty = true}
            disabled={this.props.isNotEditable} />

          {/* Do Filler Stage */}
          <Neo.FormGroup
            bind={this.props.viewModel.targetMarket.meta.isFillerStage}
            input={{ type: "switch" }}
            onChange={() => this.props.viewModel.isScreenDirty = true}
            disabled={this.props.isNotEditable} />

          {/* Role Based IRO */}
          <Neo.FormGroup
            bind={this.props.viewModel.targetMarket.meta.isRoleBasedIRO}
            input={{ type: "switch" }}
            onChange={() => this.props.viewModel.isScreenDirty = true}
            disabled={this.props.isNotEditable} />

          {/* Show German Regions */}
          <Neo.FormGroup
            bind={this.props.viewModel.targetMarket.meta.showGermanRegions}
            input={{ type: "switch" }}
            onChange={() => this.props.viewModel.isScreenDirty = true}
            disabled={this.props.isNotEditable} />
        </Neo.GridLayout>

        <Neo.GridLayout md={1}>
          <div className="row mt-3">
            {/* Client Specific Max */}
            <div className="col-md-3">
              <Neo.FormGroup
                className={"targetMarketAddEditSubQuestion"}
                bind={this.props.viewModel.targetMarket.meta.clientSpecificMax}
                onChange={() => this.props.viewModel.clearIfNonNumeric(this.props.viewModel.targetMarket.clientSpecificMax)}
                disabled={this.props.isNotEditable} />
            </div>

            {/* Campaign Snippet Language */}
            <div className="col-md-3">
              <Neo.FormGroup
                className={"targetMarketAddEditSubQuestion"}
                bind={this.props.viewModel.targetMarket.meta.languageCountryId}
                select={{ items: this.props.viewModel.countries }}
                onChange={() => this.props.viewModel.isScreenDirty = true}
                disabled={this.props.isNotEditable} />
            </div>

            {/* Batch Upload Notes */}
            <div className="col-md-6 custom-scrollbar-expand">
              <Neo.FormGroup
                className={"targetMarketAddEditSubQuestion"}
                bind={this.props.viewModel.targetMarket.meta.batchUploadNotes}
                input={{ rows: 2 }}
                onChange={() => this.props.viewModel.isScreenDirty = true}
                disabled={this.props.isNotEditable} />
            </div>
          </div>
        </Neo.GridLayout>
        { this.props.viewModel.targetMarket.isClientReview &&
          <Neo.GridLayout md={1}>
            <div className="row mt-3">
              {/* Days Before Auto Completion */}
              <div className="col-md-3">
                <Neo.FormGroup
                  className={"targetMarketAddEditSubQuestion"}
                  bind={this.props.viewModel.targetMarket.meta.daysBeforeAutoCompletion}
                  onChange={() => this.props.viewModel.clearDaysToAutoCompletion(this.props.viewModel.targetMarket.daysBeforeAutoCompletion)}
                  disabled={this.props.isNotEditable} />
              </div>
            </div>
          </Neo.GridLayout>
        }
        {/* SPECIFIC ROLES */}
        <div className="row">
          <div className="col-12">
            <div className='mr-0 ml-0 mt-2'>
              <span className='targetMarketAddEditSubQuestion'>
                {textConstants.titleText.SpecificRoles}
              </span>

              <CreatableSelect
                isDisabled={this.props.isNotEditable}
                className='col-6 p-0'
                value={this.props.viewModel.specificRoleSavedList !== null ?
                  this.mapSpecificKeywordRoles() : null}
                components={{ DropdownIndicator: null }}
                isMulti
                onChange={(e) => {
                  this.handleChange(e);
                  this.props.viewModel.isScreenDirty = true;
                }}
                noOptionsMessage={() => null}
                options={this.props.viewModel.emptyList}
                placeholder={textConstants.messageText.validationMessage.startTyping} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}