import { Attributes, List, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class IndustriesVersion extends ModelBase{

    @Attributes.Display("Version Number")
    public industriesVersionId: number = 0; 

    @Rules.StringLength(100)
    @Attributes.Display("Version Name")
    public industryVersionName: string = `V${this.industriesVersionId}`;

    public isActive: boolean = false;

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        
    }

    public toString(): string {
        if (this.isNew || !this.industryVersionName) {
            return "New Version";
        } else {
            return this.industryVersionName;
        }
    }
}