import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class Language extends ModelBase {

    languageId: number = 0;

    languageName: string = "";

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.languageName === "", textConstants.messageText.validationMessage.language);
    }

    public toString(): string {
        if (this.isNew || !this.languageName) {
            return "New Language";
        } else {
            return this.languageName;
        }
    }
}