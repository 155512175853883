import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import IROEditStatus from '../Models/Maintenance/IROEditStatus';

export interface IIROEditStatusApiClient extends Data.IUpdateableApiClient<IROEditStatus, number> {
}

@injectable()
export default class IROEditStatusApiClient extends Data.UpdateableApiClient<IROEditStatus, number> implements IIROEditStatusApiClient {
    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/IROEditStatuses`);
    }
}