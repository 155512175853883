import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import TargetMarketAccountStatus from '../Models/Maintenance/TargetMarketAccountStatus';
import { AppService, Types } from '../Services/AppService';

export interface ITargetMarketAccountStatusesApiClient extends Data.IUpdateableApiClient<TargetMarketAccountStatus, number> {

}

@injectable()
export default class TargetMarketAccountStatusesApiClient extends Data.UpdateableApiClient<TargetMarketAccountStatus, number> implements ITargetMarketAccountStatusesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/targetMarketAccountStatuses`);
    }
}