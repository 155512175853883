import React from 'react';
import { observer } from 'mobx-react';
import AddEditFollowUpTemplateVM from '../Views/CampaignMessages/AddEditFollowUpTemplateVM';
import FollowUpTemplateLookup from '../Models/CampaignMessage/FollowUpTemplateLookup';

interface IOpenFollowUpTemplateProps {
    viewModel: AddEditFollowUpTemplateVM;
    followUpTemplateLookup: FollowUpTemplateLookup,
}

@observer
export default class OpenFollowUpTemplate extends React.Component<IOpenFollowUpTemplateProps> {

    constructor(props: IOpenFollowUpTemplateProps) {
        super(props);
        this.props.viewModel.selectedModalOpenTemplate = 1;

        if (this.props.followUpTemplateLookup.ordinal === 1) {
            this.props.viewModel.selectedModalOpenTemplateDraftText = this.props.followUpTemplateLookup.messageText
        }

        this.props.viewModel.createdBy = this.props.followUpTemplateLookup.createdBy
    }

    private checkSelectedTemplate() {
        if (this.props.viewModel.selectedModalOpenTemplate === this.props.followUpTemplateLookup.ordinal) {
            return "activeOpenTemplateSidemenu"
        } else return
    }

    public render() {
        return (
            <div className='openFollowUpTemplate'>
                {/* div for the template versions (ordinals)*/}
                <div className={`${this.checkSelectedTemplate()} row openFollowUpTemplateVersion modalBorderBottom pl-3`} onClick={() => {
                    this.props.viewModel.selectedModalOpenTemplate = this.props.followUpTemplateLookup.ordinal
                    this.props.viewModel.selectedModalOpenTemplateDraftText = this.props.followUpTemplateLookup.messageText
                    this.props.viewModel.createdBy = this.props.followUpTemplateLookup.createdBy
                }}>
                    <h3 className='pt-0'>Email {this.props.followUpTemplateLookup.ordinal}</h3>
                </div>
            </div>
        );
    }
}