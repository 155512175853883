import styled from "styled-components";
import CreatableSelect from "react-select/creatable";


export const StyledOrganizationList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 24px 0;
    border-top: 1px solid #EDEEEE;
    margin-top: 16px;
`;

export const StyledOrganizationItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 8px 8px;
    gap: 8px;
    width: 100%;
    height: 48px;
    background: #F2F5F5;
    border-radius: 8px;

    span {
        margin-left: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #0B0B0C;
    }

    .delete-icon {
        padding: 6px;
        background: #FFFFFF;
        border-radius: 8px;
        cursor: select;
    }
`;


export const CustomSelect = styled(CreatableSelect)`
    .react-select__menu {
        .react-select__menu-list {
            .react-select__option {
                cursor: pointer;
                &:hover {
                    background: #002648;
                    color: #FFFFFF;
                }
                transition: all .3s ease-in-out;

                &--is-selected {
                    background: #002648;
                    color: #FFFFFF;
                }
            }
        }
    }

`;