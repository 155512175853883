import {ModelBase, NeoModel, List, Attributes} from '@singularsystems/neo-core';
import SubRegionLookup from './SubRegionLookup';
import { Observable } from '@singularsystems/neo-core/dist/Model/Decorators';


@NeoModel
export default class TargetMarketRegionLookup extends ModelBase {

    // Properties
    public regionId: number = 0;

    public regionName: string = "";

    @Attributes.NullableBoolean()
    public isSelected: Nullable<boolean> = null;

    public firstClick: boolean = false;
    
    public isExpanded: boolean = false;

    // Child List
    public subRegions = new List(SubRegionLookup);
}