import { ValueObject, NeoModel, Attributes } from '@singularsystems/neo-core';
import { IsGridCriteria } from '../../../Components/ReactDataGrid/DataGridHelper';

@NeoModel
export default class MasterAccountCriteria extends ValueObject implements IsGridCriteria {
    @Attributes.Display("Country")
    public countryId: number = 0;

    public searchString: string = "";    

    public isCoumnFilter : boolean = false;

    public notFilter: string[] = [] ;

    public fixedName: string = "";

    public originalName: string = "";

    public region: string =""

    public location: string = "";

    public employeeSize: string[] = []

    public industrySubIndustry: string[] = []

    public industriesVersion: string[] = []

    public domain: string = "";

    public status: string[] = []

    public invalidReason: string[] = []

    public dateAdded: string[] = []

    public reOpenedCount: number[] = [];

    public ResetLocalFilters() 
    {  
        this.isCoumnFilter = false;
        this.notFilter = []
        this.fixedName = "";
        this.originalName = "";
        this.region = ""
        this.location = "";
        this.employeeSize = []
        this.industrySubIndustry = []
        this.industriesVersion = []
        this.domain = "";
        this.status = []
        this.invalidReason = []
        this.dateAdded = []
        this.reOpenedCount = []
    }
}