import { injectable } from 'inversify';
import { NeoModel, Misc, Security, NotifyUtils, ModalUtils } from '@singularsystems/neo-core';

import AuthorisationTypes from '../AuthorisationTypes';
import { UserGroupRolesLookup } from '../Models/UserRoleLookup';
import { observable, makeObservable } from 'mobx';
import MembershipChange from '../Models/Hubs/MembershipChange';
import AssignedRolesChange from '../Models/Hubs/AssignedRolesChange';
import { AppService, Types } from '../../Services/AppService';

const rolesStorageKey = "neo.roles";

@injectable()
export default class AuthorisationService implements Security.IAuthorisationService {

    @observable.ref
    private roleDictionary: { [index: string]: boolean } = {};

    private userGroupRolesLookup: UserGroupRolesLookup[] = [];

    constructor (        
        private apiClient = Misc.Globals.appService.get(AuthorisationTypes.ApiClients.AuthorisationApiClient),
        private comxHub = Misc.Globals.appService.get(Types.ApiClients.ComXHub)) {
        
        // If the user refreshes their browser, get the roles from storage.
        // Session storage is cleared when the browser is closed.
        const roleJson = sessionStorage.getItem(rolesStorageKey);
        if (roleJson) {
            this.userGroupRolesLookup = JSON.parse(roleJson);
            this.flattenRoles(false);
        }

        this.comxHub.connectIfAuthenticated();

        this.comxHub.membershipChanged.subscribe(this.onMembershipChanged.bind(this));
        this.comxHub.assignedRoleChanged.subscribe(this.onAssignedRolesChanged.bind(this));
        this.comxHub.userGroupRemoved.subscribe(this.onUserGroupRemoved.bind(this));

        makeObservable(this);
    }

    public async loadRoles(showChangeNotification = false) {
        // fetch the rules
        try {
            this.userGroupRolesLookup = (await this.apiClient.users.getCurrentUserRoles()).data;
            await this.registerClientUser();

            this.flattenRoles(showChangeNotification);

            this.comxHub.connect();
        } catch (e) {
            this.roleDictionary = {};
            throw e;
        }
    }

    public async registerClientUser() {

        const { isAuthenticated, user } = AppService.get(Types.Neo.Security.AuthenticationService);
        
        if (isAuthenticated) {

            // Check that the user 
            const response = await AppService.get(Types.ApiClients.InvitedUsersApiClient).registerClientUser(user!.userName);

            if(!response.data.success){
                ModalUtils.showMessage("Client User Registration Error", response.data.error);
            }
        }
    }

    private onMembershipChanged(membershipChange: MembershipChange) {
        
        if (membershipChange.isNew) {
            this.loadRoles(true);
            this.comxHub.subscribeToGroup(membershipChange.userGroupId);
        } else {
            this.onUserGroupRemoved(membershipChange.userGroupId);
        }
    }

    private onUserGroupRemoved(userGroupId: number) {
        const index = this.userGroupRolesLookup.findIndex(ug => ug.userGroupId === userGroupId);
        if (index >= 0) {
            this.userGroupRolesLookup.splice(index, 1);
            this.flattenRoles(true);
        }
    }

    private onAssignedRolesChanged(rolesChange: AssignedRolesChange) {
        
        const userGroup = this.userGroupRolesLookup.find(ug => ug.userGroupId === rolesChange.userGroupId);
        if (userGroup) {
            for (const roleId of rolesChange.removedRoles) {
                const index = userGroup.roles.findIndex(r => r.roleId === roleId);
                if (index >= 0) {
                    userGroup.roles.splice(index, 1);
                }
            }
            userGroup.roles.push(...rolesChange.addedRoles);
            this.flattenRoles(true);
        }
    }

    private showChangeNotification() {
        NotifyUtils.addWarning("Access", "Your access rights have changed.", 4);
    }

    private flattenRoles(showChangeNotification: boolean) {
        const roleDictionary = {};

        sessionStorage.setItem(rolesStorageKey, JSON.stringify(this.userGroupRolesLookup));
        
        for (const group of this.userGroupRolesLookup) {
            for (const role of group.roles) {
                roleDictionary[role.roleKey] = true;
            }
        }

        this.roleDictionary = roleDictionary;

        if (showChangeNotification) {
            this.showChangeNotification();
        }
    }

    public hasRole(roleName: string) {
        return this.roleDictionary[roleName] !== undefined;
    }
}