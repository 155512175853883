import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class SubIndustry extends ModelBase {

    public subIndustryId: number = 0;

    @Rules.StringLength(500)
    @Attributes.Display("Name")
    public subIndustryName: string = "";
    public subIndustryDetails: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.subIndustryName === null || c.subIndustryName === '', textConstants.messageText.validationMessage.subIndustryName);
    }

    public toString(): string {
        if (this.isNew || !this.subIndustryName) {
            return "New sub-industry";
        } else {
            return this.subIndustryName;
        }
    }
}