import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class ActionListFollowUpTemplateLookup extends ModelBase {

    // Properties
    public followUpTemplateId: number = 0;

    public clientFollowUpTemplateId: number = 0;

    @Attributes.Display("Name")
    public followUpTemplateName: string = "";

    public draftText: string = "";

    public messageText: string = "";

    public ordinal: number = 0;
}