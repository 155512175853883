import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import ReProspectingStatus from '../Models/Maintenance/ReProspectingStatus';
import { AppService, Types } from '../Services/AppService';

export interface IReProspectingStatusesApiClient extends Data.IUpdateableApiClient<ReProspectingStatus, number> {

}

@injectable()
export default class ReProspectingStatusesApiClient extends Data.UpdateableApiClient<ReProspectingStatus, number> implements IReProspectingStatusesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/reProspectingStatuses`);
    }
}