import styled, {keyframes, css} from 'styled-components';


const slideOut = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
`;

const slideIn = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
`;

const slideAnimation = css<IZendeskLauncherContainerProps>`
    animation: ${props => props.isOpen ? slideOut : slideIn} 0.5s ease-in-out;
`;

interface IZendeskLauncherContainerProps {
    isOpen: boolean;
}
export const ZendeskLauncherButton = styled.div<IZendeskLauncherContainerProps>`
    background: #E8EEFA;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    position: fixed;
    z-index: 10;
    bottom: 20px;
    right: 20px;
    width: 275px;
    height: 50px;
    color: #002648;
    font-weight: 500;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    ${props => props.isOpen && slideAnimation}

    p {
        margin: 0;
        font-size: 16px;
    }
`;