import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import ReportingQuery from 'Models/Reporting/ReportingQuery';
import ReportingLookup from 'Models/Reporting/ReportingLookup';
import { List } from '@singularsystems/neo-core';
import ReportingDataClientFilter from 'Models/Reporting/ReportingDataClientFilter';
import { textConstants } from 'common/textConstants';
import ReportingReportFilter from 'Models/Reporting/ReportingReportFilter';
import ClientWithWoodpeckerCompanyId from 'Models/Client/ClientWithWoodpeckerCompanyId';
import ReportingClientCampaignFilter from 'Models/Dashboard/ReportingClientCampaignFilter';
import ReportingDataClientCampaign from 'Models/Reporting/ReportingDataClientCampaign';

export default class ReportingVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public authorisationService = AppService.get(Types.Neo.Security.AuthorisationService),
        private reportingApiClient = AppService.get(Types.ApiClients.ReportingApiClient),
        private customAuthenication = AppService.get(Types.Security.CustomAuthenticationService)) {

        super(taskRunner);
        this.makeObservable();
    }

    public isClientUser: boolean = false;

    public response: ReportingLookup | null = null
    public reportData: number[][] = []
    public reportProperties: string[] = []
    public reportCategories: string[] = []
    public isInitialLoad: boolean = true;
    public isGetReportsComplete: boolean = false;
    public reportingQuery: ReportingQuery = new ReportingQuery();

    public woodpeckerCompanies: List<ClientWithWoodpeckerCompanyId> = new List(ClientWithWoodpeckerCompanyId);

    public selectedClientId: number | null = null;
    public selectedClientName: string | null = null;
    public selectedReportId: number | null = null;
    public selectedCampaignId: number | null = null;

    public startDate: Date = this.setDaysBack(30);
    public endDate: Date = this.setDaysBack(1, false);
    public maxDate: Date = this.setDaysBack(1, false);

    public reportingClientFilters = new List(ReportingDataClientFilter)
    public reportingReportFilters = new List(ReportingReportFilter)
    public reportingClientCampaignFilters: List<ReportingClientCampaignFilter> = new List(ReportingClientCampaignFilter)

    public notEnoughDataOnChart = textConstants.generalText.enoughChartData
    public isEnoughData: boolean = false;
    public visibleSeries: number[] = [];

    public dataHasChanged = false;

    public async initialise() {

        this.isClientUser = this.customAuthenication.globalProps.isClientUser;

        if (!this.isClientUser) {
            await this.taskRunner.run(async () => {
                await this.getReports(true)
                await this.generateClientFilters()
                this.generateReportFilters()
            })
        }
    }

    private generateReportFilters() {
        textConstants.Reporting.engagementReportNames.forEach((reportName, key) => {
            let reportingReport = new ReportingReportFilter()

            reportingReport.reportingReportId = key;
            reportingReport.reportingReportName = this.setUserFriendlyName(reportName)

            this.reportingReportFilters.push(reportingReport)
        })
    }

    private async generateClientFilters() {
        let result = (await this.reportingApiClient.getClientsWithWoodpeckerId()).data

        if (result.success) {

            let tempList: List<ReportingDataClientFilter> = new List(ReportingDataClientFilter)

            let defaultReporting = new ReportingDataClientFilter()
            defaultReporting.reportingDataClientId = 0;
            defaultReporting.reportingDataClientName = textConstants.Reporting.allClients;

            tempList.push(defaultReporting)

            result.data.forEach((client: ClientWithWoodpeckerCompanyId, key: number) => {
                let reportingDataClient = new ReportingDataClientFilter()

                reportingDataClient.reportingDataClientId = client.woodpeckerCompanyId;
                reportingDataClient.reportingDataClientName = client.woodpeckerClientName;

                tempList.push(reportingDataClient)
            })

            this.reportingClientFilters = tempList
        }
    }

    public async generateClientCampaignFilters(woodpeckerCompanyId: number | null) {

        // Resets the client campaign filters for all clients
        if (woodpeckerCompanyId === null || woodpeckerCompanyId === 0) {
            this.reportingClientCampaignFilters = new List(ReportingClientCampaignFilter)
        }
        else {
            this.reportingClientCampaignFilters = new List(ReportingClientCampaignFilter)

            // Fetches that specific client's campaigns.
            let result = (await this.reportingApiClient.getClientCampaigns(woodpeckerCompanyId)).data

            if (result.success) {

                let tempList: List<ReportingClientCampaignFilter> = new List(ReportingClientCampaignFilter)

                result.data.forEach((client: ReportingDataClientCampaign, key: number) => {
                    let reportingDataClient = new ReportingClientCampaignFilter()

                    reportingDataClient.clientCampaignId = client.woodpeckerCampaignId;
                    reportingDataClient.campaignName = client.woodpeckerCampaignName;

                    tempList.push(reportingDataClient)
                })

                this.reportingClientCampaignFilters = tempList;
            }
        }
    }

    private CreateReportingQuery() {

        let companyId = this.selectedClientId ?? null

        let query = new ReportingQuery();
        query.companyId = companyId;
        query.endDate = this.endDate;
        query.startDate = this.startDate;

        query.reportType = this.selectedReportId !== null ?
            textConstants.Reporting.engagementReportNames[this.selectedReportId]
            : textConstants.Reporting.clientPerformaceOverview;

        query.campaignId = this.selectedCampaignId ?? null

        return query
    }

    private setDaysBack(daysBack: number, start: boolean = true) {
        const daysBackDate = new Date();
        daysBackDate.setDate(daysBackDate.getDate() - daysBack);

        // sets the start time to midnight 00:00:00:000
        if (start) {
            daysBackDate.setHours(0);
            daysBackDate.setMinutes(0);
            daysBackDate.setSeconds(0);
            daysBackDate.setMilliseconds(0);
        }
        // sets the end time to 23:59:59:999
        else {
            daysBackDate.setHours(23);
            daysBackDate.setMinutes(59);
            daysBackDate.setSeconds(59);
            daysBackDate.setMilliseconds(999);
        }

        return daysBackDate;
    }

    public async getReports(initialLoad: boolean = false) {

        // Creates the reporting query and this is then passed into the reporting api client.
        let query = this.CreateReportingQuery()

        let result = await this.reportingApiClient.getReports(query);

        if (result.data.success) {
            this.isEnoughData = true;
            if (result.data.data) {

                // Sets up the reports.
                await this.setupReports(result.data.data, query.reportType, initialLoad)
            }

            this.isGetReportsComplete = true;
        }
        else {
            this.isEnoughData = false;
            this.reportData = []
            this.reportProperties = []
            this.reportCategories = []
            this.dataHasChanged = true;
        }
    }

    private async setupReports(reportingLookup: ReportingLookup, reportType: string, initialLoad: boolean) {
        this.response = reportingLookup

        this.reportCategories = reportingLookup.categories

        let tempReportDataArray: number[][] = []

        reportingLookup.seriesData.forEach((reportSeries, key) => {

            // Sets the Property name to user friendly names.
            this.reportProperties = this.setUserFriendlyNames(reportingLookup.seriesPropertyNames);

            tempReportDataArray.push(reportSeries.propertyValues)
        })

        this.setVisibilitySeries()

        // Converts the array from [4][6] to [6][4] for the reporing data.
        this.reportData = this.transposeArray(tempReportDataArray)

        this.dataHasChanged = true;
    }

    // This sets the visibility for specific series
    // Disables the visibility of the Prospect To Be Contacted and Total Prospect Count
    private setVisibilitySeries() {
        let visibleProperties: number[] = []

        // Goes through each series name.
        this.reportProperties.forEach((property, key) => {

            // Finds the index of the specific property name
            let value = textConstants.Reporting.disabledLegends.findIndex(propertyName => propertyName === property)

            // If those are not found, pushes them to visible properties.
            if (value !== -1) {
                visibleProperties.push(key)
            }

            // Sets the visible series array, with the present ids.
            this.visibleSeries = visibleProperties;
        })
    }

    private convertToUserFriendlyName(input: string): string {
        // Replace underscores with spaces
        let stringWithSpaces = input.replace(/_/g, ' ');

        // Pascal case all the words
        let pascalCasedString = stringWithSpaces.replace(/(?:^|\s)\S/g, (match) => {
            return match.toUpperCase();
        });

        return pascalCasedString;
    }

    public setUserFriendlyName(propertyName: string) {
        let convertedName = this.convertToUserFriendlyName(propertyName)

        return this.convertNamesFurther(convertedName)
    }

    // Converts the user friendly names to more legibal names, this is due property objects coming back
    // not being the correct name, then this has to be converted to a better name.
    public convertNamesFurther(propertyName: string) {

        // Hard codes the switch for the series names, not ideal but this is the best way to deal with it.
        switch (propertyName) {
            case textConstants.Reporting.convertNames.CampaignMessageOpened:
                return textConstants.Reporting.convertNames.Opened;
            case textConstants.Reporting.convertNames.TotalEmailOpened:
                return textConstants.Reporting.convertNames.Opened;
            case textConstants.Reporting.convertNames.CampaignSent:
                return textConstants.Reporting.convertNames.Sent;
            case textConstants.Reporting.convertNames.TotalCampaignMessageSent:
                return textConstants.Reporting.convertNames.Sent;
            case textConstants.Reporting.convertNames.ProspectBounced:
                return textConstants.Reporting.convertNames.Bounced;
            case textConstants.Reporting.convertNames.TotalProspectBounced:
                return textConstants.Reporting.convertNames.Bounced;
            case textConstants.Reporting.convertNames.ProspectReplied:
                return textConstants.Reporting.convertNames.Replies;
            case textConstants.Reporting.convertNames.TotalProspectReplied:
                return textConstants.Reporting.convertNames.Replies;
            case textConstants.Reporting.convertNames.TotalNegativeReplies:
                return textConstants.Reporting.convertNames.NegativeReplies;
            case textConstants.Reporting.convertNames.TotalNeutralReplies:
                return textConstants.Reporting.convertNames.NeutralReplies;
            case textConstants.Reporting.convertNames.TotalPositiveReplies:
                return textConstants.Reporting.convertNames.PositiveReplies;
            default:
                // Returns the property name if not in the hardcoded items.
                return propertyName;
        }
    }

    private setUserFriendlyNames(propertyNames: string[]) {
        let categoryArray: string[] = []

        propertyNames.forEach((propertyName) => {

            let friendlyName = this.setUserFriendlyName(propertyName)

            categoryArray.push(friendlyName)
        })

        return categoryArray;
    }

    // Checks if the data has changed, this will stop the constant rerendering.
    public hasDataChanged() {
        if (this.dataHasChanged) {
            this.dataHasChanged = false;
            return true
        }
        return false
    }

    public async getReportingInformation() {

        await this.taskRunner.run(async () => {
            await this.getReports()
        })
    }

    public transposeArray<T>(array: T[][]): T[][] {
        const rows = array.length;
        const cols = array[0].length;

        // Initialize the transposed array with the inverted dimensions
        const transposedArray: T[][] = new Array(cols).fill(null).map(() => new Array(rows));

        // Loop through the original array and fill the transposed array
        for (let i = 0; i < rows; i++) {
            for (let j = 0; j < cols; j++) {
                transposedArray[j][i] = array[i][j];
            }
        }

        return transposedArray;
    }

    public additionalOptionsColumnChart() {
        const options: any =
            { yAxis: { min: undefined, max: undefined }, legend: { x: 0 }, exporting: { enabled: true } }

        if (!this.isEnoughData) {
            options.yAxis.max = 100;
            options.yAxis.min = 0;
            options.exporting.enabled = false;
        }
        else {
            options.yAxis.max = undefined;
            options.yAxis.min = undefined;
            options.exporting.enabled = true;
        }

        return options;
    }
}