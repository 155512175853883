import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import InvitedUsersVM from './InvitedUsersVM';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import { Utils } from '@singularsystems/neo-core';
import InvitedUsersComponent from './Component/InvitedUsers';

@observer
export default class InvitedUsersView extends Views.ViewBase<InvitedUsersVM> {

	constructor(props: unknown) {
		super("Invited Users", InvitedUsersVM, props);
		Utils.bindAllMethods(this);
	}

	onLeave() {
		this.viewModel.removeNotifications();
		return undefined;
	}

	public render() {
		return <Neo.Card>
			<InvitedUsersComponent viewModel={this.viewModel.InvitedUsersViewModel} title={textConstants.titleText.ManageInvites} addHeaderPadding={true} />
		</Neo.Card>
	}
}