import React from 'react';
import { Views, Neo, NeoGrid } from '@singularsystems/neo-react';
import IndustriesVersionVM from './IndustriesVersionVM';
import { observer } from 'mobx-react';
import GoBack from 'Components/GoBack';
import { textConstants } from 'common/textConstants';
import * as Icon from 'react-feather';

@observer
export default class IndustriesVersionView extends Views.ViewBase<IndustriesVersionVM> {

    constructor(props: unknown) {
        super("Industries Version", IndustriesVersionVM, props);
    }

    public render() {
        return (
            <>
                <GoBack />
                <Neo.Card>
                    <h2 className='p-24 mt-4'>
                        {textConstants.titleText.IndustriesVersions}
                    </h2>

                    <Neo.Form
                        model={this.viewModel.industriesVersionsList}
                        onSubmit={() => this.viewModel.saveIndustriesVersionList()}
                        showSummaryModal>

                        <NeoGrid.Grid
                            items={this.viewModel.industriesVersionsList}
                            showAddButton
                            addButton={{
                                icon: <Icon.Plus />,
                                variant: "secondary",
                                disabled: this.viewModel.canAddNewItem(),
                                onClick: () => this.viewModel.AddNewItem()
                            }}>
                            {(industriesVersion, industriesVersionMeta) => (
                                <NeoGrid.Row >
                                    {/* ID - which doubles as Version Number */}
                                    <NeoGrid.Column
                                        display={industriesVersionMeta.industriesVersionId}
                                        readOnly />

                                    {/* Name */}
                                    <NeoGrid.Column
                                        bind={industriesVersionMeta.industryVersionName} />

                                    {/* Active Switch */}
                                    <NeoGrid.Column
                                        bind={industriesVersionMeta.isActive}
                                        input={{ type: "switch" }}
                                        onClick={e => {
                                            e.preventDefault()
                                            this.viewModel.setVersionAsActive(industriesVersion)
                                        }} />

                                    {/* New Items can be removed */}
                                    {this.viewModel.isNewItem(industriesVersion) &&
                                        <NeoGrid.ButtonColumn
                                            showDelete
                                            deleteButton={{
                                                icon: <Icon.X size={24} />,
                                                variant: "light",
                                                className: "circled",
                                                onClick: () => this.viewModel.removedNew(industriesVersion.industriesVersionId)
                                            }} />
                                    }

                                    {/* This Empty column is just to align with the new items that have a delete button column*/}
                                    {!this.viewModel.isNewItem(industriesVersion)
                                        && this.viewModel.newItemCounter > 0
                                        &&
                                        <td></td>
                                    }
                                </NeoGrid.Row>
                            )}
                        </NeoGrid.Grid>

                        {/* Save button */}
                        <div className="my-4 text-right p-24">
                            <Neo.Button className="btn-width-100" isSubmit variant="success">
                                {textConstants.buttonText.Save}
                            </Neo.Button>
                        </div>
                    </Neo.Form>

                    {/* Invalid Data Modal */}
                    <Neo.Modal title={textConstants.titleText.InvalidSelection}
                        show={this.viewModel.showInvalidModal}
                        onClose={() => this.viewModel.showInvalidModal = false}
                        closeButton={{ text: "Close", variant: "light" }}
                    >
                        {this.viewModel.invalidModalText.split('\n').map((item, i) => {
                            return <p key={i}>{item}</p>
                        })}
                    </Neo.Modal>
                </Neo.Card>
            </>
        );
    }
}