export enum CustomerQuestionType {
    // Question that only has a numeric value
    // Maps to Integer value
    Number,

    // Question that has a 2 point slider
    // Maps an array/list of 2 longs (IDs)
    TwoPointSlider,

    // Question with 1 point on a slider, also has a text comment box on the question
    // Maps to object with long (ID) and String
    NumOnePointSliderWithComment,

    // Question with text only box
    // Maps to string
    TextOnly,

    // Maps to a Drop Down List
    // Maps to Object (long and String) Array
    CreateableDropDown,

    // Maps to a question with that allows multiple check box selections
    // Will also contain a text field if Other was selected
    MultiCheckBoxWithOtherString,
}