import { injectable } from 'inversify';
import { Data, List, Model } from '@singularsystems/neo-core';
import Country from '../Models/Maintenance/Country';
import { AppService, Types } from '../Services/AppService';
import Axios, { AxiosPromise } from 'axios';
import CommandResult from '../Models/InvitedUsers/Commands/CommandResult';
import { ResponseResolver } from '../common/utils';
import DemoClientData from 'Models/Maintenance/DemoClientData';
import ApiResult from 'Models/ApiResult';
import { MockDataJSONType } from 'Models/Enums/ApplicationEnums';
import ClientListLookup from 'Models/Client/ClientListLookup';

export interface IDemoClientsApiClient extends Data.IUpdateableApiClient<DemoClientData, number> {
  getDemoClients(): AxiosPromise<Array<Model.PlainObject<DemoClientData>>>;
  saveDemoClients(demoClients: List<DemoClientData>): AxiosPromise<Model.PlainObject<ApiResult>>;
  uploadDocument(fileList: File[] | FileList, demoClientId: number, mockDataJSON: MockDataJSONType, clientId: number): AxiosPromise<Model.PlainObject<ApiResult>>;
  downloadMockData(demoClientId: number, jsonType: MockDataJSONType): AxiosPromise<any>;
  isDemoClient(clientId: number): AxiosPromise<boolean>
}

@injectable()
export default class DemoClientsApiClient extends Data.UpdateableApiClient<DemoClientData, number> implements IDemoClientsApiClient {

  constructor(public config = AppService.get(Types.Config)) {
    super(`${config.apiPath}/DemoClient`);
  }

  public getDemoClients(): AxiosPromise<Array<Model.PlainObject<DemoClientData>>> {
    return ResponseResolver<Array<Model.PlainObject<DemoClientData>>>(Axios.get(`${this.config.apiPath}/DemoClient/GetDemoClients`));
  }

  public saveDemoClients(demoClients: List<DemoClientData>): AxiosPromise<ApiResult> {
    const apiPath = AppService.get(Types.Config).apiPath;
    return Axios.post<ApiResult>(`${apiPath}/DemoClient/SaveDemoClients`, demoClients.toJSArray());
  }

  public uploadDocument(filelist: File[] | FileList, demoClientId: number, mockDataJSON: MockDataJSONType, clientId: number) {
    const apiPath = AppService.get(Types.Config).apiPath;

    const bodyFormData = new FormData();
    bodyFormData.set('files', filelist[0]);
    bodyFormData.set('jsonType', mockDataJSON.toString());
    bodyFormData.set('demoClientId', demoClientId.toString());
    bodyFormData.set('clientId', clientId.toString());

    return Axios.post(`${apiPath}/DemoClient/UploadDocument`, bodyFormData);
  }

  public downloadMockData(demoClientId: number, jsonType: MockDataJSONType) {
    const apiPath = AppService.get(Types.Config).apiPath;
    return ResponseResolver<any>(Axios.get(`${apiPath}/DemoClient/Download/${demoClientId}?jsonType=${jsonType}`));
  }
  
  public isDemoClient(clientId: number) {
    const apiPath = AppService.get(Types.Config).apiPath;
    return ResponseResolver<boolean>(Axios.get(`${apiPath}/DemoClient/IsActiveDemoClient/${clientId}`));
  }
}