import { injectable } from 'inversify';
import { Data, Model } from '@singularsystems/neo-core';
import BuyingCenter from '../Models/Maintenance/BuyingCenter';
import { AppService, Types } from '../Services/AppService';
import Axios, { AxiosPromise } from 'axios';
import { ResponseResolver } from '../common/utils';

export interface IBuyingCentersApiClient extends Data.IUpdateableApiClient<BuyingCenter, number> {
    getBuyingCentersAsync(): AxiosPromise<Array<Model.PlainObject<BuyingCenter>>>;
}

@injectable()
export default class BuyingCentersApiClient extends Data.UpdateableApiClient<BuyingCenter, number> implements IBuyingCentersApiClient {

    constructor(public config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/buyingCenter`);
    }

    public getBuyingCentersAsync(): AxiosPromise<Array<Model.PlainObject<BuyingCenter>>> {
        return ResponseResolver<Array<Model.PlainObject<BuyingCenter>>>(Axios.get(`${this.config.apiPath}/BuyingCenter/getBuyingCenters`));
    }
}