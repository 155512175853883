import React from "react";
import { Neo } from "@singularsystems/neo-react";
import { IPropertyInstance } from "@singularsystems/neo-core/dist/Model";
import * as Icon from "react-feather";
import SearchFieldVM from "./SearchFieldVM";

export interface ISearchField {
  searchBindableProp: IPropertyInstance;
  searchString: string;
  onClickFunction(e: React.KeyboardEvent<HTMLDivElement>): void;
  suppressLabel?: boolean;
  disabled?: boolean;
  searchStringLabel?: string;
  disableButton?: boolean;
  keyDown?: () => void;
  searchFieldVM: SearchFieldVM;
}

export default class SearchField extends React.Component<ISearchField> {
  search(e: any) {
    if (this.props.searchFieldVM !== null) {
      if (this.props.searchBindableProp.value !== "") {
        this.props.searchFieldVM!.isSearching = true;
      } else {
        this.props.searchFieldVM!.isSearching = false;
      }
    }
    this.props.onClickFunction(e);
  }

  clearSearch(e: any) {
    this.props.searchBindableProp.value = "";
    if (this.props.searchFieldVM !== null) {
      this.props.searchFieldVM!.isSearching = false;
    }
    this.props.onClickFunction(e);
  }

  public render() {
    const { searchString, searchBindableProp, disabled, disableButton } =
      this.props;

    return (
      <div className="searchComponent">
        <Neo.FormGroup
          prepend={
            (searchString.length > 0 && (
              <Neo.Button
                className=""
                variant="light"
                onClick={(e: any) => this.search(e)}
                icon={<Icon.Search />}
                disabled={disableButton}
              />
            )) || (
              <Neo.Button
                onClick={(e: any) => this.search(e)}
                variant="light"
                icon={<Icon.Search />}
                disabled={disableButton}
                className={`${
                  disableButton ? "customsearchbutton" : "customsearchbutton"
                }`}
              />
            )
          }
          bind={searchBindableProp}
          placeholder={
            this.props.searchStringLabel
              ? this.props.searchStringLabel
              : "Search"
          }
          suppressLabel={true}
          className={"searchbox pull-right"}
          disabled={disabled}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              this.search(e);
            }
            if (this.props.keyDown !== undefined) {
              this.props.keyDown();
            }
          }}
          append={
            <Neo.Button
              className="searchClear"
              variant="light"
              disabled={this.props.searchBindableProp.value === ""}
              onClick={(e: any) => {
                this.clearSearch(e);
              }}
              icon={<Icon.XCircle />}
            ></Neo.Button>
          }
        />
      </div>
    );
  }
}
