import {ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class TargetMarketManagementFunction extends ModelBase {

      targetMarketManagementFunctionId: number = 0;

      targetMarketId: number | null = 0;

      managementFunctionId: number | null= 0
      
      managementSubFunctionId: number| null = null;

      isPriority?: boolean = false;

}