import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class ActionListSummaryLookup extends ModelBase {
    public hotTotal: number = 0;
    public warmTotal: number = 0;
    public coldTotal: number = 0;
    public newHotTotal: number = 0;
    public newWarmTotal: number = 0;
    public newColdTotal: number = 0;
    public hotStatuses: string[] = [];
    public warmStatuses: string[] = [];
    public coldStatuses: string[] = [];
}