import { NeoModel, List } from '@singularsystems/neo-core';
import SourceType from './../../Models/Maintenance/SourceType';
import { AppService, Types } from '../../Services/AppService';
import { textConstants } from './../../common/textConstants';
import { Views } from '@singularsystems/neo-react';

@NeoModel
export default class SourceTypeVM extends Views.ViewModelBase {
    
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private sourceTypesApiClient =  AppService.get(Types.ApiClients.SourceTypesApiClient)) {

        super(taskRunner);
    }

    // Properties
    public sourceTypeList = new List(SourceType);

    public async initialise() {
        const response = await this.taskRunner.waitFor(this.sourceTypesApiClient.get());
        this.sourceTypeList.set(response.data);
    }

    public saveSourceTypeList(){
        this.taskRunner.run(async () => {
            const response = await this.sourceTypesApiClient.saveList(this.sourceTypeList.toJSArray());
            this.sourceTypeList.update(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved,textConstants.messageText.saveMessage.SourceTypesSaved);
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}