import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class UserGroup extends ModelBase {

    public userGroupId : number = 0;

    @Rules.Required()
    @Attributes.Display("Name")
    @Rules.StringLength(100)
    public userGroupName : string = "";

    public isAdministratorGroup = false;
    
    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString() {
        if (this.isNew || !this.userGroupName) {
            return "New user group"
        } else {
            return this.userGroupName;
        }
    }
}