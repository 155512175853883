import { List, ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';
import TargetMarketAccountStatus from './TargetMarketAccountStatus';

@NeoModel
export default class TargetMarketAccountTab extends ModelBase {
    public targetMarketAccountTabId: number = 0;
    
    @Rules.StringLength(500)
    @Attributes.Display("Tab")
    public tabName: string = "";

    public targetMarketAccountStatuses = new List(TargetMarketAccountStatus)

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.tabName === null || c.tabName === '', textConstants.messageText.validationMessage.countryName);
    }

    public toString(): string {
        if (this.isNew || !this.tabName) {
            return "New tab";
        } else {
            return this.tabName;
        }
    }
}