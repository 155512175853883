import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class TargetMarketSummary extends ModelBase {
    public clientId: number = 0;
    public clientName: string ="";
    public targetMarketName: string = "";
    public targetMarketAccountsCount: number = 0;
    public relevantMasterAccountCount: number = 0;
    public isIROStage: boolean = false;
    public iroEditStatusId: number = 0;
    public countryId: number = 0;
}