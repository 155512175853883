import { AppServices, ModalUtils, List, NeoModel, NotifyUtils, Misc } from "@singularsystems/neo-core";
import { Views } from '@singularsystems/neo-react';

import { injectable } from 'inversify';

import AuthorisationTypes from '../AuthorisationTypes';
import UserGroupLookup from "../Models/UserGroupLookup";
import UserGroup from "../Models/UserGroup";
import GroupMembershipLookup from "../Models/GroupMembershipLookup";
import AssignedRole from "../Models/AssignedRole";
import MemberFindCriteria from "../Models/MemberFindCriteria";
import NewMembership from "../Models/NewMembership";
import Resource from "../Models/Resource";
import UserLookup from '../Models/UserLookup';


export enum UserGroupsViewState {
  ManageList = 2,
  ManageItem = 3
}

@injectable()
@NeoModel
export default class UserGroupsViewModel extends Views.ViewModelBase {
  public selectedTab: string = "Members";

  public userGroupsLoaded: boolean = false;

  public userGroups = new List(UserGroupLookup);

  public resourceRoles = new List(Resource);

  public userList = new List(UserLookup);

  public userGroup = new UserGroup();

  public currentUserGroupLookup = new UserGroupLookup();

  public groupMemberships = new List(GroupMembershipLookup);

  public assignedRoles = new List(AssignedRole);

  public viewState: UserGroupsViewState = UserGroupsViewState.ManageList;

  public memberFindCriteria = new MemberFindCriteria();

  public isSaving: boolean = false;

  constructor(
    taskRunner = Misc.Globals.appService.get(AppServices.NeoTypes.TaskRunner),
    public apiClient = Misc.Globals.appService.get(AuthorisationTypes.ApiClients.AuthorisationApiClient),
    public rolesTask = Misc.Globals.appService.get(AppServices.NeoTypes.TaskRunner)) {

    super(taskRunner);
  }

  public async initialise() {
    this.refresh()
  }

  public async refresh() {
    // load the roles and users. Don't await these
    this.loadResourceRolesAsync();
    this.loadUsersAsync();

    // load the user groups, await these
    const response = await this.taskRunner.waitFor(this.apiClient.userGroups.getLookupAsync());
    this.userGroups.set(response.data);
    this.userGroupsLoaded = true;
  }

  public async loadResourceRolesAsync() {
    this.rolesTask.run(async () => {
      const response = await this.apiClient.resources.getRolesAsync();
      this.resourceRoles.set(response.data);

      if (this.viewState === UserGroupsViewState.ManageItem) {
        this.computeSelectedRoles();
      }
    });
  }

  public async loadUsersAsync() {
    try {
      const response = await this.apiClient.users.getLookupAsync();
      this.userList.set(response.data.sortBy("preferredName"));
    }
    catch (e) {
      alert(e);
    }
  }

  public addUserGroup() {
    this.currentUserGroupLookup = new UserGroupLookup();
    this.userGroup = new UserGroup();
    this.groupMemberships.set([]);
    this.assignedRoles.set([]);
    this.viewState = UserGroupsViewState.ManageItem;
  }

  public async manageUserGroup(item: UserGroupLookup) {
    try {
      const response = await this.apiClient.userGroups.get(item.userGroupId);

      this.currentUserGroupLookup = item;
      this.userGroup.set(response.data.userGroup);
      this.groupMemberships.set(response.data.groupMemberships);
      this.assignedRoles.set(response.data.assignedRoles);
      this.computeSelectedRoles();
      this.viewState = UserGroupsViewState.ManageItem;
    } catch (e) {
      alert(e);
    }
  }

  public computeSelectedRoles() {
    // make a set with the assigned role ids to make the lookup faster
    const roleMap: { [id: number]: AssignedRole } = {};
    this.assignedRoles.forEach((item, index) => {
      roleMap[item.roleId] = this.assignedRoles[index];
    });
    // now loop through all resource roles and mark as selected/unselected
    this.resourceRoles.forEach(resource => {
      resource.categories.forEach(category => {
        category.roles.forEach(role => {
          if (roleMap[role.roleId]) {
            role.selected = true;
            role.assignedRoleId = roleMap[role.roleId].assignedRoleId;
          } else {
            role.selected = false;
            role.assignedRoleId = null;
          }
        });
      });
      resource.markOld();
    });
  }

  public async addMember(isCheckboxAdd: boolean = false) {
    if (isCheckboxAdd) {
      const membership = new NewMembership();
      membership.userGroupId = this.userGroup.userGroupId;
      membership.userId = this.memberFindCriteria.addUserId as number;

      this.isSaving = true;
      const response = await this.apiClient.memberships.add(membership);
    }
    else {
      if (this.canAddMember()) {
        this.taskRunner.run(async () => {

          if (this.userGroup.isDirty) {
            // Save user groups so that we know we have a user group id
            await this.saveUserGroup();
          }

          const membership = new NewMembership();
          membership.userGroupId = this.userGroup.userGroupId;
          membership.userId = this.memberFindCriteria.addUserId as number;

          this.isSaving = true;
          const response = await this.apiClient.memberships.add(membership);
          // update the list to refresh the UI
          this.currentUserGroupLookup.memberCount++;
          this.groupMemberships.update([response.data]);
          // clear the selected user
          this.memberFindCriteria.addUserId = null;
          this.isSaving = false;

          NotifyUtils.addSuccess("Member Added", `New member added to ${this.userGroup.userGroupName}`);
        }
        );
      }
    }
  }

  public canAddMember() {
    if (this.memberFindCriteria.addUserId) {

      const existingMember = this.groupMemberships.find(
        m => m.userId === Number(this.memberFindCriteria.addUserId)
      );
      if (existingMember) {
        NotifyUtils.addWarning("Already a member", `${existingMember.memberName} is already a member of the group`);
        this.memberFindCriteria.addUserId = null;
        return false;
      }
      return true;
    } else {
      NotifyUtils.addWarning("Select user", "Select the user first");
    }
    return false;
  }

  public async removeMember(member: GroupMembershipLookup, isCheckboxRemoval?: boolean) {

    if (isCheckboxRemoval) {
      await this.performRemove(member, isCheckboxRemoval);
      return
    }

    if (await ModalUtils.showYesNo(
      `Remove '${member.memberName}'?`,
      `Are you sure you want to remove member '${member.memberName}'?`)
      === Misc.ModalResult.Yes) {
      this.performRemove(member);
    }
  }

  private async performRemove(member: GroupMembershipLookup, isCheckboxRemove?: boolean) {

    if (isCheckboxRemove) {
      this.isSaving = true;
      await this.apiClient.memberships.remove(member.membershipId);
      this.isSaving = false;
    }

    if (!isCheckboxRemove) {
      this.taskRunner.run(async () => {
        this.isSaving = true;
        await this.apiClient.memberships.remove(member.membershipId);
        this.currentUserGroupLookup.memberCount--;
        await this.groupMemberships.remove(member);
        this.isSaving = false;
        NotifyUtils.addSuccess("Member Removed", `Member removed from ${this.userGroup.userGroupName}`);
      });
    }
  }

  public canSave(): boolean {
    return (
      this.isSaving || (!this.userGroup.isDirty && !this.resourceRoles.isDirty)
    );
  }

  public async save() {
    this.taskRunner.run(async () => {
      if (this.userGroup.isDirty) {
        await this.saveUserGroup();
      }

      if (this.resourceRoles.isDirty) {
        await this.saveAssignedRoles();
      }

      NotifyUtils.addSuccess("Saved Successfully", `User group ${this.userGroup.userGroupName} saved successfully`);
    });
  }

  public async saveAssignedRoles() {
    this.updateAssignedRoles();

    this.isSaving = true;
    const response = await this.apiClient.assignedRoles.save(this.assignedRoles);

    this.assignedRoles.update(response.data);
    this.computeSelectedRoles();
    this.isSaving = false;
  }

  private updateAssignedRoles() {
    this.resourceRoles.forEach(resource => {
      if (resource.isDirty) {
        resource.categories.forEach(category => {
          if (category.isDirty) {
            category.roles.forEach(role => {
              if (role.isDirty) {
                if (role.selected && role.assignedRoleId === null) {
                  // this is a new role
                  const newRole = this.assignedRoles.addNew();
                  newRole.userGroupId = this.userGroup.userGroupId;
                  newRole.roleId = role.roleId;
                }
                else if (role.selected === false && role.assignedRoleId !== null) {
                  // this is a deleted role
                  const roleToDelete = this.assignedRoles.find(r => r.assignedRoleId === role.assignedRoleId);
                  this.assignedRoles.remove(roleToDelete as AssignedRole);
                }
              }
            });
          }
        });
      }
    });
  }

  public async saveUserGroup() {
    this.isSaving = true;
    const response = await this.apiClient.userGroups.save(this.userGroup);

    this.userGroup.set(response.data);
    // update the base list (to add if new, and change name if existing)
    if (!this.currentUserGroupLookup.isNew) {
      // set the entity identifier so that it does not add a new one to the list
      response.data.entityIdentifier = this.currentUserGroupLookup.entityIdentifier;
    }
    this.userGroups.update([response.data]);

    if (this.currentUserGroupLookup.isNew) {
      // find the one that was just added
      this.currentUserGroupLookup = this.userGroups.find(
        ug => ug.userGroupId === this.userGroup.userGroupId
      ) as UserGroupLookup;
    }
    this.isSaving = false;
  }

  public deleteUserGroup(item: UserGroupLookup) {
    ModalUtils.showYesNo(
      `Delete '${item.userGroupName}'?`,
      `Are you sure you want to delete user group '${item.userGroupName}'?`,
      () => this.taskRunner.run(async () => {
        this.isSaving = true;
        await this.apiClient.userGroups.delete(item.userGroupId);
        this.userGroups.remove(item);
        this.isSaving = false;

        NotifyUtils.addSuccess("User Group Deleted", `User group ${this.userGroup.userGroupName} has been deleted`);
      })
    );
  }
}
