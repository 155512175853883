import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class BlockCommand extends ModelBase {

    public id : number = 0;

    public reason : string = "";

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.reason) {
            return 'New UnblockCommand';
        } else {
            return this.reason;
        }
    }
}