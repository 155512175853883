import { ModelBase, NeoModel } from "@singularsystems/neo-core";

@NeoModel
export default class TargetMarketPieChartLookup extends ModelBase {
    public targetMarketId: number = 0;

    public targetMarketName: string = "";
    
    public progressDone: number = 0;

    public progressProspected: number = 0;

    public progressToDo: number = 0;
}