import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import BackgroundJobTypeConfig from '../../Models/Maintenance/BackgroundJobType';
import { AppService, Types } from '../../Services/AppService';

@NeoModel
export default class BackgroundJobConfigVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private backgroundJobConfigApiClient = AppService.get(Types.ApiClients.BackgroundJobTypeApiClient),
        private appDataCache = AppService.get(Types.Services.AppDataCache),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

        super(taskRunner);
    }

    public backgroundJobConfigList = new List(BackgroundJobTypeConfig)

    public async initialise() {
        const backgroundJobResponse = await this.taskRunner.waitFor(this.backgroundJobConfigApiClient.get());
        this.backgroundJobConfigList.set(backgroundJobResponse.data);
    }

    public saveBackgroundJobConfigList() {
        this.taskRunner.run(async () => {
            const response = await this.backgroundJobConfigApiClient.saveList(this.backgroundJobConfigList.toJSArray());

            if (response.request.status === 200) {
                this.backgroundJobConfigList.update(response.data);
                this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.ConfigSaved);
                // Refresh the backgroundJob type configuration common data list
                this.appDataCache.backgroundJobTypeConfigs.expire(true);
            } else{
                this.notifications.addWarning(textConstants.titleText.Error, textConstants.messageText.saveMessage.ConfigFailed)
            }

        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}