import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { textConstants } from '../common/textConstants';
import { AppService, Types } from '../Services/AppService';
import { Navigation } from '../Models/Enums/ApplicationEnums';
import * as Roles from '../Models/Security/Roles';
import ClientNavigationButtonsVM from './ClientNavigationButtonsVM';

interface INavigation {
  viewModel: ClientNavigationButtonsVM
  back: () => void
}

@observer
export default class ClientNavigationButtons extends React.Component<INavigation> {

  private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);
  protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService)

  public render() {
    return (
      <div className='clientNavigation'>
        <label className="clientText mt-2">
          {textConstants.titleText.Client}:
          <span className=" ml-4 mr-4">
            {this.props.viewModel.clientName}
          </span>
        </label>

        {/*Small navigation buttons */}
        {this.authorisationService.hasRole(Roles.Clients.Settings) &&
          !this.props.viewModel.isClientSettings &&
          <Neo.Button
            data-tip={"View Client Settings"}
            isOutline variant="secondary" className="singleIconBtn" icon={"cogs"}
            onClick={() => {
              this.props.viewModel.navigateTo = Navigation.goToClientSettings;
              this.props.back()
            }}
          >
          </Neo.Button>}

        {this.authorisationService.hasRole(Roles.TargetMarkets.Manage) &&
          !this.props.viewModel.isTargetMarkets &&
          <Neo.Button
            data-tip={"View Target Markets"}
            isOutline variant="secondary" className="singleIconBtn" icon={"flag"}
            onClick={() => {
              this.props.viewModel.navigateTo = Navigation.goToTargetMarkets;
              this.props.back()
            }}
          >
          </Neo.Button>}

        {this.authorisationService.hasRole(Roles.IdealCustomerProfile.Manage) &&
          !this.props.viewModel.isIdealCustomerProfiles &&
          <Neo.Button
            data-tip={"View Ideal Customer Profiles"}
            isOutline variant="secondary" className="singleIconBtn" icon={"bullseye"}
            onClick={() => {
              this.props.viewModel.navigateTo = Navigation.goToCustomerProfiles;
              this.props.back();
            }}></Neo.Button>}


        {this.authorisationService.hasRole(Roles.CampaignMessages.Manage) &&
          !this.props.viewModel.isCampaignMessages &&
          <Neo.Button
            data-tip={"View Campaign Messages"}
            isOutline variant="secondary" className="singleIconBtn" icon={"envelope"}
            onClick={() => {
              this.props.viewModel.navigateTo = Navigation.goToCampaignMessages;
              this.props.back()
            }}></Neo.Button>}

        {!this.props.viewModel.isReporting &&
          <Neo.Button
            data-tip={"View Client Dashboard"}
            isOutline variant="secondary" className="singleIconBtn" icon={"fa-arrow-right-to-bracket"}
            onClick={() => {
              this.props.viewModel.navigateTo = Navigation.goToClientReports;
              this.props.back()
            }}>
          </Neo.Button>}
        {/*End Small navigation buttons */}

      </div>
    );
  }
}