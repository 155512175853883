import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import TargetMarketAccountTab from './TargetMarketAccountTab';
import TargetMarketAccountStatus from './TargetMarketAccountStatus';

@NeoModel
export default class TargetMarketAccountTabStatus extends ModelBase {

    public targetMarketAccountTabStatusId: number = 0;

    @Rules.Required()
    @Attributes.Display("Tab")
    public targetMarketAccountTabId: number | null = null;

    @Attributes.ChildObject(TargetMarketAccountTab)
    public targetMarketAccountTab: TargetMarketAccountTab | null = null;

    @Rules.Required()
    @Attributes.Display("Status")
    public targetMarketAccountStatusId: number | null = null;

    @Attributes.ChildObject(TargetMarketAccountStatus)
    public targetMarketAccountStatus: TargetMarketAccountStatus | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New Tab Status";
        } else {
            return "Tab Status";
        }
    }
}