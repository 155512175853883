import { Attributes, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';

@NeoModel
export default class BlacklistedDomain extends ModelBase {

    // Properties
    public blacklistedDomainId: number = 0;

    public clientId: number = 0    

    @Rules.StringLength(500)
    @Attributes.Display("Domain")
    public domain: string = "";    

    public toString(): string {
        if (this.isNew || !this.domain) {
            return "New domain";
        } else {
            return this.domain;
        }
    }
}