import { ModelBase, NeoModel } from '@singularsystems/neo-core';
import { textConstants } from '../../../common/textConstants';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';
import { Required } from '@singularsystems/neo-core/dist/Validation/Decorators';

@NeoModel
export class UpdateFollowUpTemplateNameCommand extends ModelBase {
    public clientFollowUpTemplateId: number = 0;
  
    @Required(textConstants.messageText.validationMessage.clientFollowUpTemplateName)
    @Display("Name")
    public clientFollowUpTemplateName: string = ""
  }