import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import Industry from '../Models/Maintenance/Industry';

export interface IIndustriesApiClient extends Data.IUpdateableApiClient<Industry, string> {

    // Client only properties / methods
}

@injectable()
export default class IndustriesApiClient extends Data.UpdateableApiClient<Industry, string> implements IIndustriesApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/industries`);
    }

    // Client only properties / methods
}