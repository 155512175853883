import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import DashboardReportConfig from '../../Models/Maintenance/DashboardReportConfig';
import { AppService, Types } from '../../Services/AppService';

@NeoModel
export default class DashboardReportConfigVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private dashboardReportConfigApiClient =  AppService.get(Types.ApiClients.DashboardReportConfigApiClient),
        private appDataCache = AppService.get(Types.Services.AppDataCache)) {

        super(taskRunner);
    }

    public dashboardReportingList = new List(DashboardReportConfig)

    public async initialise() {
        const dashboardReportingResponse = await this.taskRunner.waitFor(this.dashboardReportConfigApiClient.get());
        this.dashboardReportingList.set(dashboardReportingResponse.data);
    }

    public saveDashboardReportConfigList(){
        this.taskRunner.run(async () => {
            const response = await this.dashboardReportConfigApiClient.saveList(this.dashboardReportingList.toJSArray());
            this.dashboardReportingList.update(response.data);
            this.notifications.addSuccess(textConstants.titleText.Saved,textConstants.messageText.saveMessage.ConfigSaved);

            // Refresh the comXConfiguration common data list
            this.appDataCache.dashboardReportConfigs.expire(true);
        });
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}