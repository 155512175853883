import React from "react";
import { observer } from "mobx-react";
import * as Icon from 'react-feather';
import LeadMessageVM from "./LeadMessageVM";
import { textConstants } from "common/textConstants";

interface IActionListFooterComponent {
  viewModel: LeadMessageVM
}

@observer
export class ActionListNewMessage extends React.Component<IActionListFooterComponent> {
  public render() {
    const { viewModel } = this.props

    return (
      <div className="col">
        {/* New Message footer buttons */}
        <div className="row">
          <div className="searchTemplatesBordered clickableCursor"
            onClick={() => { viewModel.isSearchingTemplates = true }}>
            <div className="row searchTemplates">
              <div className="searchButtonForEmail">
                <Icon.Search size={24} />
              </div>
              <div className="col pl-0 m-auto" >
                <div className="searchBar">{textConstants.buttonText.OpenTemplate}</div>
              </div>
            </div>
          </div>
          <div className="col"></div>
          <button className="primaryActionLeadsButton pull-right ml-4"
            onClick={() => {
              if (viewModel.isEmailSelected()) {
                viewModel.sendEmail()
              }
            }}>
            {textConstants.buttonText.SendMessage}
          </button>
        </div>
      </div>
    );
  }
}

@observer
export class ActionListSearchTemplates extends React.Component<IActionListFooterComponent> {
  state = {
    searchString: ""
  };

  public scrollOffset: number = 0;
  private scrollContainerRef = React.createRef<HTMLDivElement>();

  private handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    this.scrollOffset = event.currentTarget.scrollLeft;
  };

  private handleMouseWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    const scrollAmount = event.deltaY * 0.2;
    const scrollContainer = this.scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.scrollLeft += scrollAmount;
      this.scrollOffset = scrollContainer.scrollLeft;
      event.preventDefault();
    }
  };

  handleChange = (event: any) => {
    const newSearchString = event.target.value;
    this.setState({ searchString: newSearchString });
    this.props.viewModel.searchString = newSearchString;
    this.props.viewModel.SearchList()
  }

  clearSearch = () => {
    this.setState({ searchString: "" });
    this.props.viewModel.searchString = "";
    this.props.viewModel.SearchList();
    const searchTextElement = document.getElementById("searchText") as HTMLInputElement;
    if (searchTextElement) {
      searchTextElement.value = "";
    }
  };

  handleClick() {
    this.forceUpdate()
  }

  public render() {
    const { viewModel } = this.props

    return (
      <div className="col">

        <div className="row">
          <div className="col p-0">
            <div className="row searchTemplatesBordered">

              {/* Search templates */}
              <div className="row searchTemplates">
                <div className="searchButtonForEmail clickableCursor"
                  onClick={() => { this.clearSearch() }}>
                  <Icon.X size={24} />
                </div>
                <div className="pl-0" >
                  <input
                    id="searchText"
                    className="searchBar"
                    disabled={viewModel.followupTemplateRibbonDisplay}
                    placeholder={textConstants.LeadEmailCreator.SearchTemplates}
                    onChange={this.handleChange}></input>
                </div>
              </div>

              {/* Button bar with overflow */}
              <div className="col overflowScroll" ref={this.scrollContainerRef}
                onScroll={this.handleScroll}
                onWheel={this.handleMouseWheel}>

                {viewModel.followupTemplateRibbonDisplay === false &&
                  <div className="followUpTemplatesList">
                    {/* Back button to the email view */}
                    <div className='followUpTemplateButton newMessage'>
                      <button
                        onClick={() => {
                          viewModel.isSearchingTemplates = false
                          this.clearSearch();
                        }}>
                        <Icon.ArrowLeft size={14} />
                        <div>{textConstants.buttonText.Back}</div>
                      </button>
                    </div>

                    {/* New message button to pull up the old message */}
                    <div key={viewModel.emailMessageText} className='followUpTemplateButton newMessage'>
                      <button
                        onClick={() => { viewModel.templateText = "" }}
                        onMouseOver={() => { viewModel.placeholderText = textConstants.LeadEmailCreator.NewMessage }}>
                        <Icon.FilePlus size={14} />
                        <div>{textConstants.LeadEmailCreator.NewMessage}</div>
                      </button>
                    </div>

                    {/* All the follow up templates available */}
                    {viewModel.filteredFollowUpTemplateList.map(item => (
                      <div key={item.clientFollowUpTemplateId} className='followUpTemplateButton'>
                        <button
                          onClick={() => {
                            viewModel.setEmailTemplateRibbon(item.clientFollowUpTemplateId, item.followUpTemplateName)
                            this.forceUpdate()
                          }}>
                          <Icon.FileText size={14} />{item.followUpTemplateName}
                        </button>
                      </div>
                    ))}
                  </div>
                }

                {viewModel.followupTemplateRibbonDisplay === true &&
                  <div className="followUpTemplatesList">
                    {/* Returns to the open templates view */}
                    <div className='followUpTemplateButton newMessage'>
                      <button
                        onClick={() => {
                          viewModel.followupTemplateRibbonDisplay = false
                          viewModel.selectedClientFollowUpTemplate = 0;
                          viewModel.selectedClientFollowUpTemplateName = ""
                          this.forceUpdate()
                        }}>
                        <Icon.ArrowLeft size={14} />
                        <div>{textConstants.buttonText.Back}</div>
                      </button>
                    </div>

                    {/* Clears the message */}
                    <div key={viewModel.emailMessageText} className='followUpTemplateButton newMessage'>
                      <button
                        onClick={() => { viewModel.templateText = "" }}
                        onMouseOver={() => { viewModel.placeholderText = "New Message" }}>
                        <Icon.Delete size={14} />
                        <div>{textConstants.buttonText.Clear}</div>
                      </button>
                    </div>

                    {/* Maps all the ordinal emails */}
                    {viewModel.individualTemplatesList.map(item => {
                      if (item.clientFollowUpTemplateId === viewModel.selectedClientFollowUpTemplate && item.messageText !== "") {
                        return <>
                          <div key={item.followUpTemplateId} className='followUpTemplateButton'>
                            <button
                              onClick={async () => {
                                viewModel.setTemplateMessage(item.clientFollowUpTemplateId, item.ordinal);
                              }}
                              onMouseOver={() => { viewModel.getTemplateMessage(item.clientFollowUpTemplateId, item.ordinal); }}
                            >
                              <Icon.FileText size={14} />Email {item.ordinal}
                            </button>
                          </div>
                        </>;
                      } else {
                        return;
                      }
                    }
                    )}
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default class ActionListDefault extends React.Component<IActionListFooterComponent> {
  public render() {

    return (
      <div className="col">
        {/* Default footer buttons */}
        <div className="row">
          <div className="col"></div>
          <button className="primaryActionLeadsButton"
            onClick={() => { this.props.viewModel.clickCreateMessage() }}>
            {textConstants.buttonText.CreateMessage}
          </button>
        </div>
      </div>
    );
  }
}