import { ModelBase, NeoModel, Rules } from '@singularsystems/neo-core'; 

@NeoModel 
export default class SaveSignatureCommand extends ModelBase { 
   @Rules.Required()
   public campaignMessageId: number = 0;

   @Rules.Required()
   public clientCampaignMessageId: number = 0;

   @Rules.Required()
   public clientId: number = 0;

   @Rules.Required()
   public campaignName: string=""

   @Rules.Required()
   public clientCampaignMessageStatusId: number = 0;

   public messageText: string=""

   public draftText: string=""

   public isPublish: boolean = false

   public isClientReview: boolean = false
}