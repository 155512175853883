import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import MasterAccountsVM from '../MasterAccountsVM';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import { isNumber } from 'lodash';
import * as Roles from '../../../Models/Security/Roles';
import * as Icon from "react-feather"

export const helperConst = {
    emptyText: "No accounts found. Please select a country and/or change search/filter",
    searchString: "searchString",
    idProperty: "masterAccountId",
    initialPageSize: 20,
    gridStyle: { minHeight: 600, maxWidth: 2000 },
}

const inListFilter = {
    filterOperator: "inlist",
    filterType: "inlistFilerType"
}

const textFilter = {
    filterOperator: "Contains",
    filterType: "textFreeFilter"
}

const filterMasterAccountStatuses = {
    key: "filterMasterAccountStatus",
    label: "accountStatusName",
    id: "accountStatusName",
    isMultiple: true,
}
// const whiteListFilterList = {
//     key: "filteredWhiteListFilterList",
//     label: "filterWhiteListName",
//     id: "filterWhiteListName",
//     isMultiple: true,
// }

const filteredIndustries = {
    key: "filteredIndustrySubIndustry",
    label: "subIndustryName",
    id: "subIndustryName",
    isMultiple: true,
}

const filteredIndustriesVersions = {
    key: "filteredIndustriesVersions",
    label: "industriesVersion",
    id: "industriesVersion",
    isMultiple: true,
}

const filteredEmployeeSize = {
    key: "filteredEmployeeSizes",
    label: "employeeSizeName",
    id: "employeeSizeName",
    isMultiple: true,
}

const filteredCreatedOn = {
    key: "filteredCreatedOn",
    label: "audit_CreatedOn",
    id: "audit_CreatedOn",
    isMultiple: true,
}

const filteredInvalidReason = {
    key: "filteredInvalidReason",
    label: "invalidReasonMessage",
    id: "invalidReasonMessage",
    isMultiple: true,
}

const filteredReOpenedCount = {
    key: "filteredReOpenedCount",
    label: "reOpenedCount",
    id: "reOpenedCount",
    isMultiple: true,
}



// const filteredProspectedTimes = {
//     key: "filteredProspectedTimes",
//     label: "prospectedTimes",
//     id: "prospectedTimesId",
//     isMultiple: true,
// }

const MasterAccountGridColumns = {
    masterAccountId: {
        name: "masterAccountId",
        display: "masterAccountId",
        // filter: inListFilter
    },
    fixedName: {
        name: "fixedName",
        display: "Fixed Name",
        filter: textFilter
    },
    originalName: {
        name: "originalName",
        display: "Original Name",
        filter: textFilter
    },
    description: {
        name: "description",
        display: "Description",
    },
    logoUrl: {
        name: "logoUrl",
        display: "Logo URL",
    },
    region: {
        name: "region",
        display: "Region",
        filter: textFilter
    },
    location: {
        name: "location",
        display: "Location",
        filter: textFilter
    },
    employeeSize: {
        name: "employeeSize",
        display: "Company Size",
        filter: inListFilter
    },
    industrySubIndustry: {
        name: "industrySubIndustry",
        display: "Industry",
        filter: inListFilter
    },
    industriesVersion: {
        name: "industriesVersion",
        display: "Industries Version",
        filter: inListFilter
    },
    domain: {
        name: "domain",
        display: "Domain",
        filter: textFilter
    },
    status: {
        name: "status",
        display: "Status",
        filter: inListFilter
    },
    invalidReason: {
        name: "invalidReason",
        display: "Invalid Reason",
        filter: inListFilter
    },
    dateAdded: {
        name: "dateAdded",
        display: "Date Added",
        filter: inListFilter
    },
    edit: {
        name: "edit",
        display: "Edit"
    },
    reOpenedCount: {
        name: "reOpenedCount",
        display: "Re-Opened",
        filter: inListFilter
    },
    isDeleted: {
        name: "isDeleted",
        display: "Delete"
    },
}


export const CreateColumns = (viewModel: MasterAccountsVM) => {
    return [
        {
            defaultLocked: true,
            name: MasterAccountGridColumns.masterAccountId.name,
            header: MasterAccountGridColumns.masterAccountId.display,
            defaultVisible: false,
            render: ({ value, data }: any) => (
                <div>
                    {data.masterAccountId}
                </div>
            ),
        },
        {
            defaultLocked: 'start',
            name: MasterAccountGridColumns.fixedName.name,
            header: MasterAccountGridColumns.fixedName.display,
            // isFilter: true,
            // filterKey: filterTargetMarketStatuses,
            sortable: true,
            defaultFlex: 1,
            minWidth: 180,
        },
        {
            name: MasterAccountGridColumns.description.name,
            header: MasterAccountGridColumns.description.display,
            defaultFlex: 1,
            sortable: true,
            minWidth: 180,
        },
        {
            name: MasterAccountGridColumns.logoUrl.name,
            header: MasterAccountGridColumns.logoUrl.display,
            defaultFlex: 1,
            sortable: true,
            minWidth: 180,
        },
        {
            defaultLocked: 'start',
            name: MasterAccountGridColumns.originalName.name,
            header: MasterAccountGridColumns.originalName.display,
            defaultFlex: 1,
            minWidth: 250,
            sortable: true
        },
        {
            name: MasterAccountGridColumns.region.name,
            header: MasterAccountGridColumns.region.display,
            minWidth: 180,
            sortable: true
        },
        {
            name: MasterAccountGridColumns.location.name,
            header: MasterAccountGridColumns.location.display,
            defaultFlex: 1,
            sortable: true,
            minWidth: 180,
        },
        {
            name: MasterAccountGridColumns.employeeSize.name,
            header: MasterAccountGridColumns.employeeSize.display,
            isFilter: true,
            filterKey: filteredEmployeeSize,
            sortable: true,
            minWidth: 180,
        },
        {
            name: MasterAccountGridColumns.industrySubIndustry.name,
            header: MasterAccountGridColumns.industrySubIndustry.display,
            isFilter: true,
            filterKey: filteredIndustries,
            sortable: true,
            minWidth: 180,
        },
        {
            name: MasterAccountGridColumns.industriesVersion.name,
            header: MasterAccountGridColumns.industriesVersion.display,
            isFilter: true,
            filterKey: filteredIndustriesVersions,
            sortable: true,
            minWidth: 180,
        },
        {
            name: MasterAccountGridColumns.domain.name,
            header: MasterAccountGridColumns.domain.display,
            defaultFlex: 1,
            sortable: true,
            minWidth: 180,
        },
        {
            // defaultLocked: 'start',
            name: MasterAccountGridColumns.status.name,
            header: MasterAccountGridColumns.status.display,
            isFilter: true,
            filterKey: filterMasterAccountStatuses,
            // sortable: true,
            minWidth: 180,
        },
        {
            // defaultLocked: 'start',
            name: MasterAccountGridColumns.invalidReason.name,
            header: MasterAccountGridColumns.invalidReason.display,
            isFilter: true,
            filterKey: filteredInvalidReason,
            // sortable: true,
            minWidth: 180,
        },
        {
            // defaultLocked: 'start',
            name: MasterAccountGridColumns.reOpenedCount.name,
            header: MasterAccountGridColumns.reOpenedCount.display,
            isFilter: true,
            filterKey: filteredReOpenedCount,
            // sortable: true,
            sortable: true,
            minWidth: 180,
        },
        {
            name: MasterAccountGridColumns.dateAdded.name,
            header: MasterAccountGridColumns.dateAdded.display,
            defaultFlex: 1,
            isFilter: true,
            filterKey: filteredCreatedOn,
            sortable: true,
            minWidth: 180,
        },
        {
            name: MasterAccountGridColumns.edit.name,
            header: MasterAccountGridColumns.edit.display,
            defaultFlex: 0.5,
            sortable: true,
            defaultLocked: 'end',
            minWidth: 80,
            render: ({ value, data }: any) => (
                <div className="text-center">
                    <Neo.Button variant="light" className="text-center gridButton customGridButtonPadding" icon={"edit"}
                        onClick={() => viewModel.securityCheck(Roles.MasterData.Manage,
                            () => viewModel.openEditModal(data))}
                    />
                </div>
            )
        },
        {
            name: MasterAccountGridColumns.isDeleted.name,
            header: MasterAccountGridColumns.isDeleted.display,
            defaultFlex: 0.5,
            sortable: true,
            defaultLocked: 'end',
            minWidth: 80,
            render: ({ value: isDeleted, data: masterAccount }: any) => (

                <div className="text-center">
                    {/* Un-delete button */}
                    {isDeleted &&
                        <Neo.Button variant="light" className="text-center buttonHover circled"
                            icon={<Icon.RotateCw />}
                            onClick={() => viewModel.securityCheck(Roles.MasterData.Manage,
                                () => viewModel.undeleteMasterAccount(masterAccount.masterAccountId))}>
                        </Neo.Button>}

                    {/* Delete button */}
                    {!isDeleted &&
                        <Neo.Button variant="danger" className="text-center masterAccountsGrid gridButton" icon={<Icon.Archive/>}
                            onClick={() => viewModel.securityCheck(Roles.MasterData.Manage,
                                () => viewModel.openDeleteModal(masterAccount))} />
                    }
                </div>
            )
        },
    ]
}

export const MasterAccountFilterColumns = () => {
    return [
        // {
        //     defaultLocked: true,
        //     name: MasterAccountGridColumns.masterAccountId.name,
        //     header: MasterAccountGridColumns.masterAccountId.display,
        //     defaultVisible: false,
        // },
        {
            name: MasterAccountGridColumns.fixedName.name,
            operator: MasterAccountGridColumns.fixedName.filter.filterOperator,
            type: MasterAccountGridColumns.fixedName.filter.filterType,
            value: null,
        },
        {
            name: MasterAccountGridColumns.originalName.name,
            operator: MasterAccountGridColumns.originalName.filter.filterOperator,
            type: MasterAccountGridColumns.originalName.filter.filterType,
            value: ''
        },
        {
            name: MasterAccountGridColumns.region.name,
            operator: MasterAccountGridColumns.region.filter.filterOperator,
            type: MasterAccountGridColumns.region.filter.filterType,
            value: ''
        },

        {
            name: MasterAccountGridColumns.employeeSize.name,
            operator: MasterAccountGridColumns.employeeSize.filter.filterOperator,
            type: MasterAccountGridColumns.employeeSize.filter.filterType,
            value: null,
        },
        {
            name: MasterAccountGridColumns.location.name,
            operator: MasterAccountGridColumns.location.filter.filterOperator,
            type: MasterAccountGridColumns.location.filter.filterType,
            value: ''
        },
        {
            name: MasterAccountGridColumns.domain.name,
            operator: MasterAccountGridColumns.domain.filter.filterOperator,
            type: MasterAccountGridColumns.domain.filter.filterType,
            value: ''
        },
        {
            name: MasterAccountGridColumns.industrySubIndustry.name,
            operator: MasterAccountGridColumns.industrySubIndustry.filter.filterOperator,
            type: MasterAccountGridColumns.industrySubIndustry.filter.filterType,
            value: null,
        },
        {
            name: MasterAccountGridColumns.industriesVersion.name,
            operator: MasterAccountGridColumns.industriesVersion.filter.filterOperator,
            type: MasterAccountGridColumns.industriesVersion.filter.filterType,
            value: null,
        },
        {
            name: MasterAccountGridColumns.status.name,
            operator: MasterAccountGridColumns.status.filter.filterOperator,
            type: MasterAccountGridColumns.status.filter.filterType,
            value: null,
        },
        {
            name: MasterAccountGridColumns.invalidReason.name,
            operator: MasterAccountGridColumns.invalidReason.filter.filterOperator,
            type: MasterAccountGridColumns.invalidReason.filter.filterType,
            value: null,
        },
        {
            name: MasterAccountGridColumns.reOpenedCount.name,
            operator: MasterAccountGridColumns.reOpenedCount.filter.filterOperator,
            type: MasterAccountGridColumns.reOpenedCount.filter.filterType,
            value: null,
        },
        {
            name: MasterAccountGridColumns.dateAdded.name,
            operator: MasterAccountGridColumns.dateAdded.filter.filterOperator,
            type: MasterAccountGridColumns.dateAdded.filter.filterType,
            value: null,
        },
        // {
        //     name: MasterAccountGridColumns.dateLastProspected.name,
        //     operator: MasterAccountGridColumns.dateLastProspected.filter.filterOperator,
        //     type: MasterAccountGridColumns.dateLastProspected.filter.filterType,
        //     value: null,
        // },
        // {
        //     name: MasterAccountGridColumns.timesProspected.name,
        //     operator: MasterAccountGridColumns.timesProspected.filter.filterOperator,
        //     type: MasterAccountGridColumns.timesProspected.filter.filterType,
        //     value: null
        // },
        // {
        //     name: MasterAccountGridColumns.whitelistImport.name,
        //     operator: MasterAccountGridColumns.whitelistImport.filter.filterOperator,
        //     type: MasterAccountGridColumns.whitelistImport.filter.filterType,
        //     value: null,
        // },
    ];
}

export const MasterAccountFilterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
    textFreeFilter: {
        type: 'string',
        emptyValue: null,
        operators: [
            {
                name: 'Contains',
                fn: ({ value, filterValue, data }: any) => {
                    return filterValue !== null ? filterValue === value : true;
                }
            },
            {
                name: 'Does not contain',
                fn: ({ value, filterValue, data }: any) => {
                    return filterValue !== null ? filterValue !== value : true;
                }
            }
        ]
    },
    numberFilter: {
        type: 'number',
        emptyValue: null,
        operators: [
            {
                name: 'eq',
                fn: ({ value, filterValue, data }: any) => {
                    if (isNumber(filterValue)) {
                        return filterValue !== null ? filterValue === value : true;
                    }
                    else {
                        return true
                    }
                }
            }
        ]
    },
    inlistFilerType: {
        type: 'inlistFilerType',
        emptyValue: null,
        operators: [
            {
                name: 'inlist',
                fn: ({ value, filterValue, data }: any) => {
                    return filterValue !== null ? filterValue === value : true;
                }
            },
            {
                name: 'notinlist',
                fn: ({ value, filterValue, data }: any) => {
                    return filterValue !== null ? filterValue !== value : true;
                }
            }
        ]
    }
});
