import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import CustomerProfileQuestionVM from './CustomerProfileQuestionVM';
import { observer } from 'mobx-react';
import GoBack from '../../Components/GoBack';
import { textConstants } from '../../common/textConstants';
import RichTextEditor from '../../Components/RichTextEditor';
import { ICPCopyTextType } from '../../Authorisation/Models/Enums/ICPCopyTextType';
import * as Icon from 'react-feather';

@observer
export default class CustomerProfileQuestionView extends Views.ViewBase<CustomerProfileQuestionVM> {

    constructor(props: unknown) {
        super("CustomerProfileQuestion", CustomerProfileQuestionVM, props);
    }

    onLeave() {
        this.viewModel.removeNotifications();
        return undefined;
    }

    private tempUpdatedHtml: string = "";

    public render() {
        return (
            <div className='CustomerProfileQuestionView'>
                <GoBack />
                <Neo.Card>
                    <h2 className='p-24 mt-4'>{textConstants.titleText.CustomerProfileQuestions}</h2>
                    <Neo.Form model={this.viewModel.customerProfileQuestions} onSubmit={() => this.viewModel.save()} showSummaryModal>
                        <NeoGrid.Grid items={this.viewModel.customerProfileQuestions}>
                            {(question, questionMeta) => (
                                <NeoGrid.Row>
                                    {/* Question Number */}
                                    <NeoGrid.Column display={questionMeta.maintenanceQuestionNumber} label={"Question Number"} className='numberWidth' />

                                    {/* Question Copy */}
                                    <NeoGrid.Column display={questionMeta.questionText} label={"Question Copy"}
                                        append={<Neo.Button
                                            variant="light"
                                            className="text-center"
                                            icon={<Icon.Edit2 />}
                                            onClick={() => {
                                                this.viewModel.showEditModal = true;
                                                this.viewModel.textType = ICPCopyTextType.Question;
                                                this.viewModel.selectedQuestionId = question.customerProfileQuestionId;
                                                this.viewModel.selectedText = question.questionText
                                            }}
                                        />} />

                                    {/* Question Help */}
                                    <NeoGrid.Column display={questionMeta.helperCopy} label={"Question Help"}

                                        append={<Neo.Button
                                            variant="light"
                                            className="text-center"
                                            icon={<Icon.Edit2 />}
                                            // disabled=
                                            onClick={() => {
                                                this.viewModel.showEditModal = true;
                                                this.viewModel.textType = ICPCopyTextType.Helper;
                                                this.viewModel.selectedQuestionId = question.customerProfileQuestionId;
                                                this.viewModel.selectedText = question.helperCopy
                                            }}
                                        />}
                                    />
                                    {/* Placeholder Copy */}
                                    <NeoGrid.Column bind={questionMeta.placeholderCopy} label={"Question Placeholder Copy"} />

                                    {/* Is for AI Campaign Message generation indicator */}
                                    <NeoGrid.Column
                                        width={100}
                                        bind={questionMeta.isAIMessageGenerationQuestion}
                                        label={"Use for campaign message generation"} />

                                </NeoGrid.Row>
                            )}
                        </NeoGrid.Grid>
                        {/* Save button */}
                        <div className="my-4 text-right p-24">
                            <Neo.Button className="btn-width-100" isSubmit variant="success">{textConstants.buttonText.Save}</Neo.Button>
                        </div>
                    </Neo.Form>

                    {/*Edit modal*/}
                    <Neo.Modal
                        title={`Update Display Text`}
                        className={"modal-style-test"}
                        show={this.viewModel.showEditModal}
                        onClose={() => this.viewModel.showEditModal = false}
                        closeButton={{ text: "Cancel", variant: "light" }}
                        acceptButton={{
                            text: "Done", variant: "secondary",
                            onClick: () => {
                                this.viewModel.UpdateSelectedPopUpText(this.tempUpdatedHtml);
                                this.viewModel.showEditModal = false
                            }
                        }}>
                        <>
                            <RichTextEditor
                                initialString={this.viewModel.selectedText}
                                getHtmlText={(htmlText: string) => {
                                    this.tempUpdatedHtml = htmlText;
                                }} />
                        </>
                    </Neo.Modal>
                </Neo.Card>
            </div>
        );
    }
}