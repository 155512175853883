import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';
import CampaignMessageCommentLookup from './CampaignMessageCommentLookup';

@NeoModel
export default class CampaignMessageLookup extends ModelBase {

    // Properties
    public campaignMessageId: number = 0;

    public clientCampaignMessageId: number = 0

    @Attributes.Display("Name")
    public campaignName: string = "";

    public campaignMessageType: string = ""

    public draftText: string = "";

    public messageText: string = "";

    public currentComment: string = "";

    public isSubject: boolean = false;

    public ordinal: number = 0;

    public comments: List<CampaignMessageCommentLookup> | null = null

    public clientCampaignMessageStatusId: number = 0

    public draftModifiedBy: string | null = ""
    public draftModifiedOn: string | null = ""
    public messageModifiedBy: string | null = ""
    public messageModifiedOn: string | null = ""

    // CampaignMessage only properties / methods
    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => this.draftMessageValidation(), textConstants.messageText.validationMessage.comxSuggestion);
    }

    public draftMessageValidation() {
        //Subject
        if (this.isSubject) {
            return this.draftText === null || this.draftText === '';
        }

        //Signature
        if (this.ordinal === 0 || this.ordinal === 1) {
            return this.draftText === null || this.draftText === '';
        }

        return false;
    }
}