import React from 'react';
import { StyledDataTooltipContainer, StyledDataTooltipHover } from './styles';
import {
  IDataTooltip
} from './types';

const parentLengthOffset = (parentLength: number | undefined) => {

  if (!parentLength) {
    return undefined
  }

  return parentLength < 10
}

const DataTooltip = ({ children, parent, hidden, type, isFinalItem, parentLength, className, ...rest }: IDataTooltip) => {
  return <StyledDataTooltipContainer
    aria-hidden={true}>
    {!hidden &&
      <StyledDataTooltipHover
        className={className}
        type={type}
        isFinalItem={isFinalItem}
        parentOffset={parentLengthOffset(parentLength)}
        {...rest}><>{children}</>
      </StyledDataTooltipHover>
    }
    <>{parent}</>
  </StyledDataTooltipContainer>
};

export default DataTooltip;
