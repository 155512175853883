import { Managers, Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../../Services/AppService";
import { textConstants } from "../../common/textConstants";
import { Data, List, NeoModel } from "@singularsystems/neo-core";
import AdvancedEmailSettingsLookup from "../../Models/Client/Query/AdvancedEmailSettingsLookup";
import AdvancedEmailSettingsSaveCommand from "../../Models/Client/Commands/AdvancedEmailSettingsSaveCommand";
import OutboundEmailTableLookup from "../../Models/Client/Query/OutboundEmailTableLookup";
import OutboundEmailCriteria from "../../Models/Client/Query/OutboundEmailCriteria";
import OutboundEmailLookup from "../../Models/Client/OutboundEmailLookup";
import SaveOutboundEmailCommand from "../../Models/Client/Commands/SaveOutboundEmailCommand";
import { isURL, isValidEmail, ValidateUsingRegex } from "../../common/utils";
import EmailProviderTypeLookup from "../../Models/Maintenance/EmailProviderTypeLookup";
import * as Roles from '../../Models/Security/Roles';
import InfoCardVM from "../InfoCardVM";
import { hotjar } from 'react-hotjar';
import { IOptions } from 'Components/CustomDropDown/CustomDropDown';
import ClientTestEmailLookup from "Models/Client/Query/ClientTestEmailLookup";
import TestEmailsSaveCommand from "Models/Client/Commands/TestEmailsSaveCommand";

@NeoModel
export default class AdvancedEmailSettingsVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private invitedUsersApiClient = AppService.get(Types.ApiClients.InvitedUsersApiClient),
    private authService = AppService.get(Types.Neo.Security.AuthenticationService),
    public authorisationService = AppService.get(Types.Neo.Security.AuthorisationService),
    private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
    public authenticationService = AppService.get(Types.Security.CustomAuthenticationService),
    public appDataCache = AppService.get(Types.Services.AppDataCache),
    private greyListedProspectApiClient = AppService.get(Types.ApiClients.GreyListedProspectApiClient)
  ) {
    super(taskRunner);
  }

  public tabManager = new Managers.TabManager();

  public advancedEmailSettingsLookup = new AdvancedEmailSettingsLookup();
  public advancedEmailSettingsSaveCommand = new AdvancedEmailSettingsSaveCommand();
  public getOutboundEmailsTableData = new OutboundEmailTableLookup();
  public outboundEmailSearchCriteria = new OutboundEmailCriteria();
  public outboundEmailLookup = new OutboundEmailLookup();
  public saveOutboundEmailCommand = new SaveOutboundEmailCommand();
  public isArchive: boolean = false;
  public showDeleteModal: boolean = false;
  public selectedEmail: string = "";
  public outboundEmailId: number = 0;
  public showUndeleteModal: boolean = false;
  public showInvalidDataModal: boolean = false;
  public showInvalidOutboundEmailModal: boolean = false;
  public isInternalTestExpanded: boolean = false;
  public invalidDataMessage: String = "";
  public invalidOutboundEmailDataMessage: String = "";
  public showAddEditModal: boolean = false;
  public emailProviderTypes = new List(EmailProviderTypeLookup)
  public emailProviderTypesCache = this.appDataCache.emailProviderTypes.get().data.filter(ept => ept.isActive);
  public microsoft = this.emailProviderTypesCache.find(ept => ept.uniqueTableKey === "EmailProviderType2")
  public other = this.emailProviderTypesCache.find(ept => ept.uniqueTableKey === "EmailProviderType4")
  public outboundEmailSaveClicked: boolean = false;
  public emailRegex: string = ""
  public InfoCardVM = new InfoCardVM;
  public isComXUser: boolean = false;
  public isOnboarding: boolean = false;
  public isInfoCardExpanded: boolean = false;
  public hasNoEmails: boolean = false;

  public outboundEmailList: OutboundEmailTableLookup[] = []
  public emailProviderList: IOptions[] = []
  public hasChanged: boolean = true // Set true initial so component can render with data
  public dataHasLoaded: boolean = false
  public showPassword: boolean = false
  public clientId: number = 0

  public testEmailAddition: ClientTestEmailLookup = new ClientTestEmailLookup()
  public testEmailList:List<ClientTestEmailLookup>= new List(ClientTestEmailLookup)
  public testEmailErrorList: string[] = []

  // Pagination
  public pageManager = new Data.PageManager(this.outboundEmailSearchCriteria, OutboundEmailTableLookup, this.clientsApiClient.getOutboundEmailsTable,
    {
      pageSize: 5,
      sortBy: "emailProviderTypeId",
      fetchInitial: false
    }
  )

  public async setAdvancedEmailSettings(clientId: number) {
    await this.setEmailProviders()
    this.clientId = clientId
    this.outboundEmailSearchCriteria.clientId = clientId;
    await this.getOutboundEmailList(clientId)

    await this.getTestEmailList(clientId)

    const regexResponse = await this.greyListedProspectApiClient.getEmailRegex();
    this.emailRegex = regexResponse.data;

    const isClientResponse = await this.invitedUsersApiClient.isClientUser(this.authService!.user!.userName);
    this.isComXUser = !isClientResponse.data

    const response = await this.clientsApiClient.getAdvancedEmailSettings(clientId);
    if (response.data) {
      this.advancedEmailSettingsLookup.set(response.data);
      this.InfoCardVM.shortDescription = this.advancedEmailSettingsLookup.internalEmailTextHeader;
      this.InfoCardVM.fullDescription = this.advancedEmailSettingsLookup.internalEmailText;
      this.InfoCardVM.title = "How does this work?";
    }

    return true
  }

  private async setEmailProviders(){
    let emailProvidersResponse = await this.clientsApiClient.getActiveEmailProviders()
    
    this.emailProviderTypes.set(emailProvidersResponse.data)

    let providers = this.emailProviderTypes.map(type =>{
      let provider: IOptions = {
        value: type.emailProviderTypeId,
        label: type.providerName
      }

      return provider;
    })

    this.emailProviderList = providers
  }

  private async getOutboundEmailList(clientId: number){
    this.dataHasLoaded = false
    let listResponse = await this.clientsApiClient.getOutboundEmailsList(clientId)

    if (listResponse.status === 200)
    {
      this.outboundEmailList = listResponse.data
      this.dataHasLoaded = true
      this.updateHasChanged()
    }
  }

  private async getTestEmailList(clientId: number){
    let listResponse = await this.clientsApiClient.getClientTestEmailsList(clientId)

    if (listResponse.status === 200)
    {
      this.testEmailList.set(listResponse.data as any[])
    }
  }

  public async saveAdvancedEmailSettings(shouldValidate = true, saveEmailList = true): Promise<boolean> {

    this.taskRunner.run(async () => {
      if (shouldValidate){
        if (!this.validationSuccess()) {
          return false;
        }
      }
      
      this.advancedEmailSettingsSaveCommand.isCNAME = this.advancedEmailSettingsLookup.isCNAME
      this.advancedEmailSettingsSaveCommand.isDKIM = this.advancedEmailSettingsLookup.isDKIM
      this.advancedEmailSettingsSaveCommand.isFirewall = this.advancedEmailSettingsLookup.isFirewall
      this.advancedEmailSettingsSaveCommand.isSPF = this.advancedEmailSettingsLookup.isSPF
      this.advancedEmailSettingsSaveCommand.clientId = this.outboundEmailSearchCriteria.clientId

      const response = await this.clientsApiClient.saveAdvancedEmailSettings(this.advancedEmailSettingsSaveCommand);
      
      if (saveEmailList){
        const emailListResponse = await this.saveTestEmailList()
      }
      else {
        this.hasNoEmails = true;
      }

      if (response.data.success) {
        if (saveEmailList) {
          this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.SettingsSaved)
        }
        if (this.authenticationService.globalProps.isOnboarding) {
          this.authenticationService.globalProps.canGoNext = true
        }
        
        return true;
      } else {
        this.notifications.addDanger(textConstants.titleText.SaveFailed, textConstants.messageText.saveMessage.SaveFailed)
        this.showInvalidDataModal = true;
        return false;
      }
    })

    return false;
  }

  public addTestEmail(){
    if (isValidEmail(this.testEmailAddition.emailAddress))
    {
      let alreadyInList = this.testEmailList.some(s => s.emailAddress.toLocaleLowerCase() === this.testEmailAddition.emailAddress.toLocaleLowerCase())

      if (!alreadyInList){
        this.testEmailAddition.clientId = this.clientId
        this.testEmailList.push(this.testEmailAddition.clone())
      }

      // Reset email
      this.resetTestEmailAddition()
    }
    else{
      this.testEmailErrorList.push(this.testEmailAddition.emailAddress)
    }
  }

  private resetTestEmailAddition(){
    this.testEmailAddition = new ClientTestEmailLookup()
    this.testEmailAddition.clientId = this.outboundEmailSearchCriteria.clientId
  }

  public validateAlltestEmails(){
    let canSave = true;
    // clean list
    this.testEmailErrorList = []

    canSave = this.testEmailList.length > 0 || this.testEmailAddition.emailAddress !== ""

    if (!canSave){
      return canSave
    }

    // Add list Items 
    this.testEmailList.forEach(email =>{
      if (!isValidEmail(email.emailAddress)){
        this.testEmailErrorList.push(email.emailAddress)
        canSave = false;
      }
    })

    // Add value in the textbox
    if (this.testEmailAddition.emailAddress !== ""){
      if (isValidEmail(this.testEmailAddition.emailAddress)){
        this.testEmailList.push(this.testEmailAddition)
        this.resetTestEmailAddition()
      }
      else{
        this.testEmailErrorList.push(this.testEmailAddition.emailAddress)
        canSave = false;
      }
    }

    return canSave
  }

  public async saveTestEmailList()
  {
    let isValid = false;

    isValid = this.validateAlltestEmails()

    if (!isValid){
      return isValid
    }

    let saveCommand = this.compileTestEmailSaveCommand()
    
    let response = await this.clientsApiClient.saveClientTestEmailList(saveCommand)

    if (response.status === 200){
      isValid = true;
    }

    return isValid
  }

  private compileTestEmailSaveCommand(){
    let saveCommand = new TestEmailsSaveCommand()
    saveCommand.clientId = this.clientId
    
    let itemList = new List(ClientTestEmailLookup)

    saveCommand.clientTestEmails.set(this.testEmailList.toJSArray())

    return saveCommand
  }

  public async deleteTestEmailFromList(clientTestEmail: ClientTestEmailLookup){
    let response = await this.clientsApiClient.deleteClientTestEmail(clientTestEmail.clientTestEmailId)

    if (response.status === 200){
      this.testEmailList.remove(clientTestEmail)
    }
  }

  public validationSuccess(): boolean {
    let result = true;
    this.invalidDataMessage = "";

    if (this.authorisationService.hasRole(Roles.TI_InternalTest.Edit) || this.authenticationService.globalProps.isOnboarding) {
      if (this.advancedEmailSettingsLookup.testEmailAddress) {
        if (!isValidEmail(this.advancedEmailSettingsLookup.testEmailAddress)) {
          this.invalidDataMessage = "The test email must be a valid email\n";
          result = false;
        }
      } else {
        this.invalidDataMessage = "You must enter a test email\n";
        result = false;
      }
    }

    if (this.authorisationService.hasRole(Roles.TI_AdvancedEmailSettings.Edit) || this.authenticationService.globalProps.isOnboarding) {
      result = this.isAdvancedEmailSettingsSectionValid()
    }

    return result;
  }

  public isAdvancedEmailSettingsSectionValid(){
    let isValid = true

    if (!this.advancedEmailSettingsLookup.isSPF) {
      this.invalidDataMessage += "You must setup SPF on your domain\n";
      isValid = false
    }

    if (!this.advancedEmailSettingsLookup.isCNAME) {
      this.invalidDataMessage += "You must setup CNAME on your domain\n";      
      isValid = false
    }

    return isValid
  }
  
  public deleteOutboundEmail() {
    this.taskRunner.run(async () => {
      this.showDeleteModal = false;
      const response = await this.clientsApiClient.deleteOutboundEmail(this.outboundEmailId);
      this.pageManager.refreshData();

      await this.getOutboundEmailList(this.outboundEmailSearchCriteria.clientId)
    });
  }

  public updateHasChanged(){
    this.hasChanged = !this.hasChanged
  }

  public async deleteOutBoundEmailFromComponent(id: number){
    this.outboundEmailId = id;

    this.deleteOutboundEmail()

    this.outboundEmailId = 0

    this.hasChanged = true;
  }

  public unDeleteOutboundEmail(): void {
    this.taskRunner.run(async () => {
      this.showUndeleteModal = false;
      const response = await this.clientsApiClient.unDeleteOutboundEmail(this.outboundEmailId);
      this.pageManager.refreshData();
    })
  }

  public saveOutboundEmail() {
    // add https:// by default if not added
    if (!this.outboundEmailLookup.emailAccountURL.startsWith("https://")){
      this.outboundEmailLookup.emailAccountURL = "https://"+ this.outboundEmailLookup.emailAccountURL
    }

    this.runOutboundEmailValidationChecks()
    if (this.showInvalidOutboundEmailModal) { return }

    this.outboundEmailLookup.clientId = this.outboundEmailSearchCriteria.clientId
    this.saveOutboundEmailCommand.mapFrom(this.outboundEmailLookup)

    this.taskRunner.run(async () => {
      const response = await this.clientsApiClient.saveOutboundEmail(this.saveOutboundEmailCommand);

      if (response.data.success) {
        this.showAddEditModal = false;
        
        this.hasNoEmails = false;

        this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.OutboundEmailSaved)
        this.pageManager.refreshData();

        await this.getOutboundEmailList(this.outboundEmailSearchCriteria.clientId)
        
        if (this.authenticationService.globalProps.isOnboarding) {
          hotjar.event("Add_Email_Onboarding")
        }
        else {
          hotjar.event("Add_Email_Settings")
        }
      }
    })
  }

  public async openAddNewEmail() {
    this.outboundEmailSaveClicked = false;
    this.outboundEmailLookup = new OutboundEmailLookup()
    
    this.setEmailProviders()
    this.showAddEditModal = true;
  }

  public async getSelectedOutboundEmail(outboundEmailId: number) {
    this.setEmailProviders()

    this.outboundEmailSaveClicked = false;
    this.taskRunner.run(async () => {
      const response = await this.clientsApiClient.getOutboundEmail(outboundEmailId);
      this.outboundEmailLookup.set(response.data)
      this.showAddEditModal = true;
    });
  }

  public async openOutboundEmailsModal(id?:number){
    if (id){
      //Edit
      await this.getSelectedOutboundEmail(id)
    }
    else{
      //New
      this.openAddNewEmail()
    }
}

  public outboundEmailValidationChecks(fieldNumber: number) {

    function checkEmpty(value: any) {
      return value === undefined || value === null || value === "" || value.length === 0
    }

    // only works after save clicked
    if (this.outboundEmailSaveClicked) {
      switch (fieldNumber) {
        case 0: return this.outboundEmailLookup.emailProviderTypeId <= 0;
        case 1: return (checkEmpty(this.outboundEmailLookup.emailAccountURL) || !isURL(this.outboundEmailLookup.emailAccountURL))
        case 2: return (checkEmpty(this.outboundEmailLookup.emailAddress) || !ValidateUsingRegex(this.outboundEmailLookup.emailAddress, this.emailRegex))
        case 3: return checkEmpty(this.outboundEmailLookup.username)
        case 4: return checkEmpty(this.outboundEmailLookup.password);
        default: return false;
      }
    }
    else return false;
  }

  private runOutboundEmailValidationChecks() {
    this.invalidOutboundEmailDataMessage = "";
    this.outboundEmailSaveClicked = true;
    this.showInvalidOutboundEmailModal = false

    if (this.outboundEmailValidationChecks(0)) {
      this.invalidOutboundEmailDataMessage = this.invalidOutboundEmailDataMessage + "Must select a provider" + "\n"
    }

    // Weblink to Email Account
    if (this.outboundEmailValidationChecks(1)) {
      this.invalidOutboundEmailDataMessage = this.invalidOutboundEmailDataMessage + "Must specify weblink to email account" + "\n"
    } else if (this.outboundEmailLookup.emailAccountURL !== "" &&
      this.outboundEmailLookup.emailAccountURL !== null &&
      !isURL(this.outboundEmailLookup.emailAccountURL)) {
      this.invalidOutboundEmailDataMessage = this.invalidOutboundEmailDataMessage + "Please provide a valid URL for Weblink to Email Account" + "\n";
    }

    // Outbound Sales Email
    if (this.outboundEmailValidationChecks(2)) {
      this.invalidOutboundEmailDataMessage = this.invalidOutboundEmailDataMessage + "Must specify outbound sales email address" + "\n"
    } else if (!ValidateUsingRegex(this.outboundEmailLookup.emailAddress, this.emailRegex)) {
      this.invalidOutboundEmailDataMessage = this.invalidOutboundEmailDataMessage + "Please provide a valid email address for Outbound Sales Email" + "\n"
    }

    // Username
    if (this.outboundEmailValidationChecks(3)) {
      this.invalidOutboundEmailDataMessage = this.invalidOutboundEmailDataMessage + "Must specify username" + "\n"
    }

    // Password
    if (this.outboundEmailValidationChecks(4)) {
      this.invalidOutboundEmailDataMessage = this.invalidOutboundEmailDataMessage + "Must specify password\n"
    }

    if (this.invalidOutboundEmailDataMessage !== "") {
      this.showInvalidOutboundEmailModal = true
    }
    else {
      this.showInvalidOutboundEmailModal = false
    }
  }
}