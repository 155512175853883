import { NeoModel, ModelBase } from '@singularsystems/neo-core';

@NeoModel
export default class BlacklistedDomainFilterList extends ModelBase {
    
}

@NeoModel
class TriStateList {
    id: number = 1
    label: string = ""
}

export var tristateDropDown: TriStateList[] = [
    {
        id: 1,
        label: "All"
    },
    {
        id: 2,
        label: "Yes"
    },
    {
        id: 3,
        label: "No"
    }]

export var YesDropDown: TriStateList[] = [
    {
        id: 2,
        label: "Yes"
    },
    {
        id: 3,
        label: "No"
    }
]