import React from 'react';
import OnboardingTechnicalSettingsVM from './OnboardingTechnicalSettingsVM';
import { observer } from 'mobx-react';
import { NavFooter } from 'Components/FooterComponent';
import { isMiniNav } from 'common/utils';
import AdvancedEmailSettings from 'Components/TechnicalIntegrationSettings/AdvancedEmailSettings';
import EmailSetup from 'Views/EmailSetup';
import OutboundEmailsModal from 'Components/Modals/OutboundEmailsModal';
import { Neo } from '@singularsystems/neo-react';
import { StyledNavigationContainer } from 'Views/IdealCustomerProfiles/Styles/ICPNavigation.styles';
import ScreenNavigator from 'Components/ScreenNavigator';
import { SectionCode } from 'Models/Maintenance/SectionCode';
import AdvancedEmailSettingSection from 'Components/TechnicalIntegrationSettings/AdvancedEmailSettingSection';
import { Validation } from '@singularsystems/neo-core';
import { Spinner } from 'reactstrap';
import TrophyWoman from "assets/img/TrophyWoman.png";
import TestEmailSection from 'Components/TechnicalIntegrationSettings/TestEmailSection';
import HtmlParser from 'react-html-parser';
import { textConstants } from 'common/textConstants';
import OnboardingModal from 'Components/Modals/OnboardingModal';

interface IOnboardingTechnicalSettingsProps {
  viewModel: OnboardingTechnicalSettingsVM;
}

@observer
export default class OnboardingTechnicalSettings extends React.Component<IOnboardingTechnicalSettingsProps> {

  constructor(props: IOnboardingTechnicalSettingsProps) {
    super(props);
  }

  public getCurrentSectionCode() {
    let section = SectionCode[`TechSetting${this.props.viewModel.currentSection}`]

    return section
  }

  public closeModal() {
    this.props.viewModel.showOnboardingModal = false
    this.props.viewModel.setStepCompleteModalAsSeen()
  }

  componentDidMount(): void {
    if (!this.props.viewModel.showOverview) {
      this.props.viewModel.authenticationService.globalProps.showOnboardingHelpCenter = true;
    }
  }

  componentWillUnmount(): void {
    this.props.viewModel.authenticationService.globalProps.showOnboardingHelpCenter = false;
  }

  private getScreen1() {
    let viewModel = this.props.viewModel

    return (
      <>
        <div className='row'>
          <div className='col-12'>
            {viewModel.advancedEmailSettingsVM.dataHasLoaded
              &&
              <EmailSetup
                outboundEmails={viewModel.advancedEmailSettingsVM.outboundEmailList}
                hasChanged={viewModel.advancedEmailSettingsVM.hasChanged}
                updateHasChanged={() => viewModel.advancedEmailSettingsVM.updateHasChanged()}
                openModal={(id?: number) => viewModel.advancedEmailSettingsVM.openOutboundEmailsModal(id)}
                deleteOutboundEmail={(id) => viewModel.advancedEmailSettingsVM.deleteOutBoundEmailFromComponent(id)}
                noEmails={this.props.viewModel.advancedEmailSettingsVM.hasNoEmails}
              />
            }
            {!viewModel.advancedEmailSettingsVM.dataHasLoaded &&
              <div className='text-center p-24 mt-4 mb-3'><Spinner /></div>
            }
          </div>
        </div>

        {/* Advanced Email Settings (Form Required to show validations)*/}
        <Neo.Form
          model={this.props.viewModel.advancedEmailSettingsVM.advancedEmailSettingsLookup}
          onSubmit={(e) => { e.preventDefault(); }}
          validationDisplayMode={Validation.DisplayMode.AfterSubmit}
        >
          <AdvancedEmailSettingSection
            viewModel={viewModel.advancedEmailSettingsVM}
            hasEditPermissions={true}
            showSegregator={true}
            isOnboarding={true}
            addPadding={true}
          />
          {/* This button is hidden, It only serves to submit the form */}
          <Neo.Button
            id={"advancedSettings"}
            isSubmit
            className="mt-2 mb-2 pull-left hideSubmitButton"
            variant="secondary"
          />
        </Neo.Form>
      </>
    )
  }

  private getScreen2() {
    let viewModel = this.props.viewModel

    return (
      <>
        <TestEmailSection
          hasEditPermissions={true}
          viewModel={viewModel.advancedEmailSettingsVM}
          isExternalUser={true}
          isOnboarding={true}
          addPadding={true}
        />
      </>
    )
  }

  private async navigateBackButton() {
    if (this.props.viewModel.currentSection === this.props.viewModel.firstStepNumber) {
      this.props.viewModel.currentSection--;
      return
    }

    let isValid = await this.props.viewModel.saveSettings(this.props.viewModel.currentSection)

    if (isValid) {
      this.props.viewModel.currentSection = Math.max(this.props.viewModel.firstStepNumber, this.props.viewModel.currentSection - 1);
      this.props.viewModel.authenticationService.onboardingFunctions.getRelevantSectionCode(this.props.viewModel.currentSection)
    }
  }

  private async navigateContinueButton() {
    let isValid = await this.props.viewModel.saveSettings(this.props.viewModel.currentSection)

    if (isValid) {
      this.props.viewModel.currentSection = Math.min(this.props.viewModel.lastSection!, this.props.viewModel.currentSection + 1);
      this.props.viewModel.authenticationService.onboardingFunctions.getRelevantSectionCode(this.props.viewModel.currentSection);
    }
  }

  private async navigateSaveAsDraft() {
    await this.props.viewModel.saveSettings(this.props.viewModel.currentSection, false)
  }

  private async saveAndFinish() {
    let isValid = await this.props.viewModel.saveSettings(this.props.viewModel.currentSection)

    if (isValid) {
      this.props.viewModel.showCompletedModal = true
    }
  }

  public render() {
    const viewModel = this.props.viewModel;

    return (
      <>
        {!this.props.viewModel.showOverview &&
          <>
            {/* NAVIGATOR */}
            <StyledNavigationContainer>
              <ScreenNavigator
                steps={this.props.viewModel.relevantNavigationSteps}
                goToStep={async (stepNumber: number) => {
                  // We have to submit the form this way
                  // Tried submitting via form and it causes a page refresh. This is the only way to show the validations
                  const formButton = document.getElementById('advancedSettings') as HTMLButtonElement;

                  if (formButton) {
                    formButton.click();
                  }

                  await this.props.viewModel.goToStep(stepNumber)
                }}
                currentStepNumber={this.props.viewModel.currentSection}
              />
            </StyledNavigationContainer>

            <div className='technicalSettingsContainer'>
              <Neo.Card className='AdvancedEmailSettings'>
                {this.getCurrentSectionCode() === SectionCode.TechSetting1 && this.getScreen1()}
                {this.getCurrentSectionCode() === SectionCode.TechSetting2 && this.getScreen2()}
              </Neo.Card>
            </div>
          </>
        }

        {/* OVERVIEW PAGE */}
        {this.props.viewModel.showOverview
          && this.props.viewModel.completedLoad
          &&
          <div className='technicalSettingsContainer'>
            <Neo.Card>
              <div className='row'>
                <div className='col-12'>
                  <AdvancedEmailSettings
                    addPadding={true}
                    removePaddingEmailSetup={false}
                    viewModel={this.props.viewModel.advancedEmailSettingsVM}
                    isOnboarding={true}
                  />
                </div>
              </div>
            </Neo.Card>
          </div>
        }

        {/* FOOTER */}
        <div className={`row appFooter`}>
          <div className={`row appFooter ${this.props.viewModel.authenticationService.onboardingFunctions.isOnboardingHelpCenterVisible() ? "helpCenterAllowance" : ""} 
                ${isMiniNav() ? this.props.viewModel.authenticationService.globalProps.isOnboarding ? "" : "miniNav" : ""}`}>
            <NavFooter
              currentSection={this.props.viewModel.currentSection}
              lastSection={this.props.viewModel.lastSection}
              saveAsDraft={async () => await this.navigateSaveAsDraft()}
              saveAndPublish={async () => await this.saveAndFinish()}
              backButton={async () => await this.navigateBackButton()}
              continueButton={async () => {
                // We have to submit the form this way
                // Tried submitting via form and it causes a page refresh. This is the only way to show the validations
                const formButton = document.getElementById('advancedSettings') as HTMLButtonElement;

                if (formButton) {
                  formButton.click();
                }

                await this.navigateContinueButton()
              }}
            />
          </div>
        </div>

        {/* Add/Edit Outbound Email modal */}
        <OutboundEmailsModal viewModel={viewModel.advancedEmailSettingsVM} />

        {/* Modal after completing steps */}
        <OnboardingModal
          isOpen={this.props.viewModel.showOnboardingModal}
          imageSrc={TrophyWoman}
          content={<>{HtmlParser(this.props.viewModel.onboardingPopupModalContent)}</>}
          closeModal={() => {
            this.closeModal();
          }}
          acceptAndCloseButton={{
            text: textConstants.generalText.Close,
            onClose: () => {
              this.closeModal();
            },
          }}
        />
      </>
    );
  }
}