import React from "react"

export interface Props {
  className: string
}

const ActionList_NoLeads = ({ className }: Props) => (
  <svg width="30vw" height="30vw" viewBox="0 0 903 780" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M162.872 209.003C160.072 213.683 154.212 215.663 149.172 216.673C142.732 217.993 136.072 218.343 129.552 218.923L125.762 219.853C125.045 220.074 124.378 220.435 123.801 220.914C123.223 221.394 122.746 221.983 122.397 222.647C122.047 223.311 121.833 224.038 121.765 224.786C121.697 225.533 121.778 226.287 122.002 227.003C124.347 235.073 127.573 242.859 131.622 250.223L125.622 256.223C123.922 253.923 123.842 249.893 121.562 250.283C119.372 248.093 116.772 247.023 114.082 249.953C108.822 255.703 109.672 267.653 117.402 270.953L115.672 279.563C115.075 282.573 113.454 285.284 111.084 287.233C108.713 289.182 105.741 290.249 102.672 290.253L104.002 280.063C95.0025 284.613 84.7925 274.773 85.5025 266.663C85.9625 261.103 84.7025 257.263 80.5925 253.233C80.3225 252.963 81.9725 251.963 81.7225 251.693C80.5625 248.183 80.4025 244.083 81.5525 241.763C83.5525 237.623 89.4425 235.203 86.7325 230.683C85.5435 228.713 84.6755 226.566 84.1625 224.323C83.6185 221.349 83.7175 218.293 84.4545 215.36C85.1915 212.428 86.5475 209.687 88.4325 207.323C92.4325 202.523 98.9325 203.813 104.242 202.113C110.712 200.113 113.702 193.183 120.472 191.043C123.842 189.973 126.962 190.463 130.332 191.233C133.182 191.893 136.002 192.633 138.802 193.513C141.602 194.393 144.062 195.123 146.802 194.303C150.422 193.303 153.212 189.923 157.162 189.893C159.133 189.848 161.062 190.464 162.642 191.643C165.562 196.893 165.582 204.513 162.872 209.003Z" fill="#FFB296" />
    <path d="M39.1111 344.404C39.1111 344.404 -69.7589 224.324 90.571 164.404C99.868 160.995 109.558 158.771 119.411 157.784C145.001 155.064 201.571 145.394 208.091 112.734" stroke="#FFB296" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M496.133 176.303V176.273" stroke="#FFB296" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M210.469 392.514V392.484" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M125.35 255.428C124.284 253.586 123.016 251.868 121.57 250.308C119.38 248.118 116.78 247.048 114.09 249.978C108.83 255.728 109.68 267.678 117.41 270.978L115.68 279.588C115.083 282.598 113.462 285.308 111.092 287.257C108.721 289.206 105.749 290.274 102.68 290.278" stroke="black" strokeWidth="4.11" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M97.8438 328.372C98.2837 325.032 99.3738 316.262 100.424 307.902C101.424 300.152 102.314 292.693 102.664 290.233L104.004 280.062" stroke="black" strokeWidth="4.11" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M159.584 228.164C159.584 228.164 146.814 228.794 144.344 239.364" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M164.222 256.09C167.702 256 172.222 255.49 173.862 251.96L165.062 242.25C162.915 239.871 161.645 236.83 161.462 233.63C161.112 228.26 161.742 219.74 156.602 214.07" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M156.698 242.654C158.139 241.06 158.21 238.778 156.858 237.556C155.506 236.333 153.242 236.635 151.802 238.229C150.361 239.822 150.289 242.105 151.641 243.327C152.993 244.549 155.257 244.248 156.698 242.654Z" fill="black" />
    <path d="M155.833 319.874L149.203 287.734" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M323.897 464.519L281.167 499.459C332.577 577.859 293.667 592.249 293.667 592.249C286.119 593.694 278.498 594.719 270.837 595.319C233.677 597.999 195.557 588.409 164.927 566.929C102.927 523.429 48.9274 436.369 77.7275 360.569C80.1505 354.158 83.1115 347.962 86.5775 342.049C89.5645 336.909 93.3675 332.289 97.8375 328.369C105.661 321.52 115.296 317.075 125.585 315.569C135.873 314.063 146.378 315.56 155.837 319.879C159.458 321.532 162.883 323.585 166.047 325.999C182.237 338.389 196.957 355.999 209.647 374.479C220.914 391.191 231.054 408.637 239.997 426.699C242.467 431.629 244.707 436.289 246.687 440.559C264.637 433.299 279.687 420.969 293.687 407.649C276.757 439.249 323.897 464.519 323.897 464.519Z" fill="black" />
    <path d="M294.004 490.078C293.184 490.708 292.334 491.368 291.484 491.998" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M167.672 357.25C179.005 378.95 190.339 400.69 201.672 422.47C210.222 438.91 224.672 449.06 245.022 441.27C267.162 432.79 285.022 416.58 301.542 400.06C318.062 383.54 334.802 367.74 358.652 363.48C389.482 357.97 418.652 374.77 439.652 396C441.268 397.619 442.29 399.736 442.553 402.008C442.816 404.28 442.305 406.575 441.102 408.52C440.33 409.764 439.299 410.828 438.08 411.638C436.86 412.448 435.48 412.986 434.034 413.214C432.587 413.443 431.109 413.357 429.699 412.963C428.289 412.569 426.98 411.876 425.862 410.93C415.912 402.44 399.722 390.16 388.462 390" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M357.742 419.133C361.202 421.443 366.002 424.003 370.002 424.003" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M402.084 394.211C409.464 397.861 412.154 408.541 406.944 414.921C400.338 423.118 392.061 429.813 382.664 434.561C372.384 439.691 365.784 436.561 355.664 430.301" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M385 403.781C385 403.781 399.62 407.401 399.23 421.591C399.204 422.196 399.096 422.795 398.91 423.371" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M374.672 412.719C374.672 412.719 394.282 425.389 384.952 433.359" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M753.482 462.383C730.382 525.873 665.712 527.663 658.312 527.663C661.412 521.133 662.252 509.663 655.462 489.563C660.011 482.921 662.405 475.04 662.321 466.989C662.237 458.939 659.679 451.11 654.992 444.563C655.082 444.483 655.132 444.373 655.212 444.293C658.446 444.096 661.655 443.611 664.802 442.843C675.962 440.183 697.962 434.343 715.422 426.123C727.262 420.533 741.072 429.493 740.062 442.563C740.062 442.733 740.062 442.893 740.002 443.083C742.314 443.082 744.592 443.638 746.642 444.706C748.692 445.774 750.454 447.321 751.778 449.217C753.101 451.112 753.947 453.298 754.244 455.591C754.541 457.883 754.28 460.214 753.482 462.383Z" fill="#FFB296" />
    <path d="M457.054 611.295C449.274 624.645 437.164 636.015 425.464 645.935C384.274 680.795 330.464 698.495 276.464 699.185C222.284 698.495 168.274 680.735 126.894 646.185C-3.85622 537.005 19.7738 408.095 40.9238 348.405C41.6687 346.341 42.6771 344.382 43.9238 342.575C45.5775 340.156 47.7244 338.114 50.2235 336.584C52.7226 335.053 55.5169 334.069 58.4239 333.695C69.5239 335.225 79.6039 344.435 77.8239 358.695C77.8239 359.245 77.6039 360.005 77.5539 360.555C48.7738 436.355 102.794 523.415 164.754 566.915C192.864 586.645 227.284 596.345 261.484 595.715H261.874C264.807 595.655 267.737 595.519 270.664 595.305C289.874 593.905 308.544 589.305 327.034 583.735C348.734 577.245 370.194 569.375 391.874 563.865C399.494 561.925 407.134 560.055 414.814 558.305C419.246 557.396 423.779 557.083 428.294 557.375C454.924 559.175 473.634 582.855 457.054 611.295Z" fill="#FFB296" />
    <path d="M463.628 611.295C455.848 624.645 443.728 636.015 432.028 645.935C389.198 682.215 332.688 699.935 276.428 699.185C222.248 698.495 168.238 680.735 126.858 646.185C-3.86231 537.005 19.7677 408.095 40.9177 348.405C41.6627 346.341 42.671 344.382 43.9177 342.575C45.5714 340.156 47.7183 338.114 50.2174 336.584C52.7165 335.053 55.5116 334.069 58.4176 333.695C69.5176 335.225 79.5976 344.435 77.8176 358.695C77.8176 359.245 77.5976 360.005 77.5476 360.555C48.7677 436.355 102.788 523.415 164.748 566.915C192.858 586.645 227.278 596.345 261.478 595.715H261.868C267.021 595.795 272.145 595.659 277.238 595.305C296.448 593.905 315.108 589.305 333.608 583.735C355.308 577.245 376.768 569.375 398.448 563.865C406.058 561.925 413.708 560.055 421.378 558.305C423.658 557.839 425.966 557.528 428.288 557.375C458.228 555.365 481.548 580.555 463.628 611.295Z" fill="#FFB296" />
    <path d="M285.462 698.998L298.622 717.618C298.622 717.618 378.252 720.718 421.832 757.298C429.892 764.068 425.072 777.218 414.552 777.218C411.914 777.227 409.359 776.299 407.342 774.598C397.052 765.958 357.742 738.798 268.822 737.978C182.182 737.168 148.952 763.978 139.762 773.748C138.714 774.852 137.451 775.729 136.051 776.326C134.651 776.922 133.144 777.226 131.622 777.218H129.912C119.112 777.218 114.542 763.578 123.072 756.958C159.452 728.668 241.002 719.648 241.002 719.648L254.922 698.648" fill="black" />
    <path d="M637.87 595.75C637.87 595.75 662.26 618.92 654.65 690.75C654.095 695.879 652.312 700.798 649.45 705.09L646.8 709.09C642.454 715.598 635.901 720.315 628.35 722.37C612.42 726.72 583.52 736.8 565.67 755.9C558.13 763.99 563.74 777.17 574.8 777.17C576.862 777.176 578.894 776.671 580.714 775.701C582.533 774.731 584.085 773.325 585.23 771.61C592.5 760.67 614.51 737.44 667.6 737.93C727.48 738.48 751.6 762.29 759.33 772.55C760.398 773.981 761.784 775.143 763.38 775.945C764.975 776.747 766.735 777.166 768.52 777.17C770.758 777.165 772.946 776.508 774.816 775.278C776.685 774.049 778.156 772.3 779.047 770.247C779.937 768.194 780.209 765.925 779.83 763.72C779.45 761.514 778.436 759.467 776.91 757.83C753.56 732.46 725 723 709.24 719.49C701.322 717.783 694.327 713.179 689.63 706.58C686.034 701.49 683.988 695.467 683.74 689.24C682.86 666.97 682.4 612.91 697.49 595.77" fill="black" />
    <path d="M327.234 583.762C370.124 562.942 416.674 549.282 462.444 536.422C508.504 523.482 555.014 512.192 601.664 501.582C620.304 497.352 639.114 493.582 657.664 488.812" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M367.469 423.711H367.499H367.519" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M362.658 421.005C359.968 423.665 350.367 437.875 347.957 440.425C334.717 454.614 320.653 468.011 305.837 480.545C302.107 483.645 298.157 486.845 293.997 490.085C293.177 490.715 292.328 491.375 291.478 492.005C263.278 513.405 226.477 534.005 192.477 520.805C191.577 520.475 190.697 520.095 189.827 519.705C137.677 496.355 101.227 437.875 98.0475 381.425C97.9375 379.585 97.8775 377.755 97.8575 375.945C97.5775 360.515 99.8575 345.085 114.657 336.945" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M77.8139 360.35L78.0239 358.71C81.3439 332.09 43.544 323.11 34.574 348.4C13.434 408.08 -10.206 536.96 120.574 646.16C204.984 716.67 341.644 717.16 425.734 645.93C437.434 636.02 449.544 624.63 457.334 611.28C477.044 577.52 446.944 550.45 412.994 558.76" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M285.462 698.998L298.622 717.618C298.622 717.618 378.252 720.718 421.832 757.298C429.892 764.068 425.072 777.218 414.552 777.218C411.914 777.227 409.359 776.299 407.342 774.598C397.052 765.958 357.742 738.798 268.822 737.978C182.182 737.168 148.952 763.978 139.762 773.748C138.714 774.852 137.451 775.729 136.051 776.326C134.651 776.922 133.144 777.226 131.622 777.218H129.912C119.112 777.218 114.542 763.578 123.072 756.958C159.452 728.668 241.002 719.648 241.002 719.648L254.922 698.648" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M241 425.109C274.38 416.109 288.13 369.019 293.67 337.359" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M209.617 374.509C218.89 373.295 227.697 369.723 235.195 364.134C242.694 358.546 248.634 351.128 252.447 342.589C253.245 340.89 253.633 339.029 253.581 337.153C253.529 335.277 253.038 333.44 252.147 331.789C248.507 325.049 243.077 317.239 244.367 309.159C245.297 303.459 249.987 299.449 255.367 298.159C258.094 297.444 260.966 297.493 263.667 298.299C265.286 298.758 266.783 299.571 268.049 300.679C269.315 301.788 270.318 303.164 270.987 304.709C272.297 307.779 270.737 310.819 268.327 312.819C265.917 314.819 263.097 315.529 260.157 316.329C259.777 316.439 258.737 316.659 257.587 316.879" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M657.617 488.794C657.617 488.794 723.417 475.014 735.777 455.164C738.094 451.545 739.538 447.437 739.997 443.164" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M333.843 454.89C448.613 442.7 578.153 444.5 578.153 444.5H650.853C655.551 444.507 660.234 443.956 664.803 442.86C675.963 440.2 697.963 434.37 715.423 426.14C727.263 420.55 741.073 429.51 740.063 442.59C740.063 442.75 740.063 442.92 740.003 443.11C742.31 443.111 744.582 443.668 746.628 444.733C748.674 445.799 750.433 447.342 751.756 449.232C753.078 451.122 753.926 453.303 754.227 455.59C754.527 457.877 754.272 460.203 753.483 462.37C728.963 529.76 657.623 527.65 657.623 527.65C648.503 527.92 639.403 528.28 630.273 528.75C549.843 532.66 470.123 543.95 392.083 563.88C370.403 569.39 348.943 577.25 327.243 583.75C308.743 589.34 290.053 593.91 270.843 595.31C233.683 598 195.563 588.4 164.933 566.92C97.8432 519.84 40.1032 421.68 86.5832 342C102.993 313.88 140.153 306.18 166.053 326C202.583 353.95 231.763 408.4 246.733 440.52" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M751.962 595.75H592.242" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M560 563.489C560.024 554.939 563.43 546.747 569.474 540.7C575.519 534.654 583.711 531.245 592.26 531.219H752C760.551 531.242 768.745 534.65 774.792 540.697C780.839 546.743 784.246 554.938 784.27 563.489" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M787.409 574.934C787.417 577.671 786.884 580.383 785.839 582.913C784.795 585.444 783.261 587.742 781.325 589.677C779.389 591.612 777.09 593.145 774.559 594.188C772.028 595.231 769.316 595.763 766.579 595.754H577.579C572.848 595.306 568.482 593.02 565.419 589.387C562.356 585.753 560.841 581.063 561.2 576.325C561.559 571.586 563.763 567.177 567.338 564.047C570.914 560.916 575.575 559.314 580.319 559.584H769.319C772.674 559.579 775.981 560.386 778.955 561.938C781.93 563.49 784.484 565.739 786.399 568.494C787.068 570.575 787.409 572.748 787.409 574.934Z" fill="#FFB296" />
    <path d="M787.414 574.929C787.422 577.666 786.888 580.378 785.844 582.908C784.8 585.438 783.266 587.737 781.33 589.672C779.394 591.607 777.094 593.14 774.564 594.183C772.033 595.226 769.321 595.758 766.584 595.749H577.584C574.811 595.81 572.053 595.316 569.473 594.297C566.894 593.277 564.543 591.753 562.561 589.813C560.578 587.873 559.002 585.557 557.927 583C556.851 580.444 556.297 577.698 556.297 574.924C556.297 572.15 556.851 569.404 557.927 566.847C559.002 564.29 560.578 561.974 562.561 560.034C564.543 558.094 566.894 556.57 569.473 555.551C572.053 554.532 574.811 554.038 577.584 554.099H766.584C770.984 554.097 775.272 555.488 778.832 558.073C782.393 560.658 785.043 564.304 786.404 568.489C787.073 570.57 787.413 572.743 787.414 574.929Z" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M293.312 336.611L292.932 339.821C292.758 341.278 292.057 342.62 290.961 343.595C289.864 344.57 288.449 345.109 286.982 345.111H266.212C264.745 345.109 263.33 344.57 262.233 343.595C261.137 342.62 260.436 341.278 260.262 339.821L257.582 316.821C258.732 316.601 259.772 316.381 260.152 316.271C263.092 315.471 265.942 314.791 268.322 312.761C270.702 310.731 272.322 307.761 270.982 304.651C270.313 303.107 269.31 301.73 268.044 300.622C266.778 299.513 265.281 298.7 263.662 298.241C260.96 297.435 258.089 297.386 255.362 298.101L254.732 292.621C254.639 291.784 254.724 290.936 254.981 290.134C255.237 289.331 255.66 288.592 256.221 287.964C256.782 287.335 257.47 286.832 258.238 286.487C259.007 286.142 259.84 285.963 260.682 285.961H292.552C293.394 285.964 294.225 286.145 294.993 286.49C295.76 286.836 296.446 287.339 297.006 287.967C297.566 288.595 297.988 289.334 298.244 290.136C298.5 290.938 298.585 291.784 298.492 292.621L297.752 298.871" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M296.085 313.164L296.055 313.384" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M294.523 326.371V326.281" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M294.824 326.372C294.735 326.357 294.643 326.357 294.554 326.372C294.428 326.358 294.3 326.358 294.174 326.372C292.878 326.377 291.631 326.868 290.68 327.748C289.729 328.628 289.143 329.834 289.038 331.126C288.934 332.417 289.319 333.701 290.116 334.723C290.913 335.745 292.065 336.43 293.344 336.642C293.895 336.752 294.463 336.752 295.014 336.642C297.51 336.244 299.789 334.984 301.454 333.082C303.673 329.432 305.215 325.411 306.004 321.212C306.018 321.17 306.018 321.124 306.004 321.082C306.614 317.988 306.846 314.832 306.694 311.682M306.694 311.682C306.694 310.332 306.524 308.992 306.364 307.682C306.059 305.49 305.057 303.455 303.507 301.876C301.956 300.297 299.94 299.258 297.754 298.912C297.644 298.912 297.514 298.912 297.404 298.852C293.204 298.332 287.314 299.922 286.224 304.662C285.224 308.942 289.864 312.612 293.674 312.942C293.674 312.942 294.674 313.072 296.084 313.162C298.904 313.352 303.594 313.322 306.694 311.682ZM306.694 311.682C307.15 311.446 307.579 311.161 307.974 310.832" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M299.201 313.398H298.511H293.181H290.831C289.242 313.403 287.716 314.016 286.565 315.112C285.413 316.207 284.725 317.701 284.641 319.288C284.563 320.83 285.06 322.345 286.036 323.542C287.011 324.739 288.395 325.532 289.921 325.768C296.821 326.878 304.701 327.108 306.051 320.928" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M637.87 595.75C637.87 595.75 662.26 618.92 654.65 690.75C654.095 695.879 652.312 700.798 649.45 705.09L646.8 709.09C642.454 715.598 635.901 720.315 628.35 722.37C612.42 726.72 583.52 736.8 565.67 755.9C558.13 763.99 563.74 777.17 574.8 777.17C576.862 777.176 578.894 776.671 580.714 775.701C582.533 774.731 584.085 773.325 585.23 771.61C592.5 760.67 614.51 737.44 667.6 737.93C727.48 738.48 751.6 762.29 759.33 772.55C760.398 773.981 761.784 775.143 763.38 775.945C764.975 776.747 766.735 777.166 768.52 777.17C770.758 777.165 772.946 776.508 774.816 775.278C776.685 774.049 778.156 772.3 779.047 770.247C779.937 768.194 780.209 765.925 779.83 763.72C779.45 761.514 778.436 759.467 776.91 757.83C753.56 732.46 725 723 709.24 719.49C701.322 717.783 694.327 713.179 689.63 706.58C686.034 701.49 683.988 695.467 683.74 689.24C682.86 666.97 682.4 612.91 697.49 595.77" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M587.168 427.862V429.482C587.168 440.572 578.228 442.482 570.508 442.672L421.098 446.152C414.548 446.312 409.038 440.592 408.898 433.432V433.132C408.898 426.132 414.058 420.282 420.518 420.132C420.518 420.132 504.108 417.772 568.828 415.992C570.068 419.912 573.068 422.932 576.828 425.302C579.943 427.165 583.543 428.056 587.168 427.862Z" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M666.59 269.762C666.598 271.587 666.18 273.387 665.37 275.022L598.55 412.002C597.766 413.592 596.644 414.992 595.262 416.103C593.88 417.213 592.272 418.008 590.55 418.432C587.791 419.139 584.87 418.841 582.31 417.592C580.258 416.582 578.531 415.014 577.327 413.069C576.123 411.124 575.49 408.88 575.5 406.592C575.505 406.03 575.545 405.469 575.62 404.912C575.798 403.672 576.169 402.468 576.72 401.342L643.53 264.342C644.737 261.884 646.741 259.906 649.215 258.732C651.689 257.559 654.488 257.257 657.156 257.878C659.824 258.498 662.202 260.004 663.905 262.149C665.607 264.295 666.532 266.953 666.53 269.692L666.59 269.762Z" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M125.635 256.239C125.555 255.959 125.445 255.689 125.355 255.419C125.265 255.149 125.135 254.839 125.055 254.539" stroke="black" strokeWidth="4.11" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M125.633 256.249L127.683 254.189L131.603 250.219" stroke="black" strokeWidth="4.11" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M148.703 261.672C148.703 261.672 151.333 269.522 161.383 270.052" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M104.005 280.063C95.0048 284.613 84.7948 274.773 85.5048 266.663C85.9648 261.103 84.7048 257.264 80.5948 253.233C80.3248 252.963 80.0748 252.683 79.8348 252.413C78.2698 250.711 77.2528 248.578 76.9138 246.29C76.5748 244.003 76.9308 241.666 77.9348 239.583C79.8048 235.233 83.8348 232.683 81.0348 227.993C79.8058 225.965 78.9088 223.754 78.3748 221.443C77.8158 218.379 77.9208 215.231 78.6818 212.21C79.4428 209.19 80.8418 206.368 82.7848 203.933C86.9048 198.933 93.6148 200.313 99.1248 198.563C105.755 196.453 108.855 189.323 115.815 187.133C119.295 186.043 122.525 186.533 126.005 187.323C128.935 188.013 131.875 188.783 134.725 189.683C137.575 190.583 140.175 191.353 143.025 190.503C146.755 189.413 149.625 185.983 153.685 185.953C157.745 185.923 160.865 188.313 162.685 191.653C165.565 196.893 165.585 204.513 162.875 209.033C160.075 213.713 154.215 215.693 149.175 216.703C142.735 218.023 136.075 218.373 129.555 218.953L125.765 219.883C124.323 220.331 123.118 221.331 122.413 222.666C121.708 224.001 121.561 225.561 122.005 227.003C124.35 235.073 127.576 242.86 131.625 250.223" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M131.602 281.212C131.602 281.212 151.442 295.742 162.752 287.732C174.062 279.722 167.672 256.242 167.672 256.242" stroke="black" strokeWidth="4.11" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M271.791 275.934C271.791 275.934 263.361 270.234 268.721 264.414C274.081 258.594 266.941 250.914 266.941 250.914" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M279.57 276.919C279.57 276.919 285.15 273.149 281.57 269.289C277.99 265.429 282.76 260.289 282.76 260.289" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M318.022 222.606C378.729 222.606 427.942 173.393 427.942 112.686C427.942 51.9786 378.729 2.76562 318.022 2.76562C257.315 2.76562 208.102 51.9786 208.102 112.686C208.102 173.393 257.315 222.606 318.022 222.606Z" stroke="#FFB296" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M318.018 173.053C351.359 173.053 378.388 146.023 378.388 112.682C378.388 79.3405 351.359 52.3125 318.018 52.3125C284.676 52.3125 257.648 79.3405 257.648 112.682C257.648 146.023 284.676 173.053 318.018 173.053Z" stroke="#FFB296" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M318.022 123.028C323.738 123.028 328.372 118.394 328.372 112.678C328.372 106.962 323.738 102.328 318.022 102.328C312.306 102.328 307.672 106.962 307.672 112.678C307.672 118.394 312.306 123.028 318.022 123.028Z" stroke="#FFB296" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M339.449 78.125L323.469 103.885" stroke="#FFB296" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M318.023 52.3125V63.0525" stroke="#FFB296" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M378.391 112.688H368.211" stroke="#FFB296" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M318.023 173.065V162.625" stroke="#FFB296" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M257.641 112.688H266.821" stroke="#FFB296" strokeWidth="4.11" strokeMiterlimit="10" />
    <path d="M423.369 295.448C445.957 295.448 464.269 277.136 464.269 254.548C464.269 231.959 445.957 213.648 423.369 213.648C400.781 213.648 382.469 231.959 382.469 254.548C382.469 277.136 400.781 295.448 423.369 295.448Z" fill="#FFB296" />
    <path d="M436.822 240.88C436.245 239.55 435.469 238.316 434.522 237.22C432.901 235.303 430.637 234.042 428.154 233.674C425.671 233.306 423.139 233.856 421.032 235.22C419.658 236.116 418.509 237.318 417.676 238.732C416.844 240.146 416.349 241.733 416.232 243.37C414.079 242.581 411.728 242.513 409.533 243.177C407.339 243.84 405.419 245.2 404.065 247.05C402.71 248.9 401.994 251.14 402.024 253.433C402.054 255.725 402.829 257.946 404.232 259.76C407.162 263.58 412.142 266.09 416.652 267.55C419.017 268.307 421.453 268.823 423.922 269.09C425.172 269.23 432.302 270.19 432.922 269.09C437.372 262.13 440.532 249.38 436.822 240.88Z" fill="white" />
    <g opacity="0.72">
      <path opacity="0.72" d="M525.309 237.61C537.747 237.61 547.829 227.528 547.829 215.09C547.829 202.653 537.747 192.57 525.309 192.57C512.872 192.57 502.789 202.653 502.789 215.09C502.789 227.528 512.872 237.61 525.309 237.61Z" fill="#FFB296" />
      <path opacity="0.72" d="M535.08 219.083C535.638 218.515 536.103 217.864 536.46 217.153C537.102 215.927 537.275 214.508 536.945 213.164C536.616 211.82 535.807 210.642 534.67 209.853C533.927 209.34 533.076 209.006 532.182 208.877C531.289 208.749 530.377 208.83 529.52 209.113C529.472 207.851 529.014 206.639 528.217 205.66C527.419 204.681 526.325 203.988 525.099 203.685C523.873 203.383 522.582 203.487 521.42 203.983C520.259 204.478 519.29 205.338 518.66 206.433C517.435 208.909 516.959 211.69 517.29 214.433C517.403 215.787 517.65 217.127 518.03 218.433C518.22 219.103 519.22 222.933 519.9 223.053C524.37 223.803 531.53 222.753 535.08 219.083Z" fill="white" />
    </g>
    <path d="M493.037 321.439L482.332 344.076C478.45 352.284 481.957 362.084 490.165 365.966L512.801 376.671C521.009 380.553 530.81 377.045 534.692 368.837L545.397 346.201C549.278 337.993 545.771 328.192 537.563 324.311L514.927 313.606C506.719 309.724 496.918 313.231 493.037 321.439Z" fill="#FFB296" />
    <path d="M539.289 350.164L531.539 366.554C529.913 369.968 527.002 372.6 523.443 373.876C519.883 375.151 515.963 374.967 512.539 373.364L492.879 364.064C489.467 362.436 486.837 359.525 485.564 355.965C484.29 352.406 484.475 348.487 486.079 345.064L486.599 343.964C491.939 339.704 501.169 333.964 504.539 341.774C509.389 353.054 515.699 349.414 523.759 344.694C530.489 340.714 538.569 339.384 539.289 350.164Z" fill="white" />
    <path d="M508.839 332.748C512.18 332.748 514.889 330.039 514.889 326.698C514.889 323.356 512.18 320.648 508.839 320.648C505.498 320.648 502.789 323.356 502.789 326.698C502.789 330.039 505.498 332.748 508.839 332.748Z" fill="white" />
    <path d="M612.062 247.194C613.265 249.387 614.138 251.746 614.652 254.194C615.568 258.672 615.305 263.312 613.889 267.657C612.472 272.003 609.951 275.906 606.572 278.984C603.892 281.354 600.482 283.204 597.772 285.684C595.795 287.39 594.339 289.619 593.572 292.114C593.432 292.967 593.089 293.773 592.572 294.464C590.992 296.164 589.442 293.464 588.072 293.004C585.864 292.286 583.469 292.403 581.342 293.334C575.749 295.673 569.52 296.005 563.71 294.275C557.9 292.545 552.868 288.858 549.465 283.841C546.063 278.824 544.501 272.784 545.043 266.747C545.586 260.709 548.2 255.045 552.442 250.714C556.632 246.444 562.112 243.714 567.442 240.934C572.102 238.464 576.742 235.834 581.802 234.354C593.662 230.804 606.322 236.714 612.062 247.194Z" fill="#FFB296" />
    <path d="M564.302 278.087C567.45 278.087 570.002 275.536 570.002 272.388C570.002 269.24 567.45 266.688 564.302 266.688C561.154 266.688 558.602 269.24 558.602 272.388C558.602 275.536 561.154 278.087 564.302 278.087Z" fill="white" />
    <path d="M579.653 269.869C582.801 269.869 585.353 267.317 585.353 264.169C585.353 261.021 582.801 258.469 579.653 258.469C576.505 258.469 573.953 261.021 573.953 264.169C573.953 267.317 576.505 269.869 579.653 269.869Z" fill="white" />
    <path d="M594.052 262.173C597.2 262.173 599.752 259.621 599.752 256.473C599.752 253.325 597.2 250.773 594.052 250.773C590.904 250.773 588.352 253.325 588.352 256.473C588.352 259.621 590.904 262.173 594.052 262.173Z" fill="white" />
    <path d="M420.258 72.2734C420.258 72.2734 457.788 188.353 602.258 182.083C650.258 176.083 707.388 182.083 758.258 249.083C835.588 351.003 804.538 464.542 769.398 536.612" stroke="#FFB296" strokeWidth="4.11" strokeMiterlimit="10" />
    <g opacity="0.54">
      <path opacity="0.54" d="M629.09 117.859C628.948 119.61 628.678 121.348 628.28 123.059L623.78 142.579C622.4 148.579 620.85 154.859 616.57 159.239C608.57 167.399 595.27 165.529 584.15 163.059L556.85 156.999C550.58 155.609 544.21 154.179 538.59 151.069C532.97 147.959 528.09 142.869 526.79 136.579C525.79 131.939 526.86 127.139 527.88 122.519L532 103.779C533.37 97.6394 534.91 91.1594 539.34 86.6894C547.23 78.7394 560.34 80.5494 571.24 82.9194L599.2 88.9994C616.06 92.6394 630.43 100.459 629.09 117.859Z" fill="#FFB296" />
      <path opacity="0.54" d="M591.439 118.38C592.608 119.467 593.471 120.843 593.939 122.369C594.408 123.896 594.466 125.519 594.109 127.075C593.751 128.631 592.989 130.065 591.901 131.234C590.813 132.402 589.436 133.263 587.909 133.73L579.589 136.28L571.269 138.83C569.742 139.297 568.119 139.354 566.563 138.995C565.008 138.636 563.574 137.875 562.405 136.786C561.237 135.698 560.376 134.322 559.908 132.795C559.44 131.269 559.381 129.646 559.739 128.09L561.739 119.61L563.689 111.14C564.045 109.583 564.805 108.148 565.893 106.978C566.981 105.809 568.357 104.946 569.884 104.478C571.411 104.009 573.034 103.952 574.59 104.31C576.147 104.669 577.581 105.431 578.749 106.52L585.119 112.45L591.439 118.38Z" fill="white" />
    </g>
    <path d="M39.4844 777.172H903.004" stroke="black" strokeWidth="4.11" strokeMiterlimit="10" />
  </svg>
)
export default ActionList_NoLeads