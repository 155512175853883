import { List, ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';
import DomainVariation from './DomainVariation';
import Region from './Region';


@NeoModel
export default class DemoClientData extends ModelBase {
    public demoClientId: number = 0;
    
    @Attributes.Display("Client")
    public clientId: number = 0;

    public isActive: boolean = false;
    
    public replyExperienceMockDataURL: string = ""

    public campaignMessageStatisticsMockDataURL: string = ""
    
    public campaignGraphMonthlyMockDataURL: string = ""

    public campaignGraphDailyMockDataURL: string = ""
}

