import { ModelBase, NeoModel, Validation, } from '@singularsystems/neo-core';
import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';
import { isEmpty } from 'lodash';
import { isURL } from '../../common/utils';

@NeoModel
export default class CalendlyLink extends ModelBase {
    public calendlyLinkId: number = 0

    @Display("Name")
    public customerSuccessManagerName: string = "";

    @Display("Calendly Link")
    public calendlyUrlLink: string = "";

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(fw => isEmpty(fw.calendlyUrlLink) || !isURL(fw.calendlyUrlLink), "Please provide a valid URL");
        rules.failWhen(fw => isEmpty(fw.customerSuccessManagerName), "Please provide a name for the Customer Success Manager");
    }
}