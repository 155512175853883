import React from 'react';
import { observer } from 'mobx-react';
import ClientCampaignDataLookup from '../../Models/Dashboard/ClientCampaignDataLookup';
import { List } from '@singularsystems/neo-core';
import DashboardVM from '../../Views/Home/DashboardVM';
import ClientCampaignWoodpeckerEmails from './ClientCampaignWoodpeckerEmails';
import ClientCampaignWoodpeckerEmailLookup from '../../Models/Dashboard/ClientCampaignWoodpeckerEmailLookup';
import { Neo } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import * as Icon from 'react-feather';
import GridChartSwitch from '../GridChartSwitch';
import ColumnChart from '../ColumnChart';
import { isMobile } from 'common/utils';

interface IClientCampaignWoodpeckerEmailTableProps {
  campaignData: List<ClientCampaignDataLookup>;
  viewModel: DashboardVM;
  back: () => void;
}

@observer
export default class ClientCampaignWoodpeckerEmailTable extends React.Component<IClientCampaignWoodpeckerEmailTableProps> {

  public isPercent: boolean = true;

  private getCampaign() {
    return this.props.viewModel.campaignData.find((id) => id.clientCampaignId === this.props.viewModel.clientCampaignId);
  }

  componentDidMount(): void {
    if (!this.getCampaign()) {
      // Navigate back if there is no campaign found
      this.props.back();
    }
  }

  public render() {
    let campaign = this.getCampaign()

    return (
      <div className='dashboardEmailRowContainer'>
        <div className='row ml-0 mr-0 pt-4'>
          {/* Back button */}
          <div className='mt-1'>
            {<Neo.Button
              className="btn-width-100 marginLeft24"
              variant="light"
              icon={<Icon.ArrowLeft />}
              onClick={async () => {
                this.props.viewModel.isWoodpeckerEmailOverview = false
                await this.props.back!()
              }}>
              {textConstants.buttonText.Back}
            </Neo.Button>
            }
          </div>

          {/* View Header */}
          <div className='header ml-3'>
            {campaign?.campaignName}
          </div>

        </div>
        <Neo.Card>
          <div className='row m-0 p-24'>
            <div className='col-10 header p-0'>Emails</div>

            {!isMobile() && <div className="col-2 p-0 moveSwitchRight pull-right">
              <GridChartSwitch
                isButton1Selected={this.props.viewModel.isGridSelected}
                toggle={() => {
                  this.props.viewModel.isGridSelected = !this.props.viewModel.isGridSelected;
                  this.props.viewModel.dataHasChanged = true
                }
                }>
              </GridChartSwitch>
            </div>}

          </div>
          {this.props.viewModel.isGridSelected &&
            <div>
              <ClientCampaignWoodpeckerEmails isHeader={true} />
              {campaign?.clientCampaignsWoodpeckerEmails.map((email: ClientCampaignWoodpeckerEmailLookup) => {
                return <ClientCampaignWoodpeckerEmails
                  key={email.campaignEmailId}
                  isHeader={false}
                  campaignEmailData={email} />
              })}
            </div>}

          {!this.props.viewModel.isGridSelected &&
            <div className='DashboardView'>
              <div className='onChart'>
                <GridChartSwitch
                  isButton1Selected={this.props.viewModel.isPercent}
                  toggle={() => {
                    this.props.viewModel.isPercent = !this.props.viewModel.isPercent;
                    this.props.viewModel.dataHasChanged = true
                  }}
                  button1Icon={<Icon.Percent />}
                  button2Icon={<Icon.Hash />}
                  className="p-24">
                </GridChartSwitch>
              </div>
              <div>
                <ColumnChart
                  SeriesNames={textConstants.GraphData.graphSeriesNames}
                  SeriesColours={textConstants.GraphData.chartBaseColour}
                  SeriesData={
                    this.props.viewModel.setSeriesValues(!this.props.viewModel.isPercent
                      ? textConstants.GraphData.emailCampaignsChartNumber : textConstants.GraphData.emailCampaignsChartPercent, campaign?.clientCampaignsWoodpeckerEmails)}
                  XAxisCategories={this.props.viewModel.setChartValues("emailNumber",
                    campaign?.clientCampaignsWoodpeckerEmails
                  )}
                  hasDataChanged={() => { return this.props.viewModel.hasDataChanged() }}
                  additionalOptions={this.props.viewModel.additionalOptionsColumnChart()}
                ></ColumnChart>
              </div>
            </div>}
        </Neo.Card>
      </div>
    );
  }
}