import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class TargetMarketAccountStatus extends ModelBase {

    public targetMarketAccountStatusId: number = 0;
    
    @Rules.StringLength(500)
    @Attributes.Display("Target Market Account Status")
    public targetMarketAccountStatusName: string = "";

    public uniqueTableKey: string = "";

    isExpanded: boolean = false

    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.targetMarketAccountStatusName === null || c.targetMarketAccountStatusName === '', textConstants.messageText.validationMessage.targetMarketAccountStatusName);
    }

    public toString(): string {
        if (this.isNew || !this.targetMarketAccountStatusName) {
            return "New target market account status";
        } else {
            return this.targetMarketAccountStatusName;
        }
    }
}