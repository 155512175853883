import React from "react"
import ReminderForm from "./ReminderForm"
import ReminderList from "./ReminderList"
import * as Icon from 'react-feather'
import { textConstants } from "common/textConstants"
import { observer } from "mobx-react"
import ReminderVM from "./ReminderVM"
import { Spinner } from "reactstrap"

interface IReminderSideMenu {
  viewModel: ReminderVM
}

// Reminder Side Menu
@observer
export default class ReminderSideMenu extends React.Component<IReminderSideMenu>{

  public render() {
    const { viewModel } = this.props

    return (
      <div className="ReminderSideMenu mt-4 ml-4">

        {this.props.viewModel.isLoading &&
          <div className="reminder-loading">
            <Spinner />
          </div>
        }

        {!this.props.viewModel.isLoading &&
          <>
            {/* Sidemenu head and add new reminder */}
            <div className="align-items-center d-flex" onClick={() => viewModel.addReminder()}>
              {textConstants.generalText.activeReminders}
              <Icon.PlusCircle className={"ml-2 mb-0 clickableCursor"} size={20} color="#7F92A3" />
            </div>

            {/* Opens up the reminder form */}
            {viewModel.isFormEdit && <ReminderForm viewModel={this.props.viewModel} saveForm={async () => { await viewModel.saveReminder() }} cancelForm={() => { viewModel.isFormEdit = false }} />}

            {/* List of reminders */}
            {!viewModel.isFormEdit && <ReminderList viewModel={this.props.viewModel}
              editReminder={(reminderId: string) => viewModel.editReminder(reminderId)}
              updateCompletion={(reminderId: string) => viewModel.updateReminderCompletion(reminderId)}
            />
            }
          </>
        }
      </div>
    )
  }
}