import { Data, NeoModel } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { textConstants } from "../../common/textConstants";
import { reaction } from "mobx";
import BatchReviewAccountLookup from "../../Models/BatchReview/BatchReviewAccountLookup";
import BatchReviewDeleteAccountCommand from "../../Models/BatchReview/Command/BatchReviewDeleteAccountCommand";
import BatchReviewDeleteContactCommand from "../../Models/BatchReview/Command/BatchReviewDeleteContactCommand";
import BatchReviewProspectsGridCriteria from "../../Models/BatchReview/Query/BatchReviewGridCriteria";
import CommandResult from "../../Models/InvitedUsers/Commands/CommandResult";
import { AppService, Types } from "../../Services/AppService";
import BatchReviewRemovalsCommand from "../../Models/BatchReview/Command/BatchReviewSaveCommand";
import SearchFieldVM from "../../Components/SearchFieldVM";
import { triggerHotjarEvent } from "../../Components/Hotjar";

@NeoModel
export default class BatchReviewProspectsVM extends Views.ViewModelBase
{
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private batchReviewApiClient = AppService.get(Types.ApiClients.BatchReviewApiClient),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        public config = AppService.get(Types.Config),
        public customAuthService = AppService.get(Types.Security.CustomAuthenticationService)
    ) {
        super(taskRunner);
    }

    public criteria: BatchReviewProspectsGridCriteria = new BatchReviewProspectsGridCriteria();
    public isComXUser : boolean = false;
    public showBatchReviewConfirmationModal : boolean = false;
    public clientId: number = 0;
    public batchReview: any;
    public batchReviewComplete: boolean = false;
    public currentSearchString : string = "";
    public accountRemoveUnremoveCommand: BatchReviewDeleteAccountCommand = new BatchReviewDeleteAccountCommand()
    public contactRemoveUnremoveCommand: BatchReviewDeleteContactCommand = new BatchReviewDeleteContactCommand()
    public isAllExpanded : boolean = false;
    public updateRemovals: BatchReviewRemovalsCommand = new BatchReviewRemovalsCommand()
    public searchFieldVM = new SearchFieldVM();
    public batchFinalReviewer : string = "";
    public batchFinalReviewDate : string = "";

    public pageManager = new Data.PageManager(this.criteria, BatchReviewAccountLookup, this.batchReviewApiClient.getBatchReviewDataLookups,
    {
        pageSize: 1000000,
        fetchInitial: false
    })

    public async fetchData(clientId: number) {
        if (clientId > 0) 
        {
            reaction(() => this.criteria.searchString, () => this.pageManagerRefresh(), { fireImmediately: false, delay: 1000 })

            this.pageManager.reset();
            
            await this.pageManagerRefresh();

            if (this.pageManager.data.length > 0){
                this.pageManager.data[0].isExpanded = true
                this.batchReviewComplete = this.pageManager.data[0].isCompleteReview
                
                await this.getBatchReviewCompletionDetails(clientId, this.pageManager.data[0].batchReviewUploadId)
            }            
        }
    }

    private async getBatchReviewCompletionDetails(clientId: number, batchReviewUploadId: number){
        // Only if the batch review is complete is this required
        if (this.batchReviewComplete){
            let response = await this.batchReviewApiClient.getBatchReviewCompletionDetails(clientId, batchReviewUploadId)

            if (response){
                this.batchFinalReviewer = response.data.userNameAndLastname
                this.batchFinalReviewDate = response.data.completionDate
            }
        }
    }

    public async canOpenBatchReview(clientId: number, batchReviewUploadId: number){
        // Dont open if no Id is provided
        let canOpen = false

        if(this.clientId <= 0 || batchReviewUploadId <= 0)
        {
            return canOpen;
        }
        else{
            let response = await this.batchReviewApiClient.isClientReviewOpen(batchReviewUploadId, clientId);

            if (response.data){
                canOpen = response.data as boolean
            }
        }

        return canOpen
    }
    
    public async pageManagerRefresh() {
        if(this.currentSearchString  !== this.criteria.searchString){
            this.currentSearchString = this.criteria.searchString
            this.criteria.isOnEntry = false;
            this.batchReviewComplete = this.pageManager.data.length > 0 ? this.pageManager.data[0].isCompleteReview : false

            await this.getBatchReviewCompletionDetails(this.criteria.clientId, this.criteria.batchReviewUploadId)
        }        
        
        await this.pageManager.refreshData(undefined, this.taskRunner);
    }

    private SetUpAccountDeleteToggleCommand(batchReviewAccountId: number)
    {
        this.accountRemoveUnremoveCommand = new BatchReviewDeleteAccountCommand();
        this.accountRemoveUnremoveCommand.batchReviewUploadId = this.criteria.batchReviewUploadId;
        this.accountRemoveUnremoveCommand.batchReviewAccountId = batchReviewAccountId
        this.accountRemoveUnremoveCommand.clientId = this.clientId
    }

    public async RemoveAccountFromProspects (batchReviewAccountId: number)
    {
        let item = this.updateRemovals.batchReviewAccountDeletions!.find(f => f.batchReviewAccountId === batchReviewAccountId);

        if(item){
            item.isDelete = true;
        }
        else{
            this.SetUpAccountDeleteToggleCommand(batchReviewAccountId)

            this.accountRemoveUnremoveCommand.isDelete = true

            this.updateRemovals.batchReviewAccountDeletions?.push(this.accountRemoveUnremoveCommand);
        }
    }

    public async UnremoveAccountFromProspects (batchReviewAccountId: number)
    {
        let item = this.updateRemovals.batchReviewAccountDeletions!.find(f => f.batchReviewAccountId === batchReviewAccountId);

        if(item){
            item.isDelete = false;
        }
        else{
            this.SetUpAccountDeleteToggleCommand(batchReviewAccountId)

            this.accountRemoveUnremoveCommand.isDelete = false

            this.updateRemovals.batchReviewAccountDeletions?.push(this.accountRemoveUnremoveCommand);
        }
    }

    private SetUpContactDeleteToggleCommand(batchReviewAccountId: number, batchReviewAccountContactId: number){
        this.contactRemoveUnremoveCommand = new BatchReviewDeleteContactCommand()
        this.contactRemoveUnremoveCommand.batchReviewUploadId = this.criteria.batchReviewUploadId;
        this.contactRemoveUnremoveCommand.batchReviewAccountId = batchReviewAccountId
        this.contactRemoveUnremoveCommand.BatchReviewAccountContactId = batchReviewAccountContactId
        this.contactRemoveUnremoveCommand.clientId = this.clientId
    }

    public async RemoveAccountContactFromProspects (batchReviewAccountId: number, batchReviewAccountContactId: number)
    {
        let item = this.updateRemovals.batchReviewContactDeletions!.find(f => f.batchReviewAccountId === batchReviewAccountId 
            && f.BatchReviewAccountContactId === batchReviewAccountContactId);

        if(item){
            item.isDelete = true;
        }
        else{
            this.SetUpContactDeleteToggleCommand(batchReviewAccountId, batchReviewAccountContactId)

            this.contactRemoveUnremoveCommand.isDelete = true

            this.updateRemovals.batchReviewContactDeletions?.push(this.contactRemoveUnremoveCommand);
        }
    }    

    public async UnremoveAccountContactFromProspects (batchReviewAccountId: number, batchReviewAccountContactId: number)
    {
        let item = this.updateRemovals.batchReviewContactDeletions!.find(f => f.batchReviewAccountId === batchReviewAccountId 
            && f.BatchReviewAccountContactId === batchReviewAccountContactId);

        if(item){
            item.isDelete = false;
        }
        else{
            this.SetUpContactDeleteToggleCommand(batchReviewAccountId, batchReviewAccountContactId)

            this.contactRemoveUnremoveCommand.isDelete = false

            this.updateRemovals.batchReviewContactDeletions?.push(this.contactRemoveUnremoveCommand);
        }
    }

    public async saveBatchReviewDraft(navigate: () => void)
    {
        this.updateRemovals.clientId = this.clientId;
        this.updateRemovals.batchReviewUploadId = this.criteria.batchReviewUploadId;

        var outcome = false

        this.taskRunner.run(async () => 
        {
            this.showBatchReviewConfirmationModal = false;
            
            const response =  await  this.batchReviewApiClient.SaveBatchAsDraft(this.criteria.batchReviewUploadId, this.updateRemovals)
                        
            const cr: CommandResult = response.data as CommandResult;

            if (cr.success) {
                this.notifications.addSuccess(
                    textConstants.titleText.BatchReviewSpaced,
                    textConstants.messageText.saveMessage.BatchReviewComplete
                )
                
                this.batchReviewComplete = true;
                triggerHotjarEvent(textConstants.Events.clientBatchReviewDraft)

                navigate();
                this.showBatchReviewConfirmationModal = false;
            }
        })
    }

    public async completeBatchReview(batchReviewUploadId : number, clientId : number, navigate: () => void)
    {
        this.updateRemovals.clientId = clientId;
        this.updateRemovals.batchReviewUploadId = batchReviewUploadId;

        var outcome = false
         this.taskRunner.run(async () => 
         {
            this.showBatchReviewConfirmationModal = false;
            
            const response =  await  this.batchReviewApiClient.completeBatchReview(batchReviewUploadId, this.updateRemovals)
                        
            const cr: CommandResult = response.data as CommandResult;
            if (cr.success) {
                this.notifications.addSuccess(
                    textConstants.titleText.BatchReviewSpaced,
                    textConstants.messageText.saveMessage.BatchReviewComplete
                )
                
                    triggerHotjarEvent(textConstants.Events.clientBatchReviewComplete)

                    this.batchReviewComplete = true;
                
                    navigate();
                    this.showBatchReviewConfirmationModal = false;
                }
            }            
        ) 
    }

    public gridExpandedCount() {
        let countOpen = this.pageManager.data.filter(bra => bra.isExpanded).length;                
        // Opposite to the toggle function as this is what the current state is 
        if (countOpen < this.pageManager.data.length) {
            this.isAllExpanded = false;
        } else {
            this.isAllExpanded = true;
        }  
    }

    public toggleGridExpand() {            
        let countOpen = this.pageManager.data.filter(bra => bra.isExpanded).length;                
        // Opposite to the toggle function as this is what the current state should become
        if (countOpen < this.pageManager.data.length) {
            this.isAllExpanded = true;
        } else {
            this.isAllExpanded = false;
        }  
        this.pageManager.data.forEach(bra => bra.isExpanded = this.isAllExpanded);
    }

    public rotateExpandAll(){
        if (this.isAllExpanded){
            return "fa-rotate-270"
        } else {
            return ""
        }
    }
}
