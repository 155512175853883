import { ModelBase, NeoModel } from "@singularsystems/neo-core";

@NeoModel
export default class DashboardCard extends ModelBase {

    public dashboardCardId: number = 0;

    public cardName: string = "";

    public cardText: string = "";

    public videoLink: string = "";

    public redirectLink: string = "";

    public headerText: string = "";

    public showDashboardNews: boolean = true;

    public imageName: string = "";

    public uniqueTableKey: string = "";

    public toString(): string {
        return "Dashboard Cards";
    }
}