export const boxShadow: {
  large: "0px 4px 24px 0px rgba(0, 0, 0, 0.16)";
  small: "4px 6px 16px 0px rgba(0, 0, 0, 0.08)";
} = {
  large: "0px 4px 24px 0px rgba(0, 0, 0, 0.16)",
  small: "4px 6px 16px 0px rgba(0, 0, 0, 0.08)"
};

interface IOutlineProps {
  px: string,
  color: string
}

export const outline = ({
  px, color
}: IOutlineProps ) => `${px}px solid ${color}`;