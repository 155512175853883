import React, { useEffect } from "react";
import { textConstants } from "common/textConstants";
import {
  StyledContentArea,
  StyledEmailCard,
  StyledEmailCardHeading,
  StyledEmailCardText,
  StyledEmailContent,
  StyledEmailContentButton,
  StyledEmailList,
  StyledLabel,
  StyledInputRead,
  StyledEmailListItem,
  StyledProvderActions,
  StyledIconHolder,
} from "./styles";

import Add from 'assets/svgs/Add';
import Edit from 'assets/svgs/Edit';
import Bin from 'assets/svgs/Bin';
import OutboundEmailTableLookup from 'Models/Client/Query/OutboundEmailTableLookup';

interface IFormValues {
  emailProvider: string;
  outboundEmail: string;
  id?: string | number;
}

export interface IOutboundEmailSetup {
  outboundEmails: OutboundEmailTableLookup[],
  hasChanged: boolean,
  updateHasChanged: () => void
  deleteOutboundEmail: (id: number) => void
  openModal: (id?: number) => void
  removePadding?: boolean,
  isExternal?: boolean,
  noEmails?: boolean,
}

const updateEmailList = (outboundEmails: OutboundEmailTableLookup[]) => {
  let data: IFormValues[] = []

  if (outboundEmails.length > 0) {
    data = outboundEmails.map(email => {
      let values: IFormValues = {
        id: email.outboundEmailId,
        emailProvider: email.providerName,
        outboundEmail: email.emailAddress,
      };

      return values;
    })
  }

  return data;
};

const EmailSetup = ({
  outboundEmails,
  hasChanged,
  updateHasChanged,
  deleteOutboundEmail,
  openModal,
  removePadding,
  noEmails,
  isExternal }
  : IOutboundEmailSetup) => {
  const [emailList, setEmailList] = React.useState<IFormValues[]>(() => updateEmailList(outboundEmails));

  const {
    EmailSettings: {
      AddNewEmail,
      EmailSubText,
      AddEmailButton,
      EmailAddressPlaceholder,
      ProviderNameLabel,
    },
  } = textConstants;

  useEffect(() => {
    if (hasChanged && outboundEmails.length > 0) {
      setEmailList(updateEmailList(outboundEmails))

      if (emailList.length > 0) {
        updateHasChanged()
      }
    }
  }, [hasChanged])

  const onDelete = (item: IFormValues) => {
    deleteOutboundEmail(item.id as number)
  };

  const emailAddressColumnWidth = { width: "50%" };

  const providerColumnWidth = { width: "40%" };

  const buttonsColumnsWidth = { width: "96px" };

  const individualButtonColumnsWidth = { width: "48px" };

  return (
    <>
      <StyledContentArea>
        <StyledEmailCard className={isExternal || removePadding ? "p-0" : ""}>

          <StyledEmailCardHeading>
            {AddNewEmail}
          </StyledEmailCardHeading>

          <StyledEmailContent>
            <StyledEmailCardText>
              {EmailSubText}
            </StyledEmailCardText>

            {/* Add Button */}
            <StyledEmailContentButton
              onClick={() => openModal()}
            >
              <Add /> {AddEmailButton}
            </StyledEmailContentButton>
          </StyledEmailContent>
          {noEmails &&
            <p className="invalid-feedback error" style={{ display: "block", marginBottom: 0 }}>
              {textConstants.generalText.needToAddEmail}
            </p>
          }

          <>
            {/* Headings */}
            {emailList.length > 0 && (
              <StyledEmailList>

                {/* Email Address */}
                <StyledEmailListItem
                  style={emailAddressColumnWidth}
                >
                  <StyledLabel>{EmailAddressPlaceholder}</StyledLabel>
                </StyledEmailListItem>

                {/* Provider Name */}
                <StyledEmailListItem
                  style={providerColumnWidth}
                >
                  <StyledLabel>{ProviderNameLabel}</StyledLabel>
                </StyledEmailListItem>

                {/* Button Aligment Space container */}
                <StyledEmailListItem
                  style={buttonsColumnsWidth}
                >
                </StyledEmailListItem>
              </StyledEmailList>
            )}

            {/* Outbound Emails */}
            {emailList.map((email: IFormValues, i) => (
              <StyledEmailList key={email.id ?? i}>
                <React.Fragment>
                  {/* Email Address */}
                  <StyledEmailListItem
                    style={emailAddressColumnWidth}
                  >
                    <StyledInputRead>{email.outboundEmail}</StyledInputRead>
                  </StyledEmailListItem>

                  {/* Provider Name */}
                  <StyledEmailListItem
                    style={providerColumnWidth}
                  >
                    <StyledInputRead>
                      {email.emailProvider}
                    </StyledInputRead>
                  </StyledEmailListItem>

                  {/* Edit Button */}
                  <StyledEmailListItem
                    style={individualButtonColumnsWidth}
                  >
                    <StyledProvderActions >
                      <StyledIconHolder
                        onClick={() => {
                          openModal(email.id as number)
                        }}
                      >
                        <span className="customSVG">
                          <Edit />
                        </span>
                      </StyledIconHolder>
                    </StyledProvderActions>
                  </StyledEmailListItem>

                  {/* Delete Button */}
                  <StyledEmailListItem
                    style={individualButtonColumnsWidth}
                  >
                    <StyledProvderActions >
                      <StyledIconHolder
                        onClick={() => onDelete(email)}
                      >
                        <Bin />
                      </StyledIconHolder>
                    </StyledProvderActions>

                  </StyledEmailListItem>
                </React.Fragment>
              </StyledEmailList>
            ))}
          </>
        </StyledEmailCard>
      </StyledContentArea>
    </>
  );
};

export default EmailSetup;
