import {
  ModelBase,
  NeoModel,
  Attributes,
} from "@singularsystems/neo-core";

import { Display } from '@singularsystems/neo-core/dist/Model/Decorators';
import { IBulkActionGrid, selectedItem } from '../ComxDataGridManager/BulkActionPageManager';

@NeoModel
export default class TargetMarketAccountLookup extends ModelBase implements IBulkActionGrid {
  
  @selectedItem(["targetMarketAccountId", "masterAccountId", "clientAccountId"])
  @Display("Select")
  public IsSelected : boolean = false

  @Attributes.Display("Cleaned Company Name")
  public fixedName: string = "";

  @Attributes.Display("Company Name")
  public originalName: string = "";

  @Attributes.Display("Status")
  public targetMarketAccountStatus: string = "";

  @Attributes.Display("Industry")
  public subIndustry: string = "";

  @Attributes.Display("Size")
  public companySize: string = "";

  @Attributes.Display("Removed From TM")
  public deleted: boolean = false;

  @Attributes.Display("Location")
  public location: string = "";

  @Attributes.Display("Domain")
  public domain: string = "";

  @Attributes.Display("Date Last Prospected")
  public dateLastProspected: string = "";

  @Attributes.Display("Priority")
  public priority: boolean = false;

  @Attributes.Display("Times Prospected")
  public timesProspected: number | null = null;

  @Attributes.Display("Reprospecting Status")
  public reProspectingStatus: boolean = false;

  public targetMarketAccountId: number = 0;

  public masterAccountId: number = 0;

  public clientAccountId: number = 0;
}
