import { ModelBase, NeoModel } from '@singularsystems/neo-core'; 
import TMLookup from '../TMLookup';

@NeoModel 
export default class SaveEmailCommand extends ModelBase { 
   //@Rules.Required()
   public campaignMessageId: number = 0;

   //@Rules.Required()
   public clientCampaignMessageId: number = 0;

   //@Rules.Required()
   public clientId: number = 0;

   //@Rules.Required()
   public campaignName: string=""

   public landingPageUrl: string=""

   public messageText: string=""

   public draftText: string=""

   public ordinal: number = 0

   public targetMarkets: TMLookup[] | null = []

   public isPublish: boolean = false

   public isClientReview: boolean = false

   public isSubject: boolean = false
}