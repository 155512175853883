import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import TargetMarketAccountsVM from './TargetMarketAccountsVM';
import { observer } from 'mobx-react';
import { addClientListRoute as route } from '../../RouteConfiguration'
import { textConstants } from '../../common/textConstants';
import { AppService, Types } from "../../Services/AppService";
import * as Roles from '../../Models/Security/Roles'
import { ValidationDisplayMode } from '@singularsystems/neo-core/dist/Validation/Misc';
import SearchField from '../../Components/SearchField';
import TargetMarketAccountDataGrid from './TargetMarketAccountGrid/TargetMarketAccountDataGrid';
import * as Icon from 'react-feather';
import Button from 'Components/Button';

interface ITargetMarketAccountsViewParams {
    viewModel: TargetMarketAccountsVM,
    back: () => void
}

@observer
export default class TargetMarketAccountsView extends React.Component<ITargetMarketAccountsViewParams> {

    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

    public securityCheck(role: any, openMethod: () => void) {
        if (this.authorisationService.hasRole(role)) {
            openMethod();
        }
        else {
            this.props.viewModel.showForbiddenModal = true;
        }
    }

    public render() {
        const { isComXUser, searchFieldVM } = this.props.viewModel
        return (
            <>
                <div className='TargetMarketAccountsView'>
                    <div className="row ml-0 mt-4 mr-0 ">
                        {/* Search field */}
                        <div className="col-12 p-0">
                            <div className="row mr-0 ml-0 mt-2 ">
                                <div className="col-4 pl-24 pr-0">
                                    <Neo.Button variant="light" onClick={() => { this.props.back() }}>
                                        <Icon.ArrowLeft />{textConstants.buttonText.Back}
                                    </Neo.Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Neo.Card>
                        <div className="row mb-0 mt-4 ml-0 mr-0">
                            <div className="col-3 p-24">
                                {/* Header */}
                                <h2 className="pt-0">{this.props.viewModel.targetMarketSummary.targetMarketName}</h2>
                            </div>

                            <div className="col-9 p-24">
                                {isComXUser &&
                                    <>
                                        {/* Add Client List button */}
                                        <Button
                                            buttonType='primary'
                                            className="pull-right ml-4 mt-0"
                                            variant="hasIcon"
                                            size="xSmall"
                                            onClick={() => {
                                                this.securityCheck(Roles.TargetMarkets.Manage,
                                                    () => this.props.viewModel.navigateToAddClientList())
                                            }}
                                        >
                                            <Icon.Plus />
                                            {route.name}
                                        </Button>

                                        {/* Download Accounts button */}
                                        <Button
                                            buttonType='primary'
                                            className="pull-right ml-4 mt-0"
                                            variant="hasIcon"
                                            size="xSmall"
                                            onClick={() => this.props.viewModel.downloadTargetMarketAccounts()}
                                        >
                                            <Icon.Download />
                                            {"Download Accounts"}
                                        </Button>
                                    </>
                                }

                                <SearchField
                                    searchStringLabel="Search"
                                    searchBindableProp={this.props.viewModel.criteria.meta.searchString}
                                    onClickFunction={(e) => {
                                        this.props.viewModel.refreshMethod()
                                        this.props.viewModel.isRefreshCount = true
                                    }}
                                    searchString={this.props.viewModel.criteria.searchString}
                                    searchFieldVM={searchFieldVM}
                                />
                            </div>
                        </div>

                        {/* Target Market Account Grid */}
                        <Neo.GridLayout md={1} className="mt-1">
                            <Neo.TabContainer className="mt-2 mb-2 m-0" variant="flat">

                                {this.props.viewModel.targetMarketAccountTabList.map(TMATab => (
                                    <TargetMarketAccountDataGrid
                                        tabs={TMATab}
                                        viewModel={this.props.viewModel}
                                        back={this.props.back}
                                        key={TMATab.tabName}
                                    />

                                ))}
                            </Neo.TabContainer>
                        </Neo.GridLayout>

                        {/* Forbidden Modal */}
                        <Neo.Modal
                            title="Forbidden"
                            show={this.props.viewModel.showForbiddenModal}
                            onClose={() => this.props.viewModel.showForbiddenModal = false}
                            closeButton={{ text: "Cancel", variant: "light" }}
                        >
                            <p>{textConstants.generalText.insufficientPrivileges}</p>
                        </Neo.Modal>

                        {/* Edit Modal */}
                        <Neo.Modal
                            title="Edit Target Market Account"
                            show={this.props.viewModel.showEditModal}
                            onClose={() => this.props.viewModel.showEditModal = false}
                            suppressForm={true}
                            footer={<div></div>}
                            className="hideModalFooter"
                        >
                            <Neo.Form
                                model={this.props.viewModel.editTargetMarketAccountCommand}
                                onSubmit={() => { this.props.viewModel.editTargetMarketAccount() }}
                                validationDisplayMode={ValidationDisplayMode.Always}
                                showSummaryModal={false}

                            >
                                <div className="d-inline-flex marginTop18">
                                    <label className=" mr-2">{textConstants.titleText.OriginalName}:</label>
                                    <div className="">{this.props.viewModel.editTargetMarketAccountCommand.originalName}</div>
                                </div>
                                <Neo.GridLayout md={1}>
                                    <Neo.FormGroup
                                        bind={this.props.viewModel.editTargetMarketAccountCommand.meta.fixedName}
                                    />
                                    <Neo.FormGroup bind={this.props.viewModel.editTargetMarketAccountCommand.meta.domain}
                                    />
                                    <Neo.FormGroup
                                        bind={this.props.viewModel.editTargetMarketAccountCommand.meta.accountStatusId}
                                        select={{ items: this.props.viewModel.targetMarketStatuses }}
                                    />
                                </Neo.GridLayout>
                                <div className="custom-footer p-0">
                                    <div className=" row m-0 float-right pt-2 submitButtonStyles">
                                        <div className="mr-3 col6ResponsivePosition p-0">
                                            <Neo.Button className="modalButtonWidth" text="Save" variant="secondary" isSubmit />
                                        </div>
                                        <div className="col6ResponsivePosition p-0">
                                            <Neo.Button className="modalButtonWidth" text="Cancel" variant="light" onClick={() => this.props.viewModel.showEditModal = false} />
                                        </div>
                                    </div>
                                </div>
                            </Neo.Form>
                        </Neo.Modal>

                        <Neo.Modal title={`Bulk Action Confirmation`} show={this.props.viewModel.showBulkActionModal}
                            onClose={() => this.props.viewModel.showBulkActionModal = false}
                            closeButton={{ text: "No", variant: "light" }}
                            acceptButton={{ text: "Yes", variant: "secondary", onClick: () => this.props.viewModel.PerformBulkAction() }}>
                            {this.props.viewModel.bulkActionMessage}
                        </Neo.Modal>

                    </Neo.Card>
                </div>
            </>
        )
    }
}