import { injectable } from 'inversify';
import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import Axios, { AxiosPromise } from 'axios';
import MasterAccountCriteria from './../Models/MasterAccounts/Query/MasterAccountCriteria';
import MasterAccountOption from '../Models/MasterAccounts/MasterAccountOption';
import EditMasterAccountCommand from '../Views/MasterAccounts/Commands/EditMasterAccount';
import CommandResult from '../Models/InvitedUsers/Commands/CommandResult';
import DeleteMasterAccountCommand from '../Views/MasterAccounts/Commands/DeleteMasterAccount';
import UndeleteMasterAccountCommand from '../Views/MasterAccounts/Commands/UndeleteMasterAccount';
import { ResponseResolver } from '../common/utils';

export interface IMasterAccounts {
    getMasterAccounts(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<MasterAccountCriteria>>): AxiosPromise<any>,
    getTemplate(option: MasterAccountOption): AxiosPromise<any>,
    uploadDocument(filelist: File[] | FileList, option: MasterAccountOption): AxiosPromise<any>,
    editMasterAccount(masterAccount: EditMasterAccountCommand): AxiosPromise<Model.PlainObject<CommandResult>>;
    deleteMasterAccount(masterAccount: DeleteMasterAccountCommand): AxiosPromise<any>;
    bulkDeleteMasterAccounts(masterAccount: DeleteMasterAccountCommand): AxiosPromise<any>;
    bulkUndeleteMasterAccounts(masterAccount: UndeleteMasterAccountCommand): AxiosPromise<any>;
    undeleteMasterAccount(masterAccount: UndeleteMasterAccountCommand): AxiosPromise<Model.PlainObject<CommandResult>>;
    downloadMasterAccounts(countryId: number): AxiosPromise<any>,
}

@injectable()
export default class MasterAccountsApiClient implements IMasterAccounts {

    constructor(public cnfig = AppService.get(Types.Config)) {
    }

    public getMasterAccounts(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<MasterAccountCriteria>>): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return Axios.get(`${apiPath}/MasterAccount?${Utils.getQueryString(request)}`);
    }

    public getTemplate(option: MasterAccountOption): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.post(`${apiPath}/MasterAccount/DownloadTemplate`, option.toJSObject()));
    }

    public uploadDocument(filelist: File[] | FileList, option: MasterAccountOption) {
        const apiPath = AppService.get(Types.Config).apiPath;

        const obj = option.toJSObject();

        const bodyFormData = new FormData();
        bodyFormData.set('options', JSON.stringify(obj));
        bodyFormData.set('files', filelist[0]);

        return Axios.post(`${apiPath}/MasterAccount/UploadDocument`, bodyFormData);
    }

    public editMasterAccount(masterAccount: EditMasterAccountCommand): AxiosPromise<{ success: boolean; error: string; id: number; }> {
        return ResponseResolver<{ success: boolean; error: string; id: number; }>(Axios.post(`${this.cnfig.apiPath}/MasterAccount/EditMasterAccount`, masterAccount.toJSObject()));
    }

    public deleteMasterAccount(masterAccount: DeleteMasterAccountCommand): AxiosPromise<{ success: boolean; error: string; id: number }> {
        return ResponseResolver<{ success: boolean; error: string; id: number }>(Axios.post(`${this.cnfig.apiPath}/MasterAccount/DeleteMasterAccount`, masterAccount.toJSObject()));
    }

    public bulkDeleteMasterAccounts(masterAccount: DeleteMasterAccountCommand): AxiosPromise<{ success: boolean; error: string; id: number }> {
        return ResponseResolver<{ success: boolean; error: string; id: number }>(Axios.post(`${this.cnfig.apiPath}/MasterAccount/BulkDeleteMasterAccounts`, masterAccount.toJSObject()));
    }

    public bulkUndeleteMasterAccounts(masterAccount: UndeleteMasterAccountCommand): AxiosPromise<{ success: boolean; error: string; id: number }> {
        return ResponseResolver<{ success: boolean; error: string; id: number }>(Axios.post(`${this.cnfig.apiPath}/MasterAccount/BulkUndeleteMasterAccounts`, masterAccount.toJSObject()));
    }

    public undeleteMasterAccount(masterAccount: DeleteMasterAccountCommand): AxiosPromise<{ success: boolean; error: string; id: number }> {
        return ResponseResolver<{ success: boolean; error: string; id: number }>(Axios.post(`${this.cnfig.apiPath}/MasterAccount/UndeleteMasterAccount`, masterAccount.toJSObject()));
    }

    public downloadMasterAccounts(countryId: Number): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.post(`${apiPath}/MasterAccount/DownloadMasterAccounts?countryId=${countryId}`));
    }
}
