import React from "react";

const CircleIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9993 28.3307C22.3631 28.3307 28.3327 22.3612 28.3327 14.9974C28.3327 7.6336 22.3631 1.66406 14.9993 1.66406C7.63555 1.66406 1.66602 7.6336 1.66602 14.9974C1.66602 22.3612 7.63555 28.3307 14.9993 28.3307Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleIcon;
