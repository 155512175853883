import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';

export interface IFollowUpTemplateCategories extends Data.IUpdateableApiClient<FollowUpTemplateCategoriesApiClient, number> {

}

@injectable()
export default class FollowUpTemplateCategoriesApiClient extends Data.UpdateableApiClient<FollowUpTemplateCategoriesApiClient, number> implements IFollowUpTemplateCategories {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/followUpTemplateCategories`);
    }
}