import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../Services/AppService';
import AddEditFollowUpTemplateVM from './AddEditFollowUpTemplateVM';
import CampaignEmailSignatureVM from './CampaignEmailSignatureVM';
import CampaignMessageVM from './CampaignMessageVM';
import ClientCampaignMessageVM from './ClientCampaignMessageVM';

@NeoModel
export default class ClientCampaignMessageCampaignsVM extends Views.ViewModelBase {
    [x: string]: any;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private authenticationService = AppService.get(Types.Neo.Security.AuthenticationService),
        private clientsApiClient = AppService.get(Types.ApiClients.ClientsApiClient),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public customAuthService = AppService.get(Types.Security.CustomAuthenticationService)) {

        super(taskRunner);
        this.customAuthService.globalProps.isOnboarding = false;
    }

    public clientId: number = 0
    public isComingFromFollowUpTemplates: boolean = false
    public showDashboard: boolean = true
    public showClientCampaignMessages: boolean = true
    public ClientCampaignMessageViewModel = new ClientCampaignMessageVM();
    public campaignMessageName: string = "";
    public clientDisplayName: string = "";
    public showEditEmailSignature: boolean = false
    public EditEmailSignatureViewModel = new CampaignEmailSignatureVM();
    public clientCampaignMessageId: number | null = null;
    public clientFollowUpTemplateId: number = 0;
    public initialCampaignLoad: boolean = true;
    public clearAddEditNotifications: boolean = true;
    public campaignMessageViewModel = new CampaignMessageVM();
    public addEditFollowUpTemplateViewModel = new AddEditFollowUpTemplateVM();
    public showCampaignMessage: boolean = false
    public showFollowUpTemplate: boolean = false
    public previousClientCampaignMessageGridPage: number = 1;

    public async initialise() {        
    }

    public async getClientName(clientId: number) {
        const response = await this.taskRunner.waitFor(this.clientsApiClient.getClient(clientId));
        return response.data.clientName;
    }

    public async setClientDisplayName(clientName: string) {
        this.clientDisplayName = clientName
    }

    public async getClientId() {
        const { user, isAuthenticated } = this.authenticationService;

        // Fetch the platformSetup and successTracker urls
        if (isAuthenticated) {
            const response = await this.clientsApiClient.getDashboardInfo(user!.userName);
            this.clientId = response.data.clientId;
        }

    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }
}