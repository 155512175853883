import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';

export interface IHelpCenterTextsApiClient extends Data.IUpdateableApiClient<HelpCenterTextsApiClient, number> {

}

@injectable()
export default class HelpCenterTextsApiClient extends Data.UpdateableApiClient<HelpCenterTextsApiClient, number> implements IHelpCenterTextsApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/HelpCenterTexts`);
    }
}