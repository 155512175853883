import { observer } from "mobx-react";
import React from "react";
import { Neo } from "@singularsystems/neo-react";
import * as Icon from "react-feather";
import ActionListVM from "../../Views/ActionList/ActionListVM";
import CustomDropDown from "Components/CustomDropDown";
import { IOptions } from "Components/CustomDropDown/CustomDropDown";
import ActionListLookupData from "Models/ActionList/ActionListLookupData";
import { convertActionListStatusesToCustomDropdown, getRelativeTimeFromDateString } from "common/utils";
import { textConstants } from "common/textConstants";

interface IActionListTableRow {
  lead: ActionListLookupData;
  viewModel: ActionListVM;
  openLeadView: (prospectId: number, campaignId: number, replyId: string) => void;
}

@observer
class ActionListTableRow extends React.Component<IActionListTableRow> {
  state = {
    showActiveStyle: "",
    isStatsExpanded: false,
    isNotesExpanded: false,
  };

  constructor(props: any) {
    super(props);
  }

  private statuses: IOptions[] = [];

  private setCheckboxStyle(lead: ActionListLookupData) {
    if (lead.isSelected) {
      return "selectedCheckbox";
    }

    return "";
  }

  private getDropDownOptions() {
    var options = convertActionListStatusesToCustomDropdown(this.props.viewModel.actionListStatuses)
    this.statuses = options
    return options
  }

  private makeBold() {
    if (!this.props.lead.read) {
      return "bold"
    } else {
      return ""
    }
  }

  // Gets the Reminder Status whether it is due or set
  private getReminderStatus(actionListData: ActionListLookupData) {
    let reminderTime = new Date(actionListData.next_reminder.time)

    if (new Date() > reminderTime) {
      return (<div><Icon.Bell size={20} className="mr-1" /><strong>{textConstants.Reminders.reminderDue}</strong></div>);
    }

    return (<div><Icon.Bell size={20} className="mr-1" />{textConstants.Reminders.reminderSet}</div>)
  }

  render() {
    return (
      <div className="actionListRowContainer">
        {
          <div className={`${this.makeBold()} actionListGrid`}>
            {/* Checkbox Column */}
            <div className={`checkboxCol px-0 my-auto text-center `}>
              <Neo.FormGroup
                className={`checkbox ${this.setCheckboxStyle(this.props.lead)}`}
                input={{ type: "checkbox" }}
                bind={this.props.lead.meta.isSelected}
                onChange={() => {
                  this.props.viewModel.hasToggledItemSelect(this.props.lead);
                }}
                suppressLabel={true}
              />
            </div>

            {/* Name Column */}
            <div className="px-0  py-3  ml-0 my-auto ">
              <div className="row mb-1 leadName">
                {!this.props.lead.secondary_prospect ?
                  `${this.props.lead.prospect_name.first_name} ${this.props.lead.prospect_name.last_name}`
                  : `${this.props.lead.secondary_prospect.email}`}
              </div>
              <div className={`${this.makeBold()} row columnSubText`}>
                {this.props.lead.secondary_prospect ?
                  textConstants.generalText.SecondaryProspect
                  : this.props.lead.prospect_email}
              </div>
            </div>

            {/* Campaign Column */}
            <div className="px-0 py-3  ml-0 my-auto ">
              <div className={`${this.makeBold()} row columnSubText campaignTitle`}>
                {this.props.lead.campaign_name === null ? "Campaign Name" : this.props.lead.campaign_name}
              </div>
              <div className={`${this.makeBold()} row columnSubText`}>
                {this.props.lead.campaign_email === null ? "Campaign Email" : this.props.lead.campaign_email}{" "}
              </div>
            </div>

            {/*Activity Column*/}
            <div>
              {this.props.lead.next_reminder &&
                <div className="px-0 py-3  ml-0 my-auto ">
                  <div className="row columnTitleText mb-1">
                    {this.getReminderStatus(this.props.lead)}
                  </div>
                  <div className="row columnSubText">
                    {this.props.lead.next_reminder.title}
                  </div>
                </div>
              }

              {/* No Reminder Present */}
              {!this.props.lead.next_reminder &&
                <div>

                  {/* Time to reply, already opened the lead */}
                  {this.props.lead.read &&
                    <div className="px-0 py-3  ml-0 my-auto ">
                      <div className="row columnTitleText">
                        {textConstants.Reminders.timeToReply}
                      </div>
                      <div className="row columnSubText">
                        {getRelativeTimeFromDateString(this.props.lead.clustered_at)}
                      </div>
                    </div>
                  }

                  {/* New reply Recieved, haven't opened the lead yet */}
                  {!this.props.lead.read &&
                    <div className="px-0 py-3 ml-0 my-auto ">
                      <div className="row columnTitleText">
                        {textConstants.Reminders.newReplyRecieved}
                      </div>
                      <div className="row columnSubText">
                        {getRelativeTimeFromDateString(this.props.lead.clustered_at)}
                      </div>
                    </div>
                  }
                </div>
              }

            </div>

            {/*Status Column*/}
            <div className={`px-0 ml-0 my-auto`}>
              <CustomDropDown
                className="width-fit-content"
                options={this.getDropDownOptions()}
                placeHolder={this.statuses.find(s => s.value === this.props.lead.cluster_info.category)?.label || ""}
                isDisabled={true}
              />
            </div>

            {/* Lead Message Navigation Button Column*/}
            {this.props.lead !== undefined && !this.props.viewModel.actionListCriteria.isArchived && (
              <div className="text-center mr-0">
                {/* UPDATE TIME */}
                <div className="row mx-0 updateTime">
                  <span className="p-0 m-0">
                    {getRelativeTimeFromDateString(this.props.lead.clustered_at)}
                  </span>
                </div>

                {/* BUTTON WITH UNSEEN DOT */}
                <div className="row pull-right mx-0">
                  <Neo.Button
                    className="text-center leadOverview"
                    variant="light"
                    onClick={() => this.props.openLeadView(this.props.lead.prospect_id, this.props.lead.campaign_id, this.props.lead.reply_id)}
                  >
                    {<Icon.ArrowRight />}
                    {!this.props.lead.read && <span className="unseenDot" />}
                  </Neo.Button>
                </div>
              </div>

            )}
          </div>
        }
      </div>
    );
  }
}

export default ActionListTableRow;
