import {
  ModelBase,
  NeoModel,
} from '@singularsystems/neo-core';

@NeoModel
export default class TargetMarketLookup extends ModelBase {
  public targetMarketId: number = 0;

  public sequenceNumber: number = 0;

  public countryId: number = 0;

  public countryName: string = "";

  public countryCode: string = "";

  public targetMarketName: string = "";

  public targetMarketStatusId: number = 0;

  public progressResponded: number = 0;

  public progressProspected: number = 0;

  public targetMarketAccountCount: number = 0;

  public prospectingCount: number = 0;

  public progressDone: number = 0;

  public isDeleted: boolean = false;

  public isCalculating: boolean = false;

  public whitelistImport: string = "";

  public isComplete: boolean = false;

  public industriesVersionId: number = 1; // Default version is always going to be 1

  // Returning the remainder for rounding will add up to 100
  public get progressToDo() : number {
    if (this.targetMarketAccountCount !== 0)
    {
      return 100 - this.progressProspected - this.progressDone;
    }
    return 0;
  }
  
  public set progressToDo(v : number) {
  }
}