import { Data } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import TargetMarketAccountTabStatus from '../Models/Maintenance/TargetMarketAccountTabStatus';
import Axios, { AxiosPromise } from 'axios';
import { ResponseResolver } from '../common/utils';

export interface ITargetMarketAccountTabStatusesApiClient extends Data.IUpdateableApiClient<TargetMarketAccountTabStatus, number> {
    getTargetMarketAccountTabStatus(): AxiosPromise<any> 
}

@injectable()
export default class TargetMarketAccountTabStatusesApiClient extends Data.UpdateableApiClient<TargetMarketAccountTabStatus, number> implements ITargetMarketAccountTabStatusesApiClient {
    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/targetMarketAccountTabStatuses`);
    }

    public getTargetMarketAccountTabStatus(): AxiosPromise<any> {
        const apiPath = AppService.get(Types.Config).apiPath;
        return ResponseResolver<any>(Axios.get(`${apiPath}/TargetMarketAccountTabStatus/getTargetMarketAccountTabStatus`));
    }
}