import { Data, List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../common/textConstants';
import BuyingCenter from '../../Models/Maintenance/BuyingCenter';
import { AppService, Types } from '../../Services/AppService';

@NeoModel
export default class BuyingCentersMaintenanceVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private buyingCentersApiClient = AppService.get(Types.ApiClients.BuyingCentersApiClient)) {
      
    super(taskRunner);
  }

  public async initialise() {
    const response = await this.taskRunner.waitFor(this.buyingCentersApiClient.getBuyingCentersAsync());
    this.buyingCenterList.set(response.data.filter(d => d.deleted === null));
    this.currentMaxUniqueKey = Math.max(...this.buyingCenterList.map(buyingCenter => Number(buyingCenter.uniqueTableKey.replace("bc", ""))), 0);    
  }

  public buyingCenterList = new List(BuyingCenter);
  public currentMaxUniqueKey: number = 0

  public async updateBuyingCenters() {

    var newBuyingCenters = this.buyingCenterList.filter(bc => bc.uniqueTableKey === "")

    newBuyingCenters.forEach(newBuyingCenter => {
      var matchingBuyingCenter = this.buyingCenterList.find(bc => bc.buyingCenterName === newBuyingCenter.buyingCenterName)
      if (matchingBuyingCenter) {
        matchingBuyingCenter.uniqueTableKey = "bc"+String(this.currentMaxUniqueKey+1)
        this.currentMaxUniqueKey++
      }
    });

    this.taskRunner.run(async () => {
      const response = await this.buyingCentersApiClient.saveList(this.buyingCenterList.toJSArray())
      this.buyingCenterList.update(response.data);
      this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.BuyingCentersSaved);

    });
  }
}