import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';

@NeoModel
export default class ReProspectingStatus extends ModelBase {

    public reProspectingStatusId: number = 0;
    
    @Rules.StringLength(500)
    @Attributes.Display("Re-prospecting Status")
    public reProspectingStatusName: string = "";
    public reProspectingFactor : number = 0
    // Client only properties / methods

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.reProspectingStatusName === null || c.reProspectingStatusName === '', textConstants.messageText.validationMessage.reProspectingStatusName);
    }

    public toString(): string {
        if (this.isNew || !this.reProspectingStatusName) {
            return "New re-prospecting status";
        } else {
            return this.reProspectingStatusName;
        }
    }
}