import React, { useEffect } from "react";
import {
  StyledStepper,
  StyledStep,
  StyledTitle,
  StyledStepIcon,
  StyledConnectorLine,
} from "./styles";

import LockIcon from "assets/svgs/Lock";
import Check from "assets/svgs/Check";
import { OnboardingSteps } from "Models/Enums/ApplicationEnums";
import { Unlock } from "react-feather";
import { ILoaded } from "Components/OnboardingSideMenu";

export interface IStep {
  id: number;
  title: string;
  active: boolean;
  completed: boolean;
  step: OnboardingSteps;
  unlocked: boolean;
}

export interface IStepperProps {
  steps: IStep[];
  active?: boolean;
  completed?: boolean;
  navigateToStep?: (currentStep: OnboardingSteps) => void
  [rest: string]: any;
  hasLoaded: (isLoaded: boolean) => void;
  sideMenuLoading: boolean;
}

const Stepper = ({ steps, active, completed, navigateToStep, sideMenuLoading, hasLoaded, ...rest }: IStepperProps) => {

  if (steps.length > 0  && !sideMenuLoading) {
    hasLoaded(true)
  }

  return (
    <StyledStepper {...rest}>
      {steps.map((step) => (
        <React.Fragment key={step.id}>
          <StyledStep
            variant={"primary"}
            active={step.active}
            completed={step.completed}
            unlocked={step.unlocked}
            onClick={() => {
              if (step.active || step.completed || step.unlocked) {
                navigateToStep!(step.step)
              }
            }}
          >
            <StyledStepIcon active={step.active} completed={step.completed} variant="primary">
              {step.completed ? <Check /> : step.unlocked ? <Unlock size={16} /> : <LockIcon />}
            </StyledStepIcon>
            <StyledTitle>{step.title}</StyledTitle>
          </StyledStep>
          <StyledConnectorLine
            active={step.active}
            completed={step.completed}
            last={step.id === steps.length + 1}
          />
        </React.Fragment>
      ))}
    </StyledStepper>
  );
};

export default Stepper;
