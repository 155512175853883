import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import ClientSettingsVM from './ClientSettingsVM';
import { observer } from 'mobx-react';
import { textConstants } from '../../common/textConstants';
import InvitedUsersComponent from '../InvitedUsers/Component/InvitedUsers';
import { AppService, Types, } from '../../Services/AppService';
import GreyListedProspect from './GreylistedProspect/GreyListedProspect';
import BlacklistedDomain from './BlacklistedDomain/BlacklistedDomain';
import { UnsavedChanges } from '../../Components/UnsavedChanges';
import BatchReviewUploadView from '../BatchReview/BatchReviewUploadView';
import * as Roles from '../../Models/Security/Roles';
import { Navigation } from '../../Models/Enums/ApplicationEnums';
import AdvancedEmailSettings from '../../Components/TechnicalIntegrationSettings/AdvancedEmailSettings';
import * as Icon from 'react-feather';
import InfoLabelHover from '../TargetMarket/elements/Label.Hover';
import { StyledButton } from 'Components/Button/styles';
import Button from 'Components/Button/Button';
import ClientNavigationButtons from 'Components/ClientNavigationButtons';

interface IEditClientProps {
  viewModel: ClientSettingsVM,
  back: () => void,
  openBatchReviewProspects: (batchReviewUploadId: number) => void,
  navigateToTargetMarket: () => void,
  children?: React.ReactNode,
}

@observer
export default class ClientSettingsView extends React.Component<IEditClientProps> {
  invitedUsersVM: any;

  private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);
  protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService)

  public onLeave() {
    if (this.props.viewModel.isComXUser) {
      return this.props.viewModel.clientDetails.isSelfDirty
    } else { return false }
  }

  public securityCheck(role: any, openMethod: () => void) {
    if (this.authorisationService.hasRole(role)) {
      openMethod();
    }
    else {
      this.props.viewModel.showForbiddenModal = true;
    }
  }

  public componentWillUnmount() {
    this.props.viewModel.isInitialLoad = false;
  }

  public render() {

    const tooltipForBlacklist = <div>{this.props.viewModel.doNotBlacklistTooltip}</div>;

    // Client
    const clientDetails = <Neo.Tab
      onDisplayed={() => { this.props.viewModel.toggleTab("", "Client", this.props.viewModel.clientId) }}
      header={textConstants.titleText.Client}>

      {/* Settings & Platform Setup header */}
      <div className="p-0 col-12">
        <h2>{textConstants.titleText.SettingsAndPlatformSetup}</h2>
      </div>

      <div className="ml-3 mr-3 marginTop24">
        <Neo.GridLayout md={2} className={"validationText mb-2"}>
          {/* Client Name */}
          <Neo.FormGroup
            placeholder={textConstants.generalText.placeHolderTypeHereText}
            bind={this.props.viewModel.clientDetails.meta.clientName} className="mb-2 pr-24" />

          {/* Client Review */}
          <div className='row m-0 p-0 blacklistSVG'>
            <Neo.FormGroup
              placeholder={textConstants.generalText.placeHolderTypeHereText}
              bind={this.props.viewModel.clientDetails.meta.isClientReview} className="mt-4 p-0"
              input={{ type: "checkbox" }}
            />

            {/* Do not blacklist in Woodpecker */}
            <Neo.FormGroup
              bind={this.props.viewModel.clientDetails.meta.doNotBlacklistWoodpecker}
              className="mt-4 ml-5 p-0"
              input={{ type: "checkbox" }}
            />
            <InfoLabelHover title={""}>{tooltipForBlacklist}</InfoLabelHover>
          </div>

          {/* Platform Setup */}
          <Neo.FormGroup
            placeholder={textConstants.generalText.placeHolderTypeHereText}
            bind={this.props.viewModel.clientDetails.meta.platformSetup} className="mb-3 pr-24" />

          {/* Success Tracking */}
          <Neo.FormGroup
            placeholder={textConstants.generalText.placeHolderTypeHereText}
            bind={this.props.viewModel.clientDetails.meta.successTracking} className="mb-3 p-0" />

          {/* Max Target Markets */}
          <Neo.FormGroup bind={this.props.viewModel.clientDetails.meta.maxTargetMarkets} className="mb-3 pr-24" />

          {/* Woodpecker API */}
          <Neo.FormGroup
            placeholder={textConstants.generalText.placeHolderTypeHereText}
            bind={this.props.viewModel.clientDetails.meta.woodpeckerApiKey} className="mb-3 p-0" />

          {/* Action Plan Checklist */}
          <Neo.FormGroup
            placeholder={textConstants.generalText.placeHolderTypeHereText}
            bind={this.props.viewModel.clientDetails.meta.actionPlanChecklist} className="mb-3 pr-24" />

          {/* Customer Success Manager */}
          <Neo.FormGroup bind={this.props.viewModel.clientDetails.meta.calendlyLinkId}
            placeholder={textConstants.generalText.placeHolderTypeHereText}
            select={{ items: this.props.viewModel.calendlyLinks }}
            onChange={() => {
              this.props.viewModel.clientDetails.isSelfDirty = true;
            }}
          />
        </Neo.GridLayout>
      </div>

      {/* Save */}
      {this.authenticationService.globalProps.clientId === 0 &&
        <div className="row m-0">
          <div className="col-lg-12 p-0">
            <Button
              buttonType='primary'
              className="pull-right ml-4 mt-0 mb-1"
              variant="hasIcon"
              size="xSmall"
              onClick={(e: any) => { e.preventDefault(); this.props.viewModel.save() }}
            >
              {textConstants.generalText.Save}
            </Button>
          </div>
        </div>
      }
    </Neo.Tab>

    const technicalIntegrationSettings = <Neo.Tab
      onDisplayed={() => {
        this.props.viewModel.toggleTab("", textConstants.TechnicalIntegrationSettings, this.props.viewModel.clientId)
      }}
      header={textConstants.TechnicalIntegrationSettings}>
      <AdvancedEmailSettings
        viewModel={this.props.viewModel.advancedEmailSettingsVM}
        addPadding={false} />
    </Neo.Tab>

    // Users
    const users =
      <Neo.Tab
        onDisplayed={() => { this.props.viewModel.toggleTab("", "Users", this.props.viewModel.clientId) }}
        header={textConstants.titleText.Users}>

        {/* Users card*/}
        <InvitedUsersComponent
          viewModel={this.props.viewModel.InvitedUsersViewModel}
          title={`${textConstants.titleText.User}s`}
          addHeaderPadding={false} />
      </Neo.Tab>;

    return (
      <>
        {this.props.viewModel.isInitialLoad &&
          <div className="ClientSettingsView">
            <UnsavedChanges
              showConfirm={this.props.viewModel.clientDetails.isSelfDirty}
              showPrompt={this.onLeave()}
              backButtonPath={"/Clients"}
            />

            {/* Back */}
            {!this.authenticationService.globalProps.isOnboarding && this.props.viewModel.isComXUser &&
              <Neo.Button
                variant="light"
                className="btn-width-100 mt-3 marginLeft24"
                icon={<Icon.ArrowLeft />}
                onClick={() => { this.props.back() }}
              >
                {textConstants.buttonText.Back}
              </Neo.Button>
            }

            <Neo.Card>
              <div className='row m-0'>
                <div className='col-4 p-24'>
                  <h2 className='pt-0 mt-2'>Client Settings</h2>
                </div>
              </div>
              {/* Client Portal card*/}
              <div className="row m-0">
                {
                  <div className="col p-24 justify-content-left" >
                    <ClientNavigationButtons viewModel={this.props.viewModel.clientNavigationButtonVM} back={this.props.back} />
                  </div>
                }
              </div>

              {this.props.viewModel.isComXUser &&
                <Neo.TabContainer
                  className='mt-2 mb-2 m-0 p-24 '
                  tabManager={this.props.viewModel.tabManager}>
                  {this.authorisationService.hasRole(Roles.Clients.Manage) && clientDetails}
                  {(this.authorisationService.hasRole(Roles.TI_AdvancedEmailSettings.View) ||
                    this.authorisationService.hasRole(Roles.TI_InternalTest.View) ||
                    this.authorisationService.hasRole(Roles.TI_OutboundEmails.View)) &&
                    <Neo.Tab
                      onDisplayed={() => {
                        this.props.viewModel.toggleTab("", textConstants.TechnicalIntegrationSettings, this.props.viewModel.clientId)
                      }}
                      header={textConstants.TechnicalIntegrationSettings}>
                      <AdvancedEmailSettings
                        addPadding={true}
                        viewModel={this.props.viewModel.advancedEmailSettingsVM} />
                    </Neo.Tab>}

                  {this.authorisationService.hasRole(Roles.Users.Manage) && users}
                  {this.authorisationService.hasRole(Roles.Blacklist.Manage) &&
                    <Neo.Tab
                      onDisplayed={() => { this.props.viewModel.toggleTab("", textConstants.titleText.Blacklist, this.props.viewModel.clientId) }}
                      header={textConstants.titleText.Blacklist}>

                      < BlacklistedDomain viewModel={this.props.viewModel} />

                    </Neo.Tab>
                  }

                  {this.authorisationService.hasRole(Roles.Greylist.Manage) && <GreyListedProspect viewModel={this.props.viewModel} />}

                  {this.authorisationService.hasRole(Roles.BatchReview.Manage) && <Neo.Tab
                    onDisplayed={() => { this.props.viewModel.toggleTab("", textConstants.titleText.BatchReviewSpaced, this.props.viewModel.clientId) }}
                    header={textConstants.titleText.BatchReviewSpaced}
                  >
                    <BatchReviewUploadView viewModel={this.props.viewModel.batchReviewUploadVM}
                      back={() => { }}
                      openBatchReviewProspects={(batchReviewUploadId: number) => {
                        this.props.openBatchReviewProspects(batchReviewUploadId)
                      }}
                      isComXUser={true} />
                  </Neo.Tab>}

                </Neo.TabContainer>
              }
            </Neo.Card>

            {!this.props.viewModel.isComXUser &&
              <Neo.TabContainer
                className='col-12 p-0 mt-2 mb-2 m-0'>
                <Neo.Tab
                  onDisplayed={() => { this.props.viewModel.toggleTab("", textConstants.titleText.Blacklist, this.props.viewModel.clientId) }}
                  header={textConstants.titleText.Blacklist}>
                  <BlacklistedDomain viewModel={this.props.viewModel} />
                </Neo.Tab>
              </Neo.TabContainer>
            }

            {/* </Neo.Card> */}
            {/* Invalid Data Modal */}
            <Neo.Modal title={textConstants.titleText.InvalidData} show={this.props.viewModel.showInvalidDataModal}
              onClose={() => this.props.viewModel.showInvalidDataModal = false}
              closeButton={{ text: "Close", variant: "light" }}
            >
              {this.props.viewModel.invalidDataMessage.split('\n').map((item, i) => {
                return <p key={i}>{item}</p>
              })}
            </Neo.Modal>
          </div>
        }
      </>
    );
  }
}