import {NeoModel, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class CustomerProfileCriteria extends ValueObject{

  public clientId: number = 0;
  
  public searchString: string = "";

  public isDeleted : boolean = false;

}