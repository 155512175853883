import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import TargetMarketStatus from '../Models/Maintenance/TargetMarketStatus';
import { AppService, Types } from '../Services/AppService';

export interface ITargetMarketStatusesApiClient extends Data.IUpdateableApiClient<TargetMarketStatus, number> {

}

@injectable()
export default class TargetMarketStatusesApiClient extends Data.UpdateableApiClient<TargetMarketStatus, number> implements ITargetMarketStatusesApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/TargetMarketStatuses`);
    }
}