import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { textConstants } from '../../../common/textConstants';
import { AppService, Types } from '../../../Services/AppService';
import UpdatePopupDataCommand from '../../../Models/Onboarding/Commands/UpdatePopupDataCommand';
import DashboardTooltipModel from '../../../Models/Maintenance/DashboardTooltipModel';
import { isURL } from '../../../common/utils';

@NeoModel
export default class OnboardingPopupMaintenanceVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private dashboardTooltipApiClient = AppService.get(Types.ApiClients.DashboardTooltipApiClient)) {
        super(taskRunner);
    }

    public removeNotifications() {
        this.notifications.store.notifications = [];
    }

    // Properties
    public dashboardToolTips = new List(DashboardTooltipModel);
    public dashboardTooltip: DashboardTooltipModel = new DashboardTooltipModel;
    public navigationList: String[] = [];
    public onboardingSaveCommandList = new List(UpdatePopupDataCommand);
    public showUpdateTextModal: boolean = false;
    public showTooltipUpdateTextModal: boolean = false;
    public showInvalidDataModal: boolean = false;
    public showInitialTooltipTable: boolean = true;
    public showInitialOnboardingTable: boolean = true;
    public showPerScreenView: boolean = false;
    public selectedPopUpId: number = 0;
    public selectedHtmlText: string = "";

    public async initialise() {
        const tooltipResponse = await this.dashboardTooltipApiClient.get();
        if (tooltipResponse.data) {
            this.dashboardToolTips.set(tooltipResponse.data.sortBy("popupName"));
        } else {
            this.notifications.addDanger(textConstants.titleText.SaveFailed, textConstants.messageText.saveMessage.SaveFailedMessage);
        }
    }

    public goBack(): void {
        if (!this.navigationList[this.navigationList.length - 1]) {
            window.history.back();
        } else {
            this.navigationList.pop();
            this.toggleView();
            this.showInitialOnboardingTable = true;
        }
    }

    public toggleView(item: DashboardTooltipModel | null = null) {
        this.showInitialTooltipTable = !this.showInitialTooltipTable;
        this.showPerScreenView = !this.showPerScreenView;
    }

    public async saveIndividualTooltipScreenData() {
        if (this.saveDashboardLinkValidations()) {
            this.taskRunner.run(async () => {
                const toolResponse = await this.dashboardTooltipApiClient.saveList(this.dashboardToolTips.toJSArray())

                if (toolResponse.status === 200) {
                    this.notifications.addSuccess(textConstants.titleText.Saved, textConstants.messageText.saveMessage.TooltipSaved);
                } else {
                    this.notifications.addDanger(textConstants.titleText.SaveFailed, textConstants.titleText.SaveFailed);
                }
            });
        }
    }

    private saveDashboardLinkValidations() {
        let isValid = true;

        if (this.dashboardTooltip.link) {
            isValid = isURL(this.dashboardTooltip.link);
        }

        if (!isValid) {
            this.notifications.addDanger(textConstants.titleText.SaveFailed, textConstants.titleText.SaveLinkFailed);
        }

        return isValid;
    }
}