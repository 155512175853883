import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import MeetingsVM from './MeetingsVM';
import { observer } from 'mobx-react';
import OnboardingCalendar from '../../Components/OnboardingCalendar';
import ScreenNavigator from 'Components/ScreenNavigator/ScreenNavigator';
import { StyledNavigationContainer } from 'Views/IdealCustomerProfiles/Styles/ICPNavigation.styles';
import HtmlParser from 'react-html-parser';
import { Spinner } from 'reactstrap';

interface IMeetingsViewProps {
    viewModel: MeetingsVM
    navigate?: () => void   // Whomever implements this, needs to call this method so that navigation can occur on Onboarding CM Base view
}

@observer
export default class MeetingsView extends React.Component<IMeetingsViewProps> {

    constructor(props: IMeetingsViewProps) {
        super(props);

        window.addEventListener('message', (e) => {
            if (this.isCalendlyEvent(e)) {
                this.props.viewModel.updateMeetingDetails(e.data, () => this.props.navigate && this.props.navigate())
            }
        });
    }

    public isCalendlyEvent(e: any) {
        return e.data.event && e.data.event.indexOf('calendly') === 0;
    }

    public render() {
        return (
            <div className='MeetingView row m-0'>
                {!this.props.viewModel.showSpinner &&
                    <>
                        <div className='col-12 p-0'>
                            <StyledNavigationContainer>
                                <ScreenNavigator
                                    steps={this.props.viewModel.navigatorSteps}
                                    goToStep={() => { }}
                                    currentStepNumber={1}
                                />
                            </StyledNavigationContainer>
                        </div>

                        <Neo.Card>
                            <div className='col-12 p-24 text-left'>
                                <div>
                                    <h3>{this.props.viewModel.maintenanceData.headerText}</h3>
                                </div>
                                <div>
                                    {HtmlParser(this.props.viewModel.maintenanceData.bodyText)}
                                </div>

                                {!this.props.viewModel.meetingLookup.isMeetingScheduled &&
                                    this.props.viewModel.showCalendly &&
                                    <>
                                        {/* Render Client's Specific Calendar */}
                                        {this.props.viewModel.clientCalendlyUrl !== "" &&
                                            <OnboardingCalendar calendlyUrl={this.props.viewModel.clientCalendlyUrl}
                                                clientId={String(this.props.viewModel.clientId)} />
                                        }

                                        {/* Render Default Calendar*/}
                                        {this.props.viewModel.clientCalendlyUrl === "" &&
                                            <OnboardingCalendar
                                                clientId={String(this.props.viewModel.clientId)} />
                                        }
                                    </>
                                }
                            </div>
                        </Neo.Card>
                    </>
                }

                {this.props.viewModel.showSpinner &&
                    <div className='text-center col-12 spinnerDiv'>
                        <Spinner animation="border" role="status" />
                    </div>
                }
            </div>
        );
    }
}