import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import BackgroundJobTypeConfig from '../Models/Maintenance/BackgroundJobType';

export interface IBackgroundJobTypeApiClient extends Data.IUpdateableApiClient<BackgroundJobTypeConfig, number> {
    
}

@injectable()
export default class BackgroundJobTypeApiClient extends Data.UpdateableApiClient<BackgroundJobTypeConfig, number> implements IBackgroundJobTypeApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/backgroundJobTypeConfigs`);
    }
}