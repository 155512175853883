import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class CustomerProfileStatus extends ModelBase {

    public customerProfileStatusId: number = 0;

    @Attributes.Display("Customer Profile Status")
    public status: string = "";

    public uniqueTableKey: string = "";

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}