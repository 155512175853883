import { Attributes, List, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { textConstants } from './../../common/textConstants';
import SubRegion from './SubRegion';

@NeoModel
export default class Region extends ModelBase{

    public regionId: number = 0;

    public countryId: number = 0;    

    @Rules.StringLength(500)
    @Attributes.Display("Name")
    public regionName: string = "";

    public subRegions = new List(SubRegion)

    isExpanded: boolean = false

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.regionName === null || c.regionName === '', textConstants.messageText.validationMessage.regionName);
    }

    public toString(): string {
        if (this.isNew || !this.regionName) {
            return "New region";
        } else {
            return this.regionName;
        }
    }
}