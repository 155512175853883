import { NeoModel, ModelBase} from  '@singularsystems/neo-core';

@NeoModel
export default class UpdateOnboardingStepCommand extends ModelBase {

    public clientId: number = 0;

    public stepNumber: number = 0;

    public isComplete: boolean = false;
}