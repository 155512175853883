import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import InformationManagementVM from './InformationManagementVM';
import { observer } from 'mobx-react';
import GoBack from '../../Components/GoBack';
import { textConstants } from '../../common/textConstants';
import RichTextEditor from '../../Components/RichTextEditor';
import * as Icon from 'react-feather';

@observer
export default class InformationManagementView extends Views.ViewBase<InformationManagementVM> {

    constructor(props: unknown) {
        super(textConstants.titleText.InformationManagement, InformationManagementVM, props);
    }

    private mailtoNewLineRegex = new RegExp("\n", "g")
    private textboxNewLineRegex = new RegExp(textConstants.generalText.MailToNewLine, "g")
    private mailToMaxLength = 1500

    onLeave() {
        this.viewModel.removeNotifications();
        return undefined;
    }

    public getCharacterCount() {
        return this.viewModel.informationManagement.technicalSettings.mailToText.replace(this.mailtoNewLineRegex, textConstants.generalText.MailToNewLine).length
    }

    public limitMailtoText() {
        // Character Limit imposed by mailto link
        if (this.getCharacterCount() >= this.mailToMaxLength) {
            // For the mailto text we need to consider the special characters that is used for new line as part of its length
            // Cut the string off
            let mailText = this.viewModel.informationManagement.technicalSettings.mailToText
                .replace(this.mailtoNewLineRegex, textConstants.generalText.MailToNewLine)
                .substring(0, this.mailToMaxLength);

            // Check if newline replacement character was split at the end of string
            mailText = this.newLineCharacterAdjustment(mailText);

            // Replace the new line back into the cut text
            this.viewModel.informationManagement.technicalSettings.mailToText = mailText.replace(this.textboxNewLineRegex, "\n");
        }
    }


    public newLineCharacterAdjustment(textToCheck: string): string {
        let subtractLength = 0

        if (textToCheck.endsWith("%0")) {
            subtractLength = 2
        }
        else if (textToCheck.endsWith("%0d")) {
            subtractLength = 3
        }
        else if (textToCheck.endsWith("%0d%")) {
            subtractLength = 4
        }
        else if (textToCheck.endsWith("%0d%0")) {
            subtractLength = 5
        }

        textToCheck = textToCheck.substring(0, (this.mailToMaxLength - subtractLength))

        return textToCheck
    }

    public render() {
        return (
            <div className="InformationManagementView">
                <GoBack />

                <Neo.Card>
                    <h2 className='p-24 mt-4'>{textConstants.titleText.InformationManagement}</h2>
                    <Neo.Form model={this.viewModel.informationManagement} onSubmit={() => this.viewModel.saveInformationManagement()} showSummaryModal>
                        <div className="mt-5" title={textConstants.generalText.TargetMarketSettings}>
                            <NeoGrid.Grid items={this.viewModel.informationManagement.informationManagementDetails}>
                                {(informationManagementDetail, informationManagementDetailMeta) => (
                                    <NeoGrid.Row >
                                        <NeoGrid.Column bind={informationManagementDetailMeta.isDisplayed}
                                            label={textConstants.titleText.Display}
                                            input={{ type: "checkbox" }}
                                            width={1} />
                                        <NeoGrid.Column display={informationManagementDetailMeta.viewName} />
                                        <NeoGrid.Column bind={informationManagementDetailMeta.videoUrl} />
                                        <NeoGrid.Column bind={informationManagementDetailMeta.displayText} append={
                                            <Neo.Button variant="light" className="text-center" icon={<Icon.Edit2 />}
                                                onClick={() => { this.viewModel.showUpdateModal(informationManagementDetail) }}>
                                            </Neo.Button>
                                        } />
                                    </NeoGrid.Row>
                                )}
                            </NeoGrid.Grid>
                        </div>

                        <div className='appended-btn-info-mng mt-4'>
                            <div className="mt-5 p-24" title={textConstants.generalText.EmailServiceProviders}>
                                <Neo.GridLayout md={4}>
                                    {this.viewModel.informationManagement.emailProviderTypes.map(provider => (
                                        <Neo.FormGroup
                                            key={provider.emailProviderTypeId}
                                            input={{ type: "checkbox" }}
                                            bind={provider.meta.isActive}
                                            label={provider.providerName}
                                        />
                                    ))}

                                </Neo.GridLayout>
                            </div>

                            {/* Header for drop down */}
                            <div className='mt-5 p-24' title={textConstants.generalText.AdvancedEmailSettings}>
                                <Neo.GridLayout md={1}>
                                    <Neo.FormGroup bind={this.viewModel.informationManagement.technicalSettings.meta.internalEmailTextHeader}
                                        label={textConstants.generalText.InternalTestEmailHeader}
                                        append={<Neo.Button
                                            variant="light"
                                            className="text-center"
                                            icon={<Icon.Edit2 />}
                                            onClick={() => this.viewModel.showTestEmailTextHeaderModal = true} />}>
                                    </Neo.FormGroup>
                                </Neo.GridLayout>

                                {/* Text for drop down */}
                                <Neo.GridLayout md={1}>
                                    <Neo.FormGroup bind={this.viewModel.informationManagement.technicalSettings.meta.internalEmailText}
                                        label={textConstants.generalText.InternalTestEmail}
                                        append={<Neo.Button
                                            variant="light"
                                            className="text-center"
                                            icon={<Icon.Edit2 />}
                                            onClick={() => this.viewModel.showTestEmailTextModal = true} />}>
                                    </Neo.FormGroup>

                                </Neo.GridLayout>

                                <Neo.GridLayout md={3}>
                                    <Neo.FormGroup bind={this.viewModel.informationManagement.technicalSettings.meta.firewallTooltip} label={"Firewall tooltip"} input={{ rows: 2 }} />
                                    <Neo.FormGroup bind={this.viewModel.informationManagement.technicalSettings.meta.dkimTooltip} label={"DKIM tooltip"} input={{ rows: 2 }} />
                                    <Neo.FormGroup bind={this.viewModel.informationManagement.technicalSettings.meta.spfTooltip} label={"SPF tooltip"} input={{ rows: 2 }} />
                                </Neo.GridLayout>

                                <Neo.GridLayout md={3}>
                                    <Neo.FormGroup bind={this.viewModel.informationManagement.technicalSettings.meta.cnameTooltip} label={"CNAME tooltip"} input={{ rows: 2 }} />
                                </Neo.GridLayout>
                            </div>

                            {/* CRM Text */}
                            <div className='mt-5 p-24' title={textConstants.generalText.CRMIntegration}>
                                <Neo.GridLayout md={2}>
                                    <Neo.FormGroup
                                        append={<Neo.Button
                                            variant="light"
                                            className="text-center gridButton"
                                            icon={<Icon.Edit2 />}
                                            onClick={() => this.viewModel.showCRMTextModal = true} />}
                                        bind={this.viewModel.informationManagement.technicalSettings.meta.crmIntegrationText}
                                        label={"CRM Integration text"} >
                                    </Neo.FormGroup>

                                    {/* CRM Video Text*/}
                                    <Neo.FormGroup
                                        append={<Neo.Button
                                            variant="light"
                                            className="text-center gridButton"
                                            icon={<Icon.Edit2 />}
                                            onClick={() => this.viewModel.showCRMVideoTextModal = true} />}
                                        bind={this.viewModel.informationManagement.technicalSettings.meta.crmIntegrationVideoText}
                                        label={"CRM Integration Video text"} >
                                    </Neo.FormGroup>

                                    <Neo.FormGroup
                                        bind={this.viewModel.informationManagement.technicalSettings.meta.crmIntegrationVideoURL}
                                        label={"Video URL"} />
                                </Neo.GridLayout>
                            </div>

                            <div className='mt-5 p-24' title={textConstants.generalText.ActionPlanChecklist}>
                                <Neo.GridLayout md={1}>
                                    <Neo.FormGroup
                                        append={<Neo.Button
                                            variant="light"
                                            className="text-center gridButton"
                                            icon={<Icon.Edit2 />}
                                            onClick={() => this.viewModel.showActionPlanTextModal = true} />}
                                        bind={this.viewModel.informationManagement.technicalSettings.meta.actionPlanText}
                                        label={"Action Plan Checklist text"} >
                                    </Neo.FormGroup>
                                </Neo.GridLayout>
                            </div>

                            <div className='mt-5 p-24' title={textConstants.generalText.TermsOfServiceText}>
                                <Neo.GridLayout md={1}>
                                    <Neo.FormGroup
                                        append={<Neo.Button
                                            variant="light"
                                            className="text-center gridButton"
                                            icon={<Icon.Edit2 />}
                                            onClick={() => this.viewModel.showTermsOfServiceTextModal = true} />}
                                        bind={this.viewModel.informationManagement.technicalSettings.meta.termsOfServiceText}
                                        label={"Terms of Service text"} >
                                    </Neo.FormGroup>
                                </Neo.GridLayout>
                            </div>

                            <div className='mt-5 p-24' title={textConstants.generalText.TechnicalIntegrationEmailBody}>
                                <Neo.GridLayout md={1}>
                                    <Neo.FormGroup
                                        append={<Neo.Button
                                            variant="light"
                                            className="text-center gridButton"
                                            icon={<Icon.Edit2 />}
                                            onClick={() => this.viewModel.showMailtToTextModal = true} />}
                                        bind={this.viewModel.informationManagement.technicalSettings.meta.mailToText}
                                        label={"Email Body text"} >
                                    </Neo.FormGroup>
                                </Neo.GridLayout>
                            </div>

                            <div className='mt-5' title={textConstants.generalText.CustomerMSuccessManagerCalendlyLinks}>
                                <NeoGrid.Grid items={this.viewModel.informationManagement.calendlyLinks}
                                    showAddButton addButton={{ icon: <Icon.Plus />, variant: "secondary" }}>
                                    {(calendlyLink, calendlyLinkMeta) => (
                                        <NeoGrid.Row >
                                            {calendlyLink.customerSuccessManagerName === "Default" &&
                                                <>
                                                    <NeoGrid.Column bind={calendlyLinkMeta.customerSuccessManagerName} readOnly={true} />
                                                    <NeoGrid.Column bind={calendlyLinkMeta.calendlyUrlLink} />
                                                    <NeoGrid.ButtonColumn />
                                                </>
                                            }
                                            {calendlyLink.customerSuccessManagerName !== "Default" &&
                                                <>
                                                    <NeoGrid.Column bind={calendlyLinkMeta.customerSuccessManagerName} />
                                                    <NeoGrid.Column bind={calendlyLinkMeta.calendlyUrlLink} />
                                                    <NeoGrid.ButtonColumn showDelete deleteButton={{
                                                        icon: <Icon.X size={24} />,
                                                        variant: "light",
                                                        className: "circled"
                                                    }
                                                    } />
                                                </>
                                            }
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            </div>
                        </div>


                        {/* Save button */}
                        <div className="my-4 text-right p-24">
                            <Neo.Button className="btn-width-100" isSubmit variant="success">{textConstants.buttonText.Save}</Neo.Button>
                        </div>
                    </Neo.Form>

                    {/* MODALS */}
                    {/* Update text modal*/}
                    <Neo.Modal title={textConstants.generalText.UpdateDisplayText} show={this.viewModel.showUpdateTextModal}
                        onClose={() => this.viewModel.showUpdateTextModal = false}
                        closeButton={{ text: "Cancel", variant: "light" }}
                        acceptButton={{ text: "Done", variant: "secondary", onClick: () => this.viewModel.saveDisplayText() }}
                        formProps={{ model: this.viewModel.informationManagementCommand }}>
                        <div>
                            <Neo.FormGroup
                                bind={this.viewModel.informationManagementCommand.meta.displayText} input={{ rows: 10 }}
                                footerText={textConstants.generalText.TargetMarketSettingsMaintenance}
                            />
                        </div>
                    </Neo.Modal>

                    <Neo.Modal
                        title={textConstants.generalText.UpdateInternalTestEmailTextHeader}
                        className={"modal-style-test"}
                        show={this.viewModel.showTestEmailTextHeaderModal}
                        onClose={() => this.viewModel.showTestEmailTextHeaderModal = false}
                        closeButton={{ text: "Cancel", variant: "light" }}
                        acceptButton={{
                            text: "Done", variant: "secondary",
                            onClick: () => {
                                this.viewModel.showTestEmailTextHeaderModal = false
                            }
                        }}>
                        <div>
                            <RichTextEditor
                                initialString={this.viewModel.informationManagement.technicalSettings.internalEmailTextHeader}
                                getHtmlText={(htmlText: string) => {
                                    this.viewModel.informationManagement.technicalSettings.internalEmailTextHeader = htmlText;
                                }} />
                        </div>
                    </Neo.Modal>

                    {/* Internal Test Email text editor */}
                    <Neo.Modal
                        title={textConstants.generalText.UpdateInternalTestEmailText}
                        className={"modal-style-test"}
                        show={this.viewModel.showTestEmailTextModal}
                        onClose={() => this.viewModel.showTestEmailTextModal = false}
                        closeButton={{ text: "Cancel", variant: "light" }}
                        acceptButton={{
                            text: "Done", variant: "secondary",
                            onClick: () => {
                                this.viewModel.showTestEmailTextModal = false
                            }
                        }}>
                        <div>
                            <RichTextEditor
                                initialString={this.viewModel.informationManagement.technicalSettings.internalEmailText}
                                getHtmlText={(htmlText: string) => {
                                    this.viewModel.informationManagement.technicalSettings.internalEmailText = htmlText;
                                }} />
                        </div>
                    </Neo.Modal>

                    {/* CRM Integration text editor */}
                    <Neo.Modal
                        title={textConstants.generalText.UpdateCRMIntegrationText}
                        className={"modal-style-test"}
                        show={this.viewModel.showCRMTextModal}
                        onClose={() => this.viewModel.showCRMTextModal = false}
                        closeButton={{ text: "Cancel", variant: "light" }}
                        acceptButton={{
                            text: "Done", variant: "secondary",
                            onClick: () => {
                                this.viewModel.showCRMTextModal = false
                            }
                        }}>
                        <div>
                            <RichTextEditor
                                initialString={this.viewModel.informationManagement.technicalSettings.crmIntegrationText}
                                getHtmlText={(htmlText: string) => {
                                    this.viewModel.informationManagement.technicalSettings.crmIntegrationText = htmlText;
                                }} />
                        </div>
                    </Neo.Modal>

                    {/* CRM Integration video text editor */}
                    <Neo.Modal
                        title={textConstants.generalText.UpdateCRMIntegrationVideoText}
                        className={"modal-style-test"}
                        show={this.viewModel.showCRMVideoTextModal}
                        onClose={() => this.viewModel.showCRMVideoTextModal = false}
                        closeButton={{ text: "Cancel", variant: "light" }}
                        acceptButton={{
                            text: "Done", variant: "secondary",
                            onClick: () => {
                                this.viewModel.showCRMVideoTextModal = false
                            }
                        }}>
                        <div>
                            <RichTextEditor
                                initialString={this.viewModel.informationManagement.technicalSettings.crmIntegrationVideoText}
                                getHtmlText={(htmlText: string) => {
                                    this.viewModel.informationManagement.technicalSettings.crmIntegrationVideoText = htmlText;
                                }} />
                        </div>
                    </Neo.Modal>

                    {/* Action Plan Checklist text editor */}
                    <Neo.Modal
                        title={textConstants.generalText.ActionPlanChecklist}
                        className={"modal-style-test"}
                        show={this.viewModel.showActionPlanTextModal}
                        onClose={() => this.viewModel.showActionPlanTextModal = false}
                        closeButton={{ text: "Cancel", variant: "light" }}
                        acceptButton={{
                            text: "Done", variant: "secondary",
                            onClick: () => {
                                this.viewModel.showActionPlanTextModal = false
                            }
                        }}>
                        <div>
                            <RichTextEditor
                                initialString={this.viewModel.informationManagement.technicalSettings.actionPlanText}
                                getHtmlText={(htmlText: string) => {
                                    this.viewModel.informationManagement.technicalSettings.actionPlanText = htmlText;
                                }} />
                        </div>
                    </Neo.Modal>


                    {/* MailTo Body Text */}
                    <Neo.Modal
                        title={textConstants.generalText.MailToBodyText}
                        className={"modal-style-test"}
                        show={this.viewModel.showMailtToTextModal}
                        onClose={() => this.viewModel.showMailtToTextModal = false}
                        closeButton={{ text: "Cancel", variant: "light" }}
                        acceptButton={{
                            text: "Done", variant: "secondary",
                            onClick: () => {
                                this.viewModel.showMailtToTextModal = false
                            }
                        }}>
                        <div className='row'>
                            <div className='col-12'>
                                <Neo.FormGroup
                                    bind={this.viewModel.informationManagement.technicalSettings.meta.mailToText}
                                    input={{ rows: 20 }}
                                    onChange={() => this.limitMailtoText()}
                                    footerText={textConstants.generalText.TargetMarketSettingsMaintenance}
                                />
                            </div>
                            <div className='col-12'>
                                <p>
                                    Character Count: {this.getCharacterCount()} / 1500
                                </p>
                            </div>
                        </div>
                    </Neo.Modal>


                    {/* Terms of Service text editor */}
                    <Neo.Modal
                        title={textConstants.generalText.TermsOfServiceText}
                        className={"modal-style-test"}
                        show={this.viewModel.showTermsOfServiceTextModal}
                        onClose={() => this.viewModel.showTermsOfServiceTextModal = false}
                        closeButton={{ text: "Cancel", variant: "light" }}
                        acceptButton={{
                            text: "Done", variant: "secondary",
                            onClick: () => {
                                this.viewModel.showTermsOfServiceTextModal = false
                            }
                        }}>
                        <div>
                            <RichTextEditor
                                initialString={this.viewModel.informationManagement.technicalSettings.termsOfServiceText}
                                getHtmlText={(htmlText: string) => {
                                    this.viewModel.informationManagement.technicalSettings.termsOfServiceText = htmlText;
                                }} />
                        </div>
                    </Neo.Modal>
                </Neo.Card>
            </div>
        );
    }
}