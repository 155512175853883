export const colors = {
    orange: {
      900: "#e67e22",
      800: "#d35400",
      700: "#c03900",
      600: "#b82e00",
      500: "#a62200",
      400: "#941b00",
      300: "#821400",
      200: "#6f0c00",
      100: "#5c0500",
      50: "#490000",
      25: "#360000"
    },
    yellow: {
      900: "#f4d03f",
      800: "#eead1c",
      700: "#e6b800",
      600: "#dd9f00",
      500: "#d48200",
      400: "#ca6500",
      300: "#bc4800",
      200: "#ab2b00",
      100: "#9a1e00",
      50: "#891200",
      25: "#780600"
    },
    xGreen: {
      900: "#008551",
      800: "#00784c",
      700: "#006b47",
      600: "#005e42",
      500: "#00513d",
      400: "#004438",
      300: "#003733",
      200: "#002a2e",
      100: "#001d29",
      50: "#001024",
      25: "#00031f"
    },
    xBlue: {
      900: "#284e8d",
      800: "#234384",
      700: "#1e387b",
      600: "#1a3372",
      500: "#162e69",
      400: "#122960",
      300: "#0e2357",
      200: "#0a1e4e",
      100: "#061945",
      50: "#02143c",
      25: "#000f33"
    },
    coldGrey: {
      900: "#333333",
      800: "#444444",
      700: "#555555",
      600: "#666666",
      500: "#7F92A3",
      400: "#818888",
      300: "#999999",
      200: "#aaaaaa",
      100: "#bbbbbb",
      50: "#cccccc",
      25: "#dddddd"
    },
    white: "#ffffff",
    altColor: '#111E1E',
  };
  
  export const palette = {
    primary: {
        main: '#FF652D',
        light: '#FF6731',
        dark: '#002648',
        contrastText: '#FFB296',
      },
    secondary: {
        main: '#00494C',
        light: '#548587',
        dark: '#00494C',
        contrastText: '#7FA4A5',
    },
    error: {
        main: '#F04541',
        light: '#F49E9D',
        dark: '#C23734',
        contrastText: '#F9EBEB',
    },
    warning: {
        main: '#E4B62E',
        light: '#EED793',
        dark: '#B69225',
        contrastText: '#F8F4E9',
    }
  };
  