import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import AppLayout, { ScreenSize } from './AppLayout';
import BreadCrumb from './BreadCrumb';
import UserStatus from './UserStatus';
import { observer } from 'mobx-react';
import { AppService, Types } from '../Services/AppService';
import OnboardingModal from './Modals/OnboardingModal';
import OnboardingPopupLookup from '../Models/Onboarding/Queries/OnboardingPopupLookup';
import ReactHtmlParser from 'react-html-parser';
import referUsImage from '../assets/img/Illustration_Refer_Us.svg'
import { textConstants } from '../common/textConstants';
import OnboardingStepsMenu from './OnboardingStepsMenu';
import HeaderLogoImage from '../assets/img/Comx-Logo-Hight-Res.png';
import Hotjar, { triggerHotjarEvent } from './Hotjar';
import * as Icon from 'react-feather';
import { isMobile } from 'common/utils';

@observer
export default class HeaderPanel extends React.Component {
  protected authenticationService = AppService.get(Types.Security.CustomAuthenticationService)
  private onboardingApiClient = AppService.get(Types.ApiClients.OnboardingApiClient)

  state = {
    referUsClicked: false,
    referUsText: "",
    referUsContent: <></>
  };

  constructor(props: any) {
    super(props);

    this.pinHeaderClick = this.pinHeaderClick.bind(this);

    this.toggleModal = this.toggleModal.bind(this);
  }

  private async setModalText() {
    if (this.state.referUsText === "") {
      let popUpData = await this.onboardingApiClient.getOnboardingPopupData(textConstants.ModalText.ReferUsText)

      if (popUpData) {
        let data = popUpData.data.data[0] as OnboardingPopupLookup

        if (data) {
          this.setState({
            referUsText: data.text
          });
        }
      }
    }
  }

  private toggleModal() {
    this.setState({
      referUsClicked: !this.state.referUsClicked
    });
  }

  private pinHeaderClick() {
    AppLayout.current.pinHeader = !AppLayout.current.pinHeader
  }

  private toggleOnboardingStepsMenu() {
    this.authenticationService.globalProps.showOnboardingStepsMenu = !this.authenticationService.globalProps.showOnboardingStepsMenu
  }

  private async getHtml() {
    const complete = await this.setModalText()
    let text = ReactHtmlParser(this.state.referUsText)

    if (text) {
      this.setState({
        referUsContent: <div className='modalContentText'><>{text}</></div>
      })
    }
  }

  public render() {

    const layout = AppLayout.current;
    const globalTask = Views.ViewBase.currentView ? Views.ViewBase.currentView.taskRunner : undefined;

    let headerClassName = "app-header-panel";
    if (layout.pinHeader && layout.currentScreenSize === ScreenSize.Huge) {
      headerClassName += " pinned";
    }
    if (!layout.isScrollTop) {
      headerClassName += " scrolled";
    }

    return (
      <div className={headerClassName}>
        {this.authenticationService.isAuthenticated &&
          <Hotjar />
        }

        {globalTask && globalTask.isBusy &&
          <Neo.ProgressBar className="page-progress" progressState={globalTask.progressState} variant={globalTask.options.variant} type="animated" />}

        {/* Onboarding Header */}
        {this.authenticationService.globalProps.isOnboarding &&
          <div className="app-header">
            <div className='row m-0'>
              <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 p-0 pl-3'>
                <a className='m-0' href='/'>
                  <img src={HeaderLogoImage} alt="" className="app-logo-header-container" />
                </a>
              </div>
              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'>
              </div>

              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-3'>
                {/* Onboarding Popup Menu with steps */}
                {this.authenticationService.globalProps.showOnboardingStepsMenu &&
                  <OnboardingStepsMenu />
                }
              </div>

              <div className='col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'>

              </div>
              <div className='col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 p-0'>

              </div>

              {/* User menu icon */}
              <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 p-0'>

                <UserStatus />

                {/* Refer Us */}
                {this.authenticationService.globalProps.isClientUser &&
                  <a className='refer-us-header'
                    onClick={async () => {
                      if (this.state.referUsText === '') {
                        let complete = await this.getHtml();
                      }
                      this.toggleModal()
                    }}>
                    Refer us
                  </a>
                }

                {/* Join Support Call */}
                {this.authenticationService.globalProps.isClientUser && <>
                  <a target="_blank" rel="noopener noreferrer" href={this.authenticationService.globalProps.joinSupportCallURLOnboarding} className='refer-us-header pull-right' onClick={() => { triggerHotjarEvent(textConstants.Events.clientSupportCall) }}>Join Support Call</a></>
                }

              </div>
            </div>
          </div>
        }

        {/* Classic Header */}
        {
          !this.authenticationService.globalProps.isOnboarding &&
          <div className="app-header">
            <div className='row m-0'>
              {isMobile() && <div className='col-1 mt-auto mb-auto'><Icon.Menu className='mb-0' onClick={() => this.authenticationService.globalProps.showSidemenu = !this.authenticationService.globalProps.showSidemenu} /></div>}
              <div className='col-1 pr-0'>
                <a className='m-0' href='/'>
                  <img src={HeaderLogoImage} alt="" className="app-logo-header-container" />
                </a>
              </div>

              <div className='col'>
                <div className="app-breadcrumb">
                  <BreadCrumb rootItem={{ label: "ComX", link: "/" }} /> {" "}
                </div>
              </div>

              {/* User menu icon, Refer Us, etc. */}
              <div className='col p-0'>
                {/* User */}
                <UserStatus />
                <div className="lineSeparator"></div>

                {/* Refer Us */}
                {this.authenticationService.globalProps.isClientUser && <>
                  <a target="_blank" rel="noopener noreferrer" href="https://form.jotform.com/200302138036338" className='refer-us-header pull-right pr-24'>Rate Us</a>

                  {/* Refer Us */}
                  <a className='refer-us-header'
                    onClick={async () => {
                      if (this.state.referUsText === '') {
                        let complete = await this.getHtml();
                      }
                      this.toggleModal()
                    }}>
                    Refer Us
                  </a></>
                }

                {/* Join Support Call */}
                {this.authenticationService.globalProps.isClientUser &&
                  <a target="_blank" rel="noopener noreferrer" href={this.authenticationService.globalProps.joinSupportCallURL} className='refer-us-header pull-right' onClick={() => { triggerHotjarEvent(textConstants.Events.clientSupportCall) }}>Join Support Call</a>
                }
              </div>
            </div>
          </div>
        }


        {this.authenticationService.globalProps.isClientUser &&
          <OnboardingModal
            isOpen={this.state.referUsClicked}
            imageSrc={referUsImage}
            content={this.state.referUsContent}
            closeModal={() => this.toggleModal()}
            acceptAndCloseButton={{
              text: "Refer a contact", onClose: () => {
                this.toggleModal();
                window.open('https://form.jotformeu.com/92051553115347', '_blank')
              }
            }}
          />
        }

      </div >
    )
  }
}