import { breakpoints } from "./breakpoints";
import spacing from "./spacing";
import { transitions } from "./transitions";
import zIndex from "./zIndex";
import { colors, palette } from "./colors";
import { outline, boxShadow } from "./utils";

export const theme = {
  breakpoints,
  colors,
  spacing,
  transitions,
  zIndex,
  outline,
  boxShadow,
  palette
};

export type Theme = typeof theme;