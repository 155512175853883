import React from "react";

const StepInactive = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="16" height="16" rx="8" fill="#F8F8F8" />
      <path
        d="M11.8777 5.83594L6.54773 11.1659L4.125 8.74321"
        stroke="#F8F8F8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StepInactive;
