import "rc-slider/assets/index.css";

import "react-range"
import React from "react";
import { observer } from "mobx-react";
import { Range } from "rc-slider";
import { Neo } from "@singularsystems/neo-react";
import { textConstants } from "../../common/textConstants";
import CustomerProfileQuestionnaireVM from "./CustomerProfileQuestionnaireVM";
import { Validation } from "@singularsystems/neo-core";
import { isNumber } from "lodash";
import { NavFooter } from "Components/FooterComponent";
import { isMiniNav } from "common/utils";
import ScreenNavigator from "Components/ScreenNavigator";
import { StyledNavigationContainer } from "./Styles/ICPNavigation.styles";
import * as Icon from 'react-feather';
import DragIcon from "assets/svgs/DragIcon";
import DeleteIcon from "assets/svgs/DeleteIcon";
import {
  StyledOrganizationList,
  StyledOrganizationItem,
  CustomSelect,
} from "./Styles/CustomerProfileView.styles";

import SortableList from "Components/Draggable/SortedList";
import { StyledButton, StyledButtonWrapper } from "Components/Button/styles";

interface ICustomerProfileQuestionnaireProps {
  viewModel: CustomerProfileQuestionnaireVM;
  back: (isPublish?: boolean) => void;
  disableBack?: boolean;
  downloadProfile?: (customerProfileId: number, profileName: string) => void;
}

interface IQuestionnaireState {
  showValue: boolean | null;
  showPrompt: boolean;
}

@observer
export default class CustomerProfileQuestionnaireView extends React.Component<
  ICustomerProfileQuestionnaireProps,
  IQuestionnaireState
> {
  constructor(props: ICustomerProfileQuestionnaireProps) {
    super(props);
    this.state = {
      showValue: false,
      showPrompt: false,
    };
  }

  public isZendeskVisible: boolean = false;

  private handleKeyDownForContinue = (event: any) => {
    if (event.key === 'Enter') {
      this.navigateContinueButton()
    }
  };

  public onDocumentSelected(fileList: File[] | FileList) {
    this.props.viewModel.fileList = fileList;

    const docxIndex = fileList[0].name.endsWith(".docx");

    if (!docxIndex) {
      const pdfIndex = fileList[0].name.endsWith(".pdf");

      if (!pdfIndex) {
        this.props.viewModel.couldNotSaveFile();
        return;
      }
    }

    this.props.viewModel.fileName = fileList[0].name;
    this.props.viewModel.saveFile = true;
    this.props.viewModel.uploadCustomerProfileFile(fileList);
  }

  public QuestionSelectorTypeText(questionNumber: number, answer: string, isOverview = false) {

    return <>
      <Neo.Textbox
        className="mt-2 col-12 textboxICP"
        bind={this.props.viewModel[answer].meta.answer}
        input={{ rows: 6 }}
        onMouseDown={() => {
          this.props.viewModel.initialComparisonValue = this.props.viewModel[answer].answer;
        }}
        onBlur={async () => {
          this.props.viewModel.finalComparisonValue = this.props.viewModel[answer].answer;
          await this.props.viewModel.OnElementBlur();
        }}
        placeholder={textConstants.generalText.placeHolderStartTyping}
        disabled={isOverview}
      />
      {this.props.viewModel.validationChecks(questionNumber) &&
        <div className="validationRed validationFontSize">{textConstants.messageText.validationMessage.questionRequired}</div>}
    </>
  }

  public QuestionSelectorTypeSlider(question: number, answer: string, marks: any, maxPoints: number, isOverview = false) {
    return <div className="pt-4 pl-1 pr-1">
      {this.props.viewModel[answer].minPointId === 0 && (
        <Range
          defaultValue={[
            this.props.viewModel[answer].minPointId,
            this.props.viewModel[answer].maxPointId,
          ]}
          min={1}
          max={maxPoints}
          marks={marks}

          onAfterChange={async (pointsOnLine) => {
            switch (question) {
              case 8:
                this.props.viewModel.setProductServiceWorth(pointsOnLine);
                break;

              default:
                this.props.viewModel.setEmployeeSize(pointsOnLine);
                break;
            }
            // This save needs a bit of a delay because it may be causing duplicates
            await this.props.viewModel.DelayedAutoSave();
          }}
          disabled={isOverview}
        />
      )}

      {/*ON ICP Edit This will be the slider that shows*/}
      {this.props.viewModel[answer].minPointId >= 1 && (
        <Range
          defaultValue={[
            this.props.viewModel[answer].minPointId,
            this.props.viewModel[answer].maxPointId,
          ]}
          min={1}
          max={maxPoints}
          marks={marks}
          // dots
          onAfterChange={async (pointsOnLine) => {
            switch (question) {
              case 8:
                this.props.viewModel.setProductServiceWorth(pointsOnLine);
                break;

              default:
                this.props.viewModel.setEmployeeSize(pointsOnLine);
                break;
            }
            await this.props.viewModel.DelayedAutoSave();
          }}
          disabled={isOverview}
        />
      )}
      {question > 0 && this.QuestionSelectorTypeSliderComment(question, answer, isOverview)}
    </div>
  }

  public QuestionSelectorTypeSliderComment(question: number, answer: string, isOverview = false) {
    return (
      <div className="sliderComment subHeading">
        <p>{textConstants.generalText.addComment}</p>
        <Neo.Textbox
          className="textboxICP mt-2 col-12"
          bind={this.props.viewModel[answer].meta.comment}
          input={{ rows: 6 }}
          onMouseDown={() => {
            if (this.props.viewModel[answer].comment !== null) {
              this.props.viewModel.initialComparisonValue = this.props.viewModel[answer].comment!.toString();
            } else this.props.viewModel.initialComparisonValue = '';
          }
          }
          onBlur={async () => {
            if (this.props.viewModel[answer].comment !== null) {
              this.props.viewModel.finalComparisonValue = this.props.viewModel[answer].comment!.toString();
            } else
              this.props.viewModel.finalComparisonValue = '';

            await this.props.viewModel.OnElementBlur();
          }}
          placeholder={textConstants.generalText.placeHolderTypeHereText}
          disabled={isOverview}
        />
      </div>)
  }

  public QuestionSelectorTypeDropDown(question: number, answer: string, isOverview = false) {
    const isQuestionTwo = 2.5 === question

    const list = this.props.viewModel[answer] !== null &&
      this.props.viewModel[answer].map((buyingCenter: any, index: number) => {
        if (buyingCenter !== undefined) {
          return {
            id: index,
            value: buyingCenter.value,
            label: buyingCenter.label,
          };
        } else {
          return null;
        }
      });

    const deleteItem = (index: number) => {
      const newList = list.filter((item: { value: any; }) => item.value !== index);
      this.props.viewModel[answer] = newList;
    }

    const DragComponent = ({ item }: any) => {
      return (
        <StyledOrganizationItem>
          <div>
            <DragIcon />
            <span>{item.label}</span>
          </div>
          <div
            onClick={() =>
              deleteItem(item.value)
            }
            className="delete-icon"
          >
            <DeleteIcon />
          </div>
        </StyledOrganizationItem>
      )
    }

    const setSortableList = async (items: any) => {
      this.props.viewModel[answer] = items

      await this.props.viewModel.DelayedAutoSave();
    }

    const handleChange = async (e: { value: any; label: any; }, isMulti: boolean) => {

      const newItem = {
        value: e.value,
        label: e.label,
      };

      this.props.viewModel.handleFunctionChange(answer, newItem, isMulti);

      await this.props.viewModel.DelayedAutoSave();
    }

    const specificQuestionSelectedChange = async (e: any) => {
      isQuestionTwo ?
        this.props.viewModel.handleBuyingCenterChange(answer, e, isQuestionTwo)
        : handleChange(e, isQuestionTwo);

      // This save needs a bit of a delay because it may be causing duplicates
      await this.props.viewModel.DelayedAutoSave();
    }

    return <>
      <CustomSelect
        className={`p-0 mt-2 ${this.props.viewModel.validationChecks(question) && "redBorder"}`}
        classNamePrefix="react-select"
        value={
          this.props.viewModel[answer] !== null &&
          this.props.viewModel[answer].map((buyingCenter: any) => {
            if (buyingCenter !== undefined) {
              return {
                value: buyingCenter.value,
                label: buyingCenter.label,
              };
            } else {
              return null;
            }
          })
        }
        isClearable={false}
        isMulti={isQuestionTwo}
        onChange={async (e: any) => { await specificQuestionSelectedChange(e) }}
        onKeyDown={this.handleKeyDownForContinue}
        options={this.props.viewModel.buyingCenterOptions}
        noOptionsMessage={() => "List is empty"}
        placeholder={textConstants.generalText.dropDownPlaceholderICP}
        isDisabled={isOverview}
      />
      {!isQuestionTwo &&
        <StyledOrganizationList>
          <SortableList
            isDisabled={isOverview}
            items={list}
            // @ts-ignore
            setItems={async (items: any) => {
              setSortableList(items)
            }}
            component={DragComponent}
          />
        </StyledOrganizationList>
      }
      {this.props.viewModel.validationChecks(question) &&
        <div className="validationRed validationFontSize">{textConstants.messageText.validationMessage.questionRequired}</div>}</>
  }

  public Question1Additional(isOverview = false) {
    return <>
      {
        this.props.viewModel.answer1.answer <= 30 &&
        this.props.viewModel.answer1.answer > 0 && //Put here so that it hides initially
        !this.props.viewModel.saveFileSuccess && (
          <div className="mt-5">
            <div className="lessThanDropbox">

              <div className="mb-3 subQuestionStyle">
                Have you confirmed your ICP with at least 30 Customers? If
                not:
              </div>
              <a
                className={"downloadExerciseTemplates"}
                onClick={() => this.props.viewModel.downloadTemplateFile()}
              >
                {textConstants.generalText.completeExercise}
                <Icon.Download size={16} />
              </a>

              <div className="mt-4">
                <Neo.FileContext
                  onFilesSelected={(e) => this.onDocumentSelected(e)}
                  allowedExtensions={[".docx", ".pdf"]}
                >
                  <Neo.FileDropArea multiple={false} disabled={isOverview}>
                    <p className="text-center uploadExerciseTemplates">
                      <Neo.FileUploadButton disabled={isOverview}>
                        <Icon.Upload />
                        {textConstants.generalText.uploadOrDragFile}
                      </Neo.FileUploadButton>
                    </p>
                  </Neo.FileDropArea>
                </Neo.FileContext>
              </div>

              {this.props.viewModel.validationChecks(1.2) && <div className="validationRed validationFontSize">{textConstants.messageText.validationMessage.questionRequired}</div>}
            </div>
          </div>
        )
      }
      {
        this.props.viewModel.answer1.answer <= 30 &&
        this.props.viewModel.saveFileSuccess && (
          <div className="mt-5 pb-5 row m-0">
            <div className="col-12 p-0">
              <div className="subQuestionStyle">
                30 Customers or Less?
              </div>
              <span className="padding-right-1">
                {this.props.viewModel.fileName}
              </span>

              <a className="mr-2 ml-2" onClick={() => { this.props.viewModel.downloadFile() }}>
                <Icon.Download />
              </a>
              {!isOverview &&
                <a className="mr-2 ml-2 " onClick={() => { this.props.viewModel.deleteFile() }}>
                  <Icon.Trash2 />
                </a>
              }
            </div>
          </div>
        )
      }</>

  }

  public QuestionSelectorTypeNumber(question: number, answer: string, isOverview = false) {
    return (
      <div className="w-100">
        <Neo.FormGroup
          suppressLabel={true}
          className="questionTextBox mt-3"
          placeholder={textConstants.generalText.placeHolderTypeHereText}
          bind={this.props.viewModel[answer].meta.answer}
          onMouseDown={() => {
            this.props.viewModel.initialComparisonValue = this.props.viewModel[answer].answer.toString();
          }}
          onBlur={async () => {
            this.props.viewModel.finalComparisonValue = this.props.viewModel[answer].answer.toString();
            await this.props.viewModel.OnElementBlur();
          }}
          onKeyDown={this.handleKeyDownForContinue}
          onKeyUp={(e) => {
            var key = e.key
            if (!isNumber(key)) {
              this.props.viewModel[answer].answer = this.props.viewModel[answer].answer
            }
          }}
          disabled={isOverview}
        />
      </div>);
  }

  public QuestionSelectorTypeBoxSelection(question: number, answer: string, isOverview = false) {

    let validationCheck: number[] = [];

    switch (question) {
      case 4:
        validationCheck = [4.1, 4.2];
        break;

      case 7:
        validationCheck = [7.1, 7.2];
        break;

      default:
        break;

    }

    return (<>
      <Neo.FormGroup
        className="mt-4"
        input={{ type: "checkbox" }}
        bind={this.props.viewModel.meta[`${answer}Box1`]}
        onBlur={async () => {
          // This save needs a bit of a delay because it may be causing duplicates
          await this.props.viewModel.DelayedAutoSave();
        }}
        size={24}
        disabled={isOverview}
      />
      <Neo.FormGroup
        input={{ type: "checkbox" }}
        bind={this.props.viewModel.meta[`${answer}Box2`]}
        onBlur={async () => {
          // This save needs a bit of a delay because it may be causing duplicates
          await this.props.viewModel.DelayedAutoSave();
        }}
        size={24}
        disabled={isOverview}
      ></Neo.FormGroup>
      <Neo.FormGroup
        input={{ type: "checkbox" }}
        bind={this.props.viewModel.meta[`${answer}Box3`]}
        onBlur={async () => {
          // This save needs a bit of a delay because it may be causing duplicates
          await this.props.viewModel.DelayedAutoSave();
        }}
        disabled={isOverview}
      />
      <Neo.FormGroup
        input={{ type: "checkbox" }}
        bind={this.props.viewModel.meta[`${answer}Box4`]}
        onBlur={async () => {
          // This save needs a bit of a delay because it may be causing duplicates
          await this.props.viewModel.DelayedAutoSave();
        }}
        size={24}
        disabled={isOverview}
      />
      <Neo.FormGroup
        input={{ type: "checkbox" }}
        bind={this.props.viewModel.meta[`${answer}Box5`]}
        onBlur={async () => {
          // This save needs a bit of a delay because it may be causing duplicates
          await this.props.viewModel.DelayedAutoSave();
        }}
        size={24}
        disabled={isOverview}
      />

      {/* Other  */}
      {this.props.viewModel[`${answer}Box5`] && (
        <Neo.Textbox
          className={`${this.props.viewModel.validationChecks(4.2) && "redBorder"} textboxICP`}
          label={""}
          bind={this.props.viewModel[answer].meta.otherString}
          input={{ rows: 6 }}
          onMouseDown={() => {
            this.props.viewModel.initialComparisonValue = this.props.viewModel[answer].otherString;
          }
          }
          size={24}
          onBlur={async () => {
            this.props.viewModel.finalComparisonValue = this.props.viewModel[answer].otherString;
            await this.props.viewModel.OnElementBlur();
          }}
          placeholder={textConstants.generalText.placeHolderStartTyping}
          disabled={isOverview}
        />
      )}
      {(this.props.viewModel.validationChecks(validationCheck[0]) || this.props.viewModel.validationChecks(validationCheck[1])) &&
        <div className="validationRed validationFontSize">{textConstants.messageText.validationMessage.questionRequired}</div>}
    </>);
  }

  public QuestionSelectorTypeName(isOverview = false) {
    return <>{/* Ideal Customer Profile (ICP) Name*/}
      <div className="row m-0">
        <div className="col-12 p-0">
          <span className="questionStyle"><span className="validationRed"></span>{textConstants.titleText.CustomerProfileName}</span>

          <Neo.Textbox
            className={`${this.props.viewModel.validationChecks(0) && "redBorder"} questionTextBox mt-3`}
            bind={this.props.viewModel.meta.profileName}
            placeholder={textConstants.generalText.placeHolderTypeHereText}
            onMouseDown={() => {
              this.props.viewModel.initialComparisonValue = this.props.viewModel.profileName;
            }}
            onBlur={async () => {
              this.props.viewModel.finalComparisonValue = this.props.viewModel.profileName;
              await this.props.viewModel.OnElementBlur();
            }}
            onKeyDown={this.handleKeyDownForContinue}
            disabled={(!this.props.viewModel.isComXUser && this.props.viewModel.customerProfileStatusId === this.props.viewModel.ICPCompleteStatusId) || isOverview}
          />
          {this.props.viewModel.validationChecks(0) &&
            <div className="validationRed validationFontSize">Please enter a name</div>}
        </div>
      </div>
    </>
  }

  private customerProfileOverviewScreen() {
    return <>
      <div className="row">
        <h4 className="col-11">{textConstants.titleText.CustomerProfileOverview}</h4>
        <div className="col-1 mt-auto mb-2" >
          <Icon.Download className="pull-right clickable"
            onClick={(e) => this.props.downloadProfile && this.props.downloadProfile(this.props.viewModel.customerProfileId, this.props.viewModel.profileName)} />
        </div>
      </div>
      <>
        <b>{textConstants.generalText.cannotEditICP}</b>
      </>
    </>
  }

  public QuestionSelector(question: number, isOverview = false) {

    let questionContent = <></>

    let questionNumber = question

    switch (questionNumber) {
      //Overview Page
      case -1: {
        questionContent =
          <>
            {this.customerProfileOverviewScreen()}
          </>
        break;
      }
      //ICP name
      case 0: {
        questionContent = <>
          {this.QuestionSelectorTypeName(isOverview)}
        </>
        break;
      }
      //Question 1
      case 1:
        questionContent = <>
          {this.QuestionSelectorTypeNumber(questionNumber, "answer1", isOverview)}
          {this.Question1Additional(isOverview)}
        </>;
        break;
      //Question 2
      case 2:
        let marks = this.props.viewModel.numberOfEmployees

        let maxPoints = 8;

        questionContent = <>
          <div className="pt-3 pb-4 subHeading">
            <div className="row m-0">{this.props.viewModel.setQuestionText(2.1)}
              <span className="pl-2">{marks[this.props.viewModel.answer2.minPointId]} - {marks[this.props.viewModel.answer2.maxPointId]}</span>
            </div>
            {this.QuestionSelectorTypeSlider(0, "answer2", marks, maxPoints, isOverview)}
          </div>

          <div className="pt-5 pb-1 subHeading">{this.props.viewModel.setQuestionText(2.2)}
            {this.QuestionSelectorTypeText(2.2, "answer3", isOverview)}</div>

          <div className="pt-3 pb-1 subHeading">{this.props.viewModel.setQuestionText(2.3)}
            {this.QuestionSelectorTypeText(2.3, "answer4", isOverview)}</div>

          <div className="pt-3 pb-1 subHeading">{this.props.viewModel.setQuestionText(2.4)}
            {this.QuestionSelectorTypeText(2.4, "answer5", isOverview)}</div>

          <div className="pt-3 pb-1 subHeading">{this.props.viewModel.setQuestionText(2.5)}
            {this.QuestionSelectorTypeDropDown(2.5, "answer6", isOverview)}</div>
        </>;
        break;

      //Question 3
      case 3: {
        questionContent = <>
          {this.QuestionSelectorTypeText(3, "answer7", isOverview)}
        </>
        break;
      }
      case 4: {
        //Question 4
        questionContent = <>
          {this.QuestionSelectorTypeBoxSelection(questionNumber, "answer8", isOverview)}
        </>
        break;
      }
      //Question 5
      case 5: {
        questionContent = <>
          {this.QuestionSelectorTypeText(questionNumber, "answer9", isOverview)}
        </>
        break;
      }
      //Question 6
      case 6: {
        questionContent = <>
          {this.QuestionSelectorTypeText(questionNumber, "answer10", isOverview)}
        </>
        break;
      }
      //Question 7
      case 7: {
        questionContent = <>
          {this.QuestionSelectorTypeBoxSelection(questionNumber, "answer11", isOverview)}
        </>
        break;
      }

      //Question 8
      case 8: {
        let marks = this.props.viewModel.productWorth

        let maxPoints = 11;

        questionContent = <>
          <div className="pt-3 pb-1 subHeading">
            <div className="row m-0">
              {textConstants.generalText.selectYourRange}
              <span className="pl-2">{marks[this.props.viewModel.answer12.minPointId]} - {marks[this.props.viewModel.answer12.maxPointId]}</span>
            </div>
          </div>
          {this.QuestionSelectorTypeSlider(questionNumber, "answer12", marks, maxPoints, isOverview)}
        </>
        break;
      }
      //Question 9
      case 9: {
        questionContent = <>
          {this.QuestionSelectorTypeText(questionNumber, "answer13", isOverview)}
        </>
        break;
      }
      //Question 10
      case 10: {
        questionContent = <>
          {this.QuestionSelectorTypeDropDown(questionNumber, "answer14", isOverview)}
        </>
        break;
      }
      //Question 11
      case 11: {
        questionContent = <>
          {this.QuestionSelectorTypeText(questionNumber, "answer15", isOverview)}
        </>
        break;
      }
      //Question 12
      case 12: {
        questionContent = <>
          {this.QuestionSelectorTypeText(questionNumber, "answer16", isOverview)}
        </>
        break;
      }
      //Question 13
      case 13: {
        questionContent = <>
          {this.QuestionSelectorTypeText(questionNumber, "answer17", isOverview)}
        </>
        break;
      }
      //Question 14
      case 14: {
        questionContent = <>
          {this.QuestionSelectorTypeText(questionNumber, "answer18", isOverview)}
        </>
        break;
      }
      //Question 15
      case 15: {
        questionContent = <>
          {this.QuestionSelectorTypeText(questionNumber, "answer19", isOverview)}
        </>
        break;
      }
      //Question 16
      case 16: {
        questionContent = <>
          {this.QuestionSelectorTypeText(questionNumber, "answer20", isOverview)}
        </>
        break;
      }
      //Question 17
      case 17: {
        questionContent = <>
          {this.QuestionSelectorTypeText(questionNumber, "answer21", isOverview)}
        </>
        break;
      }
      //Question 18
      case 18: {
        questionContent = <>
          {this.QuestionSelectorTypeText(questionNumber, "answer22", isOverview)}
        </>
        break;
      }
      //Question 19
      case 19: {
        questionContent = <>
          {this.QuestionSelectorTypeText(questionNumber, "answer23", isOverview)}
        </>
        break;
      }

      default: {
        questionContent = <></>
        break;
      }
    }

    return (
      <>
        <div className="headerText">{this.props.viewModel.setQuestionText(questionNumber)}</div>
        {questionContent}
      </>);
  }

  public navigateBackButton() {
    const { viewModel, back } = this.props
    let overviewSectionOffset = 0;

    // Need to take into account the additional step
    if ((viewModel.currentSection === 0 && !viewModel.isComplete) || (viewModel.isComplete && viewModel.currentSection === -1)) {
      back();
    }
    else if (viewModel.currentSection === 0 && viewModel.isComplete) {
      overviewSectionOffset = 1
    }

    viewModel.currentSection = Math.max(0 - overviewSectionOffset, viewModel.currentSection - 1);
    viewModel.customAuthService.onboardingFunctions.getRelevantSectionCode(viewModel.currentSection + 1)
    viewModel.UpdateNavigatorAfterSave();
  }

  public navigateContinueButton() {
    this.props.viewModel.currentSection = Math.min(this.props.viewModel.lastSection!, this.props.viewModel.currentSection + 1);
    this.props.viewModel.customAuthService.onboardingFunctions.getRelevantSectionCode(this.props.viewModel.currentSection + 1);
    this.props.viewModel.UpdateNavigatorAfterSave();
  }

  componentDidMount(): void {
    const { globalProps } = this.props.viewModel.customAuthService

    if (globalProps.showOnboardingSideMenu) {
      globalProps.showOnboardingHelpCenter = true;
    }
    this.isZendeskVisible = globalProps.isZendeskVisible;
    globalProps.isZendeskVisible = false;
  }

  componentWillUnmount(): void {
    const { globalProps } = this.props.viewModel.customAuthService

    globalProps.showOnboardingHelpCenter = false;
    globalProps.isZendeskVisible = this.isZendeskVisible;
  }

  public render() {

    return (
      <div className='customerProfileQuestionnaireView custom-scrollbar-expand'>
        <React.Fragment>
          <Neo.Form model={this.props.viewModel}
            validationDisplayMode={Validation.DisplayMode.AfterSubmit}
          >
            <>
              {/* Dropdown Navigator */}
              <StyledNavigationContainer>
                <ScreenNavigator
                  steps={this.props.viewModel.navigatorSteps}
                  goToStep={(a: number) => { this.props.viewModel.currentSection = a - 1; this.props.viewModel.UpdateNavigatorAfterSave() }}
                  currentStepNumber={this.props.viewModel.currentSection + 1}
                />
              </StyledNavigationContainer>

              {/* Current Question */}
              <div className="ICPBody">
                <Neo.Card>
                  {this.props.viewModel.currentSection === -1 && this.props.viewModel.navigatorSteps.length > 0 &&
                    this.props.viewModel.navigatorSteps.map((x, key) => (
                      <div className="mb-5" key={key}>
                        <>{this.QuestionSelector(x.id - 1, true)}</>
                      </div>
                    ))
                  }
                  {!(this.props.viewModel.currentSection === -1 && this.props.viewModel.navigatorSteps.length > 0) &&
                    this.QuestionSelector(this.props.viewModel.currentSection)
                  }
                </Neo.Card>
              </div>

              {/* Nav Footer */}
              <div className={`row appFooter ${this.props.viewModel.customAuthService.onboardingFunctions.isOnboardingHelpCenterVisible() ? "helpCenterAllowance" : ""} 
                ${isMiniNav() ? this.props.viewModel.customAuthService.globalProps.isOnboarding ? "" : "miniNav" : ""}`}>
                {
                  <NavFooter
                    currentSection={this.props.viewModel.currentSection}
                    lastSection={this.props.viewModel.lastSection}
                    saveAsDraft={async () => await this.props.viewModel.saveAndNavigateBack(false, this.props.back)}
                    saveAndPublish={async () => await this.props.viewModel.saveAndNavigateBack(true, this.props.back)}
                    backButton={() => this.navigateBackButton()}
                    continueButton={() => this.navigateContinueButton()
                    } />
                }
              </div>
            </>

            {/* Invalid Data modal */}
            <Neo.Modal
              title="Validation Errors"
              show={this.props.viewModel.showInvalidDataModal}
              onClose={() => (this.props.viewModel.showInvalidDataModal = false)}
              closeButton={{
                text: `${textConstants.buttonText.Ok}`,
                variant: "light",
              }}
            >
              {this.props.viewModel.invalidDataMessage
                .split("\n")
                .map((item, i) => {
                  return <p key={i}>{item}</p>;
                })}
            </Neo.Modal>
          </Neo.Form>
        </React.Fragment>
      </div >
    );
  }
}