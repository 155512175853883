import CountryView from './Views/Maintenance/CountryView';
import AccountStatusView from './Views/Maintenance/AccountStatusView';
import CompanySizeView from './Views/Maintenance/CompanySizeView';
import FunctionTypeView from './Views/Maintenance/FunctionTypeView';
import IndustryView from './Views/Maintenance/IndustryView';
import SubIndustryView from './Views/Maintenance/SubIndustryView';
import IndustrySubIndustryView from './Views/Maintenance/IndustrySubIndustryView';
import ReProspectingStatusView from './Views/Maintenance/ReProspectingStatusView';
import RoleView from './Views/Maintenance/RoleView';
import SourceTypeView from './Views/Maintenance/SourceTypeView';
import TargetMarketAccountStatusView from './Views/Maintenance/TargetMarketAccountStatusView';
import TargetMarketStatusView from './Views/Maintenance/TargetMarketStatusView';
import TargetMarketTypeView from './Views/Maintenance/TargetMarketTypeView';
import { IAppRoute } from './Routes';
import UpdateMasterAccountsView from './Views/MasterAccounts/UpdateMasterAccountsView';
import TargetMarketAccountsView from './Views/TargetMarketAccounts/TargetMarketAccountsView';
import AddClientListView from './Views/TargetMarketAccounts/AddClientListView';
import TargetMarketView from './Views/TargetMarket/TargetMarketView';
import AddEditTargetMarketView from './Views/TargetMarket/AddEditTargetMarketView';
import UpdateTargetMarketsView from './Views/TargetMarket/UpdateTargetMarketsView';
import ProspectingView from './Views/Prospecting/ProspectingView';
import ManagementFunctionView from './Views/Maintenance/ManagementFunctionView';
import ManagementRoleView from './Views/Maintenance/ManagementRoleView';
import LanguageView from './Views/Maintenance/LanguageView';
import TargetMarketCampaignsView from './Views/TargetMarket/TargetMarketExternalView';
import DomainVariationView from './Views/Maintenance/DomainVariationView';
import BlacklistCampaignsView from './Views/Client/BlacklistExternalView';
import TargetMarketAccountTabView from './Views/Maintenance/TargetMarketAccountTabView';
import TargetMarketAccountTabStatusView from './Views/Maintenance/TargetMarketAccountTabStatusView';
import ComXConfigurationView from './Views/Maintenance/ComXConfigurationView';
import ClientCampaignMessagesView from './Views/CampaignMessages/ClientCampaignMessageView';
import InformationManagementView from './Views/Maintenance/InformationManagementView';
import CampaignEmailSignatureView from './Views/CampaignMessages/CampaignEmailSignatureView';
import ClientCampaignMessageCampaignsView from './Views/CampaignMessages/CCMExternalView';
import CampaignMessageView from './Views/CampaignMessages/CampaignMessageView';
import CampaignMessagesMaintenanceView from './Views/Maintenance/CampaignMessagesMaintenanceView';
import BatchReviewCampaignsView from './Views/Client/BatchReviewExternalView';
import CustomerProfilesCampaignsView from './Views/IdealCustomerProfiles/CustomerProfilesExternalView';
import CustomerProfileStatusView from './Views/Maintenance/CustomerProfileStatusView';
import BuyingCentersView from './Views/Maintenance/BuyingCentersView';
import { textConstants as tc } from './common/textConstants';
import OnboardingSideBarMaintenanceView from './Views/Maintenance/Onboarding/OnboardingSideMenuMaintenanceView';
import OnboardingPopupMaintenanceView from './Views/Maintenance/Onboarding/OnboardingPopupMaintenanceView';
import CustomerProfileQuestionView from './Views/Maintenance/CustomerProfileQuestionView';
import DashboardCardView from './Views/Maintenance/Dashboard/DashboardCardView';
import InvalidReasonView from './Views/Maintenance/InvalidReasonView';
import DashboardReportConfigView from './Views/Maintenance/DashboardReportConfigView';
import BackgroundJobConfigView from './Views/Maintenance/BackgroundJobConfigView';
import ActionListView from './Views/ActionList/ActionListView';
import ActionListConfigurationsView from 'Views/Maintenance/ActionListConfigurationsView';
import IndustriesVersionView from 'Views/Maintenance/IndustriesVersionView';
import DemoClientDataView from 'Views/Maintenance/DemoClientDataView';
import OnboardingMaintenanceView from 'Views/Maintenance/Onboarding/OnboardingMaintenanceView';
import IROIndustryMappingView from 'Views/Maintenance/IROIndustryMappingView';

export const baseMaintenanceRouteInfo = {
    name: tc.Routes.Maintenance,
    path: '/Maintenance',
}

export const maintenanceRoutes = [
    {
        name: "Account Status",
        path: '/Maintenance/AccountStatuses',
        component: AccountStatusView,
        icon: "comment",
        allowAnonymous: false
    },
    {
        name: "Campaign Message",
        path: '/Maintanence/CampaignMessagesMaintenance',
        component: CampaignMessagesMaintenanceView,
        icon: "envelope",
        allowAnonymous: false
    },
    {
        name: "Company Size",
        path: "/Maintenance/CompanySize",
        component: CompanySizeView,
        icon: "user",
        allowAnonymous: false
    },
    {
        name: "Configuration",
        path: '/Maintenance/Configurations',
        component: ComXConfigurationView,
        icon: "tablets",
        allowAnonymous: false
    },
    {
        name: "Reporting",
        path: '/Maintenance/Reporting',
        component: DashboardReportConfigView,
        icon: "chart-simple",
        allowAnonymous: false
    },
    {
        name: "Background Jobs",
        path: '/Maintenance/BackgroundJobs',
        component: BackgroundJobConfigView,
        icon: "clock",
        allowAnonymous: false
    },
    {
        name: "Country",
        path: '/Maintenance/Countries',
        component: CountryView,
        icon: "flag",
        allowAnonymous: false
    },
    {
        name: "Dashboard Card",
        path: '/Maintenance/DashboardCard',
        component: DashboardCardView,
        icon: "tachometer-alt",
        allowAnonymous: false
    },
    {
        name: "Domain Variation",
        path: '/Maintenance/DomainVariations',
        component: DomainVariationView,
        icon: "globe",
        allowAnonymous: false
    },
    {
        name: "Function Type",
        path: "/Maintenance/FunctionType",
        component: FunctionTypeView,
        icon: "hdd",
        allowAnonymous: false
    },
    {
        name: "Ideal Customer Profile Question",
        path: '/Maintenance/ICP',
        component: CustomerProfileQuestionView,
        icon: "question",
        allowAnonymous: false
    },
    {
        name: "Ideal Customer Profile Status",
        path: '/Maintanence/IdealCustomerProfileMaintenance',
        component: CustomerProfileStatusView,
        icon: "bullseye",
        allowAnonymous: false
    },
    {
        name: "Industry",
        path: "/Maintenance/Industry",
        component: IndustryView,
        icon: "industry",
        allowAnonymous: false
    },
    {
        name: "Industry Sub-Industry",
        path: "/Maintenance/IndustrySubIndustry",
        component: IndustrySubIndustryView,
        icon: "newspaper",
        allowAnonymous: false
    },
    {
        name: "Industries Version",
        path: "/Maintenance/IndustriesVersion",
        component: IndustriesVersionView,
        icon: "code-branch",
        allowAnonymous: false
    },
    {
        name: "Information Management",
        path: '/Maintenance/InformationManagement',
        component: InformationManagementView,
        icon: "info-circle",
        allowAnonymous: false
    },
    {
        name: "Language",
        path: '/Maintenance/Languages',
        component: LanguageView,
        icon: "language",
        allowAnonymous: false
    },
    {
        name: "Management Function",
        path: '/Maintenance/ManagementFunctions',
        component: ManagementFunctionView,
        icon: "users-cog",
        allowAnonymous: false
    },
    {
        name: "Buying Center",
        path: '/Maintanence/BuyingCenter',
        component: BuyingCentersView,
        icon: "building",
        allowAnonymous: false
    },
    {
        name: "Management Role",
        path: '/Maintenance/ManagementRoles',
        component: ManagementRoleView,
        icon: "user-tie",
        allowAnonymous: false
    },
    {
        name: "Onboarding",
        path: '/Maintenance/OnboardingMaintenance',
        component: OnboardingMaintenanceView,
        icon: "bell",
        allowAnonymous: false
    },
    {
        // The contents of this view will eventually be moved into OnboardingMaintenanceView. I'm leaving it here for now for reference sake.
        name: "Onboarding Side Menu",
        path: '/Maintenance/OnboardingSideMenu',
        component: OnboardingSideBarMaintenanceView,
        icon: "graduation-cap",
        allowAnonymous: false
    },
    {
        // The contents of this view will eventually be moved into OnboardingMaintenanceView. I'm leaving it here for now for reference sake.
        name: "Popups",
        path: '/Maintenance/Popups',
        component: OnboardingPopupMaintenanceView,
        icon: "bell",
        allowAnonymous: false
    },
    {
        name: "Invalid Reasons",
        path: '/Maintenance/InvalidReasons',
        component: InvalidReasonView,
        icon: "xmarks-lines",
        allowAnonymous: false
    },
    {
        name: "Action List Configuration",
        path: '/Maintenance/ActionList',
        component: ActionListConfigurationsView,
        icon: "comments",
        allowAnonymous: false
    },
    {
        name: "Re-Prospecting Status",
        path: "/Maintenance/ReprospectingStatus",
        component: ReProspectingStatusView,
        icon: "address-card",
        allowAnonymous: false
    },
    {
        name: "Role",
        path: "/Maintenance/Role",
        component: RoleView,
        icon: "plus-square",
        allowAnonymous: false
    },
    {
        name: "Source Type",
        path: "/Maintenance/SourceTypeView",
        component: SourceTypeView,
        icon: "file-code",
        allowAnonymous: false
    },
    {
        name: "Sub-Industry",
        path: "/Maintenance/SubIndustry",
        component: SubIndustryView,
        icon: "hospital",
        allowAnonymous: false
    },
    {
        name: "Target Market Account Status",
        path: "/Maintenance/TargetMarketAccountStatus",
        component: TargetMarketAccountStatusView,
        icon: "file-image",
        allowAnonymous: false
    },
    {
        name: "Target Market Account Tab",
        path: '/Maintenance/TargetMarketAccountTabs',
        component: TargetMarketAccountTabView,
        icon: "indent",
        allowAnonymous: false
    },
    {
        name: "Target Market Account Tab Status",
        path: '/Maintenance/TargetMarketAccountTabStatuses',
        component: TargetMarketAccountTabStatusView,
        icon: "cog",
        allowAnonymous: false
    },
    {
        name: "Target Market Status",
        path: "/Maintenance/TargetMarketStatus",
        component: TargetMarketStatusView,
        icon: "comment-dots",
        allowAnonymous: false
    },
    {
        name: "Target Market Type",
        path: "/Maintenance/TargetMarketType",
        component: TargetMarketTypeView,
        icon: "check-square",
        allowAnonymous: false
    },
    {
        name: "Demo Client Data",
        path: '/Maintenance/DemoClientData',
        component: DemoClientDataView,
        icon: "file",
        allowAnonymous: false
    },
    {
        name: "IRO Industries Mapping",
        path: "/Maintenance/IROIndustriesMapping",
        component: IROIndustryMappingView,
        icon: "shuffle",
        allowAnonymous: false
    },

] as IAppRoute[];

export const updateMasterAccountRoute =
    {
        name: "Update Master Accounts",
        path: '/UpdateMasterAccounts',
        component: UpdateMasterAccountsView,
        icon: "file",
        allowAnonymous: false,
    } as IAppRoute;

export const targetMarketRoute = {
    name: tc.Routes.TargetMarketsSpaced,
    path: '/TargetMarkets',
    icon: "flag",
    className: "targetMarketMenu",
    component: TargetMarketView,
    allowAnonymous: false,
};

export const clientCampaignMessagesRoute = {
    name: tc.Routes.ClientCampaignMessagesSpaced,
    path: '/CampaignMessages',
    icon: "envelope",
    component: ClientCampaignMessagesView,
    allowAnonymous: false,
};

export const targetMarketAccountRoute =
    {
        name: tc.Routes.TargetMarketAccountsSpaced,
        path: '/TargetMarketAccounts',
        component: TargetMarketAccountsView,
        icon: "file",
        allowAnonymous: false
    } as IAppRoute;

export const addEditTargetMarketRoute =
    {
        name: tc.Routes.AddTargetMarketSpaced,
        path: '/AddEditTargetMarket',
        component: AddEditTargetMarketView,
        allowAnonymous: false,
    } as IAppRoute;

export const addClientListRoute =
    {
        name: tc.Routes.AddClientListSpaced,
        path: '/AddClientList',
        component: AddClientListView,
        icon: "file",
        allowAnonymous: false
    } as IAppRoute;

export const updateTargetMarketsRoute =
    {
        name: tc.Routes.UpdateTargetMarketsSpaced,
        path: '/UpdateTargetMarkets',
        component: UpdateTargetMarketsView,
        icon: "file",
        allowAnonymous: false,
    } as IAppRoute;

export const prospectingRoute =
    {
        name: tc.Routes.Prospecting,
        path: "/Prospecting",
        component: ProspectingView,
        allowAnonymous: false
    } as IAppRoute;

export const targetMarketCampaignsRoute = {
    name: tc.Routes.TargetMarketsSpaced,
    path: '/Campaigns',
    icon: "flag",
    component: TargetMarketCampaignsView,
    allowAnonymous: false,
};

export const idealCustomerProfilesRoute = {
    name: tc.Routes.IdealCustomerProfileSpaced,
    path: '/IdealCustomerProfile',
    icon: "bullseye",
    component: CustomerProfilesCampaignsView,
    allowAnonymous: false,
};

export const blacklistCampaignsRoute = {
    name: tc.Routes.Blacklist,
    path: '/Blacklist',
    icon: "ban",
    component: BlacklistCampaignsView,
    allowAnonymous: false,
};

export const batchReviewCampaignsRoute = {
    name: tc.Routes.BatchReviewSpaced,
    path: '/BatchReview',
    icon: "clipboard-check",
    component: BatchReviewCampaignsView,
    allowAnonymous: false,
};

export const campaignMessagesCampaignsRoute = {
    name: tc.Routes.CampaignMessagesSpaced,
    path: '/CampaignMessages',
    icon: "envelope",
    component: ClientCampaignMessageCampaignsView,
    allowAnonymous: false,
};

export const actionListRoute = {
    name: tc.Routes.ActionList,
    path: '/ActionList',
    icon: "check-square",
    component: ActionListView,
    allowAnonymous: false,
};

export const addEditCampaignEmailSignatureRoute =
    {
        name: tc.Routes.AddEmailSignatureSpaced,
        path: '/AddEditEmailSignature',
        component: CampaignEmailSignatureView,
        allowAnonymous: false,
    } as IAppRoute;

export const addEditCampaignMessageRoute =
    {
        name: "Add/Edit Campaign Signature",
        path: '/addEditCampaignMessage',
        component: CampaignMessageView,
        allowAnonymous: false,
    } as IAppRoute;