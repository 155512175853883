import { Neo } from "@singularsystems/neo-react"
import { observer } from "mobx-react"
import React from "react"
import ReminderVM from "./ReminderVM";
import Button from "Components/Button/Button";
import { textConstants } from "common/textConstants";
import { observable } from "mobx";

interface IReminderForm {
  viewModel: ReminderVM;
  saveForm: () => void;
  cancelForm: () => void;
}

// Reminder Form when adding a new reminder or editing an existing one.
@observer
export default class ReminderForm extends React.Component<IReminderForm> {

  public render() {
    const { viewModel, cancelForm, saveForm } = this.props

    return (
      <div className="ReminderForm">
        <Neo.Card>

          {/* Title section of the Reminder Form */}
          <Neo.FormGroup
            noValidate
            label={`${textConstants.Reminders.Title}*`}
            bind={viewModel.reminderForm.meta.title}
          />

          {/* Validation */}
          <div className="pb-3 reminder-validation-failure">{!viewModel.isValidInput && viewModel.validationText()}</div>

          {/* Description Title */}
          <div className="reminder-form-label pb-2">{textConstants.Reminders.Description}</div>

          {/* Description */}
          <Neo.Textbox
            bind={viewModel.reminderForm.meta.description}
            className="mb-3"
            input={{ rows: 3 }}
          />

          {/* Due Date */}
          <div className="pb-3">
            <Neo.FormGroup
              label={textConstants.Reminders.dueDate}
              bind={viewModel.reminderForm.meta.time}
              dateProps={{ formatString: textConstants.dateformat.format3, minDate: new Date() }}
            />
          </div>

          {/* Button Groups */}
          <div className="d-flex justify-content-between form-button-group">
            <Button variant="outlined" buttonType="primary" size="small" marginNone fillAvailable onClick={() => cancelForm()}>
              {textConstants.buttonText.Cancel}
            </Button>

            <Button variant="contained" buttonType="primary" size="small" marginNone fillAvailable onClick={() => saveForm()}>
              {textConstants.buttonText.SaveReminder}
            </Button>
          </div>
        </Neo.Card>
      </div>
    )
  }
}