import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';
import ActionListConfiguration from 'Models/ActionList/ActionListConfiguration';

export interface IActionListConfigurationsApiClient extends Data.IUpdateableApiClient<ActionListConfiguration, number> {

}

@injectable()
export default class ActionListConfigurationsApiClient extends Data.UpdateableApiClient<ActionListConfiguration, number> implements IActionListConfigurationsApiClient {

  constructor(config = AppService.get(Types.Config)) {
    super(`${config.apiPath}/ActionListConfigurations`);
  }
}