import { injectable } from 'inversify';
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../Services/AppService';

export interface IOnboardingStepIntroductionApiClient extends Data.IUpdateableApiClient<OnboardingStepIntroductionApiClient, number> {

}

@injectable()
export default class OnboardingStepIntroductionApiClient extends Data.UpdateableApiClient<OnboardingStepIntroductionApiClient, number> implements IOnboardingStepIntroductionApiClient {

    constructor(config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/OnboardingStepIntroductions`);
    }
}