import { injectable } from 'inversify';
import { Misc, SignalR, AppServices, Network } from '@singularsystems/neo-core';
import { Types } from '../../Services/AppService';
import DashboardAccessLookup from '../../Models/BatchReview/DashboardLookup';
import PlatformSearchLookup from '../../Models/Scraping/PlatformSearchLookup';
import TargetMarketLookup from '../../Models/TargetMarket/TargetMarketLookup';
import UploadNotificationLookup from '../../Models/UploadNotificationLookup';
import UserMenuAccessLookup from '../../Views/InvitedUsers/LookUps/UserMenuAccessLookup';
import AssignedRolesChange from '../../Authorisation/Models/Hubs/AssignedRolesChange';
import MembershipChange from '../../Authorisation/Models/Hubs/MembershipChange';
import ClientCampaignQueryLookup from '../../Models/Dashboard/ClientCampaignQueryLookup';
import OnboardingMeetingLookup from 'Models/Onboarding/OnboardingMeetingLookup';
import ResponseLookup from 'Models/ActionList/ResponseLookup';

export interface IComXHub extends SignalR.IHubBase {
    readonly UpdateDashboardAccess: SignalR.IHubReceiver<DashboardAccessLookup>;
    readonly UpdateSearchStatus: SignalR.IHubReceiver<PlatformSearchLookup>;
    readonly CalculationsChanged: SignalR.IHubReceiver<TargetMarketLookup[]>;
    readonly NotifyUploadUser: SignalR.IHubReceiver<UploadNotificationLookup>;
    readonly UpdateUserMenuAccess: SignalR.IHubReceiver<UserMenuAccessLookup>;
    readonly UpdateClientCampaignsData: SignalR.IHubReceiver<ClientCampaignQueryLookup>;
    readonly UpdateCalendlyMeeting: SignalR.IHubReceiver<OnboardingMeetingLookup>;
    readonly RefreshClientCampaignMessages: SignalR.IHubReceiver<number>;
    readonly UpdateResponse: SignalR.IHubReceiver<ResponseLookup>;

    /** Called when a user is added or removed from a group. */
    readonly membershipChanged: SignalR.IHubReceiver<MembershipChange>;

    /** Called when a role is added or removed from a group. */
    readonly assignedRoleChanged: SignalR.IHubReceiver<AssignedRolesChange>;

    /** Called when a user group (including all its memberships and roles) is removed. */
    readonly userGroupRemoved: SignalR.IHubReceiver<number>;

    /** Subscribe to a group. */
    subscribeToGroup(userGroupId: number) : Promise<void>;

}

@injectable()
export class ComXHub extends SignalR.HubBase implements IComXHub {

    constructor(config = Misc.Globals.appService.get(Types.Config),
        connectionManager = Misc.Globals.appService.get(AppServices.NeoTypes.Core.ConnectionManager)) {
        super(config.apiPath + "/ComXHub");

        connectionManager.registerConnection(this, "ComX Hub", Network.ConnectionType.SignalR);

        this.onDisconnected.subscribe(() => {
            console.info("Hub Connection disconnected, try reconnecting");
            
            this.connectIfAuthenticated()
        });
    }
   
    // User's Roles Hub
    public readonly membershipChanged = this.registerReceiver<MembershipChange>("MembershipChanged");

    public readonly assignedRoleChanged = this.registerReceiver<AssignedRolesChange>("AssignedRoleChanged");

    public readonly userGroupRemoved = this.registerReceiver<number>("UserGroupRemoved");

    public subscribeToGroup(userGroupId: number) : Promise<void> {
        console.info("Subscribing to user group");
        return this.invoke("SubscribeToGroup", userGroupId);
    }

    // ComX Hub
    public readonly UpdateDashboardAccess = this.registerReceiver<DashboardAccessLookup>("UpdateDashboardAccess");

    public readonly UpdateSearchStatus = this.registerReceiver<PlatformSearchLookup>("UpdateSearchStatus");

    public readonly CalculationsChanged = this.registerReceiver<TargetMarketLookup[]>("ProgressCalculationsDoneCalculating");

    public readonly NotifyUploadUser = this.registerReceiver<UploadNotificationLookup>("NotifyUploadUser");

    public readonly UpdateUserMenuAccess = this.registerReceiver<UserMenuAccessLookup>("UpdateUserMenuAccess");

    public readonly UpdateClientCampaignsData = this.registerReceiver<ClientCampaignQueryLookup>("UpdateClientCampaignsData");

    public readonly UpdateCalendlyMeeting = this.registerReceiver<OnboardingMeetingLookup>("UpdateCalendlyMeeting");

    public readonly RefreshClientCampaignMessages = this.registerReceiver<number>("RefreshClientCampaignMessagesAsync");

    public readonly UpdateResponse = this.registerReceiver<ResponseLookup>("UpdateResponse");
}