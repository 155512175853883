import { observer } from 'mobx-react';
import { Prompt } from 'react-router-dom';
import { textConstants } from '../common/textConstants';
import React from 'react';

interface IPrompProps {
    showPrompt: boolean,
    showConfirm: boolean | null,
    backButtonPath: string,
    runFunction?: () => void,
}

@observer
export class UnsavedChanges extends React.Component<IPrompProps, any> {
    get isMounted() {
        return this._isMounted
    }
    
    set isMounted(v : boolean) {
        this._isMounted = v;
    }
    
    _isMounted = false;

    constructor(props: any) {
        super(props)
        this.state = {
            showPrompt: false
        };
        
        this._isMounted = false;
    }
    private reloadValue : boolean | null = null
    componentDidMount() {
        this.isMounted = true;
        this.reloadValue = this.props.showConfirm
        window.addEventListener('beforeunload', this.beforeunload.bind(this));
    }
    componentWillUnmount() {
        this.isMounted = false;

        if (this.props.runFunction && !this.props.showPrompt) {
            
            this.props.runFunction();
            return;
        }

        window.removeEventListener('beforeunload', this.beforeunload.bind(this));
    }

    beforeunload(e: any) {
        if (this.isMounted && this.props.runFunction) {
            this.props.runFunction();
            e.returnValue = false;
            return;
        }

        if (this.isMounted && this.props.showPrompt) {
            e.returnValue = this.props.showConfirm;
        }
    }

    showWarning(location: any){
        var currentWindow = window.location.href.substr(window.location.href.lastIndexOf("/"))
        if(location.pathname.includes(this.props.backButtonPath) || !currentWindow.includes(location.pathname)){
            return  textConstants.messageText.validationMessage.UnSaveChanges
        }
      return true
    }
    render() {
        return (
            <div>
                { <Prompt when={this.props.showPrompt} message={(location: any) =>this.showWarning(location)} />}
            </div>

        )
    }
}