import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class SubRegion extends ModelBase {

    public subRegionId: number = 0;

    public subRegionName: string = "";    

    public regionId: number = 0;    

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.subRegionName === "", textConstants.messageText.validationMessage.subRole);
    }

    public toString(): string {
        if (this.isNew || !this.subRegionName) {
            return "New Sub Region";
        } else {
            return this.subRegionName;
        }
    }
}