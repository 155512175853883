import { List } from "@singularsystems/neo-core";
import Axios, { AxiosPromise } from "axios";
import { injectable } from "inversify";
import { ResponseResolver } from "../common/utils";
import ApiResult from "../Models/ApiResult";
import SaveMeetingCommand from "../Models/Onboarding/Commands/SaveMeetingCommand";
import UpdatePopupDataCommand from "../Models/Onboarding/Commands/UpdatePopupDataCommand";
import UpdateSideMenuDataCommand from "../Models/Onboarding/Commands/UpdateSideMenuDataCommand";
import UpdateTutorialSeenCommand from "../Models/Onboarding/Commands/UpdateTutorialSeenCommand";
import OnboardingSideMenuDataLookup from "../Models/Onboarding/OnboardingSideMenuDataLookup";
import OnboardingStepCompletionLookup from "../Models/Onboarding/OnboardingStepCompletionLookup";
import UpdateOnboardingStepCommand from "../Models/Onboarding/UpdateOnboardingStepCommand";
import { AppService, Types } from "../Services/AppService";
import UpdateHasSeenClientOnboardingStepIntroductionCommand from "Models/Onboarding/Commands/UpdateHasSeenClientOnboardingStepIntroductionCommand";
import UpdateHasSeenOnboardingStepPopupCommand from "Models/Onboarding/Commands/UpdateHasSeenOnboardingStepPopupCommand";
import UpdateClientOnboardingStepIntroductionCommand from 'Models/Onboarding/Commands/UpdateClientOnboardingStepIntroductionCommand';

export interface IOnboardingApiClient {
    getOnboardingSideMenuData(): AxiosPromise<ApiResult>;
    getOnboardingPopupData(searchString?: String): AxiosPromise<ApiResult>;
    getOnboardingSideMenuStepData(stepNumber: number, stepName: string): AxiosPromise<OnboardingSideMenuDataLookup>;
    getUserLoginInfo(username: string): AxiosPromise<ApiResult>;
    getClientOnboardingSteps(clientId: number): AxiosPromise<ApiResult>;
    updateOnboardingClientStep(command: UpdateOnboardingStepCommand): AxiosPromise<ApiResult>;
    updateOnboardingSideMenuData(command: UpdateSideMenuDataCommand): AxiosPromise<ApiResult>;
    updateOnboardingPopupData(commandList: List<UpdatePopupDataCommand>): AxiosPromise<ApiResult>;
    updateTechnicalIntegrationIndicator(clientId: number): AxiosPromise<ApiResult>;
    IsOnboardingStepComplete(query: OnboardingStepCompletionLookup): AxiosPromise<ApiResult>;
    updateHasSeenClientOnboardingStepIntroduction(command: UpdateHasSeenClientOnboardingStepIntroductionCommand): AxiosPromise<ApiResult>;
    updateHasSeenOnboardingStepPopup(command: UpdateHasSeenOnboardingStepPopupCommand): AxiosPromise<ApiResult>
    IsCampaignMessageReady(clientId: number): AxiosPromise<ApiResult>;
    updateTutorialSeenIndicator(command: UpdateTutorialSeenCommand): AxiosPromise<ApiResult>;
    canCompleteOnboardingCheck(command: OnboardingStepCompletionLookup): AxiosPromise<ApiResult>;
    getOnboardingMeeting(clientId: number): AxiosPromise<ApiResult>;
    saveScheduledMeetingDetails(command: SaveMeetingCommand): AxiosPromise<ApiResult>;
    getMailToText(): AxiosPromise<string>;
    getHelpCenterTexts(): AxiosPromise<ApiResult>
    getHelpCenterSupportLinks(): AxiosPromise<ApiResult>
    getClientOnboardingStepIntroduction(clientId: number): AxiosPromise<ApiResult>
    updateClientOnboardingStepIntroduction(command: UpdateClientOnboardingStepIntroductionCommand): AxiosPromise<ApiResult>
    cancelCalendlyMeetingAsync(clientId: number): AxiosPromise<ApiResult>;
    completeClientOnboarding(clientId: number): AxiosPromise<ApiResult>;
}

@injectable()
export default class OnboardingApiClient implements IOnboardingApiClient {

    constructor(public config = AppService.get(Types.Config)) {
    }

    public getOnboardingSideMenuData(): AxiosPromise<ApiResult> {
        const apiPath = AppService.get(Types.Config).apiPath
        return Axios.get<ApiResult>(`${apiPath}/Onboarding/GetOnboardingSideMenuData`);
    }

    public getOnboardingPopupData(searchString?: String): AxiosPromise<ApiResult> {
        const apiPath = AppService.get(Types.Config).apiPath
        return Axios.get<ApiResult>(`${apiPath}/Onboarding/GetOnboardingPopupData/${searchString}`);
    }

    public getOnboardingSideMenuStepData(stepNumber: number, stepName: string): AxiosPromise<OnboardingSideMenuDataLookup> {
        const apiPath = AppService.get(Types.Config).apiPath

        return ResponseResolver<any>(Axios.get<ApiResult>(`${apiPath}/Onboarding/GetSpecificSideMenuStepData?stepNumber=${stepNumber}&stepName=${stepName}`));
    }

    public getUserLoginInfo(username: string): AxiosPromise<ApiResult> {
        return Axios.post<ApiResult>(`${this.config.apiPath}/Onboarding/GetUserLoginInfo`, { username: username });
    }

    public getClientOnboardingSteps(clientId: number): AxiosPromise<ApiResult> {
        return Axios.get(`${this.config.apiPath}/Onboarding/GetClientOnboardingSteps?clientId=${clientId}`);
    }

    public getHelpCenterSupportLinks(): AxiosPromise<ApiResult> {
        return Axios.get(`${this.config.apiPath}/Onboarding/GetHelpCenterSupportLinks`);
    }

    public getHelpCenterTexts(): AxiosPromise<ApiResult> {
        return Axios.get(`${this.config.apiPath}/Onboarding/GetHelpCenterTexts`);
    }

    public updateOnboardingClientStep(command: UpdateOnboardingStepCommand): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/UpdateClientOnboardingSteps`, command.toJSObject())
    }

    public updateOnboardingSideMenuData(command: UpdateSideMenuDataCommand): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/UpdateOnboardingSideMenuData`, command.toJSObject())
    }

    public updateOnboardingPopupData(commandList: List<UpdatePopupDataCommand>): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/UpdateOnboardingPopupData`, commandList.toJSArray())
    }

    public updateTechnicalIntegrationIndicator(clientId: number): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/UpdateTechnicalIntegration`, { clientId: clientId })
    }

    public updateHasSeenClientOnboardingStepIntroduction(command: UpdateHasSeenClientOnboardingStepIntroductionCommand): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/UpdateHasSeenClientOnboardingStepIntroduction`, command.toJSObject())
    }

    public updateHasSeenOnboardingStepPopup(command: UpdateHasSeenOnboardingStepPopupCommand): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/UpdateHasSeenOnboardingStepPopup`, command.toJSObject())
    }

    public IsOnboardingStepComplete(query: OnboardingStepCompletionLookup): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/IsOnboardingStepComplete`, query.toJSObject())
    }

    public canCompleteOnboardingCheck(command: OnboardingStepCompletionLookup): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/CanCompleteOnboarding`, command.toJSObject())
    }

    public IsCampaignMessageReady(clientId: number): AxiosPromise<ApiResult> {
        return Axios.get(`${this.config.apiPath}/Onboarding/IsCampaignMessageReady?clientId=${clientId}`)
    }

    public updateTutorialSeenIndicator(command: UpdateTutorialSeenCommand): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/UpdateTutorialSeenIndicator`, command.toJSObject())
    }

    public getOnboardingMeeting(clientId: number): AxiosPromise<ApiResult> {
        return Axios.get(`${this.config.apiPath}/Onboarding/GetOnboardingMeeting?clientId=${clientId}`)
    }

    public saveScheduledMeetingDetails(command: SaveMeetingCommand): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/SaveScheduledMeetingDetails`, command.toJSObject())
    }

    public getMailToText(): AxiosPromise<string> {
        return Axios.get(`${this.config.apiPath}/Onboarding/GetMailToText`)
    }

    public getClientOnboardingStepIntroduction(clientId: number): AxiosPromise<ApiResult> {
        return Axios.get(`${this.config.apiPath}/Onboarding/GetClientOnboardingStepIntroduction?clientId=${clientId}`)
    }

    public updateClientOnboardingStepIntroduction(command: UpdateClientOnboardingStepIntroductionCommand): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/UpdateClientOnboardingStepIntroduction`, command.toJSObject())
    }

    public cancelCalendlyMeetingAsync(clientId: number): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/CancelCalendlyMeetingAsync?clientId=${clientId}`)
    }
    
    public completeClientOnboarding(clientId: number): AxiosPromise<ApiResult> {
        return Axios.post(`${this.config.apiPath}/Onboarding/CompleteClientOnboarding/${clientId}`)
    }
}