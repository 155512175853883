import { Views } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React from "react";
import { textConstants } from "../../common/textConstants";
import { AppService, Types } from "../../Services/AppService";
import DashboardView from "../Home/DashboardView";
import CustomerProfileQuestionnaireView from "./CustomerProfileQuestionnaireView";
import CustomerProfileQuestionnaireVM from "./CustomerProfileQuestionnaireVM";
import CustomerProfilesExternalVM from "./CustomerProfilesExternalVM";
import CustomerProfilesVM from "./CustomerProfilesVM";
import CustomerProfilesView from "./CustomerProfilesView";

class CustomerProfilesCampaignsViewParams {
    public clientId = {};
    public iCPMode = {};
    public customerProfileId = {};
}

@observer
export default class CustomerProfilesCampaignsView extends Views.ViewBase<CustomerProfilesExternalVM, CustomerProfilesCampaignsViewParams>{

    private authorisationService = AppService.get(Types.Neo.Security.AuthorisationService);

    constructor(props: unknown) {
        super(textConstants.generalText.IdealCustomerProfileSpaced, CustomerProfilesExternalVM, props);
    }

    static params = new CustomerProfilesCampaignsViewParams();

    public updateBreadcrumb(currentScreenName: string) {
        this.viewParams.iCPMode.description = currentScreenName;
    }

    private campaignsMode = "";
    private icpId = 0;
    private isICPAdd = false;

    public async componentDidMount() {
        this.campaignsMode = "";
        this.viewModel.showICPQuestionnaire = false
        await this.viewModel.getClientId();
        this.viewParams.clientId.value = this.viewModel.searchCriteria.clientId
        this.viewParams.iCPMode.value = textConstants.titleText.IdealCustomerProfile
        this.viewModel.customAuthService.globalProps.isZendeskVisible = true;
    }

    public async componentDidUnMount() {
        this.campaignsMode = "";
        this.viewModel.showICPQuestionnaire = false
    }

    public async viewParamsUpdated() {

        let customerProfileId = this.viewParams.customerProfileId.asNullableInt();

        let clientId = this.viewParams.clientId.asNullableInt();

        // Fix naviagtion to ICP via breadcrumb. This also fixes navigation to ICP via side menu when already in ICP
        if (clientId === null) {
            this.campaignsMode = "";
            this.viewModel.showICPQuestionnaire = false
            await this.viewModel.getClientId();
            this.viewParams.clientId.value = this.viewModel.searchCriteria.clientId
            this.viewParams.iCPMode.value = textConstants.titleText.IdealCustomerProfile
            clientId = this.viewModel.searchCriteria.clientId
        }


        if (clientId) {

            // Add/Edit ICP
            if (((this.viewParams.iCPMode.value === "ICPQuestionnaireAdd" || this.campaignsMode === "ICPQuestionnaireAdd") && this.isICPAdd) ||
                (this.viewParams.iCPMode.value === "ICPQuestionnaireEdit" || this.campaignsMode === "ICPQuestionnaireEdit")) {
                this.viewModel.customerProfileQuestionnaireViewModel = new CustomerProfileQuestionnaireVM();
                this.viewModel.customerProfileQuestionnaireViewModel.numberOfEmployees = this.viewModel.customerProfilesViewModel.numberOfEmployees
                this.viewModel.customerProfileQuestionnaireViewModel.productWorth = this.viewModel.customerProfilesViewModel.productWorth
                this.viewModel.customerProfileQuestionnaireViewModel.isComXUser = false;
                await this.viewModel.getClientName(clientId);
                this.viewParams.clientId.value = clientId
                this.viewModel.customerProfileQuestionnaireViewModel.customerProfileId = (this.campaignsMode === "ICPQuestionnaireEdit" && this.viewModel.customerProfileId) ? this.viewModel.customerProfileId : customerProfileId ?? 0;
                this.viewModel.customerProfileQuestionnaireViewModel.clientId = this.viewModel.searchCriteria.clientId;
                this.viewModel.customerProfileQuestionnaireViewModel.isComXUser = false;
                this.viewModel.customerProfileQuestionnaireViewModel.initialise();
                this.viewModel.customerProfileQuestionnaireViewModel.fetchData();

                if (this.viewModel.customerProfileQuestionnaireViewModel.customerProfileId > 0) {
                    this.updateBreadcrumb(textConstants.generalText.EditProfileSpaced);
                    this.viewModel.customerProfileQuestionnaireViewModel.isEdit = true
                } else {
                    this.updateBreadcrumb(textConstants.generalText.AddProfileSpaced);
                    this.viewModel.customerProfileQuestionnaireViewModel.isEdit = false
                }

                this.viewModel.showICPQuestionnaire = true;
            }

            // ICP list
            if (this.campaignsMode === textConstants.titleText.IdealCustomerProfile || this.viewParams.iCPMode.value === textConstants.titleText.IdealCustomerProfile) {
                this.viewModel.showICPQuestionnaire = false;
                this.viewModel.customerProfilesViewModel = new CustomerProfilesVM();
                this.viewModel.customerProfilesViewModel.taskRunner = this.viewModel.taskRunner;
                this.viewModel.customerProfilesViewModel.searchCriteria.clientId = this.viewModel.searchCriteria.clientId;
                this.viewModel.customerProfilesViewModel.isComXUser = false;

                await this.viewModel.customerProfilesViewModel.getVideoURLs();
                this.viewModel.customerProfilesViewModel.setNoICPVideo();
                await this.viewModel.customerProfilesViewModel.pageManagerRefresh()

                await this.viewModel.getClientName(this.viewModel.searchCriteria.clientId);

                // Clear breadcrumb
                this.viewParams.iCPMode.description = ""
            }
        }
        else {
            this.navigation.navigateToView(DashboardView, { clientId: null });
        }

    }

    public render() {
        return (
            <React.Fragment>
                {/* Summarized CustomerProfilesExternalView using CustomerProfilesView */}
                {!this.viewModel.showICPQuestionnaire &&


                    <CustomerProfilesView
                        viewModel={this.viewModel.customerProfilesViewModel}
                        back={() => { this.viewParams.clientId.value = null }}
                        openCustomerProfileQuestionnaire={(customerProfileId) => {
                            if (customerProfileId) {
                                this.campaignsMode = "ICPQuestionnaireEdit"
                                this.viewModel.customerProfileId = customerProfileId
                                this.icpId = customerProfileId
                                this.isICPAdd = false;
                                this.viewParams.iCPMode.value = "ICPQuestionnaireEdit"
                                this.viewParams.customerProfileId.value = customerProfileId
                            }
                            else {
                                this.campaignsMode = "ICPQuestionnaireAdd"
                                this.icpId = -1
                                this.isICPAdd = true;
                                this.viewParams.iCPMode.value = "ICPQuestionnaireAdd"
                            }
                        }}
                    />
                }

                {/* Questionnaire View */}
                {this.viewModel.showICPQuestionnaire &&
                    <div>
                        <CustomerProfileQuestionnaireView
                            viewModel={this.viewModel.customerProfileQuestionnaireViewModel}
                            back={() => {
                                this.isICPAdd = false;
                                this.viewModel.showICPQuestionnaire = false;
                                this.viewParams.iCPMode.value = textConstants.titleText.IdealCustomerProfile;
                                this.viewParams.customerProfileId.value = null
                                this.campaignsMode = textConstants.titleText.IdealCustomerProfile
                            }}
                            downloadProfile={() => this.viewModel.customerProfilesViewModel.downloadICP(
                                this.viewModel.customerProfileQuestionnaireViewModel.customerProfileId,
                                this.viewModel.customerProfileQuestionnaireViewModel.profileName
                            )}
                        />
                    </div>}
            </React.Fragment>
        )
    }
}