import React from 'react';
import { Views } from '@singularsystems/neo-react';
import BlacklistCampaignsVM from './BlacklistExternalVM';
import { observer } from 'mobx-react';
import DashboardView from '../Home/DashboardView';
import ClientSettingsVM from './ClientSettingsVM';
import { textConstants } from '../../common/textConstants';
import TargetMarketView from '../TargetMarket/TargetMarketView';
import AddEditTargetMarketView from '../TargetMarket/AddEditTargetMarketView';
import TargetMarketAccountsView from '../TargetMarketAccounts/TargetMarketAccountsView';
import BlacklistedDomain from './BlacklistedDomain/BlacklistedDomain';
import { AppService } from '../../Services/AppService';
import Types from '../../Modules/AppTypes';

class BlacklistCampaignsViewParams {
  public clientId = {}
  public campaignsMode = {} // Blacklist
}

@observer
export default class BlacklistCampaignsView extends Views.ViewBase<BlacklistCampaignsVM, BlacklistCampaignsViewParams> {

  constructor(props: unknown) {
    super(textConstants.titleText.Blacklist, BlacklistCampaignsVM, props);
  }

  static params = new BlacklistCampaignsViewParams();

  onLeave() {
    this.viewModel.removeNotifications();
    return undefined;
  }

  public async componentDidMount() {
    await this.viewModel.getClientId()
    this.viewParams.clientId.value = this.viewModel.clientId
    this.viewParams.campaignsMode.value = textConstants.titleText.Blacklist
    this.viewModel.customAuthService.globalProps.isZendeskVisible = true;
  }

  public async viewParamsUpdated() {
    let clientId = this.viewParams.clientId.asNullableInt();

    if (clientId === null) {
      await this.viewModel.getClientId()
      this.viewParams.clientId.value = this.viewModel.clientId
      this.viewParams.campaignsMode.value = textConstants.titleText.Blacklist
      clientId = this.viewModel.clientId
    }

    if (clientId) {
      if (this.viewParams.campaignsMode.value === textConstants.titleText.Blacklist) {
        // Initialize ViewModel
        this.viewModel.ClientSettingsViewModel = new ClientSettingsVM();
        this.viewModel.ClientSettingsViewModel.showBlacklist = true
        this.viewModel.ClientSettingsViewModel.isComXUser = false
        this.viewModel.ClientSettingsViewModel.taskRunner = this.viewModel.taskRunner;
        this.viewModel.ClientSettingsViewModel.clientId = clientId
        // Set values & fetch data
        const clientName = await this.viewModel.getClientName(clientId);
        this.viewModel.ClientSettingsViewModel.clientName = clientName
        await this.viewModel.ClientSettingsViewModel.fetchClient(clientId);
        this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.blacklistSearchCriteria.clientId = clientId;
        this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.taskRunner = this.viewModel.taskRunner;
        this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.blacklistedDomainsApiClient = this.viewModel.blacklistApiClient;
        this.viewModel.ClientSettingsViewModel.blacklistedDomainHelper.notifications = this.viewModel.notifications;
        this.viewModel.showDashboard = false
        this.viewModel.showBlacklist = true
      }

    } else {
      this.viewModel.initialCampaignLoad = true;
      this.viewModel.showBlacklist = false
      this.viewModel.showDashboard = true
      this.navigation.navigateToView(DashboardView, { clientId: null });
    }
  }

  public render() {
    return (
      <React.Fragment>
        {this.viewModel.showBlacklist === true &&
          <div className="customMarginTop24">
            <BlacklistedDomain viewModel={this.viewModel.ClientSettingsViewModel}
            />
          </div>
        }

        {this.viewModel.showTargetMarkets &&
          <TargetMarketView viewModel={this.viewModel.TargetMarketViewModel}
            back={() => {
              this.viewModel.showTargetMarkets = false;
              this.viewModel.showBlacklist = true;
              this.viewParams.campaignsMode.value = textConstants.titleText.Blacklist;
            }}

            openUpdateTargetMarket={() => { }}

            openAddTargetMarket={() => {
              this.viewParams.campaignsMode.value = `AddTargetMarket`;
              this.viewModel.showTargetMarkets = false;
            }}
            openTargetMarketAccounts={(targetMarketId: number) => {
              this.viewModel.showTargetMarkets = false;
              this.viewModel.targetMarketId = targetMarketId;
              this.viewParams.campaignsMode.value = `Target Market Accounts`;
            }}
            openEditTargetMarket={(targetMarketId: number, targetMarketName: string) => {
              this.viewModel.showTargetMarkets = false;
              this.viewModel.targetMarketName = targetMarketName;
              this.viewModel.targetMarketId = targetMarketId;
              this.viewParams.campaignsMode.value = `EditTargetMarket`;
            }}

            openProspectTargetMarket={(targetMarketId: number, prospectingCount: number) => { }}
          />}

        {this.viewModel.showAddTargetMarket &&
          <AddEditTargetMarketView
            viewModel={this.viewModel.AddEditTargetMarketViewModel}
            back={() => {
              this.viewModel.showAddTargetMarket = false;
              this.viewModel.showTargetMarkets = true;
              this.viewParams.campaignsMode.value = textConstants.titleText.TargetMarkets;
            }}
            clientName={this.viewModel.TargetMarketViewModel.clientName}
          />
        }

        {this.viewModel.showEditTargetMarket &&
          <AddEditTargetMarketView viewModel={this.viewModel.AddEditTargetMarketViewModel}
            back={() => {
              this.viewModel.showEditTargetMarket = false;
              this.viewModel.showTargetMarkets = true;
              this.viewParams.campaignsMode.value = textConstants.titleText.TargetMarkets;
            }
            }
            clientName={this.viewModel.TargetMarketViewModel.clientName}
          />
        }

        {this.viewModel.showTargetMarketAccounts &&
          <TargetMarketAccountsView viewModel={this.viewModel.TargetMarketAccountsViewModel}
            back={() => {
              this.viewModel.showTargetMarketAccounts = false;
              this.viewModel.showTargetMarkets = true;
              this.viewParams.campaignsMode.value = textConstants.generalText.TargetMarkets
            }
            } />

        }
      </React.Fragment>
    );
  }
}