import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { textConstants } from '../../../common/textConstants';
import OnboardingSideMenuMaintenanceVM from './OnboardingSideMenuMaintenanceVM';
import OnboardingSideMenuLookup from '../../../Models/Onboarding/Queries/OnboardingSideMenuLookup';
import RichTextEditor from '../../../Components/RichTextEditor';


export interface IOnboardingIndividualScreenViewProps {
    viewModel: OnboardingSideMenuMaintenanceVM,
    onboardingSideMenuItem: OnboardingSideMenuLookup,
    save(): void
}

@observer
export default class OnboardingIndividualScreenView extends React.Component<IOnboardingIndividualScreenViewProps> {

    onLeave() {

    }

    private isLessonText: boolean = false
    private tempUpdatedHtml: string = "";

    public render() {
        return (
            <div className='onboarding-step-menu-data'>
                <div className='row m-0'>
                    <h3 className='col-12 p-24 mt-3 mb-3 pt-0'>{this.props.viewModel.onboardingSideMenuItem.screenName}</h3>
                    <div className='col-12 p-24'>
                        <div className='row'>
                            <div className="col-sm-1 pt-2 pb-2"><div>Transcript</div></div>
                            <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.onboardingSideMenuItem.meta.transcriptText} disabled={true} suppressLabel />
                            <div className="col-sm-1 text-center">
                                <Neo.Button
                                    className="btn-width-100"
                                    variant="light"
                                    onClick={() => {
                                        this.props.viewModel.showUpdateTextModal = true;
                                        this.isLessonText = false
                                    }}>
                                    {textConstants.buttonText.Edit}
                                </Neo.Button>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-sm-1 pt-2 pb-2">Lesson Text</div>
                            <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.onboardingSideMenuItem.meta.lessonText} disabled={true} suppressLabel/>
                            <div className="col-sm-1 text-center pull-right">
                                <Neo.Button
                                    className="btn-width-100"
                                    variant="light"
                                    onClick={() => {
                                        this.props.viewModel.showUpdateTextModal = true;
                                        this.isLessonText = true;
                                    }}>
                                    {textConstants.buttonText.Edit}
                                </Neo.Button>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-sm-1 pt-2 pb-2">Header Text</div>
                            <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.onboardingSideMenuItem.meta.headerText} suppressLabel/>

                        </div>
                        <div className='row'>
                            <div className="col-sm-1 pt-2 pb-2">Video URL</div>
                            <Neo.FormGroup className="col-sm-10 mb-2" bind={this.props.onboardingSideMenuItem.meta.videoURL} suppressLabel/>
                        </div>
                    </div>
                </div>

                <div className="my-4 text-right p-24">
                    <Neo.Button className="btn-width-100" isSubmit variant="success"
                        onClick={() => this.props.save()}>{textConstants.buttonText.Save}</Neo.Button>
                </div>

                {/*Edit modals*/}
                <Neo.Modal
                    title={`Update Display Text`}
                    className={"modal-style-test"}
                    show={this.props.viewModel.showUpdateTextModal}
                    onClose={() => this.props.viewModel.showUpdateTextModal = false}
                    closeButton={{ text: "Cancel", variant: "light" }}
                    acceptButton={{
                        text: "Done", variant: "secondary",
                        onClick: () => {

                            if (this.isLessonText) {
                                this.props.onboardingSideMenuItem.lessonText = this.tempUpdatedHtml
                            }
                            else {
                                this.props.onboardingSideMenuItem.transcriptText = this.tempUpdatedHtml
                            }
                            this.props.viewModel.showUpdateTextModal = false
                        }
                    }}
                    formProps={{ model: this.props.viewModel.updateSideMenuDataCommand }}>
                    <div>
                        <RichTextEditor
                            initialString={this.isLessonText ? this.props.onboardingSideMenuItem.lessonText : this.props.onboardingSideMenuItem.transcriptText}
                            getHtmlText={(htmlText: string) => {
                                this.tempUpdatedHtml = htmlText;
                            }} />
                    </div>
                </Neo.Modal>
            </div >
        );
    }
}