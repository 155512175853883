import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';
import { textConstants } from '../../common/textConstants';

@NeoModel
export default class InvalidReason extends ModelBase {

    public invalidReasonId: number = 0;
    
    @Rules.StringLength(500)
    @Attributes.Display("Invalid Reason")
    public invalidReasonMessage: string = "";

    public uniqueTableKey: string = "";

    // Client only properties / methods
    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.invalidReasonMessage === null || c.invalidReasonMessage === '', textConstants.messageText.validationMessage.invalidReasonPrompt);
    }

    public toString(): string {
        if (this.isNew || !this.invalidReasonMessage) {
            return "New Invalid Reason";
        } else {
            return this.invalidReasonMessage;
        }
    }
}